import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { DealsList } from "../Deals/DealList";
import { CreateDealButton } from "../createDeal";
// import { DashboardCard } from "./DashboardCard";

// future work on dashboard cards

// const dashboardCards = [
//   { value: 77, text: "Total" },
//   { value: 24, text: "About to expire" },
//   { value: 60, text: "Pending approvals" },
//   { value: 24, text: "Outstanding" },
// ];

export const DashboardDealsOverview: React.FC = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Dashboard</Typography>
        <CreateDealButton />
      </Box>

      <Card elevation={10} sx={{ p: 3, mt: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1">
            <StarsRoundedIcon
              sx={{ marginRight: 1, verticalAlign: "middle" }}
            />
            Deals assigned to me
          </Typography>

          <Button
            endIcon={<ArrowForward />}
            color="primary"
            size="medium"
            variant="outlined"
            href="/Deals"
          >
            Go to deals pipeline
          </Button>
        </Box>

        {/* <Grid container spacing={1}>
          {dashboardCards.map((card) => (
            <Grid item xs={12} sm={6} md={3} key={card.text}>
              <DashboardCard value={card.value} text={card.text} />
            </Grid>
          ))}
        </Grid> */}
        <Box sx={{ height: 1500, width: 1, mt: 2, mb: 10 }}>
          <DealsList toolBar={false} filterToUser />
        </Box>
      </Card>
    </>
  );
};
