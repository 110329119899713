import {
  LazyQueryExecFunction,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { formatISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  AddDealDocument,
  AddDealsContactDocument,
  BaseLandlordServices_Insert_Input,
  Companies_Insert_Input,
  DealData_Insert_Input,
  DealFitoutLandlordWorks_Insert_Input,
  DealPermittedUses_Insert_Input,
  DealPhases_Insert_Input,
  DealSpecialConditions_Insert_Input,
  DealStatus_Enum,
  DealsCompanies_Insert_Input,
  DealsContacts_Insert_Input,
  DealsUnits_Insert_Input,
  GetAllPhasesDocument,
  GetDealContactsDocument,
  GetDealsDocument,
  GetStatesDocument,
  GetTemplateSpecialConditionsDocument,
  GetTemplateSpecialConditionsQuery,
  GetTenantContactTypeDocument,
  Companies_Constraint,
  Companies_Update_Column,
  DealsCompanies_Constraint,
  GetContactDocument,
  GetContactQuery,
  GetCompaniesDocument,
} from "../../generated/graphql-operations";
import {
  AddNewContactOption,
  CONTACT_TENANT_TYPE,
  ContactType,
  DEAL_CREATE_ACTIVE_PHASE_ID,
  DealPhaseStatus,
  DealTenantType,
  DealType,
  DealTypeEnum,
  GeneralAmountTypes,
  VIRTUAL_UNIT_LABEL,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../../utils/CommonVariables";
import {
  CompanyTenantType,
  DealData,
  IndividualTenantType,
  TenantInformationType,
  defaultData,
} from "./DealConfig";
import {
  hoardingAndSkinsToggleToInt,
  toggleToInt,
} from "../../utils/CommonFunctions";

import { NO_OPTION_USE } from "./StepTwo/PermittedUsage/PermittedUsage";
import { convertTermsToDays } from "../../utils/DateUtils";

export const useGetStateData = (country_id: number) => {
  const states = useQuery(GetStatesDocument, { variables: { country_id } });
  return states;
};

export const useGetSpecialConditions = (
  onLoad: (data: GetTemplateSpecialConditionsQuery) => void
) => {
  return useQuery(GetTemplateSpecialConditionsDocument, {
    onCompleted: onLoad,
  });
};

const createTenantObject = (tenants: TenantInformationType) => {
  const tenantContacts: DealsContacts_Insert_Input[] = tenants.individuals.map(
    (tenant: IndividualTenantType) => {
      let tenantObj: DealsContacts_Insert_Input = { contact_id: tenant.id };

      if (tenant.id === -1) {
        tenantObj = {
          Contact: {
            data: {
              Location: {
                data: {
                  street1: tenant.address,
                  street2: "",
                  suburb: tenant.city,
                  state_id: tenant.state?.value,
                  postcode: parseInt(tenant.postCode ?? "", 10) || null,
                },
              },
              phone: tenant.phone,
              email: tenant.email,
              // TODO: check these fields don't exist anymore
              // trading_name: tenant.tradingName,
              // legal_name: tenant.legalName,
              first_name: tenant.firstName,
              middle_name: tenant.middleName,
              last_name: tenant.lastName,
            },
          },
        };
      }

      return {
        contact_type: ContactType.Tenant,
        tag: CONTACT_TENANT_TYPE,
        ...tenantObj,
      };
    }
  );

  const tenantCompanies: DealsCompanies_Insert_Input[] = tenants.companies.map(
    (company: CompanyTenantType) => {
      let tenantObj: DealsCompanies_Insert_Input = {
        company_id: company.company.value,
      };

      if (company.company.value === -1) {
        let addressObj: Companies_Insert_Input = {
          address_id: company.addressId,
        };
        if (company.addressId === -1) {
          addressObj = {
            Location: {
              data: {
                street1: company.address,
                street2: "",
                suburb: company.city,
                state_id: company.state?.value,
                postcode: parseInt(company.postCode ?? "", 10) || null,
              },
            },
          };
        }

        tenantObj = {
          Company: {
            data: {
              // trading_name: company.tradingName,
              legal_name: company.legalName,
              acn: company.acn !== "" ? company.acn : null,
              email: company.email,
              phone: company.phone,
              ...addressObj,
            },
            on_conflict: {
              constraint: Companies_Constraint.UniqueacnConst,
              update_columns: [Companies_Update_Column.TradingName],
            },
          },
        };
      }

      let contactObj: DealsCompanies_Insert_Input = {
        contact_id: company.contactSelect.value,
      };

      if (company.contactSelect.value === AddNewContactOption.value) {
        // We need to create a contact
        contactObj = {
          Contact: {
            data: {
              first_name: company.firstName,
              middle_name: company.middleName,
              last_name: company.lastName,
              legal_name: `${company.firstName} ${company.lastName}`,
              email: company.email,
              phone: company.phone,
              // TODO not sure how i am going to add this into contacts
              // company_id: companyId,
              Location: {
                data: {
                  street1: company.address,
                  street2: "",
                  suburb: company.city,
                  state_id: company.state?.value,
                  postcode: parseInt(company.postCode ?? "", 10) || null,
                },
              },
            },
          },
        };
      }

      return { ...tenantObj, ...contactObj };
    }
  );

  return { tenantContacts, tenantCompanies };
};

const GetNoticeAddress = async (
  isIndividualTenant: boolean,
  tenantObj: ReturnType<typeof createTenantObject>,
  getContact: LazyQueryExecFunction<GetContactQuery, any>
) => {
  if (isIndividualTenant) {
    const newTenantLocation =
      tenantObj.tenantContacts?.[0].Contact?.data?.Location?.data;
    if (!newTenantLocation) {
      return (
        await getContact({
          variables: { contactId: tenantObj.tenantContacts?.[0]?.contact_id },
        })
      ).data?.Contacts_by_pk?.Location;
    }
    return newTenantLocation;
  }

  const newTenantLocation =
    tenantObj.tenantCompanies?.[0]?.Company?.data?.Location?.data;

  if (!newTenantLocation) {
    return (
      await getContact({
        variables: {
          contactId: tenantObj.tenantCompanies?.[0]?.contact_id,
        },
      })
    ).data?.Contacts_by_pk?.Location;
  }
  return newTenantLocation;
};

export const useTransformAndSubmitData = () => {
  const [data, setData] = useState<DealData>(defaultData);

  const navigate = useNavigate();
  const [AddDeal] = useMutation(AddDealDocument);
  const [AddDealContacts] = useMutation(AddDealsContactDocument);

  const [getPhases] = useLazyQuery(GetAllPhasesDocument);
  const [getContactTypeId] = useLazyQuery(GetTenantContactTypeDocument);
  const [getSpecialConditions] = useLazyQuery(
    GetTemplateSpecialConditionsDocument
  );
  const [getContact] = useLazyQuery(GetContactDocument);

  const onSubmit = async () => {
    const phases = (await getPhases()).data?.Phases ?? [];
    const specialConditions =
      (await getSpecialConditions()).data?.TemplateSpecialConditions ?? [];
    const contactTypeId = (await getContactTypeId()).data?.ContactTypes[0]?.id;

    if ([specialConditions, phases].some((item) => item.length === 0)) {
      throw new Error("Failed to fetch specialConditions");
    }

    if ([contactTypeId].includes(undefined)) {
      throw new Error("Failed to fetch contactTypes");
    }

    const fitoutDay = parseInt(data.fitoutPeriodDays, 10) || 0;
    const fitoutWeek = parseInt(data.fitoutPeriodWeeks, 10) || 0;

    const dealPhasesObj: DealPhases_Insert_Input[] = phases?.map((item) => ({
      is_tenancy_delivery: item.is_tenancy_delivery,
      sequence: item.sequence,
      name: item.name,
      status_id:
        item.id === DEAL_CREATE_ACTIVE_PHASE_ID
          ? DealPhaseStatus.Active
          : DealPhaseStatus.Inactive,
      DealActionItem: {
        data:
          item?.PhaseActionItem?.map((ActionItem) => ({
            required: ActionItem.ActionItem?.required ?? false,
            state: ActionItem.ActionItem?.state ?? false,
            metadata: ActionItem.ActionItem?.metadata,
            content: ActionItem.ActionItem?.content,
          })) ?? [],
      },
    }));

    // If renewal, we'll complete the first two phases

    /* eslint-disable no-param-reassign */
    if (data?.dealType === DealType.Renewal) {
      dealPhasesObj.forEach((phase) => {
        if (phase.name === "Terms" || phase.name === "Proposal") {
          phase.DealActionItem?.data.forEach((item) => {
            item.state = true;
          });

          phase.status_id = DealPhaseStatus.Complete;
        } else if (phase.name === "Offer") {
          phase.status_id = DealPhaseStatus.Active;
        }

        if (!data?.tenantWorksToggle && !data?.landlordWorksToggle) {
          if (phase.is_tenancy_delivery || phase.name === "Tenancy delivery") {
            phase.DealActionItem?.data.forEach((item) => {
              item.state = true;
            });

            phase.status_id = DealPhaseStatus.Complete;
          }
        }
      });
    }
    /* eslint-enable no-param-reassign */
    const dealPermittedUsesObj: DealPermittedUses_Insert_Input = {
      template_id:
        data.permittedUse.value === NO_OPTION_USE.value
          ? null
          : data.permittedUse.value,
      description: data.permittedUsePreview,
      food_menu: null,
      sublet: null,
    };

    const baseLandlordWorksObj: BaseLandlordServices_Insert_Input = {
      as_per_existing_toggle: false,
      air_conditioning: false,
      cool_room: false,
      electrical_dist_load_1_phase: false,
      electrical_dist_load_3_phase: false,
      floor_covering: false,
      grease_trap: false,
      hot_water_services: false,
      lighting: false,
      mechanical_exhaust: false,
      painted_walls: false,
      separate_utility_meter_gas: false,
      separate_utility_meter_water: false,
      shop_front: false,
      sink: false,
      sprinklers: false,
      suspended_ceilings: false,
      telephone: false,
      waste: false,
      water_supply: false,
      other: false,
      other_description: "",
    };

    const fitoutLandlordWorksObj: DealFitoutLandlordWorks_Insert_Input = {
      tenant_works: data.tenantWorksToggle,
      tenant_works_options: data.tenantWorks?.join(","),
      landlord_undertake_works: data.landlordWorksToggle,
      landlord_works_type: data.landlordWorks.value,
      preview: data.landlordWorksPreview,
      fitout_description: data.fitoutDescription,
      hoarding: hoardingAndSkinsToggleToInt(data.hoardingToggle ?? "tenant"),
      skins: hoardingAndSkinsToggleToInt(data.skinsToggle ?? "tenant"),
    };

    const DealSpecialConditions: DealSpecialConditions_Insert_Input[] =
      data.selectedSpecialConditions
        .filter((item) => item !== undefined)
        .map((title) => {
          const conditionItem = specialConditions.find(
            (item) => item.title === title
          );
          return {
            title,
            description: conditionItem?.description ?? "",
            laf_description: conditionItem?.laf_description ?? "",
            template_id: conditionItem?.id ?? null,
          };
        });

    let dealUnitObj: DealsUnits_Insert_Input = { unit_key: data.unit.value };
    if (data?.unit.label === VIRTUAL_UNIT_LABEL) {
      dealUnitObj = {
        VirtualUnit: {
          data: {
            estimated_area: parseInt(data?.estimatedArea, 10),
            label: data?.virtualUnit,
            property_code: data?.centre.value,
            unit_type: data?.unitType.value,
          },
        },
        description: data?.virtualUnitDescription,
      };
    } else if (data?.unit?.label?.includes(VIRTUAL_UNIT_LABEL_SUFFIX)) {
      dealUnitObj = {
        virtual_unit_id: data?.unit?.value,
        description: data?.virtualUnitDescription,
      };
    }

    const tenantObj = createTenantObject(data.tenantInfo);

    // Want to recreate this in case Contacts change in the future
    const noticeAddressLocationObj = await GetNoticeAddress(
      data.tenantInfo?.tenantType?.value === DealTenantType.Individual,
      tenantObj,
      getContact
    );

    const DealDataObj: DealData_Insert_Input = {
      lease_start: formatISO(data.leaseCommenecementDate, {
        representation: "date",
      }),
      lease_term: convertTermsToDays(data.leaseCommenecementDate, {
        years: data.termYear,
        months: data.termMonth ?? 0,
        days: data.termDay ?? 0,
      }),
      fitout_term: fitoutDay + fitoutWeek * 7,
      base_net_rent: data.baseRent,
      annual_increase: data.rentReviewAnnualIncrease,
      annual_increase_type: GeneralAmountTypes["%"],
      outgoings_psm: data.ratePerSquareMetre,
      handover_date: formatISO(data.leaseCommenecementDate, {
        representation: "date",
      }),
      promotion_amt: data.promotionLevy,
      promotion_levy_type: data.promotionLevyType.value,
      promotion_levy_state: data.promotionLevyToggle,
      outgoings_state: data.outgoingsToggle,
      turnover_rent_state: data.turnoverRentToggle,
      turnover_based_rent_percent: data.turnover,
      bank_guarantee_id: toggleToInt(data.bankGuaranteeToggle),
      guarantors_id: toggleToInt(data.guarantorsToggle),
      bank_guarantee_months: data.guaranteeAmount,
      bank_guarantee_amount: 0,
      fitout_contribution: data.fitoutContributionAmount,
      tenancy_coordination_fee: data.tenancyCoordinationFeeToggle ? 2500 : null,
      cash_incentive: data.incentiveAmount,
      rent_deposit_months: 1,
      rent_deposit: 0,
      gross_rent: 0,
      notice_email:
        data.tenantInfo.tenantType.value === DealTenantType.Individual
          ? data.tenantInfo.individuals[0]?.email
          : data.tenantInfo.companies[0]?.email,
      DealPermittedUses: { data: [dealPermittedUsesObj] },
      BaseLandlordServices: { data: [baseLandlordWorksObj] },
      DealFitoutLandlordWork: { data: fitoutLandlordWorksObj },
      DealSpecialConditions: { data: DealSpecialConditions },
      DealsUnits: { data: [dealUnitObj] },
      analyst_deal_reason: "",
      rent_starts: false,
      rent_free_period: 0,
      fixed_handover_date: false,
      signage_location: "",
      parking_location: "",
      lease_type: data.renewal?.leaseType ?? "",
      sales_category: data.renewal?.salesCategory ?? "",
      NoticeServingAddress: {
        data: {
          street1: noticeAddressLocationObj?.street1 ?? "",
          street2: noticeAddressLocationObj?.street2 ?? "",
          suburb: noticeAddressLocationObj?.suburb,
          state_id: noticeAddressLocationObj?.state_id,
          postcode: noticeAddressLocationObj?.postcode,
        },
      },
    };

    const dataDeal = await AddDeal({
      variables: {
        deal: {
          name: crypto.randomUUID(),
          status: DealStatus_Enum.InProgress,
          deal_type_id:
            data.dealType === DealType.New
              ? DealTypeEnum.New
              : DealTypeEnum.Renewal,
          deal_made_from_active_lease: data.dealType === DealType.Renewal,
          trading_name: data.tenantInfo.tradingName,
          DealPhases: { data: dealPhasesObj },
          DealData: { data: [DealDataObj] },
          DealsCompanies: {
            data: tenantObj.tenantCompanies,
            on_conflict: {
              constraint:
                DealsCompanies_Constraint.DealscompaniesDealIdCompanyUnique,
              update_columns: [],
            },
          },
          DealsContacts: { data: tenantObj.tenantContacts },
          tenant_type_id: data.tenantInfo.tenantType.value,
          customer_code:
            data.tenantInfo.tradingNameSelect.value === ""
              ? null
              : data.tenantInfo.tradingNameSelect.value,
          tenant_code: data.tenantInfo.tenantCode,
        },
      },
      refetchQueries: [
        { query: GetDealsDocument },
        { query: GetCompaniesDocument },
      ],
    });

    if (dataDeal.data?.insert_Deals_one?.id === undefined) {
      throw new Error("Failed to create deal");
    }

    await AddDealContacts({
      variables: {
        dealContacts: dataDeal?.data?.insert_Deals_one?.DealsCompanies.map(
          (contact) => {
            return {
              contact_id: contact?.Contact?.id,
              deal_id: dataDeal.data?.insert_Deals_one?.id,
              contact_type: ContactType.Tenant,
              tag: CONTACT_TENANT_TYPE,
            };
          }
        ),
      },
      refetchQueries: [GetDealContactsDocument],
    });

    navigate(`/deals/${dataDeal.data.insert_Deals_one?.id}`, {
      replace: true,
    });
  };

  return [data, setData, onSubmit] as const;
};

export const buttonStepReducer = (
  state: { step: number },
  action: { type: string }
) => {
  switch (action.type) {
    case "next":
      return { step: state.step + 1 };
    case "back":
      return { step: state.step - 1 };
    default:
      throw new Error();
  }
};
