import React from "react";
import { useQuery } from "@apollo/client";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";

import { Stack } from "@mui/material";

import {
  GetAllContactsByCompanyDocument,
  GetAllContactsByCompanyQuery,
} from "../../generated/graphql-operations";

export interface CompanyListProps {
  companyId: number;
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const CompanyContactsList = ({
  companyId,
  toolBar,
  hideColumns = undefined,
}: CompanyListProps) => {
  const { data, loading, error } = useQuery(GetAllContactsByCompanyDocument, {
    variables: { company_id: companyId },
  });
  const columns: GridColDef<
    NonNullable<GetAllContactsByCompanyQuery["Contacts"][0]>
  >[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      valueGetter: (params) =>
        JSON.parse(params.row.content ?? "{}").position ?? "",
    },
  ];
  return (
    <DataGrid
      rows={data?.Contacts || []}
      columns={columns}
      loading={loading || error !== undefined}
      columnVisibilityModel={hideColumns}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
    />
  );
};

export default CompanyContactsList;
