import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Skeleton } from "@mui/material";
import { SelectField } from "../../../Form/Form";
import { EditorControlled } from "../../../Form/FormComponents";
import { useGetPermittedTemplates } from "../../../../adapters/DealInformation/Usage/Usage";
import { Alert } from "../../../Alert";
import { FinalStepDealProps, PermittedUseSchema } from "../../DealConfig";

export const NO_OPTION_USE = { label: "Other", value: -1 };

export const PermittedUsage = (props: FinalStepDealProps) => {
  const form = useForm({
    resolver: yupResolver(PermittedUseSchema),
    defaultValues: props.state,
    mode: "onChange",
  });
  const templates = useGetPermittedTemplates();

  if (!templates.data) {
    return <Skeleton />;
  }

  if (templates.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  const permittedChoices = [
    NO_OPTION_USE,
    ...templates.data.TemplatePermittedUses.map((item, index) => ({
      label: item.title ?? `Permitted use ${index}`,
      value: item.id,
    })),
  ];

  return (
    <Box width="100%">
      <SelectField
        form={form}
        formField={{
          type: "select",
          fieldName: "permittedUse",
          helperText: "",
          choices: permittedChoices,
          label: "Permitted use:",
          onChange: (_, choice) => {
            const newContent =
              templates?.data?.TemplatePermittedUses?.find(
                (item) => item.id === choice.value
              )?.description ?? "";
            form.setValue("permittedUsePreview", newContent);

            props.setState((prev) => ({
              ...prev,
              permittedUse: choice,
              permittedUsePreview: newContent,
            }));
          },
        }}
      />

      <Box p={1} width="100%" />

      <EditorControlled
        label="Preview"
        name="permittedUsePreview"
        control={form.control}
        onChange={(data) => {
          props.setState((prev) => ({
            ...prev,
            permittedUsePreview: data ?? "",
          }));
        }}
      />
    </Box>
  );
};
