import React from "react";
import { Grid, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  contactSchema,
  MapFields,
  ContactData,
  defaultContactData,
} from "./ContactConfig";

import { Button } from "../..";
import {
  useCreateContact,
  useGetContact,
  useUpdateContact,
} from "../../../adapters/Contact/Contact";

interface CreateUpdateContactDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  defaultData: ContactData;
  onSubmit: (data: ContactData) => {} | Promise<{}>;
}

interface CreateContactDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UpdateContactDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contactId: number;
}

export const CreateUpdateContactDialog = (
  props: CreateUpdateContactDialogProps
) => {
  const form = useForm({
    resolver: yupResolver(contactSchema),
    mode: "onBlur",
    defaultValues: props.defaultData,
  });

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700, pl: 3 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <MapFields form={form} />

          <Box width="100%" mt={2} />

          <Grid item p={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={form.formState.isSubmitting}
              onClick={form.handleSubmit(async (data) => {
                await props.onSubmit(data);
                props.setOpen(false);
              })}
            >
              Submit
            </Button>
          </Grid>

          <Grid item p={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.setOpen(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const CreateContactDialog = (props: CreateContactDialogProps) => {
  const createContact = useCreateContact();
  return (
    <CreateUpdateContactDialog
      {...props}
      title="Create new contact"
      defaultData={defaultContactData}
      onSubmit={createContact}
    />
  );
};

export const UpdateContactDialog = (props: UpdateContactDialogProps) => {
  const { contactData } = useGetContact(props.contactId ?? -1);
  const updateContact = useUpdateContact(props.contactId ?? -1);

  return (
    <CreateUpdateContactDialog
      {...props}
      title="Update contact"
      defaultData={contactData}
      onSubmit={updateContact}
    />
  );
};
