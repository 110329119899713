import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { Control, useForm } from "react-hook-form";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { ValidationProps } from "../SummaryTab/Files/Config";
import {
  NotificationEmailAttachmentTypes,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../../../utils/CommonVariables";
import { FormDate, FormTextField } from "../../Form/FormComponents";
import {
  DealShopActivities_Insert_Input,
  DealShopActivityTypes_Enum,
} from "../../../generated/graphql-operations";
import theme from "../../../themes/theme";
import { TextNumberField } from "../../Form/Form";
import { useCreateDealShopWithFileNotification } from "../../../adapters/Deal/DealShopNofitication";

interface ShopClosedProps {
  propertyName: string;
  tenantName: string;
  tenantCode: string;
  shopNumber: string;
  userId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShopClosedSchema = yup.object({
  propertyName: yup.string(),
  tenantName: yup.string(),
  shopNumber: yup.string(),
  reasonForLeaving: yup.string().required("Reason for leaving is required"),
  closureDate: yup.date().required("Closure date is required"),
  comment: yup.string().required("Comments is required"),
  fileName: yup.string().required("Photo is required"),
});

type ShopClosedDialogType = yup.InferType<typeof ShopClosedSchema>;

export const ShopClosedNotificationDialog: React.FC<ShopClosedProps> = (
  props
) => {
  const form = useForm<ShopClosedDialogType>({
    resolver: yupResolver(ShopClosedSchema),
    defaultValues: {
      closureDate: new Date(),
    },
  });

  const [userFile, setUserFile] = React.useState<File | undefined>(undefined);

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onShopClosedSubmit = useCreateDealShopWithFileNotification(
    null,
    props.tenantCode,
    userFile
  );

  const resetAndClose = () => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });
    setUserFile(undefined);
    form.reset();
    props.setOpen(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const ext = acceptedFiles[0].name.split(".").pop()?.toLowerCase();
        if (!NotificationEmailAttachmentTypes.includes(ext!)) {
          setValidation({
            show: true,
            severity: "error",
            message: "Only image files are accepted",
          });
          return;
        }
        setUserFile(acceptedFiles[0]);
        form.setValue("fileName", acceptedFiles[0]?.name!);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Shop Closed Notification</DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          maxWidth="md"
          sx={{ m: "0 auto" }}
        >
          <Paper sx={{ m: 2, p: 2 }} elevation={10}>
            <Grid container>
              <Grid container>
                <Grid item sm={12}>
                  <FormTextField
                    value={props.propertyName}
                    label="Property Name"
                    disabled
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormTextField
                    value={props.tenantName}
                    label="Tenant Name"
                    disabled
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormTextField
                    value={props.shopNumber}
                    label="Shop Number"
                    disabled
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormDate
                    fieldName="closureDate"
                    title="Closure Date"
                    control={form.control as unknown as Control}
                    onChange={(e) => {
                      form.setValue("closureDate", e ?? new Date(""));
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextNumberField
                    form={form}
                    formField={{
                      fieldName: "reasonForLeaving",
                      helperText: "",
                      label: "Reason for leaving",
                      type: "text",
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextNumberField
                    form={form}
                    formField={{
                      fieldName: "comment",
                      helperText: "",
                      label: "Comments",
                      type: "text",
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <InputLabel>Photo Attachment</InputLabel>
                </Grid>
                <Grid item sm={12}>
                  <TextNumberField
                    form={form}
                    formField={{
                      fieldName: "fileName",
                      helperText: "",
                      label: "",
                      type: "text",
                      disabled: true,
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Card
                    sx={{
                      my: 2,
                      backgroundColor: isDragActive
                        ? theme.palette.secondary.dark
                        : theme.palette.secondary.main,
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive
                      ? "Drop file here"
                      : "Drop file here, or click to select file"}
                  </Card>

                  {validation.show && (
                    <Alert sx={{ mt: 2 }} severity={validation.severity}>
                      {validation.message}
                    </Alert>
                  )}
                </Grid>

                <Grid item p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    disabled={form.formState.isSubmitting}
                    onClick={form.handleSubmit(async (data) => {
                      const insertObj: DealShopActivities_Insert_Input = {
                        property_name: props.propertyName,
                        tenant_name: props.tenantName,
                        tenant_id: props.tenantCode,
                        shop_number: props.shopNumber.replace(
                          VIRTUAL_UNIT_LABEL_SUFFIX,
                          ""
                        ),
                        date_of_closure: data.closureDate.toDateString(),
                        comments: data.comment,
                        reason_for_leaving: data.reasonForLeaving,
                        activity: DealShopActivityTypes_Enum.ShopClose,
                        generated_by: props.userId,
                        file_name: data.fileName,
                      };
                      try {
                        await onShopClosedSubmit(insertObj);
                        resetAndClose();
                      } catch (error: any) {
                        setValidation({
                          message: `Received error: ${error?.message ?? ""}`,
                          severity: "error",
                          show: true,
                        });
                      }
                    })}
                  >
                    Send Notification
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      resetAndClose();
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
