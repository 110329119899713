import { AskResponse, Citation } from "./models";

type ParsedAnswer = {
  citations: Citation[];
  markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
  let answerText = answer.answer;
  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = "[doc".length;

  const filteredCitations = [] as Citation[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation: Citation = {
      ...answer.citations[Number(citationIndex) - 1],
    };

    if (!filteredCitations.some((c) => c.id === citationIndex)) {
      citationReindex += 1;
      answerText = answerText.replaceAll(link, ` [${citationReindex}] `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindexId = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
    }
  });

  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
  };
}
