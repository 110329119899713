import React from "react";
import { useSubscription } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";

import { useActionLaf } from "../../adapters/Laf/Laf";
import { FormTextField } from "../Form/FormComponents";
import { DealPhaseStatus } from "../../utils/CommonVariables";
import { Role, useReturnUserRole } from "../../contexts/Auth/UserRole";

import {
  LafActionChoices_Enum,
  SubGetApprovalsDocument,
} from "../../generated/graphql-operations";
import { useDealDisabled } from "../../adapters/DisabledDeal";

const actionDetails: Record<
  "approve" | "request" | "reject" | "escalate" | "startLaf" | "restart",
  { title: string; subtitle: string; alertTitle?: string }
> = {
  approve: {
    title: "Approve LAF",
    subtitle: "Add your comment",
    alertTitle: undefined,
  },
  startLaf: {
    title: "Start LAF",
    subtitle: "Add your comment",
    alertTitle: undefined,
  },
  request: {
    title: "Request action",
    subtitle: "Please detail the changes you are requesting",
    alertTitle: undefined,
  },
  escalate: {
    title: "Approve and escalate",
    subtitle: "Add your comment",
    alertTitle: undefined,
  },
  reject: {
    title: "Reject LAF",
    subtitle: "Add your comment",
    alertTitle: "By rejecting the LAF this deal won't be proceeding",
  },
  restart: {
    title: "Restart LAF",
    subtitle: "Add your comment",
    alertTitle:
      "Please confirm that you would like to restart the LAF. All current progress will be lost!",
  },
};

interface ActionDialogProps {
  open: boolean;
  closeDialog: () => void;
  submitDialog: (comment: string) => Promise<void>;
  details: typeof actionDetails["approve"];
  // stops users from loading data twice
  loadingState: boolean;
  setLoadingState: (loaddata: boolean) => void;
}

const ActionDialog = (props: ActionDialogProps) => {
  const [commentText, setcommentText] = React.useState("");
  const [errorSubmit, setErrorSubmit] = React.useState(false);
  const closeErrorSubmit = () => {
    setErrorSubmit(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcommentText(event.target.value);
  };
  const clearCloseDialog = () => {
    props.closeDialog();
    setcommentText("");
  };
  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>{props.details.title}</DialogTitle>
      <DialogContent>
        {props.details.alertTitle && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {props.details.alertTitle}
          </Alert>
        )}
        <FormTextField
          value={commentText}
          label={props.details.subtitle}
          textFieldProps={{
            multiline: true,
            onChange: handleChange,
          }}
        />

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            disabled={props.loadingState}
            onClick={async () => {
              props.setLoadingState(true);
              try {
                await props.submitDialog(commentText);
              } catch {
                props.setLoadingState(false);
                setErrorSubmit(true);
                return;
              }
              props.setLoadingState(false);
              clearCloseDialog();
            }}
            variant="contained"
          >
            Submit
          </Button>

          <Button onClick={clearCloseDialog} variant="text" sx={{ mr: 1 }}>
            Cancel
          </Button>
        </DialogActions>
        <Snackbar
          open={errorSubmit}
          autoHideDuration={6000}
          onClose={closeErrorSubmit}
        >
          <Alert
            onClose={closeErrorSubmit}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong, try submitting again.
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export const LafActionButtons = ({
  dealDataId,
  dealId,
  getLafAndCreateFile,
}: {
  dealDataId: number;
  dealId: number;
  getLafAndCreateFile: () => Promise<void>;
}) => {
  const theme = useTheme();
  const { instance } = useMsal();
  const { disabled: dealDisabled } = useDealDisabled(dealId);
  const currenUser = instance.getActiveAccount();
  const currentUserId = currenUser?.idTokenClaims?.oid ?? "";
  const leaseAdmin = useReturnUserRole() === Role.Admin;
  const [loadingState, setLoadingState] = React.useState(false);

  const { data, loading, error } = useSubscription(SubGetApprovalsDocument, {
    variables: { deal_data_id: dealDataId },
    fetchPolicy: "no-cache",
    onSubscriptionComplete: () => setLoadingState(false),
  });
  const lafApproval = data?.DealData_by_pk?.DealLAFApprovals;
  const lafPhase = data?.DealData_by_pk?.Deal?.DealPhases?.[0];

  const { openDialog: openApproveDialog, ...DialogApproveProps } = useActionLaf(
    dealId,
    dealDataId,
    LafActionChoices_Enum.Approved
  );

  const { openDialog: openRequestDialog, ...DialogRequestProps } = useActionLaf(
    dealId,
    dealDataId,
    LafActionChoices_Enum.RequestChange
  );

  const { openDialog: openApprovePendingDialog, ...DialogApprovePendingProps } =
    useActionLaf(dealId, dealDataId, LafActionChoices_Enum.LeaseAdminApproval);

  const { openDialog: openRestartDialog, ...DialogRestartProps } = useActionLaf(
    dealId,
    dealDataId,
    LafActionChoices_Enum.Restart
  );

  const { openDialog: openEscalateDialog, ...DialogEscalateProps } =
    useActionLaf(dealId, dealDataId, LafActionChoices_Enum.Escalation);

  const { openDialog: openRejectDialog, ...DialogRejectProps } = useActionLaf(
    dealId,
    dealDataId,
    LafActionChoices_Enum.Reject
  );

  const approvalAction = lafApproval?.laf_action;
  const escalateLevel = lafApproval?.escalate_level;
  const lastApprover = lafApproval?.last_approver;

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 1 }}>
        Error loading data, please refresh page
      </Alert>
    );
  }

  // Check if users should be able to modify laf workflow
  if (
    approvalAction === LafActionChoices_Enum.Reject ||
    loading ||
    error ||
    lafPhase?.status_id !== DealPhaseStatus.Active ||
    dealDisabled
  ) {
    return null;
  }

  if (
    (approvalAction === LafActionChoices_Enum.RequestChange ||
      approvalAction === undefined) &&
    leaseAdmin
  ) {
    return (
      <>
        <ActionDialog
          {...DialogApprovePendingProps}
          details={actionDetails.startLaf}
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />

        <ActionDialog
          {...DialogRestartProps}
          details={actionDetails.restart}
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />

        <Button
          onClick={openApprovePendingDialog}
          sx={{ [theme.breakpoints.down("sm")]: { width: "100%" }, mt: 1 }}
          variant="contained"
        >
          {approvalAction === LafActionChoices_Enum.RequestChange
            ? "Continue LAF"
            : "Begin LAF"}
        </Button>

        {approvalAction === LafActionChoices_Enum.RequestChange && (
          <Button
            onClick={openRestartDialog}
            sx={{
              [theme.breakpoints.down("sm")]: { width: "100%" },
              mt: 1,
              ml: 1,
            }}
            color="error"
            variant="contained"
          >
            Restart LAF
          </Button>
        )}
      </>
    );
  }

  if (!lafApproval?.ActiveApprover?.id) {
    return null;
  }

  const isCurrentApprover =
    lafApproval?.ActiveApprover?.id?.toLowerCase() ===
    currentUserId.toLowerCase();

  return (
    <Grid container>
      {isCurrentApprover && (
        <Grid item xs={12} sm="auto" p={1}>
          <ActionDialog
            {...DialogApproveProps}
            submitDialog={async (comment) => {
              if (escalateLevel || lastApprover) {
                await getLafAndCreateFile();
              }
              await DialogApproveProps.submitDialog(comment);
            }}
            details={actionDetails.approve}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
          <Button
            onClick={openApproveDialog}
            sx={{ width: "100%" }}
            variant="contained"
          >
            Approve
          </Button>
        </Grid>
      )}
      {escalateLevel && isCurrentApprover ? (
        <Grid xs={12} sm="auto" p={1} item>
          <ActionDialog
            {...DialogEscalateProps}
            details={actionDetails.escalate}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
          <Button
            onClick={openEscalateDialog}
            sx={{ width: "100%" }}
            variant="contained"
          >
            Approve & escalate
          </Button>
        </Grid>
      ) : null}
      {(isCurrentApprover || leaseAdmin) && (
        <Grid xs={12} sm="auto" p={1} item>
          <ActionDialog
            {...DialogRequestProps}
            details={actionDetails.request}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
          <Button
            onClick={openRequestDialog}
            sx={{ width: "100%" }}
            variant="outlined"
          >
            Request action
          </Button>
        </Grid>
      )}
      {isCurrentApprover && (
        <Grid xs={12} sm="auto" p={1} item>
          <ActionDialog
            {...DialogRejectProps}
            details={actionDetails.reject}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
          <Button
            onClick={openRejectDialog}
            color="error"
            sx={{ width: "100%" }}
            variant="contained"
          >
            Reject
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
