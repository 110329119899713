import { useMutation } from "@apollo/client";
import {
  DealRiskLevels_Enum,
  UpdateDealRiskLevelDocument,
} from "../../generated/graphql-operations";

export const useUpdateRiskLevel = () => {
  const [updateDealRiskLevel, { loading }] = useMutation(
    UpdateDealRiskLevelDocument
  );

  return [
    loading,
    async (dealId: number, riskLevel: DealRiskLevels_Enum) => {
      await updateDealRiskLevel({
        variables: { id: dealId, risk_level: riskLevel },
      });
    },
  ] as const;
};
