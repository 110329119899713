import { useMutation } from "@apollo/client";
import {
  CreateLocationDocument,
  Locations_Insert_Input,
  UpdateLocationDocument,
} from "../../../generated/graphql-operations";

export const useCreateLocation = () => {
  const [createLocationMutation] = useMutation(CreateLocationDocument);

  const createLocation = async (location: Locations_Insert_Input) => {
    const newLocation = await createLocationMutation({
      variables: {
        location,
      },
    });

    return newLocation;
  };

  return createLocation;
};

export const useUpdateLocation = () => {
  const [updateLocationMutation] = useMutation(UpdateLocationDocument);

  const updateLocation = async (id: number, data: Locations_Insert_Input) => {
    const updatedLocation = await updateLocationMutation({
      variables: {
        id,
        data,
      },
    });

    return updatedLocation;
  };

  return updateLocation;
};
