import { useMutation, useQuery } from "@apollo/client";
import {
  CreateContactDocument,
  UpdateContactDocument,
  GetContactDocument,
  GetContactsDocument,
  Locations_Insert_Input,
  Companies_Insert_Input,
  Contacts_Insert_Input,
  CreatePropertyContactDocument,
  GetContactsByPropertyDocument,
} from "../../generated/graphql-operations";
import { ContactData } from "../../components/Contacts/CreateUpdateContact/ContactConfig";
import { useCreateLocation, useUpdateLocation } from "./Locations/Locations";
import { useCreateCompany } from "../Company/Company";
import { PropertyContactData } from "../../pages/Property/PropertyContact/Config";

export const useGetContact = (id: number) => {
  const contact = useQuery(GetContactDocument, {
    variables: {
      contactId: id,
    },
  });

  const contactData: ContactData = {
    firstName: contact.data?.Contacts_by_pk?.first_name ?? "",
    middleName: contact.data?.Contacts_by_pk?.middle_name ?? "",
    lastName: contact.data?.Contacts_by_pk?.last_name ?? "",
    email: contact.data?.Contacts_by_pk?.email ?? "",
    phone: contact.data?.Contacts_by_pk?.phone ?? "",
    company: {
      label: contact.data?.Contacts_by_pk?.Company?.legal_name ?? "",
      value: contact.data?.Contacts_by_pk?.Company?.id ?? -1,
    },
    legalName: contact.data?.Contacts_by_pk?.Company?.legal_name ?? "",
    acn: contact.data?.Contacts_by_pk?.Company?.acn ?? "",
    position:
      JSON.parse(contact.data?.Contacts_by_pk?.content ?? "{}").position ?? "",
    address: contact.data?.Contacts_by_pk?.Location?.street1 ?? "",
    suburb: contact.data?.Contacts_by_pk?.Location?.suburb ?? "",
    state: {
      label: contact.data?.Contacts_by_pk?.Location?.State?.name ?? "",
      value: contact.data?.Contacts_by_pk?.Location?.State?.id ?? -1,
    },
    postcode:
      contact.data?.Contacts_by_pk?.Location?.postcode === null
        ? ""
        : `${contact.data?.Contacts_by_pk?.Location?.postcode}`,
  };

  return { contact, contactData };
};

export const useCreateContact = () => {
  const [createContactMutation] = useMutation(CreateContactDocument);
  const createLocation = useCreateLocation();
  const createCompany = useCreateCompany();

  const createContact = async (data: ContactData) => {
    const contactData: Contacts_Insert_Input = {
      first_name: data.firstName,
      middle_name: data.middleName,
      last_name: data.lastName,
      phone: data.phone,
      email: data.email,
      company_id: null,
      address_id: null,
      content: JSON.stringify({ position: data.position }),
    };

    const locationData: Locations_Insert_Input = {
      street1: data.address,
      street2: "",
      suburb: data.suburb,
      state_id: data.state.value,
      // TODO: change postcode to number type across all app OR future proof it by making it a string type in the data model
      postcode: parseInt(data.postcode ?? "", 10) || undefined,
    };

    const location = await createLocation(locationData);
    contactData.address_id = location.data?.insert_Locations_one?.id ?? null;

    if (data.company.value !== -1) {
      contactData.company_id = data.company.value as number;
    } else if (data.company.value === -1 && data.company.label !== "") {
      const companyData: Companies_Insert_Input = {
        legal_name: data.legalName,
        acn: data.acn ?? "",
      };

      const newCompany = await createCompany(companyData);

      contactData.company_id = newCompany?.data?.insert_Companies_one?.id ?? -1;
    }

    const newContact = await createContactMutation({
      variables: {
        contact: contactData,
      },
      refetchQueries: [{ query: GetContactsDocument }],
    });

    return newContact;
  };

  return createContact;
};

export const useUpdateContact = (contactID: number) => {
  const [updateContactMutation] = useMutation(UpdateContactDocument);
  const { contact } = useGetContact(contactID);
  const updateLocation = useUpdateLocation();
  const createCompany = useCreateCompany();

  const updateContact = async (data: ContactData) => {
    const content = JSON.parse(contact.data?.Contacts_by_pk?.content ?? "{}");
    content.position = data.position ?? "";

    const updatedContactData: Contacts_Insert_Input = {
      first_name: data.firstName,
      middle_name: data.middleName,
      last_name: data.lastName,
      phone: data.phone,
      email: data.email,
      company_id: data.company.value ?? -1,
      address_id: contact.data?.Contacts_by_pk?.address_id ?? -1,
      content: JSON.stringify(content),
    };

    const locationData: Locations_Insert_Input = {
      street1: data.address,
      street2: "",
      suburb: data.suburb,
      state_id: data.state.value,
      // TODO: change postcode to number type across all app OR future proof it by making it a string type in the data model
      postcode: parseInt(data.postcode ?? "", 10) || undefined,
    };

    await updateLocation(
      contact.data?.Contacts_by_pk?.address_id ?? -1,
      locationData
    );

    if (data.company.value !== -1) {
      updatedContactData.company_id = data.company.value as number;

      const updatedContact = await updateContactMutation({
        variables: {
          id: contactID,
          data: updatedContactData,
        },
        refetchQueries: [GetContactDocument],
      });

      return updatedContact;
    }

    const companyData: Companies_Insert_Input = {
      legal_name: data.legalName,
      acn: data.acn ?? "",
    };

    const newCompany = await createCompany(companyData);

    updatedContactData.company_id =
      newCompany?.data?.insert_Companies_one?.id ?? -1;

    const updatedContact = await updateContactMutation({
      variables: {
        id: contactID,
        data: updatedContactData,
      },
      refetchQueries: [GetContactDocument],
    });

    return updatedContact;
  };

  return updateContact;
};

export const useCreatePropertyContact = () => {
  const [CreatePropertyContact] = useMutation(CreatePropertyContactDocument);
  const createContact = useCreateContact();

  const createPropertyContact = async (
    propertyId: string,
    contactData: PropertyContactData
  ) => {
    const contactDataTransform: ContactData = {
      firstName: contactData.firstName,
      middleName: contactData.middleName ?? "",
      lastName: contactData.lastName,
      email: contactData.email,
      phone: contactData.phone,
      company: { label: "", value: -1 },
      legalName: "",
      acn: "",
      address: "",
      suburb: "",
      postcode: "",
      state: { label: "", value: 1 },
      position: contactData.position,
    };

    const contact = await createContact(contactDataTransform);

    const propertyContact = {
      contact_id: contact?.data?.insert_Contacts_one?.id,
      property_code: propertyId,
    };

    await CreatePropertyContact({
      variables: { data: propertyContact },
      refetchQueries: [GetContactsByPropertyDocument],
    });
  };

  return createPropertyContact;
};
