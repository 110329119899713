import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmationDialogProps {
  title: string;
  description: string;
  confirmText: string;
  declineText: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void | Promise<void>;
  onDecline?: () => void | Promise<void>;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.onDecline?.();
            handleClose();
          }}
        >
          {props.declineText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.onConfirm();
            handleClose();
          }}
          autoFocus
        >
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
