import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { SelectField } from "../../Form/Form";
import { EditorControlled } from "../../Form/FormComponents";
import { NO_CONDITION_USE } from "./Config";
import { DealInformationSectionProps } from "../DealInformationConfig";
import {
  transformSpecialConditions,
  useGetDealSpecialConditions,
} from "../../../adapters/DealInformation/SpecialConditions/SpecialConditions";
import { GetDealSpecialConditionsQuery } from "../../../generated/graphql-operations";
import { Alert } from "../../Alert";
import { CreateButton } from "../../CreateButton";
import { Button } from "../../Button";

const SpecialCondition = ({
  form,
  index,
  templateSpecialConditions,
}: {
  form: DealInformationSectionProps["form"];
  index: number;
  templateSpecialConditions: GetDealSpecialConditionsQuery["TemplateSpecialConditions"];
}) => {
  const prefixName = "specialConditions.conditions";

  const conditionsOptions = React.useMemo(
    () => [
      ...templateSpecialConditions.map((item) => ({
        label: item.title ?? "",
        value: item.id,
      })),
      NO_CONDITION_USE,
    ],
    [templateSpecialConditions]
  );

  return (
    <Grid container>
      <Grid item sm={12} px={1} pb={1}>
        <SelectField
          form={form}
          formField={{
            type: "select",
            fieldName: `${prefixName}.${index}.specialCondition`,
            helperText: "",
            choices: conditionsOptions,
            label: "Special condition",
            onChange: (_, choice) => {
              const newCondition = templateSpecialConditions.find(
                (item) => item.id === choice.value
              );
              form.setValue(
                `${prefixName}.${index}.description`,
                newCondition?.description ?? ""
              );
              form.setValue(
                `${prefixName}.${index}.descriptionLaf`,
                newCondition?.laf_description ?? ""
              );
            },
          }}
        />
      </Grid>

      <Grid item sm={12} px={1} pb={1}>
        <EditorControlled
          label="Description"
          name={`${prefixName}.${index}.description`}
          control={form.control}
        />
      </Grid>

      <Grid item sm={12} p={1}>
        <EditorControlled
          label="Description for LAF:"
          name={`${prefixName}.${index}.descriptionLaf`}
          control={form.control}
          height={80}
        />
      </Grid>
    </Grid>
  );
};

export const SpecialConditions = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;
  const data = useGetDealSpecialConditions(
    props.dealId,
    (dataComplete) => {
      form.setValue(
        "specialConditions.conditions",
        transformSpecialConditions(dataComplete).conditions
      );
      setDataState((prev) => ({ ...prev, specialConditionsLoaded: true }));
    },
    () => setDataState((prev) => ({ ...prev, specialConditionsLoaded: false }))
  );

  const optionsFieldArray = useFieldArray({
    name: "specialConditions.conditions",
    control: form.control,
  });

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  return (
    <Box width="100%">
      {optionsFieldArray.fields.map((field, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={field.id + index}>
          <SpecialCondition
            form={props.form}
            index={index}
            templateSpecialConditions={
              data.data?.TemplateSpecialConditions ?? []
            }
          />
          <Grid container justifyContent="flex-end">
            <Grid item p={1}>
              <Button
                startIcon={<Delete />}
                color="error"
                variant="text"
                onClick={() => {
                  optionsFieldArray.remove(index);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Box width="100%" p={1} />

      <Grid item p={1}>
        <CreateButton
          startIcon={<Add />}
          color="primary"
          variant="text"
          onClick={() =>
            optionsFieldArray.append({
              description: "",
              descriptionLaf: "",
              specialCondition: {
                label: "",
                value: NO_CONDITION_USE.value,
              },
            })
          }
        >
          Add special condition
        </CreateButton>
      </Grid>
    </Box>
  );
};
