import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  DealRiskLevels_Enum,
  GetDealRiskLevelDocument,
} from "../../../generated/graphql-operations";
import { DealOverviewChip } from "../DealOverview/DealOverview";
import { RiskSelectorDialog } from "./RiskSelectorDialog";

interface RiskLevelSelectProps {
  dealId: number;
}

export const RiskLevelSelect = (props: RiskLevelSelectProps) => {
  const [currentRisk, setCurrentRisk] = useState<DealRiskLevels_Enum>();
  const { data } = useQuery(GetDealRiskLevelDocument, {
    variables: { deal_id: props.dealId },
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const onRiskUpdated = async (riskLevel?: DealRiskLevels_Enum) => {
    if (!riskLevel || riskLevel === currentRisk) return;

    setCurrentRisk(riskLevel);
  };

  // Need to wait for the risk level to be loaded before setting it in state
  React.useEffect(() => {
    const risk = data?.Deals_by_pk?.risk_level;

    if (risk) {
      setCurrentRisk(risk);
    }
  }, [data?.Deals_by_pk?.risk_level]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography paddingRight={2} variant="body1">
          Deal Risk Level:
        </Typography>
        <DealOverviewChip label={currentRisk || "Select"} />
        <IconButton
          size="small"
          onClick={() => {
            handleModalOpen();
          }}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Stack>
      <RiskSelectorDialog
        open={modalOpen}
        dealId={props.dealId}
        initialRisk={currentRisk}
        onClose={() => setModalOpen(false)}
        onRiskChange={onRiskUpdated}
      />
    </>
  );
};
