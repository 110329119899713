import { useQuery, useMutation } from "@apollo/client";
import {
  ApproverSettingsType,
  ApproverType,
  EscalatedApproverType,
} from "../../components/ApproverSettings/Config";
import {
  Approvers_Insert_Input,
  CreateApproversDocument,
  GetApproversDocument,
  GetApproversQuery,
  CreateEsclatedApproversDocument,
  GetEsclatedApproversQuery,
  DeleteEsclatedApproversDocument,
} from "../../generated/graphql-operations";
import { allPropertiesOption } from "../../components/ApproverSettings/Approver";

export const useGetApprovers = () => {
  const approvers = useQuery(GetApproversDocument);
  return approvers;
};

export const useUpdateApproverSettings = () => {
  const [createApprovers] = useMutation(CreateApproversDocument);

  return async (data: ApproverSettingsType) => {
    const approverData: Approvers_Insert_Input[] = Object.entries(data).flatMap(
      ([level, approvers]) =>
        approvers
          ?.filter((approver) => approver?.user?.[0]?.value !== undefined)
          .map((approver) => {
            const properties =
              approver?.properties
                ?.filter((property) => property?.value !== "")
                .map((property) => ({
                  property_code: property?.value,
                })) ?? [];
            return {
              user_id: approver?.user?.[0]?.value ?? "",
              level: parseInt(level.split("_").pop() ?? "", 10),
              CenterApprovers: { data: properties },
            };
          }) ?? []
    );

    await createApprovers({
      variables: { objects: approverData },
    });
  };
};

export const transformData = (data: GetApproversQuery) => {
  const obj: { [k: string]: ApproverType[] } = {};
  data?.Approvers?.forEach((approver) => {
    obj[`approver_${approver.level}`] = [
      ...(obj[`approver_${approver.level}`] ?? []),
      {
        user: [
          {
            label: approver.User?.user_full_name ?? "",
            value: approver.User?.id,
          },
        ],
        properties:
          approver.CenterApprovers.length > 0
            ? approver.CenterApprovers?.map((centerApprover) => ({
                label: centerApprover.Property?.PropertyName ?? "",
                value: centerApprover.Property?.PropertyCode ?? "",
              }))
            : [allPropertiesOption],
      },
    ];
  });

  return obj;
};

export const transformEscalatedApproverData = (
  data: GetEsclatedApproversQuery
): EscalatedApproverType["users"] => {
  return data.EscalatedApprovers.map((item) => ({
    label: item.User?.user_full_name ?? "",
    value: item?.User?.id,
  }));
};

export const useUpdateEscalatedApprover = () => {
  const [createEscalatedApprover] = useMutation(
    CreateEsclatedApproversDocument
  );
  const [deleteEscalatedApprover] = useMutation(
    DeleteEsclatedApproversDocument
  );

  return async (data: EscalatedApproverType["users"]) => {
    await deleteEscalatedApprover();
    if (data === undefined || data.length === 0) {
      return;
    }
    await createEscalatedApprover({
      variables: {
        objects: data?.map((item, index) => ({
          level: index,
          user_id: item.value,
        })),
      },
    });
  };
};
