import * as yup from "yup";

export interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const PreviewSchema = yup.object({
  deal: yup
    .object({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .test(
      "NoDeal",
      "Must select Deal",
      (value) => !(value.value === -1 && value.label === "")
    ),
});
