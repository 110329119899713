import React, { useState } from "react";
import { Typography, Grid, Stack, Container } from "@mui/material";
import { CreateButton, BreadCrumbs } from "../components";
import { CompanyList } from "../components/Companies/CompanyList";
import { CreateCompanyDialog } from "../components/Companies/CreateUpdateCompany/CreateUpdateCompany";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";

export const Companies = () => {
  const [createCompanyOpen, setCreateCompanyOpen] = useState(false);
  const disable = ![Role.Admin, Role.TdAssignee].includes(
    useReturnUserRole() as Role
  );

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Companies</Typography>
            <CreateButton
              disabled={disable}
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => setCreateCompanyOpen(true)}
            >
              Create new company
            </CreateButton>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mb: 1 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Companies", url: "/Companies" },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: 800,

            width: "100%",
          }}
        >
          <CompanyList toolBar />
        </Grid>

        <CreateCompanyDialog
          open={createCompanyOpen}
          setOpen={setCreateCompanyOpen}
        />
      </Grid>
    </Container>
  );
};
