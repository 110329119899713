import { useQuery, useMutation } from "@apollo/client";
import { formatISO, parseISO } from "date-fns";
import { DateInfoType } from "../../../components/DealInformation/DateInformation/Config";
import {
  GetDealInfoDateDocument,
  GetDealInfoDateQuery,
  UpdateDealInfoDateDocument,
} from "../../../generated/graphql-operations";
import {
  convertDaysToTerms,
  convertDaysToWeekTerms,
  convertTermsToDays,
} from "../../../utils/DateUtils";

export const transformDateInfo = (data: GetDealInfoDateQuery): DateInfoType => {
  const dealData = data.Deals_by_pk?.DealData[0];
  const leaseCommencementDate = parseISO(dealData!.lease_start);
  const leaseTerm = convertDaysToTerms(
    leaseCommencementDate,
    dealData?.lease_term ?? 0
  );
  const fitoutPeriod = convertDaysToWeekTerms(dealData?.fitout_term ?? 0);
  return {
    handoverDate: parseISO(dealData?.handover_date ?? ""),
    leaseCommencementDate,
    leaseTermYear: leaseTerm?.years ?? 0,
    leaseTermMonth: leaseTerm?.months ?? 0,
    leaseTermDay: leaseTerm?.days ?? 0,
    fitoutPeriodWeek: fitoutPeriod.weeks,
    fitoutPeriodDay: fitoutPeriod.days,
    fixedHandoverDate: dealData?.fixed_handover_date ?? false,
    rentFreePeriodWeek: dealData?.rent_free_period ?? 0,
    rentStarts: dealData?.rent_starts ?? false,
  };
};

export const useMutateDateInfo = (deal_id: number) => {
  // If refetching queries is too slow then we can manually update the cache using
  // https://www.apollographql.com/docs/react/data/mutations/#refetching-after-update
  // Or we can consider using subscriptions instead
  const [updateDateInfo] = useMutation(UpdateDealInfoDateDocument, {
    refetchQueries: [GetDealInfoDateDocument],
  });
  return async (data: DateInfoType) => {
    await updateDateInfo({
      variables: {
        deal_id,
        update_object: {
          lease_start: formatISO(data.leaseCommencementDate, {
            representation: "date",
          }),
          handover_date: formatISO(data.handoverDate, {
            representation: "date",
          }),
          fitout_term: data.fitoutPeriodDay + data.fitoutPeriodWeek * 7,
          lease_term: convertTermsToDays(data.leaseCommencementDate, {
            years: data.leaseTermYear,
            months: data.leaseTermMonth,
            days: data.leaseTermDay,
          }),
          fixed_handover_date: data.fixedHandoverDate,
          rent_starts: data.rentStarts,
          rent_free_period: data.rentFreePeriodWeek,
        },
      },
    });
  };
};

export const useGetDealInfoDate = (dealId: number) => {
  const dealInfoUnitInfo = useQuery(GetDealInfoDateDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return dealInfoUnitInfo;
};
