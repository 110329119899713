import React from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { GetDealLinksDocument } from "../../../../generated/graphql-operations";
import { LinksCard } from "./LinksCard";

export const LinksComponent = (props: { dealId: number }) => {
  const dealLinks = useQuery(GetDealLinksDocument, {
    variables: {
      deal_id: props.dealId,
    },
  });

  const links = dealLinks?.data?.DealLinks;

  return (
    <Grid container spacing={2}>
      <Grid container item sm={12} spacing={2}>
        {links?.map((link) => {
          return (
            <Grid item xs="auto" key={link.id}>
              <LinksCard data={link} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
