import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { GetLafIncentiveDocument } from "../../generated/graphql-operations";
import { ScrollableTable, ScrollableTableProps } from "./ScrollableTable";
import { dollarFormat, EMPTY_SYMBOL } from "./Common";
import { Alert } from "../Alert";
import { ReadOnlyEditor } from "../Form/RichTextEditor";

const formatter = (value: number | null | undefined) => {
  if (typeof value === "number") {
    return dollarFormat.format(value);
  }
  return value ?? EMPTY_SYMBOL;
};

const columns = ["Item", "Amount", "Description"] as const;

type Columns = typeof columns[number];

export const LeaseIncentiveTable = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafIncentiveDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const dealUnits = data?.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0];
  const budgetInfo =
    dealUnits?.SavedYardiUnitProfile ??
    dealUnits?.YardiUnit?.YardiUnitProfiles?.[0];

  // TODO: Mat cost needs to be integrated latter when added to deal info
  const fitout = data.Deals_by_pk?.DealData[0].fitout_contribution ?? undefined;
  const cashIncentive =
    data.Deals_by_pk?.DealData[0].cash_incentive ?? undefined;
  const budgetCashIncentive =
    budgetInfo?.DBUDGETLANDLORDCONTRIBTOFITOUT ?? undefined;

  const landlordWorks =
    data.Deals_by_pk?.DealData[0].DealFitoutLandlordWork?.cost_landlord_works ??
    undefined;
  const budgetLessorWorks = budgetInfo?.DBUDGETEDLANDLORDWORKCOST ?? undefined;

  const makegood =
    data.Deals_by_pk?.DealData[0].DealFitoutLandlordWork?.cost_makegood ??
    undefined;

  const budgetmakeGood = budgetInfo?.DBUDGETMAKEGOOD ?? undefined;

  const cappedcat1 =
    data.Deals_by_pk?.DealData[0].tenant_contribution_landlord_works ??
    undefined;

  const landlordPreview =
    data.Deals_by_pk?.DealData[0].DealFitoutLandlordWork?.preview ?? "";

  const totalCapVars = [makegood, landlordWorks, cashIncentive, fitout];
  let totalCap: number = 0;
  if (totalCapVars.some((a) => a !== undefined)) {
    totalCap = totalCapVars.reduce(
      (partialSum, a) => (partialSum ?? 0) + (a ?? 0),
      0
    );
  }

  const budgetVariance =
    totalCap -
    (budgetCashIncentive ?? 0) -
    (budgetmakeGood ?? 0) -
    (budgetLessorWorks ?? 0);

  const rows: ScrollableTableProps<Columns>["rows"] = [
    {
      Item: "Fitout contribution",
      Amount: formatter(fitout),
      Description: "",
      key: "fitout",
    },
    {
      Item: "Budget Incentive",
      Amount: formatter(budgetCashIncentive),
      Description: "",
      key: "budgetCashIncentive",
    },
    {
      Item: "Cash incentive",
      Amount: formatter(cashIncentive),
      Description: "",
      key: "cashIncentive",
    },
    {
      Item: "Cost of landlord works",
      Amount: formatter(landlordWorks),
      Description: <ReadOnlyEditor content={landlordPreview} />,
      key: "CostLandlord",
    },
    {
      Item: "Budget lessorworks",
      Amount: formatter(budgetLessorWorks),
      Description: "",
      key: "CostLandlordBudget",
    },
    {
      Item: "Cost of makegood works",
      Amount: formatter(makegood),
      Description: "",
      key: "makegood",
    },
    {
      Item: "Budget Makegood",
      Amount: formatter(budgetmakeGood),
      Description: "",
      key: "makegoodBudget",
    },
    {
      Item: "Capped CAT1 costs",
      Amount: formatter(cappedcat1),
      Description: "",
      key: "capped",
    },
    {
      Item: "Total capital",
      Amount: formatter(totalCap),
      Description: "",
      key: "totalCap",
      sx: { fontWeight: "bold", backgroundColor: "secondary.main" },
    },
    {
      Item: "Budget variance",
      Amount: formatter(budgetVariance),
      Description: "",
      key: "BudgetVariance",
      sx: { fontWeight: "bold", backgroundColor: "secondary.main" },
    },
  ];

  return <ScrollableTable columns={columns} rows={rows} />;
};
