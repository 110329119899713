import { useQuery, useMutation } from "@apollo/client";
import {
  OptionTermType,
  DEFAULT_REVIEW_TYPE,
} from "../../../components/DealInformation/OptionTerms/Config";
import {
  GetDealInfoDealOptionsQuery,
  GetDealInfoDealOptionsDocument,
  DeleteDealOptionsDocument,
  CreateDealOptionsDocument,
} from "../../../generated/graphql-operations";
import { convertMonthsToYearsTerms } from "../../../utils/DateUtils";
import { GeneralAmountTypes } from "../../../utils/CommonVariables";

export const useGetDealInfoOptionTerms = (dealId: number) => {
  const dealInfoUnitInfo = useQuery(GetDealInfoDealOptionsDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return dealInfoUnitInfo;
};

export const transformOptionTerms = (
  data: GetDealInfoDealOptionsQuery
): OptionTermType => {
  const dealData = data.Deals_by_pk?.DealData[0];

  return {
    options: dealData?.DealOptions.map((item) => {
      const terms = convertMonthsToYearsTerms(item.term_length ?? Number.NaN);
      const year1ReviewType =
        Object.keys(GeneralAmountTypes).find(
          (typeValue) => typeValue === item.first_year_type
        ) ?? DEFAULT_REVIEW_TYPE.label;
      const yearSubsequentReviewType =
        Object.keys(GeneralAmountTypes).find(
          (typeValue) => typeValue === item.subsequent_year_type
        ) ?? DEFAULT_REVIEW_TYPE.label;
      return {
        TermYear: Number.isNaN(terms.years) ? undefined : terms.years,
        TermMonth: Number.isNaN(terms.months) ? undefined : terms.months,
        year1Review: item.first_year_amount ?? undefined,
        year1ReviewType: {
          label: year1ReviewType,
          value: year1ReviewType,
        },
        yearSubsequentReview: item.subsequent_year_amount ?? undefined,
        yearSubsequentReviewType: {
          label: yearSubsequentReviewType,
          value: yearSubsequentReviewType,
        },
        worksRequired: item.works_required ?? "",
      };
    }),
  };
};

export const useMutateDealOptionTerms = (dealId: number) => {
  const [createDealOptions] = useMutation(CreateDealOptionsDocument);
  const [deleteDealOptions] = useMutation(DeleteDealOptionsDocument);

  return async (dealDataId: number, data: OptionTermType) => {
    const dealOptions =
      data.options?.map((item) => ({
        deal_data_id: dealDataId,
        first_year_amount: item.year1Review ?? null,
        first_year_type: item.year1ReviewType.label,
        subsequent_year_amount: item.yearSubsequentReview ?? null,
        subsequent_year_type: item.yearSubsequentReviewType.label,
        works_required: item.worksRequired ?? null,
        term_length:
          Number.isNaN(item.TermMonth) && Number.isNaN(item.TermYear)
            ? null
            : (item.TermYear ?? 0) * 12 + (item.TermMonth ?? 0),
      })) ?? [];

    await deleteDealOptions({
      variables: {
        deal_id: dealId,
      },
    });

    if (dealOptions.length === 0) return;

    await createDealOptions({
      variables: {
        objects: dealOptions,
      },
    });
  };
};
