import React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import {
  GetDealsByTenantCodeDocument,
  GetDealsByTenantCodeQuery,
} from "../../generated/graphql-operations";
import { VIRTUAL_UNIT_LABEL_SUFFIX } from "../../utils/CommonVariables";

export interface ActiveLeaseDealsListProps {
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
  tenantCode: string;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const ActiveLeaseDealsList = ({
  toolBar,
  hideColumns = undefined,
  tenantCode,
}: ActiveLeaseDealsListProps) => {
  const { data, loading, error } = useQuery(GetDealsByTenantCodeDocument, {
    variables: { tenant_code: tenantCode },
  });
  const navigate = useNavigate();
  const columns: GridColDef<
    NonNullable<GetDealsByTenantCodeQuery["Deals"][0]>
  >[] = [
    {
      field: "centre",
      headerName: "Centre",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealData[0]?.DealsUnits[0]?.Unit?.Property?.label ??
        params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property?.label ??
        "",
    },
    {
      field: "shop",
      headerName: "Shop",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealData[0]?.DealsUnits[0]?.Unit?.label ??
        (params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label
          ? `${params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label}${VIRTUAL_UNIT_LABEL_SUFFIX}`
          : null) ??
        "",
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      valueGetter: (params) => params.row.Owner?.user_full_name,
    },
    {
      field: "tradingname",
      headerName: "Trading name",
      flex: 1,
      valueGetter: (params) => params.row.trading_name,
    },
    {
      field: "tenant_code",
      headerName: "Tenant ID",
      flex: 1,
      valueGetter: (params) =>
        params.row.tenant_code ??
        params.row.YardiTenants_Deals?.[0]?.TenantCode ??
        "",
    },
  ];
  return (
    <DataGrid
      rows={data?.Deals || []}
      columns={columns}
      loading={loading || error !== undefined}
      columnVisibilityModel={hideColumns}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
      getRowId={(params) => params.id}
      onCellClick={(params, event) => {
        if (!event.ctrlKey) {
          navigate(`/Deals/${params?.row?.id}`);
        }
      }}
    />
  );
};

export default ActiveLeaseDealsList;
