import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextNumberField } from "../../Form/Form";
import { TextMultiField } from "../../Form/FormComponents";
import { CheckboxField } from "./CheckboxField";
import {
  TemplateUploadDialogSchema,
  TemplateUploadDialogType,
  ValidationProps,
} from "./Config";

import { useCreateTemplateSubmit } from "../../../adapters/Templates/TemplateUpload";

interface TemplateUploadDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: TemplateUploadDialogType;
  forceReload?: () => void;
  clearCache?: () => void;
}

export const TemplateUploadDialog = (props: TemplateUploadDialogProps) => {
  const theme = useTheme();
  const form = useForm<TemplateUploadDialogType>({
    resolver: yupResolver(TemplateUploadDialogSchema),
    defaultValues: props.data,
  });

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [userFile, setUserFile] = React.useState<File | undefined>(undefined);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const ext = acceptedFiles[0].name.split(".").pop();
        if (ext !== "docx") {
          setValidation({
            show: true,
            severity: "error",
            message: "Only docx files are accepted",
          });
          return;
        }
        setUserFile(acceptedFiles[0]);
        form.setValue("fileName", acceptedFiles[0]?.name);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const isNewTemplate = !props.data;
  const onSubmit = useCreateTemplateSubmit(
    setValidation,
    userFile,
    isNewTemplate
  );

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Upload template</DialogTitle>
      <DialogContent>
        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}
        <TextNumberField
          form={form}
          formField={{
            type: "text",
            fieldName: "generateToName",
            label: "Name",
            helperText: "",
            disabled: !isNewTemplate,
          }}
        />

        <TextMultiField
          control={form.control}
          formField={{
            fieldName: "information",
            label: "Information",
            helperText: "",
          }}
        />

        <CheckboxField
          control={form.control}
          fieldName="convertToPdf"
          label="PDF Output"
          helperText="Documents generated using this template will be read-only pdf files"
          disabled={!isNewTemplate}
        />

        <CheckboxField
          control={form.control}
          fieldName="hidden"
          label="Hidden"
          helperText="Documents that are hidden will only be visible to Lease Administrators"
          disabled={!isNewTemplate}
        />

        <TextNumberField
          form={form}
          formField={{
            fieldName: "fileName",
            helperText: "",
            label: "File name",
            type: "text",
            disabled: true,
          }}
        />

        <Card
          sx={{
            my: 2,
            backgroundColor: isDragActive
              ? theme.palette.secondary.dark
              : theme.palette.secondary.main,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive
            ? "Drop file here"
            : "Drop file here, or click to select file"}
        </Card>
        {userFile && (
          <Stack direction="row" justifyContent="flex-start">
            <InsertDriveFileOutlinedIcon />
            <Typography>{userFile.name}</Typography>
          </Stack>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            disabled={form.formState.isSubmitting}
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => {
              form.handleSubmit(async (data) => {
                await onSubmit(data);
                props.forceReload?.();
              })();
            }}
          >
            {form.formState.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
              setUserFile(undefined);
              form.reset();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
