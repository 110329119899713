import React from "react";
import { useMutation } from "@apollo/client";
import {
  CancelDealDocument,
  GetDealDocument,
} from "../../generated/graphql-operations";

export const useCancelDeal = (dealId: number) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [CancelDeal] = useMutation(CancelDealDocument);

  return [
    isSubmitting,
    async (
      cancelDate: Date,
      capitalIncurred: boolean,
      cancelComment?: string
    ) => {
      setIsSubmitting(true);
      try {
        await CancelDeal({
          variables: {
            dealId,
            cancelDate: cancelDate.toDateString(),
            capitalIncurred,
            cancelComment: cancelComment ?? "",
          },
          refetchQueries: [GetDealDocument],
        });
      } catch (e) {
        setIsSubmitting(false);
        throw e;
      }
      setIsSubmitting(false);
    },
  ] as const;
};
