import React from "react";
import { DataGrid, GridColDef, GridSortDirection } from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";

import { checkAndFormatDate } from "../../../utils/DateUtils";
import { GetUsersDocument } from "../../../generated/graphql-operations";
import {
  BlobVersions,
  useDownloadBlob,
} from "../../../adapters/Templates/TemplateUpload";

export const List = ({
  data,
  fileName,
}: {
  data: BlobVersions;
  fileName: string;
}) => {
  const users = useQuery(GetUsersDocument);
  const downloadBlob = useDownloadBlob();
  const initialState = {
    columns: {
      columnVisibilityModel: {
        version: false,
      },
    },
    sorting: {
      sortModel: [{ field: "date", sort: "desc" as GridSortDirection }],
    },
  };
  const columnFormat: GridColDef<NonNullable<BlobVersions[number]>>[] = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      maxWidth: 270,
      valueGetter: (params) => params.row.id,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      maxWidth: 175,
      type: "datetime",
      valueFormatter: (params) =>
        checkAndFormatDate(params.value, "dd/MM/yyyy - hh:mm a"),
      valueGetter: (params) => {
        return params.row.date;
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 2,
      valueGetter: (params) => params.row.information,
    },
    {
      field: "updated_by",
      headerName: "Updated by",
      flex: 1,
      valueGetter: (params) => {
        return users?.data?.Users?.find(
          (user) => user.id.toLowerCase() === params.row.modifiedBy
        )?.user_full_name;
      },
    },
    {
      field: "download",
      headerName: "Download",
      width: 100,
      hideable: false,
      resizable: false,
      sortable: false,
      type: "actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="Download template">
            <IconButton
              aria-label="download"
              color="primary"
              onClick={() => {
                downloadBlob(fileName, fileName, params.row.id);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (!users.data) {
    return <Skeleton />;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          headerHeight={35}
          rowHeight={50}
          rowsPerPageOptions={[]}
          loading={data.length === 0}
          rows={data}
          columns={columnFormat}
          initialState={initialState}
          pageSize={10}
        />
      </div>
    </div>
  );
};
