import React, { useEffect } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

import { SelectField } from "../../Form/Form";
import { EditorControlled } from "../../Form/FormComponents";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { NO_OPTION_USE } from "./Config";
import {
  TOGGLE_OPTIONS_TWO,
  ToggleTwoOption,
} from "../../../utils/CommonVariables";
import {
  transformUsage,
  useGetDealInfoUsage,
  useGetPermittedTemplates,
} from "../../../adapters/DealInformation/Usage/Usage";
import { Alert } from "../../Alert";
import { ToggleButton } from "../../ToggleButton";

const FOOD_MENU_TITLE = "Food Menu";
const SUBLET_TITLE = "Sublet to Franchisee";

export const UsageInfo = (props: DealInformationSectionProps) => {
  const data = useGetDealInfoUsage(props.dealId);
  const templates = useGetPermittedTemplates();
  const { form, setDataState } = props;
  const [foodToggleState, setFoodToggleState] = React.useState(
    ToggleTwoOption.No
  );

  const [subletToggleState, setSubletToggleState] = React.useState(
    ToggleTwoOption.No
  );

  useEffect(() => {
    if (data.data) {
      const dataTransform = transformUsage(data.data);
      form.setValue("usage", dataTransform);
      setFoodToggleState(
        dataTransform.foodMenu === undefined
          ? ToggleTwoOption.No
          : ToggleTwoOption.Yes
      );
      setSubletToggleState(
        dataTransform.subletFranchisee === undefined
          ? ToggleTwoOption.No
          : ToggleTwoOption.Yes
      );
      setDataState((prev) => ({ ...prev, usageLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, usageLoaded: false }));
    }
  }, [data, form, setDataState]);

  if (data.error || templates.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data || !templates.data) {
    return <Skeleton />;
  }

  const permittedChoices = [
    NO_OPTION_USE,
    ...templates.data.TemplatePermittedUses.filter(
      (item) => ![FOOD_MENU_TITLE, SUBLET_TITLE].includes(item.title ?? "")
    ).map((item, index) => ({
      label: item.title ?? `Permitted use ${index}`,
      value: item.id,
    })),
  ];

  const foodMenuDescription =
    templates.data.TemplatePermittedUses.find(
      (item) => item.title === FOOD_MENU_TITLE
    )?.description ?? "";

  const subletDescription =
    templates.data.TemplatePermittedUses.find(
      (item) => item.title === SUBLET_TITLE
    )?.description ?? "";

  return (
    <Box width="100%">
      <SelectField
        form={props.form}
        formField={{
          type: "select",
          fieldName: "usage.permittedUse",
          helperText: "",
          choices: permittedChoices,
          label: "Permitted use:",
          onChange: (_, choice) => {
            const newContent =
              templates?.data?.TemplatePermittedUses?.find(
                (item) => item.id === choice.value
              )?.description ?? "";
            form.setValue("usage.preview", newContent);
          },
        }}
      />

      <Box p={1} width="100%" />

      <EditorControlled
        label="Preview"
        name="usage.preview"
        control={form.control}
      />

      <Grid container>
        <Box p={1} width="100%" />
        <Grid item sm={4}>
          <Typography variant="subtitle2">
            Food menu required for lease
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <ToggleButton
            options={TOGGLE_OPTIONS_TWO}
            state={foodToggleState}
            handleState={(_, new_state) => {
              if (new_state === null) {
                return;
              }
              setFoodToggleState(new_state as ToggleTwoOption);
              if (new_state === ToggleTwoOption.Yes) {
                form.setValue("usage.foodMenu", foodMenuDescription);
              } else {
                form.setValue("usage.foodMenu", undefined);
              }
            }}
          />
        </Grid>

        <Grid item sm={12}>
          <EditorControlled
            label=""
            name="usage.foodMenu"
            control={form.control}
            disable={foodToggleState === ToggleTwoOption.No}
            height={80}
          />
        </Grid>

        <Box p={1} width="100%" />

        <Grid item sm={4}>
          <Typography variant="subtitle2">Sublet to franchisee</Typography>
        </Grid>
        <Grid item sm={2}>
          <ToggleButton
            options={TOGGLE_OPTIONS_TWO}
            state={subletToggleState}
            handleState={(_, new_state) => {
              if (new_state === null) {
                return;
              }
              setSubletToggleState(new_state as ToggleTwoOption);
              if (new_state === ToggleTwoOption.Yes) {
                form.setValue("usage.subletFranchisee", subletDescription);
              } else {
                form.setValue("usage.subletFranchisee", undefined);
              }
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <EditorControlled
            label=""
            name="usage.subletFranchisee"
            control={form.control}
            disable={subletToggleState === ToggleTwoOption.No}
            height={80}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
