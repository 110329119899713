import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Link,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDownloadBlob } from "../../../adapters/Deal/Files";
import { useGetBlobList } from "../../../adapters/Templates/TemplateUpload";
import {
  GetDealsDocument,
  GetDocumentTemplatesDocument,
  ToggleDocumentTemplateConvertToPdfDocument,
  ToggleDocumentTemplateHiddenDocument,
} from "../../../generated/graphql-operations";
import { BreadCrumbs } from "../../BreadCrumbs";
import { FormField, SelectField } from "../../Form/Form";
import { TemplateUploadDialog } from "../TemplateUploadDialog/TemplateUploadDialog";
import { PreviewSchema } from "./Config";
import { List } from "./List";
import { PreviewList } from "./PreviewList";

const HELPER_FILENAME = "Document Templates Guidance for Lease Easy.docx";
const HELPER_BLOB_FILEPATH =
  "help_documents/Document Templates Guidance for Lease Easy.docx";

export const TemplateDetails = () => {
  const [updateOpen, setUpdateOpen] = useState(false);
  const params = useParams();

  const templateName = params["*"] ?? "";
  const documentTemplates = useQuery(GetDocumentTemplatesDocument, {
    variables: {
      filters: {
        generate_to_name: { _eq: templateName },
        deleted: { _eq: false },
      },
    },
  });

  const state = documentTemplates.data?.DocumentTemplates?.[0];
  const generateToName = state?.generate_to_name ?? "";
  const fileName = `${state?.template_name ?? templateName}.docx`;
  const [blobs, clearBlobCache, forceReload] = useGetBlobList(fileName);

  const deals = useQuery(GetDealsDocument);
  const downloadFile = useDownloadBlob();

  const form = useForm({
    resolver: yupResolver(PreviewSchema),
  });

  const dealField: FormField = {
    type: "select",
    fieldName: "deal",
    label: "Select deal to preview available data",
    helperText: "",
    columns: 6,
    choices: deals?.data?.Deals?.map((deal) => ({
      label: `${deal.trading_name} (${deal.id})` ?? "",
      value: deal.id,
    })),
  };
  const [toggleConvertToPdf, { data }] = useMutation(
    ToggleDocumentTemplateConvertToPdfDocument
  );

  const [toggleHidden, { data: hiddenData }] = useMutation(
    ToggleDocumentTemplateHiddenDocument
  );

  const convertToPdf =
    data?.update_DocumentTemplates_by_pk?.convert_to_pdf ??
    state?.convert_to_pdf;

  const hidden =
    hiddenData?.update_DocumentTemplates_by_pk?.hidden ?? state?.hidden;

  if (documentTemplates.error) {
    return <div>Error loading template</div>;
  }

  if (documentTemplates.loading) {
    return <CircularProgress />;
  }

  if (state === undefined) {
    return <div>Template does not exist</div>;
  }

  return (
    <>
      <Grid item>
        <Alert
          variant="standard"
          severity="info"
          sx={{
            mt: 1,
            mb: 2,
            backgroundColor:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))",
            "& .MuiAlert-icon": {
              color: "black",
            },
          }}
        >
          Download the template builder instructions
          <Link
            sx={{ pl: 0.5 }}
            component="button"
            href="/"
            onClick={async () => {
              await downloadFile(HELPER_FILENAME, HELPER_BLOB_FILEPATH);
            }}
          >
            here
          </Link>
        </Alert>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{generateToName}</Typography>
          <div>
            <Tooltip
              title={
                convertToPdf
                  ? "Documents generated using this template will be read-only pdf when they are created"
                  : "Documents generated using this template will be editable docx files when they are created"
              }
            >
              <FormControlLabel
                label="PDF Output"
                labelPlacement="start"
                sx={{ mr: 5 }}
                control={
                  <Switch
                    id="convert-to-pdf-toggle"
                    checked={convertToPdf}
                    color="primary"
                    onChange={async () =>
                      toggleConvertToPdf({
                        variables: {
                          id: state?.id,
                          convert_to_pdf: !convertToPdf,
                        },
                      })
                    }
                  />
                }
              />
            </Tooltip>
            <Tooltip
              title={
                hidden
                  ? "This template will only be visible to Lease Administrators"
                  : "This template will be visible to all roles"
              }
            >
              <FormControlLabel
                label="Hidden"
                labelPlacement="start"
                sx={{ mr: 5 }}
                control={
                  <Switch
                    id="hidden-toggle"
                    checked={hidden}
                    color="primary"
                    onChange={async () =>
                      toggleHidden({
                        variables: {
                          id: state?.id,
                          hidden: !hidden,
                        },
                      })
                    }
                  />
                }
              />
            </Tooltip>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => setUpdateOpen(true)}
            >
              Upload new version
            </Button>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Templates", url: "/templates" },
            { text: state?.template_name ?? templateName, url: "" },
          ]}
        />
      </Grid>
      <Typography mt={3} mb={2} variant="h6">
        Template versions
      </Typography>
      <Grid
        item
        xs={12}
        sx={{
          height: 500,
          width: "100%",
        }}
      >
        <List data={blobs} fileName={fileName} />
      </Grid>
      <Card elevation={5} sx={{ mt: 2 }}>
        <Typography variant="h6">Preview</Typography>
        <Grid container mt={2}>
          <Grid item xs={4}>
            <SelectField form={form} formField={dealField} />
          </Grid>
          <Box width="100%" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: 500,
            width: "100%",
            mt: 2,
          }}
        >
          <PreviewList toolBar dealId={form.watch("deal")?.value} />
        </Grid>
      </Card>

      {updateOpen && (
        <TemplateUploadDialog
          open={updateOpen}
          setOpen={setUpdateOpen}
          data={{
            convertToPdf,
            generateToName,
            hidden,
            fileName: "",
            information: "",
          }}
          forceReload={forceReload}
          clearCache={clearBlobCache}
        />
      )}
    </>
  );
};
