import { Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { BreadCrumbs } from "../components";
import { ApproverSettings } from "../components/ApproverSettings/ApproverSettings";
import { EscalatedApprover } from "../components/ApproverSettings/EscalatedApprover";
import { useReturnUserRole, Role } from "../contexts/Auth/UserRole";
import { DISABLE_SX_PROPS } from "../utils/CommonVariables";

export const ApproverSettingsPage = () => {
  const disable = useReturnUserRole() !== Role.Admin;
  const disableSxProps = disable ? DISABLE_SX_PROPS : {};
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">Approvals settings</Typography>
        </Grid>
        <Grid item xs={22} sx={{ mb: 1 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Approvals settings", url: "" },
            ]}
          />
        </Grid>
      </Grid>
      <Card sx={{ my: 2, ...disableSxProps }}>
        <Typography variant="h6">Centre approvers</Typography>
        <ApproverSettings forceOpen={disable} />
      </Card>
      <Card sx={{ mt: 2, ...disableSxProps }}>
        <Typography variant="h6">Escalated approvers</Typography>
        <Typography color="GrayText" variant="subtitle2">
          These will be the approvers that will be escalated to after the last
          centre approver
        </Typography>
        <EscalatedApprover forceOpen={disable} />
      </Card>
    </Container>
  );
};
