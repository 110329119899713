import React, { useEffect } from "react";
import { Box, Grid, InputLabel, Skeleton, TextField } from "@mui/material";
import { Control, UseFormReturn } from "react-hook-form";
import { add } from "date-fns";
import { checkAndFormatDate } from "../../../utils/DateUtils";
import { Form, FormField } from "../../Form/Form";
import { FormDate, FormSwitch } from "../../Form/FormComponents";
import { DataState, DealInformationType } from "../DealInformationConfig";
import {
  transformDateInfo,
  useGetDealInfoDate,
} from "../../../adapters/DealInformation/DateInformation/DateInformation";
import { Alert } from "../../Alert";

interface DateInformationProps {
  dealId: number;
  form: UseFormReturn<DealInformationType, object>;
  setDataState: React.Dispatch<React.SetStateAction<DataState>>;
}

export const DateInformation = (props: DateInformationProps) => {
  const dateInfo = useGetDealInfoDate(props.dealId);

  const { form, setDataState } = props;

  useEffect(() => {
    if (dateInfo.data) {
      form.setValue("dateInfo", transformDateInfo(dateInfo.data));
      setDataState((prev) => ({ ...prev, dateInfoLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, dateInfoLoaded: false }));
    }
  }, [dateInfo, form, setDataState]);

  if (dateInfo.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!dateInfo.data) {
    return <Skeleton />;
  }

  const [
    leaseCommencementDate,
    fixedHandoverDate,
    leaseTermYear,
    leaseTermMonth,
    leaseTermDay,
  ] = form.watch([
    "dateInfo.leaseCommencementDate",
    "dateInfo.fixedHandoverDate",
    "dateInfo.leaseTermYear",
    "dateInfo.leaseTermMonth",
    "dateInfo.leaseTermDay",
  ]);

  const LeaseExpireDate =
    checkAndFormatDate(
      add(leaseCommencementDate, {
        years: leaseTermYear,
        months: leaseTermMonth,
        days: (leaseTermDay ?? 0) - 1,
      }),
      "dd/MM/yyyy"
    ) ?? "Not Valid Date";

  const fitoutFields: FormField[] = [
    {
      type: "number",
      fieldName: "dateInfo.fitoutPeriodWeek",
      label: "Fitout period",
      inlineLabel: "Weeks",
      helperText: "New deals only",
      columns: 6,
    },
    {
      type: "text",
      fieldName: "dateInfo.fitoutPeriodDay",
      label: "",
      inlineLabel: "Days",
      helperText: "",
      columns: 6,
    },
  ];

  const rentFreePeriodField: FormField = {
    type: "number",
    fieldName: "dateInfo.rentFreePeriodWeek",
    label: "Rent free period",
    inlineLabel: "Weeks",
    helperText: "",
    columns: 6,
  };

  const leaseTermField: FormField[] = [
    {
      type: "number",
      fieldName: "dateInfo.leaseTermYear",
      label: "Lease term",
      helperText: "",
      columns: 4,
    },
    {
      type: "number",
      fieldName: "dateInfo.leaseTermMonth",
      label: "",

      helperText: "",
      columns: 4,
    },
    {
      type: "number",
      fieldName: "dateInfo.leaseTermDay",
      label: "",

      helperText: "",
      columns: 4,
    },
  ];

  return (
    <Box width="100%">
      <Box p={1}>
        <FormSwitch
          name="dateInfo.rentStarts"
          title="Rent starts if trade commences prior to completion of fitout period"
          control={form.control}
        />
      </Box>

      <Box p={1}>
        <FormSwitch
          name="dateInfo.fixedHandoverDate"
          title="Handover date fixed"
          control={form.control}
        />
      </Box>

      <Box p={1}>
        <FormDate
          fieldName="dateInfo.handoverDate"
          title="Handover date"
          control={form.control as unknown as Control}
          disabled={fixedHandoverDate}
          onChange={(e) => {
            form.setValue("dateInfo.leaseCommencementDate", e ?? new Date(""));
          }}
        />
      </Box>

      <Grid container>
        <Grid item p={1} sm={6}>
          <FormDate
            fieldName="dateInfo.leaseCommencementDate"
            title="Lease commencement date"
            control={form.control as unknown as Control}
          />
        </Grid>

        <Grid item sm={6}>
          <Form form={form} fields={fitoutFields} />
        </Grid>

        <Grid item sm={6}>
          <Form form={form} fields={[rentFreePeriodField]} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={6}>
          <Form form={form} fields={leaseTermField} />
        </Grid>

        <Grid item sm={6} p={1}>
          <InputLabel>Lease expiry date</InputLabel>
          <TextField
            variant="filled"
            disabled
            value={LeaseExpireDate}
            margin="dense"
            inputProps={{ "data-testid": "lease-expire-date" }}
            fullWidth
            size="small"
            hiddenLabel
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
