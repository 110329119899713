import * as yup from "yup";

export const LandlordServicesSchema = yup.object({
  asPerExistingToggle: yup.bool().default(false),
  airConditioning: yup.bool().default(false),
  coolRoom: yup.bool().default(false),
  floorCovering: yup.bool().default(false),
  greaseTrap: yup.bool().default(false),
  hotWaterServices: yup.bool().default(false),
  lighting: yup.bool().default(false),
  mechanicalExhaust: yup.bool().default(false),
  paintedWalls: yup.bool().default(false),
  electricalDistLoad_3Phase: yup.bool().default(false),
  electricalDistLoad_1Phase: yup.bool().default(false),
  separateUtilityMeter_Gas: yup.bool().default(false),
  separateUtilityMeter_Water: yup.bool().default(false),
  separateUtilityMeter_Electricity: yup.bool().default(false),
  shopFront: yup.bool().default(false),
  sink: yup.bool().default(false),
  sprinklers: yup.bool().default(false),
  suspendedCeilings: yup.bool().default(false),
  telephone: yup.bool().default(false),
  waterSupply: yup.bool().default(false),
  waste: yup.bool().default(false),
  plasteredWalls_Unpainted: yup.bool().default(false),
  other: yup.bool().default(false),
  otherDescription: yup.string().when("other", {
    is: "yes",
    then: (schema) => schema.required("Other description is required"),
    otherwise: (schema) => schema,
  }),
});

export type LandlordServicesType = yup.InferType<typeof LandlordServicesSchema>;
