import React, { useState } from "react";
import { Typography, Grid, Box, Container, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LocalPhoneSharpIcon from "@mui/icons-material/LocalPhoneSharp";
import AlternateEmailSharpIcon from "@mui/icons-material/AlternateEmailSharp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";

import { BreadCrumbs, Button } from "../components";
import { GetContactDocument } from "../generated/graphql-operations";
import {
  CreateContactDialog,
  UpdateContactDialog,
} from "../components/Contacts/CreateUpdateContact/CreateUpdateContactDialog";
import { Error } from "./Error";
import { ContactDealsList } from "../components/Contacts/ContactDealsList";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";

export const Contact = () => {
  const params = useParams();
  const contactId = parseInt(params.contactId ?? "", 10) || -1;
  const { data, loading, error } = useQuery(GetContactDocument, {
    variables: { contactId },
  });

  const disable = ![Role.Admin, Role.TdAssignee].includes(
    useReturnUserRole() as Role
  );

  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  if (loading) {
    return <Skeleton />;
  }

  if (error || data?.Contacts_by_pk === null) {
    <Error
      title="Oops, something went wrong"
      message="Try refreshing your browser and try again"
    />;
  }

  const contactInitials = data?.Contacts_by_pk?.first_name?.[0] ?? "A";

  const getAddressString = () => {
    const street = data?.Contacts_by_pk?.Location?.street1 ?? "";

    if (!street) {
      return "N/A";
    }

    const suburb = data?.Contacts_by_pk?.Location?.suburb ?? "";
    const postcode = data?.Contacts_by_pk?.Location?.postcode ?? "";

    const address = `${street || ""}, ${suburb || ""} ${postcode || ""}`;

    return address;
  };

  const contactDetails: {
    name: string;
    icon: typeof EditIcon;
    value: string;
  }[] = [
    {
      name: "phone",
      icon: LocalPhoneSharpIcon,
      value: data?.Contacts_by_pk?.phone ?? "N/A",
    },
    {
      name: "email",
      icon: AlternateEmailSharpIcon,
      value: data?.Contacts_by_pk?.email ?? "N/A",
    },
    {
      name: "location",
      icon: LocationOnIcon,
      value: getAddressString(),
    },
    {
      name: "company",
      icon: WorkIcon,
      value:
        data?.Contacts_by_pk?.Company?.legal_name === ""
          ? "N/A"
          : data?.Contacts_by_pk?.Company?.legal_name,
    },
    {
      name: "position",
      icon: PersonIcon,
      value: JSON.parse(data?.Contacts_by_pk?.content ?? "{}").position
        ? JSON.parse(data?.Contacts_by_pk?.content ?? "{}").position
        : "N/A",
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Contact</Typography>
        </Stack>
      </Grid>
      <Grid item sm={12} mb={2}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Contacts", url: "/Contacts/" },
            { text: `${data?.Contacts_by_pk?.id ?? "Contact"}`, url: "" },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box display="flex" flexGrow={1} gap={2} mb={2}>
            <Avatar> {contactInitials} </Avatar>
            <Typography variant="h6">
              {data?.Contacts_by_pk?.first_name}{" "}
              {data?.Contacts_by_pk?.last_name}
              <Button
                disabled={disable}
                onClick={() => setUpdateOpen(true)}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Typography>
          </Box>

          <Box width="100%" />

          <Grid>
            {contactDetails.map((detail) => (
              <Box display="flex" gap={1} mb={2} key={detail.name}>
                <detail.icon />
                <Typography variant="body1">{detail.value}</Typography>
              </Box>
            ))}

            <Button
              variant="contained"
              LinkComponent="a"
              href={`mailto:${data?.Contacts_by_pk?.email ?? ""}`}
              sx={{ mt: 3 }}
            >
              Send email
            </Button>

            <Box width="100%" />
          </Grid>
        </Grid>
        <Grid item xs={9} sx={{ height: 800 }}>
          <Typography mb={2} variant="h4">
            Deals
          </Typography>
          <ContactDealsList contactId={contactId} toolBar />
        </Grid>
      </Grid>

      <CreateContactDialog open={createOpen} setOpen={setCreateOpen} />

      <UpdateContactDialog
        open={updateOpen}
        setOpen={setUpdateOpen}
        contactId={contactId}
      />
    </Container>
  );
};
