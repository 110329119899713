import { useMutation, useQuery } from "@apollo/client";
import { LandlordServicesType } from "../../../components/DealInformation/LandlordServices/LandlordServicesConfig";
import {
  BaseLandlordServices_Set_Input,
  GetLandlordInformationDocument,
  GetLandlordInformationQuery,
  UpdateBaseLandlordServicesDocument,
} from "../../../generated/graphql-operations";

export const useGetLandlordServicesData = (dealId: number) => {
  const landlordInformation = useQuery(GetLandlordInformationDocument, {
    variables: {
      deal_id: dealId,
    },
  });
  return landlordInformation;
};

export const transformData = (
  data: GetLandlordInformationQuery
): LandlordServicesType => {
  return {
    asPerExistingToggle:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.as_per_existing_toggle ??
      false,
    airConditioning:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.air_conditioning ?? false,
    coolRoom:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.cool_room ?? false,
    electricalDistLoad_1Phase:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.electrical_dist_load_1_phase ?? false,
    electricalDistLoad_3Phase:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.electrical_dist_load_3_phase ?? false,
    floorCovering:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.floor_covering ?? false,
    greaseTrap:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.grease_trap ?? false,
    hotWaterServices:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.hot_water_services ??
      false,
    lighting: data?.DealData?.[0]?.BaseLandlordServices?.[0]?.lighting ?? false,
    mechanicalExhaust:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.mechanical_exhaust ??
      false,
    paintedWalls:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.painted_walls ?? false,
    separateUtilityMeter_Gas:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.separate_utility_meter_gas ?? false,
    separateUtilityMeter_Water:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.separate_utility_meter_water ?? false,
    separateUtilityMeter_Electricity:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.separate_utility_meter_electricity ?? false,
    shopFront:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.shop_front ?? false,
    sink: data?.DealData?.[0]?.BaseLandlordServices?.[0]?.sink ?? false,
    sprinklers:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.sprinklers ?? false,
    suspendedCeilings:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.suspended_ceilings ??
      false,
    telephone:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.telephone ?? false,
    waste: data?.DealData?.[0]?.BaseLandlordServices?.[0]?.waste ?? false,
    waterSupply:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.water_supply ?? false,
    plasteredWalls_Unpainted:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]
        ?.plastered_walls_unpainted ?? false,
    other: data?.DealData?.[0]?.BaseLandlordServices?.[0]?.other ?? false,
    otherDescription:
      data?.DealData?.[0]?.BaseLandlordServices?.[0]?.other_description ?? "",
  };
};

export const useMutateLandlordServices = (dealId: number) => {
  const [UpdateBaseLandlordServices] = useMutation(
    UpdateBaseLandlordServicesDocument
  );

  return async (data: LandlordServicesType) => {
    const transformedData: BaseLandlordServices_Set_Input = {
      as_per_existing_toggle: data.asPerExistingToggle,
      air_conditioning: data.airConditioning,
      cool_room: data.coolRoom,
      electrical_dist_load_1_phase: data.electricalDistLoad_1Phase,
      electrical_dist_load_3_phase: data.electricalDistLoad_3Phase,
      floor_covering: data.floorCovering,
      grease_trap: data.greaseTrap,
      hot_water_services: data.hotWaterServices,
      lighting: data.lighting,
      mechanical_exhaust: data.mechanicalExhaust,
      painted_walls: data.paintedWalls,
      separate_utility_meter_gas: data.separateUtilityMeter_Gas,
      separate_utility_meter_water: data.separateUtilityMeter_Water,
      separate_utility_meter_electricity: data.separateUtilityMeter_Electricity,
      shop_front: data.shopFront,
      sink: data.sink,
      sprinklers: data.sprinklers,
      suspended_ceilings: data.suspendedCeilings,
      telephone: data.telephone,
      waste: data.waste,
      water_supply: data.waterSupply,
      plastered_walls_unpainted: data.plasteredWalls_Unpainted,
      other: data.other,
      other_description: data.otherDescription,
    };

    await UpdateBaseLandlordServices({
      variables: {
        deal_id: dealId,
        data: transformedData,
      },
    });
  };
};
