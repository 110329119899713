import React from "react";
import { Control, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Switch,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { defaultToggleButtonSx } from "../ToggleButton";
import { TAG_SPACE_CHAR } from "../../utils/CommonVariables";
import { EditorFormWithEditor } from "./RichTextEditor";

interface FormSwitchProps {
  control: Control<any>;
  name: string;
  title: string;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | Promise<void>;
}

export const FormSwitch = ({
  title,
  disabled,
  onChange,
  ...other
}: FormSwitchProps) => (
  <Controller
    defaultValue={false}
    {...other}
    render={({ field }) => (
      <FormControlLabel
        sx={{ p: 1 }}
        control={
          <Switch
            {...field}
            checked={field.value}
            disabled={disabled}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
          />
        }
        label={title}
      />
    )}
  />
);

export const FormTextField = ({
  value,
  label,
  disabled,
  textFieldProps,
}: {
  value: number | string;
  label: string;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
}) => (
  <>
    <InputLabel>{label || TAG_SPACE_CHAR}</InputLabel>
    <TextField
      variant="filled"
      disabled={disabled}
      value={value}
      margin="dense"
      data-testid={`${label}-formTextField`}
      fullWidth
      size="small"
      hiddenLabel
      color="primary"
      {...textFieldProps}
    />
  </>
);

interface DateComp {
  fieldName: string;
  control: Control<any>;
  title: string;
  helperText?: string;
  disabled?: boolean;
  onChange?: (
    e: Date | null,
    data?: any
  ) => void | {} | Promise<void> | Promise<{}>;
}

export const FormDate = ({
  fieldName,
  control,
  title,
  onChange,
  helperText = "",
  disabled = false,
}: DateComp) => (
  <>
    <InputLabel>{title}</InputLabel>
    <Controller
      control={control}
      name={fieldName}
      render={({
        field: { onChange: fieldOnChange, value, name, onBlur, ref },
        fieldState: { error },
      }) => (
        <DatePicker
          ref={ref}
          onChange={(e) => {
            fieldOnChange(e);
            if (onChange !== undefined) {
              onChange(e);
            }
          }}
          value={value ?? ""}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={(e) => {
                onBlur();
                if (params.onBlur !== undefined) {
                  params.onBlur(e);
                }
              }}
              variant="filled"
              error={error !== undefined}
              helperText={error?.message ?? helperText}
              id={name}
              margin="dense"
              fullWidth
              size="small"
              hiddenLabel
              color="primary"
              autoComplete="bday"
            />
          )}
        />
      )}
    />
  </>
);

interface ToggleButtonProps {
  name: string;
  control: Control<any>;
  options: {
    text: string;
    id: string;
  }[];
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data?: any
  ) => void | {} | Promise<void> | Promise<{}>;
}

// TODO: replace other toggle button with this component
export const FormToggleButton = ({
  control,
  name,
  options,
  onChange,
  defaultValue,
  disabled,
}: ToggleButtonProps) => (
  <Controller
    control={control}
    defaultValue={defaultValue ?? ""}
    name={name}
    render={({ field }) => (
      <ToggleButtonGroup
        {...field}
        onChange={(e) => {
          field.onChange(e);
          onChange?.(e);
        }}
        color="primary"
        exclusive
        fullWidth
        sx={defaultToggleButtonSx}
      >
        {options.map((option) => (
          <ToggleButton
            id={option.id}
            key={option.id}
            value={option.id}
            fullWidth
            color="secondary"
            size="small"
            sx={{ fontWeight: 700, fontSize: 14 }}
            disabled={disabled}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )}
  />
);

export const EditorControlled = (props: {
  control: Control<any>;
  name: string;
  label: string;
  disable?: boolean;
  defaultValue?: string;
  height?: number;
  onChange?: (data?: string) => void | {} | Promise<void> | Promise<{}>;
}) => (
  <Controller
    name={props.name}
    control={props.control}
    defaultValue={props.defaultValue ?? ""}
    render={({ field }) => (
      <EditorFormWithEditor
        content={field.value ?? ""}
        label={props.label}
        onChange={(data) => {
          field.onChange(data);
          props.onChange?.(data);
        }}
        disable={props.disable}
        height={props.height}
      />
    )}
  />
);

interface TextMultiFieldProps {
  control: Control<any>;
  formField: {
    label: string;
    fieldName: string;
    helperText: string;
    defaultValue?: string;
    disabled?: boolean;
    onChange?: (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | React.SyntheticEvent<Element, Event>,
      data?: any
    ) => void | {} | Promise<void> | Promise<{}>;
  };
}

export const TextMultiField = (props: TextMultiFieldProps) => {
  return (
    <Controller
      // Provide default value so don't get error of
      // this switching between un/controlled component
      // This will be replaced by defaultValues in hook useForm
      defaultValue={props.formField.defaultValue ?? ""}
      control={props.control}
      name={props.formField.fieldName}
      render={({ field, fieldState }) => (
        <FormTextField
          value={field.value}
          label={props.formField.label}
          disabled={props.formField.disabled}
          textFieldProps={{
            ...field,
            multiline: true,
            id: field.name,
            error: !!fieldState.error?.message,
            helperText: fieldState.error?.message
              ? fieldState.error?.message
              : props.formField.helperText,
            onChange: (e) => {
              field.onChange(e);
              props.formField.onChange?.(e);
            },
            // Ignore this type error because you can assign this as a prop on
            // mui Text Field, useful for tests
            // @ts-ignore
            "data-testid": `${field.name}-text`,
          }}
        />
      )}
    />
  );
};

interface CheckboxFormProps {
  control: Control<any>;
  fieldName: string;
  choices: string[];
  disable?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | Promise<void>;
}

export const CheckboxForm = (props: CheckboxFormProps) => {
  type Choice = typeof props.choices[number];
  return (
    <Controller
      defaultValue={[]}
      render={({ field }) => {
        // If there is some old/missing data add it in
        const missingChoices = field.value.filter(
          (item: Choice) => !props.choices.includes(item)
        );
        return (
          <FormGroup>
            {[...props.choices, ...missingChoices].map((choice) => (
              <FormControlLabel
                disabled={props.disable}
                key={choice}
                label={choice}
                control={
                  <Checkbox
                    checked={field.value.includes(choice)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        field.onChange([choice]);
                      } else {
                        field.onChange(
                          field.value.filter((item: Choice) => item !== choice)
                        );
                      }

                      props.onChange?.(e);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        );
      }}
      name={props.fieldName}
      control={props.control}
    />
  );
};
