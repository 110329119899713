import React, { useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../components/Button";

import {
  buttonStepReducer,
  useTransformAndSubmitData,
} from "../components/CreateDeal/utils";
import { StepOne } from "../components/CreateDeal/StepOne/StepOne";
import { StepTwo } from "../components/CreateDeal/StepTwo/StepTwo";
import { GetLeasesForRenewalQuery } from "../generated/graphql-operations";
import { RenewalDialogType } from "../components/Renewals/Config";

export const CreateDeal: React.FC = () => {
  const renewalData: GetLeasesForRenewalQuery["YardiLeasesForRenewal"][0] &
    RenewalDialogType = useLocation().state as any;
  const initialState = { step: renewalData ? 2 : 1 };
  const [state, dispatch] = useReducer(buttonStepReducer, initialState);
  const [data, setData, onSubmit] = useTransformAndSubmitData();

  switch (state.step) {
    case 1:
      return <StepOne state={data} setState={setData} dispatch={dispatch} />;
    case 2:
      return (
        <StepTwo
          state={data}
          renewalData={renewalData}
          setState={setData}
          dispatch={dispatch}
          submitHandler={onSubmit}
        />
      );

    default:
      return (
        <Button
          sx={{ mt: 5 }}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => dispatch({ type: "back" })}
        >
          Back
        </Button>
      );
  }
};
