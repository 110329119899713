import React, { useState } from "react";
import { Collapse, Grid, Typography, Divider, IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface CollapseCardProps {
  heading: string;
  children: React.ReactNode;
  forceOpen?: boolean;
}

export const CollapseCard = (props: CollapseCardProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Grid container>
      <Grid item sm={12}>
        <Grid
          container
          onClick={() => setExpanded(!expanded)}
          sx={{ cursor: "pointer" }}
        >
          <Grid item pt={1} pl={2} pb={0.5}>
            <Typography variant="subtitle1">{props.heading}</Typography>
          </Grid>
          <Grid item pt={1} pr={1} sx={{ marginLeft: "auto" }}>
            <IconButton>
              <ExpandMore
                sx={{
                  transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 150ms ease",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={props.forceOpen ? true : expanded} timeout="auto">
          <Grid container>{props.children}</Grid>
        </Collapse>
      </Grid>
      <Divider sx={{ width: "100%" }} />
    </Grid>
  );
};
