import * as yup from "yup";
import {
  GeneralAmountTypes,
  PromotionLevyTypes,
} from "../../../utils/CommonVariables";
import {
  GREATER_THAN_0,
  INVALID_NUMBER,
  REQUIRED_FIELD,
} from "../../../utils/CommonErrors";

export const CommercialSchema = yup.object({
  turnoverRentToggle: yup.string().oneOf(["yes", "no", "na"]),
  turnOverRent: yup.number().when("turnoverRentToggle", {
    is: "yes",
    then: (schema) => schema.min(0, GREATER_THAN_0).required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  turnOverRentUnnatural: yup
    .number()
    .nullable()
    .when("turnoverRentToggle", {
      is: "yes",
      then: (schema) =>
        schema
          .transform((value, org) => {
            return org === "" ? undefined : Number(value);
          })
          .min(0, GREATER_THAN_0),
      otherwise: (schema) => schema,
    }),
  promotionLevyToggle: yup.string().oneOf(["yes", "no", "na"]),
  promotionLevy: yup.number().when("promotionLevyToggle", {
    is: "yes",
    then: (schema) => schema.min(0, GREATER_THAN_0).required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  promotionLevyType: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .required(),
  outgoingsToggle: yup.string().oneOf(["yes", "no", "na"]),
  outgoings: yup.number().when("outgoingsToggle", {
    is: "yes",
    then: (schema) => schema.min(0, GREATER_THAN_0).required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  annualIncreaseType: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .required(),
  annualIncrease: yup.number().when("annualIncreaseType", {
    is: (val: { value: string; label: string }) =>
      val?.value === GeneralAmountTypes.CPI ||
      val?.value === GeneralAmountTypes["Market review"],
    then: (schema) => schema,
    otherwise: (schema) =>
      schema
        .min(0, GREATER_THAN_0)
        .typeError(INVALID_NUMBER)
        .required(REQUIRED_FIELD),
  }),
  fitoutContribution: yup.number().min(0, GREATER_THAN_0).nullable(),
  cashIncentive: yup.number().min(0, GREATER_THAN_0).nullable(),
  grossRent: yup
    .number()
    .min(0, GREATER_THAN_0)
    .typeError(INVALID_NUMBER)
    .required(REQUIRED_FIELD),
  baseRent: yup
    .number()
    .min(0, GREATER_THAN_0)
    .typeError(INVALID_NUMBER)
    .required(REQUIRED_FIELD),
  cappedOccupancyCostsStateToggle: yup.string().oneOf(["yes", "no"]),
});

export type CommercialType = yup.InferType<typeof CommercialSchema>;
// Set the defaults here because putting it in the schema
// Sets it in react hook when i make a value undefined, which I don't want i want to keep undefined
const defaultValues = {
  turnOverRent: 10,
  promotionLevy: 10,
  promotionLevyType: {
    label: PromotionLevyTypes["%"],
    value: PromotionLevyTypes["%"],
  },
  annualIncrease: 4,
  annualIncreaseType: {
    label: GeneralAmountTypes["%"],
    value: GeneralAmountTypes["%"],
  },
};

// Not sure if there is a better way to do this but want
// to have types of only the default values I give and make them required
export const DEFAULT_VALUES: Required<
  Pick<CommercialType, keyof typeof defaultValues>
> = defaultValues;
