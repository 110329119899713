import {
  add,
  differenceInCalendarDays,
  Duration,
  format,
  intervalToDuration,
  isValid,
} from "date-fns";

export function checkAndFormatDate(dateValue: Date, formatString: string) {
  if (isValid(dateValue)) {
    return format(dateValue, formatString);
  }

  return undefined;
}

function convertTimeToString(value: number, fmt: Intl.RelativeTimeFormatUnit) {
  const rtf = new Intl.RelativeTimeFormat("en", { style: "long" });
  const formattedParts = rtf.formatToParts(value, fmt);
  const strValue = formattedParts[formattedParts.length - 1].value;

  return `${value} ${strValue}`;
}

export function convertTermsToDays(dateValue: Date, terms: Duration) {
  return differenceInCalendarDays(add(dateValue, terms), dateValue);
}

export function convertDaysToTerms(dateValue: Date, days: number) {
  if (!isValid(dateValue)) {
    return null;
  }
  return intervalToDuration({
    end: add(dateValue, { days }),
    start: dateValue,
  });
}

export function convertDaysToWeekTerms(days: number) {
  return { weeks: Math.floor(days / 7), days: days % 7 };
}

export function convertMonthsToYearsTerms(months: number) {
  return { years: Math.floor(months / 12), months: months % 12 };
}

// Given a number of days, returns a string formatted X.Y years or X.Y months
// if duration is shorter than a year
export function convertDaysToYearsMonthsDays(
  startDate: Date,
  durationInDays: number
): string {
  const duration = convertDaysToTerms(startDate, durationInDays);

  const years = duration?.years;
  const months = duration?.months;
  const days = duration?.days;

  if (years && years >= 1) {
    const fractionalMonths = months ? months / 12 : 0;

    const result = Number((years + fractionalMonths).toFixed(1));
    return convertTimeToString(result, "year");
  }

  if (months && months >= 1) {
    const fractionalDays = days ? days / 31 : 0;

    const result = Number((months + fractionalDays).toFixed(1));
    return convertTimeToString(result, "month");
  }

  if (days && days >= 1) {
    return convertTimeToString(days, "day");
  }

  return "";
}
