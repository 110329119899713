import React from "react";
import { Typography, Grid, Container } from "@mui/material";
import { BreadCrumbs } from "../components";
import { PropertyList } from "../components/Properties/PropertyList";

export const Properties = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">Properties</Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 1 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Properties", url: "/properties" },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: 800,
            width: "100%",
          }}
        >
          <PropertyList toolBar />
        </Grid>
      </Grid>
    </Container>
  );
};
