import React from "react";
import {
  Grid,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  SvgIcon,
} from "@mui/material";

import { ReactComponent as DealStatusActiveIcon } from "./deal_status_active.svg";
import { ReactComponent as DealStatusCompleteIcon } from "./deal_status_complete.svg";
import { ReactComponent as DealStatusInactiveIcon } from "./deal_status_inactive.svg";

import { DealPhase } from "../PushDealDialogServices";
import { DealPhaseStatus } from "../../../utils/CommonVariables";

interface DealStatusBarProps {
  currentPhase?: number;
  setCurrentPhase: React.Dispatch<React.SetStateAction<number | undefined>>;
  phases: DealPhase[];
}

const StatusIcon = (phase: DealPhase) => {
  let IconComponent = DealStatusInactiveIcon;

  if (phase.statusID === DealPhaseStatus.Active) {
    IconComponent = DealStatusActiveIcon;
  } else if (phase.statusID === DealPhaseStatus.Complete) {
    IconComponent = DealStatusCompleteIcon;
  }

  return (
    <SvgIcon
      component={IconComponent}
      style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
    />
  );
};

const CurrentPhaseHighlight = () => {
  return <Box className="phase-highlight" />;
};

export const DealStatusBar = (props: DealStatusBarProps) => {
  const handlePhaseChange = (id: number | null | undefined) => {
    if (typeof id === "number") {
      props.setCurrentPhase(id);
    }
  };

  return (
    <Box className="deal-status-bar">
      <Grid container>
        <Grid item sm={12}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>

        <Grid item sm={12} mt={2}>
          <Stepper alternativeLabel activeStep={-1}>
            {props.phases.map((phase) => (
              <Step
                aria-label={`step phase ${phase.name}`}
                key={phase.name}
                onClick={() => handlePhaseChange(phase.id)}
              >
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": { color: "white" },
                  }}
                  StepIconComponent={() => StatusIcon(phase)}
                >
                  <span style={{ cursor: "pointer" }}>{phase.name}</span>
                  {phase.id === props.currentPhase ? (
                    <CurrentPhaseHighlight />
                  ) : null}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
};
