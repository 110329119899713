import { useMutation, useQuery } from "@apollo/client";
import { DealTypeType } from "../../../components/DealInformation/DealInformationConfig";
import {
  GetDealTypesDocument,
  GetDealAnalystDataDocument,
  GetDealAnalystDataQuery,
  UpdateDealTypeDocument,
  UpdateDealAnalystDataDocument,
} from "../../../generated/graphql-operations";

export const useGetDealTypes = () => {
  const dealTypes = useQuery(GetDealTypesDocument);
  return dealTypes;
};

export const useGetDealAnalystData = (dealId: number) => {
  const analystData = useQuery(GetDealAnalystDataDocument, {
    variables: {
      deal_id: dealId,
    },
  });
  return analystData;
};

export const transformData = (
  analystData: GetDealAnalystDataQuery
): DealTypeType => {
  const currentDealType = {
    label: analystData?.Deals_by_pk?.DealType?.title ?? "",
    value: analystData.Deals_by_pk?.DealType?.id ?? -1,
  };

  const analystDealComp =
    analystData.Deals_by_pk?.DealData[0]?.analyst_deal_comp;

  const analystNormalisedBaseRent =
    analystData.Deals_by_pk?.DealData[0]?.analyst_normalised_base_rent;

  const analystNormalisedPromo =
    analystData.Deals_by_pk?.DealData[0]?.analyst_normalised_promo;

  const analystDealReason =
    analystData.Deals_by_pk?.DealData[0]?.analyst_deal_reason;

  const result = {
    dealType: currentDealType,
    dealComp: analystDealComp?.toString() ?? "",
    normalisedBaseRent: analystNormalisedBaseRent?.toString() ?? "",
    normalisedPromo: analystNormalisedPromo?.toString() ?? "",
    dealReason: analystDealReason?.toString() ?? "",
  };

  return result;
};

export const useMutateDealType = (dealId: number) => {
  const [UpdateDealType] = useMutation(UpdateDealTypeDocument);
  const [UpdateDealAnalystData] = useMutation(UpdateDealAnalystDataDocument);
  return async (data: DealTypeType) => {
    await UpdateDealType({
      variables: {
        deal_id: dealId,
        deal_type_id: data.dealType.value ?? -1,
      },
    });

    await UpdateDealAnalystData({
      variables: {
        deal_id: dealId,
        analyst_deal_comp: parseFloat(data.dealComp) ?? null,
        analyst_normalised_base_rent:
          parseFloat(data.normalisedBaseRent) ?? null,
        analyst_normalised_promo: parseFloat(data.normalisedPromo) ?? null,
        analyst_deal_reason: data.dealReason ?? null,
      },
    });
  };
};
