import { useMutation, useQuery } from "@apollo/client";
import { CompanyData } from "../../components/Companies/CreateUpdateCompany/Config";
import {
  Companies_Insert_Input,
  CreateCompanyDocument,
  GetCompaniesDocument,
  UpdateCompanyDocument,
  GetCompanyDocument,
  Locations_Insert_Input,
} from "../../generated/graphql-operations";
import {
  useCreateLocation,
  useUpdateLocation,
} from "../Contact/Locations/Locations";

export const useGetCompany = (id: number) => {
  const company = useQuery(GetCompanyDocument, {
    variables: {
      id,
    },
  });
  return company;
};

export const useGetCompanies = () => {
  const companies = useQuery(GetCompaniesDocument);
  return companies;
};

export const useCreateCompany = () => {
  const [createCompanyMutation] = useMutation(CreateCompanyDocument);

  const createCompany = async (company: Companies_Insert_Input) => {
    const newCompany = await createCompanyMutation({
      variables: {
        company,
      },
      refetchQueries: [GetCompaniesDocument],
    });

    return newCompany;
  };

  return createCompany;
};

export const useUpdateCompany = () => {
  const [updateCompanyMutation] = useMutation(UpdateCompanyDocument);

  const updateCompany = async (id: number, data: Companies_Insert_Input) => {
    const updatedCompany = await updateCompanyMutation({
      variables: {
        id,
        data,
      },
      refetchQueries: [GetCompaniesDocument],
    });

    return updatedCompany;
  };

  return updateCompany;
};

export const useCreateNewCompany = () => {
  const createCompany = useCreateCompany();
  const createLocation = useCreateLocation();

  const createNewCompany = async (data: CompanyData) => {
    const location = await createLocation({
      street1: data?.address,
      street2: "",
      state_id: data?.state.value ?? 1,
      suburb: data?.city,
      postcode: parseInt(data?.postCode, 10),
    });

    const companyData: Companies_Insert_Input = {
      legal_name: data?.legalName,
      acn: data?.acn !== "" ? data?.acn : null,
      email: data?.email,
      phone: data?.phone,
      address_id: location?.data?.insert_Locations_one?.id,
    };

    const newCompany = await createCompany(companyData);

    return newCompany;
  };

  return createNewCompany;
};

export const useUpdateCompanyData = (id: number) => {
  const updateCompany = useUpdateCompany();
  const updateLocation = useUpdateLocation();
  const updateCompanyData = async (data: CompanyData) => {
    const companyData: Companies_Insert_Input = {
      legal_name: data?.legalName,
      acn: data?.acn ?? null,
      email: data?.email,
      phone: data?.phone,
    };

    const locationData: Locations_Insert_Input = {
      street1: data?.address,
      street2: "",
      suburb: data?.city,
      postcode: parseInt(data?.postCode, 10),
      state_id: data?.state.value,
    };

    await updateCompany(id, companyData);
    await updateLocation(data?.addressId, locationData);
  };

  return updateCompanyData;
};

export const useGetCompanyData = (id: number) => {
  const company = useGetCompany(id);
  const companyData: CompanyData = {
    legalName: company?.data?.Companies_by_pk?.legal_name ?? "",
    acn: company?.data?.Companies_by_pk?.acn ?? "",
    addressId: company?.data?.Companies_by_pk?.address_id ?? -1,
    email: company?.data?.Companies_by_pk?.email ?? "",
    phone: company?.data?.Companies_by_pk?.phone ?? "",
    address: company?.data?.Companies_by_pk?.Location?.street1 ?? "",
    city: company?.data?.Companies_by_pk?.Location?.suburb ?? "",
    state: {
      label: company?.data?.Companies_by_pk?.Location?.State?.name ?? "",
      value: company?.data?.Companies_by_pk?.Location?.State?.id ?? 1,
    },
    postCode: `${company?.data?.Companies_by_pk?.Location?.postcode ?? ""}`,
  };

  return companyData;
};
