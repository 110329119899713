import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Control, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  VIRTUAL_UNIT_LABEL_SUFFIX,
  NotificationEmailAttachmentTypes,
} from "../../../utils/CommonVariables";
import { TextNumberField } from "../../Form/Form";

import { FormTextField, FormDate } from "../../Form/FormComponents";
import { ValidationProps } from "../SummaryTab/Files/Config";
import {
  DealShopActivities_Insert_Input,
  DealShopActivityTypes_Enum,
} from "../../../generated/graphql-operations";
import { useCreateDealShopWithFileNotification } from "../../../adapters/Deal/DealShopNofitication";
import { checkAndFormatDate } from "../../../utils/DateUtils";

interface ShopOpenProps {
  dealDisabled: boolean;
  dealId: number;
  propertyName: string;
  tenantName: string;
  tenantCode?: string | null;
  shopNumber: string;
  submittedUser: string;
  userId: string;
  estimatedOpenDate: string;
}

const ShopOpenSchema = yup.object({
  propertyName: yup.string(),
  tenantName: yup.string(),
  shopNumber: yup.string(),
  submittedUser: yup.string(),
  fileName: yup.string().required("Photo is required"),
  openDate: yup.date().required("Open date is required"),
});

type ShopOpenDialogType = yup.InferType<typeof ShopOpenSchema>;

export const ShopOpenNotificationDialog: React.FC<ShopOpenProps> = (props) => {
  const theme = useTheme();

  const form = useForm<ShopOpenDialogType>({
    resolver: yupResolver(ShopOpenSchema),
    defaultValues: {
      openDate: new Date(),
    },
  });

  const [userFile, setUserFile] = React.useState<File | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onShopOpenSubmit = useCreateDealShopWithFileNotification(
    props.dealId,
    null,
    userFile
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const ext = acceptedFiles[0].name.split(".").pop()?.toLowerCase();
        if (!NotificationEmailAttachmentTypes.includes(ext!)) {
          setValidation({
            show: true,
            severity: "error",
            message: "Only image files are accepted",
          });
          return;
        }
        setUserFile(acceptedFiles[0]);
        form.setValue("fileName", acceptedFiles[0]?.name!);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const resetAndClose = () => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });
    setUserFile(undefined);
    form.reset();
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={props.dealDisabled}
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ mt: 2, mr: 1 }}
      >
        Shop Open Notification
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Shop Open Notification</DialogTitle>
        <DialogContent>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            maxWidth="md"
            sx={{ m: "0 auto" }}
          >
            <Paper sx={{ m: 2, p: 2 }} elevation={10}>
              <Grid container>
                <Grid container>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.propertyName}
                      label="Property Name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.tenantName}
                      label="Tenant Name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.shopNumber}
                      label="Shop Number"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.submittedUser}
                      label="User Name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={
                        props.estimatedOpenDate
                          ? checkAndFormatDate(
                              new Date(props.estimatedOpenDate),
                              "dd/MM/yyyy"
                            ) ?? ""
                          : checkAndFormatDate(new Date(), "dd/MM/yyyy") ?? ""
                      }
                      label="Estimated open date"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormDate
                      fieldName="openDate"
                      title="Actual open Date"
                      control={form.control as unknown as Control}
                      onChange={(e) => {
                        form.setValue("openDate", e ?? new Date());
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <InputLabel>Photo Attachment</InputLabel>
                  </Grid>
                  <Grid item sm={12}>
                    <TextNumberField
                      form={form}
                      formField={{
                        fieldName: "fileName",
                        helperText: "",
                        label: "",
                        type: "text",
                        disabled: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Card
                      sx={{
                        my: 2,
                        backgroundColor: isDragActive
                          ? theme.palette.secondary.dark
                          : theme.palette.secondary.main,
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {isDragActive
                        ? "Drop file here"
                        : "Drop file here, or click to select file"}
                    </Card>

                    {validation.show && (
                      <Alert sx={{ mt: 2 }} severity={validation.severity}>
                        {validation.message}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                      disabled={form.formState.isSubmitting}
                      onClick={form.handleSubmit(async (data) => {
                        const insertObj: DealShopActivities_Insert_Input = {
                          property_name: props.propertyName,
                          tenant_name: props.tenantName,
                          shop_number: props.shopNumber.replace(
                            VIRTUAL_UNIT_LABEL_SUFFIX,
                            ""
                          ),
                          submitted_user_name: props.submittedUser,
                          open_date: data.openDate.toDateString(),
                          activity: DealShopActivityTypes_Enum.ShopOpen,
                          deal_id: props.dealId,
                          generated_by: props.userId,
                          file_name: data.fileName,
                          estimated_open_date:
                            props.estimatedOpenDate === ""
                              ? new Date().toDateString()
                              : props.estimatedOpenDate,
                        };
                        try {
                          await onShopOpenSubmit(insertObj);
                          resetAndClose();
                        } catch (error: any) {
                          setValidation({
                            message: `Received error: ${error?.message ?? ""}`,
                            severity: "error",
                            show: true,
                          });
                        }
                      })}
                    >
                      Send Notification
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        resetAndClose();
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
