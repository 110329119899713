import React, { useEffect } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import {
  DealInformationSectionProps,
  DealInformationType,
} from "../DealInformationConfig";
import { FormSwitch, TextMultiField } from "../../Form/FormComponents";
import { Alert } from "../../Alert";
import {
  transformData,
  useGetLandlordServicesData,
} from "../../../adapters/DealInformation/LandlordServices/LandlordServices";

const switches: {
  name: keyof DealInformationType["landlordServices"];
  title: string;
}[] = [
  {
    name: "airConditioning",
    title: "Air conditioning",
  },
  {
    name: "coolRoom",
    title: "Cool room",
  },
  {
    name: "floorCovering",
    title: "Floor covering",
  },
  {
    name: "greaseTrap",
    title: "Grease trap",
  },
  {
    name: "hotWaterServices",
    title: "Hot water services",
  },
  {
    name: "lighting",
    title: "Lighting",
  },
  {
    name: "mechanicalExhaust",
    title: "Mechanical exhaust",
  },
  {
    name: "paintedWalls",
    title: "Painted walls",
  },
  {
    name: "electricalDistLoad_3Phase",
    title: "Electrical dist load (3 phase)",
  },
  {
    name: "electricalDistLoad_1Phase",
    title: "Electrical dist load (1 phase)",
  },
  {
    name: "separateUtilityMeter_Gas",
    title: "Separate utility meter - gas",
  },
  {
    name: "separateUtilityMeter_Water",
    title: "Separate utility meter - water",
  },
  {
    name: "separateUtilityMeter_Electricity",
    title: "Separate utility meter - electricity",
  },
  {
    name: "shopFront",
    title: "Shop front",
  },
  {
    name: "sink",
    title: "Sink",
  },
  {
    name: "sprinklers",
    title: "Sprinklers",
  },
  {
    name: "suspendedCeilings",
    title: "Suspended ceilings",
  },
  {
    name: "telephone",
    title: "Telephone",
  },
  {
    name: "waterSupply",
    title: "Water supply",
  },
  {
    name: "waste",
    title: "Waste",
  },
  {
    name: "plasteredWalls_Unpainted",
    title: "Plastered Walls (unpainted)",
  },
];

export const LandlordServices = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;

  const asPerExisting = form.watch("landlordServices.asPerExistingToggle");

  const landlordInformation = useGetLandlordServicesData(props.dealId);

  useEffect(() => {
    if (landlordInformation.data) {
      setDataState((prev) => ({ ...prev, landlordServicesLoaded: true }));
      const transformedData = transformData(landlordInformation.data);
      form.setValue("landlordServices", transformedData);
    } else {
      setDataState((prev) => ({ ...prev, landlordServicesLoaded: false }));
    }
  }, [form, landlordInformation, setDataState]);

  if (landlordInformation.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!landlordInformation.data) {
    return <Skeleton />;
  }

  return (
    <>
      <FormSwitch
        name="landlordServices.asPerExistingToggle"
        control={form.control}
        title="As per existing (Renewals only)"
        onChange={(e) => {
          if ((e.target as HTMLInputElement).checked) {
            form.setValue("landlordServices.other", true);
            switches.forEach(({ name }) => {
              form.setValue(`landlordServices.${name}`, false);
            });
          } else {
            form.setValue("landlordServices.other", false);
          }
        }}
      />

      <Box width="100%" mb={2} />

      {switches.map((item) => (
        <Grid item sm={6} key={item.name}>
          <FormSwitch
            name={`landlordServices.${item.name}`}
            control={form.control}
            title={item.title}
            disabled={asPerExisting}
          />
        </Grid>
      ))}

      <Grid item sm={6}>
        <FormSwitch
          name="landlordServices.other"
          control={form.control}
          title="Other"
        />
      </Grid>

      <Box width="100%" mb={1} />

      <TextMultiField
        control={form.control}
        formField={{
          fieldName: "landlordServices.otherDescription",
          helperText: "",
          label: "Other description",
          disabled: !form.watch("landlordServices.other"),
        }}
      />
    </>
  );
};
