import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import React from "react";
import { Control, useForm } from "react-hook-form";
import * as yup from "yup";
import { VIRTUAL_UNIT_LABEL_SUFFIX } from "../../../utils/CommonVariables";

import { FormDate, FormTextField } from "../../Form/FormComponents";
import {
  DealShopActivities_Insert_Input,
  DealShopActivityTypes_Enum,
} from "../../../generated/graphql-operations";
import { TextNumberField } from "../../Form/Form";
import { useCreateDealShopNotification } from "../../../adapters/Deal/DealShopNofitication";
import { REQUIRED_FIELD, INVALID_DATE } from "../../../utils/CommonErrors";

interface ShopHandoverProps {
  dealDisabled: boolean;
  dealId: number;
  propertyName: string;
  tenantName: string;
  shopNumber: string;
  tenantId: string;
  dealType: string;
  isRenewal: boolean;
  rentFreePeriod: number;
  address: {
    street: string;
    city: string;
    postcode: string;
    country: string;
  };
  userId: string;
}

const ShopHandoverSchema = yup.object({
  propertyName: yup.string(),
  tenantName: yup.string(),
  shopNumber: yup.string(),
  address: yup.string(),
  actualHandoverDate: yup
    .date()
    .required(REQUIRED_FIELD)
    .typeError(INVALID_DATE),
  estimatedOpenDate: yup.date().required("Required"),
  forecastTradeDate: yup
    .date()
    .required("Forecast Trade date/ Rent Commencement date is required"),
  comment: yup.string().required("Comment is required"),
});

type ShopHandoverDialogType = yup.InferType<typeof ShopHandoverSchema>;

export const ShopHandoverNotificationDialog: React.FC<ShopHandoverProps> = (
  props
) => {
  const form = useForm<ShopHandoverDialogType>({
    resolver: yupResolver(ShopHandoverSchema),
    defaultValues: {
      actualHandoverDate: new Date(),
      forecastTradeDate: new Date(),
      estimatedOpenDate: new Date(),
    },
  });

  const [open, setOpen] = React.useState(false);

  const onShopHandoverSubmit = useCreateDealShopNotification();

  const resetAndClose = () => {
    form.reset();
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={props.dealDisabled}
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ mt: 2, mr: 1 }}
      >
        Shop Handover Notification
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Shop Handover Notification</DialogTitle>
        <DialogContent>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            maxWidth="md"
            sx={{ m: "0 auto" }}
          >
            <Paper sx={{ m: 2, p: 2 }} elevation={10}>
              <Grid container>
                <Grid container>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.dealType}
                      label="Deal type"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.rentFreePeriod}
                      label="Rent free period"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.propertyName}
                      label="Property name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.tenantName}
                      label="Tenant name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.tenantId}
                      label="Tenant ID"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.shopNumber}
                      label="Shop number"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.address.street}
                      label="Street"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.address.city}
                      label="City"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.address.postcode}
                      label="Postcode"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.address.country}
                      label="Country"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormDate
                      fieldName="actualHandoverDate"
                      title={
                        props.isRenewal
                          ? "Lease start date"
                          : "Actual handover date"
                      }
                      control={form.control as unknown as Control}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormDate
                      fieldName="forecastTradeDate"
                      title="Forecast trading date/ Rent commencement date"
                      control={form.control as unknown as Control}
                      onChange={(e) => {
                        form.setValue("forecastTradeDate", e ?? new Date(""));
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormDate
                      fieldName="estimatedOpenDate"
                      title="Estimated open date"
                      control={form.control as unknown as Control}
                      onChange={(e) => {
                        form.setValue("estimatedOpenDate", e ?? new Date(""));
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextNumberField
                      form={form}
                      formField={{
                        fieldName: "comment",
                        helperText: "",
                        label: "Comments",
                        type: "text",
                      }}
                    />
                  </Grid>

                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                      disabled={form.formState.isSubmitting}
                      onClick={form.handleSubmit(async (data) => {
                        const insertObj: DealShopActivities_Insert_Input = {
                          property_name: props.propertyName,
                          tenant_name: props.tenantName,
                          shop_number: props.shopNumber.replace(
                            VIRTUAL_UNIT_LABEL_SUFFIX,
                            ""
                          ),
                          property_address: JSON.stringify(props.address),
                          actual_handover_date:
                            data.actualHandoverDate.toDateString(),
                          forecast_trade_date:
                            data.forecastTradeDate.toDateString(),
                          comments: data.comment,
                          activity: DealShopActivityTypes_Enum.ShopHandover,
                          deal_id: props.dealId,
                          generated_by: props.userId,
                          tenant_id: props.tenantId,
                          rent_free_period: props.rentFreePeriod,
                          open_date: data.estimatedOpenDate.toDateString(),
                          deal_type: props.dealType,
                        };
                        await onShopHandoverSubmit(insertObj);

                        resetAndClose();
                      })}
                    >
                      Send Notification
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        resetAndClose();
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
