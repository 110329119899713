import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { parseISO } from "date-fns";
import { useMutation } from "@apollo/client";
import {
  GetDocumentTemplatesDocument,
  GetDocumentTemplatesQuery,
  SoftDeleteDocumentTemplateDocument,
} from "../../../generated/graphql-operations";
import { checkAndFormatDate } from "../../../utils/DateUtils";
import { BLACK_ALPHA_60 } from "../../../utils/CommonVariables";
import { ConfirmationDialog } from "../../ConfirmationDialog";

export const TemplateCard = (props: {
  data: GetDocumentTemplatesQuery["DocumentTemplates"][number];
}) => {
  const [deleteTemplate] = useMutation(SoftDeleteDocumentTemplateDocument);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const fileDate =
    checkAndFormatDate(
      parseISO(
        `${
          props.data.modified_at
            ? props.data.modified_at
            : props.data.created_at
        }Z`
      ),
      "ccc d LLL yyyy, hh:mm a"
    ) ?? "";
  return (
    <Card
      sx={{
        pt: 2,
        pl: 2,
        pb: 0,
        backgroundColor: theme.palette.secondary.main,
        width: 300,
        height: 180,
      }}
    >
      <Grid container alignItems="flex-start">
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            {props.data.convert_to_pdf ? (
              <PictureAsPdfIcon />
            ) : (
              <TextSnippetIcon />
            )}
            <Typography
              variant="subtitle1"
              color={theme.palette.text.primary}
              sx={{ ml: 1, lineHeight: 1.4, height: 60 }}
            >
              {props.data.generate_to_name}
            </Typography>
          </Stack>
          <Typography variant="body2" color={BLACK_ALPHA_60} mt={1}>
            Uploaded by: {props.data.Users?.user_full_name}
          </Typography>
          <Typography variant="body2" color={BLACK_ALPHA_60}>
            {`${fileDate}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            sx={{ mt: 2, ml: -1 }}
            onClick={() =>
              navigate(
                `/templates/${encodeURIComponent(
                  props.data.generate_to_name ?? ""
                )}`,
                {
                  state: props.data,
                }
              )
            }
          >
            Update
          </Button>
          <Button
            variant="text"
            sx={{ mt: 2, ml: -1, color: theme.palette.error.main }}
            onClick={() => setOpenConfirmation(true)}
          >
            Delete
          </Button>
        </Grid>
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          title="Delete Template"
          description="Are you sure you want to delete this template?"
          confirmText="Yes"
          declineText="No"
          onConfirm={async () => {
            await deleteTemplate({
              variables: {
                id: props.data.id,
              },
              refetchQueries: [GetDocumentTemplatesDocument],
            });
          }}
        />
      </Grid>
    </Card>
  );
};
