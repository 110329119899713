import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { TextNumberField } from "../Form/Form";
import {
  UpdateVirtualUnitDocument,
  GetVirtualUnitsByPropertyDocument,
} from "../../generated/graphql-operations";
import { CurrencyFormatter } from "../MuiCustomNumberFormat";

interface VirtualUnitEditProps {
  id: number;
  propertyId: string;
  label: string;
  estimatedArea: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const VirtualUnitSchema = yup.object({
  label: yup.string().required("Label is required"),
  estimatedArea: yup.number().required("Estimated area is required"),
});
type VirtualUnitDialogType = yup.InferType<typeof VirtualUnitSchema>;

export const VirtualUnitEdit = (props: VirtualUnitEditProps) => {
  const form = useForm<VirtualUnitDialogType>({
    resolver: yupResolver(VirtualUnitSchema),
  });

  useEffect(() => {
    form.setValue("label", props.label);
    form.setValue("estimatedArea", props.estimatedArea);
  }, [form, props.estimatedArea, props.label]);

  const [updateVirtualUnitMutation] = useMutation(UpdateVirtualUnitDocument, {
    refetchQueries: [
      {
        query: GetVirtualUnitsByPropertyDocument,
        variables: { property_code: props.propertyId },
      },
    ],
  });

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edit virtual unit</DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          maxWidth="md"
          sx={{ m: "0 auto" }}
        >
          <Paper sx={{ m: 2, p: 2 }} elevation={10}>
            <Grid container>
              <Grid container>
                <Grid item sm={12}>
                  <TextNumberField
                    form={form}
                    formField={{
                      fieldName: "label",
                      helperText: "",
                      label: "Label",
                      type: "text",
                      disabled: false,
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextNumberField
                    form={form}
                    formField={{
                      fieldName: "estimatedArea",
                      helperText: "",
                      label: "Estimated area",
                      type: "text",
                      disabled: false,
                      customComp: CurrencyFormatter,
                    }}
                  />
                </Grid>
                <Grid item p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    disabled={form.formState.isSubmitting}
                    onClick={form.handleSubmit(async (unitData) => {
                      await updateVirtualUnitMutation({
                        variables: {
                          id: props.id,
                          label: unitData.label,
                          estimated_area: unitData.estimatedArea,
                        },
                      });
                      form.reset();
                      props.setOpen(false);
                    })}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      form.reset();
                      props.setOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
