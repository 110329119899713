import React, { useState } from "react";
import { Tab, Tabs, Avatar, Grid } from "@mui/material";
import {
  HistoryRounded,
  FileCopyRounded,
  CommentRounded,
  FolderRounded,
  PersonRounded,
  ContentCopyOutlined,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import { parseISO } from "date-fns";
import { useSubscription } from "@apollo/client";
import { SubGetDealDocumentsDocument } from "../../../generated/graphql-operations";
import theme from "../../../themes/theme";
import { Button } from "../../Button";
import { GenerateDocumentDialog } from "../../GenerateDocumentDialog";
import { ErroredDocumentCard } from "./Documents/ErroredDocumentCard";
import { DocumentCard } from "./Documents/DocumentCard";
import { DealComments } from "./Comments/DealComments";
import { checkAndFormatDate } from "../../../utils/DateUtils";
import { FilesComponent } from "./Files/Files";
import { ActivityLog } from "./Activity/ActivityLog";
import { WHITE_ALPHA_08 } from "../../../utils/CommonVariables";
import { DealContacts } from "./Contacts/DealContacts";
import { LinksComponent } from "./Links/Links";
import { useReturnUserRole, Role } from "../../../contexts/Auth/UserRole";

export interface DealSummaryTabsProps {
  dealId: number;
  // eslint-disable-next-line react/no-unused-prop-types
  dealDisabled: boolean;
}

const tabs: { label: string; icon: typeof HistoryRounded }[] = [
  {
    label: "ACTIVITY",
    icon: HistoryRounded,
  },
  {
    label: "DOCUMENTS",
    icon: FileCopyRounded,
  },
  {
    label: "COMMENTS",
    icon: CommentRounded,
  },
  {
    label: "FILES",
    icon: FolderRounded,
  },
  {
    label: "CONTACTS",
    icon: PersonRounded,
  },
  {
    label: "LINKS",
    icon: LinkIcon,
  },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivitySection = (props: DealSummaryTabsProps) => (
  <Grid item sm={12}>
    <ActivityLog dealId={props.dealId} />
  </Grid>
);

const DocumentsSection = (props: DealSummaryTabsProps) => {
  const [open, setOpen] = useState(false);
  const isReadOnly = useReturnUserRole() === Role.ReadOnly;

  const dealDocuments = useSubscription(SubGetDealDocumentsDocument, {
    variables: {
      deal_id: props.dealId,
    },
  });

  const docs = dealDocuments?.data?.GeneratedDocuments;
  type DocType = NonNullable<typeof docs>;

  const documents = docs?.reduce<DocType>((aggr, current) => {
    // check current item doesn't exist in aggregate
    if (!aggr?.some((item) => item.document_name === current.document_name)) {
      return [...aggr, current];
    }

    return aggr;
  }, []);

  return (
    <Grid>
      <Grid container item sm={12} spacing={2} mb={2}>
        {documents?.map((document) => {
          const key = `${document.document_name}_${document.id}`;
          const createdAt =
            checkAndFormatDate(
              parseISO(`${document.generated_at}Z`),
              "ccc d LLL, hh:mm a"
            ) ?? "Invalid date";
          return (
            <Grid item xs="auto" key={key}>
              {document.has_error ? (
                <ErroredDocumentCard
                  documentName={document.document_name ?? ""}
                  documentPath={document.full_path ?? ""}
                  createdAt={createdAt}
                  errorsJson={document.errors_json}
                  disabled={props.dealDisabled}
                />
              ) : (
                <DocumentCard
                  documentName={document.document_name ?? ""}
                  documentPath={document.full_path ?? ""}
                  createdAt={createdAt}
                  disabled={props.dealDisabled}
                />
              )}
            </Grid>
          );
        })}
      </Grid>

      <Button
        disabled={props.dealDisabled || isReadOnly}
        color="primary"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        <ContentCopyOutlined
          sx={{ color: theme.palette.primary.main, mr: 1 }}
        />
        <span style={{ color: theme.palette.primary.main }}>
          Generate document
        </span>
      </Button>

      <GenerateDocumentDialog
        dealId={props.dealId}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CommentsSection = (props: DealSummaryTabsProps) => (
  <Grid item sm={12}>
    <DealComments {...props} />
  </Grid>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilesSection = (props: DealSummaryTabsProps) => (
  <Grid>
    <FilesComponent {...props} />
  </Grid>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContactsSection = (props: DealSummaryTabsProps) => (
  <Grid item xs={12}>
    <DealContacts {...props} />
  </Grid>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LinksSection = (props: DealSummaryTabsProps) => (
  <Grid item xs={12}>
    <LinksComponent {...props} />
  </Grid>
);

const sections: { name: string; section: typeof ActivitySection }[] = [
  { name: "activitySection", section: ActivitySection },
  { name: "documentsSection", section: DocumentsSection },
  { name: "commentsSection", section: CommentsSection },
  { name: "filesSection", section: FilesSection },
  { name: "contactsSection", section: ContactsSection },
  { name: "linksSection", section: LinksSection },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DealSummaryTabs = (props: DealSummaryTabsProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = async (
    event: React.SyntheticEvent,
    tabIndex: number
  ) => {
    setCurrentTab(tabIndex);
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{ backgroundColor: WHITE_ALPHA_08 }}
        TabIndicatorProps={{
          sx: { backgroundColor: theme.palette.text.primary },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            icon={
              <Avatar sx={{ backgroundColor: theme.palette.secondary.main }}>
                <tab.icon sx={{ color: theme.palette.text.primary }} />
              </Avatar>
            }
            iconPosition="start"
            label={tab.label}
            sx={{
              color: theme.palette.text.primary,
              "&.Mui-selected": {
                backgroundColor: "white",
                color: theme.palette.text.primary,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            }}
          />
        ))}
      </Tabs>
      <Grid container p={2}>
        {sections.map((section, index) => {
          return currentTab === index ? (
            <section.section {...props} key={section.name} />
          ) : null;
        })}
      </Grid>
    </>
  );
};
