import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  DeleteVirtualUnitDocument,
  GetDealsUnitsForVirtualUnitDocument,
  GetVirtualUnitsByPropertyDocument,
  GetVirtualUnitsByPropertyQuery,
} from "../../generated/graphql-operations";
import { VirtualUnitEdit } from "../../components/VirtualUnit/VirtualUnitEdit";

export interface PropertyVirtualUnitsProps {
  propertyId: string;
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const PropertyVirtualUnits = ({
  propertyId,
  toolBar,
  hideColumns = undefined,
}: PropertyVirtualUnitsProps) => {
  const { data, loading, error } = useQuery(GetVirtualUnitsByPropertyDocument, {
    variables: { property_code: propertyId },
  });
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openUpdateConfirmation, setOpenUpdateConfirmation] =
    React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const [selectedVirtualUnit, setSelectedVirtualUnit] = React.useState<
    GetVirtualUnitsByPropertyQuery["VirtualUnits"][number] | undefined
  >(undefined);

  const [getDealsUnits] = useLazyQuery(GetDealsUnitsForVirtualUnitDocument);

  const [deleteVirtualUnitMutation] = useMutation(DeleteVirtualUnitDocument, {
    refetchQueries: [
      {
        query: GetVirtualUnitsByPropertyDocument,
        variables: { property_code: propertyId },
      },
    ],
  });

  const onVirtualUnitDelete = async (e: any, row: any) => {
    e.stopPropagation();

    const dealsUnits = await getDealsUnits({
      variables: { virtual_unit_id: row.id },
    });
    const hasDealsUnitsData = dealsUnits.data?.DealsUnits
      ? dealsUnits.data?.DealsUnits.length > 0
      : false;
    if (hasDealsUnitsData) {
      setShowAlert(true);
      return;
    }

    setSelectedVirtualUnit(
      data?.VirtualUnits.find((item) => item.id === row.id)
    );
    setOpenDeleteConfirmation(true);
  };

  const onVirtualUnitUpdate = async (id: number) => {
    setSelectedVirtualUnit(data?.VirtualUnits.find((item) => item.id === id));

    setOpenUpdateConfirmation(true);
  };

  const deleteVirtualUnit = async () => {
    deleteVirtualUnitMutation({
      variables: { id: selectedVirtualUnit?.id },
    });

    setSelectedVirtualUnit(undefined);
    setOpenDeleteConfirmation(false);
  };

  const columns: GridColDef<
    NonNullable<GetVirtualUnitsByPropertyQuery["VirtualUnits"][0]>
  >[] = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "estimated_area",
      headerName: "Estimated area",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "survey_area",
      headerName: "Survey area",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "unit_type",
      headerName: "Unit type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "area_survey_required",
      headerName: "Area survey required",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => {
                onVirtualUnitUpdate(params.row.id);
              }}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>

            <Button
              onClick={(e: any) => onVirtualUnitDelete(e, params.row)}
              variant="contained"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      {showAlert && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlert(false);
          }}
        >
          This virtual unit cannot be deleted because it has deals units
          associated with it.
        </Alert>
      )}
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .disabled-row": {
            backgroundColor: "lightgray",
            "&:hover": {
              backgroundColor: "lightgray",
            },
          },
        }}
      >
        <DataGrid
          rows={data?.VirtualUnits || []}
          columns={columns}
          loading={loading || error !== undefined}
          columnVisibilityModel={hideColumns}
          components={toolBar ? { Toolbar: CustomToolbar } : {}}
          getRowId={(params) => params.id ?? ""}
          getRowClassName={() => "disabled-row"}
          isRowSelectable={() => false}
        />

        <Dialog
          open={openDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this virtual unit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await deleteVirtualUnit();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setOpenDeleteConfirmation(false);
              }}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <VirtualUnitEdit
          id={selectedVirtualUnit?.id ?? ""}
          label={selectedVirtualUnit?.label ?? ""}
          estimatedArea={selectedVirtualUnit?.estimated_area ?? 0}
          propertyId={propertyId}
          open={openUpdateConfirmation}
          setOpen={setOpenUpdateConfirmation}
        />
      </Box>
    </>
  );
};
