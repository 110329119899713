import * as yup from "yup";

import {
  REQUIRED_FIELD,
  GREATER_THAN_0,
  INVALID_DATE,
} from "../../../utils/CommonErrors";

export const FitoutLandlordWorksSchema = yup.object({
  landlordUndertakeWorks: yup.bool().default(false),
  landlordWorks: yup
    .object({ label: yup.string(), value: yup.string() })
    .required()
    .default({ label: "Other", value: "other" }),
  preview: yup.string(),
  costLandlordWorks: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  costMakegood: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  cappedCategory: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  tenancyDeliveryDealType: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .required(REQUIRED_FIELD),
  tenantWorks: yup.bool().default(false),
  tenantWorksOptions: yup.array().of(yup.string()).required(),
  fitoutPreviousTenant: yup.string(),
  refurbishedFitoutWorks: yup.bool().default(false),
  dateCompleteFitout: yup
    .date()
    .transform((value, org) => {
      return org === null ? undefined : value;
    })
    .typeError(INVALID_DATE),
  partialFitoutItems: yup.string(),
  hoardingToggle: yup.string().oneOf(["landlord", "tenant", "split", "na"]),
  skinsToggle: yup.string().oneOf(["landlord", "tenant", "split", "na"]),
  fitoutDescription: yup.string(),
});

export type FitoutLandlordWorksType = yup.InferType<
  typeof FitoutLandlordWorksSchema
>;

export enum TenancyDeliveryDealType {
  NO_WORK = "No works required",
  NEW_LEASE_FOOD = "New lease - food",
  NEW_LEASE_NO_FOOD = "New lease - non-food",
  LEASE_RENEWAL_FULL_REFURB = "Lease renewal - full refurb",
  LEASE_RENEWAL_PART_REFURB = "Lease renewal - partial refurb",
  LEASE_RENEWAL_MINOR_REFURB = "Lease renewal - minor refurb",
  ATM_LICENCE = "ATM licence",
}

export const TENANCY_DELIVERY_CHOICES = Object.values(
  TenancyDeliveryDealType
).map((value) => ({
  label: value,
  value,
}));

export const DEFAULT_VALUES = {
  tenancyDeliveryDealType: TENANCY_DELIVERY_CHOICES[0],
  refurbishedFitoutWorks: false,
  tenantWorks: false,
  landlordUndertakeWorks: false,
};

export const TENANT_WORK_CHOICES = [
  "Tenant will complete full fitout",
  "Tenant will use existing fitout",
  "Tenant will complete partial fitout",
];
