import React from "react";
import { Grid, Skeleton } from "@mui/material";

import { SelectField, TextNumberField } from "../../Form/Form";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { useGetHistoricalData } from "../../../adapters/DealInformation/HistoricalData/HistoricalData";
import { Alert } from "../../Alert";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";

const EMPTY_CHOICE = { label: "", value: "" };

export const HistoricalData = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;
  const data = useGetHistoricalData(props.dealId, () =>
    setDataState((prev) => ({ ...prev, historicalDataLoaded: false }))
  );
  React.useEffect(() => {
    if (data.data) {
      const dealData = data.data.DealData[0];
      form.setValue("historicalData", {
        historicalBaseRent: dealData?.historical_base_rent,
        historicalOutgoings: dealData?.historical_outgoings,
        historicalPromoLevy: dealData?.historical_promo_levy,
        HistoricalTenant: {
          label: dealData?.historical_tenant_name,
          value: "",
        },
      });
      setDataState((prev) => ({ ...prev, historicalDataLoaded: true }));
    }
  }, [form, setDataState, data]);

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  const historicalTenants =
    data.data.DealData?.[0].DealsUnits[0].YardiUnit?.YardiTenantToUnits;

  const tenantChoices =
    historicalTenants?.map((tenant) => ({
      label: tenant?.YardiTenant?.TenantName ?? "",
      value: tenant?.YardiTenant?.TenantCode ?? "",
    })) ?? [];

  return (
    <Grid container width="100%">
      <Grid item sm={12} p={1}>
        <SelectField
          form={props.form}
          formField={{
            type: "select",
            fieldName: "historicalData.HistoricalTenant",
            helperText: "",
            choices: [EMPTY_CHOICE, ...tenantChoices],
            onChange: (_, choice) => {
              const tenantInfo = historicalTenants?.find(
                (tenant) => tenant.YardiTenant?.TenantCode === choice.value
              )?.YardiTenant;

              props.form.setValue(
                "historicalData.historicalBaseRent",
                Number(tenantInfo?.BaseRent)
              );
              props.form.setValue(
                "historicalData.historicalOutgoings",
                Number(tenantInfo?.Outgoings)
              );
              props.form.setValue(
                "historicalData.historicalPromoLevy",
                Number(tenantInfo?.PromoLevy)
              );
            },
            label: "Current/Previous tenants",
          }}
        />
      </Grid>

      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            type: "text",
            fieldName: "historicalData.historicalBaseRent",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Base rent",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>
      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            type: "text",
            fieldName: "historicalData.historicalOutgoings",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Outgoings",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>
      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            type: "text",
            fieldName: "historicalData.historicalPromoLevy",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Promo levy",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>
    </Grid>
  );
};
