import * as yup from "yup";
import {
  REQUIRED_FIELD,
  GREATER_THAN_0,
  INTEGER_VALUE,
  INVALID_NUMBER,
  INVALID_DATE,
} from "../../../utils/CommonErrors";

export const DateSchema = yup.object({
  fixedHandoverDate: yup.boolean().default(false),
  handoverDate: yup
    .date()
    .required(REQUIRED_FIELD)
    .typeError(INVALID_DATE)
    .min(new Date(1800, 1, 1), INVALID_DATE),
  leaseCommencementDate: yup
    .date()
    .required(REQUIRED_FIELD)
    .typeError(INVALID_DATE)
    .min(new Date(1800, 1, 1), INVALID_DATE),
  fitoutPeriodWeek: yup
    .number()
    .typeError(INVALID_NUMBER)
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE)
    .required(REQUIRED_FIELD),
  fitoutPeriodDay: yup
    .number()
    .typeError(INVALID_NUMBER)
    .integer(INTEGER_VALUE)
    .min(0, GREATER_THAN_0)
    .required(REQUIRED_FIELD),
  leaseTermYear: yup
    .number()
    .typeError(INVALID_NUMBER)
    .integer(INTEGER_VALUE)
    .min(0, GREATER_THAN_0)
    .required(REQUIRED_FIELD),
  leaseTermMonth: yup
    .number()
    .typeError(INVALID_NUMBER)
    .integer(INTEGER_VALUE)
    .min(0, GREATER_THAN_0)
    .required(REQUIRED_FIELD),
  leaseTermDay: yup
    .number()
    .typeError(INVALID_NUMBER)
    .integer(INTEGER_VALUE)
    .min(0, GREATER_THAN_0)
    .required(REQUIRED_FIELD),
  rentStarts: yup.boolean().default(false),
  rentFreePeriodWeek: yup
    .number()
    .typeError(INVALID_NUMBER)
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE)
    .required(REQUIRED_FIELD),
});

export type DateInfoType = yup.InferType<typeof DateSchema>;
