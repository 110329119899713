import * as yup from "yup";

const CustomChargeSchema = yup.object({
  description: yup.string().default("").required("Description is required"),
  amount: yup.number().required("Amount is required").default(0),
});

export const ChargesSchema = yup.object({
  tenancyCoordinationFee: yup.number().nullable(true),
  tenantContributionLandlordWorks: yup.number().nullable(true),
  recoverySurveyFee: yup.number().nullable(true),
  openingPromotionLevyFees: yup.number().nullable(true),
  developmentFees: yup.number().nullable(true),
  rubbishRemovalFees: yup.number().nullable(true),
  otherCharges: yup.array().of(CustomChargeSchema).required().default([]),
});

export const defaultCustomCharge = CustomChargeSchema.getDefault();
export type CustomChargeType = yup.InferType<typeof CustomChargeSchema>;
export type ChargesType = yup.InferType<typeof ChargesSchema>;
