import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import { BreadCrumbs } from "../../components";
import { ActiveLeasesList } from "../../components/ActiveLeasesList";

export const ActiveLeases = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Renewals</Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} sx={{ mb: 1 }}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Active leases", url: "/active-leases" },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: 1500,
          width: "100%",
        }}
      >
        <ActiveLeasesList toolBar />
      </Grid>
    </Grid>
  );
};
