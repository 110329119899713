import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormField } from "../../components/Form/Form";
import { CurrencyFormatter } from "../../components/MuiCustomNumberFormat";
import { GetPropertyExpenseDocument } from "../../generated/graphql-operations";

const fields: FormField[] = [
  {
    type: "text",
    fieldName: "adminstrationCosts",
    label: "Adminstration costs",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "auditFees",
    label: "Audit fees",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "managementFees",
    label: "Management fees",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "airConditioningMaintenance",
    label: "Air conditioning maintenance",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "airConditioningOperatingCosts",
    label: "Air conditioning operating costs",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "bodyCorporateStrataLevies",
    label: "Body corporate strata levies",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "buildingIntelligenceServices",
    label: "Building intelligence services",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "customerTrafficFlowServices",
    label: "Customer traffic flow services",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "energyManagementServices",
    label: "Energy management services",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "gardeningAndLandscaping",
    label: "Gardening and landscaping",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "insurance",
    label: "Insurance",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "pestControl",
    label: "Pest control",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "ventilation",
    label: "Ventilation",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "caretaking",
    label: "Caretaking",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "emergencySystems",
    label: "Emergency systems",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "fireLevy",
    label: "Fire levy",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "fireProtection",
    label: "Fire protection",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "securityServices",
    label: "Security services",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "cleaningConsumables",
    label: "Cleaning consumables",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "cleaningCosts",
    label: "Cleaning costs",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "postBoxes",
    label: "Post boxes",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "publicTelephones",
    label: "Public telephones",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "carParking",
    label: "Car parking",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "childMinding",
    label: "Child minding",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "escalators",
    label: "Esclators",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "eLifts",
    label: "ELifts",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "uniforms",
    label: "Uniforms",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "informationDirectories",
    label: "Information directories",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "publicAddressMusic",
    label: "Public address/music",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "signage",
    label: "Signage",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "localGovtRatesAndCharges",
    label: "Local govt rates & charges",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "waterSewerageDrainageRatesAndCharges",
    label: "Water; sewerage; drainage rates and charges",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "electricity",
    label: "Electricity",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "repairsAndMaintenance",
    label: "Repairs and maintenance",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "sinkingFund",
    label: "Sinking fund",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "gas",
    label: "Gas",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "oil",
    label: "Oil",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "water",
    label: "Water",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "sewageDisposal",
    label: "Sewage disposal",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "wasteCollectionDisposal",
    label: "Waste collection and disposal",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "workplaceHealthAndSafety",
    label: "Workplace health and safety",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "staffWageSalaries",
    label: "Staff wage salaries",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "miscellaneous",
    label: "Miscellaneous",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
];

export const PropertyExpense = (props: { propertyId: string }) => {
  const propertyExpenses = useQuery(GetPropertyExpenseDocument, {
    variables: { property_code: props.propertyId },
  });

  const form = useForm();

  useEffect(() => {
    form.reset({
      adminstrationCosts:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_admin,
      auditFees: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_audit,
      managementFees:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_mgmt_fee,
      airConditioningMaintenance:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_air_maint,
      airConditioningOperatingCosts:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_air_ops,
      bodyCorporateStrataLevies:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_strata,
      buildingIntelligenceServices:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_building_intelligence,
      customerTrafficFlowServices:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_traffic_flow,
      energyManagementServices:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_energy_mgmt,
      gardeningAndLandscaping:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_landscape,
      insurance:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_insurance,
      pestControl: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_pest,
      ventilation:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_ventilation,
      caretaking:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_caretaking,
      emergencySystems:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_emergency_sys,
      fireLevy:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_fire_levy,
      fireProtection:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_fire_protection,
      securityServices:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_security,
      cleaningConsumables:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_clean_consumable,
      cleaningCosts:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_cleaning_costs,
      postBoxes:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_post_boxes,
      publicTelephones:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_public_telephones,
      carParking:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_car_parking,
      childMinding:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_child_minding,
      escalators:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_escalators,
      eLifts: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_lift,
      uniforms:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_uniforms,
      informationDirectories:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_info_directories,
      publicAddressMusic:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_public_address,
      signage: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_signage,
      localGovtRatesAndCharges:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_local_gov_charges,
      waterSewerageDrainageRatesAndCharges:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_water_sewage_rates,
      electricity:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_electricity,
      repairsAndMaintenance:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_repairs_and_maint,
      sinkingFund:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_sinking_fund,
      gas: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_gas,
      oil: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_oil,
      water: propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_water,
      sewageDisposal:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_sewerage_disposal,
      wasteCollectionDisposal:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]
          ?.exp_water_collection_and_disposal,
      workplaceHealthAndSafety:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_ohs,
      staffWageSalaries:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.exp_staff_wages,
      miscellaneous:
        propertyExpenses?.data?.YardiPropertyProfiles?.[0]?.miscellaneous,
    });
  }, [form, propertyExpenses?.data?.YardiPropertyProfiles]);

  return <Form form={form} fields={fields} />;
};
