import { useMutation, useQuery } from "@apollo/client";
import {
  UnitInformationType,
  defaultUnitInformationData,
} from "../../../components/DealInformation/DealInformationConfig";
import {
  AddVirtualUnitDocument,
  GetDealInfoUnitInfoDocument,
  GetDealInfoUnitInfoQuery,
  GetVirtualUnitsDocument,
  UpdateDealUnitDocument,
  VirtualUnits_Insert_Input,
} from "../../../generated/graphql-operations";
import { VIRTUAL_UNIT_LABEL_SUFFIX } from "../../../utils/CommonVariables";
import { createCacheUpdateFunction } from "../../../utils/CommonFunctions";

export const transformUnitInformation = (
  unitInfo: GetDealInfoUnitInfoQuery
): UnitInformationType => {
  return {
    centre: {
      label:
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property
          ?.label ??
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
          ?.label ??
        defaultUnitInformationData.centre.label,
      value:
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property
          ?.property_code ??
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
          ?.property_code ??
        defaultUnitInformationData.centre.value,
    },
    unit: {
      label:
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.label ??
        (unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label
          ? `${unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label}${VIRTUAL_UNIT_LABEL_SUFFIX}`
          : null) ??
        defaultUnitInformationData.unit.label,
      value:
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.UnitKey ??
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.id ??
        defaultUnitInformationData.unit.value,
    },
    unitType: {
      label: "",
      value: "",
    },
    virtualUnit: "",
    estimatedArea:
      parseFloat(
        unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit
          ?.estimated_area ??
          unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit
            ?.estimated_area ??
          ""
      ) ?? defaultUnitInformationData.estimatedArea,
    centreUnderDevelopment:
      unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property
        ?.center_under_development === "true",
    areaSurveyRequired:
      unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0].area_survey_required ??
      false,
    isVirtual: !!unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit,
    virtualUnitDescription:
      unitInfo?.Deals_by_pk?.DealData[0]?.DealsUnits?.[0]?.description ?? "",
  };
};

export const useGetDealInfoUnitInfo = (dealId: number) => {
  const dealInfoUnitInfo = useQuery(GetDealInfoUnitInfoDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return dealInfoUnitInfo;
};

export const useMutateUnitInformation = (dealId: number) => {
  const [UpdateDealUnit] = useMutation(UpdateDealUnitDocument);
  const [AddVirtualUnit] = useMutation(AddVirtualUnitDocument);

  return async (data: UnitInformationType) => {
    // Using an existing unit option
    if (data.unit.value) {
      await UpdateDealUnit({
        variables: {
          deal_id: dealId,
          unit_id: !data.isVirtual ? data.unit.value : null,
          virtual_unit_id: data.isVirtual ? data.unit.value : null,
          area_survey_required: data.areaSurveyRequired,
          description: data.virtualUnitDescription,
        },
      });

      return;
    }

    const updateCache = createCacheUpdateFunction(
      GetVirtualUnitsDocument,
      "VirtualUnits",
      "insert_VirtualUnits_one"
    );

    const virtualUnit: VirtualUnits_Insert_Input = {
      estimated_area: data?.estimatedArea,
      label: data?.virtualUnit,
      property_code: data?.centre.value,
      unit_type: data?.unitType.value,
      area_survey_required: data.areaSurveyRequired,
    };

    const newUnit = await AddVirtualUnit({
      variables: {
        unit: virtualUnit,
      },
      update: updateCache,
    });

    const unitId = newUnit.data?.insert_VirtualUnits_one?.id;

    await UpdateDealUnit({
      variables: {
        deal_id: dealId,
        unit_id: null,
        virtual_unit_id: unitId,
        area_survey_required: data.areaSurveyRequired,
        description: data.virtualUnitDescription,
      },
    });
  };
};
