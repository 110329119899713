import * as React from "react";
import { createTheme } from "@mui/material/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import sx from "@mui/system/sx";
import type {} from "@mui/x-data-grid/themeAugmentation";

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: sx({
          border: 0,
          boxShadow:
            "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }),
        columnHeaders: {
          backgroundColor: "#001b42",
          padding: 8,
          color: "#ffffff",
          cellContent: { ":hover": { textDecoration: "underline" } },
        },

        menuIconButton: {
          color: "#fff",
        },
        filterIcon: {
          color: "#fff",
        },
        menuIcon: {
          color: "#fff",
        },
        sortIcon: {
          color: "#fff",
        },
      },
    },

    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: sx({
          borderRadius: 0.5,
        }),
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: sx({
          marginTop: 0,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: sx({
          marginLeft: 0,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: sx({
          fontSize: "0.875rem",
          color: "secondary",
          marginBottom: 1,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: sx({
          fontSize: "0.875rem",
          color: "#001B42",
          marginBottom: 1,
        }),
      },
    },
    MuiStep: {
      styleOverrides: {
        root: sx({
          paddingRight: 0,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: sx({
          padding: 2,
        }),
      },
    },
    // This override exists so these display properly in PDFs
    MuiChip: {
      styleOverrides: {
        root: sx({
          backgroundColor: "#EBEBEB",
          display: "flex",
          width: "fit-content",
        }),
      },
    },
  },

  palette: {
    primary: {
      main: "#2A6EBB",
      dark: "#00448A",
      light: "#679CEE",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#D4E2F0",
      light: "#F7F9FC",
      dark: "#A3B0BD",
      contrastText: "#001B42",
    },
    error: {
      main: "#D14F4F",
      dark: "#D32F2F",
      light: "#E57373",
    },
    warning: {
      main: "#FFA726",
      light: "#F57C00",
      dark: "#FFB74D",
    },
    info: {
      main: "#001B42",
      light: "#0288D1",
    },
    success: {
      main: "#00AA78",
      dark: "#388E3C",
      light: "#81C784",
    },
    text: {
      primary: "#001b42",
      secondary: "rgba(0,0,0,0.6)",
    },
    background: {
      default: "#F7F9FC",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    button: {
      fontWeight: 700,
      textTransform: "inherit",
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: "700",
    },
    h4: {
      "@media (max-width:1400px)": {
        fontSize: "1.5rem",
      },
    },
    subtitle1: {
      fontWeight: "700",
    },
    body2: {
      fontSize: "0.875rem",
    },

    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: "700",
    },
  },
});

export default theme;
