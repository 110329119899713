import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Stack,
  Box,
  Card,
  useTheme,
  Typography,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextNumberField } from "../../../Form/Form";
import { GetDealFilesQuery } from "../../../../generated/graphql-operations";
import { TagBar } from "../../../TagBar";
import { checkAndFormatDate } from "../../../../utils/DateUtils";
import {
  ValidationProps,
  CreateFileDialogSchema,
  CreateFileDialogType,
  BlobVersions,
} from "./Config";

import {
  useUpdateFileSubmit,
  useGetBlobList,
  useDownloadBlob,
} from "../../../../adapters/Deal/Files";

const ColumnFormat: GridColDef<BlobVersions[number]>[] = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    type: "date",
    valueFormatter: (params) =>
      checkAndFormatDate(params.value, "dd/MM/yyyy HH:mm"),
    valueGetter: (params) => {
      return params.row.date;
    },
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
];

export const BlobList = ({
  data,
  handleRowSelect,
}: {
  data: BlobVersions;
  handleRowSelect: (id: string) => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        height: 200,
        backgroundColor: "white",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          headerHeight={35}
          rowHeight={35}
          rowsPerPageOptions={[]}
          loading={data.length === 0}
          rows={data}
          columns={ColumnFormat}
          onCellClick={(params, event) => {
            if (!event.ctrlKey) {
              handleRowSelect?.(params.row.id);
            }
          }}
        />
      </div>
    </div>
  );
};

interface FileEditDialogProps {
  // This should set fileData to be undefined so closes this dialog
  setFileDataUndefined: () => void;
  fileData: GetDealFilesQuery["DealFiles"][number] | undefined;
  dealDisabled: boolean;
}

export const FileEditDialog = (props: FileEditDialogProps) => {
  const theme = useTheme();
  const form = useForm<CreateFileDialogType>({
    resolver: yupResolver(CreateFileDialogSchema),
  });
  const [blobs, clearBlobCache, forceBlobReload] = useGetBlobList(
    props.fileData?.file_path
  );
  const downloadBlob = useDownloadBlob();

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [userFile, setUserFile] = React.useState<File | undefined>(undefined);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        setUserFile(acceptedFiles[0]);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const onSubmit = useUpdateFileSubmit(setValidation, userFile);

  React.useEffect(() => {
    if (props.fileData) {
      form.setValue("fileName", props.fileData.file_name);
      form.setValue(
        "tags",
        props.fileData.DealFileTags.map((item) => item.Tags.name ?? "") ?? []
      );
    }
  }, [props.fileData, form]);

  if (props.fileData === undefined) {
    return null;
  }

  const { fileData, dealDisabled } = props;

  return (
    <Dialog open={props.fileData !== undefined} fullWidth>
      <DialogTitle>Upload file</DialogTitle>
      <DialogContent>
        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}
        <TextNumberField
          form={form}
          formField={{
            fieldName: "fileName",
            helperText: "",
            label: "File name",
            type: "text",
          }}
        />
        <TagBar
          control={form.control}
          formField={{
            fieldName: "tags",
            label: "Tag",
            placeholder: "value",
            helperText: "",
          }}
        />
        <Box width="100%" padding={1} />
        <BlobList
          data={blobs}
          handleRowSelect={(id) =>
            downloadBlob(fileData.file_name, fileData.file_path, id)
          }
        />
        <Card
          sx={{
            my: 2,
            backgroundColor: isDragActive
              ? theme.palette.secondary.dark
              : theme.palette.secondary.main,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive
            ? "Drop file here"
            : "Drop file here, or click to select file"}
        </Card>
        {userFile && (
          <Stack direction="row" justifyContent="flex-start">
            <InsertDriveFileOutlinedIcon />{" "}
            <Typography>{userFile.name}</Typography>
          </Stack>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            disabled={form.formState.isSubmitting || dealDisabled}
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => {
              form.handleSubmit(async (data) => {
                try {
                  await onSubmit(data, fileData.file_path, fileData.id);
                  forceBlobReload();
                } catch (e: any) {
                  setValidation({
                    show: true,
                    severity: "error",
                    message: `Failed due to: ${e?.message}`,
                  });
                }
              })();
            }}
          >
            {form.formState.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setFileDataUndefined();
              setValidation((prev) => ({ ...prev, show: false }));
              form.reset();
              setUserFile(undefined);
              clearBlobCache();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
