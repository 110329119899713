import React, { useMemo } from "react";

interface FeatureFlags {
  isAiEnabled?: boolean;
}

interface FeatureFlagsContextType {
  features: FeatureFlags;
}

export const FeatureFlagsContext = React.createContext<
  FeatureFlagsContextType | undefined
>(undefined);

export const FeatureFlagsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  // TODO (Alex): I think here is fine for now, but in the future we should
  // probably move this somewhere, maybe the backend or Hasura?
  const features: FeatureFlagsContextType = useMemo(
    () => ({
      features: {
        isAiEnabled: process.env.REACT_APP_FLAG_AI_ENABLED === "true",
      },
    }),
    []
  );

  return (
    <FeatureFlagsContext.Provider value={features}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
