import { useMutation, useQuery } from "@apollo/client";
import {
  CommercialType,
  DEFAULT_VALUES,
} from "../../../components/DealInformation/Commercial/Config";
import {
  GetDealInfoCommercialDocument,
  GetDealInfoCommercialQuery,
  UpdateDealDataInfoDocument,
} from "../../../generated/graphql-operations";
import {
  GeneralAmountTypes,
  PromotionLevyTypes,
  ToggleString,
} from "../../../utils/CommonVariables";

export const useGetDealInfoCommercial = (dealId: number) => {
  const dealInfoUnitInfo = useQuery(GetDealInfoCommercialDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return dealInfoUnitInfo;
};

export const transformCommercial = (
  data: GetDealInfoCommercialQuery
): CommercialType => {
  const dealData = data.Deals_by_pk?.DealData[0];

  const annualIncreaseTypeData =
    Object.keys(GeneralAmountTypes).find(
      (item) => item === dealData?.annual_increase_type
    ) ?? DEFAULT_VALUES.annualIncreaseType.label;
  const annualIncreaseType = {
    label: annualIncreaseTypeData,
    value: annualIncreaseTypeData,
  };

  const promotionLevyTypeData =
    Object.keys(PromotionLevyTypes).find(
      (item) => item === dealData?.promotion_levy_type
    ) ?? DEFAULT_VALUES.promotionLevyType.label;
  const promotionLevyType = {
    label: promotionLevyTypeData,
    value: promotionLevyTypeData,
  };

  // Check if annual Increase should be undefined for these types
  const isAnnualNonNumber =
    annualIncreaseType.value === GeneralAmountTypes.CPI ||
    annualIncreaseType.value === GeneralAmountTypes["Market review"];

  return {
    baseRent: dealData?.base_net_rent ?? 0,
    grossRent: dealData?.gross_rent ?? 0,
    outgoings: dealData?.outgoings_psm ?? undefined,
    outgoingsToggle: dealData?.outgoings_state ?? ToggleString.Yes,
    promotionLevy: dealData?.promotion_amt ?? undefined,
    promotionLevyToggle: dealData?.promotion_levy_state ?? ToggleString.Yes,
    turnOverRent: dealData?.turnover_based_rent_percent ?? undefined,
    turnOverRentUnnatural: dealData?.turn_over_rent_unnatural ?? undefined,
    turnoverRentToggle: dealData?.turnover_rent_state ?? ToggleString.Yes,
    annualIncrease: isAnnualNonNumber
      ? undefined
      : dealData?.annual_increase ?? DEFAULT_VALUES.annualIncrease,
    annualIncreaseType,
    promotionLevyType,
    cashIncentive: dealData?.cash_incentive,
    fitoutContribution: dealData?.fitout_contribution,
    cappedOccupancyCostsStateToggle: dealData?.capped_occupancy_costs_state,
  };
};

export const useMutateDealCommercial = (deal_id: number) => {
  // If refetching queries is too slow then we can manually update the cache using
  // https://www.apollographql.com/docs/react/data/mutations/#refetching-after-update
  // Or we can consider using subscriptions instead
  const [updateDateInfo] = useMutation(UpdateDealDataInfoDocument, {
    refetchQueries: [GetDealInfoCommercialDocument],
  });
  return async (data: CommercialType) => {
    await updateDateInfo({
      variables: {
        deal_id,
        update_object: {
          base_net_rent: data.baseRent ?? null,
          gross_rent: data.grossRent ?? null,
          outgoings_psm: data.outgoings ?? null,
          outgoings_state: data.outgoingsToggle,
          promotion_amt: data.promotionLevy ?? null,
          promotion_levy_state: data.promotionLevyToggle,
          promotion_levy_type: data.promotionLevyType.label,
          turnover_based_rent_percent: data.turnOverRent ?? null,
          turn_over_rent_unnatural: data.turnOverRentUnnatural ?? null,
          turnover_rent_state: data.turnoverRentToggle,
          annual_increase: data.annualIncrease ?? null,
          annual_increase_type: data.annualIncreaseType.label,
          fitout_contribution: data.fitoutContribution ?? null,
          cash_incentive: data.cashIncentive ?? null,
          capped_occupancy_costs_state: data.cappedOccupancyCostsStateToggle,
        },
      },
    });
  };
};
