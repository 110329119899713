import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  InlineDefinitionList,
  InlineDLDefinition,
  InlineDLTerm,
} from "./InlineDefinitionList";

// FIXME: Should we convert to a monorepo so we can share types between backend and frontend?
export type Error = {
  message: string;
  name?: string;
  stack?: string;
  properties?: {
    id?: string;
    explanation?: string;
    xtag?: string;
    offset?: number;
    file?: string;
    locationWord?: number;
    rootError?: Error;
    errors?: Error[];
    stack?: string[];
  };
};

interface ErrorDetailsDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errors: Error[];
}

export const ErrorDetailsDialog = (props: ErrorDetailsDialogProps) => (
  <Dialog open={props.open} onClose={() => props.setOpen(false)}>
    <DialogTitle>Error Details</DialogTitle>
    <DialogContent>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {props.errors.map((error, i) => (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={error.message}
                secondary={
                  <>
                    {error.properties?.explanation}
                    {(error.properties?.id ||
                      error.properties?.locationWord ||
                      error.properties?.offset) && (
                      <InlineDefinitionList>
                        {error.properties?.id && (
                          <>
                            <InlineDLTerm>ErrorID: </InlineDLTerm>
                            <InlineDLDefinition>
                              <Link
                                href={`https://docxtemplater.com/docs/errors/#${error.properties?.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {error.properties?.id}
                              </Link>
                            </InlineDLDefinition>
                          </>
                        )}
                        {error.properties?.locationWord && (
                          <>
                            <InlineDLTerm>Location: </InlineDLTerm>
                            <InlineDLDefinition>
                              {error.properties?.locationWord}
                            </InlineDLDefinition>
                          </>
                        )}
                        {error.properties?.offset && (
                          <>
                            <InlineDLTerm>Offset: </InlineDLTerm>
                            <InlineDLDefinition>
                              {error.properties?.offset}
                            </InlineDLDefinition>
                          </>
                        )}
                      </InlineDefinitionList>
                    )}
                  </>
                }
              />
            </ListItem>
            {i < props.errors.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
      <DialogActions>
        <Button variant="text" onClick={() => props.setOpen(false)}>
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
