import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import theme from "../../../../themes/theme";
import { Button } from "../../../Button";
import { BLACK_ALPHA_60 } from "../../../../utils/CommonVariables";
import { useDownloadBlob } from "../../../../adapters/Deal/Files";

interface DocumentCardProps {
  documentName: string;
  documentPath: string;
  createdAt: string;
  disabled: boolean;
}

export const DocumentCard = (props: DocumentCardProps) => {
  const downloadFile = useDownloadBlob();
  const downloadOnClick = async () =>
    downloadFile(props.documentPath, props.documentPath);

  return (
    <Card
      sx={{
        pt: 2,
        pl: 2,
        pr: 6,
        pb: 0,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <CardHeader
        avatar={<ArticleIcon />}
        title={props.documentName}
        titleTypographyProps={{
          variant: "subtitle1",
          color: theme.palette.text.primary,
        }}
      />
      <CardContent>
        <Typography variant="body2" color={BLACK_ALPHA_60}>
          Generated at {props.createdAt}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          disabled={props.disabled}
          variant="text"
          sx={{ p: 1 }}
          onClick={downloadOnClick}
        >
          Download
        </Button>
      </CardActions>
    </Card>
  );
};
