import * as yup from "yup";

export interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const TemplateUploadDialogSchema = yup.object({
  generateToName: yup.string().required(),
  information: yup.string(),
  fileName: yup.string(),
  convertToPdf: yup.boolean(),
  hidden: yup.boolean(),
});

export type TemplateUploadDialogType = yup.InferType<
  typeof TemplateUploadDialogSchema
>;

export type BlobVersions = { id: string; date: Date; name: string }[];
