import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { InputLabel, TextField, Grid, Typography } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { useQuery } from "@apollo/client";

import { GetAllContactsDocument } from "../../../generated/graphql-operations";
import {
  IndividualTenantType,
  defaultIndividual,
} from "./TenantInformationConfig";
import { useGetStateData } from "../../../utils/CommonQueries";
import { Form, FormField } from "../../Form/Form";
import { PhoneNumberFormatter } from "../../MuiCustomNumberFormat";
import { DealInformationType } from "../DealInformationConfig";

const ADD_NEW_TENANT_FIELD = {
  ...defaultIndividual,
  tenant: { label: "Add new tenant", value: -1 },
};
export interface TenantFormProps {
  form: UseFormReturn<DealInformationType, any>;
  index: number;
}

const filterOptions = createFilterOptions({
  limit: 10,
  stringify: ({
    id,
    email,
    phone,
    firstName,
    middleName,
    lastName,
    address,
    city,
    state,
    postCode,
  }: IndividualTenantType) =>
    `${id} ${email} ${phone} ${firstName} ${middleName} ${lastName} ${address} ${city} ${state} ${postCode}`,
});

export const IndividualTenantForm = (props: TenantFormProps) => {
  const allExistingTenantsQuery = useQuery(GetAllContactsDocument);
  const states = useGetStateData(1);

  const allExistingTenants: IndividualTenantType[] =
    allExistingTenantsQuery.data?.Contacts.map((contact) => {
      const tenant: IndividualTenantType = {
        tenant: {
          label: `${contact?.first_name} ${contact?.middle_name ?? ""} ${
            contact?.last_name
          }`,
          value: contact?.id ?? -1,
        },
        label: `${contact?.first_name} ${contact?.middle_name ?? ""} ${
          contact?.last_name
        }`,
        id: contact?.id ?? -1,
        email: contact?.email ?? "",
        phone: contact?.phone ?? "",
        firstName: contact?.first_name ?? "",
        middleName: contact?.middle_name ?? "",
        lastName: contact?.last_name ?? "",
        address: contact?.Location?.street1 ?? "",
        city: contact?.Location?.suburb ?? "",
        state: {
          label: contact?.Location?.State?.name ?? "",
          value: contact?.Location?.State?.id ?? 1,
        },
        postCode: contact?.Location?.postcode?.toString() ?? "",
        addressId: contact?.address_id ?? -1,
      };

      return tenant;
    }).sort((a: IndividualTenantType, b: IndividualTenantType) =>
      a.firstName.localeCompare(b.firstName)
    ) ?? [];

  const formFields: FormField[] = [
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.firstName` as const,
      label: "First name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.middleName` as const,
      label: "Middle name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.lastName` as const,
      label: "Last name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.email` as const,
      label: "Email",
      helperText: "Mandatory",
      columns: 6,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.phone` as const,
      label: "Phone number",
      helperText: "Mandatory",
      columns: 6,
      customComp: PhoneNumberFormatter,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.address` as const,
      label: "Address",
      helperText: "",
      columns: 12,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.city` as const,
      label: "City/Suburb",
      helperText: "",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "select",
      fieldName: `tenantInfo.individuals.${props.index}.state` as const,
      label: "State",
      helperText: "",
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.individuals.${props.index}.postCode` as const,
      label: "Postcode",
      helperText: "",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.individuals.${props.index}.tenant.value` as const
        ) !== -1,
    },
  ];

  return (
    <>
      <Grid item xs={12} p={1}>
        <InputLabel>
          Search by name, email, phone or legal entity (or add new tenant)
        </InputLabel>
        <Controller
          defaultValue={{}}
          control={props.form.control}
          name={`tenantInfo.individuals.${props.index}.tenant.label` as const}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disableClearable
              id={`tenant-${props.index}`}
              options={[ADD_NEW_TENANT_FIELD, ...allExistingTenants] ?? []}
              filterOptions={filterOptions}
              getOptionLabel={(value) => value.tenant?.label}
              filterSelectedOptions
              renderOption={(renderProps, option) => {
                return (
                  <li {...renderProps} key={option.id}>
                    {option.tenant?.label}
                  </li>
                );
              }}
              value={props.form.watch(`tenantInfo.individuals.${props.index}`)}
              onChange={(e, value: IndividualTenantType | null) => {
                field.onChange();

                if (!value) {
                  return;
                }

                props.form.setValue(
                  `tenantInfo.individuals.${props.index}`,
                  value,
                  {
                    shouldValidate: true,
                  }
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  placeholder="Search..."
                  variant="filled"
                  error={
                    props.form.formState.errors.tenantInfo?.individuals?.[
                      props.index
                    ] !== undefined
                  }
                  helperText={
                    props.form.formState.errors.tenantInfo?.individuals?.[
                      props.index
                    ]
                      ? props.form.formState.errors.tenantInfo?.individuals?.[
                          props.index
                        ]?.message?.toString()
                      : "Mandatory"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      {props.form.watch(
        `tenantInfo.individuals.${props.index}.tenant.value `
      ) !== 0 &&
      props.form.watch(`tenantInfo.individuals.${props.index}.tenant.label`) !==
        "" ? (
        <>
          <Typography variant="subtitle2" pl={1} pt={1}>
            Tenant contact information
          </Typography>
          <Form form={props.form} fields={formFields} />
        </>
      ) : null}
    </>
  );
};
