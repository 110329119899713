import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
  Card,
  IconButton,
  Chip,
  Stack,
  Grid,
  Alert,
} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import { useMutation } from "@apollo/client";
import {
  UpdateDealActionItemDocument,
  InsertDealActionItemDocument,
  UpdateDealPhaseDocument,
  GetDealDocument,
  DealStatus_Enum,
  UpdateDealStatusDocument,
} from "../../../generated/graphql-operations";

import theme from "../../../themes/theme";

import {
  DealPhase,
  ActionItem,
  validateActionList,
  DealPushValidationProps,
} from "../PushDealDialogServices";
import {
  BLACK_ALPHA_60,
  DEAL_APPROVAL_PHASE_NAME,
  DealPhaseStatus,
  TENANCY_DELIVERY_PHASE_NAME,
} from "../../../utils/CommonVariables";
import { Button } from "../../Button";
import { AllocateTaskDialog } from "./AllocateTaskDialog";
import { apolloClient } from "../../../contexts/ApolloConfig";
import { Role, useReturnUserRole } from "../../../contexts/Auth/UserRole";

const ActionListCard = (props: { children: React.ReactNode }) => (
  <Card
    elevation={10}
    sx={{
      p: 2,
      backgroundColor: theme.palette.secondary.main,
      minHeight: 320,
      maxWHeight: 300,
    }}
  >
    {props.children}
  </Card>
);

export interface ActionListProps extends DealPhase {
  dealId: number;
  normalPhases?: DealPhase[];
  tenancyPhases?: DealPhase[];
  setCurrentPhase: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCurrentTenancyPhase: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  dealDisabled: boolean;
}

const handleTenancyComplete = async (
  currentPhaseId: number,
  normalPhases: DealPhase[],
  tenancyPhases: DealPhase[],
  updateDealPhaseStatus: (
    id: number,
    status_id: DealPhaseStatus
  ) => Promise<void>
) => {
  // Last tenancy Phase, complete Phase
  const lastIndex = (tenancyPhases?.length ?? -1) - 1;
  const currentIndex =
    tenancyPhases?.findIndex((item) => item.id === currentPhaseId) ?? -1;
  if (currentIndex === lastIndex) {
    // Get/Check TenancyPhase exists
    const tenancyPhaseIndex =
      normalPhases?.findIndex(
        (item) => item.name === TENANCY_DELIVERY_PHASE_NAME
      ) ?? -1;
    if (normalPhases?.[tenancyPhaseIndex] === undefined) {
      return { tenancy: undefined, normal: undefined };
    }
    // Update
    await updateDealPhaseStatus(
      normalPhases[tenancyPhaseIndex].id,
      DealPhaseStatus.Complete
    );

    const nextPhase = normalPhases?.[tenancyPhaseIndex + 1];
    if (nextPhase?.statusID === DealPhaseStatus.Inactive) {
      await updateDealPhaseStatus(nextPhase.id ?? -1, DealPhaseStatus.Active);
    }

    return { tenancy: undefined, normal: nextPhase?.id };
  }

  const nextPhase = tenancyPhases?.[currentIndex + 1];

  if (nextPhase?.statusID === DealPhaseStatus.Inactive) {
    await updateDealPhaseStatus(nextPhase.id ?? -1, DealPhaseStatus.Active);
  }

  return { tenancy: nextPhase?.id, normal: undefined };
};

export const ActionList = (props: ActionListProps) => {
  const [newItem, setNewItem] = React.useState("");
  const [newItemRequired, setNewItemRequired] = React.useState(false);
  const [allocateOpen, setAllocateOpen] = useState(false);
  const allowedTDPhases = ["Lease", "Registration"];

  const [validation, setValidation] = useState<DealPushValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [updateActionItem] = useMutation(UpdateDealActionItemDocument);
  const [insertActionItem] = useMutation(InsertDealActionItemDocument);
  const [UpdateDealPhaseStatus] = useMutation(UpdateDealPhaseDocument);
  const [updateDealStatus] = useMutation(UpdateDealStatusDocument);
  const userRole = useReturnUserRole();

  const canPushPhase = (phase: ActionListProps) => {
    if (userRole === Role.TdAssignee) {
      return (
        phase.isTenancyDelivery || allowedTDPhases.includes(phase.name ?? "")
      );
    }

    return true;
  };

  const SimpleUpdatePhaseStatus = async (
    id: number,
    status_id: DealPhaseStatus
  ) => {
    await UpdateDealPhaseStatus({
      variables: {
        id,
        status_id: status_id as number,
      },
    });
  };

  if (props.id === undefined) {
    return (
      <ActionListCard>
        <Typography variant="subtitle1">No Phase selected</Typography>
      </ActionListCard>
    );
  }

  const disabled = props.dealDisabled;

  const handleUpdate = async (item: ActionItem) => {
    const newItemState = !item.state;
    await updateActionItem({
      variables: {
        id: item.id,
        data: {
          content: JSON.stringify({ text: item.text }),
          required: item.required,
          state: newItemState,
        },
      },
      refetchQueries: [GetDealDocument],
    });
  };

  const handleAddNewItem = async () => {
    if (
      newItem === "" ||
      // Check no duplicates
      props.actionItems.some((item) => item.text === newItem)
    ) {
      setNewItem("");
      setNewItemRequired(false);
      return;
    }
    await insertActionItem({
      variables: {
        object: {
          deal_phase_id: props.id,
          state: false,
          required: newItemRequired,
          content: JSON.stringify({
            text: newItem,
          }),
        },
      },
      refetchQueries: [GetDealDocument],
    });
    setNewItem("");
    setNewItemRequired(false);
  };

  const numberOfItems = props.actionItems?.length;
  const numberOfItemsComplete = props.actionItems?.filter(
    (item) => item.state
  )?.length;

  // Handles tenancy phase activation
  const handleActivate = !props.isTenancyDelivery
    ? async () => {}
    : async () => {
        await SimpleUpdatePhaseStatus(props.id, DealPhaseStatus.Active);
        const tenancyPhase = props.normalPhases?.find(
          (item) => item.name === TENANCY_DELIVERY_PHASE_NAME
        );

        if (
          tenancyPhase &&
          [DealPhaseStatus.Inactive, DealPhaseStatus.Complete].includes(
            tenancyPhase?.statusID ?? -1
          )
        ) {
          await SimpleUpdatePhaseStatus(
            tenancyPhase?.id,
            DealPhaseStatus.Active
          );
        }
        await apolloClient.refetchQueries({ include: [GetDealDocument] });
      };

  // Handles tenancy phase when making it inactive
  const handleDeActivate = !props.isTenancyDelivery
    ? async () => {}
    : async () => {
        await SimpleUpdatePhaseStatus(props.id, DealPhaseStatus.Inactive);
        const tenancyPhase = props.normalPhases?.find(
          (item) => item.name === TENANCY_DELIVERY_PHASE_NAME
        );

        if (
          tenancyPhase &&
          [DealPhaseStatus.Complete].includes(tenancyPhase?.statusID ?? -1)
        ) {
          await SimpleUpdatePhaseStatus(
            tenancyPhase?.id,
            DealPhaseStatus.Active
          );
        }
        await apolloClient.refetchQueries({ include: [GetDealDocument] });
      };

  const handleComplete = async () => {
    const isValid = validateActionList(props.actionItems);

    if (!isValid) {
      setValidation({
        show: true,
        severity: "error",
        message: `${props.name} cannot be set to Complete because it has unfinished required actions.`,
      });
      return;
    }

    await SimpleUpdatePhaseStatus(props.id, DealPhaseStatus.Complete);

    if (props.isTenancyDelivery) {
      const PhaseIdState = await handleTenancyComplete(
        props.id,
        props.normalPhases ?? [],
        props.tenancyPhases ?? [],
        SimpleUpdatePhaseStatus
      );
      await apolloClient.refetchQueries({ include: [GetDealDocument] });
      if (PhaseIdState.tenancy) {
        props.setCurrentTenancyPhase(PhaseIdState.tenancy);
      } else if (PhaseIdState.normal) {
        props.setCurrentPhase(PhaseIdState.normal);
      }
      setValidation((prev) => ({ ...prev, show: false }));

      return;
    }

    const currentIndex =
      props.normalPhases?.findIndex((item) => item.id === props.id) ?? -1;

    const lastIndex = (props.normalPhases?.length ?? -1) - 1;

    if (currentIndex === lastIndex) {
      await apolloClient.refetchQueries({ include: [GetDealDocument] });
      setValidation((prev) => ({ ...prev, show: false }));
      // Change status to completed on last phase
      await updateDealStatus({
        variables: { dealId: props.dealId, status: DealStatus_Enum.Completed },
        refetchQueries: [GetDealDocument],
      });
      return;
    }

    // Update next Phase
    const nextPhase = props.normalPhases?.[currentIndex + 1];
    if (nextPhase?.statusID === DealPhaseStatus.Inactive) {
      await SimpleUpdatePhaseStatus(
        nextPhase?.id ?? -1,
        DealPhaseStatus.Active
      );
    }

    // If next phase is Deal Approval, activate Tenancy Delivery phase concurrently
    if (nextPhase?.name === DEAL_APPROVAL_PHASE_NAME) {
      const tdPhase = props.normalPhases?.[currentIndex + 2];

      if (tdPhase?.statusID === DealPhaseStatus.Inactive) {
        await SimpleUpdatePhaseStatus(
          tdPhase?.id ?? -1,
          DealPhaseStatus.Active
        );
      }

      const tdFirstPhase = props.tenancyPhases?.[0];

      // If first TD phase is inactive, activate it and set as current TD phase
      if (tdFirstPhase?.statusID === DealPhaseStatus.Inactive) {
        await SimpleUpdatePhaseStatus(
          tdFirstPhase?.id ?? -1,
          DealPhaseStatus.Active
        );

        props.setCurrentTenancyPhase(tdFirstPhase?.id);
      }
      // Else if first TD phase is active or complete, set the first active TD phase or last complete phase as current TD phase
      else {
        const tdFirstActivePhase = props.tenancyPhases?.find(
          (item) => item?.statusID === DealPhaseStatus.Active
        );

        if (tdFirstActivePhase !== undefined) {
          props.setCurrentTenancyPhase(tdFirstActivePhase?.id);
        } else {
          const tdLastCompletePhase = props.tenancyPhases
            ?.reverse()
            ?.find((item) => item?.statusID === DealPhaseStatus.Complete)?.id;

          props.setCurrentTenancyPhase(tdLastCompletePhase);
        }
      }
    }

    props.setCurrentPhase(nextPhase?.id);
    await apolloClient.refetchQueries({ include: [GetDealDocument] });
    setValidation((prev) => ({ ...prev, show: false }));
  };

  return (
    <ActionListCard>
      <Grid sx={{ overflow: "auto", maxHeight: 235 }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="subtitle1">{props.name} checklist</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
              {numberOfItemsComplete} of {numberOfItems}
            </Typography>
          </Grid>
        </Grid>
        {props.id !== undefined ? (
          <>
            <FormGroup sx={{ p: 1, maxHeight: "100%", overflow: "auto" }}>
              {props.actionItems.map((item) => (
                <React.Fragment key={item.id}>
                  <FormControlLabel
                    aria-label={`item ${item.text}`}
                    disabled={disabled || item.userDisabled}
                    control={
                      <Checkbox
                        defaultChecked={item.state}
                        onChange={() => {
                          handleUpdate(item);
                        }}
                      />
                    }
                    label={
                      item.required ? (
                        <span>
                          {item.text}
                          <Typography
                            color="red"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </Typography>
                        </span>
                      ) : (
                        item.text
                      )
                    }
                  />
                  <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    {item.assigned_to.map((assignee) => (
                      <Chip label={assignee} size="small" key={assignee} />
                    ))}
                  </Stack>
                </React.Fragment>
              ))}
            </FormGroup>

            {/* Add new item label */}
            <Stack direction="row">
              <IconButton onClick={handleAddNewItem} disabled={disabled}>
                <AddBoxOutlinedIcon sx={{ ml: -0.15 }} color="action" />
              </IconButton>
              <TextField
                size="small"
                variant="standard"
                placeholder="Add new"
                value={newItem}
                disabled={disabled}
                onChange={(event) => {
                  setNewItem((event.target as HTMLInputElement).value);
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    await handleAddNewItem();
                  }
                }}
              />
              <IconButton onClick={() => setNewItemRequired((prev) => !prev)}>
                <Typography color={newItemRequired ? "red" : "grey"}>
                  *
                </Typography>
              </IconButton>
            </Stack>
          </>
        ) : null}
      </Grid>
      {props.statusID === DealPhaseStatus.Complete &&
        props.isTenancyDelivery &&
        !disabled &&
        canPushPhase(props) && (
          <Grid container rowSpacing={0.5} columnSpacing={0.5} sx={{ mt: 1.5 }}>
            <Grid item xs={4} sm="auto">
              <Button
                variant="contained"
                onClick={handleActivate}
                color="error"
              >
                Reactivate phase
              </Button>
            </Grid>
          </Grid>
        )}
      {props.statusID === DealPhaseStatus.Inactive &&
        props.isTenancyDelivery &&
        !disabled &&
        canPushPhase(props) && (
          <Grid container rowSpacing={0.5} columnSpacing={0.5} sx={{ mt: 1.5 }}>
            <Grid item xs={4} sm="auto">
              <Button variant="contained" onClick={handleActivate}>
                Activate phase
              </Button>
            </Grid>
          </Grid>
        )}
      {props.statusID === DealPhaseStatus.Active &&
        !disabled &&
        canPushPhase(props) && (
          <Grid container rowSpacing={0.5} columnSpacing={0.5} sx={{ mt: 1.5 }}>
            <Grid item xs={4} sm="auto">
              <Button variant="contained" onClick={handleComplete}>
                Complete phase
              </Button>
            </Grid>
            <Grid item xs={4} sm="auto">
              <Button
                variant="outlined"
                onClick={() => {
                  setAllocateOpen(true);
                }}
              >
                Allocate task
              </Button>
              <AllocateTaskDialog
                open={allocateOpen}
                setOpen={setAllocateOpen}
                actionItems={props.actionItems}
                dealId={props.dealId}
              />
            </Grid>
            {props.isTenancyDelivery && (
              <Grid item xs={4} sm="auto">
                <Button
                  variant="outlined"
                  onClick={handleDeActivate}
                  sx={{ color: "GrayText", borderColor: "GrayText" }}
                >
                  Deactivate phase
                </Button>
              </Grid>
            )}

            {validation.show && (
              <Alert
                sx={{ mt: 2 }}
                severity={validation.severity}
                onClose={() => {
                  setValidation((prev) => ({ ...prev, show: false }));
                }}
              >
                {validation.message}
              </Alert>
            )}
          </Grid>
        )}
    </ActionListCard>
  );
};
