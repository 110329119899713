import { useQuery, useMutation } from "@apollo/client";
import { ChargesType } from "../../../components/DealInformation/Charges/ChargesConfig";
import {
  GetChargesInformationDocument,
  GetChargesInformationQuery,
  UpdateChargesInformationDocument,
  GetDealDataIdDocument,
  CreateDealExtensionItemsDocument,
  DeleteDealCustomChargesDocument,
} from "../../../generated/graphql-operations";

export const useGetDealInformationChargesInformation = (dealId: number) => {
  const chargesInformation = useQuery(GetChargesInformationDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return chargesInformation;
};

export const transformData = (
  data: GetChargesInformationQuery
): ChargesType => {
  return {
    tenancyCoordinationFee:
      data?.DealData?.[0]?.tenancy_coordination_fee ?? undefined,
    tenantContributionLandlordWorks:
      data?.DealData?.[0]?.tenant_contribution_landlord_works ?? undefined,
    recoverySurveyFee: data?.DealData?.[0]?.recovery_survey_fee ?? undefined,
    openingPromotionLevyFees:
      data?.DealData?.[0]?.opening_promotion_levy_fees ?? undefined,
    developmentFees: data?.DealData?.[0]?.development_fees ?? undefined,
    rubbishRemovalFees: data?.DealData?.[0]?.rubbish_removal_fees ?? undefined,
    otherCharges: data?.DealData?.[0]?.DealExtensionItems.filter(
      (item) => item.tag === "charge"
    ).map((charge) => {
      return {
        description: JSON.parse(charge?.item_data ?? "{}").description ?? "",
        amount: JSON.parse(charge?.item_data ?? "{}").amount ?? undefined,
      };
    }),
  };
};

export const useMutateCharges = (dealId: number) => {
  const [UpdateChargesInformation] = useMutation(
    UpdateChargesInformationDocument
  );
  const GetDealDataId = useQuery(GetDealDataIdDocument, {
    variables: {
      deal_id: dealId,
    },
  });
  const [CreateDealExtensionItems] = useMutation(
    CreateDealExtensionItemsDocument
  );
  const [DeleteDealCustomCharges] = useMutation(
    DeleteDealCustomChargesDocument
  );

  const createCustomCharges = (data: ChargesType) => {
    Promise.all(
      data.otherCharges.map(async (charge) => {
        const otherCharge = {
          deal_data_id: GetDealDataId?.data?.DealData?.[0]?.id,
          item_data: JSON.stringify({
            description: charge.description,
            amount: charge.amount,
          }),
          tag: "charge",
        };

        await CreateDealExtensionItems({
          variables: {
            data: otherCharge,
          },
        });
      })
    );
  };

  return async (data: ChargesType) => {
    const transformedData = {
      tenancy_coordination_fee: data.tenancyCoordinationFee,
      tenant_contribution_landlord_works: data.tenantContributionLandlordWorks,
      recovery_survey_fee: data.recoverySurveyFee,
      opening_promotion_levy_fees: data.openingPromotionLevyFees,
      development_fees: data.developmentFees,
      rubbish_removal_fees: data.rubbishRemovalFees,
    };

    await UpdateChargesInformation({
      variables: {
        deal_id: dealId,
        data: transformedData,
      },
    });

    await DeleteDealCustomCharges({
      variables: {
        deal_data_id: GetDealDataId?.data?.DealData?.[0]?.id,
      },
    });

    await createCustomCharges(data);
  };
};
