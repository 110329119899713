import * as yup from "yup";

export const SpecialConditionSchema = yup.object({
  specialCondition: yup
    .object({ label: yup.string(), value: yup.number().required() })
    .required(),
  description: yup.string(),
  descriptionLaf: yup.string(),
});

export const SpecialConditionsSchema = yup.object({
  conditions: yup.array(SpecialConditionSchema),
});

export type SpecialConditionType = yup.InferType<typeof SpecialConditionSchema>;
export type SpecialConditionsType = yup.InferType<
  typeof SpecialConditionsSchema
>;

export const NO_CONDITION_USE = { label: "Other", value: -1 };
