import React from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ActiveLeaseDealsList } from "./ActiveLeaseDealList";
import { BreadCrumbs } from "../../components";

export const ActiveLease = () => {
  const params = useParams();
  const tenantCode = params.tenantCode ?? "";

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Active lease</Typography>
        </Stack>
      </Grid>
      <Grid item sm={12} mb={2}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Active leases", url: "/active-leases/" },
            { text: `${tenantCode}`, url: "" },
          ]}
        />
      </Grid>

      <Box sx={{ height: 800, width: 1, mt: 2, mb: 10 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Deals
        </Typography>
        <ActiveLeaseDealsList toolBar tenantCode={tenantCode} />
      </Box>
    </Container>
  );
};
