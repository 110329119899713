import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Alert, Box, Button } from "@mui/material";
import { Form, FormField } from "../../components/Form/Form";
import {
  FormSwitch,
  TextMultiField,
} from "../../components/Form/FormComponents";
import {
  GetLandlordFacilitiesDocument,
  GetPropertyInformationDocument,
  UpsertPropertiesInformationDocument,
} from "../../generated/graphql-operations";

const fields: FormField[] = [
  {
    type: "text",
    fieldName: "tenancyCleaning",
    label: "Tenancy cleaning",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "commonAreaCleaning",
    label: "Common area cleaning",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "commonAreaLighting",
    label: "Common area lighting",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "securityServices",
    label: "Security services",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "binRooms",
    label: "Bin rooms",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "childMinding",
    label: "Child minding",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "staffToiler",
    label: "Staff toilet",
    columns: 4,
    helperText: "",
    disabled: true,
  },
];

const fieldsB: FormField[] = [
  {
    type: "text",
    fieldName: "noOfShops",
    label: "No. of shops",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "noOfShopsOccupied",
    label: "No. of shops occupied",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "noOfShopsVacant",
    label: "No. of shops vacant",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "stdTenantContrToTC",
    label: "Std tenantContr to TC fee p.t",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "stdTenantContrToSurvey",
    label: "Std tenantContr to survey fee p.t",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "embeddedNetwork",
    label: "Embedded network",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "siteArea",
    label: "Site area",
    columns: 4,
    helperText: "",
    disabled: true,
  },
];

const computeServicesAndFacilities = (
  data: Record<string, unknown>
): string => {
  const servicesAndFacilities = {
    services_tenancy_clean: "Tenancy cleaning; ",
    services_common_clean: "Common area cleaning; ",
    services_common_light: "Common area lighting; ",
    services_security: "Common area security; ",
    services_bin_room: "Bin rooms; ",
    exp_child: "Child minding; ",
    exp_staff_toilet: "Staff toilet; ",
    exp_other: "",
  };
  const availableServicesAndFacilities: (string | unknown)[] = [];
  Object.entries(servicesAndFacilities).forEach(([key, yesString]) => {
    availableServicesAndFacilities.push(
      // eslint-disable-next-line no-nested-ternary
      !data[key] || data[key] === "No"
        ? ""
        : key !== "exp_other"
        ? yesString
        : data[key]
    );
  });
  return availableServicesAndFacilities.join("");
};

export const PropertyLandlordServices = (props: { propertyId: string }) => {
  const propertyLandlordServices = useQuery(GetLandlordFacilitiesDocument, {
    variables: { property_code: props.propertyId },
  });

  const propertyInformation = useQuery(GetPropertyInformationDocument, {
    variables: { property_code: props.propertyId },
  });

  const [upsertPropertyInformation] = useMutation(
    UpsertPropertiesInformationDocument
  );

  const [otherDisabled, setOtherDisabled] = useState(true);
  const [impactDisabled, setImpactDisabled] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const form = useForm();

  const descriptionField = {
    type: "text",
    fieldName: "description",
    label: "Description",
    columns: 12,
    helperText: "",
    disabled: otherDisabled,
  };

  const centreFacilitiesField = {
    fieldName: "centreFacilities",
    label: "Centre facilities",
    columns: 4,
    helperText: "",
    disabled: true,
  };

  const impactField = {
    type: "text",
    fieldName: "marketAndCompetitorImpact",
    label: "Market and competitor impact",
    columns: 12,
    helperText: "",
    disabled: impactDisabled,
  };

  useEffect(() => {
    form.reset({
      tenancyCleaning:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.services_tenancy_clean,
      commonAreaCleaning:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.services_common_clean,
      commonAreaLighting:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.services_common_light,
      securityServices:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.services_security,
      binRooms:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.services_bin_room,
      childMinding:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]?.exp_child,
      staffToiler:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.exp_staff_toilet,

      centreFacilities: computeServicesAndFacilities(
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0] ?? {}
      ),

      noOfShops:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]?.num_shop,
      noOfShopsOccupied:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.shops_leased_and_occupied,
      noOfShopsVacant:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.shops_leased_and_unoccupied,
      stdTenantContrToTC:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.tenancy_coord_fee_per_trans,
      stdTenantContrToSurvey:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.tenancy_coord_fee_per_trans,
      siteArea:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]?.site_area?.toString(),

      otherToggle:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.exp_other !== null &&
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]
          ?.exp_other !== undefined &&
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]?.exp_other
          .length > 0,

      description:
        propertyLandlordServices?.data?.YardiPropertyProfiles?.[0]?.exp_other,

      // Editable fields

      embeddedNetworkToggle:
        propertyInformation?.data?.PropertiesInformation?.[0]
          ?.embedded_network_toggle === "1",

      marketAndCompetitorImpact:
        propertyInformation?.data?.PropertiesInformation?.[0]?.market_impact ??
        "",
    });

    setOtherDisabled(
      propertyInformation?.data?.PropertiesInformation?.[0]?.other_toggle !==
        "1"
    );
    setImpactDisabled(
      propertyInformation?.data?.PropertiesInformation?.[0]
        ?.embedded_network_toggle !== "1"
    );
  }, [
    form,
    propertyLandlordServices?.data?.YardiPropertyProfiles,
    propertyInformation?.data?.PropertiesInformation,
  ]);

  return (
    <>
      <Form form={form} fields={fields} />

      <FormSwitch
        name="otherToggle"
        title="Other"
        control={form.control}
        disabled
      />

      <TextMultiField control={form.control} formField={descriptionField} />

      <Box sx={{ m: 2 }} />

      <TextMultiField
        control={form.control}
        formField={centreFacilitiesField}
      />

      <Form form={form} fields={fieldsB} />

      <FormSwitch
        name="embeddedNetworkToggle"
        title="Embedded network"
        control={form.control}
        onChange={(e) => {
          const { checked } = e.target as HTMLInputElement;
          setImpactDisabled(!checked);
          if (!checked) {
            form.setValue("marketAndCompetitorImpact", "");
          }
        }}
      />
      <TextMultiField control={form.control} formField={impactField} />
      <Button
        variant="contained"
        sx={{ mt: 2 }}
        onClick={async () =>
          form.handleSubmit(async (data) => {
            await upsertPropertyInformation({
              variables: {
                object: {
                  property_code: props.propertyId,
                  embedded_network_toggle: data.embeddedNetworkToggle
                    ? "1"
                    : "0",
                  market_impact: data.marketAndCompetitorImpact,
                },
              },
              refetchQueries: [
                GetLandlordFacilitiesDocument,
                GetPropertyInformationDocument,
              ],
            });
            setShowConfirmation(true);
          })()
        }
      >
        Save
      </Button>

      {showConfirmation && (
        <Alert
          severity="success"
          onClose={() => setShowConfirmation(false)}
          sx={{ mt: 2 }}
        >
          Saved successfully
        </Alert>
      )}
    </>
  );
};
