import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Skeleton } from "@mui/material";
import { GetUsersDocument } from "../../generated/graphql-operations";
import { CollapseCard } from "../CollapseCard";
import { IformFieldChoice } from "../Form/Form";
import { Approver } from "./Approver";
import {
  ApproverSettingsSchema,
  ApproverType,
  defaultApproverSettings,
} from "./Config";
import { Button } from "../Button";
import {
  transformData,
  useGetApprovers,
  useUpdateApproverSettings,
} from "../../adapters/Approvals/ApprovalSettings";
import { SubmitAlert } from "../SubmitAlert";

export const ApproverSettings = ({ forceOpen }: { forceOpen: boolean }) => {
  const updateApproverSettings = useUpdateApproverSettings();
  const [submitState, setSubmitState] = React.useState<boolean | undefined>();
  const form = useForm({
    resolver: yupResolver(ApproverSettingsSchema),
    mode: "onChange",
    defaultValues: defaultApproverSettings,
  });

  const users = useQuery(GetUsersDocument);
  const approvers = useGetApprovers();

  useEffect(() => {
    if (approvers.data && approvers.data.Approvers.length > 0) {
      const transformedData = transformData(approvers.data);
      form.reset(transformedData);
    }
  }, [form, approvers.data]);

  if (!approvers.data) {
    return <Skeleton />;
  }

  const approverList = [
    { heading: "Second approver", index: 2 },
    { heading: "Third approver", index: 3 },
    { heading: "Fourth approver", index: 4 },
    { heading: "Fifth approver", index: 5 },
  ] as const;

  type ApproverIndexType = typeof approverList[number]["index"];

  return (
    <>
      {approverList.map((elem) => {
        const selectedUsers = form
          .watch(`approver_${elem.index as ApproverIndexType}`)
          ?.flatMap(
            (setting: ApproverType) => (setting?.user?.[0] as any)?.value
          )
          .filter((value) => value !== undefined);

        const availableUsers =
          users.data?.Users?.filter(
            (user) => !selectedUsers?.includes(user.id)
          ).map(
            (availableUser) =>
              ({
                label: availableUser.user_full_name,
                value: availableUser.id,
              } as IformFieldChoice)
          ) ?? [];

        return (
          <CollapseCard
            heading={elem.heading}
            key={elem.index}
            forceOpen={forceOpen}
          >
            <Approver index={elem.index} users={availableUsers} form={form} />
          </CollapseCard>
        );
      })}

      <SubmitAlert
        successSubmit={submitState}
        onClick={() => setSubmitState(undefined)}
      />

      <Button
        sx={{ mt: 5 }}
        variant="contained"
        onClick={async () => {
          await form.handleSubmit(async (data) => {
            try {
              await updateApproverSettings(data);
              setSubmitState(true);
            } catch {
              setSubmitState(false);
            }
          })();
        }}
      >
        {form.formState.isSubmitting ? <CircularProgress /> : "Save"}
      </Button>
    </>
  );
};
