import * as React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { NavBar, AppBar, Body } from "../components/MainAppBar";

interface MainAppBarProps {
  children: React.ReactNode;
}

export const MainAppBar = ({ children }: MainAppBarProps) => {
  const theme = useTheme();
  const defaultClosed = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(defaultClosed);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <NavBar {...{ open, handleDrawerClose }} />
      <Body open={open}>{children}</Body>
    </Box>
  );
};
