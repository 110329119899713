import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking as underlying,
  AppInsightsContext as AIContext,
} from "@microsoft/applicationinsights-react-js";

/*
 * For documentation on how to use this, see:
 * - https://docs.microsoft.com/en-gb/azure/azure-monitor/app/javascript-react-plugin#basic-usage
 * - https://docs.microsoft.com/en-gb/azure/azure-monitor/app/javascript-react-plugin#using-react-hooks
 */

export const reactPlugin = new ReactPlugin();

export function withAITracking<P>(
  component: React.ComponentType<P>,
  componentName?: string,
  className?: string
): React.ComponentType<P> {
  return underlying(reactPlugin, component, componentName, className);
}

/*
 * Configuration options here:
 * - https://docs.microsoft.com/en-gb/azure/azure-monitor/app/javascript?tabs=npm#configuration
 */
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    enableCorsCorrelation: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
export const AppInsightsContext = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <AIContext.Provider value={reactPlugin}>...{children}</AIContext.Provider>
);
