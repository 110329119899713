import React from "react";

export interface InlineDefinitionListProps {
  style?: React.StyleHTMLAttributes<any>;
  width?: string | number;
  children: React.ReactNode;
}

export const InlineDefinitionList = (props: InlineDefinitionListProps) => (
  <dl
    style={{
      display: "flex",
      flexFlow: "row",
      flexWrap: "wrap",
      width: props.width ?? "150px",
      overflow: "visible",
      ...props.style,
    }}
  >
    {props.children}
  </dl>
);

export interface InlineDLProps {
  style?: React.StyleHTMLAttributes<any>;
  children: React.ReactNode;
}

export const InlineDLTerm = (props: InlineDLProps) => (
  <dt
    style={{
      flex: "0 0 50%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: "bold",
      ...props.style,
    }}
  >
    {props.children}
  </dt>
);

export const InlineDLDefinition = (props: InlineDLProps) => (
  <dd
    style={{
      flex: "0 0 50%",
      marginLeft: "auto",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      ...props.style,
    }}
  >
    {props.children}
  </dd>
);
