import * as yup from "yup";

export interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const CreateFileDialogSchema = yup.object({
  fileName: yup.string().required(),
  tags: yup.array().of(yup.string().required()),
});

export type CreateFileDialogType = yup.InferType<typeof CreateFileDialogSchema>;

export type BlobVersions = { id: string; date: Date; name: string }[];
