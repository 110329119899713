import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Box,
  Card,
  Typography,
  Container,
  CircularProgress,
  Alert,
  AlertColor,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { format } from "date-fns";

import {
  allDataLoaded,
  dataStateDefault,
  DealInformationSchema,
  DealInformationType,
  DealInformationProps,
} from "./DealInformationConfig";
import { CollapseCard } from "../CollapseCard";
import { Button } from "../Button";
import { BreadCrumbs } from "../BreadCrumbs";

import { TenantInformation } from "./TenantInformation/TenantInformation";
import { UnitInformation } from "./UnitInformation/UnitInformation";
import { DealType } from "./DealType/DealType";
import { DateInformation } from "./DateInformation/DateInfo";
import { CommercialInfo } from "./Commercial/Commercial";
import { UsageInfo } from "./Usage/Usage";
import { StorageInfo } from "./Storage/Storage";
import { Charges } from "./Charges/Charges";
import { OptionTerm } from "./OptionTerms/OptionTerms";
import { Deposit } from "./Deposit/Deposit";
import {
  useTransformAndSubmitData,
  useCanSaveDeal,
} from "../../adapters/DealInformation/DealInformation";
import { LandlordServices } from "./LandlordServices/LandlordServices";
import { FitoutLandlordWorks } from "./FitoutLandlordWorks/FitoutLandlordWorks";
import { SpecialConditions } from "./SpecialConditions/SpecialConditions";
import { DISABLE_SX_PROPS } from "../../utils/CommonVariables";
import { HistoricalData } from "./HistoricalData/HistoricalData";

const camelToSpaceCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

export const DealInformation = (props: DealInformationProps) => {
  const canSave = useCanSaveDeal(props.dealId);
  const [onSubmit] = useTransformAndSubmitData(props.dealId);
  const form = useForm<DealInformationType>({
    resolver: yupResolver(DealInformationSchema),
    mode: "onBlur",
  });
  const [statusBarOpen, setStatusBarOpen] = React.useState<{
    status: AlertColor;
    message: string;
  } | null>(null);

  const setErrorBar = (errors: string[]) => {
    const message = `Failed to save, errors in: ${errors
      .map((item) => camelToSpaceCase(item))
      .join(", ")}`;
    setStatusBarOpen({ status: "error", message });
  };

  const setSucessBar = () => {
    const message = `Saved ${format(Date.now(), "dd/MM/yyyy HH:mm")}`;
    setStatusBarOpen({ status: "success", message });
  };

  const handleClose = () => {
    setStatusBarOpen(null);
  };

  const [dataState, setDataState] = useState(dataStateDefault);

  const dataLoaded = allDataLoaded(dataState);
  const isDisabled =
    !dataLoaded || form.formState.isSubmitting || !canSave.status;
  let disabledMsg = "";

  if (!canSave.status) {
    disabledMsg = canSave.reason ?? "";
  } else if (form.formState.isSubmitting) {
    disabledMsg = "Can't edit data while saving data.";
  } else if (dataLoaded) {
    disabledMsg =
      "Deal can not be edited as data is still loading, if this persists, please refresh page.";
  }

  const disableSx = isDisabled ? DISABLE_SX_PROPS : {};

  return (
    <Container maxWidth="xl">
      <Grid container p={3}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h6">Deal #{props.dealId}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 3 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Deals", url: "/deals/" },
              { text: `${props.dealId}`, url: `/deals/${props.dealId}` },
              { text: "Deal information", url: "" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {statusBarOpen && (
            <Alert
              onClose={handleClose}
              severity={statusBarOpen.status}
              sx={{ mb: 2 }}
            >
              {statusBarOpen.message}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          {isDisabled && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {disabledMsg}
            </Alert>
          )}
        </Grid>
        <Card elevation={10}>
          <Grid container pt={1} pl={1}>
            <Grid item xs={6}>
              <Typography variant="h6">Deal information</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  sx={{
                    borderRadius: 3,
                    mr: 2,
                  }}
                  href={`/deals/${props.dealId}`}
                >
                  <ArrowBack sx={{ pr: 1 }} />
                  Back to deal summary
                </Button>

                <Button
                  variant="contained"
                  sx={{ borderRadius: 1.5 }}
                  disabled={isDisabled}
                  onClick={() => {
                    if (dataLoaded) {
                      form.handleSubmit(
                        async (data) => {
                          try {
                            await onSubmit(data);
                            setSucessBar();
                          } catch (e: any) {
                            setErrorBar([e?.message]);
                          }
                        },
                        (errors) => setErrorBar(Object.keys(errors))
                      )();
                    }
                  }}
                >
                  {form.formState.isSubmitting ? <CircularProgress /> : "Save"}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box width="100%" />
          <Grid item sm={12} p={0.5} pt={2}>
            <CollapseCard heading="Tenant information">
              <Grid container p={3} sx={disableSx}>
                <TenantInformation
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Unit information">
              <Grid container p={2} sx={disableSx}>
                <UnitInformation
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Deal type">
              <Grid container p={2} sx={disableSx}>
                <DealType
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Commercial terms">
              <Grid container p={2} sx={disableSx}>
                <CommercialInfo
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Dates">
              <Grid container p={2} sx={disableSx}>
                <DateInformation
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Usage">
              <Grid container p={2} sx={disableSx}>
                <UsageInfo
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Storage, seating, signage & parking">
              <Grid container p={3} sx={disableSx}>
                <StorageInfo
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Deposit, guarantees & insurance">
              <Grid container p={3} sx={disableSx}>
                <Deposit
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Fitout & landlord works">
              <Grid container p={3} sx={disableSx}>
                <FitoutLandlordWorks
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Base landlord services">
              <Grid container p={3} sx={disableSx}>
                <LandlordServices
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Special conditions">
              <Grid container p={3} sx={disableSx}>
                <SpecialConditions
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Tenant charges & contributions">
              <Grid container p={3} sx={disableSx}>
                <Charges
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Option terms">
              <Grid container p={3} sx={disableSx}>
                <OptionTerm
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
          <Grid item sm={12} p={0.5}>
            <CollapseCard heading="Current/Previous data">
              <Grid container p={3} sx={disableSx}>
                <HistoricalData
                  dealId={props.dealId}
                  form={form}
                  setDataState={setDataState}
                />
              </Grid>
            </CollapseCard>
          </Grid>
        </Card>
      </Grid>
    </Container>
  );
};
