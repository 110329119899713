import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetDocumentTemplatesDocument,
  GenerateDocumentDocument,
} from "../generated/graphql-operations";
import { Button } from "./Button";
import { FormField, SelectField } from "./Form/Form";

const GenerateDocumentDialogSchema = yup.object({
  template: yup
    .object({
      label: yup.string().required().default(""),
      value: yup.number().required().default(-1),
    })
    .default({ label: "", value: -1 })
    .test(
      "NoTemplate",
      "Must select Template",
      (value) => !(value.value === -1 && value.label === "")
    ),
});

type GenerateDocumentDialogType = yup.InferType<
  typeof GenerateDocumentDialogSchema
>;

interface GenerateDocumentDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealId: number;
}

interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const GenerateDocumentDialog = (props: GenerateDocumentDialogProps) => {
  const form = useForm<GenerateDocumentDialogType>({
    resolver: yupResolver(GenerateDocumentDialogSchema),
  });

  const documentTemplates = useQuery(GetDocumentTemplatesDocument);
  const [generateDocument] = useMutation(GenerateDocumentDocument);

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (templateId: number) => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });

    const document = await generateDocument({
      variables: {
        deal_id: props.dealId,
        template_id: templateId,
      },
    });

    if (document.data) {
      setValidation({
        show: true,
        severity: "success",
        message:
          "Success - your document will appear in the Documents tab shortly",
      });
    } else {
      setValidation({
        show: true,
        severity: "error",
        message:
          "Error - problem generating document. Please refresh and try again",
      });
    }
  };

  const templateSelectField: FormField = {
    type: "select",
    fieldName: "template",
    label: "Templates",
    helperText: "",
    choices:
      documentTemplates?.data?.DocumentTemplates?.map((template) => ({
        label: template.generate_to_name ?? "",
        value: template.id ?? -1,
      })) ?? [],
    columns: 12,
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Generate document</DialogTitle>
      <DialogContent>
        <SelectField form={form} formField={templateSelectField} />

        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => {
              form.handleSubmit(async () => {
                await onSubmit(form.getValues("template.value"));
              })();
            }}
          >
            Generate
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
