import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
} from "@mui/material";

import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";

import { FinalStepDealProps, DealTerm } from "../../DealConfig";

// TODO: change selectedFormChecklistName to a Set by changing the yup schema to a Set
interface FormChecklistProps {
  parentProps: FinalStepDealProps;
  formChecklistName: keyof DealTerm;
  selectedFormChecklistName: keyof DealTerm;
  title: string;
}

export const FormChecklist = (props: FormChecklistProps) => {
  const [newChecklistItem, setNewChecklistItem] = useState("");

  const updateDefaultChecklist = () => {
    if (!newChecklistItem) {
      return;
    }

    props.parentProps.setState((prev) => {
      const currentListValue = prev[props.formChecklistName] as string[];
      if (currentListValue.includes(newChecklistItem)) {
        return prev;
      }

      return {
        ...prev,
        [props.formChecklistName]: [...currentListValue, newChecklistItem],
      };
    });

    setNewChecklistItem("");
  };

  return (
    <>
      <Grid item sm={3} mt={1}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          {props.title}
        </Typography>
      </Grid>

      <Box width="100%" />

      <FormGroup sx={{ p: 1, mb: 1 }}>
        {(props.parentProps.state[props.formChecklistName] as string[]).map(
          (item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={(
                    props.parentProps.state[
                      props.selectedFormChecklistName
                    ] as string[]
                  ).includes(item)}
                  onChange={(data) => {
                    const { checked } = data.target;
                    const newSelectedFormChecklist = new Set(
                      props.parentProps.state[
                        props.selectedFormChecklistName
                      ] as string[]
                    );
                    if (checked) {
                      newSelectedFormChecklist.add(item);
                    } else {
                      newSelectedFormChecklist.delete(item);
                    }

                    props.parentProps.setState((prev) => ({
                      ...prev,
                      [props.selectedFormChecklistName]: Array.from(
                        newSelectedFormChecklist
                      ),
                    }));
                  }}
                />
              }
              label={item}
            />
          )
        )}

        <Grid container>
          <FormControlLabel
            disabled
            label={
              <TextField
                size="small"
                variant="standard"
                placeholder="Add new"
                value={newChecklistItem}
                onChange={(event) => {
                  setNewChecklistItem((event.target as HTMLInputElement).value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    updateDefaultChecklist();
                  }
                }}
              />
            }
            control={<Checkbox />}
            onClick={() => {}}
          />

          <IconButton onClick={updateDefaultChecklist}>
            <AddBoxSharpIcon color="primary" />
          </IconButton>
        </Grid>
      </FormGroup>
    </>
  );
};
