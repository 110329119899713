import { Button } from "@mui/material";
import React from "react";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";

export const CreateDealButton = () => {
  const disable = useReturnUserRole() === Role.ReadOnly;
  return (
    <Button
      disabled={disable}
      color="primary"
      size="medium"
      variant="contained"
      href="/create-deal"
    >
      Create new deal
    </Button>
  );
};
