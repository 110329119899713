import { Control, Controller } from "react-hook-form";
import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";

type HelperTextType =
  | {
      t: string;
      f: string;
    }
  | string;

interface CheckboxFieldProps {
  control: Control<any>;
  fieldName: string;
  label: string;
  helperText?: HelperTextType;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | Promise<void>;
}

const displayHelperText = (
  checked: boolean,
  helperText: HelperTextType
): string => {
  if (typeof helperText === "string") {
    return helperText;
  }
  return checked ? helperText.t : helperText.f;
};

export const CheckboxField = ({
  control,
  fieldName: name,
  label,
  helperText,
  disabled,
  onChange,
}: CheckboxFieldProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={false}
    render={({ field }) => (
      <FormControlLabel
        disabled={disabled}
        key={name}
        sx={{ mt: 0 }}
        label={
          <>
            <Typography sx={{ mt: 1 }}>{label}</Typography>
            {helperText && (
              <FormHelperText sx={{ mt: 0 }}>
                {displayHelperText(field.value, helperText)}
              </FormHelperText>
            )}
          </>
        }
        control={
          <Checkbox
            checked={field.value}
            onChange={(e) => {
              field.onChange(e.target.checked);
              onChange?.(e);
            }}
          />
        }
      />
    )}
  />
);
