import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { FormTextField } from "../../Form/FormComponents";
import {
  CreateCommentBulkDocument,
  DealRiskLevels_Enum,
} from "../../../generated/graphql-operations";
import { useUpdateRiskLevel } from "../../../adapters/Risk/RiskLevel";

interface RiskSelectorDialogProps {
  dealId: number;
  open: boolean;
  initialRisk?: DealRiskLevels_Enum;
  onRiskChange: (newRisk: DealRiskLevels_Enum) => void;
  onClose: () => void;
}

export const RiskSelectorDialog = (props: RiskSelectorDialogProps) => {
  const [isSubmitting, updateDealRiskLevel] = useUpdateRiskLevel();
  const [AddComment] = useMutation(CreateCommentBulkDocument);
  const [commentText, setCommentText] = React.useState("");
  const [errorSubmit, setErrorSubmit] = React.useState(false);
  const [selectedRisk, setSelectedRisk] = React.useState<
    DealRiskLevels_Enum | undefined
  >();
  const [successSubmit, setSuccessSubmit] = React.useState(false);

  useEffect(() => {
    setSelectedRisk(props.initialRisk);
  }, [props.initialRisk]);

  const closeErrorSubmit = () => {
    setErrorSubmit(false);
  };

  const closeSuccessSubmit = () => {
    setSuccessSubmit(false);
  };

  const handleRiskSelected = (riskLevel: DealRiskLevels_Enum) => {
    setSelectedRisk(riskLevel);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };
  const clearCloseDialog = () => {
    props.onClose();
    setCommentText("");
  };

  const onSubmit = async () => {
    try {
      await updateDealRiskLevel(props.dealId, selectedRisk!);

      if (commentText) {
        await AddComment({
          variables: {
            object: {
              deal_id: props.dealId,
              text: commentText,
            },
          },
        });
      }

      setSuccessSubmit(true);
      props.onRiskChange(selectedRisk!);
    } catch {
      setErrorSubmit(true);
    }
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Change Deal Risk Level</DialogTitle>
      <DialogContent>
        <InputLabel id="riskLabel">Risk Level</InputLabel>
        {/* hiddenLabel is used to remove the extra padding that MUI reserves at */}
        {/* the top of the selector for labels */}
        <FormControl hiddenLabel fullWidth>
          <Select
            sx={{ mb: 3, p: 0 }}
            variant="filled"
            labelId="riskLabel"
            value={selectedRisk}
            onChange={(risk) =>
              handleRiskSelected(risk.target.value as DealRiskLevels_Enum)
            }
          >
            <MenuItem value={DealRiskLevels_Enum.Low}>
              <Typography sx={{ p: 0 }}>Low</Typography>
            </MenuItem>
            <MenuItem value={DealRiskLevels_Enum.Medium}>Medium</MenuItem>
            <MenuItem value={DealRiskLevels_Enum.High}>High</MenuItem>
          </Select>
        </FormControl>

        <FormTextField
          value={commentText}
          label="Add your comment"
          textFieldProps={{
            multiline: true,
            onChange: handleChange,
          }}
        />

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            disabled={
              isSubmitting ||
              !selectedRisk ||
              props.initialRisk === selectedRisk
            }
            onClick={onSubmit}
            variant="contained"
          >
            Save
          </Button>

          <Button onClick={clearCloseDialog} variant="text" sx={{ mr: 1 }}>
            Close
          </Button>
        </DialogActions>
        <Snackbar
          open={errorSubmit}
          autoHideDuration={6000}
          onClose={closeErrorSubmit}
        >
          <Alert
            onClose={closeErrorSubmit}
            severity="error"
            sx={{ width: "100%" }}
          >
            There was an error updating the risk level, please try again.
          </Alert>
        </Snackbar>

        <Snackbar
          open={successSubmit}
          autoHideDuration={6000}
          onClose={closeSuccessSubmit}
        >
          <Alert
            onClose={closeSuccessSubmit}
            severity="success"
            sx={{ width: "100%" }}
          >
            Risk level updated!
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};
