import { ChatMessage } from "../../generated/graphql-operations";

export type AskResponse = {
  answer: string;
  citations: Citation[];
  error?: string;
};

export type Citation = {
  content: string;
  id: string;
  title: string | null;
  url: string | null;
  metadata: string | null;
  chunk_id: string | null;
  reindexId: string | null;
};

export type ToolMessageContent = {
  citations: Citation[];
  intent: string;
};

// export type ChatMessage = {
//   id: string;
//   role: ChatRole;
//   content: string;
//   end_turn?: boolean;
//   date: string;
// };

export enum ChatRole {
  Assistant = "assistant",
  User = "user",
  Error = "error",
  Tool = "tool",
}

export type Conversation = {
  id: string;
  title: string;
  messages: ChatMessage[];
  date: string;
};

export type ChatResponseChoice = {
  messages: ChatMessage[];
};

export type ChatResponse = {
  id: string;
  model: string;
  created: number;
  choices: ChatResponseChoice[];
  error?: any;
};

export type ConversationRequest = {
  messages: ChatMessage[];
};
