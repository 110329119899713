import React from "react";
import * as yup from "yup";
import { UseFormReturn } from "react-hook-form";
import { Grid } from "@mui/material";

import {
  AcnNumberFormatter,
  YupPhoneValidation,
  PhoneNumberFormatter,
} from "../../MuiCustomNumberFormat";
import { useGetCompanies } from "../../../adapters/Company/Company";
import { useGetStateData } from "../../../utils/CommonQueries";
import { FormField, SelectField, TextNumberField } from "../../Form/Form";

const ADD_NEW_COMPANY_FIELD = { label: "Add new company", value: -1 };

export const contactSchema = yup.object({
  firstName: yup.string().label("First name").required().default(""),
  middleName: yup.string().default(""),
  lastName: yup.string().label("Last name").required().default(""),
  email: yup.string().email().required("Email is a required field").default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  company: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .default({ label: "", value: -1 }),
  legalName: yup.string().label("Legal name").default(""),
  acn: yup
    .string()
    .test(
      "AcnLength",
      "ACN must be 9 digits",
      (val) => val?.length === 0 || val?.length === 9
    )
    .label("ACN"),
  position: yup.string().label("Position"),
  address: yup.string().label("Address"),
  suburb: yup.string(),
  state: yup
    .object({
      label: yup.string(),
      value: yup.number(),
    })
    // Started getting an FK_contraint error if the default is -1, so it now defaults to 1 (NSW) if the state isn't entered on creation
    .default({ label: "", value: 1 }),
  postcode: yup.string(),
});

export interface ContactData extends yup.InferType<typeof contactSchema> {}

export const defaultContactData: ContactData = contactSchema.getDefault();

export interface ContactSubmitData {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  company_id: number | null;
  address_id: number | null;
  content?: string | null;
}

interface MapFormFieldsProps {
  form: UseFormReturn<ContactData, object>;
}

export const MapFields = (props: MapFormFieldsProps) => {
  const states = useGetStateData(1);
  const companies = useGetCompanies();

  const fields: FormField[] = [
    {
      type: "text",
      fieldName: "firstName",
      label: "First name",
      helperText: "Mandatory",
      columns: 4,
    },
    {
      type: "text",
      fieldName: "middleName",
      label: "Middle name",
      helperText: "",
      columns: 4,
    },
    {
      type: "text",
      fieldName: "lastName",
      label: "Last name",
      helperText: "Mandatory",
      columns: 4,
    },
    {
      type: "text",
      fieldName: "email",
      label: "Email",
      helperText: "Mandatory",
      columns: 6,
    },
    {
      type: "text",
      fieldName: "phone",
      label: "Phone number",
      helperText: "Mandatory",
      columns: 6,
      customComp: PhoneNumberFormatter,
    },
    {
      type: "select",
      fieldName: "company",
      label: "Company",
      choices: [
        ADD_NEW_COMPANY_FIELD,
        ...(companies.data?.Companies.map((company) => ({
          label: company.legal_name as string,
          value: company.id,
        })) ?? []),
      ],
      helperText: "",
      columns: 12,
      onChange: (e, data) => {
        const currentCompany = companies.data?.Companies.find(
          (company) => company.id === data.value
        );

        props.form.setValue("legalName", currentCompany?.legal_name ?? "");
        props.form.setValue("acn", currentCompany?.acn ?? "");
      },
    },
    {
      type: "text",
      fieldName: "legalName",
      label: "Legal name",
      helperText: "Mandatory",
      columns: 6,
      disabled: props.form.watch("company").value !== -1,
    },
    {
      type: "text",
      fieldName: "acn",
      label: "ACN",
      helperText: "",
      columns: 6,
      customComp: AcnNumberFormatter,
      disabled: props.form.watch("company").value !== -1,
    },
    {
      type: "text",
      fieldName: "position",
      label: "Position",
      helperText: "",
      columns: 6,
    },
    {
      type: "text",
      fieldName: "address",
      label: "Address",
      helperText: "",
      columns: 12,
    },
    {
      type: "text",
      fieldName: "suburb",
      label: "Suburb",
      helperText: "",
      columns: 4,
    },
    {
      type: "select",
      fieldName: "state",
      label: "State",
      helperText: "",
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      columns: 4,
    },
    {
      type: "text",
      fieldName: "postcode",
      label: "Postcode",
      helperText: "",
      columns: 4,
    },
  ];

  const mapFormFields = () =>
    fields.map((field) => {
      return (
        <Grid item sm={field.columns} p={1} key={field.fieldName}>
          {field.type === "text" || field.type === "number" ? (
            <TextNumberField form={props.form} formField={field} />
          ) : null}

          {field.type === "select" ? (
            <SelectField form={props.form} formField={field} />
          ) : null}
        </Grid>
      );
    });

  return <Grid container>{mapFormFields()}</Grid>;
};

export interface ContactProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contactId?: number;
}
