import { Grid, Typography } from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { FormField, IformFieldChoice, MultiSelectField } from "../Form/Form";

interface SettingProps {
  parentIndex: number;
  index: number;
  form: UseFormReturn<any>;
  centres: IformFieldChoice[];
  users: IformFieldChoice[];
}

export const Setting = (props: SettingProps) => {
  const userField: FormField = {
    type: "select",
    label: "Name",
    fieldName: `approver_${props.parentIndex}.${props.index}.user` as const,
    helperText: "",
    choices: props.users,
    columns: 6,
  };

  const centresField: FormField = {
    type: "select",
    label: "Property name",
    fieldName:
      `approver_${props.parentIndex}.${props.index}.properties` as const,
    helperText: "Search by property name",
    choices: props.centres,
    columns: 12,
  };

  return (
    <Grid container p={1}>
      <Grid item mb={2} xs={12}>
        <Typography variant="subtitle2">Centres managed</Typography>
      </Grid>
      <Grid item xs={4} mb={1}>
        <MultiSelectField
          control={props.form.control}
          formField={userField}
          limitSelections={1}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectField
          control={props.form.control}
          formField={centresField}
        />
      </Grid>
    </Grid>
  );
};
