import React from "react";
import { useQuery } from "@apollo/client";
import { useFieldArray, useForm } from "react-hook-form";
import { Add, Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Grid, Skeleton, Box } from "@mui/material";
import {
  GetUsersDocument,
  GetEsclatedApproversDocument,
} from "../../generated/graphql-operations";
import { FormField, SelectField } from "../Form/Form";
import {
  EscalatedApproverSchema,
  EscalatedApproverType,
  defaultEscalatedApprover,
} from "./Config";
import { Button } from "../Button";
import { CreateButton } from "../CreateButton";
import { CollapseCard } from "../CollapseCard";

import {
  useUpdateEscalatedApprover,
  transformEscalatedApproverData,
} from "../../adapters/Approvals/ApprovalSettings";
import { formatOrdinals } from "../../utils/CommonFunctions";
import { SubmitAlert } from "../SubmitAlert";

export const EscalatedApprover = ({ forceOpen }: { forceOpen: boolean }) => {
  const updateApprovers = useUpdateEscalatedApprover();
  const [submitState, setSubmitState] = React.useState<boolean | undefined>();

  const form = useForm<EscalatedApproverType>({
    resolver: yupResolver(EscalatedApproverSchema),
    mode: "onChange",
  });

  const approverFieldArray = useFieldArray({
    name: "users",
    control: form.control,
  });

  const users = useQuery(GetUsersDocument);
  const approvers = useQuery(GetEsclatedApproversDocument);

  React.useEffect(() => {
    if (approvers.data) {
      const transformedData = transformEscalatedApproverData(approvers.data);
      form.setValue("users", transformedData);
    }
  }, [form, approvers.data]);

  // TODO also handle error case
  if (!users.data || !approvers.data) {
    return <Skeleton />;
  }
  // TODO: Should probs use a useMemo or caching here
  const selectedUsers = form.watch("users");
  const selectedUsersIds = selectedUsers?.map((item) => item.value) ?? [];
  const filteredUserData =
    users.data.Users.filter((item) => !selectedUsersIds.includes(item.id)) ??
    [];

  return (
    <>
      {approverFieldArray.fields.map((field, index) => {
        const userField: FormField = {
          type: "select",
          label: "",
          fieldName: `users.${index}`,
          helperText: "",
          choices:
            filteredUserData.map((item) => ({
              label: item.user_full_name ?? "",
              value: item.id,
            })) ?? [],
          columns: 12,
        };

        return (
          <CollapseCard
            heading={`${formatOrdinals(index + 1)} escalation`}
            key={field.id}
            forceOpen={!!form.formState.errors?.users?.[index] || forceOpen}
          >
            <Grid container alignItems="flex-end">
              <Grid item xs={9}>
                <SelectField form={form} formField={userField} />
              </Grid>
              <Grid item xs={3}>
                <Button
                  startIcon={<Delete />}
                  color="error"
                  variant="text"
                  onClick={() => {
                    approverFieldArray.remove(index);
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </CollapseCard>
        );
      })}

      <Box width="100%" />
      <CreateButton
        startIcon={<Add />}
        color="primary"
        variant="text"
        onClick={() => approverFieldArray.append(defaultEscalatedApprover)}
      >
        Add approver
      </CreateButton>

      <Box width="100%" />

      <SubmitAlert
        successSubmit={submitState}
        onClick={() => setSubmitState(undefined)}
      />

      <Button
        sx={{ mt: 5 }}
        variant="contained"
        onClick={form.handleSubmit(async (data) => {
          try {
            await updateApprovers(data.users);
            setSubmitState(true);
          } catch {
            setSubmitState(false);
          }
        })}
      >
        {form.formState.isSubmitting ? <CircularProgress /> : "Save"}
      </Button>
    </>
  );
};
