import * as yup from "yup";
import { UseFormReturn } from "react-hook-form";
import { UnitInformationSchema } from "./UnitInformation/UnitInformationConfig";
import { CommercialSchema } from "./Commercial/Config";
import { DateSchema } from "./DateInformation/Config";
import { DealTypeSchema } from "./DealType/DealTypeConfig";
import { TenantInformationSchema } from "./TenantInformation/TenantInformationConfig";
import { UsageSchema } from "./Usage/Config";
import { StorageSchema } from "./Storage/StorageConfig";
import { ChargesSchema } from "./Charges/ChargesConfig";
import { OptionTermSchema } from "./OptionTerms/Config";
import { DepositSchema } from "./Deposit/DepositConfig";
import { LandlordServicesSchema } from "./LandlordServices/LandlordServicesConfig";
import { FitoutLandlordWorksSchema } from "./FitoutLandlordWorks/Config";
import { SpecialConditionsSchema } from "./SpecialConditions/Config";
import { HistoricalDataSchema } from "./HistoricalData/Config";

export const dataStateDefault = {
  tenantInfoLoaded: false,
  unitInfoLoaded: false,
  dateInfoLoaded: false,
  dealTypeLoaded: false,
  commercialLoaded: false,
  usageLoaded: false,
  storageLoaded: false,
  chargesLoaded: false,
  optionTermsLoaded: false,
  depositLoaded: false,
  landlordServicesLoaded: false,
  fitoutLandlordWorksLoaded: false,
  specialConditionsLoaded: false,
  historicalDataLoaded: false,
};
export type DataState = typeof dataStateDefault;

export interface DealInformationProps {
  dealId: number;
}

export interface DealInformationSectionProps {
  dealId: number;
  form: UseFormReturn<DealInformationType, object>;
  setDataState: React.Dispatch<React.SetStateAction<DataState>>;
}

export const allDataLoaded = (dataState: DataState) => {
  return Object.values(dataState).every((state) => state);
};

export const DealInformationSchema = yup.object({
  tenantInfo: TenantInformationSchema,
  unitInfo: UnitInformationSchema,
  dateInfo: DateSchema,
  dealType: DealTypeSchema,
  commercial: CommercialSchema,
  usage: UsageSchema,
  storage: StorageSchema,
  charges: ChargesSchema,
  optionTerm: OptionTermSchema,
  deposit: DepositSchema,
  landlordServices: LandlordServicesSchema,
  fitoutLandlordWorks: FitoutLandlordWorksSchema,
  specialConditions: SpecialConditionsSchema,
  historicalData: HistoricalDataSchema,
});

export type UnitInformationType = yup.InferType<typeof UnitInformationSchema>;
export type DealTypeType = yup.InferType<typeof DealTypeSchema>;
export type DealInformationType = yup.InferType<typeof DealInformationSchema>;

export const defaultUnitInformationData = UnitInformationSchema.getDefault();
export const defaultDealTypeData = DealTypeSchema.getDefault();
export const defaultTenantInformationData =
  TenantInformationSchema.getDefault();
export const defaultDealInformationData = DealInformationSchema.getDefault();
