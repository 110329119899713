import * as yup from "yup";

export const UsageSchema = yup.object({
  permittedUse: yup
    .object({ label: yup.string(), value: yup.number() })
    .required(),
  preview: yup.string(),
  foodMenu: yup.string(),
  subletFranchisee: yup.string(),
});

export type UsageType = yup.InferType<typeof UsageSchema>;

export const NO_OPTION_USE = { label: "Other", value: -1 };
