import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, Typography, Chip, Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { add, parseISO } from "date-fns";
import { GetLafDataDocument } from "../../generated/graphql-operations";
import { checkAndFormatDate, convertDaysToTerms } from "../../utils/DateUtils";
import { Alert } from "../Alert";
import { numberFormat } from "./Common";
import {
  DealTenantType,
  MOBILE_MEDIA_QUERY,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../../utils/CommonVariables";

export const LafOverview = (props: { dealId: number }) => {
  const mobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const { data, error } = useQuery(GetLafDataDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data || !data.Deals_by_pk) {
    return (
      <Skeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width="100%"
        height={100}
      />
    );
  }
  const dealUnits = data?.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0];
  const unit = dealUnits?.Unit ?? dealUnits?.VirtualUnit;

  const leaseStartDate = parseISO(data.Deals_by_pk.DealData[0]?.lease_start);
  const leaseTerm = convertDaysToTerms(
    leaseStartDate,
    data?.Deals_by_pk?.DealData[0]?.lease_term ?? 0
  );

  const LeaseExpireDate = add(leaseStartDate, {
    years: leaseTerm?.years,
    months: leaseTerm?.months,
    days: (leaseTerm?.days ?? 0) - 1,
  });

  let tenants: { label: string; value: string }[] = [];
  if (data?.Deals_by_pk?.TenantType?.id === DealTenantType.Individual) {
    tenants =
      data?.Deals_by_pk?.DealsContacts.map((item) => ({
        label: "Tenant",
        value:
          item.Contact?.legal_name ??
          `${item.Contact?.first_name ?? ""} ${item.Contact?.last_name ?? ""}`,
      })) ?? [];
  } else {
    tenants =
      data?.Deals_by_pk?.DealsCompanies.flatMap((item) => [
        { label: "Tenant", value: item.Company?.legal_name ?? "" },
        { label: "ACN", value: item.Company?.acn ?? "" },
      ]) ?? [];
  }

  const overviewDeal = [
    {
      label: "Centre",
      value: unit?.Property?.label,
    },
    {
      label: "Estimated area",
      value: `${unit?.estimated_area ?? 0} sqm`,
    },
    {
      label: "Unit",
      value:
        dealUnits?.Unit?.label ??
        (dealUnits?.VirtualUnit?.label
          ? `${dealUnits?.VirtualUnit?.label}${VIRTUAL_UNIT_LABEL_SUFFIX}`
          : null),
    },
    {
      label: "Deal owner",
      value: data?.Deals_by_pk?.Owner?.user_full_name,
    },
    {
      label: "Deal type",
      value: data?.Deals_by_pk?.DealType.title,
    },
    ...tenants,
    {
      label: "Trading name",
      value: data?.Deals_by_pk?.trading_name,
    },
    {
      label: "Lease start date",
      value: checkAndFormatDate(leaseStartDate, "dd/MM/yyyy") ?? "",
    },
    {
      label: "Fitout period",
      value: `${numberFormat.format(
        (data?.Deals_by_pk?.DealData[0]?.fitout_term ?? 0) / 7
      )} weeks`,
    },
    {
      label: "Lease expiry date",
      value: checkAndFormatDate(LeaseExpireDate, "dd/MM/yyyy") ?? "",
    },
  ];
  return (
    <Grid container mb={3} justifyContent="space-between">
      {overviewDeal.map((item) => (
        <Grid item xs={mobile ? 5 : "auto"} mr={1} pb={1} key={item.label}>
          <Typography>{item.label}</Typography>
          {Array.isArray(item.value) ? (
            item.value.map((label) => <Chip label={label} />)
          ) : (
            <Chip label={[item.value]} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
