import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";

import {
  CreateCommentBulkDocument,
  UpdateApprovalsDocument,
  Tags_Constraint,
  CreateDealFileDocument,
  GetDealFilesDocument,
  Tags_Update_Column,
  DealFiles_Constraint,
  GetLafVersionDocument,
  LafActionChoices_Enum,
} from "../../generated/graphql-operations";
import { DEFAULT_LAF_TAG } from "../../components/Laf/Common";
import {
  BLOB_CLIENT_URL,
  DOCUMENT_CONTAINER_NAME,
} from "../../utils/CommonVariables";

import { BrowserCredential } from "../../contexts/Auth/AzureBrowserAuth";

export const lafVersionTag = (lafVersion: number | undefined) => {
  if (!lafVersion) {
    return DEFAULT_LAF_TAG;
  }
  return `${DEFAULT_LAF_TAG} ${lafVersion}`;
};

export const useGetLafVersion = (dealId: number) => {
  const lafQuery = useQuery(GetLafVersionDocument, {
    variables: { deal_id: dealId },
  });

  return lafQuery.data?.Deals_by_pk?.laf_version;
};

export const useActionLaf = (
  dealId: number,
  dealDataId: number,
  approvalAction: LafActionChoices_Enum
) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const lafVersion = useGetLafVersion(dealId);
  const [CreateComment] = useMutation(CreateCommentBulkDocument);
  const [UpdateApproval] = useMutation(UpdateApprovalsDocument);

  const submitFunction = async (comment: string) => {
    if (comment !== "" && comment !== undefined && comment !== null) {
      const commentQuery = await CreateComment({
        variables: {
          object: {
            deal_id: dealId,
            text: comment,
            CommentTags: {
              data: [lafVersionTag(lafVersion)].map((name) => ({
                Tag: {
                  data: { name },
                  on_conflict: {
                    update_columns: [Tags_Update_Column.Name],
                    constraint: Tags_Constraint.TagsNameUnique,
                  },
                },
              })),
            },
          },
        },
      });
      const commentId = commentQuery.data?.insert_Comments_one?.id;
      if (!commentId) {
        throw new Error("Something went wrong while submitting comment");
      }
    }

    await UpdateApproval({
      variables: {
        modified_at: new Date(),
        laf_action: approvalAction,
        deal_data_id: dealDataId,
      },
    });
  };

  return {
    open: dialogOpen,
    closeDialog: () => {
      setDialogOpen(false);
    },
    openDialog: () => {
      setDialogOpen(true);
    },
    submitDialog: submitFunction,
  };
};

export const useCreateBlobSubmit = (dealId: number) => {
  const lafVersion = useGetLafVersion(dealId);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [createFile] = useMutation(CreateDealFileDocument);

  const onSubmit = async (blob: Blob | null, fileNameSuffix?: string) => {
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      throw new Error("Not Authenticated");
    }

    if (blob === null) {
      throw new Error("No Blob");
    }

    const blobServiceClient = new BlobServiceClient(
      BLOB_CLIENT_URL,
      new BrowserCredential(instance, isAuthenticated, inProgress)
    );
    const pathName = `${dealId}/${uuidv4()}`;
    const blockBlobClient = blobServiceClient
      .getContainerClient(DOCUMENT_CONTAINER_NAME)
      .getBlockBlobClient(pathName);

    await blockBlobClient.upload(blob, blob?.arrayBuffer.length, {
      // Need this to know what file extension to download as
      metadata: {
        name: "Approved Laf.pdf",
      },
    });

    // Currently duplicate names are skipped
    const suffixFileName = fileNameSuffix ? ` - ${fileNameSuffix}` : "";
    await createFile({
      variables: {
        file_object: {
          deal_id: dealId,
          file_name: `Approved LAF${suffixFileName}`,
          file_path: pathName,
          not_deleted: true,
          DealFileTags: {
            data: [lafVersionTag(lafVersion)].map((tag) => ({
              Tags: {
                data: { name: tag },
                on_conflict: {
                  constraint: Tags_Constraint.TagsNameUnique,
                  update_columns: [Tags_Update_Column.Name],
                },
              },
            })),
          },
        },
      },
      onError: (error) => {
        blockBlobClient.deleteIfExists();
        // if this file already exists then skip.
        if (
          !error.message.includes(
            DealFiles_Constraint.DealfilesDealIdFilenameDeleteUnique
          )
        ) {
          throw new Error("Error creating Blob");
        }
      },
      refetchQueries: [GetDealFilesDocument],
    });
  };

  return onSubmit;
};
