import React, { useState } from "react";
import { Grid, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { parseISO } from "date-fns";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DeletePermittedUseTemplateDocument,
  GetUsagePermittedUsesTemplateDocument,
  GetUsagePermittedUsesTemplateQuery,
} from "../../../generated/graphql-operations";
import { checkAndFormatDate } from "../../../utils/DateUtils";
import { CreateDialog, UpdateDialog } from "./Dialog/Dialog";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { Role, useReturnUserRole } from "../../../contexts/Auth/UserRole";

export const PermittedUseTemplate = () => {
  const leaseAdmin = useReturnUserRole() === Role.Admin;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>();
  const [updateDialogId, setUpdateDialogId] = useState<number>();

  const [deletePermittedUse] = useMutation(DeletePermittedUseTemplateDocument);

  const onEditClick = (templateId: number) => {
    setUpdateDialogId(templateId);
    setUpdateDialogOpen(true);
  };

  const onDeleteClick = (templateId: number) => {
    setDeleteDialogId(templateId);
    setDeleteDialogOpen(true);
  };

  const columns: GridColDef<
    NonNullable<
      GetUsagePermittedUsesTemplateQuery["TemplatePermittedUses"][number]
    >
  >[] = [
    {
      field: "title",
      headerName: "Permitted Use",
      flex: 1,
    },
    {
      field: "Updated by",
      headerName: "Updated by",
      flex: 1,
      valueGetter: (params) => params.row.ModifiedBy?.user_full_name,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      type: "date",
      valueFormatter: (params) =>
        checkAndFormatDate(params.value, "dd/MM/yyyy HH:mm"),
      valueGetter: (params) => {
        return params.row.modified_at
          ? parseISO(`${params.row.modified_at}Z`)
          : undefined;
      },
    },
    {
      field: "buttons",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={() => onEditClick(params.row.id)}
              variant="outlined"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              color="error"
              size="small"
              onClick={() => onDeleteClick(params.row.id)}
            >
              <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
          </Grid>
        </Grid>
      ),
    },
  ];

  const { data, loading } = useQuery(GetUsagePermittedUsesTemplateDocument);

  const setClose = () => {
    // Need to set this to undefined otherwise, form will not rerender with default value of
    // template from db
    setUpdateDialogId(undefined);
    setUpdateDialogOpen(false);
  };

  if (!leaseAdmin) return <div />;

  return (
    <Grid container item xs={12} justifyContent="flex-end">
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      {updateDialogId !== undefined && (
        <UpdateDialog
          templateId={updateDialogId}
          open={updateDialogOpen}
          onClose={setClose}
        />
      )}

      <ConfirmationDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title="Delete Permitted Use"
        description="Are you sure you want to delete this Permitted Use?"
        confirmText="Yes"
        declineText="No"
        onConfirm={async () => {
          await deletePermittedUse({
            variables: {
              id: deleteDialogId,
            },
            refetchQueries: [GetUsagePermittedUsesTemplateDocument],
          });
        }}
      />
      <Grid item pb={1}>
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => setCreateDialogOpen(true)}
        >
          Create new Permitted Use
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          height: 500,
          width: "100%",
        }}
        xs={12}
      >
        <DataGrid
          columns={columns}
          rows={data?.TemplatePermittedUses ?? []}
          loading={loading}
          onCellClick={(params) => {
            if (params.field !== "buttons") {
              onEditClick(params.row.id);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
