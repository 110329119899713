import { useQuery, useMutation } from "@apollo/client";

import {
  GetSpecialConditionTemplateQuery,
  GetSpecialConditionTemplatesDocument,
  GetSpecialConditionTemplateDocument,
  UpdateSpecialConditionTemplateDocument,
  CreateSpecialConditionTemplateDocument,
  CreatePermittedUseTemplateDocument,
  GetUsagePermittedUsesTemplateDocument,
  GetPermittedUseTemplateDocument,
  GetPermittedUseTemplateQuery,
  UpdatePermittedUseTemplateDocument,
} from "../../generated/graphql-operations";

import { SpecialConditionType } from "../../components/Template/SpecialCondition/Dialog/Config";
import { PermittedUseType } from "../../components/Template/PermittedUse/Dialog/Config";

export const useGetSpecialConditionTemplates = () => {
  return useQuery(GetSpecialConditionTemplatesDocument);
};

export const useGetSpecialConditionTemplate = (Id: number) => {
  return useQuery(GetSpecialConditionTemplateDocument, {
    variables: {
      id: Id,
    },
  }).data;
};

export const useGetPermittedUseTemplate = (Id: number) => {
  return useQuery(GetPermittedUseTemplateDocument, {
    variables: {
      id: Id,
    },
  }).data;
};

export const applyPermittedUseDefaults = (
  data?: GetPermittedUseTemplateQuery["TemplatePermittedUses_by_pk"]
): PermittedUseType => {
  return {
    title: data?.title ?? "",
    description: data?.description ?? "",
  };
};

export const useUpdatePermittedUse = (id: number) => {
  const [updatePermittedUse] = useMutation(UpdatePermittedUseTemplateDocument, {
    refetchQueries: [
      GetPermittedUseTemplateDocument,
      GetUsagePermittedUsesTemplateDocument,
    ],
  });

  return async (data: PermittedUseType) => {
    await updatePermittedUse({
      variables: { id, data },
    });
  };
};

export const applySpecialConditionDefaults = (
  data: GetSpecialConditionTemplateQuery["TemplateSpecialConditions_by_pk"]
): SpecialConditionType => {
  return {
    title: data?.title ?? "",
    priority: data?.priority ?? undefined,
    description: data?.description ?? "",
    descriptionLaf: data?.laf_description ?? "",
  };
};

export const useUpdateSpecialConditions = (Id: number) => {
  const [updateCondition] = useMutation(
    UpdateSpecialConditionTemplateDocument,
    {
      refetchQueries: [
        GetSpecialConditionTemplateDocument,
        GetSpecialConditionTemplatesDocument,
      ],
    }
  );
  return async (data: SpecialConditionType) => {
    await updateCondition({
      variables: {
        id: { id: Id },
        data: {
          description: data.description,
          laf_description: data.descriptionLaf,
          title: data.title,
          priority: data.priority ?? null,
        },
      },
    });
  };
};

export const useCreateSpecialConditions = () => {
  const [createCondition] = useMutation(
    CreateSpecialConditionTemplateDocument,
    {
      refetchQueries: [GetSpecialConditionTemplatesDocument],
    }
  );
  return async (data: SpecialConditionType) => {
    await createCondition({
      variables: {
        data: {
          description: data.description,
          laf_description: data.descriptionLaf,
          title: data.title,
          priority: data.priority ?? null,
        },
      },
    });
  };
};

export const useCreatePermittedUse = () => {
  const [createPermittedUse] = useMutation(CreatePermittedUseTemplateDocument, {
    refetchQueries: [GetUsagePermittedUsesTemplateDocument],
  });
  return async (data: PermittedUseType) => {
    await createPermittedUse({
      variables: {
        data: {
          description: data.description,
          title: data.title,
        },
      },
    });
  };
};
