// Magic unicode to maintain same height of labeled and unlabeled fields.

import { DealStatus_Enum } from "../generated/graphql-operations";

// Passing in any whitespace in the label causes it to not render and ruins the alignment of the fields
export const TAG_SPACE_CHAR = "\uDB40\uDC20";
export const ERROR_COLOR = "#D14F4F";
export const BLACK_ALPHA_60 = "rgba(0, 0, 0, 0.6)";
export const BLACK_ALPHA_38 = "rgba(0, 0, 0, 0.38)";
export const WHITE_ALPHA_08 = "rgba(42, 110, 187, 0.08)";

export const MOBILE_MEDIA_QUERY = "(max-width:600px)";

export const DISABLE_SX_PROPS = { pointerEvents: "none", opacity: 0.7 };

export enum DealPhaseStatus {
  Active = 1,
  Complete,
  Inactive,
}

export enum DealTenantType {
  Individual = 1,
  Company,
}

// Term Phase is active when deal is created
export const DEAL_CREATE_ACTIVE_PHASE_ID = 1;
export const TENANCY_DELIVERY_PHASE_NAME = "Tenancy delivery";
export const LAF_PHASE_NAME = "LAF review";
export const LEASE_ADMIN_NAME = "Lease admin";
export const SHOP_CLOSED_PHASE_NAME = "Shop closed";
export const LAST_PHASE_NAME = "Registration";

// Other phase Names
export const HANDOVER_PHASE_NAME = "Handover";
export const DEAL_APPROVAL_PHASE_NAME = "Deal approval";
export const LEASE_PHASE_NAME = "Lease";

// Deal Action Item Names
export const LETTER_OF_ACCEPTANCE_SENT = "Letter of acceptance sent";
export const SURVEY_PLAN_ATTACHED = "Survey plan attached";
export const LEASE_EXECUTED_AND_UPLOADED = "Lease executed and uploaded";

export enum UserStatus {
  Active = 1,
  Inactive,
}

export enum UnitType {
  Actualized = 1,
  Virtual,
}

export enum Toggle {
  YES = 1,
  NO,
  NA,
}

export enum ToggleString {
  Yes = "yes",
  No = "no",
  "N/A" = "na",
}

export enum ToggleTwoOption {
  Yes = "yes",
  No = "no",
}

export const TOGGLE_OPTIONS_TWO = Object.entries(ToggleTwoOption).map(
  ([text, id]) => ({
    id,
    text,
  })
);

export const TOGGLE_OPTIONS = Object.entries(ToggleString).map(
  ([text, id]) => ({
    id,
    text,
  })
);

export enum GeneralAmountTypes {
  "%" = "%",
  $ = "$",
  CPI = "CPI",
  "Market review" = "Market review",
  "CPI + %" = "CPI + %",
}

// Filter out 0, 1 from Enum and map to form Choices
export const AMOUNT_TYPE_CHOICES = Object.keys(GeneralAmountTypes).map(
  (label) => ({
    label,
    value: label,
  })
);

export enum PromotionLevyTypes {
  "%" = "%",
  $ = "$",
}

export const PROMOTION_LEVY_CHOICES = Object.keys(PromotionLevyTypes).map(
  (label) => ({
    label,
    value: label,
  })
);

export enum ContactType {
  Tenant = 1,
  Approver,
  Watcher,
}

export const AddNewContactOption = { label: "Add new contact", value: 0 };

export const DOCUMENT_CONTAINER_NAME = "documents";
export const TEMPLATE_CONTAINER_NAME = "templates";
export const BLOB_CLIENT_URL = `https://${process.env.REACT_APP_AZURE_CONTAINER}.blob.core.windows.net`;

export const CONTACT_TENANT_TYPE = "Tenant";
export const CONTACT_TENANT_TYPE_COMPANY = "Tenant";

export const VIRTUAL_UNIT_LABEL_SUFFIX = " - Virtual Unit";
export const VIRTUAL_UNIT_LABEL = "Virtual Unit";

export enum DealTenancyPhase {
  ShopOpen = "Shop open",
  ShopHandover = "Handover",
}

export const DealDisabledStatus = [
  DealStatus_Enum.Rejected,
  DealStatus_Enum.Superseded,
  DealStatus_Enum.Deleted,
  DealStatus_Enum.Cancelled,
  DealStatus_Enum.Completed,
];

export const NotificationEmailAttachmentTypes = [
  "bmp",
  "gif",
  "jpeg",
  "jpg",
  "png",
  "tif",
];

export const DealType = {
  New: "New",
  Renewal: "Renewal",
  Opportunity: "Opportunity",
  CurrentYearVacancy: "Current Year Vacancy",
  RemixRelocation: "Remix/Relocation",
  NewLeaseChangeOwnership: "New Lease on Change of Ownership",
  Churn: "Churn",
  OptionRenewal: "Option Renewal",
  OptionMarketReview: "Option Market Review",
  RenewalCovidExtension: "Renewal - Covid Extension",
};

export enum DealTypeEnum {
  New = 1,
  Renewal,
}

export const hoardingAndSkinsOptions = [
  { text: "Landlord", id: "landlord" },
  { text: "Tenant", id: "tenant" },
  { text: "Split", id: "split" },
  { text: "N/A", id: "na" },
];
