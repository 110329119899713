import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Card,
  Typography,
  useTheme,
  Button,
  Chip,
  Grid,
  Stack,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { parseISO } from "date-fns";
import {
  GetDealFilesDocument,
  GetDealFilesQuery,
  SoftDeleteFileDocument,
} from "../../../../generated/graphql-operations";
import { checkAndFormatDate } from "../../../../utils/DateUtils";
import { BLACK_ALPHA_60 } from "../../../../utils/CommonVariables";
import { useDownloadBlob } from "../../../../adapters/Deal/Files";
import { ConfirmationDialog } from "../../../ConfirmationDialog";

export const FileCard = (props: {
  data: GetDealFilesQuery["DealFiles"][number];
  // This function should set the edit Dialog to show its self
  setEditDialog: () => void;
  dealDisabled: boolean;
}) => {
  const theme = useTheme();
  const downloadBlob = useDownloadBlob();
  const fileDate =
    checkAndFormatDate(
      parseISO(`${props.data.modified_at}Z`),
      "ccc d LLL yyyy, hh:mm a"
    ) ?? "";
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteFile] = useMutation(SoftDeleteFileDocument);
  return (
    <Card
      sx={{
        pt: 2,
        pl: 2,
        pb: 0,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Grid container alignItems="flex-start">
        <Grid item sm={10}>
          <Typography variant="subtitle1" color={theme.palette.text.primary}>
            {props.data.file_name}
          </Typography>
          <Typography variant="body2" color={BLACK_ALPHA_60}>
            Uploaded by {props.data.Users?.user_full_name}
          </Typography>
          <Typography variant="body2" color={BLACK_ALPHA_60}>
            {`${fileDate}`}
          </Typography>
          <Stack direction="row">
            {props.data.DealFileTags.map((item) => (
              <Chip key={item.Tags.id} label={item.Tags.name ?? ""} />
            ))}
          </Stack>
          <Button
            variant="text"
            sx={{ mt: 2, ml: -1 }}
            onClick={async () => {
              downloadBlob(
                props.data.file_name ?? "",
                props.data.file_path ?? ""
              );
            }}
          >
            Download
          </Button>
          <Button
            variant="text"
            sx={{ mt: 2, ml: -1, color: theme.palette.error.main }}
            onClick={() => setOpenConfirmation(true)}
            disabled={props.dealDisabled}
          >
            Delete
          </Button>
        </Grid>
        <Grid item sm={2}>
          <IconButton onClick={props.setEditDialog}>
            <MoreVertIcon color="primary" />
          </IconButton>
        </Grid>
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          title="Delete File"
          description="Are you sure you want to delete this file?"
          confirmText="Yes"
          declineText="No"
          onConfirm={async () => {
            await deleteFile({
              variables: {
                id: props.data.id,
              },
              refetchQueries: [GetDealFilesDocument],
            });
          }}
        />
      </Grid>
    </Card>
  );
};
