import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Snackbar,
  DialogActions,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  DeleteDealDocument,
  GetDealDocument,
} from "../../../generated/graphql-operations";

interface DeleteDealDialogProps {
  dealId: number;
  disable: boolean;
}

export const DeleteDealDialog = (props: DeleteDealDialogProps) => {
  const [errorSubmit, setErrorSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [DeleteDeal] = useMutation(DeleteDealDocument, {
    variables: { dealId: props.dealId },
    refetchQueries: [GetDealDocument],
  });
  const closeErrorSubmit = () => setErrorSubmit(false);

  const clearCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        color="error"
        disabled={props.disable}
        onClick={() => setOpen(true)}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Delete deal</DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ mb: 1 }}>
            Warning: this cannot be undone.
          </Alert>

          <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
            <Button
              color="error"
              onClick={async () => {
                try {
                  DeleteDeal();
                } catch {
                  setErrorSubmit(true);
                }
                clearCloseDialog();
              }}
              variant="contained"
            >
              Delete
            </Button>

            <Button onClick={clearCloseDialog} variant="text" sx={{ mr: 1 }}>
              Cancel
            </Button>
          </DialogActions>
          <Snackbar
            open={errorSubmit}
            autoHideDuration={6000}
            onClose={closeErrorSubmit}
          >
            <Alert
              onClose={closeErrorSubmit}
              severity="error"
              sx={{ width: "100%" }}
            >
              Something went wrong, try submitting again.
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </>
  );
};
