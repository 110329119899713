import React from "react";
import NumberFormat, {
  InputAttributes,
  NumberFormatProps,
} from "react-number-format";
import {
  formatIncompletePhoneNumber,
  validatePhoneNumberLength,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { TestFunction } from "yup";

const COUNTRY_PHONE_VAL = "AU";

interface CustomProps {
  name: string;
  onChange: (event: {
    target: { name: string; value: number | string | undefined };
  }) => void;
  onBlur: (
    event:
      | { target: { name: string; value: number | string | undefined } }
      | undefined
  ) => void;
}

/**
 * Creates a custom format forwardRef to be used
 * to Customize react text Input
 * This is put into the InputProps of mui Text field like so:
 * <TextField InputProps={{ inputComponent: NumberFormatCustom() as any }}>
 * Note: you can't call this function within a component
 * Or it will rerender whenever the component changes deleting everything
 * Note: This updates every change, even onBlur Otherwise React Hook form
 * Saves the value as the formatted value instead of the true value
 */
export const MuiNumberFormatter = (
  formatProps: NumberFormatProps,
  useFloatValue: boolean = false
) =>
  React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
    function NumberFormatCustom(props, ref) {
      const { onChange, onBlur, ...other } = props;

      return (
        <NumberFormat
          {...other}
          getInputRef={ref}
          // Currently removing onBlur
          // As this causes the formatted value to be saved
          // instead of the true value.
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: useFloatValue ? values.floatValue : values.value,
              },
            });
            onBlur({
              target: {
                name: props.name,
                value: useFloatValue ? values.floatValue : values.value,
              },
            });
          }}
          {...formatProps}
        />
      );
    }
  );

export const AcnNumberFormatter = MuiNumberFormatter({
  format: "### ### ###",
  mask: "_",
});

export const CurrencyFormatter = MuiNumberFormatter(
  {
    thousandSeparator: true,
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  true
);

export const PercentageFormatter = MuiNumberFormatter(
  {
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  true
);

export const PercentageFormatterExtraSmall = MuiNumberFormatter(
  {
    decimalScale: 4,
    fixedDecimalScale: true,
  },
  true
);

export const PhoneNumberFormatter = MuiNumberFormatter({
  type: "tel",
  required: true,
  allowNegative: false,
  allowLeadingZeros: true,
  decimalScale: 0,
  isAllowed: ({ value }) => value.length <= 10,
  format: (value) => {
    // Change this line to include + and remove country to
    // Allow for international formatting
    return formatIncompletePhoneNumber(`${value}`, COUNTRY_PHONE_VAL);
  },
});

export const YupPhoneValidation: TestFunction<string | undefined> = (
  value,
  { createError, path }
) => {
  const val = value ?? "";
  const res = validatePhoneNumberLength(val, COUNTRY_PHONE_VAL);

  switch (res) {
    case "INVALID_COUNTRY":
      return createError({
        path,
        message: "Invalid country",
      });
    case "NOT_A_NUMBER":
      return createError({
        path,
        message: "Not a recognised phone number",
      });
    case "TOO_SHORT":
      return createError({
        path,
        message: "Phone number is too short",
      });
    case "TOO_LONG":
      return createError({
        path,
        message: "Phone number is too long",
      });
    case "INVALID_LENGTH":
      return createError({
        path,
        message: "Phone numbers length is invalid",
      });
    default:
      break;
  }

  if (!isValidPhoneNumber(val, COUNTRY_PHONE_VAL)) {
    if (
      val.length === 8 &&
      formatIncompletePhoneNumber(`${val}`, COUNTRY_PHONE_VAL)[0] !== "("
    ) {
      return createError({
        path,
        message: "Phone number is not valid, did you forget area code?",
      });
    }

    return createError({
      path,
      message: "Phone number is not valid",
    });
  }

  return true;
};
