import React from "react";
import {
  InteractionType,
  OIDC_DEFAULT_SCOPES,
  EventMessage,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  MsalAuthenticationResult,
} from "@azure/msal-react";
import { ApolloError } from "@apollo/client";

import { pca } from "./AuthConfig";
import { apolloClient } from "../ApolloConfig";
import { Upsert_UserDocument } from "../../generated/graphql-operations";
import { UserStatus } from "../../utils/CommonVariables";

export const ErrorComponent = ({ error }: MsalAuthenticationResult) => {
  return <p>An Error Occurred: {error ? error.errorCode : "unknown error"}</p>;
};

export const ErrorLoginUserComponent = ({ message }: { message: string }) => {
  return (
    <>
      <p>An error occurred when updating your details.</p>
      <p>Please open application in new tab and try again.</p>
      <p>Technical error: {message}</p>
    </>
  );
};

export const AuthGate = ({ children }: { children?: React.ReactNode }) => {
  const [errorUser, setErrorUser] = React.useState<undefined | string>(
    undefined
  );
  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      pca.setActiveAccount(account);
      apolloClient
        .mutate({
          mutation: Upsert_UserDocument,
          variables: {
            object: {
              // ID added in hasura
              status: UserStatus.Active,
              last_login: new Date(),
            },
          },
        })
        .then((data) => {
          if (data.errors) {
            setErrorUser(data.errors.map((item) => item.message).join(", "));
          } else {
            setErrorUser(undefined);
          }
        })
        .catch((e: ApolloError) => {
          setErrorUser(`Error ${e.message}`);
        });
    }
  });

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: [...OIDC_DEFAULT_SCOPES, "User.Read"],
        }}
        errorComponent={ErrorComponent}
      >
        {errorUser === undefined ? (
          children
        ) : (
          <ErrorLoginUserComponent message={errorUser} />
        )}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
