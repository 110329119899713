import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Snackbar,
  DialogActions,
  Grid,
} from "@mui/material";

import CopyAllIcon from "@mui/icons-material/CopyAll";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "../../Form/FormComponents";
import { useCopyDealReLaf, useCopyDeal } from "../../../adapters/Deal/CopyDeal";
import { DealData, DealTermSchema } from "../../CreateDeal/DealConfig";
import { LocationSelect } from "../../LocationSelect";

interface CopyDealRelafDialogProps {
  dealId: number;
  disableReLaf: boolean;
}
interface CopyDealDialogProps {
  dealId: number;
  disable: boolean;
}

export const CopyDealDialog = (props: CopyDealDialogProps) => {
  const [isSubmitting, submitCopyDeal] = useCopyDeal(props.dealId);
  const [commentText, setcommentText] = React.useState("");
  const [errorSubmit, setErrorSubmit] = React.useState(false);

  const form: UseFormReturn<DealData> = useForm({
    resolver: yupResolver(DealTermSchema),
    mode: "onBlur",
  });

  const [open, setOpen] = React.useState(false);
  const closeErrorSubmit = () => {
    setErrorSubmit(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcommentText(event.target.value);
  };
  const clearCloseDialog = () => {
    setOpen(false);
    setcommentText("");
  };
  return (
    <>
      <Button
        disabled={props.disable}
        onClick={() => setOpen(true)}
        startIcon={<CopyAllIcon />}
      >
        Copy
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Copy deal</DialogTitle>
        <DialogContent>
          <LocationSelect form={form} />

          <Grid container p={1}>
            <FormTextField
              value={commentText}
              label="Add your comment"
              textFieldProps={{
                multiline: true,
                onChange: handleChange,
              }}
            />
          </Grid>

          <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
            <Button
              disabled={isSubmitting}
              onClick={async () => {
                try {
                  await submitCopyDeal(commentText, {
                    value: String(form.getValues("unit")?.value),
                    label: form.getValues("unit")?.label,
                    propertyCode: String(form.getValues("centre")?.value),
                    estimatedArea:
                      parseFloat(form.getValues("estimatedArea")) || 0,
                    virtualUnitName: form.getValues("virtualUnit"),
                    virtualUnitDescription: form.getValues(
                      "virtualUnitDescription"
                    ),
                    virtualUnitType: form.getValues("unitType")?.value,
                  });
                } catch {
                  setErrorSubmit(true);
                }
              }}
              variant="contained"
            >
              Copy
            </Button>

            <Button onClick={clearCloseDialog} variant="text" sx={{ mr: 1 }}>
              Cancel
            </Button>
          </DialogActions>
          <Snackbar
            open={errorSubmit}
            autoHideDuration={6000}
            onClose={closeErrorSubmit}
          >
            <Alert
              onClose={closeErrorSubmit}
              severity="error"
              sx={{ width: "100%" }}
            >
              Something went wrong, try submitting again.
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const CopyDealDialogReLaf = (props: CopyDealRelafDialogProps) => {
  const [isSubmittingRelaf, submitCopyDealRelaf] = useCopyDealReLaf(
    props.dealId
  );
  const [commentText, setcommentText] = React.useState("");
  const [errorSubmit, setErrorSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const closeErrorSubmit = () => {
    setErrorSubmit(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcommentText(event.target.value);
  };
  const clearCloseDialog = () => {
    setOpen(false);
    setcommentText("");
  };
  return (
    <>
      <Button
        disabled={props.disableReLaf}
        onClick={() => setOpen(true)}
        startIcon={<AutorenewIcon />}
      >
        ReLAF
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>ReLAF deal</DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ mb: 1 }}>
            This will put the current deal in Superseded status
          </Alert>
          <FormTextField
            value={commentText}
            label="Add your comment"
            textFieldProps={{
              multiline: true,
              onChange: handleChange,
            }}
          />

          <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
            <Button
              disabled={isSubmittingRelaf}
              onClick={async () => {
                try {
                  await submitCopyDealRelaf(commentText);
                } catch {
                  setErrorSubmit(true);
                }
              }}
              variant="contained"
            >
              ReLAF
            </Button>

            <Button onClick={clearCloseDialog} variant="text" sx={{ mr: 1 }}>
              Cancel
            </Button>
          </DialogActions>
          <Snackbar
            open={errorSubmit}
            autoHideDuration={6000}
            onClose={closeErrorSubmit}
          >
            <Alert
              onClose={closeErrorSubmit}
              severity="error"
              sx={{ width: "100%" }}
            >
              Something went wrong, try submitting again.
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </>
  );
};
