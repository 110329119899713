import * as yup from "yup";

export const DealTypeSchema = yup.object({
  dealType: yup
    .object({
      label: yup.string(),
      value: yup.number(),
    })
    .required("Deal type is a required field")
    .default({ label: "", value: -1 })
    .test(
      "NoDealType",
      "Must select Deal type",
      (value) => !(value.value === -1 && value.label === "")
    ),
  dealComp: yup.string().default(""),
  normalisedBaseRent: yup.string().default(""),
  dealReason: yup.string().default(""),
  normalisedPromo: yup.string().default(""),
});
