import { useMemo } from "react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { subYears } from "date-fns";
import { HistoricalDataType } from "../../../components/DealInformation/HistoricalData/Config";
import {
  GetHistoricalDataDocument,
  UpdateHistoricalDataDocument,
} from "../../../generated/graphql-operations";

export const useGetHistoricalData = (
  dealId: number,
  onError: (error: ApolloError) => void
) => {
  const todayDate = useMemo(() => new Date(), []);
  return useQuery(GetHistoricalDataDocument, {
    variables: {
      deal_id: dealId,
      // insert current date minus 1 year
      filter_tenant_dates: subYears(todayDate, 1),
    },
    onError,
  });
};

export const useMutateHistoricalData = () => {
  const [updateHistoricalData] = useMutation(UpdateHistoricalDataDocument);
  return async (dealDataId: number, data: HistoricalDataType) => {
    await updateHistoricalData({
      variables: {
        deal_data_id: dealDataId,
        historical_base_rent: data.historicalBaseRent ?? null,
        historical_outgoings: data.historicalOutgoings ?? null,
        historical_promo_levy: data.historicalPromoLevy ?? null,
        historical_tenant_name: data.HistoricalTenant.label,
      },
    });
  };
};
