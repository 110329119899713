import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Skeleton,
  Typography,
} from "@mui/material";
import { SelectField, TextNumberField } from "../../Form/Form";
import { FormTextField, FormToggleButton } from "../../Form/FormComponents";
import { DealInformationSectionProps } from "../DealInformationConfig";

import { DEFAULT_VALUES } from "./Config";
import {
  AMOUNT_TYPE_CHOICES,
  GeneralAmountTypes,
  PROMOTION_LEVY_CHOICES,
  PromotionLevyTypes,
  TOGGLE_OPTIONS,
  TOGGLE_OPTIONS_TWO,
  ToggleString,
} from "../../../utils/CommonVariables";
import {
  CurrencyFormatter,
  PercentageFormatterExtraSmall,
} from "../../MuiCustomNumberFormat";
import {
  transformCommercial,
  useGetDealInfoCommercial,
} from "../../../adapters/DealInformation/Commercial/Commercial";
import { Alert } from "../../Alert";

const isDisabledType = (typeValue: string) =>
  (
    [GeneralAmountTypes.CPI, GeneralAmountTypes["Market review"]] as string[]
  ).includes(typeValue);

export const CommercialInfo = (props: DealInformationSectionProps) => {
  const data = useGetDealInfoCommercial(props.dealId);

  const { form, setDataState } = props;

  useEffect(() => {
    if (data.data) {
      form.setValue("commercial", transformCommercial(data.data));
      setDataState((prev) => ({ ...prev, commercialLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, commercialLoaded: false }));
    }
  }, [data, form, setDataState]);

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  const [estimatedArea, commercialObj] = form.watch([
    "unitInfo.estimatedArea",
    "commercial",
  ]);
  const naturalTurnover =
    ((commercialObj?.baseRent ?? Number.NaN) /
      (commercialObj?.turnOverRent ?? Number.NaN)) *
    100;

  const outgoingsAnnualRate =
    (estimatedArea ?? 0) * (commercialObj?.outgoings ?? 0);

  let promotionAnnualRate = 0;
  let promotionAnnualRateBase = 0;
  switch (commercialObj?.promotionLevyType?.value) {
    case PromotionLevyTypes.$:
      promotionAnnualRate = commercialObj?.promotionLevy ?? 0;
      promotionAnnualRateBase = commercialObj?.promotionLevy ?? 0;
      break;
    case PromotionLevyTypes["%"]: {
      const promoLevy = commercialObj?.promotionLevy ?? 0;
      promotionAnnualRate = (promoLevy / 100) * (commercialObj.baseRent ?? 0);
      promotionAnnualRateBase =
        (((commercialObj.grossRent ?? 0) - outgoingsAnnualRate) * promoLevy) /
        (promoLevy + 100);
      break;
    }
    default:
  }

  const calcGrossRent = () =>
    props.form.setValue(
      "commercial.grossRent",
      (commercialObj?.baseRent ?? 0) + outgoingsAnnualRate + promotionAnnualRate
    );

  const calcBaseRent = () =>
    props.form.setValue(
      "commercial.baseRent",
      Math.max(
        0,
        (commercialObj?.grossRent ?? 0) -
          outgoingsAnnualRate -
          promotionAnnualRateBase
      )
    );

  return (
    <Box>
      <Grid container p={1} spacing={1}>
        <Grid item sm={12}>
          <FormTextField
            value={estimatedArea}
            label="Estimated area"
            disabled
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">{"m\u00B2"}</InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        {/** --------------Gross/Base Rent Section--------------  */}
        <Grid item container spacing={1} sm={6}>
          <Grid item sm={12}>
            <TextNumberField
              form={props.form}
              formField={{
                type: "text",
                fieldName: "commercial.grossRent",
                helperText: "",
                label: "Gross rent",
                adornment: { value: "$", position: "start" },
                customComp: CurrencyFormatter,
              }}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={calcGrossRent}
            >
              Calculate gross rent
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} sm={6}>
          <Grid item sm={12}>
            <TextNumberField
              form={props.form}
              formField={{
                type: "text",
                fieldName: "commercial.baseRent",
                helperText: "",
                label: "Base rent (exc GST)",
                adornment: { value: "$", position: "start" },
                customComp: CurrencyFormatter,
              }}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              name="base"
              onClick={calcBaseRent}
            >
              Calculate base rent
            </Button>
          </Grid>
        </Grid>

        {/** --------------Turnover Rent Section--------------  */}
        <Box p={1} width="100%" />

        <Grid item sm={4}>
          <Typography variant="subtitle2">Turnover rent</Typography>
        </Grid>

        <Grid item sm={2}>
          <FormToggleButton
            control={props.form.control}
            name="commercial.turnoverRentToggle"
            options={TOGGLE_OPTIONS}
            onChange={(e) => {
              const targetValue = (e.target as HTMLInputElement).value;
              let valueOverride: number | undefined;
              if (targetValue === ToggleString.Yes) {
                valueOverride = DEFAULT_VALUES.turnOverRent;
              }
              props.form.setValue("commercial.turnOverRent", valueOverride);
              props.form.setValue(
                "commercial.turnOverRentUnnatural",
                undefined
              );
            }}
          />
        </Grid>

        <Box width="100%" />

        <Grid item sm={6}>
          <TextNumberField
            form={props.form}
            formField={{
              disabled: commercialObj?.turnoverRentToggle !== "yes",
              type: "text",
              fieldName: "commercial.turnOverRent",
              helperText: "",
              adornment: { value: "%", position: "start" },
              label: "Turnover",
              customComp: PercentageFormatterExtraSmall,
            }}
          />
        </Grid>

        <Box width="100%" />

        <Grid item sm={6}>
          <FormTextField
            value={naturalTurnover}
            label="Natural turnover rent threshold (exc GST)"
            disabled
            textFieldProps={{
              InputProps: {
                inputComponent: CurrencyFormatter as any,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <TextNumberField
            form={props.form}
            formField={{
              type: "text",
              disabled: commercialObj?.turnoverRentToggle !== "yes",
              fieldName: "commercial.turnOverRentUnnatural",
              helperText: "",
              label: "Unnatural turnover rent threshold (exc GST)",
              adornment: { value: "$", position: "start" },
              customComp: CurrencyFormatter,
            }}
          />
        </Grid>

        {/** --------------Promotion levy Section--------------  */}
        <Box p={1} width="100%" />

        <Grid item sm={4}>
          <Typography variant="subtitle2">Promotion levy</Typography>
        </Grid>

        <Grid item sm={2}>
          <FormToggleButton
            control={props.form.control}
            name="commercial.promotionLevyToggle"
            options={TOGGLE_OPTIONS}
            onChange={(e) => {
              const targetValue = (e.target as HTMLInputElement).value;
              let valueOverride: number | undefined;
              if (targetValue === ToggleString.Yes) {
                valueOverride = DEFAULT_VALUES.promotionLevy;
              }
              props.form.setValue(
                "commercial.promotionLevyType",
                DEFAULT_VALUES.promotionLevyType
              );
              props.form.setValue("commercial.promotionLevy", valueOverride);
            }}
          />
        </Grid>

        <Box width="100%" />

        <Grid item sm={1}>
          <SelectField
            form={props.form}
            formField={{
              disabled: commercialObj?.promotionLevyToggle !== "yes",
              type: "select",
              fieldName: "commercial.promotionLevyType",
              helperText: "",
              choices: PROMOTION_LEVY_CHOICES,
              defaultValue: DEFAULT_VALUES.promotionLevyType,
              label: "Type",
            }}
          />
        </Grid>

        <Grid item sm={5}>
          <TextNumberField
            form={props.form}
            formField={{
              disabled: commercialObj?.promotionLevyToggle !== "yes",
              type: "text",
              fieldName: "commercial.promotionLevy",
              helperText: "",
              label: "Amount",
              customComp: CurrencyFormatter,
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <FormTextField
            value={promotionAnnualRate ?? ""}
            label="Annual rate (exc GST)"
            disabled
            textFieldProps={{
              InputProps: {
                inputComponent: CurrencyFormatter as any,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        {/** --------------Outgoings Section--------------  */}
        <Box p={1} width="100%" />

        <Grid item sm={4}>
          <Typography variant="subtitle2">Outgoings</Typography>
        </Grid>

        <Grid item sm={2}>
          <FormToggleButton
            control={props.form.control}
            name="commercial.outgoingsToggle"
            options={TOGGLE_OPTIONS}
            onChange={() => {
              props.form.setValue("commercial.outgoings", undefined);
            }}
          />
        </Grid>

        <Box width="100%" />

        <Grid item sm={6}>
          <TextNumberField
            form={props.form}
            formField={{
              disabled: commercialObj?.outgoingsToggle !== "yes",
              type: "text",
              fieldName: "commercial.outgoings",
              helperText: "",
              adornment: { value: "$", position: "start" },
              label: "Rate per square metre",
              customComp: CurrencyFormatter,
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <FormTextField
            value={outgoingsAnnualRate ?? ""}
            label="Annual rate (exc GST)"
            disabled
            textFieldProps={{
              InputProps: {
                inputComponent: CurrencyFormatter as any,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        {/** --------------Annual Increase Section--------------  */}
        <Box p={1} width="100%" />

        <Grid item sm={6}>
          <Typography variant="subtitle2">Rent review</Typography>
        </Grid>

        <Box width="100%" />

        <Grid item sm={2}>
          <SelectField
            form={props.form}
            formField={{
              type: "select",
              fieldName: "commercial.annualIncreaseType",
              helperText: "",
              choices: AMOUNT_TYPE_CHOICES,
              defaultValue: DEFAULT_VALUES.annualIncreaseType,
              label: "Type",
              onChange: (_, selectChoice) => {
                if (isDisabledType(selectChoice.value)) {
                  form.setValue("commercial.annualIncrease", undefined);
                }
              },
            }}
          />
        </Grid>

        <Grid item sm={4}>
          <TextNumberField
            form={props.form}
            formField={{
              type: "text",
              fieldName: "commercial.annualIncrease",
              helperText: "",
              label: "Annual increase",
              customComp: CurrencyFormatter,
              disabled: isDisabledType(
                form.watch("commercial.annualIncreaseType")?.value
              ),
            }}
          />
        </Grid>

        {/** --------------Fitout Contribution--------------  */}
        <Box p={1} width="100%" />
        <Grid item sm={6}>
          <Typography variant="subtitle2">Fitout contribution</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item sm={6}>
          <TextNumberField
            form={props.form}
            formField={{
              type: "text",
              fieldName: "commercial.fitoutContribution",
              helperText: "",
              adornment: { value: "$", position: "start" },
              label: "Amount (exc GST)",
              customComp: CurrencyFormatter,
            }}
          />
        </Grid>

        {/** --------------Cash incentive--------------  */}
        <Box width="100%" />
        <Grid item sm={6}>
          <Typography variant="subtitle2">Cash incentive</Typography>
        </Grid>
        <Box width="100%" />

        <Grid item sm={6}>
          <TextNumberField
            form={props.form}
            formField={{
              type: "text",
              fieldName: "commercial.cashIncentive",
              helperText: "",
              adornment: { value: "$", position: "start" },
              label: "Amount (exc GST)",
              customComp: CurrencyFormatter,
            }}
          />
        </Grid>

        {/** --------------Capped occupancy costs--------------  */}
        <Box p={1} width="100%" />

        <Grid item sm={4}>
          <Typography variant="subtitle2">Capped occupancy costs</Typography>
        </Grid>

        <Grid item sm={2}>
          <FormToggleButton
            control={props.form.control}
            name="commercial.cappedOccupancyCostsStateToggle"
            options={TOGGLE_OPTIONS_TWO}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
// Fitout contribution’ and ‘Cash incentive’
