import React from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { TAG_SPACE_CHAR } from "../utils/CommonVariables";
import { GetTagsDocument } from "../generated/graphql-operations";

interface TagBarProps {
  formField: {
    label: string;
    fieldName: string;
    helperText: string;
    placeholder?: string;
  };
  control: Control<any>;
}

export const TagBar = (props: TagBarProps) => {
  const tagData = useQuery(GetTagsDocument);
  const tags = Array.from(
    new Set(tagData?.data?.Tags.map((item) => item.name)) ?? []
  );

  return (
    <>
      <InputLabel>{props.formField.label || TAG_SPACE_CHAR}</InputLabel>
      <Controller
        control={props.control}
        name={props.formField.fieldName}
        defaultValue={[]}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            multiple
            id="tags-filled"
            options={tags}
            autoSelect
            freeSolo
            renderTags={(value: string[], getTagProps) => {
              return value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                fullWidth
                size="small"
                hiddenLabel
                placeholder={props.formField.placeholder ?? ""}
                error={!!fieldState.error?.message}
                helperText={
                  fieldState.error?.message
                    ? fieldState.error?.message
                    : props.formField.helperText
                }
              />
            )}
          />
        )}
      />
    </>
  );
};
