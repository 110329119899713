import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from "msw";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bit: any;
  date: string;
  float8: any;
  jsonb: any;
  money: any;
  numeric: any;
  time: any;
  timestamptz: any;
  uuid: any;
  yardi_deal_sync_status: any;
};

/** columns and relationships of "ActionAllocations" */
export type ActionAllocations = {
  __typename?: "ActionAllocations";
  /** An object relationship */
  AllocatedByUser?: Maybe<Users>;
  /** An object relationship */
  Deal?: Maybe<Deals>;
  /** An object relationship */
  DealActionItem: DealActionItem;
  /** An object relationship */
  User: Users;
  allocated_by?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_action_id: Scalars["bigint"];
  deal_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "ActionAllocations" */
export type ActionAllocations_Aggregate = {
  __typename?: "ActionAllocations_aggregate";
  aggregate?: Maybe<ActionAllocations_Aggregate_Fields>;
  nodes: Array<ActionAllocations>;
};

export type ActionAllocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<ActionAllocations_Aggregate_Bool_Exp_Count>;
};

export type ActionAllocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ActionAllocations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ActionAllocations" */
export type ActionAllocations_Aggregate_Fields = {
  __typename?: "ActionAllocations_aggregate_fields";
  avg?: Maybe<ActionAllocations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<ActionAllocations_Max_Fields>;
  min?: Maybe<ActionAllocations_Min_Fields>;
  stddev?: Maybe<ActionAllocations_Stddev_Fields>;
  stddev_pop?: Maybe<ActionAllocations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActionAllocations_Stddev_Samp_Fields>;
  sum?: Maybe<ActionAllocations_Sum_Fields>;
  var_pop?: Maybe<ActionAllocations_Var_Pop_Fields>;
  var_samp?: Maybe<ActionAllocations_Var_Samp_Fields>;
  variance?: Maybe<ActionAllocations_Variance_Fields>;
};

/** aggregate fields of "ActionAllocations" */
export type ActionAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ActionAllocations" */
export type ActionAllocations_Aggregate_Order_By = {
  avg?: InputMaybe<ActionAllocations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ActionAllocations_Max_Order_By>;
  min?: InputMaybe<ActionAllocations_Min_Order_By>;
  stddev?: InputMaybe<ActionAllocations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ActionAllocations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ActionAllocations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ActionAllocations_Sum_Order_By>;
  var_pop?: InputMaybe<ActionAllocations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ActionAllocations_Var_Samp_Order_By>;
  variance?: InputMaybe<ActionAllocations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ActionAllocations" */
export type ActionAllocations_Arr_Rel_Insert_Input = {
  data: Array<ActionAllocations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionAllocations_On_Conflict>;
};

/** aggregate avg on columns */
export type ActionAllocations_Avg_Fields = {
  __typename?: "ActionAllocations_avg_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ActionAllocations" */
export type ActionAllocations_Avg_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ActionAllocations". All fields are combined with a logical 'AND'. */
export type ActionAllocations_Bool_Exp = {
  AllocatedByUser?: InputMaybe<Users_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  DealActionItem?: InputMaybe<DealActionItem_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<ActionAllocations_Bool_Exp>>;
  _not?: InputMaybe<ActionAllocations_Bool_Exp>;
  _or?: InputMaybe<Array<ActionAllocations_Bool_Exp>>;
  allocated_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_action_id?: InputMaybe<Bigint_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActionAllocations" */
export enum ActionAllocations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActionAllocationsPkey = "ActionAllocations_pkey",
  /** unique or primary key constraint on columns "deal_action_id" */
  ActionallocationsDealIdUnique = "actionallocations_deal_id_unique",
}

/** input type for incrementing numeric columns in table "ActionAllocations" */
export type ActionAllocations_Inc_Input = {
  deal_action_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ActionAllocations" */
export type ActionAllocations_Insert_Input = {
  AllocatedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  DealActionItem?: InputMaybe<DealActionItem_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  allocated_by?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_action_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type ActionAllocations_Max_Fields = {
  __typename?: "ActionAllocations_max_fields";
  allocated_by?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_action_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ActionAllocations" */
export type ActionAllocations_Max_Order_By = {
  allocated_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ActionAllocations_Min_Fields = {
  __typename?: "ActionAllocations_min_fields";
  allocated_by?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_action_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ActionAllocations" */
export type ActionAllocations_Min_Order_By = {
  allocated_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ActionAllocations" */
export type ActionAllocations_Mutation_Response = {
  __typename?: "ActionAllocations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ActionAllocations>;
};

/** input type for inserting object relation for remote table "ActionAllocations" */
export type ActionAllocations_Obj_Rel_Insert_Input = {
  data: ActionAllocations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionAllocations_On_Conflict>;
};

/** on_conflict condition type for table "ActionAllocations" */
export type ActionAllocations_On_Conflict = {
  constraint: ActionAllocations_Constraint;
  update_columns?: Array<ActionAllocations_Update_Column>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** Ordering options when selecting data from "ActionAllocations". */
export type ActionAllocations_Order_By = {
  AllocatedByUser?: InputMaybe<Users_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  DealActionItem?: InputMaybe<DealActionItem_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  allocated_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActionAllocations */
export type ActionAllocations_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "ActionAllocations" */
export enum ActionAllocations_Select_Column {
  /** column name */
  AllocatedBy = "allocated_by",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealActionId = "deal_action_id",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "ActionAllocations" */
export type ActionAllocations_Set_Input = {
  allocated_by?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_action_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type ActionAllocations_Stddev_Fields = {
  __typename?: "ActionAllocations_stddev_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ActionAllocations" */
export type ActionAllocations_Stddev_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ActionAllocations_Stddev_Pop_Fields = {
  __typename?: "ActionAllocations_stddev_pop_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ActionAllocations" */
export type ActionAllocations_Stddev_Pop_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ActionAllocations_Stddev_Samp_Fields = {
  __typename?: "ActionAllocations_stddev_samp_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ActionAllocations" */
export type ActionAllocations_Stddev_Samp_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ActionAllocations" */
export type ActionAllocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActionAllocations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionAllocations_Stream_Cursor_Value_Input = {
  allocated_by?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_action_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type ActionAllocations_Sum_Fields = {
  __typename?: "ActionAllocations_sum_fields";
  deal_action_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ActionAllocations" */
export type ActionAllocations_Sum_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ActionAllocations" */
export enum ActionAllocations_Update_Column {
  /** column name */
  AllocatedBy = "allocated_by",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealActionId = "deal_action_id",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

export type ActionAllocations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActionAllocations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionAllocations_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActionAllocations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActionAllocations_Var_Pop_Fields = {
  __typename?: "ActionAllocations_var_pop_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ActionAllocations" */
export type ActionAllocations_Var_Pop_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ActionAllocations_Var_Samp_Fields = {
  __typename?: "ActionAllocations_var_samp_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ActionAllocations" */
export type ActionAllocations_Var_Samp_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ActionAllocations_Variance_Fields = {
  __typename?: "ActionAllocations_variance_fields";
  deal_action_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ActionAllocations" */
export type ActionAllocations_Variance_Order_By = {
  deal_action_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ActionItems" */
export type ActionItems = {
  __typename?: "ActionItems";
  /** An array relationship */
  PhaseActionItem: Array<PhaseActionItem>;
  /** An aggregate relationship */
  PhaseActionItem_aggregate: PhaseActionItem_Aggregate;
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  metadata?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  required: Scalars["Boolean"];
  state: Scalars["Boolean"];
};

/** columns and relationships of "ActionItems" */
export type ActionItemsPhaseActionItemArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

/** columns and relationships of "ActionItems" */
export type ActionItemsPhaseActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

/** aggregated selection of "ActionItems" */
export type ActionItems_Aggregate = {
  __typename?: "ActionItems_aggregate";
  aggregate?: Maybe<ActionItems_Aggregate_Fields>;
  nodes: Array<ActionItems>;
};

/** aggregate fields of "ActionItems" */
export type ActionItems_Aggregate_Fields = {
  __typename?: "ActionItems_aggregate_fields";
  avg?: Maybe<ActionItems_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<ActionItems_Max_Fields>;
  min?: Maybe<ActionItems_Min_Fields>;
  stddev?: Maybe<ActionItems_Stddev_Fields>;
  stddev_pop?: Maybe<ActionItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActionItems_Stddev_Samp_Fields>;
  sum?: Maybe<ActionItems_Sum_Fields>;
  var_pop?: Maybe<ActionItems_Var_Pop_Fields>;
  var_samp?: Maybe<ActionItems_Var_Samp_Fields>;
  variance?: Maybe<ActionItems_Variance_Fields>;
};

/** aggregate fields of "ActionItems" */
export type ActionItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActionItems_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type ActionItems_Avg_Fields = {
  __typename?: "ActionItems_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "ActionItems". All fields are combined with a logical 'AND'. */
export type ActionItems_Bool_Exp = {
  PhaseActionItem?: InputMaybe<PhaseActionItem_Bool_Exp>;
  PhaseActionItem_aggregate?: InputMaybe<PhaseActionItem_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ActionItems_Bool_Exp>>;
  _not?: InputMaybe<ActionItems_Bool_Exp>;
  _or?: InputMaybe<Array<ActionItems_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  required?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActionItems" */
export enum ActionItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActionItemsPkey = "ActionItems_pkey",
}

/** input type for incrementing numeric columns in table "ActionItems" */
export type ActionItems_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ActionItems" */
export type ActionItems_Insert_Input = {
  PhaseActionItem?: InputMaybe<PhaseActionItem_Arr_Rel_Insert_Input>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type ActionItems_Max_Fields = {
  __typename?: "ActionItems_max_fields";
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type ActionItems_Min_Fields = {
  __typename?: "ActionItems_min_fields";
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "ActionItems" */
export type ActionItems_Mutation_Response = {
  __typename?: "ActionItems_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ActionItems>;
};

/** input type for inserting object relation for remote table "ActionItems" */
export type ActionItems_Obj_Rel_Insert_Input = {
  data: ActionItems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionItems_On_Conflict>;
};

/** on_conflict condition type for table "ActionItems" */
export type ActionItems_On_Conflict = {
  constraint: ActionItems_Constraint;
  update_columns?: Array<ActionItems_Update_Column>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

/** Ordering options when selecting data from "ActionItems". */
export type ActionItems_Order_By = {
  PhaseActionItem_aggregate?: InputMaybe<PhaseActionItem_Aggregate_Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  required?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActionItems */
export type ActionItems_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "ActionItems" */
export enum ActionItems_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
}

/** input type for updating data in table "ActionItems" */
export type ActionItems_Set_Input = {
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type ActionItems_Stddev_Fields = {
  __typename?: "ActionItems_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type ActionItems_Stddev_Pop_Fields = {
  __typename?: "ActionItems_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type ActionItems_Stddev_Samp_Fields = {
  __typename?: "ActionItems_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "ActionItems" */
export type ActionItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActionItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionItems_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate sum on columns */
export type ActionItems_Sum_Fields = {
  __typename?: "ActionItems_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "ActionItems" */
export enum ActionItems_Update_Column {
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
}

export type ActionItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActionItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActionItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActionItems_Var_Pop_Fields = {
  __typename?: "ActionItems_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type ActionItems_Var_Samp_Fields = {
  __typename?: "ActionItems_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type ActionItems_Variance_Fields = {
  __typename?: "ActionItems_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "ActivityLog" */
export type ActivityLog = {
  __typename?: "ActivityLog";
  /** An object relationship */
  Deal: Deals;
  /** An object relationship */
  LogType?: Maybe<ActivityLogType>;
  /** An object relationship */
  User?: Maybe<Users>;
  change_key: Scalars["String"];
  created_at: Scalars["timestamptz"];
  deal_id: Scalars["bigint"];
  display_string?: Maybe<Scalars["String"]>;
  event_id: Scalars["String"];
  id: Scalars["bigint"];
  log_type?: Maybe<ActivityLogType_Enum>;
  new_value: Scalars["String"];
  old_value: Scalars["String"];
  user_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "ActivityLogType" */
export type ActivityLogType = {
  __typename?: "ActivityLogType";
  /** An array relationship */
  ActivityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  ActivityLogs_aggregate: ActivityLog_Aggregate;
  log_type: Scalars["String"];
  log_type_description: Scalars["String"];
};

/** columns and relationships of "ActivityLogType" */
export type ActivityLogTypeActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** columns and relationships of "ActivityLogType" */
export type ActivityLogTypeActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** aggregated selection of "ActivityLogType" */
export type ActivityLogType_Aggregate = {
  __typename?: "ActivityLogType_aggregate";
  aggregate?: Maybe<ActivityLogType_Aggregate_Fields>;
  nodes: Array<ActivityLogType>;
};

/** aggregate fields of "ActivityLogType" */
export type ActivityLogType_Aggregate_Fields = {
  __typename?: "ActivityLogType_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<ActivityLogType_Max_Fields>;
  min?: Maybe<ActivityLogType_Min_Fields>;
};

/** aggregate fields of "ActivityLogType" */
export type ActivityLogType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityLogType_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ActivityLogType". All fields are combined with a logical 'AND'. */
export type ActivityLogType_Bool_Exp = {
  ActivityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ActivityLogType_Bool_Exp>>;
  _not?: InputMaybe<ActivityLogType_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityLogType_Bool_Exp>>;
  log_type?: InputMaybe<String_Comparison_Exp>;
  log_type_description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivityLogType" */
export enum ActivityLogType_Constraint {
  /** unique or primary key constraint on columns "log_type" */
  ActivityLogTypePkey = "ActivityLogType_pkey",
  /** unique or primary key constraint on columns "log_type" */
  ActivitylogtypeLogTypeUnique = "activitylogtype_log_type_unique",
  /** unique or primary key constraint on columns "log_type" */
  Idx_20056UniqueLogType = "idx_20056_unique_log_type",
}

export enum ActivityLogType_Enum {
  /** This log type is used when a deal action item is allocated to a user */
  Alloc = "ALLOC",
  /** This log type is used when risk is changed by the server automatically */
  Autorsk = "AUTORSK",
  /** This log type is used when a new comment is added */
  Comms = "COMMS",
  /** This log type is used when a deal action item is updated */
  Daiup = "DAIUP",
  /** This log type is used when any deal information is changed */
  Dlchg = "DLCHG",
  /** This log type is when the deal phase changes */
  Dlpchg = "DLPCHG",
  /** This log type is used when a new document is uploaded */
  Docup = "DOCUP",
  /** This log type is used when a shop closed notification was sent */
  Dsaclosed = "DSACLOSED",
  /** This log type is used when a shop handover notification was sent */
  Dsahandover = "DSAHANDOVER",
  /** This log type is used when a shop open notification was sent */
  Dsaopen = "DSAOPEN",
  /** This log type is used when a file is uploaded */
  Filup = "FILUP",
  /** This log type is used when LAF is changed or started */
  Laf = "LAF",
}

/** Boolean expression to compare columns of type "ActivityLogType_enum". All fields are combined with logical 'AND'. */
export type ActivityLogType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ActivityLogType_Enum>;
  _in?: InputMaybe<Array<ActivityLogType_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<ActivityLogType_Enum>;
  _nin?: InputMaybe<Array<ActivityLogType_Enum>>;
};

/** input type for inserting data into table "ActivityLogType" */
export type ActivityLogType_Insert_Input = {
  ActivityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  log_type?: InputMaybe<Scalars["String"]>;
  log_type_description?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type ActivityLogType_Max_Fields = {
  __typename?: "ActivityLogType_max_fields";
  log_type?: Maybe<Scalars["String"]>;
  log_type_description?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type ActivityLogType_Min_Fields = {
  __typename?: "ActivityLogType_min_fields";
  log_type?: Maybe<Scalars["String"]>;
  log_type_description?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ActivityLogType" */
export type ActivityLogType_Mutation_Response = {
  __typename?: "ActivityLogType_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityLogType>;
};

/** input type for inserting object relation for remote table "ActivityLogType" */
export type ActivityLogType_Obj_Rel_Insert_Input = {
  data: ActivityLogType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ActivityLogType_On_Conflict>;
};

/** on_conflict condition type for table "ActivityLogType" */
export type ActivityLogType_On_Conflict = {
  constraint: ActivityLogType_Constraint;
  update_columns?: Array<ActivityLogType_Update_Column>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivityLogType". */
export type ActivityLogType_Order_By = {
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  log_type?: InputMaybe<Order_By>;
  log_type_description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActivityLogType */
export type ActivityLogType_Pk_Columns_Input = {
  log_type: Scalars["String"];
};

/** select columns of table "ActivityLogType" */
export enum ActivityLogType_Select_Column {
  /** column name */
  LogType = "log_type",
  /** column name */
  LogTypeDescription = "log_type_description",
}

/** input type for updating data in table "ActivityLogType" */
export type ActivityLogType_Set_Input = {
  log_type?: InputMaybe<Scalars["String"]>;
  log_type_description?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "ActivityLogType" */
export type ActivityLogType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivityLogType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityLogType_Stream_Cursor_Value_Input = {
  log_type?: InputMaybe<Scalars["String"]>;
  log_type_description?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "ActivityLogType" */
export enum ActivityLogType_Update_Column {
  /** column name */
  LogType = "log_type",
  /** column name */
  LogTypeDescription = "log_type_description",
}

export type ActivityLogType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityLogType_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivityLogType_Bool_Exp;
};

/** aggregated selection of "ActivityLog" */
export type ActivityLog_Aggregate = {
  __typename?: "ActivityLog_aggregate";
  aggregate?: Maybe<ActivityLog_Aggregate_Fields>;
  nodes: Array<ActivityLog>;
};

export type ActivityLog_Aggregate_Bool_Exp = {
  count?: InputMaybe<ActivityLog_Aggregate_Bool_Exp_Count>;
};

export type ActivityLog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ActivityLog_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ActivityLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ActivityLog" */
export type ActivityLog_Aggregate_Fields = {
  __typename?: "ActivityLog_aggregate_fields";
  avg?: Maybe<ActivityLog_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<ActivityLog_Max_Fields>;
  min?: Maybe<ActivityLog_Min_Fields>;
  stddev?: Maybe<ActivityLog_Stddev_Fields>;
  stddev_pop?: Maybe<ActivityLog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActivityLog_Stddev_Samp_Fields>;
  sum?: Maybe<ActivityLog_Sum_Fields>;
  var_pop?: Maybe<ActivityLog_Var_Pop_Fields>;
  var_samp?: Maybe<ActivityLog_Var_Samp_Fields>;
  variance?: Maybe<ActivityLog_Variance_Fields>;
};

/** aggregate fields of "ActivityLog" */
export type ActivityLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityLog_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ActivityLog" */
export type ActivityLog_Aggregate_Order_By = {
  avg?: InputMaybe<ActivityLog_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ActivityLog_Max_Order_By>;
  min?: InputMaybe<ActivityLog_Min_Order_By>;
  stddev?: InputMaybe<ActivityLog_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ActivityLog_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ActivityLog_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ActivityLog_Sum_Order_By>;
  var_pop?: InputMaybe<ActivityLog_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ActivityLog_Var_Samp_Order_By>;
  variance?: InputMaybe<ActivityLog_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ActivityLog" */
export type ActivityLog_Arr_Rel_Insert_Input = {
  data: Array<ActivityLog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};

/** aggregate avg on columns */
export type ActivityLog_Avg_Fields = {
  __typename?: "ActivityLog_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ActivityLog" */
export type ActivityLog_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ActivityLog". All fields are combined with a logical 'AND'. */
export type ActivityLog_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  LogType?: InputMaybe<ActivityLogType_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<ActivityLog_Bool_Exp>>;
  _not?: InputMaybe<ActivityLog_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityLog_Bool_Exp>>;
  change_key?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  display_string?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  log_type?: InputMaybe<ActivityLogType_Enum_Comparison_Exp>;
  new_value?: InputMaybe<String_Comparison_Exp>;
  old_value?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivityLog" */
export enum ActivityLog_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityLogPkey = "ActivityLog_pkey",
}

/** input type for incrementing numeric columns in table "ActivityLog" */
export type ActivityLog_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ActivityLog" */
export type ActivityLog_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  LogType?: InputMaybe<ActivityLogType_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  change_key?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  display_string?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  log_type?: InputMaybe<ActivityLogType_Enum>;
  new_value?: InputMaybe<Scalars["String"]>;
  old_value?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type ActivityLog_Max_Fields = {
  __typename?: "ActivityLog_max_fields";
  change_key?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  display_string?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  new_value?: Maybe<Scalars["String"]>;
  old_value?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ActivityLog" */
export type ActivityLog_Max_Order_By = {
  change_key?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  display_string?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_value?: InputMaybe<Order_By>;
  old_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ActivityLog_Min_Fields = {
  __typename?: "ActivityLog_min_fields";
  change_key?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  display_string?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  new_value?: Maybe<Scalars["String"]>;
  old_value?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ActivityLog" */
export type ActivityLog_Min_Order_By = {
  change_key?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  display_string?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_value?: InputMaybe<Order_By>;
  old_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ActivityLog" */
export type ActivityLog_Mutation_Response = {
  __typename?: "ActivityLog_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityLog>;
};

/** on_conflict condition type for table "ActivityLog" */
export type ActivityLog_On_Conflict = {
  constraint: ActivityLog_Constraint;
  update_columns?: Array<ActivityLog_Update_Column>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivityLog". */
export type ActivityLog_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  LogType?: InputMaybe<ActivityLogType_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  change_key?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  display_string?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  new_value?: InputMaybe<Order_By>;
  old_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActivityLog */
export type ActivityLog_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "ActivityLog" */
export enum ActivityLog_Select_Column {
  /** column name */
  ChangeKey = "change_key",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DisplayString = "display_string",
  /** column name */
  EventId = "event_id",
  /** column name */
  Id = "id",
  /** column name */
  LogType = "log_type",
  /** column name */
  NewValue = "new_value",
  /** column name */
  OldValue = "old_value",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "ActivityLog" */
export type ActivityLog_Set_Input = {
  change_key?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  display_string?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  log_type?: InputMaybe<ActivityLogType_Enum>;
  new_value?: InputMaybe<Scalars["String"]>;
  old_value?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type ActivityLog_Stddev_Fields = {
  __typename?: "ActivityLog_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ActivityLog" */
export type ActivityLog_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ActivityLog_Stddev_Pop_Fields = {
  __typename?: "ActivityLog_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ActivityLog" */
export type ActivityLog_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ActivityLog_Stddev_Samp_Fields = {
  __typename?: "ActivityLog_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ActivityLog" */
export type ActivityLog_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ActivityLog" */
export type ActivityLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivityLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityLog_Stream_Cursor_Value_Input = {
  change_key?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  display_string?: InputMaybe<Scalars["String"]>;
  event_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  log_type?: InputMaybe<ActivityLogType_Enum>;
  new_value?: InputMaybe<Scalars["String"]>;
  old_value?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type ActivityLog_Sum_Fields = {
  __typename?: "ActivityLog_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "ActivityLog" */
export type ActivityLog_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ActivityLog" */
export enum ActivityLog_Update_Column {
  /** column name */
  ChangeKey = "change_key",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DisplayString = "display_string",
  /** column name */
  EventId = "event_id",
  /** column name */
  Id = "id",
  /** column name */
  LogType = "log_type",
  /** column name */
  NewValue = "new_value",
  /** column name */
  OldValue = "old_value",
  /** column name */
  UserId = "user_id",
}

export type ActivityLog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActivityLog_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivityLog_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActivityLog_Var_Pop_Fields = {
  __typename?: "ActivityLog_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ActivityLog" */
export type ActivityLog_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ActivityLog_Var_Samp_Fields = {
  __typename?: "ActivityLog_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ActivityLog" */
export type ActivityLog_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ActivityLog_Variance_Fields = {
  __typename?: "ActivityLog_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ActivityLog" */
export type ActivityLog_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Agreements" */
export type Agreements = {
  __typename?: "Agreements";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Agreements" */
export type Agreements_Aggregate = {
  __typename?: "Agreements_aggregate";
  aggregate?: Maybe<Agreements_Aggregate_Fields>;
  nodes: Array<Agreements>;
};

export type Agreements_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agreements_Aggregate_Bool_Exp_Count>;
};

export type Agreements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agreements_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Agreements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Agreements" */
export type Agreements_Aggregate_Fields = {
  __typename?: "Agreements_aggregate_fields";
  avg?: Maybe<Agreements_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Agreements_Max_Fields>;
  min?: Maybe<Agreements_Min_Fields>;
  stddev?: Maybe<Agreements_Stddev_Fields>;
  stddev_pop?: Maybe<Agreements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agreements_Stddev_Samp_Fields>;
  sum?: Maybe<Agreements_Sum_Fields>;
  var_pop?: Maybe<Agreements_Var_Pop_Fields>;
  var_samp?: Maybe<Agreements_Var_Samp_Fields>;
  variance?: Maybe<Agreements_Variance_Fields>;
};

/** aggregate fields of "Agreements" */
export type Agreements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agreements_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Agreements" */
export type Agreements_Aggregate_Order_By = {
  avg?: InputMaybe<Agreements_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Agreements_Max_Order_By>;
  min?: InputMaybe<Agreements_Min_Order_By>;
  stddev?: InputMaybe<Agreements_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agreements_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agreements_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agreements_Sum_Order_By>;
  var_pop?: InputMaybe<Agreements_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agreements_Var_Samp_Order_By>;
  variance?: InputMaybe<Agreements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Agreements" */
export type Agreements_Arr_Rel_Insert_Input = {
  data: Array<Agreements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Agreements_On_Conflict>;
};

/** aggregate avg on columns */
export type Agreements_Avg_Fields = {
  __typename?: "Agreements_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Agreements" */
export type Agreements_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Agreements". All fields are combined with a logical 'AND'. */
export type Agreements_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Agreements_Bool_Exp>>;
  _not?: InputMaybe<Agreements_Bool_Exp>;
  _or?: InputMaybe<Array<Agreements_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Agreements" */
export enum Agreements_Constraint {
  /** unique or primary key constraint on columns "id" */
  AgreementsPkey = "Agreements_pkey",
}

/** input type for incrementing numeric columns in table "Agreements" */
export type Agreements_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Agreements" */
export type Agreements_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Agreements_Max_Fields = {
  __typename?: "Agreements_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Agreements" */
export type Agreements_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Agreements_Min_Fields = {
  __typename?: "Agreements_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Agreements" */
export type Agreements_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Agreements" */
export type Agreements_Mutation_Response = {
  __typename?: "Agreements_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Agreements>;
};

/** on_conflict condition type for table "Agreements" */
export type Agreements_On_Conflict = {
  constraint: Agreements_Constraint;
  update_columns?: Array<Agreements_Update_Column>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

/** Ordering options when selecting data from "Agreements". */
export type Agreements_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Agreements */
export type Agreements_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Agreements" */
export enum Agreements_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Agreements" */
export type Agreements_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Agreements_Stddev_Fields = {
  __typename?: "Agreements_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Agreements" */
export type Agreements_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Agreements_Stddev_Pop_Fields = {
  __typename?: "Agreements_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Agreements" */
export type Agreements_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Agreements_Stddev_Samp_Fields = {
  __typename?: "Agreements_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Agreements" */
export type Agreements_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Agreements" */
export type Agreements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agreements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agreements_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Agreements_Sum_Fields = {
  __typename?: "Agreements_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Agreements" */
export type Agreements_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Agreements" */
export enum Agreements_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Agreements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agreements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agreements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agreements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Agreements_Var_Pop_Fields = {
  __typename?: "Agreements_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Agreements" */
export type Agreements_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Agreements_Var_Samp_Fields = {
  __typename?: "Agreements_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Agreements" */
export type Agreements_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Agreements_Variance_Fields = {
  __typename?: "Agreements_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Agreements" */
export type Agreements_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Approvers" */
export type Approvers = {
  __typename?: "Approvers";
  /** An array relationship */
  CenterApprovers: Array<CenterApprovers>;
  /** An aggregate relationship */
  CenterApprovers_aggregate: CenterApprovers_Aggregate;
  /** An object relationship */
  User: Users;
  id: Scalars["bigint"];
  level?: Maybe<Scalars["Int"]>;
  user_id: Scalars["uuid"];
};

/** columns and relationships of "Approvers" */
export type ApproversCenterApproversArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

/** columns and relationships of "Approvers" */
export type ApproversCenterApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

/** aggregated selection of "Approvers" */
export type Approvers_Aggregate = {
  __typename?: "Approvers_aggregate";
  aggregate?: Maybe<Approvers_Aggregate_Fields>;
  nodes: Array<Approvers>;
};

export type Approvers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Approvers_Aggregate_Bool_Exp_Count>;
};

export type Approvers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Approvers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Approvers" */
export type Approvers_Aggregate_Fields = {
  __typename?: "Approvers_aggregate_fields";
  avg?: Maybe<Approvers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Approvers_Max_Fields>;
  min?: Maybe<Approvers_Min_Fields>;
  stddev?: Maybe<Approvers_Stddev_Fields>;
  stddev_pop?: Maybe<Approvers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Approvers_Stddev_Samp_Fields>;
  sum?: Maybe<Approvers_Sum_Fields>;
  var_pop?: Maybe<Approvers_Var_Pop_Fields>;
  var_samp?: Maybe<Approvers_Var_Samp_Fields>;
  variance?: Maybe<Approvers_Variance_Fields>;
};

/** aggregate fields of "Approvers" */
export type Approvers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Approvers" */
export type Approvers_Aggregate_Order_By = {
  avg?: InputMaybe<Approvers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Approvers_Max_Order_By>;
  min?: InputMaybe<Approvers_Min_Order_By>;
  stddev?: InputMaybe<Approvers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Approvers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Approvers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Approvers_Sum_Order_By>;
  var_pop?: InputMaybe<Approvers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Approvers_Var_Samp_Order_By>;
  variance?: InputMaybe<Approvers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Approvers" */
export type Approvers_Arr_Rel_Insert_Input = {
  data: Array<Approvers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Approvers_On_Conflict>;
};

/** aggregate avg on columns */
export type Approvers_Avg_Fields = {
  __typename?: "Approvers_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Approvers" */
export type Approvers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Approvers". All fields are combined with a logical 'AND'. */
export type Approvers_Bool_Exp = {
  CenterApprovers?: InputMaybe<CenterApprovers_Bool_Exp>;
  CenterApprovers_aggregate?: InputMaybe<CenterApprovers_Aggregate_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Approvers_Bool_Exp>>;
  _not?: InputMaybe<Approvers_Bool_Exp>;
  _or?: InputMaybe<Array<Approvers_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Approvers" */
export enum Approvers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApproversPkey = "Approvers_pkey",
}

/** input type for incrementing numeric columns in table "Approvers" */
export type Approvers_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Approvers" */
export type Approvers_Insert_Input = {
  CenterApprovers?: InputMaybe<CenterApprovers_Arr_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Approvers_Max_Fields = {
  __typename?: "Approvers_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Approvers" */
export type Approvers_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Approvers_Min_Fields = {
  __typename?: "Approvers_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Approvers" */
export type Approvers_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Approvers" */
export type Approvers_Mutation_Response = {
  __typename?: "Approvers_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Approvers>;
};

/** input type for inserting object relation for remote table "Approvers" */
export type Approvers_Obj_Rel_Insert_Input = {
  data: Approvers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Approvers_On_Conflict>;
};

/** on_conflict condition type for table "Approvers" */
export type Approvers_On_Conflict = {
  constraint: Approvers_Constraint;
  update_columns?: Array<Approvers_Update_Column>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

/** Ordering options when selecting data from "Approvers". */
export type Approvers_Order_By = {
  CenterApprovers_aggregate?: InputMaybe<CenterApprovers_Aggregate_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Approvers */
export type Approvers_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Approvers" */
export enum Approvers_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Level = "level",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "Approvers" */
export type Approvers_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Approvers_Stddev_Fields = {
  __typename?: "Approvers_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Approvers" */
export type Approvers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Approvers_Stddev_Pop_Fields = {
  __typename?: "Approvers_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Approvers" */
export type Approvers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Approvers_Stddev_Samp_Fields = {
  __typename?: "Approvers_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Approvers" */
export type Approvers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Approvers" */
export type Approvers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approvers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approvers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Approvers_Sum_Fields = {
  __typename?: "Approvers_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Approvers" */
export type Approvers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** update columns of table "Approvers" */
export enum Approvers_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Level = "level",
  /** column name */
  UserId = "user_id",
}

export type Approvers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Approvers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Approvers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Approvers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Approvers_Var_Pop_Fields = {
  __typename?: "Approvers_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Approvers" */
export type Approvers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Approvers_Var_Samp_Fields = {
  __typename?: "Approvers_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Approvers" */
export type Approvers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Approvers_Variance_Fields = {
  __typename?: "Approvers_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Approvers" */
export type Approvers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** columns and relationships of "BaseLandlordServices" */
export type BaseLandlordServices = {
  __typename?: "BaseLandlordServices";
  /** An object relationship */
  DealData?: Maybe<DealData>;
  air_conditioning?: Maybe<Scalars["Boolean"]>;
  as_per_existing_toggle?: Maybe<Scalars["Boolean"]>;
  cool_room?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  electrical_dist_load_1_phase?: Maybe<Scalars["Boolean"]>;
  electrical_dist_load_3_phase?: Maybe<Scalars["Boolean"]>;
  floor_covering?: Maybe<Scalars["Boolean"]>;
  grease_trap?: Maybe<Scalars["Boolean"]>;
  hot_water_services?: Maybe<Scalars["Boolean"]>;
  id: Scalars["bigint"];
  lighting?: Maybe<Scalars["Boolean"]>;
  mechanical_exhaust?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  other?: Maybe<Scalars["Boolean"]>;
  other_description?: Maybe<Scalars["String"]>;
  painted_walls?: Maybe<Scalars["Boolean"]>;
  plastered_walls_unpainted?: Maybe<Scalars["Boolean"]>;
  separate_utility_meter_electricity?: Maybe<Scalars["Boolean"]>;
  separate_utility_meter_gas?: Maybe<Scalars["Boolean"]>;
  separate_utility_meter_water?: Maybe<Scalars["Boolean"]>;
  shop_front?: Maybe<Scalars["Boolean"]>;
  sink?: Maybe<Scalars["Boolean"]>;
  sprinklers?: Maybe<Scalars["Boolean"]>;
  suspended_ceilings?: Maybe<Scalars["Boolean"]>;
  telephone?: Maybe<Scalars["Boolean"]>;
  waste?: Maybe<Scalars["Boolean"]>;
  water_supply?: Maybe<Scalars["Boolean"]>;
};

/** aggregated selection of "BaseLandlordServices" */
export type BaseLandlordServices_Aggregate = {
  __typename?: "BaseLandlordServices_aggregate";
  aggregate?: Maybe<BaseLandlordServices_Aggregate_Fields>;
  nodes: Array<BaseLandlordServices>;
};

export type BaseLandlordServices_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<BaseLandlordServices_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<BaseLandlordServices_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<BaseLandlordServices_Aggregate_Bool_Exp_Count>;
};

export type BaseLandlordServices_Aggregate_Bool_Exp_Bool_And = {
  arguments: BaseLandlordServices_Select_Column_BaseLandlordServices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<BaseLandlordServices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BaseLandlordServices_Aggregate_Bool_Exp_Bool_Or = {
  arguments: BaseLandlordServices_Select_Column_BaseLandlordServices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<BaseLandlordServices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BaseLandlordServices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<BaseLandlordServices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BaseLandlordServices" */
export type BaseLandlordServices_Aggregate_Fields = {
  __typename?: "BaseLandlordServices_aggregate_fields";
  avg?: Maybe<BaseLandlordServices_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<BaseLandlordServices_Max_Fields>;
  min?: Maybe<BaseLandlordServices_Min_Fields>;
  stddev?: Maybe<BaseLandlordServices_Stddev_Fields>;
  stddev_pop?: Maybe<BaseLandlordServices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BaseLandlordServices_Stddev_Samp_Fields>;
  sum?: Maybe<BaseLandlordServices_Sum_Fields>;
  var_pop?: Maybe<BaseLandlordServices_Var_Pop_Fields>;
  var_samp?: Maybe<BaseLandlordServices_Var_Samp_Fields>;
  variance?: Maybe<BaseLandlordServices_Variance_Fields>;
};

/** aggregate fields of "BaseLandlordServices" */
export type BaseLandlordServices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "BaseLandlordServices" */
export type BaseLandlordServices_Aggregate_Order_By = {
  avg?: InputMaybe<BaseLandlordServices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BaseLandlordServices_Max_Order_By>;
  min?: InputMaybe<BaseLandlordServices_Min_Order_By>;
  stddev?: InputMaybe<BaseLandlordServices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<BaseLandlordServices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<BaseLandlordServices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<BaseLandlordServices_Sum_Order_By>;
  var_pop?: InputMaybe<BaseLandlordServices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<BaseLandlordServices_Var_Samp_Order_By>;
  variance?: InputMaybe<BaseLandlordServices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "BaseLandlordServices" */
export type BaseLandlordServices_Arr_Rel_Insert_Input = {
  data: Array<BaseLandlordServices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BaseLandlordServices_On_Conflict>;
};

/** aggregate avg on columns */
export type BaseLandlordServices_Avg_Fields = {
  __typename?: "BaseLandlordServices_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BaseLandlordServices". All fields are combined with a logical 'AND'. */
export type BaseLandlordServices_Bool_Exp = {
  DealData?: InputMaybe<DealData_Bool_Exp>;
  _and?: InputMaybe<Array<BaseLandlordServices_Bool_Exp>>;
  _not?: InputMaybe<BaseLandlordServices_Bool_Exp>;
  _or?: InputMaybe<Array<BaseLandlordServices_Bool_Exp>>;
  air_conditioning?: InputMaybe<Boolean_Comparison_Exp>;
  as_per_existing_toggle?: InputMaybe<Boolean_Comparison_Exp>;
  cool_room?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  electrical_dist_load_1_phase?: InputMaybe<Boolean_Comparison_Exp>;
  electrical_dist_load_3_phase?: InputMaybe<Boolean_Comparison_Exp>;
  floor_covering?: InputMaybe<Boolean_Comparison_Exp>;
  grease_trap?: InputMaybe<Boolean_Comparison_Exp>;
  hot_water_services?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lighting?: InputMaybe<Boolean_Comparison_Exp>;
  mechanical_exhaust?: InputMaybe<Boolean_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  other?: InputMaybe<Boolean_Comparison_Exp>;
  other_description?: InputMaybe<String_Comparison_Exp>;
  painted_walls?: InputMaybe<Boolean_Comparison_Exp>;
  plastered_walls_unpainted?: InputMaybe<Boolean_Comparison_Exp>;
  separate_utility_meter_electricity?: InputMaybe<Boolean_Comparison_Exp>;
  separate_utility_meter_gas?: InputMaybe<Boolean_Comparison_Exp>;
  separate_utility_meter_water?: InputMaybe<Boolean_Comparison_Exp>;
  shop_front?: InputMaybe<Boolean_Comparison_Exp>;
  sink?: InputMaybe<Boolean_Comparison_Exp>;
  sprinklers?: InputMaybe<Boolean_Comparison_Exp>;
  suspended_ceilings?: InputMaybe<Boolean_Comparison_Exp>;
  telephone?: InputMaybe<Boolean_Comparison_Exp>;
  waste?: InputMaybe<Boolean_Comparison_Exp>;
  water_supply?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "BaseLandlordServices" */
export enum BaseLandlordServices_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseLandlordServicesPkey = "BaseLandlordServices_pkey",
}

/** input type for incrementing numeric columns in table "BaseLandlordServices" */
export type BaseLandlordServices_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "BaseLandlordServices" */
export type BaseLandlordServices_Insert_Input = {
  DealData?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  air_conditioning?: InputMaybe<Scalars["Boolean"]>;
  as_per_existing_toggle?: InputMaybe<Scalars["Boolean"]>;
  cool_room?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  electrical_dist_load_1_phase?: InputMaybe<Scalars["Boolean"]>;
  electrical_dist_load_3_phase?: InputMaybe<Scalars["Boolean"]>;
  floor_covering?: InputMaybe<Scalars["Boolean"]>;
  grease_trap?: InputMaybe<Scalars["Boolean"]>;
  hot_water_services?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  lighting?: InputMaybe<Scalars["Boolean"]>;
  mechanical_exhaust?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other?: InputMaybe<Scalars["Boolean"]>;
  other_description?: InputMaybe<Scalars["String"]>;
  painted_walls?: InputMaybe<Scalars["Boolean"]>;
  plastered_walls_unpainted?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_electricity?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_gas?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_water?: InputMaybe<Scalars["Boolean"]>;
  shop_front?: InputMaybe<Scalars["Boolean"]>;
  sink?: InputMaybe<Scalars["Boolean"]>;
  sprinklers?: InputMaybe<Scalars["Boolean"]>;
  suspended_ceilings?: InputMaybe<Scalars["Boolean"]>;
  telephone?: InputMaybe<Scalars["Boolean"]>;
  waste?: InputMaybe<Scalars["Boolean"]>;
  water_supply?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type BaseLandlordServices_Max_Fields = {
  __typename?: "BaseLandlordServices_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  other_description?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  other_description?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BaseLandlordServices_Min_Fields = {
  __typename?: "BaseLandlordServices_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  other_description?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  other_description?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BaseLandlordServices" */
export type BaseLandlordServices_Mutation_Response = {
  __typename?: "BaseLandlordServices_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<BaseLandlordServices>;
};

/** on_conflict condition type for table "BaseLandlordServices" */
export type BaseLandlordServices_On_Conflict = {
  constraint: BaseLandlordServices_Constraint;
  update_columns?: Array<BaseLandlordServices_Update_Column>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

/** Ordering options when selecting data from "BaseLandlordServices". */
export type BaseLandlordServices_Order_By = {
  DealData?: InputMaybe<DealData_Order_By>;
  air_conditioning?: InputMaybe<Order_By>;
  as_per_existing_toggle?: InputMaybe<Order_By>;
  cool_room?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  electrical_dist_load_1_phase?: InputMaybe<Order_By>;
  electrical_dist_load_3_phase?: InputMaybe<Order_By>;
  floor_covering?: InputMaybe<Order_By>;
  grease_trap?: InputMaybe<Order_By>;
  hot_water_services?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lighting?: InputMaybe<Order_By>;
  mechanical_exhaust?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  other?: InputMaybe<Order_By>;
  other_description?: InputMaybe<Order_By>;
  painted_walls?: InputMaybe<Order_By>;
  plastered_walls_unpainted?: InputMaybe<Order_By>;
  separate_utility_meter_electricity?: InputMaybe<Order_By>;
  separate_utility_meter_gas?: InputMaybe<Order_By>;
  separate_utility_meter_water?: InputMaybe<Order_By>;
  shop_front?: InputMaybe<Order_By>;
  sink?: InputMaybe<Order_By>;
  sprinklers?: InputMaybe<Order_By>;
  suspended_ceilings?: InputMaybe<Order_By>;
  telephone?: InputMaybe<Order_By>;
  waste?: InputMaybe<Order_By>;
  water_supply?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BaseLandlordServices */
export type BaseLandlordServices_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "BaseLandlordServices" */
export enum BaseLandlordServices_Select_Column {
  /** column name */
  AirConditioning = "air_conditioning",
  /** column name */
  AsPerExistingToggle = "as_per_existing_toggle",
  /** column name */
  CoolRoom = "cool_room",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  ElectricalDistLoad_1Phase = "electrical_dist_load_1_phase",
  /** column name */
  ElectricalDistLoad_3Phase = "electrical_dist_load_3_phase",
  /** column name */
  FloorCovering = "floor_covering",
  /** column name */
  GreaseTrap = "grease_trap",
  /** column name */
  HotWaterServices = "hot_water_services",
  /** column name */
  Id = "id",
  /** column name */
  Lighting = "lighting",
  /** column name */
  MechanicalExhaust = "mechanical_exhaust",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Other = "other",
  /** column name */
  OtherDescription = "other_description",
  /** column name */
  PaintedWalls = "painted_walls",
  /** column name */
  PlasteredWallsUnpainted = "plastered_walls_unpainted",
  /** column name */
  SeparateUtilityMeterElectricity = "separate_utility_meter_electricity",
  /** column name */
  SeparateUtilityMeterGas = "separate_utility_meter_gas",
  /** column name */
  SeparateUtilityMeterWater = "separate_utility_meter_water",
  /** column name */
  ShopFront = "shop_front",
  /** column name */
  Sink = "sink",
  /** column name */
  Sprinklers = "sprinklers",
  /** column name */
  SuspendedCeilings = "suspended_ceilings",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Waste = "waste",
  /** column name */
  WaterSupply = "water_supply",
}

/** select "BaseLandlordServices_aggregate_bool_exp_bool_and_arguments_columns" columns of table "BaseLandlordServices" */
export enum BaseLandlordServices_Select_Column_BaseLandlordServices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AirConditioning = "air_conditioning",
  /** column name */
  AsPerExistingToggle = "as_per_existing_toggle",
  /** column name */
  CoolRoom = "cool_room",
  /** column name */
  ElectricalDistLoad_1Phase = "electrical_dist_load_1_phase",
  /** column name */
  ElectricalDistLoad_3Phase = "electrical_dist_load_3_phase",
  /** column name */
  FloorCovering = "floor_covering",
  /** column name */
  GreaseTrap = "grease_trap",
  /** column name */
  HotWaterServices = "hot_water_services",
  /** column name */
  Lighting = "lighting",
  /** column name */
  MechanicalExhaust = "mechanical_exhaust",
  /** column name */
  Other = "other",
  /** column name */
  PaintedWalls = "painted_walls",
  /** column name */
  PlasteredWallsUnpainted = "plastered_walls_unpainted",
  /** column name */
  SeparateUtilityMeterElectricity = "separate_utility_meter_electricity",
  /** column name */
  SeparateUtilityMeterGas = "separate_utility_meter_gas",
  /** column name */
  SeparateUtilityMeterWater = "separate_utility_meter_water",
  /** column name */
  ShopFront = "shop_front",
  /** column name */
  Sink = "sink",
  /** column name */
  Sprinklers = "sprinklers",
  /** column name */
  SuspendedCeilings = "suspended_ceilings",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Waste = "waste",
  /** column name */
  WaterSupply = "water_supply",
}

/** select "BaseLandlordServices_aggregate_bool_exp_bool_or_arguments_columns" columns of table "BaseLandlordServices" */
export enum BaseLandlordServices_Select_Column_BaseLandlordServices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AirConditioning = "air_conditioning",
  /** column name */
  AsPerExistingToggle = "as_per_existing_toggle",
  /** column name */
  CoolRoom = "cool_room",
  /** column name */
  ElectricalDistLoad_1Phase = "electrical_dist_load_1_phase",
  /** column name */
  ElectricalDistLoad_3Phase = "electrical_dist_load_3_phase",
  /** column name */
  FloorCovering = "floor_covering",
  /** column name */
  GreaseTrap = "grease_trap",
  /** column name */
  HotWaterServices = "hot_water_services",
  /** column name */
  Lighting = "lighting",
  /** column name */
  MechanicalExhaust = "mechanical_exhaust",
  /** column name */
  Other = "other",
  /** column name */
  PaintedWalls = "painted_walls",
  /** column name */
  PlasteredWallsUnpainted = "plastered_walls_unpainted",
  /** column name */
  SeparateUtilityMeterElectricity = "separate_utility_meter_electricity",
  /** column name */
  SeparateUtilityMeterGas = "separate_utility_meter_gas",
  /** column name */
  SeparateUtilityMeterWater = "separate_utility_meter_water",
  /** column name */
  ShopFront = "shop_front",
  /** column name */
  Sink = "sink",
  /** column name */
  Sprinklers = "sprinklers",
  /** column name */
  SuspendedCeilings = "suspended_ceilings",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Waste = "waste",
  /** column name */
  WaterSupply = "water_supply",
}

/** input type for updating data in table "BaseLandlordServices" */
export type BaseLandlordServices_Set_Input = {
  air_conditioning?: InputMaybe<Scalars["Boolean"]>;
  as_per_existing_toggle?: InputMaybe<Scalars["Boolean"]>;
  cool_room?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  electrical_dist_load_1_phase?: InputMaybe<Scalars["Boolean"]>;
  electrical_dist_load_3_phase?: InputMaybe<Scalars["Boolean"]>;
  floor_covering?: InputMaybe<Scalars["Boolean"]>;
  grease_trap?: InputMaybe<Scalars["Boolean"]>;
  hot_water_services?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  lighting?: InputMaybe<Scalars["Boolean"]>;
  mechanical_exhaust?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other?: InputMaybe<Scalars["Boolean"]>;
  other_description?: InputMaybe<Scalars["String"]>;
  painted_walls?: InputMaybe<Scalars["Boolean"]>;
  plastered_walls_unpainted?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_electricity?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_gas?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_water?: InputMaybe<Scalars["Boolean"]>;
  shop_front?: InputMaybe<Scalars["Boolean"]>;
  sink?: InputMaybe<Scalars["Boolean"]>;
  sprinklers?: InputMaybe<Scalars["Boolean"]>;
  suspended_ceilings?: InputMaybe<Scalars["Boolean"]>;
  telephone?: InputMaybe<Scalars["Boolean"]>;
  waste?: InputMaybe<Scalars["Boolean"]>;
  water_supply?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type BaseLandlordServices_Stddev_Fields = {
  __typename?: "BaseLandlordServices_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BaseLandlordServices_Stddev_Pop_Fields = {
  __typename?: "BaseLandlordServices_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BaseLandlordServices_Stddev_Samp_Fields = {
  __typename?: "BaseLandlordServices_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "BaseLandlordServices" */
export type BaseLandlordServices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BaseLandlordServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BaseLandlordServices_Stream_Cursor_Value_Input = {
  air_conditioning?: InputMaybe<Scalars["Boolean"]>;
  as_per_existing_toggle?: InputMaybe<Scalars["Boolean"]>;
  cool_room?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  electrical_dist_load_1_phase?: InputMaybe<Scalars["Boolean"]>;
  electrical_dist_load_3_phase?: InputMaybe<Scalars["Boolean"]>;
  floor_covering?: InputMaybe<Scalars["Boolean"]>;
  grease_trap?: InputMaybe<Scalars["Boolean"]>;
  hot_water_services?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  lighting?: InputMaybe<Scalars["Boolean"]>;
  mechanical_exhaust?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other?: InputMaybe<Scalars["Boolean"]>;
  other_description?: InputMaybe<Scalars["String"]>;
  painted_walls?: InputMaybe<Scalars["Boolean"]>;
  plastered_walls_unpainted?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_electricity?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_gas?: InputMaybe<Scalars["Boolean"]>;
  separate_utility_meter_water?: InputMaybe<Scalars["Boolean"]>;
  shop_front?: InputMaybe<Scalars["Boolean"]>;
  sink?: InputMaybe<Scalars["Boolean"]>;
  sprinklers?: InputMaybe<Scalars["Boolean"]>;
  suspended_ceilings?: InputMaybe<Scalars["Boolean"]>;
  telephone?: InputMaybe<Scalars["Boolean"]>;
  waste?: InputMaybe<Scalars["Boolean"]>;
  water_supply?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate sum on columns */
export type BaseLandlordServices_Sum_Fields = {
  __typename?: "BaseLandlordServices_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "BaseLandlordServices" */
export enum BaseLandlordServices_Update_Column {
  /** column name */
  AirConditioning = "air_conditioning",
  /** column name */
  AsPerExistingToggle = "as_per_existing_toggle",
  /** column name */
  CoolRoom = "cool_room",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  ElectricalDistLoad_1Phase = "electrical_dist_load_1_phase",
  /** column name */
  ElectricalDistLoad_3Phase = "electrical_dist_load_3_phase",
  /** column name */
  FloorCovering = "floor_covering",
  /** column name */
  GreaseTrap = "grease_trap",
  /** column name */
  HotWaterServices = "hot_water_services",
  /** column name */
  Id = "id",
  /** column name */
  Lighting = "lighting",
  /** column name */
  MechanicalExhaust = "mechanical_exhaust",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Other = "other",
  /** column name */
  OtherDescription = "other_description",
  /** column name */
  PaintedWalls = "painted_walls",
  /** column name */
  PlasteredWallsUnpainted = "plastered_walls_unpainted",
  /** column name */
  SeparateUtilityMeterElectricity = "separate_utility_meter_electricity",
  /** column name */
  SeparateUtilityMeterGas = "separate_utility_meter_gas",
  /** column name */
  SeparateUtilityMeterWater = "separate_utility_meter_water",
  /** column name */
  ShopFront = "shop_front",
  /** column name */
  Sink = "sink",
  /** column name */
  Sprinklers = "sprinklers",
  /** column name */
  SuspendedCeilings = "suspended_ceilings",
  /** column name */
  Telephone = "telephone",
  /** column name */
  Waste = "waste",
  /** column name */
  WaterSupply = "water_supply",
}

export type BaseLandlordServices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BaseLandlordServices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BaseLandlordServices_Set_Input>;
  /** filter the rows which have to be updated */
  where: BaseLandlordServices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BaseLandlordServices_Var_Pop_Fields = {
  __typename?: "BaseLandlordServices_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BaseLandlordServices_Var_Samp_Fields = {
  __typename?: "BaseLandlordServices_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type BaseLandlordServices_Variance_Fields = {
  __typename?: "BaseLandlordServices_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "BaseLandlordServices" */
export type BaseLandlordServices_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

/** columns and relationships of "CenterApprovers" */
export type CenterApprovers = {
  __typename?: "CenterApprovers";
  /** An object relationship */
  Approver?: Maybe<Approvers>;
  /** An object relationship */
  Property?: Maybe<YardiProperties>;
  approver_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  property_code?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "CenterApprovers" */
export type CenterApprovers_Aggregate = {
  __typename?: "CenterApprovers_aggregate";
  aggregate?: Maybe<CenterApprovers_Aggregate_Fields>;
  nodes: Array<CenterApprovers>;
};

export type CenterApprovers_Aggregate_Bool_Exp = {
  count?: InputMaybe<CenterApprovers_Aggregate_Bool_Exp_Count>;
};

export type CenterApprovers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<CenterApprovers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CenterApprovers" */
export type CenterApprovers_Aggregate_Fields = {
  __typename?: "CenterApprovers_aggregate_fields";
  avg?: Maybe<CenterApprovers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<CenterApprovers_Max_Fields>;
  min?: Maybe<CenterApprovers_Min_Fields>;
  stddev?: Maybe<CenterApprovers_Stddev_Fields>;
  stddev_pop?: Maybe<CenterApprovers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CenterApprovers_Stddev_Samp_Fields>;
  sum?: Maybe<CenterApprovers_Sum_Fields>;
  var_pop?: Maybe<CenterApprovers_Var_Pop_Fields>;
  var_samp?: Maybe<CenterApprovers_Var_Samp_Fields>;
  variance?: Maybe<CenterApprovers_Variance_Fields>;
};

/** aggregate fields of "CenterApprovers" */
export type CenterApprovers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "CenterApprovers" */
export type CenterApprovers_Aggregate_Order_By = {
  avg?: InputMaybe<CenterApprovers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CenterApprovers_Max_Order_By>;
  min?: InputMaybe<CenterApprovers_Min_Order_By>;
  stddev?: InputMaybe<CenterApprovers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CenterApprovers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CenterApprovers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CenterApprovers_Sum_Order_By>;
  var_pop?: InputMaybe<CenterApprovers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CenterApprovers_Var_Samp_Order_By>;
  variance?: InputMaybe<CenterApprovers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CenterApprovers" */
export type CenterApprovers_Arr_Rel_Insert_Input = {
  data: Array<CenterApprovers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CenterApprovers_On_Conflict>;
};

/** aggregate avg on columns */
export type CenterApprovers_Avg_Fields = {
  __typename?: "CenterApprovers_avg_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "CenterApprovers" */
export type CenterApprovers_Avg_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CenterApprovers". All fields are combined with a logical 'AND'. */
export type CenterApprovers_Bool_Exp = {
  Approver?: InputMaybe<Approvers_Bool_Exp>;
  Property?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<CenterApprovers_Bool_Exp>>;
  _not?: InputMaybe<CenterApprovers_Bool_Exp>;
  _or?: InputMaybe<Array<CenterApprovers_Bool_Exp>>;
  approver_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CenterApprovers" */
export enum CenterApprovers_Constraint {
  /** unique or primary key constraint on columns "id" */
  CenterApproversPkey = "CenterApprovers_pkey",
}

/** input type for incrementing numeric columns in table "CenterApprovers" */
export type CenterApprovers_Inc_Input = {
  approver_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "CenterApprovers" */
export type CenterApprovers_Insert_Input = {
  Approver?: InputMaybe<Approvers_Obj_Rel_Insert_Input>;
  Property?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  approver_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CenterApprovers_Max_Fields = {
  __typename?: "CenterApprovers_max_fields";
  approver_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "CenterApprovers" */
export type CenterApprovers_Max_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CenterApprovers_Min_Fields = {
  __typename?: "CenterApprovers_min_fields";
  approver_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "CenterApprovers" */
export type CenterApprovers_Min_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CenterApprovers" */
export type CenterApprovers_Mutation_Response = {
  __typename?: "CenterApprovers_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CenterApprovers>;
};

/** on_conflict condition type for table "CenterApprovers" */
export type CenterApprovers_On_Conflict = {
  constraint: CenterApprovers_Constraint;
  update_columns?: Array<CenterApprovers_Update_Column>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

/** Ordering options when selecting data from "CenterApprovers". */
export type CenterApprovers_Order_By = {
  Approver?: InputMaybe<Approvers_Order_By>;
  Property?: InputMaybe<YardiProperties_Order_By>;
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CenterApprovers */
export type CenterApprovers_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "CenterApprovers" */
export enum CenterApprovers_Select_Column {
  /** column name */
  ApproverId = "approver_id",
  /** column name */
  Id = "id",
  /** column name */
  PropertyCode = "property_code",
}

/** input type for updating data in table "CenterApprovers" */
export type CenterApprovers_Set_Input = {
  approver_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type CenterApprovers_Stddev_Fields = {
  __typename?: "CenterApprovers_stddev_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "CenterApprovers" */
export type CenterApprovers_Stddev_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CenterApprovers_Stddev_Pop_Fields = {
  __typename?: "CenterApprovers_stddev_pop_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "CenterApprovers" */
export type CenterApprovers_Stddev_Pop_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CenterApprovers_Stddev_Samp_Fields = {
  __typename?: "CenterApprovers_stddev_samp_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "CenterApprovers" */
export type CenterApprovers_Stddev_Samp_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CenterApprovers" */
export type CenterApprovers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CenterApprovers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CenterApprovers_Stream_Cursor_Value_Input = {
  approver_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type CenterApprovers_Sum_Fields = {
  __typename?: "CenterApprovers_sum_fields";
  approver_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "CenterApprovers" */
export type CenterApprovers_Sum_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "CenterApprovers" */
export enum CenterApprovers_Update_Column {
  /** column name */
  ApproverId = "approver_id",
  /** column name */
  Id = "id",
  /** column name */
  PropertyCode = "property_code",
}

export type CenterApprovers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CenterApprovers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CenterApprovers_Set_Input>;
  /** filter the rows which have to be updated */
  where: CenterApprovers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CenterApprovers_Var_Pop_Fields = {
  __typename?: "CenterApprovers_var_pop_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "CenterApprovers" */
export type CenterApprovers_Var_Pop_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CenterApprovers_Var_Samp_Fields = {
  __typename?: "CenterApprovers_var_samp_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "CenterApprovers" */
export type CenterApprovers_Var_Samp_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CenterApprovers_Variance_Fields = {
  __typename?: "CenterApprovers_variance_fields";
  approver_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "CenterApprovers" */
export type CenterApprovers_Variance_Order_By = {
  approver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  content: Scalars["String"];
  context?: Maybe<ResponseContext>;
  id: Scalars["String"];
  role: Scalars["String"];
};

export type ChatMessageInput = {
  content: Scalars["String"];
  id: Scalars["String"];
  role: Scalars["String"];
};

export type ChatResponse = {
  __typename?: "ChatResponse";
  choices: Array<Maybe<ChatResponseChoice>>;
  created: Scalars["Int"];
  id: Scalars["String"];
};

export type ChatResponseChoice = {
  __typename?: "ChatResponseChoice";
  message: ChatMessage;
};

/** columns and relationships of "CommentTags" */
export type CommentTags = {
  __typename?: "CommentTags";
  /** An object relationship */
  Comment?: Maybe<Comments>;
  /** An object relationship */
  Tag?: Maybe<Tags>;
  comment_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "CommentTags" */
export type CommentTags_Aggregate = {
  __typename?: "CommentTags_aggregate";
  aggregate?: Maybe<CommentTags_Aggregate_Fields>;
  nodes: Array<CommentTags>;
};

export type CommentTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<CommentTags_Aggregate_Bool_Exp_Count>;
};

export type CommentTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CommentTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<CommentTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CommentTags" */
export type CommentTags_Aggregate_Fields = {
  __typename?: "CommentTags_aggregate_fields";
  avg?: Maybe<CommentTags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<CommentTags_Max_Fields>;
  min?: Maybe<CommentTags_Min_Fields>;
  stddev?: Maybe<CommentTags_Stddev_Fields>;
  stddev_pop?: Maybe<CommentTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CommentTags_Stddev_Samp_Fields>;
  sum?: Maybe<CommentTags_Sum_Fields>;
  var_pop?: Maybe<CommentTags_Var_Pop_Fields>;
  var_samp?: Maybe<CommentTags_Var_Samp_Fields>;
  variance?: Maybe<CommentTags_Variance_Fields>;
};

/** aggregate fields of "CommentTags" */
export type CommentTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CommentTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "CommentTags" */
export type CommentTags_Aggregate_Order_By = {
  avg?: InputMaybe<CommentTags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CommentTags_Max_Order_By>;
  min?: InputMaybe<CommentTags_Min_Order_By>;
  stddev?: InputMaybe<CommentTags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CommentTags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CommentTags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CommentTags_Sum_Order_By>;
  var_pop?: InputMaybe<CommentTags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CommentTags_Var_Samp_Order_By>;
  variance?: InputMaybe<CommentTags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CommentTags" */
export type CommentTags_Arr_Rel_Insert_Input = {
  data: Array<CommentTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentTags_On_Conflict>;
};

/** aggregate avg on columns */
export type CommentTags_Avg_Fields = {
  __typename?: "CommentTags_avg_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "CommentTags" */
export type CommentTags_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CommentTags". All fields are combined with a logical 'AND'. */
export type CommentTags_Bool_Exp = {
  Comment?: InputMaybe<Comments_Bool_Exp>;
  Tag?: InputMaybe<Tags_Bool_Exp>;
  _and?: InputMaybe<Array<CommentTags_Bool_Exp>>;
  _not?: InputMaybe<CommentTags_Bool_Exp>;
  _or?: InputMaybe<Array<CommentTags_Bool_Exp>>;
  comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "CommentTags" */
export enum CommentTags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentTagsPkey = "CommentTags_pkey",
  /** unique or primary key constraint on columns "comment_id", "tag_id" */
  CommenttagsTagCommentUnique = "commenttags_tag_comment_unique",
}

/** input type for incrementing numeric columns in table "CommentTags" */
export type CommentTags_Inc_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "CommentTags" */
export type CommentTags_Insert_Input = {
  Comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  Tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type CommentTags_Max_Fields = {
  __typename?: "CommentTags_max_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "CommentTags" */
export type CommentTags_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CommentTags_Min_Fields = {
  __typename?: "CommentTags_min_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "CommentTags" */
export type CommentTags_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CommentTags" */
export type CommentTags_Mutation_Response = {
  __typename?: "CommentTags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CommentTags>;
};

/** on_conflict condition type for table "CommentTags" */
export type CommentTags_On_Conflict = {
  constraint: CommentTags_Constraint;
  update_columns?: Array<CommentTags_Update_Column>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

/** Ordering options when selecting data from "CommentTags". */
export type CommentTags_Order_By = {
  Comment?: InputMaybe<Comments_Order_By>;
  Tag?: InputMaybe<Tags_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CommentTags */
export type CommentTags_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "CommentTags" */
export enum CommentTags_Select_Column {
  /** column name */
  CommentId = "comment_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TagId = "tag_id",
}

/** input type for updating data in table "CommentTags" */
export type CommentTags_Set_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type CommentTags_Stddev_Fields = {
  __typename?: "CommentTags_stddev_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "CommentTags" */
export type CommentTags_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CommentTags_Stddev_Pop_Fields = {
  __typename?: "CommentTags_stddev_pop_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "CommentTags" */
export type CommentTags_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CommentTags_Stddev_Samp_Fields = {
  __typename?: "CommentTags_stddev_samp_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "CommentTags" */
export type CommentTags_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CommentTags" */
export type CommentTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CommentTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentTags_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type CommentTags_Sum_Fields = {
  __typename?: "CommentTags_sum_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "CommentTags" */
export type CommentTags_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** update columns of table "CommentTags" */
export enum CommentTags_Update_Column {
  /** column name */
  CommentId = "comment_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TagId = "tag_id",
}

export type CommentTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CommentTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: CommentTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CommentTags_Var_Pop_Fields = {
  __typename?: "CommentTags_var_pop_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "CommentTags" */
export type CommentTags_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CommentTags_Var_Samp_Fields = {
  __typename?: "CommentTags_var_samp_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "CommentTags" */
export type CommentTags_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CommentTags_Variance_Fields = {
  __typename?: "CommentTags_variance_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "CommentTags" */
export type CommentTags_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** Users tagged in comments */
export type CommentUserTags = {
  __typename?: "CommentUserTags";
  /** An object relationship */
  Comment: Comments;
  /** An object relationship */
  User: Users;
  comment_id: Scalars["bigint"];
  id: Scalars["Int"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "CommentUserTags" */
export type CommentUserTags_Aggregate = {
  __typename?: "CommentUserTags_aggregate";
  aggregate?: Maybe<CommentUserTags_Aggregate_Fields>;
  nodes: Array<CommentUserTags>;
};

export type CommentUserTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<CommentUserTags_Aggregate_Bool_Exp_Count>;
};

export type CommentUserTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<CommentUserTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CommentUserTags" */
export type CommentUserTags_Aggregate_Fields = {
  __typename?: "CommentUserTags_aggregate_fields";
  avg?: Maybe<CommentUserTags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<CommentUserTags_Max_Fields>;
  min?: Maybe<CommentUserTags_Min_Fields>;
  stddev?: Maybe<CommentUserTags_Stddev_Fields>;
  stddev_pop?: Maybe<CommentUserTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CommentUserTags_Stddev_Samp_Fields>;
  sum?: Maybe<CommentUserTags_Sum_Fields>;
  var_pop?: Maybe<CommentUserTags_Var_Pop_Fields>;
  var_samp?: Maybe<CommentUserTags_Var_Samp_Fields>;
  variance?: Maybe<CommentUserTags_Variance_Fields>;
};

/** aggregate fields of "CommentUserTags" */
export type CommentUserTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "CommentUserTags" */
export type CommentUserTags_Aggregate_Order_By = {
  avg?: InputMaybe<CommentUserTags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CommentUserTags_Max_Order_By>;
  min?: InputMaybe<CommentUserTags_Min_Order_By>;
  stddev?: InputMaybe<CommentUserTags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CommentUserTags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CommentUserTags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CommentUserTags_Sum_Order_By>;
  var_pop?: InputMaybe<CommentUserTags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CommentUserTags_Var_Samp_Order_By>;
  variance?: InputMaybe<CommentUserTags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CommentUserTags" */
export type CommentUserTags_Arr_Rel_Insert_Input = {
  data: Array<CommentUserTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentUserTags_On_Conflict>;
};

/** aggregate avg on columns */
export type CommentUserTags_Avg_Fields = {
  __typename?: "CommentUserTags_avg_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "CommentUserTags" */
export type CommentUserTags_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CommentUserTags". All fields are combined with a logical 'AND'. */
export type CommentUserTags_Bool_Exp = {
  Comment?: InputMaybe<Comments_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<CommentUserTags_Bool_Exp>>;
  _not?: InputMaybe<CommentUserTags_Bool_Exp>;
  _or?: InputMaybe<Array<CommentUserTags_Bool_Exp>>;
  comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CommentUserTags" */
export enum CommentUserTags_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentUserTagsPkey = "CommentUserTags_pkey",
}

/** input type for incrementing numeric columns in table "CommentUserTags" */
export type CommentUserTags_Inc_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "CommentUserTags" */
export type CommentUserTags_Insert_Input = {
  Comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type CommentUserTags_Max_Fields = {
  __typename?: "CommentUserTags_max_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "CommentUserTags" */
export type CommentUserTags_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CommentUserTags_Min_Fields = {
  __typename?: "CommentUserTags_min_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "CommentUserTags" */
export type CommentUserTags_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CommentUserTags" */
export type CommentUserTags_Mutation_Response = {
  __typename?: "CommentUserTags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CommentUserTags>;
};

/** on_conflict condition type for table "CommentUserTags" */
export type CommentUserTags_On_Conflict = {
  constraint: CommentUserTags_Constraint;
  update_columns?: Array<CommentUserTags_Update_Column>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

/** Ordering options when selecting data from "CommentUserTags". */
export type CommentUserTags_Order_By = {
  Comment?: InputMaybe<Comments_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CommentUserTags */
export type CommentUserTags_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "CommentUserTags" */
export enum CommentUserTags_Select_Column {
  /** column name */
  CommentId = "comment_id",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "CommentUserTags" */
export type CommentUserTags_Set_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type CommentUserTags_Stddev_Fields = {
  __typename?: "CommentUserTags_stddev_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "CommentUserTags" */
export type CommentUserTags_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CommentUserTags_Stddev_Pop_Fields = {
  __typename?: "CommentUserTags_stddev_pop_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "CommentUserTags" */
export type CommentUserTags_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CommentUserTags_Stddev_Samp_Fields = {
  __typename?: "CommentUserTags_stddev_samp_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "CommentUserTags" */
export type CommentUserTags_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CommentUserTags" */
export type CommentUserTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CommentUserTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentUserTags_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type CommentUserTags_Sum_Fields = {
  __typename?: "CommentUserTags_sum_fields";
  comment_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "CommentUserTags" */
export type CommentUserTags_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "CommentUserTags" */
export enum CommentUserTags_Update_Column {
  /** column name */
  CommentId = "comment_id",
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
}

export type CommentUserTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CommentUserTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentUserTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: CommentUserTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CommentUserTags_Var_Pop_Fields = {
  __typename?: "CommentUserTags_var_pop_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "CommentUserTags" */
export type CommentUserTags_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CommentUserTags_Var_Samp_Fields = {
  __typename?: "CommentUserTags_var_samp_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "CommentUserTags" */
export type CommentUserTags_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CommentUserTags_Variance_Fields = {
  __typename?: "CommentUserTags_variance_fields";
  comment_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "CommentUserTags" */
export type CommentUserTags_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Comments" */
export type Comments = {
  __typename?: "Comments";
  /** An array relationship */
  CommentTags: Array<CommentTags>;
  /** An aggregate relationship */
  CommentTags_aggregate: CommentTags_Aggregate;
  /** An array relationship */
  CommentUserTags: Array<CommentUserTags>;
  /** An aggregate relationship */
  CommentUserTags_aggregate: CommentUserTags_Aggregate;
  /** An object relationship */
  Deal?: Maybe<Deals>;
  /** An object relationship */
  User?: Maybe<Users>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  text?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "Comments" */
export type CommentsCommentTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

/** columns and relationships of "Comments" */
export type CommentsCommentTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

/** columns and relationships of "Comments" */
export type CommentsCommentUserTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

/** columns and relationships of "Comments" */
export type CommentsCommentUserTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

/** aggregated selection of "Comments" */
export type Comments_Aggregate = {
  __typename?: "Comments_aggregate";
  aggregate?: Maybe<Comments_Aggregate_Fields>;
  nodes: Array<Comments>;
};

export type Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comments_Aggregate_Bool_Exp_Count>;
};

export type Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Comments" */
export type Comments_Aggregate_Fields = {
  __typename?: "Comments_aggregate_fields";
  avg?: Maybe<Comments_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Comments_Max_Fields>;
  min?: Maybe<Comments_Min_Fields>;
  stddev?: Maybe<Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Comments_Sum_Fields>;
  var_pop?: Maybe<Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Comments_Var_Samp_Fields>;
  variance?: Maybe<Comments_Variance_Fields>;
};

/** aggregate fields of "Comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Comments" */
export type Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comments_Max_Order_By>;
  min?: InputMaybe<Comments_Min_Order_By>;
  stddev?: InputMaybe<Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Comments_Avg_Fields = {
  __typename?: "Comments_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Comments" */
export type Comments_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  CommentTags?: InputMaybe<CommentTags_Bool_Exp>;
  CommentTags_aggregate?: InputMaybe<CommentTags_Aggregate_Bool_Exp>;
  CommentUserTags?: InputMaybe<CommentUserTags_Bool_Exp>;
  CommentUserTags_aggregate?: InputMaybe<CommentUserTags_Aggregate_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Comments_Bool_Exp>>;
  _not?: InputMaybe<Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = "Comments_pkey",
}

/** input type for incrementing numeric columns in table "Comments" */
export type Comments_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Comments" */
export type Comments_Insert_Input = {
  CommentTags?: InputMaybe<CommentTags_Arr_Rel_Insert_Input>;
  CommentUserTags?: InputMaybe<CommentUserTags_Arr_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  text?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  __typename?: "Comments_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  text?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Comments" */
export type Comments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  __typename?: "Comments_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  text?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Comments" */
export type Comments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Comments" */
export type Comments_Mutation_Response = {
  __typename?: "Comments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "Comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "Comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns?: Array<Comments_Update_Column>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "Comments". */
export type Comments_Order_By = {
  CommentTags_aggregate?: InputMaybe<CommentTags_Aggregate_Order_By>;
  CommentUserTags_aggregate?: InputMaybe<CommentUserTags_Aggregate_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Comments */
export type Comments_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Comments" */
export enum Comments_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  Text = "text",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "Comments" */
export type Comments_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  text?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Comments_Stddev_Fields = {
  __typename?: "Comments_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Comments" */
export type Comments_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comments_Stddev_Pop_Fields = {
  __typename?: "Comments_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Comments" */
export type Comments_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comments_Stddev_Samp_Fields = {
  __typename?: "Comments_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Comments" */
export type Comments_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  text?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Comments_Sum_Fields = {
  __typename?: "Comments_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Comments" */
export type Comments_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Comments" */
export enum Comments_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  Text = "text",
  /** column name */
  UserId = "user_id",
}

export type Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comments_Var_Pop_Fields = {
  __typename?: "Comments_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Comments" */
export type Comments_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comments_Var_Samp_Fields = {
  __typename?: "Comments_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Comments" */
export type Comments_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comments_Variance_Fields = {
  __typename?: "Comments_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Comments" */
export type Comments_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Companies" */
export type Companies = {
  __typename?: "Companies";
  /** An array relationship */
  Contacts: Array<Contacts>;
  /** An aggregate relationship */
  Contacts_aggregate: Contacts_Aggregate;
  /** An array relationship */
  DealsCompanies: Array<DealsCompanies>;
  /** An aggregate relationship */
  DealsCompanies_aggregate: DealsCompanies_Aggregate;
  /** An object relationship */
  Location?: Maybe<Locations>;
  acn?: Maybe<Scalars["String"]>;
  address_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  legal_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  not_deleted?: Maybe<Scalars["Boolean"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Companies" */
export type CompaniesContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "Companies" */
export type CompaniesContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "Companies" */
export type CompaniesDealsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** columns and relationships of "Companies" */
export type CompaniesDealsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** aggregated selection of "Companies" */
export type Companies_Aggregate = {
  __typename?: "Companies_aggregate";
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

export type Companies_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Companies_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Companies_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Companies_Aggregate_Bool_Exp_Count>;
};

export type Companies_Aggregate_Bool_Exp_Bool_And = {
  arguments: Companies_Select_Column_Companies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Companies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Companies_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Companies_Select_Column_Companies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Companies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Companies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Companies_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Companies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Companies" */
export type Companies_Aggregate_Fields = {
  __typename?: "Companies_aggregate_fields";
  avg?: Maybe<Companies_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
  stddev?: Maybe<Companies_Stddev_Fields>;
  stddev_pop?: Maybe<Companies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Companies_Stddev_Samp_Fields>;
  sum?: Maybe<Companies_Sum_Fields>;
  var_pop?: Maybe<Companies_Var_Pop_Fields>;
  var_samp?: Maybe<Companies_Var_Samp_Fields>;
  variance?: Maybe<Companies_Variance_Fields>;
};

/** aggregate fields of "Companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Companies_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Companies" */
export type Companies_Aggregate_Order_By = {
  avg?: InputMaybe<Companies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Companies_Max_Order_By>;
  min?: InputMaybe<Companies_Min_Order_By>;
  stddev?: InputMaybe<Companies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Companies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Companies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Companies_Sum_Order_By>;
  var_pop?: InputMaybe<Companies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Companies_Var_Samp_Order_By>;
  variance?: InputMaybe<Companies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Companies" */
export type Companies_Arr_Rel_Insert_Input = {
  data: Array<Companies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** aggregate avg on columns */
export type Companies_Avg_Fields = {
  __typename?: "Companies_avg_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Companies" */
export type Companies_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  Contacts?: InputMaybe<Contacts_Bool_Exp>;
  Contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  DealsCompanies?: InputMaybe<DealsCompanies_Bool_Exp>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Bool_Exp>;
  Location?: InputMaybe<Locations_Bool_Exp>;
  _and?: InputMaybe<Array<Companies_Bool_Exp>>;
  _not?: InputMaybe<Companies_Bool_Exp>;
  _or?: InputMaybe<Array<Companies_Bool_Exp>>;
  acn?: InputMaybe<String_Comparison_Exp>;
  address_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  legal_name?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  not_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  trading_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompaniesPkey = "Companies_pkey",
  /** unique or primary key constraint on columns "acn", "not_deleted" */
  UniqueacnConst = "uniqueacn_const",
}

/** input type for incrementing numeric columns in table "Companies" */
export type Companies_Inc_Input = {
  address_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Companies" */
export type Companies_Insert_Input = {
  Contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  DealsCompanies?: InputMaybe<DealsCompanies_Arr_Rel_Insert_Input>;
  Location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  acn?: InputMaybe<Scalars["String"]>;
  address_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  __typename?: "Companies_max_fields";
  acn?: Maybe<Scalars["String"]>;
  address_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  legal_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Companies" */
export type Companies_Max_Order_By = {
  acn?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  __typename?: "Companies_min_fields";
  acn?: Maybe<Scalars["String"]>;
  address_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  legal_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Companies" */
export type Companies_Min_Order_By = {
  acn?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Companies" */
export type Companies_Mutation_Response = {
  __typename?: "Companies_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "Companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** on_conflict condition type for table "Companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns?: Array<Companies_Update_Column>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

/** Ordering options when selecting data from "Companies". */
export type Companies_Order_By = {
  Contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Order_By>;
  Location?: InputMaybe<Locations_Order_By>;
  acn?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  not_deleted?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Companies */
export type Companies_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Companies" */
export enum Companies_Select_Column {
  /** column name */
  Acn = "acn",
  /** column name */
  AddressId = "address_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NotDeleted = "not_deleted",
  /** column name */
  Phone = "phone",
  /** column name */
  TradingName = "trading_name",
}

/** select "Companies_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Companies" */
export enum Companies_Select_Column_Companies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  NotDeleted = "not_deleted",
}

/** select "Companies_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Companies" */
export enum Companies_Select_Column_Companies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  NotDeleted = "not_deleted",
}

/** input type for updating data in table "Companies" */
export type Companies_Set_Input = {
  acn?: InputMaybe<Scalars["String"]>;
  address_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Companies_Stddev_Fields = {
  __typename?: "Companies_stddev_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Companies" */
export type Companies_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Companies_Stddev_Pop_Fields = {
  __typename?: "Companies_stddev_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Companies" */
export type Companies_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Companies_Stddev_Samp_Fields = {
  __typename?: "Companies_stddev_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Companies" */
export type Companies_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Companies" */
export type Companies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Companies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Companies_Stream_Cursor_Value_Input = {
  acn?: InputMaybe<Scalars["String"]>;
  address_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Companies_Sum_Fields = {
  __typename?: "Companies_sum_fields";
  address_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Companies" */
export type Companies_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Companies" */
export enum Companies_Update_Column {
  /** column name */
  Acn = "acn",
  /** column name */
  AddressId = "address_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NotDeleted = "not_deleted",
  /** column name */
  Phone = "phone",
  /** column name */
  TradingName = "trading_name",
}

export type Companies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Companies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Companies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Companies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Companies_Var_Pop_Fields = {
  __typename?: "Companies_var_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Companies" */
export type Companies_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Companies_Var_Samp_Fields = {
  __typename?: "Companies_var_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Companies" */
export type Companies_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Companies_Variance_Fields = {
  __typename?: "Companies_variance_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Companies" */
export type Companies_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ContactTypes" */
export type ContactTypes = {
  __typename?: "ContactTypes";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  is_internal?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "ContactTypes" */
export type ContactTypes_Aggregate = {
  __typename?: "ContactTypes_aggregate";
  aggregate?: Maybe<ContactTypes_Aggregate_Fields>;
  nodes: Array<ContactTypes>;
};

/** aggregate fields of "ContactTypes" */
export type ContactTypes_Aggregate_Fields = {
  __typename?: "ContactTypes_aggregate_fields";
  avg?: Maybe<ContactTypes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<ContactTypes_Max_Fields>;
  min?: Maybe<ContactTypes_Min_Fields>;
  stddev?: Maybe<ContactTypes_Stddev_Fields>;
  stddev_pop?: Maybe<ContactTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContactTypes_Stddev_Samp_Fields>;
  sum?: Maybe<ContactTypes_Sum_Fields>;
  var_pop?: Maybe<ContactTypes_Var_Pop_Fields>;
  var_samp?: Maybe<ContactTypes_Var_Samp_Fields>;
  variance?: Maybe<ContactTypes_Variance_Fields>;
};

/** aggregate fields of "ContactTypes" */
export type ContactTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContactTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type ContactTypes_Avg_Fields = {
  __typename?: "ContactTypes_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "ContactTypes". All fields are combined with a logical 'AND'. */
export type ContactTypes_Bool_Exp = {
  _and?: InputMaybe<Array<ContactTypes_Bool_Exp>>;
  _not?: InputMaybe<ContactTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ContactTypes_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_internal?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContactTypes" */
export enum ContactTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactTypesPkey = "ContactTypes_pkey",
}

/** input type for incrementing numeric columns in table "ContactTypes" */
export type ContactTypes_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "ContactTypes" */
export type ContactTypes_Insert_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_internal?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type ContactTypes_Max_Fields = {
  __typename?: "ContactTypes_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type ContactTypes_Min_Fields = {
  __typename?: "ContactTypes_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ContactTypes" */
export type ContactTypes_Mutation_Response = {
  __typename?: "ContactTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ContactTypes>;
};

/** input type for inserting object relation for remote table "ContactTypes" */
export type ContactTypes_Obj_Rel_Insert_Input = {
  data: ContactTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactTypes_On_Conflict>;
};

/** on_conflict condition type for table "ContactTypes" */
export type ContactTypes_On_Conflict = {
  constraint: ContactTypes_Constraint;
  update_columns?: Array<ContactTypes_Update_Column>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "ContactTypes". */
export type ContactTypes_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_internal?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContactTypes */
export type ContactTypes_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "ContactTypes" */
export enum ContactTypes_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsInternal = "is_internal",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "ContactTypes" */
export type ContactTypes_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_internal?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type ContactTypes_Stddev_Fields = {
  __typename?: "ContactTypes_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type ContactTypes_Stddev_Pop_Fields = {
  __typename?: "ContactTypes_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type ContactTypes_Stddev_Samp_Fields = {
  __typename?: "ContactTypes_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "ContactTypes" */
export type ContactTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContactTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactTypes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_internal?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type ContactTypes_Sum_Fields = {
  __typename?: "ContactTypes_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "ContactTypes" */
export enum ContactTypes_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsInternal = "is_internal",
  /** column name */
  Title = "title",
}

export type ContactTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContactTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContactTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContactTypes_Var_Pop_Fields = {
  __typename?: "ContactTypes_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type ContactTypes_Var_Samp_Fields = {
  __typename?: "ContactTypes_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type ContactTypes_Variance_Fields = {
  __typename?: "ContactTypes_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Contacts" */
export type Contacts = {
  __typename?: "Contacts";
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  DealsCompanies: Array<DealsCompanies>;
  /** An aggregate relationship */
  DealsCompanies_aggregate: DealsCompanies_Aggregate;
  /** An array relationship */
  DealsContacts: Array<DealsContacts>;
  /** An aggregate relationship */
  DealsContacts_aggregate: DealsContacts_Aggregate;
  /** An object relationship */
  Location?: Maybe<Locations>;
  /** An array relationship */
  PropertyContacts: Array<PropertyContacts>;
  /** An aggregate relationship */
  PropertyContacts_aggregate: PropertyContacts_Aggregate;
  /** An array relationship */
  YardiContacts_Contacts: Array<YardiContacts_Contacts>;
  /** An aggregate relationship */
  YardiContacts_Contacts_aggregate: YardiContacts_Contacts_Aggregate;
  address_id?: Maybe<Scalars["bigint"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["bigint"]>;
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  last_name?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Contacts" */
export type ContactsDealsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsDealsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsDealsContactsArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsDealsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsPropertyContactsArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsPropertyContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsYardiContacts_ContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

/** columns and relationships of "Contacts" */
export type ContactsYardiContacts_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

/** aggregated selection of "Contacts" */
export type Contacts_Aggregate = {
  __typename?: "Contacts_aggregate";
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

export type Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contacts_Aggregate_Bool_Exp_Count>;
};

export type Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: "Contacts_aggregate_fields";
  avg?: Maybe<Contacts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
  stddev?: Maybe<Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Contacts_Sum_Fields>;
  var_pop?: Maybe<Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Contacts_Var_Samp_Fields>;
  variance?: Maybe<Contacts_Variance_Fields>;
};

/** aggregate fields of "Contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Contacts" */
export type Contacts_Aggregate_Order_By = {
  avg?: InputMaybe<Contacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contacts_Max_Order_By>;
  min?: InputMaybe<Contacts_Min_Order_By>;
  stddev?: InputMaybe<Contacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contacts_Sum_Order_By>;
  var_pop?: InputMaybe<Contacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contacts_Var_Samp_Order_By>;
  variance?: InputMaybe<Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type Contacts_Avg_Fields = {
  __typename?: "Contacts_avg_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Contacts" */
export type Contacts_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  DealsCompanies?: InputMaybe<DealsCompanies_Bool_Exp>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Bool_Exp>;
  DealsContacts?: InputMaybe<DealsContacts_Bool_Exp>;
  DealsContacts_aggregate?: InputMaybe<DealsContacts_Aggregate_Bool_Exp>;
  Location?: InputMaybe<Locations_Bool_Exp>;
  PropertyContacts?: InputMaybe<PropertyContacts_Bool_Exp>;
  PropertyContacts_aggregate?: InputMaybe<PropertyContacts_Aggregate_Bool_Exp>;
  YardiContacts_Contacts?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
  YardiContacts_Contacts_aggregate?: InputMaybe<YardiContacts_Contacts_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  address_id?: InputMaybe<Bigint_Comparison_Exp>;
  alternate_email?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Bigint_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  legal_name?: InputMaybe<String_Comparison_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  trading_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = "Contacts_pkey",
}

/** input type for incrementing numeric columns in table "Contacts" */
export type Contacts_Inc_Input = {
  address_id?: InputMaybe<Scalars["bigint"]>;
  company_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Contacts" */
export type Contacts_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  DealsCompanies?: InputMaybe<DealsCompanies_Arr_Rel_Insert_Input>;
  DealsContacts?: InputMaybe<DealsContacts_Arr_Rel_Insert_Input>;
  Location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  PropertyContacts?: InputMaybe<PropertyContacts_Arr_Rel_Insert_Input>;
  YardiContacts_Contacts?: InputMaybe<YardiContacts_Contacts_Arr_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars["bigint"]>;
  alternate_email?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["bigint"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  middle_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: "Contacts_max_fields";
  address_id?: Maybe<Scalars["bigint"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["bigint"]>;
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_name?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Contacts" */
export type Contacts_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  alternate_email?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: "Contacts_min_fields";
  address_id?: Maybe<Scalars["bigint"]>;
  alternate_email?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["bigint"]>;
  content?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_name?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Contacts" */
export type Contacts_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  alternate_email?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Contacts" */
export type Contacts_Mutation_Response = {
  __typename?: "Contacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "Contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** on_conflict condition type for table "Contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "Contacts". */
export type Contacts_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Order_By>;
  DealsContacts_aggregate?: InputMaybe<DealsContacts_Aggregate_Order_By>;
  Location?: InputMaybe<Locations_Order_By>;
  PropertyContacts_aggregate?: InputMaybe<PropertyContacts_Aggregate_Order_By>;
  YardiContacts_Contacts_aggregate?: InputMaybe<YardiContacts_Contacts_Aggregate_Order_By>;
  address_id?: InputMaybe<Order_By>;
  alternate_email?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Contacts" */
export enum Contacts_Select_Column {
  /** column name */
  AddressId = "address_id",
  /** column name */
  AlternateEmail = "alternate_email",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "last_name",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Phone = "phone",
  /** column name */
  TradingName = "trading_name",
}

/** input type for updating data in table "Contacts" */
export type Contacts_Set_Input = {
  address_id?: InputMaybe<Scalars["bigint"]>;
  alternate_email?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["bigint"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  middle_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Contacts_Stddev_Fields = {
  __typename?: "Contacts_stddev_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Contacts" */
export type Contacts_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contacts_Stddev_Pop_Fields = {
  __typename?: "Contacts_stddev_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Contacts" */
export type Contacts_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contacts_Stddev_Samp_Fields = {
  __typename?: "Contacts_stddev_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Contacts" */
export type Contacts_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars["bigint"]>;
  alternate_email?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["bigint"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  middle_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Contacts_Sum_Fields = {
  __typename?: "Contacts_sum_fields";
  address_id?: Maybe<Scalars["bigint"]>;
  company_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Contacts" */
export type Contacts_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Contacts" */
export enum Contacts_Update_Column {
  /** column name */
  AddressId = "address_id",
  /** column name */
  AlternateEmail = "alternate_email",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "last_name",
  /** column name */
  LegalName = "legal_name",
  /** column name */
  MiddleName = "middle_name",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Phone = "phone",
  /** column name */
  TradingName = "trading_name",
}

export type Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contacts_Var_Pop_Fields = {
  __typename?: "Contacts_var_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Contacts" */
export type Contacts_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contacts_Var_Samp_Fields = {
  __typename?: "Contacts_var_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Contacts" */
export type Contacts_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contacts_Variance_Fields = {
  __typename?: "Contacts_variance_fields";
  address_id?: Maybe<Scalars["Float"]>;
  company_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Contacts" */
export type Contacts_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type ContextMessage = {
  __typename?: "ContextMessage";
  content: Scalars["String"];
  role: Scalars["String"];
};

/** columns and relationships of "Countries" */
export type Countries = {
  __typename?: "Countries";
  /** An array relationship */
  States: Array<States>;
  /** An aggregate relationship */
  States_aggregate: States_Aggregate;
  id: Scalars["bigint"];
  iso_country_code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Countries" */
export type CountriesStatesArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

/** columns and relationships of "Countries" */
export type CountriesStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

/** aggregated selection of "Countries" */
export type Countries_Aggregate = {
  __typename?: "Countries_aggregate";
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "Countries" */
export type Countries_Aggregate_Fields = {
  __typename?: "Countries_aggregate_fields";
  avg?: Maybe<Countries_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
  stddev?: Maybe<Countries_Stddev_Fields>;
  stddev_pop?: Maybe<Countries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Countries_Stddev_Samp_Fields>;
  sum?: Maybe<Countries_Sum_Fields>;
  var_pop?: Maybe<Countries_Var_Pop_Fields>;
  var_samp?: Maybe<Countries_Var_Samp_Fields>;
  variance?: Maybe<Countries_Variance_Fields>;
};

/** aggregate fields of "Countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Countries_Avg_Fields = {
  __typename?: "Countries_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  States?: InputMaybe<States_Bool_Exp>;
  States_aggregate?: InputMaybe<States_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  iso_country_code?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountriesPkey = "Countries_pkey",
}

/** input type for incrementing numeric columns in table "Countries" */
export type Countries_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Countries" */
export type Countries_Insert_Input = {
  States?: InputMaybe<States_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  iso_country_code?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: "Countries_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  iso_country_code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: "Countries_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  iso_country_code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Countries" */
export type Countries_Mutation_Response = {
  __typename?: "Countries_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** input type for inserting object relation for remote table "Countries" */
export type Countries_Obj_Rel_Insert_Input = {
  data: Countries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** on_conflict condition type for table "Countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "Countries". */
export type Countries_Order_By = {
  States_aggregate?: InputMaybe<States_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  iso_country_code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Countries */
export type Countries_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Countries" */
export enum Countries_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsoCountryCode = "iso_country_code",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "Countries" */
export type Countries_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  iso_country_code?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Countries_Stddev_Fields = {
  __typename?: "Countries_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Countries_Stddev_Pop_Fields = {
  __typename?: "Countries_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Countries_Stddev_Samp_Fields = {
  __typename?: "Countries_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  iso_country_code?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Countries_Sum_Fields = {
  __typename?: "Countries_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "Countries" */
export enum Countries_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsoCountryCode = "iso_country_code",
  /** column name */
  Name = "name",
}

export type Countries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Countries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Countries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Countries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Countries_Var_Pop_Fields = {
  __typename?: "Countries_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Countries_Var_Samp_Fields = {
  __typename?: "Countries_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Countries_Variance_Fields = {
  __typename?: "Countries_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "CustomLicences" */
export type CustomLicences = {
  __typename?: "CustomLicences";
  /** An array relationship */
  DealDataCustomLicences: Array<DealDataCustomLicences>;
  /** An aggregate relationship */
  DealDataCustomLicences_aggregate: DealDataCustomLicences_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  licence_description?: Maybe<Scalars["String"]>;
  licence_name?: Maybe<Scalars["String"]>;
  licence_rent?: Maybe<Scalars["numeric"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "CustomLicences" */
export type CustomLicencesDealDataCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

/** columns and relationships of "CustomLicences" */
export type CustomLicencesDealDataCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

/** aggregated selection of "CustomLicences" */
export type CustomLicences_Aggregate = {
  __typename?: "CustomLicences_aggregate";
  aggregate?: Maybe<CustomLicences_Aggregate_Fields>;
  nodes: Array<CustomLicences>;
};

/** aggregate fields of "CustomLicences" */
export type CustomLicences_Aggregate_Fields = {
  __typename?: "CustomLicences_aggregate_fields";
  avg?: Maybe<CustomLicences_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<CustomLicences_Max_Fields>;
  min?: Maybe<CustomLicences_Min_Fields>;
  stddev?: Maybe<CustomLicences_Stddev_Fields>;
  stddev_pop?: Maybe<CustomLicences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CustomLicences_Stddev_Samp_Fields>;
  sum?: Maybe<CustomLicences_Sum_Fields>;
  var_pop?: Maybe<CustomLicences_Var_Pop_Fields>;
  var_samp?: Maybe<CustomLicences_Var_Samp_Fields>;
  variance?: Maybe<CustomLicences_Variance_Fields>;
};

/** aggregate fields of "CustomLicences" */
export type CustomLicences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomLicences_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type CustomLicences_Avg_Fields = {
  __typename?: "CustomLicences_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "CustomLicences". All fields are combined with a logical 'AND'. */
export type CustomLicences_Bool_Exp = {
  DealDataCustomLicences?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
  DealDataCustomLicences_aggregate?: InputMaybe<DealDataCustomLicences_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CustomLicences_Bool_Exp>>;
  _not?: InputMaybe<CustomLicences_Bool_Exp>;
  _or?: InputMaybe<Array<CustomLicences_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  licence_description?: InputMaybe<String_Comparison_Exp>;
  licence_name?: InputMaybe<String_Comparison_Exp>;
  licence_rent?: InputMaybe<Numeric_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomLicences" */
export enum CustomLicences_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomLicencesPkey = "CustomLicences_pkey",
}

/** input type for incrementing numeric columns in table "CustomLicences" */
export type CustomLicences_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  licence_rent?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "CustomLicences" */
export type CustomLicences_Insert_Input = {
  DealDataCustomLicences?: InputMaybe<DealDataCustomLicences_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  licence_description?: InputMaybe<Scalars["String"]>;
  licence_name?: InputMaybe<Scalars["String"]>;
  licence_rent?: InputMaybe<Scalars["numeric"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type CustomLicences_Max_Fields = {
  __typename?: "CustomLicences_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  licence_description?: Maybe<Scalars["String"]>;
  licence_name?: Maybe<Scalars["String"]>;
  licence_rent?: Maybe<Scalars["numeric"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type CustomLicences_Min_Fields = {
  __typename?: "CustomLicences_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  licence_description?: Maybe<Scalars["String"]>;
  licence_name?: Maybe<Scalars["String"]>;
  licence_rent?: Maybe<Scalars["numeric"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "CustomLicences" */
export type CustomLicences_Mutation_Response = {
  __typename?: "CustomLicences_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CustomLicences>;
};

/** input type for inserting object relation for remote table "CustomLicences" */
export type CustomLicences_Obj_Rel_Insert_Input = {
  data: CustomLicences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomLicences_On_Conflict>;
};

/** on_conflict condition type for table "CustomLicences" */
export type CustomLicences_On_Conflict = {
  constraint: CustomLicences_Constraint;
  update_columns?: Array<CustomLicences_Update_Column>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomLicences". */
export type CustomLicences_Order_By = {
  DealDataCustomLicences_aggregate?: InputMaybe<DealDataCustomLicences_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  licence_description?: InputMaybe<Order_By>;
  licence_name?: InputMaybe<Order_By>;
  licence_rent?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomLicences */
export type CustomLicences_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "CustomLicences" */
export enum CustomLicences_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LicenceDescription = "licence_description",
  /** column name */
  LicenceName = "licence_name",
  /** column name */
  LicenceRent = "licence_rent",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "CustomLicences" */
export type CustomLicences_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  licence_description?: InputMaybe<Scalars["String"]>;
  licence_name?: InputMaybe<Scalars["String"]>;
  licence_rent?: InputMaybe<Scalars["numeric"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type CustomLicences_Stddev_Fields = {
  __typename?: "CustomLicences_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type CustomLicences_Stddev_Pop_Fields = {
  __typename?: "CustomLicences_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type CustomLicences_Stddev_Samp_Fields = {
  __typename?: "CustomLicences_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "CustomLicences" */
export type CustomLicences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomLicences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomLicences_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  licence_description?: InputMaybe<Scalars["String"]>;
  licence_name?: InputMaybe<Scalars["String"]>;
  licence_rent?: InputMaybe<Scalars["numeric"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type CustomLicences_Sum_Fields = {
  __typename?: "CustomLicences_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  licence_rent?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "CustomLicences" */
export enum CustomLicences_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LicenceDescription = "licence_description",
  /** column name */
  LicenceName = "licence_name",
  /** column name */
  LicenceRent = "licence_rent",
  /** column name */
  ModifiedAt = "modified_at",
}

export type CustomLicences_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomLicences_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomLicences_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomLicences_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomLicences_Var_Pop_Fields = {
  __typename?: "CustomLicences_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type CustomLicences_Var_Samp_Fields = {
  __typename?: "CustomLicences_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type CustomLicences_Variance_Fields = {
  __typename?: "CustomLicences_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  licence_rent?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "DealActionItem" */
export type DealActionItem = {
  __typename?: "DealActionItem";
  /** An object relationship */
  ActionAllocation?: Maybe<ActionAllocations>;
  /** An array relationship */
  ActionAllocations: Array<ActionAllocations>;
  /** An aggregate relationship */
  ActionAllocations_aggregate: ActionAllocations_Aggregate;
  /** An object relationship */
  DealPhases?: Maybe<DealPhases>;
  /** An object relationship */
  User?: Maybe<Users>;
  content?: Maybe<Scalars["String"]>;
  deal_phase_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  metadata?: Maybe<Scalars["String"]>;
  required: Scalars["Boolean"];
  state: Scalars["Boolean"];
  user_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "DealActionItem" */
export type DealActionItemActionAllocationsArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "DealActionItem" */
export type DealActionItemActionAllocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** aggregated selection of "DealActionItem" */
export type DealActionItem_Aggregate = {
  __typename?: "DealActionItem_aggregate";
  aggregate?: Maybe<DealActionItem_Aggregate_Fields>;
  nodes: Array<DealActionItem>;
};

export type DealActionItem_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealActionItem_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealActionItem_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealActionItem_Aggregate_Bool_Exp_Count>;
};

export type DealActionItem_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealActionItem_Select_Column_DealActionItem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealActionItem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealActionItem_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealActionItem_Select_Column_DealActionItem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealActionItem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealActionItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealActionItem_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealActionItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealActionItem" */
export type DealActionItem_Aggregate_Fields = {
  __typename?: "DealActionItem_aggregate_fields";
  avg?: Maybe<DealActionItem_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealActionItem_Max_Fields>;
  min?: Maybe<DealActionItem_Min_Fields>;
  stddev?: Maybe<DealActionItem_Stddev_Fields>;
  stddev_pop?: Maybe<DealActionItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealActionItem_Stddev_Samp_Fields>;
  sum?: Maybe<DealActionItem_Sum_Fields>;
  var_pop?: Maybe<DealActionItem_Var_Pop_Fields>;
  var_samp?: Maybe<DealActionItem_Var_Samp_Fields>;
  variance?: Maybe<DealActionItem_Variance_Fields>;
};

/** aggregate fields of "DealActionItem" */
export type DealActionItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealActionItem_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealActionItem" */
export type DealActionItem_Aggregate_Order_By = {
  avg?: InputMaybe<DealActionItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealActionItem_Max_Order_By>;
  min?: InputMaybe<DealActionItem_Min_Order_By>;
  stddev?: InputMaybe<DealActionItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealActionItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealActionItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealActionItem_Sum_Order_By>;
  var_pop?: InputMaybe<DealActionItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealActionItem_Var_Samp_Order_By>;
  variance?: InputMaybe<DealActionItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealActionItem" */
export type DealActionItem_Arr_Rel_Insert_Input = {
  data: Array<DealActionItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealActionItem_On_Conflict>;
};

/** aggregate avg on columns */
export type DealActionItem_Avg_Fields = {
  __typename?: "DealActionItem_avg_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealActionItem" */
export type DealActionItem_Avg_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealActionItem". All fields are combined with a logical 'AND'. */
export type DealActionItem_Bool_Exp = {
  ActionAllocation?: InputMaybe<ActionAllocations_Bool_Exp>;
  ActionAllocations?: InputMaybe<ActionAllocations_Bool_Exp>;
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Bool_Exp>;
  DealPhases?: InputMaybe<DealPhases_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DealActionItem_Bool_Exp>>;
  _not?: InputMaybe<DealActionItem_Bool_Exp>;
  _or?: InputMaybe<Array<DealActionItem_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  deal_phase_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  required?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealActionItem" */
export enum DealActionItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealActionItemPkey = "DealActionItem_pkey",
}

/** input type for incrementing numeric columns in table "DealActionItem" */
export type DealActionItem_Inc_Input = {
  deal_phase_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealActionItem" */
export type DealActionItem_Insert_Input = {
  ActionAllocation?: InputMaybe<ActionAllocations_Obj_Rel_Insert_Input>;
  ActionAllocations?: InputMaybe<ActionAllocations_Arr_Rel_Insert_Input>;
  DealPhases?: InputMaybe<DealPhases_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  content?: InputMaybe<Scalars["String"]>;
  deal_phase_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type DealActionItem_Max_Fields = {
  __typename?: "DealActionItem_max_fields";
  content?: Maybe<Scalars["String"]>;
  deal_phase_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "DealActionItem" */
export type DealActionItem_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealActionItem_Min_Fields = {
  __typename?: "DealActionItem_min_fields";
  content?: Maybe<Scalars["String"]>;
  deal_phase_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  metadata?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "DealActionItem" */
export type DealActionItem_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealActionItem" */
export type DealActionItem_Mutation_Response = {
  __typename?: "DealActionItem_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealActionItem>;
};

/** input type for inserting object relation for remote table "DealActionItem" */
export type DealActionItem_Obj_Rel_Insert_Input = {
  data: DealActionItem_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealActionItem_On_Conflict>;
};

/** on_conflict condition type for table "DealActionItem" */
export type DealActionItem_On_Conflict = {
  constraint: DealActionItem_Constraint;
  update_columns?: Array<DealActionItem_Update_Column>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

/** Ordering options when selecting data from "DealActionItem". */
export type DealActionItem_Order_By = {
  ActionAllocation?: InputMaybe<ActionAllocations_Order_By>;
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Order_By>;
  DealPhases?: InputMaybe<DealPhases_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  content?: InputMaybe<Order_By>;
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  required?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealActionItem */
export type DealActionItem_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealActionItem" */
export enum DealActionItem_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  DealPhaseId = "deal_phase_id",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
  /** column name */
  UserId = "user_id",
}

/** select "DealActionItem_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealActionItem" */
export enum DealActionItem_Select_Column_DealActionItem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
}

/** select "DealActionItem_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealActionItem" */
export enum DealActionItem_Select_Column_DealActionItem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
}

/** input type for updating data in table "DealActionItem" */
export type DealActionItem_Set_Input = {
  content?: InputMaybe<Scalars["String"]>;
  deal_phase_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DealActionItem_Stddev_Fields = {
  __typename?: "DealActionItem_stddev_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealActionItem" */
export type DealActionItem_Stddev_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealActionItem_Stddev_Pop_Fields = {
  __typename?: "DealActionItem_stddev_pop_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealActionItem" */
export type DealActionItem_Stddev_Pop_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealActionItem_Stddev_Samp_Fields = {
  __typename?: "DealActionItem_stddev_samp_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealActionItem" */
export type DealActionItem_Stddev_Samp_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealActionItem" */
export type DealActionItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealActionItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealActionItem_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars["String"]>;
  deal_phase_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  state?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type DealActionItem_Sum_Fields = {
  __typename?: "DealActionItem_sum_fields";
  deal_phase_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealActionItem" */
export type DealActionItem_Sum_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealActionItem" */
export enum DealActionItem_Update_Column {
  /** column name */
  Content = "content",
  /** column name */
  DealPhaseId = "deal_phase_id",
  /** column name */
  Id = "id",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Required = "required",
  /** column name */
  State = "state",
  /** column name */
  UserId = "user_id",
}

export type DealActionItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealActionItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealActionItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealActionItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealActionItem_Var_Pop_Fields = {
  __typename?: "DealActionItem_var_pop_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealActionItem" */
export type DealActionItem_Var_Pop_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealActionItem_Var_Samp_Fields = {
  __typename?: "DealActionItem_var_samp_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealActionItem" */
export type DealActionItem_Var_Samp_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealActionItem_Variance_Fields = {
  __typename?: "DealActionItem_variance_fields";
  deal_phase_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealActionItem" */
export type DealActionItem_Variance_Order_By = {
  deal_phase_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealBusinessContacts" */
export type DealBusinessContacts = {
  __typename?: "DealBusinessContacts";
  /** An object relationship */
  Contact?: Maybe<Contacts>;
  /** An object relationship */
  Deal?: Maybe<Deals>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealBusinessContacts" */
export type DealBusinessContacts_Aggregate = {
  __typename?: "DealBusinessContacts_aggregate";
  aggregate?: Maybe<DealBusinessContacts_Aggregate_Fields>;
  nodes: Array<DealBusinessContacts>;
};

/** aggregate fields of "DealBusinessContacts" */
export type DealBusinessContacts_Aggregate_Fields = {
  __typename?: "DealBusinessContacts_aggregate_fields";
  avg?: Maybe<DealBusinessContacts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealBusinessContacts_Max_Fields>;
  min?: Maybe<DealBusinessContacts_Min_Fields>;
  stddev?: Maybe<DealBusinessContacts_Stddev_Fields>;
  stddev_pop?: Maybe<DealBusinessContacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealBusinessContacts_Stddev_Samp_Fields>;
  sum?: Maybe<DealBusinessContacts_Sum_Fields>;
  var_pop?: Maybe<DealBusinessContacts_Var_Pop_Fields>;
  var_samp?: Maybe<DealBusinessContacts_Var_Samp_Fields>;
  variance?: Maybe<DealBusinessContacts_Variance_Fields>;
};

/** aggregate fields of "DealBusinessContacts" */
export type DealBusinessContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealBusinessContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type DealBusinessContacts_Avg_Fields = {
  __typename?: "DealBusinessContacts_avg_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "DealBusinessContacts". All fields are combined with a logical 'AND'. */
export type DealBusinessContacts_Bool_Exp = {
  Contact?: InputMaybe<Contacts_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<DealBusinessContacts_Bool_Exp>>;
  _not?: InputMaybe<DealBusinessContacts_Bool_Exp>;
  _or?: InputMaybe<Array<DealBusinessContacts_Bool_Exp>>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealBusinessContacts" */
export enum DealBusinessContacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealBusinessContactsPkey = "DealBusinessContacts_pkey",
  /** unique or primary key constraint on columns "deal_id", "contact_id" */
  DealbusinesscontactsDealContactUnique = "dealbusinesscontacts_deal_contact_unique",
}

/** input type for incrementing numeric columns in table "DealBusinessContacts" */
export type DealBusinessContacts_Inc_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealBusinessContacts" */
export type DealBusinessContacts_Insert_Input = {
  Contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealBusinessContacts_Max_Fields = {
  __typename?: "DealBusinessContacts_max_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealBusinessContacts_Min_Fields = {
  __typename?: "DealBusinessContacts_min_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealBusinessContacts" */
export type DealBusinessContacts_Mutation_Response = {
  __typename?: "DealBusinessContacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealBusinessContacts>;
};

/** on_conflict condition type for table "DealBusinessContacts" */
export type DealBusinessContacts_On_Conflict = {
  constraint: DealBusinessContacts_Constraint;
  update_columns?: Array<DealBusinessContacts_Update_Column>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "DealBusinessContacts". */
export type DealBusinessContacts_Order_By = {
  Contact?: InputMaybe<Contacts_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealBusinessContacts */
export type DealBusinessContacts_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealBusinessContacts" */
export enum DealBusinessContacts_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Tag = "tag",
}

/** input type for updating data in table "DealBusinessContacts" */
export type DealBusinessContacts_Set_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealBusinessContacts_Stddev_Fields = {
  __typename?: "DealBusinessContacts_stddev_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DealBusinessContacts_Stddev_Pop_Fields = {
  __typename?: "DealBusinessContacts_stddev_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DealBusinessContacts_Stddev_Samp_Fields = {
  __typename?: "DealBusinessContacts_stddev_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "DealBusinessContacts" */
export type DealBusinessContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealBusinessContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealBusinessContacts_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealBusinessContacts_Sum_Fields = {
  __typename?: "DealBusinessContacts_sum_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "DealBusinessContacts" */
export enum DealBusinessContacts_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Tag = "tag",
}

export type DealBusinessContacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealBusinessContacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealBusinessContacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealBusinessContacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealBusinessContacts_Var_Pop_Fields = {
  __typename?: "DealBusinessContacts_var_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DealBusinessContacts_Var_Samp_Fields = {
  __typename?: "DealBusinessContacts_var_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DealBusinessContacts_Variance_Fields = {
  __typename?: "DealBusinessContacts_variance_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "DealContactsTags" */
export type DealContactsTags = {
  __typename?: "DealContactsTags";
  id: Scalars["bigint"];
  name: Scalars["String"];
};

/** aggregated selection of "DealContactsTags" */
export type DealContactsTags_Aggregate = {
  __typename?: "DealContactsTags_aggregate";
  aggregate?: Maybe<DealContactsTags_Aggregate_Fields>;
  nodes: Array<DealContactsTags>;
};

/** aggregate fields of "DealContactsTags" */
export type DealContactsTags_Aggregate_Fields = {
  __typename?: "DealContactsTags_aggregate_fields";
  avg?: Maybe<DealContactsTags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealContactsTags_Max_Fields>;
  min?: Maybe<DealContactsTags_Min_Fields>;
  stddev?: Maybe<DealContactsTags_Stddev_Fields>;
  stddev_pop?: Maybe<DealContactsTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealContactsTags_Stddev_Samp_Fields>;
  sum?: Maybe<DealContactsTags_Sum_Fields>;
  var_pop?: Maybe<DealContactsTags_Var_Pop_Fields>;
  var_samp?: Maybe<DealContactsTags_Var_Samp_Fields>;
  variance?: Maybe<DealContactsTags_Variance_Fields>;
};

/** aggregate fields of "DealContactsTags" */
export type DealContactsTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealContactsTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type DealContactsTags_Avg_Fields = {
  __typename?: "DealContactsTags_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "DealContactsTags". All fields are combined with a logical 'AND'. */
export type DealContactsTags_Bool_Exp = {
  _and?: InputMaybe<Array<DealContactsTags_Bool_Exp>>;
  _not?: InputMaybe<DealContactsTags_Bool_Exp>;
  _or?: InputMaybe<Array<DealContactsTags_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealContactsTags" */
export enum DealContactsTags_Constraint {
  /** unique or primary key constraint on columns "name" */
  DealContactsTagsNameKey = "DealContactsTags_name_key",
  /** unique or primary key constraint on columns "id" */
  DealContactsTagsPkey = "DealContactsTags_pkey",
}

/** input type for incrementing numeric columns in table "DealContactsTags" */
export type DealContactsTags_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealContactsTags" */
export type DealContactsTags_Insert_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealContactsTags_Max_Fields = {
  __typename?: "DealContactsTags_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealContactsTags_Min_Fields = {
  __typename?: "DealContactsTags_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealContactsTags" */
export type DealContactsTags_Mutation_Response = {
  __typename?: "DealContactsTags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealContactsTags>;
};

/** on_conflict condition type for table "DealContactsTags" */
export type DealContactsTags_On_Conflict = {
  constraint: DealContactsTags_Constraint;
  update_columns?: Array<DealContactsTags_Update_Column>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

/** Ordering options when selecting data from "DealContactsTags". */
export type DealContactsTags_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealContactsTags */
export type DealContactsTags_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealContactsTags" */
export enum DealContactsTags_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "DealContactsTags" */
export type DealContactsTags_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealContactsTags_Stddev_Fields = {
  __typename?: "DealContactsTags_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DealContactsTags_Stddev_Pop_Fields = {
  __typename?: "DealContactsTags_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DealContactsTags_Stddev_Samp_Fields = {
  __typename?: "DealContactsTags_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "DealContactsTags" */
export type DealContactsTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealContactsTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealContactsTags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealContactsTags_Sum_Fields = {
  __typename?: "DealContactsTags_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "DealContactsTags" */
export enum DealContactsTags_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type DealContactsTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealContactsTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealContactsTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealContactsTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealContactsTags_Var_Pop_Fields = {
  __typename?: "DealContactsTags_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DealContactsTags_Var_Samp_Fields = {
  __typename?: "DealContactsTags_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DealContactsTags_Variance_Fields = {
  __typename?: "DealContactsTags_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "DealData" */
export type DealData = {
  __typename?: "DealData";
  /** An array relationship */
  BaseLandlordServices: Array<BaseLandlordServices>;
  /** An aggregate relationship */
  BaseLandlordServices_aggregate: BaseLandlordServices_Aggregate;
  /** An object relationship */
  Deal: Deals;
  /** An object relationship */
  DealDataCalculations?: Maybe<DealDataCalculations>;
  /** An array relationship */
  DealDataCustomLicences: Array<DealDataCustomLicences>;
  /** An aggregate relationship */
  DealDataCustomLicences_aggregate: DealDataCustomLicences_Aggregate;
  /** An array relationship */
  DealDataGuarantors: Array<DealDataGuarantors>;
  /** An aggregate relationship */
  DealDataGuarantors_aggregate: DealDataGuarantors_Aggregate;
  /** An array relationship */
  DealExtensionItems: Array<DealExtensionItems>;
  /** An aggregate relationship */
  DealExtensionItems_aggregate: DealExtensionItems_Aggregate;
  /** An object relationship */
  DealFitoutLandlordWork?: Maybe<DealFitoutLandlordWorks>;
  /** An array relationship */
  DealFitoutLandlordWorks: Array<DealFitoutLandlordWorks>;
  /** An aggregate relationship */
  DealFitoutLandlordWorks_aggregate: DealFitoutLandlordWorks_Aggregate;
  /** An object relationship */
  DealLAFApprovals?: Maybe<DealLafApprovals>;
  /** An array relationship */
  DealOptions: Array<DealOptions>;
  /** An aggregate relationship */
  DealOptions_aggregate: DealOptions_Aggregate;
  /** An array relationship */
  DealPermittedUses: Array<DealPermittedUses>;
  /** An aggregate relationship */
  DealPermittedUses_aggregate: DealPermittedUses_Aggregate;
  /** An array relationship */
  DealSpecialConditions: Array<DealSpecialConditions>;
  /** An aggregate relationship */
  DealSpecialConditions_aggregate: DealSpecialConditions_Aggregate;
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  /** An object relationship */
  NoticeServingAddress?: Maybe<Locations>;
  achieved_bank_guarantee?: Maybe<Scalars["numeric"]>;
  achieved_rent_deposit?: Maybe<Scalars["numeric"]>;
  analyst_deal_comp?: Maybe<Scalars["numeric"]>;
  analyst_deal_reason?: Maybe<Scalars["String"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["numeric"]>;
  analyst_normalised_promo?: Maybe<Scalars["numeric"]>;
  annual_increase?: Maybe<Scalars["numeric"]>;
  annual_increase_type?: Maybe<Scalars["String"]>;
  bank_guarantee_amount?: Maybe<Scalars["numeric"]>;
  bank_guarantee_id?: Maybe<Scalars["Int"]>;
  bank_guarantee_months?: Maybe<Scalars["Int"]>;
  base_net_rent: Scalars["numeric"];
  capped_occupancy_costs_state: Scalars["String"];
  cash_incentive?: Maybe<Scalars["numeric"]>;
  charge_code?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  dealLAFApprovalsByDealDataId: Array<DealLafApprovals>;
  /** An aggregate relationship */
  dealLAFApprovalsByDealDataId_aggregate: DealLafApprovals_Aggregate;
  deal_id: Scalars["bigint"];
  development_fees?: Maybe<Scalars["numeric"]>;
  fitout_contribution?: Maybe<Scalars["numeric"]>;
  fitout_term: Scalars["Int"];
  fixed_handover_date?: Maybe<Scalars["Boolean"]>;
  gross_rent?: Maybe<Scalars["numeric"]>;
  guarantors_id?: Maybe<Scalars["Int"]>;
  /** A computed field, executes function "add_fitout_to_handover_date" */
  handover_add_fitout?: Maybe<Scalars["date"]>;
  handover_date: Scalars["date"];
  historical_base_rent?: Maybe<Scalars["numeric"]>;
  historical_outgoings?: Maybe<Scalars["numeric"]>;
  historical_promo_levy?: Maybe<Scalars["numeric"]>;
  historical_tenant_name: Scalars["String"];
  /** An object relationship */
  hoardingTriSelectEnum?: Maybe<TriSelectEnum>;
  hoarding_amt?: Maybe<Scalars["numeric"]>;
  hoarding_id?: Maybe<Scalars["Int"]>;
  id: Scalars["bigint"];
  last_modified_by?: Maybe<Scalars["String"]>;
  lease_start: Scalars["date"];
  lease_term: Scalars["Int"];
  lease_type?: Maybe<Scalars["String"]>;
  mat_sales?: Maybe<Scalars["numeric"]>;
  metadata?: Maybe<Scalars["String"]>;
  notice_address_id?: Maybe<Scalars["bigint"]>;
  notice_email?: Maybe<Scalars["String"]>;
  number_of_carparks?: Maybe<Scalars["Int"]>;
  number_of_signs?: Maybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["numeric"]>;
  outgoings_psm?: Maybe<Scalars["numeric"]>;
  outgoings_state?: Maybe<Scalars["String"]>;
  parking_licence_required?: Maybe<Scalars["Boolean"]>;
  parking_location?: Maybe<Scalars["String"]>;
  parking_rent?: Maybe<Scalars["numeric"]>;
  promotion_amt?: Maybe<Scalars["numeric"]>;
  promotion_levy_state?: Maybe<Scalars["String"]>;
  promotion_levy_type?: Maybe<Scalars["String"]>;
  recovery_survey_fee?: Maybe<Scalars["numeric"]>;
  rent_deposit?: Maybe<Scalars["numeric"]>;
  rent_deposit_months?: Maybe<Scalars["Int"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  rent_starts?: Maybe<Scalars["Boolean"]>;
  rubbish_removal_fees?: Maybe<Scalars["numeric"]>;
  sales_category?: Maybe<Scalars["String"]>;
  sales_type?: Maybe<SalesType_Enum>;
  seating_area?: Maybe<Scalars["numeric"]>;
  seating_licence_required?: Maybe<Scalars["Boolean"]>;
  seating_rent?: Maybe<Scalars["numeric"]>;
  security_amt?: Maybe<Scalars["numeric"]>;
  security_id?: Maybe<Scalars["Int"]>;
  signage_licence_required?: Maybe<Scalars["Boolean"]>;
  signage_location?: Maybe<Scalars["String"]>;
  signage_rent?: Maybe<Scalars["numeric"]>;
  storage_area?: Maybe<Scalars["numeric"]>;
  storage_licence_required?: Maybe<Scalars["Boolean"]>;
  storage_rent?: Maybe<Scalars["numeric"]>;
  tenancy_coordination?: Maybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: Maybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["numeric"]>;
  /** An object relationship */
  triSelectEnumByBankGuaranteeId?: Maybe<TriSelectEnum>;
  /** An object relationship */
  triSelectEnumByGuarantorsId?: Maybe<TriSelectEnum>;
  /** An object relationship */
  triSelectEnumBySecurityId?: Maybe<TriSelectEnum>;
  turn_over_rent_unnatural?: Maybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: Maybe<Scalars["numeric"]>;
  turnover_rent_state?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "DealData" */
export type DealDataBaseLandlordServicesArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataBaseLandlordServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealDataCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealDataCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealDataGuarantorsArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealDataGuarantors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealExtensionItemsArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealExtensionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealFitoutLandlordWorksArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealFitoutLandlordWorks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealOptionsArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealPermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealPermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealLafApprovalsByDealDataIdArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "DealData" */
export type DealDataDealLafApprovalsByDealDataId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "DealDataCalculations" */
export type DealDataCalculations = {
  __typename?: "DealDataCalculations";
  annual_promo?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  outgoings_calc?: Maybe<Scalars["numeric"]>;
  promo_calc?: Maybe<Scalars["numeric"]>;
  rent_deposit_monthly?: Maybe<Scalars["numeric"]>;
};

/** aggregated selection of "DealDataCalculations" */
export type DealDataCalculations_Aggregate = {
  __typename?: "DealDataCalculations_aggregate";
  aggregate?: Maybe<DealDataCalculations_Aggregate_Fields>;
  nodes: Array<DealDataCalculations>;
};

/** aggregate fields of "DealDataCalculations" */
export type DealDataCalculations_Aggregate_Fields = {
  __typename?: "DealDataCalculations_aggregate_fields";
  avg?: Maybe<DealDataCalculations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealDataCalculations_Max_Fields>;
  min?: Maybe<DealDataCalculations_Min_Fields>;
  stddev?: Maybe<DealDataCalculations_Stddev_Fields>;
  stddev_pop?: Maybe<DealDataCalculations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealDataCalculations_Stddev_Samp_Fields>;
  sum?: Maybe<DealDataCalculations_Sum_Fields>;
  var_pop?: Maybe<DealDataCalculations_Var_Pop_Fields>;
  var_samp?: Maybe<DealDataCalculations_Var_Samp_Fields>;
  variance?: Maybe<DealDataCalculations_Variance_Fields>;
};

/** aggregate fields of "DealDataCalculations" */
export type DealDataCalculations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealDataCalculations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type DealDataCalculations_Avg_Fields = {
  __typename?: "DealDataCalculations_avg_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "DealDataCalculations". All fields are combined with a logical 'AND'. */
export type DealDataCalculations_Bool_Exp = {
  _and?: InputMaybe<Array<DealDataCalculations_Bool_Exp>>;
  _not?: InputMaybe<DealDataCalculations_Bool_Exp>;
  _or?: InputMaybe<Array<DealDataCalculations_Bool_Exp>>;
  annual_promo?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  outgoings_calc?: InputMaybe<Numeric_Comparison_Exp>;
  promo_calc?: InputMaybe<Numeric_Comparison_Exp>;
  rent_deposit_monthly?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "DealDataCalculations" */
export type DealDataCalculations_Insert_Input = {
  annual_promo?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  outgoings_calc?: InputMaybe<Scalars["numeric"]>;
  promo_calc?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_monthly?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type DealDataCalculations_Max_Fields = {
  __typename?: "DealDataCalculations_max_fields";
  annual_promo?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  outgoings_calc?: Maybe<Scalars["numeric"]>;
  promo_calc?: Maybe<Scalars["numeric"]>;
  rent_deposit_monthly?: Maybe<Scalars["numeric"]>;
};

/** aggregate min on columns */
export type DealDataCalculations_Min_Fields = {
  __typename?: "DealDataCalculations_min_fields";
  annual_promo?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  outgoings_calc?: Maybe<Scalars["numeric"]>;
  promo_calc?: Maybe<Scalars["numeric"]>;
  rent_deposit_monthly?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting object relation for remote table "DealDataCalculations" */
export type DealDataCalculations_Obj_Rel_Insert_Input = {
  data: DealDataCalculations_Insert_Input;
};

/** Ordering options when selecting data from "DealDataCalculations". */
export type DealDataCalculations_Order_By = {
  annual_promo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outgoings_calc?: InputMaybe<Order_By>;
  promo_calc?: InputMaybe<Order_By>;
  rent_deposit_monthly?: InputMaybe<Order_By>;
};

/** select columns of table "DealDataCalculations" */
export enum DealDataCalculations_Select_Column {
  /** column name */
  AnnualPromo = "annual_promo",
  /** column name */
  Id = "id",
  /** column name */
  OutgoingsCalc = "outgoings_calc",
  /** column name */
  PromoCalc = "promo_calc",
  /** column name */
  RentDepositMonthly = "rent_deposit_monthly",
}

/** aggregate stddev on columns */
export type DealDataCalculations_Stddev_Fields = {
  __typename?: "DealDataCalculations_stddev_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DealDataCalculations_Stddev_Pop_Fields = {
  __typename?: "DealDataCalculations_stddev_pop_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DealDataCalculations_Stddev_Samp_Fields = {
  __typename?: "DealDataCalculations_stddev_samp_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "DealDataCalculations" */
export type DealDataCalculations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealDataCalculations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealDataCalculations_Stream_Cursor_Value_Input = {
  annual_promo?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  outgoings_calc?: InputMaybe<Scalars["numeric"]>;
  promo_calc?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_monthly?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate sum on columns */
export type DealDataCalculations_Sum_Fields = {
  __typename?: "DealDataCalculations_sum_fields";
  annual_promo?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  outgoings_calc?: Maybe<Scalars["numeric"]>;
  promo_calc?: Maybe<Scalars["numeric"]>;
  rent_deposit_monthly?: Maybe<Scalars["numeric"]>;
};

/** aggregate var_pop on columns */
export type DealDataCalculations_Var_Pop_Fields = {
  __typename?: "DealDataCalculations_var_pop_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DealDataCalculations_Var_Samp_Fields = {
  __typename?: "DealDataCalculations_var_samp_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DealDataCalculations_Variance_Fields = {
  __typename?: "DealDataCalculations_variance_fields";
  annual_promo?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outgoings_calc?: Maybe<Scalars["Float"]>;
  promo_calc?: Maybe<Scalars["Float"]>;
  rent_deposit_monthly?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "DealDataCustomLicences" */
export type DealDataCustomLicences = {
  __typename?: "DealDataCustomLicences";
  /** An object relationship */
  CustomLicences?: Maybe<CustomLicences>;
  /** An object relationship */
  DealData?: Maybe<DealData>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_licence_id?: Maybe<Scalars["bigint"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "DealDataCustomLicences" */
export type DealDataCustomLicences_Aggregate = {
  __typename?: "DealDataCustomLicences_aggregate";
  aggregate?: Maybe<DealDataCustomLicences_Aggregate_Fields>;
  nodes: Array<DealDataCustomLicences>;
};

export type DealDataCustomLicences_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealDataCustomLicences_Aggregate_Bool_Exp_Count>;
};

export type DealDataCustomLicences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealDataCustomLicences" */
export type DealDataCustomLicences_Aggregate_Fields = {
  __typename?: "DealDataCustomLicences_aggregate_fields";
  avg?: Maybe<DealDataCustomLicences_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealDataCustomLicences_Max_Fields>;
  min?: Maybe<DealDataCustomLicences_Min_Fields>;
  stddev?: Maybe<DealDataCustomLicences_Stddev_Fields>;
  stddev_pop?: Maybe<DealDataCustomLicences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealDataCustomLicences_Stddev_Samp_Fields>;
  sum?: Maybe<DealDataCustomLicences_Sum_Fields>;
  var_pop?: Maybe<DealDataCustomLicences_Var_Pop_Fields>;
  var_samp?: Maybe<DealDataCustomLicences_Var_Samp_Fields>;
  variance?: Maybe<DealDataCustomLicences_Variance_Fields>;
};

/** aggregate fields of "DealDataCustomLicences" */
export type DealDataCustomLicences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Aggregate_Order_By = {
  avg?: InputMaybe<DealDataCustomLicences_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealDataCustomLicences_Max_Order_By>;
  min?: InputMaybe<DealDataCustomLicences_Min_Order_By>;
  stddev?: InputMaybe<DealDataCustomLicences_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealDataCustomLicences_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealDataCustomLicences_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealDataCustomLicences_Sum_Order_By>;
  var_pop?: InputMaybe<DealDataCustomLicences_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealDataCustomLicences_Var_Samp_Order_By>;
  variance?: InputMaybe<DealDataCustomLicences_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealDataCustomLicences" */
export type DealDataCustomLicences_Arr_Rel_Insert_Input = {
  data: Array<DealDataCustomLicences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealDataCustomLicences_On_Conflict>;
};

/** aggregate avg on columns */
export type DealDataCustomLicences_Avg_Fields = {
  __typename?: "DealDataCustomLicences_avg_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Avg_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealDataCustomLicences". All fields are combined with a logical 'AND'. */
export type DealDataCustomLicences_Bool_Exp = {
  CustomLicences?: InputMaybe<CustomLicences_Bool_Exp>;
  DealData?: InputMaybe<DealData_Bool_Exp>;
  _and?: InputMaybe<Array<DealDataCustomLicences_Bool_Exp>>;
  _not?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
  _or?: InputMaybe<Array<DealDataCustomLicences_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_licence_id?: InputMaybe<Bigint_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealDataCustomLicences" */
export enum DealDataCustomLicences_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealDataCustomLicencesPkey = "DealDataCustomLicences_pkey",
  /** unique or primary key constraint on columns "custom_licence_id", "deal_data_id" */
  DealdatacustomlicencesDealDataCustomLicenceUnique = "dealdatacustomlicences_deal_data_custom_licence_unique",
}

/** input type for incrementing numeric columns in table "DealDataCustomLicences" */
export type DealDataCustomLicences_Inc_Input = {
  custom_licence_id?: InputMaybe<Scalars["bigint"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealDataCustomLicences" */
export type DealDataCustomLicences_Insert_Input = {
  CustomLicences?: InputMaybe<CustomLicences_Obj_Rel_Insert_Input>;
  DealData?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_licence_id?: InputMaybe<Scalars["bigint"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type DealDataCustomLicences_Max_Fields = {
  __typename?: "DealDataCustomLicences_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_licence_id?: Maybe<Scalars["bigint"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealDataCustomLicences_Min_Fields = {
  __typename?: "DealDataCustomLicences_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_licence_id?: Maybe<Scalars["bigint"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealDataCustomLicences" */
export type DealDataCustomLicences_Mutation_Response = {
  __typename?: "DealDataCustomLicences_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealDataCustomLicences>;
};

/** on_conflict condition type for table "DealDataCustomLicences" */
export type DealDataCustomLicences_On_Conflict = {
  constraint: DealDataCustomLicences_Constraint;
  update_columns?: Array<DealDataCustomLicences_Update_Column>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

/** Ordering options when selecting data from "DealDataCustomLicences". */
export type DealDataCustomLicences_Order_By = {
  CustomLicences?: InputMaybe<CustomLicences_Order_By>;
  DealData?: InputMaybe<DealData_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealDataCustomLicences */
export type DealDataCustomLicences_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealDataCustomLicences" */
export enum DealDataCustomLicences_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomLicenceId = "custom_licence_id",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "DealDataCustomLicences" */
export type DealDataCustomLicences_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_licence_id?: InputMaybe<Scalars["bigint"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type DealDataCustomLicences_Stddev_Fields = {
  __typename?: "DealDataCustomLicences_stddev_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Stddev_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealDataCustomLicences_Stddev_Pop_Fields = {
  __typename?: "DealDataCustomLicences_stddev_pop_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Stddev_Pop_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealDataCustomLicences_Stddev_Samp_Fields = {
  __typename?: "DealDataCustomLicences_stddev_samp_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Stddev_Samp_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealDataCustomLicences" */
export type DealDataCustomLicences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealDataCustomLicences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealDataCustomLicences_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_licence_id?: InputMaybe<Scalars["bigint"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type DealDataCustomLicences_Sum_Fields = {
  __typename?: "DealDataCustomLicences_sum_fields";
  custom_licence_id?: Maybe<Scalars["bigint"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Sum_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealDataCustomLicences" */
export enum DealDataCustomLicences_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomLicenceId = "custom_licence_id",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type DealDataCustomLicences_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealDataCustomLicences_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealDataCustomLicences_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealDataCustomLicences_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealDataCustomLicences_Var_Pop_Fields = {
  __typename?: "DealDataCustomLicences_var_pop_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Var_Pop_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealDataCustomLicences_Var_Samp_Fields = {
  __typename?: "DealDataCustomLicences_var_samp_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Var_Samp_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealDataCustomLicences_Variance_Fields = {
  __typename?: "DealDataCustomLicences_variance_fields";
  custom_licence_id?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealDataCustomLicences" */
export type DealDataCustomLicences_Variance_Order_By = {
  custom_licence_id?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealDataGuarantors" */
export type DealDataGuarantors = {
  __typename?: "DealDataGuarantors";
  /** An object relationship */
  DealData?: Maybe<DealData>;
  /** An object relationship */
  State?: Maybe<States>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealDataGuarantors" */
export type DealDataGuarantors_Aggregate = {
  __typename?: "DealDataGuarantors_aggregate";
  aggregate?: Maybe<DealDataGuarantors_Aggregate_Fields>;
  nodes: Array<DealDataGuarantors>;
};

export type DealDataGuarantors_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealDataGuarantors_Aggregate_Bool_Exp_Count>;
};

export type DealDataGuarantors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealDataGuarantors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealDataGuarantors" */
export type DealDataGuarantors_Aggregate_Fields = {
  __typename?: "DealDataGuarantors_aggregate_fields";
  avg?: Maybe<DealDataGuarantors_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealDataGuarantors_Max_Fields>;
  min?: Maybe<DealDataGuarantors_Min_Fields>;
  stddev?: Maybe<DealDataGuarantors_Stddev_Fields>;
  stddev_pop?: Maybe<DealDataGuarantors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealDataGuarantors_Stddev_Samp_Fields>;
  sum?: Maybe<DealDataGuarantors_Sum_Fields>;
  var_pop?: Maybe<DealDataGuarantors_Var_Pop_Fields>;
  var_samp?: Maybe<DealDataGuarantors_Var_Samp_Fields>;
  variance?: Maybe<DealDataGuarantors_Variance_Fields>;
};

/** aggregate fields of "DealDataGuarantors" */
export type DealDataGuarantors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealDataGuarantors" */
export type DealDataGuarantors_Aggregate_Order_By = {
  avg?: InputMaybe<DealDataGuarantors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealDataGuarantors_Max_Order_By>;
  min?: InputMaybe<DealDataGuarantors_Min_Order_By>;
  stddev?: InputMaybe<DealDataGuarantors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealDataGuarantors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealDataGuarantors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealDataGuarantors_Sum_Order_By>;
  var_pop?: InputMaybe<DealDataGuarantors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealDataGuarantors_Var_Samp_Order_By>;
  variance?: InputMaybe<DealDataGuarantors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealDataGuarantors" */
export type DealDataGuarantors_Arr_Rel_Insert_Input = {
  data: Array<DealDataGuarantors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealDataGuarantors_On_Conflict>;
};

/** aggregate avg on columns */
export type DealDataGuarantors_Avg_Fields = {
  __typename?: "DealDataGuarantors_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealDataGuarantors". All fields are combined with a logical 'AND'. */
export type DealDataGuarantors_Bool_Exp = {
  DealData?: InputMaybe<DealData_Bool_Exp>;
  State?: InputMaybe<States_Bool_Exp>;
  _and?: InputMaybe<Array<DealDataGuarantors_Bool_Exp>>;
  _not?: InputMaybe<DealDataGuarantors_Bool_Exp>;
  _or?: InputMaybe<Array<DealDataGuarantors_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  postcode?: InputMaybe<Int_Comparison_Exp>;
  state_id?: InputMaybe<Int_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  suburb?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealDataGuarantors" */
export enum DealDataGuarantors_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealDataGuarantorsPkey = "DealDataGuarantors_pkey",
}

/** input type for incrementing numeric columns in table "DealDataGuarantors" */
export type DealDataGuarantors_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealDataGuarantors" */
export type DealDataGuarantors_Insert_Input = {
  DealData?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  State?: InputMaybe<States_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  email?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealDataGuarantors_Max_Fields = {
  __typename?: "DealDataGuarantors_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealDataGuarantors_Min_Fields = {
  __typename?: "DealDataGuarantors_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  email?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealDataGuarantors" */
export type DealDataGuarantors_Mutation_Response = {
  __typename?: "DealDataGuarantors_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealDataGuarantors>;
};

/** on_conflict condition type for table "DealDataGuarantors" */
export type DealDataGuarantors_On_Conflict = {
  constraint: DealDataGuarantors_Constraint;
  update_columns?: Array<DealDataGuarantors_Update_Column>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

/** Ordering options when selecting data from "DealDataGuarantors". */
export type DealDataGuarantors_Order_By = {
  DealData?: InputMaybe<DealData_Order_By>;
  State?: InputMaybe<States_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealDataGuarantors */
export type DealDataGuarantors_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealDataGuarantors" */
export enum DealDataGuarantors_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Email = "email",
  /** column name */
  FullName = "full_name",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Postcode = "postcode",
  /** column name */
  StateId = "state_id",
  /** column name */
  Street1 = "street1",
  /** column name */
  Street2 = "street2",
  /** column name */
  Suburb = "suburb",
}

/** input type for updating data in table "DealDataGuarantors" */
export type DealDataGuarantors_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  email?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealDataGuarantors_Stddev_Fields = {
  __typename?: "DealDataGuarantors_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealDataGuarantors_Stddev_Pop_Fields = {
  __typename?: "DealDataGuarantors_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealDataGuarantors_Stddev_Samp_Fields = {
  __typename?: "DealDataGuarantors_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealDataGuarantors" */
export type DealDataGuarantors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealDataGuarantors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealDataGuarantors_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  email?: InputMaybe<Scalars["String"]>;
  full_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealDataGuarantors_Sum_Fields = {
  __typename?: "DealDataGuarantors_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealDataGuarantors" */
export enum DealDataGuarantors_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Email = "email",
  /** column name */
  FullName = "full_name",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Postcode = "postcode",
  /** column name */
  StateId = "state_id",
  /** column name */
  Street1 = "street1",
  /** column name */
  Street2 = "street2",
  /** column name */
  Suburb = "suburb",
}

export type DealDataGuarantors_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealDataGuarantors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealDataGuarantors_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealDataGuarantors_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealDataGuarantors_Var_Pop_Fields = {
  __typename?: "DealDataGuarantors_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealDataGuarantors_Var_Samp_Fields = {
  __typename?: "DealDataGuarantors_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealDataGuarantors_Variance_Fields = {
  __typename?: "DealDataGuarantors_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealDataGuarantors" */
export type DealDataGuarantors_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "DealData" */
export type DealData_Aggregate = {
  __typename?: "DealData_aggregate";
  aggregate?: Maybe<DealData_Aggregate_Fields>;
  nodes: Array<DealData>;
};

export type DealData_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealData_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealData_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealData_Aggregate_Bool_Exp_Count>;
};

export type DealData_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealData_Select_Column_DealData_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealData_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealData_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealData_Select_Column_DealData_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealData_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealData_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealData_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealData_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealData" */
export type DealData_Aggregate_Fields = {
  __typename?: "DealData_aggregate_fields";
  avg?: Maybe<DealData_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealData_Max_Fields>;
  min?: Maybe<DealData_Min_Fields>;
  stddev?: Maybe<DealData_Stddev_Fields>;
  stddev_pop?: Maybe<DealData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealData_Stddev_Samp_Fields>;
  sum?: Maybe<DealData_Sum_Fields>;
  var_pop?: Maybe<DealData_Var_Pop_Fields>;
  var_samp?: Maybe<DealData_Var_Samp_Fields>;
  variance?: Maybe<DealData_Variance_Fields>;
};

/** aggregate fields of "DealData" */
export type DealData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealData_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealData" */
export type DealData_Aggregate_Order_By = {
  avg?: InputMaybe<DealData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealData_Max_Order_By>;
  min?: InputMaybe<DealData_Min_Order_By>;
  stddev?: InputMaybe<DealData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealData_Sum_Order_By>;
  var_pop?: InputMaybe<DealData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealData_Var_Samp_Order_By>;
  variance?: InputMaybe<DealData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealData" */
export type DealData_Arr_Rel_Insert_Input = {
  data: Array<DealData_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealData_On_Conflict>;
};

/** aggregate avg on columns */
export type DealData_Avg_Fields = {
  __typename?: "DealData_avg_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealData" */
export type DealData_Avg_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealData". All fields are combined with a logical 'AND'. */
export type DealData_Bool_Exp = {
  BaseLandlordServices?: InputMaybe<BaseLandlordServices_Bool_Exp>;
  BaseLandlordServices_aggregate?: InputMaybe<BaseLandlordServices_Aggregate_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  DealDataCalculations?: InputMaybe<DealDataCalculations_Bool_Exp>;
  DealDataCustomLicences?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
  DealDataCustomLicences_aggregate?: InputMaybe<DealDataCustomLicences_Aggregate_Bool_Exp>;
  DealDataGuarantors?: InputMaybe<DealDataGuarantors_Bool_Exp>;
  DealDataGuarantors_aggregate?: InputMaybe<DealDataGuarantors_Aggregate_Bool_Exp>;
  DealExtensionItems?: InputMaybe<DealExtensionItems_Bool_Exp>;
  DealExtensionItems_aggregate?: InputMaybe<DealExtensionItems_Aggregate_Bool_Exp>;
  DealFitoutLandlordWork?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  DealFitoutLandlordWorks?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  DealFitoutLandlordWorks_aggregate?: InputMaybe<DealFitoutLandlordWorks_Aggregate_Bool_Exp>;
  DealLAFApprovals?: InputMaybe<DealLafApprovals_Bool_Exp>;
  DealOptions?: InputMaybe<DealOptions_Bool_Exp>;
  DealOptions_aggregate?: InputMaybe<DealOptions_Aggregate_Bool_Exp>;
  DealPermittedUses?: InputMaybe<DealPermittedUses_Bool_Exp>;
  DealPermittedUses_aggregate?: InputMaybe<DealPermittedUses_Aggregate_Bool_Exp>;
  DealSpecialConditions?: InputMaybe<DealSpecialConditions_Bool_Exp>;
  DealSpecialConditions_aggregate?: InputMaybe<DealSpecialConditions_Aggregate_Bool_Exp>;
  DealsUnits?: InputMaybe<DealsUnits_Bool_Exp>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Bool_Exp>;
  NoticeServingAddress?: InputMaybe<Locations_Bool_Exp>;
  _and?: InputMaybe<Array<DealData_Bool_Exp>>;
  _not?: InputMaybe<DealData_Bool_Exp>;
  _or?: InputMaybe<Array<DealData_Bool_Exp>>;
  achieved_bank_guarantee?: InputMaybe<Numeric_Comparison_Exp>;
  achieved_rent_deposit?: InputMaybe<Numeric_Comparison_Exp>;
  analyst_deal_comp?: InputMaybe<Numeric_Comparison_Exp>;
  analyst_deal_reason?: InputMaybe<String_Comparison_Exp>;
  analyst_normalised_base_rent?: InputMaybe<Numeric_Comparison_Exp>;
  analyst_normalised_promo?: InputMaybe<Numeric_Comparison_Exp>;
  annual_increase?: InputMaybe<Numeric_Comparison_Exp>;
  annual_increase_type?: InputMaybe<String_Comparison_Exp>;
  bank_guarantee_amount?: InputMaybe<Numeric_Comparison_Exp>;
  bank_guarantee_id?: InputMaybe<Int_Comparison_Exp>;
  bank_guarantee_months?: InputMaybe<Int_Comparison_Exp>;
  base_net_rent?: InputMaybe<Numeric_Comparison_Exp>;
  capped_occupancy_costs_state?: InputMaybe<String_Comparison_Exp>;
  cash_incentive?: InputMaybe<Numeric_Comparison_Exp>;
  charge_code?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dealLAFApprovalsByDealDataId?: InputMaybe<DealLafApprovals_Bool_Exp>;
  dealLAFApprovalsByDealDataId_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  development_fees?: InputMaybe<Numeric_Comparison_Exp>;
  fitout_contribution?: InputMaybe<Numeric_Comparison_Exp>;
  fitout_term?: InputMaybe<Int_Comparison_Exp>;
  fixed_handover_date?: InputMaybe<Boolean_Comparison_Exp>;
  gross_rent?: InputMaybe<Numeric_Comparison_Exp>;
  guarantors_id?: InputMaybe<Int_Comparison_Exp>;
  handover_add_fitout?: InputMaybe<Date_Comparison_Exp>;
  handover_date?: InputMaybe<Date_Comparison_Exp>;
  historical_base_rent?: InputMaybe<Numeric_Comparison_Exp>;
  historical_outgoings?: InputMaybe<Numeric_Comparison_Exp>;
  historical_promo_levy?: InputMaybe<Numeric_Comparison_Exp>;
  historical_tenant_name?: InputMaybe<String_Comparison_Exp>;
  hoardingTriSelectEnum?: InputMaybe<TriSelectEnum_Bool_Exp>;
  hoarding_amt?: InputMaybe<Numeric_Comparison_Exp>;
  hoarding_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_modified_by?: InputMaybe<String_Comparison_Exp>;
  lease_start?: InputMaybe<Date_Comparison_Exp>;
  lease_term?: InputMaybe<Int_Comparison_Exp>;
  lease_type?: InputMaybe<String_Comparison_Exp>;
  mat_sales?: InputMaybe<Numeric_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  notice_address_id?: InputMaybe<Bigint_Comparison_Exp>;
  notice_email?: InputMaybe<String_Comparison_Exp>;
  number_of_carparks?: InputMaybe<Int_Comparison_Exp>;
  number_of_signs?: InputMaybe<Int_Comparison_Exp>;
  opening_promotion_levy_fees?: InputMaybe<Numeric_Comparison_Exp>;
  outgoings_psm?: InputMaybe<Numeric_Comparison_Exp>;
  outgoings_state?: InputMaybe<String_Comparison_Exp>;
  parking_licence_required?: InputMaybe<Boolean_Comparison_Exp>;
  parking_location?: InputMaybe<String_Comparison_Exp>;
  parking_rent?: InputMaybe<Numeric_Comparison_Exp>;
  promotion_amt?: InputMaybe<Numeric_Comparison_Exp>;
  promotion_levy_state?: InputMaybe<String_Comparison_Exp>;
  promotion_levy_type?: InputMaybe<String_Comparison_Exp>;
  recovery_survey_fee?: InputMaybe<Numeric_Comparison_Exp>;
  rent_deposit?: InputMaybe<Numeric_Comparison_Exp>;
  rent_deposit_months?: InputMaybe<Int_Comparison_Exp>;
  rent_free_period?: InputMaybe<Numeric_Comparison_Exp>;
  rent_starts?: InputMaybe<Boolean_Comparison_Exp>;
  rubbish_removal_fees?: InputMaybe<Numeric_Comparison_Exp>;
  sales_category?: InputMaybe<String_Comparison_Exp>;
  sales_type?: InputMaybe<SalesType_Enum_Comparison_Exp>;
  seating_area?: InputMaybe<Numeric_Comparison_Exp>;
  seating_licence_required?: InputMaybe<Boolean_Comparison_Exp>;
  seating_rent?: InputMaybe<Numeric_Comparison_Exp>;
  security_amt?: InputMaybe<Numeric_Comparison_Exp>;
  security_id?: InputMaybe<Int_Comparison_Exp>;
  signage_licence_required?: InputMaybe<Boolean_Comparison_Exp>;
  signage_location?: InputMaybe<String_Comparison_Exp>;
  signage_rent?: InputMaybe<Numeric_Comparison_Exp>;
  storage_area?: InputMaybe<Numeric_Comparison_Exp>;
  storage_licence_required?: InputMaybe<Boolean_Comparison_Exp>;
  storage_rent?: InputMaybe<Numeric_Comparison_Exp>;
  tenancy_coordination?: InputMaybe<Numeric_Comparison_Exp>;
  tenancy_coordination_fee?: InputMaybe<Numeric_Comparison_Exp>;
  tenant_contribution_landlord_works?: InputMaybe<Numeric_Comparison_Exp>;
  triSelectEnumByBankGuaranteeId?: InputMaybe<TriSelectEnum_Bool_Exp>;
  triSelectEnumByGuarantorsId?: InputMaybe<TriSelectEnum_Bool_Exp>;
  triSelectEnumBySecurityId?: InputMaybe<TriSelectEnum_Bool_Exp>;
  turn_over_rent_unnatural?: InputMaybe<Numeric_Comparison_Exp>;
  turnover_based_rent_percent?: InputMaybe<Numeric_Comparison_Exp>;
  turnover_rent_state?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealData" */
export enum DealData_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealDataPkey = "DealData_pkey",
  /** unique or primary key constraint on columns "deal_id", "version" */
  DealidVersionUnique = "dealid_version_unique",
}

/** input type for incrementing numeric columns in table "DealData" */
export type DealData_Inc_Input = {
  achieved_bank_guarantee?: InputMaybe<Scalars["numeric"]>;
  achieved_rent_deposit?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_comp?: InputMaybe<Scalars["numeric"]>;
  analyst_normalised_base_rent?: InputMaybe<Scalars["numeric"]>;
  analyst_normalised_promo?: InputMaybe<Scalars["numeric"]>;
  annual_increase?: InputMaybe<Scalars["numeric"]>;
  bank_guarantee_amount?: InputMaybe<Scalars["numeric"]>;
  bank_guarantee_id?: InputMaybe<Scalars["Int"]>;
  bank_guarantee_months?: InputMaybe<Scalars["Int"]>;
  base_net_rent?: InputMaybe<Scalars["numeric"]>;
  cash_incentive?: InputMaybe<Scalars["numeric"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  development_fees?: InputMaybe<Scalars["numeric"]>;
  fitout_contribution?: InputMaybe<Scalars["numeric"]>;
  fitout_term?: InputMaybe<Scalars["Int"]>;
  gross_rent?: InputMaybe<Scalars["numeric"]>;
  guarantors_id?: InputMaybe<Scalars["Int"]>;
  historical_base_rent?: InputMaybe<Scalars["numeric"]>;
  historical_outgoings?: InputMaybe<Scalars["numeric"]>;
  historical_promo_levy?: InputMaybe<Scalars["numeric"]>;
  hoarding_amt?: InputMaybe<Scalars["numeric"]>;
  hoarding_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  lease_term?: InputMaybe<Scalars["Int"]>;
  mat_sales?: InputMaybe<Scalars["numeric"]>;
  notice_address_id?: InputMaybe<Scalars["bigint"]>;
  number_of_carparks?: InputMaybe<Scalars["Int"]>;
  number_of_signs?: InputMaybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: InputMaybe<Scalars["numeric"]>;
  outgoings_psm?: InputMaybe<Scalars["numeric"]>;
  parking_rent?: InputMaybe<Scalars["numeric"]>;
  promotion_amt?: InputMaybe<Scalars["numeric"]>;
  recovery_survey_fee?: InputMaybe<Scalars["numeric"]>;
  rent_deposit?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_months?: InputMaybe<Scalars["Int"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  rubbish_removal_fees?: InputMaybe<Scalars["numeric"]>;
  seating_area?: InputMaybe<Scalars["numeric"]>;
  seating_rent?: InputMaybe<Scalars["numeric"]>;
  security_amt?: InputMaybe<Scalars["numeric"]>;
  security_id?: InputMaybe<Scalars["Int"]>;
  signage_rent?: InputMaybe<Scalars["numeric"]>;
  storage_area?: InputMaybe<Scalars["numeric"]>;
  storage_rent?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: InputMaybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: InputMaybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: InputMaybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: InputMaybe<Scalars["numeric"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealData" */
export type DealData_Insert_Input = {
  BaseLandlordServices?: InputMaybe<BaseLandlordServices_Arr_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  DealDataCalculations?: InputMaybe<DealDataCalculations_Obj_Rel_Insert_Input>;
  DealDataCustomLicences?: InputMaybe<DealDataCustomLicences_Arr_Rel_Insert_Input>;
  DealDataGuarantors?: InputMaybe<DealDataGuarantors_Arr_Rel_Insert_Input>;
  DealExtensionItems?: InputMaybe<DealExtensionItems_Arr_Rel_Insert_Input>;
  DealFitoutLandlordWork?: InputMaybe<DealFitoutLandlordWorks_Obj_Rel_Insert_Input>;
  DealFitoutLandlordWorks?: InputMaybe<DealFitoutLandlordWorks_Arr_Rel_Insert_Input>;
  DealLAFApprovals?: InputMaybe<DealLafApprovals_Obj_Rel_Insert_Input>;
  DealOptions?: InputMaybe<DealOptions_Arr_Rel_Insert_Input>;
  DealPermittedUses?: InputMaybe<DealPermittedUses_Arr_Rel_Insert_Input>;
  DealSpecialConditions?: InputMaybe<DealSpecialConditions_Arr_Rel_Insert_Input>;
  DealsUnits?: InputMaybe<DealsUnits_Arr_Rel_Insert_Input>;
  NoticeServingAddress?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  achieved_bank_guarantee?: InputMaybe<Scalars["numeric"]>;
  achieved_rent_deposit?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_comp?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_reason?: InputMaybe<Scalars["String"]>;
  analyst_normalised_base_rent?: InputMaybe<Scalars["numeric"]>;
  analyst_normalised_promo?: InputMaybe<Scalars["numeric"]>;
  annual_increase?: InputMaybe<Scalars["numeric"]>;
  annual_increase_type?: InputMaybe<Scalars["String"]>;
  bank_guarantee_amount?: InputMaybe<Scalars["numeric"]>;
  bank_guarantee_id?: InputMaybe<Scalars["Int"]>;
  bank_guarantee_months?: InputMaybe<Scalars["Int"]>;
  base_net_rent?: InputMaybe<Scalars["numeric"]>;
  capped_occupancy_costs_state?: InputMaybe<Scalars["String"]>;
  cash_incentive?: InputMaybe<Scalars["numeric"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dealLAFApprovalsByDealDataId?: InputMaybe<DealLafApprovals_Arr_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  development_fees?: InputMaybe<Scalars["numeric"]>;
  fitout_contribution?: InputMaybe<Scalars["numeric"]>;
  fitout_term?: InputMaybe<Scalars["Int"]>;
  fixed_handover_date?: InputMaybe<Scalars["Boolean"]>;
  gross_rent?: InputMaybe<Scalars["numeric"]>;
  guarantors_id?: InputMaybe<Scalars["Int"]>;
  handover_date?: InputMaybe<Scalars["date"]>;
  historical_base_rent?: InputMaybe<Scalars["numeric"]>;
  historical_outgoings?: InputMaybe<Scalars["numeric"]>;
  historical_promo_levy?: InputMaybe<Scalars["numeric"]>;
  historical_tenant_name?: InputMaybe<Scalars["String"]>;
  hoardingTriSelectEnum?: InputMaybe<TriSelectEnum_Obj_Rel_Insert_Input>;
  hoarding_amt?: InputMaybe<Scalars["numeric"]>;
  hoarding_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_modified_by?: InputMaybe<Scalars["String"]>;
  lease_start?: InputMaybe<Scalars["date"]>;
  lease_term?: InputMaybe<Scalars["Int"]>;
  lease_type?: InputMaybe<Scalars["String"]>;
  mat_sales?: InputMaybe<Scalars["numeric"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  notice_address_id?: InputMaybe<Scalars["bigint"]>;
  notice_email?: InputMaybe<Scalars["String"]>;
  number_of_carparks?: InputMaybe<Scalars["Int"]>;
  number_of_signs?: InputMaybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: InputMaybe<Scalars["numeric"]>;
  outgoings_psm?: InputMaybe<Scalars["numeric"]>;
  outgoings_state?: InputMaybe<Scalars["String"]>;
  parking_licence_required?: InputMaybe<Scalars["Boolean"]>;
  parking_location?: InputMaybe<Scalars["String"]>;
  parking_rent?: InputMaybe<Scalars["numeric"]>;
  promotion_amt?: InputMaybe<Scalars["numeric"]>;
  promotion_levy_state?: InputMaybe<Scalars["String"]>;
  promotion_levy_type?: InputMaybe<Scalars["String"]>;
  recovery_survey_fee?: InputMaybe<Scalars["numeric"]>;
  rent_deposit?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_months?: InputMaybe<Scalars["Int"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  rent_starts?: InputMaybe<Scalars["Boolean"]>;
  rubbish_removal_fees?: InputMaybe<Scalars["numeric"]>;
  sales_category?: InputMaybe<Scalars["String"]>;
  sales_type?: InputMaybe<SalesType_Enum>;
  seating_area?: InputMaybe<Scalars["numeric"]>;
  seating_licence_required?: InputMaybe<Scalars["Boolean"]>;
  seating_rent?: InputMaybe<Scalars["numeric"]>;
  security_amt?: InputMaybe<Scalars["numeric"]>;
  security_id?: InputMaybe<Scalars["Int"]>;
  signage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  signage_location?: InputMaybe<Scalars["String"]>;
  signage_rent?: InputMaybe<Scalars["numeric"]>;
  storage_area?: InputMaybe<Scalars["numeric"]>;
  storage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  storage_rent?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: InputMaybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: InputMaybe<Scalars["numeric"]>;
  triSelectEnumByBankGuaranteeId?: InputMaybe<TriSelectEnum_Obj_Rel_Insert_Input>;
  triSelectEnumByGuarantorsId?: InputMaybe<TriSelectEnum_Obj_Rel_Insert_Input>;
  triSelectEnumBySecurityId?: InputMaybe<TriSelectEnum_Obj_Rel_Insert_Input>;
  turn_over_rent_unnatural?: InputMaybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: InputMaybe<Scalars["numeric"]>;
  turnover_rent_state?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type DealData_Max_Fields = {
  __typename?: "DealData_max_fields";
  achieved_bank_guarantee?: Maybe<Scalars["numeric"]>;
  achieved_rent_deposit?: Maybe<Scalars["numeric"]>;
  analyst_deal_comp?: Maybe<Scalars["numeric"]>;
  analyst_deal_reason?: Maybe<Scalars["String"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["numeric"]>;
  analyst_normalised_promo?: Maybe<Scalars["numeric"]>;
  annual_increase?: Maybe<Scalars["numeric"]>;
  annual_increase_type?: Maybe<Scalars["String"]>;
  bank_guarantee_amount?: Maybe<Scalars["numeric"]>;
  bank_guarantee_id?: Maybe<Scalars["Int"]>;
  bank_guarantee_months?: Maybe<Scalars["Int"]>;
  base_net_rent?: Maybe<Scalars["numeric"]>;
  capped_occupancy_costs_state?: Maybe<Scalars["String"]>;
  cash_incentive?: Maybe<Scalars["numeric"]>;
  charge_code?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  development_fees?: Maybe<Scalars["numeric"]>;
  fitout_contribution?: Maybe<Scalars["numeric"]>;
  fitout_term?: Maybe<Scalars["Int"]>;
  gross_rent?: Maybe<Scalars["numeric"]>;
  guarantors_id?: Maybe<Scalars["Int"]>;
  handover_date?: Maybe<Scalars["date"]>;
  historical_base_rent?: Maybe<Scalars["numeric"]>;
  historical_outgoings?: Maybe<Scalars["numeric"]>;
  historical_promo_levy?: Maybe<Scalars["numeric"]>;
  historical_tenant_name?: Maybe<Scalars["String"]>;
  hoarding_amt?: Maybe<Scalars["numeric"]>;
  hoarding_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_modified_by?: Maybe<Scalars["String"]>;
  lease_start?: Maybe<Scalars["date"]>;
  lease_term?: Maybe<Scalars["Int"]>;
  lease_type?: Maybe<Scalars["String"]>;
  mat_sales?: Maybe<Scalars["numeric"]>;
  metadata?: Maybe<Scalars["String"]>;
  notice_address_id?: Maybe<Scalars["bigint"]>;
  notice_email?: Maybe<Scalars["String"]>;
  number_of_carparks?: Maybe<Scalars["Int"]>;
  number_of_signs?: Maybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["numeric"]>;
  outgoings_psm?: Maybe<Scalars["numeric"]>;
  outgoings_state?: Maybe<Scalars["String"]>;
  parking_location?: Maybe<Scalars["String"]>;
  parking_rent?: Maybe<Scalars["numeric"]>;
  promotion_amt?: Maybe<Scalars["numeric"]>;
  promotion_levy_state?: Maybe<Scalars["String"]>;
  promotion_levy_type?: Maybe<Scalars["String"]>;
  recovery_survey_fee?: Maybe<Scalars["numeric"]>;
  rent_deposit?: Maybe<Scalars["numeric"]>;
  rent_deposit_months?: Maybe<Scalars["Int"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  rubbish_removal_fees?: Maybe<Scalars["numeric"]>;
  sales_category?: Maybe<Scalars["String"]>;
  seating_area?: Maybe<Scalars["numeric"]>;
  seating_rent?: Maybe<Scalars["numeric"]>;
  security_amt?: Maybe<Scalars["numeric"]>;
  security_id?: Maybe<Scalars["Int"]>;
  signage_location?: Maybe<Scalars["String"]>;
  signage_rent?: Maybe<Scalars["numeric"]>;
  storage_area?: Maybe<Scalars["numeric"]>;
  storage_rent?: Maybe<Scalars["numeric"]>;
  tenancy_coordination?: Maybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: Maybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: Maybe<Scalars["numeric"]>;
  turnover_rent_state?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "DealData" */
export type DealData_Max_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_deal_reason?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  annual_increase_type?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  capped_occupancy_costs_state?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  handover_date?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  historical_tenant_name?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  lease_start?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  lease_type?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  notice_email?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  outgoings_state?: InputMaybe<Order_By>;
  parking_location?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  promotion_levy_state?: InputMaybe<Order_By>;
  promotion_levy_type?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  sales_category?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_location?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  turnover_rent_state?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealData_Min_Fields = {
  __typename?: "DealData_min_fields";
  achieved_bank_guarantee?: Maybe<Scalars["numeric"]>;
  achieved_rent_deposit?: Maybe<Scalars["numeric"]>;
  analyst_deal_comp?: Maybe<Scalars["numeric"]>;
  analyst_deal_reason?: Maybe<Scalars["String"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["numeric"]>;
  analyst_normalised_promo?: Maybe<Scalars["numeric"]>;
  annual_increase?: Maybe<Scalars["numeric"]>;
  annual_increase_type?: Maybe<Scalars["String"]>;
  bank_guarantee_amount?: Maybe<Scalars["numeric"]>;
  bank_guarantee_id?: Maybe<Scalars["Int"]>;
  bank_guarantee_months?: Maybe<Scalars["Int"]>;
  base_net_rent?: Maybe<Scalars["numeric"]>;
  capped_occupancy_costs_state?: Maybe<Scalars["String"]>;
  cash_incentive?: Maybe<Scalars["numeric"]>;
  charge_code?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  development_fees?: Maybe<Scalars["numeric"]>;
  fitout_contribution?: Maybe<Scalars["numeric"]>;
  fitout_term?: Maybe<Scalars["Int"]>;
  gross_rent?: Maybe<Scalars["numeric"]>;
  guarantors_id?: Maybe<Scalars["Int"]>;
  handover_date?: Maybe<Scalars["date"]>;
  historical_base_rent?: Maybe<Scalars["numeric"]>;
  historical_outgoings?: Maybe<Scalars["numeric"]>;
  historical_promo_levy?: Maybe<Scalars["numeric"]>;
  historical_tenant_name?: Maybe<Scalars["String"]>;
  hoarding_amt?: Maybe<Scalars["numeric"]>;
  hoarding_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  last_modified_by?: Maybe<Scalars["String"]>;
  lease_start?: Maybe<Scalars["date"]>;
  lease_term?: Maybe<Scalars["Int"]>;
  lease_type?: Maybe<Scalars["String"]>;
  mat_sales?: Maybe<Scalars["numeric"]>;
  metadata?: Maybe<Scalars["String"]>;
  notice_address_id?: Maybe<Scalars["bigint"]>;
  notice_email?: Maybe<Scalars["String"]>;
  number_of_carparks?: Maybe<Scalars["Int"]>;
  number_of_signs?: Maybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["numeric"]>;
  outgoings_psm?: Maybe<Scalars["numeric"]>;
  outgoings_state?: Maybe<Scalars["String"]>;
  parking_location?: Maybe<Scalars["String"]>;
  parking_rent?: Maybe<Scalars["numeric"]>;
  promotion_amt?: Maybe<Scalars["numeric"]>;
  promotion_levy_state?: Maybe<Scalars["String"]>;
  promotion_levy_type?: Maybe<Scalars["String"]>;
  recovery_survey_fee?: Maybe<Scalars["numeric"]>;
  rent_deposit?: Maybe<Scalars["numeric"]>;
  rent_deposit_months?: Maybe<Scalars["Int"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  rubbish_removal_fees?: Maybe<Scalars["numeric"]>;
  sales_category?: Maybe<Scalars["String"]>;
  seating_area?: Maybe<Scalars["numeric"]>;
  seating_rent?: Maybe<Scalars["numeric"]>;
  security_amt?: Maybe<Scalars["numeric"]>;
  security_id?: Maybe<Scalars["Int"]>;
  signage_location?: Maybe<Scalars["String"]>;
  signage_rent?: Maybe<Scalars["numeric"]>;
  storage_area?: Maybe<Scalars["numeric"]>;
  storage_rent?: Maybe<Scalars["numeric"]>;
  tenancy_coordination?: Maybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: Maybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: Maybe<Scalars["numeric"]>;
  turnover_rent_state?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "DealData" */
export type DealData_Min_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_deal_reason?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  annual_increase_type?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  capped_occupancy_costs_state?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  handover_date?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  historical_tenant_name?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  lease_start?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  lease_type?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  notice_email?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  outgoings_state?: InputMaybe<Order_By>;
  parking_location?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  promotion_levy_state?: InputMaybe<Order_By>;
  promotion_levy_type?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  sales_category?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_location?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  turnover_rent_state?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealData" */
export type DealData_Mutation_Response = {
  __typename?: "DealData_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealData>;
};

/** input type for inserting object relation for remote table "DealData" */
export type DealData_Obj_Rel_Insert_Input = {
  data: DealData_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealData_On_Conflict>;
};

/** on_conflict condition type for table "DealData" */
export type DealData_On_Conflict = {
  constraint: DealData_Constraint;
  update_columns?: Array<DealData_Update_Column>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** Ordering options when selecting data from "DealData". */
export type DealData_Order_By = {
  BaseLandlordServices_aggregate?: InputMaybe<BaseLandlordServices_Aggregate_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  DealDataCalculations?: InputMaybe<DealDataCalculations_Order_By>;
  DealDataCustomLicences_aggregate?: InputMaybe<DealDataCustomLicences_Aggregate_Order_By>;
  DealDataGuarantors_aggregate?: InputMaybe<DealDataGuarantors_Aggregate_Order_By>;
  DealExtensionItems_aggregate?: InputMaybe<DealExtensionItems_Aggregate_Order_By>;
  DealFitoutLandlordWork?: InputMaybe<DealFitoutLandlordWorks_Order_By>;
  DealFitoutLandlordWorks_aggregate?: InputMaybe<DealFitoutLandlordWorks_Aggregate_Order_By>;
  DealLAFApprovals?: InputMaybe<DealLafApprovals_Order_By>;
  DealOptions_aggregate?: InputMaybe<DealOptions_Aggregate_Order_By>;
  DealPermittedUses_aggregate?: InputMaybe<DealPermittedUses_Aggregate_Order_By>;
  DealSpecialConditions_aggregate?: InputMaybe<DealSpecialConditions_Aggregate_Order_By>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Order_By>;
  NoticeServingAddress?: InputMaybe<Locations_Order_By>;
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_deal_reason?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  annual_increase_type?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  capped_occupancy_costs_state?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dealLAFApprovalsByDealDataId_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  fixed_handover_date?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  handover_add_fitout?: InputMaybe<Order_By>;
  handover_date?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  historical_tenant_name?: InputMaybe<Order_By>;
  hoardingTriSelectEnum?: InputMaybe<TriSelectEnum_Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  lease_start?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  lease_type?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  notice_email?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  outgoings_state?: InputMaybe<Order_By>;
  parking_licence_required?: InputMaybe<Order_By>;
  parking_location?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  promotion_levy_state?: InputMaybe<Order_By>;
  promotion_levy_type?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rent_starts?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  sales_category?: InputMaybe<Order_By>;
  sales_type?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_licence_required?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_licence_required?: InputMaybe<Order_By>;
  signage_location?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_licence_required?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  triSelectEnumByBankGuaranteeId?: InputMaybe<TriSelectEnum_Order_By>;
  triSelectEnumByGuarantorsId?: InputMaybe<TriSelectEnum_Order_By>;
  triSelectEnumBySecurityId?: InputMaybe<TriSelectEnum_Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  turnover_rent_state?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealData */
export type DealData_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealData" */
export enum DealData_Select_Column {
  /** column name */
  AchievedBankGuarantee = "achieved_bank_guarantee",
  /** column name */
  AchievedRentDeposit = "achieved_rent_deposit",
  /** column name */
  AnalystDealComp = "analyst_deal_comp",
  /** column name */
  AnalystDealReason = "analyst_deal_reason",
  /** column name */
  AnalystNormalisedBaseRent = "analyst_normalised_base_rent",
  /** column name */
  AnalystNormalisedPromo = "analyst_normalised_promo",
  /** column name */
  AnnualIncrease = "annual_increase",
  /** column name */
  AnnualIncreaseType = "annual_increase_type",
  /** column name */
  BankGuaranteeAmount = "bank_guarantee_amount",
  /** column name */
  BankGuaranteeId = "bank_guarantee_id",
  /** column name */
  BankGuaranteeMonths = "bank_guarantee_months",
  /** column name */
  BaseNetRent = "base_net_rent",
  /** column name */
  CappedOccupancyCostsState = "capped_occupancy_costs_state",
  /** column name */
  CashIncentive = "cash_incentive",
  /** column name */
  ChargeCode = "charge_code",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DevelopmentFees = "development_fees",
  /** column name */
  FitoutContribution = "fitout_contribution",
  /** column name */
  FitoutTerm = "fitout_term",
  /** column name */
  FixedHandoverDate = "fixed_handover_date",
  /** column name */
  GrossRent = "gross_rent",
  /** column name */
  GuarantorsId = "guarantors_id",
  /** column name */
  HandoverDate = "handover_date",
  /** column name */
  HistoricalBaseRent = "historical_base_rent",
  /** column name */
  HistoricalOutgoings = "historical_outgoings",
  /** column name */
  HistoricalPromoLevy = "historical_promo_levy",
  /** column name */
  HistoricalTenantName = "historical_tenant_name",
  /** column name */
  HoardingAmt = "hoarding_amt",
  /** column name */
  HoardingId = "hoarding_id",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedBy = "last_modified_by",
  /** column name */
  LeaseStart = "lease_start",
  /** column name */
  LeaseTerm = "lease_term",
  /** column name */
  LeaseType = "lease_type",
  /** column name */
  MatSales = "mat_sales",
  /** column name */
  Metadata = "metadata",
  /** column name */
  NoticeAddressId = "notice_address_id",
  /** column name */
  NoticeEmail = "notice_email",
  /** column name */
  NumberOfCarparks = "number_of_carparks",
  /** column name */
  NumberOfSigns = "number_of_signs",
  /** column name */
  OpeningPromotionLevyFees = "opening_promotion_levy_fees",
  /** column name */
  OutgoingsPsm = "outgoings_psm",
  /** column name */
  OutgoingsState = "outgoings_state",
  /** column name */
  ParkingLicenceRequired = "parking_licence_required",
  /** column name */
  ParkingLocation = "parking_location",
  /** column name */
  ParkingRent = "parking_rent",
  /** column name */
  PromotionAmt = "promotion_amt",
  /** column name */
  PromotionLevyState = "promotion_levy_state",
  /** column name */
  PromotionLevyType = "promotion_levy_type",
  /** column name */
  RecoverySurveyFee = "recovery_survey_fee",
  /** column name */
  RentDeposit = "rent_deposit",
  /** column name */
  RentDepositMonths = "rent_deposit_months",
  /** column name */
  RentFreePeriod = "rent_free_period",
  /** column name */
  RentStarts = "rent_starts",
  /** column name */
  RubbishRemovalFees = "rubbish_removal_fees",
  /** column name */
  SalesCategory = "sales_category",
  /** column name */
  SalesType = "sales_type",
  /** column name */
  SeatingArea = "seating_area",
  /** column name */
  SeatingLicenceRequired = "seating_licence_required",
  /** column name */
  SeatingRent = "seating_rent",
  /** column name */
  SecurityAmt = "security_amt",
  /** column name */
  SecurityId = "security_id",
  /** column name */
  SignageLicenceRequired = "signage_licence_required",
  /** column name */
  SignageLocation = "signage_location",
  /** column name */
  SignageRent = "signage_rent",
  /** column name */
  StorageArea = "storage_area",
  /** column name */
  StorageLicenceRequired = "storage_licence_required",
  /** column name */
  StorageRent = "storage_rent",
  /** column name */
  TenancyCoordination = "tenancy_coordination",
  /** column name */
  TenancyCoordinationFee = "tenancy_coordination_fee",
  /** column name */
  TenantContributionLandlordWorks = "tenant_contribution_landlord_works",
  /** column name */
  TurnOverRentUnnatural = "turn_over_rent_unnatural",
  /** column name */
  TurnoverBasedRentPercent = "turnover_based_rent_percent",
  /** column name */
  TurnoverRentState = "turnover_rent_state",
  /** column name */
  Version = "version",
}

/** select "DealData_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealData" */
export enum DealData_Select_Column_DealData_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FixedHandoverDate = "fixed_handover_date",
  /** column name */
  ParkingLicenceRequired = "parking_licence_required",
  /** column name */
  RentStarts = "rent_starts",
  /** column name */
  SeatingLicenceRequired = "seating_licence_required",
  /** column name */
  SignageLicenceRequired = "signage_licence_required",
  /** column name */
  StorageLicenceRequired = "storage_licence_required",
}

/** select "DealData_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealData" */
export enum DealData_Select_Column_DealData_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FixedHandoverDate = "fixed_handover_date",
  /** column name */
  ParkingLicenceRequired = "parking_licence_required",
  /** column name */
  RentStarts = "rent_starts",
  /** column name */
  SeatingLicenceRequired = "seating_licence_required",
  /** column name */
  SignageLicenceRequired = "signage_licence_required",
  /** column name */
  StorageLicenceRequired = "storage_licence_required",
}

/** input type for updating data in table "DealData" */
export type DealData_Set_Input = {
  achieved_bank_guarantee?: InputMaybe<Scalars["numeric"]>;
  achieved_rent_deposit?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_comp?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_reason?: InputMaybe<Scalars["String"]>;
  analyst_normalised_base_rent?: InputMaybe<Scalars["numeric"]>;
  analyst_normalised_promo?: InputMaybe<Scalars["numeric"]>;
  annual_increase?: InputMaybe<Scalars["numeric"]>;
  annual_increase_type?: InputMaybe<Scalars["String"]>;
  bank_guarantee_amount?: InputMaybe<Scalars["numeric"]>;
  bank_guarantee_id?: InputMaybe<Scalars["Int"]>;
  bank_guarantee_months?: InputMaybe<Scalars["Int"]>;
  base_net_rent?: InputMaybe<Scalars["numeric"]>;
  capped_occupancy_costs_state?: InputMaybe<Scalars["String"]>;
  cash_incentive?: InputMaybe<Scalars["numeric"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  development_fees?: InputMaybe<Scalars["numeric"]>;
  fitout_contribution?: InputMaybe<Scalars["numeric"]>;
  fitout_term?: InputMaybe<Scalars["Int"]>;
  fixed_handover_date?: InputMaybe<Scalars["Boolean"]>;
  gross_rent?: InputMaybe<Scalars["numeric"]>;
  guarantors_id?: InputMaybe<Scalars["Int"]>;
  handover_date?: InputMaybe<Scalars["date"]>;
  historical_base_rent?: InputMaybe<Scalars["numeric"]>;
  historical_outgoings?: InputMaybe<Scalars["numeric"]>;
  historical_promo_levy?: InputMaybe<Scalars["numeric"]>;
  historical_tenant_name?: InputMaybe<Scalars["String"]>;
  hoarding_amt?: InputMaybe<Scalars["numeric"]>;
  hoarding_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_modified_by?: InputMaybe<Scalars["String"]>;
  lease_start?: InputMaybe<Scalars["date"]>;
  lease_term?: InputMaybe<Scalars["Int"]>;
  lease_type?: InputMaybe<Scalars["String"]>;
  mat_sales?: InputMaybe<Scalars["numeric"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  notice_address_id?: InputMaybe<Scalars["bigint"]>;
  notice_email?: InputMaybe<Scalars["String"]>;
  number_of_carparks?: InputMaybe<Scalars["Int"]>;
  number_of_signs?: InputMaybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: InputMaybe<Scalars["numeric"]>;
  outgoings_psm?: InputMaybe<Scalars["numeric"]>;
  outgoings_state?: InputMaybe<Scalars["String"]>;
  parking_licence_required?: InputMaybe<Scalars["Boolean"]>;
  parking_location?: InputMaybe<Scalars["String"]>;
  parking_rent?: InputMaybe<Scalars["numeric"]>;
  promotion_amt?: InputMaybe<Scalars["numeric"]>;
  promotion_levy_state?: InputMaybe<Scalars["String"]>;
  promotion_levy_type?: InputMaybe<Scalars["String"]>;
  recovery_survey_fee?: InputMaybe<Scalars["numeric"]>;
  rent_deposit?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_months?: InputMaybe<Scalars["Int"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  rent_starts?: InputMaybe<Scalars["Boolean"]>;
  rubbish_removal_fees?: InputMaybe<Scalars["numeric"]>;
  sales_category?: InputMaybe<Scalars["String"]>;
  sales_type?: InputMaybe<SalesType_Enum>;
  seating_area?: InputMaybe<Scalars["numeric"]>;
  seating_licence_required?: InputMaybe<Scalars["Boolean"]>;
  seating_rent?: InputMaybe<Scalars["numeric"]>;
  security_amt?: InputMaybe<Scalars["numeric"]>;
  security_id?: InputMaybe<Scalars["Int"]>;
  signage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  signage_location?: InputMaybe<Scalars["String"]>;
  signage_rent?: InputMaybe<Scalars["numeric"]>;
  storage_area?: InputMaybe<Scalars["numeric"]>;
  storage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  storage_rent?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: InputMaybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: InputMaybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: InputMaybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: InputMaybe<Scalars["numeric"]>;
  turnover_rent_state?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type DealData_Stddev_Fields = {
  __typename?: "DealData_stddev_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealData" */
export type DealData_Stddev_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealData_Stddev_Pop_Fields = {
  __typename?: "DealData_stddev_pop_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealData" */
export type DealData_Stddev_Pop_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealData_Stddev_Samp_Fields = {
  __typename?: "DealData_stddev_samp_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealData" */
export type DealData_Stddev_Samp_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealData" */
export type DealData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealData_Stream_Cursor_Value_Input = {
  achieved_bank_guarantee?: InputMaybe<Scalars["numeric"]>;
  achieved_rent_deposit?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_comp?: InputMaybe<Scalars["numeric"]>;
  analyst_deal_reason?: InputMaybe<Scalars["String"]>;
  analyst_normalised_base_rent?: InputMaybe<Scalars["numeric"]>;
  analyst_normalised_promo?: InputMaybe<Scalars["numeric"]>;
  annual_increase?: InputMaybe<Scalars["numeric"]>;
  annual_increase_type?: InputMaybe<Scalars["String"]>;
  bank_guarantee_amount?: InputMaybe<Scalars["numeric"]>;
  bank_guarantee_id?: InputMaybe<Scalars["Int"]>;
  bank_guarantee_months?: InputMaybe<Scalars["Int"]>;
  base_net_rent?: InputMaybe<Scalars["numeric"]>;
  capped_occupancy_costs_state?: InputMaybe<Scalars["String"]>;
  cash_incentive?: InputMaybe<Scalars["numeric"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  development_fees?: InputMaybe<Scalars["numeric"]>;
  fitout_contribution?: InputMaybe<Scalars["numeric"]>;
  fitout_term?: InputMaybe<Scalars["Int"]>;
  fixed_handover_date?: InputMaybe<Scalars["Boolean"]>;
  gross_rent?: InputMaybe<Scalars["numeric"]>;
  guarantors_id?: InputMaybe<Scalars["Int"]>;
  handover_date?: InputMaybe<Scalars["date"]>;
  historical_base_rent?: InputMaybe<Scalars["numeric"]>;
  historical_outgoings?: InputMaybe<Scalars["numeric"]>;
  historical_promo_levy?: InputMaybe<Scalars["numeric"]>;
  historical_tenant_name?: InputMaybe<Scalars["String"]>;
  hoarding_amt?: InputMaybe<Scalars["numeric"]>;
  hoarding_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  last_modified_by?: InputMaybe<Scalars["String"]>;
  lease_start?: InputMaybe<Scalars["date"]>;
  lease_term?: InputMaybe<Scalars["Int"]>;
  lease_type?: InputMaybe<Scalars["String"]>;
  mat_sales?: InputMaybe<Scalars["numeric"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  notice_address_id?: InputMaybe<Scalars["bigint"]>;
  notice_email?: InputMaybe<Scalars["String"]>;
  number_of_carparks?: InputMaybe<Scalars["Int"]>;
  number_of_signs?: InputMaybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: InputMaybe<Scalars["numeric"]>;
  outgoings_psm?: InputMaybe<Scalars["numeric"]>;
  outgoings_state?: InputMaybe<Scalars["String"]>;
  parking_licence_required?: InputMaybe<Scalars["Boolean"]>;
  parking_location?: InputMaybe<Scalars["String"]>;
  parking_rent?: InputMaybe<Scalars["numeric"]>;
  promotion_amt?: InputMaybe<Scalars["numeric"]>;
  promotion_levy_state?: InputMaybe<Scalars["String"]>;
  promotion_levy_type?: InputMaybe<Scalars["String"]>;
  recovery_survey_fee?: InputMaybe<Scalars["numeric"]>;
  rent_deposit?: InputMaybe<Scalars["numeric"]>;
  rent_deposit_months?: InputMaybe<Scalars["Int"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  rent_starts?: InputMaybe<Scalars["Boolean"]>;
  rubbish_removal_fees?: InputMaybe<Scalars["numeric"]>;
  sales_category?: InputMaybe<Scalars["String"]>;
  sales_type?: InputMaybe<SalesType_Enum>;
  seating_area?: InputMaybe<Scalars["numeric"]>;
  seating_licence_required?: InputMaybe<Scalars["Boolean"]>;
  seating_rent?: InputMaybe<Scalars["numeric"]>;
  security_amt?: InputMaybe<Scalars["numeric"]>;
  security_id?: InputMaybe<Scalars["Int"]>;
  signage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  signage_location?: InputMaybe<Scalars["String"]>;
  signage_rent?: InputMaybe<Scalars["numeric"]>;
  storage_area?: InputMaybe<Scalars["numeric"]>;
  storage_licence_required?: InputMaybe<Scalars["Boolean"]>;
  storage_rent?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination?: InputMaybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: InputMaybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: InputMaybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: InputMaybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: InputMaybe<Scalars["numeric"]>;
  turnover_rent_state?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type DealData_Sum_Fields = {
  __typename?: "DealData_sum_fields";
  achieved_bank_guarantee?: Maybe<Scalars["numeric"]>;
  achieved_rent_deposit?: Maybe<Scalars["numeric"]>;
  analyst_deal_comp?: Maybe<Scalars["numeric"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["numeric"]>;
  analyst_normalised_promo?: Maybe<Scalars["numeric"]>;
  annual_increase?: Maybe<Scalars["numeric"]>;
  bank_guarantee_amount?: Maybe<Scalars["numeric"]>;
  bank_guarantee_id?: Maybe<Scalars["Int"]>;
  bank_guarantee_months?: Maybe<Scalars["Int"]>;
  base_net_rent?: Maybe<Scalars["numeric"]>;
  cash_incentive?: Maybe<Scalars["numeric"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  development_fees?: Maybe<Scalars["numeric"]>;
  fitout_contribution?: Maybe<Scalars["numeric"]>;
  fitout_term?: Maybe<Scalars["Int"]>;
  gross_rent?: Maybe<Scalars["numeric"]>;
  guarantors_id?: Maybe<Scalars["Int"]>;
  historical_base_rent?: Maybe<Scalars["numeric"]>;
  historical_outgoings?: Maybe<Scalars["numeric"]>;
  historical_promo_levy?: Maybe<Scalars["numeric"]>;
  hoarding_amt?: Maybe<Scalars["numeric"]>;
  hoarding_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  lease_term?: Maybe<Scalars["Int"]>;
  mat_sales?: Maybe<Scalars["numeric"]>;
  notice_address_id?: Maybe<Scalars["bigint"]>;
  number_of_carparks?: Maybe<Scalars["Int"]>;
  number_of_signs?: Maybe<Scalars["Int"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["numeric"]>;
  outgoings_psm?: Maybe<Scalars["numeric"]>;
  parking_rent?: Maybe<Scalars["numeric"]>;
  promotion_amt?: Maybe<Scalars["numeric"]>;
  recovery_survey_fee?: Maybe<Scalars["numeric"]>;
  rent_deposit?: Maybe<Scalars["numeric"]>;
  rent_deposit_months?: Maybe<Scalars["Int"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  rubbish_removal_fees?: Maybe<Scalars["numeric"]>;
  seating_area?: Maybe<Scalars["numeric"]>;
  seating_rent?: Maybe<Scalars["numeric"]>;
  security_amt?: Maybe<Scalars["numeric"]>;
  security_id?: Maybe<Scalars["Int"]>;
  signage_rent?: Maybe<Scalars["numeric"]>;
  storage_area?: Maybe<Scalars["numeric"]>;
  storage_rent?: Maybe<Scalars["numeric"]>;
  tenancy_coordination?: Maybe<Scalars["numeric"]>;
  tenancy_coordination_fee?: Maybe<Scalars["numeric"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["numeric"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["numeric"]>;
  turnover_based_rent_percent?: Maybe<Scalars["numeric"]>;
  version?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealData" */
export type DealData_Sum_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** update columns of table "DealData" */
export enum DealData_Update_Column {
  /** column name */
  AchievedBankGuarantee = "achieved_bank_guarantee",
  /** column name */
  AchievedRentDeposit = "achieved_rent_deposit",
  /** column name */
  AnalystDealComp = "analyst_deal_comp",
  /** column name */
  AnalystDealReason = "analyst_deal_reason",
  /** column name */
  AnalystNormalisedBaseRent = "analyst_normalised_base_rent",
  /** column name */
  AnalystNormalisedPromo = "analyst_normalised_promo",
  /** column name */
  AnnualIncrease = "annual_increase",
  /** column name */
  AnnualIncreaseType = "annual_increase_type",
  /** column name */
  BankGuaranteeAmount = "bank_guarantee_amount",
  /** column name */
  BankGuaranteeId = "bank_guarantee_id",
  /** column name */
  BankGuaranteeMonths = "bank_guarantee_months",
  /** column name */
  BaseNetRent = "base_net_rent",
  /** column name */
  CappedOccupancyCostsState = "capped_occupancy_costs_state",
  /** column name */
  CashIncentive = "cash_incentive",
  /** column name */
  ChargeCode = "charge_code",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DevelopmentFees = "development_fees",
  /** column name */
  FitoutContribution = "fitout_contribution",
  /** column name */
  FitoutTerm = "fitout_term",
  /** column name */
  FixedHandoverDate = "fixed_handover_date",
  /** column name */
  GrossRent = "gross_rent",
  /** column name */
  GuarantorsId = "guarantors_id",
  /** column name */
  HandoverDate = "handover_date",
  /** column name */
  HistoricalBaseRent = "historical_base_rent",
  /** column name */
  HistoricalOutgoings = "historical_outgoings",
  /** column name */
  HistoricalPromoLevy = "historical_promo_levy",
  /** column name */
  HistoricalTenantName = "historical_tenant_name",
  /** column name */
  HoardingAmt = "hoarding_amt",
  /** column name */
  HoardingId = "hoarding_id",
  /** column name */
  Id = "id",
  /** column name */
  LastModifiedBy = "last_modified_by",
  /** column name */
  LeaseStart = "lease_start",
  /** column name */
  LeaseTerm = "lease_term",
  /** column name */
  LeaseType = "lease_type",
  /** column name */
  MatSales = "mat_sales",
  /** column name */
  Metadata = "metadata",
  /** column name */
  NoticeAddressId = "notice_address_id",
  /** column name */
  NoticeEmail = "notice_email",
  /** column name */
  NumberOfCarparks = "number_of_carparks",
  /** column name */
  NumberOfSigns = "number_of_signs",
  /** column name */
  OpeningPromotionLevyFees = "opening_promotion_levy_fees",
  /** column name */
  OutgoingsPsm = "outgoings_psm",
  /** column name */
  OutgoingsState = "outgoings_state",
  /** column name */
  ParkingLicenceRequired = "parking_licence_required",
  /** column name */
  ParkingLocation = "parking_location",
  /** column name */
  ParkingRent = "parking_rent",
  /** column name */
  PromotionAmt = "promotion_amt",
  /** column name */
  PromotionLevyState = "promotion_levy_state",
  /** column name */
  PromotionLevyType = "promotion_levy_type",
  /** column name */
  RecoverySurveyFee = "recovery_survey_fee",
  /** column name */
  RentDeposit = "rent_deposit",
  /** column name */
  RentDepositMonths = "rent_deposit_months",
  /** column name */
  RentFreePeriod = "rent_free_period",
  /** column name */
  RentStarts = "rent_starts",
  /** column name */
  RubbishRemovalFees = "rubbish_removal_fees",
  /** column name */
  SalesCategory = "sales_category",
  /** column name */
  SalesType = "sales_type",
  /** column name */
  SeatingArea = "seating_area",
  /** column name */
  SeatingLicenceRequired = "seating_licence_required",
  /** column name */
  SeatingRent = "seating_rent",
  /** column name */
  SecurityAmt = "security_amt",
  /** column name */
  SecurityId = "security_id",
  /** column name */
  SignageLicenceRequired = "signage_licence_required",
  /** column name */
  SignageLocation = "signage_location",
  /** column name */
  SignageRent = "signage_rent",
  /** column name */
  StorageArea = "storage_area",
  /** column name */
  StorageLicenceRequired = "storage_licence_required",
  /** column name */
  StorageRent = "storage_rent",
  /** column name */
  TenancyCoordination = "tenancy_coordination",
  /** column name */
  TenancyCoordinationFee = "tenancy_coordination_fee",
  /** column name */
  TenantContributionLandlordWorks = "tenant_contribution_landlord_works",
  /** column name */
  TurnOverRentUnnatural = "turn_over_rent_unnatural",
  /** column name */
  TurnoverBasedRentPercent = "turnover_based_rent_percent",
  /** column name */
  TurnoverRentState = "turnover_rent_state",
  /** column name */
  Version = "version",
}

export type DealData_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealData_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealData_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealData_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealData_Var_Pop_Fields = {
  __typename?: "DealData_var_pop_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealData" */
export type DealData_Var_Pop_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealData_Var_Samp_Fields = {
  __typename?: "DealData_var_samp_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealData" */
export type DealData_Var_Samp_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealData_Variance_Fields = {
  __typename?: "DealData_variance_fields";
  achieved_bank_guarantee?: Maybe<Scalars["Float"]>;
  achieved_rent_deposit?: Maybe<Scalars["Float"]>;
  analyst_deal_comp?: Maybe<Scalars["Float"]>;
  analyst_normalised_base_rent?: Maybe<Scalars["Float"]>;
  analyst_normalised_promo?: Maybe<Scalars["Float"]>;
  annual_increase?: Maybe<Scalars["Float"]>;
  bank_guarantee_amount?: Maybe<Scalars["Float"]>;
  bank_guarantee_id?: Maybe<Scalars["Float"]>;
  bank_guarantee_months?: Maybe<Scalars["Float"]>;
  base_net_rent?: Maybe<Scalars["Float"]>;
  cash_incentive?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  development_fees?: Maybe<Scalars["Float"]>;
  fitout_contribution?: Maybe<Scalars["Float"]>;
  fitout_term?: Maybe<Scalars["Float"]>;
  gross_rent?: Maybe<Scalars["Float"]>;
  guarantors_id?: Maybe<Scalars["Float"]>;
  historical_base_rent?: Maybe<Scalars["Float"]>;
  historical_outgoings?: Maybe<Scalars["Float"]>;
  historical_promo_levy?: Maybe<Scalars["Float"]>;
  hoarding_amt?: Maybe<Scalars["Float"]>;
  hoarding_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lease_term?: Maybe<Scalars["Float"]>;
  mat_sales?: Maybe<Scalars["Float"]>;
  notice_address_id?: Maybe<Scalars["Float"]>;
  number_of_carparks?: Maybe<Scalars["Float"]>;
  number_of_signs?: Maybe<Scalars["Float"]>;
  opening_promotion_levy_fees?: Maybe<Scalars["Float"]>;
  outgoings_psm?: Maybe<Scalars["Float"]>;
  parking_rent?: Maybe<Scalars["Float"]>;
  promotion_amt?: Maybe<Scalars["Float"]>;
  recovery_survey_fee?: Maybe<Scalars["Float"]>;
  rent_deposit?: Maybe<Scalars["Float"]>;
  rent_deposit_months?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
  rubbish_removal_fees?: Maybe<Scalars["Float"]>;
  seating_area?: Maybe<Scalars["Float"]>;
  seating_rent?: Maybe<Scalars["Float"]>;
  security_amt?: Maybe<Scalars["Float"]>;
  security_id?: Maybe<Scalars["Float"]>;
  signage_rent?: Maybe<Scalars["Float"]>;
  storage_area?: Maybe<Scalars["Float"]>;
  storage_rent?: Maybe<Scalars["Float"]>;
  tenancy_coordination?: Maybe<Scalars["Float"]>;
  tenancy_coordination_fee?: Maybe<Scalars["Float"]>;
  tenant_contribution_landlord_works?: Maybe<Scalars["Float"]>;
  turn_over_rent_unnatural?: Maybe<Scalars["Float"]>;
  turnover_based_rent_percent?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealData" */
export type DealData_Variance_Order_By = {
  achieved_bank_guarantee?: InputMaybe<Order_By>;
  achieved_rent_deposit?: InputMaybe<Order_By>;
  analyst_deal_comp?: InputMaybe<Order_By>;
  analyst_normalised_base_rent?: InputMaybe<Order_By>;
  analyst_normalised_promo?: InputMaybe<Order_By>;
  annual_increase?: InputMaybe<Order_By>;
  bank_guarantee_amount?: InputMaybe<Order_By>;
  bank_guarantee_id?: InputMaybe<Order_By>;
  bank_guarantee_months?: InputMaybe<Order_By>;
  base_net_rent?: InputMaybe<Order_By>;
  cash_incentive?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  development_fees?: InputMaybe<Order_By>;
  fitout_contribution?: InputMaybe<Order_By>;
  fitout_term?: InputMaybe<Order_By>;
  gross_rent?: InputMaybe<Order_By>;
  guarantors_id?: InputMaybe<Order_By>;
  historical_base_rent?: InputMaybe<Order_By>;
  historical_outgoings?: InputMaybe<Order_By>;
  historical_promo_levy?: InputMaybe<Order_By>;
  hoarding_amt?: InputMaybe<Order_By>;
  hoarding_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lease_term?: InputMaybe<Order_By>;
  mat_sales?: InputMaybe<Order_By>;
  notice_address_id?: InputMaybe<Order_By>;
  number_of_carparks?: InputMaybe<Order_By>;
  number_of_signs?: InputMaybe<Order_By>;
  opening_promotion_levy_fees?: InputMaybe<Order_By>;
  outgoings_psm?: InputMaybe<Order_By>;
  parking_rent?: InputMaybe<Order_By>;
  promotion_amt?: InputMaybe<Order_By>;
  recovery_survey_fee?: InputMaybe<Order_By>;
  rent_deposit?: InputMaybe<Order_By>;
  rent_deposit_months?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  rubbish_removal_fees?: InputMaybe<Order_By>;
  seating_area?: InputMaybe<Order_By>;
  seating_rent?: InputMaybe<Order_By>;
  security_amt?: InputMaybe<Order_By>;
  security_id?: InputMaybe<Order_By>;
  signage_rent?: InputMaybe<Order_By>;
  storage_area?: InputMaybe<Order_By>;
  storage_rent?: InputMaybe<Order_By>;
  tenancy_coordination?: InputMaybe<Order_By>;
  tenancy_coordination_fee?: InputMaybe<Order_By>;
  tenant_contribution_landlord_works?: InputMaybe<Order_By>;
  turn_over_rent_unnatural?: InputMaybe<Order_By>;
  turnover_based_rent_percent?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealExtensionItems" */
export type DealExtensionItems = {
  __typename?: "DealExtensionItems";
  /** An object relationship */
  DealDatum?: Maybe<DealData>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  item_data?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealExtensionItems" */
export type DealExtensionItems_Aggregate = {
  __typename?: "DealExtensionItems_aggregate";
  aggregate?: Maybe<DealExtensionItems_Aggregate_Fields>;
  nodes: Array<DealExtensionItems>;
};

export type DealExtensionItems_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealExtensionItems_Aggregate_Bool_Exp_Count>;
};

export type DealExtensionItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealExtensionItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealExtensionItems" */
export type DealExtensionItems_Aggregate_Fields = {
  __typename?: "DealExtensionItems_aggregate_fields";
  avg?: Maybe<DealExtensionItems_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealExtensionItems_Max_Fields>;
  min?: Maybe<DealExtensionItems_Min_Fields>;
  stddev?: Maybe<DealExtensionItems_Stddev_Fields>;
  stddev_pop?: Maybe<DealExtensionItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealExtensionItems_Stddev_Samp_Fields>;
  sum?: Maybe<DealExtensionItems_Sum_Fields>;
  var_pop?: Maybe<DealExtensionItems_Var_Pop_Fields>;
  var_samp?: Maybe<DealExtensionItems_Var_Samp_Fields>;
  variance?: Maybe<DealExtensionItems_Variance_Fields>;
};

/** aggregate fields of "DealExtensionItems" */
export type DealExtensionItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealExtensionItems" */
export type DealExtensionItems_Aggregate_Order_By = {
  avg?: InputMaybe<DealExtensionItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealExtensionItems_Max_Order_By>;
  min?: InputMaybe<DealExtensionItems_Min_Order_By>;
  stddev?: InputMaybe<DealExtensionItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealExtensionItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealExtensionItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealExtensionItems_Sum_Order_By>;
  var_pop?: InputMaybe<DealExtensionItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealExtensionItems_Var_Samp_Order_By>;
  variance?: InputMaybe<DealExtensionItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealExtensionItems" */
export type DealExtensionItems_Arr_Rel_Insert_Input = {
  data: Array<DealExtensionItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealExtensionItems_On_Conflict>;
};

/** aggregate avg on columns */
export type DealExtensionItems_Avg_Fields = {
  __typename?: "DealExtensionItems_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealExtensionItems". All fields are combined with a logical 'AND'. */
export type DealExtensionItems_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  _and?: InputMaybe<Array<DealExtensionItems_Bool_Exp>>;
  _not?: InputMaybe<DealExtensionItems_Bool_Exp>;
  _or?: InputMaybe<Array<DealExtensionItems_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  item_data?: InputMaybe<String_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealExtensionItems" */
export enum DealExtensionItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealExtensionItemsPkey = "DealExtensionItems_pkey",
}

/** input type for incrementing numeric columns in table "DealExtensionItems" */
export type DealExtensionItems_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealExtensionItems" */
export type DealExtensionItems_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  item_data?: InputMaybe<Scalars["String"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealExtensionItems_Max_Fields = {
  __typename?: "DealExtensionItems_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  item_data?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_data?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealExtensionItems_Min_Fields = {
  __typename?: "DealExtensionItems_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  item_data?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_data?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealExtensionItems" */
export type DealExtensionItems_Mutation_Response = {
  __typename?: "DealExtensionItems_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealExtensionItems>;
};

/** on_conflict condition type for table "DealExtensionItems" */
export type DealExtensionItems_On_Conflict = {
  constraint: DealExtensionItems_Constraint;
  update_columns?: Array<DealExtensionItems_Update_Column>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

/** Ordering options when selecting data from "DealExtensionItems". */
export type DealExtensionItems_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_data?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealExtensionItems */
export type DealExtensionItems_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealExtensionItems" */
export enum DealExtensionItems_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Id = "id",
  /** column name */
  ItemData = "item_data",
  /** column name */
  Tag = "tag",
}

/** input type for updating data in table "DealExtensionItems" */
export type DealExtensionItems_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  item_data?: InputMaybe<Scalars["String"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealExtensionItems_Stddev_Fields = {
  __typename?: "DealExtensionItems_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealExtensionItems_Stddev_Pop_Fields = {
  __typename?: "DealExtensionItems_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealExtensionItems_Stddev_Samp_Fields = {
  __typename?: "DealExtensionItems_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealExtensionItems" */
export type DealExtensionItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealExtensionItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealExtensionItems_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  item_data?: InputMaybe<Scalars["String"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealExtensionItems_Sum_Fields = {
  __typename?: "DealExtensionItems_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealExtensionItems" */
export enum DealExtensionItems_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Id = "id",
  /** column name */
  ItemData = "item_data",
  /** column name */
  Tag = "tag",
}

export type DealExtensionItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealExtensionItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealExtensionItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealExtensionItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealExtensionItems_Var_Pop_Fields = {
  __typename?: "DealExtensionItems_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealExtensionItems_Var_Samp_Fields = {
  __typename?: "DealExtensionItems_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealExtensionItems_Variance_Fields = {
  __typename?: "DealExtensionItems_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealExtensionItems" */
export type DealExtensionItems_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealFileChunks" */
export type DealFileChunks = {
  __typename?: "DealFileChunks";
  chunk_id: Scalars["String"];
  deal_file_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
};

/** aggregated selection of "DealFileChunks" */
export type DealFileChunks_Aggregate = {
  __typename?: "DealFileChunks_aggregate";
  aggregate?: Maybe<DealFileChunks_Aggregate_Fields>;
  nodes: Array<DealFileChunks>;
};

/** aggregate fields of "DealFileChunks" */
export type DealFileChunks_Aggregate_Fields = {
  __typename?: "DealFileChunks_aggregate_fields";
  avg?: Maybe<DealFileChunks_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealFileChunks_Max_Fields>;
  min?: Maybe<DealFileChunks_Min_Fields>;
  stddev?: Maybe<DealFileChunks_Stddev_Fields>;
  stddev_pop?: Maybe<DealFileChunks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealFileChunks_Stddev_Samp_Fields>;
  sum?: Maybe<DealFileChunks_Sum_Fields>;
  var_pop?: Maybe<DealFileChunks_Var_Pop_Fields>;
  var_samp?: Maybe<DealFileChunks_Var_Samp_Fields>;
  variance?: Maybe<DealFileChunks_Variance_Fields>;
};

/** aggregate fields of "DealFileChunks" */
export type DealFileChunks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealFileChunks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type DealFileChunks_Avg_Fields = {
  __typename?: "DealFileChunks_avg_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "DealFileChunks". All fields are combined with a logical 'AND'. */
export type DealFileChunks_Bool_Exp = {
  _and?: InputMaybe<Array<DealFileChunks_Bool_Exp>>;
  _not?: InputMaybe<DealFileChunks_Bool_Exp>;
  _or?: InputMaybe<Array<DealFileChunks_Bool_Exp>>;
  chunk_id?: InputMaybe<String_Comparison_Exp>;
  deal_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealFileChunks" */
export enum DealFileChunks_Constraint {
  /** unique or primary key constraint on columns "chunk_id" */
  DealFileChunksChunkIdKey = "DealFileChunks_chunk_id_key",
  /** unique or primary key constraint on columns "id" */
  DealFileChunksPkey = "DealFileChunks_pkey",
}

/** input type for incrementing numeric columns in table "DealFileChunks" */
export type DealFileChunks_Inc_Input = {
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealFileChunks" */
export type DealFileChunks_Insert_Input = {
  chunk_id?: InputMaybe<Scalars["String"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type DealFileChunks_Max_Fields = {
  __typename?: "DealFileChunks_max_fields";
  chunk_id?: Maybe<Scalars["String"]>;
  deal_file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type DealFileChunks_Min_Fields = {
  __typename?: "DealFileChunks_min_fields";
  chunk_id?: Maybe<Scalars["String"]>;
  deal_file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** response of any mutation on the table "DealFileChunks" */
export type DealFileChunks_Mutation_Response = {
  __typename?: "DealFileChunks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealFileChunks>;
};

/** on_conflict condition type for table "DealFileChunks" */
export type DealFileChunks_On_Conflict = {
  constraint: DealFileChunks_Constraint;
  update_columns?: Array<DealFileChunks_Update_Column>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

/** Ordering options when selecting data from "DealFileChunks". */
export type DealFileChunks_Order_By = {
  chunk_id?: InputMaybe<Order_By>;
  deal_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealFileChunks */
export type DealFileChunks_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealFileChunks" */
export enum DealFileChunks_Select_Column {
  /** column name */
  ChunkId = "chunk_id",
  /** column name */
  DealFileId = "deal_file_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "DealFileChunks" */
export type DealFileChunks_Set_Input = {
  chunk_id?: InputMaybe<Scalars["String"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type DealFileChunks_Stddev_Fields = {
  __typename?: "DealFileChunks_stddev_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DealFileChunks_Stddev_Pop_Fields = {
  __typename?: "DealFileChunks_stddev_pop_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DealFileChunks_Stddev_Samp_Fields = {
  __typename?: "DealFileChunks_stddev_samp_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "DealFileChunks" */
export type DealFileChunks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealFileChunks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealFileChunks_Stream_Cursor_Value_Input = {
  chunk_id?: InputMaybe<Scalars["String"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type DealFileChunks_Sum_Fields = {
  __typename?: "DealFileChunks_sum_fields";
  deal_file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "DealFileChunks" */
export enum DealFileChunks_Update_Column {
  /** column name */
  ChunkId = "chunk_id",
  /** column name */
  DealFileId = "deal_file_id",
  /** column name */
  Id = "id",
}

export type DealFileChunks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealFileChunks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealFileChunks_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealFileChunks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealFileChunks_Var_Pop_Fields = {
  __typename?: "DealFileChunks_var_pop_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DealFileChunks_Var_Samp_Fields = {
  __typename?: "DealFileChunks_var_samp_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DealFileChunks_Variance_Fields = {
  __typename?: "DealFileChunks_variance_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "DealFileTags" */
export type DealFileTags = {
  __typename?: "DealFileTags";
  /** An object relationship */
  DealFile: DealFiles;
  /** An object relationship */
  Tags: Tags;
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id: Scalars["bigint"];
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id: Scalars["bigint"];
};

/** aggregated selection of "DealFileTags" */
export type DealFileTags_Aggregate = {
  __typename?: "DealFileTags_aggregate";
  aggregate?: Maybe<DealFileTags_Aggregate_Fields>;
  nodes: Array<DealFileTags>;
};

export type DealFileTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealFileTags_Aggregate_Bool_Exp_Count>;
};

export type DealFileTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealFileTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFileTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealFileTags" */
export type DealFileTags_Aggregate_Fields = {
  __typename?: "DealFileTags_aggregate_fields";
  avg?: Maybe<DealFileTags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealFileTags_Max_Fields>;
  min?: Maybe<DealFileTags_Min_Fields>;
  stddev?: Maybe<DealFileTags_Stddev_Fields>;
  stddev_pop?: Maybe<DealFileTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealFileTags_Stddev_Samp_Fields>;
  sum?: Maybe<DealFileTags_Sum_Fields>;
  var_pop?: Maybe<DealFileTags_Var_Pop_Fields>;
  var_samp?: Maybe<DealFileTags_Var_Samp_Fields>;
  variance?: Maybe<DealFileTags_Variance_Fields>;
};

/** aggregate fields of "DealFileTags" */
export type DealFileTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealFileTags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealFileTags" */
export type DealFileTags_Aggregate_Order_By = {
  avg?: InputMaybe<DealFileTags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealFileTags_Max_Order_By>;
  min?: InputMaybe<DealFileTags_Min_Order_By>;
  stddev?: InputMaybe<DealFileTags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealFileTags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealFileTags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealFileTags_Sum_Order_By>;
  var_pop?: InputMaybe<DealFileTags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealFileTags_Var_Samp_Order_By>;
  variance?: InputMaybe<DealFileTags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealFileTags" */
export type DealFileTags_Arr_Rel_Insert_Input = {
  data: Array<DealFileTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealFileTags_On_Conflict>;
};

/** aggregate avg on columns */
export type DealFileTags_Avg_Fields = {
  __typename?: "DealFileTags_avg_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealFileTags" */
export type DealFileTags_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealFileTags". All fields are combined with a logical 'AND'. */
export type DealFileTags_Bool_Exp = {
  DealFile?: InputMaybe<DealFiles_Bool_Exp>;
  Tags?: InputMaybe<Tags_Bool_Exp>;
  _and?: InputMaybe<Array<DealFileTags_Bool_Exp>>;
  _not?: InputMaybe<DealFileTags_Bool_Exp>;
  _or?: InputMaybe<Array<DealFileTags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealFileTags" */
export enum DealFileTags_Constraint {
  /** unique or primary key constraint on columns "tag_id", "file_id" */
  DealFileTagsFileIdTagIdKey = "DealFileTags_file_id_tag_id_key",
  /** unique or primary key constraint on columns "id" */
  DealFileTagsPkey = "DealFileTags_pkey",
}

/** input type for incrementing numeric columns in table "DealFileTags" */
export type DealFileTags_Inc_Input = {
  file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealFileTags" */
export type DealFileTags_Insert_Input = {
  DealFile?: InputMaybe<DealFiles_Obj_Rel_Insert_Input>;
  Tags?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type DealFileTags_Max_Fields = {
  __typename?: "DealFileTags_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "DealFileTags" */
export type DealFileTags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealFileTags_Min_Fields = {
  __typename?: "DealFileTags_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "DealFileTags" */
export type DealFileTags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealFileTags" */
export type DealFileTags_Mutation_Response = {
  __typename?: "DealFileTags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealFileTags>;
};

/** on_conflict condition type for table "DealFileTags" */
export type DealFileTags_On_Conflict = {
  constraint: DealFileTags_Constraint;
  update_columns?: Array<DealFileTags_Update_Column>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

/** Ordering options when selecting data from "DealFileTags". */
export type DealFileTags_Order_By = {
  DealFile?: InputMaybe<DealFiles_Order_By>;
  Tags?: InputMaybe<Tags_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealFileTags */
export type DealFileTags_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealFileTags" */
export enum DealFileTags_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TagId = "tag_id",
}

/** input type for updating data in table "DealFileTags" */
export type DealFileTags_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type DealFileTags_Stddev_Fields = {
  __typename?: "DealFileTags_stddev_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealFileTags" */
export type DealFileTags_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealFileTags_Stddev_Pop_Fields = {
  __typename?: "DealFileTags_stddev_pop_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealFileTags" */
export type DealFileTags_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealFileTags_Stddev_Samp_Fields = {
  __typename?: "DealFileTags_stddev_samp_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealFileTags" */
export type DealFileTags_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealFileTags" */
export type DealFileTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealFileTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealFileTags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type DealFileTags_Sum_Fields = {
  __typename?: "DealFileTags_sum_fields";
  file_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  tag_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealFileTags" */
export type DealFileTags_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealFileTags" */
export enum DealFileTags_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileId = "file_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TagId = "tag_id",
}

export type DealFileTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealFileTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealFileTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealFileTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealFileTags_Var_Pop_Fields = {
  __typename?: "DealFileTags_var_pop_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealFileTags" */
export type DealFileTags_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealFileTags_Var_Samp_Fields = {
  __typename?: "DealFileTags_var_samp_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealFileTags" */
export type DealFileTags_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealFileTags_Variance_Fields = {
  __typename?: "DealFileTags_variance_fields";
  file_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  tag_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealFileTags" */
export type DealFileTags_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealFiles" */
export type DealFiles = {
  __typename?: "DealFiles";
  /** An array relationship */
  DealFileTags: Array<DealFileTags>;
  /** An aggregate relationship */
  DealFileTags_aggregate: DealFileTags_Aggregate;
  /** An object relationship */
  Deals: Deals;
  /** An object relationship */
  Users: Users;
  cog_search_process: Scalars["Boolean"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id: Scalars["bigint"];
  file_name: Scalars["String"];
  file_path: Scalars["String"];
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  not_deleted?: Maybe<Scalars["Boolean"]>;
  user_id: Scalars["uuid"];
};

/** columns and relationships of "DealFiles" */
export type DealFilesDealFileTagsArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

/** columns and relationships of "DealFiles" */
export type DealFilesDealFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

/** aggregated selection of "DealFiles" */
export type DealFiles_Aggregate = {
  __typename?: "DealFiles_aggregate";
  aggregate?: Maybe<DealFiles_Aggregate_Fields>;
  nodes: Array<DealFiles>;
};

export type DealFiles_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealFiles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealFiles_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealFiles_Aggregate_Bool_Exp_Count>;
};

export type DealFiles_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealFiles_Select_Column_DealFiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealFiles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealFiles_Select_Column_DealFiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealFiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealFiles" */
export type DealFiles_Aggregate_Fields = {
  __typename?: "DealFiles_aggregate_fields";
  avg?: Maybe<DealFiles_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealFiles_Max_Fields>;
  min?: Maybe<DealFiles_Min_Fields>;
  stddev?: Maybe<DealFiles_Stddev_Fields>;
  stddev_pop?: Maybe<DealFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealFiles_Stddev_Samp_Fields>;
  sum?: Maybe<DealFiles_Sum_Fields>;
  var_pop?: Maybe<DealFiles_Var_Pop_Fields>;
  var_samp?: Maybe<DealFiles_Var_Samp_Fields>;
  variance?: Maybe<DealFiles_Variance_Fields>;
};

/** aggregate fields of "DealFiles" */
export type DealFiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealFiles" */
export type DealFiles_Aggregate_Order_By = {
  avg?: InputMaybe<DealFiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealFiles_Max_Order_By>;
  min?: InputMaybe<DealFiles_Min_Order_By>;
  stddev?: InputMaybe<DealFiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealFiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealFiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealFiles_Sum_Order_By>;
  var_pop?: InputMaybe<DealFiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealFiles_Var_Samp_Order_By>;
  variance?: InputMaybe<DealFiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealFiles" */
export type DealFiles_Arr_Rel_Insert_Input = {
  data: Array<DealFiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealFiles_On_Conflict>;
};

/** aggregate avg on columns */
export type DealFiles_Avg_Fields = {
  __typename?: "DealFiles_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealFiles" */
export type DealFiles_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealFiles". All fields are combined with a logical 'AND'. */
export type DealFiles_Bool_Exp = {
  DealFileTags?: InputMaybe<DealFileTags_Bool_Exp>;
  DealFileTags_aggregate?: InputMaybe<DealFileTags_Aggregate_Bool_Exp>;
  Deals?: InputMaybe<Deals_Bool_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DealFiles_Bool_Exp>>;
  _not?: InputMaybe<DealFiles_Bool_Exp>;
  _or?: InputMaybe<Array<DealFiles_Bool_Exp>>;
  cog_search_process?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  not_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealFiles" */
export enum DealFiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealFilesPkey = "DealFiles_pkey",
  /** unique or primary key constraint on columns "deal_id", "not_deleted", "file_name" */
  DealfilesDealIdFilenameDeleteUnique = "dealfiles_deal_id_filename_delete_unique",
}

/** input type for incrementing numeric columns in table "DealFiles" */
export type DealFiles_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealFiles" */
export type DealFiles_Insert_Input = {
  DealFileTags?: InputMaybe<DealFileTags_Arr_Rel_Insert_Input>;
  Deals?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  Users?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  cog_search_process?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type DealFiles_Max_Fields = {
  __typename?: "DealFiles_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "DealFiles" */
export type DealFiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealFiles_Min_Fields = {
  __typename?: "DealFiles_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "DealFiles" */
export type DealFiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealFiles" */
export type DealFiles_Mutation_Response = {
  __typename?: "DealFiles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealFiles>;
};

/** input type for inserting object relation for remote table "DealFiles" */
export type DealFiles_Obj_Rel_Insert_Input = {
  data: DealFiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealFiles_On_Conflict>;
};

/** on_conflict condition type for table "DealFiles" */
export type DealFiles_On_Conflict = {
  constraint: DealFiles_Constraint;
  update_columns?: Array<DealFiles_Update_Column>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

/** Ordering options when selecting data from "DealFiles". */
export type DealFiles_Order_By = {
  DealFileTags_aggregate?: InputMaybe<DealFileTags_Aggregate_Order_By>;
  Deals?: InputMaybe<Deals_Order_By>;
  Users?: InputMaybe<Users_Order_By>;
  cog_search_process?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  not_deleted?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealFiles */
export type DealFiles_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealFiles" */
export enum DealFiles_Select_Column {
  /** column name */
  CogSearchProcess = "cog_search_process",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NotDeleted = "not_deleted",
  /** column name */
  UserId = "user_id",
}

/** select "DealFiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealFiles" */
export enum DealFiles_Select_Column_DealFiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CogSearchProcess = "cog_search_process",
  /** column name */
  NotDeleted = "not_deleted",
}

/** select "DealFiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealFiles" */
export enum DealFiles_Select_Column_DealFiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CogSearchProcess = "cog_search_process",
  /** column name */
  NotDeleted = "not_deleted",
}

/** input type for updating data in table "DealFiles" */
export type DealFiles_Set_Input = {
  cog_search_process?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DealFiles_Stddev_Fields = {
  __typename?: "DealFiles_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealFiles" */
export type DealFiles_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealFiles_Stddev_Pop_Fields = {
  __typename?: "DealFiles_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealFiles" */
export type DealFiles_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealFiles_Stddev_Samp_Fields = {
  __typename?: "DealFiles_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealFiles" */
export type DealFiles_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealFiles" */
export type DealFiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealFiles_Stream_Cursor_Value_Input = {
  cog_search_process?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  not_deleted?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type DealFiles_Sum_Fields = {
  __typename?: "DealFiles_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealFiles" */
export type DealFiles_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealFiles" */
export enum DealFiles_Update_Column {
  /** column name */
  CogSearchProcess = "cog_search_process",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NotDeleted = "not_deleted",
  /** column name */
  UserId = "user_id",
}

export type DealFiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealFiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealFiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealFiles_Var_Pop_Fields = {
  __typename?: "DealFiles_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealFiles" */
export type DealFiles_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealFiles_Var_Samp_Fields = {
  __typename?: "DealFiles_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealFiles" */
export type DealFiles_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealFiles_Variance_Fields = {
  __typename?: "DealFiles_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealFiles" */
export type DealFiles_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks = {
  __typename?: "DealFitoutLandlordWorks";
  /** An object relationship */
  DealDatum?: Maybe<DealData>;
  capped_category?: Maybe<Scalars["numeric"]>;
  cost_landlord_works?: Maybe<Scalars["numeric"]>;
  cost_makegood?: Maybe<Scalars["numeric"]>;
  date_complete_fitout?: Maybe<Scalars["date"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  fitout_description?: Maybe<Scalars["String"]>;
  fitout_previous_tenant?: Maybe<Scalars["String"]>;
  hoarding?: Maybe<Scalars["Int"]>;
  id: Scalars["bigint"];
  landlord_undertake_works?: Maybe<Scalars["Boolean"]>;
  landlord_works_type?: Maybe<Scalars["String"]>;
  partial_fitout_items?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["String"]>;
  refurbished_fitout_works?: Maybe<Scalars["Boolean"]>;
  skins?: Maybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: Maybe<Scalars["String"]>;
  tenant_works?: Maybe<Scalars["Boolean"]>;
  tenant_works_options?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Aggregate = {
  __typename?: "DealFitoutLandlordWorks_aggregate";
  aggregate?: Maybe<DealFitoutLandlordWorks_Aggregate_Fields>;
  nodes: Array<DealFitoutLandlordWorks>;
};

export type DealFitoutLandlordWorks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealFitoutLandlordWorks_Aggregate_Bool_Exp_Count>;
};

export type DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealFitoutLandlordWorks_Select_Column_DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealFitoutLandlordWorks_Select_Column_DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealFitoutLandlordWorks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Aggregate_Fields = {
  __typename?: "DealFitoutLandlordWorks_aggregate_fields";
  avg?: Maybe<DealFitoutLandlordWorks_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealFitoutLandlordWorks_Max_Fields>;
  min?: Maybe<DealFitoutLandlordWorks_Min_Fields>;
  stddev?: Maybe<DealFitoutLandlordWorks_Stddev_Fields>;
  stddev_pop?: Maybe<DealFitoutLandlordWorks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealFitoutLandlordWorks_Stddev_Samp_Fields>;
  sum?: Maybe<DealFitoutLandlordWorks_Sum_Fields>;
  var_pop?: Maybe<DealFitoutLandlordWorks_Var_Pop_Fields>;
  var_samp?: Maybe<DealFitoutLandlordWorks_Var_Samp_Fields>;
  variance?: Maybe<DealFitoutLandlordWorks_Variance_Fields>;
};

/** aggregate fields of "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Aggregate_Order_By = {
  avg?: InputMaybe<DealFitoutLandlordWorks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealFitoutLandlordWorks_Max_Order_By>;
  min?: InputMaybe<DealFitoutLandlordWorks_Min_Order_By>;
  stddev?: InputMaybe<DealFitoutLandlordWorks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealFitoutLandlordWorks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealFitoutLandlordWorks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealFitoutLandlordWorks_Sum_Order_By>;
  var_pop?: InputMaybe<DealFitoutLandlordWorks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealFitoutLandlordWorks_Var_Samp_Order_By>;
  variance?: InputMaybe<DealFitoutLandlordWorks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Arr_Rel_Insert_Input = {
  data: Array<DealFitoutLandlordWorks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealFitoutLandlordWorks_On_Conflict>;
};

/** aggregate avg on columns */
export type DealFitoutLandlordWorks_Avg_Fields = {
  __typename?: "DealFitoutLandlordWorks_avg_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Avg_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealFitoutLandlordWorks". All fields are combined with a logical 'AND'. */
export type DealFitoutLandlordWorks_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  _and?: InputMaybe<Array<DealFitoutLandlordWorks_Bool_Exp>>;
  _not?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
  _or?: InputMaybe<Array<DealFitoutLandlordWorks_Bool_Exp>>;
  capped_category?: InputMaybe<Numeric_Comparison_Exp>;
  cost_landlord_works?: InputMaybe<Numeric_Comparison_Exp>;
  cost_makegood?: InputMaybe<Numeric_Comparison_Exp>;
  date_complete_fitout?: InputMaybe<Date_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  fitout_description?: InputMaybe<String_Comparison_Exp>;
  fitout_previous_tenant?: InputMaybe<String_Comparison_Exp>;
  hoarding?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  landlord_undertake_works?: InputMaybe<Boolean_Comparison_Exp>;
  landlord_works_type?: InputMaybe<String_Comparison_Exp>;
  partial_fitout_items?: InputMaybe<String_Comparison_Exp>;
  preview?: InputMaybe<String_Comparison_Exp>;
  refurbished_fitout_works?: InputMaybe<Boolean_Comparison_Exp>;
  skins?: InputMaybe<Int_Comparison_Exp>;
  tenancy_delivery_deal_type?: InputMaybe<String_Comparison_Exp>;
  tenant_works?: InputMaybe<Boolean_Comparison_Exp>;
  tenant_works_options?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealFitoutLandlordWorks" */
export enum DealFitoutLandlordWorks_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealFitoutLandlordWorksPkey = "DealFitoutLandlordWorks_pkey",
  /** unique or primary key constraint on columns "deal_data_id" */
  DealfitoutlandlordworksDealIdUnique = "dealfitoutlandlordworks_deal_id_unique",
}

/** input type for incrementing numeric columns in table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Inc_Input = {
  capped_category?: InputMaybe<Scalars["numeric"]>;
  cost_landlord_works?: InputMaybe<Scalars["numeric"]>;
  cost_makegood?: InputMaybe<Scalars["numeric"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  hoarding?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  skins?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  capped_category?: InputMaybe<Scalars["numeric"]>;
  cost_landlord_works?: InputMaybe<Scalars["numeric"]>;
  cost_makegood?: InputMaybe<Scalars["numeric"]>;
  date_complete_fitout?: InputMaybe<Scalars["date"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  fitout_description?: InputMaybe<Scalars["String"]>;
  fitout_previous_tenant?: InputMaybe<Scalars["String"]>;
  hoarding?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  landlord_undertake_works?: InputMaybe<Scalars["Boolean"]>;
  landlord_works_type?: InputMaybe<Scalars["String"]>;
  partial_fitout_items?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["String"]>;
  refurbished_fitout_works?: InputMaybe<Scalars["Boolean"]>;
  skins?: InputMaybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: InputMaybe<Scalars["String"]>;
  tenant_works?: InputMaybe<Scalars["Boolean"]>;
  tenant_works_options?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealFitoutLandlordWorks_Max_Fields = {
  __typename?: "DealFitoutLandlordWorks_max_fields";
  capped_category?: Maybe<Scalars["numeric"]>;
  cost_landlord_works?: Maybe<Scalars["numeric"]>;
  cost_makegood?: Maybe<Scalars["numeric"]>;
  date_complete_fitout?: Maybe<Scalars["date"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  fitout_description?: Maybe<Scalars["String"]>;
  fitout_previous_tenant?: Maybe<Scalars["String"]>;
  hoarding?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  landlord_works_type?: Maybe<Scalars["String"]>;
  partial_fitout_items?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["String"]>;
  skins?: Maybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: Maybe<Scalars["String"]>;
  tenant_works_options?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Max_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  date_complete_fitout?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  fitout_description?: InputMaybe<Order_By>;
  fitout_previous_tenant?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landlord_works_type?: InputMaybe<Order_By>;
  partial_fitout_items?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
  tenancy_delivery_deal_type?: InputMaybe<Order_By>;
  tenant_works_options?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealFitoutLandlordWorks_Min_Fields = {
  __typename?: "DealFitoutLandlordWorks_min_fields";
  capped_category?: Maybe<Scalars["numeric"]>;
  cost_landlord_works?: Maybe<Scalars["numeric"]>;
  cost_makegood?: Maybe<Scalars["numeric"]>;
  date_complete_fitout?: Maybe<Scalars["date"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  fitout_description?: Maybe<Scalars["String"]>;
  fitout_previous_tenant?: Maybe<Scalars["String"]>;
  hoarding?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  landlord_works_type?: Maybe<Scalars["String"]>;
  partial_fitout_items?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["String"]>;
  skins?: Maybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: Maybe<Scalars["String"]>;
  tenant_works_options?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Min_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  date_complete_fitout?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  fitout_description?: InputMaybe<Order_By>;
  fitout_previous_tenant?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landlord_works_type?: InputMaybe<Order_By>;
  partial_fitout_items?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
  tenancy_delivery_deal_type?: InputMaybe<Order_By>;
  tenant_works_options?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Mutation_Response = {
  __typename?: "DealFitoutLandlordWorks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealFitoutLandlordWorks>;
};

/** input type for inserting object relation for remote table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Obj_Rel_Insert_Input = {
  data: DealFitoutLandlordWorks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealFitoutLandlordWorks_On_Conflict>;
};

/** on_conflict condition type for table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_On_Conflict = {
  constraint: DealFitoutLandlordWorks_Constraint;
  update_columns?: Array<DealFitoutLandlordWorks_Update_Column>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

/** Ordering options when selecting data from "DealFitoutLandlordWorks". */
export type DealFitoutLandlordWorks_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  date_complete_fitout?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  fitout_description?: InputMaybe<Order_By>;
  fitout_previous_tenant?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  landlord_undertake_works?: InputMaybe<Order_By>;
  landlord_works_type?: InputMaybe<Order_By>;
  partial_fitout_items?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  refurbished_fitout_works?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
  tenancy_delivery_deal_type?: InputMaybe<Order_By>;
  tenant_works?: InputMaybe<Order_By>;
  tenant_works_options?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealFitoutLandlordWorks */
export type DealFitoutLandlordWorks_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealFitoutLandlordWorks" */
export enum DealFitoutLandlordWorks_Select_Column {
  /** column name */
  CappedCategory = "capped_category",
  /** column name */
  CostLandlordWorks = "cost_landlord_works",
  /** column name */
  CostMakegood = "cost_makegood",
  /** column name */
  DateCompleteFitout = "date_complete_fitout",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  FitoutDescription = "fitout_description",
  /** column name */
  FitoutPreviousTenant = "fitout_previous_tenant",
  /** column name */
  Hoarding = "hoarding",
  /** column name */
  Id = "id",
  /** column name */
  LandlordUndertakeWorks = "landlord_undertake_works",
  /** column name */
  LandlordWorksType = "landlord_works_type",
  /** column name */
  PartialFitoutItems = "partial_fitout_items",
  /** column name */
  Preview = "preview",
  /** column name */
  RefurbishedFitoutWorks = "refurbished_fitout_works",
  /** column name */
  Skins = "skins",
  /** column name */
  TenancyDeliveryDealType = "tenancy_delivery_deal_type",
  /** column name */
  TenantWorks = "tenant_works",
  /** column name */
  TenantWorksOptions = "tenant_works_options",
}

/** select "DealFitoutLandlordWorks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealFitoutLandlordWorks" */
export enum DealFitoutLandlordWorks_Select_Column_DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  LandlordUndertakeWorks = "landlord_undertake_works",
  /** column name */
  RefurbishedFitoutWorks = "refurbished_fitout_works",
  /** column name */
  TenantWorks = "tenant_works",
}

/** select "DealFitoutLandlordWorks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealFitoutLandlordWorks" */
export enum DealFitoutLandlordWorks_Select_Column_DealFitoutLandlordWorks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  LandlordUndertakeWorks = "landlord_undertake_works",
  /** column name */
  RefurbishedFitoutWorks = "refurbished_fitout_works",
  /** column name */
  TenantWorks = "tenant_works",
}

/** input type for updating data in table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Set_Input = {
  capped_category?: InputMaybe<Scalars["numeric"]>;
  cost_landlord_works?: InputMaybe<Scalars["numeric"]>;
  cost_makegood?: InputMaybe<Scalars["numeric"]>;
  date_complete_fitout?: InputMaybe<Scalars["date"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  fitout_description?: InputMaybe<Scalars["String"]>;
  fitout_previous_tenant?: InputMaybe<Scalars["String"]>;
  hoarding?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  landlord_undertake_works?: InputMaybe<Scalars["Boolean"]>;
  landlord_works_type?: InputMaybe<Scalars["String"]>;
  partial_fitout_items?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["String"]>;
  refurbished_fitout_works?: InputMaybe<Scalars["Boolean"]>;
  skins?: InputMaybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: InputMaybe<Scalars["String"]>;
  tenant_works?: InputMaybe<Scalars["Boolean"]>;
  tenant_works_options?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealFitoutLandlordWorks_Stddev_Fields = {
  __typename?: "DealFitoutLandlordWorks_stddev_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Stddev_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealFitoutLandlordWorks_Stddev_Pop_Fields = {
  __typename?: "DealFitoutLandlordWorks_stddev_pop_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Stddev_Pop_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealFitoutLandlordWorks_Stddev_Samp_Fields = {
  __typename?: "DealFitoutLandlordWorks_stddev_samp_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Stddev_Samp_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealFitoutLandlordWorks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealFitoutLandlordWorks_Stream_Cursor_Value_Input = {
  capped_category?: InputMaybe<Scalars["numeric"]>;
  cost_landlord_works?: InputMaybe<Scalars["numeric"]>;
  cost_makegood?: InputMaybe<Scalars["numeric"]>;
  date_complete_fitout?: InputMaybe<Scalars["date"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  fitout_description?: InputMaybe<Scalars["String"]>;
  fitout_previous_tenant?: InputMaybe<Scalars["String"]>;
  hoarding?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  landlord_undertake_works?: InputMaybe<Scalars["Boolean"]>;
  landlord_works_type?: InputMaybe<Scalars["String"]>;
  partial_fitout_items?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["String"]>;
  refurbished_fitout_works?: InputMaybe<Scalars["Boolean"]>;
  skins?: InputMaybe<Scalars["Int"]>;
  tenancy_delivery_deal_type?: InputMaybe<Scalars["String"]>;
  tenant_works?: InputMaybe<Scalars["Boolean"]>;
  tenant_works_options?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealFitoutLandlordWorks_Sum_Fields = {
  __typename?: "DealFitoutLandlordWorks_sum_fields";
  capped_category?: Maybe<Scalars["numeric"]>;
  cost_landlord_works?: Maybe<Scalars["numeric"]>;
  cost_makegood?: Maybe<Scalars["numeric"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  hoarding?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  skins?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Sum_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** update columns of table "DealFitoutLandlordWorks" */
export enum DealFitoutLandlordWorks_Update_Column {
  /** column name */
  CappedCategory = "capped_category",
  /** column name */
  CostLandlordWorks = "cost_landlord_works",
  /** column name */
  CostMakegood = "cost_makegood",
  /** column name */
  DateCompleteFitout = "date_complete_fitout",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  FitoutDescription = "fitout_description",
  /** column name */
  FitoutPreviousTenant = "fitout_previous_tenant",
  /** column name */
  Hoarding = "hoarding",
  /** column name */
  Id = "id",
  /** column name */
  LandlordUndertakeWorks = "landlord_undertake_works",
  /** column name */
  LandlordWorksType = "landlord_works_type",
  /** column name */
  PartialFitoutItems = "partial_fitout_items",
  /** column name */
  Preview = "preview",
  /** column name */
  RefurbishedFitoutWorks = "refurbished_fitout_works",
  /** column name */
  Skins = "skins",
  /** column name */
  TenancyDeliveryDealType = "tenancy_delivery_deal_type",
  /** column name */
  TenantWorks = "tenant_works",
  /** column name */
  TenantWorksOptions = "tenant_works_options",
}

export type DealFitoutLandlordWorks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealFitoutLandlordWorks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealFitoutLandlordWorks_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealFitoutLandlordWorks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealFitoutLandlordWorks_Var_Pop_Fields = {
  __typename?: "DealFitoutLandlordWorks_var_pop_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Var_Pop_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealFitoutLandlordWorks_Var_Samp_Fields = {
  __typename?: "DealFitoutLandlordWorks_var_samp_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Var_Samp_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealFitoutLandlordWorks_Variance_Fields = {
  __typename?: "DealFitoutLandlordWorks_variance_fields";
  capped_category?: Maybe<Scalars["Float"]>;
  cost_landlord_works?: Maybe<Scalars["Float"]>;
  cost_makegood?: Maybe<Scalars["Float"]>;
  deal_data_id?: Maybe<Scalars["Float"]>;
  hoarding?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  skins?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealFitoutLandlordWorks" */
export type DealFitoutLandlordWorks_Variance_Order_By = {
  capped_category?: InputMaybe<Order_By>;
  cost_landlord_works?: InputMaybe<Order_By>;
  cost_makegood?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  hoarding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skins?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealLAFApprovals" */
export type DealLafApprovals = {
  __typename?: "DealLAFApprovals";
  /** An object relationship */
  ActiveApprover?: Maybe<Users>;
  /** An object relationship */
  DealData?: Maybe<DealData>;
  /** An object relationship */
  LafActionChoice?: Maybe<LafActionChoices>;
  /** An object relationship */
  User?: Maybe<Users>;
  active_approver_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  escalate_level?: Maybe<Scalars["Boolean"]>;
  id: Scalars["bigint"];
  laf_action?: Maybe<LafActionChoices_Enum>;
  last_approver?: Maybe<Scalars["Boolean"]>;
  level?: Maybe<Scalars["Int"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "DealLAFApprovals" */
export type DealLafApprovals_Aggregate = {
  __typename?: "DealLAFApprovals_aggregate";
  aggregate?: Maybe<DealLafApprovals_Aggregate_Fields>;
  nodes: Array<DealLafApprovals>;
};

export type DealLafApprovals_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp_Count>;
};

export type DealLafApprovals_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealLafApprovals_Select_Column_DealLafApprovals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealLafApprovals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealLafApprovals_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealLafApprovals_Select_Column_DealLafApprovals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealLafApprovals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealLafApprovals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealLafApprovals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealLAFApprovals" */
export type DealLafApprovals_Aggregate_Fields = {
  __typename?: "DealLAFApprovals_aggregate_fields";
  avg?: Maybe<DealLafApprovals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealLafApprovals_Max_Fields>;
  min?: Maybe<DealLafApprovals_Min_Fields>;
  stddev?: Maybe<DealLafApprovals_Stddev_Fields>;
  stddev_pop?: Maybe<DealLafApprovals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealLafApprovals_Stddev_Samp_Fields>;
  sum?: Maybe<DealLafApprovals_Sum_Fields>;
  var_pop?: Maybe<DealLafApprovals_Var_Pop_Fields>;
  var_samp?: Maybe<DealLafApprovals_Var_Samp_Fields>;
  variance?: Maybe<DealLafApprovals_Variance_Fields>;
};

/** aggregate fields of "DealLAFApprovals" */
export type DealLafApprovals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealLAFApprovals" */
export type DealLafApprovals_Aggregate_Order_By = {
  avg?: InputMaybe<DealLafApprovals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealLafApprovals_Max_Order_By>;
  min?: InputMaybe<DealLafApprovals_Min_Order_By>;
  stddev?: InputMaybe<DealLafApprovals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealLafApprovals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealLafApprovals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealLafApprovals_Sum_Order_By>;
  var_pop?: InputMaybe<DealLafApprovals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealLafApprovals_Var_Samp_Order_By>;
  variance?: InputMaybe<DealLafApprovals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealLAFApprovals" */
export type DealLafApprovals_Arr_Rel_Insert_Input = {
  data: Array<DealLafApprovals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealLafApprovals_On_Conflict>;
};

/** aggregate avg on columns */
export type DealLafApprovals_Avg_Fields = {
  __typename?: "DealLAFApprovals_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealLAFApprovals". All fields are combined with a logical 'AND'. */
export type DealLafApprovals_Bool_Exp = {
  ActiveApprover?: InputMaybe<Users_Bool_Exp>;
  DealData?: InputMaybe<DealData_Bool_Exp>;
  LafActionChoice?: InputMaybe<LafActionChoices_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DealLafApprovals_Bool_Exp>>;
  _not?: InputMaybe<DealLafApprovals_Bool_Exp>;
  _or?: InputMaybe<Array<DealLafApprovals_Bool_Exp>>;
  active_approver_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  escalate_level?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  laf_action?: InputMaybe<LafActionChoices_Enum_Comparison_Exp>;
  last_approver?: InputMaybe<Boolean_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealLAFApprovals" */
export enum DealLafApprovals_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealLafApprovalsPkey = "DealLAFApprovals_pkey",
  /** unique or primary key constraint on columns "deal_data_id" */
  DeallafapprovalsDealIdUnique = "deallafapprovals_deal_id_unique",
}

/** input type for incrementing numeric columns in table "DealLAFApprovals" */
export type DealLafApprovals_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealLAFApprovals" */
export type DealLafApprovals_Insert_Input = {
  ActiveApprover?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  DealData?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  LafActionChoice?: InputMaybe<LafActionChoices_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  active_approver_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  escalate_level?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_action?: InputMaybe<LafActionChoices_Enum>;
  last_approver?: InputMaybe<Scalars["Boolean"]>;
  level?: InputMaybe<Scalars["Int"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type DealLafApprovals_Max_Fields = {
  __typename?: "DealLAFApprovals_max_fields";
  active_approver_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Max_Order_By = {
  active_approver_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealLafApprovals_Min_Fields = {
  __typename?: "DealLAFApprovals_min_fields";
  active_approver_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Min_Order_By = {
  active_approver_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealLAFApprovals" */
export type DealLafApprovals_Mutation_Response = {
  __typename?: "DealLAFApprovals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealLafApprovals>;
};

/** input type for inserting object relation for remote table "DealLAFApprovals" */
export type DealLafApprovals_Obj_Rel_Insert_Input = {
  data: DealLafApprovals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealLafApprovals_On_Conflict>;
};

/** on_conflict condition type for table "DealLAFApprovals" */
export type DealLafApprovals_On_Conflict = {
  constraint: DealLafApprovals_Constraint;
  update_columns?: Array<DealLafApprovals_Update_Column>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** Ordering options when selecting data from "DealLAFApprovals". */
export type DealLafApprovals_Order_By = {
  ActiveApprover?: InputMaybe<Users_Order_By>;
  DealData?: InputMaybe<DealData_Order_By>;
  LafActionChoice?: InputMaybe<LafActionChoices_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  active_approver_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  escalate_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_action?: InputMaybe<Order_By>;
  last_approver?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealLAFApprovals */
export type DealLafApprovals_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealLAFApprovals" */
export enum DealLafApprovals_Select_Column {
  /** column name */
  ActiveApproverId = "active_approver_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  EscalateLevel = "escalate_level",
  /** column name */
  Id = "id",
  /** column name */
  LafAction = "laf_action",
  /** column name */
  LastApprover = "last_approver",
  /** column name */
  Level = "level",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  UpdatedBy = "updated_by",
}

/** select "DealLAFApprovals_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealLAFApprovals" */
export enum DealLafApprovals_Select_Column_DealLafApprovals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EscalateLevel = "escalate_level",
  /** column name */
  LastApprover = "last_approver",
}

/** select "DealLAFApprovals_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealLAFApprovals" */
export enum DealLafApprovals_Select_Column_DealLafApprovals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EscalateLevel = "escalate_level",
  /** column name */
  LastApprover = "last_approver",
}

/** input type for updating data in table "DealLAFApprovals" */
export type DealLafApprovals_Set_Input = {
  active_approver_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  escalate_level?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_action?: InputMaybe<LafActionChoices_Enum>;
  last_approver?: InputMaybe<Scalars["Boolean"]>;
  level?: InputMaybe<Scalars["Int"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DealLafApprovals_Stddev_Fields = {
  __typename?: "DealLAFApprovals_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealLafApprovals_Stddev_Pop_Fields = {
  __typename?: "DealLAFApprovals_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealLafApprovals_Stddev_Samp_Fields = {
  __typename?: "DealLAFApprovals_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealLAFApprovals" */
export type DealLafApprovals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealLafApprovals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealLafApprovals_Stream_Cursor_Value_Input = {
  active_approver_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  escalate_level?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_action?: InputMaybe<LafActionChoices_Enum>;
  last_approver?: InputMaybe<Scalars["Boolean"]>;
  level?: InputMaybe<Scalars["Int"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_by?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type DealLafApprovals_Sum_Fields = {
  __typename?: "DealLAFApprovals_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** update columns of table "DealLAFApprovals" */
export enum DealLafApprovals_Update_Column {
  /** column name */
  ActiveApproverId = "active_approver_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  EscalateLevel = "escalate_level",
  /** column name */
  Id = "id",
  /** column name */
  LafAction = "laf_action",
  /** column name */
  LastApprover = "last_approver",
  /** column name */
  Level = "level",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  UpdatedBy = "updated_by",
}

export type DealLafApprovals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealLafApprovals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealLafApprovals_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealLafApprovals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealLafApprovals_Var_Pop_Fields = {
  __typename?: "DealLAFApprovals_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealLafApprovals_Var_Samp_Fields = {
  __typename?: "DealLAFApprovals_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealLafApprovals_Variance_Fields = {
  __typename?: "DealLAFApprovals_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealLAFApprovals" */
export type DealLafApprovals_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealLinks" */
export type DealLinks = {
  __typename?: "DealLinks";
  /** An object relationship */
  Deal: Deals;
  /** An object relationship */
  dealByDealLinkId: Deals;
  deal_id: Scalars["Int"];
  deal_link_id: Scalars["Int"];
  id: Scalars["Int"];
};

/** aggregated selection of "DealLinks" */
export type DealLinks_Aggregate = {
  __typename?: "DealLinks_aggregate";
  aggregate?: Maybe<DealLinks_Aggregate_Fields>;
  nodes: Array<DealLinks>;
};

export type DealLinks_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealLinks_Aggregate_Bool_Exp_Count>;
};

export type DealLinks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealLinks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealLinks" */
export type DealLinks_Aggregate_Fields = {
  __typename?: "DealLinks_aggregate_fields";
  avg?: Maybe<DealLinks_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealLinks_Max_Fields>;
  min?: Maybe<DealLinks_Min_Fields>;
  stddev?: Maybe<DealLinks_Stddev_Fields>;
  stddev_pop?: Maybe<DealLinks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealLinks_Stddev_Samp_Fields>;
  sum?: Maybe<DealLinks_Sum_Fields>;
  var_pop?: Maybe<DealLinks_Var_Pop_Fields>;
  var_samp?: Maybe<DealLinks_Var_Samp_Fields>;
  variance?: Maybe<DealLinks_Variance_Fields>;
};

/** aggregate fields of "DealLinks" */
export type DealLinks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealLinks" */
export type DealLinks_Aggregate_Order_By = {
  avg?: InputMaybe<DealLinks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealLinks_Max_Order_By>;
  min?: InputMaybe<DealLinks_Min_Order_By>;
  stddev?: InputMaybe<DealLinks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealLinks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealLinks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealLinks_Sum_Order_By>;
  var_pop?: InputMaybe<DealLinks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealLinks_Var_Samp_Order_By>;
  variance?: InputMaybe<DealLinks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealLinks" */
export type DealLinks_Arr_Rel_Insert_Input = {
  data: Array<DealLinks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealLinks_On_Conflict>;
};

/** aggregate avg on columns */
export type DealLinks_Avg_Fields = {
  __typename?: "DealLinks_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealLinks" */
export type DealLinks_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealLinks". All fields are combined with a logical 'AND'. */
export type DealLinks_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<DealLinks_Bool_Exp>>;
  _not?: InputMaybe<DealLinks_Bool_Exp>;
  _or?: InputMaybe<Array<DealLinks_Bool_Exp>>;
  dealByDealLinkId?: InputMaybe<Deals_Bool_Exp>;
  deal_id?: InputMaybe<Int_Comparison_Exp>;
  deal_link_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealLinks" */
export enum DealLinks_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealLinksPkey = "DealLinks_pkey",
}

/** input type for incrementing numeric columns in table "DealLinks" */
export type DealLinks_Inc_Input = {
  deal_id?: InputMaybe<Scalars["Int"]>;
  deal_link_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealLinks" */
export type DealLinks_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  dealByDealLinkId?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars["Int"]>;
  deal_link_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type DealLinks_Max_Fields = {
  __typename?: "DealLinks_max_fields";
  deal_id?: Maybe<Scalars["Int"]>;
  deal_link_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "DealLinks" */
export type DealLinks_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealLinks_Min_Fields = {
  __typename?: "DealLinks_min_fields";
  deal_id?: Maybe<Scalars["Int"]>;
  deal_link_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "DealLinks" */
export type DealLinks_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealLinks" */
export type DealLinks_Mutation_Response = {
  __typename?: "DealLinks_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealLinks>;
};

/** on_conflict condition type for table "DealLinks" */
export type DealLinks_On_Conflict = {
  constraint: DealLinks_Constraint;
  update_columns?: Array<DealLinks_Update_Column>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

/** Ordering options when selecting data from "DealLinks". */
export type DealLinks_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  dealByDealLinkId?: InputMaybe<Deals_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealLinks */
export type DealLinks_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "DealLinks" */
export enum DealLinks_Select_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  DealLinkId = "deal_link_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "DealLinks" */
export type DealLinks_Set_Input = {
  deal_id?: InputMaybe<Scalars["Int"]>;
  deal_link_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type DealLinks_Stddev_Fields = {
  __typename?: "DealLinks_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealLinks" */
export type DealLinks_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealLinks_Stddev_Pop_Fields = {
  __typename?: "DealLinks_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealLinks" */
export type DealLinks_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealLinks_Stddev_Samp_Fields = {
  __typename?: "DealLinks_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealLinks" */
export type DealLinks_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealLinks" */
export type DealLinks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealLinks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealLinks_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars["Int"]>;
  deal_link_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type DealLinks_Sum_Fields = {
  __typename?: "DealLinks_sum_fields";
  deal_id?: Maybe<Scalars["Int"]>;
  deal_link_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealLinks" */
export type DealLinks_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealLinks" */
export enum DealLinks_Update_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  DealLinkId = "deal_link_id",
  /** column name */
  Id = "id",
}

export type DealLinks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealLinks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealLinks_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealLinks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealLinks_Var_Pop_Fields = {
  __typename?: "DealLinks_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealLinks" */
export type DealLinks_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealLinks_Var_Samp_Fields = {
  __typename?: "DealLinks_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealLinks" */
export type DealLinks_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealLinks_Variance_Fields = {
  __typename?: "DealLinks_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  deal_link_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealLinks" */
export type DealLinks_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  deal_link_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealOptions" */
export type DealOptions = {
  __typename?: "DealOptions";
  /** An object relationship */
  DealDatum?: Maybe<DealData>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  first_year_amount?: Maybe<Scalars["Int"]>;
  first_year_type?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: Maybe<Scalars["Int"]>;
  subsequent_year_type?: Maybe<Scalars["String"]>;
  term_length?: Maybe<Scalars["Int"]>;
  works_required?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealOptions" */
export type DealOptions_Aggregate = {
  __typename?: "DealOptions_aggregate";
  aggregate?: Maybe<DealOptions_Aggregate_Fields>;
  nodes: Array<DealOptions>;
};

export type DealOptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealOptions_Aggregate_Bool_Exp_Count>;
};

export type DealOptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealOptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealOptions" */
export type DealOptions_Aggregate_Fields = {
  __typename?: "DealOptions_aggregate_fields";
  avg?: Maybe<DealOptions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealOptions_Max_Fields>;
  min?: Maybe<DealOptions_Min_Fields>;
  stddev?: Maybe<DealOptions_Stddev_Fields>;
  stddev_pop?: Maybe<DealOptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealOptions_Stddev_Samp_Fields>;
  sum?: Maybe<DealOptions_Sum_Fields>;
  var_pop?: Maybe<DealOptions_Var_Pop_Fields>;
  var_samp?: Maybe<DealOptions_Var_Samp_Fields>;
  variance?: Maybe<DealOptions_Variance_Fields>;
};

/** aggregate fields of "DealOptions" */
export type DealOptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealOptions" */
export type DealOptions_Aggregate_Order_By = {
  avg?: InputMaybe<DealOptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealOptions_Max_Order_By>;
  min?: InputMaybe<DealOptions_Min_Order_By>;
  stddev?: InputMaybe<DealOptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealOptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealOptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealOptions_Sum_Order_By>;
  var_pop?: InputMaybe<DealOptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealOptions_Var_Samp_Order_By>;
  variance?: InputMaybe<DealOptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealOptions" */
export type DealOptions_Arr_Rel_Insert_Input = {
  data: Array<DealOptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealOptions_On_Conflict>;
};

/** aggregate avg on columns */
export type DealOptions_Avg_Fields = {
  __typename?: "DealOptions_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealOptions" */
export type DealOptions_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealOptions". All fields are combined with a logical 'AND'. */
export type DealOptions_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  _and?: InputMaybe<Array<DealOptions_Bool_Exp>>;
  _not?: InputMaybe<DealOptions_Bool_Exp>;
  _or?: InputMaybe<Array<DealOptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  first_year_amount?: InputMaybe<Int_Comparison_Exp>;
  first_year_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  subsequent_year_amount?: InputMaybe<Int_Comparison_Exp>;
  subsequent_year_type?: InputMaybe<String_Comparison_Exp>;
  term_length?: InputMaybe<Int_Comparison_Exp>;
  works_required?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealOptions" */
export enum DealOptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealOptionsPkey = "DealOptions_pkey",
}

/** input type for incrementing numeric columns in table "DealOptions" */
export type DealOptions_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  first_year_amount?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  subsequent_year_amount?: InputMaybe<Scalars["Int"]>;
  term_length?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealOptions" */
export type DealOptions_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  first_year_amount?: InputMaybe<Scalars["Int"]>;
  first_year_type?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: InputMaybe<Scalars["Int"]>;
  subsequent_year_type?: InputMaybe<Scalars["String"]>;
  term_length?: InputMaybe<Scalars["Int"]>;
  works_required?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealOptions_Max_Fields = {
  __typename?: "DealOptions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  first_year_amount?: Maybe<Scalars["Int"]>;
  first_year_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: Maybe<Scalars["Int"]>;
  subsequent_year_type?: Maybe<Scalars["String"]>;
  term_length?: Maybe<Scalars["Int"]>;
  works_required?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealOptions" */
export type DealOptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  first_year_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  subsequent_year_type?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
  works_required?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealOptions_Min_Fields = {
  __typename?: "DealOptions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  first_year_amount?: Maybe<Scalars["Int"]>;
  first_year_type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: Maybe<Scalars["Int"]>;
  subsequent_year_type?: Maybe<Scalars["String"]>;
  term_length?: Maybe<Scalars["Int"]>;
  works_required?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealOptions" */
export type DealOptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  first_year_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  subsequent_year_type?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
  works_required?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealOptions" */
export type DealOptions_Mutation_Response = {
  __typename?: "DealOptions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealOptions>;
};

/** on_conflict condition type for table "DealOptions" */
export type DealOptions_On_Conflict = {
  constraint: DealOptions_Constraint;
  update_columns?: Array<DealOptions_Update_Column>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

/** Ordering options when selecting data from "DealOptions". */
export type DealOptions_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  first_year_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  subsequent_year_type?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
  works_required?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealOptions */
export type DealOptions_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealOptions" */
export enum DealOptions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  FirstYearAmount = "first_year_amount",
  /** column name */
  FirstYearType = "first_year_type",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  SubsequentYearAmount = "subsequent_year_amount",
  /** column name */
  SubsequentYearType = "subsequent_year_type",
  /** column name */
  TermLength = "term_length",
  /** column name */
  WorksRequired = "works_required",
}

/** input type for updating data in table "DealOptions" */
export type DealOptions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  first_year_amount?: InputMaybe<Scalars["Int"]>;
  first_year_type?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: InputMaybe<Scalars["Int"]>;
  subsequent_year_type?: InputMaybe<Scalars["String"]>;
  term_length?: InputMaybe<Scalars["Int"]>;
  works_required?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealOptions_Stddev_Fields = {
  __typename?: "DealOptions_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealOptions" */
export type DealOptions_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealOptions_Stddev_Pop_Fields = {
  __typename?: "DealOptions_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealOptions" */
export type DealOptions_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealOptions_Stddev_Samp_Fields = {
  __typename?: "DealOptions_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealOptions" */
export type DealOptions_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealOptions" */
export type DealOptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealOptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealOptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  first_year_amount?: InputMaybe<Scalars["Int"]>;
  first_year_type?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  subsequent_year_amount?: InputMaybe<Scalars["Int"]>;
  subsequent_year_type?: InputMaybe<Scalars["String"]>;
  term_length?: InputMaybe<Scalars["Int"]>;
  works_required?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealOptions_Sum_Fields = {
  __typename?: "DealOptions_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  first_year_amount?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  subsequent_year_amount?: Maybe<Scalars["Int"]>;
  term_length?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealOptions" */
export type DealOptions_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** update columns of table "DealOptions" */
export enum DealOptions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  FirstYearAmount = "first_year_amount",
  /** column name */
  FirstYearType = "first_year_type",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  SubsequentYearAmount = "subsequent_year_amount",
  /** column name */
  SubsequentYearType = "subsequent_year_type",
  /** column name */
  TermLength = "term_length",
  /** column name */
  WorksRequired = "works_required",
}

export type DealOptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealOptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealOptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealOptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealOptions_Var_Pop_Fields = {
  __typename?: "DealOptions_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealOptions" */
export type DealOptions_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealOptions_Var_Samp_Fields = {
  __typename?: "DealOptions_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealOptions" */
export type DealOptions_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealOptions_Variance_Fields = {
  __typename?: "DealOptions_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  first_year_amount?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  subsequent_year_amount?: Maybe<Scalars["Float"]>;
  term_length?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealOptions" */
export type DealOptions_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  first_year_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subsequent_year_amount?: InputMaybe<Order_By>;
  term_length?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealPermittedUses" */
export type DealPermittedUses = {
  __typename?: "DealPermittedUses";
  /** An object relationship */
  DealDatum: DealData;
  /** An object relationship */
  TemplatePermittedUse?: Maybe<TemplatePermittedUses>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id: Scalars["bigint"];
  description?: Maybe<Scalars["String"]>;
  food_menu?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  sublet?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealPermittedUses" */
export type DealPermittedUses_Aggregate = {
  __typename?: "DealPermittedUses_aggregate";
  aggregate?: Maybe<DealPermittedUses_Aggregate_Fields>;
  nodes: Array<DealPermittedUses>;
};

export type DealPermittedUses_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealPermittedUses_Aggregate_Bool_Exp_Count>;
};

export type DealPermittedUses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealPermittedUses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealPermittedUses" */
export type DealPermittedUses_Aggregate_Fields = {
  __typename?: "DealPermittedUses_aggregate_fields";
  avg?: Maybe<DealPermittedUses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealPermittedUses_Max_Fields>;
  min?: Maybe<DealPermittedUses_Min_Fields>;
  stddev?: Maybe<DealPermittedUses_Stddev_Fields>;
  stddev_pop?: Maybe<DealPermittedUses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealPermittedUses_Stddev_Samp_Fields>;
  sum?: Maybe<DealPermittedUses_Sum_Fields>;
  var_pop?: Maybe<DealPermittedUses_Var_Pop_Fields>;
  var_samp?: Maybe<DealPermittedUses_Var_Samp_Fields>;
  variance?: Maybe<DealPermittedUses_Variance_Fields>;
};

/** aggregate fields of "DealPermittedUses" */
export type DealPermittedUses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealPermittedUses" */
export type DealPermittedUses_Aggregate_Order_By = {
  avg?: InputMaybe<DealPermittedUses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealPermittedUses_Max_Order_By>;
  min?: InputMaybe<DealPermittedUses_Min_Order_By>;
  stddev?: InputMaybe<DealPermittedUses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealPermittedUses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealPermittedUses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealPermittedUses_Sum_Order_By>;
  var_pop?: InputMaybe<DealPermittedUses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealPermittedUses_Var_Samp_Order_By>;
  variance?: InputMaybe<DealPermittedUses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealPermittedUses" */
export type DealPermittedUses_Arr_Rel_Insert_Input = {
  data: Array<DealPermittedUses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealPermittedUses_On_Conflict>;
};

/** aggregate avg on columns */
export type DealPermittedUses_Avg_Fields = {
  __typename?: "DealPermittedUses_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealPermittedUses". All fields are combined with a logical 'AND'. */
export type DealPermittedUses_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  TemplatePermittedUse?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
  _and?: InputMaybe<Array<DealPermittedUses_Bool_Exp>>;
  _not?: InputMaybe<DealPermittedUses_Bool_Exp>;
  _or?: InputMaybe<Array<DealPermittedUses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  food_menu?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  sublet?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealPermittedUses" */
export enum DealPermittedUses_Constraint {
  /** unique or primary key constraint on columns "deal_data_id" */
  DealPermittedUsesDealDataIdKey = "DealPermittedUses_deal_data_id_key",
  /** unique or primary key constraint on columns "id" */
  DealPermittedUsesPkey = "DealPermittedUses_pkey",
}

/** input type for incrementing numeric columns in table "DealPermittedUses" */
export type DealPermittedUses_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealPermittedUses" */
export type DealPermittedUses_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  TemplatePermittedUse?: InputMaybe<TemplatePermittedUses_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  food_menu?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  sublet?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealPermittedUses_Max_Fields = {
  __typename?: "DealPermittedUses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  food_menu?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  sublet?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  food_menu?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sublet?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealPermittedUses_Min_Fields = {
  __typename?: "DealPermittedUses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  food_menu?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  sublet?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  food_menu?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sublet?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealPermittedUses" */
export type DealPermittedUses_Mutation_Response = {
  __typename?: "DealPermittedUses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealPermittedUses>;
};

/** on_conflict condition type for table "DealPermittedUses" */
export type DealPermittedUses_On_Conflict = {
  constraint: DealPermittedUses_Constraint;
  update_columns?: Array<DealPermittedUses_Update_Column>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

/** Ordering options when selecting data from "DealPermittedUses". */
export type DealPermittedUses_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  TemplatePermittedUse?: InputMaybe<TemplatePermittedUses_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  food_menu?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sublet?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealPermittedUses */
export type DealPermittedUses_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealPermittedUses" */
export enum DealPermittedUses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  FoodMenu = "food_menu",
  /** column name */
  Id = "id",
  /** column name */
  Sublet = "sublet",
  /** column name */
  TemplateId = "template_id",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "DealPermittedUses" */
export type DealPermittedUses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  food_menu?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  sublet?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealPermittedUses_Stddev_Fields = {
  __typename?: "DealPermittedUses_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealPermittedUses_Stddev_Pop_Fields = {
  __typename?: "DealPermittedUses_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealPermittedUses_Stddev_Samp_Fields = {
  __typename?: "DealPermittedUses_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealPermittedUses" */
export type DealPermittedUses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealPermittedUses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealPermittedUses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  food_menu?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  sublet?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealPermittedUses_Sum_Fields = {
  __typename?: "DealPermittedUses_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  template_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealPermittedUses" */
export enum DealPermittedUses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  FoodMenu = "food_menu",
  /** column name */
  Id = "id",
  /** column name */
  Sublet = "sublet",
  /** column name */
  TemplateId = "template_id",
  /** column name */
  Title = "title",
}

export type DealPermittedUses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealPermittedUses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealPermittedUses_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealPermittedUses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealPermittedUses_Var_Pop_Fields = {
  __typename?: "DealPermittedUses_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealPermittedUses_Var_Samp_Fields = {
  __typename?: "DealPermittedUses_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealPermittedUses_Variance_Fields = {
  __typename?: "DealPermittedUses_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealPermittedUses" */
export type DealPermittedUses_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealPhases" */
export type DealPhases = {
  __typename?: "DealPhases";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  /** An array relationship */
  DealActionItem: Array<DealActionItem>;
  /** An aggregate relationship */
  DealActionItem_aggregate: DealActionItem_Aggregate;
  /** An object relationship */
  Phase?: Maybe<Phases>;
  /** An object relationship */
  PhaseDealStatus?: Maybe<PhaseDealStatus>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  is_tenancy_delivery?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
  sequence?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "DealPhases" */
export type DealPhasesDealActionItemArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

/** columns and relationships of "DealPhases" */
export type DealPhasesDealActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

/** aggregated selection of "DealPhases" */
export type DealPhases_Aggregate = {
  __typename?: "DealPhases_aggregate";
  aggregate?: Maybe<DealPhases_Aggregate_Fields>;
  nodes: Array<DealPhases>;
};

export type DealPhases_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealPhases_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealPhases_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealPhases_Aggregate_Bool_Exp_Count>;
};

export type DealPhases_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealPhases_Select_Column_DealPhases_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealPhases_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealPhases_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealPhases_Select_Column_DealPhases_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealPhases_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealPhases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealPhases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealPhases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealPhases" */
export type DealPhases_Aggregate_Fields = {
  __typename?: "DealPhases_aggregate_fields";
  avg?: Maybe<DealPhases_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealPhases_Max_Fields>;
  min?: Maybe<DealPhases_Min_Fields>;
  stddev?: Maybe<DealPhases_Stddev_Fields>;
  stddev_pop?: Maybe<DealPhases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealPhases_Stddev_Samp_Fields>;
  sum?: Maybe<DealPhases_Sum_Fields>;
  var_pop?: Maybe<DealPhases_Var_Pop_Fields>;
  var_samp?: Maybe<DealPhases_Var_Samp_Fields>;
  variance?: Maybe<DealPhases_Variance_Fields>;
};

/** aggregate fields of "DealPhases" */
export type DealPhases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealPhases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealPhases" */
export type DealPhases_Aggregate_Order_By = {
  avg?: InputMaybe<DealPhases_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealPhases_Max_Order_By>;
  min?: InputMaybe<DealPhases_Min_Order_By>;
  stddev?: InputMaybe<DealPhases_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealPhases_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealPhases_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealPhases_Sum_Order_By>;
  var_pop?: InputMaybe<DealPhases_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealPhases_Var_Samp_Order_By>;
  variance?: InputMaybe<DealPhases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealPhases" */
export type DealPhases_Arr_Rel_Insert_Input = {
  data: Array<DealPhases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealPhases_On_Conflict>;
};

/** aggregate avg on columns */
export type DealPhases_Avg_Fields = {
  __typename?: "DealPhases_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealPhases" */
export type DealPhases_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealPhases". All fields are combined with a logical 'AND'. */
export type DealPhases_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  DealActionItem?: InputMaybe<DealActionItem_Bool_Exp>;
  DealActionItem_aggregate?: InputMaybe<DealActionItem_Aggregate_Bool_Exp>;
  Phase?: InputMaybe<Phases_Bool_Exp>;
  PhaseDealStatus?: InputMaybe<PhaseDealStatus_Bool_Exp>;
  _and?: InputMaybe<Array<DealPhases_Bool_Exp>>;
  _not?: InputMaybe<DealPhases_Bool_Exp>;
  _or?: InputMaybe<Array<DealPhases_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_tenancy_delivery?: InputMaybe<Boolean_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phase_id?: InputMaybe<Bigint_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  status_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealPhases" */
export enum DealPhases_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealPhasesPkey = "DealPhases_pkey",
}

/** input type for incrementing numeric columns in table "DealPhases" */
export type DealPhases_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
  status_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "DealPhases" */
export type DealPhases_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  DealActionItem?: InputMaybe<DealActionItem_Arr_Rel_Insert_Input>;
  Phase?: InputMaybe<Phases_Obj_Rel_Insert_Input>;
  PhaseDealStatus?: InputMaybe<PhaseDealStatus_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
  status_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type DealPhases_Max_Fields = {
  __typename?: "DealPhases_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
  sequence?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "DealPhases" */
export type DealPhases_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealPhases_Min_Fields = {
  __typename?: "DealPhases_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
  sequence?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "DealPhases" */
export type DealPhases_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealPhases" */
export type DealPhases_Mutation_Response = {
  __typename?: "DealPhases_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealPhases>;
};

/** input type for inserting object relation for remote table "DealPhases" */
export type DealPhases_Obj_Rel_Insert_Input = {
  data: DealPhases_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealPhases_On_Conflict>;
};

/** on_conflict condition type for table "DealPhases" */
export type DealPhases_On_Conflict = {
  constraint: DealPhases_Constraint;
  update_columns?: Array<DealPhases_Update_Column>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** Ordering options when selecting data from "DealPhases". */
export type DealPhases_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  DealActionItem_aggregate?: InputMaybe<DealActionItem_Aggregate_Order_By>;
  Phase?: InputMaybe<Phases_Order_By>;
  PhaseDealStatus?: InputMaybe<PhaseDealStatus_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_tenancy_delivery?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealPhases */
export type DealPhases_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealPhases" */
export enum DealPhases_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Name = "name",
  /** column name */
  PhaseId = "phase_id",
  /** column name */
  Sequence = "sequence",
  /** column name */
  StatusId = "status_id",
}

/** select "DealPhases_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealPhases" */
export enum DealPhases_Select_Column_DealPhases_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
}

/** select "DealPhases_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealPhases" */
export enum DealPhases_Select_Column_DealPhases_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
}

/** input type for updating data in table "DealPhases" */
export type DealPhases_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
  status_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type DealPhases_Stddev_Fields = {
  __typename?: "DealPhases_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealPhases" */
export type DealPhases_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealPhases_Stddev_Pop_Fields = {
  __typename?: "DealPhases_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealPhases" */
export type DealPhases_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealPhases_Stddev_Samp_Fields = {
  __typename?: "DealPhases_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealPhases" */
export type DealPhases_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealPhases" */
export type DealPhases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealPhases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealPhases_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
  status_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type DealPhases_Sum_Fields = {
  __typename?: "DealPhases_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
  sequence?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "DealPhases" */
export type DealPhases_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealPhases" */
export enum DealPhases_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Name = "name",
  /** column name */
  PhaseId = "phase_id",
  /** column name */
  Sequence = "sequence",
  /** column name */
  StatusId = "status_id",
}

export type DealPhases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealPhases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealPhases_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealPhases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealPhases_Var_Pop_Fields = {
  __typename?: "DealPhases_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealPhases" */
export type DealPhases_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealPhases_Var_Samp_Fields = {
  __typename?: "DealPhases_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealPhases" */
export type DealPhases_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealPhases_Variance_Fields = {
  __typename?: "DealPhases_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealPhases" */
export type DealPhases_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealRiskLevels" */
export type DealRiskLevels = {
  __typename?: "DealRiskLevels";
  comment: Scalars["String"];
  risk_level: Scalars["String"];
};

/** aggregated selection of "DealRiskLevels" */
export type DealRiskLevels_Aggregate = {
  __typename?: "DealRiskLevels_aggregate";
  aggregate?: Maybe<DealRiskLevels_Aggregate_Fields>;
  nodes: Array<DealRiskLevels>;
};

/** aggregate fields of "DealRiskLevels" */
export type DealRiskLevels_Aggregate_Fields = {
  __typename?: "DealRiskLevels_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<DealRiskLevels_Max_Fields>;
  min?: Maybe<DealRiskLevels_Min_Fields>;
};

/** aggregate fields of "DealRiskLevels" */
export type DealRiskLevels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealRiskLevels_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "DealRiskLevels". All fields are combined with a logical 'AND'. */
export type DealRiskLevels_Bool_Exp = {
  _and?: InputMaybe<Array<DealRiskLevels_Bool_Exp>>;
  _not?: InputMaybe<DealRiskLevels_Bool_Exp>;
  _or?: InputMaybe<Array<DealRiskLevels_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  risk_level?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealRiskLevels" */
export enum DealRiskLevels_Constraint {
  /** unique or primary key constraint on columns "risk_level" */
  DealRiskLevelsPkey = "DealRiskLevels_pkey",
}

export enum DealRiskLevels_Enum {
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

/** Boolean expression to compare columns of type "DealRiskLevels_enum". All fields are combined with logical 'AND'. */
export type DealRiskLevels_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DealRiskLevels_Enum>;
  _in?: InputMaybe<Array<DealRiskLevels_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<DealRiskLevels_Enum>;
  _nin?: InputMaybe<Array<DealRiskLevels_Enum>>;
};

/** input type for inserting data into table "DealRiskLevels" */
export type DealRiskLevels_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealRiskLevels_Max_Fields = {
  __typename?: "DealRiskLevels_max_fields";
  comment?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealRiskLevels_Min_Fields = {
  __typename?: "DealRiskLevels_min_fields";
  comment?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealRiskLevels" */
export type DealRiskLevels_Mutation_Response = {
  __typename?: "DealRiskLevels_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealRiskLevels>;
};

/** on_conflict condition type for table "DealRiskLevels" */
export type DealRiskLevels_On_Conflict = {
  constraint: DealRiskLevels_Constraint;
  update_columns?: Array<DealRiskLevels_Update_Column>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

/** Ordering options when selecting data from "DealRiskLevels". */
export type DealRiskLevels_Order_By = {
  comment?: InputMaybe<Order_By>;
  risk_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealRiskLevels */
export type DealRiskLevels_Pk_Columns_Input = {
  risk_level: Scalars["String"];
};

/** select columns of table "DealRiskLevels" */
export enum DealRiskLevels_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  RiskLevel = "risk_level",
}

/** input type for updating data in table "DealRiskLevels" */
export type DealRiskLevels_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "DealRiskLevels" */
export type DealRiskLevels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealRiskLevels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealRiskLevels_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "DealRiskLevels" */
export enum DealRiskLevels_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  RiskLevel = "risk_level",
}

export type DealRiskLevels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealRiskLevels_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealRiskLevels_Bool_Exp;
};

/** This table contains deal shop activities for notification purposes */
export type DealShopActivities = {
  __typename?: "DealShopActivities";
  /** An object relationship */
  Deals?: Maybe<Deals>;
  activity: DealShopActivityTypes_Enum;
  /** An object relationship */
  activity_type?: Maybe<DealShopActivityTypes>;
  actual_handover_date?: Maybe<Scalars["date"]>;
  comments?: Maybe<Scalars["String"]>;
  date_of_closure?: Maybe<Scalars["date"]>;
  deal_file_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  deal_type?: Maybe<Scalars["String"]>;
  estimated_open_date?: Maybe<Scalars["date"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  forecast_trade_date?: Maybe<Scalars["date"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  id: Scalars["bigint"];
  open_date?: Maybe<Scalars["date"]>;
  property_address?: Maybe<Scalars["String"]>;
  property_name: Scalars["String"];
  reason_for_leaving?: Maybe<Scalars["String"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  shop_number?: Maybe<Scalars["String"]>;
  submitted_user_name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["String"]>;
  tenant_name?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealShopActivities" */
export type DealShopActivities_Aggregate = {
  __typename?: "DealShopActivities_aggregate";
  aggregate?: Maybe<DealShopActivities_Aggregate_Fields>;
  nodes: Array<DealShopActivities>;
};

export type DealShopActivities_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealShopActivities_Aggregate_Bool_Exp_Count>;
};

export type DealShopActivities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealShopActivities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealShopActivities" */
export type DealShopActivities_Aggregate_Fields = {
  __typename?: "DealShopActivities_aggregate_fields";
  avg?: Maybe<DealShopActivities_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealShopActivities_Max_Fields>;
  min?: Maybe<DealShopActivities_Min_Fields>;
  stddev?: Maybe<DealShopActivities_Stddev_Fields>;
  stddev_pop?: Maybe<DealShopActivities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealShopActivities_Stddev_Samp_Fields>;
  sum?: Maybe<DealShopActivities_Sum_Fields>;
  var_pop?: Maybe<DealShopActivities_Var_Pop_Fields>;
  var_samp?: Maybe<DealShopActivities_Var_Samp_Fields>;
  variance?: Maybe<DealShopActivities_Variance_Fields>;
};

/** aggregate fields of "DealShopActivities" */
export type DealShopActivities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealShopActivities" */
export type DealShopActivities_Aggregate_Order_By = {
  avg?: InputMaybe<DealShopActivities_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealShopActivities_Max_Order_By>;
  min?: InputMaybe<DealShopActivities_Min_Order_By>;
  stddev?: InputMaybe<DealShopActivities_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealShopActivities_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealShopActivities_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealShopActivities_Sum_Order_By>;
  var_pop?: InputMaybe<DealShopActivities_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealShopActivities_Var_Samp_Order_By>;
  variance?: InputMaybe<DealShopActivities_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealShopActivities" */
export type DealShopActivities_Arr_Rel_Insert_Input = {
  data: Array<DealShopActivities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealShopActivities_On_Conflict>;
};

/** aggregate avg on columns */
export type DealShopActivities_Avg_Fields = {
  __typename?: "DealShopActivities_avg_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealShopActivities" */
export type DealShopActivities_Avg_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealShopActivities". All fields are combined with a logical 'AND'. */
export type DealShopActivities_Bool_Exp = {
  Deals?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<DealShopActivities_Bool_Exp>>;
  _not?: InputMaybe<DealShopActivities_Bool_Exp>;
  _or?: InputMaybe<Array<DealShopActivities_Bool_Exp>>;
  activity?: InputMaybe<DealShopActivityTypes_Enum_Comparison_Exp>;
  activity_type?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
  actual_handover_date?: InputMaybe<Date_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  date_of_closure?: InputMaybe<Date_Comparison_Exp>;
  deal_file_id?: InputMaybe<Bigint_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  deal_type?: InputMaybe<String_Comparison_Exp>;
  estimated_open_date?: InputMaybe<Date_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  forecast_trade_date?: InputMaybe<Date_Comparison_Exp>;
  generated_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  open_date?: InputMaybe<Date_Comparison_Exp>;
  property_address?: InputMaybe<String_Comparison_Exp>;
  property_name?: InputMaybe<String_Comparison_Exp>;
  reason_for_leaving?: InputMaybe<String_Comparison_Exp>;
  rent_free_period?: InputMaybe<Numeric_Comparison_Exp>;
  shop_number?: InputMaybe<String_Comparison_Exp>;
  submitted_user_name?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<String_Comparison_Exp>;
  tenant_name?: InputMaybe<String_Comparison_Exp>;
  trading_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealShopActivities" */
export enum DealShopActivities_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealShopActivitiesPkey = "DealShopActivities_pkey",
}

/** input type for incrementing numeric columns in table "DealShopActivities" */
export type DealShopActivities_Inc_Input = {
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "DealShopActivities" */
export type DealShopActivities_Insert_Input = {
  Deals?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  activity?: InputMaybe<DealShopActivityTypes_Enum>;
  activity_type?: InputMaybe<DealShopActivityTypes_Obj_Rel_Insert_Input>;
  actual_handover_date?: InputMaybe<Scalars["date"]>;
  comments?: InputMaybe<Scalars["String"]>;
  date_of_closure?: InputMaybe<Scalars["date"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deal_type?: InputMaybe<Scalars["String"]>;
  estimated_open_date?: InputMaybe<Scalars["date"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  forecast_trade_date?: InputMaybe<Scalars["date"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  open_date?: InputMaybe<Scalars["date"]>;
  property_address?: InputMaybe<Scalars["String"]>;
  property_name?: InputMaybe<Scalars["String"]>;
  reason_for_leaving?: InputMaybe<Scalars["String"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  shop_number?: InputMaybe<Scalars["String"]>;
  submitted_user_name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["String"]>;
  tenant_name?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealShopActivities_Max_Fields = {
  __typename?: "DealShopActivities_max_fields";
  actual_handover_date?: Maybe<Scalars["date"]>;
  comments?: Maybe<Scalars["String"]>;
  date_of_closure?: Maybe<Scalars["date"]>;
  deal_file_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  deal_type?: Maybe<Scalars["String"]>;
  estimated_open_date?: Maybe<Scalars["date"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  forecast_trade_date?: Maybe<Scalars["date"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  open_date?: Maybe<Scalars["date"]>;
  property_address?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  reason_for_leaving?: Maybe<Scalars["String"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  shop_number?: Maybe<Scalars["String"]>;
  submitted_user_name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["String"]>;
  tenant_name?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealShopActivities" */
export type DealShopActivities_Max_Order_By = {
  actual_handover_date?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  date_of_closure?: InputMaybe<Order_By>;
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_type?: InputMaybe<Order_By>;
  estimated_open_date?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  forecast_trade_date?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  open_date?: InputMaybe<Order_By>;
  property_address?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  reason_for_leaving?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  shop_number?: InputMaybe<Order_By>;
  submitted_user_name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_name?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealShopActivities_Min_Fields = {
  __typename?: "DealShopActivities_min_fields";
  actual_handover_date?: Maybe<Scalars["date"]>;
  comments?: Maybe<Scalars["String"]>;
  date_of_closure?: Maybe<Scalars["date"]>;
  deal_file_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  deal_type?: Maybe<Scalars["String"]>;
  estimated_open_date?: Maybe<Scalars["date"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  forecast_trade_date?: Maybe<Scalars["date"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  open_date?: Maybe<Scalars["date"]>;
  property_address?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  reason_for_leaving?: Maybe<Scalars["String"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
  shop_number?: Maybe<Scalars["String"]>;
  submitted_user_name?: Maybe<Scalars["String"]>;
  tenant_id?: Maybe<Scalars["String"]>;
  tenant_name?: Maybe<Scalars["String"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealShopActivities" */
export type DealShopActivities_Min_Order_By = {
  actual_handover_date?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  date_of_closure?: InputMaybe<Order_By>;
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_type?: InputMaybe<Order_By>;
  estimated_open_date?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  forecast_trade_date?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  open_date?: InputMaybe<Order_By>;
  property_address?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  reason_for_leaving?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  shop_number?: InputMaybe<Order_By>;
  submitted_user_name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_name?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealShopActivities" */
export type DealShopActivities_Mutation_Response = {
  __typename?: "DealShopActivities_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealShopActivities>;
};

/** input type for inserting object relation for remote table "DealShopActivities" */
export type DealShopActivities_Obj_Rel_Insert_Input = {
  data: DealShopActivities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealShopActivities_On_Conflict>;
};

/** on_conflict condition type for table "DealShopActivities" */
export type DealShopActivities_On_Conflict = {
  constraint: DealShopActivities_Constraint;
  update_columns?: Array<DealShopActivities_Update_Column>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

/** Ordering options when selecting data from "DealShopActivities". */
export type DealShopActivities_Order_By = {
  Deals?: InputMaybe<Deals_Order_By>;
  activity?: InputMaybe<Order_By>;
  activity_type?: InputMaybe<DealShopActivityTypes_Order_By>;
  actual_handover_date?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  date_of_closure?: InputMaybe<Order_By>;
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_type?: InputMaybe<Order_By>;
  estimated_open_date?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  forecast_trade_date?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  open_date?: InputMaybe<Order_By>;
  property_address?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  reason_for_leaving?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
  shop_number?: InputMaybe<Order_By>;
  submitted_user_name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  tenant_name?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealShopActivities */
export type DealShopActivities_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealShopActivities" */
export enum DealShopActivities_Select_Column {
  /** column name */
  Activity = "activity",
  /** column name */
  ActualHandoverDate = "actual_handover_date",
  /** column name */
  Comments = "comments",
  /** column name */
  DateOfClosure = "date_of_closure",
  /** column name */
  DealFileId = "deal_file_id",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DealType = "deal_type",
  /** column name */
  EstimatedOpenDate = "estimated_open_date",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  ForecastTradeDate = "forecast_trade_date",
  /** column name */
  GeneratedBy = "generated_by",
  /** column name */
  Id = "id",
  /** column name */
  OpenDate = "open_date",
  /** column name */
  PropertyAddress = "property_address",
  /** column name */
  PropertyName = "property_name",
  /** column name */
  ReasonForLeaving = "reason_for_leaving",
  /** column name */
  RentFreePeriod = "rent_free_period",
  /** column name */
  ShopNumber = "shop_number",
  /** column name */
  SubmittedUserName = "submitted_user_name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  TenantName = "tenant_name",
  /** column name */
  TradingName = "trading_name",
}

/** input type for updating data in table "DealShopActivities" */
export type DealShopActivities_Set_Input = {
  activity?: InputMaybe<DealShopActivityTypes_Enum>;
  actual_handover_date?: InputMaybe<Scalars["date"]>;
  comments?: InputMaybe<Scalars["String"]>;
  date_of_closure?: InputMaybe<Scalars["date"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deal_type?: InputMaybe<Scalars["String"]>;
  estimated_open_date?: InputMaybe<Scalars["date"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  forecast_trade_date?: InputMaybe<Scalars["date"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  open_date?: InputMaybe<Scalars["date"]>;
  property_address?: InputMaybe<Scalars["String"]>;
  property_name?: InputMaybe<Scalars["String"]>;
  reason_for_leaving?: InputMaybe<Scalars["String"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  shop_number?: InputMaybe<Scalars["String"]>;
  submitted_user_name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["String"]>;
  tenant_name?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealShopActivities_Stddev_Fields = {
  __typename?: "DealShopActivities_stddev_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealShopActivities" */
export type DealShopActivities_Stddev_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealShopActivities_Stddev_Pop_Fields = {
  __typename?: "DealShopActivities_stddev_pop_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealShopActivities" */
export type DealShopActivities_Stddev_Pop_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealShopActivities_Stddev_Samp_Fields = {
  __typename?: "DealShopActivities_stddev_samp_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealShopActivities" */
export type DealShopActivities_Stddev_Samp_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealShopActivities" */
export type DealShopActivities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealShopActivities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealShopActivities_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<DealShopActivityTypes_Enum>;
  actual_handover_date?: InputMaybe<Scalars["date"]>;
  comments?: InputMaybe<Scalars["String"]>;
  date_of_closure?: InputMaybe<Scalars["date"]>;
  deal_file_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deal_type?: InputMaybe<Scalars["String"]>;
  estimated_open_date?: InputMaybe<Scalars["date"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  forecast_trade_date?: InputMaybe<Scalars["date"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  open_date?: InputMaybe<Scalars["date"]>;
  property_address?: InputMaybe<Scalars["String"]>;
  property_name?: InputMaybe<Scalars["String"]>;
  reason_for_leaving?: InputMaybe<Scalars["String"]>;
  rent_free_period?: InputMaybe<Scalars["numeric"]>;
  shop_number?: InputMaybe<Scalars["String"]>;
  submitted_user_name?: InputMaybe<Scalars["String"]>;
  tenant_id?: InputMaybe<Scalars["String"]>;
  tenant_name?: InputMaybe<Scalars["String"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealShopActivities_Sum_Fields = {
  __typename?: "DealShopActivities_sum_fields";
  deal_file_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  rent_free_period?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "DealShopActivities" */
export type DealShopActivities_Sum_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** update columns of table "DealShopActivities" */
export enum DealShopActivities_Update_Column {
  /** column name */
  Activity = "activity",
  /** column name */
  ActualHandoverDate = "actual_handover_date",
  /** column name */
  Comments = "comments",
  /** column name */
  DateOfClosure = "date_of_closure",
  /** column name */
  DealFileId = "deal_file_id",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DealType = "deal_type",
  /** column name */
  EstimatedOpenDate = "estimated_open_date",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  ForecastTradeDate = "forecast_trade_date",
  /** column name */
  GeneratedBy = "generated_by",
  /** column name */
  Id = "id",
  /** column name */
  OpenDate = "open_date",
  /** column name */
  PropertyAddress = "property_address",
  /** column name */
  PropertyName = "property_name",
  /** column name */
  ReasonForLeaving = "reason_for_leaving",
  /** column name */
  RentFreePeriod = "rent_free_period",
  /** column name */
  ShopNumber = "shop_number",
  /** column name */
  SubmittedUserName = "submitted_user_name",
  /** column name */
  TenantId = "tenant_id",
  /** column name */
  TenantName = "tenant_name",
  /** column name */
  TradingName = "trading_name",
}

export type DealShopActivities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealShopActivities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealShopActivities_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealShopActivities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealShopActivities_Var_Pop_Fields = {
  __typename?: "DealShopActivities_var_pop_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealShopActivities" */
export type DealShopActivities_Var_Pop_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealShopActivities_Var_Samp_Fields = {
  __typename?: "DealShopActivities_var_samp_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealShopActivities" */
export type DealShopActivities_Var_Samp_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealShopActivities_Variance_Fields = {
  __typename?: "DealShopActivities_variance_fields";
  deal_file_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rent_free_period?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealShopActivities" */
export type DealShopActivities_Variance_Order_By = {
  deal_file_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rent_free_period?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealShopActivityTypes" */
export type DealShopActivityTypes = {
  __typename?: "DealShopActivityTypes";
  /** An object relationship */
  DealShopActivities?: Maybe<DealShopActivities>;
  comment?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** aggregated selection of "DealShopActivityTypes" */
export type DealShopActivityTypes_Aggregate = {
  __typename?: "DealShopActivityTypes_aggregate";
  aggregate?: Maybe<DealShopActivityTypes_Aggregate_Fields>;
  nodes: Array<DealShopActivityTypes>;
};

/** aggregate fields of "DealShopActivityTypes" */
export type DealShopActivityTypes_Aggregate_Fields = {
  __typename?: "DealShopActivityTypes_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<DealShopActivityTypes_Max_Fields>;
  min?: Maybe<DealShopActivityTypes_Min_Fields>;
};

/** aggregate fields of "DealShopActivityTypes" */
export type DealShopActivityTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealShopActivityTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "DealShopActivityTypes". All fields are combined with a logical 'AND'. */
export type DealShopActivityTypes_Bool_Exp = {
  DealShopActivities?: InputMaybe<DealShopActivities_Bool_Exp>;
  _and?: InputMaybe<Array<DealShopActivityTypes_Bool_Exp>>;
  _not?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
  _or?: InputMaybe<Array<DealShopActivityTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealShopActivityTypes" */
export enum DealShopActivityTypes_Constraint {
  /** unique or primary key constraint on columns "name" */
  DealShopActivityTypesPkey = "DealShopActivityTypes_pkey",
}

export enum DealShopActivityTypes_Enum {
  ShopClose = "ShopClose",
  ShopHandover = "ShopHandover",
  ShopOpen = "ShopOpen",
}

/** Boolean expression to compare columns of type "DealShopActivityTypes_enum". All fields are combined with logical 'AND'. */
export type DealShopActivityTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DealShopActivityTypes_Enum>;
  _in?: InputMaybe<Array<DealShopActivityTypes_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<DealShopActivityTypes_Enum>;
  _nin?: InputMaybe<Array<DealShopActivityTypes_Enum>>;
};

/** input type for inserting data into table "DealShopActivityTypes" */
export type DealShopActivityTypes_Insert_Input = {
  DealShopActivities?: InputMaybe<DealShopActivities_Obj_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealShopActivityTypes_Max_Fields = {
  __typename?: "DealShopActivityTypes_max_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealShopActivityTypes_Min_Fields = {
  __typename?: "DealShopActivityTypes_min_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealShopActivityTypes" */
export type DealShopActivityTypes_Mutation_Response = {
  __typename?: "DealShopActivityTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealShopActivityTypes>;
};

/** input type for inserting object relation for remote table "DealShopActivityTypes" */
export type DealShopActivityTypes_Obj_Rel_Insert_Input = {
  data: DealShopActivityTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealShopActivityTypes_On_Conflict>;
};

/** on_conflict condition type for table "DealShopActivityTypes" */
export type DealShopActivityTypes_On_Conflict = {
  constraint: DealShopActivityTypes_Constraint;
  update_columns?: Array<DealShopActivityTypes_Update_Column>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "DealShopActivityTypes". */
export type DealShopActivityTypes_Order_By = {
  DealShopActivities?: InputMaybe<DealShopActivities_Order_By>;
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealShopActivityTypes */
export type DealShopActivityTypes_Pk_Columns_Input = {
  name: Scalars["String"];
};

/** select columns of table "DealShopActivityTypes" */
export enum DealShopActivityTypes_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "DealShopActivityTypes" */
export type DealShopActivityTypes_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "DealShopActivityTypes" */
export type DealShopActivityTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealShopActivityTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealShopActivityTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "DealShopActivityTypes" */
export enum DealShopActivityTypes_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

export type DealShopActivityTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealShopActivityTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealShopActivityTypes_Bool_Exp;
};

/** columns and relationships of "DealSpecialConditions" */
export type DealSpecialConditions = {
  __typename?: "DealSpecialConditions";
  /** An object relationship */
  DealDatum: DealData;
  /** An object relationship */
  TemplateSpecialCondition?: Maybe<TemplateSpecialConditions>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id: Scalars["bigint"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  laf_description?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealSpecialConditions" */
export type DealSpecialConditions_Aggregate = {
  __typename?: "DealSpecialConditions_aggregate";
  aggregate?: Maybe<DealSpecialConditions_Aggregate_Fields>;
  nodes: Array<DealSpecialConditions>;
};

export type DealSpecialConditions_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealSpecialConditions_Aggregate_Bool_Exp_Count>;
};

export type DealSpecialConditions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealSpecialConditions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealSpecialConditions" */
export type DealSpecialConditions_Aggregate_Fields = {
  __typename?: "DealSpecialConditions_aggregate_fields";
  avg?: Maybe<DealSpecialConditions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealSpecialConditions_Max_Fields>;
  min?: Maybe<DealSpecialConditions_Min_Fields>;
  stddev?: Maybe<DealSpecialConditions_Stddev_Fields>;
  stddev_pop?: Maybe<DealSpecialConditions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealSpecialConditions_Stddev_Samp_Fields>;
  sum?: Maybe<DealSpecialConditions_Sum_Fields>;
  var_pop?: Maybe<DealSpecialConditions_Var_Pop_Fields>;
  var_samp?: Maybe<DealSpecialConditions_Var_Samp_Fields>;
  variance?: Maybe<DealSpecialConditions_Variance_Fields>;
};

/** aggregate fields of "DealSpecialConditions" */
export type DealSpecialConditions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealSpecialConditions" */
export type DealSpecialConditions_Aggregate_Order_By = {
  avg?: InputMaybe<DealSpecialConditions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealSpecialConditions_Max_Order_By>;
  min?: InputMaybe<DealSpecialConditions_Min_Order_By>;
  stddev?: InputMaybe<DealSpecialConditions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealSpecialConditions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealSpecialConditions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealSpecialConditions_Sum_Order_By>;
  var_pop?: InputMaybe<DealSpecialConditions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealSpecialConditions_Var_Samp_Order_By>;
  variance?: InputMaybe<DealSpecialConditions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealSpecialConditions" */
export type DealSpecialConditions_Arr_Rel_Insert_Input = {
  data: Array<DealSpecialConditions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealSpecialConditions_On_Conflict>;
};

/** aggregate avg on columns */
export type DealSpecialConditions_Avg_Fields = {
  __typename?: "DealSpecialConditions_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealSpecialConditions". All fields are combined with a logical 'AND'. */
export type DealSpecialConditions_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  TemplateSpecialCondition?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
  _and?: InputMaybe<Array<DealSpecialConditions_Bool_Exp>>;
  _not?: InputMaybe<DealSpecialConditions_Bool_Exp>;
  _or?: InputMaybe<Array<DealSpecialConditions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  laf_description?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealSpecialConditions" */
export enum DealSpecialConditions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealSpecialConditionsPkey = "DealSpecialConditions_pkey",
}

/** input type for incrementing numeric columns in table "DealSpecialConditions" */
export type DealSpecialConditions_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealSpecialConditions" */
export type DealSpecialConditions_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  TemplateSpecialCondition?: InputMaybe<TemplateSpecialConditions_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealSpecialConditions_Max_Fields = {
  __typename?: "DealSpecialConditions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_description?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealSpecialConditions_Min_Fields = {
  __typename?: "DealSpecialConditions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_description?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealSpecialConditions" */
export type DealSpecialConditions_Mutation_Response = {
  __typename?: "DealSpecialConditions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealSpecialConditions>;
};

/** on_conflict condition type for table "DealSpecialConditions" */
export type DealSpecialConditions_On_Conflict = {
  constraint: DealSpecialConditions_Constraint;
  update_columns?: Array<DealSpecialConditions_Update_Column>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

/** Ordering options when selecting data from "DealSpecialConditions". */
export type DealSpecialConditions_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  TemplateSpecialCondition?: InputMaybe<TemplateSpecialConditions_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealSpecialConditions */
export type DealSpecialConditions_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealSpecialConditions" */
export enum DealSpecialConditions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafDescription = "laf_description",
  /** column name */
  TemplateId = "template_id",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "DealSpecialConditions" */
export type DealSpecialConditions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealSpecialConditions_Stddev_Fields = {
  __typename?: "DealSpecialConditions_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealSpecialConditions_Stddev_Pop_Fields = {
  __typename?: "DealSpecialConditions_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealSpecialConditions_Stddev_Samp_Fields = {
  __typename?: "DealSpecialConditions_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealSpecialConditions" */
export type DealSpecialConditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealSpecialConditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealSpecialConditions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealSpecialConditions_Sum_Fields = {
  __typename?: "DealSpecialConditions_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  template_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealSpecialConditions" */
export enum DealSpecialConditions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafDescription = "laf_description",
  /** column name */
  TemplateId = "template_id",
  /** column name */
  Title = "title",
}

export type DealSpecialConditions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealSpecialConditions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealSpecialConditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealSpecialConditions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealSpecialConditions_Var_Pop_Fields = {
  __typename?: "DealSpecialConditions_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealSpecialConditions_Var_Samp_Fields = {
  __typename?: "DealSpecialConditions_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealSpecialConditions_Variance_Fields = {
  __typename?: "DealSpecialConditions_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealSpecialConditions" */
export type DealSpecialConditions_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealStatus" */
export type DealStatus = {
  __typename?: "DealStatus";
  comment: Scalars["String"];
  name: Scalars["String"];
};

/** aggregated selection of "DealStatus" */
export type DealStatus_Aggregate = {
  __typename?: "DealStatus_aggregate";
  aggregate?: Maybe<DealStatus_Aggregate_Fields>;
  nodes: Array<DealStatus>;
};

/** aggregate fields of "DealStatus" */
export type DealStatus_Aggregate_Fields = {
  __typename?: "DealStatus_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<DealStatus_Max_Fields>;
  min?: Maybe<DealStatus_Min_Fields>;
};

/** aggregate fields of "DealStatus" */
export type DealStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "DealStatus". All fields are combined with a logical 'AND'. */
export type DealStatus_Bool_Exp = {
  _and?: InputMaybe<Array<DealStatus_Bool_Exp>>;
  _not?: InputMaybe<DealStatus_Bool_Exp>;
  _or?: InputMaybe<Array<DealStatus_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealStatus" */
export enum DealStatus_Constraint {
  /** unique or primary key constraint on columns "name" */
  DealStatusPkey = "DealStatus_pkey",
}

export enum DealStatus_Enum {
  Approved = "Approved",
  Cancelled = "Cancelled",
  /** Deal is completed */
  Completed = "Completed",
  /** Deleting deals */
  Deleted = "Deleted",
  InProgress = "InProgress",
  OnHold = "OnHold",
  /** Usually rejected by LAF or ReLAF */
  Rejected = "Rejected",
  /** Deal was previously approved but required to be reLAFed */
  RelafReview = "RelafReview",
  Review = "Review",
  /** Status for ReLaf */
  Superseded = "Superseded",
}

/** Boolean expression to compare columns of type "DealStatus_enum". All fields are combined with logical 'AND'. */
export type DealStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DealStatus_Enum>;
  _in?: InputMaybe<Array<DealStatus_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<DealStatus_Enum>;
  _nin?: InputMaybe<Array<DealStatus_Enum>>;
};

/** input type for inserting data into table "DealStatus" */
export type DealStatus_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealStatus_Max_Fields = {
  __typename?: "DealStatus_max_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealStatus_Min_Fields = {
  __typename?: "DealStatus_min_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealStatus" */
export type DealStatus_Mutation_Response = {
  __typename?: "DealStatus_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealStatus>;
};

/** input type for inserting object relation for remote table "DealStatus" */
export type DealStatus_Obj_Rel_Insert_Input = {
  data: DealStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealStatus_On_Conflict>;
};

/** on_conflict condition type for table "DealStatus" */
export type DealStatus_On_Conflict = {
  constraint: DealStatus_Constraint;
  update_columns?: Array<DealStatus_Update_Column>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "DealStatus". */
export type DealStatus_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealStatus */
export type DealStatus_Pk_Columns_Input = {
  name: Scalars["String"];
};

/** select columns of table "DealStatus" */
export enum DealStatus_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "DealStatus" */
export type DealStatus_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "DealStatus" */
export type DealStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealStatus_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "DealStatus" */
export enum DealStatus_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

export type DealStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealStatus_Bool_Exp;
};

/** columns and relationships of "DealTypes" */
export type DealTypes = {
  __typename?: "DealTypes";
  /** An array relationship */
  Deals: Array<Deals>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  parent_id: Scalars["bigint"];
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "DealTypes" */
export type DealTypesDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "DealTypes" */
export type DealTypesDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** aggregated selection of "DealTypes" */
export type DealTypes_Aggregate = {
  __typename?: "DealTypes_aggregate";
  aggregate?: Maybe<DealTypes_Aggregate_Fields>;
  nodes: Array<DealTypes>;
};

/** aggregate fields of "DealTypes" */
export type DealTypes_Aggregate_Fields = {
  __typename?: "DealTypes_aggregate_fields";
  avg?: Maybe<DealTypes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealTypes_Max_Fields>;
  min?: Maybe<DealTypes_Min_Fields>;
  stddev?: Maybe<DealTypes_Stddev_Fields>;
  stddev_pop?: Maybe<DealTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealTypes_Stddev_Samp_Fields>;
  sum?: Maybe<DealTypes_Sum_Fields>;
  var_pop?: Maybe<DealTypes_Var_Pop_Fields>;
  var_samp?: Maybe<DealTypes_Var_Samp_Fields>;
  variance?: Maybe<DealTypes_Variance_Fields>;
};

/** aggregate fields of "DealTypes" */
export type DealTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type DealTypes_Avg_Fields = {
  __typename?: "DealTypes_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "DealTypes". All fields are combined with a logical 'AND'. */
export type DealTypes_Bool_Exp = {
  Deals?: InputMaybe<Deals_Bool_Exp>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<DealTypes_Bool_Exp>>;
  _not?: InputMaybe<DealTypes_Bool_Exp>;
  _or?: InputMaybe<Array<DealTypes_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parent_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealTypes" */
export enum DealTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealTypesPkey = "DealTypes_pkey",
}

/** input type for incrementing numeric columns in table "DealTypes" */
export type DealTypes_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  parent_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealTypes" */
export type DealTypes_Insert_Input = {
  Deals?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  parent_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealTypes_Max_Fields = {
  __typename?: "DealTypes_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  parent_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type DealTypes_Min_Fields = {
  __typename?: "DealTypes_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  parent_id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "DealTypes" */
export type DealTypes_Mutation_Response = {
  __typename?: "DealTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealTypes>;
};

/** input type for inserting object relation for remote table "DealTypes" */
export type DealTypes_Obj_Rel_Insert_Input = {
  data: DealTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DealTypes_On_Conflict>;
};

/** on_conflict condition type for table "DealTypes" */
export type DealTypes_On_Conflict = {
  constraint: DealTypes_Constraint;
  update_columns?: Array<DealTypes_Update_Column>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "DealTypes". */
export type DealTypes_Order_By = {
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealTypes */
export type DealTypes_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealTypes" */
export enum DealTypes_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "DealTypes" */
export type DealTypes_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  parent_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealTypes_Stddev_Fields = {
  __typename?: "DealTypes_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type DealTypes_Stddev_Pop_Fields = {
  __typename?: "DealTypes_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type DealTypes_Stddev_Samp_Fields = {
  __typename?: "DealTypes_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "DealTypes" */
export type DealTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealTypes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  parent_id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealTypes_Sum_Fields = {
  __typename?: "DealTypes_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  parent_id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "DealTypes" */
export enum DealTypes_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  Title = "title",
}

export type DealTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealTypes_Var_Pop_Fields = {
  __typename?: "DealTypes_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type DealTypes_Var_Samp_Fields = {
  __typename?: "DealTypes_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type DealTypes_Variance_Fields = {
  __typename?: "DealTypes_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Deals" */
export type Deals = {
  __typename?: "Deals";
  /** An array relationship */
  ActionAllocations: Array<ActionAllocations>;
  /** An aggregate relationship */
  ActionAllocations_aggregate: ActionAllocations_Aggregate;
  /** An array relationship */
  ActivityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  ActivityLogs_aggregate: ActivityLog_Aggregate;
  /** An array relationship */
  Agreements: Array<Agreements>;
  /** An aggregate relationship */
  Agreements_aggregate: Agreements_Aggregate;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  DealData: Array<DealData>;
  /** An aggregate relationship */
  DealData_aggregate: DealData_Aggregate;
  /** An array relationship */
  DealFiles: Array<DealFiles>;
  /** An aggregate relationship */
  DealFiles_aggregate: DealFiles_Aggregate;
  /** An array relationship */
  DealLinks: Array<DealLinks>;
  /** An aggregate relationship */
  DealLinks_aggregate: DealLinks_Aggregate;
  /** An array relationship */
  DealPhases: Array<DealPhases>;
  /** An aggregate relationship */
  DealPhases_aggregate: DealPhases_Aggregate;
  /** An array relationship */
  DealShopActivities: Array<DealShopActivities>;
  /** An aggregate relationship */
  DealShopActivities_aggregate: DealShopActivities_Aggregate;
  /** An object relationship */
  DealStatus: DealStatus;
  /** An object relationship */
  DealType: DealTypes;
  /** An array relationship */
  DealsCompanies: Array<DealsCompanies>;
  /** An aggregate relationship */
  DealsCompanies_aggregate: DealsCompanies_Aggregate;
  /** An array relationship */
  DealsContacts: Array<DealsContacts>;
  /** An aggregate relationship */
  DealsContacts_aggregate: DealsContacts_Aggregate;
  /** An array relationship */
  DocumentRequests: Array<DocumentRequests>;
  /** An aggregate relationship */
  DocumentRequests_aggregate: DocumentRequests_Aggregate;
  /** An array relationship */
  GeneratedDocuments: Array<GeneratedDocuments>;
  /** An aggregate relationship */
  GeneratedDocuments_aggregate: GeneratedDocuments_Aggregate;
  /** An object relationship */
  LA_Assignee?: Maybe<Users>;
  /** An array relationship */
  Leases: Array<Leases>;
  /** An aggregate relationship */
  Leases_aggregate: Leases_Aggregate;
  /** An array relationship */
  Offers: Array<Offers>;
  /** An aggregate relationship */
  Offers_aggregate: Offers_Aggregate;
  /** An object relationship */
  Owner?: Maybe<Users>;
  /** An array relationship */
  Proposals: Array<Proposals>;
  /** An aggregate relationship */
  Proposals_aggregate: Proposals_Aggregate;
  /** An array relationship */
  Registrations: Array<Registrations>;
  /** An aggregate relationship */
  Registrations_aggregate: Registrations_Aggregate;
  /** An object relationship */
  TD_Assignee?: Maybe<Users>;
  /** An object relationship */
  TenantType?: Maybe<TenantTypes>;
  /** An array relationship */
  Terms: Array<Terms>;
  /** An aggregate relationship */
  Terms_aggregate: Terms_Aggregate;
  /** An object relationship */
  User: Users;
  /** An array relationship */
  YardiAmendments_Deals: Array<YardiAmendments_Deals>;
  /** An aggregate relationship */
  YardiAmendments_Deals_aggregate: YardiAmendments_Deals_Aggregate;
  /** An object relationship */
  YardiCustomer?: Maybe<YardiCustomers>;
  /** An array relationship */
  YardiCustomers_Deals: Array<YardiCustomers_Deals>;
  /** An aggregate relationship */
  YardiCustomers_Deals_aggregate: YardiCustomers_Deals_Aggregate;
  /** An array relationship */
  YardiDealSyncStatuses: Array<YardiDealSyncStatuses>;
  /** An aggregate relationship */
  YardiDealSyncStatuses_aggregate: YardiDealSyncStatuses_Aggregate;
  /** An object relationship */
  YardiTenant?: Maybe<YardiTenants>;
  /** An array relationship */
  YardiTenants_Deals: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_Deals_aggregate: YardiTenants_Deals_Aggregate;
  cancel_comment?: Maybe<Scalars["String"]>;
  capital_incurred?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by: Scalars["uuid"];
  customer_code?: Maybe<Scalars["String"]>;
  deal_made_from_active_lease: Scalars["Boolean"];
  deal_type_id: Scalars["bigint"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  fallover_date?: Maybe<Scalars["date"]>;
  id: Scalars["bigint"];
  la_assignee?: Maybe<Scalars["uuid"]>;
  la_assignee_description?: Maybe<Scalars["String"]>;
  laf_version: Scalars["Int"];
  last_modified_by?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  owner_description?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<DealRiskLevels_Enum>;
  risk_set_manually: Scalars["Boolean"];
  status: DealStatus_Enum;
  td_assignee?: Maybe<Scalars["uuid"]>;
  td_assignee_description?: Maybe<Scalars["String"]>;
  tenant_code?: Maybe<Scalars["String"]>;
  tenant_type_id?: Maybe<Scalars["Int"]>;
  trading_name?: Maybe<Scalars["String"]>;
  /** An object relationship */
  userByLastModifiedBy?: Maybe<Users>;
};

/** columns and relationships of "Deals" */
export type DealsActionAllocationsArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsActionAllocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsAgreementsArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsAgreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealDataArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealFilesArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealLinksArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealPhasesArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealShopActivitiesArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealShopActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealsContactsArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDealsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDocumentRequestsArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsDocumentRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsGeneratedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsGeneratedDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsLeasesArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsLeases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsOffersArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsOffers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsProposalsArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsProposals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsTermsArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsTerms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiAmendments_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiAmendments_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiCustomers_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiCustomers_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiDealSyncStatusesArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiDealSyncStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiTenants_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** columns and relationships of "Deals" */
export type DealsYardiTenants_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** columns and relationships of "DealsCompanies" */
export type DealsCompanies = {
  __typename?: "DealsCompanies";
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Contact?: Maybe<Contacts>;
  /** An object relationship */
  Deal?: Maybe<Deals>;
  company_id?: Maybe<Scalars["bigint"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "DealsCompanies" */
export type DealsCompanies_Aggregate = {
  __typename?: "DealsCompanies_aggregate";
  aggregate?: Maybe<DealsCompanies_Aggregate_Fields>;
  nodes: Array<DealsCompanies>;
};

export type DealsCompanies_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealsCompanies_Aggregate_Bool_Exp_Count>;
};

export type DealsCompanies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealsCompanies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealsCompanies" */
export type DealsCompanies_Aggregate_Fields = {
  __typename?: "DealsCompanies_aggregate_fields";
  avg?: Maybe<DealsCompanies_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealsCompanies_Max_Fields>;
  min?: Maybe<DealsCompanies_Min_Fields>;
  stddev?: Maybe<DealsCompanies_Stddev_Fields>;
  stddev_pop?: Maybe<DealsCompanies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealsCompanies_Stddev_Samp_Fields>;
  sum?: Maybe<DealsCompanies_Sum_Fields>;
  var_pop?: Maybe<DealsCompanies_Var_Pop_Fields>;
  var_samp?: Maybe<DealsCompanies_Var_Samp_Fields>;
  variance?: Maybe<DealsCompanies_Variance_Fields>;
};

/** aggregate fields of "DealsCompanies" */
export type DealsCompanies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealsCompanies" */
export type DealsCompanies_Aggregate_Order_By = {
  avg?: InputMaybe<DealsCompanies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealsCompanies_Max_Order_By>;
  min?: InputMaybe<DealsCompanies_Min_Order_By>;
  stddev?: InputMaybe<DealsCompanies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealsCompanies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealsCompanies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealsCompanies_Sum_Order_By>;
  var_pop?: InputMaybe<DealsCompanies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealsCompanies_Var_Samp_Order_By>;
  variance?: InputMaybe<DealsCompanies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealsCompanies" */
export type DealsCompanies_Arr_Rel_Insert_Input = {
  data: Array<DealsCompanies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealsCompanies_On_Conflict>;
};

/** aggregate avg on columns */
export type DealsCompanies_Avg_Fields = {
  __typename?: "DealsCompanies_avg_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealsCompanies" */
export type DealsCompanies_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealsCompanies". All fields are combined with a logical 'AND'. */
export type DealsCompanies_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Contact?: InputMaybe<Contacts_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<DealsCompanies_Bool_Exp>>;
  _not?: InputMaybe<DealsCompanies_Bool_Exp>;
  _or?: InputMaybe<Array<DealsCompanies_Bool_Exp>>;
  company_id?: InputMaybe<Bigint_Comparison_Exp>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealsCompanies" */
export enum DealsCompanies_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealsCompaniesPkey = "DealsCompanies_pkey",
  /** unique or primary key constraint on columns "deal_id", "company_id" */
  DealscompaniesDealIdCompanyUnique = "dealscompanies_deal_id_company_unique",
}

/** input type for incrementing numeric columns in table "DealsCompanies" */
export type DealsCompanies_Inc_Input = {
  company_id?: InputMaybe<Scalars["bigint"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealsCompanies" */
export type DealsCompanies_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars["bigint"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type DealsCompanies_Max_Fields = {
  __typename?: "DealsCompanies_max_fields";
  company_id?: Maybe<Scalars["bigint"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "DealsCompanies" */
export type DealsCompanies_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealsCompanies_Min_Fields = {
  __typename?: "DealsCompanies_min_fields";
  company_id?: Maybe<Scalars["bigint"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "DealsCompanies" */
export type DealsCompanies_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealsCompanies" */
export type DealsCompanies_Mutation_Response = {
  __typename?: "DealsCompanies_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealsCompanies>;
};

/** on_conflict condition type for table "DealsCompanies" */
export type DealsCompanies_On_Conflict = {
  constraint: DealsCompanies_Constraint;
  update_columns?: Array<DealsCompanies_Update_Column>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

/** Ordering options when selecting data from "DealsCompanies". */
export type DealsCompanies_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Contact?: InputMaybe<Contacts_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealsCompanies */
export type DealsCompanies_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealsCompanies" */
export enum DealsCompanies_Select_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "DealsCompanies" */
export type DealsCompanies_Set_Input = {
  company_id?: InputMaybe<Scalars["bigint"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type DealsCompanies_Stddev_Fields = {
  __typename?: "DealsCompanies_stddev_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealsCompanies" */
export type DealsCompanies_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealsCompanies_Stddev_Pop_Fields = {
  __typename?: "DealsCompanies_stddev_pop_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealsCompanies" */
export type DealsCompanies_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealsCompanies_Stddev_Samp_Fields = {
  __typename?: "DealsCompanies_stddev_samp_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealsCompanies" */
export type DealsCompanies_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealsCompanies" */
export type DealsCompanies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealsCompanies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealsCompanies_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars["bigint"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type DealsCompanies_Sum_Fields = {
  __typename?: "DealsCompanies_sum_fields";
  company_id?: Maybe<Scalars["bigint"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealsCompanies" */
export type DealsCompanies_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealsCompanies" */
export enum DealsCompanies_Update_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type DealsCompanies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealsCompanies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealsCompanies_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealsCompanies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealsCompanies_Var_Pop_Fields = {
  __typename?: "DealsCompanies_var_pop_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealsCompanies" */
export type DealsCompanies_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealsCompanies_Var_Samp_Fields = {
  __typename?: "DealsCompanies_var_samp_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealsCompanies" */
export type DealsCompanies_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealsCompanies_Variance_Fields = {
  __typename?: "DealsCompanies_variance_fields";
  company_id?: Maybe<Scalars["Float"]>;
  contact_id?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealsCompanies" */
export type DealsCompanies_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealsContacts" */
export type DealsContacts = {
  __typename?: "DealsContacts";
  /** An object relationship */
  Contact?: Maybe<Contacts>;
  /** An object relationship */
  ContactType?: Maybe<ContactTypes>;
  /** An object relationship */
  Deal?: Maybe<Deals>;
  contact_id?: Maybe<Scalars["bigint"]>;
  contact_type: Scalars["Int"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "DealsContacts" */
export type DealsContacts_Aggregate = {
  __typename?: "DealsContacts_aggregate";
  aggregate?: Maybe<DealsContacts_Aggregate_Fields>;
  nodes: Array<DealsContacts>;
};

export type DealsContacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<DealsContacts_Aggregate_Bool_Exp_Count>;
};

export type DealsContacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealsContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealsContacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealsContacts" */
export type DealsContacts_Aggregate_Fields = {
  __typename?: "DealsContacts_aggregate_fields";
  avg?: Maybe<DealsContacts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealsContacts_Max_Fields>;
  min?: Maybe<DealsContacts_Min_Fields>;
  stddev?: Maybe<DealsContacts_Stddev_Fields>;
  stddev_pop?: Maybe<DealsContacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealsContacts_Stddev_Samp_Fields>;
  sum?: Maybe<DealsContacts_Sum_Fields>;
  var_pop?: Maybe<DealsContacts_Var_Pop_Fields>;
  var_samp?: Maybe<DealsContacts_Var_Samp_Fields>;
  variance?: Maybe<DealsContacts_Variance_Fields>;
};

/** aggregate fields of "DealsContacts" */
export type DealsContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealsContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealsContacts" */
export type DealsContacts_Aggregate_Order_By = {
  avg?: InputMaybe<DealsContacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealsContacts_Max_Order_By>;
  min?: InputMaybe<DealsContacts_Min_Order_By>;
  stddev?: InputMaybe<DealsContacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealsContacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealsContacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealsContacts_Sum_Order_By>;
  var_pop?: InputMaybe<DealsContacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealsContacts_Var_Samp_Order_By>;
  variance?: InputMaybe<DealsContacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealsContacts" */
export type DealsContacts_Arr_Rel_Insert_Input = {
  data: Array<DealsContacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealsContacts_On_Conflict>;
};

/** aggregate avg on columns */
export type DealsContacts_Avg_Fields = {
  __typename?: "DealsContacts_avg_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealsContacts" */
export type DealsContacts_Avg_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealsContacts". All fields are combined with a logical 'AND'. */
export type DealsContacts_Bool_Exp = {
  Contact?: InputMaybe<Contacts_Bool_Exp>;
  ContactType?: InputMaybe<ContactTypes_Bool_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<DealsContacts_Bool_Exp>>;
  _not?: InputMaybe<DealsContacts_Bool_Exp>;
  _or?: InputMaybe<Array<DealsContacts_Bool_Exp>>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  contact_type?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealsContacts" */
export enum DealsContacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealsContactsPkey = "DealsContacts_pkey",
  /** unique or primary key constraint on columns "deal_id", "contact_id" */
  DealidContactidUnique = "dealid_contactid_unique",
}

/** input type for incrementing numeric columns in table "DealsContacts" */
export type DealsContacts_Inc_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  contact_type?: InputMaybe<Scalars["Int"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealsContacts" */
export type DealsContacts_Insert_Input = {
  Contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  ContactType?: InputMaybe<ContactTypes_Obj_Rel_Insert_Input>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  contact_type?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type DealsContacts_Max_Fields = {
  __typename?: "DealsContacts_max_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  contact_type?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "DealsContacts" */
export type DealsContacts_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealsContacts_Min_Fields = {
  __typename?: "DealsContacts_min_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  contact_type?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "DealsContacts" */
export type DealsContacts_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealsContacts" */
export type DealsContacts_Mutation_Response = {
  __typename?: "DealsContacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealsContacts>;
};

/** on_conflict condition type for table "DealsContacts" */
export type DealsContacts_On_Conflict = {
  constraint: DealsContacts_Constraint;
  update_columns?: Array<DealsContacts_Update_Column>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "DealsContacts". */
export type DealsContacts_Order_By = {
  Contact?: InputMaybe<Contacts_Order_By>;
  ContactType?: InputMaybe<ContactTypes_Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealsContacts */
export type DealsContacts_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealsContacts" */
export enum DealsContacts_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  ContactType = "contact_type",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Tag = "tag",
}

/** input type for updating data in table "DealsContacts" */
export type DealsContacts_Set_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  contact_type?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type DealsContacts_Stddev_Fields = {
  __typename?: "DealsContacts_stddev_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealsContacts" */
export type DealsContacts_Stddev_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealsContacts_Stddev_Pop_Fields = {
  __typename?: "DealsContacts_stddev_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealsContacts" */
export type DealsContacts_Stddev_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealsContacts_Stddev_Samp_Fields = {
  __typename?: "DealsContacts_stddev_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealsContacts" */
export type DealsContacts_Stddev_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealsContacts" */
export type DealsContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealsContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealsContacts_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  contact_type?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type DealsContacts_Sum_Fields = {
  __typename?: "DealsContacts_sum_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  contact_type?: Maybe<Scalars["Int"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealsContacts" */
export type DealsContacts_Sum_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DealsContacts" */
export enum DealsContacts_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  ContactType = "contact_type",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Tag = "tag",
}

export type DealsContacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealsContacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealsContacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealsContacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealsContacts_Var_Pop_Fields = {
  __typename?: "DealsContacts_var_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealsContacts" */
export type DealsContacts_Var_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealsContacts_Var_Samp_Fields = {
  __typename?: "DealsContacts_var_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealsContacts" */
export type DealsContacts_Var_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealsContacts_Variance_Fields = {
  __typename?: "DealsContacts_variance_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  contact_type?: Maybe<Scalars["Float"]>;
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealsContacts" */
export type DealsContacts_Variance_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DealsUnits" */
export type DealsUnits = {
  __typename?: "DealsUnits";
  /** An object relationship */
  DealDatum?: Maybe<DealData>;
  /** An object relationship */
  SavedYardiUnitProfile?: Maybe<YardiUnitProfiles>;
  /** An object relationship */
  Unit?: Maybe<Units>;
  /** An object relationship */
  VirtualUnit?: Maybe<VirtualUnits>;
  /** An object relationship */
  YardiUnit?: Maybe<YardiUnits>;
  area_survey_required?: Maybe<Scalars["Boolean"]>;
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  laf_yardi_unit_profile_id?: Maybe<Scalars["String"]>;
  unit_key?: Maybe<Scalars["String"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "DealsUnits" */
export type DealsUnits_Aggregate = {
  __typename?: "DealsUnits_aggregate";
  aggregate?: Maybe<DealsUnits_Aggregate_Fields>;
  nodes: Array<DealsUnits>;
};

export type DealsUnits_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DealsUnits_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DealsUnits_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DealsUnits_Aggregate_Bool_Exp_Count>;
};

export type DealsUnits_Aggregate_Bool_Exp_Bool_And = {
  arguments: DealsUnits_Select_Column_DealsUnits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealsUnits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealsUnits_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DealsUnits_Select_Column_DealsUnits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealsUnits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DealsUnits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DealsUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DealsUnits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DealsUnits" */
export type DealsUnits_Aggregate_Fields = {
  __typename?: "DealsUnits_aggregate_fields";
  avg?: Maybe<DealsUnits_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DealsUnits_Max_Fields>;
  min?: Maybe<DealsUnits_Min_Fields>;
  stddev?: Maybe<DealsUnits_Stddev_Fields>;
  stddev_pop?: Maybe<DealsUnits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealsUnits_Stddev_Samp_Fields>;
  sum?: Maybe<DealsUnits_Sum_Fields>;
  var_pop?: Maybe<DealsUnits_Var_Pop_Fields>;
  var_samp?: Maybe<DealsUnits_Var_Samp_Fields>;
  variance?: Maybe<DealsUnits_Variance_Fields>;
};

/** aggregate fields of "DealsUnits" */
export type DealsUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DealsUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DealsUnits" */
export type DealsUnits_Aggregate_Order_By = {
  avg?: InputMaybe<DealsUnits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DealsUnits_Max_Order_By>;
  min?: InputMaybe<DealsUnits_Min_Order_By>;
  stddev?: InputMaybe<DealsUnits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DealsUnits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DealsUnits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DealsUnits_Sum_Order_By>;
  var_pop?: InputMaybe<DealsUnits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DealsUnits_Var_Samp_Order_By>;
  variance?: InputMaybe<DealsUnits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DealsUnits" */
export type DealsUnits_Arr_Rel_Insert_Input = {
  data: Array<DealsUnits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DealsUnits_On_Conflict>;
};

/** aggregate avg on columns */
export type DealsUnits_Avg_Fields = {
  __typename?: "DealsUnits_avg_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DealsUnits" */
export type DealsUnits_Avg_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DealsUnits". All fields are combined with a logical 'AND'. */
export type DealsUnits_Bool_Exp = {
  DealDatum?: InputMaybe<DealData_Bool_Exp>;
  SavedYardiUnitProfile?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  Unit?: InputMaybe<Units_Bool_Exp>;
  VirtualUnit?: InputMaybe<VirtualUnits_Bool_Exp>;
  YardiUnit?: InputMaybe<YardiUnits_Bool_Exp>;
  _and?: InputMaybe<Array<DealsUnits_Bool_Exp>>;
  _not?: InputMaybe<DealsUnits_Bool_Exp>;
  _or?: InputMaybe<Array<DealsUnits_Bool_Exp>>;
  area_survey_required?: InputMaybe<Boolean_Comparison_Exp>;
  deal_data_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  laf_yardi_unit_profile_id?: InputMaybe<String_Comparison_Exp>;
  unit_key?: InputMaybe<String_Comparison_Exp>;
  virtual_unit_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "DealsUnits" */
export enum DealsUnits_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealsUnitsPkey = "DealsUnits_pkey",
}

/** input type for incrementing numeric columns in table "DealsUnits" */
export type DealsUnits_Inc_Input = {
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DealsUnits" */
export type DealsUnits_Insert_Input = {
  DealDatum?: InputMaybe<DealData_Obj_Rel_Insert_Input>;
  SavedYardiUnitProfile?: InputMaybe<YardiUnitProfiles_Obj_Rel_Insert_Input>;
  Unit?: InputMaybe<Units_Obj_Rel_Insert_Input>;
  VirtualUnit?: InputMaybe<VirtualUnits_Obj_Rel_Insert_Input>;
  YardiUnit?: InputMaybe<YardiUnits_Obj_Rel_Insert_Input>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_yardi_unit_profile_id?: InputMaybe<Scalars["String"]>;
  unit_key?: InputMaybe<Scalars["String"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type DealsUnits_Max_Fields = {
  __typename?: "DealsUnits_max_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_yardi_unit_profile_id?: Maybe<Scalars["String"]>;
  unit_key?: Maybe<Scalars["String"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "DealsUnits" */
export type DealsUnits_Max_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_yardi_unit_profile_id?: InputMaybe<Order_By>;
  unit_key?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DealsUnits_Min_Fields = {
  __typename?: "DealsUnits_min_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_yardi_unit_profile_id?: Maybe<Scalars["String"]>;
  unit_key?: Maybe<Scalars["String"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "DealsUnits" */
export type DealsUnits_Min_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_yardi_unit_profile_id?: InputMaybe<Order_By>;
  unit_key?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DealsUnits" */
export type DealsUnits_Mutation_Response = {
  __typename?: "DealsUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DealsUnits>;
};

/** on_conflict condition type for table "DealsUnits" */
export type DealsUnits_On_Conflict = {
  constraint: DealsUnits_Constraint;
  update_columns?: Array<DealsUnits_Update_Column>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "DealsUnits". */
export type DealsUnits_Order_By = {
  DealDatum?: InputMaybe<DealData_Order_By>;
  SavedYardiUnitProfile?: InputMaybe<YardiUnitProfiles_Order_By>;
  Unit?: InputMaybe<Units_Order_By>;
  VirtualUnit?: InputMaybe<VirtualUnits_Order_By>;
  YardiUnit?: InputMaybe<YardiUnits_Order_By>;
  area_survey_required?: InputMaybe<Order_By>;
  deal_data_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_yardi_unit_profile_id?: InputMaybe<Order_By>;
  unit_key?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DealsUnits */
export type DealsUnits_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DealsUnits" */
export enum DealsUnits_Select_Column {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafYardiUnitProfileId = "laf_yardi_unit_profile_id",
  /** column name */
  UnitKey = "unit_key",
  /** column name */
  VirtualUnitId = "virtual_unit_id",
}

/** select "DealsUnits_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DealsUnits" */
export enum DealsUnits_Select_Column_DealsUnits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
}

/** select "DealsUnits_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DealsUnits" */
export enum DealsUnits_Select_Column_DealsUnits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
}

/** input type for updating data in table "DealsUnits" */
export type DealsUnits_Set_Input = {
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_yardi_unit_profile_id?: InputMaybe<Scalars["String"]>;
  unit_key?: InputMaybe<Scalars["String"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type DealsUnits_Stddev_Fields = {
  __typename?: "DealsUnits_stddev_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DealsUnits" */
export type DealsUnits_Stddev_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DealsUnits_Stddev_Pop_Fields = {
  __typename?: "DealsUnits_stddev_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DealsUnits" */
export type DealsUnits_Stddev_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DealsUnits_Stddev_Samp_Fields = {
  __typename?: "DealsUnits_stddev_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DealsUnits" */
export type DealsUnits_Stddev_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DealsUnits" */
export type DealsUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DealsUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DealsUnits_Stream_Cursor_Value_Input = {
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_yardi_unit_profile_id?: InputMaybe<Scalars["String"]>;
  unit_key?: InputMaybe<Scalars["String"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type DealsUnits_Sum_Fields = {
  __typename?: "DealsUnits_sum_fields";
  deal_data_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DealsUnits" */
export type DealsUnits_Sum_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** update columns of table "DealsUnits" */
export enum DealsUnits_Update_Column {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  DealDataId = "deal_data_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafYardiUnitProfileId = "laf_yardi_unit_profile_id",
  /** column name */
  UnitKey = "unit_key",
  /** column name */
  VirtualUnitId = "virtual_unit_id",
}

export type DealsUnits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DealsUnits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DealsUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealsUnits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DealsUnits_Var_Pop_Fields = {
  __typename?: "DealsUnits_var_pop_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DealsUnits" */
export type DealsUnits_Var_Pop_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DealsUnits_Var_Samp_Fields = {
  __typename?: "DealsUnits_var_samp_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DealsUnits" */
export type DealsUnits_Var_Samp_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DealsUnits_Variance_Fields = {
  __typename?: "DealsUnits_variance_fields";
  deal_data_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DealsUnits" */
export type DealsUnits_Variance_Order_By = {
  deal_data_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Deals" */
export type Deals_Aggregate = {
  __typename?: "Deals_aggregate";
  aggregate?: Maybe<Deals_Aggregate_Fields>;
  nodes: Array<Deals>;
};

export type Deals_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Deals_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Deals_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Deals_Aggregate_Bool_Exp_Count>;
};

export type Deals_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Deals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Deals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Deals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Deals" */
export type Deals_Aggregate_Fields = {
  __typename?: "Deals_aggregate_fields";
  avg?: Maybe<Deals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Deals_Max_Fields>;
  min?: Maybe<Deals_Min_Fields>;
  stddev?: Maybe<Deals_Stddev_Fields>;
  stddev_pop?: Maybe<Deals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deals_Stddev_Samp_Fields>;
  sum?: Maybe<Deals_Sum_Fields>;
  var_pop?: Maybe<Deals_Var_Pop_Fields>;
  var_samp?: Maybe<Deals_Var_Samp_Fields>;
  variance?: Maybe<Deals_Variance_Fields>;
};

/** aggregate fields of "Deals" */
export type Deals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Deals" */
export type Deals_Aggregate_Order_By = {
  avg?: InputMaybe<Deals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deals_Max_Order_By>;
  min?: InputMaybe<Deals_Min_Order_By>;
  stddev?: InputMaybe<Deals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Deals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Deals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Deals_Sum_Order_By>;
  var_pop?: InputMaybe<Deals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Deals_Var_Samp_Order_By>;
  variance?: InputMaybe<Deals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Deals" */
export type Deals_Arr_Rel_Insert_Input = {
  data: Array<Deals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deals_On_Conflict>;
};

/** aggregate avg on columns */
export type Deals_Avg_Fields = {
  __typename?: "Deals_avg_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Deals" */
export type Deals_Avg_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Deals". All fields are combined with a logical 'AND'. */
export type Deals_Bool_Exp = {
  ActionAllocations?: InputMaybe<ActionAllocations_Bool_Exp>;
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Bool_Exp>;
  ActivityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  Agreements?: InputMaybe<Agreements_Bool_Exp>;
  Agreements_aggregate?: InputMaybe<Agreements_Aggregate_Bool_Exp>;
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  DealData?: InputMaybe<DealData_Bool_Exp>;
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  DealFiles?: InputMaybe<DealFiles_Bool_Exp>;
  DealFiles_aggregate?: InputMaybe<DealFiles_Aggregate_Bool_Exp>;
  DealLinks?: InputMaybe<DealLinks_Bool_Exp>;
  DealLinks_aggregate?: InputMaybe<DealLinks_Aggregate_Bool_Exp>;
  DealPhases?: InputMaybe<DealPhases_Bool_Exp>;
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Bool_Exp>;
  DealShopActivities?: InputMaybe<DealShopActivities_Bool_Exp>;
  DealShopActivities_aggregate?: InputMaybe<DealShopActivities_Aggregate_Bool_Exp>;
  DealStatus?: InputMaybe<DealStatus_Bool_Exp>;
  DealType?: InputMaybe<DealTypes_Bool_Exp>;
  DealsCompanies?: InputMaybe<DealsCompanies_Bool_Exp>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Bool_Exp>;
  DealsContacts?: InputMaybe<DealsContacts_Bool_Exp>;
  DealsContacts_aggregate?: InputMaybe<DealsContacts_Aggregate_Bool_Exp>;
  DocumentRequests?: InputMaybe<DocumentRequests_Bool_Exp>;
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Bool_Exp>;
  GeneratedDocuments?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  GeneratedDocuments_aggregate?: InputMaybe<GeneratedDocuments_Aggregate_Bool_Exp>;
  LA_Assignee?: InputMaybe<Users_Bool_Exp>;
  Leases?: InputMaybe<Leases_Bool_Exp>;
  Leases_aggregate?: InputMaybe<Leases_Aggregate_Bool_Exp>;
  Offers?: InputMaybe<Offers_Bool_Exp>;
  Offers_aggregate?: InputMaybe<Offers_Aggregate_Bool_Exp>;
  Owner?: InputMaybe<Users_Bool_Exp>;
  Proposals?: InputMaybe<Proposals_Bool_Exp>;
  Proposals_aggregate?: InputMaybe<Proposals_Aggregate_Bool_Exp>;
  Registrations?: InputMaybe<Registrations_Bool_Exp>;
  Registrations_aggregate?: InputMaybe<Registrations_Aggregate_Bool_Exp>;
  TD_Assignee?: InputMaybe<Users_Bool_Exp>;
  TenantType?: InputMaybe<TenantTypes_Bool_Exp>;
  Terms?: InputMaybe<Terms_Bool_Exp>;
  Terms_aggregate?: InputMaybe<Terms_Aggregate_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  YardiAmendments_Deals?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
  YardiAmendments_Deals_aggregate?: InputMaybe<YardiAmendments_Deals_Aggregate_Bool_Exp>;
  YardiCustomer?: InputMaybe<YardiCustomers_Bool_Exp>;
  YardiCustomers_Deals?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
  YardiCustomers_Deals_aggregate?: InputMaybe<YardiCustomers_Deals_Aggregate_Bool_Exp>;
  YardiDealSyncStatuses?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
  YardiDealSyncStatuses_aggregate?: InputMaybe<YardiDealSyncStatuses_Aggregate_Bool_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Deals_Bool_Exp>>;
  _not?: InputMaybe<Deals_Bool_Exp>;
  _or?: InputMaybe<Array<Deals_Bool_Exp>>;
  cancel_comment?: InputMaybe<String_Comparison_Exp>;
  capital_incurred?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  customer_code?: InputMaybe<String_Comparison_Exp>;
  deal_made_from_active_lease?: InputMaybe<Boolean_Comparison_Exp>;
  deal_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fallover_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  la_assignee?: InputMaybe<Uuid_Comparison_Exp>;
  la_assignee_description?: InputMaybe<String_Comparison_Exp>;
  laf_version?: InputMaybe<Int_Comparison_Exp>;
  last_modified_by?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Uuid_Comparison_Exp>;
  owner_description?: InputMaybe<String_Comparison_Exp>;
  risk_level?: InputMaybe<DealRiskLevels_Enum_Comparison_Exp>;
  risk_set_manually?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<DealStatus_Enum_Comparison_Exp>;
  td_assignee?: InputMaybe<Uuid_Comparison_Exp>;
  td_assignee_description?: InputMaybe<String_Comparison_Exp>;
  tenant_code?: InputMaybe<String_Comparison_Exp>;
  tenant_type_id?: InputMaybe<Int_Comparison_Exp>;
  trading_name?: InputMaybe<String_Comparison_Exp>;
  userByLastModifiedBy?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "Deals" */
export enum Deals_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealsPkey = "Deals_pkey",
}

/** input type for incrementing numeric columns in table "Deals" */
export type Deals_Inc_Input = {
  deal_type_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_version?: InputMaybe<Scalars["Int"]>;
  tenant_type_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Deals" */
export type Deals_Insert_Input = {
  ActionAllocations?: InputMaybe<ActionAllocations_Arr_Rel_Insert_Input>;
  ActivityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  Agreements?: InputMaybe<Agreements_Arr_Rel_Insert_Input>;
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  DealData?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  DealFiles?: InputMaybe<DealFiles_Arr_Rel_Insert_Input>;
  DealLinks?: InputMaybe<DealLinks_Arr_Rel_Insert_Input>;
  DealPhases?: InputMaybe<DealPhases_Arr_Rel_Insert_Input>;
  DealShopActivities?: InputMaybe<DealShopActivities_Arr_Rel_Insert_Input>;
  DealStatus?: InputMaybe<DealStatus_Obj_Rel_Insert_Input>;
  DealType?: InputMaybe<DealTypes_Obj_Rel_Insert_Input>;
  DealsCompanies?: InputMaybe<DealsCompanies_Arr_Rel_Insert_Input>;
  DealsContacts?: InputMaybe<DealsContacts_Arr_Rel_Insert_Input>;
  DocumentRequests?: InputMaybe<DocumentRequests_Arr_Rel_Insert_Input>;
  GeneratedDocuments?: InputMaybe<GeneratedDocuments_Arr_Rel_Insert_Input>;
  LA_Assignee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  Leases?: InputMaybe<Leases_Arr_Rel_Insert_Input>;
  Offers?: InputMaybe<Offers_Arr_Rel_Insert_Input>;
  Owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  Proposals?: InputMaybe<Proposals_Arr_Rel_Insert_Input>;
  Registrations?: InputMaybe<Registrations_Arr_Rel_Insert_Input>;
  TD_Assignee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  TenantType?: InputMaybe<TenantTypes_Obj_Rel_Insert_Input>;
  Terms?: InputMaybe<Terms_Arr_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  YardiAmendments_Deals?: InputMaybe<YardiAmendments_Deals_Arr_Rel_Insert_Input>;
  YardiCustomer?: InputMaybe<YardiCustomers_Obj_Rel_Insert_Input>;
  YardiCustomers_Deals?: InputMaybe<YardiCustomers_Deals_Arr_Rel_Insert_Input>;
  YardiDealSyncStatuses?: InputMaybe<YardiDealSyncStatuses_Arr_Rel_Insert_Input>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Arr_Rel_Insert_Input>;
  cancel_comment?: InputMaybe<Scalars["String"]>;
  capital_incurred?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  customer_code?: InputMaybe<Scalars["String"]>;
  deal_made_from_active_lease?: InputMaybe<Scalars["Boolean"]>;
  deal_type_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  fallover_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  la_assignee?: InputMaybe<Scalars["uuid"]>;
  la_assignee_description?: InputMaybe<Scalars["String"]>;
  laf_version?: InputMaybe<Scalars["Int"]>;
  last_modified_by?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  owner_description?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<DealRiskLevels_Enum>;
  risk_set_manually?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<DealStatus_Enum>;
  td_assignee?: InputMaybe<Scalars["uuid"]>;
  td_assignee_description?: InputMaybe<Scalars["String"]>;
  tenant_code?: InputMaybe<Scalars["String"]>;
  tenant_type_id?: InputMaybe<Scalars["Int"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
  userByLastModifiedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Deals_Max_Fields = {
  __typename?: "Deals_max_fields";
  cancel_comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  customer_code?: Maybe<Scalars["String"]>;
  deal_type_id?: Maybe<Scalars["bigint"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  fallover_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["bigint"]>;
  la_assignee?: Maybe<Scalars["uuid"]>;
  la_assignee_description?: Maybe<Scalars["String"]>;
  laf_version?: Maybe<Scalars["Int"]>;
  last_modified_by?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  owner_description?: Maybe<Scalars["String"]>;
  td_assignee?: Maybe<Scalars["uuid"]>;
  td_assignee_description?: Maybe<Scalars["String"]>;
  tenant_code?: Maybe<Scalars["String"]>;
  tenant_type_id?: Maybe<Scalars["Int"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Deals" */
export type Deals_Max_Order_By = {
  cancel_comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_code?: InputMaybe<Order_By>;
  deal_type_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  fallover_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  la_assignee?: InputMaybe<Order_By>;
  la_assignee_description?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  owner_description?: InputMaybe<Order_By>;
  td_assignee?: InputMaybe<Order_By>;
  td_assignee_description?: InputMaybe<Order_By>;
  tenant_code?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Deals_Min_Fields = {
  __typename?: "Deals_min_fields";
  cancel_comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  customer_code?: Maybe<Scalars["String"]>;
  deal_type_id?: Maybe<Scalars["bigint"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  fallover_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["bigint"]>;
  la_assignee?: Maybe<Scalars["uuid"]>;
  la_assignee_description?: Maybe<Scalars["String"]>;
  laf_version?: Maybe<Scalars["Int"]>;
  last_modified_by?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["uuid"]>;
  owner_description?: Maybe<Scalars["String"]>;
  td_assignee?: Maybe<Scalars["uuid"]>;
  td_assignee_description?: Maybe<Scalars["String"]>;
  tenant_code?: Maybe<Scalars["String"]>;
  tenant_type_id?: Maybe<Scalars["Int"]>;
  trading_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Deals" */
export type Deals_Min_Order_By = {
  cancel_comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_code?: InputMaybe<Order_By>;
  deal_type_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  fallover_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  la_assignee?: InputMaybe<Order_By>;
  la_assignee_description?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  owner_description?: InputMaybe<Order_By>;
  td_assignee?: InputMaybe<Order_By>;
  td_assignee_description?: InputMaybe<Order_By>;
  tenant_code?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Deals" */
export type Deals_Mutation_Response = {
  __typename?: "Deals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Deals>;
};

/** input type for inserting object relation for remote table "Deals" */
export type Deals_Obj_Rel_Insert_Input = {
  data: Deals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deals_On_Conflict>;
};

/** on_conflict condition type for table "Deals" */
export type Deals_On_Conflict = {
  constraint: Deals_Constraint;
  update_columns?: Array<Deals_Update_Column>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** Ordering options when selecting data from "Deals". */
export type Deals_Order_By = {
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Order_By>;
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  Agreements_aggregate?: InputMaybe<Agreements_Aggregate_Order_By>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  DealFiles_aggregate?: InputMaybe<DealFiles_Aggregate_Order_By>;
  DealLinks_aggregate?: InputMaybe<DealLinks_Aggregate_Order_By>;
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Order_By>;
  DealShopActivities_aggregate?: InputMaybe<DealShopActivities_Aggregate_Order_By>;
  DealStatus?: InputMaybe<DealStatus_Order_By>;
  DealType?: InputMaybe<DealTypes_Order_By>;
  DealsCompanies_aggregate?: InputMaybe<DealsCompanies_Aggregate_Order_By>;
  DealsContacts_aggregate?: InputMaybe<DealsContacts_Aggregate_Order_By>;
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Order_By>;
  GeneratedDocuments_aggregate?: InputMaybe<GeneratedDocuments_Aggregate_Order_By>;
  LA_Assignee?: InputMaybe<Users_Order_By>;
  Leases_aggregate?: InputMaybe<Leases_Aggregate_Order_By>;
  Offers_aggregate?: InputMaybe<Offers_Aggregate_Order_By>;
  Owner?: InputMaybe<Users_Order_By>;
  Proposals_aggregate?: InputMaybe<Proposals_Aggregate_Order_By>;
  Registrations_aggregate?: InputMaybe<Registrations_Aggregate_Order_By>;
  TD_Assignee?: InputMaybe<Users_Order_By>;
  TenantType?: InputMaybe<TenantTypes_Order_By>;
  Terms_aggregate?: InputMaybe<Terms_Aggregate_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  YardiAmendments_Deals_aggregate?: InputMaybe<YardiAmendments_Deals_Aggregate_Order_By>;
  YardiCustomer?: InputMaybe<YardiCustomers_Order_By>;
  YardiCustomers_Deals_aggregate?: InputMaybe<YardiCustomers_Deals_Aggregate_Order_By>;
  YardiDealSyncStatuses_aggregate?: InputMaybe<YardiDealSyncStatuses_Aggregate_Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Order_By>;
  cancel_comment?: InputMaybe<Order_By>;
  capital_incurred?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_code?: InputMaybe<Order_By>;
  deal_made_from_active_lease?: InputMaybe<Order_By>;
  deal_type_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  fallover_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  la_assignee?: InputMaybe<Order_By>;
  la_assignee_description?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  last_modified_by?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  owner_description?: InputMaybe<Order_By>;
  risk_level?: InputMaybe<Order_By>;
  risk_set_manually?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  td_assignee?: InputMaybe<Order_By>;
  td_assignee_description?: InputMaybe<Order_By>;
  tenant_code?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
  trading_name?: InputMaybe<Order_By>;
  userByLastModifiedBy?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: Deals */
export type Deals_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Deals" */
export enum Deals_Select_Column {
  /** column name */
  CancelComment = "cancel_comment",
  /** column name */
  CapitalIncurred = "capital_incurred",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CustomerCode = "customer_code",
  /** column name */
  DealMadeFromActiveLease = "deal_made_from_active_lease",
  /** column name */
  DealTypeId = "deal_type_id",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  FalloverDate = "fallover_date",
  /** column name */
  Id = "id",
  /** column name */
  LaAssignee = "la_assignee",
  /** column name */
  LaAssigneeDescription = "la_assignee_description",
  /** column name */
  LafVersion = "laf_version",
  /** column name */
  LastModifiedBy = "last_modified_by",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  OwnerDescription = "owner_description",
  /** column name */
  RiskLevel = "risk_level",
  /** column name */
  RiskSetManually = "risk_set_manually",
  /** column name */
  Status = "status",
  /** column name */
  TdAssignee = "td_assignee",
  /** column name */
  TdAssigneeDescription = "td_assignee_description",
  /** column name */
  TenantCode = "tenant_code",
  /** column name */
  TenantTypeId = "tenant_type_id",
  /** column name */
  TradingName = "trading_name",
}

/** select "Deals_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CapitalIncurred = "capital_incurred",
  /** column name */
  DealMadeFromActiveLease = "deal_made_from_active_lease",
  /** column name */
  RiskSetManually = "risk_set_manually",
}

/** select "Deals_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Deals" */
export enum Deals_Select_Column_Deals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CapitalIncurred = "capital_incurred",
  /** column name */
  DealMadeFromActiveLease = "deal_made_from_active_lease",
  /** column name */
  RiskSetManually = "risk_set_manually",
}

/** input type for updating data in table "Deals" */
export type Deals_Set_Input = {
  cancel_comment?: InputMaybe<Scalars["String"]>;
  capital_incurred?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  customer_code?: InputMaybe<Scalars["String"]>;
  deal_made_from_active_lease?: InputMaybe<Scalars["Boolean"]>;
  deal_type_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  fallover_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  la_assignee?: InputMaybe<Scalars["uuid"]>;
  la_assignee_description?: InputMaybe<Scalars["String"]>;
  laf_version?: InputMaybe<Scalars["Int"]>;
  last_modified_by?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  owner_description?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<DealRiskLevels_Enum>;
  risk_set_manually?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<DealStatus_Enum>;
  td_assignee?: InputMaybe<Scalars["uuid"]>;
  td_assignee_description?: InputMaybe<Scalars["String"]>;
  tenant_code?: InputMaybe<Scalars["String"]>;
  tenant_type_id?: InputMaybe<Scalars["Int"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Deals_Stddev_Fields = {
  __typename?: "Deals_stddev_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Deals" */
export type Deals_Stddev_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deals_Stddev_Pop_Fields = {
  __typename?: "Deals_stddev_pop_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Deals" */
export type Deals_Stddev_Pop_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deals_Stddev_Samp_Fields = {
  __typename?: "Deals_stddev_samp_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Deals" */
export type Deals_Stddev_Samp_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Deals" */
export type Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deals_Stream_Cursor_Value_Input = {
  cancel_comment?: InputMaybe<Scalars["String"]>;
  capital_incurred?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  customer_code?: InputMaybe<Scalars["String"]>;
  deal_made_from_active_lease?: InputMaybe<Scalars["Boolean"]>;
  deal_type_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  fallover_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  la_assignee?: InputMaybe<Scalars["uuid"]>;
  la_assignee_description?: InputMaybe<Scalars["String"]>;
  laf_version?: InputMaybe<Scalars["Int"]>;
  last_modified_by?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["uuid"]>;
  owner_description?: InputMaybe<Scalars["String"]>;
  risk_level?: InputMaybe<DealRiskLevels_Enum>;
  risk_set_manually?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<DealStatus_Enum>;
  td_assignee?: InputMaybe<Scalars["uuid"]>;
  td_assignee_description?: InputMaybe<Scalars["String"]>;
  tenant_code?: InputMaybe<Scalars["String"]>;
  tenant_type_id?: InputMaybe<Scalars["Int"]>;
  trading_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Deals_Sum_Fields = {
  __typename?: "Deals_sum_fields";
  deal_type_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_version?: Maybe<Scalars["Int"]>;
  tenant_type_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Deals" */
export type Deals_Sum_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "Deals" */
export enum Deals_Update_Column {
  /** column name */
  CancelComment = "cancel_comment",
  /** column name */
  CapitalIncurred = "capital_incurred",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  CustomerCode = "customer_code",
  /** column name */
  DealMadeFromActiveLease = "deal_made_from_active_lease",
  /** column name */
  DealTypeId = "deal_type_id",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  FalloverDate = "fallover_date",
  /** column name */
  Id = "id",
  /** column name */
  LaAssignee = "la_assignee",
  /** column name */
  LaAssigneeDescription = "la_assignee_description",
  /** column name */
  LafVersion = "laf_version",
  /** column name */
  LastModifiedBy = "last_modified_by",
  /** column name */
  Name = "name",
  /** column name */
  Owner = "owner",
  /** column name */
  OwnerDescription = "owner_description",
  /** column name */
  RiskLevel = "risk_level",
  /** column name */
  RiskSetManually = "risk_set_manually",
  /** column name */
  Status = "status",
  /** column name */
  TdAssignee = "td_assignee",
  /** column name */
  TdAssigneeDescription = "td_assignee_description",
  /** column name */
  TenantCode = "tenant_code",
  /** column name */
  TenantTypeId = "tenant_type_id",
  /** column name */
  TradingName = "trading_name",
}

export type Deals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deals_Var_Pop_Fields = {
  __typename?: "Deals_var_pop_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Deals" */
export type Deals_Var_Pop_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deals_Var_Samp_Fields = {
  __typename?: "Deals_var_samp_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Deals" */
export type Deals_Var_Samp_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Deals_Variance_Fields = {
  __typename?: "Deals_variance_fields";
  deal_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  laf_version?: Maybe<Scalars["Float"]>;
  tenant_type_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Deals" */
export type Deals_Variance_Order_By = {
  deal_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_version?: InputMaybe<Order_By>;
  tenant_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DocumentRequests" */
export type DocumentRequests = {
  __typename?: "DocumentRequests";
  /** An object relationship */
  Deal: Deals;
  /** An object relationship */
  DocumentTemplate: DocumentTemplates;
  /** An object relationship */
  User?: Maybe<Users>;
  deal_id: Scalars["bigint"];
  id: Scalars["bigint"];
  requested_by?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  template_id: Scalars["bigint"];
};

/** aggregated selection of "DocumentRequests" */
export type DocumentRequests_Aggregate = {
  __typename?: "DocumentRequests_aggregate";
  aggregate?: Maybe<DocumentRequests_Aggregate_Fields>;
  nodes: Array<DocumentRequests>;
};

export type DocumentRequests_Aggregate_Bool_Exp = {
  count?: InputMaybe<DocumentRequests_Aggregate_Bool_Exp_Count>;
};

export type DocumentRequests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DocumentRequests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DocumentRequests" */
export type DocumentRequests_Aggregate_Fields = {
  __typename?: "DocumentRequests_aggregate_fields";
  avg?: Maybe<DocumentRequests_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DocumentRequests_Max_Fields>;
  min?: Maybe<DocumentRequests_Min_Fields>;
  stddev?: Maybe<DocumentRequests_Stddev_Fields>;
  stddev_pop?: Maybe<DocumentRequests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DocumentRequests_Stddev_Samp_Fields>;
  sum?: Maybe<DocumentRequests_Sum_Fields>;
  var_pop?: Maybe<DocumentRequests_Var_Pop_Fields>;
  var_samp?: Maybe<DocumentRequests_Var_Samp_Fields>;
  variance?: Maybe<DocumentRequests_Variance_Fields>;
};

/** aggregate fields of "DocumentRequests" */
export type DocumentRequests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DocumentRequests" */
export type DocumentRequests_Aggregate_Order_By = {
  avg?: InputMaybe<DocumentRequests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DocumentRequests_Max_Order_By>;
  min?: InputMaybe<DocumentRequests_Min_Order_By>;
  stddev?: InputMaybe<DocumentRequests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DocumentRequests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DocumentRequests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DocumentRequests_Sum_Order_By>;
  var_pop?: InputMaybe<DocumentRequests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DocumentRequests_Var_Samp_Order_By>;
  variance?: InputMaybe<DocumentRequests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DocumentRequests" */
export type DocumentRequests_Arr_Rel_Insert_Input = {
  data: Array<DocumentRequests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentRequests_On_Conflict>;
};

/** aggregate avg on columns */
export type DocumentRequests_Avg_Fields = {
  __typename?: "DocumentRequests_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DocumentRequests" */
export type DocumentRequests_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DocumentRequests". All fields are combined with a logical 'AND'. */
export type DocumentRequests_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  DocumentTemplate?: InputMaybe<DocumentTemplates_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DocumentRequests_Bool_Exp>>;
  _not?: InputMaybe<DocumentRequests_Bool_Exp>;
  _or?: InputMaybe<Array<DocumentRequests_Bool_Exp>>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  requested_by?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "DocumentRequests" */
export enum DocumentRequests_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentRequestsPkey = "DocumentRequests_pkey",
}

/** input type for incrementing numeric columns in table "DocumentRequests" */
export type DocumentRequests_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DocumentRequests" */
export type DocumentRequests_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  DocumentTemplate?: InputMaybe<DocumentTemplates_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  requested_by?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type DocumentRequests_Max_Fields = {
  __typename?: "DocumentRequests_max_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  requested_by?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "DocumentRequests" */
export type DocumentRequests_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DocumentRequests_Min_Fields = {
  __typename?: "DocumentRequests_min_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  requested_by?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  template_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "DocumentRequests" */
export type DocumentRequests_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DocumentRequests" */
export type DocumentRequests_Mutation_Response = {
  __typename?: "DocumentRequests_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentRequests>;
};

/** on_conflict condition type for table "DocumentRequests" */
export type DocumentRequests_On_Conflict = {
  constraint: DocumentRequests_Constraint;
  update_columns?: Array<DocumentRequests_Update_Column>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** Ordering options when selecting data from "DocumentRequests". */
export type DocumentRequests_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  DocumentTemplate?: InputMaybe<DocumentTemplates_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DocumentRequests */
export type DocumentRequests_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DocumentRequests" */
export enum DocumentRequests_Select_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  RequestedBy = "requested_by",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "template_id",
}

/** input type for updating data in table "DocumentRequests" */
export type DocumentRequests_Set_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  requested_by?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type DocumentRequests_Stddev_Fields = {
  __typename?: "DocumentRequests_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DocumentRequests" */
export type DocumentRequests_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DocumentRequests_Stddev_Pop_Fields = {
  __typename?: "DocumentRequests_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DocumentRequests" */
export type DocumentRequests_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DocumentRequests_Stddev_Samp_Fields = {
  __typename?: "DocumentRequests_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DocumentRequests" */
export type DocumentRequests_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DocumentRequests" */
export type DocumentRequests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DocumentRequests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentRequests_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  requested_by?: InputMaybe<Scalars["uuid"]>;
  status?: InputMaybe<Scalars["String"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type DocumentRequests_Sum_Fields = {
  __typename?: "DocumentRequests_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  template_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DocumentRequests" */
export type DocumentRequests_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** update columns of table "DocumentRequests" */
export enum DocumentRequests_Update_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  RequestedBy = "requested_by",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "template_id",
}

export type DocumentRequests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentRequests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentRequests_Set_Input>;
  /** filter the rows which have to be updated */
  where: DocumentRequests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DocumentRequests_Var_Pop_Fields = {
  __typename?: "DocumentRequests_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DocumentRequests" */
export type DocumentRequests_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DocumentRequests_Var_Samp_Fields = {
  __typename?: "DocumentRequests_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DocumentRequests" */
export type DocumentRequests_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DocumentRequests_Variance_Fields = {
  __typename?: "DocumentRequests_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  template_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DocumentRequests" */
export type DocumentRequests_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DocumentTemplates" */
export type DocumentTemplates = {
  __typename?: "DocumentTemplates";
  /** An array relationship */
  DocumentRequests: Array<DocumentRequests>;
  /** An aggregate relationship */
  DocumentRequests_aggregate: DocumentRequests_Aggregate;
  /** An object relationship */
  Users: Users;
  convert_to_pdf: Scalars["Boolean"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted: Scalars["Boolean"];
  event_trigger_name: Scalars["String"];
  generate_to_name?: Maybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  id: Scalars["bigint"];
  information?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  template_name: Scalars["String"];
  template_path: Scalars["String"];
  user_id: Scalars["uuid"];
};

/** columns and relationships of "DocumentTemplates" */
export type DocumentTemplatesDocumentRequestsArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** columns and relationships of "DocumentTemplates" */
export type DocumentTemplatesDocumentRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** aggregated selection of "DocumentTemplates" */
export type DocumentTemplates_Aggregate = {
  __typename?: "DocumentTemplates_aggregate";
  aggregate?: Maybe<DocumentTemplates_Aggregate_Fields>;
  nodes: Array<DocumentTemplates>;
};

export type DocumentTemplates_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<DocumentTemplates_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<DocumentTemplates_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<DocumentTemplates_Aggregate_Bool_Exp_Count>;
};

export type DocumentTemplates_Aggregate_Bool_Exp_Bool_And = {
  arguments: DocumentTemplates_Select_Column_DocumentTemplates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DocumentTemplates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DocumentTemplates_Aggregate_Bool_Exp_Bool_Or = {
  arguments: DocumentTemplates_Select_Column_DocumentTemplates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DocumentTemplates_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type DocumentTemplates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<DocumentTemplates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "DocumentTemplates" */
export type DocumentTemplates_Aggregate_Fields = {
  __typename?: "DocumentTemplates_aggregate_fields";
  avg?: Maybe<DocumentTemplates_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<DocumentTemplates_Max_Fields>;
  min?: Maybe<DocumentTemplates_Min_Fields>;
  stddev?: Maybe<DocumentTemplates_Stddev_Fields>;
  stddev_pop?: Maybe<DocumentTemplates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DocumentTemplates_Stddev_Samp_Fields>;
  sum?: Maybe<DocumentTemplates_Sum_Fields>;
  var_pop?: Maybe<DocumentTemplates_Var_Pop_Fields>;
  var_samp?: Maybe<DocumentTemplates_Var_Samp_Fields>;
  variance?: Maybe<DocumentTemplates_Variance_Fields>;
};

/** aggregate fields of "DocumentTemplates" */
export type DocumentTemplates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "DocumentTemplates" */
export type DocumentTemplates_Aggregate_Order_By = {
  avg?: InputMaybe<DocumentTemplates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DocumentTemplates_Max_Order_By>;
  min?: InputMaybe<DocumentTemplates_Min_Order_By>;
  stddev?: InputMaybe<DocumentTemplates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DocumentTemplates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DocumentTemplates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DocumentTemplates_Sum_Order_By>;
  var_pop?: InputMaybe<DocumentTemplates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DocumentTemplates_Var_Samp_Order_By>;
  variance?: InputMaybe<DocumentTemplates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "DocumentTemplates" */
export type DocumentTemplates_Arr_Rel_Insert_Input = {
  data: Array<DocumentTemplates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentTemplates_On_Conflict>;
};

/** aggregate avg on columns */
export type DocumentTemplates_Avg_Fields = {
  __typename?: "DocumentTemplates_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "DocumentTemplates". All fields are combined with a logical 'AND'. */
export type DocumentTemplates_Bool_Exp = {
  DocumentRequests?: InputMaybe<DocumentRequests_Bool_Exp>;
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Bool_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DocumentTemplates_Bool_Exp>>;
  _not?: InputMaybe<DocumentTemplates_Bool_Exp>;
  _or?: InputMaybe<Array<DocumentTemplates_Bool_Exp>>;
  convert_to_pdf?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  event_trigger_name?: InputMaybe<String_Comparison_Exp>;
  generate_to_name?: InputMaybe<String_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  information?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
  template_path?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "DocumentTemplates" */
export enum DocumentTemplates_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentTemplatesPkey = "DocumentTemplates_pkey",
  /** unique or primary key constraint on columns "generate_to_name" */
  IdxGeneratednameDeletedPartial = "idx_generatedname_deleted_partial",
}

/** input type for incrementing numeric columns in table "DocumentTemplates" */
export type DocumentTemplates_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "DocumentTemplates" */
export type DocumentTemplates_Insert_Input = {
  DocumentRequests?: InputMaybe<DocumentRequests_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  convert_to_pdf?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  event_trigger_name?: InputMaybe<Scalars["String"]>;
  generate_to_name?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  information?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  template_name?: InputMaybe<Scalars["String"]>;
  template_path?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type DocumentTemplates_Max_Fields = {
  __typename?: "DocumentTemplates_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_trigger_name?: Maybe<Scalars["String"]>;
  generate_to_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  information?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  template_name?: Maybe<Scalars["String"]>;
  template_path?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_trigger_name?: InputMaybe<Order_By>;
  generate_to_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  information?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  template_path?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DocumentTemplates_Min_Fields = {
  __typename?: "DocumentTemplates_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_trigger_name?: Maybe<Scalars["String"]>;
  generate_to_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  information?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  template_name?: Maybe<Scalars["String"]>;
  template_path?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_trigger_name?: InputMaybe<Order_By>;
  generate_to_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  information?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  template_path?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "DocumentTemplates" */
export type DocumentTemplates_Mutation_Response = {
  __typename?: "DocumentTemplates_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentTemplates>;
};

/** input type for inserting object relation for remote table "DocumentTemplates" */
export type DocumentTemplates_Obj_Rel_Insert_Input = {
  data: DocumentTemplates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentTemplates_On_Conflict>;
};

/** on_conflict condition type for table "DocumentTemplates" */
export type DocumentTemplates_On_Conflict = {
  constraint: DocumentTemplates_Constraint;
  update_columns?: Array<DocumentTemplates_Update_Column>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

/** Ordering options when selecting data from "DocumentTemplates". */
export type DocumentTemplates_Order_By = {
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Order_By>;
  Users?: InputMaybe<Users_Order_By>;
  convert_to_pdf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  event_trigger_name?: InputMaybe<Order_By>;
  generate_to_name?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  information?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  template_path?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DocumentTemplates */
export type DocumentTemplates_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "DocumentTemplates" */
export enum DocumentTemplates_Select_Column {
  /** column name */
  ConvertToPdf = "convert_to_pdf",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EventTriggerName = "event_trigger_name",
  /** column name */
  GenerateToName = "generate_to_name",
  /** column name */
  Hidden = "hidden",
  /** column name */
  Id = "id",
  /** column name */
  Information = "information",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TemplateName = "template_name",
  /** column name */
  TemplatePath = "template_path",
  /** column name */
  UserId = "user_id",
}

/** select "DocumentTemplates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "DocumentTemplates" */
export enum DocumentTemplates_Select_Column_DocumentTemplates_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ConvertToPdf = "convert_to_pdf",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Hidden = "hidden",
}

/** select "DocumentTemplates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "DocumentTemplates" */
export enum DocumentTemplates_Select_Column_DocumentTemplates_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ConvertToPdf = "convert_to_pdf",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Hidden = "hidden",
}

/** input type for updating data in table "DocumentTemplates" */
export type DocumentTemplates_Set_Input = {
  convert_to_pdf?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  event_trigger_name?: InputMaybe<Scalars["String"]>;
  generate_to_name?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  information?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  template_name?: InputMaybe<Scalars["String"]>;
  template_path?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type DocumentTemplates_Stddev_Fields = {
  __typename?: "DocumentTemplates_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DocumentTemplates_Stddev_Pop_Fields = {
  __typename?: "DocumentTemplates_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DocumentTemplates_Stddev_Samp_Fields = {
  __typename?: "DocumentTemplates_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "DocumentTemplates" */
export type DocumentTemplates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DocumentTemplates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentTemplates_Stream_Cursor_Value_Input = {
  convert_to_pdf?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  event_trigger_name?: InputMaybe<Scalars["String"]>;
  generate_to_name?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  information?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  template_name?: InputMaybe<Scalars["String"]>;
  template_path?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type DocumentTemplates_Sum_Fields = {
  __typename?: "DocumentTemplates_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "DocumentTemplates" */
export enum DocumentTemplates_Update_Column {
  /** column name */
  ConvertToPdf = "convert_to_pdf",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EventTriggerName = "event_trigger_name",
  /** column name */
  GenerateToName = "generate_to_name",
  /** column name */
  Hidden = "hidden",
  /** column name */
  Id = "id",
  /** column name */
  Information = "information",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  TemplateName = "template_name",
  /** column name */
  TemplatePath = "template_path",
  /** column name */
  UserId = "user_id",
}

export type DocumentTemplates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentTemplates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentTemplates_Set_Input>;
  /** filter the rows which have to be updated */
  where: DocumentTemplates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DocumentTemplates_Var_Pop_Fields = {
  __typename?: "DocumentTemplates_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DocumentTemplates_Var_Samp_Fields = {
  __typename?: "DocumentTemplates_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DocumentTemplates_Variance_Fields = {
  __typename?: "DocumentTemplates_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "DocumentTemplates" */
export type DocumentTemplates_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type EgestInput = {
  dealId: Scalars["Int"];
  startFromStart: Scalars["Boolean"];
};

export type EgestOutput = {
  __typename?: "EgestOutput";
  jobId?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "EscalatedApprovers" */
export type EscalatedApprovers = {
  __typename?: "EscalatedApprovers";
  /** An object relationship */
  User?: Maybe<Users>;
  id: Scalars["bigint"];
  level?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "EscalatedApprovers" */
export type EscalatedApprovers_Aggregate = {
  __typename?: "EscalatedApprovers_aggregate";
  aggregate?: Maybe<EscalatedApprovers_Aggregate_Fields>;
  nodes: Array<EscalatedApprovers>;
};

export type EscalatedApprovers_Aggregate_Bool_Exp = {
  count?: InputMaybe<EscalatedApprovers_Aggregate_Bool_Exp_Count>;
};

export type EscalatedApprovers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<EscalatedApprovers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "EscalatedApprovers" */
export type EscalatedApprovers_Aggregate_Fields = {
  __typename?: "EscalatedApprovers_aggregate_fields";
  avg?: Maybe<EscalatedApprovers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<EscalatedApprovers_Max_Fields>;
  min?: Maybe<EscalatedApprovers_Min_Fields>;
  stddev?: Maybe<EscalatedApprovers_Stddev_Fields>;
  stddev_pop?: Maybe<EscalatedApprovers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EscalatedApprovers_Stddev_Samp_Fields>;
  sum?: Maybe<EscalatedApprovers_Sum_Fields>;
  var_pop?: Maybe<EscalatedApprovers_Var_Pop_Fields>;
  var_samp?: Maybe<EscalatedApprovers_Var_Samp_Fields>;
  variance?: Maybe<EscalatedApprovers_Variance_Fields>;
};

/** aggregate fields of "EscalatedApprovers" */
export type EscalatedApprovers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "EscalatedApprovers" */
export type EscalatedApprovers_Aggregate_Order_By = {
  avg?: InputMaybe<EscalatedApprovers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EscalatedApprovers_Max_Order_By>;
  min?: InputMaybe<EscalatedApprovers_Min_Order_By>;
  stddev?: InputMaybe<EscalatedApprovers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EscalatedApprovers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EscalatedApprovers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EscalatedApprovers_Sum_Order_By>;
  var_pop?: InputMaybe<EscalatedApprovers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EscalatedApprovers_Var_Samp_Order_By>;
  variance?: InputMaybe<EscalatedApprovers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EscalatedApprovers" */
export type EscalatedApprovers_Arr_Rel_Insert_Input = {
  data: Array<EscalatedApprovers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EscalatedApprovers_On_Conflict>;
};

/** aggregate avg on columns */
export type EscalatedApprovers_Avg_Fields = {
  __typename?: "EscalatedApprovers_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EscalatedApprovers". All fields are combined with a logical 'AND'. */
export type EscalatedApprovers_Bool_Exp = {
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<EscalatedApprovers_Bool_Exp>>;
  _not?: InputMaybe<EscalatedApprovers_Bool_Exp>;
  _or?: InputMaybe<Array<EscalatedApprovers_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "EscalatedApprovers" */
export enum EscalatedApprovers_Constraint {
  /** unique or primary key constraint on columns "id" */
  EscalatedApproversPkey = "EscalatedApprovers_pkey",
}

/** input type for incrementing numeric columns in table "EscalatedApprovers" */
export type EscalatedApprovers_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "EscalatedApprovers" */
export type EscalatedApprovers_Insert_Input = {
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type EscalatedApprovers_Max_Fields = {
  __typename?: "EscalatedApprovers_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EscalatedApprovers_Min_Fields = {
  __typename?: "EscalatedApprovers_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EscalatedApprovers" */
export type EscalatedApprovers_Mutation_Response = {
  __typename?: "EscalatedApprovers_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EscalatedApprovers>;
};

/** on_conflict condition type for table "EscalatedApprovers" */
export type EscalatedApprovers_On_Conflict = {
  constraint: EscalatedApprovers_Constraint;
  update_columns?: Array<EscalatedApprovers_Update_Column>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

/** Ordering options when selecting data from "EscalatedApprovers". */
export type EscalatedApprovers_Order_By = {
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EscalatedApprovers */
export type EscalatedApprovers_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "EscalatedApprovers" */
export enum EscalatedApprovers_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Level = "level",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "EscalatedApprovers" */
export type EscalatedApprovers_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type EscalatedApprovers_Stddev_Fields = {
  __typename?: "EscalatedApprovers_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EscalatedApprovers_Stddev_Pop_Fields = {
  __typename?: "EscalatedApprovers_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EscalatedApprovers_Stddev_Samp_Fields = {
  __typename?: "EscalatedApprovers_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "EscalatedApprovers" */
export type EscalatedApprovers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EscalatedApprovers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EscalatedApprovers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  level?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type EscalatedApprovers_Sum_Fields = {
  __typename?: "EscalatedApprovers_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  level?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** update columns of table "EscalatedApprovers" */
export enum EscalatedApprovers_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Level = "level",
  /** column name */
  UserId = "user_id",
}

export type EscalatedApprovers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EscalatedApprovers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EscalatedApprovers_Set_Input>;
  /** filter the rows which have to be updated */
  where: EscalatedApprovers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EscalatedApprovers_Var_Pop_Fields = {
  __typename?: "EscalatedApprovers_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EscalatedApprovers_Var_Samp_Fields = {
  __typename?: "EscalatedApprovers_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EscalatedApprovers_Variance_Fields = {
  __typename?: "EscalatedApprovers_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  level?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "EscalatedApprovers" */
export type EscalatedApprovers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
};

/** columns and relationships of "GeneratedDocuments" */
export type GeneratedDocuments = {
  __typename?: "GeneratedDocuments";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  /** An object relationship */
  User?: Maybe<Users>;
  deal_id?: Maybe<Scalars["bigint"]>;
  document_name?: Maybe<Scalars["String"]>;
  errors_json?: Maybe<Scalars["String"]>;
  full_path?: Maybe<Scalars["String"]>;
  generated_at?: Maybe<Scalars["timestamptz"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  has_error: Scalars["Boolean"];
  id: Scalars["bigint"];
};

/** aggregated selection of "GeneratedDocuments" */
export type GeneratedDocuments_Aggregate = {
  __typename?: "GeneratedDocuments_aggregate";
  aggregate?: Maybe<GeneratedDocuments_Aggregate_Fields>;
  nodes: Array<GeneratedDocuments>;
};

export type GeneratedDocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<GeneratedDocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<GeneratedDocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<GeneratedDocuments_Aggregate_Bool_Exp_Count>;
};

export type GeneratedDocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: GeneratedDocuments_Select_Column_GeneratedDocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GeneratedDocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GeneratedDocuments_Select_Column_GeneratedDocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GeneratedDocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "GeneratedDocuments" */
export type GeneratedDocuments_Aggregate_Fields = {
  __typename?: "GeneratedDocuments_aggregate_fields";
  avg?: Maybe<GeneratedDocuments_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<GeneratedDocuments_Max_Fields>;
  min?: Maybe<GeneratedDocuments_Min_Fields>;
  stddev?: Maybe<GeneratedDocuments_Stddev_Fields>;
  stddev_pop?: Maybe<GeneratedDocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GeneratedDocuments_Stddev_Samp_Fields>;
  sum?: Maybe<GeneratedDocuments_Sum_Fields>;
  var_pop?: Maybe<GeneratedDocuments_Var_Pop_Fields>;
  var_samp?: Maybe<GeneratedDocuments_Var_Samp_Fields>;
  variance?: Maybe<GeneratedDocuments_Variance_Fields>;
};

/** aggregate fields of "GeneratedDocuments" */
export type GeneratedDocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "GeneratedDocuments" */
export type GeneratedDocuments_Aggregate_Order_By = {
  avg?: InputMaybe<GeneratedDocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GeneratedDocuments_Max_Order_By>;
  min?: InputMaybe<GeneratedDocuments_Min_Order_By>;
  stddev?: InputMaybe<GeneratedDocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GeneratedDocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GeneratedDocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GeneratedDocuments_Sum_Order_By>;
  var_pop?: InputMaybe<GeneratedDocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GeneratedDocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<GeneratedDocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "GeneratedDocuments" */
export type GeneratedDocuments_Arr_Rel_Insert_Input = {
  data: Array<GeneratedDocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GeneratedDocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type GeneratedDocuments_Avg_Fields = {
  __typename?: "GeneratedDocuments_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GeneratedDocuments". All fields are combined with a logical 'AND'. */
export type GeneratedDocuments_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<GeneratedDocuments_Bool_Exp>>;
  _not?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  _or?: InputMaybe<Array<GeneratedDocuments_Bool_Exp>>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  document_name?: InputMaybe<String_Comparison_Exp>;
  errors_json?: InputMaybe<String_Comparison_Exp>;
  full_path?: InputMaybe<String_Comparison_Exp>;
  generated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  generated_by?: InputMaybe<Uuid_Comparison_Exp>;
  has_error?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "GeneratedDocuments" */
export enum GeneratedDocuments_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeneratedDocumentsPkey = "GeneratedDocuments_pkey",
}

/** input type for incrementing numeric columns in table "GeneratedDocuments" */
export type GeneratedDocuments_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "GeneratedDocuments" */
export type GeneratedDocuments_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  document_name?: InputMaybe<Scalars["String"]>;
  errors_json?: InputMaybe<Scalars["String"]>;
  full_path?: InputMaybe<Scalars["String"]>;
  generated_at?: InputMaybe<Scalars["timestamptz"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  has_error?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type GeneratedDocuments_Max_Fields = {
  __typename?: "GeneratedDocuments_max_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  document_name?: Maybe<Scalars["String"]>;
  errors_json?: Maybe<Scalars["String"]>;
  full_path?: Maybe<Scalars["String"]>;
  generated_at?: Maybe<Scalars["timestamptz"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  document_name?: InputMaybe<Order_By>;
  errors_json?: InputMaybe<Order_By>;
  full_path?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GeneratedDocuments_Min_Fields = {
  __typename?: "GeneratedDocuments_min_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  document_name?: Maybe<Scalars["String"]>;
  errors_json?: Maybe<Scalars["String"]>;
  full_path?: Maybe<Scalars["String"]>;
  generated_at?: Maybe<Scalars["timestamptz"]>;
  generated_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  document_name?: InputMaybe<Order_By>;
  errors_json?: InputMaybe<Order_By>;
  full_path?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "GeneratedDocuments" */
export type GeneratedDocuments_Mutation_Response = {
  __typename?: "GeneratedDocuments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<GeneratedDocuments>;
};

/** on_conflict condition type for table "GeneratedDocuments" */
export type GeneratedDocuments_On_Conflict = {
  constraint: GeneratedDocuments_Constraint;
  update_columns?: Array<GeneratedDocuments_Update_Column>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "GeneratedDocuments". */
export type GeneratedDocuments_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  document_name?: InputMaybe<Order_By>;
  errors_json?: InputMaybe<Order_By>;
  full_path?: InputMaybe<Order_By>;
  generated_at?: InputMaybe<Order_By>;
  generated_by?: InputMaybe<Order_By>;
  has_error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GeneratedDocuments */
export type GeneratedDocuments_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "GeneratedDocuments" */
export enum GeneratedDocuments_Select_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  DocumentName = "document_name",
  /** column name */
  ErrorsJson = "errors_json",
  /** column name */
  FullPath = "full_path",
  /** column name */
  GeneratedAt = "generated_at",
  /** column name */
  GeneratedBy = "generated_by",
  /** column name */
  HasError = "has_error",
  /** column name */
  Id = "id",
}

/** select "GeneratedDocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "GeneratedDocuments" */
export enum GeneratedDocuments_Select_Column_GeneratedDocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasError = "has_error",
}

/** select "GeneratedDocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "GeneratedDocuments" */
export enum GeneratedDocuments_Select_Column_GeneratedDocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasError = "has_error",
}

/** input type for updating data in table "GeneratedDocuments" */
export type GeneratedDocuments_Set_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  document_name?: InputMaybe<Scalars["String"]>;
  errors_json?: InputMaybe<Scalars["String"]>;
  full_path?: InputMaybe<Scalars["String"]>;
  generated_at?: InputMaybe<Scalars["timestamptz"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  has_error?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type GeneratedDocuments_Stddev_Fields = {
  __typename?: "GeneratedDocuments_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GeneratedDocuments_Stddev_Pop_Fields = {
  __typename?: "GeneratedDocuments_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GeneratedDocuments_Stddev_Samp_Fields = {
  __typename?: "GeneratedDocuments_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "GeneratedDocuments" */
export type GeneratedDocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GeneratedDocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GeneratedDocuments_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  document_name?: InputMaybe<Scalars["String"]>;
  errors_json?: InputMaybe<Scalars["String"]>;
  full_path?: InputMaybe<Scalars["String"]>;
  generated_at?: InputMaybe<Scalars["timestamptz"]>;
  generated_by?: InputMaybe<Scalars["uuid"]>;
  has_error?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type GeneratedDocuments_Sum_Fields = {
  __typename?: "GeneratedDocuments_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "GeneratedDocuments" */
export enum GeneratedDocuments_Update_Column {
  /** column name */
  DealId = "deal_id",
  /** column name */
  DocumentName = "document_name",
  /** column name */
  ErrorsJson = "errors_json",
  /** column name */
  FullPath = "full_path",
  /** column name */
  GeneratedAt = "generated_at",
  /** column name */
  GeneratedBy = "generated_by",
  /** column name */
  HasError = "has_error",
  /** column name */
  Id = "id",
}

export type GeneratedDocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GeneratedDocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeneratedDocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: GeneratedDocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GeneratedDocuments_Var_Pop_Fields = {
  __typename?: "GeneratedDocuments_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GeneratedDocuments_Var_Samp_Fields = {
  __typename?: "GeneratedDocuments_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GeneratedDocuments_Variance_Fields = {
  __typename?: "GeneratedDocuments_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "GeneratedDocuments" */
export type GeneratedDocuments_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

export type JobCounts = {
  __typename?: "JobCounts";
  active?: Maybe<Scalars["Int"]>;
  completed?: Maybe<Scalars["Int"]>;
  delayed?: Maybe<Scalars["Int"]>;
  failed?: Maybe<Scalars["Int"]>;
  paused?: Maybe<Scalars["Int"]>;
  waiting?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "LafActionChoices" */
export type LafActionChoices = {
  __typename?: "LafActionChoices";
  comment?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** aggregated selection of "LafActionChoices" */
export type LafActionChoices_Aggregate = {
  __typename?: "LafActionChoices_aggregate";
  aggregate?: Maybe<LafActionChoices_Aggregate_Fields>;
  nodes: Array<LafActionChoices>;
};

/** aggregate fields of "LafActionChoices" */
export type LafActionChoices_Aggregate_Fields = {
  __typename?: "LafActionChoices_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<LafActionChoices_Max_Fields>;
  min?: Maybe<LafActionChoices_Min_Fields>;
};

/** aggregate fields of "LafActionChoices" */
export type LafActionChoices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LafActionChoices_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "LafActionChoices". All fields are combined with a logical 'AND'. */
export type LafActionChoices_Bool_Exp = {
  _and?: InputMaybe<Array<LafActionChoices_Bool_Exp>>;
  _not?: InputMaybe<LafActionChoices_Bool_Exp>;
  _or?: InputMaybe<Array<LafActionChoices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "LafActionChoices" */
export enum LafActionChoices_Constraint {
  /** unique or primary key constraint on columns "name" */
  LafActionChoicesPkey = "LafActionChoices_pkey",
}

export enum LafActionChoices_Enum {
  Approved = "Approved",
  Escalation = "Escalation",
  Finished = "Finished",
  LeaseAdminApproval = "LeaseAdminApproval",
  /** When you reject a deal. */
  Reject = "Reject",
  RequestChange = "RequestChange",
  Restart = "Restart",
}

/** Boolean expression to compare columns of type "LafActionChoices_enum". All fields are combined with logical 'AND'. */
export type LafActionChoices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<LafActionChoices_Enum>;
  _in?: InputMaybe<Array<LafActionChoices_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<LafActionChoices_Enum>;
  _nin?: InputMaybe<Array<LafActionChoices_Enum>>;
};

/** input type for inserting data into table "LafActionChoices" */
export type LafActionChoices_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type LafActionChoices_Max_Fields = {
  __typename?: "LafActionChoices_max_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type LafActionChoices_Min_Fields = {
  __typename?: "LafActionChoices_min_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "LafActionChoices" */
export type LafActionChoices_Mutation_Response = {
  __typename?: "LafActionChoices_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<LafActionChoices>;
};

/** input type for inserting object relation for remote table "LafActionChoices" */
export type LafActionChoices_Obj_Rel_Insert_Input = {
  data: LafActionChoices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LafActionChoices_On_Conflict>;
};

/** on_conflict condition type for table "LafActionChoices" */
export type LafActionChoices_On_Conflict = {
  constraint: LafActionChoices_Constraint;
  update_columns?: Array<LafActionChoices_Update_Column>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

/** Ordering options when selecting data from "LafActionChoices". */
export type LafActionChoices_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LafActionChoices */
export type LafActionChoices_Pk_Columns_Input = {
  name: Scalars["String"];
};

/** select columns of table "LafActionChoices" */
export enum LafActionChoices_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "LafActionChoices" */
export type LafActionChoices_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "LafActionChoices" */
export type LafActionChoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LafActionChoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LafActionChoices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "LafActionChoices" */
export enum LafActionChoices_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

export type LafActionChoices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LafActionChoices_Set_Input>;
  /** filter the rows which have to be updated */
  where: LafActionChoices_Bool_Exp;
};

/** columns and relationships of "Leases" */
export type Leases = {
  __typename?: "Leases";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Leases" */
export type Leases_Aggregate = {
  __typename?: "Leases_aggregate";
  aggregate?: Maybe<Leases_Aggregate_Fields>;
  nodes: Array<Leases>;
};

export type Leases_Aggregate_Bool_Exp = {
  count?: InputMaybe<Leases_Aggregate_Bool_Exp_Count>;
};

export type Leases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Leases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Leases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Leases" */
export type Leases_Aggregate_Fields = {
  __typename?: "Leases_aggregate_fields";
  avg?: Maybe<Leases_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Leases_Max_Fields>;
  min?: Maybe<Leases_Min_Fields>;
  stddev?: Maybe<Leases_Stddev_Fields>;
  stddev_pop?: Maybe<Leases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Leases_Stddev_Samp_Fields>;
  sum?: Maybe<Leases_Sum_Fields>;
  var_pop?: Maybe<Leases_Var_Pop_Fields>;
  var_samp?: Maybe<Leases_Var_Samp_Fields>;
  variance?: Maybe<Leases_Variance_Fields>;
};

/** aggregate fields of "Leases" */
export type Leases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Leases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Leases" */
export type Leases_Aggregate_Order_By = {
  avg?: InputMaybe<Leases_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Leases_Max_Order_By>;
  min?: InputMaybe<Leases_Min_Order_By>;
  stddev?: InputMaybe<Leases_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Leases_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Leases_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Leases_Sum_Order_By>;
  var_pop?: InputMaybe<Leases_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Leases_Var_Samp_Order_By>;
  variance?: InputMaybe<Leases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Leases" */
export type Leases_Arr_Rel_Insert_Input = {
  data: Array<Leases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Leases_On_Conflict>;
};

/** aggregate avg on columns */
export type Leases_Avg_Fields = {
  __typename?: "Leases_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Leases" */
export type Leases_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Leases". All fields are combined with a logical 'AND'. */
export type Leases_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Leases_Bool_Exp>>;
  _not?: InputMaybe<Leases_Bool_Exp>;
  _or?: InputMaybe<Array<Leases_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Leases" */
export enum Leases_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeasesPkey = "Leases_pkey",
}

/** input type for incrementing numeric columns in table "Leases" */
export type Leases_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Leases" */
export type Leases_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Leases_Max_Fields = {
  __typename?: "Leases_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Leases" */
export type Leases_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Leases_Min_Fields = {
  __typename?: "Leases_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Leases" */
export type Leases_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Leases" */
export type Leases_Mutation_Response = {
  __typename?: "Leases_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Leases>;
};

/** on_conflict condition type for table "Leases" */
export type Leases_On_Conflict = {
  constraint: Leases_Constraint;
  update_columns?: Array<Leases_Update_Column>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

/** Ordering options when selecting data from "Leases". */
export type Leases_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Leases */
export type Leases_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Leases" */
export enum Leases_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Leases" */
export type Leases_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Leases_Stddev_Fields = {
  __typename?: "Leases_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Leases" */
export type Leases_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Leases_Stddev_Pop_Fields = {
  __typename?: "Leases_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Leases" */
export type Leases_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Leases_Stddev_Samp_Fields = {
  __typename?: "Leases_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Leases" */
export type Leases_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Leases" */
export type Leases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leases_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Leases_Sum_Fields = {
  __typename?: "Leases_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Leases" */
export type Leases_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Leases" */
export enum Leases_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Leases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Leases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Leases_Var_Pop_Fields = {
  __typename?: "Leases_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Leases" */
export type Leases_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Leases_Var_Samp_Fields = {
  __typename?: "Leases_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Leases" */
export type Leases_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Leases_Variance_Fields = {
  __typename?: "Leases_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Leases" */
export type Leases_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Locations" */
export type Locations = {
  __typename?: "Locations";
  /** An array relationship */
  Companies: Array<Companies>;
  /** An aggregate relationship */
  Companies_aggregate: Companies_Aggregate;
  /** An array relationship */
  Contacts: Array<Contacts>;
  /** An aggregate relationship */
  Contacts_aggregate: Contacts_Aggregate;
  /** An array relationship */
  DealData: Array<DealData>;
  /** An aggregate relationship */
  DealData_aggregate: DealData_Aggregate;
  /** An object relationship */
  State?: Maybe<States>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Locations" */
export type LocationsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

/** columns and relationships of "Locations" */
export type LocationsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

/** columns and relationships of "Locations" */
export type LocationsContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "Locations" */
export type LocationsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** columns and relationships of "Locations" */
export type LocationsDealDataArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "Locations" */
export type LocationsDealData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** aggregated selection of "Locations" */
export type Locations_Aggregate = {
  __typename?: "Locations_aggregate";
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

export type Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Locations_Aggregate_Bool_Exp_Count>;
};

export type Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Locations" */
export type Locations_Aggregate_Fields = {
  __typename?: "Locations_aggregate_fields";
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};

/** aggregate fields of "Locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Locations" */
export type Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Locations_Max_Order_By>;
  min?: InputMaybe<Locations_Min_Order_By>;
  stddev?: InputMaybe<Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Locations" */
export type Locations_Arr_Rel_Insert_Input = {
  data: Array<Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: "Locations_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Locations" */
export type Locations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  Companies?: InputMaybe<Companies_Bool_Exp>;
  Companies_aggregate?: InputMaybe<Companies_Aggregate_Bool_Exp>;
  Contacts?: InputMaybe<Contacts_Bool_Exp>;
  Contacts_aggregate?: InputMaybe<Contacts_Aggregate_Bool_Exp>;
  DealData?: InputMaybe<DealData_Bool_Exp>;
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  State?: InputMaybe<States_Bool_Exp>;
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  postcode?: InputMaybe<Int_Comparison_Exp>;
  state_id?: InputMaybe<Int_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  suburb?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = "Locations_pkey",
}

/** input type for incrementing numeric columns in table "Locations" */
export type Locations_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Locations" */
export type Locations_Insert_Input = {
  Companies?: InputMaybe<Companies_Arr_Rel_Insert_Input>;
  Contacts?: InputMaybe<Contacts_Arr_Rel_Insert_Input>;
  DealData?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  State?: InputMaybe<States_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: "Locations_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Locations" */
export type Locations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: "Locations_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Locations" */
export type Locations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Locations" */
export type Locations_Mutation_Response = {
  __typename?: "Locations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "Locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "Locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "Locations". */
export type Locations_Order_By = {
  Companies_aggregate?: InputMaybe<Companies_Aggregate_Order_By>;
  Contacts_aggregate?: InputMaybe<Contacts_Aggregate_Order_By>;
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  State?: InputMaybe<States_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Locations" */
export enum Locations_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Postcode = "postcode",
  /** column name */
  StateId = "state_id",
  /** column name */
  Street1 = "street1",
  /** column name */
  Street2 = "street2",
  /** column name */
  Suburb = "suburb",
}

/** input type for updating data in table "Locations" */
export type Locations_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: "Locations_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Locations" */
export type Locations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: "Locations_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Locations" */
export type Locations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: "Locations_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Locations" */
export type Locations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  postcode?: InputMaybe<Scalars["Int"]>;
  state_id?: InputMaybe<Scalars["Int"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: "Locations_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  postcode?: Maybe<Scalars["Int"]>;
  state_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Locations" */
export type Locations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** update columns of table "Locations" */
export enum Locations_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Postcode = "postcode",
  /** column name */
  StateId = "state_id",
  /** column name */
  Street1 = "street1",
  /** column name */
  Street2 = "street2",
  /** column name */
  Suburb = "suburb",
}

export type Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: "Locations_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Locations" */
export type Locations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: "Locations_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Locations" */
export type Locations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: "Locations_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["Float"]>;
  state_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Locations" */
export type Locations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Offers" */
export type Offers = {
  __typename?: "Offers";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Offers" */
export type Offers_Aggregate = {
  __typename?: "Offers_aggregate";
  aggregate?: Maybe<Offers_Aggregate_Fields>;
  nodes: Array<Offers>;
};

export type Offers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Offers_Aggregate_Bool_Exp_Count>;
};

export type Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Offers" */
export type Offers_Aggregate_Fields = {
  __typename?: "Offers_aggregate_fields";
  avg?: Maybe<Offers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Offers_Max_Fields>;
  min?: Maybe<Offers_Min_Fields>;
  stddev?: Maybe<Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Offers_Sum_Fields>;
  var_pop?: Maybe<Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Offers_Var_Samp_Fields>;
  variance?: Maybe<Offers_Variance_Fields>;
};

/** aggregate fields of "Offers" */
export type Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Offers" */
export type Offers_Aggregate_Order_By = {
  avg?: InputMaybe<Offers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Offers_Max_Order_By>;
  min?: InputMaybe<Offers_Min_Order_By>;
  stddev?: InputMaybe<Offers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Offers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Offers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Offers_Sum_Order_By>;
  var_pop?: InputMaybe<Offers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Offers_Var_Samp_Order_By>;
  variance?: InputMaybe<Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Offers" */
export type Offers_Arr_Rel_Insert_Input = {
  data: Array<Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type Offers_Avg_Fields = {
  __typename?: "Offers_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Offers" */
export type Offers_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Offers". All fields are combined with a logical 'AND'. */
export type Offers_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Offers_Bool_Exp>>;
  _not?: InputMaybe<Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Offers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Offers" */
export enum Offers_Constraint {
  /** unique or primary key constraint on columns "id" */
  OffersPkey = "Offers_pkey",
}

/** input type for incrementing numeric columns in table "Offers" */
export type Offers_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Offers" */
export type Offers_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Offers_Max_Fields = {
  __typename?: "Offers_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Offers" */
export type Offers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Offers_Min_Fields = {
  __typename?: "Offers_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Offers" */
export type Offers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Offers" */
export type Offers_Mutation_Response = {
  __typename?: "Offers_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Offers>;
};

/** on_conflict condition type for table "Offers" */
export type Offers_On_Conflict = {
  constraint: Offers_Constraint;
  update_columns?: Array<Offers_Update_Column>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "Offers". */
export type Offers_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Offers */
export type Offers_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Offers" */
export enum Offers_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Offers" */
export type Offers_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Offers_Stddev_Fields = {
  __typename?: "Offers_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Offers" */
export type Offers_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Offers_Stddev_Pop_Fields = {
  __typename?: "Offers_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Offers" */
export type Offers_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Offers_Stddev_Samp_Fields = {
  __typename?: "Offers_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Offers" */
export type Offers_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Offers" */
export type Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Offers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Offers_Sum_Fields = {
  __typename?: "Offers_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Offers" */
export type Offers_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Offers" */
export enum Offers_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Offers_Var_Pop_Fields = {
  __typename?: "Offers_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Offers" */
export type Offers_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Offers_Var_Samp_Fields = {
  __typename?: "Offers_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Offers" */
export type Offers_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Offers_Variance_Fields = {
  __typename?: "Offers_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Offers" */
export type Offers_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "PhaseActionItem" */
export type PhaseActionItem = {
  __typename?: "PhaseActionItem";
  /** An object relationship */
  ActionItem?: Maybe<ActionItems>;
  /** An object relationship */
  Phase?: Maybe<Phases>;
  action_item_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  phase_id?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "PhaseActionItem" */
export type PhaseActionItem_Aggregate = {
  __typename?: "PhaseActionItem_aggregate";
  aggregate?: Maybe<PhaseActionItem_Aggregate_Fields>;
  nodes: Array<PhaseActionItem>;
};

export type PhaseActionItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<PhaseActionItem_Aggregate_Bool_Exp_Count>;
};

export type PhaseActionItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<PhaseActionItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PhaseActionItem" */
export type PhaseActionItem_Aggregate_Fields = {
  __typename?: "PhaseActionItem_aggregate_fields";
  avg?: Maybe<PhaseActionItem_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PhaseActionItem_Max_Fields>;
  min?: Maybe<PhaseActionItem_Min_Fields>;
  stddev?: Maybe<PhaseActionItem_Stddev_Fields>;
  stddev_pop?: Maybe<PhaseActionItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PhaseActionItem_Stddev_Samp_Fields>;
  sum?: Maybe<PhaseActionItem_Sum_Fields>;
  var_pop?: Maybe<PhaseActionItem_Var_Pop_Fields>;
  var_samp?: Maybe<PhaseActionItem_Var_Samp_Fields>;
  variance?: Maybe<PhaseActionItem_Variance_Fields>;
};

/** aggregate fields of "PhaseActionItem" */
export type PhaseActionItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "PhaseActionItem" */
export type PhaseActionItem_Aggregate_Order_By = {
  avg?: InputMaybe<PhaseActionItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PhaseActionItem_Max_Order_By>;
  min?: InputMaybe<PhaseActionItem_Min_Order_By>;
  stddev?: InputMaybe<PhaseActionItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PhaseActionItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PhaseActionItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PhaseActionItem_Sum_Order_By>;
  var_pop?: InputMaybe<PhaseActionItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PhaseActionItem_Var_Samp_Order_By>;
  variance?: InputMaybe<PhaseActionItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PhaseActionItem" */
export type PhaseActionItem_Arr_Rel_Insert_Input = {
  data: Array<PhaseActionItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PhaseActionItem_On_Conflict>;
};

/** aggregate avg on columns */
export type PhaseActionItem_Avg_Fields = {
  __typename?: "PhaseActionItem_avg_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Avg_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PhaseActionItem". All fields are combined with a logical 'AND'. */
export type PhaseActionItem_Bool_Exp = {
  ActionItem?: InputMaybe<ActionItems_Bool_Exp>;
  Phase?: InputMaybe<Phases_Bool_Exp>;
  _and?: InputMaybe<Array<PhaseActionItem_Bool_Exp>>;
  _not?: InputMaybe<PhaseActionItem_Bool_Exp>;
  _or?: InputMaybe<Array<PhaseActionItem_Bool_Exp>>;
  action_item_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phase_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "PhaseActionItem" */
export enum PhaseActionItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhaseActionItemPkey = "PhaseActionItem_pkey",
}

/** input type for incrementing numeric columns in table "PhaseActionItem" */
export type PhaseActionItem_Inc_Input = {
  action_item_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "PhaseActionItem" */
export type PhaseActionItem_Insert_Input = {
  ActionItem?: InputMaybe<ActionItems_Obj_Rel_Insert_Input>;
  Phase?: InputMaybe<Phases_Obj_Rel_Insert_Input>;
  action_item_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type PhaseActionItem_Max_Fields = {
  __typename?: "PhaseActionItem_max_fields";
  action_item_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Max_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PhaseActionItem_Min_Fields = {
  __typename?: "PhaseActionItem_min_fields";
  action_item_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Min_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PhaseActionItem" */
export type PhaseActionItem_Mutation_Response = {
  __typename?: "PhaseActionItem_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PhaseActionItem>;
};

/** on_conflict condition type for table "PhaseActionItem" */
export type PhaseActionItem_On_Conflict = {
  constraint: PhaseActionItem_Constraint;
  update_columns?: Array<PhaseActionItem_Update_Column>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

/** Ordering options when selecting data from "PhaseActionItem". */
export type PhaseActionItem_Order_By = {
  ActionItem?: InputMaybe<ActionItems_Order_By>;
  Phase?: InputMaybe<Phases_Order_By>;
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PhaseActionItem */
export type PhaseActionItem_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "PhaseActionItem" */
export enum PhaseActionItem_Select_Column {
  /** column name */
  ActionItemId = "action_item_id",
  /** column name */
  Id = "id",
  /** column name */
  PhaseId = "phase_id",
}

/** input type for updating data in table "PhaseActionItem" */
export type PhaseActionItem_Set_Input = {
  action_item_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type PhaseActionItem_Stddev_Fields = {
  __typename?: "PhaseActionItem_stddev_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Stddev_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PhaseActionItem_Stddev_Pop_Fields = {
  __typename?: "PhaseActionItem_stddev_pop_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Stddev_Pop_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PhaseActionItem_Stddev_Samp_Fields = {
  __typename?: "PhaseActionItem_stddev_samp_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Stddev_Samp_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PhaseActionItem" */
export type PhaseActionItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PhaseActionItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PhaseActionItem_Stream_Cursor_Value_Input = {
  action_item_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  phase_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type PhaseActionItem_Sum_Fields = {
  __typename?: "PhaseActionItem_sum_fields";
  action_item_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  phase_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Sum_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** update columns of table "PhaseActionItem" */
export enum PhaseActionItem_Update_Column {
  /** column name */
  ActionItemId = "action_item_id",
  /** column name */
  Id = "id",
  /** column name */
  PhaseId = "phase_id",
}

export type PhaseActionItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PhaseActionItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PhaseActionItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: PhaseActionItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PhaseActionItem_Var_Pop_Fields = {
  __typename?: "PhaseActionItem_var_pop_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Var_Pop_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PhaseActionItem_Var_Samp_Fields = {
  __typename?: "PhaseActionItem_var_samp_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Var_Samp_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PhaseActionItem_Variance_Fields = {
  __typename?: "PhaseActionItem_variance_fields";
  action_item_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  phase_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "PhaseActionItem" */
export type PhaseActionItem_Variance_Order_By = {
  action_item_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "PhaseDealStatus" */
export type PhaseDealStatus = {
  __typename?: "PhaseDealStatus";
  /** An array relationship */
  DealPhases: Array<DealPhases>;
  /** An aggregate relationship */
  DealPhases_aggregate: DealPhases_Aggregate;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  name: Scalars["String"];
};

/** columns and relationships of "PhaseDealStatus" */
export type PhaseDealStatusDealPhasesArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** columns and relationships of "PhaseDealStatus" */
export type PhaseDealStatusDealPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** aggregated selection of "PhaseDealStatus" */
export type PhaseDealStatus_Aggregate = {
  __typename?: "PhaseDealStatus_aggregate";
  aggregate?: Maybe<PhaseDealStatus_Aggregate_Fields>;
  nodes: Array<PhaseDealStatus>;
};

/** aggregate fields of "PhaseDealStatus" */
export type PhaseDealStatus_Aggregate_Fields = {
  __typename?: "PhaseDealStatus_aggregate_fields";
  avg?: Maybe<PhaseDealStatus_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PhaseDealStatus_Max_Fields>;
  min?: Maybe<PhaseDealStatus_Min_Fields>;
  stddev?: Maybe<PhaseDealStatus_Stddev_Fields>;
  stddev_pop?: Maybe<PhaseDealStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PhaseDealStatus_Stddev_Samp_Fields>;
  sum?: Maybe<PhaseDealStatus_Sum_Fields>;
  var_pop?: Maybe<PhaseDealStatus_Var_Pop_Fields>;
  var_samp?: Maybe<PhaseDealStatus_Var_Samp_Fields>;
  variance?: Maybe<PhaseDealStatus_Variance_Fields>;
};

/** aggregate fields of "PhaseDealStatus" */
export type PhaseDealStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PhaseDealStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type PhaseDealStatus_Avg_Fields = {
  __typename?: "PhaseDealStatus_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "PhaseDealStatus". All fields are combined with a logical 'AND'. */
export type PhaseDealStatus_Bool_Exp = {
  DealPhases?: InputMaybe<DealPhases_Bool_Exp>;
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<PhaseDealStatus_Bool_Exp>>;
  _not?: InputMaybe<PhaseDealStatus_Bool_Exp>;
  _or?: InputMaybe<Array<PhaseDealStatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PhaseDealStatus" */
export enum PhaseDealStatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhaseDealStatusPkey = "PhaseDealStatus_pkey",
}

/** input type for incrementing numeric columns in table "PhaseDealStatus" */
export type PhaseDealStatus_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "PhaseDealStatus" */
export type PhaseDealStatus_Insert_Input = {
  DealPhases?: InputMaybe<DealPhases_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PhaseDealStatus_Max_Fields = {
  __typename?: "PhaseDealStatus_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type PhaseDealStatus_Min_Fields = {
  __typename?: "PhaseDealStatus_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "PhaseDealStatus" */
export type PhaseDealStatus_Mutation_Response = {
  __typename?: "PhaseDealStatus_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PhaseDealStatus>;
};

/** input type for inserting object relation for remote table "PhaseDealStatus" */
export type PhaseDealStatus_Obj_Rel_Insert_Input = {
  data: PhaseDealStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PhaseDealStatus_On_Conflict>;
};

/** on_conflict condition type for table "PhaseDealStatus" */
export type PhaseDealStatus_On_Conflict = {
  constraint: PhaseDealStatus_Constraint;
  update_columns?: Array<PhaseDealStatus_Update_Column>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "PhaseDealStatus". */
export type PhaseDealStatus_Order_By = {
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PhaseDealStatus */
export type PhaseDealStatus_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "PhaseDealStatus" */
export enum PhaseDealStatus_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "PhaseDealStatus" */
export type PhaseDealStatus_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type PhaseDealStatus_Stddev_Fields = {
  __typename?: "PhaseDealStatus_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type PhaseDealStatus_Stddev_Pop_Fields = {
  __typename?: "PhaseDealStatus_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type PhaseDealStatus_Stddev_Samp_Fields = {
  __typename?: "PhaseDealStatus_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "PhaseDealStatus" */
export type PhaseDealStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PhaseDealStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PhaseDealStatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type PhaseDealStatus_Sum_Fields = {
  __typename?: "PhaseDealStatus_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "PhaseDealStatus" */
export enum PhaseDealStatus_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type PhaseDealStatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PhaseDealStatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PhaseDealStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: PhaseDealStatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PhaseDealStatus_Var_Pop_Fields = {
  __typename?: "PhaseDealStatus_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type PhaseDealStatus_Var_Samp_Fields = {
  __typename?: "PhaseDealStatus_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type PhaseDealStatus_Variance_Fields = {
  __typename?: "PhaseDealStatus_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Phases" */
export type Phases = {
  __typename?: "Phases";
  /** An array relationship */
  DealPhases: Array<DealPhases>;
  /** An aggregate relationship */
  DealPhases_aggregate: DealPhases_Aggregate;
  /** An array relationship */
  PhaseActionItem: Array<PhaseActionItem>;
  /** An aggregate relationship */
  PhaseActionItem_aggregate: PhaseActionItem_Aggregate;
  id: Scalars["bigint"];
  is_tenancy_delivery?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  sequence?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "Phases" */
export type PhasesDealPhasesArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** columns and relationships of "Phases" */
export type PhasesDealPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

/** columns and relationships of "Phases" */
export type PhasesPhaseActionItemArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

/** columns and relationships of "Phases" */
export type PhasesPhaseActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

/** aggregated selection of "Phases" */
export type Phases_Aggregate = {
  __typename?: "Phases_aggregate";
  aggregate?: Maybe<Phases_Aggregate_Fields>;
  nodes: Array<Phases>;
};

/** aggregate fields of "Phases" */
export type Phases_Aggregate_Fields = {
  __typename?: "Phases_aggregate_fields";
  avg?: Maybe<Phases_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Phases_Max_Fields>;
  min?: Maybe<Phases_Min_Fields>;
  stddev?: Maybe<Phases_Stddev_Fields>;
  stddev_pop?: Maybe<Phases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phases_Stddev_Samp_Fields>;
  sum?: Maybe<Phases_Sum_Fields>;
  var_pop?: Maybe<Phases_Var_Pop_Fields>;
  var_samp?: Maybe<Phases_Var_Samp_Fields>;
  variance?: Maybe<Phases_Variance_Fields>;
};

/** aggregate fields of "Phases" */
export type Phases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Phases_Avg_Fields = {
  __typename?: "Phases_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Phases". All fields are combined with a logical 'AND'. */
export type Phases_Bool_Exp = {
  DealPhases?: InputMaybe<DealPhases_Bool_Exp>;
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Bool_Exp>;
  PhaseActionItem?: InputMaybe<PhaseActionItem_Bool_Exp>;
  PhaseActionItem_aggregate?: InputMaybe<PhaseActionItem_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Phases_Bool_Exp>>;
  _not?: InputMaybe<Phases_Bool_Exp>;
  _or?: InputMaybe<Array<Phases_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_tenancy_delivery?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Phases" */
export enum Phases_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhasesPkey = "Phases_pkey",
}

/** input type for incrementing numeric columns in table "Phases" */
export type Phases_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Phases" */
export type Phases_Insert_Input = {
  DealPhases?: InputMaybe<DealPhases_Arr_Rel_Insert_Input>;
  PhaseActionItem?: InputMaybe<PhaseActionItem_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Phases_Max_Fields = {
  __typename?: "Phases_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  sequence?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Phases_Min_Fields = {
  __typename?: "Phases_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
  sequence?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "Phases" */
export type Phases_Mutation_Response = {
  __typename?: "Phases_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Phases>;
};

/** input type for inserting object relation for remote table "Phases" */
export type Phases_Obj_Rel_Insert_Input = {
  data: Phases_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Phases_On_Conflict>;
};

/** on_conflict condition type for table "Phases" */
export type Phases_On_Conflict = {
  constraint: Phases_Constraint;
  update_columns?: Array<Phases_Update_Column>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

/** Ordering options when selecting data from "Phases". */
export type Phases_Order_By = {
  DealPhases_aggregate?: InputMaybe<DealPhases_Aggregate_Order_By>;
  PhaseActionItem_aggregate?: InputMaybe<PhaseActionItem_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_tenancy_delivery?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Phases */
export type Phases_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Phases" */
export enum Phases_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
  /** column name */
  Name = "name",
  /** column name */
  Sequence = "sequence",
}

/** input type for updating data in table "Phases" */
export type Phases_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Phases_Stddev_Fields = {
  __typename?: "Phases_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Phases_Stddev_Pop_Fields = {
  __typename?: "Phases_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Phases_Stddev_Samp_Fields = {
  __typename?: "Phases_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Phases" */
export type Phases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Phases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Phases_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  is_tenancy_delivery?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Phases_Sum_Fields = {
  __typename?: "Phases_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  sequence?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Phases" */
export enum Phases_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsTenancyDelivery = "is_tenancy_delivery",
  /** column name */
  Name = "name",
  /** column name */
  Sequence = "sequence",
}

export type Phases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Phases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Phases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Phases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Phases_Var_Pop_Fields = {
  __typename?: "Phases_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Phases_Var_Samp_Fields = {
  __typename?: "Phases_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Phases_Variance_Fields = {
  __typename?: "Phases_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  sequence?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Properties" */
export type Properties = {
  __typename?: "Properties";
  /** An array relationship */
  VirtualUnit: Array<VirtualUnits>;
  /** An aggregate relationship */
  VirtualUnit_aggregate: VirtualUnits_Aggregate;
  /** An array relationship */
  YardiContacts_YardiProperties: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_YardiProperties_aggregate: YardiContacts_YardiProperties_Aggregate;
  /** An object relationship */
  YardiPropertyProfile?: Maybe<YardiPropertyProfiles>;
  attributes?: Maybe<Scalars["jsonb"]>;
  center_under_development?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hub?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Properties" */
export type PropertiesVirtualUnitArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

/** columns and relationships of "Properties" */
export type PropertiesVirtualUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

/** columns and relationships of "Properties" */
export type PropertiesYardiContacts_YardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "Properties" */
export type PropertiesYardiContacts_YardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "Properties" */
export type PropertiesAttributesArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "PropertiesInformation" */
export type PropertiesInformation = {
  __typename?: "PropertiesInformation";
  /** An object relationship */
  Property?: Maybe<YardiProperties>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  embedded_network_toggle?: Maybe<Scalars["bit"]>;
  id: Scalars["bigint"];
  market_impact?: Maybe<Scalars["String"]>;
  modified_at: Scalars["timestamptz"];
  other_toggle?: Maybe<Scalars["bit"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "PropertiesInformation" */
export type PropertiesInformation_Aggregate = {
  __typename?: "PropertiesInformation_aggregate";
  aggregate?: Maybe<PropertiesInformation_Aggregate_Fields>;
  nodes: Array<PropertiesInformation>;
};

/** aggregate fields of "PropertiesInformation" */
export type PropertiesInformation_Aggregate_Fields = {
  __typename?: "PropertiesInformation_aggregate_fields";
  avg?: Maybe<PropertiesInformation_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PropertiesInformation_Max_Fields>;
  min?: Maybe<PropertiesInformation_Min_Fields>;
  stddev?: Maybe<PropertiesInformation_Stddev_Fields>;
  stddev_pop?: Maybe<PropertiesInformation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PropertiesInformation_Stddev_Samp_Fields>;
  sum?: Maybe<PropertiesInformation_Sum_Fields>;
  var_pop?: Maybe<PropertiesInformation_Var_Pop_Fields>;
  var_samp?: Maybe<PropertiesInformation_Var_Samp_Fields>;
  variance?: Maybe<PropertiesInformation_Variance_Fields>;
};

/** aggregate fields of "PropertiesInformation" */
export type PropertiesInformation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PropertiesInformation_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type PropertiesInformation_Avg_Fields = {
  __typename?: "PropertiesInformation_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "PropertiesInformation". All fields are combined with a logical 'AND'. */
export type PropertiesInformation_Bool_Exp = {
  Property?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<PropertiesInformation_Bool_Exp>>;
  _not?: InputMaybe<PropertiesInformation_Bool_Exp>;
  _or?: InputMaybe<Array<PropertiesInformation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  embedded_network_toggle?: InputMaybe<Bit_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  market_impact?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  other_toggle?: InputMaybe<Bit_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PropertiesInformation" */
export enum PropertiesInformation_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertiesInformationPkey = "PropertiesInformation_pkey",
  /** unique or primary key constraint on columns "property_code" */
  PropertiesInformationPropertyCodeKey = "PropertiesInformation_property_code_key",
}

/** input type for incrementing numeric columns in table "PropertiesInformation" */
export type PropertiesInformation_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "PropertiesInformation" */
export type PropertiesInformation_Insert_Input = {
  Property?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  embedded_network_toggle?: InputMaybe<Scalars["bit"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  market_impact?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other_toggle?: InputMaybe<Scalars["bit"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PropertiesInformation_Max_Fields = {
  __typename?: "PropertiesInformation_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  market_impact?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type PropertiesInformation_Min_Fields = {
  __typename?: "PropertiesInformation_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  market_impact?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "PropertiesInformation" */
export type PropertiesInformation_Mutation_Response = {
  __typename?: "PropertiesInformation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PropertiesInformation>;
};

/** input type for inserting object relation for remote table "PropertiesInformation" */
export type PropertiesInformation_Obj_Rel_Insert_Input = {
  data: PropertiesInformation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PropertiesInformation_On_Conflict>;
};

/** on_conflict condition type for table "PropertiesInformation" */
export type PropertiesInformation_On_Conflict = {
  constraint: PropertiesInformation_Constraint;
  update_columns?: Array<PropertiesInformation_Update_Column>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

/** Ordering options when selecting data from "PropertiesInformation". */
export type PropertiesInformation_Order_By = {
  Property?: InputMaybe<YardiProperties_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  embedded_network_toggle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  market_impact?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  other_toggle?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PropertiesInformation */
export type PropertiesInformation_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "PropertiesInformation" */
export enum PropertiesInformation_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Description = "description",
  /** column name */
  EmbeddedNetworkToggle = "embedded_network_toggle",
  /** column name */
  Id = "id",
  /** column name */
  MarketImpact = "market_impact",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  OtherToggle = "other_toggle",
  /** column name */
  PropertyCode = "property_code",
}

/** input type for updating data in table "PropertiesInformation" */
export type PropertiesInformation_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  embedded_network_toggle?: InputMaybe<Scalars["bit"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  market_impact?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other_toggle?: InputMaybe<Scalars["bit"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type PropertiesInformation_Stddev_Fields = {
  __typename?: "PropertiesInformation_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type PropertiesInformation_Stddev_Pop_Fields = {
  __typename?: "PropertiesInformation_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type PropertiesInformation_Stddev_Samp_Fields = {
  __typename?: "PropertiesInformation_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "PropertiesInformation" */
export type PropertiesInformation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PropertiesInformation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PropertiesInformation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  embedded_network_toggle?: InputMaybe<Scalars["bit"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  market_impact?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  other_toggle?: InputMaybe<Scalars["bit"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type PropertiesInformation_Sum_Fields = {
  __typename?: "PropertiesInformation_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "PropertiesInformation" */
export enum PropertiesInformation_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Description = "description",
  /** column name */
  EmbeddedNetworkToggle = "embedded_network_toggle",
  /** column name */
  Id = "id",
  /** column name */
  MarketImpact = "market_impact",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  OtherToggle = "other_toggle",
  /** column name */
  PropertyCode = "property_code",
}

export type PropertiesInformation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PropertiesInformation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PropertiesInformation_Set_Input>;
  /** filter the rows which have to be updated */
  where: PropertiesInformation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PropertiesInformation_Var_Pop_Fields = {
  __typename?: "PropertiesInformation_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type PropertiesInformation_Var_Samp_Fields = {
  __typename?: "PropertiesInformation_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type PropertiesInformation_Variance_Fields = {
  __typename?: "PropertiesInformation_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregated selection of "Properties" */
export type Properties_Aggregate = {
  __typename?: "Properties_aggregate";
  aggregate?: Maybe<Properties_Aggregate_Fields>;
  nodes: Array<Properties>;
};

/** aggregate fields of "Properties" */
export type Properties_Aggregate_Fields = {
  __typename?: "Properties_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Properties_Max_Fields>;
  min?: Maybe<Properties_Min_Fields>;
};

/** aggregate fields of "Properties" */
export type Properties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "Properties". All fields are combined with a logical 'AND'. */
export type Properties_Bool_Exp = {
  VirtualUnit?: InputMaybe<VirtualUnits_Bool_Exp>;
  VirtualUnit_aggregate?: InputMaybe<VirtualUnits_Aggregate_Bool_Exp>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Bool_Exp>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
  _and?: InputMaybe<Array<Properties_Bool_Exp>>;
  _not?: InputMaybe<Properties_Bool_Exp>;
  _or?: InputMaybe<Array<Properties_Bool_Exp>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  center_under_development?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hub?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  suburb?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Properties" */
export type Properties_Insert_Input = {
  VirtualUnit?: InputMaybe<VirtualUnits_Arr_Rel_Insert_Input>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Arr_Rel_Insert_Input>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Obj_Rel_Insert_Input>;
  attributes?: InputMaybe<Scalars["jsonb"]>;
  center_under_development?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  hub?: InputMaybe<Scalars["String"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  notes?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  property_type?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Properties_Max_Fields = {
  __typename?: "Properties_max_fields";
  center_under_development?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hub?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Properties_Min_Fields = {
  __typename?: "Properties_min_fields";
  center_under_development?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hub?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

/** input type for inserting object relation for remote table "Properties" */
export type Properties_Obj_Rel_Insert_Input = {
  data: Properties_Insert_Input;
};

/** Ordering options when selecting data from "Properties". */
export type Properties_Order_By = {
  VirtualUnit_aggregate?: InputMaybe<VirtualUnits_Aggregate_Order_By>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Order_By>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Order_By>;
  attributes?: InputMaybe<Order_By>;
  center_under_development?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  hub?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** select columns of table "Properties" */
export enum Properties_Select_Column {
  /** column name */
  Attributes = "attributes",
  /** column name */
  CenterUnderDevelopment = "center_under_development",
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Hub = "hub",
  /** column name */
  Label = "label",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Notes = "notes",
  /** column name */
  Postcode = "postcode",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  PropertyType = "property_type",
  /** column name */
  State = "state",
  /** column name */
  Status = "status",
  /** column name */
  Street1 = "street1",
  /** column name */
  Street2 = "street2",
  /** column name */
  Suburb = "suburb",
}

/** Streaming cursor of the table "Properties" */
export type Properties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Properties_Stream_Cursor_Value_Input = {
  attributes?: InputMaybe<Scalars["jsonb"]>;
  center_under_development?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  hub?: InputMaybe<Scalars["String"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  notes?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  property_type?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "PropertyContacts" */
export type PropertyContacts = {
  __typename?: "PropertyContacts";
  /** An object relationship */
  Contact?: Maybe<Contacts>;
  /** An object relationship */
  Property?: Maybe<YardiProperties>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "PropertyContacts" */
export type PropertyContacts_Aggregate = {
  __typename?: "PropertyContacts_aggregate";
  aggregate?: Maybe<PropertyContacts_Aggregate_Fields>;
  nodes: Array<PropertyContacts>;
};

export type PropertyContacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<PropertyContacts_Aggregate_Bool_Exp_Count>;
};

export type PropertyContacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<PropertyContacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PropertyContacts" */
export type PropertyContacts_Aggregate_Fields = {
  __typename?: "PropertyContacts_aggregate_fields";
  avg?: Maybe<PropertyContacts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PropertyContacts_Max_Fields>;
  min?: Maybe<PropertyContacts_Min_Fields>;
  stddev?: Maybe<PropertyContacts_Stddev_Fields>;
  stddev_pop?: Maybe<PropertyContacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PropertyContacts_Stddev_Samp_Fields>;
  sum?: Maybe<PropertyContacts_Sum_Fields>;
  var_pop?: Maybe<PropertyContacts_Var_Pop_Fields>;
  var_samp?: Maybe<PropertyContacts_Var_Samp_Fields>;
  variance?: Maybe<PropertyContacts_Variance_Fields>;
};

/** aggregate fields of "PropertyContacts" */
export type PropertyContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "PropertyContacts" */
export type PropertyContacts_Aggregate_Order_By = {
  avg?: InputMaybe<PropertyContacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PropertyContacts_Max_Order_By>;
  min?: InputMaybe<PropertyContacts_Min_Order_By>;
  stddev?: InputMaybe<PropertyContacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PropertyContacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PropertyContacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PropertyContacts_Sum_Order_By>;
  var_pop?: InputMaybe<PropertyContacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PropertyContacts_Var_Samp_Order_By>;
  variance?: InputMaybe<PropertyContacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PropertyContacts" */
export type PropertyContacts_Arr_Rel_Insert_Input = {
  data: Array<PropertyContacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PropertyContacts_On_Conflict>;
};

/** aggregate avg on columns */
export type PropertyContacts_Avg_Fields = {
  __typename?: "PropertyContacts_avg_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "PropertyContacts" */
export type PropertyContacts_Avg_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PropertyContacts". All fields are combined with a logical 'AND'. */
export type PropertyContacts_Bool_Exp = {
  Contact?: InputMaybe<Contacts_Bool_Exp>;
  Property?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<PropertyContacts_Bool_Exp>>;
  _not?: InputMaybe<PropertyContacts_Bool_Exp>;
  _or?: InputMaybe<Array<PropertyContacts_Bool_Exp>>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PropertyContacts" */
export enum PropertyContacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyContactsPkey = "PropertyContacts_pkey",
}

/** input type for incrementing numeric columns in table "PropertyContacts" */
export type PropertyContacts_Inc_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "PropertyContacts" */
export type PropertyContacts_Insert_Input = {
  Contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  Property?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PropertyContacts_Max_Fields = {
  __typename?: "PropertyContacts_max_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "PropertyContacts" */
export type PropertyContacts_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PropertyContacts_Min_Fields = {
  __typename?: "PropertyContacts_min_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "PropertyContacts" */
export type PropertyContacts_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PropertyContacts" */
export type PropertyContacts_Mutation_Response = {
  __typename?: "PropertyContacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PropertyContacts>;
};

/** on_conflict condition type for table "PropertyContacts" */
export type PropertyContacts_On_Conflict = {
  constraint: PropertyContacts_Constraint;
  update_columns?: Array<PropertyContacts_Update_Column>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "PropertyContacts". */
export type PropertyContacts_Order_By = {
  Contact?: InputMaybe<Contacts_Order_By>;
  Property?: InputMaybe<YardiProperties_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PropertyContacts */
export type PropertyContacts_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "PropertyContacts" */
export enum PropertyContacts_Select_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
}

/** input type for updating data in table "PropertyContacts" */
export type PropertyContacts_Set_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type PropertyContacts_Stddev_Fields = {
  __typename?: "PropertyContacts_stddev_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "PropertyContacts" */
export type PropertyContacts_Stddev_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PropertyContacts_Stddev_Pop_Fields = {
  __typename?: "PropertyContacts_stddev_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "PropertyContacts" */
export type PropertyContacts_Stddev_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PropertyContacts_Stddev_Samp_Fields = {
  __typename?: "PropertyContacts_stddev_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "PropertyContacts" */
export type PropertyContacts_Stddev_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PropertyContacts" */
export type PropertyContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PropertyContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PropertyContacts_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type PropertyContacts_Sum_Fields = {
  __typename?: "PropertyContacts_sum_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "PropertyContacts" */
export type PropertyContacts_Sum_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "PropertyContacts" */
export enum PropertyContacts_Update_Column {
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
}

export type PropertyContacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PropertyContacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PropertyContacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: PropertyContacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PropertyContacts_Var_Pop_Fields = {
  __typename?: "PropertyContacts_var_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "PropertyContacts" */
export type PropertyContacts_Var_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PropertyContacts_Var_Samp_Fields = {
  __typename?: "PropertyContacts_var_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "PropertyContacts" */
export type PropertyContacts_Var_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PropertyContacts_Variance_Fields = {
  __typename?: "PropertyContacts_variance_fields";
  contact_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "PropertyContacts" */
export type PropertyContacts_Variance_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "PropertyFileTypes" */
export type PropertyFileTypes = {
  __typename?: "PropertyFileTypes";
  comment?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** aggregated selection of "PropertyFileTypes" */
export type PropertyFileTypes_Aggregate = {
  __typename?: "PropertyFileTypes_aggregate";
  aggregate?: Maybe<PropertyFileTypes_Aggregate_Fields>;
  nodes: Array<PropertyFileTypes>;
};

/** aggregate fields of "PropertyFileTypes" */
export type PropertyFileTypes_Aggregate_Fields = {
  __typename?: "PropertyFileTypes_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<PropertyFileTypes_Max_Fields>;
  min?: Maybe<PropertyFileTypes_Min_Fields>;
};

/** aggregate fields of "PropertyFileTypes" */
export type PropertyFileTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PropertyFileTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "PropertyFileTypes". All fields are combined with a logical 'AND'. */
export type PropertyFileTypes_Bool_Exp = {
  _and?: InputMaybe<Array<PropertyFileTypes_Bool_Exp>>;
  _not?: InputMaybe<PropertyFileTypes_Bool_Exp>;
  _or?: InputMaybe<Array<PropertyFileTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PropertyFileTypes" */
export enum PropertyFileTypes_Constraint {
  /** unique or primary key constraint on columns "name" */
  PropertyFileTypesPkey = "PropertyFileTypes_pkey",
}

export enum PropertyFileTypes_Enum {
  /** Centre map */
  CentreMap = "centre_map",
  /** Legislation */
  Legislation = "legislation",
}

/** Boolean expression to compare columns of type "PropertyFileTypes_enum". All fields are combined with logical 'AND'. */
export type PropertyFileTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<PropertyFileTypes_Enum>;
  _in?: InputMaybe<Array<PropertyFileTypes_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<PropertyFileTypes_Enum>;
  _nin?: InputMaybe<Array<PropertyFileTypes_Enum>>;
};

/** input type for inserting data into table "PropertyFileTypes" */
export type PropertyFileTypes_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type PropertyFileTypes_Max_Fields = {
  __typename?: "PropertyFileTypes_max_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type PropertyFileTypes_Min_Fields = {
  __typename?: "PropertyFileTypes_min_fields";
  comment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "PropertyFileTypes" */
export type PropertyFileTypes_Mutation_Response = {
  __typename?: "PropertyFileTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PropertyFileTypes>;
};

/** on_conflict condition type for table "PropertyFileTypes" */
export type PropertyFileTypes_On_Conflict = {
  constraint: PropertyFileTypes_Constraint;
  update_columns?: Array<PropertyFileTypes_Update_Column>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "PropertyFileTypes". */
export type PropertyFileTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PropertyFileTypes */
export type PropertyFileTypes_Pk_Columns_Input = {
  name: Scalars["String"];
};

/** select columns of table "PropertyFileTypes" */
export enum PropertyFileTypes_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "PropertyFileTypes" */
export type PropertyFileTypes_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "PropertyFileTypes" */
export type PropertyFileTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PropertyFileTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PropertyFileTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "PropertyFileTypes" */
export enum PropertyFileTypes_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Name = "name",
}

export type PropertyFileTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PropertyFileTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: PropertyFileTypes_Bool_Exp;
};

/** columns and relationships of "PropertyFiles" */
export type PropertyFiles = {
  __typename?: "PropertyFiles";
  /** An object relationship */
  Property?: Maybe<YardiProperties>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_name: Scalars["String"];
  file_path: Scalars["String"];
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  type?: Maybe<PropertyFileTypes_Enum>;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "PropertyFiles" */
export type PropertyFiles_Aggregate = {
  __typename?: "PropertyFiles_aggregate";
  aggregate?: Maybe<PropertyFiles_Aggregate_Fields>;
  nodes: Array<PropertyFiles>;
};

export type PropertyFiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<PropertyFiles_Aggregate_Bool_Exp_Count>;
};

export type PropertyFiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<PropertyFiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PropertyFiles" */
export type PropertyFiles_Aggregate_Fields = {
  __typename?: "PropertyFiles_aggregate_fields";
  avg?: Maybe<PropertyFiles_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PropertyFiles_Max_Fields>;
  min?: Maybe<PropertyFiles_Min_Fields>;
  stddev?: Maybe<PropertyFiles_Stddev_Fields>;
  stddev_pop?: Maybe<PropertyFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PropertyFiles_Stddev_Samp_Fields>;
  sum?: Maybe<PropertyFiles_Sum_Fields>;
  var_pop?: Maybe<PropertyFiles_Var_Pop_Fields>;
  var_samp?: Maybe<PropertyFiles_Var_Samp_Fields>;
  variance?: Maybe<PropertyFiles_Variance_Fields>;
};

/** aggregate fields of "PropertyFiles" */
export type PropertyFiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "PropertyFiles" */
export type PropertyFiles_Aggregate_Order_By = {
  avg?: InputMaybe<PropertyFiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PropertyFiles_Max_Order_By>;
  min?: InputMaybe<PropertyFiles_Min_Order_By>;
  stddev?: InputMaybe<PropertyFiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PropertyFiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PropertyFiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PropertyFiles_Sum_Order_By>;
  var_pop?: InputMaybe<PropertyFiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PropertyFiles_Var_Samp_Order_By>;
  variance?: InputMaybe<PropertyFiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PropertyFiles" */
export type PropertyFiles_Arr_Rel_Insert_Input = {
  data: Array<PropertyFiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PropertyFiles_On_Conflict>;
};

/** aggregate avg on columns */
export type PropertyFiles_Avg_Fields = {
  __typename?: "PropertyFiles_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "PropertyFiles" */
export type PropertyFiles_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PropertyFiles". All fields are combined with a logical 'AND'. */
export type PropertyFiles_Bool_Exp = {
  Property?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<PropertyFiles_Bool_Exp>>;
  _not?: InputMaybe<PropertyFiles_Bool_Exp>;
  _or?: InputMaybe<Array<PropertyFiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<PropertyFileTypes_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "PropertyFiles" */
export enum PropertyFiles_Constraint {
  /** unique or primary key constraint on columns "file_path" */
  PropertyFilesFilePathKey = "PropertyFiles_file_path_key",
  /** unique or primary key constraint on columns "id" */
  PropertyFilesPkey = "PropertyFiles_pkey",
}

/** input type for incrementing numeric columns in table "PropertyFiles" */
export type PropertyFiles_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "PropertyFiles" */
export type PropertyFiles_Insert_Input = {
  Property?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PropertyFileTypes_Enum>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type PropertyFiles_Max_Fields = {
  __typename?: "PropertyFiles_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "PropertyFiles" */
export type PropertyFiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PropertyFiles_Min_Fields = {
  __typename?: "PropertyFiles_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_name?: Maybe<Scalars["String"]>;
  file_path?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "PropertyFiles" */
export type PropertyFiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PropertyFiles" */
export type PropertyFiles_Mutation_Response = {
  __typename?: "PropertyFiles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PropertyFiles>;
};

/** on_conflict condition type for table "PropertyFiles" */
export type PropertyFiles_On_Conflict = {
  constraint: PropertyFiles_Constraint;
  update_columns?: Array<PropertyFiles_Update_Column>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

/** Ordering options when selecting data from "PropertyFiles". */
export type PropertyFiles_Order_By = {
  Property?: InputMaybe<YardiProperties_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PropertyFiles */
export type PropertyFiles_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "PropertyFiles" */
export enum PropertyFiles_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  Type = "type",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "PropertyFiles" */
export type PropertyFiles_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PropertyFileTypes_Enum>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type PropertyFiles_Stddev_Fields = {
  __typename?: "PropertyFiles_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "PropertyFiles" */
export type PropertyFiles_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PropertyFiles_Stddev_Pop_Fields = {
  __typename?: "PropertyFiles_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "PropertyFiles" */
export type PropertyFiles_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PropertyFiles_Stddev_Samp_Fields = {
  __typename?: "PropertyFiles_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "PropertyFiles" */
export type PropertyFiles_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PropertyFiles" */
export type PropertyFiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PropertyFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PropertyFiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_path?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<PropertyFileTypes_Enum>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type PropertyFiles_Sum_Fields = {
  __typename?: "PropertyFiles_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "PropertyFiles" */
export type PropertyFiles_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "PropertyFiles" */
export enum PropertyFiles_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FileName = "file_name",
  /** column name */
  FilePath = "file_path",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  Type = "type",
  /** column name */
  UserId = "user_id",
}

export type PropertyFiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PropertyFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PropertyFiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: PropertyFiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PropertyFiles_Var_Pop_Fields = {
  __typename?: "PropertyFiles_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "PropertyFiles" */
export type PropertyFiles_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PropertyFiles_Var_Samp_Fields = {
  __typename?: "PropertyFiles_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "PropertyFiles" */
export type PropertyFiles_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PropertyFiles_Variance_Fields = {
  __typename?: "PropertyFiles_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "PropertyFiles" */
export type PropertyFiles_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Proposals" */
export type Proposals = {
  __typename?: "Proposals";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Proposals" */
export type Proposals_Aggregate = {
  __typename?: "Proposals_aggregate";
  aggregate?: Maybe<Proposals_Aggregate_Fields>;
  nodes: Array<Proposals>;
};

export type Proposals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Proposals_Aggregate_Bool_Exp_Count>;
};

export type Proposals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Proposals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Proposals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Proposals" */
export type Proposals_Aggregate_Fields = {
  __typename?: "Proposals_aggregate_fields";
  avg?: Maybe<Proposals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Proposals_Max_Fields>;
  min?: Maybe<Proposals_Min_Fields>;
  stddev?: Maybe<Proposals_Stddev_Fields>;
  stddev_pop?: Maybe<Proposals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Proposals_Stddev_Samp_Fields>;
  sum?: Maybe<Proposals_Sum_Fields>;
  var_pop?: Maybe<Proposals_Var_Pop_Fields>;
  var_samp?: Maybe<Proposals_Var_Samp_Fields>;
  variance?: Maybe<Proposals_Variance_Fields>;
};

/** aggregate fields of "Proposals" */
export type Proposals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Proposals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Proposals" */
export type Proposals_Aggregate_Order_By = {
  avg?: InputMaybe<Proposals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Proposals_Max_Order_By>;
  min?: InputMaybe<Proposals_Min_Order_By>;
  stddev?: InputMaybe<Proposals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Proposals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Proposals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Proposals_Sum_Order_By>;
  var_pop?: InputMaybe<Proposals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Proposals_Var_Samp_Order_By>;
  variance?: InputMaybe<Proposals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Proposals" */
export type Proposals_Arr_Rel_Insert_Input = {
  data: Array<Proposals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Proposals_On_Conflict>;
};

/** aggregate avg on columns */
export type Proposals_Avg_Fields = {
  __typename?: "Proposals_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Proposals" */
export type Proposals_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Proposals". All fields are combined with a logical 'AND'. */
export type Proposals_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Proposals_Bool_Exp>>;
  _not?: InputMaybe<Proposals_Bool_Exp>;
  _or?: InputMaybe<Array<Proposals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Proposals" */
export enum Proposals_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProposalsPkey = "Proposals_pkey",
}

/** input type for incrementing numeric columns in table "Proposals" */
export type Proposals_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Proposals" */
export type Proposals_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Proposals_Max_Fields = {
  __typename?: "Proposals_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Proposals" */
export type Proposals_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Proposals_Min_Fields = {
  __typename?: "Proposals_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Proposals" */
export type Proposals_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Proposals" */
export type Proposals_Mutation_Response = {
  __typename?: "Proposals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Proposals>;
};

/** on_conflict condition type for table "Proposals" */
export type Proposals_On_Conflict = {
  constraint: Proposals_Constraint;
  update_columns?: Array<Proposals_Update_Column>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

/** Ordering options when selecting data from "Proposals". */
export type Proposals_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Proposals */
export type Proposals_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Proposals" */
export enum Proposals_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Proposals" */
export type Proposals_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Proposals_Stddev_Fields = {
  __typename?: "Proposals_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Proposals" */
export type Proposals_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Proposals_Stddev_Pop_Fields = {
  __typename?: "Proposals_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Proposals" */
export type Proposals_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Proposals_Stddev_Samp_Fields = {
  __typename?: "Proposals_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Proposals" */
export type Proposals_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Proposals" */
export type Proposals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Proposals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Proposals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Proposals_Sum_Fields = {
  __typename?: "Proposals_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Proposals" */
export type Proposals_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Proposals" */
export enum Proposals_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Proposals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Proposals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Proposals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Proposals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Proposals_Var_Pop_Fields = {
  __typename?: "Proposals_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Proposals" */
export type Proposals_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Proposals_Var_Samp_Fields = {
  __typename?: "Proposals_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Proposals" */
export type Proposals_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Proposals_Variance_Fields = {
  __typename?: "Proposals_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Proposals" */
export type Proposals_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type QuestionnaireDeleteOutput = {
  __typename?: "QuestionnaireDeleteOutput";
  message?: Maybe<Scalars["String"]>;
};

export type QueueCounts = {
  __typename?: "QueueCounts";
  egest?: Maybe<JobCounts>;
  ingest?: Maybe<JobCounts>;
  persistence?: Maybe<JobCounts>;
};

/** columns and relationships of "Registrations" */
export type Registrations = {
  __typename?: "Registrations";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Registrations" */
export type Registrations_Aggregate = {
  __typename?: "Registrations_aggregate";
  aggregate?: Maybe<Registrations_Aggregate_Fields>;
  nodes: Array<Registrations>;
};

export type Registrations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Registrations_Aggregate_Bool_Exp_Count>;
};

export type Registrations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Registrations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Registrations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Registrations" */
export type Registrations_Aggregate_Fields = {
  __typename?: "Registrations_aggregate_fields";
  avg?: Maybe<Registrations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Registrations_Max_Fields>;
  min?: Maybe<Registrations_Min_Fields>;
  stddev?: Maybe<Registrations_Stddev_Fields>;
  stddev_pop?: Maybe<Registrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registrations_Stddev_Samp_Fields>;
  sum?: Maybe<Registrations_Sum_Fields>;
  var_pop?: Maybe<Registrations_Var_Pop_Fields>;
  var_samp?: Maybe<Registrations_Var_Samp_Fields>;
  variance?: Maybe<Registrations_Variance_Fields>;
};

/** aggregate fields of "Registrations" */
export type Registrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Registrations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Registrations" */
export type Registrations_Aggregate_Order_By = {
  avg?: InputMaybe<Registrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Registrations_Max_Order_By>;
  min?: InputMaybe<Registrations_Min_Order_By>;
  stddev?: InputMaybe<Registrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Registrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Registrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Registrations_Sum_Order_By>;
  var_pop?: InputMaybe<Registrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Registrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Registrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Registrations" */
export type Registrations_Arr_Rel_Insert_Input = {
  data: Array<Registrations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Registrations_On_Conflict>;
};

/** aggregate avg on columns */
export type Registrations_Avg_Fields = {
  __typename?: "Registrations_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Registrations" */
export type Registrations_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Registrations". All fields are combined with a logical 'AND'. */
export type Registrations_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Registrations_Bool_Exp>>;
  _not?: InputMaybe<Registrations_Bool_Exp>;
  _or?: InputMaybe<Array<Registrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Registrations" */
export enum Registrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationsPkey = "Registrations_pkey",
}

/** input type for incrementing numeric columns in table "Registrations" */
export type Registrations_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Registrations" */
export type Registrations_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Registrations_Max_Fields = {
  __typename?: "Registrations_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Registrations" */
export type Registrations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Registrations_Min_Fields = {
  __typename?: "Registrations_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Registrations" */
export type Registrations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Registrations" */
export type Registrations_Mutation_Response = {
  __typename?: "Registrations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Registrations>;
};

/** on_conflict condition type for table "Registrations" */
export type Registrations_On_Conflict = {
  constraint: Registrations_Constraint;
  update_columns?: Array<Registrations_Update_Column>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

/** Ordering options when selecting data from "Registrations". */
export type Registrations_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Registrations */
export type Registrations_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Registrations" */
export enum Registrations_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Registrations" */
export type Registrations_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Registrations_Stddev_Fields = {
  __typename?: "Registrations_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Registrations" */
export type Registrations_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Registrations_Stddev_Pop_Fields = {
  __typename?: "Registrations_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Registrations" */
export type Registrations_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Registrations_Stddev_Samp_Fields = {
  __typename?: "Registrations_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Registrations" */
export type Registrations_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Registrations" */
export type Registrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Registrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Registrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Registrations_Sum_Fields = {
  __typename?: "Registrations_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Registrations" */
export type Registrations_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Registrations" */
export enum Registrations_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Registrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Registrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Registrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Registrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Registrations_Var_Pop_Fields = {
  __typename?: "Registrations_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Registrations" */
export type Registrations_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Registrations_Var_Samp_Fields = {
  __typename?: "Registrations_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Registrations" */
export type Registrations_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Registrations_Variance_Fields = {
  __typename?: "Registrations_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Registrations" */
export type Registrations_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type ResponseContext = {
  __typename?: "ResponseContext";
  messages: Array<Maybe<ContextMessage>>;
};

/** columns and relationships of "SalesType" */
export type SalesType = {
  __typename?: "SalesType";
  description: Scalars["String"];
  name: Scalars["String"];
};

/** aggregated selection of "SalesType" */
export type SalesType_Aggregate = {
  __typename?: "SalesType_aggregate";
  aggregate?: Maybe<SalesType_Aggregate_Fields>;
  nodes: Array<SalesType>;
};

/** aggregate fields of "SalesType" */
export type SalesType_Aggregate_Fields = {
  __typename?: "SalesType_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<SalesType_Max_Fields>;
  min?: Maybe<SalesType_Min_Fields>;
};

/** aggregate fields of "SalesType" */
export type SalesType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SalesType_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "SalesType". All fields are combined with a logical 'AND'. */
export type SalesType_Bool_Exp = {
  _and?: InputMaybe<Array<SalesType_Bool_Exp>>;
  _not?: InputMaybe<SalesType_Bool_Exp>;
  _or?: InputMaybe<Array<SalesType_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SalesType" */
export enum SalesType_Constraint {
  /** unique or primary key constraint on columns "name" */
  SalesTypePkey = "SalesType_pkey",
}

export enum SalesType_Enum {
  /** Children's Apparel */
  Appchild = "appchild",
  /** Men's Apparel */
  Appmen = "appmen",
  /** Men's & Women's Apparel */
  Apprluni = "apprluni",
  /** Women's Apparel */
  Appwomen = "appwomen",
  /** Automotive Accessories */
  Automot = "automot",
  /** Bags/Luggage */
  Bags = "bags",
  /** Bakery/Cakes/Pastries */
  Bakery = "bakery",
  /** Books */
  Books = "books",
  /** Butcher */
  Butcher = "butcher",
  /** Cafes and Restaurants */
  Caferest = "caferest",
  /** Cinemas */
  Cinema = "cinema",
  /** Cosmetics */
  Cosmetic = "cosmetic",
  /** Delicatessen */
  Deli = "deli",
  /** Discount Department Store */
  Discdep = "discdep",
  /** Discount Variety */
  Discount = "discount",
  /** Dry Cleaners */
  Dryclean = "dryclean",
  /** Electrical/Sound/Computers */
  Electric = "electric",
  /** Fashion Accessories */
  Fashnacc = "fashnacc",
  /** Film Processing/Photography */
  Filmproc = "filmproc",
  Florists = "florists",
  /** Footwear */
  Footwear = "footwear",
  /** Fresh Seafood */
  Frseafd = "frseafd",
  /** Fruit and Vegetables */
  Fruitveg = "fruitveg",
  /** Furniture/Hardware */
  Furnitur = "furnitur",
  /** General Retail */
  Genretai = "genretai",
  /** Giftware */
  Gifts = "gifts",
  /** Gymnasium */
  Gym = "gym",
  /** Hairdessing/Beauty&Nails */
  Hairdres = "hairdres",
  /** Homewares */
  Homeware = "homeware",
  /** Jeaneries & Unisex */
  Jeansuni = "jeansuni",
  /** Jewellery */
  Jewel = "jewel",
  /** Key Cutting/Shoe Repairs/Watch Repairs */
  Keywatch = "keywatch",
  /** Liquor */
  Liquor = "liquor",
  /** Lotto */
  Lotto = "lotto",
  /** Majors */
  Major = "major",
  /** Manchester */
  Manchest = "manchest",
  /** Mini-Major */
  Minimajo = "minimajo",
  /** Phone & Mobile Communications */
  Mobileph = "mobileph",
  /** Music/Video/Games */
  Musicgam = "musicgam",
  /** Newsagents & Stationary */
  News = "news",
  /** Non Retail */
  Nonretai = "nonretai",
  /** Optometrist */
  Optometr = "optometr",
  /** Other Entertainment */
  Othenter = "othenter",
  /** Other Retail */
  Otherret = "otherret",
  /** Other Retail (incl Sales Reporting Pad Sites) */
  Othrtlpa = "othrtlpa",
  /** Other Retail Services */
  Othrtlsv = "othrtlsv",
  /** Other Speciality Food */
  Othspecl = "othspecl",
  /** Pharmacy */
  Pharm = "pharm",
  /** Poultry */
  Poultry = "poultry",
  /** Online Supermarket */
  Smonline = "smonline",
  /** Sporting Goods */
  Sport = "sport",
  /** Supermarket */
  Supermkt = "supermkt",
  /** Takeaway Food */
  Takefood = "takefood",
  /** Travel Agents */
  Travel = "travel",
}

/** Boolean expression to compare columns of type "SalesType_enum". All fields are combined with logical 'AND'. */
export type SalesType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<SalesType_Enum>;
  _in?: InputMaybe<Array<SalesType_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<SalesType_Enum>;
  _nin?: InputMaybe<Array<SalesType_Enum>>;
};

/** input type for inserting data into table "SalesType" */
export type SalesType_Insert_Input = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type SalesType_Max_Fields = {
  __typename?: "SalesType_max_fields";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type SalesType_Min_Fields = {
  __typename?: "SalesType_min_fields";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "SalesType" */
export type SalesType_Mutation_Response = {
  __typename?: "SalesType_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<SalesType>;
};

/** on_conflict condition type for table "SalesType" */
export type SalesType_On_Conflict = {
  constraint: SalesType_Constraint;
  update_columns?: Array<SalesType_Update_Column>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

/** Ordering options when selecting data from "SalesType". */
export type SalesType_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SalesType */
export type SalesType_Pk_Columns_Input = {
  name: Scalars["String"];
};

/** select columns of table "SalesType" */
export enum SalesType_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "SalesType" */
export type SalesType_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "SalesType" */
export type SalesType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SalesType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesType_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "SalesType" */
export enum SalesType_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Name = "name",
}

export type SalesType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesType_Set_Input>;
  /** filter the rows which have to be updated */
  where: SalesType_Bool_Exp;
};

/** columns and relationships of "States" */
export type States = {
  __typename?: "States";
  /** An object relationship */
  Country?: Maybe<Countries>;
  /** An array relationship */
  DealDataGuarantors: Array<DealDataGuarantors>;
  /** An aggregate relationship */
  DealDataGuarantors_aggregate: DealDataGuarantors_Aggregate;
  /** An array relationship */
  Locations: Array<Locations>;
  /** An aggregate relationship */
  Locations_aggregate: Locations_Aggregate;
  country_id?: Maybe<Scalars["Int"]>;
  id: Scalars["bigint"];
  name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "States" */
export type StatesDealDataGuarantorsArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

/** columns and relationships of "States" */
export type StatesDealDataGuarantors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

/** columns and relationships of "States" */
export type StatesLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** columns and relationships of "States" */
export type StatesLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** aggregated selection of "States" */
export type States_Aggregate = {
  __typename?: "States_aggregate";
  aggregate?: Maybe<States_Aggregate_Fields>;
  nodes: Array<States>;
};

export type States_Aggregate_Bool_Exp = {
  count?: InputMaybe<States_Aggregate_Bool_Exp_Count>;
};

export type States_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<States_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<States_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "States" */
export type States_Aggregate_Fields = {
  __typename?: "States_aggregate_fields";
  avg?: Maybe<States_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<States_Max_Fields>;
  min?: Maybe<States_Min_Fields>;
  stddev?: Maybe<States_Stddev_Fields>;
  stddev_pop?: Maybe<States_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<States_Stddev_Samp_Fields>;
  sum?: Maybe<States_Sum_Fields>;
  var_pop?: Maybe<States_Var_Pop_Fields>;
  var_samp?: Maybe<States_Var_Samp_Fields>;
  variance?: Maybe<States_Variance_Fields>;
};

/** aggregate fields of "States" */
export type States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<States_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "States" */
export type States_Aggregate_Order_By = {
  avg?: InputMaybe<States_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<States_Max_Order_By>;
  min?: InputMaybe<States_Min_Order_By>;
  stddev?: InputMaybe<States_Stddev_Order_By>;
  stddev_pop?: InputMaybe<States_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<States_Stddev_Samp_Order_By>;
  sum?: InputMaybe<States_Sum_Order_By>;
  var_pop?: InputMaybe<States_Var_Pop_Order_By>;
  var_samp?: InputMaybe<States_Var_Samp_Order_By>;
  variance?: InputMaybe<States_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "States" */
export type States_Arr_Rel_Insert_Input = {
  data: Array<States_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<States_On_Conflict>;
};

/** aggregate avg on columns */
export type States_Avg_Fields = {
  __typename?: "States_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "States" */
export type States_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "States". All fields are combined with a logical 'AND'. */
export type States_Bool_Exp = {
  Country?: InputMaybe<Countries_Bool_Exp>;
  DealDataGuarantors?: InputMaybe<DealDataGuarantors_Bool_Exp>;
  DealDataGuarantors_aggregate?: InputMaybe<DealDataGuarantors_Aggregate_Bool_Exp>;
  Locations?: InputMaybe<Locations_Bool_Exp>;
  Locations_aggregate?: InputMaybe<Locations_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<States_Bool_Exp>>;
  _not?: InputMaybe<States_Bool_Exp>;
  _or?: InputMaybe<Array<States_Bool_Exp>>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "States" */
export enum States_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatesPkey = "States_pkey",
}

/** input type for incrementing numeric columns in table "States" */
export type States_Inc_Input = {
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "States" */
export type States_Insert_Input = {
  Country?: InputMaybe<Countries_Obj_Rel_Insert_Input>;
  DealDataGuarantors?: InputMaybe<DealDataGuarantors_Arr_Rel_Insert_Input>;
  Locations?: InputMaybe<Locations_Arr_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type States_Max_Fields = {
  __typename?: "States_max_fields";
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "States" */
export type States_Max_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type States_Min_Fields = {
  __typename?: "States_min_fields";
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "States" */
export type States_Min_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "States" */
export type States_Mutation_Response = {
  __typename?: "States_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<States>;
};

/** input type for inserting object relation for remote table "States" */
export type States_Obj_Rel_Insert_Input = {
  data: States_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<States_On_Conflict>;
};

/** on_conflict condition type for table "States" */
export type States_On_Conflict = {
  constraint: States_Constraint;
  update_columns?: Array<States_Update_Column>;
  where?: InputMaybe<States_Bool_Exp>;
};

/** Ordering options when selecting data from "States". */
export type States_Order_By = {
  Country?: InputMaybe<Countries_Order_By>;
  DealDataGuarantors_aggregate?: InputMaybe<DealDataGuarantors_Aggregate_Order_By>;
  Locations_aggregate?: InputMaybe<Locations_Aggregate_Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: States */
export type States_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "States" */
export enum States_Select_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "States" */
export type States_Set_Input = {
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type States_Stddev_Fields = {
  __typename?: "States_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "States" */
export type States_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type States_Stddev_Pop_Fields = {
  __typename?: "States_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "States" */
export type States_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type States_Stddev_Samp_Fields = {
  __typename?: "States_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "States" */
export type States_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "States" */
export type States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type States_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type States_Sum_Fields = {
  __typename?: "States_sum_fields";
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "States" */
export type States_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "States" */
export enum States_Update_Column {
  /** column name */
  CountryId = "country_id",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type States_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<States_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<States_Set_Input>;
  /** filter the rows which have to be updated */
  where: States_Bool_Exp;
};

/** aggregate var_pop on columns */
export type States_Var_Pop_Fields = {
  __typename?: "States_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "States" */
export type States_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type States_Var_Samp_Fields = {
  __typename?: "States_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "States" */
export type States_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type States_Variance_Fields = {
  __typename?: "States_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "States" */
export type States_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "Tags" */
export type Tags = {
  __typename?: "Tags";
  /** An array relationship */
  CommentTags: Array<CommentTags>;
  /** An aggregate relationship */
  CommentTags_aggregate: CommentTags_Aggregate;
  /** An array relationship */
  DealFileTags: Array<DealFileTags>;
  /** An aggregate relationship */
  DealFileTags_aggregate: DealFileTags_Aggregate;
  id: Scalars["bigint"];
  name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Tags" */
export type TagsCommentTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

/** columns and relationships of "Tags" */
export type TagsCommentTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

/** columns and relationships of "Tags" */
export type TagsDealFileTagsArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

/** columns and relationships of "Tags" */
export type TagsDealFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

/** aggregated selection of "Tags" */
export type Tags_Aggregate = {
  __typename?: "Tags_aggregate";
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "Tags" */
export type Tags_Aggregate_Fields = {
  __typename?: "Tags_aggregate_fields";
  avg?: Maybe<Tags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
  stddev?: Maybe<Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Tags_Sum_Fields>;
  var_pop?: Maybe<Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Tags_Var_Samp_Fields>;
  variance?: Maybe<Tags_Variance_Fields>;
};

/** aggregate fields of "Tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Tags_Avg_Fields = {
  __typename?: "Tags_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  CommentTags?: InputMaybe<CommentTags_Bool_Exp>;
  CommentTags_aggregate?: InputMaybe<CommentTags_Aggregate_Bool_Exp>;
  DealFileTags?: InputMaybe<DealFileTags_Bool_Exp>;
  DealFileTags_aggregate?: InputMaybe<DealFileTags_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagsPkey = "Tags_pkey",
  /** unique or primary key constraint on columns "name" */
  TagsNameUnique = "tags_name_unique",
}

/** input type for incrementing numeric columns in table "Tags" */
export type Tags_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Tags" */
export type Tags_Insert_Input = {
  CommentTags?: InputMaybe<CommentTags_Arr_Rel_Insert_Input>;
  DealFileTags?: InputMaybe<DealFileTags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: "Tags_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: "Tags_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Tags" */
export type Tags_Mutation_Response = {
  __typename?: "Tags_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "Tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "Tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "Tags". */
export type Tags_Order_By = {
  CommentTags_aggregate?: InputMaybe<CommentTags_Aggregate_Order_By>;
  DealFileTags_aggregate?: InputMaybe<DealFileTags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Tags" */
export enum Tags_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "Tags" */
export type Tags_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Tags_Stddev_Fields = {
  __typename?: "Tags_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Tags_Stddev_Pop_Fields = {
  __typename?: "Tags_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Tags_Stddev_Samp_Fields = {
  __typename?: "Tags_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Tags_Sum_Fields = {
  __typename?: "Tags_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "Tags" */
export enum Tags_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tags_Var_Pop_Fields = {
  __typename?: "Tags_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Tags_Var_Samp_Fields = {
  __typename?: "Tags_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Tags_Variance_Fields = {
  __typename?: "Tags_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "TemplatePermittedUses" */
export type TemplatePermittedUses = {
  __typename?: "TemplatePermittedUses";
  /** An array relationship */
  DealPermittedUses: Array<DealPermittedUses>;
  /** An aggregate relationship */
  DealPermittedUses_aggregate: DealPermittedUses_Aggregate;
  /** An object relationship */
  ModifiedBy?: Maybe<Users>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "TemplatePermittedUses" */
export type TemplatePermittedUsesDealPermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

/** columns and relationships of "TemplatePermittedUses" */
export type TemplatePermittedUsesDealPermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

/** aggregated selection of "TemplatePermittedUses" */
export type TemplatePermittedUses_Aggregate = {
  __typename?: "TemplatePermittedUses_aggregate";
  aggregate?: Maybe<TemplatePermittedUses_Aggregate_Fields>;
  nodes: Array<TemplatePermittedUses>;
};

export type TemplatePermittedUses_Aggregate_Bool_Exp = {
  count?: InputMaybe<TemplatePermittedUses_Aggregate_Bool_Exp_Count>;
};

export type TemplatePermittedUses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TemplatePermittedUses" */
export type TemplatePermittedUses_Aggregate_Fields = {
  __typename?: "TemplatePermittedUses_aggregate_fields";
  avg?: Maybe<TemplatePermittedUses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<TemplatePermittedUses_Max_Fields>;
  min?: Maybe<TemplatePermittedUses_Min_Fields>;
  stddev?: Maybe<TemplatePermittedUses_Stddev_Fields>;
  stddev_pop?: Maybe<TemplatePermittedUses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TemplatePermittedUses_Stddev_Samp_Fields>;
  sum?: Maybe<TemplatePermittedUses_Sum_Fields>;
  var_pop?: Maybe<TemplatePermittedUses_Var_Pop_Fields>;
  var_samp?: Maybe<TemplatePermittedUses_Var_Samp_Fields>;
  variance?: Maybe<TemplatePermittedUses_Variance_Fields>;
};

/** aggregate fields of "TemplatePermittedUses" */
export type TemplatePermittedUses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Aggregate_Order_By = {
  avg?: InputMaybe<TemplatePermittedUses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TemplatePermittedUses_Max_Order_By>;
  min?: InputMaybe<TemplatePermittedUses_Min_Order_By>;
  stddev?: InputMaybe<TemplatePermittedUses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TemplatePermittedUses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TemplatePermittedUses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TemplatePermittedUses_Sum_Order_By>;
  var_pop?: InputMaybe<TemplatePermittedUses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TemplatePermittedUses_Var_Samp_Order_By>;
  variance?: InputMaybe<TemplatePermittedUses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TemplatePermittedUses" */
export type TemplatePermittedUses_Arr_Rel_Insert_Input = {
  data: Array<TemplatePermittedUses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplatePermittedUses_On_Conflict>;
};

/** aggregate avg on columns */
export type TemplatePermittedUses_Avg_Fields = {
  __typename?: "TemplatePermittedUses_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TemplatePermittedUses". All fields are combined with a logical 'AND'. */
export type TemplatePermittedUses_Bool_Exp = {
  DealPermittedUses?: InputMaybe<DealPermittedUses_Bool_Exp>;
  DealPermittedUses_aggregate?: InputMaybe<DealPermittedUses_Aggregate_Bool_Exp>;
  ModifiedBy?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<TemplatePermittedUses_Bool_Exp>>;
  _not?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
  _or?: InputMaybe<Array<TemplatePermittedUses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_by?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TemplatePermittedUses" */
export enum TemplatePermittedUses_Constraint {
  /** unique or primary key constraint on columns "id" */
  TemplatePermittedUsesPkey = "TemplatePermittedUses_pkey",
}

/** input type for incrementing numeric columns in table "TemplatePermittedUses" */
export type TemplatePermittedUses_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "TemplatePermittedUses" */
export type TemplatePermittedUses_Insert_Input = {
  DealPermittedUses?: InputMaybe<DealPermittedUses_Arr_Rel_Insert_Input>;
  ModifiedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type TemplatePermittedUses_Max_Fields = {
  __typename?: "TemplatePermittedUses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TemplatePermittedUses_Min_Fields = {
  __typename?: "TemplatePermittedUses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TemplatePermittedUses" */
export type TemplatePermittedUses_Mutation_Response = {
  __typename?: "TemplatePermittedUses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TemplatePermittedUses>;
};

/** input type for inserting object relation for remote table "TemplatePermittedUses" */
export type TemplatePermittedUses_Obj_Rel_Insert_Input = {
  data: TemplatePermittedUses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplatePermittedUses_On_Conflict>;
};

/** on_conflict condition type for table "TemplatePermittedUses" */
export type TemplatePermittedUses_On_Conflict = {
  constraint: TemplatePermittedUses_Constraint;
  update_columns?: Array<TemplatePermittedUses_Update_Column>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

/** Ordering options when selecting data from "TemplatePermittedUses". */
export type TemplatePermittedUses_Order_By = {
  DealPermittedUses_aggregate?: InputMaybe<DealPermittedUses_Aggregate_Order_By>;
  ModifiedBy?: InputMaybe<Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TemplatePermittedUses */
export type TemplatePermittedUses_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "TemplatePermittedUses" */
export enum TemplatePermittedUses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "TemplatePermittedUses" */
export type TemplatePermittedUses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type TemplatePermittedUses_Stddev_Fields = {
  __typename?: "TemplatePermittedUses_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TemplatePermittedUses_Stddev_Pop_Fields = {
  __typename?: "TemplatePermittedUses_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TemplatePermittedUses_Stddev_Samp_Fields = {
  __typename?: "TemplatePermittedUses_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TemplatePermittedUses" */
export type TemplatePermittedUses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TemplatePermittedUses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TemplatePermittedUses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type TemplatePermittedUses_Sum_Fields = {
  __typename?: "TemplatePermittedUses_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "TemplatePermittedUses" */
export enum TemplatePermittedUses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  Title = "title",
}

export type TemplatePermittedUses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TemplatePermittedUses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TemplatePermittedUses_Set_Input>;
  /** filter the rows which have to be updated */
  where: TemplatePermittedUses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TemplatePermittedUses_Var_Pop_Fields = {
  __typename?: "TemplatePermittedUses_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TemplatePermittedUses_Var_Samp_Fields = {
  __typename?: "TemplatePermittedUses_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TemplatePermittedUses_Variance_Fields = {
  __typename?: "TemplatePermittedUses_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "TemplatePermittedUses" */
export type TemplatePermittedUses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "TemplateSpecialConditions" */
export type TemplateSpecialConditions = {
  __typename?: "TemplateSpecialConditions";
  /** An array relationship */
  DealSpecialConditions: Array<DealSpecialConditions>;
  /** An aggregate relationship */
  DealSpecialConditions_aggregate: DealSpecialConditions_Aggregate;
  /** An object relationship */
  ModifiedBy?: Maybe<Users>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  laf_description?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  priority?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "TemplateSpecialConditions" */
export type TemplateSpecialConditionsDealSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

/** columns and relationships of "TemplateSpecialConditions" */
export type TemplateSpecialConditionsDealSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

/** aggregated selection of "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Aggregate = {
  __typename?: "TemplateSpecialConditions_aggregate";
  aggregate?: Maybe<TemplateSpecialConditions_Aggregate_Fields>;
  nodes: Array<TemplateSpecialConditions>;
};

export type TemplateSpecialConditions_Aggregate_Bool_Exp = {
  count?: InputMaybe<TemplateSpecialConditions_Aggregate_Bool_Exp_Count>;
};

export type TemplateSpecialConditions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Aggregate_Fields = {
  __typename?: "TemplateSpecialConditions_aggregate_fields";
  avg?: Maybe<TemplateSpecialConditions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<TemplateSpecialConditions_Max_Fields>;
  min?: Maybe<TemplateSpecialConditions_Min_Fields>;
  stddev?: Maybe<TemplateSpecialConditions_Stddev_Fields>;
  stddev_pop?: Maybe<TemplateSpecialConditions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TemplateSpecialConditions_Stddev_Samp_Fields>;
  sum?: Maybe<TemplateSpecialConditions_Sum_Fields>;
  var_pop?: Maybe<TemplateSpecialConditions_Var_Pop_Fields>;
  var_samp?: Maybe<TemplateSpecialConditions_Var_Samp_Fields>;
  variance?: Maybe<TemplateSpecialConditions_Variance_Fields>;
};

/** aggregate fields of "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Aggregate_Order_By = {
  avg?: InputMaybe<TemplateSpecialConditions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TemplateSpecialConditions_Max_Order_By>;
  min?: InputMaybe<TemplateSpecialConditions_Min_Order_By>;
  stddev?: InputMaybe<TemplateSpecialConditions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TemplateSpecialConditions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TemplateSpecialConditions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TemplateSpecialConditions_Sum_Order_By>;
  var_pop?: InputMaybe<TemplateSpecialConditions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TemplateSpecialConditions_Var_Samp_Order_By>;
  variance?: InputMaybe<TemplateSpecialConditions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Arr_Rel_Insert_Input = {
  data: Array<TemplateSpecialConditions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplateSpecialConditions_On_Conflict>;
};

/** aggregate avg on columns */
export type TemplateSpecialConditions_Avg_Fields = {
  __typename?: "TemplateSpecialConditions_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TemplateSpecialConditions". All fields are combined with a logical 'AND'. */
export type TemplateSpecialConditions_Bool_Exp = {
  DealSpecialConditions?: InputMaybe<DealSpecialConditions_Bool_Exp>;
  DealSpecialConditions_aggregate?: InputMaybe<DealSpecialConditions_Aggregate_Bool_Exp>;
  ModifiedBy?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<TemplateSpecialConditions_Bool_Exp>>;
  _not?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
  _or?: InputMaybe<Array<TemplateSpecialConditions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  laf_description?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_by?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TemplateSpecialConditions" */
export enum TemplateSpecialConditions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TemplateSpecialConditionsPkey = "TemplateSpecialConditions_pkey",
}

/** input type for incrementing numeric columns in table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  priority?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Insert_Input = {
  DealSpecialConditions?: InputMaybe<DealSpecialConditions_Arr_Rel_Insert_Input>;
  ModifiedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type TemplateSpecialConditions_Max_Fields = {
  __typename?: "TemplateSpecialConditions_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_description?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  priority?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TemplateSpecialConditions_Min_Fields = {
  __typename?: "TemplateSpecialConditions_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  laf_description?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  modified_by?: Maybe<Scalars["uuid"]>;
  priority?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Mutation_Response = {
  __typename?: "TemplateSpecialConditions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TemplateSpecialConditions>;
};

/** input type for inserting object relation for remote table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Obj_Rel_Insert_Input = {
  data: TemplateSpecialConditions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplateSpecialConditions_On_Conflict>;
};

/** on_conflict condition type for table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_On_Conflict = {
  constraint: TemplateSpecialConditions_Constraint;
  update_columns?: Array<TemplateSpecialConditions_Update_Column>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

/** Ordering options when selecting data from "TemplateSpecialConditions". */
export type TemplateSpecialConditions_Order_By = {
  DealSpecialConditions_aggregate?: InputMaybe<DealSpecialConditions_Aggregate_Order_By>;
  ModifiedBy?: InputMaybe<Users_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laf_description?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  modified_by?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TemplateSpecialConditions */
export type TemplateSpecialConditions_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "TemplateSpecialConditions" */
export enum TemplateSpecialConditions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafDescription = "laf_description",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  Priority = "priority",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type TemplateSpecialConditions_Stddev_Fields = {
  __typename?: "TemplateSpecialConditions_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TemplateSpecialConditions_Stddev_Pop_Fields = {
  __typename?: "TemplateSpecialConditions_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TemplateSpecialConditions_Stddev_Samp_Fields = {
  __typename?: "TemplateSpecialConditions_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TemplateSpecialConditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TemplateSpecialConditions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  laf_description?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_by?: InputMaybe<Scalars["uuid"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type TemplateSpecialConditions_Sum_Fields = {
  __typename?: "TemplateSpecialConditions_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  priority?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** update columns of table "TemplateSpecialConditions" */
export enum TemplateSpecialConditions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LafDescription = "laf_description",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  ModifiedBy = "modified_by",
  /** column name */
  Priority = "priority",
  /** column name */
  Title = "title",
}

export type TemplateSpecialConditions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TemplateSpecialConditions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TemplateSpecialConditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: TemplateSpecialConditions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TemplateSpecialConditions_Var_Pop_Fields = {
  __typename?: "TemplateSpecialConditions_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TemplateSpecialConditions_Var_Samp_Fields = {
  __typename?: "TemplateSpecialConditions_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TemplateSpecialConditions_Variance_Fields = {
  __typename?: "TemplateSpecialConditions_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "TemplateSpecialConditions" */
export type TemplateSpecialConditions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** columns and relationships of "TenantTypes" */
export type TenantTypes = {
  __typename?: "TenantTypes";
  /** An array relationship */
  Deals: Array<Deals>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "TenantTypes" */
export type TenantTypesDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "TenantTypes" */
export type TenantTypesDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** aggregated selection of "TenantTypes" */
export type TenantTypes_Aggregate = {
  __typename?: "TenantTypes_aggregate";
  aggregate?: Maybe<TenantTypes_Aggregate_Fields>;
  nodes: Array<TenantTypes>;
};

/** aggregate fields of "TenantTypes" */
export type TenantTypes_Aggregate_Fields = {
  __typename?: "TenantTypes_aggregate_fields";
  avg?: Maybe<TenantTypes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<TenantTypes_Max_Fields>;
  min?: Maybe<TenantTypes_Min_Fields>;
  stddev?: Maybe<TenantTypes_Stddev_Fields>;
  stddev_pop?: Maybe<TenantTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TenantTypes_Stddev_Samp_Fields>;
  sum?: Maybe<TenantTypes_Sum_Fields>;
  var_pop?: Maybe<TenantTypes_Var_Pop_Fields>;
  var_samp?: Maybe<TenantTypes_Var_Samp_Fields>;
  variance?: Maybe<TenantTypes_Variance_Fields>;
};

/** aggregate fields of "TenantTypes" */
export type TenantTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TenantTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type TenantTypes_Avg_Fields = {
  __typename?: "TenantTypes_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "TenantTypes". All fields are combined with a logical 'AND'. */
export type TenantTypes_Bool_Exp = {
  Deals?: InputMaybe<Deals_Bool_Exp>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TenantTypes_Bool_Exp>>;
  _not?: InputMaybe<TenantTypes_Bool_Exp>;
  _or?: InputMaybe<Array<TenantTypes_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TenantTypes" */
export enum TenantTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantTypesPkey = "TenantTypes_pkey",
}

/** input type for incrementing numeric columns in table "TenantTypes" */
export type TenantTypes_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "TenantTypes" */
export type TenantTypes_Insert_Input = {
  Deals?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type TenantTypes_Max_Fields = {
  __typename?: "TenantTypes_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type TenantTypes_Min_Fields = {
  __typename?: "TenantTypes_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  title?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "TenantTypes" */
export type TenantTypes_Mutation_Response = {
  __typename?: "TenantTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TenantTypes>;
};

/** input type for inserting object relation for remote table "TenantTypes" */
export type TenantTypes_Obj_Rel_Insert_Input = {
  data: TenantTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TenantTypes_On_Conflict>;
};

/** on_conflict condition type for table "TenantTypes" */
export type TenantTypes_On_Conflict = {
  constraint: TenantTypes_Constraint;
  update_columns?: Array<TenantTypes_Update_Column>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "TenantTypes". */
export type TenantTypes_Order_By = {
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TenantTypes */
export type TenantTypes_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "TenantTypes" */
export enum TenantTypes_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "TenantTypes" */
export type TenantTypes_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type TenantTypes_Stddev_Fields = {
  __typename?: "TenantTypes_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type TenantTypes_Stddev_Pop_Fields = {
  __typename?: "TenantTypes_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type TenantTypes_Stddev_Samp_Fields = {
  __typename?: "TenantTypes_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "TenantTypes" */
export type TenantTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TenantTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TenantTypes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type TenantTypes_Sum_Fields = {
  __typename?: "TenantTypes_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "TenantTypes" */
export enum TenantTypes_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
}

export type TenantTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TenantTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TenantTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: TenantTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TenantTypes_Var_Pop_Fields = {
  __typename?: "TenantTypes_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type TenantTypes_Var_Samp_Fields = {
  __typename?: "TenantTypes_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type TenantTypes_Variance_Fields = {
  __typename?: "TenantTypes_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Terms" */
export type Terms = {
  __typename?: "Terms";
  /** An object relationship */
  Deal?: Maybe<Deals>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id: Scalars["bigint"];
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "Terms" */
export type Terms_Aggregate = {
  __typename?: "Terms_aggregate";
  aggregate?: Maybe<Terms_Aggregate_Fields>;
  nodes: Array<Terms>;
};

export type Terms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Terms_Aggregate_Bool_Exp_Count>;
};

export type Terms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Terms_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Terms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Terms" */
export type Terms_Aggregate_Fields = {
  __typename?: "Terms_aggregate_fields";
  avg?: Maybe<Terms_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Terms_Max_Fields>;
  min?: Maybe<Terms_Min_Fields>;
  stddev?: Maybe<Terms_Stddev_Fields>;
  stddev_pop?: Maybe<Terms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Terms_Stddev_Samp_Fields>;
  sum?: Maybe<Terms_Sum_Fields>;
  var_pop?: Maybe<Terms_Var_Pop_Fields>;
  var_samp?: Maybe<Terms_Var_Samp_Fields>;
  variance?: Maybe<Terms_Variance_Fields>;
};

/** aggregate fields of "Terms" */
export type Terms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terms_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Terms" */
export type Terms_Aggregate_Order_By = {
  avg?: InputMaybe<Terms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Terms_Max_Order_By>;
  min?: InputMaybe<Terms_Min_Order_By>;
  stddev?: InputMaybe<Terms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Terms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Terms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Terms_Sum_Order_By>;
  var_pop?: InputMaybe<Terms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Terms_Var_Samp_Order_By>;
  variance?: InputMaybe<Terms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Terms" */
export type Terms_Arr_Rel_Insert_Input = {
  data: Array<Terms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Terms_On_Conflict>;
};

/** aggregate avg on columns */
export type Terms_Avg_Fields = {
  __typename?: "Terms_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Terms" */
export type Terms_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Terms". All fields are combined with a logical 'AND'. */
export type Terms_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<Terms_Bool_Exp>>;
  _not?: InputMaybe<Terms_Bool_Exp>;
  _or?: InputMaybe<Array<Terms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Terms" */
export enum Terms_Constraint {
  /** unique or primary key constraint on columns "id" */
  TermsPkey = "Terms_pkey",
}

/** input type for incrementing numeric columns in table "Terms" */
export type Terms_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "Terms" */
export type Terms_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Terms_Max_Fields = {
  __typename?: "Terms_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "Terms" */
export type Terms_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Terms_Min_Fields = {
  __typename?: "Terms_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "Terms" */
export type Terms_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Terms" */
export type Terms_Mutation_Response = {
  __typename?: "Terms_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Terms>;
};

/** on_conflict condition type for table "Terms" */
export type Terms_On_Conflict = {
  constraint: Terms_Constraint;
  update_columns?: Array<Terms_Update_Column>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

/** Ordering options when selecting data from "Terms". */
export type Terms_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Terms */
export type Terms_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "Terms" */
export enum Terms_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "Terms" */
export type Terms_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Terms_Stddev_Fields = {
  __typename?: "Terms_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Terms" */
export type Terms_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Terms_Stddev_Pop_Fields = {
  __typename?: "Terms_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Terms" */
export type Terms_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Terms_Stddev_Samp_Fields = {
  __typename?: "Terms_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Terms" */
export type Terms_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Terms" */
export type Terms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Terms_Sum_Fields = {
  __typename?: "Terms_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Terms" */
export type Terms_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Terms" */
export enum Terms_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
}

export type Terms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Terms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Terms_Var_Pop_Fields = {
  __typename?: "Terms_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Terms" */
export type Terms_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Terms_Var_Samp_Fields = {
  __typename?: "Terms_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Terms" */
export type Terms_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Terms_Variance_Fields = {
  __typename?: "Terms_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Terms" */
export type Terms_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnum = {
  __typename?: "TriSelectEnum";
  /** An array relationship */
  DealData: Array<DealData>;
  /** An aggregate relationship */
  DealData_aggregate: DealData_Aggregate;
  /** An array relationship */
  dealDataByBankGuaranteeId: Array<DealData>;
  /** An aggregate relationship */
  dealDataByBankGuaranteeId_aggregate: DealData_Aggregate;
  /** An array relationship */
  dealDataByHoardingId: Array<DealData>;
  /** An aggregate relationship */
  dealDataByHoardingId_aggregate: DealData_Aggregate;
  /** An array relationship */
  dealDataBySecurityId: Array<DealData>;
  /** An aggregate relationship */
  dealDataBySecurityId_aggregate: DealData_Aggregate;
  id: Scalars["bigint"];
  name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataByBankGuaranteeIdArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataByBankGuaranteeId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataByHoardingIdArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataByHoardingId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataBySecurityIdArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** columns and relationships of "TriSelectEnum" */
export type TriSelectEnumDealDataBySecurityId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

/** aggregated selection of "TriSelectEnum" */
export type TriSelectEnum_Aggregate = {
  __typename?: "TriSelectEnum_aggregate";
  aggregate?: Maybe<TriSelectEnum_Aggregate_Fields>;
  nodes: Array<TriSelectEnum>;
};

/** aggregate fields of "TriSelectEnum" */
export type TriSelectEnum_Aggregate_Fields = {
  __typename?: "TriSelectEnum_aggregate_fields";
  avg?: Maybe<TriSelectEnum_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<TriSelectEnum_Max_Fields>;
  min?: Maybe<TriSelectEnum_Min_Fields>;
  stddev?: Maybe<TriSelectEnum_Stddev_Fields>;
  stddev_pop?: Maybe<TriSelectEnum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TriSelectEnum_Stddev_Samp_Fields>;
  sum?: Maybe<TriSelectEnum_Sum_Fields>;
  var_pop?: Maybe<TriSelectEnum_Var_Pop_Fields>;
  var_samp?: Maybe<TriSelectEnum_Var_Samp_Fields>;
  variance?: Maybe<TriSelectEnum_Variance_Fields>;
};

/** aggregate fields of "TriSelectEnum" */
export type TriSelectEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TriSelectEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type TriSelectEnum_Avg_Fields = {
  __typename?: "TriSelectEnum_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "TriSelectEnum". All fields are combined with a logical 'AND'. */
export type TriSelectEnum_Bool_Exp = {
  DealData?: InputMaybe<DealData_Bool_Exp>;
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TriSelectEnum_Bool_Exp>>;
  _not?: InputMaybe<TriSelectEnum_Bool_Exp>;
  _or?: InputMaybe<Array<TriSelectEnum_Bool_Exp>>;
  dealDataByBankGuaranteeId?: InputMaybe<DealData_Bool_Exp>;
  dealDataByBankGuaranteeId_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  dealDataByHoardingId?: InputMaybe<DealData_Bool_Exp>;
  dealDataByHoardingId_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  dealDataBySecurityId?: InputMaybe<DealData_Bool_Exp>;
  dealDataBySecurityId_aggregate?: InputMaybe<DealData_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TriSelectEnum" */
export enum TriSelectEnum_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriSelectEnumPkey = "TriSelectEnum_pkey",
}

/** input type for incrementing numeric columns in table "TriSelectEnum" */
export type TriSelectEnum_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "TriSelectEnum" */
export type TriSelectEnum_Insert_Input = {
  DealData?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  dealDataByBankGuaranteeId?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  dealDataByHoardingId?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  dealDataBySecurityId?: InputMaybe<DealData_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type TriSelectEnum_Max_Fields = {
  __typename?: "TriSelectEnum_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type TriSelectEnum_Min_Fields = {
  __typename?: "TriSelectEnum_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "TriSelectEnum" */
export type TriSelectEnum_Mutation_Response = {
  __typename?: "TriSelectEnum_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TriSelectEnum>;
};

/** input type for inserting object relation for remote table "TriSelectEnum" */
export type TriSelectEnum_Obj_Rel_Insert_Input = {
  data: TriSelectEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TriSelectEnum_On_Conflict>;
};

/** on_conflict condition type for table "TriSelectEnum" */
export type TriSelectEnum_On_Conflict = {
  constraint: TriSelectEnum_Constraint;
  update_columns?: Array<TriSelectEnum_Update_Column>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "TriSelectEnum". */
export type TriSelectEnum_Order_By = {
  DealData_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  dealDataByBankGuaranteeId_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  dealDataByHoardingId_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  dealDataBySecurityId_aggregate?: InputMaybe<DealData_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TriSelectEnum */
export type TriSelectEnum_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "TriSelectEnum" */
export enum TriSelectEnum_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "TriSelectEnum" */
export type TriSelectEnum_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type TriSelectEnum_Stddev_Fields = {
  __typename?: "TriSelectEnum_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type TriSelectEnum_Stddev_Pop_Fields = {
  __typename?: "TriSelectEnum_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type TriSelectEnum_Stddev_Samp_Fields = {
  __typename?: "TriSelectEnum_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "TriSelectEnum" */
export type TriSelectEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TriSelectEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TriSelectEnum_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type TriSelectEnum_Sum_Fields = {
  __typename?: "TriSelectEnum_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "TriSelectEnum" */
export enum TriSelectEnum_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type TriSelectEnum_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TriSelectEnum_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TriSelectEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: TriSelectEnum_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TriSelectEnum_Var_Pop_Fields = {
  __typename?: "TriSelectEnum_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type TriSelectEnum_Var_Samp_Fields = {
  __typename?: "TriSelectEnum_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type TriSelectEnum_Variance_Fields = {
  __typename?: "TriSelectEnum_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Unit = {
  estimatedArea: Scalars["Float"];
  label: Scalars["String"];
  propertyCode: Scalars["String"];
  value: Scalars["String"];
  virtualUnitDescription?: InputMaybe<Scalars["String"]>;
  virtualUnitName?: InputMaybe<Scalars["String"]>;
  virtualUnitType?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "UnitTypes" */
export type UnitTypes = {
  __typename?: "UnitTypes";
  /** fetch data from the table: "Units" */
  Units: Array<Units>;
  /** fetch aggregated fields from the table: "Units" */
  Units_aggregate: Units_Aggregate;
  id: Scalars["bigint"];
  type_name?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "UnitTypes" */
export type UnitTypesUnitsArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

/** columns and relationships of "UnitTypes" */
export type UnitTypesUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

/** aggregated selection of "UnitTypes" */
export type UnitTypes_Aggregate = {
  __typename?: "UnitTypes_aggregate";
  aggregate?: Maybe<UnitTypes_Aggregate_Fields>;
  nodes: Array<UnitTypes>;
};

/** aggregate fields of "UnitTypes" */
export type UnitTypes_Aggregate_Fields = {
  __typename?: "UnitTypes_aggregate_fields";
  avg?: Maybe<UnitTypes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<UnitTypes_Max_Fields>;
  min?: Maybe<UnitTypes_Min_Fields>;
  stddev?: Maybe<UnitTypes_Stddev_Fields>;
  stddev_pop?: Maybe<UnitTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UnitTypes_Stddev_Samp_Fields>;
  sum?: Maybe<UnitTypes_Sum_Fields>;
  var_pop?: Maybe<UnitTypes_Var_Pop_Fields>;
  var_samp?: Maybe<UnitTypes_Var_Samp_Fields>;
  variance?: Maybe<UnitTypes_Variance_Fields>;
};

/** aggregate fields of "UnitTypes" */
export type UnitTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UnitTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type UnitTypes_Avg_Fields = {
  __typename?: "UnitTypes_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "UnitTypes". All fields are combined with a logical 'AND'. */
export type UnitTypes_Bool_Exp = {
  Units?: InputMaybe<Units_Bool_Exp>;
  Units_aggregate?: InputMaybe<Units_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UnitTypes_Bool_Exp>>;
  _not?: InputMaybe<UnitTypes_Bool_Exp>;
  _or?: InputMaybe<Array<UnitTypes_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UnitTypes" */
export enum UnitTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnitTypesPkey = "UnitTypes_pkey",
}

/** input type for incrementing numeric columns in table "UnitTypes" */
export type UnitTypes_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "UnitTypes" */
export type UnitTypes_Insert_Input = {
  Units?: InputMaybe<Units_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["bigint"]>;
  type_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UnitTypes_Max_Fields = {
  __typename?: "UnitTypes_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  type_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type UnitTypes_Min_Fields = {
  __typename?: "UnitTypes_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  type_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "UnitTypes" */
export type UnitTypes_Mutation_Response = {
  __typename?: "UnitTypes_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<UnitTypes>;
};

/** on_conflict condition type for table "UnitTypes" */
export type UnitTypes_On_Conflict = {
  constraint: UnitTypes_Constraint;
  update_columns?: Array<UnitTypes_Update_Column>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "UnitTypes". */
export type UnitTypes_Order_By = {
  Units_aggregate?: InputMaybe<Units_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  type_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UnitTypes */
export type UnitTypes_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "UnitTypes" */
export enum UnitTypes_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  TypeName = "type_name",
}

/** input type for updating data in table "UnitTypes" */
export type UnitTypes_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  type_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type UnitTypes_Stddev_Fields = {
  __typename?: "UnitTypes_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type UnitTypes_Stddev_Pop_Fields = {
  __typename?: "UnitTypes_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type UnitTypes_Stddev_Samp_Fields = {
  __typename?: "UnitTypes_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "UnitTypes" */
export type UnitTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UnitTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UnitTypes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  type_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type UnitTypes_Sum_Fields = {
  __typename?: "UnitTypes_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "UnitTypes" */
export enum UnitTypes_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  TypeName = "type_name",
}

export type UnitTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UnitTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UnitTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: UnitTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UnitTypes_Var_Pop_Fields = {
  __typename?: "UnitTypes_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type UnitTypes_Var_Samp_Fields = {
  __typename?: "UnitTypes_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type UnitTypes_Variance_Fields = {
  __typename?: "UnitTypes_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Units" */
export type Units = {
  __typename?: "Units";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  /** An object relationship */
  Property?: Maybe<Properties>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiUnitProfiles: Array<YardiUnitProfiles>;
  /** An aggregate relationship */
  YardiUnitProfiles_aggregate: YardiUnitProfiles_Aggregate;
  area_survey_required?: Maybe<Scalars["Boolean"]>;
  charge_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["numeric"]>;
  unit_type_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "Units" */
export type UnitsYardiUnitProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** columns and relationships of "Units" */
export type UnitsYardiUnitProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** aggregated selection of "Units" */
export type Units_Aggregate = {
  __typename?: "Units_aggregate";
  aggregate?: Maybe<Units_Aggregate_Fields>;
  nodes: Array<Units>;
};

export type Units_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Units_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Units_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Units_Aggregate_Bool_Exp_Count>;
};

export type Units_Aggregate_Bool_Exp_Bool_And = {
  arguments: Units_Select_Column_Units_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Units_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Units_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Units_Select_Column_Units_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Units_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Units_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Units_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Units_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Units" */
export type Units_Aggregate_Fields = {
  __typename?: "Units_aggregate_fields";
  avg?: Maybe<Units_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Units_Max_Fields>;
  min?: Maybe<Units_Min_Fields>;
  stddev?: Maybe<Units_Stddev_Fields>;
  stddev_pop?: Maybe<Units_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Units_Stddev_Samp_Fields>;
  sum?: Maybe<Units_Sum_Fields>;
  var_pop?: Maybe<Units_Var_Pop_Fields>;
  var_samp?: Maybe<Units_Var_Samp_Fields>;
  variance?: Maybe<Units_Variance_Fields>;
};

/** aggregate fields of "Units" */
export type Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Units_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Units" */
export type Units_Aggregate_Order_By = {
  avg?: InputMaybe<Units_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Units_Max_Order_By>;
  min?: InputMaybe<Units_Min_Order_By>;
  stddev?: InputMaybe<Units_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Units_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Units_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Units_Sum_Order_By>;
  var_pop?: InputMaybe<Units_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Units_Var_Samp_Order_By>;
  variance?: InputMaybe<Units_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Units" */
export type Units_Arr_Rel_Insert_Input = {
  data: Array<Units_Insert_Input>;
};

/** aggregate avg on columns */
export type Units_Avg_Fields = {
  __typename?: "Units_avg_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Units" */
export type Units_Avg_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Units". All fields are combined with a logical 'AND'. */
export type Units_Bool_Exp = {
  Attribute1?: InputMaybe<String_Comparison_Exp>;
  Attribute2?: InputMaybe<String_Comparison_Exp>;
  Attribute3?: InputMaybe<String_Comparison_Exp>;
  Attribute4?: InputMaybe<String_Comparison_Exp>;
  Attribute5?: InputMaybe<String_Comparison_Exp>;
  Attribute6?: InputMaybe<String_Comparison_Exp>;
  Attribute7?: InputMaybe<String_Comparison_Exp>;
  Attribute8?: InputMaybe<String_Comparison_Exp>;
  Attribute9?: InputMaybe<String_Comparison_Exp>;
  Attribute10?: InputMaybe<String_Comparison_Exp>;
  Building?: InputMaybe<String_Comparison_Exp>;
  FloorCode?: InputMaybe<String_Comparison_Exp>;
  IsExclude?: InputMaybe<String_Comparison_Exp>;
  LevelName?: InputMaybe<String_Comparison_Exp>;
  LevelNumber?: InputMaybe<String_Comparison_Exp>;
  Location?: InputMaybe<String_Comparison_Exp>;
  MarketRent?: InputMaybe<Numeric_Comparison_Exp>;
  Property?: InputMaybe<Properties_Bool_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  RentalType?: InputMaybe<String_Comparison_Exp>;
  UnitArea?: InputMaybe<Numeric_Comparison_Exp>;
  UnitCode?: InputMaybe<String_Comparison_Exp>;
  UnitKey?: InputMaybe<String_Comparison_Exp>;
  UnitStatus?: InputMaybe<String_Comparison_Exp>;
  UnitType?: InputMaybe<String_Comparison_Exp>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Units_Bool_Exp>>;
  _not?: InputMaybe<Units_Bool_Exp>;
  _or?: InputMaybe<Array<Units_Bool_Exp>>;
  area_survey_required?: InputMaybe<Boolean_Comparison_Exp>;
  charge_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  estimated_area?: InputMaybe<Numeric_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
  survey_area?: InputMaybe<Numeric_Comparison_Exp>;
  unit_type_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Units" */
export type Units_Inc_Input = {
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  survey_area?: InputMaybe<Scalars["numeric"]>;
  unit_type_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Units" */
export type Units_Insert_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  Property?: InputMaybe<Properties_Obj_Rel_Insert_Input>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Arr_Rel_Insert_Input>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  notes?: InputMaybe<Scalars["String"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["numeric"]>;
  unit_type_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Units_Max_Fields = {
  __typename?: "Units_max_fields";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  charge_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["numeric"]>;
  unit_type_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "Units" */
export type Units_Max_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Units_Min_Fields = {
  __typename?: "Units_min_fields";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  charge_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["numeric"]>;
  unit_type_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "Units" */
export type Units_Min_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Units" */
export type Units_Mutation_Response = {
  __typename?: "Units_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Units>;
};

/** input type for inserting object relation for remote table "Units" */
export type Units_Obj_Rel_Insert_Input = {
  data: Units_Insert_Input;
};

/** Ordering options when selecting data from "Units". */
export type Units_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  Property?: InputMaybe<Properties_Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Order_By>;
  area_survey_required?: InputMaybe<Order_By>;
  charge_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** select columns of table "Units" */
export enum Units_Select_Column {
  /** column name */
  Attribute1 = "Attribute1",
  /** column name */
  Attribute2 = "Attribute2",
  /** column name */
  Attribute3 = "Attribute3",
  /** column name */
  Attribute4 = "Attribute4",
  /** column name */
  Attribute5 = "Attribute5",
  /** column name */
  Attribute6 = "Attribute6",
  /** column name */
  Attribute7 = "Attribute7",
  /** column name */
  Attribute8 = "Attribute8",
  /** column name */
  Attribute9 = "Attribute9",
  /** column name */
  Attribute10 = "Attribute10",
  /** column name */
  Building = "Building",
  /** column name */
  FloorCode = "FloorCode",
  /** column name */
  IsExclude = "IsExclude",
  /** column name */
  LevelName = "LevelName",
  /** column name */
  LevelNumber = "LevelNumber",
  /** column name */
  Location = "Location",
  /** column name */
  MarketRent = "MarketRent",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  RentalType = "RentalType",
  /** column name */
  UnitArea = "UnitArea",
  /** column name */
  UnitCode = "UnitCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  UnitStatus = "UnitStatus",
  /** column name */
  UnitType = "UnitType",
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  ChargeCode = "charge_code",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  EstimatedArea = "estimated_area",
  /** column name */
  Label = "label",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  Notes = "notes",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  SurveyArea = "survey_area",
  /** column name */
  UnitTypeId = "unit_type_id",
}

/** select "Units_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Units" */
export enum Units_Select_Column_Units_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
}

/** select "Units_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Units" */
export enum Units_Select_Column_Units_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
}

/** input type for updating data in table "Units" */
export type Units_Set_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  notes?: InputMaybe<Scalars["String"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["numeric"]>;
  unit_type_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Units_Stddev_Fields = {
  __typename?: "Units_stddev_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Units" */
export type Units_Stddev_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Units_Stddev_Pop_Fields = {
  __typename?: "Units_stddev_pop_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Units" */
export type Units_Stddev_Pop_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Units_Stddev_Samp_Fields = {
  __typename?: "Units_stddev_samp_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Units" */
export type Units_Stddev_Samp_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Units" */
export type Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Units_Stream_Cursor_Value_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  charge_code?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  notes?: InputMaybe<Scalars["String"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["numeric"]>;
  unit_type_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Units_Sum_Fields = {
  __typename?: "Units_sum_fields";
  MarketRent?: Maybe<Scalars["numeric"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  survey_area?: Maybe<Scalars["numeric"]>;
  unit_type_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Units" */
export type Units_Sum_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

export type Units_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Units_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Units_Set_Input>;
  /** filter the rows which have to be updated */
  where: Units_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Units_Var_Pop_Fields = {
  __typename?: "Units_var_pop_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Units" */
export type Units_Var_Pop_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Units_Var_Samp_Fields = {
  __typename?: "Units_var_samp_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Units" */
export type Units_Var_Samp_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Units_Variance_Fields = {
  __typename?: "Units_variance_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
  estimated_area?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
  unit_type_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Units" */
export type Units_Variance_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "UserStatus" */
export type UserStatus = {
  __typename?: "UserStatus";
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
};

/** columns and relationships of "UserStatus" */
export type UserStatusUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "UserStatus" */
export type UserStatusUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "UserStatus" */
export type UserStatus_Aggregate = {
  __typename?: "UserStatus_aggregate";
  aggregate?: Maybe<UserStatus_Aggregate_Fields>;
  nodes: Array<UserStatus>;
};

/** aggregate fields of "UserStatus" */
export type UserStatus_Aggregate_Fields = {
  __typename?: "UserStatus_aggregate_fields";
  avg?: Maybe<UserStatus_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<UserStatus_Max_Fields>;
  min?: Maybe<UserStatus_Min_Fields>;
  stddev?: Maybe<UserStatus_Stddev_Fields>;
  stddev_pop?: Maybe<UserStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserStatus_Stddev_Samp_Fields>;
  sum?: Maybe<UserStatus_Sum_Fields>;
  var_pop?: Maybe<UserStatus_Var_Pop_Fields>;
  var_samp?: Maybe<UserStatus_Var_Samp_Fields>;
  variance?: Maybe<UserStatus_Variance_Fields>;
};

/** aggregate fields of "UserStatus" */
export type UserStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type UserStatus_Avg_Fields = {
  __typename?: "UserStatus_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "UserStatus". All fields are combined with a logical 'AND'. */
export type UserStatus_Bool_Exp = {
  Users?: InputMaybe<Users_Bool_Exp>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UserStatus_Bool_Exp>>;
  _not?: InputMaybe<UserStatus_Bool_Exp>;
  _or?: InputMaybe<Array<UserStatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserStatus" */
export enum UserStatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserStatusPkey = "UserStatus_pkey",
}

/** input type for incrementing numeric columns in table "UserStatus" */
export type UserStatus_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "UserStatus" */
export type UserStatus_Insert_Input = {
  Users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type UserStatus_Max_Fields = {
  __typename?: "UserStatus_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type UserStatus_Min_Fields = {
  __typename?: "UserStatus_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** response of any mutation on the table "UserStatus" */
export type UserStatus_Mutation_Response = {
  __typename?: "UserStatus_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<UserStatus>;
};

/** input type for inserting object relation for remote table "UserStatus" */
export type UserStatus_Obj_Rel_Insert_Input = {
  data: UserStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};

/** on_conflict condition type for table "UserStatus" */
export type UserStatus_On_Conflict = {
  constraint: UserStatus_Constraint;
  update_columns?: Array<UserStatus_Update_Column>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "UserStatus". */
export type UserStatus_Order_By = {
  Users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserStatus */
export type UserStatus_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "UserStatus" */
export enum UserStatus_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "UserStatus" */
export type UserStatus_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type UserStatus_Stddev_Fields = {
  __typename?: "UserStatus_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type UserStatus_Stddev_Pop_Fields = {
  __typename?: "UserStatus_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type UserStatus_Stddev_Samp_Fields = {
  __typename?: "UserStatus_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "UserStatus" */
export type UserStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type UserStatus_Sum_Fields = {
  __typename?: "UserStatus_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "UserStatus" */
export enum UserStatus_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
}

export type UserStatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserStatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserStatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserStatus_Var_Pop_Fields = {
  __typename?: "UserStatus_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type UserStatus_Var_Samp_Fields = {
  __typename?: "UserStatus_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type UserStatus_Variance_Fields = {
  __typename?: "UserStatus_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "Users" */
export type Users = {
  __typename?: "Users";
  /** An array relationship */
  ActionAllocations: Array<ActionAllocations>;
  /** An aggregate relationship */
  ActionAllocations_aggregate: ActionAllocations_Aggregate;
  /** An array relationship */
  ActivityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  ActivityLogs_aggregate: ActivityLog_Aggregate;
  /** An array relationship */
  Approvers: Array<Approvers>;
  /** An aggregate relationship */
  Approvers_aggregate: Approvers_Aggregate;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  DealActionItems: Array<DealActionItem>;
  /** An aggregate relationship */
  DealActionItems_aggregate: DealActionItem_Aggregate;
  /** An array relationship */
  DealFiles: Array<DealFiles>;
  /** An aggregate relationship */
  DealFiles_aggregate: DealFiles_Aggregate;
  /** An array relationship */
  DealLAFApprovals: Array<DealLafApprovals>;
  /** An aggregate relationship */
  DealLAFApprovals_aggregate: DealLafApprovals_Aggregate;
  /** An array relationship */
  Deals: Array<Deals>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  /** An array relationship */
  DocumentRequests: Array<DocumentRequests>;
  /** An aggregate relationship */
  DocumentRequests_aggregate: DocumentRequests_Aggregate;
  /** An array relationship */
  DocumentTemplates: Array<DocumentTemplates>;
  /** An aggregate relationship */
  DocumentTemplates_aggregate: DocumentTemplates_Aggregate;
  /** An array relationship */
  EscalatedApprovers: Array<EscalatedApprovers>;
  /** An aggregate relationship */
  EscalatedApprovers_aggregate: EscalatedApprovers_Aggregate;
  /** An array relationship */
  GeneratedDocuments: Array<GeneratedDocuments>;
  /** An aggregate relationship */
  GeneratedDocuments_aggregate: GeneratedDocuments_Aggregate;
  /** An array relationship */
  PropertyFiles: Array<PropertyFiles>;
  /** An aggregate relationship */
  PropertyFiles_aggregate: PropertyFiles_Aggregate;
  /** An array relationship */
  TemplatePermittedUses: Array<TemplatePermittedUses>;
  /** An aggregate relationship */
  TemplatePermittedUses_aggregate: TemplatePermittedUses_Aggregate;
  /** An array relationship */
  TemplateSpecialConditions: Array<TemplateSpecialConditions>;
  /** An aggregate relationship */
  TemplateSpecialConditions_aggregate: TemplateSpecialConditions_Aggregate;
  /** An object relationship */
  UserStatus: UserStatus;
  /** An array relationship */
  actionAllocationsByAllocatedBy: Array<ActionAllocations>;
  /** An aggregate relationship */
  actionAllocationsByAllocatedBy_aggregate: ActionAllocations_Aggregate;
  /** An array relationship */
  dealLAFApprovalsByUpdatedBy: Array<DealLafApprovals>;
  /** An aggregate relationship */
  dealLAFApprovalsByUpdatedBy_aggregate: DealLafApprovals_Aggregate;
  /** An array relationship */
  dealsByLaAssignee: Array<Deals>;
  /** An aggregate relationship */
  dealsByLaAssignee_aggregate: Deals_Aggregate;
  /** An array relationship */
  dealsByLastModifiedBy: Array<Deals>;
  /** An aggregate relationship */
  dealsByLastModifiedBy_aggregate: Deals_Aggregate;
  /** An array relationship */
  dealsByOwner: Array<Deals>;
  /** An aggregate relationship */
  dealsByOwner_aggregate: Deals_Aggregate;
  /** An array relationship */
  dealsByTdAssignee: Array<Deals>;
  /** An aggregate relationship */
  dealsByTdAssignee_aggregate: Deals_Aggregate;
  id: Scalars["uuid"];
  job_title?: Maybe<Scalars["String"]>;
  last_login?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  status: Scalars["Int"];
  user_full_name?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "Users" */
export type UsersActionAllocationsArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersActionAllocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersApproversArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealActionItemsArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealActionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealFilesArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealLafApprovalsArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealLafApprovals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDocumentRequestsArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDocumentRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDocumentTemplatesArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDocumentTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersEscalatedApproversArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersEscalatedApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersGeneratedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersGeneratedDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersPropertyFilesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersPropertyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersTemplatePermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersTemplatePermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersTemplateSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersTemplateSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersActionAllocationsByAllocatedByArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersActionAllocationsByAllocatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealLafApprovalsByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealLafApprovalsByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByLaAssigneeArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByLaAssignee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByLastModifiedByArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByLastModifiedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByOwnerArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByOwner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByTdAssigneeArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "Users" */
export type UsersDealsByTdAssignee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** aggregated selection of "Users" */
export type Users_Aggregate = {
  __typename?: "Users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Users" */
export type Users_Aggregate_Fields = {
  __typename?: "Users_aggregate_fields";
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};

/** aggregate fields of "Users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: "Users_avg_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Users" */
export type Users_Avg_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  ActionAllocations?: InputMaybe<ActionAllocations_Bool_Exp>;
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Bool_Exp>;
  ActivityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  Approvers?: InputMaybe<Approvers_Bool_Exp>;
  Approvers_aggregate?: InputMaybe<Approvers_Aggregate_Bool_Exp>;
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  DealActionItems?: InputMaybe<DealActionItem_Bool_Exp>;
  DealActionItems_aggregate?: InputMaybe<DealActionItem_Aggregate_Bool_Exp>;
  DealFiles?: InputMaybe<DealFiles_Bool_Exp>;
  DealFiles_aggregate?: InputMaybe<DealFiles_Aggregate_Bool_Exp>;
  DealLAFApprovals?: InputMaybe<DealLafApprovals_Bool_Exp>;
  DealLAFApprovals_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp>;
  Deals?: InputMaybe<Deals_Bool_Exp>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  DocumentRequests?: InputMaybe<DocumentRequests_Bool_Exp>;
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Bool_Exp>;
  DocumentTemplates?: InputMaybe<DocumentTemplates_Bool_Exp>;
  DocumentTemplates_aggregate?: InputMaybe<DocumentTemplates_Aggregate_Bool_Exp>;
  EscalatedApprovers?: InputMaybe<EscalatedApprovers_Bool_Exp>;
  EscalatedApprovers_aggregate?: InputMaybe<EscalatedApprovers_Aggregate_Bool_Exp>;
  GeneratedDocuments?: InputMaybe<GeneratedDocuments_Bool_Exp>;
  GeneratedDocuments_aggregate?: InputMaybe<GeneratedDocuments_Aggregate_Bool_Exp>;
  PropertyFiles?: InputMaybe<PropertyFiles_Bool_Exp>;
  PropertyFiles_aggregate?: InputMaybe<PropertyFiles_Aggregate_Bool_Exp>;
  TemplatePermittedUses?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
  TemplatePermittedUses_aggregate?: InputMaybe<TemplatePermittedUses_Aggregate_Bool_Exp>;
  TemplateSpecialConditions?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
  TemplateSpecialConditions_aggregate?: InputMaybe<TemplateSpecialConditions_Aggregate_Bool_Exp>;
  UserStatus?: InputMaybe<UserStatus_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  actionAllocationsByAllocatedBy?: InputMaybe<ActionAllocations_Bool_Exp>;
  actionAllocationsByAllocatedBy_aggregate?: InputMaybe<ActionAllocations_Aggregate_Bool_Exp>;
  dealLAFApprovalsByUpdatedBy?: InputMaybe<DealLafApprovals_Bool_Exp>;
  dealLAFApprovalsByUpdatedBy_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Bool_Exp>;
  dealsByLaAssignee?: InputMaybe<Deals_Bool_Exp>;
  dealsByLaAssignee_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  dealsByLastModifiedBy?: InputMaybe<Deals_Bool_Exp>;
  dealsByLastModifiedBy_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  dealsByOwner?: InputMaybe<Deals_Bool_Exp>;
  dealsByOwner_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  dealsByTdAssignee?: InputMaybe<Deals_Bool_Exp>;
  dealsByTdAssignee_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_title?: InputMaybe<String_Comparison_Exp>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  user_full_name?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = "Users_pkey",
}

/** input type for incrementing numeric columns in table "Users" */
export type Users_Inc_Input = {
  status?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Users" */
export type Users_Insert_Input = {
  ActionAllocations?: InputMaybe<ActionAllocations_Arr_Rel_Insert_Input>;
  ActivityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  Approvers?: InputMaybe<Approvers_Arr_Rel_Insert_Input>;
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  DealActionItems?: InputMaybe<DealActionItem_Arr_Rel_Insert_Input>;
  DealFiles?: InputMaybe<DealFiles_Arr_Rel_Insert_Input>;
  DealLAFApprovals?: InputMaybe<DealLafApprovals_Arr_Rel_Insert_Input>;
  Deals?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  DocumentRequests?: InputMaybe<DocumentRequests_Arr_Rel_Insert_Input>;
  DocumentTemplates?: InputMaybe<DocumentTemplates_Arr_Rel_Insert_Input>;
  EscalatedApprovers?: InputMaybe<EscalatedApprovers_Arr_Rel_Insert_Input>;
  GeneratedDocuments?: InputMaybe<GeneratedDocuments_Arr_Rel_Insert_Input>;
  PropertyFiles?: InputMaybe<PropertyFiles_Arr_Rel_Insert_Input>;
  TemplatePermittedUses?: InputMaybe<TemplatePermittedUses_Arr_Rel_Insert_Input>;
  TemplateSpecialConditions?: InputMaybe<TemplateSpecialConditions_Arr_Rel_Insert_Input>;
  UserStatus?: InputMaybe<UserStatus_Obj_Rel_Insert_Input>;
  actionAllocationsByAllocatedBy?: InputMaybe<ActionAllocations_Arr_Rel_Insert_Input>;
  dealLAFApprovalsByUpdatedBy?: InputMaybe<DealLafApprovals_Arr_Rel_Insert_Input>;
  dealsByLaAssignee?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  dealsByLastModifiedBy?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  dealsByOwner?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  dealsByTdAssignee?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  job_title?: InputMaybe<Scalars["String"]>;
  last_login?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Int"]>;
  user_full_name?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "Users_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  job_title?: Maybe<Scalars["String"]>;
  last_login?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  user_full_name?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Users" */
export type Users_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_full_name?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "Users_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  job_title?: Maybe<Scalars["String"]>;
  last_login?: Maybe<Scalars["timestamptz"]>;
  phone?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  user_full_name?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Users" */
export type Users_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_full_name?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Users" */
export type Users_Mutation_Response = {
  __typename?: "Users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "Users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "Users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "Users". */
export type Users_Order_By = {
  ActionAllocations_aggregate?: InputMaybe<ActionAllocations_Aggregate_Order_By>;
  ActivityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  Approvers_aggregate?: InputMaybe<Approvers_Aggregate_Order_By>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  DealActionItems_aggregate?: InputMaybe<DealActionItem_Aggregate_Order_By>;
  DealFiles_aggregate?: InputMaybe<DealFiles_Aggregate_Order_By>;
  DealLAFApprovals_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Order_By>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  DocumentRequests_aggregate?: InputMaybe<DocumentRequests_Aggregate_Order_By>;
  DocumentTemplates_aggregate?: InputMaybe<DocumentTemplates_Aggregate_Order_By>;
  EscalatedApprovers_aggregate?: InputMaybe<EscalatedApprovers_Aggregate_Order_By>;
  GeneratedDocuments_aggregate?: InputMaybe<GeneratedDocuments_Aggregate_Order_By>;
  PropertyFiles_aggregate?: InputMaybe<PropertyFiles_Aggregate_Order_By>;
  TemplatePermittedUses_aggregate?: InputMaybe<TemplatePermittedUses_Aggregate_Order_By>;
  TemplateSpecialConditions_aggregate?: InputMaybe<TemplateSpecialConditions_Aggregate_Order_By>;
  UserStatus?: InputMaybe<UserStatus_Order_By>;
  actionAllocationsByAllocatedBy_aggregate?: InputMaybe<ActionAllocations_Aggregate_Order_By>;
  dealLAFApprovalsByUpdatedBy_aggregate?: InputMaybe<DealLafApprovals_Aggregate_Order_By>;
  dealsByLaAssignee_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  dealsByLastModifiedBy_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  dealsByOwner_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  dealsByTdAssignee_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_full_name?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Users" */
export enum Users_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  JobTitle = "job_title",
  /** column name */
  LastLogin = "last_login",
  /** column name */
  Phone = "phone",
  /** column name */
  Status = "status",
  /** column name */
  UserFullName = "user_full_name",
  /** column name */
  Username = "username",
}

/** input type for updating data in table "Users" */
export type Users_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  job_title?: InputMaybe<Scalars["String"]>;
  last_login?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Int"]>;
  user_full_name?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: "Users_stddev_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Users" */
export type Users_Stddev_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: "Users_stddev_pop_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Users" */
export type Users_Stddev_Pop_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: "Users_stddev_samp_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Users" */
export type Users_Stddev_Samp_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  job_title?: InputMaybe<Scalars["String"]>;
  last_login?: InputMaybe<Scalars["timestamptz"]>;
  phone?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Int"]>;
  user_full_name?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: "Users_sum_fields";
  status?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Users" */
export type Users_Sum_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** update columns of table "Users" */
export enum Users_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  JobTitle = "job_title",
  /** column name */
  LastLogin = "last_login",
  /** column name */
  Phone = "phone",
  /** column name */
  Status = "status",
  /** column name */
  UserFullName = "user_full_name",
  /** column name */
  Username = "username",
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: "Users_var_pop_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Users" */
export type Users_Var_Pop_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: "Users_var_samp_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Users" */
export type Users_Var_Samp_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: "Users_variance_fields";
  status?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Users" */
export type Users_Variance_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "VirtualUnits" */
export type VirtualUnits = {
  __typename?: "VirtualUnits";
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  /** An object relationship */
  Property?: Maybe<Properties>;
  area_survey_required?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted: Scalars["Boolean"];
  estimated_area?: Maybe<Scalars["numeric"]>;
  id: Scalars["bigint"];
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["Int"]>;
  unit_type?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "VirtualUnits" */
export type VirtualUnitsDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "VirtualUnits" */
export type VirtualUnitsDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** aggregated selection of "VirtualUnits" */
export type VirtualUnits_Aggregate = {
  __typename?: "VirtualUnits_aggregate";
  aggregate?: Maybe<VirtualUnits_Aggregate_Fields>;
  nodes: Array<VirtualUnits>;
};

export type VirtualUnits_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<VirtualUnits_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<VirtualUnits_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<VirtualUnits_Aggregate_Bool_Exp_Count>;
};

export type VirtualUnits_Aggregate_Bool_Exp_Bool_And = {
  arguments: VirtualUnits_Select_Column_VirtualUnits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<VirtualUnits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type VirtualUnits_Aggregate_Bool_Exp_Bool_Or = {
  arguments: VirtualUnits_Select_Column_VirtualUnits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<VirtualUnits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type VirtualUnits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<VirtualUnits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "VirtualUnits" */
export type VirtualUnits_Aggregate_Fields = {
  __typename?: "VirtualUnits_aggregate_fields";
  avg?: Maybe<VirtualUnits_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<VirtualUnits_Max_Fields>;
  min?: Maybe<VirtualUnits_Min_Fields>;
  stddev?: Maybe<VirtualUnits_Stddev_Fields>;
  stddev_pop?: Maybe<VirtualUnits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VirtualUnits_Stddev_Samp_Fields>;
  sum?: Maybe<VirtualUnits_Sum_Fields>;
  var_pop?: Maybe<VirtualUnits_Var_Pop_Fields>;
  var_samp?: Maybe<VirtualUnits_Var_Samp_Fields>;
  variance?: Maybe<VirtualUnits_Variance_Fields>;
};

/** aggregate fields of "VirtualUnits" */
export type VirtualUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "VirtualUnits" */
export type VirtualUnits_Aggregate_Order_By = {
  avg?: InputMaybe<VirtualUnits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<VirtualUnits_Max_Order_By>;
  min?: InputMaybe<VirtualUnits_Min_Order_By>;
  stddev?: InputMaybe<VirtualUnits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<VirtualUnits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<VirtualUnits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<VirtualUnits_Sum_Order_By>;
  var_pop?: InputMaybe<VirtualUnits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<VirtualUnits_Var_Samp_Order_By>;
  variance?: InputMaybe<VirtualUnits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "VirtualUnits" */
export type VirtualUnits_Arr_Rel_Insert_Input = {
  data: Array<VirtualUnits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<VirtualUnits_On_Conflict>;
};

/** aggregate avg on columns */
export type VirtualUnits_Avg_Fields = {
  __typename?: "VirtualUnits_avg_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "VirtualUnits" */
export type VirtualUnits_Avg_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "VirtualUnits". All fields are combined with a logical 'AND'. */
export type VirtualUnits_Bool_Exp = {
  DealsUnits?: InputMaybe<DealsUnits_Bool_Exp>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Bool_Exp>;
  Property?: InputMaybe<Properties_Bool_Exp>;
  _and?: InputMaybe<Array<VirtualUnits_Bool_Exp>>;
  _not?: InputMaybe<VirtualUnits_Bool_Exp>;
  _or?: InputMaybe<Array<VirtualUnits_Bool_Exp>>;
  area_survey_required?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  estimated_area?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  property_code?: InputMaybe<String_Comparison_Exp>;
  survey_area?: InputMaybe<Int_Comparison_Exp>;
  unit_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "VirtualUnits" */
export enum VirtualUnits_Constraint {
  /** unique or primary key constraint on columns "id" */
  VirtualUnitsPkey = "VirtualUnits_pkey",
}

/** input type for incrementing numeric columns in table "VirtualUnits" */
export type VirtualUnits_Inc_Input = {
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  survey_area?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "VirtualUnits" */
export type VirtualUnits_Insert_Input = {
  DealsUnits?: InputMaybe<DealsUnits_Arr_Rel_Insert_Input>;
  Property?: InputMaybe<Properties_Obj_Rel_Insert_Input>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["Int"]>;
  unit_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type VirtualUnits_Max_Fields = {
  __typename?: "VirtualUnits_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["Int"]>;
  unit_type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "VirtualUnits" */
export type VirtualUnits_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type VirtualUnits_Min_Fields = {
  __typename?: "VirtualUnits_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  estimated_area?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  label?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  property_code?: Maybe<Scalars["String"]>;
  survey_area?: Maybe<Scalars["Int"]>;
  unit_type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "VirtualUnits" */
export type VirtualUnits_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "VirtualUnits" */
export type VirtualUnits_Mutation_Response = {
  __typename?: "VirtualUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<VirtualUnits>;
};

/** input type for inserting object relation for remote table "VirtualUnits" */
export type VirtualUnits_Obj_Rel_Insert_Input = {
  data: VirtualUnits_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<VirtualUnits_On_Conflict>;
};

/** on_conflict condition type for table "VirtualUnits" */
export type VirtualUnits_On_Conflict = {
  constraint: VirtualUnits_Constraint;
  update_columns?: Array<VirtualUnits_Update_Column>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "VirtualUnits". */
export type VirtualUnits_Order_By = {
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Order_By>;
  Property?: InputMaybe<Properties_Order_By>;
  area_survey_required?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  property_code?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
  unit_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VirtualUnits */
export type VirtualUnits_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "VirtualUnits" */
export enum VirtualUnits_Select_Column {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EstimatedArea = "estimated_area",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  SurveyArea = "survey_area",
  /** column name */
  UnitType = "unit_type",
}

/** select "VirtualUnits_aggregate_bool_exp_bool_and_arguments_columns" columns of table "VirtualUnits" */
export enum VirtualUnits_Select_Column_VirtualUnits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  Deleted = "deleted",
}

/** select "VirtualUnits_aggregate_bool_exp_bool_or_arguments_columns" columns of table "VirtualUnits" */
export enum VirtualUnits_Select_Column_VirtualUnits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "VirtualUnits" */
export type VirtualUnits_Set_Input = {
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["Int"]>;
  unit_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type VirtualUnits_Stddev_Fields = {
  __typename?: "VirtualUnits_stddev_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "VirtualUnits" */
export type VirtualUnits_Stddev_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VirtualUnits_Stddev_Pop_Fields = {
  __typename?: "VirtualUnits_stddev_pop_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "VirtualUnits" */
export type VirtualUnits_Stddev_Pop_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VirtualUnits_Stddev_Samp_Fields = {
  __typename?: "VirtualUnits_stddev_samp_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "VirtualUnits" */
export type VirtualUnits_Stddev_Samp_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "VirtualUnits" */
export type VirtualUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VirtualUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VirtualUnits_Stream_Cursor_Value_Input = {
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  estimated_area?: InputMaybe<Scalars["numeric"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  label?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  property_code?: InputMaybe<Scalars["String"]>;
  survey_area?: InputMaybe<Scalars["Int"]>;
  unit_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type VirtualUnits_Sum_Fields = {
  __typename?: "VirtualUnits_sum_fields";
  estimated_area?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["bigint"]>;
  survey_area?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "VirtualUnits" */
export type VirtualUnits_Sum_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** update columns of table "VirtualUnits" */
export enum VirtualUnits_Update_Column {
  /** column name */
  AreaSurveyRequired = "area_survey_required",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EstimatedArea = "estimated_area",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  PropertyCode = "property_code",
  /** column name */
  SurveyArea = "survey_area",
  /** column name */
  UnitType = "unit_type",
}

export type VirtualUnits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VirtualUnits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VirtualUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: VirtualUnits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type VirtualUnits_Var_Pop_Fields = {
  __typename?: "VirtualUnits_var_pop_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "VirtualUnits" */
export type VirtualUnits_Var_Pop_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VirtualUnits_Var_Samp_Fields = {
  __typename?: "VirtualUnits_var_samp_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "VirtualUnits" */
export type VirtualUnits_Var_Samp_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type VirtualUnits_Variance_Fields = {
  __typename?: "VirtualUnits_variance_fields";
  estimated_area?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  survey_area?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "VirtualUnits" */
export type VirtualUnits_Variance_Order_By = {
  estimated_area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_area?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiActiveLeases" */
export type YardiActiveLeases = {
  __typename?: "YardiActiveLeases";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiTenants_Deals: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_Deals_aggregate: YardiTenants_Deals_Aggregate;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "YardiActiveLeases" */
export type YardiActiveLeasesYardiTenants_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** columns and relationships of "YardiActiveLeases" */
export type YardiActiveLeasesYardiTenants_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** aggregated selection of "YardiActiveLeases" */
export type YardiActiveLeases_Aggregate = {
  __typename?: "YardiActiveLeases_aggregate";
  aggregate?: Maybe<YardiActiveLeases_Aggregate_Fields>;
  nodes: Array<YardiActiveLeases>;
};

/** aggregate fields of "YardiActiveLeases" */
export type YardiActiveLeases_Aggregate_Fields = {
  __typename?: "YardiActiveLeases_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiActiveLeases_Max_Fields>;
  min?: Maybe<YardiActiveLeases_Min_Fields>;
};

/** aggregate fields of "YardiActiveLeases" */
export type YardiActiveLeases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiActiveLeases_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiActiveLeases". All fields are combined with a logical 'AND'. */
export type YardiActiveLeases_Bool_Exp = {
  Anchor?: InputMaybe<String_Comparison_Exp>;
  BaseRent?: InputMaybe<String_Comparison_Exp>;
  BrandCode?: InputMaybe<String_Comparison_Exp>;
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  EndDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  IsHoldover?: InputMaybe<String_Comparison_Exp>;
  LeaseType?: InputMaybe<String_Comparison_Exp>;
  MaintenanceNotes?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  Outgoings?: InputMaybe<String_Comparison_Exp>;
  PromoLevy?: InputMaybe<String_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  SalesCategory?: InputMaybe<String_Comparison_Exp>;
  TIC?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  TenantName?: InputMaybe<String_Comparison_Exp>;
  TenantStartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantStatus?: InputMaybe<String_Comparison_Exp>;
  TradeName?: InputMaybe<String_Comparison_Exp>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<YardiActiveLeases_Bool_Exp>>;
  _not?: InputMaybe<YardiActiveLeases_Bool_Exp>;
  _or?: InputMaybe<Array<YardiActiveLeases_Bool_Exp>>;
  unit1?: InputMaybe<String_Comparison_Exp>;
  unit2?: InputMaybe<String_Comparison_Exp>;
  unit3?: InputMaybe<String_Comparison_Exp>;
  unit4?: InputMaybe<String_Comparison_Exp>;
  unit5?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "YardiActiveLeases" */
export type YardiActiveLeases_Insert_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  EndDate?: InputMaybe<Scalars["timestamptz"]>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Arr_Rel_Insert_Input>;
  unit1?: InputMaybe<Scalars["String"]>;
  unit2?: InputMaybe<Scalars["String"]>;
  unit3?: InputMaybe<Scalars["String"]>;
  unit4?: InputMaybe<Scalars["String"]>;
  unit5?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type YardiActiveLeases_Max_Fields = {
  __typename?: "YardiActiveLeases_max_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type YardiActiveLeases_Min_Fields = {
  __typename?: "YardiActiveLeases_min_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** input type for inserting object relation for remote table "YardiActiveLeases" */
export type YardiActiveLeases_Obj_Rel_Insert_Input = {
  data: YardiActiveLeases_Insert_Input;
};

/** Ordering options when selecting data from "YardiActiveLeases". */
export type YardiActiveLeases_Order_By = {
  Anchor?: InputMaybe<Order_By>;
  BaseRent?: InputMaybe<Order_By>;
  BrandCode?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  IsHoldover?: InputMaybe<Order_By>;
  LeaseType?: InputMaybe<Order_By>;
  MaintenanceNotes?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  Outgoings?: InputMaybe<Order_By>;
  PromoLevy?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  SalesCategory?: InputMaybe<Order_By>;
  TIC?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  TenantName?: InputMaybe<Order_By>;
  TenantStartDate?: InputMaybe<Order_By>;
  TenantStatus?: InputMaybe<Order_By>;
  TradeName?: InputMaybe<Order_By>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Order_By>;
  unit1?: InputMaybe<Order_By>;
  unit2?: InputMaybe<Order_By>;
  unit3?: InputMaybe<Order_By>;
  unit4?: InputMaybe<Order_By>;
  unit5?: InputMaybe<Order_By>;
};

/** select columns of table "YardiActiveLeases" */
export enum YardiActiveLeases_Select_Column {
  /** column name */
  Anchor = "Anchor",
  /** column name */
  BaseRent = "BaseRent",
  /** column name */
  BrandCode = "BrandCode",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  EndDate = "EndDate",
  /** column name */
  IsHoldover = "IsHoldover",
  /** column name */
  LeaseType = "LeaseType",
  /** column name */
  MaintenanceNotes = "MaintenanceNotes",
  /** column name */
  Notes = "Notes",
  /** column name */
  Outgoings = "Outgoings",
  /** column name */
  PromoLevy = "PromoLevy",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  SalesCategory = "SalesCategory",
  /** column name */
  Tic = "TIC",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantStartDate = "TenantStartDate",
  /** column name */
  TenantStatus = "TenantStatus",
  /** column name */
  TradeName = "TradeName",
  /** column name */
  Unit1 = "unit1",
  /** column name */
  Unit2 = "unit2",
  /** column name */
  Unit3 = "unit3",
  /** column name */
  Unit4 = "unit4",
  /** column name */
  Unit5 = "unit5",
}

/** Streaming cursor of the table "YardiActiveLeases" */
export type YardiActiveLeases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiActiveLeases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiActiveLeases_Stream_Cursor_Value_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  EndDate?: InputMaybe<Scalars["timestamptz"]>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  unit1?: InputMaybe<Scalars["String"]>;
  unit2?: InputMaybe<Scalars["String"]>;
  unit3?: InputMaybe<Scalars["String"]>;
  unit4?: InputMaybe<Scalars["String"]>;
  unit5?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "YardiAmendments" */
export type YardiAmendments = {
  __typename?: "YardiAmendments";
  AmendmentDescription?: Maybe<Scalars["String"]>;
  AmendmentFromDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentKey: Scalars["String"];
  AmendmentSequence: Scalars["Int"];
  AmendmentStatus?: Maybe<Scalars["String"]>;
  AmendmentTerm?: Maybe<Scalars["String"]>;
  AmendmentToDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentType?: Maybe<Scalars["String"]>;
  ContractedArea?: Maybe<Scalars["numeric"]>;
  HandoverDate?: Maybe<Scalars["timestamptz"]>;
  MonthToMonth?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  OccupancyCert?: Maybe<Scalars["String"]>;
  PossessionDate?: Maybe<Scalars["timestamptz"]>;
  SignDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Units?: Maybe<Scalars["String"]>;
  /** An object relationship */
  YardiTenant?: Maybe<YardiTenants>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiAmendments_Deals" */
export type YardiAmendments_Deals = {
  __typename?: "YardiAmendments_Deals";
  AmendmentCode: Scalars["String"];
  /** An object relationship */
  Deal: Deals;
  created_at: Scalars["timestamptz"];
  deal_id: Scalars["bigint"];
};

/** aggregated selection of "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Aggregate = {
  __typename?: "YardiAmendments_Deals_aggregate";
  aggregate?: Maybe<YardiAmendments_Deals_Aggregate_Fields>;
  nodes: Array<YardiAmendments_Deals>;
};

export type YardiAmendments_Deals_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiAmendments_Deals_Aggregate_Bool_Exp_Count>;
};

export type YardiAmendments_Deals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Aggregate_Fields = {
  __typename?: "YardiAmendments_Deals_aggregate_fields";
  avg?: Maybe<YardiAmendments_Deals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiAmendments_Deals_Max_Fields>;
  min?: Maybe<YardiAmendments_Deals_Min_Fields>;
  stddev?: Maybe<YardiAmendments_Deals_Stddev_Fields>;
  stddev_pop?: Maybe<YardiAmendments_Deals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiAmendments_Deals_Stddev_Samp_Fields>;
  sum?: Maybe<YardiAmendments_Deals_Sum_Fields>;
  var_pop?: Maybe<YardiAmendments_Deals_Var_Pop_Fields>;
  var_samp?: Maybe<YardiAmendments_Deals_Var_Samp_Fields>;
  variance?: Maybe<YardiAmendments_Deals_Variance_Fields>;
};

/** aggregate fields of "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Aggregate_Order_By = {
  avg?: InputMaybe<YardiAmendments_Deals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiAmendments_Deals_Max_Order_By>;
  min?: InputMaybe<YardiAmendments_Deals_Min_Order_By>;
  stddev?: InputMaybe<YardiAmendments_Deals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiAmendments_Deals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiAmendments_Deals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiAmendments_Deals_Sum_Order_By>;
  var_pop?: InputMaybe<YardiAmendments_Deals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiAmendments_Deals_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiAmendments_Deals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Arr_Rel_Insert_Input = {
  data: Array<YardiAmendments_Deals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiAmendments_Deals_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiAmendments_Deals_Avg_Fields = {
  __typename?: "YardiAmendments_Deals_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiAmendments_Deals". All fields are combined with a logical 'AND'. */
export type YardiAmendments_Deals_Bool_Exp = {
  AmendmentCode?: InputMaybe<String_Comparison_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<YardiAmendments_Deals_Bool_Exp>>;
  _not?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
  _or?: InputMaybe<Array<YardiAmendments_Deals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiAmendments_Deals" */
export enum YardiAmendments_Deals_Constraint {
  /** unique or primary key constraint on columns "deal_id", "AmendmentCode" */
  YardiAmendmentsDealsPkey = "YardiAmendments_Deals_pkey",
}

/** input type for incrementing numeric columns in table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Insert_Input = {
  AmendmentCode?: InputMaybe<Scalars["String"]>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type YardiAmendments_Deals_Max_Fields = {
  __typename?: "YardiAmendments_Deals_max_fields";
  AmendmentCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Max_Order_By = {
  AmendmentCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiAmendments_Deals_Min_Fields = {
  __typename?: "YardiAmendments_Deals_min_fields";
  AmendmentCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Min_Order_By = {
  AmendmentCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Mutation_Response = {
  __typename?: "YardiAmendments_Deals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiAmendments_Deals>;
};

/** on_conflict condition type for table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_On_Conflict = {
  constraint: YardiAmendments_Deals_Constraint;
  update_columns?: Array<YardiAmendments_Deals_Update_Column>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiAmendments_Deals". */
export type YardiAmendments_Deals_Order_By = {
  AmendmentCode?: InputMaybe<Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiAmendments_Deals */
export type YardiAmendments_Deals_Pk_Columns_Input = {
  AmendmentCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** select columns of table "YardiAmendments_Deals" */
export enum YardiAmendments_Deals_Select_Column {
  /** column name */
  AmendmentCode = "AmendmentCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

/** input type for updating data in table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Set_Input = {
  AmendmentCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type YardiAmendments_Deals_Stddev_Fields = {
  __typename?: "YardiAmendments_Deals_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiAmendments_Deals_Stddev_Pop_Fields = {
  __typename?: "YardiAmendments_Deals_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiAmendments_Deals_Stddev_Samp_Fields = {
  __typename?: "YardiAmendments_Deals_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiAmendments_Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiAmendments_Deals_Stream_Cursor_Value_Input = {
  AmendmentCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type YardiAmendments_Deals_Sum_Fields = {
  __typename?: "YardiAmendments_Deals_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** update columns of table "YardiAmendments_Deals" */
export enum YardiAmendments_Deals_Update_Column {
  /** column name */
  AmendmentCode = "AmendmentCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

export type YardiAmendments_Deals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiAmendments_Deals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiAmendments_Deals_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiAmendments_Deals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiAmendments_Deals_Var_Pop_Fields = {
  __typename?: "YardiAmendments_Deals_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiAmendments_Deals_Var_Samp_Fields = {
  __typename?: "YardiAmendments_Deals_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiAmendments_Deals_Variance_Fields = {
  __typename?: "YardiAmendments_Deals_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiAmendments_Deals" */
export type YardiAmendments_Deals_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "YardiAmendments" */
export type YardiAmendments_Aggregate = {
  __typename?: "YardiAmendments_aggregate";
  aggregate?: Maybe<YardiAmendments_Aggregate_Fields>;
  nodes: Array<YardiAmendments>;
};

export type YardiAmendments_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiAmendments_Aggregate_Bool_Exp_Count>;
};

export type YardiAmendments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiAmendments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiAmendments" */
export type YardiAmendments_Aggregate_Fields = {
  __typename?: "YardiAmendments_aggregate_fields";
  avg?: Maybe<YardiAmendments_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiAmendments_Max_Fields>;
  min?: Maybe<YardiAmendments_Min_Fields>;
  stddev?: Maybe<YardiAmendments_Stddev_Fields>;
  stddev_pop?: Maybe<YardiAmendments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiAmendments_Stddev_Samp_Fields>;
  sum?: Maybe<YardiAmendments_Sum_Fields>;
  var_pop?: Maybe<YardiAmendments_Var_Pop_Fields>;
  var_samp?: Maybe<YardiAmendments_Var_Samp_Fields>;
  variance?: Maybe<YardiAmendments_Variance_Fields>;
};

/** aggregate fields of "YardiAmendments" */
export type YardiAmendments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiAmendments" */
export type YardiAmendments_Aggregate_Order_By = {
  avg?: InputMaybe<YardiAmendments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiAmendments_Max_Order_By>;
  min?: InputMaybe<YardiAmendments_Min_Order_By>;
  stddev?: InputMaybe<YardiAmendments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiAmendments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiAmendments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiAmendments_Sum_Order_By>;
  var_pop?: InputMaybe<YardiAmendments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiAmendments_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiAmendments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiAmendments" */
export type YardiAmendments_Arr_Rel_Insert_Input = {
  data: Array<YardiAmendments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiAmendments_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiAmendments_Avg_Fields = {
  __typename?: "YardiAmendments_avg_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiAmendments" */
export type YardiAmendments_Avg_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiAmendments". All fields are combined with a logical 'AND'. */
export type YardiAmendments_Bool_Exp = {
  AmendmentDescription?: InputMaybe<String_Comparison_Exp>;
  AmendmentFromDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  AmendmentKey?: InputMaybe<String_Comparison_Exp>;
  AmendmentSequence?: InputMaybe<Int_Comparison_Exp>;
  AmendmentStatus?: InputMaybe<String_Comparison_Exp>;
  AmendmentTerm?: InputMaybe<String_Comparison_Exp>;
  AmendmentToDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  AmendmentType?: InputMaybe<String_Comparison_Exp>;
  ContractedArea?: InputMaybe<Numeric_Comparison_Exp>;
  HandoverDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  MonthToMonth?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  OccupancyCert?: InputMaybe<String_Comparison_Exp>;
  PossessionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  SignDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  Units?: InputMaybe<String_Comparison_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  _and?: InputMaybe<Array<YardiAmendments_Bool_Exp>>;
  _not?: InputMaybe<YardiAmendments_Bool_Exp>;
  _or?: InputMaybe<Array<YardiAmendments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiAmendments" */
export enum YardiAmendments_Constraint {
  /** unique or primary key constraint on columns "AmendmentKey" */
  YardiAmendmentsPkey = "YardiAmendments_pkey",
}

/** input type for incrementing numeric columns in table "YardiAmendments" */
export type YardiAmendments_Inc_Input = {
  AmendmentSequence?: InputMaybe<Scalars["Int"]>;
  ContractedArea?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "YardiAmendments" */
export type YardiAmendments_Insert_Input = {
  AmendmentDescription?: InputMaybe<Scalars["String"]>;
  AmendmentFromDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentKey?: InputMaybe<Scalars["String"]>;
  AmendmentSequence?: InputMaybe<Scalars["Int"]>;
  AmendmentStatus?: InputMaybe<Scalars["String"]>;
  AmendmentTerm?: InputMaybe<Scalars["String"]>;
  AmendmentToDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentType?: InputMaybe<Scalars["String"]>;
  ContractedArea?: InputMaybe<Scalars["numeric"]>;
  HandoverDate?: InputMaybe<Scalars["timestamptz"]>;
  MonthToMonth?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  OccupancyCert?: InputMaybe<Scalars["String"]>;
  PossessionDate?: InputMaybe<Scalars["timestamptz"]>;
  SignDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Units?: InputMaybe<Scalars["String"]>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiAmendments_Max_Fields = {
  __typename?: "YardiAmendments_max_fields";
  AmendmentDescription?: Maybe<Scalars["String"]>;
  AmendmentFromDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentKey?: Maybe<Scalars["String"]>;
  AmendmentSequence?: Maybe<Scalars["Int"]>;
  AmendmentStatus?: Maybe<Scalars["String"]>;
  AmendmentTerm?: Maybe<Scalars["String"]>;
  AmendmentToDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentType?: Maybe<Scalars["String"]>;
  ContractedArea?: Maybe<Scalars["numeric"]>;
  HandoverDate?: Maybe<Scalars["timestamptz"]>;
  MonthToMonth?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  OccupancyCert?: Maybe<Scalars["String"]>;
  PossessionDate?: Maybe<Scalars["timestamptz"]>;
  SignDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Units?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiAmendments" */
export type YardiAmendments_Max_Order_By = {
  AmendmentDescription?: InputMaybe<Order_By>;
  AmendmentFromDate?: InputMaybe<Order_By>;
  AmendmentKey?: InputMaybe<Order_By>;
  AmendmentSequence?: InputMaybe<Order_By>;
  AmendmentStatus?: InputMaybe<Order_By>;
  AmendmentTerm?: InputMaybe<Order_By>;
  AmendmentToDate?: InputMaybe<Order_By>;
  AmendmentType?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
  HandoverDate?: InputMaybe<Order_By>;
  MonthToMonth?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  OccupancyCert?: InputMaybe<Order_By>;
  PossessionDate?: InputMaybe<Order_By>;
  SignDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Units?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiAmendments_Min_Fields = {
  __typename?: "YardiAmendments_min_fields";
  AmendmentDescription?: Maybe<Scalars["String"]>;
  AmendmentFromDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentKey?: Maybe<Scalars["String"]>;
  AmendmentSequence?: Maybe<Scalars["Int"]>;
  AmendmentStatus?: Maybe<Scalars["String"]>;
  AmendmentTerm?: Maybe<Scalars["String"]>;
  AmendmentToDate?: Maybe<Scalars["timestamptz"]>;
  AmendmentType?: Maybe<Scalars["String"]>;
  ContractedArea?: Maybe<Scalars["numeric"]>;
  HandoverDate?: Maybe<Scalars["timestamptz"]>;
  MonthToMonth?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  OccupancyCert?: Maybe<Scalars["String"]>;
  PossessionDate?: Maybe<Scalars["timestamptz"]>;
  SignDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Units?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiAmendments" */
export type YardiAmendments_Min_Order_By = {
  AmendmentDescription?: InputMaybe<Order_By>;
  AmendmentFromDate?: InputMaybe<Order_By>;
  AmendmentKey?: InputMaybe<Order_By>;
  AmendmentSequence?: InputMaybe<Order_By>;
  AmendmentStatus?: InputMaybe<Order_By>;
  AmendmentTerm?: InputMaybe<Order_By>;
  AmendmentToDate?: InputMaybe<Order_By>;
  AmendmentType?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
  HandoverDate?: InputMaybe<Order_By>;
  MonthToMonth?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  OccupancyCert?: InputMaybe<Order_By>;
  PossessionDate?: InputMaybe<Order_By>;
  SignDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Units?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiAmendments" */
export type YardiAmendments_Mutation_Response = {
  __typename?: "YardiAmendments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiAmendments>;
};

/** on_conflict condition type for table "YardiAmendments" */
export type YardiAmendments_On_Conflict = {
  constraint: YardiAmendments_Constraint;
  update_columns?: Array<YardiAmendments_Update_Column>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiAmendments". */
export type YardiAmendments_Order_By = {
  AmendmentDescription?: InputMaybe<Order_By>;
  AmendmentFromDate?: InputMaybe<Order_By>;
  AmendmentKey?: InputMaybe<Order_By>;
  AmendmentSequence?: InputMaybe<Order_By>;
  AmendmentStatus?: InputMaybe<Order_By>;
  AmendmentTerm?: InputMaybe<Order_By>;
  AmendmentToDate?: InputMaybe<Order_By>;
  AmendmentType?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
  HandoverDate?: InputMaybe<Order_By>;
  MonthToMonth?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  OccupancyCert?: InputMaybe<Order_By>;
  PossessionDate?: InputMaybe<Order_By>;
  SignDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Units?: InputMaybe<Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiAmendments */
export type YardiAmendments_Pk_Columns_Input = {
  AmendmentKey: Scalars["String"];
};

/** select columns of table "YardiAmendments" */
export enum YardiAmendments_Select_Column {
  /** column name */
  AmendmentDescription = "AmendmentDescription",
  /** column name */
  AmendmentFromDate = "AmendmentFromDate",
  /** column name */
  AmendmentKey = "AmendmentKey",
  /** column name */
  AmendmentSequence = "AmendmentSequence",
  /** column name */
  AmendmentStatus = "AmendmentStatus",
  /** column name */
  AmendmentTerm = "AmendmentTerm",
  /** column name */
  AmendmentToDate = "AmendmentToDate",
  /** column name */
  AmendmentType = "AmendmentType",
  /** column name */
  ContractedArea = "ContractedArea",
  /** column name */
  HandoverDate = "HandoverDate",
  /** column name */
  MonthToMonth = "MonthToMonth",
  /** column name */
  Notes = "Notes",
  /** column name */
  OccupancyCert = "OccupancyCert",
  /** column name */
  PossessionDate = "PossessionDate",
  /** column name */
  SignDate = "SignDate",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  Units = "Units",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiAmendments" */
export type YardiAmendments_Set_Input = {
  AmendmentDescription?: InputMaybe<Scalars["String"]>;
  AmendmentFromDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentKey?: InputMaybe<Scalars["String"]>;
  AmendmentSequence?: InputMaybe<Scalars["Int"]>;
  AmendmentStatus?: InputMaybe<Scalars["String"]>;
  AmendmentTerm?: InputMaybe<Scalars["String"]>;
  AmendmentToDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentType?: InputMaybe<Scalars["String"]>;
  ContractedArea?: InputMaybe<Scalars["numeric"]>;
  HandoverDate?: InputMaybe<Scalars["timestamptz"]>;
  MonthToMonth?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  OccupancyCert?: InputMaybe<Scalars["String"]>;
  PossessionDate?: InputMaybe<Scalars["timestamptz"]>;
  SignDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Units?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiAmendments_Stddev_Fields = {
  __typename?: "YardiAmendments_stddev_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiAmendments" */
export type YardiAmendments_Stddev_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiAmendments_Stddev_Pop_Fields = {
  __typename?: "YardiAmendments_stddev_pop_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiAmendments" */
export type YardiAmendments_Stddev_Pop_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiAmendments_Stddev_Samp_Fields = {
  __typename?: "YardiAmendments_stddev_samp_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiAmendments" */
export type YardiAmendments_Stddev_Samp_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiAmendments" */
export type YardiAmendments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiAmendments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiAmendments_Stream_Cursor_Value_Input = {
  AmendmentDescription?: InputMaybe<Scalars["String"]>;
  AmendmentFromDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentKey?: InputMaybe<Scalars["String"]>;
  AmendmentSequence?: InputMaybe<Scalars["Int"]>;
  AmendmentStatus?: InputMaybe<Scalars["String"]>;
  AmendmentTerm?: InputMaybe<Scalars["String"]>;
  AmendmentToDate?: InputMaybe<Scalars["timestamptz"]>;
  AmendmentType?: InputMaybe<Scalars["String"]>;
  ContractedArea?: InputMaybe<Scalars["numeric"]>;
  HandoverDate?: InputMaybe<Scalars["timestamptz"]>;
  MonthToMonth?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  OccupancyCert?: InputMaybe<Scalars["String"]>;
  PossessionDate?: InputMaybe<Scalars["timestamptz"]>;
  SignDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Units?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiAmendments_Sum_Fields = {
  __typename?: "YardiAmendments_sum_fields";
  AmendmentSequence?: Maybe<Scalars["Int"]>;
  ContractedArea?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "YardiAmendments" */
export type YardiAmendments_Sum_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** update columns of table "YardiAmendments" */
export enum YardiAmendments_Update_Column {
  /** column name */
  AmendmentDescription = "AmendmentDescription",
  /** column name */
  AmendmentFromDate = "AmendmentFromDate",
  /** column name */
  AmendmentKey = "AmendmentKey",
  /** column name */
  AmendmentSequence = "AmendmentSequence",
  /** column name */
  AmendmentStatus = "AmendmentStatus",
  /** column name */
  AmendmentTerm = "AmendmentTerm",
  /** column name */
  AmendmentToDate = "AmendmentToDate",
  /** column name */
  AmendmentType = "AmendmentType",
  /** column name */
  ContractedArea = "ContractedArea",
  /** column name */
  HandoverDate = "HandoverDate",
  /** column name */
  MonthToMonth = "MonthToMonth",
  /** column name */
  Notes = "Notes",
  /** column name */
  OccupancyCert = "OccupancyCert",
  /** column name */
  PossessionDate = "PossessionDate",
  /** column name */
  SignDate = "SignDate",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  Units = "Units",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiAmendments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiAmendments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiAmendments_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiAmendments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiAmendments_Var_Pop_Fields = {
  __typename?: "YardiAmendments_var_pop_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiAmendments" */
export type YardiAmendments_Var_Pop_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiAmendments_Var_Samp_Fields = {
  __typename?: "YardiAmendments_var_samp_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiAmendments" */
export type YardiAmendments_Var_Samp_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiAmendments_Variance_Fields = {
  __typename?: "YardiAmendments_variance_fields";
  AmendmentSequence?: Maybe<Scalars["Float"]>;
  ContractedArea?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiAmendments" */
export type YardiAmendments_Variance_Order_By = {
  AmendmentSequence?: InputMaybe<Order_By>;
  ContractedArea?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiContacts" */
export type YardiContacts = {
  __typename?: "YardiContacts";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  Address3?: Maybe<Scalars["String"]>;
  Address4?: Maybe<Scalars["String"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  ContactCode: Scalars["String"];
  Country?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  ExtRefId?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  IsInternal: Scalars["Boolean"];
  IsPrimary?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  PhoneNumber1?: Maybe<Scalars["String"]>;
  PhoneNumber2?: Maybe<Scalars["String"]>;
  PhoneNumber3?: Maybe<Scalars["String"]>;
  PhoneNumber4?: Maybe<Scalars["String"]>;
  PhoneNumber5?: Maybe<Scalars["String"]>;
  PhoneNumber6?: Maybe<Scalars["String"]>;
  PhoneNumber7?: Maybe<Scalars["String"]>;
  PhoneNumber8?: Maybe<Scalars["String"]>;
  PhoneNumber9?: Maybe<Scalars["String"]>;
  PhoneNumber10?: Maybe<Scalars["String"]>;
  RecordType?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Salutation?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Title?: Maybe<Scalars["String"]>;
  URL?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiContacts_Contacts: Array<YardiContacts_Contacts>;
  /** An aggregate relationship */
  YardiContacts_Contacts_aggregate: YardiContacts_Contacts_Aggregate;
  /** An array relationship */
  YardiContacts_YardiProperties: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_YardiProperties_aggregate: YardiContacts_YardiProperties_Aggregate;
  /** An object relationship */
  YardiCustomer?: Maybe<YardiCustomers>;
  /** An object relationship */
  YardiTenant?: Maybe<YardiTenants>;
  ZipCode?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiContacts" */
export type YardiContactsYardiContacts_ContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

/** columns and relationships of "YardiContacts" */
export type YardiContactsYardiContacts_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

/** columns and relationships of "YardiContacts" */
export type YardiContactsYardiContacts_YardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "YardiContacts" */
export type YardiContactsYardiContacts_YardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "YardiContacts_Contacts" */
export type YardiContacts_Contacts = {
  __typename?: "YardiContacts_Contacts";
  /** An object relationship */
  Contact: Contacts;
  ContactCode: Scalars["String"];
  /** An object relationship */
  YardiContact: YardiContacts;
  contact_id: Scalars["bigint"];
  created_at: Scalars["timestamptz"];
};

/** aggregated selection of "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Aggregate = {
  __typename?: "YardiContacts_Contacts_aggregate";
  aggregate?: Maybe<YardiContacts_Contacts_Aggregate_Fields>;
  nodes: Array<YardiContacts_Contacts>;
};

export type YardiContacts_Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiContacts_Contacts_Aggregate_Bool_Exp_Count>;
};

export type YardiContacts_Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Aggregate_Fields = {
  __typename?: "YardiContacts_Contacts_aggregate_fields";
  avg?: Maybe<YardiContacts_Contacts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiContacts_Contacts_Max_Fields>;
  min?: Maybe<YardiContacts_Contacts_Min_Fields>;
  stddev?: Maybe<YardiContacts_Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<YardiContacts_Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiContacts_Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<YardiContacts_Contacts_Sum_Fields>;
  var_pop?: Maybe<YardiContacts_Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<YardiContacts_Contacts_Var_Samp_Fields>;
  variance?: Maybe<YardiContacts_Contacts_Variance_Fields>;
};

/** aggregate fields of "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Aggregate_Order_By = {
  avg?: InputMaybe<YardiContacts_Contacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiContacts_Contacts_Max_Order_By>;
  min?: InputMaybe<YardiContacts_Contacts_Min_Order_By>;
  stddev?: InputMaybe<YardiContacts_Contacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiContacts_Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiContacts_Contacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiContacts_Contacts_Sum_Order_By>;
  var_pop?: InputMaybe<YardiContacts_Contacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiContacts_Contacts_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiContacts_Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Arr_Rel_Insert_Input = {
  data: Array<YardiContacts_Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiContacts_Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiContacts_Contacts_Avg_Fields = {
  __typename?: "YardiContacts_Contacts_avg_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Avg_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiContacts_Contacts". All fields are combined with a logical 'AND'. */
export type YardiContacts_Contacts_Bool_Exp = {
  Contact?: InputMaybe<Contacts_Bool_Exp>;
  ContactCode?: InputMaybe<String_Comparison_Exp>;
  YardiContact?: InputMaybe<YardiContacts_Bool_Exp>;
  _and?: InputMaybe<Array<YardiContacts_Contacts_Bool_Exp>>;
  _not?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<YardiContacts_Contacts_Bool_Exp>>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiContacts_Contacts" */
export enum YardiContacts_Contacts_Constraint {
  /** unique or primary key constraint on columns "ContactCode", "contact_id" */
  YardiContactsContactsPkey = "YardiContacts_Contacts_pkey",
}

/** input type for incrementing numeric columns in table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Inc_Input = {
  contact_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Insert_Input = {
  Contact?: InputMaybe<Contacts_Obj_Rel_Insert_Input>;
  ContactCode?: InputMaybe<Scalars["String"]>;
  YardiContact?: InputMaybe<YardiContacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiContacts_Contacts_Max_Fields = {
  __typename?: "YardiContacts_Contacts_max_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Max_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiContacts_Contacts_Min_Fields = {
  __typename?: "YardiContacts_Contacts_min_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  contact_id?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Min_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Mutation_Response = {
  __typename?: "YardiContacts_Contacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiContacts_Contacts>;
};

/** on_conflict condition type for table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_On_Conflict = {
  constraint: YardiContacts_Contacts_Constraint;
  update_columns?: Array<YardiContacts_Contacts_Update_Column>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiContacts_Contacts". */
export type YardiContacts_Contacts_Order_By = {
  Contact?: InputMaybe<Contacts_Order_By>;
  ContactCode?: InputMaybe<Order_By>;
  YardiContact?: InputMaybe<YardiContacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiContacts_Contacts */
export type YardiContacts_Contacts_Pk_Columns_Input = {
  ContactCode: Scalars["String"];
  contact_id: Scalars["bigint"];
};

/** select columns of table "YardiContacts_Contacts" */
export enum YardiContacts_Contacts_Select_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
}

/** input type for updating data in table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Set_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiContacts_Contacts_Stddev_Fields = {
  __typename?: "YardiContacts_Contacts_stddev_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Stddev_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiContacts_Contacts_Stddev_Pop_Fields = {
  __typename?: "YardiContacts_Contacts_stddev_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Stddev_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiContacts_Contacts_Stddev_Samp_Fields = {
  __typename?: "YardiContacts_Contacts_stddev_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Stddev_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiContacts_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiContacts_Contacts_Stream_Cursor_Value_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  contact_id?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiContacts_Contacts_Sum_Fields = {
  __typename?: "YardiContacts_Contacts_sum_fields";
  contact_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Sum_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** update columns of table "YardiContacts_Contacts" */
export enum YardiContacts_Contacts_Update_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  ContactId = "contact_id",
  /** column name */
  CreatedAt = "created_at",
}

export type YardiContacts_Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiContacts_Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiContacts_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiContacts_Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiContacts_Contacts_Var_Pop_Fields = {
  __typename?: "YardiContacts_Contacts_var_pop_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Var_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiContacts_Contacts_Var_Samp_Fields = {
  __typename?: "YardiContacts_Contacts_var_samp_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Var_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiContacts_Contacts_Variance_Fields = {
  __typename?: "YardiContacts_Contacts_variance_fields";
  contact_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiContacts_Contacts" */
export type YardiContacts_Contacts_Variance_Order_By = {
  contact_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners = {
  __typename?: "YardiContacts_DealOwners";
  ContactCode: Scalars["String"];
  created_at: Scalars["time"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Aggregate = {
  __typename?: "YardiContacts_DealOwners_aggregate";
  aggregate?: Maybe<YardiContacts_DealOwners_Aggregate_Fields>;
  nodes: Array<YardiContacts_DealOwners>;
};

/** aggregate fields of "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Aggregate_Fields = {
  __typename?: "YardiContacts_DealOwners_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiContacts_DealOwners_Max_Fields>;
  min?: Maybe<YardiContacts_DealOwners_Min_Fields>;
};

/** aggregate fields of "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiContacts_DealOwners_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiContacts_DealOwners". All fields are combined with a logical 'AND'. */
export type YardiContacts_DealOwners_Bool_Exp = {
  ContactCode?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiContacts_DealOwners_Bool_Exp>>;
  _not?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
  _or?: InputMaybe<Array<YardiContacts_DealOwners_Bool_Exp>>;
  created_at?: InputMaybe<Time_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiContacts_DealOwners" */
export enum YardiContacts_DealOwners_Constraint {
  /** unique or primary key constraint on columns "ContactCode", "user_id" */
  YardiContactsDealOwnersPkey = "YardiContacts_DealOwners_pkey",
}

/** input type for inserting data into table "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Insert_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["time"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type YardiContacts_DealOwners_Max_Fields = {
  __typename?: "YardiContacts_DealOwners_max_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type YardiContacts_DealOwners_Min_Fields = {
  __typename?: "YardiContacts_DealOwners_min_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Mutation_Response = {
  __typename?: "YardiContacts_DealOwners_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiContacts_DealOwners>;
};

/** on_conflict condition type for table "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_On_Conflict = {
  constraint: YardiContacts_DealOwners_Constraint;
  update_columns?: Array<YardiContacts_DealOwners_Update_Column>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiContacts_DealOwners". */
export type YardiContacts_DealOwners_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiContacts_DealOwners */
export type YardiContacts_DealOwners_Pk_Columns_Input = {
  ContactCode: Scalars["String"];
  user_id: Scalars["uuid"];
};

/** select columns of table "YardiContacts_DealOwners" */
export enum YardiContacts_DealOwners_Select_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Set_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["time"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "YardiContacts_DealOwners" */
export type YardiContacts_DealOwners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiContacts_DealOwners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiContacts_DealOwners_Stream_Cursor_Value_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["time"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "YardiContacts_DealOwners" */
export enum YardiContacts_DealOwners_Update_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  UserId = "user_id",
}

export type YardiContacts_DealOwners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiContacts_DealOwners_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiContacts_DealOwners_Bool_Exp;
};

/** columns and relationships of "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties = {
  __typename?: "YardiContacts_YardiProperties";
  ContactCode: Scalars["String"];
  PropertyCode: Scalars["String"];
  Role?: Maybe<Scalars["String"]>;
  /** An object relationship */
  YardiContact: YardiContacts;
  /** An object relationship */
  YardiProperty: YardiProperties;
  created_at: Scalars["timestamptz"];
};

/** aggregated selection of "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Aggregate = {
  __typename?: "YardiContacts_YardiProperties_aggregate";
  aggregate?: Maybe<YardiContacts_YardiProperties_Aggregate_Fields>;
  nodes: Array<YardiContacts_YardiProperties>;
};

export type YardiContacts_YardiProperties_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Bool_Exp_Count>;
};

export type YardiContacts_YardiProperties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Aggregate_Fields = {
  __typename?: "YardiContacts_YardiProperties_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiContacts_YardiProperties_Max_Fields>;
  min?: Maybe<YardiContacts_YardiProperties_Min_Fields>;
};

/** aggregate fields of "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiContacts_YardiProperties_Max_Order_By>;
  min?: InputMaybe<YardiContacts_YardiProperties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Arr_Rel_Insert_Input = {
  data: Array<YardiContacts_YardiProperties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiContacts_YardiProperties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "YardiContacts_YardiProperties". All fields are combined with a logical 'AND'. */
export type YardiContacts_YardiProperties_Bool_Exp = {
  ContactCode?: InputMaybe<String_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  Role?: InputMaybe<String_Comparison_Exp>;
  YardiContact?: InputMaybe<YardiContacts_Bool_Exp>;
  YardiProperty?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<YardiContacts_YardiProperties_Bool_Exp>>;
  _not?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
  _or?: InputMaybe<Array<YardiContacts_YardiProperties_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiContacts_YardiProperties" */
export enum YardiContacts_YardiProperties_Constraint {
  /** unique or primary key constraint on columns "ContactCode", "PropertyCode" */
  YardiContactsYardiPropertiesPkey = "YardiContacts_YardiProperties_pkey",
}

/** input type for inserting data into table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Insert_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  YardiContact?: InputMaybe<YardiContacts_Obj_Rel_Insert_Input>;
  YardiProperty?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiContacts_YardiProperties_Max_Fields = {
  __typename?: "YardiContacts_YardiProperties_max_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Max_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiContacts_YardiProperties_Min_Fields = {
  __typename?: "YardiContacts_YardiProperties_min_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Min_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Mutation_Response = {
  __typename?: "YardiContacts_YardiProperties_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiContacts_YardiProperties>;
};

/** on_conflict condition type for table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_On_Conflict = {
  constraint: YardiContacts_YardiProperties_Constraint;
  update_columns?: Array<YardiContacts_YardiProperties_Update_Column>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiContacts_YardiProperties". */
export type YardiContacts_YardiProperties_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  YardiContact?: InputMaybe<YardiContacts_Order_By>;
  YardiProperty?: InputMaybe<YardiProperties_Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiContacts_YardiProperties */
export type YardiContacts_YardiProperties_Pk_Columns_Input = {
  ContactCode: Scalars["String"];
  PropertyCode: Scalars["String"];
};

/** select columns of table "YardiContacts_YardiProperties" */
export enum YardiContacts_YardiProperties_Select_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Role = "Role",
  /** column name */
  CreatedAt = "created_at",
}

/** input type for updating data in table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Set_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiContacts_YardiProperties" */
export type YardiContacts_YardiProperties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiContacts_YardiProperties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiContacts_YardiProperties_Stream_Cursor_Value_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiContacts_YardiProperties" */
export enum YardiContacts_YardiProperties_Update_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Role = "Role",
  /** column name */
  CreatedAt = "created_at",
}

export type YardiContacts_YardiProperties_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiContacts_YardiProperties_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiContacts_YardiProperties_Bool_Exp;
};

/** aggregated selection of "YardiContacts" */
export type YardiContacts_Aggregate = {
  __typename?: "YardiContacts_aggregate";
  aggregate?: Maybe<YardiContacts_Aggregate_Fields>;
  nodes: Array<YardiContacts>;
};

export type YardiContacts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<YardiContacts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<YardiContacts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<YardiContacts_Aggregate_Bool_Exp_Count>;
};

export type YardiContacts_Aggregate_Bool_Exp_Bool_And = {
  arguments: YardiContacts_Select_Column_YardiContacts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiContacts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type YardiContacts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: YardiContacts_Select_Column_YardiContacts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiContacts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type YardiContacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiContacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiContacts" */
export type YardiContacts_Aggregate_Fields = {
  __typename?: "YardiContacts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiContacts_Max_Fields>;
  min?: Maybe<YardiContacts_Min_Fields>;
};

/** aggregate fields of "YardiContacts" */
export type YardiContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiContacts" */
export type YardiContacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiContacts_Max_Order_By>;
  min?: InputMaybe<YardiContacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "YardiContacts" */
export type YardiContacts_Arr_Rel_Insert_Input = {
  data: Array<YardiContacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiContacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "YardiContacts". All fields are combined with a logical 'AND'. */
export type YardiContacts_Bool_Exp = {
  Address1?: InputMaybe<String_Comparison_Exp>;
  Address2?: InputMaybe<String_Comparison_Exp>;
  Address3?: InputMaybe<String_Comparison_Exp>;
  Address4?: InputMaybe<String_Comparison_Exp>;
  AlternateEmail?: InputMaybe<String_Comparison_Exp>;
  City?: InputMaybe<String_Comparison_Exp>;
  CompanyName?: InputMaybe<String_Comparison_Exp>;
  ContactCode?: InputMaybe<String_Comparison_Exp>;
  Country?: InputMaybe<String_Comparison_Exp>;
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  ExtRefId?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  IsInternal?: InputMaybe<Boolean_Comparison_Exp>;
  IsPrimary?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber1?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber2?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber3?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber4?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber5?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber6?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber7?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber8?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber9?: InputMaybe<String_Comparison_Exp>;
  PhoneNumber10?: InputMaybe<String_Comparison_Exp>;
  RecordType?: InputMaybe<String_Comparison_Exp>;
  Role?: InputMaybe<String_Comparison_Exp>;
  Salutation?: InputMaybe<String_Comparison_Exp>;
  State?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  URL?: InputMaybe<String_Comparison_Exp>;
  YardiContacts_Contacts?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
  YardiContacts_Contacts_aggregate?: InputMaybe<YardiContacts_Contacts_Aggregate_Bool_Exp>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Bool_Exp>;
  YardiCustomer?: InputMaybe<YardiCustomers_Bool_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  ZipCode?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiContacts_Bool_Exp>>;
  _not?: InputMaybe<YardiContacts_Bool_Exp>;
  _or?: InputMaybe<Array<YardiContacts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiContacts" */
export enum YardiContacts_Constraint {
  /** unique or primary key constraint on columns "ContactCode" */
  YardiContactsPkey = "YardiContacts_pkey",
}

/** input type for inserting data into table "YardiContacts" */
export type YardiContacts_Insert_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  Address3?: InputMaybe<Scalars["String"]>;
  Address4?: InputMaybe<Scalars["String"]>;
  AlternateEmail?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  CompanyName?: InputMaybe<Scalars["String"]>;
  ContactCode?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  Description?: InputMaybe<Scalars["String"]>;
  Email?: InputMaybe<Scalars["String"]>;
  ExtRefId?: InputMaybe<Scalars["String"]>;
  FirstName?: InputMaybe<Scalars["String"]>;
  IsInternal?: InputMaybe<Scalars["Boolean"]>;
  IsPrimary?: InputMaybe<Scalars["String"]>;
  LastName?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PhoneNumber1?: InputMaybe<Scalars["String"]>;
  PhoneNumber2?: InputMaybe<Scalars["String"]>;
  PhoneNumber3?: InputMaybe<Scalars["String"]>;
  PhoneNumber4?: InputMaybe<Scalars["String"]>;
  PhoneNumber5?: InputMaybe<Scalars["String"]>;
  PhoneNumber6?: InputMaybe<Scalars["String"]>;
  PhoneNumber7?: InputMaybe<Scalars["String"]>;
  PhoneNumber8?: InputMaybe<Scalars["String"]>;
  PhoneNumber9?: InputMaybe<Scalars["String"]>;
  PhoneNumber10?: InputMaybe<Scalars["String"]>;
  RecordType?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  Salutation?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Title?: InputMaybe<Scalars["String"]>;
  URL?: InputMaybe<Scalars["String"]>;
  YardiContacts_Contacts?: InputMaybe<YardiContacts_Contacts_Arr_Rel_Insert_Input>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Arr_Rel_Insert_Input>;
  YardiCustomer?: InputMaybe<YardiCustomers_Obj_Rel_Insert_Input>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  ZipCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiContacts_Max_Fields = {
  __typename?: "YardiContacts_max_fields";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  Address3?: Maybe<Scalars["String"]>;
  Address4?: Maybe<Scalars["String"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  ContactCode?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  ExtRefId?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  IsPrimary?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  PhoneNumber1?: Maybe<Scalars["String"]>;
  PhoneNumber2?: Maybe<Scalars["String"]>;
  PhoneNumber3?: Maybe<Scalars["String"]>;
  PhoneNumber4?: Maybe<Scalars["String"]>;
  PhoneNumber5?: Maybe<Scalars["String"]>;
  PhoneNumber6?: Maybe<Scalars["String"]>;
  PhoneNumber7?: Maybe<Scalars["String"]>;
  PhoneNumber8?: Maybe<Scalars["String"]>;
  PhoneNumber9?: Maybe<Scalars["String"]>;
  PhoneNumber10?: Maybe<Scalars["String"]>;
  RecordType?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Salutation?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Title?: Maybe<Scalars["String"]>;
  URL?: Maybe<Scalars["String"]>;
  ZipCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiContacts" */
export type YardiContacts_Max_Order_By = {
  Address1?: InputMaybe<Order_By>;
  Address2?: InputMaybe<Order_By>;
  Address3?: InputMaybe<Order_By>;
  Address4?: InputMaybe<Order_By>;
  AlternateEmail?: InputMaybe<Order_By>;
  City?: InputMaybe<Order_By>;
  CompanyName?: InputMaybe<Order_By>;
  ContactCode?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  ExtRefId?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  IsPrimary?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PhoneNumber1?: InputMaybe<Order_By>;
  PhoneNumber2?: InputMaybe<Order_By>;
  PhoneNumber3?: InputMaybe<Order_By>;
  PhoneNumber4?: InputMaybe<Order_By>;
  PhoneNumber5?: InputMaybe<Order_By>;
  PhoneNumber6?: InputMaybe<Order_By>;
  PhoneNumber7?: InputMaybe<Order_By>;
  PhoneNumber8?: InputMaybe<Order_By>;
  PhoneNumber9?: InputMaybe<Order_By>;
  PhoneNumber10?: InputMaybe<Order_By>;
  RecordType?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  Salutation?: InputMaybe<Order_By>;
  State?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  URL?: InputMaybe<Order_By>;
  ZipCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiContacts_Min_Fields = {
  __typename?: "YardiContacts_min_fields";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  Address3?: Maybe<Scalars["String"]>;
  Address4?: Maybe<Scalars["String"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  ContactCode?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  ExtRefId?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  IsPrimary?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  PhoneNumber1?: Maybe<Scalars["String"]>;
  PhoneNumber2?: Maybe<Scalars["String"]>;
  PhoneNumber3?: Maybe<Scalars["String"]>;
  PhoneNumber4?: Maybe<Scalars["String"]>;
  PhoneNumber5?: Maybe<Scalars["String"]>;
  PhoneNumber6?: Maybe<Scalars["String"]>;
  PhoneNumber7?: Maybe<Scalars["String"]>;
  PhoneNumber8?: Maybe<Scalars["String"]>;
  PhoneNumber9?: Maybe<Scalars["String"]>;
  PhoneNumber10?: Maybe<Scalars["String"]>;
  RecordType?: Maybe<Scalars["String"]>;
  Role?: Maybe<Scalars["String"]>;
  Salutation?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  Title?: Maybe<Scalars["String"]>;
  URL?: Maybe<Scalars["String"]>;
  ZipCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiContacts" */
export type YardiContacts_Min_Order_By = {
  Address1?: InputMaybe<Order_By>;
  Address2?: InputMaybe<Order_By>;
  Address3?: InputMaybe<Order_By>;
  Address4?: InputMaybe<Order_By>;
  AlternateEmail?: InputMaybe<Order_By>;
  City?: InputMaybe<Order_By>;
  CompanyName?: InputMaybe<Order_By>;
  ContactCode?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  ExtRefId?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  IsPrimary?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PhoneNumber1?: InputMaybe<Order_By>;
  PhoneNumber2?: InputMaybe<Order_By>;
  PhoneNumber3?: InputMaybe<Order_By>;
  PhoneNumber4?: InputMaybe<Order_By>;
  PhoneNumber5?: InputMaybe<Order_By>;
  PhoneNumber6?: InputMaybe<Order_By>;
  PhoneNumber7?: InputMaybe<Order_By>;
  PhoneNumber8?: InputMaybe<Order_By>;
  PhoneNumber9?: InputMaybe<Order_By>;
  PhoneNumber10?: InputMaybe<Order_By>;
  RecordType?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  Salutation?: InputMaybe<Order_By>;
  State?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  URL?: InputMaybe<Order_By>;
  ZipCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiContacts" */
export type YardiContacts_Mutation_Response = {
  __typename?: "YardiContacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiContacts>;
};

/** input type for inserting object relation for remote table "YardiContacts" */
export type YardiContacts_Obj_Rel_Insert_Input = {
  data: YardiContacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiContacts_On_Conflict>;
};

/** on_conflict condition type for table "YardiContacts" */
export type YardiContacts_On_Conflict = {
  constraint: YardiContacts_Constraint;
  update_columns?: Array<YardiContacts_Update_Column>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiContacts". */
export type YardiContacts_Order_By = {
  Address1?: InputMaybe<Order_By>;
  Address2?: InputMaybe<Order_By>;
  Address3?: InputMaybe<Order_By>;
  Address4?: InputMaybe<Order_By>;
  AlternateEmail?: InputMaybe<Order_By>;
  City?: InputMaybe<Order_By>;
  CompanyName?: InputMaybe<Order_By>;
  ContactCode?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  ExtRefId?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  IsInternal?: InputMaybe<Order_By>;
  IsPrimary?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PhoneNumber1?: InputMaybe<Order_By>;
  PhoneNumber2?: InputMaybe<Order_By>;
  PhoneNumber3?: InputMaybe<Order_By>;
  PhoneNumber4?: InputMaybe<Order_By>;
  PhoneNumber5?: InputMaybe<Order_By>;
  PhoneNumber6?: InputMaybe<Order_By>;
  PhoneNumber7?: InputMaybe<Order_By>;
  PhoneNumber8?: InputMaybe<Order_By>;
  PhoneNumber9?: InputMaybe<Order_By>;
  PhoneNumber10?: InputMaybe<Order_By>;
  RecordType?: InputMaybe<Order_By>;
  Role?: InputMaybe<Order_By>;
  Salutation?: InputMaybe<Order_By>;
  State?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  URL?: InputMaybe<Order_By>;
  YardiContacts_Contacts_aggregate?: InputMaybe<YardiContacts_Contacts_Aggregate_Order_By>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Order_By>;
  YardiCustomer?: InputMaybe<YardiCustomers_Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  ZipCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiContacts */
export type YardiContacts_Pk_Columns_Input = {
  ContactCode: Scalars["String"];
};

/** select columns of table "YardiContacts" */
export enum YardiContacts_Select_Column {
  /** column name */
  Address1 = "Address1",
  /** column name */
  Address2 = "Address2",
  /** column name */
  Address3 = "Address3",
  /** column name */
  Address4 = "Address4",
  /** column name */
  AlternateEmail = "AlternateEmail",
  /** column name */
  City = "City",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  Country = "Country",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  Description = "Description",
  /** column name */
  Email = "Email",
  /** column name */
  ExtRefId = "ExtRefId",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  IsInternal = "IsInternal",
  /** column name */
  IsPrimary = "IsPrimary",
  /** column name */
  LastName = "LastName",
  /** column name */
  Notes = "Notes",
  /** column name */
  PhoneNumber1 = "PhoneNumber1",
  /** column name */
  PhoneNumber2 = "PhoneNumber2",
  /** column name */
  PhoneNumber3 = "PhoneNumber3",
  /** column name */
  PhoneNumber4 = "PhoneNumber4",
  /** column name */
  PhoneNumber5 = "PhoneNumber5",
  /** column name */
  PhoneNumber6 = "PhoneNumber6",
  /** column name */
  PhoneNumber7 = "PhoneNumber7",
  /** column name */
  PhoneNumber8 = "PhoneNumber8",
  /** column name */
  PhoneNumber9 = "PhoneNumber9",
  /** column name */
  PhoneNumber10 = "PhoneNumber10",
  /** column name */
  RecordType = "RecordType",
  /** column name */
  Role = "Role",
  /** column name */
  Salutation = "Salutation",
  /** column name */
  State = "State",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  Title = "Title",
  /** column name */
  Url = "URL",
  /** column name */
  ZipCode = "ZipCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** select "YardiContacts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "YardiContacts" */
export enum YardiContacts_Select_Column_YardiContacts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsInternal = "IsInternal",
}

/** select "YardiContacts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "YardiContacts" */
export enum YardiContacts_Select_Column_YardiContacts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsInternal = "IsInternal",
}

/** input type for updating data in table "YardiContacts" */
export type YardiContacts_Set_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  Address3?: InputMaybe<Scalars["String"]>;
  Address4?: InputMaybe<Scalars["String"]>;
  AlternateEmail?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  CompanyName?: InputMaybe<Scalars["String"]>;
  ContactCode?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  Description?: InputMaybe<Scalars["String"]>;
  Email?: InputMaybe<Scalars["String"]>;
  ExtRefId?: InputMaybe<Scalars["String"]>;
  FirstName?: InputMaybe<Scalars["String"]>;
  IsInternal?: InputMaybe<Scalars["Boolean"]>;
  IsPrimary?: InputMaybe<Scalars["String"]>;
  LastName?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PhoneNumber1?: InputMaybe<Scalars["String"]>;
  PhoneNumber2?: InputMaybe<Scalars["String"]>;
  PhoneNumber3?: InputMaybe<Scalars["String"]>;
  PhoneNumber4?: InputMaybe<Scalars["String"]>;
  PhoneNumber5?: InputMaybe<Scalars["String"]>;
  PhoneNumber6?: InputMaybe<Scalars["String"]>;
  PhoneNumber7?: InputMaybe<Scalars["String"]>;
  PhoneNumber8?: InputMaybe<Scalars["String"]>;
  PhoneNumber9?: InputMaybe<Scalars["String"]>;
  PhoneNumber10?: InputMaybe<Scalars["String"]>;
  RecordType?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  Salutation?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Title?: InputMaybe<Scalars["String"]>;
  URL?: InputMaybe<Scalars["String"]>;
  ZipCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiContacts" */
export type YardiContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiContacts_Stream_Cursor_Value_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  Address3?: InputMaybe<Scalars["String"]>;
  Address4?: InputMaybe<Scalars["String"]>;
  AlternateEmail?: InputMaybe<Scalars["String"]>;
  City?: InputMaybe<Scalars["String"]>;
  CompanyName?: InputMaybe<Scalars["String"]>;
  ContactCode?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  Description?: InputMaybe<Scalars["String"]>;
  Email?: InputMaybe<Scalars["String"]>;
  ExtRefId?: InputMaybe<Scalars["String"]>;
  FirstName?: InputMaybe<Scalars["String"]>;
  IsInternal?: InputMaybe<Scalars["Boolean"]>;
  IsPrimary?: InputMaybe<Scalars["String"]>;
  LastName?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PhoneNumber1?: InputMaybe<Scalars["String"]>;
  PhoneNumber2?: InputMaybe<Scalars["String"]>;
  PhoneNumber3?: InputMaybe<Scalars["String"]>;
  PhoneNumber4?: InputMaybe<Scalars["String"]>;
  PhoneNumber5?: InputMaybe<Scalars["String"]>;
  PhoneNumber6?: InputMaybe<Scalars["String"]>;
  PhoneNumber7?: InputMaybe<Scalars["String"]>;
  PhoneNumber8?: InputMaybe<Scalars["String"]>;
  PhoneNumber9?: InputMaybe<Scalars["String"]>;
  PhoneNumber10?: InputMaybe<Scalars["String"]>;
  RecordType?: InputMaybe<Scalars["String"]>;
  Role?: InputMaybe<Scalars["String"]>;
  Salutation?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  Title?: InputMaybe<Scalars["String"]>;
  URL?: InputMaybe<Scalars["String"]>;
  ZipCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiContacts" */
export enum YardiContacts_Update_Column {
  /** column name */
  Address1 = "Address1",
  /** column name */
  Address2 = "Address2",
  /** column name */
  Address3 = "Address3",
  /** column name */
  Address4 = "Address4",
  /** column name */
  AlternateEmail = "AlternateEmail",
  /** column name */
  City = "City",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  Country = "Country",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  Description = "Description",
  /** column name */
  Email = "Email",
  /** column name */
  ExtRefId = "ExtRefId",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  IsInternal = "IsInternal",
  /** column name */
  IsPrimary = "IsPrimary",
  /** column name */
  LastName = "LastName",
  /** column name */
  Notes = "Notes",
  /** column name */
  PhoneNumber1 = "PhoneNumber1",
  /** column name */
  PhoneNumber2 = "PhoneNumber2",
  /** column name */
  PhoneNumber3 = "PhoneNumber3",
  /** column name */
  PhoneNumber4 = "PhoneNumber4",
  /** column name */
  PhoneNumber5 = "PhoneNumber5",
  /** column name */
  PhoneNumber6 = "PhoneNumber6",
  /** column name */
  PhoneNumber7 = "PhoneNumber7",
  /** column name */
  PhoneNumber8 = "PhoneNumber8",
  /** column name */
  PhoneNumber9 = "PhoneNumber9",
  /** column name */
  PhoneNumber10 = "PhoneNumber10",
  /** column name */
  RecordType = "RecordType",
  /** column name */
  Role = "Role",
  /** column name */
  Salutation = "Salutation",
  /** column name */
  State = "State",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  Title = "Title",
  /** column name */
  Url = "URL",
  /** column name */
  ZipCode = "ZipCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiContacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiContacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiContacts_Bool_Exp;
};

/** columns and relationships of "YardiCustomers" */
export type YardiCustomers = {
  __typename?: "YardiCustomers";
  CustomerCode: Scalars["String"];
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerStatus?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiContacts: Array<YardiContacts>;
  /** An aggregate relationship */
  YardiContacts_aggregate: YardiContacts_Aggregate;
  /** An array relationship */
  YardiCustomers_Deals: Array<YardiCustomers_Deals>;
  /** An aggregate relationship */
  YardiCustomers_Deals_aggregate: YardiCustomers_Deals_Aggregate;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiCustomers" */
export type YardiCustomersYardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

/** columns and relationships of "YardiCustomers" */
export type YardiCustomersYardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

/** columns and relationships of "YardiCustomers" */
export type YardiCustomersYardiCustomers_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

/** columns and relationships of "YardiCustomers" */
export type YardiCustomersYardiCustomers_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

/** columns and relationships of "YardiCustomers_Deals" */
export type YardiCustomers_Deals = {
  __typename?: "YardiCustomers_Deals";
  CustomerCode: Scalars["String"];
  /** An object relationship */
  Deal: Deals;
  /** An object relationship */
  YardiCustomer: YardiCustomers;
  created_at: Scalars["timestamptz"];
  deal_id: Scalars["bigint"];
};

/** aggregated selection of "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Aggregate = {
  __typename?: "YardiCustomers_Deals_aggregate";
  aggregate?: Maybe<YardiCustomers_Deals_Aggregate_Fields>;
  nodes: Array<YardiCustomers_Deals>;
};

export type YardiCustomers_Deals_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiCustomers_Deals_Aggregate_Bool_Exp_Count>;
};

export type YardiCustomers_Deals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Aggregate_Fields = {
  __typename?: "YardiCustomers_Deals_aggregate_fields";
  avg?: Maybe<YardiCustomers_Deals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiCustomers_Deals_Max_Fields>;
  min?: Maybe<YardiCustomers_Deals_Min_Fields>;
  stddev?: Maybe<YardiCustomers_Deals_Stddev_Fields>;
  stddev_pop?: Maybe<YardiCustomers_Deals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiCustomers_Deals_Stddev_Samp_Fields>;
  sum?: Maybe<YardiCustomers_Deals_Sum_Fields>;
  var_pop?: Maybe<YardiCustomers_Deals_Var_Pop_Fields>;
  var_samp?: Maybe<YardiCustomers_Deals_Var_Samp_Fields>;
  variance?: Maybe<YardiCustomers_Deals_Variance_Fields>;
};

/** aggregate fields of "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Aggregate_Order_By = {
  avg?: InputMaybe<YardiCustomers_Deals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiCustomers_Deals_Max_Order_By>;
  min?: InputMaybe<YardiCustomers_Deals_Min_Order_By>;
  stddev?: InputMaybe<YardiCustomers_Deals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiCustomers_Deals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiCustomers_Deals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiCustomers_Deals_Sum_Order_By>;
  var_pop?: InputMaybe<YardiCustomers_Deals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiCustomers_Deals_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiCustomers_Deals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Arr_Rel_Insert_Input = {
  data: Array<YardiCustomers_Deals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiCustomers_Deals_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiCustomers_Deals_Avg_Fields = {
  __typename?: "YardiCustomers_Deals_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiCustomers_Deals". All fields are combined with a logical 'AND'. */
export type YardiCustomers_Deals_Bool_Exp = {
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  Deal?: InputMaybe<Deals_Bool_Exp>;
  YardiCustomer?: InputMaybe<YardiCustomers_Bool_Exp>;
  _and?: InputMaybe<Array<YardiCustomers_Deals_Bool_Exp>>;
  _not?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
  _or?: InputMaybe<Array<YardiCustomers_Deals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiCustomers_Deals" */
export enum YardiCustomers_Deals_Constraint {
  /** unique or primary key constraint on columns "CustomerCode", "deal_id" */
  YardiCustomersDealsPkey = "YardiCustomers_Deals_pkey",
}

/** input type for incrementing numeric columns in table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Insert_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  YardiCustomer?: InputMaybe<YardiCustomers_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type YardiCustomers_Deals_Max_Fields = {
  __typename?: "YardiCustomers_Deals_max_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Max_Order_By = {
  CustomerCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiCustomers_Deals_Min_Fields = {
  __typename?: "YardiCustomers_Deals_min_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Min_Order_By = {
  CustomerCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Mutation_Response = {
  __typename?: "YardiCustomers_Deals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiCustomers_Deals>;
};

/** on_conflict condition type for table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_On_Conflict = {
  constraint: YardiCustomers_Deals_Constraint;
  update_columns?: Array<YardiCustomers_Deals_Update_Column>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiCustomers_Deals". */
export type YardiCustomers_Deals_Order_By = {
  CustomerCode?: InputMaybe<Order_By>;
  Deal?: InputMaybe<Deals_Order_By>;
  YardiCustomer?: InputMaybe<YardiCustomers_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiCustomers_Deals */
export type YardiCustomers_Deals_Pk_Columns_Input = {
  CustomerCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** select columns of table "YardiCustomers_Deals" */
export enum YardiCustomers_Deals_Select_Column {
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

/** input type for updating data in table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Set_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type YardiCustomers_Deals_Stddev_Fields = {
  __typename?: "YardiCustomers_Deals_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiCustomers_Deals_Stddev_Pop_Fields = {
  __typename?: "YardiCustomers_Deals_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiCustomers_Deals_Stddev_Samp_Fields = {
  __typename?: "YardiCustomers_Deals_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiCustomers_Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiCustomers_Deals_Stream_Cursor_Value_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type YardiCustomers_Deals_Sum_Fields = {
  __typename?: "YardiCustomers_Deals_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** update columns of table "YardiCustomers_Deals" */
export enum YardiCustomers_Deals_Update_Column {
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

export type YardiCustomers_Deals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiCustomers_Deals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiCustomers_Deals_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiCustomers_Deals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiCustomers_Deals_Var_Pop_Fields = {
  __typename?: "YardiCustomers_Deals_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiCustomers_Deals_Var_Samp_Fields = {
  __typename?: "YardiCustomers_Deals_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiCustomers_Deals_Variance_Fields = {
  __typename?: "YardiCustomers_Deals_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiCustomers_Deals" */
export type YardiCustomers_Deals_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "YardiCustomers" */
export type YardiCustomers_Aggregate = {
  __typename?: "YardiCustomers_aggregate";
  aggregate?: Maybe<YardiCustomers_Aggregate_Fields>;
  nodes: Array<YardiCustomers>;
};

/** aggregate fields of "YardiCustomers" */
export type YardiCustomers_Aggregate_Fields = {
  __typename?: "YardiCustomers_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiCustomers_Max_Fields>;
  min?: Maybe<YardiCustomers_Min_Fields>;
};

/** aggregate fields of "YardiCustomers" */
export type YardiCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiCustomers". All fields are combined with a logical 'AND'. */
export type YardiCustomers_Bool_Exp = {
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  CustomerName?: InputMaybe<String_Comparison_Exp>;
  CustomerStatus?: InputMaybe<String_Comparison_Exp>;
  CustomerType?: InputMaybe<String_Comparison_Exp>;
  ParentCustomerCode?: InputMaybe<String_Comparison_Exp>;
  YardiContacts?: InputMaybe<YardiContacts_Bool_Exp>;
  YardiContacts_aggregate?: InputMaybe<YardiContacts_Aggregate_Bool_Exp>;
  YardiCustomers_Deals?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
  YardiCustomers_Deals_aggregate?: InputMaybe<YardiCustomers_Deals_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<YardiCustomers_Bool_Exp>>;
  _not?: InputMaybe<YardiCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<YardiCustomers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiCustomers" */
export enum YardiCustomers_Constraint {
  /** unique or primary key constraint on columns "CustomerCode" */
  YardiCustomersPkey = "YardiCustomers_pkey",
}

/** input type for inserting data into table "YardiCustomers" */
export type YardiCustomers_Insert_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  CustomerName?: InputMaybe<Scalars["String"]>;
  CustomerStatus?: InputMaybe<Scalars["String"]>;
  CustomerType?: InputMaybe<Scalars["String"]>;
  ParentCustomerCode?: InputMaybe<Scalars["String"]>;
  YardiContacts?: InputMaybe<YardiContacts_Arr_Rel_Insert_Input>;
  YardiCustomers_Deals?: InputMaybe<YardiCustomers_Deals_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiCustomers_Max_Fields = {
  __typename?: "YardiCustomers_max_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerStatus?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type YardiCustomers_Min_Fields = {
  __typename?: "YardiCustomers_min_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerStatus?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "YardiCustomers" */
export type YardiCustomers_Mutation_Response = {
  __typename?: "YardiCustomers_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiCustomers>;
};

/** input type for inserting object relation for remote table "YardiCustomers" */
export type YardiCustomers_Obj_Rel_Insert_Input = {
  data: YardiCustomers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiCustomers_On_Conflict>;
};

/** on_conflict condition type for table "YardiCustomers" */
export type YardiCustomers_On_Conflict = {
  constraint: YardiCustomers_Constraint;
  update_columns?: Array<YardiCustomers_Update_Column>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiCustomers". */
export type YardiCustomers_Order_By = {
  CustomerCode?: InputMaybe<Order_By>;
  CustomerName?: InputMaybe<Order_By>;
  CustomerStatus?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  ParentCustomerCode?: InputMaybe<Order_By>;
  YardiContacts_aggregate?: InputMaybe<YardiContacts_Aggregate_Order_By>;
  YardiCustomers_Deals_aggregate?: InputMaybe<YardiCustomers_Deals_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiCustomers */
export type YardiCustomers_Pk_Columns_Input = {
  CustomerCode: Scalars["String"];
};

/** select columns of table "YardiCustomers" */
export enum YardiCustomers_Select_Column {
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  CustomerStatus = "CustomerStatus",
  /** column name */
  CustomerType = "CustomerType",
  /** column name */
  ParentCustomerCode = "ParentCustomerCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiCustomers" */
export type YardiCustomers_Set_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  CustomerName?: InputMaybe<Scalars["String"]>;
  CustomerStatus?: InputMaybe<Scalars["String"]>;
  CustomerType?: InputMaybe<Scalars["String"]>;
  ParentCustomerCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiCustomers" */
export type YardiCustomers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiCustomers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiCustomers_Stream_Cursor_Value_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  CustomerName?: InputMaybe<Scalars["String"]>;
  CustomerStatus?: InputMaybe<Scalars["String"]>;
  CustomerType?: InputMaybe<Scalars["String"]>;
  ParentCustomerCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiCustomers" */
export enum YardiCustomers_Update_Column {
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  CustomerStatus = "CustomerStatus",
  /** column name */
  CustomerType = "CustomerType",
  /** column name */
  ParentCustomerCode = "ParentCustomerCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiCustomers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiCustomers_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiCustomers_Bool_Exp;
};

/** columns and relationships of "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses = {
  __typename?: "YardiDealSyncStatuses";
  /** An object relationship */
  Deal: Deals;
  created_at: Scalars["timestamptz"];
  deal_id: Scalars["bigint"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  modified_at: Scalars["timestamptz"];
  state: Scalars["jsonb"];
  status: Scalars["yardi_deal_sync_status"];
};

/** columns and relationships of "YardiDealSyncStatuses" */
export type YardiDealSyncStatusesStateArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Aggregate = {
  __typename?: "YardiDealSyncStatuses_aggregate";
  aggregate?: Maybe<YardiDealSyncStatuses_Aggregate_Fields>;
  nodes: Array<YardiDealSyncStatuses>;
};

export type YardiDealSyncStatuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiDealSyncStatuses_Aggregate_Bool_Exp_Count>;
};

export type YardiDealSyncStatuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Aggregate_Fields = {
  __typename?: "YardiDealSyncStatuses_aggregate_fields";
  avg?: Maybe<YardiDealSyncStatuses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiDealSyncStatuses_Max_Fields>;
  min?: Maybe<YardiDealSyncStatuses_Min_Fields>;
  stddev?: Maybe<YardiDealSyncStatuses_Stddev_Fields>;
  stddev_pop?: Maybe<YardiDealSyncStatuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiDealSyncStatuses_Stddev_Samp_Fields>;
  sum?: Maybe<YardiDealSyncStatuses_Sum_Fields>;
  var_pop?: Maybe<YardiDealSyncStatuses_Var_Pop_Fields>;
  var_samp?: Maybe<YardiDealSyncStatuses_Var_Samp_Fields>;
  variance?: Maybe<YardiDealSyncStatuses_Variance_Fields>;
};

/** aggregate fields of "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Aggregate_Order_By = {
  avg?: InputMaybe<YardiDealSyncStatuses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiDealSyncStatuses_Max_Order_By>;
  min?: InputMaybe<YardiDealSyncStatuses_Min_Order_By>;
  stddev?: InputMaybe<YardiDealSyncStatuses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiDealSyncStatuses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiDealSyncStatuses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiDealSyncStatuses_Sum_Order_By>;
  var_pop?: InputMaybe<YardiDealSyncStatuses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiDealSyncStatuses_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiDealSyncStatuses_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type YardiDealSyncStatuses_Append_Input = {
  state?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Arr_Rel_Insert_Input = {
  data: Array<YardiDealSyncStatuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiDealSyncStatuses_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiDealSyncStatuses_Avg_Fields = {
  __typename?: "YardiDealSyncStatuses_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiDealSyncStatuses". All fields are combined with a logical 'AND'. */
export type YardiDealSyncStatuses_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  _and?: InputMaybe<Array<YardiDealSyncStatuses_Bool_Exp>>;
  _not?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
  _or?: InputMaybe<Array<YardiDealSyncStatuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Yardi_Deal_Sync_Status_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiDealSyncStatuses" */
export enum YardiDealSyncStatuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  YardiDealSyncStatusesPkey = "YardiDealSyncStatuses_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type YardiDealSyncStatuses_Delete_At_Path_Input = {
  state?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type YardiDealSyncStatuses_Delete_Elem_Input = {
  state?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type YardiDealSyncStatuses_Delete_Key_Input = {
  state?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["jsonb"]>;
  status?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
};

/** aggregate max on columns */
export type YardiDealSyncStatuses_Max_Fields = {
  __typename?: "YardiDealSyncStatuses_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["yardi_deal_sync_status"]>;
};

/** order by max() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiDealSyncStatuses_Min_Fields = {
  __typename?: "YardiDealSyncStatuses_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["yardi_deal_sync_status"]>;
};

/** order by min() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Mutation_Response = {
  __typename?: "YardiDealSyncStatuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiDealSyncStatuses>;
};

/** on_conflict condition type for table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_On_Conflict = {
  constraint: YardiDealSyncStatuses_Constraint;
  update_columns?: Array<YardiDealSyncStatuses_Update_Column>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiDealSyncStatuses". */
export type YardiDealSyncStatuses_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiDealSyncStatuses */
export type YardiDealSyncStatuses_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type YardiDealSyncStatuses_Prepend_Input = {
  state?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "YardiDealSyncStatuses" */
export enum YardiDealSyncStatuses_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  State = "state",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["jsonb"]>;
  status?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
};

/** aggregate stddev on columns */
export type YardiDealSyncStatuses_Stddev_Fields = {
  __typename?: "YardiDealSyncStatuses_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiDealSyncStatuses_Stddev_Pop_Fields = {
  __typename?: "YardiDealSyncStatuses_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiDealSyncStatuses_Stddev_Samp_Fields = {
  __typename?: "YardiDealSyncStatuses_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiDealSyncStatuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiDealSyncStatuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  state?: InputMaybe<Scalars["jsonb"]>;
  status?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
};

/** aggregate sum on columns */
export type YardiDealSyncStatuses_Sum_Fields = {
  __typename?: "YardiDealSyncStatuses_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "YardiDealSyncStatuses" */
export enum YardiDealSyncStatuses_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  State = "state",
  /** column name */
  Status = "status",
}

export type YardiDealSyncStatuses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<YardiDealSyncStatuses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<YardiDealSyncStatuses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<YardiDealSyncStatuses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<YardiDealSyncStatuses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiDealSyncStatuses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<YardiDealSyncStatuses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiDealSyncStatuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiDealSyncStatuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiDealSyncStatuses_Var_Pop_Fields = {
  __typename?: "YardiDealSyncStatuses_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiDealSyncStatuses_Var_Samp_Fields = {
  __typename?: "YardiDealSyncStatuses_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiDealSyncStatuses_Variance_Fields = {
  __typename?: "YardiDealSyncStatuses_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiDealSyncStatuses" */
export type YardiDealSyncStatuses_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiIngestionInfos" */
export type YardiIngestionInfos = {
  __typename?: "YardiIngestionInfos";
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  extra_info?: Maybe<Scalars["jsonb"]>;
  id: Scalars["Int"];
  message?: Maybe<Scalars["String"]>;
  modified_at: Scalars["timestamptz"];
  ref_id: Scalars["String"];
  ref_table: Scalars["String"];
  status: Scalars["String"];
};

/** columns and relationships of "YardiIngestionInfos" */
export type YardiIngestionInfosExtra_InfoArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "YardiIngestionInfos" */
export type YardiIngestionInfos_Aggregate = {
  __typename?: "YardiIngestionInfos_aggregate";
  aggregate?: Maybe<YardiIngestionInfos_Aggregate_Fields>;
  nodes: Array<YardiIngestionInfos>;
};

/** aggregate fields of "YardiIngestionInfos" */
export type YardiIngestionInfos_Aggregate_Fields = {
  __typename?: "YardiIngestionInfos_aggregate_fields";
  avg?: Maybe<YardiIngestionInfos_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiIngestionInfos_Max_Fields>;
  min?: Maybe<YardiIngestionInfos_Min_Fields>;
  stddev?: Maybe<YardiIngestionInfos_Stddev_Fields>;
  stddev_pop?: Maybe<YardiIngestionInfos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiIngestionInfos_Stddev_Samp_Fields>;
  sum?: Maybe<YardiIngestionInfos_Sum_Fields>;
  var_pop?: Maybe<YardiIngestionInfos_Var_Pop_Fields>;
  var_samp?: Maybe<YardiIngestionInfos_Var_Samp_Fields>;
  variance?: Maybe<YardiIngestionInfos_Variance_Fields>;
};

/** aggregate fields of "YardiIngestionInfos" */
export type YardiIngestionInfos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiIngestionInfos_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type YardiIngestionInfos_Append_Input = {
  extra_info?: InputMaybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type YardiIngestionInfos_Avg_Fields = {
  __typename?: "YardiIngestionInfos_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "YardiIngestionInfos". All fields are combined with a logical 'AND'. */
export type YardiIngestionInfos_Bool_Exp = {
  _and?: InputMaybe<Array<YardiIngestionInfos_Bool_Exp>>;
  _not?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
  _or?: InputMaybe<Array<YardiIngestionInfos_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extra_info?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ref_id?: InputMaybe<String_Comparison_Exp>;
  ref_table?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiIngestionInfos" */
export enum YardiIngestionInfos_Constraint {
  /** unique or primary key constraint on columns "id" */
  YardiIngestionInfosPkey = "YardiIngestionInfos_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type YardiIngestionInfos_Delete_At_Path_Input = {
  extra_info?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type YardiIngestionInfos_Delete_Elem_Input = {
  extra_info?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type YardiIngestionInfos_Delete_Key_Input = {
  extra_info?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "YardiIngestionInfos" */
export type YardiIngestionInfos_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "YardiIngestionInfos" */
export type YardiIngestionInfos_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  extra_info?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["Int"]>;
  message?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  ref_id?: InputMaybe<Scalars["String"]>;
  ref_table?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type YardiIngestionInfos_Max_Fields = {
  __typename?: "YardiIngestionInfos_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  ref_id?: Maybe<Scalars["String"]>;
  ref_table?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type YardiIngestionInfos_Min_Fields = {
  __typename?: "YardiIngestionInfos_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  ref_id?: Maybe<Scalars["String"]>;
  ref_table?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "YardiIngestionInfos" */
export type YardiIngestionInfos_Mutation_Response = {
  __typename?: "YardiIngestionInfos_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiIngestionInfos>;
};

/** on_conflict condition type for table "YardiIngestionInfos" */
export type YardiIngestionInfos_On_Conflict = {
  constraint: YardiIngestionInfos_Constraint;
  update_columns?: Array<YardiIngestionInfos_Update_Column>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiIngestionInfos". */
export type YardiIngestionInfos_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  extra_info?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  ref_id?: InputMaybe<Order_By>;
  ref_table?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiIngestionInfos */
export type YardiIngestionInfos_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type YardiIngestionInfos_Prepend_Input = {
  extra_info?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "YardiIngestionInfos" */
export enum YardiIngestionInfos_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ExtraInfo = "extra_info",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  RefId = "ref_id",
  /** column name */
  RefTable = "ref_table",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "YardiIngestionInfos" */
export type YardiIngestionInfos_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  extra_info?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["Int"]>;
  message?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  ref_id?: InputMaybe<Scalars["String"]>;
  ref_table?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type YardiIngestionInfos_Stddev_Fields = {
  __typename?: "YardiIngestionInfos_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type YardiIngestionInfos_Stddev_Pop_Fields = {
  __typename?: "YardiIngestionInfos_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type YardiIngestionInfos_Stddev_Samp_Fields = {
  __typename?: "YardiIngestionInfos_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "YardiIngestionInfos" */
export type YardiIngestionInfos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiIngestionInfos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiIngestionInfos_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  extra_info?: InputMaybe<Scalars["jsonb"]>;
  id?: InputMaybe<Scalars["Int"]>;
  message?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  ref_id?: InputMaybe<Scalars["String"]>;
  ref_table?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type YardiIngestionInfos_Sum_Fields = {
  __typename?: "YardiIngestionInfos_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "YardiIngestionInfos" */
export enum YardiIngestionInfos_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ExtraInfo = "extra_info",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  RefId = "ref_id",
  /** column name */
  RefTable = "ref_table",
  /** column name */
  Status = "status",
}

export type YardiIngestionInfos_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<YardiIngestionInfos_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<YardiIngestionInfos_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<YardiIngestionInfos_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<YardiIngestionInfos_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiIngestionInfos_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<YardiIngestionInfos_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiIngestionInfos_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiIngestionInfos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiIngestionInfos_Var_Pop_Fields = {
  __typename?: "YardiIngestionInfos_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type YardiIngestionInfos_Var_Samp_Fields = {
  __typename?: "YardiIngestionInfos_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type YardiIngestionInfos_Variance_Fields = {
  __typename?: "YardiIngestionInfos_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "YardiLeasesForRenewal" */
export type YardiLeasesForRenewal = {
  __typename?: "YardiLeasesForRenewal";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "YardiLeasesForRenewal" */
export type YardiLeasesForRenewal_Aggregate = {
  __typename?: "YardiLeasesForRenewal_aggregate";
  aggregate?: Maybe<YardiLeasesForRenewal_Aggregate_Fields>;
  nodes: Array<YardiLeasesForRenewal>;
};

/** aggregate fields of "YardiLeasesForRenewal" */
export type YardiLeasesForRenewal_Aggregate_Fields = {
  __typename?: "YardiLeasesForRenewal_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiLeasesForRenewal_Max_Fields>;
  min?: Maybe<YardiLeasesForRenewal_Min_Fields>;
};

/** aggregate fields of "YardiLeasesForRenewal" */
export type YardiLeasesForRenewal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiLeasesForRenewal_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiLeasesForRenewal". All fields are combined with a logical 'AND'. */
export type YardiLeasesForRenewal_Bool_Exp = {
  Anchor?: InputMaybe<String_Comparison_Exp>;
  BaseRent?: InputMaybe<String_Comparison_Exp>;
  BrandCode?: InputMaybe<String_Comparison_Exp>;
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  EndDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  IsHoldover?: InputMaybe<String_Comparison_Exp>;
  LeaseType?: InputMaybe<String_Comparison_Exp>;
  MaintenanceNotes?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  Outgoings?: InputMaybe<String_Comparison_Exp>;
  PromoLevy?: InputMaybe<String_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  SalesCategory?: InputMaybe<String_Comparison_Exp>;
  TIC?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  TenantName?: InputMaybe<String_Comparison_Exp>;
  TenantStartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantStatus?: InputMaybe<String_Comparison_Exp>;
  TradeName?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiLeasesForRenewal_Bool_Exp>>;
  _not?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
  _or?: InputMaybe<Array<YardiLeasesForRenewal_Bool_Exp>>;
  unit1?: InputMaybe<String_Comparison_Exp>;
  unit2?: InputMaybe<String_Comparison_Exp>;
  unit3?: InputMaybe<String_Comparison_Exp>;
  unit4?: InputMaybe<String_Comparison_Exp>;
  unit5?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type YardiLeasesForRenewal_Max_Fields = {
  __typename?: "YardiLeasesForRenewal_max_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type YardiLeasesForRenewal_Min_Fields = {
  __typename?: "YardiLeasesForRenewal_min_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  EndDate?: Maybe<Scalars["timestamptz"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  unit1?: Maybe<Scalars["String"]>;
  unit2?: Maybe<Scalars["String"]>;
  unit3?: Maybe<Scalars["String"]>;
  unit4?: Maybe<Scalars["String"]>;
  unit5?: Maybe<Scalars["String"]>;
};

/** Ordering options when selecting data from "YardiLeasesForRenewal". */
export type YardiLeasesForRenewal_Order_By = {
  Anchor?: InputMaybe<Order_By>;
  BaseRent?: InputMaybe<Order_By>;
  BrandCode?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  EndDate?: InputMaybe<Order_By>;
  IsHoldover?: InputMaybe<Order_By>;
  LeaseType?: InputMaybe<Order_By>;
  MaintenanceNotes?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  Outgoings?: InputMaybe<Order_By>;
  PromoLevy?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  SalesCategory?: InputMaybe<Order_By>;
  TIC?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  TenantName?: InputMaybe<Order_By>;
  TenantStartDate?: InputMaybe<Order_By>;
  TenantStatus?: InputMaybe<Order_By>;
  TradeName?: InputMaybe<Order_By>;
  unit1?: InputMaybe<Order_By>;
  unit2?: InputMaybe<Order_By>;
  unit3?: InputMaybe<Order_By>;
  unit4?: InputMaybe<Order_By>;
  unit5?: InputMaybe<Order_By>;
};

/** select columns of table "YardiLeasesForRenewal" */
export enum YardiLeasesForRenewal_Select_Column {
  /** column name */
  Anchor = "Anchor",
  /** column name */
  BaseRent = "BaseRent",
  /** column name */
  BrandCode = "BrandCode",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  EndDate = "EndDate",
  /** column name */
  IsHoldover = "IsHoldover",
  /** column name */
  LeaseType = "LeaseType",
  /** column name */
  MaintenanceNotes = "MaintenanceNotes",
  /** column name */
  Notes = "Notes",
  /** column name */
  Outgoings = "Outgoings",
  /** column name */
  PromoLevy = "PromoLevy",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  SalesCategory = "SalesCategory",
  /** column name */
  Tic = "TIC",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantStartDate = "TenantStartDate",
  /** column name */
  TenantStatus = "TenantStatus",
  /** column name */
  TradeName = "TradeName",
  /** column name */
  Unit1 = "unit1",
  /** column name */
  Unit2 = "unit2",
  /** column name */
  Unit3 = "unit3",
  /** column name */
  Unit4 = "unit4",
  /** column name */
  Unit5 = "unit5",
}

/** Streaming cursor of the table "YardiLeasesForRenewal" */
export type YardiLeasesForRenewal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiLeasesForRenewal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiLeasesForRenewal_Stream_Cursor_Value_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  EndDate?: InputMaybe<Scalars["timestamptz"]>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  unit1?: InputMaybe<Scalars["String"]>;
  unit2?: InputMaybe<Scalars["String"]>;
  unit3?: InputMaybe<Scalars["String"]>;
  unit4?: InputMaybe<Scalars["String"]>;
  unit5?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "YardiLimitedCustomers" */
export type YardiLimitedCustomers = {
  __typename?: "YardiLimitedCustomers";
  CustomerCode?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "YardiLimitedCustomers" */
export type YardiLimitedCustomers_Aggregate = {
  __typename?: "YardiLimitedCustomers_aggregate";
  aggregate?: Maybe<YardiLimitedCustomers_Aggregate_Fields>;
  nodes: Array<YardiLimitedCustomers>;
};

/** aggregate fields of "YardiLimitedCustomers" */
export type YardiLimitedCustomers_Aggregate_Fields = {
  __typename?: "YardiLimitedCustomers_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiLimitedCustomers_Max_Fields>;
  min?: Maybe<YardiLimitedCustomers_Min_Fields>;
};

/** aggregate fields of "YardiLimitedCustomers" */
export type YardiLimitedCustomers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiLimitedCustomers_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiLimitedCustomers". All fields are combined with a logical 'AND'. */
export type YardiLimitedCustomers_Bool_Exp = {
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  CustomerName?: InputMaybe<String_Comparison_Exp>;
  CustomerType?: InputMaybe<String_Comparison_Exp>;
  ParentCustomerCode?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiLimitedCustomers_Bool_Exp>>;
  _not?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
  _or?: InputMaybe<Array<YardiLimitedCustomers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type YardiLimitedCustomers_Max_Fields = {
  __typename?: "YardiLimitedCustomers_max_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type YardiLimitedCustomers_Min_Fields = {
  __typename?: "YardiLimitedCustomers_min_fields";
  CustomerCode?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["String"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  ParentCustomerCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
};

/** Ordering options when selecting data from "YardiLimitedCustomers". */
export type YardiLimitedCustomers_Order_By = {
  CustomerCode?: InputMaybe<Order_By>;
  CustomerName?: InputMaybe<Order_By>;
  CustomerType?: InputMaybe<Order_By>;
  ParentCustomerCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
};

/** select columns of table "YardiLimitedCustomers" */
export enum YardiLimitedCustomers_Select_Column {
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  CustomerType = "CustomerType",
  /** column name */
  ParentCustomerCode = "ParentCustomerCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
}

/** Streaming cursor of the table "YardiLimitedCustomers" */
export type YardiLimitedCustomers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiLimitedCustomers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiLimitedCustomers_Stream_Cursor_Value_Input = {
  CustomerCode?: InputMaybe<Scalars["String"]>;
  CustomerName?: InputMaybe<Scalars["String"]>;
  CustomerType?: InputMaybe<Scalars["String"]>;
  ParentCustomerCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "YardiProperties" */
export type YardiProperties = {
  __typename?: "YardiProperties";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  ApMonth: Scalars["String"];
  ArMonth: Scalars["String"];
  Attributes?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  CenterApprovers: Array<CenterApprovers>;
  /** An aggregate relationship */
  CenterApprovers_aggregate: CenterApprovers_Aggregate;
  City?: Maybe<Scalars["String"]>;
  Country: Scalars["String"];
  JournalMonth: Scalars["String"];
  LegalEntity: Scalars["String"];
  Notes?: Maybe<Scalars["String"]>;
  PostCode?: Maybe<Scalars["String"]>;
  /** An object relationship */
  PropertiesInformation?: Maybe<PropertiesInformation>;
  PropertyArea: Scalars["numeric"];
  PropertyCode: Scalars["String"];
  /** An array relationship */
  PropertyContacts: Array<PropertyContacts>;
  /** An aggregate relationship */
  PropertyContacts_aggregate: PropertyContacts_Aggregate;
  /** An array relationship */
  PropertyFiles: Array<PropertyFiles>;
  /** An aggregate relationship */
  PropertyFiles_aggregate: PropertyFiles_Aggregate;
  PropertyName: Scalars["String"];
  PropertyStatus: Scalars["String"];
  PropertyType: Scalars["String"];
  State?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiContacts_YardiProperties: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_YardiProperties_aggregate: YardiContacts_YardiProperties_Aggregate;
  /** An object relationship */
  YardiPropertyProfile?: Maybe<YardiPropertyProfiles>;
  /** An array relationship */
  YardiTenants: Array<YardiTenants>;
  /** An aggregate relationship */
  YardiTenants_aggregate: YardiTenants_Aggregate;
  /** An array relationship */
  YardiUnitProfiles: Array<YardiUnitProfiles>;
  /** An aggregate relationship */
  YardiUnitProfiles_aggregate: YardiUnitProfiles_Aggregate;
  /** An array relationship */
  YardiUnits: Array<YardiUnits>;
  /** An aggregate relationship */
  YardiUnits_aggregate: YardiUnits_Aggregate;
  /** An array relationship */
  YardiVirtualUnits: Array<YardiVirtualUnits>;
  /** An aggregate relationship */
  YardiVirtualUnits_aggregate: YardiVirtualUnits_Aggregate;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesAttributesArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesCenterApproversArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesCenterApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesPropertyContactsArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesPropertyContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesPropertyFilesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesPropertyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiContacts_YardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiContacts_YardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiTenantsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiUnitProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiUnitProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiVirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

/** columns and relationships of "YardiProperties" */
export type YardiPropertiesYardiVirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

/** aggregated selection of "YardiProperties" */
export type YardiProperties_Aggregate = {
  __typename?: "YardiProperties_aggregate";
  aggregate?: Maybe<YardiProperties_Aggregate_Fields>;
  nodes: Array<YardiProperties>;
};

/** aggregate fields of "YardiProperties" */
export type YardiProperties_Aggregate_Fields = {
  __typename?: "YardiProperties_aggregate_fields";
  avg?: Maybe<YardiProperties_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiProperties_Max_Fields>;
  min?: Maybe<YardiProperties_Min_Fields>;
  stddev?: Maybe<YardiProperties_Stddev_Fields>;
  stddev_pop?: Maybe<YardiProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiProperties_Stddev_Samp_Fields>;
  sum?: Maybe<YardiProperties_Sum_Fields>;
  var_pop?: Maybe<YardiProperties_Var_Pop_Fields>;
  var_samp?: Maybe<YardiProperties_Var_Samp_Fields>;
  variance?: Maybe<YardiProperties_Variance_Fields>;
};

/** aggregate fields of "YardiProperties" */
export type YardiProperties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiProperties_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type YardiProperties_Append_Input = {
  Attributes?: InputMaybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type YardiProperties_Avg_Fields = {
  __typename?: "YardiProperties_avg_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "YardiProperties". All fields are combined with a logical 'AND'. */
export type YardiProperties_Bool_Exp = {
  Address1?: InputMaybe<String_Comparison_Exp>;
  Address2?: InputMaybe<String_Comparison_Exp>;
  ApMonth?: InputMaybe<String_Comparison_Exp>;
  ArMonth?: InputMaybe<String_Comparison_Exp>;
  Attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  CenterApprovers?: InputMaybe<CenterApprovers_Bool_Exp>;
  CenterApprovers_aggregate?: InputMaybe<CenterApprovers_Aggregate_Bool_Exp>;
  City?: InputMaybe<String_Comparison_Exp>;
  Country?: InputMaybe<String_Comparison_Exp>;
  JournalMonth?: InputMaybe<String_Comparison_Exp>;
  LegalEntity?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  PostCode?: InputMaybe<String_Comparison_Exp>;
  PropertiesInformation?: InputMaybe<PropertiesInformation_Bool_Exp>;
  PropertyArea?: InputMaybe<Numeric_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  PropertyContacts?: InputMaybe<PropertyContacts_Bool_Exp>;
  PropertyContacts_aggregate?: InputMaybe<PropertyContacts_Aggregate_Bool_Exp>;
  PropertyFiles?: InputMaybe<PropertyFiles_Bool_Exp>;
  PropertyFiles_aggregate?: InputMaybe<PropertyFiles_Aggregate_Bool_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  PropertyStatus?: InputMaybe<String_Comparison_Exp>;
  PropertyType?: InputMaybe<String_Comparison_Exp>;
  State?: InputMaybe<String_Comparison_Exp>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Bool_Exp>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
  YardiTenants?: InputMaybe<YardiTenants_Bool_Exp>;
  YardiTenants_aggregate?: InputMaybe<YardiTenants_Aggregate_Bool_Exp>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Bool_Exp>;
  YardiUnits?: InputMaybe<YardiUnits_Bool_Exp>;
  YardiUnits_aggregate?: InputMaybe<YardiUnits_Aggregate_Bool_Exp>;
  YardiVirtualUnits?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
  YardiVirtualUnits_aggregate?: InputMaybe<YardiVirtualUnits_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<YardiProperties_Bool_Exp>>;
  _not?: InputMaybe<YardiProperties_Bool_Exp>;
  _or?: InputMaybe<Array<YardiProperties_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiProperties" */
export enum YardiProperties_Constraint {
  /** unique or primary key constraint on columns "PropertyCode" */
  YardiPropertiesPkey = "YardiProperties_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type YardiProperties_Delete_At_Path_Input = {
  Attributes?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type YardiProperties_Delete_Elem_Input = {
  Attributes?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type YardiProperties_Delete_Key_Input = {
  Attributes?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "YardiProperties" */
export type YardiProperties_Inc_Input = {
  PropertyArea?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "YardiProperties" */
export type YardiProperties_Insert_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  ApMonth?: InputMaybe<Scalars["String"]>;
  ArMonth?: InputMaybe<Scalars["String"]>;
  Attributes?: InputMaybe<Scalars["jsonb"]>;
  CenterApprovers?: InputMaybe<CenterApprovers_Arr_Rel_Insert_Input>;
  City?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  JournalMonth?: InputMaybe<Scalars["String"]>;
  LegalEntity?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PostCode?: InputMaybe<Scalars["String"]>;
  PropertiesInformation?: InputMaybe<PropertiesInformation_Obj_Rel_Insert_Input>;
  PropertyArea?: InputMaybe<Scalars["numeric"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyContacts?: InputMaybe<PropertyContacts_Arr_Rel_Insert_Input>;
  PropertyFiles?: InputMaybe<PropertyFiles_Arr_Rel_Insert_Input>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  PropertyStatus?: InputMaybe<Scalars["String"]>;
  PropertyType?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  YardiContacts_YardiProperties?: InputMaybe<YardiContacts_YardiProperties_Arr_Rel_Insert_Input>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Obj_Rel_Insert_Input>;
  YardiTenants?: InputMaybe<YardiTenants_Arr_Rel_Insert_Input>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Arr_Rel_Insert_Input>;
  YardiUnits?: InputMaybe<YardiUnits_Arr_Rel_Insert_Input>;
  YardiVirtualUnits?: InputMaybe<YardiVirtualUnits_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiProperties_Max_Fields = {
  __typename?: "YardiProperties_max_fields";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  ApMonth?: Maybe<Scalars["String"]>;
  ArMonth?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  JournalMonth?: Maybe<Scalars["String"]>;
  LegalEntity?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  PostCode?: Maybe<Scalars["String"]>;
  PropertyArea?: Maybe<Scalars["numeric"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  PropertyStatus?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type YardiProperties_Min_Fields = {
  __typename?: "YardiProperties_min_fields";
  Address1?: Maybe<Scalars["String"]>;
  Address2?: Maybe<Scalars["String"]>;
  ApMonth?: Maybe<Scalars["String"]>;
  ArMonth?: Maybe<Scalars["String"]>;
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  JournalMonth?: Maybe<Scalars["String"]>;
  LegalEntity?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  PostCode?: Maybe<Scalars["String"]>;
  PropertyArea?: Maybe<Scalars["numeric"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  PropertyStatus?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "YardiProperties" */
export type YardiProperties_Mutation_Response = {
  __typename?: "YardiProperties_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiProperties>;
};

/** input type for inserting object relation for remote table "YardiProperties" */
export type YardiProperties_Obj_Rel_Insert_Input = {
  data: YardiProperties_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiProperties_On_Conflict>;
};

/** on_conflict condition type for table "YardiProperties" */
export type YardiProperties_On_Conflict = {
  constraint: YardiProperties_Constraint;
  update_columns?: Array<YardiProperties_Update_Column>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiProperties". */
export type YardiProperties_Order_By = {
  Address1?: InputMaybe<Order_By>;
  Address2?: InputMaybe<Order_By>;
  ApMonth?: InputMaybe<Order_By>;
  ArMonth?: InputMaybe<Order_By>;
  Attributes?: InputMaybe<Order_By>;
  CenterApprovers_aggregate?: InputMaybe<CenterApprovers_Aggregate_Order_By>;
  City?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  JournalMonth?: InputMaybe<Order_By>;
  LegalEntity?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PostCode?: InputMaybe<Order_By>;
  PropertiesInformation?: InputMaybe<PropertiesInformation_Order_By>;
  PropertyArea?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyContacts_aggregate?: InputMaybe<PropertyContacts_Aggregate_Order_By>;
  PropertyFiles_aggregate?: InputMaybe<PropertyFiles_Aggregate_Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  PropertyStatus?: InputMaybe<Order_By>;
  PropertyType?: InputMaybe<Order_By>;
  State?: InputMaybe<Order_By>;
  YardiContacts_YardiProperties_aggregate?: InputMaybe<YardiContacts_YardiProperties_Aggregate_Order_By>;
  YardiPropertyProfile?: InputMaybe<YardiPropertyProfiles_Order_By>;
  YardiTenants_aggregate?: InputMaybe<YardiTenants_Aggregate_Order_By>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Order_By>;
  YardiUnits_aggregate?: InputMaybe<YardiUnits_Aggregate_Order_By>;
  YardiVirtualUnits_aggregate?: InputMaybe<YardiVirtualUnits_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiProperties */
export type YardiProperties_Pk_Columns_Input = {
  PropertyCode: Scalars["String"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type YardiProperties_Prepend_Input = {
  Attributes?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "YardiProperties" */
export enum YardiProperties_Select_Column {
  /** column name */
  Address1 = "Address1",
  /** column name */
  Address2 = "Address2",
  /** column name */
  ApMonth = "ApMonth",
  /** column name */
  ArMonth = "ArMonth",
  /** column name */
  Attributes = "Attributes",
  /** column name */
  City = "City",
  /** column name */
  Country = "Country",
  /** column name */
  JournalMonth = "JournalMonth",
  /** column name */
  LegalEntity = "LegalEntity",
  /** column name */
  Notes = "Notes",
  /** column name */
  PostCode = "PostCode",
  /** column name */
  PropertyArea = "PropertyArea",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  PropertyStatus = "PropertyStatus",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  State = "State",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiProperties" */
export type YardiProperties_Set_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  ApMonth?: InputMaybe<Scalars["String"]>;
  ArMonth?: InputMaybe<Scalars["String"]>;
  Attributes?: InputMaybe<Scalars["jsonb"]>;
  City?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  JournalMonth?: InputMaybe<Scalars["String"]>;
  LegalEntity?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PostCode?: InputMaybe<Scalars["String"]>;
  PropertyArea?: InputMaybe<Scalars["numeric"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  PropertyStatus?: InputMaybe<Scalars["String"]>;
  PropertyType?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiProperties_Stddev_Fields = {
  __typename?: "YardiProperties_stddev_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type YardiProperties_Stddev_Pop_Fields = {
  __typename?: "YardiProperties_stddev_pop_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type YardiProperties_Stddev_Samp_Fields = {
  __typename?: "YardiProperties_stddev_samp_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "YardiProperties" */
export type YardiProperties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiProperties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiProperties_Stream_Cursor_Value_Input = {
  Address1?: InputMaybe<Scalars["String"]>;
  Address2?: InputMaybe<Scalars["String"]>;
  ApMonth?: InputMaybe<Scalars["String"]>;
  ArMonth?: InputMaybe<Scalars["String"]>;
  Attributes?: InputMaybe<Scalars["jsonb"]>;
  City?: InputMaybe<Scalars["String"]>;
  Country?: InputMaybe<Scalars["String"]>;
  JournalMonth?: InputMaybe<Scalars["String"]>;
  LegalEntity?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PostCode?: InputMaybe<Scalars["String"]>;
  PropertyArea?: InputMaybe<Scalars["numeric"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  PropertyStatus?: InputMaybe<Scalars["String"]>;
  PropertyType?: InputMaybe<Scalars["String"]>;
  State?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiProperties_Sum_Fields = {
  __typename?: "YardiProperties_sum_fields";
  PropertyArea?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "YardiProperties" */
export enum YardiProperties_Update_Column {
  /** column name */
  Address1 = "Address1",
  /** column name */
  Address2 = "Address2",
  /** column name */
  ApMonth = "ApMonth",
  /** column name */
  ArMonth = "ArMonth",
  /** column name */
  Attributes = "Attributes",
  /** column name */
  City = "City",
  /** column name */
  Country = "Country",
  /** column name */
  JournalMonth = "JournalMonth",
  /** column name */
  LegalEntity = "LegalEntity",
  /** column name */
  Notes = "Notes",
  /** column name */
  PostCode = "PostCode",
  /** column name */
  PropertyArea = "PropertyArea",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  PropertyStatus = "PropertyStatus",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  State = "State",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiProperties_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<YardiProperties_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<YardiProperties_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<YardiProperties_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<YardiProperties_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiProperties_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<YardiProperties_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiProperties_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiProperties_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiProperties_Var_Pop_Fields = {
  __typename?: "YardiProperties_var_pop_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type YardiProperties_Var_Samp_Fields = {
  __typename?: "YardiProperties_var_samp_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type YardiProperties_Variance_Fields = {
  __typename?: "YardiProperties_variance_fields";
  PropertyArea?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "YardiPropertyProfiles" */
export type YardiPropertyProfiles = {
  __typename?: "YardiPropertyProfiles";
  PropertyCode: Scalars["String"];
  SSOLICITORPOST?: Maybe<Scalars["String"]>;
  /** An object relationship */
  YardiProperty: YardiProperties;
  additional_property_comments?: Maybe<Scalars["String"]>;
  alteration?: Maybe<Scalars["String"]>;
  alteration_works?: Maybe<Scalars["String"]>;
  anchor_tenant_expiry?: Maybe<Scalars["String"]>;
  annual_gr_return_centre?: Maybe<Scalars["money"]>;
  annual_gr_return_food?: Maybe<Scalars["money"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["money"]>;
  annual_gr_return_service?: Maybe<Scalars["money"]>;
  centre_exp?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  custom_table_record_code: Scalars["String"];
  customer_parking_space_num?: Maybe<Scalars["float8"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  development?: Maybe<Scalars["String"]>;
  effect_of_obligations?: Maybe<Scalars["String"]>;
  exp_admin?: Maybe<Scalars["money"]>;
  exp_air_maint?: Maybe<Scalars["money"]>;
  exp_air_ops?: Maybe<Scalars["money"]>;
  exp_audit?: Maybe<Scalars["money"]>;
  exp_building_intelligence?: Maybe<Scalars["money"]>;
  exp_car_parking?: Maybe<Scalars["money"]>;
  exp_caretaking?: Maybe<Scalars["money"]>;
  exp_child?: Maybe<Scalars["String"]>;
  exp_child_minding?: Maybe<Scalars["money"]>;
  exp_clean_consumable?: Maybe<Scalars["money"]>;
  exp_cleaning_costs?: Maybe<Scalars["money"]>;
  exp_electricity?: Maybe<Scalars["money"]>;
  exp_emergency_sys?: Maybe<Scalars["money"]>;
  exp_energy_mgmt?: Maybe<Scalars["money"]>;
  exp_escalators?: Maybe<Scalars["money"]>;
  exp_fire_levy?: Maybe<Scalars["money"]>;
  exp_fire_protection?: Maybe<Scalars["money"]>;
  exp_gas?: Maybe<Scalars["money"]>;
  exp_info_directories?: Maybe<Scalars["money"]>;
  exp_insurance?: Maybe<Scalars["money"]>;
  exp_land_tax?: Maybe<Scalars["money"]>;
  exp_landscape?: Maybe<Scalars["money"]>;
  exp_lift?: Maybe<Scalars["money"]>;
  exp_local_gov_charges?: Maybe<Scalars["money"]>;
  exp_mgmt_fee?: Maybe<Scalars["money"]>;
  exp_ohs?: Maybe<Scalars["money"]>;
  exp_oil?: Maybe<Scalars["money"]>;
  exp_other?: Maybe<Scalars["String"]>;
  exp_pest?: Maybe<Scalars["money"]>;
  exp_post_boxes?: Maybe<Scalars["money"]>;
  exp_public_address?: Maybe<Scalars["money"]>;
  exp_public_telephones?: Maybe<Scalars["money"]>;
  exp_repairs_and_maint?: Maybe<Scalars["money"]>;
  exp_security?: Maybe<Scalars["money"]>;
  exp_sewerage_disposal?: Maybe<Scalars["money"]>;
  exp_signage?: Maybe<Scalars["money"]>;
  exp_sinking_fund?: Maybe<Scalars["money"]>;
  exp_staff_toilet?: Maybe<Scalars["String"]>;
  exp_staff_wages?: Maybe<Scalars["money"]>;
  exp_strata?: Maybe<Scalars["money"]>;
  exp_traffic_flow?: Maybe<Scalars["money"]>;
  exp_uniforms?: Maybe<Scalars["money"]>;
  exp_ventilation?: Maybe<Scalars["money"]>;
  exp_water?: Maybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["money"]>;
  exp_water_sewage_rates?: Maybe<Scalars["money"]>;
  head_or_crown_lease?: Maybe<Scalars["String"]>;
  head_or_crown_lease_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: Maybe<Scalars["timestamptz"]>;
  landlord_acn?: Maybe<Scalars["String"]>;
  landlord_address?: Maybe<Scalars["String"]>;
  landlord_fax?: Maybe<Scalars["String"]>;
  landlord_name?: Maybe<Scalars["String"]>;
  landlord_service_fac?: Maybe<Scalars["String"]>;
  lease_registration_fee?: Maybe<Scalars["money"]>;
  legal_procedure_premises?: Maybe<Scalars["String"]>;
  management_company?: Maybe<Scalars["String"]>;
  management_company_abn?: Maybe<Scalars["String"]>;
  management_company_address?: Maybe<Scalars["String"]>;
  management_company_fax?: Maybe<Scalars["String"]>;
  management_company_phone?: Maybe<Scalars["String"]>;
  management_company_postal?: Maybe<Scalars["String"]>;
  management_company_real_estate_licence?: Maybe<Scalars["String"]>;
  market_competitor?: Maybe<Scalars["String"]>;
  miscellaneous?: Maybe<Scalars["money"]>;
  modified_at: Scalars["timestamptz"];
  num_shop?: Maybe<Scalars["String"]>;
  parking_space_num?: Maybe<Scalars["float8"]>;
  profile_year_start?: Maybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: Maybe<Scalars["String"]>;
  require_minister_consent_to_crown?: Maybe<Scalars["String"]>;
  require_mortgagee_consent?: Maybe<Scalars["String"]>;
  services_bin_room?: Maybe<Scalars["String"]>;
  services_common_clean?: Maybe<Scalars["String"]>;
  services_common_light?: Maybe<Scalars["String"]>;
  services_security?: Maybe<Scalars["String"]>;
  services_tenancy_clean?: Maybe<Scalars["String"]>;
  shops_leased_and_occupied?: Maybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: Maybe<Scalars["String"]>;
  site_area?: Maybe<Scalars["Int"]>;
  survey_fee_per_trans?: Maybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["money"]>;
  trade_hour_fri?: Maybe<Scalars["String"]>;
  trade_hour_holiday?: Maybe<Scalars["String"]>;
  trade_hour_mon?: Maybe<Scalars["String"]>;
  trade_hour_sat?: Maybe<Scalars["String"]>;
  trade_hour_sun?: Maybe<Scalars["String"]>;
  trade_hour_thur?: Maybe<Scalars["String"]>;
  trade_hour_tues?: Maybe<Scalars["String"]>;
  trade_hour_wed?: Maybe<Scalars["String"]>;
  turn_over_sat?: Maybe<Scalars["timestamptz"]>;
};

/** aggregated selection of "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Aggregate = {
  __typename?: "YardiPropertyProfiles_aggregate";
  aggregate?: Maybe<YardiPropertyProfiles_Aggregate_Fields>;
  nodes: Array<YardiPropertyProfiles>;
};

/** aggregate fields of "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Aggregate_Fields = {
  __typename?: "YardiPropertyProfiles_aggregate_fields";
  avg?: Maybe<YardiPropertyProfiles_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiPropertyProfiles_Max_Fields>;
  min?: Maybe<YardiPropertyProfiles_Min_Fields>;
  stddev?: Maybe<YardiPropertyProfiles_Stddev_Fields>;
  stddev_pop?: Maybe<YardiPropertyProfiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiPropertyProfiles_Stddev_Samp_Fields>;
  sum?: Maybe<YardiPropertyProfiles_Sum_Fields>;
  var_pop?: Maybe<YardiPropertyProfiles_Var_Pop_Fields>;
  var_samp?: Maybe<YardiPropertyProfiles_Var_Samp_Fields>;
  variance?: Maybe<YardiPropertyProfiles_Variance_Fields>;
};

/** aggregate fields of "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiPropertyProfiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type YardiPropertyProfiles_Avg_Fields = {
  __typename?: "YardiPropertyProfiles_avg_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "YardiPropertyProfiles". All fields are combined with a logical 'AND'. */
export type YardiPropertyProfiles_Bool_Exp = {
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  SSOLICITORPOST?: InputMaybe<String_Comparison_Exp>;
  YardiProperty?: InputMaybe<YardiProperties_Bool_Exp>;
  _and?: InputMaybe<Array<YardiPropertyProfiles_Bool_Exp>>;
  _not?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
  _or?: InputMaybe<Array<YardiPropertyProfiles_Bool_Exp>>;
  additional_property_comments?: InputMaybe<String_Comparison_Exp>;
  alteration?: InputMaybe<String_Comparison_Exp>;
  alteration_works?: InputMaybe<String_Comparison_Exp>;
  anchor_tenant_expiry?: InputMaybe<String_Comparison_Exp>;
  annual_gr_return_centre?: InputMaybe<Money_Comparison_Exp>;
  annual_gr_return_food?: InputMaybe<Money_Comparison_Exp>;
  annual_gr_return_nonfood?: InputMaybe<Money_Comparison_Exp>;
  annual_gr_return_service?: InputMaybe<Money_Comparison_Exp>;
  centre_exp?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_table_record_code?: InputMaybe<String_Comparison_Exp>;
  customer_parking_space_num?: InputMaybe<Float8_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  development?: InputMaybe<String_Comparison_Exp>;
  effect_of_obligations?: InputMaybe<String_Comparison_Exp>;
  exp_admin?: InputMaybe<Money_Comparison_Exp>;
  exp_air_maint?: InputMaybe<Money_Comparison_Exp>;
  exp_air_ops?: InputMaybe<Money_Comparison_Exp>;
  exp_audit?: InputMaybe<Money_Comparison_Exp>;
  exp_building_intelligence?: InputMaybe<Money_Comparison_Exp>;
  exp_car_parking?: InputMaybe<Money_Comparison_Exp>;
  exp_caretaking?: InputMaybe<Money_Comparison_Exp>;
  exp_child?: InputMaybe<String_Comparison_Exp>;
  exp_child_minding?: InputMaybe<Money_Comparison_Exp>;
  exp_clean_consumable?: InputMaybe<Money_Comparison_Exp>;
  exp_cleaning_costs?: InputMaybe<Money_Comparison_Exp>;
  exp_electricity?: InputMaybe<Money_Comparison_Exp>;
  exp_emergency_sys?: InputMaybe<Money_Comparison_Exp>;
  exp_energy_mgmt?: InputMaybe<Money_Comparison_Exp>;
  exp_escalators?: InputMaybe<Money_Comparison_Exp>;
  exp_fire_levy?: InputMaybe<Money_Comparison_Exp>;
  exp_fire_protection?: InputMaybe<Money_Comparison_Exp>;
  exp_gas?: InputMaybe<Money_Comparison_Exp>;
  exp_info_directories?: InputMaybe<Money_Comparison_Exp>;
  exp_insurance?: InputMaybe<Money_Comparison_Exp>;
  exp_land_tax?: InputMaybe<Money_Comparison_Exp>;
  exp_landscape?: InputMaybe<Money_Comparison_Exp>;
  exp_lift?: InputMaybe<Money_Comparison_Exp>;
  exp_local_gov_charges?: InputMaybe<Money_Comparison_Exp>;
  exp_mgmt_fee?: InputMaybe<Money_Comparison_Exp>;
  exp_ohs?: InputMaybe<Money_Comparison_Exp>;
  exp_oil?: InputMaybe<Money_Comparison_Exp>;
  exp_other?: InputMaybe<String_Comparison_Exp>;
  exp_pest?: InputMaybe<Money_Comparison_Exp>;
  exp_post_boxes?: InputMaybe<Money_Comparison_Exp>;
  exp_public_address?: InputMaybe<Money_Comparison_Exp>;
  exp_public_telephones?: InputMaybe<Money_Comparison_Exp>;
  exp_repairs_and_maint?: InputMaybe<Money_Comparison_Exp>;
  exp_security?: InputMaybe<Money_Comparison_Exp>;
  exp_sewerage_disposal?: InputMaybe<Money_Comparison_Exp>;
  exp_signage?: InputMaybe<Money_Comparison_Exp>;
  exp_sinking_fund?: InputMaybe<Money_Comparison_Exp>;
  exp_staff_toilet?: InputMaybe<String_Comparison_Exp>;
  exp_staff_wages?: InputMaybe<Money_Comparison_Exp>;
  exp_strata?: InputMaybe<Money_Comparison_Exp>;
  exp_traffic_flow?: InputMaybe<Money_Comparison_Exp>;
  exp_uniforms?: InputMaybe<Money_Comparison_Exp>;
  exp_ventilation?: InputMaybe<Money_Comparison_Exp>;
  exp_water?: InputMaybe<Money_Comparison_Exp>;
  exp_water_collection_and_disposal?: InputMaybe<Money_Comparison_Exp>;
  exp_water_sewage_rates?: InputMaybe<Money_Comparison_Exp>;
  head_or_crown_lease?: InputMaybe<String_Comparison_Exp>;
  head_or_crown_lease_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  head_or_crown_lease_option_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  head_or_crown_lease_option_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  head_or_crown_lease_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  landlord_acn?: InputMaybe<String_Comparison_Exp>;
  landlord_address?: InputMaybe<String_Comparison_Exp>;
  landlord_fax?: InputMaybe<String_Comparison_Exp>;
  landlord_name?: InputMaybe<String_Comparison_Exp>;
  landlord_service_fac?: InputMaybe<String_Comparison_Exp>;
  lease_registration_fee?: InputMaybe<Money_Comparison_Exp>;
  legal_procedure_premises?: InputMaybe<String_Comparison_Exp>;
  management_company?: InputMaybe<String_Comparison_Exp>;
  management_company_abn?: InputMaybe<String_Comparison_Exp>;
  management_company_address?: InputMaybe<String_Comparison_Exp>;
  management_company_fax?: InputMaybe<String_Comparison_Exp>;
  management_company_phone?: InputMaybe<String_Comparison_Exp>;
  management_company_postal?: InputMaybe<String_Comparison_Exp>;
  management_company_real_estate_licence?: InputMaybe<String_Comparison_Exp>;
  market_competitor?: InputMaybe<String_Comparison_Exp>;
  miscellaneous?: InputMaybe<Money_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  num_shop?: InputMaybe<String_Comparison_Exp>;
  parking_space_num?: InputMaybe<Float8_Comparison_Exp>;
  profile_year_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  require_consent_to_lease?: InputMaybe<String_Comparison_Exp>;
  require_minister_consent_to_crown?: InputMaybe<String_Comparison_Exp>;
  require_mortgagee_consent?: InputMaybe<String_Comparison_Exp>;
  services_bin_room?: InputMaybe<String_Comparison_Exp>;
  services_common_clean?: InputMaybe<String_Comparison_Exp>;
  services_common_light?: InputMaybe<String_Comparison_Exp>;
  services_security?: InputMaybe<String_Comparison_Exp>;
  services_tenancy_clean?: InputMaybe<String_Comparison_Exp>;
  shops_leased_and_occupied?: InputMaybe<String_Comparison_Exp>;
  shops_leased_and_unoccupied?: InputMaybe<String_Comparison_Exp>;
  site_area?: InputMaybe<Int_Comparison_Exp>;
  survey_fee_per_trans?: InputMaybe<Money_Comparison_Exp>;
  tenancy_coord_fee_per_trans?: InputMaybe<Money_Comparison_Exp>;
  trade_hour_fri?: InputMaybe<String_Comparison_Exp>;
  trade_hour_holiday?: InputMaybe<String_Comparison_Exp>;
  trade_hour_mon?: InputMaybe<String_Comparison_Exp>;
  trade_hour_sat?: InputMaybe<String_Comparison_Exp>;
  trade_hour_sun?: InputMaybe<String_Comparison_Exp>;
  trade_hour_thur?: InputMaybe<String_Comparison_Exp>;
  trade_hour_tues?: InputMaybe<String_Comparison_Exp>;
  trade_hour_wed?: InputMaybe<String_Comparison_Exp>;
  turn_over_sat?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiPropertyProfiles" */
export enum YardiPropertyProfiles_Constraint {
  /** unique or primary key constraint on columns "PropertyCode" */
  YardiPropertyProfilesPkey = "YardiPropertyProfiles_pkey",
}

/** input type for incrementing numeric columns in table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Inc_Input = {
  annual_gr_return_centre?: InputMaybe<Scalars["money"]>;
  annual_gr_return_food?: InputMaybe<Scalars["money"]>;
  annual_gr_return_nonfood?: InputMaybe<Scalars["money"]>;
  annual_gr_return_service?: InputMaybe<Scalars["money"]>;
  customer_parking_space_num?: InputMaybe<Scalars["float8"]>;
  exp_admin?: InputMaybe<Scalars["money"]>;
  exp_air_maint?: InputMaybe<Scalars["money"]>;
  exp_air_ops?: InputMaybe<Scalars["money"]>;
  exp_audit?: InputMaybe<Scalars["money"]>;
  exp_building_intelligence?: InputMaybe<Scalars["money"]>;
  exp_car_parking?: InputMaybe<Scalars["money"]>;
  exp_caretaking?: InputMaybe<Scalars["money"]>;
  exp_child_minding?: InputMaybe<Scalars["money"]>;
  exp_clean_consumable?: InputMaybe<Scalars["money"]>;
  exp_cleaning_costs?: InputMaybe<Scalars["money"]>;
  exp_electricity?: InputMaybe<Scalars["money"]>;
  exp_emergency_sys?: InputMaybe<Scalars["money"]>;
  exp_energy_mgmt?: InputMaybe<Scalars["money"]>;
  exp_escalators?: InputMaybe<Scalars["money"]>;
  exp_fire_levy?: InputMaybe<Scalars["money"]>;
  exp_fire_protection?: InputMaybe<Scalars["money"]>;
  exp_gas?: InputMaybe<Scalars["money"]>;
  exp_info_directories?: InputMaybe<Scalars["money"]>;
  exp_insurance?: InputMaybe<Scalars["money"]>;
  exp_land_tax?: InputMaybe<Scalars["money"]>;
  exp_landscape?: InputMaybe<Scalars["money"]>;
  exp_lift?: InputMaybe<Scalars["money"]>;
  exp_local_gov_charges?: InputMaybe<Scalars["money"]>;
  exp_mgmt_fee?: InputMaybe<Scalars["money"]>;
  exp_ohs?: InputMaybe<Scalars["money"]>;
  exp_oil?: InputMaybe<Scalars["money"]>;
  exp_pest?: InputMaybe<Scalars["money"]>;
  exp_post_boxes?: InputMaybe<Scalars["money"]>;
  exp_public_address?: InputMaybe<Scalars["money"]>;
  exp_public_telephones?: InputMaybe<Scalars["money"]>;
  exp_repairs_and_maint?: InputMaybe<Scalars["money"]>;
  exp_security?: InputMaybe<Scalars["money"]>;
  exp_sewerage_disposal?: InputMaybe<Scalars["money"]>;
  exp_signage?: InputMaybe<Scalars["money"]>;
  exp_sinking_fund?: InputMaybe<Scalars["money"]>;
  exp_staff_wages?: InputMaybe<Scalars["money"]>;
  exp_strata?: InputMaybe<Scalars["money"]>;
  exp_traffic_flow?: InputMaybe<Scalars["money"]>;
  exp_uniforms?: InputMaybe<Scalars["money"]>;
  exp_ventilation?: InputMaybe<Scalars["money"]>;
  exp_water?: InputMaybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: InputMaybe<Scalars["money"]>;
  exp_water_sewage_rates?: InputMaybe<Scalars["money"]>;
  lease_registration_fee?: InputMaybe<Scalars["money"]>;
  miscellaneous?: InputMaybe<Scalars["money"]>;
  parking_space_num?: InputMaybe<Scalars["float8"]>;
  site_area?: InputMaybe<Scalars["Int"]>;
  survey_fee_per_trans?: InputMaybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: InputMaybe<Scalars["money"]>;
};

/** input type for inserting data into table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Insert_Input = {
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SSOLICITORPOST?: InputMaybe<Scalars["String"]>;
  YardiProperty?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  additional_property_comments?: InputMaybe<Scalars["String"]>;
  alteration?: InputMaybe<Scalars["String"]>;
  alteration_works?: InputMaybe<Scalars["String"]>;
  anchor_tenant_expiry?: InputMaybe<Scalars["String"]>;
  annual_gr_return_centre?: InputMaybe<Scalars["money"]>;
  annual_gr_return_food?: InputMaybe<Scalars["money"]>;
  annual_gr_return_nonfood?: InputMaybe<Scalars["money"]>;
  annual_gr_return_service?: InputMaybe<Scalars["money"]>;
  centre_exp?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_table_record_code?: InputMaybe<Scalars["String"]>;
  customer_parking_space_num?: InputMaybe<Scalars["float8"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  development?: InputMaybe<Scalars["String"]>;
  effect_of_obligations?: InputMaybe<Scalars["String"]>;
  exp_admin?: InputMaybe<Scalars["money"]>;
  exp_air_maint?: InputMaybe<Scalars["money"]>;
  exp_air_ops?: InputMaybe<Scalars["money"]>;
  exp_audit?: InputMaybe<Scalars["money"]>;
  exp_building_intelligence?: InputMaybe<Scalars["money"]>;
  exp_car_parking?: InputMaybe<Scalars["money"]>;
  exp_caretaking?: InputMaybe<Scalars["money"]>;
  exp_child?: InputMaybe<Scalars["String"]>;
  exp_child_minding?: InputMaybe<Scalars["money"]>;
  exp_clean_consumable?: InputMaybe<Scalars["money"]>;
  exp_cleaning_costs?: InputMaybe<Scalars["money"]>;
  exp_electricity?: InputMaybe<Scalars["money"]>;
  exp_emergency_sys?: InputMaybe<Scalars["money"]>;
  exp_energy_mgmt?: InputMaybe<Scalars["money"]>;
  exp_escalators?: InputMaybe<Scalars["money"]>;
  exp_fire_levy?: InputMaybe<Scalars["money"]>;
  exp_fire_protection?: InputMaybe<Scalars["money"]>;
  exp_gas?: InputMaybe<Scalars["money"]>;
  exp_info_directories?: InputMaybe<Scalars["money"]>;
  exp_insurance?: InputMaybe<Scalars["money"]>;
  exp_land_tax?: InputMaybe<Scalars["money"]>;
  exp_landscape?: InputMaybe<Scalars["money"]>;
  exp_lift?: InputMaybe<Scalars["money"]>;
  exp_local_gov_charges?: InputMaybe<Scalars["money"]>;
  exp_mgmt_fee?: InputMaybe<Scalars["money"]>;
  exp_ohs?: InputMaybe<Scalars["money"]>;
  exp_oil?: InputMaybe<Scalars["money"]>;
  exp_other?: InputMaybe<Scalars["String"]>;
  exp_pest?: InputMaybe<Scalars["money"]>;
  exp_post_boxes?: InputMaybe<Scalars["money"]>;
  exp_public_address?: InputMaybe<Scalars["money"]>;
  exp_public_telephones?: InputMaybe<Scalars["money"]>;
  exp_repairs_and_maint?: InputMaybe<Scalars["money"]>;
  exp_security?: InputMaybe<Scalars["money"]>;
  exp_sewerage_disposal?: InputMaybe<Scalars["money"]>;
  exp_signage?: InputMaybe<Scalars["money"]>;
  exp_sinking_fund?: InputMaybe<Scalars["money"]>;
  exp_staff_toilet?: InputMaybe<Scalars["String"]>;
  exp_staff_wages?: InputMaybe<Scalars["money"]>;
  exp_strata?: InputMaybe<Scalars["money"]>;
  exp_traffic_flow?: InputMaybe<Scalars["money"]>;
  exp_uniforms?: InputMaybe<Scalars["money"]>;
  exp_ventilation?: InputMaybe<Scalars["money"]>;
  exp_water?: InputMaybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: InputMaybe<Scalars["money"]>;
  exp_water_sewage_rates?: InputMaybe<Scalars["money"]>;
  head_or_crown_lease?: InputMaybe<Scalars["String"]>;
  head_or_crown_lease_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: InputMaybe<Scalars["timestamptz"]>;
  landlord_acn?: InputMaybe<Scalars["String"]>;
  landlord_address?: InputMaybe<Scalars["String"]>;
  landlord_fax?: InputMaybe<Scalars["String"]>;
  landlord_name?: InputMaybe<Scalars["String"]>;
  landlord_service_fac?: InputMaybe<Scalars["String"]>;
  lease_registration_fee?: InputMaybe<Scalars["money"]>;
  legal_procedure_premises?: InputMaybe<Scalars["String"]>;
  management_company?: InputMaybe<Scalars["String"]>;
  management_company_abn?: InputMaybe<Scalars["String"]>;
  management_company_address?: InputMaybe<Scalars["String"]>;
  management_company_fax?: InputMaybe<Scalars["String"]>;
  management_company_phone?: InputMaybe<Scalars["String"]>;
  management_company_postal?: InputMaybe<Scalars["String"]>;
  management_company_real_estate_licence?: InputMaybe<Scalars["String"]>;
  market_competitor?: InputMaybe<Scalars["String"]>;
  miscellaneous?: InputMaybe<Scalars["money"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  num_shop?: InputMaybe<Scalars["String"]>;
  parking_space_num?: InputMaybe<Scalars["float8"]>;
  profile_year_start?: InputMaybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: InputMaybe<Scalars["String"]>;
  require_minister_consent_to_crown?: InputMaybe<Scalars["String"]>;
  require_mortgagee_consent?: InputMaybe<Scalars["String"]>;
  services_bin_room?: InputMaybe<Scalars["String"]>;
  services_common_clean?: InputMaybe<Scalars["String"]>;
  services_common_light?: InputMaybe<Scalars["String"]>;
  services_security?: InputMaybe<Scalars["String"]>;
  services_tenancy_clean?: InputMaybe<Scalars["String"]>;
  shops_leased_and_occupied?: InputMaybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: InputMaybe<Scalars["String"]>;
  site_area?: InputMaybe<Scalars["Int"]>;
  survey_fee_per_trans?: InputMaybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: InputMaybe<Scalars["money"]>;
  trade_hour_fri?: InputMaybe<Scalars["String"]>;
  trade_hour_holiday?: InputMaybe<Scalars["String"]>;
  trade_hour_mon?: InputMaybe<Scalars["String"]>;
  trade_hour_sat?: InputMaybe<Scalars["String"]>;
  trade_hour_sun?: InputMaybe<Scalars["String"]>;
  trade_hour_thur?: InputMaybe<Scalars["String"]>;
  trade_hour_tues?: InputMaybe<Scalars["String"]>;
  trade_hour_wed?: InputMaybe<Scalars["String"]>;
  turn_over_sat?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiPropertyProfiles_Max_Fields = {
  __typename?: "YardiPropertyProfiles_max_fields";
  PropertyCode?: Maybe<Scalars["String"]>;
  SSOLICITORPOST?: Maybe<Scalars["String"]>;
  additional_property_comments?: Maybe<Scalars["String"]>;
  alteration?: Maybe<Scalars["String"]>;
  alteration_works?: Maybe<Scalars["String"]>;
  anchor_tenant_expiry?: Maybe<Scalars["String"]>;
  annual_gr_return_centre?: Maybe<Scalars["money"]>;
  annual_gr_return_food?: Maybe<Scalars["money"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["money"]>;
  annual_gr_return_service?: Maybe<Scalars["money"]>;
  centre_exp?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_table_record_code?: Maybe<Scalars["String"]>;
  customer_parking_space_num?: Maybe<Scalars["float8"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  development?: Maybe<Scalars["String"]>;
  effect_of_obligations?: Maybe<Scalars["String"]>;
  exp_admin?: Maybe<Scalars["money"]>;
  exp_air_maint?: Maybe<Scalars["money"]>;
  exp_air_ops?: Maybe<Scalars["money"]>;
  exp_audit?: Maybe<Scalars["money"]>;
  exp_building_intelligence?: Maybe<Scalars["money"]>;
  exp_car_parking?: Maybe<Scalars["money"]>;
  exp_caretaking?: Maybe<Scalars["money"]>;
  exp_child?: Maybe<Scalars["String"]>;
  exp_child_minding?: Maybe<Scalars["money"]>;
  exp_clean_consumable?: Maybe<Scalars["money"]>;
  exp_cleaning_costs?: Maybe<Scalars["money"]>;
  exp_electricity?: Maybe<Scalars["money"]>;
  exp_emergency_sys?: Maybe<Scalars["money"]>;
  exp_energy_mgmt?: Maybe<Scalars["money"]>;
  exp_escalators?: Maybe<Scalars["money"]>;
  exp_fire_levy?: Maybe<Scalars["money"]>;
  exp_fire_protection?: Maybe<Scalars["money"]>;
  exp_gas?: Maybe<Scalars["money"]>;
  exp_info_directories?: Maybe<Scalars["money"]>;
  exp_insurance?: Maybe<Scalars["money"]>;
  exp_land_tax?: Maybe<Scalars["money"]>;
  exp_landscape?: Maybe<Scalars["money"]>;
  exp_lift?: Maybe<Scalars["money"]>;
  exp_local_gov_charges?: Maybe<Scalars["money"]>;
  exp_mgmt_fee?: Maybe<Scalars["money"]>;
  exp_ohs?: Maybe<Scalars["money"]>;
  exp_oil?: Maybe<Scalars["money"]>;
  exp_other?: Maybe<Scalars["String"]>;
  exp_pest?: Maybe<Scalars["money"]>;
  exp_post_boxes?: Maybe<Scalars["money"]>;
  exp_public_address?: Maybe<Scalars["money"]>;
  exp_public_telephones?: Maybe<Scalars["money"]>;
  exp_repairs_and_maint?: Maybe<Scalars["money"]>;
  exp_security?: Maybe<Scalars["money"]>;
  exp_sewerage_disposal?: Maybe<Scalars["money"]>;
  exp_signage?: Maybe<Scalars["money"]>;
  exp_sinking_fund?: Maybe<Scalars["money"]>;
  exp_staff_toilet?: Maybe<Scalars["String"]>;
  exp_staff_wages?: Maybe<Scalars["money"]>;
  exp_strata?: Maybe<Scalars["money"]>;
  exp_traffic_flow?: Maybe<Scalars["money"]>;
  exp_uniforms?: Maybe<Scalars["money"]>;
  exp_ventilation?: Maybe<Scalars["money"]>;
  exp_water?: Maybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["money"]>;
  exp_water_sewage_rates?: Maybe<Scalars["money"]>;
  head_or_crown_lease?: Maybe<Scalars["String"]>;
  head_or_crown_lease_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: Maybe<Scalars["timestamptz"]>;
  landlord_acn?: Maybe<Scalars["String"]>;
  landlord_address?: Maybe<Scalars["String"]>;
  landlord_fax?: Maybe<Scalars["String"]>;
  landlord_name?: Maybe<Scalars["String"]>;
  landlord_service_fac?: Maybe<Scalars["String"]>;
  lease_registration_fee?: Maybe<Scalars["money"]>;
  legal_procedure_premises?: Maybe<Scalars["String"]>;
  management_company?: Maybe<Scalars["String"]>;
  management_company_abn?: Maybe<Scalars["String"]>;
  management_company_address?: Maybe<Scalars["String"]>;
  management_company_fax?: Maybe<Scalars["String"]>;
  management_company_phone?: Maybe<Scalars["String"]>;
  management_company_postal?: Maybe<Scalars["String"]>;
  management_company_real_estate_licence?: Maybe<Scalars["String"]>;
  market_competitor?: Maybe<Scalars["String"]>;
  miscellaneous?: Maybe<Scalars["money"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  num_shop?: Maybe<Scalars["String"]>;
  parking_space_num?: Maybe<Scalars["float8"]>;
  profile_year_start?: Maybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: Maybe<Scalars["String"]>;
  require_minister_consent_to_crown?: Maybe<Scalars["String"]>;
  require_mortgagee_consent?: Maybe<Scalars["String"]>;
  services_bin_room?: Maybe<Scalars["String"]>;
  services_common_clean?: Maybe<Scalars["String"]>;
  services_common_light?: Maybe<Scalars["String"]>;
  services_security?: Maybe<Scalars["String"]>;
  services_tenancy_clean?: Maybe<Scalars["String"]>;
  shops_leased_and_occupied?: Maybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: Maybe<Scalars["String"]>;
  site_area?: Maybe<Scalars["Int"]>;
  survey_fee_per_trans?: Maybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["money"]>;
  trade_hour_fri?: Maybe<Scalars["String"]>;
  trade_hour_holiday?: Maybe<Scalars["String"]>;
  trade_hour_mon?: Maybe<Scalars["String"]>;
  trade_hour_sat?: Maybe<Scalars["String"]>;
  trade_hour_sun?: Maybe<Scalars["String"]>;
  trade_hour_thur?: Maybe<Scalars["String"]>;
  trade_hour_tues?: Maybe<Scalars["String"]>;
  trade_hour_wed?: Maybe<Scalars["String"]>;
  turn_over_sat?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type YardiPropertyProfiles_Min_Fields = {
  __typename?: "YardiPropertyProfiles_min_fields";
  PropertyCode?: Maybe<Scalars["String"]>;
  SSOLICITORPOST?: Maybe<Scalars["String"]>;
  additional_property_comments?: Maybe<Scalars["String"]>;
  alteration?: Maybe<Scalars["String"]>;
  alteration_works?: Maybe<Scalars["String"]>;
  anchor_tenant_expiry?: Maybe<Scalars["String"]>;
  annual_gr_return_centre?: Maybe<Scalars["money"]>;
  annual_gr_return_food?: Maybe<Scalars["money"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["money"]>;
  annual_gr_return_service?: Maybe<Scalars["money"]>;
  centre_exp?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_table_record_code?: Maybe<Scalars["String"]>;
  customer_parking_space_num?: Maybe<Scalars["float8"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  development?: Maybe<Scalars["String"]>;
  effect_of_obligations?: Maybe<Scalars["String"]>;
  exp_admin?: Maybe<Scalars["money"]>;
  exp_air_maint?: Maybe<Scalars["money"]>;
  exp_air_ops?: Maybe<Scalars["money"]>;
  exp_audit?: Maybe<Scalars["money"]>;
  exp_building_intelligence?: Maybe<Scalars["money"]>;
  exp_car_parking?: Maybe<Scalars["money"]>;
  exp_caretaking?: Maybe<Scalars["money"]>;
  exp_child?: Maybe<Scalars["String"]>;
  exp_child_minding?: Maybe<Scalars["money"]>;
  exp_clean_consumable?: Maybe<Scalars["money"]>;
  exp_cleaning_costs?: Maybe<Scalars["money"]>;
  exp_electricity?: Maybe<Scalars["money"]>;
  exp_emergency_sys?: Maybe<Scalars["money"]>;
  exp_energy_mgmt?: Maybe<Scalars["money"]>;
  exp_escalators?: Maybe<Scalars["money"]>;
  exp_fire_levy?: Maybe<Scalars["money"]>;
  exp_fire_protection?: Maybe<Scalars["money"]>;
  exp_gas?: Maybe<Scalars["money"]>;
  exp_info_directories?: Maybe<Scalars["money"]>;
  exp_insurance?: Maybe<Scalars["money"]>;
  exp_land_tax?: Maybe<Scalars["money"]>;
  exp_landscape?: Maybe<Scalars["money"]>;
  exp_lift?: Maybe<Scalars["money"]>;
  exp_local_gov_charges?: Maybe<Scalars["money"]>;
  exp_mgmt_fee?: Maybe<Scalars["money"]>;
  exp_ohs?: Maybe<Scalars["money"]>;
  exp_oil?: Maybe<Scalars["money"]>;
  exp_other?: Maybe<Scalars["String"]>;
  exp_pest?: Maybe<Scalars["money"]>;
  exp_post_boxes?: Maybe<Scalars["money"]>;
  exp_public_address?: Maybe<Scalars["money"]>;
  exp_public_telephones?: Maybe<Scalars["money"]>;
  exp_repairs_and_maint?: Maybe<Scalars["money"]>;
  exp_security?: Maybe<Scalars["money"]>;
  exp_sewerage_disposal?: Maybe<Scalars["money"]>;
  exp_signage?: Maybe<Scalars["money"]>;
  exp_sinking_fund?: Maybe<Scalars["money"]>;
  exp_staff_toilet?: Maybe<Scalars["String"]>;
  exp_staff_wages?: Maybe<Scalars["money"]>;
  exp_strata?: Maybe<Scalars["money"]>;
  exp_traffic_flow?: Maybe<Scalars["money"]>;
  exp_uniforms?: Maybe<Scalars["money"]>;
  exp_ventilation?: Maybe<Scalars["money"]>;
  exp_water?: Maybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["money"]>;
  exp_water_sewage_rates?: Maybe<Scalars["money"]>;
  head_or_crown_lease?: Maybe<Scalars["String"]>;
  head_or_crown_lease_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: Maybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: Maybe<Scalars["timestamptz"]>;
  landlord_acn?: Maybe<Scalars["String"]>;
  landlord_address?: Maybe<Scalars["String"]>;
  landlord_fax?: Maybe<Scalars["String"]>;
  landlord_name?: Maybe<Scalars["String"]>;
  landlord_service_fac?: Maybe<Scalars["String"]>;
  lease_registration_fee?: Maybe<Scalars["money"]>;
  legal_procedure_premises?: Maybe<Scalars["String"]>;
  management_company?: Maybe<Scalars["String"]>;
  management_company_abn?: Maybe<Scalars["String"]>;
  management_company_address?: Maybe<Scalars["String"]>;
  management_company_fax?: Maybe<Scalars["String"]>;
  management_company_phone?: Maybe<Scalars["String"]>;
  management_company_postal?: Maybe<Scalars["String"]>;
  management_company_real_estate_licence?: Maybe<Scalars["String"]>;
  market_competitor?: Maybe<Scalars["String"]>;
  miscellaneous?: Maybe<Scalars["money"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
  num_shop?: Maybe<Scalars["String"]>;
  parking_space_num?: Maybe<Scalars["float8"]>;
  profile_year_start?: Maybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: Maybe<Scalars["String"]>;
  require_minister_consent_to_crown?: Maybe<Scalars["String"]>;
  require_mortgagee_consent?: Maybe<Scalars["String"]>;
  services_bin_room?: Maybe<Scalars["String"]>;
  services_common_clean?: Maybe<Scalars["String"]>;
  services_common_light?: Maybe<Scalars["String"]>;
  services_security?: Maybe<Scalars["String"]>;
  services_tenancy_clean?: Maybe<Scalars["String"]>;
  shops_leased_and_occupied?: Maybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: Maybe<Scalars["String"]>;
  site_area?: Maybe<Scalars["Int"]>;
  survey_fee_per_trans?: Maybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["money"]>;
  trade_hour_fri?: Maybe<Scalars["String"]>;
  trade_hour_holiday?: Maybe<Scalars["String"]>;
  trade_hour_mon?: Maybe<Scalars["String"]>;
  trade_hour_sat?: Maybe<Scalars["String"]>;
  trade_hour_sun?: Maybe<Scalars["String"]>;
  trade_hour_thur?: Maybe<Scalars["String"]>;
  trade_hour_tues?: Maybe<Scalars["String"]>;
  trade_hour_wed?: Maybe<Scalars["String"]>;
  turn_over_sat?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Mutation_Response = {
  __typename?: "YardiPropertyProfiles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiPropertyProfiles>;
};

/** input type for inserting object relation for remote table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Obj_Rel_Insert_Input = {
  data: YardiPropertyProfiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiPropertyProfiles_On_Conflict>;
};

/** on_conflict condition type for table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_On_Conflict = {
  constraint: YardiPropertyProfiles_Constraint;
  update_columns?: Array<YardiPropertyProfiles_Update_Column>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiPropertyProfiles". */
export type YardiPropertyProfiles_Order_By = {
  PropertyCode?: InputMaybe<Order_By>;
  SSOLICITORPOST?: InputMaybe<Order_By>;
  YardiProperty?: InputMaybe<YardiProperties_Order_By>;
  additional_property_comments?: InputMaybe<Order_By>;
  alteration?: InputMaybe<Order_By>;
  alteration_works?: InputMaybe<Order_By>;
  anchor_tenant_expiry?: InputMaybe<Order_By>;
  annual_gr_return_centre?: InputMaybe<Order_By>;
  annual_gr_return_food?: InputMaybe<Order_By>;
  annual_gr_return_nonfood?: InputMaybe<Order_By>;
  annual_gr_return_service?: InputMaybe<Order_By>;
  centre_exp?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_table_record_code?: InputMaybe<Order_By>;
  customer_parking_space_num?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  development?: InputMaybe<Order_By>;
  effect_of_obligations?: InputMaybe<Order_By>;
  exp_admin?: InputMaybe<Order_By>;
  exp_air_maint?: InputMaybe<Order_By>;
  exp_air_ops?: InputMaybe<Order_By>;
  exp_audit?: InputMaybe<Order_By>;
  exp_building_intelligence?: InputMaybe<Order_By>;
  exp_car_parking?: InputMaybe<Order_By>;
  exp_caretaking?: InputMaybe<Order_By>;
  exp_child?: InputMaybe<Order_By>;
  exp_child_minding?: InputMaybe<Order_By>;
  exp_clean_consumable?: InputMaybe<Order_By>;
  exp_cleaning_costs?: InputMaybe<Order_By>;
  exp_electricity?: InputMaybe<Order_By>;
  exp_emergency_sys?: InputMaybe<Order_By>;
  exp_energy_mgmt?: InputMaybe<Order_By>;
  exp_escalators?: InputMaybe<Order_By>;
  exp_fire_levy?: InputMaybe<Order_By>;
  exp_fire_protection?: InputMaybe<Order_By>;
  exp_gas?: InputMaybe<Order_By>;
  exp_info_directories?: InputMaybe<Order_By>;
  exp_insurance?: InputMaybe<Order_By>;
  exp_land_tax?: InputMaybe<Order_By>;
  exp_landscape?: InputMaybe<Order_By>;
  exp_lift?: InputMaybe<Order_By>;
  exp_local_gov_charges?: InputMaybe<Order_By>;
  exp_mgmt_fee?: InputMaybe<Order_By>;
  exp_ohs?: InputMaybe<Order_By>;
  exp_oil?: InputMaybe<Order_By>;
  exp_other?: InputMaybe<Order_By>;
  exp_pest?: InputMaybe<Order_By>;
  exp_post_boxes?: InputMaybe<Order_By>;
  exp_public_address?: InputMaybe<Order_By>;
  exp_public_telephones?: InputMaybe<Order_By>;
  exp_repairs_and_maint?: InputMaybe<Order_By>;
  exp_security?: InputMaybe<Order_By>;
  exp_sewerage_disposal?: InputMaybe<Order_By>;
  exp_signage?: InputMaybe<Order_By>;
  exp_sinking_fund?: InputMaybe<Order_By>;
  exp_staff_toilet?: InputMaybe<Order_By>;
  exp_staff_wages?: InputMaybe<Order_By>;
  exp_strata?: InputMaybe<Order_By>;
  exp_traffic_flow?: InputMaybe<Order_By>;
  exp_uniforms?: InputMaybe<Order_By>;
  exp_ventilation?: InputMaybe<Order_By>;
  exp_water?: InputMaybe<Order_By>;
  exp_water_collection_and_disposal?: InputMaybe<Order_By>;
  exp_water_sewage_rates?: InputMaybe<Order_By>;
  head_or_crown_lease?: InputMaybe<Order_By>;
  head_or_crown_lease_end?: InputMaybe<Order_By>;
  head_or_crown_lease_option_end?: InputMaybe<Order_By>;
  head_or_crown_lease_option_start?: InputMaybe<Order_By>;
  head_or_crown_lease_start?: InputMaybe<Order_By>;
  landlord_acn?: InputMaybe<Order_By>;
  landlord_address?: InputMaybe<Order_By>;
  landlord_fax?: InputMaybe<Order_By>;
  landlord_name?: InputMaybe<Order_By>;
  landlord_service_fac?: InputMaybe<Order_By>;
  lease_registration_fee?: InputMaybe<Order_By>;
  legal_procedure_premises?: InputMaybe<Order_By>;
  management_company?: InputMaybe<Order_By>;
  management_company_abn?: InputMaybe<Order_By>;
  management_company_address?: InputMaybe<Order_By>;
  management_company_fax?: InputMaybe<Order_By>;
  management_company_phone?: InputMaybe<Order_By>;
  management_company_postal?: InputMaybe<Order_By>;
  management_company_real_estate_licence?: InputMaybe<Order_By>;
  market_competitor?: InputMaybe<Order_By>;
  miscellaneous?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  num_shop?: InputMaybe<Order_By>;
  parking_space_num?: InputMaybe<Order_By>;
  profile_year_start?: InputMaybe<Order_By>;
  require_consent_to_lease?: InputMaybe<Order_By>;
  require_minister_consent_to_crown?: InputMaybe<Order_By>;
  require_mortgagee_consent?: InputMaybe<Order_By>;
  services_bin_room?: InputMaybe<Order_By>;
  services_common_clean?: InputMaybe<Order_By>;
  services_common_light?: InputMaybe<Order_By>;
  services_security?: InputMaybe<Order_By>;
  services_tenancy_clean?: InputMaybe<Order_By>;
  shops_leased_and_occupied?: InputMaybe<Order_By>;
  shops_leased_and_unoccupied?: InputMaybe<Order_By>;
  site_area?: InputMaybe<Order_By>;
  survey_fee_per_trans?: InputMaybe<Order_By>;
  tenancy_coord_fee_per_trans?: InputMaybe<Order_By>;
  trade_hour_fri?: InputMaybe<Order_By>;
  trade_hour_holiday?: InputMaybe<Order_By>;
  trade_hour_mon?: InputMaybe<Order_By>;
  trade_hour_sat?: InputMaybe<Order_By>;
  trade_hour_sun?: InputMaybe<Order_By>;
  trade_hour_thur?: InputMaybe<Order_By>;
  trade_hour_tues?: InputMaybe<Order_By>;
  trade_hour_wed?: InputMaybe<Order_By>;
  turn_over_sat?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiPropertyProfiles */
export type YardiPropertyProfiles_Pk_Columns_Input = {
  PropertyCode: Scalars["String"];
};

/** select columns of table "YardiPropertyProfiles" */
export enum YardiPropertyProfiles_Select_Column {
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Ssolicitorpost = "SSOLICITORPOST",
  /** column name */
  AdditionalPropertyComments = "additional_property_comments",
  /** column name */
  Alteration = "alteration",
  /** column name */
  AlterationWorks = "alteration_works",
  /** column name */
  AnchorTenantExpiry = "anchor_tenant_expiry",
  /** column name */
  AnnualGrReturnCentre = "annual_gr_return_centre",
  /** column name */
  AnnualGrReturnFood = "annual_gr_return_food",
  /** column name */
  AnnualGrReturnNonfood = "annual_gr_return_nonfood",
  /** column name */
  AnnualGrReturnService = "annual_gr_return_service",
  /** column name */
  CentreExp = "centre_exp",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomTableRecordCode = "custom_table_record_code",
  /** column name */
  CustomerParkingSpaceNum = "customer_parking_space_num",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Development = "development",
  /** column name */
  EffectOfObligations = "effect_of_obligations",
  /** column name */
  ExpAdmin = "exp_admin",
  /** column name */
  ExpAirMaint = "exp_air_maint",
  /** column name */
  ExpAirOps = "exp_air_ops",
  /** column name */
  ExpAudit = "exp_audit",
  /** column name */
  ExpBuildingIntelligence = "exp_building_intelligence",
  /** column name */
  ExpCarParking = "exp_car_parking",
  /** column name */
  ExpCaretaking = "exp_caretaking",
  /** column name */
  ExpChild = "exp_child",
  /** column name */
  ExpChildMinding = "exp_child_minding",
  /** column name */
  ExpCleanConsumable = "exp_clean_consumable",
  /** column name */
  ExpCleaningCosts = "exp_cleaning_costs",
  /** column name */
  ExpElectricity = "exp_electricity",
  /** column name */
  ExpEmergencySys = "exp_emergency_sys",
  /** column name */
  ExpEnergyMgmt = "exp_energy_mgmt",
  /** column name */
  ExpEscalators = "exp_escalators",
  /** column name */
  ExpFireLevy = "exp_fire_levy",
  /** column name */
  ExpFireProtection = "exp_fire_protection",
  /** column name */
  ExpGas = "exp_gas",
  /** column name */
  ExpInfoDirectories = "exp_info_directories",
  /** column name */
  ExpInsurance = "exp_insurance",
  /** column name */
  ExpLandTax = "exp_land_tax",
  /** column name */
  ExpLandscape = "exp_landscape",
  /** column name */
  ExpLift = "exp_lift",
  /** column name */
  ExpLocalGovCharges = "exp_local_gov_charges",
  /** column name */
  ExpMgmtFee = "exp_mgmt_fee",
  /** column name */
  ExpOhs = "exp_ohs",
  /** column name */
  ExpOil = "exp_oil",
  /** column name */
  ExpOther = "exp_other",
  /** column name */
  ExpPest = "exp_pest",
  /** column name */
  ExpPostBoxes = "exp_post_boxes",
  /** column name */
  ExpPublicAddress = "exp_public_address",
  /** column name */
  ExpPublicTelephones = "exp_public_telephones",
  /** column name */
  ExpRepairsAndMaint = "exp_repairs_and_maint",
  /** column name */
  ExpSecurity = "exp_security",
  /** column name */
  ExpSewerageDisposal = "exp_sewerage_disposal",
  /** column name */
  ExpSignage = "exp_signage",
  /** column name */
  ExpSinkingFund = "exp_sinking_fund",
  /** column name */
  ExpStaffToilet = "exp_staff_toilet",
  /** column name */
  ExpStaffWages = "exp_staff_wages",
  /** column name */
  ExpStrata = "exp_strata",
  /** column name */
  ExpTrafficFlow = "exp_traffic_flow",
  /** column name */
  ExpUniforms = "exp_uniforms",
  /** column name */
  ExpVentilation = "exp_ventilation",
  /** column name */
  ExpWater = "exp_water",
  /** column name */
  ExpWaterCollectionAndDisposal = "exp_water_collection_and_disposal",
  /** column name */
  ExpWaterSewageRates = "exp_water_sewage_rates",
  /** column name */
  HeadOrCrownLease = "head_or_crown_lease",
  /** column name */
  HeadOrCrownLeaseEnd = "head_or_crown_lease_end",
  /** column name */
  HeadOrCrownLeaseOptionEnd = "head_or_crown_lease_option_end",
  /** column name */
  HeadOrCrownLeaseOptionStart = "head_or_crown_lease_option_start",
  /** column name */
  HeadOrCrownLeaseStart = "head_or_crown_lease_start",
  /** column name */
  LandlordAcn = "landlord_acn",
  /** column name */
  LandlordAddress = "landlord_address",
  /** column name */
  LandlordFax = "landlord_fax",
  /** column name */
  LandlordName = "landlord_name",
  /** column name */
  LandlordServiceFac = "landlord_service_fac",
  /** column name */
  LeaseRegistrationFee = "lease_registration_fee",
  /** column name */
  LegalProcedurePremises = "legal_procedure_premises",
  /** column name */
  ManagementCompany = "management_company",
  /** column name */
  ManagementCompanyAbn = "management_company_abn",
  /** column name */
  ManagementCompanyAddress = "management_company_address",
  /** column name */
  ManagementCompanyFax = "management_company_fax",
  /** column name */
  ManagementCompanyPhone = "management_company_phone",
  /** column name */
  ManagementCompanyPostal = "management_company_postal",
  /** column name */
  ManagementCompanyRealEstateLicence = "management_company_real_estate_licence",
  /** column name */
  MarketCompetitor = "market_competitor",
  /** column name */
  Miscellaneous = "miscellaneous",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NumShop = "num_shop",
  /** column name */
  ParkingSpaceNum = "parking_space_num",
  /** column name */
  ProfileYearStart = "profile_year_start",
  /** column name */
  RequireConsentToLease = "require_consent_to_lease",
  /** column name */
  RequireMinisterConsentToCrown = "require_minister_consent_to_crown",
  /** column name */
  RequireMortgageeConsent = "require_mortgagee_consent",
  /** column name */
  ServicesBinRoom = "services_bin_room",
  /** column name */
  ServicesCommonClean = "services_common_clean",
  /** column name */
  ServicesCommonLight = "services_common_light",
  /** column name */
  ServicesSecurity = "services_security",
  /** column name */
  ServicesTenancyClean = "services_tenancy_clean",
  /** column name */
  ShopsLeasedAndOccupied = "shops_leased_and_occupied",
  /** column name */
  ShopsLeasedAndUnoccupied = "shops_leased_and_unoccupied",
  /** column name */
  SiteArea = "site_area",
  /** column name */
  SurveyFeePerTrans = "survey_fee_per_trans",
  /** column name */
  TenancyCoordFeePerTrans = "tenancy_coord_fee_per_trans",
  /** column name */
  TradeHourFri = "trade_hour_fri",
  /** column name */
  TradeHourHoliday = "trade_hour_holiday",
  /** column name */
  TradeHourMon = "trade_hour_mon",
  /** column name */
  TradeHourSat = "trade_hour_sat",
  /** column name */
  TradeHourSun = "trade_hour_sun",
  /** column name */
  TradeHourThur = "trade_hour_thur",
  /** column name */
  TradeHourTues = "trade_hour_tues",
  /** column name */
  TradeHourWed = "trade_hour_wed",
  /** column name */
  TurnOverSat = "turn_over_sat",
}

/** input type for updating data in table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Set_Input = {
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SSOLICITORPOST?: InputMaybe<Scalars["String"]>;
  additional_property_comments?: InputMaybe<Scalars["String"]>;
  alteration?: InputMaybe<Scalars["String"]>;
  alteration_works?: InputMaybe<Scalars["String"]>;
  anchor_tenant_expiry?: InputMaybe<Scalars["String"]>;
  annual_gr_return_centre?: InputMaybe<Scalars["money"]>;
  annual_gr_return_food?: InputMaybe<Scalars["money"]>;
  annual_gr_return_nonfood?: InputMaybe<Scalars["money"]>;
  annual_gr_return_service?: InputMaybe<Scalars["money"]>;
  centre_exp?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_table_record_code?: InputMaybe<Scalars["String"]>;
  customer_parking_space_num?: InputMaybe<Scalars["float8"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  development?: InputMaybe<Scalars["String"]>;
  effect_of_obligations?: InputMaybe<Scalars["String"]>;
  exp_admin?: InputMaybe<Scalars["money"]>;
  exp_air_maint?: InputMaybe<Scalars["money"]>;
  exp_air_ops?: InputMaybe<Scalars["money"]>;
  exp_audit?: InputMaybe<Scalars["money"]>;
  exp_building_intelligence?: InputMaybe<Scalars["money"]>;
  exp_car_parking?: InputMaybe<Scalars["money"]>;
  exp_caretaking?: InputMaybe<Scalars["money"]>;
  exp_child?: InputMaybe<Scalars["String"]>;
  exp_child_minding?: InputMaybe<Scalars["money"]>;
  exp_clean_consumable?: InputMaybe<Scalars["money"]>;
  exp_cleaning_costs?: InputMaybe<Scalars["money"]>;
  exp_electricity?: InputMaybe<Scalars["money"]>;
  exp_emergency_sys?: InputMaybe<Scalars["money"]>;
  exp_energy_mgmt?: InputMaybe<Scalars["money"]>;
  exp_escalators?: InputMaybe<Scalars["money"]>;
  exp_fire_levy?: InputMaybe<Scalars["money"]>;
  exp_fire_protection?: InputMaybe<Scalars["money"]>;
  exp_gas?: InputMaybe<Scalars["money"]>;
  exp_info_directories?: InputMaybe<Scalars["money"]>;
  exp_insurance?: InputMaybe<Scalars["money"]>;
  exp_land_tax?: InputMaybe<Scalars["money"]>;
  exp_landscape?: InputMaybe<Scalars["money"]>;
  exp_lift?: InputMaybe<Scalars["money"]>;
  exp_local_gov_charges?: InputMaybe<Scalars["money"]>;
  exp_mgmt_fee?: InputMaybe<Scalars["money"]>;
  exp_ohs?: InputMaybe<Scalars["money"]>;
  exp_oil?: InputMaybe<Scalars["money"]>;
  exp_other?: InputMaybe<Scalars["String"]>;
  exp_pest?: InputMaybe<Scalars["money"]>;
  exp_post_boxes?: InputMaybe<Scalars["money"]>;
  exp_public_address?: InputMaybe<Scalars["money"]>;
  exp_public_telephones?: InputMaybe<Scalars["money"]>;
  exp_repairs_and_maint?: InputMaybe<Scalars["money"]>;
  exp_security?: InputMaybe<Scalars["money"]>;
  exp_sewerage_disposal?: InputMaybe<Scalars["money"]>;
  exp_signage?: InputMaybe<Scalars["money"]>;
  exp_sinking_fund?: InputMaybe<Scalars["money"]>;
  exp_staff_toilet?: InputMaybe<Scalars["String"]>;
  exp_staff_wages?: InputMaybe<Scalars["money"]>;
  exp_strata?: InputMaybe<Scalars["money"]>;
  exp_traffic_flow?: InputMaybe<Scalars["money"]>;
  exp_uniforms?: InputMaybe<Scalars["money"]>;
  exp_ventilation?: InputMaybe<Scalars["money"]>;
  exp_water?: InputMaybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: InputMaybe<Scalars["money"]>;
  exp_water_sewage_rates?: InputMaybe<Scalars["money"]>;
  head_or_crown_lease?: InputMaybe<Scalars["String"]>;
  head_or_crown_lease_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: InputMaybe<Scalars["timestamptz"]>;
  landlord_acn?: InputMaybe<Scalars["String"]>;
  landlord_address?: InputMaybe<Scalars["String"]>;
  landlord_fax?: InputMaybe<Scalars["String"]>;
  landlord_name?: InputMaybe<Scalars["String"]>;
  landlord_service_fac?: InputMaybe<Scalars["String"]>;
  lease_registration_fee?: InputMaybe<Scalars["money"]>;
  legal_procedure_premises?: InputMaybe<Scalars["String"]>;
  management_company?: InputMaybe<Scalars["String"]>;
  management_company_abn?: InputMaybe<Scalars["String"]>;
  management_company_address?: InputMaybe<Scalars["String"]>;
  management_company_fax?: InputMaybe<Scalars["String"]>;
  management_company_phone?: InputMaybe<Scalars["String"]>;
  management_company_postal?: InputMaybe<Scalars["String"]>;
  management_company_real_estate_licence?: InputMaybe<Scalars["String"]>;
  market_competitor?: InputMaybe<Scalars["String"]>;
  miscellaneous?: InputMaybe<Scalars["money"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  num_shop?: InputMaybe<Scalars["String"]>;
  parking_space_num?: InputMaybe<Scalars["float8"]>;
  profile_year_start?: InputMaybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: InputMaybe<Scalars["String"]>;
  require_minister_consent_to_crown?: InputMaybe<Scalars["String"]>;
  require_mortgagee_consent?: InputMaybe<Scalars["String"]>;
  services_bin_room?: InputMaybe<Scalars["String"]>;
  services_common_clean?: InputMaybe<Scalars["String"]>;
  services_common_light?: InputMaybe<Scalars["String"]>;
  services_security?: InputMaybe<Scalars["String"]>;
  services_tenancy_clean?: InputMaybe<Scalars["String"]>;
  shops_leased_and_occupied?: InputMaybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: InputMaybe<Scalars["String"]>;
  site_area?: InputMaybe<Scalars["Int"]>;
  survey_fee_per_trans?: InputMaybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: InputMaybe<Scalars["money"]>;
  trade_hour_fri?: InputMaybe<Scalars["String"]>;
  trade_hour_holiday?: InputMaybe<Scalars["String"]>;
  trade_hour_mon?: InputMaybe<Scalars["String"]>;
  trade_hour_sat?: InputMaybe<Scalars["String"]>;
  trade_hour_sun?: InputMaybe<Scalars["String"]>;
  trade_hour_thur?: InputMaybe<Scalars["String"]>;
  trade_hour_tues?: InputMaybe<Scalars["String"]>;
  trade_hour_wed?: InputMaybe<Scalars["String"]>;
  turn_over_sat?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiPropertyProfiles_Stddev_Fields = {
  __typename?: "YardiPropertyProfiles_stddev_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type YardiPropertyProfiles_Stddev_Pop_Fields = {
  __typename?: "YardiPropertyProfiles_stddev_pop_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type YardiPropertyProfiles_Stddev_Samp_Fields = {
  __typename?: "YardiPropertyProfiles_stddev_samp_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "YardiPropertyProfiles" */
export type YardiPropertyProfiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiPropertyProfiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiPropertyProfiles_Stream_Cursor_Value_Input = {
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SSOLICITORPOST?: InputMaybe<Scalars["String"]>;
  additional_property_comments?: InputMaybe<Scalars["String"]>;
  alteration?: InputMaybe<Scalars["String"]>;
  alteration_works?: InputMaybe<Scalars["String"]>;
  anchor_tenant_expiry?: InputMaybe<Scalars["String"]>;
  annual_gr_return_centre?: InputMaybe<Scalars["money"]>;
  annual_gr_return_food?: InputMaybe<Scalars["money"]>;
  annual_gr_return_nonfood?: InputMaybe<Scalars["money"]>;
  annual_gr_return_service?: InputMaybe<Scalars["money"]>;
  centre_exp?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  custom_table_record_code?: InputMaybe<Scalars["String"]>;
  customer_parking_space_num?: InputMaybe<Scalars["float8"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  development?: InputMaybe<Scalars["String"]>;
  effect_of_obligations?: InputMaybe<Scalars["String"]>;
  exp_admin?: InputMaybe<Scalars["money"]>;
  exp_air_maint?: InputMaybe<Scalars["money"]>;
  exp_air_ops?: InputMaybe<Scalars["money"]>;
  exp_audit?: InputMaybe<Scalars["money"]>;
  exp_building_intelligence?: InputMaybe<Scalars["money"]>;
  exp_car_parking?: InputMaybe<Scalars["money"]>;
  exp_caretaking?: InputMaybe<Scalars["money"]>;
  exp_child?: InputMaybe<Scalars["String"]>;
  exp_child_minding?: InputMaybe<Scalars["money"]>;
  exp_clean_consumable?: InputMaybe<Scalars["money"]>;
  exp_cleaning_costs?: InputMaybe<Scalars["money"]>;
  exp_electricity?: InputMaybe<Scalars["money"]>;
  exp_emergency_sys?: InputMaybe<Scalars["money"]>;
  exp_energy_mgmt?: InputMaybe<Scalars["money"]>;
  exp_escalators?: InputMaybe<Scalars["money"]>;
  exp_fire_levy?: InputMaybe<Scalars["money"]>;
  exp_fire_protection?: InputMaybe<Scalars["money"]>;
  exp_gas?: InputMaybe<Scalars["money"]>;
  exp_info_directories?: InputMaybe<Scalars["money"]>;
  exp_insurance?: InputMaybe<Scalars["money"]>;
  exp_land_tax?: InputMaybe<Scalars["money"]>;
  exp_landscape?: InputMaybe<Scalars["money"]>;
  exp_lift?: InputMaybe<Scalars["money"]>;
  exp_local_gov_charges?: InputMaybe<Scalars["money"]>;
  exp_mgmt_fee?: InputMaybe<Scalars["money"]>;
  exp_ohs?: InputMaybe<Scalars["money"]>;
  exp_oil?: InputMaybe<Scalars["money"]>;
  exp_other?: InputMaybe<Scalars["String"]>;
  exp_pest?: InputMaybe<Scalars["money"]>;
  exp_post_boxes?: InputMaybe<Scalars["money"]>;
  exp_public_address?: InputMaybe<Scalars["money"]>;
  exp_public_telephones?: InputMaybe<Scalars["money"]>;
  exp_repairs_and_maint?: InputMaybe<Scalars["money"]>;
  exp_security?: InputMaybe<Scalars["money"]>;
  exp_sewerage_disposal?: InputMaybe<Scalars["money"]>;
  exp_signage?: InputMaybe<Scalars["money"]>;
  exp_sinking_fund?: InputMaybe<Scalars["money"]>;
  exp_staff_toilet?: InputMaybe<Scalars["String"]>;
  exp_staff_wages?: InputMaybe<Scalars["money"]>;
  exp_strata?: InputMaybe<Scalars["money"]>;
  exp_traffic_flow?: InputMaybe<Scalars["money"]>;
  exp_uniforms?: InputMaybe<Scalars["money"]>;
  exp_ventilation?: InputMaybe<Scalars["money"]>;
  exp_water?: InputMaybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: InputMaybe<Scalars["money"]>;
  exp_water_sewage_rates?: InputMaybe<Scalars["money"]>;
  head_or_crown_lease?: InputMaybe<Scalars["String"]>;
  head_or_crown_lease_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_end?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_option_start?: InputMaybe<Scalars["timestamptz"]>;
  head_or_crown_lease_start?: InputMaybe<Scalars["timestamptz"]>;
  landlord_acn?: InputMaybe<Scalars["String"]>;
  landlord_address?: InputMaybe<Scalars["String"]>;
  landlord_fax?: InputMaybe<Scalars["String"]>;
  landlord_name?: InputMaybe<Scalars["String"]>;
  landlord_service_fac?: InputMaybe<Scalars["String"]>;
  lease_registration_fee?: InputMaybe<Scalars["money"]>;
  legal_procedure_premises?: InputMaybe<Scalars["String"]>;
  management_company?: InputMaybe<Scalars["String"]>;
  management_company_abn?: InputMaybe<Scalars["String"]>;
  management_company_address?: InputMaybe<Scalars["String"]>;
  management_company_fax?: InputMaybe<Scalars["String"]>;
  management_company_phone?: InputMaybe<Scalars["String"]>;
  management_company_postal?: InputMaybe<Scalars["String"]>;
  management_company_real_estate_licence?: InputMaybe<Scalars["String"]>;
  market_competitor?: InputMaybe<Scalars["String"]>;
  miscellaneous?: InputMaybe<Scalars["money"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
  num_shop?: InputMaybe<Scalars["String"]>;
  parking_space_num?: InputMaybe<Scalars["float8"]>;
  profile_year_start?: InputMaybe<Scalars["timestamptz"]>;
  require_consent_to_lease?: InputMaybe<Scalars["String"]>;
  require_minister_consent_to_crown?: InputMaybe<Scalars["String"]>;
  require_mortgagee_consent?: InputMaybe<Scalars["String"]>;
  services_bin_room?: InputMaybe<Scalars["String"]>;
  services_common_clean?: InputMaybe<Scalars["String"]>;
  services_common_light?: InputMaybe<Scalars["String"]>;
  services_security?: InputMaybe<Scalars["String"]>;
  services_tenancy_clean?: InputMaybe<Scalars["String"]>;
  shops_leased_and_occupied?: InputMaybe<Scalars["String"]>;
  shops_leased_and_unoccupied?: InputMaybe<Scalars["String"]>;
  site_area?: InputMaybe<Scalars["Int"]>;
  survey_fee_per_trans?: InputMaybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: InputMaybe<Scalars["money"]>;
  trade_hour_fri?: InputMaybe<Scalars["String"]>;
  trade_hour_holiday?: InputMaybe<Scalars["String"]>;
  trade_hour_mon?: InputMaybe<Scalars["String"]>;
  trade_hour_sat?: InputMaybe<Scalars["String"]>;
  trade_hour_sun?: InputMaybe<Scalars["String"]>;
  trade_hour_thur?: InputMaybe<Scalars["String"]>;
  trade_hour_tues?: InputMaybe<Scalars["String"]>;
  trade_hour_wed?: InputMaybe<Scalars["String"]>;
  turn_over_sat?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiPropertyProfiles_Sum_Fields = {
  __typename?: "YardiPropertyProfiles_sum_fields";
  annual_gr_return_centre?: Maybe<Scalars["money"]>;
  annual_gr_return_food?: Maybe<Scalars["money"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["money"]>;
  annual_gr_return_service?: Maybe<Scalars["money"]>;
  customer_parking_space_num?: Maybe<Scalars["float8"]>;
  exp_admin?: Maybe<Scalars["money"]>;
  exp_air_maint?: Maybe<Scalars["money"]>;
  exp_air_ops?: Maybe<Scalars["money"]>;
  exp_audit?: Maybe<Scalars["money"]>;
  exp_building_intelligence?: Maybe<Scalars["money"]>;
  exp_car_parking?: Maybe<Scalars["money"]>;
  exp_caretaking?: Maybe<Scalars["money"]>;
  exp_child_minding?: Maybe<Scalars["money"]>;
  exp_clean_consumable?: Maybe<Scalars["money"]>;
  exp_cleaning_costs?: Maybe<Scalars["money"]>;
  exp_electricity?: Maybe<Scalars["money"]>;
  exp_emergency_sys?: Maybe<Scalars["money"]>;
  exp_energy_mgmt?: Maybe<Scalars["money"]>;
  exp_escalators?: Maybe<Scalars["money"]>;
  exp_fire_levy?: Maybe<Scalars["money"]>;
  exp_fire_protection?: Maybe<Scalars["money"]>;
  exp_gas?: Maybe<Scalars["money"]>;
  exp_info_directories?: Maybe<Scalars["money"]>;
  exp_insurance?: Maybe<Scalars["money"]>;
  exp_land_tax?: Maybe<Scalars["money"]>;
  exp_landscape?: Maybe<Scalars["money"]>;
  exp_lift?: Maybe<Scalars["money"]>;
  exp_local_gov_charges?: Maybe<Scalars["money"]>;
  exp_mgmt_fee?: Maybe<Scalars["money"]>;
  exp_ohs?: Maybe<Scalars["money"]>;
  exp_oil?: Maybe<Scalars["money"]>;
  exp_pest?: Maybe<Scalars["money"]>;
  exp_post_boxes?: Maybe<Scalars["money"]>;
  exp_public_address?: Maybe<Scalars["money"]>;
  exp_public_telephones?: Maybe<Scalars["money"]>;
  exp_repairs_and_maint?: Maybe<Scalars["money"]>;
  exp_security?: Maybe<Scalars["money"]>;
  exp_sewerage_disposal?: Maybe<Scalars["money"]>;
  exp_signage?: Maybe<Scalars["money"]>;
  exp_sinking_fund?: Maybe<Scalars["money"]>;
  exp_staff_wages?: Maybe<Scalars["money"]>;
  exp_strata?: Maybe<Scalars["money"]>;
  exp_traffic_flow?: Maybe<Scalars["money"]>;
  exp_uniforms?: Maybe<Scalars["money"]>;
  exp_ventilation?: Maybe<Scalars["money"]>;
  exp_water?: Maybe<Scalars["money"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["money"]>;
  exp_water_sewage_rates?: Maybe<Scalars["money"]>;
  lease_registration_fee?: Maybe<Scalars["money"]>;
  miscellaneous?: Maybe<Scalars["money"]>;
  parking_space_num?: Maybe<Scalars["float8"]>;
  site_area?: Maybe<Scalars["Int"]>;
  survey_fee_per_trans?: Maybe<Scalars["money"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["money"]>;
};

/** update columns of table "YardiPropertyProfiles" */
export enum YardiPropertyProfiles_Update_Column {
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Ssolicitorpost = "SSOLICITORPOST",
  /** column name */
  AdditionalPropertyComments = "additional_property_comments",
  /** column name */
  Alteration = "alteration",
  /** column name */
  AlterationWorks = "alteration_works",
  /** column name */
  AnchorTenantExpiry = "anchor_tenant_expiry",
  /** column name */
  AnnualGrReturnCentre = "annual_gr_return_centre",
  /** column name */
  AnnualGrReturnFood = "annual_gr_return_food",
  /** column name */
  AnnualGrReturnNonfood = "annual_gr_return_nonfood",
  /** column name */
  AnnualGrReturnService = "annual_gr_return_service",
  /** column name */
  CentreExp = "centre_exp",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CustomTableRecordCode = "custom_table_record_code",
  /** column name */
  CustomerParkingSpaceNum = "customer_parking_space_num",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Development = "development",
  /** column name */
  EffectOfObligations = "effect_of_obligations",
  /** column name */
  ExpAdmin = "exp_admin",
  /** column name */
  ExpAirMaint = "exp_air_maint",
  /** column name */
  ExpAirOps = "exp_air_ops",
  /** column name */
  ExpAudit = "exp_audit",
  /** column name */
  ExpBuildingIntelligence = "exp_building_intelligence",
  /** column name */
  ExpCarParking = "exp_car_parking",
  /** column name */
  ExpCaretaking = "exp_caretaking",
  /** column name */
  ExpChild = "exp_child",
  /** column name */
  ExpChildMinding = "exp_child_minding",
  /** column name */
  ExpCleanConsumable = "exp_clean_consumable",
  /** column name */
  ExpCleaningCosts = "exp_cleaning_costs",
  /** column name */
  ExpElectricity = "exp_electricity",
  /** column name */
  ExpEmergencySys = "exp_emergency_sys",
  /** column name */
  ExpEnergyMgmt = "exp_energy_mgmt",
  /** column name */
  ExpEscalators = "exp_escalators",
  /** column name */
  ExpFireLevy = "exp_fire_levy",
  /** column name */
  ExpFireProtection = "exp_fire_protection",
  /** column name */
  ExpGas = "exp_gas",
  /** column name */
  ExpInfoDirectories = "exp_info_directories",
  /** column name */
  ExpInsurance = "exp_insurance",
  /** column name */
  ExpLandTax = "exp_land_tax",
  /** column name */
  ExpLandscape = "exp_landscape",
  /** column name */
  ExpLift = "exp_lift",
  /** column name */
  ExpLocalGovCharges = "exp_local_gov_charges",
  /** column name */
  ExpMgmtFee = "exp_mgmt_fee",
  /** column name */
  ExpOhs = "exp_ohs",
  /** column name */
  ExpOil = "exp_oil",
  /** column name */
  ExpOther = "exp_other",
  /** column name */
  ExpPest = "exp_pest",
  /** column name */
  ExpPostBoxes = "exp_post_boxes",
  /** column name */
  ExpPublicAddress = "exp_public_address",
  /** column name */
  ExpPublicTelephones = "exp_public_telephones",
  /** column name */
  ExpRepairsAndMaint = "exp_repairs_and_maint",
  /** column name */
  ExpSecurity = "exp_security",
  /** column name */
  ExpSewerageDisposal = "exp_sewerage_disposal",
  /** column name */
  ExpSignage = "exp_signage",
  /** column name */
  ExpSinkingFund = "exp_sinking_fund",
  /** column name */
  ExpStaffToilet = "exp_staff_toilet",
  /** column name */
  ExpStaffWages = "exp_staff_wages",
  /** column name */
  ExpStrata = "exp_strata",
  /** column name */
  ExpTrafficFlow = "exp_traffic_flow",
  /** column name */
  ExpUniforms = "exp_uniforms",
  /** column name */
  ExpVentilation = "exp_ventilation",
  /** column name */
  ExpWater = "exp_water",
  /** column name */
  ExpWaterCollectionAndDisposal = "exp_water_collection_and_disposal",
  /** column name */
  ExpWaterSewageRates = "exp_water_sewage_rates",
  /** column name */
  HeadOrCrownLease = "head_or_crown_lease",
  /** column name */
  HeadOrCrownLeaseEnd = "head_or_crown_lease_end",
  /** column name */
  HeadOrCrownLeaseOptionEnd = "head_or_crown_lease_option_end",
  /** column name */
  HeadOrCrownLeaseOptionStart = "head_or_crown_lease_option_start",
  /** column name */
  HeadOrCrownLeaseStart = "head_or_crown_lease_start",
  /** column name */
  LandlordAcn = "landlord_acn",
  /** column name */
  LandlordAddress = "landlord_address",
  /** column name */
  LandlordFax = "landlord_fax",
  /** column name */
  LandlordName = "landlord_name",
  /** column name */
  LandlordServiceFac = "landlord_service_fac",
  /** column name */
  LeaseRegistrationFee = "lease_registration_fee",
  /** column name */
  LegalProcedurePremises = "legal_procedure_premises",
  /** column name */
  ManagementCompany = "management_company",
  /** column name */
  ManagementCompanyAbn = "management_company_abn",
  /** column name */
  ManagementCompanyAddress = "management_company_address",
  /** column name */
  ManagementCompanyFax = "management_company_fax",
  /** column name */
  ManagementCompanyPhone = "management_company_phone",
  /** column name */
  ManagementCompanyPostal = "management_company_postal",
  /** column name */
  ManagementCompanyRealEstateLicence = "management_company_real_estate_licence",
  /** column name */
  MarketCompetitor = "market_competitor",
  /** column name */
  Miscellaneous = "miscellaneous",
  /** column name */
  ModifiedAt = "modified_at",
  /** column name */
  NumShop = "num_shop",
  /** column name */
  ParkingSpaceNum = "parking_space_num",
  /** column name */
  ProfileYearStart = "profile_year_start",
  /** column name */
  RequireConsentToLease = "require_consent_to_lease",
  /** column name */
  RequireMinisterConsentToCrown = "require_minister_consent_to_crown",
  /** column name */
  RequireMortgageeConsent = "require_mortgagee_consent",
  /** column name */
  ServicesBinRoom = "services_bin_room",
  /** column name */
  ServicesCommonClean = "services_common_clean",
  /** column name */
  ServicesCommonLight = "services_common_light",
  /** column name */
  ServicesSecurity = "services_security",
  /** column name */
  ServicesTenancyClean = "services_tenancy_clean",
  /** column name */
  ShopsLeasedAndOccupied = "shops_leased_and_occupied",
  /** column name */
  ShopsLeasedAndUnoccupied = "shops_leased_and_unoccupied",
  /** column name */
  SiteArea = "site_area",
  /** column name */
  SurveyFeePerTrans = "survey_fee_per_trans",
  /** column name */
  TenancyCoordFeePerTrans = "tenancy_coord_fee_per_trans",
  /** column name */
  TradeHourFri = "trade_hour_fri",
  /** column name */
  TradeHourHoliday = "trade_hour_holiday",
  /** column name */
  TradeHourMon = "trade_hour_mon",
  /** column name */
  TradeHourSat = "trade_hour_sat",
  /** column name */
  TradeHourSun = "trade_hour_sun",
  /** column name */
  TradeHourThur = "trade_hour_thur",
  /** column name */
  TradeHourTues = "trade_hour_tues",
  /** column name */
  TradeHourWed = "trade_hour_wed",
  /** column name */
  TurnOverSat = "turn_over_sat",
}

export type YardiPropertyProfiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiPropertyProfiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiPropertyProfiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiPropertyProfiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiPropertyProfiles_Var_Pop_Fields = {
  __typename?: "YardiPropertyProfiles_var_pop_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type YardiPropertyProfiles_Var_Samp_Fields = {
  __typename?: "YardiPropertyProfiles_var_samp_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type YardiPropertyProfiles_Variance_Fields = {
  __typename?: "YardiPropertyProfiles_variance_fields";
  annual_gr_return_centre?: Maybe<Scalars["Float"]>;
  annual_gr_return_food?: Maybe<Scalars["Float"]>;
  annual_gr_return_nonfood?: Maybe<Scalars["Float"]>;
  annual_gr_return_service?: Maybe<Scalars["Float"]>;
  customer_parking_space_num?: Maybe<Scalars["Float"]>;
  exp_admin?: Maybe<Scalars["Float"]>;
  exp_air_maint?: Maybe<Scalars["Float"]>;
  exp_air_ops?: Maybe<Scalars["Float"]>;
  exp_audit?: Maybe<Scalars["Float"]>;
  exp_building_intelligence?: Maybe<Scalars["Float"]>;
  exp_car_parking?: Maybe<Scalars["Float"]>;
  exp_caretaking?: Maybe<Scalars["Float"]>;
  exp_child_minding?: Maybe<Scalars["Float"]>;
  exp_clean_consumable?: Maybe<Scalars["Float"]>;
  exp_cleaning_costs?: Maybe<Scalars["Float"]>;
  exp_electricity?: Maybe<Scalars["Float"]>;
  exp_emergency_sys?: Maybe<Scalars["Float"]>;
  exp_energy_mgmt?: Maybe<Scalars["Float"]>;
  exp_escalators?: Maybe<Scalars["Float"]>;
  exp_fire_levy?: Maybe<Scalars["Float"]>;
  exp_fire_protection?: Maybe<Scalars["Float"]>;
  exp_gas?: Maybe<Scalars["Float"]>;
  exp_info_directories?: Maybe<Scalars["Float"]>;
  exp_insurance?: Maybe<Scalars["Float"]>;
  exp_land_tax?: Maybe<Scalars["Float"]>;
  exp_landscape?: Maybe<Scalars["Float"]>;
  exp_lift?: Maybe<Scalars["Float"]>;
  exp_local_gov_charges?: Maybe<Scalars["Float"]>;
  exp_mgmt_fee?: Maybe<Scalars["Float"]>;
  exp_ohs?: Maybe<Scalars["Float"]>;
  exp_oil?: Maybe<Scalars["Float"]>;
  exp_pest?: Maybe<Scalars["Float"]>;
  exp_post_boxes?: Maybe<Scalars["Float"]>;
  exp_public_address?: Maybe<Scalars["Float"]>;
  exp_public_telephones?: Maybe<Scalars["Float"]>;
  exp_repairs_and_maint?: Maybe<Scalars["Float"]>;
  exp_security?: Maybe<Scalars["Float"]>;
  exp_sewerage_disposal?: Maybe<Scalars["Float"]>;
  exp_signage?: Maybe<Scalars["Float"]>;
  exp_sinking_fund?: Maybe<Scalars["Float"]>;
  exp_staff_wages?: Maybe<Scalars["Float"]>;
  exp_strata?: Maybe<Scalars["Float"]>;
  exp_traffic_flow?: Maybe<Scalars["Float"]>;
  exp_uniforms?: Maybe<Scalars["Float"]>;
  exp_ventilation?: Maybe<Scalars["Float"]>;
  exp_water?: Maybe<Scalars["Float"]>;
  exp_water_collection_and_disposal?: Maybe<Scalars["Float"]>;
  exp_water_sewage_rates?: Maybe<Scalars["Float"]>;
  lease_registration_fee?: Maybe<Scalars["Float"]>;
  miscellaneous?: Maybe<Scalars["Float"]>;
  parking_space_num?: Maybe<Scalars["Float"]>;
  site_area?: Maybe<Scalars["Float"]>;
  survey_fee_per_trans?: Maybe<Scalars["Float"]>;
  tenancy_coord_fee_per_trans?: Maybe<Scalars["Float"]>;
};

/** Mapping of tenants to units */
export type YardiTenantToUnits = {
  __typename?: "YardiTenantToUnits";
  OccupancyEndDate?: Maybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode: Scalars["String"];
  UnitKey: Scalars["String"];
  /** An object relationship */
  YardiTenant: YardiTenants;
  /** An object relationship */
  YardiUnit: YardiUnits;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** aggregated selection of "YardiTenantToUnits" */
export type YardiTenantToUnits_Aggregate = {
  __typename?: "YardiTenantToUnits_aggregate";
  aggregate?: Maybe<YardiTenantToUnits_Aggregate_Fields>;
  nodes: Array<YardiTenantToUnits>;
};

export type YardiTenantToUnits_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiTenantToUnits_Aggregate_Bool_Exp_Count>;
};

export type YardiTenantToUnits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiTenantToUnits" */
export type YardiTenantToUnits_Aggregate_Fields = {
  __typename?: "YardiTenantToUnits_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiTenantToUnits_Max_Fields>;
  min?: Maybe<YardiTenantToUnits_Min_Fields>;
};

/** aggregate fields of "YardiTenantToUnits" */
export type YardiTenantToUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiTenantToUnits" */
export type YardiTenantToUnits_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiTenantToUnits_Max_Order_By>;
  min?: InputMaybe<YardiTenantToUnits_Min_Order_By>;
};

/** input type for inserting array relation for remote table "YardiTenantToUnits" */
export type YardiTenantToUnits_Arr_Rel_Insert_Input = {
  data: Array<YardiTenantToUnits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiTenantToUnits_On_Conflict>;
};

/** Boolean expression to filter rows from the table "YardiTenantToUnits". All fields are combined with a logical 'AND'. */
export type YardiTenantToUnits_Bool_Exp = {
  OccupancyEndDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OccupancyStartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  UnitKey?: InputMaybe<String_Comparison_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  YardiUnit?: InputMaybe<YardiUnits_Bool_Exp>;
  _and?: InputMaybe<Array<YardiTenantToUnits_Bool_Exp>>;
  _not?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
  _or?: InputMaybe<Array<YardiTenantToUnits_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiTenantToUnits" */
export enum YardiTenantToUnits_Constraint {
  /** unique or primary key constraint on columns "TenantCode", "UnitKey" */
  YardiTenantToUnitsPkey = "YardiTenantToUnits_pkey",
}

/** input type for inserting data into table "YardiTenantToUnits" */
export type YardiTenantToUnits_Insert_Input = {
  OccupancyEndDate?: InputMaybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  YardiUnit?: InputMaybe<YardiUnits_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiTenantToUnits_Max_Fields = {
  __typename?: "YardiTenantToUnits_max_fields";
  OccupancyEndDate?: Maybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiTenantToUnits" */
export type YardiTenantToUnits_Max_Order_By = {
  OccupancyEndDate?: InputMaybe<Order_By>;
  OccupancyStartDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiTenantToUnits_Min_Fields = {
  __typename?: "YardiTenantToUnits_min_fields";
  OccupancyEndDate?: Maybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiTenantToUnits" */
export type YardiTenantToUnits_Min_Order_By = {
  OccupancyEndDate?: InputMaybe<Order_By>;
  OccupancyStartDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiTenantToUnits" */
export type YardiTenantToUnits_Mutation_Response = {
  __typename?: "YardiTenantToUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiTenantToUnits>;
};

/** on_conflict condition type for table "YardiTenantToUnits" */
export type YardiTenantToUnits_On_Conflict = {
  constraint: YardiTenantToUnits_Constraint;
  update_columns?: Array<YardiTenantToUnits_Update_Column>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiTenantToUnits". */
export type YardiTenantToUnits_Order_By = {
  OccupancyEndDate?: InputMaybe<Order_By>;
  OccupancyStartDate?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  YardiUnit?: InputMaybe<YardiUnits_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiTenantToUnits */
export type YardiTenantToUnits_Pk_Columns_Input = {
  TenantCode: Scalars["String"];
  UnitKey: Scalars["String"];
};

/** select columns of table "YardiTenantToUnits" */
export enum YardiTenantToUnits_Select_Column {
  /** column name */
  OccupancyEndDate = "OccupancyEndDate",
  /** column name */
  OccupancyStartDate = "OccupancyStartDate",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiTenantToUnits" */
export type YardiTenantToUnits_Set_Input = {
  OccupancyEndDate?: InputMaybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiTenantToUnits" */
export type YardiTenantToUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiTenantToUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiTenantToUnits_Stream_Cursor_Value_Input = {
  OccupancyEndDate?: InputMaybe<Scalars["timestamptz"]>;
  OccupancyStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiTenantToUnits" */
export enum YardiTenantToUnits_Update_Column {
  /** column name */
  OccupancyEndDate = "OccupancyEndDate",
  /** column name */
  OccupancyStartDate = "OccupancyStartDate",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiTenantToUnits_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiTenantToUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiTenantToUnits_Bool_Exp;
};

/** columns and relationships of "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts = {
  __typename?: "YardiTenant_YardiContacts";
  ContactCode: Scalars["String"];
  TenantCode: Scalars["String"];
  /** An object relationship */
  YardiContact: YardiContacts;
  /** An object relationship */
  YardiTenant: YardiTenants;
  created_at: Scalars["timestamptz"];
};

/** aggregated selection of "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Aggregate = {
  __typename?: "YardiTenant_YardiContacts_aggregate";
  aggregate?: Maybe<YardiTenant_YardiContacts_Aggregate_Fields>;
  nodes: Array<YardiTenant_YardiContacts>;
};

/** aggregate fields of "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Aggregate_Fields = {
  __typename?: "YardiTenant_YardiContacts_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiTenant_YardiContacts_Max_Fields>;
  min?: Maybe<YardiTenant_YardiContacts_Min_Fields>;
};

/** aggregate fields of "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiTenant_YardiContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "YardiTenant_YardiContacts". All fields are combined with a logical 'AND'. */
export type YardiTenant_YardiContacts_Bool_Exp = {
  ContactCode?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  YardiContact?: InputMaybe<YardiContacts_Bool_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  _and?: InputMaybe<Array<YardiTenant_YardiContacts_Bool_Exp>>;
  _not?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
  _or?: InputMaybe<Array<YardiTenant_YardiContacts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiTenant_YardiContacts" */
export enum YardiTenant_YardiContacts_Constraint {
  /** unique or primary key constraint on columns "ContactCode", "TenantCode" */
  YardiTenantYardiContactsPkey = "YardiTenant_YardiContacts_pkey",
}

/** input type for inserting data into table "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Insert_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  YardiContact?: InputMaybe<YardiContacts_Obj_Rel_Insert_Input>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiTenant_YardiContacts_Max_Fields = {
  __typename?: "YardiTenant_YardiContacts_max_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type YardiTenant_YardiContacts_Min_Fields = {
  __typename?: "YardiTenant_YardiContacts_min_fields";
  ContactCode?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Mutation_Response = {
  __typename?: "YardiTenant_YardiContacts_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiTenant_YardiContacts>;
};

/** on_conflict condition type for table "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_On_Conflict = {
  constraint: YardiTenant_YardiContacts_Constraint;
  update_columns?: Array<YardiTenant_YardiContacts_Update_Column>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiTenant_YardiContacts". */
export type YardiTenant_YardiContacts_Order_By = {
  ContactCode?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  YardiContact?: InputMaybe<YardiContacts_Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiTenant_YardiContacts */
export type YardiTenant_YardiContacts_Pk_Columns_Input = {
  ContactCode: Scalars["String"];
  TenantCode: Scalars["String"];
};

/** select columns of table "YardiTenant_YardiContacts" */
export enum YardiTenant_YardiContacts_Select_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  CreatedAt = "created_at",
}

/** input type for updating data in table "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Set_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiTenant_YardiContacts" */
export type YardiTenant_YardiContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiTenant_YardiContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiTenant_YardiContacts_Stream_Cursor_Value_Input = {
  ContactCode?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiTenant_YardiContacts" */
export enum YardiTenant_YardiContacts_Update_Column {
  /** column name */
  ContactCode = "ContactCode",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  CreatedAt = "created_at",
}

export type YardiTenant_YardiContacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiTenant_YardiContacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiTenant_YardiContacts_Bool_Exp;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenants = {
  __typename?: "YardiTenants";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  /** An array relationship */
  Deals: Array<Deals>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode: Scalars["String"];
  TenantEndDate?: Maybe<Scalars["timestamptz"]>;
  TenantField1?: Maybe<Scalars["String"]>;
  TenantField2?: Maybe<Scalars["String"]>;
  TenantField3?: Maybe<Scalars["String"]>;
  TenantField4?: Maybe<Scalars["String"]>;
  TenantField5?: Maybe<Scalars["String"]>;
  TenantField6?: Maybe<Scalars["String"]>;
  TenantField7?: Maybe<Scalars["String"]>;
  TenantField8?: Maybe<Scalars["String"]>;
  TenantField9?: Maybe<Scalars["String"]>;
  TenantField10?: Maybe<Scalars["String"]>;
  TenantField11?: Maybe<Scalars["String"]>;
  TenantField12?: Maybe<Scalars["String"]>;
  TenantField13?: Maybe<Scalars["String"]>;
  TenantField14?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  /** An array relationship */
  YardiAmendments: Array<YardiAmendments>;
  /** An aggregate relationship */
  YardiAmendments_aggregate: YardiAmendments_Aggregate;
  /** An array relationship */
  YardiContacts: Array<YardiContacts>;
  /** An aggregate relationship */
  YardiContacts_aggregate: YardiContacts_Aggregate;
  /** An object relationship */
  YardiProperty?: Maybe<YardiProperties>;
  /** An array relationship */
  YardiTenants_Deals: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_Deals_aggregate: YardiTenants_Deals_Aggregate;
  /** An array relationship */
  YardiUnits: Array<YardiUnits>;
  /** An aggregate relationship */
  YardiUnits_aggregate: YardiUnits_Aggregate;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiAmendmentsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiAmendments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiTenants_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiTenants_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

/** columns and relationships of "YardiTenants" */
export type YardiTenantsYardiUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

/** columns and relationships of "YardiTenants_Deals" */
export type YardiTenants_Deals = {
  __typename?: "YardiTenants_Deals";
  /** An object relationship */
  Deal: Deals;
  TenantCode: Scalars["String"];
  /** An object relationship */
  YardiActiveLeases?: Maybe<YardiActiveLeases>;
  /** An object relationship */
  YardiTenant: YardiTenants;
  created_at: Scalars["timestamptz"];
  deal_id: Scalars["bigint"];
};

/** aggregated selection of "YardiTenants_Deals" */
export type YardiTenants_Deals_Aggregate = {
  __typename?: "YardiTenants_Deals_aggregate";
  aggregate?: Maybe<YardiTenants_Deals_Aggregate_Fields>;
  nodes: Array<YardiTenants_Deals>;
};

export type YardiTenants_Deals_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiTenants_Deals_Aggregate_Bool_Exp_Count>;
};

export type YardiTenants_Deals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiTenants_Deals" */
export type YardiTenants_Deals_Aggregate_Fields = {
  __typename?: "YardiTenants_Deals_aggregate_fields";
  avg?: Maybe<YardiTenants_Deals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiTenants_Deals_Max_Fields>;
  min?: Maybe<YardiTenants_Deals_Min_Fields>;
  stddev?: Maybe<YardiTenants_Deals_Stddev_Fields>;
  stddev_pop?: Maybe<YardiTenants_Deals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiTenants_Deals_Stddev_Samp_Fields>;
  sum?: Maybe<YardiTenants_Deals_Sum_Fields>;
  var_pop?: Maybe<YardiTenants_Deals_Var_Pop_Fields>;
  var_samp?: Maybe<YardiTenants_Deals_Var_Samp_Fields>;
  variance?: Maybe<YardiTenants_Deals_Variance_Fields>;
};

/** aggregate fields of "YardiTenants_Deals" */
export type YardiTenants_Deals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Aggregate_Order_By = {
  avg?: InputMaybe<YardiTenants_Deals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiTenants_Deals_Max_Order_By>;
  min?: InputMaybe<YardiTenants_Deals_Min_Order_By>;
  stddev?: InputMaybe<YardiTenants_Deals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiTenants_Deals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiTenants_Deals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiTenants_Deals_Sum_Order_By>;
  var_pop?: InputMaybe<YardiTenants_Deals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiTenants_Deals_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiTenants_Deals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiTenants_Deals" */
export type YardiTenants_Deals_Arr_Rel_Insert_Input = {
  data: Array<YardiTenants_Deals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiTenants_Deals_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiTenants_Deals_Avg_Fields = {
  __typename?: "YardiTenants_Deals_avg_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiTenants_Deals". All fields are combined with a logical 'AND'. */
export type YardiTenants_Deals_Bool_Exp = {
  Deal?: InputMaybe<Deals_Bool_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  YardiActiveLeases?: InputMaybe<YardiActiveLeases_Bool_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  _and?: InputMaybe<Array<YardiTenants_Deals_Bool_Exp>>;
  _not?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
  _or?: InputMaybe<Array<YardiTenants_Deals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiTenants_Deals" */
export enum YardiTenants_Deals_Constraint {
  /** unique or primary key constraint on columns "deal_id", "TenantCode" */
  YardiTenantsDealsPkey = "YardiTenants_Deals_pkey",
}

/** input type for incrementing numeric columns in table "YardiTenants_Deals" */
export type YardiTenants_Deals_Inc_Input = {
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiTenants_Deals" */
export type YardiTenants_Deals_Insert_Input = {
  Deal?: InputMaybe<Deals_Obj_Rel_Insert_Input>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  YardiActiveLeases?: InputMaybe<YardiActiveLeases_Obj_Rel_Insert_Input>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type YardiTenants_Deals_Max_Fields = {
  __typename?: "YardiTenants_Deals_max_fields";
  TenantCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Max_Order_By = {
  TenantCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiTenants_Deals_Min_Fields = {
  __typename?: "YardiTenants_Deals_min_fields";
  TenantCode?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Min_Order_By = {
  TenantCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiTenants_Deals" */
export type YardiTenants_Deals_Mutation_Response = {
  __typename?: "YardiTenants_Deals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiTenants_Deals>;
};

/** on_conflict condition type for table "YardiTenants_Deals" */
export type YardiTenants_Deals_On_Conflict = {
  constraint: YardiTenants_Deals_Constraint;
  update_columns?: Array<YardiTenants_Deals_Update_Column>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiTenants_Deals". */
export type YardiTenants_Deals_Order_By = {
  Deal?: InputMaybe<Deals_Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  YardiActiveLeases?: InputMaybe<YardiActiveLeases_Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiTenants_Deals */
export type YardiTenants_Deals_Pk_Columns_Input = {
  TenantCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** select columns of table "YardiTenants_Deals" */
export enum YardiTenants_Deals_Select_Column {
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

/** input type for updating data in table "YardiTenants_Deals" */
export type YardiTenants_Deals_Set_Input = {
  TenantCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type YardiTenants_Deals_Stddev_Fields = {
  __typename?: "YardiTenants_Deals_stddev_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiTenants_Deals_Stddev_Pop_Fields = {
  __typename?: "YardiTenants_Deals_stddev_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiTenants_Deals_Stddev_Samp_Fields = {
  __typename?: "YardiTenants_Deals_stddev_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiTenants_Deals" */
export type YardiTenants_Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiTenants_Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiTenants_Deals_Stream_Cursor_Value_Input = {
  TenantCode?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deal_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type YardiTenants_Deals_Sum_Fields = {
  __typename?: "YardiTenants_Deals_sum_fields";
  deal_id?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** update columns of table "YardiTenants_Deals" */
export enum YardiTenants_Deals_Update_Column {
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DealId = "deal_id",
}

export type YardiTenants_Deals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiTenants_Deals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiTenants_Deals_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiTenants_Deals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiTenants_Deals_Var_Pop_Fields = {
  __typename?: "YardiTenants_Deals_var_pop_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiTenants_Deals_Var_Samp_Fields = {
  __typename?: "YardiTenants_Deals_var_samp_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiTenants_Deals_Variance_Fields = {
  __typename?: "YardiTenants_Deals_variance_fields";
  deal_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiTenants_Deals" */
export type YardiTenants_Deals_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "YardiTenants" */
export type YardiTenants_Aggregate = {
  __typename?: "YardiTenants_aggregate";
  aggregate?: Maybe<YardiTenants_Aggregate_Fields>;
  nodes: Array<YardiTenants>;
};

export type YardiTenants_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiTenants_Aggregate_Bool_Exp_Count>;
};

export type YardiTenants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiTenants_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiTenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiTenants" */
export type YardiTenants_Aggregate_Fields = {
  __typename?: "YardiTenants_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<YardiTenants_Max_Fields>;
  min?: Maybe<YardiTenants_Min_Fields>;
};

/** aggregate fields of "YardiTenants" */
export type YardiTenants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiTenants_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiTenants" */
export type YardiTenants_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiTenants_Max_Order_By>;
  min?: InputMaybe<YardiTenants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "YardiTenants" */
export type YardiTenants_Arr_Rel_Insert_Input = {
  data: Array<YardiTenants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiTenants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "YardiTenants". All fields are combined with a logical 'AND'. */
export type YardiTenants_Bool_Exp = {
  Anchor?: InputMaybe<String_Comparison_Exp>;
  BaseRent?: InputMaybe<String_Comparison_Exp>;
  BrandCode?: InputMaybe<String_Comparison_Exp>;
  CustomerCode?: InputMaybe<String_Comparison_Exp>;
  Deals?: InputMaybe<Deals_Bool_Exp>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Bool_Exp>;
  IsHoldover?: InputMaybe<String_Comparison_Exp>;
  LeaseType?: InputMaybe<String_Comparison_Exp>;
  MaintenanceNotes?: InputMaybe<String_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  Outgoings?: InputMaybe<String_Comparison_Exp>;
  PromoLevy?: InputMaybe<String_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  SalesCategory?: InputMaybe<String_Comparison_Exp>;
  TIC?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  TenantEndDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantField1?: InputMaybe<String_Comparison_Exp>;
  TenantField2?: InputMaybe<String_Comparison_Exp>;
  TenantField3?: InputMaybe<String_Comparison_Exp>;
  TenantField4?: InputMaybe<String_Comparison_Exp>;
  TenantField5?: InputMaybe<String_Comparison_Exp>;
  TenantField6?: InputMaybe<String_Comparison_Exp>;
  TenantField7?: InputMaybe<String_Comparison_Exp>;
  TenantField8?: InputMaybe<String_Comparison_Exp>;
  TenantField9?: InputMaybe<String_Comparison_Exp>;
  TenantField10?: InputMaybe<String_Comparison_Exp>;
  TenantField11?: InputMaybe<String_Comparison_Exp>;
  TenantField12?: InputMaybe<String_Comparison_Exp>;
  TenantField13?: InputMaybe<String_Comparison_Exp>;
  TenantField14?: InputMaybe<String_Comparison_Exp>;
  TenantName?: InputMaybe<String_Comparison_Exp>;
  TenantStartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TenantStatus?: InputMaybe<String_Comparison_Exp>;
  TradeName?: InputMaybe<String_Comparison_Exp>;
  YardiAmendments?: InputMaybe<YardiAmendments_Bool_Exp>;
  YardiAmendments_aggregate?: InputMaybe<YardiAmendments_Aggregate_Bool_Exp>;
  YardiContacts?: InputMaybe<YardiContacts_Bool_Exp>;
  YardiContacts_aggregate?: InputMaybe<YardiContacts_Aggregate_Bool_Exp>;
  YardiProperty?: InputMaybe<YardiProperties_Bool_Exp>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Bool_Exp>;
  YardiUnits?: InputMaybe<YardiUnits_Bool_Exp>;
  YardiUnits_aggregate?: InputMaybe<YardiUnits_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<YardiTenants_Bool_Exp>>;
  _not?: InputMaybe<YardiTenants_Bool_Exp>;
  _or?: InputMaybe<Array<YardiTenants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiTenants" */
export enum YardiTenants_Constraint {
  /** unique or primary key constraint on columns "TenantCode" */
  YardiTenantsPkey = "YardiTenants_pkey",
}

/** input type for inserting data into table "YardiTenants" */
export type YardiTenants_Insert_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  Deals?: InputMaybe<Deals_Arr_Rel_Insert_Input>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantEndDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantField1?: InputMaybe<Scalars["String"]>;
  TenantField2?: InputMaybe<Scalars["String"]>;
  TenantField3?: InputMaybe<Scalars["String"]>;
  TenantField4?: InputMaybe<Scalars["String"]>;
  TenantField5?: InputMaybe<Scalars["String"]>;
  TenantField6?: InputMaybe<Scalars["String"]>;
  TenantField7?: InputMaybe<Scalars["String"]>;
  TenantField8?: InputMaybe<Scalars["String"]>;
  TenantField9?: InputMaybe<Scalars["String"]>;
  TenantField10?: InputMaybe<Scalars["String"]>;
  TenantField11?: InputMaybe<Scalars["String"]>;
  TenantField12?: InputMaybe<Scalars["String"]>;
  TenantField13?: InputMaybe<Scalars["String"]>;
  TenantField14?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  YardiAmendments?: InputMaybe<YardiAmendments_Arr_Rel_Insert_Input>;
  YardiContacts?: InputMaybe<YardiContacts_Arr_Rel_Insert_Input>;
  YardiProperty?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  YardiTenants_Deals?: InputMaybe<YardiTenants_Deals_Arr_Rel_Insert_Input>;
  YardiUnits?: InputMaybe<YardiUnits_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiTenants_Max_Fields = {
  __typename?: "YardiTenants_max_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantEndDate?: Maybe<Scalars["timestamptz"]>;
  TenantField1?: Maybe<Scalars["String"]>;
  TenantField2?: Maybe<Scalars["String"]>;
  TenantField3?: Maybe<Scalars["String"]>;
  TenantField4?: Maybe<Scalars["String"]>;
  TenantField5?: Maybe<Scalars["String"]>;
  TenantField6?: Maybe<Scalars["String"]>;
  TenantField7?: Maybe<Scalars["String"]>;
  TenantField8?: Maybe<Scalars["String"]>;
  TenantField9?: Maybe<Scalars["String"]>;
  TenantField10?: Maybe<Scalars["String"]>;
  TenantField11?: Maybe<Scalars["String"]>;
  TenantField12?: Maybe<Scalars["String"]>;
  TenantField13?: Maybe<Scalars["String"]>;
  TenantField14?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiTenants" */
export type YardiTenants_Max_Order_By = {
  Anchor?: InputMaybe<Order_By>;
  BaseRent?: InputMaybe<Order_By>;
  BrandCode?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  IsHoldover?: InputMaybe<Order_By>;
  LeaseType?: InputMaybe<Order_By>;
  MaintenanceNotes?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  Outgoings?: InputMaybe<Order_By>;
  PromoLevy?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  SalesCategory?: InputMaybe<Order_By>;
  TIC?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  TenantEndDate?: InputMaybe<Order_By>;
  TenantField1?: InputMaybe<Order_By>;
  TenantField2?: InputMaybe<Order_By>;
  TenantField3?: InputMaybe<Order_By>;
  TenantField4?: InputMaybe<Order_By>;
  TenantField5?: InputMaybe<Order_By>;
  TenantField6?: InputMaybe<Order_By>;
  TenantField7?: InputMaybe<Order_By>;
  TenantField8?: InputMaybe<Order_By>;
  TenantField9?: InputMaybe<Order_By>;
  TenantField10?: InputMaybe<Order_By>;
  TenantField11?: InputMaybe<Order_By>;
  TenantField12?: InputMaybe<Order_By>;
  TenantField13?: InputMaybe<Order_By>;
  TenantField14?: InputMaybe<Order_By>;
  TenantName?: InputMaybe<Order_By>;
  TenantStartDate?: InputMaybe<Order_By>;
  TenantStatus?: InputMaybe<Order_By>;
  TradeName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiTenants_Min_Fields = {
  __typename?: "YardiTenants_min_fields";
  Anchor?: Maybe<Scalars["String"]>;
  BaseRent?: Maybe<Scalars["String"]>;
  BrandCode?: Maybe<Scalars["String"]>;
  CustomerCode?: Maybe<Scalars["String"]>;
  IsHoldover?: Maybe<Scalars["String"]>;
  LeaseType?: Maybe<Scalars["String"]>;
  MaintenanceNotes?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Outgoings?: Maybe<Scalars["String"]>;
  PromoLevy?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  SalesCategory?: Maybe<Scalars["String"]>;
  TIC?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  TenantEndDate?: Maybe<Scalars["timestamptz"]>;
  TenantField1?: Maybe<Scalars["String"]>;
  TenantField2?: Maybe<Scalars["String"]>;
  TenantField3?: Maybe<Scalars["String"]>;
  TenantField4?: Maybe<Scalars["String"]>;
  TenantField5?: Maybe<Scalars["String"]>;
  TenantField6?: Maybe<Scalars["String"]>;
  TenantField7?: Maybe<Scalars["String"]>;
  TenantField8?: Maybe<Scalars["String"]>;
  TenantField9?: Maybe<Scalars["String"]>;
  TenantField10?: Maybe<Scalars["String"]>;
  TenantField11?: Maybe<Scalars["String"]>;
  TenantField12?: Maybe<Scalars["String"]>;
  TenantField13?: Maybe<Scalars["String"]>;
  TenantField14?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantStartDate?: Maybe<Scalars["timestamptz"]>;
  TenantStatus?: Maybe<Scalars["String"]>;
  TradeName?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiTenants" */
export type YardiTenants_Min_Order_By = {
  Anchor?: InputMaybe<Order_By>;
  BaseRent?: InputMaybe<Order_By>;
  BrandCode?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  IsHoldover?: InputMaybe<Order_By>;
  LeaseType?: InputMaybe<Order_By>;
  MaintenanceNotes?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  Outgoings?: InputMaybe<Order_By>;
  PromoLevy?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  SalesCategory?: InputMaybe<Order_By>;
  TIC?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  TenantEndDate?: InputMaybe<Order_By>;
  TenantField1?: InputMaybe<Order_By>;
  TenantField2?: InputMaybe<Order_By>;
  TenantField3?: InputMaybe<Order_By>;
  TenantField4?: InputMaybe<Order_By>;
  TenantField5?: InputMaybe<Order_By>;
  TenantField6?: InputMaybe<Order_By>;
  TenantField7?: InputMaybe<Order_By>;
  TenantField8?: InputMaybe<Order_By>;
  TenantField9?: InputMaybe<Order_By>;
  TenantField10?: InputMaybe<Order_By>;
  TenantField11?: InputMaybe<Order_By>;
  TenantField12?: InputMaybe<Order_By>;
  TenantField13?: InputMaybe<Order_By>;
  TenantField14?: InputMaybe<Order_By>;
  TenantName?: InputMaybe<Order_By>;
  TenantStartDate?: InputMaybe<Order_By>;
  TenantStatus?: InputMaybe<Order_By>;
  TradeName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiTenants" */
export type YardiTenants_Mutation_Response = {
  __typename?: "YardiTenants_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiTenants>;
};

/** input type for inserting object relation for remote table "YardiTenants" */
export type YardiTenants_Obj_Rel_Insert_Input = {
  data: YardiTenants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiTenants_On_Conflict>;
};

/** on_conflict condition type for table "YardiTenants" */
export type YardiTenants_On_Conflict = {
  constraint: YardiTenants_Constraint;
  update_columns?: Array<YardiTenants_Update_Column>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiTenants". */
export type YardiTenants_Order_By = {
  Anchor?: InputMaybe<Order_By>;
  BaseRent?: InputMaybe<Order_By>;
  BrandCode?: InputMaybe<Order_By>;
  CustomerCode?: InputMaybe<Order_By>;
  Deals_aggregate?: InputMaybe<Deals_Aggregate_Order_By>;
  IsHoldover?: InputMaybe<Order_By>;
  LeaseType?: InputMaybe<Order_By>;
  MaintenanceNotes?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  Outgoings?: InputMaybe<Order_By>;
  PromoLevy?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  SalesCategory?: InputMaybe<Order_By>;
  TIC?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  TenantEndDate?: InputMaybe<Order_By>;
  TenantField1?: InputMaybe<Order_By>;
  TenantField2?: InputMaybe<Order_By>;
  TenantField3?: InputMaybe<Order_By>;
  TenantField4?: InputMaybe<Order_By>;
  TenantField5?: InputMaybe<Order_By>;
  TenantField6?: InputMaybe<Order_By>;
  TenantField7?: InputMaybe<Order_By>;
  TenantField8?: InputMaybe<Order_By>;
  TenantField9?: InputMaybe<Order_By>;
  TenantField10?: InputMaybe<Order_By>;
  TenantField11?: InputMaybe<Order_By>;
  TenantField12?: InputMaybe<Order_By>;
  TenantField13?: InputMaybe<Order_By>;
  TenantField14?: InputMaybe<Order_By>;
  TenantName?: InputMaybe<Order_By>;
  TenantStartDate?: InputMaybe<Order_By>;
  TenantStatus?: InputMaybe<Order_By>;
  TradeName?: InputMaybe<Order_By>;
  YardiAmendments_aggregate?: InputMaybe<YardiAmendments_Aggregate_Order_By>;
  YardiContacts_aggregate?: InputMaybe<YardiContacts_Aggregate_Order_By>;
  YardiProperty?: InputMaybe<YardiProperties_Order_By>;
  YardiTenants_Deals_aggregate?: InputMaybe<YardiTenants_Deals_Aggregate_Order_By>;
  YardiUnits_aggregate?: InputMaybe<YardiUnits_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiTenants */
export type YardiTenants_Pk_Columns_Input = {
  TenantCode: Scalars["String"];
};

/** select columns of table "YardiTenants" */
export enum YardiTenants_Select_Column {
  /** column name */
  Anchor = "Anchor",
  /** column name */
  BaseRent = "BaseRent",
  /** column name */
  BrandCode = "BrandCode",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  IsHoldover = "IsHoldover",
  /** column name */
  LeaseType = "LeaseType",
  /** column name */
  MaintenanceNotes = "MaintenanceNotes",
  /** column name */
  Notes = "Notes",
  /** column name */
  Outgoings = "Outgoings",
  /** column name */
  PromoLevy = "PromoLevy",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  SalesCategory = "SalesCategory",
  /** column name */
  Tic = "TIC",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  TenantEndDate = "TenantEndDate",
  /** column name */
  TenantField1 = "TenantField1",
  /** column name */
  TenantField2 = "TenantField2",
  /** column name */
  TenantField3 = "TenantField3",
  /** column name */
  TenantField4 = "TenantField4",
  /** column name */
  TenantField5 = "TenantField5",
  /** column name */
  TenantField6 = "TenantField6",
  /** column name */
  TenantField7 = "TenantField7",
  /** column name */
  TenantField8 = "TenantField8",
  /** column name */
  TenantField9 = "TenantField9",
  /** column name */
  TenantField10 = "TenantField10",
  /** column name */
  TenantField11 = "TenantField11",
  /** column name */
  TenantField12 = "TenantField12",
  /** column name */
  TenantField13 = "TenantField13",
  /** column name */
  TenantField14 = "TenantField14",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantStartDate = "TenantStartDate",
  /** column name */
  TenantStatus = "TenantStatus",
  /** column name */
  TradeName = "TradeName",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiTenants" */
export type YardiTenants_Set_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantEndDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantField1?: InputMaybe<Scalars["String"]>;
  TenantField2?: InputMaybe<Scalars["String"]>;
  TenantField3?: InputMaybe<Scalars["String"]>;
  TenantField4?: InputMaybe<Scalars["String"]>;
  TenantField5?: InputMaybe<Scalars["String"]>;
  TenantField6?: InputMaybe<Scalars["String"]>;
  TenantField7?: InputMaybe<Scalars["String"]>;
  TenantField8?: InputMaybe<Scalars["String"]>;
  TenantField9?: InputMaybe<Scalars["String"]>;
  TenantField10?: InputMaybe<Scalars["String"]>;
  TenantField11?: InputMaybe<Scalars["String"]>;
  TenantField12?: InputMaybe<Scalars["String"]>;
  TenantField13?: InputMaybe<Scalars["String"]>;
  TenantField14?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "YardiTenants" */
export type YardiTenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiTenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiTenants_Stream_Cursor_Value_Input = {
  Anchor?: InputMaybe<Scalars["String"]>;
  BaseRent?: InputMaybe<Scalars["String"]>;
  BrandCode?: InputMaybe<Scalars["String"]>;
  CustomerCode?: InputMaybe<Scalars["String"]>;
  IsHoldover?: InputMaybe<Scalars["String"]>;
  LeaseType?: InputMaybe<Scalars["String"]>;
  MaintenanceNotes?: InputMaybe<Scalars["String"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  Outgoings?: InputMaybe<Scalars["String"]>;
  PromoLevy?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  SalesCategory?: InputMaybe<Scalars["String"]>;
  TIC?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  TenantEndDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantField1?: InputMaybe<Scalars["String"]>;
  TenantField2?: InputMaybe<Scalars["String"]>;
  TenantField3?: InputMaybe<Scalars["String"]>;
  TenantField4?: InputMaybe<Scalars["String"]>;
  TenantField5?: InputMaybe<Scalars["String"]>;
  TenantField6?: InputMaybe<Scalars["String"]>;
  TenantField7?: InputMaybe<Scalars["String"]>;
  TenantField8?: InputMaybe<Scalars["String"]>;
  TenantField9?: InputMaybe<Scalars["String"]>;
  TenantField10?: InputMaybe<Scalars["String"]>;
  TenantField11?: InputMaybe<Scalars["String"]>;
  TenantField12?: InputMaybe<Scalars["String"]>;
  TenantField13?: InputMaybe<Scalars["String"]>;
  TenantField14?: InputMaybe<Scalars["String"]>;
  TenantName?: InputMaybe<Scalars["String"]>;
  TenantStartDate?: InputMaybe<Scalars["timestamptz"]>;
  TenantStatus?: InputMaybe<Scalars["String"]>;
  TradeName?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "YardiTenants" */
export enum YardiTenants_Update_Column {
  /** column name */
  Anchor = "Anchor",
  /** column name */
  BaseRent = "BaseRent",
  /** column name */
  BrandCode = "BrandCode",
  /** column name */
  CustomerCode = "CustomerCode",
  /** column name */
  IsHoldover = "IsHoldover",
  /** column name */
  LeaseType = "LeaseType",
  /** column name */
  MaintenanceNotes = "MaintenanceNotes",
  /** column name */
  Notes = "Notes",
  /** column name */
  Outgoings = "Outgoings",
  /** column name */
  PromoLevy = "PromoLevy",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  SalesCategory = "SalesCategory",
  /** column name */
  Tic = "TIC",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  TenantEndDate = "TenantEndDate",
  /** column name */
  TenantField1 = "TenantField1",
  /** column name */
  TenantField2 = "TenantField2",
  /** column name */
  TenantField3 = "TenantField3",
  /** column name */
  TenantField4 = "TenantField4",
  /** column name */
  TenantField5 = "TenantField5",
  /** column name */
  TenantField6 = "TenantField6",
  /** column name */
  TenantField7 = "TenantField7",
  /** column name */
  TenantField8 = "TenantField8",
  /** column name */
  TenantField9 = "TenantField9",
  /** column name */
  TenantField10 = "TenantField10",
  /** column name */
  TenantField11 = "TenantField11",
  /** column name */
  TenantField12 = "TenantField12",
  /** column name */
  TenantField13 = "TenantField13",
  /** column name */
  TenantField14 = "TenantField14",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantStartDate = "TenantStartDate",
  /** column name */
  TenantStatus = "TenantStatus",
  /** column name */
  TradeName = "TradeName",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiTenants_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiTenants_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiTenants_Bool_Exp;
};

/** columns and relationships of "YardiUnitProfiles" */
export type YardiUnitProfiles = {
  __typename?: "YardiUnitProfiles";
  BUDGETEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: Maybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: Maybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: Maybe<Scalars["String"]>;
  BUDGETVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode: Scalars["String"];
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["Int"]>;
  FINCENTIVE?: Maybe<Scalars["numeric"]>;
  FLEASESTART?: Maybe<Scalars["timestamptz"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["numeric"]>;
  FMAKEGOOD?: Maybe<Scalars["numeric"]>;
  FOGPSM?: Maybe<Scalars["numeric"]>;
  FPROMO?: Maybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["numeric"]>;
  PCAPOCCCOST?: Maybe<Scalars["numeric"]>;
  POGPSM?: Maybe<Scalars["numeric"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["numeric"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  PropertyCode: Scalars["String"];
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  UnitCode: Scalars["String"];
  UnitKey?: Maybe<Scalars["String"]>;
  UnitProfileKey: Scalars["String"];
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  /** An object relationship */
  YardiProperty: YardiProperties;
  /** An object relationship */
  YardiUnit?: Maybe<YardiUnits>;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiUnitProfiles" */
export type YardiUnitProfilesDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "YardiUnitProfiles" */
export type YardiUnitProfilesDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** aggregated selection of "YardiUnitProfiles" */
export type YardiUnitProfiles_Aggregate = {
  __typename?: "YardiUnitProfiles_aggregate";
  aggregate?: Maybe<YardiUnitProfiles_Aggregate_Fields>;
  nodes: Array<YardiUnitProfiles>;
};

export type YardiUnitProfiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiUnitProfiles_Aggregate_Bool_Exp_Count>;
};

export type YardiUnitProfiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiUnitProfiles" */
export type YardiUnitProfiles_Aggregate_Fields = {
  __typename?: "YardiUnitProfiles_aggregate_fields";
  avg?: Maybe<YardiUnitProfiles_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiUnitProfiles_Max_Fields>;
  min?: Maybe<YardiUnitProfiles_Min_Fields>;
  stddev?: Maybe<YardiUnitProfiles_Stddev_Fields>;
  stddev_pop?: Maybe<YardiUnitProfiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiUnitProfiles_Stddev_Samp_Fields>;
  sum?: Maybe<YardiUnitProfiles_Sum_Fields>;
  var_pop?: Maybe<YardiUnitProfiles_Var_Pop_Fields>;
  var_samp?: Maybe<YardiUnitProfiles_Var_Samp_Fields>;
  variance?: Maybe<YardiUnitProfiles_Variance_Fields>;
};

/** aggregate fields of "YardiUnitProfiles" */
export type YardiUnitProfiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Aggregate_Order_By = {
  avg?: InputMaybe<YardiUnitProfiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiUnitProfiles_Max_Order_By>;
  min?: InputMaybe<YardiUnitProfiles_Min_Order_By>;
  stddev?: InputMaybe<YardiUnitProfiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiUnitProfiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiUnitProfiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiUnitProfiles_Sum_Order_By>;
  var_pop?: InputMaybe<YardiUnitProfiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiUnitProfiles_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiUnitProfiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiUnitProfiles" */
export type YardiUnitProfiles_Arr_Rel_Insert_Input = {
  data: Array<YardiUnitProfiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiUnitProfiles_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiUnitProfiles_Avg_Fields = {
  __typename?: "YardiUnitProfiles_avg_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Avg_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiUnitProfiles". All fields are combined with a logical 'AND'. */
export type YardiUnitProfiles_Bool_Exp = {
  BUDGETEXPIRYDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  BUDGETFITOUTDAYS?: InputMaybe<Numeric_Comparison_Exp>;
  BUDGETLEASESTART?: InputMaybe<Timestamptz_Comparison_Exp>;
  BUDGETSTATUS?: InputMaybe<String_Comparison_Exp>;
  BUDGETVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  CustomTableRecordCode?: InputMaybe<String_Comparison_Exp>;
  DANNUALPROMOLEVY?: InputMaybe<Numeric_Comparison_Exp>;
  DANNUALRECOVERYPSM?: InputMaybe<Numeric_Comparison_Exp>;
  DANNUALRENT?: InputMaybe<Numeric_Comparison_Exp>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Numeric_Comparison_Exp>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Numeric_Comparison_Exp>;
  DBUDGETMAKEGOOD?: InputMaybe<Numeric_Comparison_Exp>;
  DTBUDGETLEASEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  DTPROFILEYEARSTART?: InputMaybe<Timestamptz_Comparison_Exp>;
  DealsUnits?: InputMaybe<DealsUnits_Bool_Exp>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Bool_Exp>;
  FBASERENT?: InputMaybe<Numeric_Comparison_Exp>;
  FEXPIRYDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  FFITOUTDAYS?: InputMaybe<Int_Comparison_Exp>;
  FINCENTIVE?: InputMaybe<Numeric_Comparison_Exp>;
  FLEASESTART?: InputMaybe<Timestamptz_Comparison_Exp>;
  FLEASETYPE?: InputMaybe<String_Comparison_Exp>;
  FLESSORWORKS?: InputMaybe<Numeric_Comparison_Exp>;
  FMAKEGOOD?: InputMaybe<Numeric_Comparison_Exp>;
  FOGPSM?: InputMaybe<Numeric_Comparison_Exp>;
  FPROMO?: InputMaybe<Numeric_Comparison_Exp>;
  FRENTCOMMENCE?: InputMaybe<Timestamptz_Comparison_Exp>;
  FSTATUS?: InputMaybe<String_Comparison_Exp>;
  FVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  INHOUSENETRENT?: InputMaybe<Numeric_Comparison_Exp>;
  PAREA?: InputMaybe<Numeric_Comparison_Exp>;
  PBASERENT?: InputMaybe<Numeric_Comparison_Exp>;
  PCAPOCCCOST?: InputMaybe<Numeric_Comparison_Exp>;
  POGPSM?: InputMaybe<Numeric_Comparison_Exp>;
  POWNERNAME?: InputMaybe<String_Comparison_Exp>;
  PPROMO?: InputMaybe<Numeric_Comparison_Exp>;
  PTENANT?: InputMaybe<String_Comparison_Exp>;
  PTENANTNAME?: InputMaybe<String_Comparison_Exp>;
  PVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<String_Comparison_Exp>;
  SECTION1?: InputMaybe<String_Comparison_Exp>;
  SECTION2?: InputMaybe<String_Comparison_Exp>;
  SECTION3?: InputMaybe<String_Comparison_Exp>;
  SECTION4?: InputMaybe<String_Comparison_Exp>;
  SECTION5?: InputMaybe<String_Comparison_Exp>;
  SMGMTFEE?: InputMaybe<Numeric_Comparison_Exp>;
  UnitCode?: InputMaybe<String_Comparison_Exp>;
  UnitKey?: InputMaybe<String_Comparison_Exp>;
  UnitProfileKey?: InputMaybe<String_Comparison_Exp>;
  VALUATIONNETRENT?: InputMaybe<Numeric_Comparison_Exp>;
  YardiProperty?: InputMaybe<YardiProperties_Bool_Exp>;
  YardiUnit?: InputMaybe<YardiUnits_Bool_Exp>;
  _and?: InputMaybe<Array<YardiUnitProfiles_Bool_Exp>>;
  _not?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  _or?: InputMaybe<Array<YardiUnitProfiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiUnitProfiles" */
export enum YardiUnitProfiles_Constraint {
  /** unique or primary key constraint on columns "UnitProfileKey" */
  YardiUnitProfilesPkey = "YardiUnitProfiles_pkey",
}

/** input type for incrementing numeric columns in table "YardiUnitProfiles" */
export type YardiUnitProfiles_Inc_Input = {
  BUDGETFITOUTDAYS?: InputMaybe<Scalars["numeric"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["Int"]>;
  FINCENTIVE?: InputMaybe<Scalars["numeric"]>;
  FLESSORWORKS?: InputMaybe<Scalars["numeric"]>;
  FMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  FOGPSM?: InputMaybe<Scalars["numeric"]>;
  FPROMO?: InputMaybe<Scalars["numeric"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["numeric"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["numeric"]>;
  POGPSM?: InputMaybe<Scalars["numeric"]>;
  PPROMO?: InputMaybe<Scalars["numeric"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "YardiUnitProfiles" */
export type YardiUnitProfiles_Insert_Input = {
  BUDGETEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: InputMaybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: InputMaybe<Scalars["String"]>;
  BUDGETVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: InputMaybe<Scalars["timestamptz"]>;
  DealsUnits?: InputMaybe<DealsUnits_Arr_Rel_Insert_Input>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["Int"]>;
  FINCENTIVE?: InputMaybe<Scalars["numeric"]>;
  FLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["numeric"]>;
  FMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  FOGPSM?: InputMaybe<Scalars["numeric"]>;
  FPROMO?: InputMaybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["numeric"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["numeric"]>;
  POGPSM?: InputMaybe<Scalars["numeric"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["numeric"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitProfileKey?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  YardiProperty?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  YardiUnit?: InputMaybe<YardiUnits_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiUnitProfiles_Max_Fields = {
  __typename?: "YardiUnitProfiles_max_fields";
  BUDGETEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: Maybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: Maybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: Maybe<Scalars["String"]>;
  BUDGETVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: Maybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: Maybe<Scalars["timestamptz"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["Int"]>;
  FINCENTIVE?: Maybe<Scalars["numeric"]>;
  FLEASESTART?: Maybe<Scalars["timestamptz"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["numeric"]>;
  FMAKEGOOD?: Maybe<Scalars["numeric"]>;
  FOGPSM?: Maybe<Scalars["numeric"]>;
  FPROMO?: Maybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["numeric"]>;
  PCAPOCCCOST?: Maybe<Scalars["numeric"]>;
  POGPSM?: Maybe<Scalars["numeric"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["numeric"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitProfileKey?: Maybe<Scalars["String"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Max_Order_By = {
  BUDGETEXPIRYDATE?: InputMaybe<Order_By>;
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  BUDGETLEASESTART?: InputMaybe<Order_By>;
  BUDGETSTATUS?: InputMaybe<Order_By>;
  BUDGETVACATEDATE?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTPROFILEYEARSTART?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitProfileKey?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiUnitProfiles_Min_Fields = {
  __typename?: "YardiUnitProfiles_min_fields";
  BUDGETEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: Maybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: Maybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: Maybe<Scalars["String"]>;
  BUDGETVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: Maybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: Maybe<Scalars["timestamptz"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["Int"]>;
  FINCENTIVE?: Maybe<Scalars["numeric"]>;
  FLEASESTART?: Maybe<Scalars["timestamptz"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["numeric"]>;
  FMAKEGOOD?: Maybe<Scalars["numeric"]>;
  FOGPSM?: Maybe<Scalars["numeric"]>;
  FPROMO?: Maybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["numeric"]>;
  PCAPOCCCOST?: Maybe<Scalars["numeric"]>;
  POGPSM?: Maybe<Scalars["numeric"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["numeric"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitProfileKey?: Maybe<Scalars["String"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Min_Order_By = {
  BUDGETEXPIRYDATE?: InputMaybe<Order_By>;
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  BUDGETLEASESTART?: InputMaybe<Order_By>;
  BUDGETSTATUS?: InputMaybe<Order_By>;
  BUDGETVACATEDATE?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTPROFILEYEARSTART?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitProfileKey?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiUnitProfiles" */
export type YardiUnitProfiles_Mutation_Response = {
  __typename?: "YardiUnitProfiles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiUnitProfiles>;
};

/** input type for inserting object relation for remote table "YardiUnitProfiles" */
export type YardiUnitProfiles_Obj_Rel_Insert_Input = {
  data: YardiUnitProfiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiUnitProfiles_On_Conflict>;
};

/** on_conflict condition type for table "YardiUnitProfiles" */
export type YardiUnitProfiles_On_Conflict = {
  constraint: YardiUnitProfiles_Constraint;
  update_columns?: Array<YardiUnitProfiles_Update_Column>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiUnitProfiles". */
export type YardiUnitProfiles_Order_By = {
  BUDGETEXPIRYDATE?: InputMaybe<Order_By>;
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  BUDGETLEASESTART?: InputMaybe<Order_By>;
  BUDGETSTATUS?: InputMaybe<Order_By>;
  BUDGETVACATEDATE?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTPROFILEYEARSTART?: InputMaybe<Order_By>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitProfileKey?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  YardiProperty?: InputMaybe<YardiProperties_Order_By>;
  YardiUnit?: InputMaybe<YardiUnits_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiUnitProfiles */
export type YardiUnitProfiles_Pk_Columns_Input = {
  UnitProfileKey: Scalars["String"];
};

/** select columns of table "YardiUnitProfiles" */
export enum YardiUnitProfiles_Select_Column {
  /** column name */
  Budgetexpirydate = "BUDGETEXPIRYDATE",
  /** column name */
  Budgetfitoutdays = "BUDGETFITOUTDAYS",
  /** column name */
  Budgetleasestart = "BUDGETLEASESTART",
  /** column name */
  Budgetstatus = "BUDGETSTATUS",
  /** column name */
  Budgetvacatedate = "BUDGETVACATEDATE",
  /** column name */
  CustomTableRecordCode = "CustomTableRecordCode",
  /** column name */
  Dannualpromolevy = "DANNUALPROMOLEVY",
  /** column name */
  Dannualrecoverypsm = "DANNUALRECOVERYPSM",
  /** column name */
  Dannualrent = "DANNUALRENT",
  /** column name */
  Dbudgetedlandlordworkcost = "DBUDGETEDLANDLORDWORKCOST",
  /** column name */
  Dbudgetlandlordcontribtofitout = "DBUDGETLANDLORDCONTRIBTOFITOUT",
  /** column name */
  Dbudgetmakegood = "DBUDGETMAKEGOOD",
  /** column name */
  Dtbudgetleasedate = "DTBUDGETLEASEDATE",
  /** column name */
  Dtprofileyearstart = "DTPROFILEYEARSTART",
  /** column name */
  Fbaserent = "FBASERENT",
  /** column name */
  Fexpirydate = "FEXPIRYDATE",
  /** column name */
  Ffitoutdays = "FFITOUTDAYS",
  /** column name */
  Fincentive = "FINCENTIVE",
  /** column name */
  Fleasestart = "FLEASESTART",
  /** column name */
  Fleasetype = "FLEASETYPE",
  /** column name */
  Flessorworks = "FLESSORWORKS",
  /** column name */
  Fmakegood = "FMAKEGOOD",
  /** column name */
  Fogpsm = "FOGPSM",
  /** column name */
  Fpromo = "FPROMO",
  /** column name */
  Frentcommence = "FRENTCOMMENCE",
  /** column name */
  Fstatus = "FSTATUS",
  /** column name */
  Fvacatedate = "FVACATEDATE",
  /** column name */
  Inhousenetrent = "INHOUSENETRENT",
  /** column name */
  Parea = "PAREA",
  /** column name */
  Pbaserent = "PBASERENT",
  /** column name */
  Pcapocccost = "PCAPOCCCOST",
  /** column name */
  Pogpsm = "POGPSM",
  /** column name */
  Pownername = "POWNERNAME",
  /** column name */
  Ppromo = "PPROMO",
  /** column name */
  Ptenant = "PTENANT",
  /** column name */
  Ptenantname = "PTENANTNAME",
  /** column name */
  Pvacatedate = "PVACATEDATE",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Sdefaultrecoveryprofiledesc = "SDEFAULTRECOVERYPROFILEDESC",
  /** column name */
  Section1 = "SECTION1",
  /** column name */
  Section2 = "SECTION2",
  /** column name */
  Section3 = "SECTION3",
  /** column name */
  Section4 = "SECTION4",
  /** column name */
  Section5 = "SECTION5",
  /** column name */
  Smgmtfee = "SMGMTFEE",
  /** column name */
  UnitCode = "UnitCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  UnitProfileKey = "UnitProfileKey",
  /** column name */
  Valuationnetrent = "VALUATIONNETRENT",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiUnitProfiles" */
export type YardiUnitProfiles_Set_Input = {
  BUDGETEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: InputMaybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: InputMaybe<Scalars["String"]>;
  BUDGETVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: InputMaybe<Scalars["timestamptz"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["Int"]>;
  FINCENTIVE?: InputMaybe<Scalars["numeric"]>;
  FLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["numeric"]>;
  FMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  FOGPSM?: InputMaybe<Scalars["numeric"]>;
  FPROMO?: InputMaybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["numeric"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["numeric"]>;
  POGPSM?: InputMaybe<Scalars["numeric"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["numeric"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitProfileKey?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiUnitProfiles_Stddev_Fields = {
  __typename?: "YardiUnitProfiles_stddev_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Stddev_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiUnitProfiles_Stddev_Pop_Fields = {
  __typename?: "YardiUnitProfiles_stddev_pop_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Stddev_Pop_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiUnitProfiles_Stddev_Samp_Fields = {
  __typename?: "YardiUnitProfiles_stddev_samp_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Stddev_Samp_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiUnitProfiles" */
export type YardiUnitProfiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiUnitProfiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiUnitProfiles_Stream_Cursor_Value_Input = {
  BUDGETEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETFITOUTDAYS?: InputMaybe<Scalars["numeric"]>;
  BUDGETLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  BUDGETSTATUS?: InputMaybe<Scalars["String"]>;
  BUDGETVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTPROFILEYEARSTART?: InputMaybe<Scalars["timestamptz"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["Int"]>;
  FINCENTIVE?: InputMaybe<Scalars["numeric"]>;
  FLEASESTART?: InputMaybe<Scalars["timestamptz"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["numeric"]>;
  FMAKEGOOD?: InputMaybe<Scalars["numeric"]>;
  FOGPSM?: InputMaybe<Scalars["numeric"]>;
  FPROMO?: InputMaybe<Scalars["numeric"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["numeric"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["numeric"]>;
  POGPSM?: InputMaybe<Scalars["numeric"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["numeric"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitProfileKey?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiUnitProfiles_Sum_Fields = {
  __typename?: "YardiUnitProfiles_sum_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["numeric"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["numeric"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FFITOUTDAYS?: Maybe<Scalars["Int"]>;
  FINCENTIVE?: Maybe<Scalars["numeric"]>;
  FLESSORWORKS?: Maybe<Scalars["numeric"]>;
  FMAKEGOOD?: Maybe<Scalars["numeric"]>;
  FOGPSM?: Maybe<Scalars["numeric"]>;
  FPROMO?: Maybe<Scalars["numeric"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["numeric"]>;
  PCAPOCCCOST?: Maybe<Scalars["numeric"]>;
  POGPSM?: Maybe<Scalars["numeric"]>;
  PPROMO?: Maybe<Scalars["numeric"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Sum_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** update columns of table "YardiUnitProfiles" */
export enum YardiUnitProfiles_Update_Column {
  /** column name */
  Budgetexpirydate = "BUDGETEXPIRYDATE",
  /** column name */
  Budgetfitoutdays = "BUDGETFITOUTDAYS",
  /** column name */
  Budgetleasestart = "BUDGETLEASESTART",
  /** column name */
  Budgetstatus = "BUDGETSTATUS",
  /** column name */
  Budgetvacatedate = "BUDGETVACATEDATE",
  /** column name */
  CustomTableRecordCode = "CustomTableRecordCode",
  /** column name */
  Dannualpromolevy = "DANNUALPROMOLEVY",
  /** column name */
  Dannualrecoverypsm = "DANNUALRECOVERYPSM",
  /** column name */
  Dannualrent = "DANNUALRENT",
  /** column name */
  Dbudgetedlandlordworkcost = "DBUDGETEDLANDLORDWORKCOST",
  /** column name */
  Dbudgetlandlordcontribtofitout = "DBUDGETLANDLORDCONTRIBTOFITOUT",
  /** column name */
  Dbudgetmakegood = "DBUDGETMAKEGOOD",
  /** column name */
  Dtbudgetleasedate = "DTBUDGETLEASEDATE",
  /** column name */
  Dtprofileyearstart = "DTPROFILEYEARSTART",
  /** column name */
  Fbaserent = "FBASERENT",
  /** column name */
  Fexpirydate = "FEXPIRYDATE",
  /** column name */
  Ffitoutdays = "FFITOUTDAYS",
  /** column name */
  Fincentive = "FINCENTIVE",
  /** column name */
  Fleasestart = "FLEASESTART",
  /** column name */
  Fleasetype = "FLEASETYPE",
  /** column name */
  Flessorworks = "FLESSORWORKS",
  /** column name */
  Fmakegood = "FMAKEGOOD",
  /** column name */
  Fogpsm = "FOGPSM",
  /** column name */
  Fpromo = "FPROMO",
  /** column name */
  Frentcommence = "FRENTCOMMENCE",
  /** column name */
  Fstatus = "FSTATUS",
  /** column name */
  Fvacatedate = "FVACATEDATE",
  /** column name */
  Inhousenetrent = "INHOUSENETRENT",
  /** column name */
  Parea = "PAREA",
  /** column name */
  Pbaserent = "PBASERENT",
  /** column name */
  Pcapocccost = "PCAPOCCCOST",
  /** column name */
  Pogpsm = "POGPSM",
  /** column name */
  Pownername = "POWNERNAME",
  /** column name */
  Ppromo = "PPROMO",
  /** column name */
  Ptenant = "PTENANT",
  /** column name */
  Ptenantname = "PTENANTNAME",
  /** column name */
  Pvacatedate = "PVACATEDATE",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Sdefaultrecoveryprofiledesc = "SDEFAULTRECOVERYPROFILEDESC",
  /** column name */
  Section1 = "SECTION1",
  /** column name */
  Section2 = "SECTION2",
  /** column name */
  Section3 = "SECTION3",
  /** column name */
  Section4 = "SECTION4",
  /** column name */
  Section5 = "SECTION5",
  /** column name */
  Smgmtfee = "SMGMTFEE",
  /** column name */
  UnitCode = "UnitCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  UnitProfileKey = "UnitProfileKey",
  /** column name */
  Valuationnetrent = "VALUATIONNETRENT",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiUnitProfiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiUnitProfiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiUnitProfiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiUnitProfiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiUnitProfiles_Var_Pop_Fields = {
  __typename?: "YardiUnitProfiles_var_pop_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Var_Pop_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiUnitProfiles_Var_Samp_Fields = {
  __typename?: "YardiUnitProfiles_var_samp_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Var_Samp_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiUnitProfiles_Variance_Fields = {
  __typename?: "YardiUnitProfiles_variance_fields";
  BUDGETFITOUTDAYS?: Maybe<Scalars["Float"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  DBUDGETMAKEGOOD?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  FFITOUTDAYS?: Maybe<Scalars["Float"]>;
  FINCENTIVE?: Maybe<Scalars["Float"]>;
  FLESSORWORKS?: Maybe<Scalars["Float"]>;
  FMAKEGOOD?: Maybe<Scalars["Float"]>;
  FOGPSM?: Maybe<Scalars["Float"]>;
  FPROMO?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  PBASERENT?: Maybe<Scalars["Float"]>;
  PCAPOCCCOST?: Maybe<Scalars["Float"]>;
  POGPSM?: Maybe<Scalars["Float"]>;
  PPROMO?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiUnitProfiles" */
export type YardiUnitProfiles_Variance_Order_By = {
  BUDGETFITOUTDAYS?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DBUDGETMAKEGOOD?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnits = {
  __typename?: "YardiUnits";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  Notes?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType: Scalars["String"];
  TenantCode?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode: Scalars["String"];
  UnitKey: Scalars["String"];
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  /** An object relationship */
  YardiProperty?: Maybe<YardiProperties>;
  /** An object relationship */
  YardiTenant?: Maybe<YardiTenants>;
  /** An array relationship */
  YardiTenantToUnits: Array<YardiTenantToUnits>;
  /** An aggregate relationship */
  YardiTenantToUnits_aggregate: YardiTenantToUnits_Aggregate;
  /** An array relationship */
  YardiUnitProfiles: Array<YardiUnitProfiles>;
  /** An aggregate relationship */
  YardiUnitProfiles_aggregate: YardiUnitProfiles_Aggregate;
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsYardiTenantToUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsYardiTenantToUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsYardiUnitProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** columns and relationships of "YardiUnits" */
export type YardiUnitsYardiUnitProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

/** aggregated selection of "YardiUnits" */
export type YardiUnits_Aggregate = {
  __typename?: "YardiUnits_aggregate";
  aggregate?: Maybe<YardiUnits_Aggregate_Fields>;
  nodes: Array<YardiUnits>;
};

export type YardiUnits_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiUnits_Aggregate_Bool_Exp_Count>;
};

export type YardiUnits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiUnits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiUnits" */
export type YardiUnits_Aggregate_Fields = {
  __typename?: "YardiUnits_aggregate_fields";
  avg?: Maybe<YardiUnits_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiUnits_Max_Fields>;
  min?: Maybe<YardiUnits_Min_Fields>;
  stddev?: Maybe<YardiUnits_Stddev_Fields>;
  stddev_pop?: Maybe<YardiUnits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiUnits_Stddev_Samp_Fields>;
  sum?: Maybe<YardiUnits_Sum_Fields>;
  var_pop?: Maybe<YardiUnits_Var_Pop_Fields>;
  var_samp?: Maybe<YardiUnits_Var_Samp_Fields>;
  variance?: Maybe<YardiUnits_Variance_Fields>;
};

/** aggregate fields of "YardiUnits" */
export type YardiUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiUnits" */
export type YardiUnits_Aggregate_Order_By = {
  avg?: InputMaybe<YardiUnits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiUnits_Max_Order_By>;
  min?: InputMaybe<YardiUnits_Min_Order_By>;
  stddev?: InputMaybe<YardiUnits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiUnits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiUnits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiUnits_Sum_Order_By>;
  var_pop?: InputMaybe<YardiUnits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiUnits_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiUnits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiUnits" */
export type YardiUnits_Arr_Rel_Insert_Input = {
  data: Array<YardiUnits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiUnits_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiUnits_Avg_Fields = {
  __typename?: "YardiUnits_avg_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiUnits" */
export type YardiUnits_Avg_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiUnits". All fields are combined with a logical 'AND'. */
export type YardiUnits_Bool_Exp = {
  Attribute1?: InputMaybe<String_Comparison_Exp>;
  Attribute2?: InputMaybe<String_Comparison_Exp>;
  Attribute3?: InputMaybe<String_Comparison_Exp>;
  Attribute4?: InputMaybe<String_Comparison_Exp>;
  Attribute5?: InputMaybe<String_Comparison_Exp>;
  Attribute6?: InputMaybe<String_Comparison_Exp>;
  Attribute7?: InputMaybe<String_Comparison_Exp>;
  Attribute8?: InputMaybe<String_Comparison_Exp>;
  Attribute9?: InputMaybe<String_Comparison_Exp>;
  Attribute10?: InputMaybe<String_Comparison_Exp>;
  Building?: InputMaybe<String_Comparison_Exp>;
  DealsUnits?: InputMaybe<DealsUnits_Bool_Exp>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Bool_Exp>;
  FloorCode?: InputMaybe<String_Comparison_Exp>;
  IsExclude?: InputMaybe<String_Comparison_Exp>;
  LevelName?: InputMaybe<String_Comparison_Exp>;
  LevelNumber?: InputMaybe<String_Comparison_Exp>;
  Location?: InputMaybe<String_Comparison_Exp>;
  MarketRent?: InputMaybe<Numeric_Comparison_Exp>;
  Notes?: InputMaybe<String_Comparison_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  PropertyName?: InputMaybe<String_Comparison_Exp>;
  RentalType?: InputMaybe<String_Comparison_Exp>;
  TenantCode?: InputMaybe<String_Comparison_Exp>;
  UnitArea?: InputMaybe<Numeric_Comparison_Exp>;
  UnitCode?: InputMaybe<String_Comparison_Exp>;
  UnitKey?: InputMaybe<String_Comparison_Exp>;
  UnitStatus?: InputMaybe<String_Comparison_Exp>;
  UnitType?: InputMaybe<String_Comparison_Exp>;
  YardiProperty?: InputMaybe<YardiProperties_Bool_Exp>;
  YardiTenant?: InputMaybe<YardiTenants_Bool_Exp>;
  YardiTenantToUnits?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
  YardiTenantToUnits_aggregate?: InputMaybe<YardiTenantToUnits_Aggregate_Bool_Exp>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<YardiUnits_Bool_Exp>>;
  _not?: InputMaybe<YardiUnits_Bool_Exp>;
  _or?: InputMaybe<Array<YardiUnits_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiUnits" */
export enum YardiUnits_Constraint {
  /** unique or primary key constraint on columns "UnitKey" */
  YardiUnitsPkey = "YardiUnits_pkey",
}

/** input type for incrementing numeric columns in table "YardiUnits" */
export type YardiUnits_Inc_Input = {
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "YardiUnits" */
export type YardiUnits_Insert_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  DealsUnits?: InputMaybe<DealsUnits_Arr_Rel_Insert_Input>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  YardiProperty?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  YardiTenant?: InputMaybe<YardiTenants_Obj_Rel_Insert_Input>;
  YardiTenantToUnits?: InputMaybe<YardiTenantToUnits_Arr_Rel_Insert_Input>;
  YardiUnitProfiles?: InputMaybe<YardiUnitProfiles_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiUnits_Max_Fields = {
  __typename?: "YardiUnits_max_fields";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  Notes?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiUnits" */
export type YardiUnits_Max_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiUnits_Min_Fields = {
  __typename?: "YardiUnits_min_fields";
  Attribute1?: Maybe<Scalars["String"]>;
  Attribute2?: Maybe<Scalars["String"]>;
  Attribute3?: Maybe<Scalars["String"]>;
  Attribute4?: Maybe<Scalars["String"]>;
  Attribute5?: Maybe<Scalars["String"]>;
  Attribute6?: Maybe<Scalars["String"]>;
  Attribute7?: Maybe<Scalars["String"]>;
  Attribute8?: Maybe<Scalars["String"]>;
  Attribute9?: Maybe<Scalars["String"]>;
  Attribute10?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  FloorCode?: Maybe<Scalars["String"]>;
  IsExclude?: Maybe<Scalars["String"]>;
  LevelName?: Maybe<Scalars["String"]>;
  LevelNumber?: Maybe<Scalars["String"]>;
  Location?: Maybe<Scalars["String"]>;
  MarketRent?: Maybe<Scalars["numeric"]>;
  Notes?: Maybe<Scalars["String"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  PropertyName?: Maybe<Scalars["String"]>;
  RentalType?: Maybe<Scalars["String"]>;
  TenantCode?: Maybe<Scalars["String"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
  UnitCode?: Maybe<Scalars["String"]>;
  UnitKey?: Maybe<Scalars["String"]>;
  UnitStatus?: Maybe<Scalars["String"]>;
  UnitType?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiUnits" */
export type YardiUnits_Min_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiUnits" */
export type YardiUnits_Mutation_Response = {
  __typename?: "YardiUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiUnits>;
};

/** input type for inserting object relation for remote table "YardiUnits" */
export type YardiUnits_Obj_Rel_Insert_Input = {
  data: YardiUnits_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiUnits_On_Conflict>;
};

/** on_conflict condition type for table "YardiUnits" */
export type YardiUnits_On_Conflict = {
  constraint: YardiUnits_Constraint;
  update_columns?: Array<YardiUnits_Update_Column>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiUnits". */
export type YardiUnits_Order_By = {
  Attribute1?: InputMaybe<Order_By>;
  Attribute2?: InputMaybe<Order_By>;
  Attribute3?: InputMaybe<Order_By>;
  Attribute4?: InputMaybe<Order_By>;
  Attribute5?: InputMaybe<Order_By>;
  Attribute6?: InputMaybe<Order_By>;
  Attribute7?: InputMaybe<Order_By>;
  Attribute8?: InputMaybe<Order_By>;
  Attribute9?: InputMaybe<Order_By>;
  Attribute10?: InputMaybe<Order_By>;
  Building?: InputMaybe<Order_By>;
  DealsUnits_aggregate?: InputMaybe<DealsUnits_Aggregate_Order_By>;
  FloorCode?: InputMaybe<Order_By>;
  IsExclude?: InputMaybe<Order_By>;
  LevelName?: InputMaybe<Order_By>;
  LevelNumber?: InputMaybe<Order_By>;
  Location?: InputMaybe<Order_By>;
  MarketRent?: InputMaybe<Order_By>;
  Notes?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  PropertyName?: InputMaybe<Order_By>;
  RentalType?: InputMaybe<Order_By>;
  TenantCode?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
  UnitCode?: InputMaybe<Order_By>;
  UnitKey?: InputMaybe<Order_By>;
  UnitStatus?: InputMaybe<Order_By>;
  UnitType?: InputMaybe<Order_By>;
  YardiProperty?: InputMaybe<YardiProperties_Order_By>;
  YardiTenant?: InputMaybe<YardiTenants_Order_By>;
  YardiTenantToUnits_aggregate?: InputMaybe<YardiTenantToUnits_Aggregate_Order_By>;
  YardiUnitProfiles_aggregate?: InputMaybe<YardiUnitProfiles_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiUnits */
export type YardiUnits_Pk_Columns_Input = {
  UnitKey: Scalars["String"];
};

/** select columns of table "YardiUnits" */
export enum YardiUnits_Select_Column {
  /** column name */
  Attribute1 = "Attribute1",
  /** column name */
  Attribute2 = "Attribute2",
  /** column name */
  Attribute3 = "Attribute3",
  /** column name */
  Attribute4 = "Attribute4",
  /** column name */
  Attribute5 = "Attribute5",
  /** column name */
  Attribute6 = "Attribute6",
  /** column name */
  Attribute7 = "Attribute7",
  /** column name */
  Attribute8 = "Attribute8",
  /** column name */
  Attribute9 = "Attribute9",
  /** column name */
  Attribute10 = "Attribute10",
  /** column name */
  Building = "Building",
  /** column name */
  FloorCode = "FloorCode",
  /** column name */
  IsExclude = "IsExclude",
  /** column name */
  LevelName = "LevelName",
  /** column name */
  LevelNumber = "LevelNumber",
  /** column name */
  Location = "Location",
  /** column name */
  MarketRent = "MarketRent",
  /** column name */
  Notes = "Notes",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  RentalType = "RentalType",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  UnitArea = "UnitArea",
  /** column name */
  UnitCode = "UnitCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  UnitStatus = "UnitStatus",
  /** column name */
  UnitType = "UnitType",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiUnits" */
export type YardiUnits_Set_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiUnits_Stddev_Fields = {
  __typename?: "YardiUnits_stddev_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiUnits" */
export type YardiUnits_Stddev_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiUnits_Stddev_Pop_Fields = {
  __typename?: "YardiUnits_stddev_pop_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiUnits" */
export type YardiUnits_Stddev_Pop_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiUnits_Stddev_Samp_Fields = {
  __typename?: "YardiUnits_stddev_samp_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiUnits" */
export type YardiUnits_Stddev_Samp_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiUnits" */
export type YardiUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiUnits_Stream_Cursor_Value_Input = {
  Attribute1?: InputMaybe<Scalars["String"]>;
  Attribute2?: InputMaybe<Scalars["String"]>;
  Attribute3?: InputMaybe<Scalars["String"]>;
  Attribute4?: InputMaybe<Scalars["String"]>;
  Attribute5?: InputMaybe<Scalars["String"]>;
  Attribute6?: InputMaybe<Scalars["String"]>;
  Attribute7?: InputMaybe<Scalars["String"]>;
  Attribute8?: InputMaybe<Scalars["String"]>;
  Attribute9?: InputMaybe<Scalars["String"]>;
  Attribute10?: InputMaybe<Scalars["String"]>;
  Building?: InputMaybe<Scalars["String"]>;
  FloorCode?: InputMaybe<Scalars["String"]>;
  IsExclude?: InputMaybe<Scalars["String"]>;
  LevelName?: InputMaybe<Scalars["String"]>;
  LevelNumber?: InputMaybe<Scalars["String"]>;
  Location?: InputMaybe<Scalars["String"]>;
  MarketRent?: InputMaybe<Scalars["numeric"]>;
  Notes?: InputMaybe<Scalars["String"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  PropertyName?: InputMaybe<Scalars["String"]>;
  RentalType?: InputMaybe<Scalars["String"]>;
  TenantCode?: InputMaybe<Scalars["String"]>;
  UnitArea?: InputMaybe<Scalars["numeric"]>;
  UnitCode?: InputMaybe<Scalars["String"]>;
  UnitKey?: InputMaybe<Scalars["String"]>;
  UnitStatus?: InputMaybe<Scalars["String"]>;
  UnitType?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiUnits_Sum_Fields = {
  __typename?: "YardiUnits_sum_fields";
  MarketRent?: Maybe<Scalars["numeric"]>;
  UnitArea?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "YardiUnits" */
export type YardiUnits_Sum_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** update columns of table "YardiUnits" */
export enum YardiUnits_Update_Column {
  /** column name */
  Attribute1 = "Attribute1",
  /** column name */
  Attribute2 = "Attribute2",
  /** column name */
  Attribute3 = "Attribute3",
  /** column name */
  Attribute4 = "Attribute4",
  /** column name */
  Attribute5 = "Attribute5",
  /** column name */
  Attribute6 = "Attribute6",
  /** column name */
  Attribute7 = "Attribute7",
  /** column name */
  Attribute8 = "Attribute8",
  /** column name */
  Attribute9 = "Attribute9",
  /** column name */
  Attribute10 = "Attribute10",
  /** column name */
  Building = "Building",
  /** column name */
  FloorCode = "FloorCode",
  /** column name */
  IsExclude = "IsExclude",
  /** column name */
  LevelName = "LevelName",
  /** column name */
  LevelNumber = "LevelNumber",
  /** column name */
  Location = "Location",
  /** column name */
  MarketRent = "MarketRent",
  /** column name */
  Notes = "Notes",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  PropertyName = "PropertyName",
  /** column name */
  RentalType = "RentalType",
  /** column name */
  TenantCode = "TenantCode",
  /** column name */
  UnitArea = "UnitArea",
  /** column name */
  UnitCode = "UnitCode",
  /** column name */
  UnitKey = "UnitKey",
  /** column name */
  UnitStatus = "UnitStatus",
  /** column name */
  UnitType = "UnitType",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiUnits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiUnits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiUnits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiUnits_Var_Pop_Fields = {
  __typename?: "YardiUnits_var_pop_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiUnits" */
export type YardiUnits_Var_Pop_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiUnits_Var_Samp_Fields = {
  __typename?: "YardiUnits_var_samp_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiUnits" */
export type YardiUnits_Var_Samp_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiUnits_Variance_Fields = {
  __typename?: "YardiUnits_variance_fields";
  MarketRent?: Maybe<Scalars["Float"]>;
  UnitArea?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiUnits" */
export type YardiUnits_Variance_Order_By = {
  MarketRent?: InputMaybe<Order_By>;
  UnitArea?: InputMaybe<Order_By>;
};

/** columns and relationships of "YardiVirtualUnits" */
export type YardiVirtualUnits = {
  __typename?: "YardiVirtualUnits";
  BudgetExpiryDate?: Maybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: Maybe<Scalars["String"]>;
  BudgetLeaseStart?: Maybe<Scalars["String"]>;
  BudgetMakegood?: Maybe<Scalars["String"]>;
  BudgetStatus?: Maybe<Scalars["String"]>;
  BudgetVacateDate?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: Maybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DAREA?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: Maybe<Scalars["timestamptz"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["String"]>;
  FINCENTIVE?: Maybe<Scalars["String"]>;
  FLEASESTART?: Maybe<Scalars["String"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["String"]>;
  FMAKEGOOD?: Maybe<Scalars["String"]>;
  FOGPSM?: Maybe<Scalars["String"]>;
  FPROMO?: Maybe<Scalars["String"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: Maybe<Scalars["String"]>;
  GENDEAL?: Maybe<Scalars["String"]>;
  GENDEALREASON?: Maybe<Scalars["String"]>;
  GENIGNORE?: Maybe<Scalars["String"]>;
  GENLECOMMENT?: Maybe<Scalars["String"]>;
  GENSHOP?: Maybe<Scalars["String"]>;
  HEADER1?: Maybe<Scalars["String"]>;
  HEADER1A?: Maybe<Scalars["String"]>;
  HEADER2?: Maybe<Scalars["String"]>;
  HEADER3?: Maybe<Scalars["String"]>;
  HMERGESPACE1?: Maybe<Scalars["numeric"]>;
  HMERGESPACE2?: Maybe<Scalars["numeric"]>;
  HMERGESPACE3?: Maybe<Scalars["numeric"]>;
  HMERGESPACE4?: Maybe<Scalars["numeric"]>;
  HMERGESPACE5?: Maybe<Scalars["numeric"]>;
  HREMEASUREOF?: Maybe<Scalars["numeric"]>;
  HSUBSPACEOF?: Maybe<Scalars["numeric"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  MODIFAREA?: Maybe<Scalars["numeric"]>;
  MODIFBASERENT?: Maybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: Maybe<Scalars["String"]>;
  MODIFINCENTIVE?: Maybe<Scalars["String"]>;
  MODIFLEASESTART?: Maybe<Scalars["String"]>;
  MODIFLEASETYPE?: Maybe<Scalars["String"]>;
  MODIFLESSORWORKS?: Maybe<Scalars["String"]>;
  MODIFMAKEGOOD?: Maybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: Maybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: Maybe<Scalars["String"]>;
  MODIFOWNERNAME?: Maybe<Scalars["String"]>;
  MODIFPROMO?: Maybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: Maybe<Scalars["String"]>;
  MODIFSTATUS?: Maybe<Scalars["String"]>;
  MODIFTENANTNAME?: Maybe<Scalars["String"]>;
  MODIFVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: Maybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: Maybe<Scalars["String"]>;
  OVERRIDETYPE?: Maybe<Scalars["String"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["String"]>;
  PCAPOCCCOST?: Maybe<Scalars["String"]>;
  POGPSM?: Maybe<Scalars["String"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["String"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  Property?: Maybe<YardiProperties>;
  PropertyCode: Scalars["String"];
  RECONFIGEFFECTIVEDATE?: Maybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: Maybe<Scalars["String"]>;
  SASSOCIATIONDESC?: Maybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SECTION6?: Maybe<Scalars["String"]>;
  SECTION7?: Maybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSEREASON?: Maybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: Maybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: Maybe<Scalars["String"]>;
  SHOPOPEN?: Maybe<Scalars["String"]>;
  SHOPRENTSTART?: Maybe<Scalars["String"]>;
  SMERGETYPE?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  SNEWSPACE?: Maybe<Scalars["String"]>;
  SSETMERGESPACE1?: Maybe<Scalars["String"]>;
  SSETMERGESPACE2?: Maybe<Scalars["String"]>;
  SSETMERGESPACE3?: Maybe<Scalars["String"]>;
  SSETMERGESPACE4?: Maybe<Scalars["String"]>;
  SSETMERGESPACE5?: Maybe<Scalars["String"]>;
  SSETREMEASUREOF?: Maybe<Scalars["String"]>;
  SSETSUBSPACEOF?: Maybe<Scalars["String"]>;
  SSTATUS?: Maybe<Scalars["String"]>;
  SUNITCODE?: Maybe<Scalars["String"]>;
  SUNITTYPE?: Maybe<Scalars["String"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  VirtualUnitKey: Scalars["String"];
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at: Scalars["timestamptz"];
};

/** columns and relationships of "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits = {
  __typename?: "YardiVirtualUnits_VirtualUnits";
  VirtualUnitKey: Scalars["String"];
  created_at: Scalars["timestamptz"];
  virtual_unit_id: Scalars["bigint"];
};

/** aggregated selection of "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Aggregate = {
  __typename?: "YardiVirtualUnits_VirtualUnits_aggregate";
  aggregate?: Maybe<YardiVirtualUnits_VirtualUnits_Aggregate_Fields>;
  nodes: Array<YardiVirtualUnits_VirtualUnits>;
};

/** aggregate fields of "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Aggregate_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_aggregate_fields";
  avg?: Maybe<YardiVirtualUnits_VirtualUnits_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiVirtualUnits_VirtualUnits_Max_Fields>;
  min?: Maybe<YardiVirtualUnits_VirtualUnits_Min_Fields>;
  stddev?: Maybe<YardiVirtualUnits_VirtualUnits_Stddev_Fields>;
  stddev_pop?: Maybe<YardiVirtualUnits_VirtualUnits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiVirtualUnits_VirtualUnits_Stddev_Samp_Fields>;
  sum?: Maybe<YardiVirtualUnits_VirtualUnits_Sum_Fields>;
  var_pop?: Maybe<YardiVirtualUnits_VirtualUnits_Var_Pop_Fields>;
  var_samp?: Maybe<YardiVirtualUnits_VirtualUnits_Var_Samp_Fields>;
  variance?: Maybe<YardiVirtualUnits_VirtualUnits_Variance_Fields>;
};

/** aggregate fields of "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type YardiVirtualUnits_VirtualUnits_Avg_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_avg_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "YardiVirtualUnits_VirtualUnits". All fields are combined with a logical 'AND'. */
export type YardiVirtualUnits_VirtualUnits_Bool_Exp = {
  VirtualUnitKey?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Bool_Exp>>;
  _not?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
  _or?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  virtual_unit_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiVirtualUnits_VirtualUnits" */
export enum YardiVirtualUnits_VirtualUnits_Constraint {
  /** unique or primary key constraint on columns "VirtualUnitKey", "virtual_unit_id" */
  YardiVirtualUnitsVirtualUnitsPkey = "YardiVirtualUnits_VirtualUnits_pkey",
}

/** input type for incrementing numeric columns in table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Inc_Input = {
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Insert_Input = {
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type YardiVirtualUnits_VirtualUnits_Max_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_max_fields";
  VirtualUnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** aggregate min on columns */
export type YardiVirtualUnits_VirtualUnits_Min_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_min_fields";
  VirtualUnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** response of any mutation on the table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Mutation_Response = {
  __typename?: "YardiVirtualUnits_VirtualUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiVirtualUnits_VirtualUnits>;
};

/** on_conflict condition type for table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_On_Conflict = {
  constraint: YardiVirtualUnits_VirtualUnits_Constraint;
  update_columns?: Array<YardiVirtualUnits_VirtualUnits_Update_Column>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiVirtualUnits_VirtualUnits". */
export type YardiVirtualUnits_VirtualUnits_Order_By = {
  VirtualUnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  virtual_unit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiVirtualUnits_VirtualUnits */
export type YardiVirtualUnits_VirtualUnits_Pk_Columns_Input = {
  VirtualUnitKey: Scalars["String"];
  virtual_unit_id: Scalars["bigint"];
};

/** select columns of table "YardiVirtualUnits_VirtualUnits" */
export enum YardiVirtualUnits_VirtualUnits_Select_Column {
  /** column name */
  VirtualUnitKey = "VirtualUnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  VirtualUnitId = "virtual_unit_id",
}

/** input type for updating data in table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Set_Input = {
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type YardiVirtualUnits_VirtualUnits_Stddev_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_stddev_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type YardiVirtualUnits_VirtualUnits_Stddev_Pop_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_stddev_pop_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type YardiVirtualUnits_VirtualUnits_Stddev_Samp_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_stddev_samp_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "YardiVirtualUnits_VirtualUnits" */
export type YardiVirtualUnits_VirtualUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiVirtualUnits_VirtualUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiVirtualUnits_VirtualUnits_Stream_Cursor_Value_Input = {
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
};

/** aggregate sum on columns */
export type YardiVirtualUnits_VirtualUnits_Sum_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_sum_fields";
  virtual_unit_id?: Maybe<Scalars["bigint"]>;
};

/** update columns of table "YardiVirtualUnits_VirtualUnits" */
export enum YardiVirtualUnits_VirtualUnits_Update_Column {
  /** column name */
  VirtualUnitKey = "VirtualUnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  VirtualUnitId = "virtual_unit_id",
}

export type YardiVirtualUnits_VirtualUnits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiVirtualUnits_VirtualUnits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiVirtualUnits_VirtualUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiVirtualUnits_VirtualUnits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiVirtualUnits_VirtualUnits_Var_Pop_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_var_pop_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type YardiVirtualUnits_VirtualUnits_Var_Samp_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_var_samp_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type YardiVirtualUnits_VirtualUnits_Variance_Fields = {
  __typename?: "YardiVirtualUnits_VirtualUnits_variance_fields";
  virtual_unit_id?: Maybe<Scalars["Float"]>;
};

/** aggregated selection of "YardiVirtualUnits" */
export type YardiVirtualUnits_Aggregate = {
  __typename?: "YardiVirtualUnits_aggregate";
  aggregate?: Maybe<YardiVirtualUnits_Aggregate_Fields>;
  nodes: Array<YardiVirtualUnits>;
};

export type YardiVirtualUnits_Aggregate_Bool_Exp = {
  count?: InputMaybe<YardiVirtualUnits_Aggregate_Bool_Exp_Count>;
};

export type YardiVirtualUnits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "YardiVirtualUnits" */
export type YardiVirtualUnits_Aggregate_Fields = {
  __typename?: "YardiVirtualUnits_aggregate_fields";
  avg?: Maybe<YardiVirtualUnits_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<YardiVirtualUnits_Max_Fields>;
  min?: Maybe<YardiVirtualUnits_Min_Fields>;
  stddev?: Maybe<YardiVirtualUnits_Stddev_Fields>;
  stddev_pop?: Maybe<YardiVirtualUnits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<YardiVirtualUnits_Stddev_Samp_Fields>;
  sum?: Maybe<YardiVirtualUnits_Sum_Fields>;
  var_pop?: Maybe<YardiVirtualUnits_Var_Pop_Fields>;
  var_samp?: Maybe<YardiVirtualUnits_Var_Samp_Fields>;
  variance?: Maybe<YardiVirtualUnits_Variance_Fields>;
};

/** aggregate fields of "YardiVirtualUnits" */
export type YardiVirtualUnits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Aggregate_Order_By = {
  avg?: InputMaybe<YardiVirtualUnits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<YardiVirtualUnits_Max_Order_By>;
  min?: InputMaybe<YardiVirtualUnits_Min_Order_By>;
  stddev?: InputMaybe<YardiVirtualUnits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<YardiVirtualUnits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<YardiVirtualUnits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<YardiVirtualUnits_Sum_Order_By>;
  var_pop?: InputMaybe<YardiVirtualUnits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<YardiVirtualUnits_Var_Samp_Order_By>;
  variance?: InputMaybe<YardiVirtualUnits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "YardiVirtualUnits" */
export type YardiVirtualUnits_Arr_Rel_Insert_Input = {
  data: Array<YardiVirtualUnits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<YardiVirtualUnits_On_Conflict>;
};

/** aggregate avg on columns */
export type YardiVirtualUnits_Avg_Fields = {
  __typename?: "YardiVirtualUnits_avg_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Avg_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "YardiVirtualUnits". All fields are combined with a logical 'AND'. */
export type YardiVirtualUnits_Bool_Exp = {
  BudgetExpiryDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  BudgetFitoutDays?: InputMaybe<String_Comparison_Exp>;
  BudgetLeaseStart?: InputMaybe<String_Comparison_Exp>;
  BudgetMakegood?: InputMaybe<String_Comparison_Exp>;
  BudgetStatus?: InputMaybe<String_Comparison_Exp>;
  BudgetVacateDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CustomTableRecordCode?: InputMaybe<String_Comparison_Exp>;
  DANNUALPROMOLEVY?: InputMaybe<Numeric_Comparison_Exp>;
  DANNUALRECOVERYPSM?: InputMaybe<Numeric_Comparison_Exp>;
  DANNUALRENT?: InputMaybe<Numeric_Comparison_Exp>;
  DAREA?: InputMaybe<Numeric_Comparison_Exp>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Numeric_Comparison_Exp>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Numeric_Comparison_Exp>;
  DTBUDGETLEASEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  DTCONCEPTION?: InputMaybe<Timestamptz_Comparison_Exp>;
  FBASERENT?: InputMaybe<Numeric_Comparison_Exp>;
  FEXPIRYDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  FFITOUTDAYS?: InputMaybe<String_Comparison_Exp>;
  FINCENTIVE?: InputMaybe<String_Comparison_Exp>;
  FLEASESTART?: InputMaybe<String_Comparison_Exp>;
  FLEASETYPE?: InputMaybe<String_Comparison_Exp>;
  FLESSORWORKS?: InputMaybe<String_Comparison_Exp>;
  FMAKEGOOD?: InputMaybe<String_Comparison_Exp>;
  FOGPSM?: InputMaybe<String_Comparison_Exp>;
  FPROMO?: InputMaybe<String_Comparison_Exp>;
  FRENTCOMMENCE?: InputMaybe<Timestamptz_Comparison_Exp>;
  FSTATUS?: InputMaybe<String_Comparison_Exp>;
  FVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  GENCAPOCCCOST?: InputMaybe<String_Comparison_Exp>;
  GENDEAL?: InputMaybe<String_Comparison_Exp>;
  GENDEALREASON?: InputMaybe<String_Comparison_Exp>;
  GENIGNORE?: InputMaybe<String_Comparison_Exp>;
  GENLECOMMENT?: InputMaybe<String_Comparison_Exp>;
  GENSHOP?: InputMaybe<String_Comparison_Exp>;
  HEADER1?: InputMaybe<String_Comparison_Exp>;
  HEADER1A?: InputMaybe<String_Comparison_Exp>;
  HEADER2?: InputMaybe<String_Comparison_Exp>;
  HEADER3?: InputMaybe<String_Comparison_Exp>;
  HMERGESPACE1?: InputMaybe<Numeric_Comparison_Exp>;
  HMERGESPACE2?: InputMaybe<Numeric_Comparison_Exp>;
  HMERGESPACE3?: InputMaybe<Numeric_Comparison_Exp>;
  HMERGESPACE4?: InputMaybe<Numeric_Comparison_Exp>;
  HMERGESPACE5?: InputMaybe<Numeric_Comparison_Exp>;
  HREMEASUREOF?: InputMaybe<Numeric_Comparison_Exp>;
  HSUBSPACEOF?: InputMaybe<Numeric_Comparison_Exp>;
  INHOUSENETRENT?: InputMaybe<Numeric_Comparison_Exp>;
  MODIFAREA?: InputMaybe<Numeric_Comparison_Exp>;
  MODIFBASERENT?: InputMaybe<Numeric_Comparison_Exp>;
  MODIFEXPIRYDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  MODIFFITOUT?: InputMaybe<String_Comparison_Exp>;
  MODIFINCENTIVE?: InputMaybe<String_Comparison_Exp>;
  MODIFLEASESTART?: InputMaybe<String_Comparison_Exp>;
  MODIFLEASETYPE?: InputMaybe<String_Comparison_Exp>;
  MODIFLESSORWORKS?: InputMaybe<String_Comparison_Exp>;
  MODIFMAKEGOOD?: InputMaybe<String_Comparison_Exp>;
  MODIFNEXTRENTREVIEW?: InputMaybe<String_Comparison_Exp>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  MODIFOGPSM?: InputMaybe<String_Comparison_Exp>;
  MODIFOWNERNAME?: InputMaybe<String_Comparison_Exp>;
  MODIFPROMO?: InputMaybe<String_Comparison_Exp>;
  MODIFRENTCOMMENCE?: InputMaybe<String_Comparison_Exp>;
  MODIFSTATUS?: InputMaybe<String_Comparison_Exp>;
  MODIFTENANTNAME?: InputMaybe<String_Comparison_Exp>;
  MODIFVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  OVERRIDESALESCATEGORY?: InputMaybe<String_Comparison_Exp>;
  OVERRIDESALESTYPE?: InputMaybe<String_Comparison_Exp>;
  OVERRIDETYPE?: InputMaybe<String_Comparison_Exp>;
  PAREA?: InputMaybe<Numeric_Comparison_Exp>;
  PBASERENT?: InputMaybe<String_Comparison_Exp>;
  PCAPOCCCOST?: InputMaybe<String_Comparison_Exp>;
  POGPSM?: InputMaybe<String_Comparison_Exp>;
  POWNERNAME?: InputMaybe<String_Comparison_Exp>;
  PPROMO?: InputMaybe<String_Comparison_Exp>;
  PTENANT?: InputMaybe<String_Comparison_Exp>;
  PTENANTNAME?: InputMaybe<String_Comparison_Exp>;
  PVACATEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  Property?: InputMaybe<YardiProperties_Bool_Exp>;
  PropertyCode?: InputMaybe<String_Comparison_Exp>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  RECONFIGGROUP?: InputMaybe<String_Comparison_Exp>;
  SASSOCIATIONDESC?: InputMaybe<String_Comparison_Exp>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<String_Comparison_Exp>;
  SDESC?: InputMaybe<String_Comparison_Exp>;
  SECTION1?: InputMaybe<String_Comparison_Exp>;
  SECTION2?: InputMaybe<String_Comparison_Exp>;
  SECTION3?: InputMaybe<String_Comparison_Exp>;
  SECTION4?: InputMaybe<String_Comparison_Exp>;
  SECTION5?: InputMaybe<String_Comparison_Exp>;
  SECTION6?: InputMaybe<String_Comparison_Exp>;
  SECTION7?: InputMaybe<String_Comparison_Exp>;
  SHOPACTUALCLOSE?: InputMaybe<String_Comparison_Exp>;
  SHOPCLOSE?: InputMaybe<String_Comparison_Exp>;
  SHOPCLOSEREASON?: InputMaybe<String_Comparison_Exp>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Timestamptz_Comparison_Exp>;
  SHOPHANDOVER?: InputMaybe<String_Comparison_Exp>;
  SHOPOPEN?: InputMaybe<String_Comparison_Exp>;
  SHOPRENTSTART?: InputMaybe<String_Comparison_Exp>;
  SMERGETYPE?: InputMaybe<String_Comparison_Exp>;
  SMGMTFEE?: InputMaybe<Numeric_Comparison_Exp>;
  SNEWSPACE?: InputMaybe<String_Comparison_Exp>;
  SSETMERGESPACE1?: InputMaybe<String_Comparison_Exp>;
  SSETMERGESPACE2?: InputMaybe<String_Comparison_Exp>;
  SSETMERGESPACE3?: InputMaybe<String_Comparison_Exp>;
  SSETMERGESPACE4?: InputMaybe<String_Comparison_Exp>;
  SSETMERGESPACE5?: InputMaybe<String_Comparison_Exp>;
  SSETREMEASUREOF?: InputMaybe<String_Comparison_Exp>;
  SSETSUBSPACEOF?: InputMaybe<String_Comparison_Exp>;
  SSTATUS?: InputMaybe<String_Comparison_Exp>;
  SUNITCODE?: InputMaybe<String_Comparison_Exp>;
  SUNITTYPE?: InputMaybe<String_Comparison_Exp>;
  VALUATIONNETRENT?: InputMaybe<Numeric_Comparison_Exp>;
  VirtualUnitKey?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<YardiVirtualUnits_Bool_Exp>>;
  _not?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
  _or?: InputMaybe<Array<YardiVirtualUnits_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "YardiVirtualUnits" */
export enum YardiVirtualUnits_Constraint {
  /** unique or primary key constraint on columns "VirtualUnitKey" */
  YardiVirtualUnitsPkey = "YardiVirtualUnits_pkey",
}

/** input type for incrementing numeric columns in table "YardiVirtualUnits" */
export type YardiVirtualUnits_Inc_Input = {
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DAREA?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE1?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE2?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE3?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE4?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE5?: InputMaybe<Scalars["numeric"]>;
  HREMEASUREOF?: InputMaybe<Scalars["numeric"]>;
  HSUBSPACEOF?: InputMaybe<Scalars["numeric"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  MODIFAREA?: InputMaybe<Scalars["numeric"]>;
  MODIFBASERENT?: InputMaybe<Scalars["numeric"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "YardiVirtualUnits" */
export type YardiVirtualUnits_Insert_Input = {
  BudgetExpiryDate?: InputMaybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: InputMaybe<Scalars["String"]>;
  BudgetLeaseStart?: InputMaybe<Scalars["String"]>;
  BudgetMakegood?: InputMaybe<Scalars["String"]>;
  BudgetStatus?: InputMaybe<Scalars["String"]>;
  BudgetVacateDate?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DAREA?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: InputMaybe<Scalars["timestamptz"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["String"]>;
  FINCENTIVE?: InputMaybe<Scalars["String"]>;
  FLEASESTART?: InputMaybe<Scalars["String"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["String"]>;
  FMAKEGOOD?: InputMaybe<Scalars["String"]>;
  FOGPSM?: InputMaybe<Scalars["String"]>;
  FPROMO?: InputMaybe<Scalars["String"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  GENDEAL?: InputMaybe<Scalars["String"]>;
  GENDEALREASON?: InputMaybe<Scalars["String"]>;
  GENIGNORE?: InputMaybe<Scalars["String"]>;
  GENLECOMMENT?: InputMaybe<Scalars["String"]>;
  GENSHOP?: InputMaybe<Scalars["String"]>;
  HEADER1?: InputMaybe<Scalars["String"]>;
  HEADER1A?: InputMaybe<Scalars["String"]>;
  HEADER2?: InputMaybe<Scalars["String"]>;
  HEADER3?: InputMaybe<Scalars["String"]>;
  HMERGESPACE1?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE2?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE3?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE4?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE5?: InputMaybe<Scalars["numeric"]>;
  HREMEASUREOF?: InputMaybe<Scalars["numeric"]>;
  HSUBSPACEOF?: InputMaybe<Scalars["numeric"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  MODIFAREA?: InputMaybe<Scalars["numeric"]>;
  MODIFBASERENT?: InputMaybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: InputMaybe<Scalars["String"]>;
  MODIFINCENTIVE?: InputMaybe<Scalars["String"]>;
  MODIFLEASESTART?: InputMaybe<Scalars["String"]>;
  MODIFLEASETYPE?: InputMaybe<Scalars["String"]>;
  MODIFLESSORWORKS?: InputMaybe<Scalars["String"]>;
  MODIFMAKEGOOD?: InputMaybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: InputMaybe<Scalars["String"]>;
  MODIFOWNERNAME?: InputMaybe<Scalars["String"]>;
  MODIFPROMO?: InputMaybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: InputMaybe<Scalars["String"]>;
  MODIFSTATUS?: InputMaybe<Scalars["String"]>;
  MODIFTENANTNAME?: InputMaybe<Scalars["String"]>;
  MODIFVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: InputMaybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: InputMaybe<Scalars["String"]>;
  OVERRIDETYPE?: InputMaybe<Scalars["String"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["String"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  POGPSM?: InputMaybe<Scalars["String"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["String"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  Property?: InputMaybe<YardiProperties_Obj_Rel_Insert_Input>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: InputMaybe<Scalars["String"]>;
  SASSOCIATIONDESC?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SECTION6?: InputMaybe<Scalars["String"]>;
  SECTION7?: InputMaybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSEREASON?: InputMaybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: InputMaybe<Scalars["String"]>;
  SHOPOPEN?: InputMaybe<Scalars["String"]>;
  SHOPRENTSTART?: InputMaybe<Scalars["String"]>;
  SMERGETYPE?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  SNEWSPACE?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE1?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE2?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE3?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE4?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE5?: InputMaybe<Scalars["String"]>;
  SSETREMEASUREOF?: InputMaybe<Scalars["String"]>;
  SSETSUBSPACEOF?: InputMaybe<Scalars["String"]>;
  SSTATUS?: InputMaybe<Scalars["String"]>;
  SUNITCODE?: InputMaybe<Scalars["String"]>;
  SUNITTYPE?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type YardiVirtualUnits_Max_Fields = {
  __typename?: "YardiVirtualUnits_max_fields";
  BudgetExpiryDate?: Maybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: Maybe<Scalars["String"]>;
  BudgetLeaseStart?: Maybe<Scalars["String"]>;
  BudgetMakegood?: Maybe<Scalars["String"]>;
  BudgetStatus?: Maybe<Scalars["String"]>;
  BudgetVacateDate?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: Maybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DAREA?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: Maybe<Scalars["timestamptz"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["String"]>;
  FINCENTIVE?: Maybe<Scalars["String"]>;
  FLEASESTART?: Maybe<Scalars["String"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["String"]>;
  FMAKEGOOD?: Maybe<Scalars["String"]>;
  FOGPSM?: Maybe<Scalars["String"]>;
  FPROMO?: Maybe<Scalars["String"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: Maybe<Scalars["String"]>;
  GENDEAL?: Maybe<Scalars["String"]>;
  GENDEALREASON?: Maybe<Scalars["String"]>;
  GENIGNORE?: Maybe<Scalars["String"]>;
  GENLECOMMENT?: Maybe<Scalars["String"]>;
  GENSHOP?: Maybe<Scalars["String"]>;
  HEADER1?: Maybe<Scalars["String"]>;
  HEADER1A?: Maybe<Scalars["String"]>;
  HEADER2?: Maybe<Scalars["String"]>;
  HEADER3?: Maybe<Scalars["String"]>;
  HMERGESPACE1?: Maybe<Scalars["numeric"]>;
  HMERGESPACE2?: Maybe<Scalars["numeric"]>;
  HMERGESPACE3?: Maybe<Scalars["numeric"]>;
  HMERGESPACE4?: Maybe<Scalars["numeric"]>;
  HMERGESPACE5?: Maybe<Scalars["numeric"]>;
  HREMEASUREOF?: Maybe<Scalars["numeric"]>;
  HSUBSPACEOF?: Maybe<Scalars["numeric"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  MODIFAREA?: Maybe<Scalars["numeric"]>;
  MODIFBASERENT?: Maybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: Maybe<Scalars["String"]>;
  MODIFINCENTIVE?: Maybe<Scalars["String"]>;
  MODIFLEASESTART?: Maybe<Scalars["String"]>;
  MODIFLEASETYPE?: Maybe<Scalars["String"]>;
  MODIFLESSORWORKS?: Maybe<Scalars["String"]>;
  MODIFMAKEGOOD?: Maybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: Maybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: Maybe<Scalars["String"]>;
  MODIFOWNERNAME?: Maybe<Scalars["String"]>;
  MODIFPROMO?: Maybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: Maybe<Scalars["String"]>;
  MODIFSTATUS?: Maybe<Scalars["String"]>;
  MODIFTENANTNAME?: Maybe<Scalars["String"]>;
  MODIFVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: Maybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: Maybe<Scalars["String"]>;
  OVERRIDETYPE?: Maybe<Scalars["String"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["String"]>;
  PCAPOCCCOST?: Maybe<Scalars["String"]>;
  POGPSM?: Maybe<Scalars["String"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["String"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  RECONFIGEFFECTIVEDATE?: Maybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: Maybe<Scalars["String"]>;
  SASSOCIATIONDESC?: Maybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SECTION6?: Maybe<Scalars["String"]>;
  SECTION7?: Maybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSEREASON?: Maybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: Maybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: Maybe<Scalars["String"]>;
  SHOPOPEN?: Maybe<Scalars["String"]>;
  SHOPRENTSTART?: Maybe<Scalars["String"]>;
  SMERGETYPE?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  SNEWSPACE?: Maybe<Scalars["String"]>;
  SSETMERGESPACE1?: Maybe<Scalars["String"]>;
  SSETMERGESPACE2?: Maybe<Scalars["String"]>;
  SSETMERGESPACE3?: Maybe<Scalars["String"]>;
  SSETMERGESPACE4?: Maybe<Scalars["String"]>;
  SSETMERGESPACE5?: Maybe<Scalars["String"]>;
  SSETREMEASUREOF?: Maybe<Scalars["String"]>;
  SSETSUBSPACEOF?: Maybe<Scalars["String"]>;
  SSTATUS?: Maybe<Scalars["String"]>;
  SUNITCODE?: Maybe<Scalars["String"]>;
  SUNITTYPE?: Maybe<Scalars["String"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  VirtualUnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Max_Order_By = {
  BudgetExpiryDate?: InputMaybe<Order_By>;
  BudgetFitoutDays?: InputMaybe<Order_By>;
  BudgetLeaseStart?: InputMaybe<Order_By>;
  BudgetMakegood?: InputMaybe<Order_By>;
  BudgetStatus?: InputMaybe<Order_By>;
  BudgetVacateDate?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTCONCEPTION?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  GENCAPOCCCOST?: InputMaybe<Order_By>;
  GENDEAL?: InputMaybe<Order_By>;
  GENDEALREASON?: InputMaybe<Order_By>;
  GENIGNORE?: InputMaybe<Order_By>;
  GENLECOMMENT?: InputMaybe<Order_By>;
  GENSHOP?: InputMaybe<Order_By>;
  HEADER1?: InputMaybe<Order_By>;
  HEADER1A?: InputMaybe<Order_By>;
  HEADER2?: InputMaybe<Order_By>;
  HEADER3?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  MODIFEXPIRYDATE?: InputMaybe<Order_By>;
  MODIFFITOUT?: InputMaybe<Order_By>;
  MODIFINCENTIVE?: InputMaybe<Order_By>;
  MODIFLEASESTART?: InputMaybe<Order_By>;
  MODIFLEASETYPE?: InputMaybe<Order_By>;
  MODIFLESSORWORKS?: InputMaybe<Order_By>;
  MODIFMAKEGOOD?: InputMaybe<Order_By>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Order_By>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Order_By>;
  MODIFOGPSM?: InputMaybe<Order_By>;
  MODIFOWNERNAME?: InputMaybe<Order_By>;
  MODIFPROMO?: InputMaybe<Order_By>;
  MODIFRENTCOMMENCE?: InputMaybe<Order_By>;
  MODIFSTATUS?: InputMaybe<Order_By>;
  MODIFTENANTNAME?: InputMaybe<Order_By>;
  MODIFVACATEDATE?: InputMaybe<Order_By>;
  OVERRIDESALESCATEGORY?: InputMaybe<Order_By>;
  OVERRIDESALESTYPE?: InputMaybe<Order_By>;
  OVERRIDETYPE?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Order_By>;
  RECONFIGGROUP?: InputMaybe<Order_By>;
  SASSOCIATIONDESC?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SECTION6?: InputMaybe<Order_By>;
  SECTION7?: InputMaybe<Order_By>;
  SHOPACTUALCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSEREASON?: InputMaybe<Order_By>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Order_By>;
  SHOPHANDOVER?: InputMaybe<Order_By>;
  SHOPOPEN?: InputMaybe<Order_By>;
  SHOPRENTSTART?: InputMaybe<Order_By>;
  SMERGETYPE?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  SNEWSPACE?: InputMaybe<Order_By>;
  SSETMERGESPACE1?: InputMaybe<Order_By>;
  SSETMERGESPACE2?: InputMaybe<Order_By>;
  SSETMERGESPACE3?: InputMaybe<Order_By>;
  SSETMERGESPACE4?: InputMaybe<Order_By>;
  SSETMERGESPACE5?: InputMaybe<Order_By>;
  SSETREMEASUREOF?: InputMaybe<Order_By>;
  SSETSUBSPACEOF?: InputMaybe<Order_By>;
  SSTATUS?: InputMaybe<Order_By>;
  SUNITCODE?: InputMaybe<Order_By>;
  SUNITTYPE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  VirtualUnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type YardiVirtualUnits_Min_Fields = {
  __typename?: "YardiVirtualUnits_min_fields";
  BudgetExpiryDate?: Maybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: Maybe<Scalars["String"]>;
  BudgetLeaseStart?: Maybe<Scalars["String"]>;
  BudgetMakegood?: Maybe<Scalars["String"]>;
  BudgetStatus?: Maybe<Scalars["String"]>;
  BudgetVacateDate?: Maybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: Maybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DAREA?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: Maybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: Maybe<Scalars["timestamptz"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  FEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: Maybe<Scalars["String"]>;
  FINCENTIVE?: Maybe<Scalars["String"]>;
  FLEASESTART?: Maybe<Scalars["String"]>;
  FLEASETYPE?: Maybe<Scalars["String"]>;
  FLESSORWORKS?: Maybe<Scalars["String"]>;
  FMAKEGOOD?: Maybe<Scalars["String"]>;
  FOGPSM?: Maybe<Scalars["String"]>;
  FPROMO?: Maybe<Scalars["String"]>;
  FRENTCOMMENCE?: Maybe<Scalars["timestamptz"]>;
  FSTATUS?: Maybe<Scalars["String"]>;
  FVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: Maybe<Scalars["String"]>;
  GENDEAL?: Maybe<Scalars["String"]>;
  GENDEALREASON?: Maybe<Scalars["String"]>;
  GENIGNORE?: Maybe<Scalars["String"]>;
  GENLECOMMENT?: Maybe<Scalars["String"]>;
  GENSHOP?: Maybe<Scalars["String"]>;
  HEADER1?: Maybe<Scalars["String"]>;
  HEADER1A?: Maybe<Scalars["String"]>;
  HEADER2?: Maybe<Scalars["String"]>;
  HEADER3?: Maybe<Scalars["String"]>;
  HMERGESPACE1?: Maybe<Scalars["numeric"]>;
  HMERGESPACE2?: Maybe<Scalars["numeric"]>;
  HMERGESPACE3?: Maybe<Scalars["numeric"]>;
  HMERGESPACE4?: Maybe<Scalars["numeric"]>;
  HMERGESPACE5?: Maybe<Scalars["numeric"]>;
  HREMEASUREOF?: Maybe<Scalars["numeric"]>;
  HSUBSPACEOF?: Maybe<Scalars["numeric"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  MODIFAREA?: Maybe<Scalars["numeric"]>;
  MODIFBASERENT?: Maybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: Maybe<Scalars["String"]>;
  MODIFINCENTIVE?: Maybe<Scalars["String"]>;
  MODIFLEASESTART?: Maybe<Scalars["String"]>;
  MODIFLEASETYPE?: Maybe<Scalars["String"]>;
  MODIFLESSORWORKS?: Maybe<Scalars["String"]>;
  MODIFMAKEGOOD?: Maybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: Maybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: Maybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: Maybe<Scalars["String"]>;
  MODIFOWNERNAME?: Maybe<Scalars["String"]>;
  MODIFPROMO?: Maybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: Maybe<Scalars["String"]>;
  MODIFSTATUS?: Maybe<Scalars["String"]>;
  MODIFTENANTNAME?: Maybe<Scalars["String"]>;
  MODIFVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: Maybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: Maybe<Scalars["String"]>;
  OVERRIDETYPE?: Maybe<Scalars["String"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  PBASERENT?: Maybe<Scalars["String"]>;
  PCAPOCCCOST?: Maybe<Scalars["String"]>;
  POGPSM?: Maybe<Scalars["String"]>;
  POWNERNAME?: Maybe<Scalars["String"]>;
  PPROMO?: Maybe<Scalars["String"]>;
  PTENANT?: Maybe<Scalars["String"]>;
  PTENANTNAME?: Maybe<Scalars["String"]>;
  PVACATEDATE?: Maybe<Scalars["timestamptz"]>;
  PropertyCode?: Maybe<Scalars["String"]>;
  RECONFIGEFFECTIVEDATE?: Maybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: Maybe<Scalars["String"]>;
  SASSOCIATIONDESC?: Maybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: Maybe<Scalars["String"]>;
  SDESC?: Maybe<Scalars["String"]>;
  SECTION1?: Maybe<Scalars["String"]>;
  SECTION2?: Maybe<Scalars["String"]>;
  SECTION3?: Maybe<Scalars["String"]>;
  SECTION4?: Maybe<Scalars["String"]>;
  SECTION5?: Maybe<Scalars["String"]>;
  SECTION6?: Maybe<Scalars["String"]>;
  SECTION7?: Maybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSE?: Maybe<Scalars["String"]>;
  SHOPCLOSEREASON?: Maybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: Maybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: Maybe<Scalars["String"]>;
  SHOPOPEN?: Maybe<Scalars["String"]>;
  SHOPRENTSTART?: Maybe<Scalars["String"]>;
  SMERGETYPE?: Maybe<Scalars["String"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  SNEWSPACE?: Maybe<Scalars["String"]>;
  SSETMERGESPACE1?: Maybe<Scalars["String"]>;
  SSETMERGESPACE2?: Maybe<Scalars["String"]>;
  SSETMERGESPACE3?: Maybe<Scalars["String"]>;
  SSETMERGESPACE4?: Maybe<Scalars["String"]>;
  SSETMERGESPACE5?: Maybe<Scalars["String"]>;
  SSETREMEASUREOF?: Maybe<Scalars["String"]>;
  SSETSUBSPACEOF?: Maybe<Scalars["String"]>;
  SSTATUS?: Maybe<Scalars["String"]>;
  SUNITCODE?: Maybe<Scalars["String"]>;
  SUNITTYPE?: Maybe<Scalars["String"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
  VirtualUnitKey?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  modified_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Min_Order_By = {
  BudgetExpiryDate?: InputMaybe<Order_By>;
  BudgetFitoutDays?: InputMaybe<Order_By>;
  BudgetLeaseStart?: InputMaybe<Order_By>;
  BudgetMakegood?: InputMaybe<Order_By>;
  BudgetStatus?: InputMaybe<Order_By>;
  BudgetVacateDate?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTCONCEPTION?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  GENCAPOCCCOST?: InputMaybe<Order_By>;
  GENDEAL?: InputMaybe<Order_By>;
  GENDEALREASON?: InputMaybe<Order_By>;
  GENIGNORE?: InputMaybe<Order_By>;
  GENLECOMMENT?: InputMaybe<Order_By>;
  GENSHOP?: InputMaybe<Order_By>;
  HEADER1?: InputMaybe<Order_By>;
  HEADER1A?: InputMaybe<Order_By>;
  HEADER2?: InputMaybe<Order_By>;
  HEADER3?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  MODIFEXPIRYDATE?: InputMaybe<Order_By>;
  MODIFFITOUT?: InputMaybe<Order_By>;
  MODIFINCENTIVE?: InputMaybe<Order_By>;
  MODIFLEASESTART?: InputMaybe<Order_By>;
  MODIFLEASETYPE?: InputMaybe<Order_By>;
  MODIFLESSORWORKS?: InputMaybe<Order_By>;
  MODIFMAKEGOOD?: InputMaybe<Order_By>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Order_By>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Order_By>;
  MODIFOGPSM?: InputMaybe<Order_By>;
  MODIFOWNERNAME?: InputMaybe<Order_By>;
  MODIFPROMO?: InputMaybe<Order_By>;
  MODIFRENTCOMMENCE?: InputMaybe<Order_By>;
  MODIFSTATUS?: InputMaybe<Order_By>;
  MODIFTENANTNAME?: InputMaybe<Order_By>;
  MODIFVACATEDATE?: InputMaybe<Order_By>;
  OVERRIDESALESCATEGORY?: InputMaybe<Order_By>;
  OVERRIDESALESTYPE?: InputMaybe<Order_By>;
  OVERRIDETYPE?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Order_By>;
  RECONFIGGROUP?: InputMaybe<Order_By>;
  SASSOCIATIONDESC?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SECTION6?: InputMaybe<Order_By>;
  SECTION7?: InputMaybe<Order_By>;
  SHOPACTUALCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSEREASON?: InputMaybe<Order_By>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Order_By>;
  SHOPHANDOVER?: InputMaybe<Order_By>;
  SHOPOPEN?: InputMaybe<Order_By>;
  SHOPRENTSTART?: InputMaybe<Order_By>;
  SMERGETYPE?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  SNEWSPACE?: InputMaybe<Order_By>;
  SSETMERGESPACE1?: InputMaybe<Order_By>;
  SSETMERGESPACE2?: InputMaybe<Order_By>;
  SSETMERGESPACE3?: InputMaybe<Order_By>;
  SSETMERGESPACE4?: InputMaybe<Order_By>;
  SSETMERGESPACE5?: InputMaybe<Order_By>;
  SSETREMEASUREOF?: InputMaybe<Order_By>;
  SSETSUBSPACEOF?: InputMaybe<Order_By>;
  SSTATUS?: InputMaybe<Order_By>;
  SUNITCODE?: InputMaybe<Order_By>;
  SUNITTYPE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  VirtualUnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "YardiVirtualUnits" */
export type YardiVirtualUnits_Mutation_Response = {
  __typename?: "YardiVirtualUnits_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<YardiVirtualUnits>;
};

/** on_conflict condition type for table "YardiVirtualUnits" */
export type YardiVirtualUnits_On_Conflict = {
  constraint: YardiVirtualUnits_Constraint;
  update_columns?: Array<YardiVirtualUnits_Update_Column>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

/** Ordering options when selecting data from "YardiVirtualUnits". */
export type YardiVirtualUnits_Order_By = {
  BudgetExpiryDate?: InputMaybe<Order_By>;
  BudgetFitoutDays?: InputMaybe<Order_By>;
  BudgetLeaseStart?: InputMaybe<Order_By>;
  BudgetMakegood?: InputMaybe<Order_By>;
  BudgetStatus?: InputMaybe<Order_By>;
  BudgetVacateDate?: InputMaybe<Order_By>;
  CustomTableRecordCode?: InputMaybe<Order_By>;
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  DTBUDGETLEASEDATE?: InputMaybe<Order_By>;
  DTCONCEPTION?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  FEXPIRYDATE?: InputMaybe<Order_By>;
  FFITOUTDAYS?: InputMaybe<Order_By>;
  FINCENTIVE?: InputMaybe<Order_By>;
  FLEASESTART?: InputMaybe<Order_By>;
  FLEASETYPE?: InputMaybe<Order_By>;
  FLESSORWORKS?: InputMaybe<Order_By>;
  FMAKEGOOD?: InputMaybe<Order_By>;
  FOGPSM?: InputMaybe<Order_By>;
  FPROMO?: InputMaybe<Order_By>;
  FRENTCOMMENCE?: InputMaybe<Order_By>;
  FSTATUS?: InputMaybe<Order_By>;
  FVACATEDATE?: InputMaybe<Order_By>;
  GENCAPOCCCOST?: InputMaybe<Order_By>;
  GENDEAL?: InputMaybe<Order_By>;
  GENDEALREASON?: InputMaybe<Order_By>;
  GENIGNORE?: InputMaybe<Order_By>;
  GENLECOMMENT?: InputMaybe<Order_By>;
  GENSHOP?: InputMaybe<Order_By>;
  HEADER1?: InputMaybe<Order_By>;
  HEADER1A?: InputMaybe<Order_By>;
  HEADER2?: InputMaybe<Order_By>;
  HEADER3?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  MODIFEXPIRYDATE?: InputMaybe<Order_By>;
  MODIFFITOUT?: InputMaybe<Order_By>;
  MODIFINCENTIVE?: InputMaybe<Order_By>;
  MODIFLEASESTART?: InputMaybe<Order_By>;
  MODIFLEASETYPE?: InputMaybe<Order_By>;
  MODIFLESSORWORKS?: InputMaybe<Order_By>;
  MODIFMAKEGOOD?: InputMaybe<Order_By>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Order_By>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Order_By>;
  MODIFOGPSM?: InputMaybe<Order_By>;
  MODIFOWNERNAME?: InputMaybe<Order_By>;
  MODIFPROMO?: InputMaybe<Order_By>;
  MODIFRENTCOMMENCE?: InputMaybe<Order_By>;
  MODIFSTATUS?: InputMaybe<Order_By>;
  MODIFTENANTNAME?: InputMaybe<Order_By>;
  MODIFVACATEDATE?: InputMaybe<Order_By>;
  OVERRIDESALESCATEGORY?: InputMaybe<Order_By>;
  OVERRIDESALESTYPE?: InputMaybe<Order_By>;
  OVERRIDETYPE?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  PBASERENT?: InputMaybe<Order_By>;
  PCAPOCCCOST?: InputMaybe<Order_By>;
  POGPSM?: InputMaybe<Order_By>;
  POWNERNAME?: InputMaybe<Order_By>;
  PPROMO?: InputMaybe<Order_By>;
  PTENANT?: InputMaybe<Order_By>;
  PTENANTNAME?: InputMaybe<Order_By>;
  PVACATEDATE?: InputMaybe<Order_By>;
  Property?: InputMaybe<YardiProperties_Order_By>;
  PropertyCode?: InputMaybe<Order_By>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Order_By>;
  RECONFIGGROUP?: InputMaybe<Order_By>;
  SASSOCIATIONDESC?: InputMaybe<Order_By>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Order_By>;
  SDESC?: InputMaybe<Order_By>;
  SECTION1?: InputMaybe<Order_By>;
  SECTION2?: InputMaybe<Order_By>;
  SECTION3?: InputMaybe<Order_By>;
  SECTION4?: InputMaybe<Order_By>;
  SECTION5?: InputMaybe<Order_By>;
  SECTION6?: InputMaybe<Order_By>;
  SECTION7?: InputMaybe<Order_By>;
  SHOPACTUALCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSE?: InputMaybe<Order_By>;
  SHOPCLOSEREASON?: InputMaybe<Order_By>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Order_By>;
  SHOPHANDOVER?: InputMaybe<Order_By>;
  SHOPOPEN?: InputMaybe<Order_By>;
  SHOPRENTSTART?: InputMaybe<Order_By>;
  SMERGETYPE?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  SNEWSPACE?: InputMaybe<Order_By>;
  SSETMERGESPACE1?: InputMaybe<Order_By>;
  SSETMERGESPACE2?: InputMaybe<Order_By>;
  SSETMERGESPACE3?: InputMaybe<Order_By>;
  SSETMERGESPACE4?: InputMaybe<Order_By>;
  SSETMERGESPACE5?: InputMaybe<Order_By>;
  SSETREMEASUREOF?: InputMaybe<Order_By>;
  SSETSUBSPACEOF?: InputMaybe<Order_By>;
  SSTATUS?: InputMaybe<Order_By>;
  SUNITCODE?: InputMaybe<Order_By>;
  SUNITTYPE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
  VirtualUnitKey?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: YardiVirtualUnits */
export type YardiVirtualUnits_Pk_Columns_Input = {
  VirtualUnitKey: Scalars["String"];
};

/** select columns of table "YardiVirtualUnits" */
export enum YardiVirtualUnits_Select_Column {
  /** column name */
  BudgetExpiryDate = "BudgetExpiryDate",
  /** column name */
  BudgetFitoutDays = "BudgetFitoutDays",
  /** column name */
  BudgetLeaseStart = "BudgetLeaseStart",
  /** column name */
  BudgetMakegood = "BudgetMakegood",
  /** column name */
  BudgetStatus = "BudgetStatus",
  /** column name */
  BudgetVacateDate = "BudgetVacateDate",
  /** column name */
  CustomTableRecordCode = "CustomTableRecordCode",
  /** column name */
  Dannualpromolevy = "DANNUALPROMOLEVY",
  /** column name */
  Dannualrecoverypsm = "DANNUALRECOVERYPSM",
  /** column name */
  Dannualrent = "DANNUALRENT",
  /** column name */
  Darea = "DAREA",
  /** column name */
  Dbudgetedlandlordworkcost = "DBUDGETEDLANDLORDWORKCOST",
  /** column name */
  Dbudgetlandlordcontribtofitout = "DBUDGETLANDLORDCONTRIBTOFITOUT",
  /** column name */
  Dtbudgetleasedate = "DTBUDGETLEASEDATE",
  /** column name */
  Dtconception = "DTCONCEPTION",
  /** column name */
  Fbaserent = "FBASERENT",
  /** column name */
  Fexpirydate = "FEXPIRYDATE",
  /** column name */
  Ffitoutdays = "FFITOUTDAYS",
  /** column name */
  Fincentive = "FINCENTIVE",
  /** column name */
  Fleasestart = "FLEASESTART",
  /** column name */
  Fleasetype = "FLEASETYPE",
  /** column name */
  Flessorworks = "FLESSORWORKS",
  /** column name */
  Fmakegood = "FMAKEGOOD",
  /** column name */
  Fogpsm = "FOGPSM",
  /** column name */
  Fpromo = "FPROMO",
  /** column name */
  Frentcommence = "FRENTCOMMENCE",
  /** column name */
  Fstatus = "FSTATUS",
  /** column name */
  Fvacatedate = "FVACATEDATE",
  /** column name */
  Gencapocccost = "GENCAPOCCCOST",
  /** column name */
  Gendeal = "GENDEAL",
  /** column name */
  Gendealreason = "GENDEALREASON",
  /** column name */
  Genignore = "GENIGNORE",
  /** column name */
  Genlecomment = "GENLECOMMENT",
  /** column name */
  Genshop = "GENSHOP",
  /** column name */
  Header1 = "HEADER1",
  /** column name */
  Header1A = "HEADER1A",
  /** column name */
  Header2 = "HEADER2",
  /** column name */
  Header3 = "HEADER3",
  /** column name */
  Hmergespace1 = "HMERGESPACE1",
  /** column name */
  Hmergespace2 = "HMERGESPACE2",
  /** column name */
  Hmergespace3 = "HMERGESPACE3",
  /** column name */
  Hmergespace4 = "HMERGESPACE4",
  /** column name */
  Hmergespace5 = "HMERGESPACE5",
  /** column name */
  Hremeasureof = "HREMEASUREOF",
  /** column name */
  Hsubspaceof = "HSUBSPACEOF",
  /** column name */
  Inhousenetrent = "INHOUSENETRENT",
  /** column name */
  Modifarea = "MODIFAREA",
  /** column name */
  Modifbaserent = "MODIFBASERENT",
  /** column name */
  Modifexpirydate = "MODIFEXPIRYDATE",
  /** column name */
  Modiffitout = "MODIFFITOUT",
  /** column name */
  Modifincentive = "MODIFINCENTIVE",
  /** column name */
  Modifleasestart = "MODIFLEASESTART",
  /** column name */
  Modifleasetype = "MODIFLEASETYPE",
  /** column name */
  Modiflessorworks = "MODIFLESSORWORKS",
  /** column name */
  Modifmakegood = "MODIFMAKEGOOD",
  /** column name */
  Modifnextrentreview = "MODIFNEXTRENTREVIEW",
  /** column name */
  Modifnextreviewdate = "MODIFNEXTREVIEWDATE",
  /** column name */
  Modifogpsm = "MODIFOGPSM",
  /** column name */
  Modifownername = "MODIFOWNERNAME",
  /** column name */
  Modifpromo = "MODIFPROMO",
  /** column name */
  Modifrentcommence = "MODIFRENTCOMMENCE",
  /** column name */
  Modifstatus = "MODIFSTATUS",
  /** column name */
  Modiftenantname = "MODIFTENANTNAME",
  /** column name */
  Modifvacatedate = "MODIFVACATEDATE",
  /** column name */
  Overridesalescategory = "OVERRIDESALESCATEGORY",
  /** column name */
  Overridesalestype = "OVERRIDESALESTYPE",
  /** column name */
  Overridetype = "OVERRIDETYPE",
  /** column name */
  Parea = "PAREA",
  /** column name */
  Pbaserent = "PBASERENT",
  /** column name */
  Pcapocccost = "PCAPOCCCOST",
  /** column name */
  Pogpsm = "POGPSM",
  /** column name */
  Pownername = "POWNERNAME",
  /** column name */
  Ppromo = "PPROMO",
  /** column name */
  Ptenant = "PTENANT",
  /** column name */
  Ptenantname = "PTENANTNAME",
  /** column name */
  Pvacatedate = "PVACATEDATE",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Reconfigeffectivedate = "RECONFIGEFFECTIVEDATE",
  /** column name */
  Reconfiggroup = "RECONFIGGROUP",
  /** column name */
  Sassociationdesc = "SASSOCIATIONDESC",
  /** column name */
  Sdefaultrecoveryprofiledesc = "SDEFAULTRECOVERYPROFILEDESC",
  /** column name */
  Sdesc = "SDESC",
  /** column name */
  Section1 = "SECTION1",
  /** column name */
  Section2 = "SECTION2",
  /** column name */
  Section3 = "SECTION3",
  /** column name */
  Section4 = "SECTION4",
  /** column name */
  Section5 = "SECTION5",
  /** column name */
  Section6 = "SECTION6",
  /** column name */
  Section7 = "SECTION7",
  /** column name */
  Shopactualclose = "SHOPACTUALCLOSE",
  /** column name */
  Shopclose = "SHOPCLOSE",
  /** column name */
  Shopclosereason = "SHOPCLOSEREASON",
  /** column name */
  Shopforecasttradedate = "SHOPFORECASTTRADEDATE",
  /** column name */
  Shophandover = "SHOPHANDOVER",
  /** column name */
  Shopopen = "SHOPOPEN",
  /** column name */
  Shoprentstart = "SHOPRENTSTART",
  /** column name */
  Smergetype = "SMERGETYPE",
  /** column name */
  Smgmtfee = "SMGMTFEE",
  /** column name */
  Snewspace = "SNEWSPACE",
  /** column name */
  Ssetmergespace1 = "SSETMERGESPACE1",
  /** column name */
  Ssetmergespace2 = "SSETMERGESPACE2",
  /** column name */
  Ssetmergespace3 = "SSETMERGESPACE3",
  /** column name */
  Ssetmergespace4 = "SSETMERGESPACE4",
  /** column name */
  Ssetmergespace5 = "SSETMERGESPACE5",
  /** column name */
  Ssetremeasureof = "SSETREMEASUREOF",
  /** column name */
  Ssetsubspaceof = "SSETSUBSPACEOF",
  /** column name */
  Sstatus = "SSTATUS",
  /** column name */
  Sunitcode = "SUNITCODE",
  /** column name */
  Sunittype = "SUNITTYPE",
  /** column name */
  Valuationnetrent = "VALUATIONNETRENT",
  /** column name */
  VirtualUnitKey = "VirtualUnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

/** input type for updating data in table "YardiVirtualUnits" */
export type YardiVirtualUnits_Set_Input = {
  BudgetExpiryDate?: InputMaybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: InputMaybe<Scalars["String"]>;
  BudgetLeaseStart?: InputMaybe<Scalars["String"]>;
  BudgetMakegood?: InputMaybe<Scalars["String"]>;
  BudgetStatus?: InputMaybe<Scalars["String"]>;
  BudgetVacateDate?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DAREA?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: InputMaybe<Scalars["timestamptz"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["String"]>;
  FINCENTIVE?: InputMaybe<Scalars["String"]>;
  FLEASESTART?: InputMaybe<Scalars["String"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["String"]>;
  FMAKEGOOD?: InputMaybe<Scalars["String"]>;
  FOGPSM?: InputMaybe<Scalars["String"]>;
  FPROMO?: InputMaybe<Scalars["String"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  GENDEAL?: InputMaybe<Scalars["String"]>;
  GENDEALREASON?: InputMaybe<Scalars["String"]>;
  GENIGNORE?: InputMaybe<Scalars["String"]>;
  GENLECOMMENT?: InputMaybe<Scalars["String"]>;
  GENSHOP?: InputMaybe<Scalars["String"]>;
  HEADER1?: InputMaybe<Scalars["String"]>;
  HEADER1A?: InputMaybe<Scalars["String"]>;
  HEADER2?: InputMaybe<Scalars["String"]>;
  HEADER3?: InputMaybe<Scalars["String"]>;
  HMERGESPACE1?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE2?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE3?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE4?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE5?: InputMaybe<Scalars["numeric"]>;
  HREMEASUREOF?: InputMaybe<Scalars["numeric"]>;
  HSUBSPACEOF?: InputMaybe<Scalars["numeric"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  MODIFAREA?: InputMaybe<Scalars["numeric"]>;
  MODIFBASERENT?: InputMaybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: InputMaybe<Scalars["String"]>;
  MODIFINCENTIVE?: InputMaybe<Scalars["String"]>;
  MODIFLEASESTART?: InputMaybe<Scalars["String"]>;
  MODIFLEASETYPE?: InputMaybe<Scalars["String"]>;
  MODIFLESSORWORKS?: InputMaybe<Scalars["String"]>;
  MODIFMAKEGOOD?: InputMaybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: InputMaybe<Scalars["String"]>;
  MODIFOWNERNAME?: InputMaybe<Scalars["String"]>;
  MODIFPROMO?: InputMaybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: InputMaybe<Scalars["String"]>;
  MODIFSTATUS?: InputMaybe<Scalars["String"]>;
  MODIFTENANTNAME?: InputMaybe<Scalars["String"]>;
  MODIFVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: InputMaybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: InputMaybe<Scalars["String"]>;
  OVERRIDETYPE?: InputMaybe<Scalars["String"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["String"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  POGPSM?: InputMaybe<Scalars["String"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["String"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: InputMaybe<Scalars["String"]>;
  SASSOCIATIONDESC?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SECTION6?: InputMaybe<Scalars["String"]>;
  SECTION7?: InputMaybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSEREASON?: InputMaybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: InputMaybe<Scalars["String"]>;
  SHOPOPEN?: InputMaybe<Scalars["String"]>;
  SHOPRENTSTART?: InputMaybe<Scalars["String"]>;
  SMERGETYPE?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  SNEWSPACE?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE1?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE2?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE3?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE4?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE5?: InputMaybe<Scalars["String"]>;
  SSETREMEASUREOF?: InputMaybe<Scalars["String"]>;
  SSETSUBSPACEOF?: InputMaybe<Scalars["String"]>;
  SSTATUS?: InputMaybe<Scalars["String"]>;
  SUNITCODE?: InputMaybe<Scalars["String"]>;
  SUNITTYPE?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type YardiVirtualUnits_Stddev_Fields = {
  __typename?: "YardiVirtualUnits_stddev_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Stddev_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type YardiVirtualUnits_Stddev_Pop_Fields = {
  __typename?: "YardiVirtualUnits_stddev_pop_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Stddev_Pop_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type YardiVirtualUnits_Stddev_Samp_Fields = {
  __typename?: "YardiVirtualUnits_stddev_samp_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Stddev_Samp_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "YardiVirtualUnits" */
export type YardiVirtualUnits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: YardiVirtualUnits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type YardiVirtualUnits_Stream_Cursor_Value_Input = {
  BudgetExpiryDate?: InputMaybe<Scalars["timestamptz"]>;
  BudgetFitoutDays?: InputMaybe<Scalars["String"]>;
  BudgetLeaseStart?: InputMaybe<Scalars["String"]>;
  BudgetMakegood?: InputMaybe<Scalars["String"]>;
  BudgetStatus?: InputMaybe<Scalars["String"]>;
  BudgetVacateDate?: InputMaybe<Scalars["timestamptz"]>;
  CustomTableRecordCode?: InputMaybe<Scalars["String"]>;
  DANNUALPROMOLEVY?: InputMaybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: InputMaybe<Scalars["numeric"]>;
  DANNUALRENT?: InputMaybe<Scalars["numeric"]>;
  DAREA?: InputMaybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Scalars["numeric"]>;
  DTBUDGETLEASEDATE?: InputMaybe<Scalars["timestamptz"]>;
  DTCONCEPTION?: InputMaybe<Scalars["timestamptz"]>;
  FBASERENT?: InputMaybe<Scalars["numeric"]>;
  FEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  FFITOUTDAYS?: InputMaybe<Scalars["String"]>;
  FINCENTIVE?: InputMaybe<Scalars["String"]>;
  FLEASESTART?: InputMaybe<Scalars["String"]>;
  FLEASETYPE?: InputMaybe<Scalars["String"]>;
  FLESSORWORKS?: InputMaybe<Scalars["String"]>;
  FMAKEGOOD?: InputMaybe<Scalars["String"]>;
  FOGPSM?: InputMaybe<Scalars["String"]>;
  FPROMO?: InputMaybe<Scalars["String"]>;
  FRENTCOMMENCE?: InputMaybe<Scalars["timestamptz"]>;
  FSTATUS?: InputMaybe<Scalars["String"]>;
  FVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  GENCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  GENDEAL?: InputMaybe<Scalars["String"]>;
  GENDEALREASON?: InputMaybe<Scalars["String"]>;
  GENIGNORE?: InputMaybe<Scalars["String"]>;
  GENLECOMMENT?: InputMaybe<Scalars["String"]>;
  GENSHOP?: InputMaybe<Scalars["String"]>;
  HEADER1?: InputMaybe<Scalars["String"]>;
  HEADER1A?: InputMaybe<Scalars["String"]>;
  HEADER2?: InputMaybe<Scalars["String"]>;
  HEADER3?: InputMaybe<Scalars["String"]>;
  HMERGESPACE1?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE2?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE3?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE4?: InputMaybe<Scalars["numeric"]>;
  HMERGESPACE5?: InputMaybe<Scalars["numeric"]>;
  HREMEASUREOF?: InputMaybe<Scalars["numeric"]>;
  HSUBSPACEOF?: InputMaybe<Scalars["numeric"]>;
  INHOUSENETRENT?: InputMaybe<Scalars["numeric"]>;
  MODIFAREA?: InputMaybe<Scalars["numeric"]>;
  MODIFBASERENT?: InputMaybe<Scalars["numeric"]>;
  MODIFEXPIRYDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFFITOUT?: InputMaybe<Scalars["String"]>;
  MODIFINCENTIVE?: InputMaybe<Scalars["String"]>;
  MODIFLEASESTART?: InputMaybe<Scalars["String"]>;
  MODIFLEASETYPE?: InputMaybe<Scalars["String"]>;
  MODIFLESSORWORKS?: InputMaybe<Scalars["String"]>;
  MODIFMAKEGOOD?: InputMaybe<Scalars["String"]>;
  MODIFNEXTRENTREVIEW?: InputMaybe<Scalars["String"]>;
  MODIFNEXTREVIEWDATE?: InputMaybe<Scalars["timestamptz"]>;
  MODIFOGPSM?: InputMaybe<Scalars["String"]>;
  MODIFOWNERNAME?: InputMaybe<Scalars["String"]>;
  MODIFPROMO?: InputMaybe<Scalars["String"]>;
  MODIFRENTCOMMENCE?: InputMaybe<Scalars["String"]>;
  MODIFSTATUS?: InputMaybe<Scalars["String"]>;
  MODIFTENANTNAME?: InputMaybe<Scalars["String"]>;
  MODIFVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  OVERRIDESALESCATEGORY?: InputMaybe<Scalars["String"]>;
  OVERRIDESALESTYPE?: InputMaybe<Scalars["String"]>;
  OVERRIDETYPE?: InputMaybe<Scalars["String"]>;
  PAREA?: InputMaybe<Scalars["numeric"]>;
  PBASERENT?: InputMaybe<Scalars["String"]>;
  PCAPOCCCOST?: InputMaybe<Scalars["String"]>;
  POGPSM?: InputMaybe<Scalars["String"]>;
  POWNERNAME?: InputMaybe<Scalars["String"]>;
  PPROMO?: InputMaybe<Scalars["String"]>;
  PTENANT?: InputMaybe<Scalars["String"]>;
  PTENANTNAME?: InputMaybe<Scalars["String"]>;
  PVACATEDATE?: InputMaybe<Scalars["timestamptz"]>;
  PropertyCode?: InputMaybe<Scalars["String"]>;
  RECONFIGEFFECTIVEDATE?: InputMaybe<Scalars["timestamptz"]>;
  RECONFIGGROUP?: InputMaybe<Scalars["String"]>;
  SASSOCIATIONDESC?: InputMaybe<Scalars["String"]>;
  SDEFAULTRECOVERYPROFILEDESC?: InputMaybe<Scalars["String"]>;
  SDESC?: InputMaybe<Scalars["String"]>;
  SECTION1?: InputMaybe<Scalars["String"]>;
  SECTION2?: InputMaybe<Scalars["String"]>;
  SECTION3?: InputMaybe<Scalars["String"]>;
  SECTION4?: InputMaybe<Scalars["String"]>;
  SECTION5?: InputMaybe<Scalars["String"]>;
  SECTION6?: InputMaybe<Scalars["String"]>;
  SECTION7?: InputMaybe<Scalars["String"]>;
  SHOPACTUALCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSE?: InputMaybe<Scalars["String"]>;
  SHOPCLOSEREASON?: InputMaybe<Scalars["String"]>;
  SHOPFORECASTTRADEDATE?: InputMaybe<Scalars["timestamptz"]>;
  SHOPHANDOVER?: InputMaybe<Scalars["String"]>;
  SHOPOPEN?: InputMaybe<Scalars["String"]>;
  SHOPRENTSTART?: InputMaybe<Scalars["String"]>;
  SMERGETYPE?: InputMaybe<Scalars["String"]>;
  SMGMTFEE?: InputMaybe<Scalars["numeric"]>;
  SNEWSPACE?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE1?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE2?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE3?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE4?: InputMaybe<Scalars["String"]>;
  SSETMERGESPACE5?: InputMaybe<Scalars["String"]>;
  SSETREMEASUREOF?: InputMaybe<Scalars["String"]>;
  SSETSUBSPACEOF?: InputMaybe<Scalars["String"]>;
  SSTATUS?: InputMaybe<Scalars["String"]>;
  SUNITCODE?: InputMaybe<Scalars["String"]>;
  SUNITTYPE?: InputMaybe<Scalars["String"]>;
  VALUATIONNETRENT?: InputMaybe<Scalars["numeric"]>;
  VirtualUnitKey?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  deleted_at?: InputMaybe<Scalars["timestamptz"]>;
  modified_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type YardiVirtualUnits_Sum_Fields = {
  __typename?: "YardiVirtualUnits_sum_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["numeric"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["numeric"]>;
  DANNUALRENT?: Maybe<Scalars["numeric"]>;
  DAREA?: Maybe<Scalars["numeric"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["numeric"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["numeric"]>;
  FBASERENT?: Maybe<Scalars["numeric"]>;
  HMERGESPACE1?: Maybe<Scalars["numeric"]>;
  HMERGESPACE2?: Maybe<Scalars["numeric"]>;
  HMERGESPACE3?: Maybe<Scalars["numeric"]>;
  HMERGESPACE4?: Maybe<Scalars["numeric"]>;
  HMERGESPACE5?: Maybe<Scalars["numeric"]>;
  HREMEASUREOF?: Maybe<Scalars["numeric"]>;
  HSUBSPACEOF?: Maybe<Scalars["numeric"]>;
  INHOUSENETRENT?: Maybe<Scalars["numeric"]>;
  MODIFAREA?: Maybe<Scalars["numeric"]>;
  MODIFBASERENT?: Maybe<Scalars["numeric"]>;
  PAREA?: Maybe<Scalars["numeric"]>;
  SMGMTFEE?: Maybe<Scalars["numeric"]>;
  VALUATIONNETRENT?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Sum_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** update columns of table "YardiVirtualUnits" */
export enum YardiVirtualUnits_Update_Column {
  /** column name */
  BudgetExpiryDate = "BudgetExpiryDate",
  /** column name */
  BudgetFitoutDays = "BudgetFitoutDays",
  /** column name */
  BudgetLeaseStart = "BudgetLeaseStart",
  /** column name */
  BudgetMakegood = "BudgetMakegood",
  /** column name */
  BudgetStatus = "BudgetStatus",
  /** column name */
  BudgetVacateDate = "BudgetVacateDate",
  /** column name */
  CustomTableRecordCode = "CustomTableRecordCode",
  /** column name */
  Dannualpromolevy = "DANNUALPROMOLEVY",
  /** column name */
  Dannualrecoverypsm = "DANNUALRECOVERYPSM",
  /** column name */
  Dannualrent = "DANNUALRENT",
  /** column name */
  Darea = "DAREA",
  /** column name */
  Dbudgetedlandlordworkcost = "DBUDGETEDLANDLORDWORKCOST",
  /** column name */
  Dbudgetlandlordcontribtofitout = "DBUDGETLANDLORDCONTRIBTOFITOUT",
  /** column name */
  Dtbudgetleasedate = "DTBUDGETLEASEDATE",
  /** column name */
  Dtconception = "DTCONCEPTION",
  /** column name */
  Fbaserent = "FBASERENT",
  /** column name */
  Fexpirydate = "FEXPIRYDATE",
  /** column name */
  Ffitoutdays = "FFITOUTDAYS",
  /** column name */
  Fincentive = "FINCENTIVE",
  /** column name */
  Fleasestart = "FLEASESTART",
  /** column name */
  Fleasetype = "FLEASETYPE",
  /** column name */
  Flessorworks = "FLESSORWORKS",
  /** column name */
  Fmakegood = "FMAKEGOOD",
  /** column name */
  Fogpsm = "FOGPSM",
  /** column name */
  Fpromo = "FPROMO",
  /** column name */
  Frentcommence = "FRENTCOMMENCE",
  /** column name */
  Fstatus = "FSTATUS",
  /** column name */
  Fvacatedate = "FVACATEDATE",
  /** column name */
  Gencapocccost = "GENCAPOCCCOST",
  /** column name */
  Gendeal = "GENDEAL",
  /** column name */
  Gendealreason = "GENDEALREASON",
  /** column name */
  Genignore = "GENIGNORE",
  /** column name */
  Genlecomment = "GENLECOMMENT",
  /** column name */
  Genshop = "GENSHOP",
  /** column name */
  Header1 = "HEADER1",
  /** column name */
  Header1A = "HEADER1A",
  /** column name */
  Header2 = "HEADER2",
  /** column name */
  Header3 = "HEADER3",
  /** column name */
  Hmergespace1 = "HMERGESPACE1",
  /** column name */
  Hmergespace2 = "HMERGESPACE2",
  /** column name */
  Hmergespace3 = "HMERGESPACE3",
  /** column name */
  Hmergespace4 = "HMERGESPACE4",
  /** column name */
  Hmergespace5 = "HMERGESPACE5",
  /** column name */
  Hremeasureof = "HREMEASUREOF",
  /** column name */
  Hsubspaceof = "HSUBSPACEOF",
  /** column name */
  Inhousenetrent = "INHOUSENETRENT",
  /** column name */
  Modifarea = "MODIFAREA",
  /** column name */
  Modifbaserent = "MODIFBASERENT",
  /** column name */
  Modifexpirydate = "MODIFEXPIRYDATE",
  /** column name */
  Modiffitout = "MODIFFITOUT",
  /** column name */
  Modifincentive = "MODIFINCENTIVE",
  /** column name */
  Modifleasestart = "MODIFLEASESTART",
  /** column name */
  Modifleasetype = "MODIFLEASETYPE",
  /** column name */
  Modiflessorworks = "MODIFLESSORWORKS",
  /** column name */
  Modifmakegood = "MODIFMAKEGOOD",
  /** column name */
  Modifnextrentreview = "MODIFNEXTRENTREVIEW",
  /** column name */
  Modifnextreviewdate = "MODIFNEXTREVIEWDATE",
  /** column name */
  Modifogpsm = "MODIFOGPSM",
  /** column name */
  Modifownername = "MODIFOWNERNAME",
  /** column name */
  Modifpromo = "MODIFPROMO",
  /** column name */
  Modifrentcommence = "MODIFRENTCOMMENCE",
  /** column name */
  Modifstatus = "MODIFSTATUS",
  /** column name */
  Modiftenantname = "MODIFTENANTNAME",
  /** column name */
  Modifvacatedate = "MODIFVACATEDATE",
  /** column name */
  Overridesalescategory = "OVERRIDESALESCATEGORY",
  /** column name */
  Overridesalestype = "OVERRIDESALESTYPE",
  /** column name */
  Overridetype = "OVERRIDETYPE",
  /** column name */
  Parea = "PAREA",
  /** column name */
  Pbaserent = "PBASERENT",
  /** column name */
  Pcapocccost = "PCAPOCCCOST",
  /** column name */
  Pogpsm = "POGPSM",
  /** column name */
  Pownername = "POWNERNAME",
  /** column name */
  Ppromo = "PPROMO",
  /** column name */
  Ptenant = "PTENANT",
  /** column name */
  Ptenantname = "PTENANTNAME",
  /** column name */
  Pvacatedate = "PVACATEDATE",
  /** column name */
  PropertyCode = "PropertyCode",
  /** column name */
  Reconfigeffectivedate = "RECONFIGEFFECTIVEDATE",
  /** column name */
  Reconfiggroup = "RECONFIGGROUP",
  /** column name */
  Sassociationdesc = "SASSOCIATIONDESC",
  /** column name */
  Sdefaultrecoveryprofiledesc = "SDEFAULTRECOVERYPROFILEDESC",
  /** column name */
  Sdesc = "SDESC",
  /** column name */
  Section1 = "SECTION1",
  /** column name */
  Section2 = "SECTION2",
  /** column name */
  Section3 = "SECTION3",
  /** column name */
  Section4 = "SECTION4",
  /** column name */
  Section5 = "SECTION5",
  /** column name */
  Section6 = "SECTION6",
  /** column name */
  Section7 = "SECTION7",
  /** column name */
  Shopactualclose = "SHOPACTUALCLOSE",
  /** column name */
  Shopclose = "SHOPCLOSE",
  /** column name */
  Shopclosereason = "SHOPCLOSEREASON",
  /** column name */
  Shopforecasttradedate = "SHOPFORECASTTRADEDATE",
  /** column name */
  Shophandover = "SHOPHANDOVER",
  /** column name */
  Shopopen = "SHOPOPEN",
  /** column name */
  Shoprentstart = "SHOPRENTSTART",
  /** column name */
  Smergetype = "SMERGETYPE",
  /** column name */
  Smgmtfee = "SMGMTFEE",
  /** column name */
  Snewspace = "SNEWSPACE",
  /** column name */
  Ssetmergespace1 = "SSETMERGESPACE1",
  /** column name */
  Ssetmergespace2 = "SSETMERGESPACE2",
  /** column name */
  Ssetmergespace3 = "SSETMERGESPACE3",
  /** column name */
  Ssetmergespace4 = "SSETMERGESPACE4",
  /** column name */
  Ssetmergespace5 = "SSETMERGESPACE5",
  /** column name */
  Ssetremeasureof = "SSETREMEASUREOF",
  /** column name */
  Ssetsubspaceof = "SSETSUBSPACEOF",
  /** column name */
  Sstatus = "SSTATUS",
  /** column name */
  Sunitcode = "SUNITCODE",
  /** column name */
  Sunittype = "SUNITTYPE",
  /** column name */
  Valuationnetrent = "VALUATIONNETRENT",
  /** column name */
  VirtualUnitKey = "VirtualUnitKey",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  ModifiedAt = "modified_at",
}

export type YardiVirtualUnits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<YardiVirtualUnits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YardiVirtualUnits_Set_Input>;
  /** filter the rows which have to be updated */
  where: YardiVirtualUnits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type YardiVirtualUnits_Var_Pop_Fields = {
  __typename?: "YardiVirtualUnits_var_pop_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Var_Pop_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type YardiVirtualUnits_Var_Samp_Fields = {
  __typename?: "YardiVirtualUnits_var_samp_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Var_Samp_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type YardiVirtualUnits_Variance_Fields = {
  __typename?: "YardiVirtualUnits_variance_fields";
  DANNUALPROMOLEVY?: Maybe<Scalars["Float"]>;
  DANNUALRECOVERYPSM?: Maybe<Scalars["Float"]>;
  DANNUALRENT?: Maybe<Scalars["Float"]>;
  DAREA?: Maybe<Scalars["Float"]>;
  DBUDGETEDLANDLORDWORKCOST?: Maybe<Scalars["Float"]>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: Maybe<Scalars["Float"]>;
  FBASERENT?: Maybe<Scalars["Float"]>;
  HMERGESPACE1?: Maybe<Scalars["Float"]>;
  HMERGESPACE2?: Maybe<Scalars["Float"]>;
  HMERGESPACE3?: Maybe<Scalars["Float"]>;
  HMERGESPACE4?: Maybe<Scalars["Float"]>;
  HMERGESPACE5?: Maybe<Scalars["Float"]>;
  HREMEASUREOF?: Maybe<Scalars["Float"]>;
  HSUBSPACEOF?: Maybe<Scalars["Float"]>;
  INHOUSENETRENT?: Maybe<Scalars["Float"]>;
  MODIFAREA?: Maybe<Scalars["Float"]>;
  MODIFBASERENT?: Maybe<Scalars["Float"]>;
  PAREA?: Maybe<Scalars["Float"]>;
  SMGMTFEE?: Maybe<Scalars["Float"]>;
  VALUATIONNETRENT?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "YardiVirtualUnits" */
export type YardiVirtualUnits_Variance_Order_By = {
  DANNUALPROMOLEVY?: InputMaybe<Order_By>;
  DANNUALRECOVERYPSM?: InputMaybe<Order_By>;
  DANNUALRENT?: InputMaybe<Order_By>;
  DAREA?: InputMaybe<Order_By>;
  DBUDGETEDLANDLORDWORKCOST?: InputMaybe<Order_By>;
  DBUDGETLANDLORDCONTRIBTOFITOUT?: InputMaybe<Order_By>;
  FBASERENT?: InputMaybe<Order_By>;
  HMERGESPACE1?: InputMaybe<Order_By>;
  HMERGESPACE2?: InputMaybe<Order_By>;
  HMERGESPACE3?: InputMaybe<Order_By>;
  HMERGESPACE4?: InputMaybe<Order_By>;
  HMERGESPACE5?: InputMaybe<Order_By>;
  HREMEASUREOF?: InputMaybe<Order_By>;
  HSUBSPACEOF?: InputMaybe<Order_By>;
  INHOUSENETRENT?: InputMaybe<Order_By>;
  MODIFAREA?: InputMaybe<Order_By>;
  MODIFBASERENT?: InputMaybe<Order_By>;
  PAREA?: InputMaybe<Order_By>;
  SMGMTFEE?: InputMaybe<Order_By>;
  VALUATIONNETRENT?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["bigint"]>;
  _gt?: InputMaybe<Scalars["bigint"]>;
  _gte?: InputMaybe<Scalars["bigint"]>;
  _in?: InputMaybe<Array<Scalars["bigint"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["bigint"]>;
  _lte?: InputMaybe<Scalars["bigint"]>;
  _neq?: InputMaybe<Scalars["bigint"]>;
  _nin?: InputMaybe<Array<Scalars["bigint"]>>;
};

/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
export type Bit_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["bit"]>;
  _gt?: InputMaybe<Scalars["bit"]>;
  _gte?: InputMaybe<Scalars["bit"]>;
  _in?: InputMaybe<Array<Scalars["bit"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["bit"]>;
  _lte?: InputMaybe<Scalars["bit"]>;
  _neq?: InputMaybe<Scalars["bit"]>;
  _nin?: InputMaybe<Array<Scalars["bit"]>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["float8"]>;
  _gt?: InputMaybe<Scalars["float8"]>;
  _gte?: InputMaybe<Scalars["float8"]>;
  _in?: InputMaybe<Array<Scalars["float8"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["float8"]>;
  _lte?: InputMaybe<Scalars["float8"]>;
  _neq?: InputMaybe<Scalars["float8"]>;
  _nin?: InputMaybe<Array<Scalars["float8"]>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

/** Boolean expression to compare columns of type "money". All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["money"]>;
  _gt?: InputMaybe<Scalars["money"]>;
  _gte?: InputMaybe<Scalars["money"]>;
  _in?: InputMaybe<Array<Scalars["money"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["money"]>;
  _lte?: InputMaybe<Scalars["money"]>;
  _neq?: InputMaybe<Scalars["money"]>;
  _nin?: InputMaybe<Array<Scalars["money"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** Copying a Deal */
  copyDeal?: Maybe<NewDealId>;
  /** Copy Deal for ReLAF */
  copyDealRelaf?: Maybe<NewDealId>;
  /** delete data from the table: "ActionAllocations" */
  delete_ActionAllocations?: Maybe<ActionAllocations_Mutation_Response>;
  /** delete single row from the table: "ActionAllocations" */
  delete_ActionAllocations_by_pk?: Maybe<ActionAllocations>;
  /** delete data from the table: "ActionItems" */
  delete_ActionItems?: Maybe<ActionItems_Mutation_Response>;
  /** delete single row from the table: "ActionItems" */
  delete_ActionItems_by_pk?: Maybe<ActionItems>;
  /** delete data from the table: "ActivityLog" */
  delete_ActivityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** delete data from the table: "ActivityLogType" */
  delete_ActivityLogType?: Maybe<ActivityLogType_Mutation_Response>;
  /** delete single row from the table: "ActivityLogType" */
  delete_ActivityLogType_by_pk?: Maybe<ActivityLogType>;
  /** delete single row from the table: "ActivityLog" */
  delete_ActivityLog_by_pk?: Maybe<ActivityLog>;
  /** delete data from the table: "Agreements" */
  delete_Agreements?: Maybe<Agreements_Mutation_Response>;
  /** delete single row from the table: "Agreements" */
  delete_Agreements_by_pk?: Maybe<Agreements>;
  /** delete data from the table: "Approvers" */
  delete_Approvers?: Maybe<Approvers_Mutation_Response>;
  /** delete single row from the table: "Approvers" */
  delete_Approvers_by_pk?: Maybe<Approvers>;
  /** delete data from the table: "BaseLandlordServices" */
  delete_BaseLandlordServices?: Maybe<BaseLandlordServices_Mutation_Response>;
  /** delete single row from the table: "BaseLandlordServices" */
  delete_BaseLandlordServices_by_pk?: Maybe<BaseLandlordServices>;
  /** delete data from the table: "CenterApprovers" */
  delete_CenterApprovers?: Maybe<CenterApprovers_Mutation_Response>;
  /** delete single row from the table: "CenterApprovers" */
  delete_CenterApprovers_by_pk?: Maybe<CenterApprovers>;
  /** delete data from the table: "CommentTags" */
  delete_CommentTags?: Maybe<CommentTags_Mutation_Response>;
  /** delete single row from the table: "CommentTags" */
  delete_CommentTags_by_pk?: Maybe<CommentTags>;
  /** delete data from the table: "CommentUserTags" */
  delete_CommentUserTags?: Maybe<CommentUserTags_Mutation_Response>;
  /** delete single row from the table: "CommentUserTags" */
  delete_CommentUserTags_by_pk?: Maybe<CommentUserTags>;
  /** delete data from the table: "Comments" */
  delete_Comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "Comments" */
  delete_Comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "Companies" */
  delete_Companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "Companies" */
  delete_Companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "ContactTypes" */
  delete_ContactTypes?: Maybe<ContactTypes_Mutation_Response>;
  /** delete single row from the table: "ContactTypes" */
  delete_ContactTypes_by_pk?: Maybe<ContactTypes>;
  /** delete data from the table: "Contacts" */
  delete_Contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "Contacts" */
  delete_Contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "Countries" */
  delete_Countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "Countries" */
  delete_Countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "CustomLicences" */
  delete_CustomLicences?: Maybe<CustomLicences_Mutation_Response>;
  /** delete single row from the table: "CustomLicences" */
  delete_CustomLicences_by_pk?: Maybe<CustomLicences>;
  /** delete data from the table: "DealActionItem" */
  delete_DealActionItem?: Maybe<DealActionItem_Mutation_Response>;
  /** delete single row from the table: "DealActionItem" */
  delete_DealActionItem_by_pk?: Maybe<DealActionItem>;
  /** delete data from the table: "DealBusinessContacts" */
  delete_DealBusinessContacts?: Maybe<DealBusinessContacts_Mutation_Response>;
  /** delete single row from the table: "DealBusinessContacts" */
  delete_DealBusinessContacts_by_pk?: Maybe<DealBusinessContacts>;
  /** delete data from the table: "DealContactsTags" */
  delete_DealContactsTags?: Maybe<DealContactsTags_Mutation_Response>;
  /** delete single row from the table: "DealContactsTags" */
  delete_DealContactsTags_by_pk?: Maybe<DealContactsTags>;
  /** delete data from the table: "DealData" */
  delete_DealData?: Maybe<DealData_Mutation_Response>;
  /** delete data from the table: "DealDataCustomLicences" */
  delete_DealDataCustomLicences?: Maybe<DealDataCustomLicences_Mutation_Response>;
  /** delete single row from the table: "DealDataCustomLicences" */
  delete_DealDataCustomLicences_by_pk?: Maybe<DealDataCustomLicences>;
  /** delete data from the table: "DealDataGuarantors" */
  delete_DealDataGuarantors?: Maybe<DealDataGuarantors_Mutation_Response>;
  /** delete single row from the table: "DealDataGuarantors" */
  delete_DealDataGuarantors_by_pk?: Maybe<DealDataGuarantors>;
  /** delete single row from the table: "DealData" */
  delete_DealData_by_pk?: Maybe<DealData>;
  /** delete data from the table: "DealExtensionItems" */
  delete_DealExtensionItems?: Maybe<DealExtensionItems_Mutation_Response>;
  /** delete single row from the table: "DealExtensionItems" */
  delete_DealExtensionItems_by_pk?: Maybe<DealExtensionItems>;
  /** delete data from the table: "DealFileChunks" */
  delete_DealFileChunks?: Maybe<DealFileChunks_Mutation_Response>;
  /** delete single row from the table: "DealFileChunks" */
  delete_DealFileChunks_by_pk?: Maybe<DealFileChunks>;
  /** delete data from the table: "DealFileTags" */
  delete_DealFileTags?: Maybe<DealFileTags_Mutation_Response>;
  /** delete single row from the table: "DealFileTags" */
  delete_DealFileTags_by_pk?: Maybe<DealFileTags>;
  /** delete data from the table: "DealFiles" */
  delete_DealFiles?: Maybe<DealFiles_Mutation_Response>;
  /** delete single row from the table: "DealFiles" */
  delete_DealFiles_by_pk?: Maybe<DealFiles>;
  /** delete data from the table: "DealFitoutLandlordWorks" */
  delete_DealFitoutLandlordWorks?: Maybe<DealFitoutLandlordWorks_Mutation_Response>;
  /** delete single row from the table: "DealFitoutLandlordWorks" */
  delete_DealFitoutLandlordWorks_by_pk?: Maybe<DealFitoutLandlordWorks>;
  /** delete data from the table: "DealLAFApprovals" */
  delete_DealLAFApprovals?: Maybe<DealLafApprovals_Mutation_Response>;
  /** delete single row from the table: "DealLAFApprovals" */
  delete_DealLAFApprovals_by_pk?: Maybe<DealLafApprovals>;
  /** delete data from the table: "DealLinks" */
  delete_DealLinks?: Maybe<DealLinks_Mutation_Response>;
  /** delete single row from the table: "DealLinks" */
  delete_DealLinks_by_pk?: Maybe<DealLinks>;
  /** delete data from the table: "DealOptions" */
  delete_DealOptions?: Maybe<DealOptions_Mutation_Response>;
  /** delete single row from the table: "DealOptions" */
  delete_DealOptions_by_pk?: Maybe<DealOptions>;
  /** delete data from the table: "DealPermittedUses" */
  delete_DealPermittedUses?: Maybe<DealPermittedUses_Mutation_Response>;
  /** delete single row from the table: "DealPermittedUses" */
  delete_DealPermittedUses_by_pk?: Maybe<DealPermittedUses>;
  /** delete data from the table: "DealPhases" */
  delete_DealPhases?: Maybe<DealPhases_Mutation_Response>;
  /** delete single row from the table: "DealPhases" */
  delete_DealPhases_by_pk?: Maybe<DealPhases>;
  /** delete data from the table: "DealRiskLevels" */
  delete_DealRiskLevels?: Maybe<DealRiskLevels_Mutation_Response>;
  /** delete single row from the table: "DealRiskLevels" */
  delete_DealRiskLevels_by_pk?: Maybe<DealRiskLevels>;
  /** delete data from the table: "DealShopActivities" */
  delete_DealShopActivities?: Maybe<DealShopActivities_Mutation_Response>;
  /** delete single row from the table: "DealShopActivities" */
  delete_DealShopActivities_by_pk?: Maybe<DealShopActivities>;
  /** delete data from the table: "DealShopActivityTypes" */
  delete_DealShopActivityTypes?: Maybe<DealShopActivityTypes_Mutation_Response>;
  /** delete single row from the table: "DealShopActivityTypes" */
  delete_DealShopActivityTypes_by_pk?: Maybe<DealShopActivityTypes>;
  /** delete data from the table: "DealSpecialConditions" */
  delete_DealSpecialConditions?: Maybe<DealSpecialConditions_Mutation_Response>;
  /** delete single row from the table: "DealSpecialConditions" */
  delete_DealSpecialConditions_by_pk?: Maybe<DealSpecialConditions>;
  /** delete data from the table: "DealStatus" */
  delete_DealStatus?: Maybe<DealStatus_Mutation_Response>;
  /** delete single row from the table: "DealStatus" */
  delete_DealStatus_by_pk?: Maybe<DealStatus>;
  /** delete data from the table: "DealTypes" */
  delete_DealTypes?: Maybe<DealTypes_Mutation_Response>;
  /** delete single row from the table: "DealTypes" */
  delete_DealTypes_by_pk?: Maybe<DealTypes>;
  /** delete data from the table: "Deals" */
  delete_Deals?: Maybe<Deals_Mutation_Response>;
  /** delete data from the table: "DealsCompanies" */
  delete_DealsCompanies?: Maybe<DealsCompanies_Mutation_Response>;
  /** delete single row from the table: "DealsCompanies" */
  delete_DealsCompanies_by_pk?: Maybe<DealsCompanies>;
  /** delete data from the table: "DealsContacts" */
  delete_DealsContacts?: Maybe<DealsContacts_Mutation_Response>;
  /** delete single row from the table: "DealsContacts" */
  delete_DealsContacts_by_pk?: Maybe<DealsContacts>;
  /** delete data from the table: "DealsUnits" */
  delete_DealsUnits?: Maybe<DealsUnits_Mutation_Response>;
  /** delete single row from the table: "DealsUnits" */
  delete_DealsUnits_by_pk?: Maybe<DealsUnits>;
  /** delete single row from the table: "Deals" */
  delete_Deals_by_pk?: Maybe<Deals>;
  /** delete data from the table: "DocumentRequests" */
  delete_DocumentRequests?: Maybe<DocumentRequests_Mutation_Response>;
  /** delete single row from the table: "DocumentRequests" */
  delete_DocumentRequests_by_pk?: Maybe<DocumentRequests>;
  /** delete data from the table: "DocumentTemplates" */
  delete_DocumentTemplates?: Maybe<DocumentTemplates_Mutation_Response>;
  /** delete single row from the table: "DocumentTemplates" */
  delete_DocumentTemplates_by_pk?: Maybe<DocumentTemplates>;
  /** delete data from the table: "EscalatedApprovers" */
  delete_EscalatedApprovers?: Maybe<EscalatedApprovers_Mutation_Response>;
  /** delete single row from the table: "EscalatedApprovers" */
  delete_EscalatedApprovers_by_pk?: Maybe<EscalatedApprovers>;
  /** delete data from the table: "GeneratedDocuments" */
  delete_GeneratedDocuments?: Maybe<GeneratedDocuments_Mutation_Response>;
  /** delete single row from the table: "GeneratedDocuments" */
  delete_GeneratedDocuments_by_pk?: Maybe<GeneratedDocuments>;
  /** delete data from the table: "LafActionChoices" */
  delete_LafActionChoices?: Maybe<LafActionChoices_Mutation_Response>;
  /** delete single row from the table: "LafActionChoices" */
  delete_LafActionChoices_by_pk?: Maybe<LafActionChoices>;
  /** delete data from the table: "Leases" */
  delete_Leases?: Maybe<Leases_Mutation_Response>;
  /** delete single row from the table: "Leases" */
  delete_Leases_by_pk?: Maybe<Leases>;
  /** delete data from the table: "Locations" */
  delete_Locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "Locations" */
  delete_Locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "Offers" */
  delete_Offers?: Maybe<Offers_Mutation_Response>;
  /** delete single row from the table: "Offers" */
  delete_Offers_by_pk?: Maybe<Offers>;
  /** delete data from the table: "PhaseActionItem" */
  delete_PhaseActionItem?: Maybe<PhaseActionItem_Mutation_Response>;
  /** delete single row from the table: "PhaseActionItem" */
  delete_PhaseActionItem_by_pk?: Maybe<PhaseActionItem>;
  /** delete data from the table: "PhaseDealStatus" */
  delete_PhaseDealStatus?: Maybe<PhaseDealStatus_Mutation_Response>;
  /** delete single row from the table: "PhaseDealStatus" */
  delete_PhaseDealStatus_by_pk?: Maybe<PhaseDealStatus>;
  /** delete data from the table: "Phases" */
  delete_Phases?: Maybe<Phases_Mutation_Response>;
  /** delete single row from the table: "Phases" */
  delete_Phases_by_pk?: Maybe<Phases>;
  /** delete data from the table: "PropertiesInformation" */
  delete_PropertiesInformation?: Maybe<PropertiesInformation_Mutation_Response>;
  /** delete single row from the table: "PropertiesInformation" */
  delete_PropertiesInformation_by_pk?: Maybe<PropertiesInformation>;
  /** delete data from the table: "PropertyContacts" */
  delete_PropertyContacts?: Maybe<PropertyContacts_Mutation_Response>;
  /** delete single row from the table: "PropertyContacts" */
  delete_PropertyContacts_by_pk?: Maybe<PropertyContacts>;
  /** delete data from the table: "PropertyFileTypes" */
  delete_PropertyFileTypes?: Maybe<PropertyFileTypes_Mutation_Response>;
  /** delete single row from the table: "PropertyFileTypes" */
  delete_PropertyFileTypes_by_pk?: Maybe<PropertyFileTypes>;
  /** delete data from the table: "PropertyFiles" */
  delete_PropertyFiles?: Maybe<PropertyFiles_Mutation_Response>;
  /** delete single row from the table: "PropertyFiles" */
  delete_PropertyFiles_by_pk?: Maybe<PropertyFiles>;
  /** delete data from the table: "Proposals" */
  delete_Proposals?: Maybe<Proposals_Mutation_Response>;
  /** delete single row from the table: "Proposals" */
  delete_Proposals_by_pk?: Maybe<Proposals>;
  /** delete data from the table: "Registrations" */
  delete_Registrations?: Maybe<Registrations_Mutation_Response>;
  /** delete single row from the table: "Registrations" */
  delete_Registrations_by_pk?: Maybe<Registrations>;
  /** delete data from the table: "SalesType" */
  delete_SalesType?: Maybe<SalesType_Mutation_Response>;
  /** delete single row from the table: "SalesType" */
  delete_SalesType_by_pk?: Maybe<SalesType>;
  /** delete data from the table: "States" */
  delete_States?: Maybe<States_Mutation_Response>;
  /** delete single row from the table: "States" */
  delete_States_by_pk?: Maybe<States>;
  /** delete data from the table: "Tags" */
  delete_Tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "Tags" */
  delete_Tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "TemplatePermittedUses" */
  delete_TemplatePermittedUses?: Maybe<TemplatePermittedUses_Mutation_Response>;
  /** delete single row from the table: "TemplatePermittedUses" */
  delete_TemplatePermittedUses_by_pk?: Maybe<TemplatePermittedUses>;
  /** delete data from the table: "TemplateSpecialConditions" */
  delete_TemplateSpecialConditions?: Maybe<TemplateSpecialConditions_Mutation_Response>;
  /** delete single row from the table: "TemplateSpecialConditions" */
  delete_TemplateSpecialConditions_by_pk?: Maybe<TemplateSpecialConditions>;
  /** delete data from the table: "TenantTypes" */
  delete_TenantTypes?: Maybe<TenantTypes_Mutation_Response>;
  /** delete single row from the table: "TenantTypes" */
  delete_TenantTypes_by_pk?: Maybe<TenantTypes>;
  /** delete data from the table: "Terms" */
  delete_Terms?: Maybe<Terms_Mutation_Response>;
  /** delete single row from the table: "Terms" */
  delete_Terms_by_pk?: Maybe<Terms>;
  /** delete data from the table: "TriSelectEnum" */
  delete_TriSelectEnum?: Maybe<TriSelectEnum_Mutation_Response>;
  /** delete single row from the table: "TriSelectEnum" */
  delete_TriSelectEnum_by_pk?: Maybe<TriSelectEnum>;
  /** delete data from the table: "UnitTypes" */
  delete_UnitTypes?: Maybe<UnitTypes_Mutation_Response>;
  /** delete single row from the table: "UnitTypes" */
  delete_UnitTypes_by_pk?: Maybe<UnitTypes>;
  /** delete data from the table: "Units" */
  delete_Units?: Maybe<Units_Mutation_Response>;
  /** delete data from the table: "UserStatus" */
  delete_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  /** delete single row from the table: "UserStatus" */
  delete_UserStatus_by_pk?: Maybe<UserStatus>;
  /** delete data from the table: "Users" */
  delete_Users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "Users" */
  delete_Users_by_pk?: Maybe<Users>;
  /** delete data from the table: "VirtualUnits" */
  delete_VirtualUnits?: Maybe<VirtualUnits_Mutation_Response>;
  /** delete single row from the table: "VirtualUnits" */
  delete_VirtualUnits_by_pk?: Maybe<VirtualUnits>;
  /** delete data from the table: "YardiAmendments" */
  delete_YardiAmendments?: Maybe<YardiAmendments_Mutation_Response>;
  /** delete data from the table: "YardiAmendments_Deals" */
  delete_YardiAmendments_Deals?: Maybe<YardiAmendments_Deals_Mutation_Response>;
  /** delete single row from the table: "YardiAmendments_Deals" */
  delete_YardiAmendments_Deals_by_pk?: Maybe<YardiAmendments_Deals>;
  /** delete single row from the table: "YardiAmendments" */
  delete_YardiAmendments_by_pk?: Maybe<YardiAmendments>;
  /** delete data from the table: "YardiContacts" */
  delete_YardiContacts?: Maybe<YardiContacts_Mutation_Response>;
  /** delete data from the table: "YardiContacts_Contacts" */
  delete_YardiContacts_Contacts?: Maybe<YardiContacts_Contacts_Mutation_Response>;
  /** delete single row from the table: "YardiContacts_Contacts" */
  delete_YardiContacts_Contacts_by_pk?: Maybe<YardiContacts_Contacts>;
  /** delete data from the table: "YardiContacts_DealOwners" */
  delete_YardiContacts_DealOwners?: Maybe<YardiContacts_DealOwners_Mutation_Response>;
  /** delete single row from the table: "YardiContacts_DealOwners" */
  delete_YardiContacts_DealOwners_by_pk?: Maybe<YardiContacts_DealOwners>;
  /** delete data from the table: "YardiContacts_YardiProperties" */
  delete_YardiContacts_YardiProperties?: Maybe<YardiContacts_YardiProperties_Mutation_Response>;
  /** delete single row from the table: "YardiContacts_YardiProperties" */
  delete_YardiContacts_YardiProperties_by_pk?: Maybe<YardiContacts_YardiProperties>;
  /** delete single row from the table: "YardiContacts" */
  delete_YardiContacts_by_pk?: Maybe<YardiContacts>;
  /** delete data from the table: "YardiCustomers" */
  delete_YardiCustomers?: Maybe<YardiCustomers_Mutation_Response>;
  /** delete data from the table: "YardiCustomers_Deals" */
  delete_YardiCustomers_Deals?: Maybe<YardiCustomers_Deals_Mutation_Response>;
  /** delete single row from the table: "YardiCustomers_Deals" */
  delete_YardiCustomers_Deals_by_pk?: Maybe<YardiCustomers_Deals>;
  /** delete single row from the table: "YardiCustomers" */
  delete_YardiCustomers_by_pk?: Maybe<YardiCustomers>;
  /** delete data from the table: "YardiDealSyncStatuses" */
  delete_YardiDealSyncStatuses?: Maybe<YardiDealSyncStatuses_Mutation_Response>;
  /** delete single row from the table: "YardiDealSyncStatuses" */
  delete_YardiDealSyncStatuses_by_pk?: Maybe<YardiDealSyncStatuses>;
  /** delete data from the table: "YardiIngestionInfos" */
  delete_YardiIngestionInfos?: Maybe<YardiIngestionInfos_Mutation_Response>;
  /** delete single row from the table: "YardiIngestionInfos" */
  delete_YardiIngestionInfos_by_pk?: Maybe<YardiIngestionInfos>;
  /** delete data from the table: "YardiProperties" */
  delete_YardiProperties?: Maybe<YardiProperties_Mutation_Response>;
  /** delete single row from the table: "YardiProperties" */
  delete_YardiProperties_by_pk?: Maybe<YardiProperties>;
  /** delete data from the table: "YardiPropertyProfiles" */
  delete_YardiPropertyProfiles?: Maybe<YardiPropertyProfiles_Mutation_Response>;
  /** delete single row from the table: "YardiPropertyProfiles" */
  delete_YardiPropertyProfiles_by_pk?: Maybe<YardiPropertyProfiles>;
  /** delete data from the table: "YardiTenantToUnits" */
  delete_YardiTenantToUnits?: Maybe<YardiTenantToUnits_Mutation_Response>;
  /** delete single row from the table: "YardiTenantToUnits" */
  delete_YardiTenantToUnits_by_pk?: Maybe<YardiTenantToUnits>;
  /** delete data from the table: "YardiTenant_YardiContacts" */
  delete_YardiTenant_YardiContacts?: Maybe<YardiTenant_YardiContacts_Mutation_Response>;
  /** delete single row from the table: "YardiTenant_YardiContacts" */
  delete_YardiTenant_YardiContacts_by_pk?: Maybe<YardiTenant_YardiContacts>;
  /** delete data from the table: "YardiTenants" */
  delete_YardiTenants?: Maybe<YardiTenants_Mutation_Response>;
  /** delete data from the table: "YardiTenants_Deals" */
  delete_YardiTenants_Deals?: Maybe<YardiTenants_Deals_Mutation_Response>;
  /** delete single row from the table: "YardiTenants_Deals" */
  delete_YardiTenants_Deals_by_pk?: Maybe<YardiTenants_Deals>;
  /** delete single row from the table: "YardiTenants" */
  delete_YardiTenants_by_pk?: Maybe<YardiTenants>;
  /** delete data from the table: "YardiUnitProfiles" */
  delete_YardiUnitProfiles?: Maybe<YardiUnitProfiles_Mutation_Response>;
  /** delete single row from the table: "YardiUnitProfiles" */
  delete_YardiUnitProfiles_by_pk?: Maybe<YardiUnitProfiles>;
  /** delete data from the table: "YardiUnits" */
  delete_YardiUnits?: Maybe<YardiUnits_Mutation_Response>;
  /** delete single row from the table: "YardiUnits" */
  delete_YardiUnits_by_pk?: Maybe<YardiUnits>;
  /** delete data from the table: "YardiVirtualUnits" */
  delete_YardiVirtualUnits?: Maybe<YardiVirtualUnits_Mutation_Response>;
  /** delete data from the table: "YardiVirtualUnits_VirtualUnits" */
  delete_YardiVirtualUnits_VirtualUnits?: Maybe<YardiVirtualUnits_VirtualUnits_Mutation_Response>;
  /** delete single row from the table: "YardiVirtualUnits_VirtualUnits" */
  delete_YardiVirtualUnits_VirtualUnits_by_pk?: Maybe<YardiVirtualUnits_VirtualUnits>;
  /** delete single row from the table: "YardiVirtualUnits" */
  delete_YardiVirtualUnits_by_pk?: Maybe<YardiVirtualUnits>;
  egestJob?: Maybe<EgestOutput>;
  /** insert data into the table: "ActionAllocations" */
  insert_ActionAllocations?: Maybe<ActionAllocations_Mutation_Response>;
  /** insert a single row into the table: "ActionAllocations" */
  insert_ActionAllocations_one?: Maybe<ActionAllocations>;
  /** insert data into the table: "ActionItems" */
  insert_ActionItems?: Maybe<ActionItems_Mutation_Response>;
  /** insert a single row into the table: "ActionItems" */
  insert_ActionItems_one?: Maybe<ActionItems>;
  /** insert data into the table: "ActivityLog" */
  insert_ActivityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** insert data into the table: "ActivityLogType" */
  insert_ActivityLogType?: Maybe<ActivityLogType_Mutation_Response>;
  /** insert a single row into the table: "ActivityLogType" */
  insert_ActivityLogType_one?: Maybe<ActivityLogType>;
  /** insert a single row into the table: "ActivityLog" */
  insert_ActivityLog_one?: Maybe<ActivityLog>;
  /** insert data into the table: "Agreements" */
  insert_Agreements?: Maybe<Agreements_Mutation_Response>;
  /** insert a single row into the table: "Agreements" */
  insert_Agreements_one?: Maybe<Agreements>;
  /** insert data into the table: "Approvers" */
  insert_Approvers?: Maybe<Approvers_Mutation_Response>;
  /** insert a single row into the table: "Approvers" */
  insert_Approvers_one?: Maybe<Approvers>;
  /** insert data into the table: "BaseLandlordServices" */
  insert_BaseLandlordServices?: Maybe<BaseLandlordServices_Mutation_Response>;
  /** insert a single row into the table: "BaseLandlordServices" */
  insert_BaseLandlordServices_one?: Maybe<BaseLandlordServices>;
  /** insert data into the table: "CenterApprovers" */
  insert_CenterApprovers?: Maybe<CenterApprovers_Mutation_Response>;
  /** insert a single row into the table: "CenterApprovers" */
  insert_CenterApprovers_one?: Maybe<CenterApprovers>;
  /** insert data into the table: "CommentTags" */
  insert_CommentTags?: Maybe<CommentTags_Mutation_Response>;
  /** insert a single row into the table: "CommentTags" */
  insert_CommentTags_one?: Maybe<CommentTags>;
  /** insert data into the table: "CommentUserTags" */
  insert_CommentUserTags?: Maybe<CommentUserTags_Mutation_Response>;
  /** insert a single row into the table: "CommentUserTags" */
  insert_CommentUserTags_one?: Maybe<CommentUserTags>;
  /** insert data into the table: "Comments" */
  insert_Comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "Comments" */
  insert_Comments_one?: Maybe<Comments>;
  /** insert data into the table: "Companies" */
  insert_Companies?: Maybe<Companies_Mutation_Response>;
  /** insert a single row into the table: "Companies" */
  insert_Companies_one?: Maybe<Companies>;
  /** insert data into the table: "ContactTypes" */
  insert_ContactTypes?: Maybe<ContactTypes_Mutation_Response>;
  /** insert a single row into the table: "ContactTypes" */
  insert_ContactTypes_one?: Maybe<ContactTypes>;
  /** insert data into the table: "Contacts" */
  insert_Contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "Contacts" */
  insert_Contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "Countries" */
  insert_Countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "Countries" */
  insert_Countries_one?: Maybe<Countries>;
  /** insert data into the table: "CustomLicences" */
  insert_CustomLicences?: Maybe<CustomLicences_Mutation_Response>;
  /** insert a single row into the table: "CustomLicences" */
  insert_CustomLicences_one?: Maybe<CustomLicences>;
  /** insert data into the table: "DealActionItem" */
  insert_DealActionItem?: Maybe<DealActionItem_Mutation_Response>;
  /** insert a single row into the table: "DealActionItem" */
  insert_DealActionItem_one?: Maybe<DealActionItem>;
  /** insert data into the table: "DealBusinessContacts" */
  insert_DealBusinessContacts?: Maybe<DealBusinessContacts_Mutation_Response>;
  /** insert a single row into the table: "DealBusinessContacts" */
  insert_DealBusinessContacts_one?: Maybe<DealBusinessContacts>;
  /** insert data into the table: "DealContactsTags" */
  insert_DealContactsTags?: Maybe<DealContactsTags_Mutation_Response>;
  /** insert a single row into the table: "DealContactsTags" */
  insert_DealContactsTags_one?: Maybe<DealContactsTags>;
  /** insert data into the table: "DealData" */
  insert_DealData?: Maybe<DealData_Mutation_Response>;
  /** insert data into the table: "DealDataCustomLicences" */
  insert_DealDataCustomLicences?: Maybe<DealDataCustomLicences_Mutation_Response>;
  /** insert a single row into the table: "DealDataCustomLicences" */
  insert_DealDataCustomLicences_one?: Maybe<DealDataCustomLicences>;
  /** insert data into the table: "DealDataGuarantors" */
  insert_DealDataGuarantors?: Maybe<DealDataGuarantors_Mutation_Response>;
  /** insert a single row into the table: "DealDataGuarantors" */
  insert_DealDataGuarantors_one?: Maybe<DealDataGuarantors>;
  /** insert a single row into the table: "DealData" */
  insert_DealData_one?: Maybe<DealData>;
  /** insert data into the table: "DealExtensionItems" */
  insert_DealExtensionItems?: Maybe<DealExtensionItems_Mutation_Response>;
  /** insert a single row into the table: "DealExtensionItems" */
  insert_DealExtensionItems_one?: Maybe<DealExtensionItems>;
  /** insert data into the table: "DealFileChunks" */
  insert_DealFileChunks?: Maybe<DealFileChunks_Mutation_Response>;
  /** insert a single row into the table: "DealFileChunks" */
  insert_DealFileChunks_one?: Maybe<DealFileChunks>;
  /** insert data into the table: "DealFileTags" */
  insert_DealFileTags?: Maybe<DealFileTags_Mutation_Response>;
  /** insert a single row into the table: "DealFileTags" */
  insert_DealFileTags_one?: Maybe<DealFileTags>;
  /** insert data into the table: "DealFiles" */
  insert_DealFiles?: Maybe<DealFiles_Mutation_Response>;
  /** insert a single row into the table: "DealFiles" */
  insert_DealFiles_one?: Maybe<DealFiles>;
  /** insert data into the table: "DealFitoutLandlordWorks" */
  insert_DealFitoutLandlordWorks?: Maybe<DealFitoutLandlordWorks_Mutation_Response>;
  /** insert a single row into the table: "DealFitoutLandlordWorks" */
  insert_DealFitoutLandlordWorks_one?: Maybe<DealFitoutLandlordWorks>;
  /** insert data into the table: "DealLAFApprovals" */
  insert_DealLAFApprovals?: Maybe<DealLafApprovals_Mutation_Response>;
  /** insert a single row into the table: "DealLAFApprovals" */
  insert_DealLAFApprovals_one?: Maybe<DealLafApprovals>;
  /** insert data into the table: "DealLinks" */
  insert_DealLinks?: Maybe<DealLinks_Mutation_Response>;
  /** insert a single row into the table: "DealLinks" */
  insert_DealLinks_one?: Maybe<DealLinks>;
  /** insert data into the table: "DealOptions" */
  insert_DealOptions?: Maybe<DealOptions_Mutation_Response>;
  /** insert a single row into the table: "DealOptions" */
  insert_DealOptions_one?: Maybe<DealOptions>;
  /** insert data into the table: "DealPermittedUses" */
  insert_DealPermittedUses?: Maybe<DealPermittedUses_Mutation_Response>;
  /** insert a single row into the table: "DealPermittedUses" */
  insert_DealPermittedUses_one?: Maybe<DealPermittedUses>;
  /** insert data into the table: "DealPhases" */
  insert_DealPhases?: Maybe<DealPhases_Mutation_Response>;
  /** insert a single row into the table: "DealPhases" */
  insert_DealPhases_one?: Maybe<DealPhases>;
  /** insert data into the table: "DealRiskLevels" */
  insert_DealRiskLevels?: Maybe<DealRiskLevels_Mutation_Response>;
  /** insert a single row into the table: "DealRiskLevels" */
  insert_DealRiskLevels_one?: Maybe<DealRiskLevels>;
  /** insert data into the table: "DealShopActivities" */
  insert_DealShopActivities?: Maybe<DealShopActivities_Mutation_Response>;
  /** insert a single row into the table: "DealShopActivities" */
  insert_DealShopActivities_one?: Maybe<DealShopActivities>;
  /** insert data into the table: "DealShopActivityTypes" */
  insert_DealShopActivityTypes?: Maybe<DealShopActivityTypes_Mutation_Response>;
  /** insert a single row into the table: "DealShopActivityTypes" */
  insert_DealShopActivityTypes_one?: Maybe<DealShopActivityTypes>;
  /** insert data into the table: "DealSpecialConditions" */
  insert_DealSpecialConditions?: Maybe<DealSpecialConditions_Mutation_Response>;
  /** insert a single row into the table: "DealSpecialConditions" */
  insert_DealSpecialConditions_one?: Maybe<DealSpecialConditions>;
  /** insert data into the table: "DealStatus" */
  insert_DealStatus?: Maybe<DealStatus_Mutation_Response>;
  /** insert a single row into the table: "DealStatus" */
  insert_DealStatus_one?: Maybe<DealStatus>;
  /** insert data into the table: "DealTypes" */
  insert_DealTypes?: Maybe<DealTypes_Mutation_Response>;
  /** insert a single row into the table: "DealTypes" */
  insert_DealTypes_one?: Maybe<DealTypes>;
  /** insert data into the table: "Deals" */
  insert_Deals?: Maybe<Deals_Mutation_Response>;
  /** insert data into the table: "DealsCompanies" */
  insert_DealsCompanies?: Maybe<DealsCompanies_Mutation_Response>;
  /** insert a single row into the table: "DealsCompanies" */
  insert_DealsCompanies_one?: Maybe<DealsCompanies>;
  /** insert data into the table: "DealsContacts" */
  insert_DealsContacts?: Maybe<DealsContacts_Mutation_Response>;
  /** insert a single row into the table: "DealsContacts" */
  insert_DealsContacts_one?: Maybe<DealsContacts>;
  /** insert data into the table: "DealsUnits" */
  insert_DealsUnits?: Maybe<DealsUnits_Mutation_Response>;
  /** insert a single row into the table: "DealsUnits" */
  insert_DealsUnits_one?: Maybe<DealsUnits>;
  /** insert a single row into the table: "Deals" */
  insert_Deals_one?: Maybe<Deals>;
  /** insert data into the table: "DocumentRequests" */
  insert_DocumentRequests?: Maybe<DocumentRequests_Mutation_Response>;
  /** insert a single row into the table: "DocumentRequests" */
  insert_DocumentRequests_one?: Maybe<DocumentRequests>;
  /** insert data into the table: "DocumentTemplates" */
  insert_DocumentTemplates?: Maybe<DocumentTemplates_Mutation_Response>;
  /** insert a single row into the table: "DocumentTemplates" */
  insert_DocumentTemplates_one?: Maybe<DocumentTemplates>;
  /** insert data into the table: "EscalatedApprovers" */
  insert_EscalatedApprovers?: Maybe<EscalatedApprovers_Mutation_Response>;
  /** insert a single row into the table: "EscalatedApprovers" */
  insert_EscalatedApprovers_one?: Maybe<EscalatedApprovers>;
  /** insert data into the table: "GeneratedDocuments" */
  insert_GeneratedDocuments?: Maybe<GeneratedDocuments_Mutation_Response>;
  /** insert a single row into the table: "GeneratedDocuments" */
  insert_GeneratedDocuments_one?: Maybe<GeneratedDocuments>;
  /** insert data into the table: "LafActionChoices" */
  insert_LafActionChoices?: Maybe<LafActionChoices_Mutation_Response>;
  /** insert a single row into the table: "LafActionChoices" */
  insert_LafActionChoices_one?: Maybe<LafActionChoices>;
  /** insert data into the table: "Leases" */
  insert_Leases?: Maybe<Leases_Mutation_Response>;
  /** insert a single row into the table: "Leases" */
  insert_Leases_one?: Maybe<Leases>;
  /** insert data into the table: "Locations" */
  insert_Locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "Locations" */
  insert_Locations_one?: Maybe<Locations>;
  /** insert data into the table: "Offers" */
  insert_Offers?: Maybe<Offers_Mutation_Response>;
  /** insert a single row into the table: "Offers" */
  insert_Offers_one?: Maybe<Offers>;
  /** insert data into the table: "PhaseActionItem" */
  insert_PhaseActionItem?: Maybe<PhaseActionItem_Mutation_Response>;
  /** insert a single row into the table: "PhaseActionItem" */
  insert_PhaseActionItem_one?: Maybe<PhaseActionItem>;
  /** insert data into the table: "PhaseDealStatus" */
  insert_PhaseDealStatus?: Maybe<PhaseDealStatus_Mutation_Response>;
  /** insert a single row into the table: "PhaseDealStatus" */
  insert_PhaseDealStatus_one?: Maybe<PhaseDealStatus>;
  /** insert data into the table: "Phases" */
  insert_Phases?: Maybe<Phases_Mutation_Response>;
  /** insert a single row into the table: "Phases" */
  insert_Phases_one?: Maybe<Phases>;
  /** insert data into the table: "PropertiesInformation" */
  insert_PropertiesInformation?: Maybe<PropertiesInformation_Mutation_Response>;
  /** insert a single row into the table: "PropertiesInformation" */
  insert_PropertiesInformation_one?: Maybe<PropertiesInformation>;
  /** insert data into the table: "PropertyContacts" */
  insert_PropertyContacts?: Maybe<PropertyContacts_Mutation_Response>;
  /** insert a single row into the table: "PropertyContacts" */
  insert_PropertyContacts_one?: Maybe<PropertyContacts>;
  /** insert data into the table: "PropertyFileTypes" */
  insert_PropertyFileTypes?: Maybe<PropertyFileTypes_Mutation_Response>;
  /** insert a single row into the table: "PropertyFileTypes" */
  insert_PropertyFileTypes_one?: Maybe<PropertyFileTypes>;
  /** insert data into the table: "PropertyFiles" */
  insert_PropertyFiles?: Maybe<PropertyFiles_Mutation_Response>;
  /** insert a single row into the table: "PropertyFiles" */
  insert_PropertyFiles_one?: Maybe<PropertyFiles>;
  /** insert data into the table: "Proposals" */
  insert_Proposals?: Maybe<Proposals_Mutation_Response>;
  /** insert a single row into the table: "Proposals" */
  insert_Proposals_one?: Maybe<Proposals>;
  /** insert data into the table: "Registrations" */
  insert_Registrations?: Maybe<Registrations_Mutation_Response>;
  /** insert a single row into the table: "Registrations" */
  insert_Registrations_one?: Maybe<Registrations>;
  /** insert data into the table: "SalesType" */
  insert_SalesType?: Maybe<SalesType_Mutation_Response>;
  /** insert a single row into the table: "SalesType" */
  insert_SalesType_one?: Maybe<SalesType>;
  /** insert data into the table: "States" */
  insert_States?: Maybe<States_Mutation_Response>;
  /** insert a single row into the table: "States" */
  insert_States_one?: Maybe<States>;
  /** insert data into the table: "Tags" */
  insert_Tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "Tags" */
  insert_Tags_one?: Maybe<Tags>;
  /** insert data into the table: "TemplatePermittedUses" */
  insert_TemplatePermittedUses?: Maybe<TemplatePermittedUses_Mutation_Response>;
  /** insert a single row into the table: "TemplatePermittedUses" */
  insert_TemplatePermittedUses_one?: Maybe<TemplatePermittedUses>;
  /** insert data into the table: "TemplateSpecialConditions" */
  insert_TemplateSpecialConditions?: Maybe<TemplateSpecialConditions_Mutation_Response>;
  /** insert a single row into the table: "TemplateSpecialConditions" */
  insert_TemplateSpecialConditions_one?: Maybe<TemplateSpecialConditions>;
  /** insert data into the table: "TenantTypes" */
  insert_TenantTypes?: Maybe<TenantTypes_Mutation_Response>;
  /** insert a single row into the table: "TenantTypes" */
  insert_TenantTypes_one?: Maybe<TenantTypes>;
  /** insert data into the table: "Terms" */
  insert_Terms?: Maybe<Terms_Mutation_Response>;
  /** insert a single row into the table: "Terms" */
  insert_Terms_one?: Maybe<Terms>;
  /** insert data into the table: "TriSelectEnum" */
  insert_TriSelectEnum?: Maybe<TriSelectEnum_Mutation_Response>;
  /** insert a single row into the table: "TriSelectEnum" */
  insert_TriSelectEnum_one?: Maybe<TriSelectEnum>;
  /** insert data into the table: "UnitTypes" */
  insert_UnitTypes?: Maybe<UnitTypes_Mutation_Response>;
  /** insert a single row into the table: "UnitTypes" */
  insert_UnitTypes_one?: Maybe<UnitTypes>;
  /** insert data into the table: "Units" */
  insert_Units?: Maybe<Units_Mutation_Response>;
  /** insert a single row into the table: "Units" */
  insert_Units_one?: Maybe<Units>;
  /** insert data into the table: "UserStatus" */
  insert_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  /** insert a single row into the table: "UserStatus" */
  insert_UserStatus_one?: Maybe<UserStatus>;
  /** insert data into the table: "Users" */
  insert_Users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "Users" */
  insert_Users_one?: Maybe<Users>;
  /** insert data into the table: "VirtualUnits" */
  insert_VirtualUnits?: Maybe<VirtualUnits_Mutation_Response>;
  /** insert a single row into the table: "VirtualUnits" */
  insert_VirtualUnits_one?: Maybe<VirtualUnits>;
  /** insert data into the table: "YardiAmendments" */
  insert_YardiAmendments?: Maybe<YardiAmendments_Mutation_Response>;
  /** insert data into the table: "YardiAmendments_Deals" */
  insert_YardiAmendments_Deals?: Maybe<YardiAmendments_Deals_Mutation_Response>;
  /** insert a single row into the table: "YardiAmendments_Deals" */
  insert_YardiAmendments_Deals_one?: Maybe<YardiAmendments_Deals>;
  /** insert a single row into the table: "YardiAmendments" */
  insert_YardiAmendments_one?: Maybe<YardiAmendments>;
  /** insert data into the table: "YardiContacts" */
  insert_YardiContacts?: Maybe<YardiContacts_Mutation_Response>;
  /** insert data into the table: "YardiContacts_Contacts" */
  insert_YardiContacts_Contacts?: Maybe<YardiContacts_Contacts_Mutation_Response>;
  /** insert a single row into the table: "YardiContacts_Contacts" */
  insert_YardiContacts_Contacts_one?: Maybe<YardiContacts_Contacts>;
  /** insert data into the table: "YardiContacts_DealOwners" */
  insert_YardiContacts_DealOwners?: Maybe<YardiContacts_DealOwners_Mutation_Response>;
  /** insert a single row into the table: "YardiContacts_DealOwners" */
  insert_YardiContacts_DealOwners_one?: Maybe<YardiContacts_DealOwners>;
  /** insert data into the table: "YardiContacts_YardiProperties" */
  insert_YardiContacts_YardiProperties?: Maybe<YardiContacts_YardiProperties_Mutation_Response>;
  /** insert a single row into the table: "YardiContacts_YardiProperties" */
  insert_YardiContacts_YardiProperties_one?: Maybe<YardiContacts_YardiProperties>;
  /** insert a single row into the table: "YardiContacts" */
  insert_YardiContacts_one?: Maybe<YardiContacts>;
  /** insert data into the table: "YardiCustomers" */
  insert_YardiCustomers?: Maybe<YardiCustomers_Mutation_Response>;
  /** insert data into the table: "YardiCustomers_Deals" */
  insert_YardiCustomers_Deals?: Maybe<YardiCustomers_Deals_Mutation_Response>;
  /** insert a single row into the table: "YardiCustomers_Deals" */
  insert_YardiCustomers_Deals_one?: Maybe<YardiCustomers_Deals>;
  /** insert a single row into the table: "YardiCustomers" */
  insert_YardiCustomers_one?: Maybe<YardiCustomers>;
  /** insert data into the table: "YardiDealSyncStatuses" */
  insert_YardiDealSyncStatuses?: Maybe<YardiDealSyncStatuses_Mutation_Response>;
  /** insert a single row into the table: "YardiDealSyncStatuses" */
  insert_YardiDealSyncStatuses_one?: Maybe<YardiDealSyncStatuses>;
  /** insert data into the table: "YardiIngestionInfos" */
  insert_YardiIngestionInfos?: Maybe<YardiIngestionInfos_Mutation_Response>;
  /** insert a single row into the table: "YardiIngestionInfos" */
  insert_YardiIngestionInfos_one?: Maybe<YardiIngestionInfos>;
  /** insert data into the table: "YardiProperties" */
  insert_YardiProperties?: Maybe<YardiProperties_Mutation_Response>;
  /** insert a single row into the table: "YardiProperties" */
  insert_YardiProperties_one?: Maybe<YardiProperties>;
  /** insert data into the table: "YardiPropertyProfiles" */
  insert_YardiPropertyProfiles?: Maybe<YardiPropertyProfiles_Mutation_Response>;
  /** insert a single row into the table: "YardiPropertyProfiles" */
  insert_YardiPropertyProfiles_one?: Maybe<YardiPropertyProfiles>;
  /** insert data into the table: "YardiTenantToUnits" */
  insert_YardiTenantToUnits?: Maybe<YardiTenantToUnits_Mutation_Response>;
  /** insert a single row into the table: "YardiTenantToUnits" */
  insert_YardiTenantToUnits_one?: Maybe<YardiTenantToUnits>;
  /** insert data into the table: "YardiTenant_YardiContacts" */
  insert_YardiTenant_YardiContacts?: Maybe<YardiTenant_YardiContacts_Mutation_Response>;
  /** insert a single row into the table: "YardiTenant_YardiContacts" */
  insert_YardiTenant_YardiContacts_one?: Maybe<YardiTenant_YardiContacts>;
  /** insert data into the table: "YardiTenants" */
  insert_YardiTenants?: Maybe<YardiTenants_Mutation_Response>;
  /** insert data into the table: "YardiTenants_Deals" */
  insert_YardiTenants_Deals?: Maybe<YardiTenants_Deals_Mutation_Response>;
  /** insert a single row into the table: "YardiTenants_Deals" */
  insert_YardiTenants_Deals_one?: Maybe<YardiTenants_Deals>;
  /** insert a single row into the table: "YardiTenants" */
  insert_YardiTenants_one?: Maybe<YardiTenants>;
  /** insert data into the table: "YardiUnitProfiles" */
  insert_YardiUnitProfiles?: Maybe<YardiUnitProfiles_Mutation_Response>;
  /** insert a single row into the table: "YardiUnitProfiles" */
  insert_YardiUnitProfiles_one?: Maybe<YardiUnitProfiles>;
  /** insert data into the table: "YardiUnits" */
  insert_YardiUnits?: Maybe<YardiUnits_Mutation_Response>;
  /** insert a single row into the table: "YardiUnits" */
  insert_YardiUnits_one?: Maybe<YardiUnits>;
  /** insert data into the table: "YardiVirtualUnits" */
  insert_YardiVirtualUnits?: Maybe<YardiVirtualUnits_Mutation_Response>;
  /** insert data into the table: "YardiVirtualUnits_VirtualUnits" */
  insert_YardiVirtualUnits_VirtualUnits?: Maybe<YardiVirtualUnits_VirtualUnits_Mutation_Response>;
  /** insert a single row into the table: "YardiVirtualUnits_VirtualUnits" */
  insert_YardiVirtualUnits_VirtualUnits_one?: Maybe<YardiVirtualUnits_VirtualUnits>;
  /** insert a single row into the table: "YardiVirtualUnits" */
  insert_YardiVirtualUnits_one?: Maybe<YardiVirtualUnits>;
  queueEmpty?: Maybe<QueueCounts>;
  /** update data of the table: "ActionAllocations" */
  update_ActionAllocations?: Maybe<ActionAllocations_Mutation_Response>;
  /** update single row of the table: "ActionAllocations" */
  update_ActionAllocations_by_pk?: Maybe<ActionAllocations>;
  /** update multiples rows of table: "ActionAllocations" */
  update_ActionAllocations_many?: Maybe<
    Array<Maybe<ActionAllocations_Mutation_Response>>
  >;
  /** update data of the table: "ActionItems" */
  update_ActionItems?: Maybe<ActionItems_Mutation_Response>;
  /** update single row of the table: "ActionItems" */
  update_ActionItems_by_pk?: Maybe<ActionItems>;
  /** update multiples rows of table: "ActionItems" */
  update_ActionItems_many?: Maybe<Array<Maybe<ActionItems_Mutation_Response>>>;
  /** update data of the table: "ActivityLog" */
  update_ActivityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** update data of the table: "ActivityLogType" */
  update_ActivityLogType?: Maybe<ActivityLogType_Mutation_Response>;
  /** update single row of the table: "ActivityLogType" */
  update_ActivityLogType_by_pk?: Maybe<ActivityLogType>;
  /** update multiples rows of table: "ActivityLogType" */
  update_ActivityLogType_many?: Maybe<
    Array<Maybe<ActivityLogType_Mutation_Response>>
  >;
  /** update single row of the table: "ActivityLog" */
  update_ActivityLog_by_pk?: Maybe<ActivityLog>;
  /** update multiples rows of table: "ActivityLog" */
  update_ActivityLog_many?: Maybe<Array<Maybe<ActivityLog_Mutation_Response>>>;
  /** update data of the table: "Agreements" */
  update_Agreements?: Maybe<Agreements_Mutation_Response>;
  /** update single row of the table: "Agreements" */
  update_Agreements_by_pk?: Maybe<Agreements>;
  /** update multiples rows of table: "Agreements" */
  update_Agreements_many?: Maybe<Array<Maybe<Agreements_Mutation_Response>>>;
  /** update data of the table: "Approvers" */
  update_Approvers?: Maybe<Approvers_Mutation_Response>;
  /** update single row of the table: "Approvers" */
  update_Approvers_by_pk?: Maybe<Approvers>;
  /** update multiples rows of table: "Approvers" */
  update_Approvers_many?: Maybe<Array<Maybe<Approvers_Mutation_Response>>>;
  /** update data of the table: "BaseLandlordServices" */
  update_BaseLandlordServices?: Maybe<BaseLandlordServices_Mutation_Response>;
  /** update single row of the table: "BaseLandlordServices" */
  update_BaseLandlordServices_by_pk?: Maybe<BaseLandlordServices>;
  /** update multiples rows of table: "BaseLandlordServices" */
  update_BaseLandlordServices_many?: Maybe<
    Array<Maybe<BaseLandlordServices_Mutation_Response>>
  >;
  /** update data of the table: "CenterApprovers" */
  update_CenterApprovers?: Maybe<CenterApprovers_Mutation_Response>;
  /** update single row of the table: "CenterApprovers" */
  update_CenterApprovers_by_pk?: Maybe<CenterApprovers>;
  /** update multiples rows of table: "CenterApprovers" */
  update_CenterApprovers_many?: Maybe<
    Array<Maybe<CenterApprovers_Mutation_Response>>
  >;
  /** update data of the table: "CommentTags" */
  update_CommentTags?: Maybe<CommentTags_Mutation_Response>;
  /** update single row of the table: "CommentTags" */
  update_CommentTags_by_pk?: Maybe<CommentTags>;
  /** update multiples rows of table: "CommentTags" */
  update_CommentTags_many?: Maybe<Array<Maybe<CommentTags_Mutation_Response>>>;
  /** update data of the table: "CommentUserTags" */
  update_CommentUserTags?: Maybe<CommentUserTags_Mutation_Response>;
  /** update single row of the table: "CommentUserTags" */
  update_CommentUserTags_by_pk?: Maybe<CommentUserTags>;
  /** update multiples rows of table: "CommentUserTags" */
  update_CommentUserTags_many?: Maybe<
    Array<Maybe<CommentUserTags_Mutation_Response>>
  >;
  /** update data of the table: "Comments" */
  update_Comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "Comments" */
  update_Comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "Comments" */
  update_Comments_many?: Maybe<Array<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "Companies" */
  update_Companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "Companies" */
  update_Companies_by_pk?: Maybe<Companies>;
  /** update multiples rows of table: "Companies" */
  update_Companies_many?: Maybe<Array<Maybe<Companies_Mutation_Response>>>;
  /** update data of the table: "ContactTypes" */
  update_ContactTypes?: Maybe<ContactTypes_Mutation_Response>;
  /** update single row of the table: "ContactTypes" */
  update_ContactTypes_by_pk?: Maybe<ContactTypes>;
  /** update multiples rows of table: "ContactTypes" */
  update_ContactTypes_many?: Maybe<
    Array<Maybe<ContactTypes_Mutation_Response>>
  >;
  /** update data of the table: "Contacts" */
  update_Contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "Contacts" */
  update_Contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "Contacts" */
  update_Contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "Countries" */
  update_Countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "Countries" */
  update_Countries_by_pk?: Maybe<Countries>;
  /** update multiples rows of table: "Countries" */
  update_Countries_many?: Maybe<Array<Maybe<Countries_Mutation_Response>>>;
  /** update data of the table: "CustomLicences" */
  update_CustomLicences?: Maybe<CustomLicences_Mutation_Response>;
  /** update single row of the table: "CustomLicences" */
  update_CustomLicences_by_pk?: Maybe<CustomLicences>;
  /** update multiples rows of table: "CustomLicences" */
  update_CustomLicences_many?: Maybe<
    Array<Maybe<CustomLicences_Mutation_Response>>
  >;
  /** update data of the table: "DealActionItem" */
  update_DealActionItem?: Maybe<DealActionItem_Mutation_Response>;
  /** update single row of the table: "DealActionItem" */
  update_DealActionItem_by_pk?: Maybe<DealActionItem>;
  /** update multiples rows of table: "DealActionItem" */
  update_DealActionItem_many?: Maybe<
    Array<Maybe<DealActionItem_Mutation_Response>>
  >;
  /** update data of the table: "DealBusinessContacts" */
  update_DealBusinessContacts?: Maybe<DealBusinessContacts_Mutation_Response>;
  /** update single row of the table: "DealBusinessContacts" */
  update_DealBusinessContacts_by_pk?: Maybe<DealBusinessContacts>;
  /** update multiples rows of table: "DealBusinessContacts" */
  update_DealBusinessContacts_many?: Maybe<
    Array<Maybe<DealBusinessContacts_Mutation_Response>>
  >;
  /** update data of the table: "DealContactsTags" */
  update_DealContactsTags?: Maybe<DealContactsTags_Mutation_Response>;
  /** update single row of the table: "DealContactsTags" */
  update_DealContactsTags_by_pk?: Maybe<DealContactsTags>;
  /** update multiples rows of table: "DealContactsTags" */
  update_DealContactsTags_many?: Maybe<
    Array<Maybe<DealContactsTags_Mutation_Response>>
  >;
  /** update data of the table: "DealData" */
  update_DealData?: Maybe<DealData_Mutation_Response>;
  /** update data of the table: "DealDataCustomLicences" */
  update_DealDataCustomLicences?: Maybe<DealDataCustomLicences_Mutation_Response>;
  /** update single row of the table: "DealDataCustomLicences" */
  update_DealDataCustomLicences_by_pk?: Maybe<DealDataCustomLicences>;
  /** update multiples rows of table: "DealDataCustomLicences" */
  update_DealDataCustomLicences_many?: Maybe<
    Array<Maybe<DealDataCustomLicences_Mutation_Response>>
  >;
  /** update data of the table: "DealDataGuarantors" */
  update_DealDataGuarantors?: Maybe<DealDataGuarantors_Mutation_Response>;
  /** update single row of the table: "DealDataGuarantors" */
  update_DealDataGuarantors_by_pk?: Maybe<DealDataGuarantors>;
  /** update multiples rows of table: "DealDataGuarantors" */
  update_DealDataGuarantors_many?: Maybe<
    Array<Maybe<DealDataGuarantors_Mutation_Response>>
  >;
  /** update single row of the table: "DealData" */
  update_DealData_by_pk?: Maybe<DealData>;
  /** update multiples rows of table: "DealData" */
  update_DealData_many?: Maybe<Array<Maybe<DealData_Mutation_Response>>>;
  /** update data of the table: "DealExtensionItems" */
  update_DealExtensionItems?: Maybe<DealExtensionItems_Mutation_Response>;
  /** update single row of the table: "DealExtensionItems" */
  update_DealExtensionItems_by_pk?: Maybe<DealExtensionItems>;
  /** update multiples rows of table: "DealExtensionItems" */
  update_DealExtensionItems_many?: Maybe<
    Array<Maybe<DealExtensionItems_Mutation_Response>>
  >;
  /** update data of the table: "DealFileChunks" */
  update_DealFileChunks?: Maybe<DealFileChunks_Mutation_Response>;
  /** update single row of the table: "DealFileChunks" */
  update_DealFileChunks_by_pk?: Maybe<DealFileChunks>;
  /** update multiples rows of table: "DealFileChunks" */
  update_DealFileChunks_many?: Maybe<
    Array<Maybe<DealFileChunks_Mutation_Response>>
  >;
  /** update data of the table: "DealFileTags" */
  update_DealFileTags?: Maybe<DealFileTags_Mutation_Response>;
  /** update single row of the table: "DealFileTags" */
  update_DealFileTags_by_pk?: Maybe<DealFileTags>;
  /** update multiples rows of table: "DealFileTags" */
  update_DealFileTags_many?: Maybe<
    Array<Maybe<DealFileTags_Mutation_Response>>
  >;
  /** update data of the table: "DealFiles" */
  update_DealFiles?: Maybe<DealFiles_Mutation_Response>;
  /** update single row of the table: "DealFiles" */
  update_DealFiles_by_pk?: Maybe<DealFiles>;
  /** update multiples rows of table: "DealFiles" */
  update_DealFiles_many?: Maybe<Array<Maybe<DealFiles_Mutation_Response>>>;
  /** update data of the table: "DealFitoutLandlordWorks" */
  update_DealFitoutLandlordWorks?: Maybe<DealFitoutLandlordWorks_Mutation_Response>;
  /** update single row of the table: "DealFitoutLandlordWorks" */
  update_DealFitoutLandlordWorks_by_pk?: Maybe<DealFitoutLandlordWorks>;
  /** update multiples rows of table: "DealFitoutLandlordWorks" */
  update_DealFitoutLandlordWorks_many?: Maybe<
    Array<Maybe<DealFitoutLandlordWorks_Mutation_Response>>
  >;
  /** update data of the table: "DealLAFApprovals" */
  update_DealLAFApprovals?: Maybe<DealLafApprovals_Mutation_Response>;
  /** update single row of the table: "DealLAFApprovals" */
  update_DealLAFApprovals_by_pk?: Maybe<DealLafApprovals>;
  /** update multiples rows of table: "DealLAFApprovals" */
  update_DealLAFApprovals_many?: Maybe<
    Array<Maybe<DealLafApprovals_Mutation_Response>>
  >;
  /** update data of the table: "DealLinks" */
  update_DealLinks?: Maybe<DealLinks_Mutation_Response>;
  /** update single row of the table: "DealLinks" */
  update_DealLinks_by_pk?: Maybe<DealLinks>;
  /** update multiples rows of table: "DealLinks" */
  update_DealLinks_many?: Maybe<Array<Maybe<DealLinks_Mutation_Response>>>;
  /** update data of the table: "DealOptions" */
  update_DealOptions?: Maybe<DealOptions_Mutation_Response>;
  /** update single row of the table: "DealOptions" */
  update_DealOptions_by_pk?: Maybe<DealOptions>;
  /** update multiples rows of table: "DealOptions" */
  update_DealOptions_many?: Maybe<Array<Maybe<DealOptions_Mutation_Response>>>;
  /** update data of the table: "DealPermittedUses" */
  update_DealPermittedUses?: Maybe<DealPermittedUses_Mutation_Response>;
  /** update single row of the table: "DealPermittedUses" */
  update_DealPermittedUses_by_pk?: Maybe<DealPermittedUses>;
  /** update multiples rows of table: "DealPermittedUses" */
  update_DealPermittedUses_many?: Maybe<
    Array<Maybe<DealPermittedUses_Mutation_Response>>
  >;
  /** update data of the table: "DealPhases" */
  update_DealPhases?: Maybe<DealPhases_Mutation_Response>;
  /** update single row of the table: "DealPhases" */
  update_DealPhases_by_pk?: Maybe<DealPhases>;
  /** update multiples rows of table: "DealPhases" */
  update_DealPhases_many?: Maybe<Array<Maybe<DealPhases_Mutation_Response>>>;
  /** update data of the table: "DealRiskLevels" */
  update_DealRiskLevels?: Maybe<DealRiskLevels_Mutation_Response>;
  /** update single row of the table: "DealRiskLevels" */
  update_DealRiskLevels_by_pk?: Maybe<DealRiskLevels>;
  /** update multiples rows of table: "DealRiskLevels" */
  update_DealRiskLevels_many?: Maybe<
    Array<Maybe<DealRiskLevels_Mutation_Response>>
  >;
  /** update data of the table: "DealShopActivities" */
  update_DealShopActivities?: Maybe<DealShopActivities_Mutation_Response>;
  /** update single row of the table: "DealShopActivities" */
  update_DealShopActivities_by_pk?: Maybe<DealShopActivities>;
  /** update multiples rows of table: "DealShopActivities" */
  update_DealShopActivities_many?: Maybe<
    Array<Maybe<DealShopActivities_Mutation_Response>>
  >;
  /** update data of the table: "DealShopActivityTypes" */
  update_DealShopActivityTypes?: Maybe<DealShopActivityTypes_Mutation_Response>;
  /** update single row of the table: "DealShopActivityTypes" */
  update_DealShopActivityTypes_by_pk?: Maybe<DealShopActivityTypes>;
  /** update multiples rows of table: "DealShopActivityTypes" */
  update_DealShopActivityTypes_many?: Maybe<
    Array<Maybe<DealShopActivityTypes_Mutation_Response>>
  >;
  /** update data of the table: "DealSpecialConditions" */
  update_DealSpecialConditions?: Maybe<DealSpecialConditions_Mutation_Response>;
  /** update single row of the table: "DealSpecialConditions" */
  update_DealSpecialConditions_by_pk?: Maybe<DealSpecialConditions>;
  /** update multiples rows of table: "DealSpecialConditions" */
  update_DealSpecialConditions_many?: Maybe<
    Array<Maybe<DealSpecialConditions_Mutation_Response>>
  >;
  /** update data of the table: "DealStatus" */
  update_DealStatus?: Maybe<DealStatus_Mutation_Response>;
  /** update single row of the table: "DealStatus" */
  update_DealStatus_by_pk?: Maybe<DealStatus>;
  /** update multiples rows of table: "DealStatus" */
  update_DealStatus_many?: Maybe<Array<Maybe<DealStatus_Mutation_Response>>>;
  /** update data of the table: "DealTypes" */
  update_DealTypes?: Maybe<DealTypes_Mutation_Response>;
  /** update single row of the table: "DealTypes" */
  update_DealTypes_by_pk?: Maybe<DealTypes>;
  /** update multiples rows of table: "DealTypes" */
  update_DealTypes_many?: Maybe<Array<Maybe<DealTypes_Mutation_Response>>>;
  /** update data of the table: "Deals" */
  update_Deals?: Maybe<Deals_Mutation_Response>;
  /** update data of the table: "DealsCompanies" */
  update_DealsCompanies?: Maybe<DealsCompanies_Mutation_Response>;
  /** update single row of the table: "DealsCompanies" */
  update_DealsCompanies_by_pk?: Maybe<DealsCompanies>;
  /** update multiples rows of table: "DealsCompanies" */
  update_DealsCompanies_many?: Maybe<
    Array<Maybe<DealsCompanies_Mutation_Response>>
  >;
  /** update data of the table: "DealsContacts" */
  update_DealsContacts?: Maybe<DealsContacts_Mutation_Response>;
  /** update single row of the table: "DealsContacts" */
  update_DealsContacts_by_pk?: Maybe<DealsContacts>;
  /** update multiples rows of table: "DealsContacts" */
  update_DealsContacts_many?: Maybe<
    Array<Maybe<DealsContacts_Mutation_Response>>
  >;
  /** update data of the table: "DealsUnits" */
  update_DealsUnits?: Maybe<DealsUnits_Mutation_Response>;
  /** update single row of the table: "DealsUnits" */
  update_DealsUnits_by_pk?: Maybe<DealsUnits>;
  /** update multiples rows of table: "DealsUnits" */
  update_DealsUnits_many?: Maybe<Array<Maybe<DealsUnits_Mutation_Response>>>;
  /** update single row of the table: "Deals" */
  update_Deals_by_pk?: Maybe<Deals>;
  /** update multiples rows of table: "Deals" */
  update_Deals_many?: Maybe<Array<Maybe<Deals_Mutation_Response>>>;
  /** update data of the table: "DocumentRequests" */
  update_DocumentRequests?: Maybe<DocumentRequests_Mutation_Response>;
  /** update single row of the table: "DocumentRequests" */
  update_DocumentRequests_by_pk?: Maybe<DocumentRequests>;
  /** update multiples rows of table: "DocumentRequests" */
  update_DocumentRequests_many?: Maybe<
    Array<Maybe<DocumentRequests_Mutation_Response>>
  >;
  /** update data of the table: "DocumentTemplates" */
  update_DocumentTemplates?: Maybe<DocumentTemplates_Mutation_Response>;
  /** update single row of the table: "DocumentTemplates" */
  update_DocumentTemplates_by_pk?: Maybe<DocumentTemplates>;
  /** update multiples rows of table: "DocumentTemplates" */
  update_DocumentTemplates_many?: Maybe<
    Array<Maybe<DocumentTemplates_Mutation_Response>>
  >;
  /** update data of the table: "EscalatedApprovers" */
  update_EscalatedApprovers?: Maybe<EscalatedApprovers_Mutation_Response>;
  /** update single row of the table: "EscalatedApprovers" */
  update_EscalatedApprovers_by_pk?: Maybe<EscalatedApprovers>;
  /** update multiples rows of table: "EscalatedApprovers" */
  update_EscalatedApprovers_many?: Maybe<
    Array<Maybe<EscalatedApprovers_Mutation_Response>>
  >;
  /** update data of the table: "GeneratedDocuments" */
  update_GeneratedDocuments?: Maybe<GeneratedDocuments_Mutation_Response>;
  /** update single row of the table: "GeneratedDocuments" */
  update_GeneratedDocuments_by_pk?: Maybe<GeneratedDocuments>;
  /** update multiples rows of table: "GeneratedDocuments" */
  update_GeneratedDocuments_many?: Maybe<
    Array<Maybe<GeneratedDocuments_Mutation_Response>>
  >;
  /** update data of the table: "LafActionChoices" */
  update_LafActionChoices?: Maybe<LafActionChoices_Mutation_Response>;
  /** update single row of the table: "LafActionChoices" */
  update_LafActionChoices_by_pk?: Maybe<LafActionChoices>;
  /** update multiples rows of table: "LafActionChoices" */
  update_LafActionChoices_many?: Maybe<
    Array<Maybe<LafActionChoices_Mutation_Response>>
  >;
  /** update data of the table: "Leases" */
  update_Leases?: Maybe<Leases_Mutation_Response>;
  /** update single row of the table: "Leases" */
  update_Leases_by_pk?: Maybe<Leases>;
  /** update multiples rows of table: "Leases" */
  update_Leases_many?: Maybe<Array<Maybe<Leases_Mutation_Response>>>;
  /** update data of the table: "Locations" */
  update_Locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "Locations" */
  update_Locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "Locations" */
  update_Locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "Offers" */
  update_Offers?: Maybe<Offers_Mutation_Response>;
  /** update single row of the table: "Offers" */
  update_Offers_by_pk?: Maybe<Offers>;
  /** update multiples rows of table: "Offers" */
  update_Offers_many?: Maybe<Array<Maybe<Offers_Mutation_Response>>>;
  /** update data of the table: "PhaseActionItem" */
  update_PhaseActionItem?: Maybe<PhaseActionItem_Mutation_Response>;
  /** update single row of the table: "PhaseActionItem" */
  update_PhaseActionItem_by_pk?: Maybe<PhaseActionItem>;
  /** update multiples rows of table: "PhaseActionItem" */
  update_PhaseActionItem_many?: Maybe<
    Array<Maybe<PhaseActionItem_Mutation_Response>>
  >;
  /** update data of the table: "PhaseDealStatus" */
  update_PhaseDealStatus?: Maybe<PhaseDealStatus_Mutation_Response>;
  /** update single row of the table: "PhaseDealStatus" */
  update_PhaseDealStatus_by_pk?: Maybe<PhaseDealStatus>;
  /** update multiples rows of table: "PhaseDealStatus" */
  update_PhaseDealStatus_many?: Maybe<
    Array<Maybe<PhaseDealStatus_Mutation_Response>>
  >;
  /** update data of the table: "Phases" */
  update_Phases?: Maybe<Phases_Mutation_Response>;
  /** update single row of the table: "Phases" */
  update_Phases_by_pk?: Maybe<Phases>;
  /** update multiples rows of table: "Phases" */
  update_Phases_many?: Maybe<Array<Maybe<Phases_Mutation_Response>>>;
  /** update data of the table: "PropertiesInformation" */
  update_PropertiesInformation?: Maybe<PropertiesInformation_Mutation_Response>;
  /** update single row of the table: "PropertiesInformation" */
  update_PropertiesInformation_by_pk?: Maybe<PropertiesInformation>;
  /** update multiples rows of table: "PropertiesInformation" */
  update_PropertiesInformation_many?: Maybe<
    Array<Maybe<PropertiesInformation_Mutation_Response>>
  >;
  /** update data of the table: "PropertyContacts" */
  update_PropertyContacts?: Maybe<PropertyContacts_Mutation_Response>;
  /** update single row of the table: "PropertyContacts" */
  update_PropertyContacts_by_pk?: Maybe<PropertyContacts>;
  /** update multiples rows of table: "PropertyContacts" */
  update_PropertyContacts_many?: Maybe<
    Array<Maybe<PropertyContacts_Mutation_Response>>
  >;
  /** update data of the table: "PropertyFileTypes" */
  update_PropertyFileTypes?: Maybe<PropertyFileTypes_Mutation_Response>;
  /** update single row of the table: "PropertyFileTypes" */
  update_PropertyFileTypes_by_pk?: Maybe<PropertyFileTypes>;
  /** update multiples rows of table: "PropertyFileTypes" */
  update_PropertyFileTypes_many?: Maybe<
    Array<Maybe<PropertyFileTypes_Mutation_Response>>
  >;
  /** update data of the table: "PropertyFiles" */
  update_PropertyFiles?: Maybe<PropertyFiles_Mutation_Response>;
  /** update single row of the table: "PropertyFiles" */
  update_PropertyFiles_by_pk?: Maybe<PropertyFiles>;
  /** update multiples rows of table: "PropertyFiles" */
  update_PropertyFiles_many?: Maybe<
    Array<Maybe<PropertyFiles_Mutation_Response>>
  >;
  /** update data of the table: "Proposals" */
  update_Proposals?: Maybe<Proposals_Mutation_Response>;
  /** update single row of the table: "Proposals" */
  update_Proposals_by_pk?: Maybe<Proposals>;
  /** update multiples rows of table: "Proposals" */
  update_Proposals_many?: Maybe<Array<Maybe<Proposals_Mutation_Response>>>;
  /** update data of the table: "Registrations" */
  update_Registrations?: Maybe<Registrations_Mutation_Response>;
  /** update single row of the table: "Registrations" */
  update_Registrations_by_pk?: Maybe<Registrations>;
  /** update multiples rows of table: "Registrations" */
  update_Registrations_many?: Maybe<
    Array<Maybe<Registrations_Mutation_Response>>
  >;
  /** update data of the table: "SalesType" */
  update_SalesType?: Maybe<SalesType_Mutation_Response>;
  /** update single row of the table: "SalesType" */
  update_SalesType_by_pk?: Maybe<SalesType>;
  /** update multiples rows of table: "SalesType" */
  update_SalesType_many?: Maybe<Array<Maybe<SalesType_Mutation_Response>>>;
  /** update data of the table: "States" */
  update_States?: Maybe<States_Mutation_Response>;
  /** update single row of the table: "States" */
  update_States_by_pk?: Maybe<States>;
  /** update multiples rows of table: "States" */
  update_States_many?: Maybe<Array<Maybe<States_Mutation_Response>>>;
  /** update data of the table: "Tags" */
  update_Tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "Tags" */
  update_Tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "Tags" */
  update_Tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "TemplatePermittedUses" */
  update_TemplatePermittedUses?: Maybe<TemplatePermittedUses_Mutation_Response>;
  /** update single row of the table: "TemplatePermittedUses" */
  update_TemplatePermittedUses_by_pk?: Maybe<TemplatePermittedUses>;
  /** update multiples rows of table: "TemplatePermittedUses" */
  update_TemplatePermittedUses_many?: Maybe<
    Array<Maybe<TemplatePermittedUses_Mutation_Response>>
  >;
  /** update data of the table: "TemplateSpecialConditions" */
  update_TemplateSpecialConditions?: Maybe<TemplateSpecialConditions_Mutation_Response>;
  /** update single row of the table: "TemplateSpecialConditions" */
  update_TemplateSpecialConditions_by_pk?: Maybe<TemplateSpecialConditions>;
  /** update multiples rows of table: "TemplateSpecialConditions" */
  update_TemplateSpecialConditions_many?: Maybe<
    Array<Maybe<TemplateSpecialConditions_Mutation_Response>>
  >;
  /** update data of the table: "TenantTypes" */
  update_TenantTypes?: Maybe<TenantTypes_Mutation_Response>;
  /** update single row of the table: "TenantTypes" */
  update_TenantTypes_by_pk?: Maybe<TenantTypes>;
  /** update multiples rows of table: "TenantTypes" */
  update_TenantTypes_many?: Maybe<Array<Maybe<TenantTypes_Mutation_Response>>>;
  /** update data of the table: "Terms" */
  update_Terms?: Maybe<Terms_Mutation_Response>;
  /** update single row of the table: "Terms" */
  update_Terms_by_pk?: Maybe<Terms>;
  /** update multiples rows of table: "Terms" */
  update_Terms_many?: Maybe<Array<Maybe<Terms_Mutation_Response>>>;
  /** update data of the table: "TriSelectEnum" */
  update_TriSelectEnum?: Maybe<TriSelectEnum_Mutation_Response>;
  /** update single row of the table: "TriSelectEnum" */
  update_TriSelectEnum_by_pk?: Maybe<TriSelectEnum>;
  /** update multiples rows of table: "TriSelectEnum" */
  update_TriSelectEnum_many?: Maybe<
    Array<Maybe<TriSelectEnum_Mutation_Response>>
  >;
  /** update data of the table: "UnitTypes" */
  update_UnitTypes?: Maybe<UnitTypes_Mutation_Response>;
  /** update single row of the table: "UnitTypes" */
  update_UnitTypes_by_pk?: Maybe<UnitTypes>;
  /** update multiples rows of table: "UnitTypes" */
  update_UnitTypes_many?: Maybe<Array<Maybe<UnitTypes_Mutation_Response>>>;
  /** update data of the table: "Units" */
  update_Units?: Maybe<Units_Mutation_Response>;
  /** update multiples rows of table: "Units" */
  update_Units_many?: Maybe<Array<Maybe<Units_Mutation_Response>>>;
  /** update data of the table: "UserStatus" */
  update_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  /** update single row of the table: "UserStatus" */
  update_UserStatus_by_pk?: Maybe<UserStatus>;
  /** update multiples rows of table: "UserStatus" */
  update_UserStatus_many?: Maybe<Array<Maybe<UserStatus_Mutation_Response>>>;
  /** update data of the table: "Users" */
  update_Users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "Users" */
  update_Users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "Users" */
  update_Users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "VirtualUnits" */
  update_VirtualUnits?: Maybe<VirtualUnits_Mutation_Response>;
  /** update single row of the table: "VirtualUnits" */
  update_VirtualUnits_by_pk?: Maybe<VirtualUnits>;
  /** update multiples rows of table: "VirtualUnits" */
  update_VirtualUnits_many?: Maybe<
    Array<Maybe<VirtualUnits_Mutation_Response>>
  >;
  /** update data of the table: "YardiAmendments" */
  update_YardiAmendments?: Maybe<YardiAmendments_Mutation_Response>;
  /** update data of the table: "YardiAmendments_Deals" */
  update_YardiAmendments_Deals?: Maybe<YardiAmendments_Deals_Mutation_Response>;
  /** update single row of the table: "YardiAmendments_Deals" */
  update_YardiAmendments_Deals_by_pk?: Maybe<YardiAmendments_Deals>;
  /** update multiples rows of table: "YardiAmendments_Deals" */
  update_YardiAmendments_Deals_many?: Maybe<
    Array<Maybe<YardiAmendments_Deals_Mutation_Response>>
  >;
  /** update single row of the table: "YardiAmendments" */
  update_YardiAmendments_by_pk?: Maybe<YardiAmendments>;
  /** update multiples rows of table: "YardiAmendments" */
  update_YardiAmendments_many?: Maybe<
    Array<Maybe<YardiAmendments_Mutation_Response>>
  >;
  /** update data of the table: "YardiContacts" */
  update_YardiContacts?: Maybe<YardiContacts_Mutation_Response>;
  /** update data of the table: "YardiContacts_Contacts" */
  update_YardiContacts_Contacts?: Maybe<YardiContacts_Contacts_Mutation_Response>;
  /** update single row of the table: "YardiContacts_Contacts" */
  update_YardiContacts_Contacts_by_pk?: Maybe<YardiContacts_Contacts>;
  /** update multiples rows of table: "YardiContacts_Contacts" */
  update_YardiContacts_Contacts_many?: Maybe<
    Array<Maybe<YardiContacts_Contacts_Mutation_Response>>
  >;
  /** update data of the table: "YardiContacts_DealOwners" */
  update_YardiContacts_DealOwners?: Maybe<YardiContacts_DealOwners_Mutation_Response>;
  /** update single row of the table: "YardiContacts_DealOwners" */
  update_YardiContacts_DealOwners_by_pk?: Maybe<YardiContacts_DealOwners>;
  /** update multiples rows of table: "YardiContacts_DealOwners" */
  update_YardiContacts_DealOwners_many?: Maybe<
    Array<Maybe<YardiContacts_DealOwners_Mutation_Response>>
  >;
  /** update data of the table: "YardiContacts_YardiProperties" */
  update_YardiContacts_YardiProperties?: Maybe<YardiContacts_YardiProperties_Mutation_Response>;
  /** update single row of the table: "YardiContacts_YardiProperties" */
  update_YardiContacts_YardiProperties_by_pk?: Maybe<YardiContacts_YardiProperties>;
  /** update multiples rows of table: "YardiContacts_YardiProperties" */
  update_YardiContacts_YardiProperties_many?: Maybe<
    Array<Maybe<YardiContacts_YardiProperties_Mutation_Response>>
  >;
  /** update single row of the table: "YardiContacts" */
  update_YardiContacts_by_pk?: Maybe<YardiContacts>;
  /** update multiples rows of table: "YardiContacts" */
  update_YardiContacts_many?: Maybe<
    Array<Maybe<YardiContacts_Mutation_Response>>
  >;
  /** update data of the table: "YardiCustomers" */
  update_YardiCustomers?: Maybe<YardiCustomers_Mutation_Response>;
  /** update data of the table: "YardiCustomers_Deals" */
  update_YardiCustomers_Deals?: Maybe<YardiCustomers_Deals_Mutation_Response>;
  /** update single row of the table: "YardiCustomers_Deals" */
  update_YardiCustomers_Deals_by_pk?: Maybe<YardiCustomers_Deals>;
  /** update multiples rows of table: "YardiCustomers_Deals" */
  update_YardiCustomers_Deals_many?: Maybe<
    Array<Maybe<YardiCustomers_Deals_Mutation_Response>>
  >;
  /** update single row of the table: "YardiCustomers" */
  update_YardiCustomers_by_pk?: Maybe<YardiCustomers>;
  /** update multiples rows of table: "YardiCustomers" */
  update_YardiCustomers_many?: Maybe<
    Array<Maybe<YardiCustomers_Mutation_Response>>
  >;
  /** update data of the table: "YardiDealSyncStatuses" */
  update_YardiDealSyncStatuses?: Maybe<YardiDealSyncStatuses_Mutation_Response>;
  /** update single row of the table: "YardiDealSyncStatuses" */
  update_YardiDealSyncStatuses_by_pk?: Maybe<YardiDealSyncStatuses>;
  /** update multiples rows of table: "YardiDealSyncStatuses" */
  update_YardiDealSyncStatuses_many?: Maybe<
    Array<Maybe<YardiDealSyncStatuses_Mutation_Response>>
  >;
  /** update data of the table: "YardiIngestionInfos" */
  update_YardiIngestionInfos?: Maybe<YardiIngestionInfos_Mutation_Response>;
  /** update single row of the table: "YardiIngestionInfos" */
  update_YardiIngestionInfos_by_pk?: Maybe<YardiIngestionInfos>;
  /** update multiples rows of table: "YardiIngestionInfos" */
  update_YardiIngestionInfos_many?: Maybe<
    Array<Maybe<YardiIngestionInfos_Mutation_Response>>
  >;
  /** update data of the table: "YardiProperties" */
  update_YardiProperties?: Maybe<YardiProperties_Mutation_Response>;
  /** update single row of the table: "YardiProperties" */
  update_YardiProperties_by_pk?: Maybe<YardiProperties>;
  /** update multiples rows of table: "YardiProperties" */
  update_YardiProperties_many?: Maybe<
    Array<Maybe<YardiProperties_Mutation_Response>>
  >;
  /** update data of the table: "YardiPropertyProfiles" */
  update_YardiPropertyProfiles?: Maybe<YardiPropertyProfiles_Mutation_Response>;
  /** update single row of the table: "YardiPropertyProfiles" */
  update_YardiPropertyProfiles_by_pk?: Maybe<YardiPropertyProfiles>;
  /** update multiples rows of table: "YardiPropertyProfiles" */
  update_YardiPropertyProfiles_many?: Maybe<
    Array<Maybe<YardiPropertyProfiles_Mutation_Response>>
  >;
  /** update data of the table: "YardiTenantToUnits" */
  update_YardiTenantToUnits?: Maybe<YardiTenantToUnits_Mutation_Response>;
  /** update single row of the table: "YardiTenantToUnits" */
  update_YardiTenantToUnits_by_pk?: Maybe<YardiTenantToUnits>;
  /** update multiples rows of table: "YardiTenantToUnits" */
  update_YardiTenantToUnits_many?: Maybe<
    Array<Maybe<YardiTenantToUnits_Mutation_Response>>
  >;
  /** update data of the table: "YardiTenant_YardiContacts" */
  update_YardiTenant_YardiContacts?: Maybe<YardiTenant_YardiContacts_Mutation_Response>;
  /** update single row of the table: "YardiTenant_YardiContacts" */
  update_YardiTenant_YardiContacts_by_pk?: Maybe<YardiTenant_YardiContacts>;
  /** update multiples rows of table: "YardiTenant_YardiContacts" */
  update_YardiTenant_YardiContacts_many?: Maybe<
    Array<Maybe<YardiTenant_YardiContacts_Mutation_Response>>
  >;
  /** update data of the table: "YardiTenants" */
  update_YardiTenants?: Maybe<YardiTenants_Mutation_Response>;
  /** update data of the table: "YardiTenants_Deals" */
  update_YardiTenants_Deals?: Maybe<YardiTenants_Deals_Mutation_Response>;
  /** update single row of the table: "YardiTenants_Deals" */
  update_YardiTenants_Deals_by_pk?: Maybe<YardiTenants_Deals>;
  /** update multiples rows of table: "YardiTenants_Deals" */
  update_YardiTenants_Deals_many?: Maybe<
    Array<Maybe<YardiTenants_Deals_Mutation_Response>>
  >;
  /** update single row of the table: "YardiTenants" */
  update_YardiTenants_by_pk?: Maybe<YardiTenants>;
  /** update multiples rows of table: "YardiTenants" */
  update_YardiTenants_many?: Maybe<
    Array<Maybe<YardiTenants_Mutation_Response>>
  >;
  /** update data of the table: "YardiUnitProfiles" */
  update_YardiUnitProfiles?: Maybe<YardiUnitProfiles_Mutation_Response>;
  /** update single row of the table: "YardiUnitProfiles" */
  update_YardiUnitProfiles_by_pk?: Maybe<YardiUnitProfiles>;
  /** update multiples rows of table: "YardiUnitProfiles" */
  update_YardiUnitProfiles_many?: Maybe<
    Array<Maybe<YardiUnitProfiles_Mutation_Response>>
  >;
  /** update data of the table: "YardiUnits" */
  update_YardiUnits?: Maybe<YardiUnits_Mutation_Response>;
  /** update single row of the table: "YardiUnits" */
  update_YardiUnits_by_pk?: Maybe<YardiUnits>;
  /** update multiples rows of table: "YardiUnits" */
  update_YardiUnits_many?: Maybe<Array<Maybe<YardiUnits_Mutation_Response>>>;
  /** update data of the table: "YardiVirtualUnits" */
  update_YardiVirtualUnits?: Maybe<YardiVirtualUnits_Mutation_Response>;
  /** update data of the table: "YardiVirtualUnits_VirtualUnits" */
  update_YardiVirtualUnits_VirtualUnits?: Maybe<YardiVirtualUnits_VirtualUnits_Mutation_Response>;
  /** update single row of the table: "YardiVirtualUnits_VirtualUnits" */
  update_YardiVirtualUnits_VirtualUnits_by_pk?: Maybe<YardiVirtualUnits_VirtualUnits>;
  /** update multiples rows of table: "YardiVirtualUnits_VirtualUnits" */
  update_YardiVirtualUnits_VirtualUnits_many?: Maybe<
    Array<Maybe<YardiVirtualUnits_VirtualUnits_Mutation_Response>>
  >;
  /** update single row of the table: "YardiVirtualUnits" */
  update_YardiVirtualUnits_by_pk?: Maybe<YardiVirtualUnits>;
  /** update multiples rows of table: "YardiVirtualUnits" */
  update_YardiVirtualUnits_many?: Maybe<
    Array<Maybe<YardiVirtualUnits_Mutation_Response>>
  >;
  /** Delete a questionnaire record from Yardi to avoid duplication errs */
  yardiDeleteQuestionnaire?: Maybe<QuestionnaireDeleteOutput>;
};

/** mutation root */
export type Mutation_RootCopyDealArgs = {
  comment: Scalars["String"];
  dealId: Scalars["Int"];
  unit: Unit;
};

/** mutation root */
export type Mutation_RootCopyDealRelafArgs = {
  comment: Scalars["String"];
  dealId: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_ActionAllocationsArgs = {
  where: ActionAllocations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ActionAllocations_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ActionItemsArgs = {
  where: ActionItems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ActionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ActivityLogArgs = {
  where: ActivityLog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ActivityLogTypeArgs = {
  where: ActivityLogType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ActivityLogType_By_PkArgs = {
  log_type: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_ActivityLog_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_AgreementsArgs = {
  where: Agreements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Agreements_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ApproversArgs = {
  where: Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Approvers_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_BaseLandlordServicesArgs = {
  where: BaseLandlordServices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_BaseLandlordServices_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CenterApproversArgs = {
  where: CenterApprovers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CenterApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CommentTagsArgs = {
  where: CommentTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CommentTags_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CommentUserTagsArgs = {
  where: CommentUserTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CommentUserTags_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ContactTypesArgs = {
  where: ContactTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_CustomLicencesArgs = {
  where: CustomLicences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealActionItemArgs = {
  where: DealActionItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealBusinessContactsArgs = {
  where: DealBusinessContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealBusinessContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealContactsTagsArgs = {
  where: DealContactsTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealContactsTags_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealDataArgs = {
  where: DealData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealDataCustomLicencesArgs = {
  where: DealDataCustomLicences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealDataCustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealDataGuarantorsArgs = {
  where: DealDataGuarantors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealDataGuarantors_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealData_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealExtensionItemsArgs = {
  where: DealExtensionItems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealExtensionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealFileChunksArgs = {
  where: DealFileChunks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealFileChunks_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealFileTagsArgs = {
  where: DealFileTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealFileTags_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealFilesArgs = {
  where: DealFiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealFitoutLandlordWorksArgs = {
  where: DealFitoutLandlordWorks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealFitoutLandlordWorks_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealLafApprovalsArgs = {
  where: DealLafApprovals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealLafApprovals_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealLinksArgs = {
  where: DealLinks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealLinks_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_DealOptionsArgs = {
  where: DealOptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealOptions_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealPermittedUsesArgs = {
  where: DealPermittedUses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealPermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealPhasesArgs = {
  where: DealPhases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealPhases_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealRiskLevelsArgs = {
  where: DealRiskLevels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealRiskLevels_By_PkArgs = {
  risk_level: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DealShopActivitiesArgs = {
  where: DealShopActivities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealShopActivities_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealShopActivityTypesArgs = {
  where: DealShopActivityTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealShopActivityTypes_By_PkArgs = {
  name: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DealSpecialConditionsArgs = {
  where: DealSpecialConditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealStatusArgs = {
  where: DealStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealStatus_By_PkArgs = {
  name: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DealTypesArgs = {
  where: DealTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealsArgs = {
  where: Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealsCompaniesArgs = {
  where: DealsCompanies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealsCompanies_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealsContactsArgs = {
  where: DealsContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealsContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DealsUnitsArgs = {
  where: DealsUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DealsUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_Deals_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DocumentRequestsArgs = {
  where: DocumentRequests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DocumentRequests_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_DocumentTemplatesArgs = {
  where: DocumentTemplates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DocumentTemplates_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_EscalatedApproversArgs = {
  where: EscalatedApprovers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_EscalatedApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_GeneratedDocumentsArgs = {
  where: GeneratedDocuments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GeneratedDocuments_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_LafActionChoicesArgs = {
  where: LafActionChoices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_LafActionChoices_By_PkArgs = {
  name: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_LeasesArgs = {
  where: Leases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leases_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_OffersArgs = {
  where: Offers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Offers_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PhaseActionItemArgs = {
  where: PhaseActionItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PhaseActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PhaseDealStatusArgs = {
  where: PhaseDealStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PhaseDealStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PhasesArgs = {
  where: Phases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Phases_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PropertiesInformationArgs = {
  where: PropertiesInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PropertiesInformation_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PropertyContactsArgs = {
  where: PropertyContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PropertyContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_PropertyFileTypesArgs = {
  where: PropertyFileTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PropertyFileTypes_By_PkArgs = {
  name: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_PropertyFilesArgs = {
  where: PropertyFiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PropertyFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_ProposalsArgs = {
  where: Proposals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Proposals_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_RegistrationsArgs = {
  where: Registrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Registrations_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_SalesTypeArgs = {
  where: SalesType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesType_By_PkArgs = {
  name: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_StatesArgs = {
  where: States_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_States_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TemplatePermittedUsesArgs = {
  where: TemplatePermittedUses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TemplatePermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TemplateSpecialConditionsArgs = {
  where: TemplateSpecialConditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TemplateSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TenantTypesArgs = {
  where: TenantTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TenantTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TermsArgs = {
  where: Terms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Terms_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_TriSelectEnumArgs = {
  where: TriSelectEnum_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TriSelectEnum_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_UnitTypesArgs = {
  where: UnitTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UnitTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_UnitsArgs = {
  where: Units_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserStatusArgs = {
  where: UserStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_VirtualUnitsArgs = {
  where: VirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_VirtualUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiAmendmentsArgs = {
  where: YardiAmendments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiAmendments_DealsArgs = {
  where: YardiAmendments_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiAmendments_Deals_By_PkArgs = {
  AmendmentCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiAmendments_By_PkArgs = {
  AmendmentKey: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiContactsArgs = {
  where: YardiContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_ContactsArgs = {
  where: YardiContacts_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_Contacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  contact_id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_DealOwnersArgs = {
  where: YardiContacts_DealOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_DealOwners_By_PkArgs = {
  ContactCode: Scalars["String"];
  user_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_YardiPropertiesArgs = {
  where: YardiContacts_YardiProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_YardiProperties_By_PkArgs = {
  ContactCode: Scalars["String"];
  PropertyCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiCustomersArgs = {
  where: YardiCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiCustomers_DealsArgs = {
  where: YardiCustomers_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiCustomers_Deals_By_PkArgs = {
  CustomerCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiCustomers_By_PkArgs = {
  CustomerCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiDealSyncStatusesArgs = {
  where: YardiDealSyncStatuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiDealSyncStatuses_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiIngestionInfosArgs = {
  where: YardiIngestionInfos_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiIngestionInfos_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_YardiPropertiesArgs = {
  where: YardiProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiProperties_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiPropertyProfilesArgs = {
  where: YardiPropertyProfiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiPropertyProfiles_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiTenantToUnitsArgs = {
  where: YardiTenantToUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiTenantToUnits_By_PkArgs = {
  TenantCode: Scalars["String"];
  UnitKey: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiTenant_YardiContactsArgs = {
  where: YardiTenant_YardiContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiTenant_YardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  TenantCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiTenantsArgs = {
  where: YardiTenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiTenants_DealsArgs = {
  where: YardiTenants_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiTenants_Deals_By_PkArgs = {
  TenantCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiTenants_By_PkArgs = {
  TenantCode: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiUnitProfilesArgs = {
  where: YardiUnitProfiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiUnitProfiles_By_PkArgs = {
  UnitProfileKey: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiUnitsArgs = {
  where: YardiUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiUnits_By_PkArgs = {
  UnitKey: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_YardiVirtualUnitsArgs = {
  where: YardiVirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiVirtualUnits_VirtualUnitsArgs = {
  where: YardiVirtualUnits_VirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_YardiVirtualUnits_VirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
  virtual_unit_id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_YardiVirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
};

/** mutation root */
export type Mutation_RootEgestJobArgs = {
  dealArg: EgestInput;
};

/** mutation root */
export type Mutation_RootInsert_ActionAllocationsArgs = {
  objects: Array<ActionAllocations_Insert_Input>;
  on_conflict?: InputMaybe<ActionAllocations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActionAllocations_OneArgs = {
  object: ActionAllocations_Insert_Input;
  on_conflict?: InputMaybe<ActionAllocations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActionItemsArgs = {
  objects: Array<ActionItems_Insert_Input>;
  on_conflict?: InputMaybe<ActionItems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActionItems_OneArgs = {
  object: ActionItems_Insert_Input;
  on_conflict?: InputMaybe<ActionItems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActivityLogArgs = {
  objects: Array<ActivityLog_Insert_Input>;
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActivityLogTypeArgs = {
  objects: Array<ActivityLogType_Insert_Input>;
  on_conflict?: InputMaybe<ActivityLogType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActivityLogType_OneArgs = {
  object: ActivityLogType_Insert_Input;
  on_conflict?: InputMaybe<ActivityLogType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ActivityLog_OneArgs = {
  object: ActivityLog_Insert_Input;
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AgreementsArgs = {
  objects: Array<Agreements_Insert_Input>;
  on_conflict?: InputMaybe<Agreements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Agreements_OneArgs = {
  object: Agreements_Insert_Input;
  on_conflict?: InputMaybe<Agreements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ApproversArgs = {
  objects: Array<Approvers_Insert_Input>;
  on_conflict?: InputMaybe<Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Approvers_OneArgs = {
  object: Approvers_Insert_Input;
  on_conflict?: InputMaybe<Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_BaseLandlordServicesArgs = {
  objects: Array<BaseLandlordServices_Insert_Input>;
  on_conflict?: InputMaybe<BaseLandlordServices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_BaseLandlordServices_OneArgs = {
  object: BaseLandlordServices_Insert_Input;
  on_conflict?: InputMaybe<BaseLandlordServices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CenterApproversArgs = {
  objects: Array<CenterApprovers_Insert_Input>;
  on_conflict?: InputMaybe<CenterApprovers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CenterApprovers_OneArgs = {
  object: CenterApprovers_Insert_Input;
  on_conflict?: InputMaybe<CenterApprovers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentTagsArgs = {
  objects: Array<CommentTags_Insert_Input>;
  on_conflict?: InputMaybe<CommentTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentTags_OneArgs = {
  object: CommentTags_Insert_Input;
  on_conflict?: InputMaybe<CommentTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentUserTagsArgs = {
  objects: Array<CommentUserTags_Insert_Input>;
  on_conflict?: InputMaybe<CommentUserTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentUserTags_OneArgs = {
  object: CommentUserTags_Insert_Input;
  on_conflict?: InputMaybe<CommentUserTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactTypesArgs = {
  objects: Array<ContactTypes_Insert_Input>;
  on_conflict?: InputMaybe<ContactTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactTypes_OneArgs = {
  object: ContactTypes_Insert_Input;
  on_conflict?: InputMaybe<ContactTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CustomLicencesArgs = {
  objects: Array<CustomLicences_Insert_Input>;
  on_conflict?: InputMaybe<CustomLicences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CustomLicences_OneArgs = {
  object: CustomLicences_Insert_Input;
  on_conflict?: InputMaybe<CustomLicences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealActionItemArgs = {
  objects: Array<DealActionItem_Insert_Input>;
  on_conflict?: InputMaybe<DealActionItem_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealActionItem_OneArgs = {
  object: DealActionItem_Insert_Input;
  on_conflict?: InputMaybe<DealActionItem_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealBusinessContactsArgs = {
  objects: Array<DealBusinessContacts_Insert_Input>;
  on_conflict?: InputMaybe<DealBusinessContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealBusinessContacts_OneArgs = {
  object: DealBusinessContacts_Insert_Input;
  on_conflict?: InputMaybe<DealBusinessContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealContactsTagsArgs = {
  objects: Array<DealContactsTags_Insert_Input>;
  on_conflict?: InputMaybe<DealContactsTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealContactsTags_OneArgs = {
  object: DealContactsTags_Insert_Input;
  on_conflict?: InputMaybe<DealContactsTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealDataArgs = {
  objects: Array<DealData_Insert_Input>;
  on_conflict?: InputMaybe<DealData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealDataCustomLicencesArgs = {
  objects: Array<DealDataCustomLicences_Insert_Input>;
  on_conflict?: InputMaybe<DealDataCustomLicences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealDataCustomLicences_OneArgs = {
  object: DealDataCustomLicences_Insert_Input;
  on_conflict?: InputMaybe<DealDataCustomLicences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealDataGuarantorsArgs = {
  objects: Array<DealDataGuarantors_Insert_Input>;
  on_conflict?: InputMaybe<DealDataGuarantors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealDataGuarantors_OneArgs = {
  object: DealDataGuarantors_Insert_Input;
  on_conflict?: InputMaybe<DealDataGuarantors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealData_OneArgs = {
  object: DealData_Insert_Input;
  on_conflict?: InputMaybe<DealData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealExtensionItemsArgs = {
  objects: Array<DealExtensionItems_Insert_Input>;
  on_conflict?: InputMaybe<DealExtensionItems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealExtensionItems_OneArgs = {
  object: DealExtensionItems_Insert_Input;
  on_conflict?: InputMaybe<DealExtensionItems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFileChunksArgs = {
  objects: Array<DealFileChunks_Insert_Input>;
  on_conflict?: InputMaybe<DealFileChunks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFileChunks_OneArgs = {
  object: DealFileChunks_Insert_Input;
  on_conflict?: InputMaybe<DealFileChunks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFileTagsArgs = {
  objects: Array<DealFileTags_Insert_Input>;
  on_conflict?: InputMaybe<DealFileTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFileTags_OneArgs = {
  object: DealFileTags_Insert_Input;
  on_conflict?: InputMaybe<DealFileTags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFilesArgs = {
  objects: Array<DealFiles_Insert_Input>;
  on_conflict?: InputMaybe<DealFiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFiles_OneArgs = {
  object: DealFiles_Insert_Input;
  on_conflict?: InputMaybe<DealFiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFitoutLandlordWorksArgs = {
  objects: Array<DealFitoutLandlordWorks_Insert_Input>;
  on_conflict?: InputMaybe<DealFitoutLandlordWorks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealFitoutLandlordWorks_OneArgs = {
  object: DealFitoutLandlordWorks_Insert_Input;
  on_conflict?: InputMaybe<DealFitoutLandlordWorks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealLafApprovalsArgs = {
  objects: Array<DealLafApprovals_Insert_Input>;
  on_conflict?: InputMaybe<DealLafApprovals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealLafApprovals_OneArgs = {
  object: DealLafApprovals_Insert_Input;
  on_conflict?: InputMaybe<DealLafApprovals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealLinksArgs = {
  objects: Array<DealLinks_Insert_Input>;
  on_conflict?: InputMaybe<DealLinks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealLinks_OneArgs = {
  object: DealLinks_Insert_Input;
  on_conflict?: InputMaybe<DealLinks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealOptionsArgs = {
  objects: Array<DealOptions_Insert_Input>;
  on_conflict?: InputMaybe<DealOptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealOptions_OneArgs = {
  object: DealOptions_Insert_Input;
  on_conflict?: InputMaybe<DealOptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealPermittedUsesArgs = {
  objects: Array<DealPermittedUses_Insert_Input>;
  on_conflict?: InputMaybe<DealPermittedUses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealPermittedUses_OneArgs = {
  object: DealPermittedUses_Insert_Input;
  on_conflict?: InputMaybe<DealPermittedUses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealPhasesArgs = {
  objects: Array<DealPhases_Insert_Input>;
  on_conflict?: InputMaybe<DealPhases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealPhases_OneArgs = {
  object: DealPhases_Insert_Input;
  on_conflict?: InputMaybe<DealPhases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealRiskLevelsArgs = {
  objects: Array<DealRiskLevels_Insert_Input>;
  on_conflict?: InputMaybe<DealRiskLevels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealRiskLevels_OneArgs = {
  object: DealRiskLevels_Insert_Input;
  on_conflict?: InputMaybe<DealRiskLevels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealShopActivitiesArgs = {
  objects: Array<DealShopActivities_Insert_Input>;
  on_conflict?: InputMaybe<DealShopActivities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealShopActivities_OneArgs = {
  object: DealShopActivities_Insert_Input;
  on_conflict?: InputMaybe<DealShopActivities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealShopActivityTypesArgs = {
  objects: Array<DealShopActivityTypes_Insert_Input>;
  on_conflict?: InputMaybe<DealShopActivityTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealShopActivityTypes_OneArgs = {
  object: DealShopActivityTypes_Insert_Input;
  on_conflict?: InputMaybe<DealShopActivityTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealSpecialConditionsArgs = {
  objects: Array<DealSpecialConditions_Insert_Input>;
  on_conflict?: InputMaybe<DealSpecialConditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealSpecialConditions_OneArgs = {
  object: DealSpecialConditions_Insert_Input;
  on_conflict?: InputMaybe<DealSpecialConditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealStatusArgs = {
  objects: Array<DealStatus_Insert_Input>;
  on_conflict?: InputMaybe<DealStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealStatus_OneArgs = {
  object: DealStatus_Insert_Input;
  on_conflict?: InputMaybe<DealStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealTypesArgs = {
  objects: Array<DealTypes_Insert_Input>;
  on_conflict?: InputMaybe<DealTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealTypes_OneArgs = {
  object: DealTypes_Insert_Input;
  on_conflict?: InputMaybe<DealTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsArgs = {
  objects: Array<Deals_Insert_Input>;
  on_conflict?: InputMaybe<Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsCompaniesArgs = {
  objects: Array<DealsCompanies_Insert_Input>;
  on_conflict?: InputMaybe<DealsCompanies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsCompanies_OneArgs = {
  object: DealsCompanies_Insert_Input;
  on_conflict?: InputMaybe<DealsCompanies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsContactsArgs = {
  objects: Array<DealsContacts_Insert_Input>;
  on_conflict?: InputMaybe<DealsContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsContacts_OneArgs = {
  object: DealsContacts_Insert_Input;
  on_conflict?: InputMaybe<DealsContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsUnitsArgs = {
  objects: Array<DealsUnits_Insert_Input>;
  on_conflict?: InputMaybe<DealsUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DealsUnits_OneArgs = {
  object: DealsUnits_Insert_Input;
  on_conflict?: InputMaybe<DealsUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Deals_OneArgs = {
  object: Deals_Insert_Input;
  on_conflict?: InputMaybe<Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentRequestsArgs = {
  objects: Array<DocumentRequests_Insert_Input>;
  on_conflict?: InputMaybe<DocumentRequests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentRequests_OneArgs = {
  object: DocumentRequests_Insert_Input;
  on_conflict?: InputMaybe<DocumentRequests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentTemplatesArgs = {
  objects: Array<DocumentTemplates_Insert_Input>;
  on_conflict?: InputMaybe<DocumentTemplates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentTemplates_OneArgs = {
  object: DocumentTemplates_Insert_Input;
  on_conflict?: InputMaybe<DocumentTemplates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EscalatedApproversArgs = {
  objects: Array<EscalatedApprovers_Insert_Input>;
  on_conflict?: InputMaybe<EscalatedApprovers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EscalatedApprovers_OneArgs = {
  object: EscalatedApprovers_Insert_Input;
  on_conflict?: InputMaybe<EscalatedApprovers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GeneratedDocumentsArgs = {
  objects: Array<GeneratedDocuments_Insert_Input>;
  on_conflict?: InputMaybe<GeneratedDocuments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GeneratedDocuments_OneArgs = {
  object: GeneratedDocuments_Insert_Input;
  on_conflict?: InputMaybe<GeneratedDocuments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LafActionChoicesArgs = {
  objects: Array<LafActionChoices_Insert_Input>;
  on_conflict?: InputMaybe<LafActionChoices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LafActionChoices_OneArgs = {
  object: LafActionChoices_Insert_Input;
  on_conflict?: InputMaybe<LafActionChoices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LeasesArgs = {
  objects: Array<Leases_Insert_Input>;
  on_conflict?: InputMaybe<Leases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leases_OneArgs = {
  object: Leases_Insert_Input;
  on_conflict?: InputMaybe<Leases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OffersArgs = {
  objects: Array<Offers_Insert_Input>;
  on_conflict?: InputMaybe<Offers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Offers_OneArgs = {
  object: Offers_Insert_Input;
  on_conflict?: InputMaybe<Offers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PhaseActionItemArgs = {
  objects: Array<PhaseActionItem_Insert_Input>;
  on_conflict?: InputMaybe<PhaseActionItem_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PhaseActionItem_OneArgs = {
  object: PhaseActionItem_Insert_Input;
  on_conflict?: InputMaybe<PhaseActionItem_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PhaseDealStatusArgs = {
  objects: Array<PhaseDealStatus_Insert_Input>;
  on_conflict?: InputMaybe<PhaseDealStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PhaseDealStatus_OneArgs = {
  object: PhaseDealStatus_Insert_Input;
  on_conflict?: InputMaybe<PhaseDealStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PhasesArgs = {
  objects: Array<Phases_Insert_Input>;
  on_conflict?: InputMaybe<Phases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Phases_OneArgs = {
  object: Phases_Insert_Input;
  on_conflict?: InputMaybe<Phases_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertiesInformationArgs = {
  objects: Array<PropertiesInformation_Insert_Input>;
  on_conflict?: InputMaybe<PropertiesInformation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertiesInformation_OneArgs = {
  object: PropertiesInformation_Insert_Input;
  on_conflict?: InputMaybe<PropertiesInformation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyContactsArgs = {
  objects: Array<PropertyContacts_Insert_Input>;
  on_conflict?: InputMaybe<PropertyContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyContacts_OneArgs = {
  object: PropertyContacts_Insert_Input;
  on_conflict?: InputMaybe<PropertyContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyFileTypesArgs = {
  objects: Array<PropertyFileTypes_Insert_Input>;
  on_conflict?: InputMaybe<PropertyFileTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyFileTypes_OneArgs = {
  object: PropertyFileTypes_Insert_Input;
  on_conflict?: InputMaybe<PropertyFileTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyFilesArgs = {
  objects: Array<PropertyFiles_Insert_Input>;
  on_conflict?: InputMaybe<PropertyFiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PropertyFiles_OneArgs = {
  object: PropertyFiles_Insert_Input;
  on_conflict?: InputMaybe<PropertyFiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProposalsArgs = {
  objects: Array<Proposals_Insert_Input>;
  on_conflict?: InputMaybe<Proposals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Proposals_OneArgs = {
  object: Proposals_Insert_Input;
  on_conflict?: InputMaybe<Proposals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RegistrationsArgs = {
  objects: Array<Registrations_Insert_Input>;
  on_conflict?: InputMaybe<Registrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Registrations_OneArgs = {
  object: Registrations_Insert_Input;
  on_conflict?: InputMaybe<Registrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesTypeArgs = {
  objects: Array<SalesType_Insert_Input>;
  on_conflict?: InputMaybe<SalesType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesType_OneArgs = {
  object: SalesType_Insert_Input;
  on_conflict?: InputMaybe<SalesType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StatesArgs = {
  objects: Array<States_Insert_Input>;
  on_conflict?: InputMaybe<States_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_States_OneArgs = {
  object: States_Insert_Input;
  on_conflict?: InputMaybe<States_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TemplatePermittedUsesArgs = {
  objects: Array<TemplatePermittedUses_Insert_Input>;
  on_conflict?: InputMaybe<TemplatePermittedUses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TemplatePermittedUses_OneArgs = {
  object: TemplatePermittedUses_Insert_Input;
  on_conflict?: InputMaybe<TemplatePermittedUses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TemplateSpecialConditionsArgs = {
  objects: Array<TemplateSpecialConditions_Insert_Input>;
  on_conflict?: InputMaybe<TemplateSpecialConditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TemplateSpecialConditions_OneArgs = {
  object: TemplateSpecialConditions_Insert_Input;
  on_conflict?: InputMaybe<TemplateSpecialConditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TenantTypesArgs = {
  objects: Array<TenantTypes_Insert_Input>;
  on_conflict?: InputMaybe<TenantTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TenantTypes_OneArgs = {
  object: TenantTypes_Insert_Input;
  on_conflict?: InputMaybe<TenantTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TermsArgs = {
  objects: Array<Terms_Insert_Input>;
  on_conflict?: InputMaybe<Terms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terms_OneArgs = {
  object: Terms_Insert_Input;
  on_conflict?: InputMaybe<Terms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TriSelectEnumArgs = {
  objects: Array<TriSelectEnum_Insert_Input>;
  on_conflict?: InputMaybe<TriSelectEnum_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TriSelectEnum_OneArgs = {
  object: TriSelectEnum_Insert_Input;
  on_conflict?: InputMaybe<TriSelectEnum_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UnitTypesArgs = {
  objects: Array<UnitTypes_Insert_Input>;
  on_conflict?: InputMaybe<UnitTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UnitTypes_OneArgs = {
  object: UnitTypes_Insert_Input;
  on_conflict?: InputMaybe<UnitTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UnitsArgs = {
  objects: Array<Units_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Units_OneArgs = {
  object: Units_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_UserStatusArgs = {
  objects: Array<UserStatus_Insert_Input>;
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserStatus_OneArgs = {
  object: UserStatus_Insert_Input;
  on_conflict?: InputMaybe<UserStatus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VirtualUnitsArgs = {
  objects: Array<VirtualUnits_Insert_Input>;
  on_conflict?: InputMaybe<VirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VirtualUnits_OneArgs = {
  object: VirtualUnits_Insert_Input;
  on_conflict?: InputMaybe<VirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiAmendmentsArgs = {
  objects: Array<YardiAmendments_Insert_Input>;
  on_conflict?: InputMaybe<YardiAmendments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiAmendments_DealsArgs = {
  objects: Array<YardiAmendments_Deals_Insert_Input>;
  on_conflict?: InputMaybe<YardiAmendments_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiAmendments_Deals_OneArgs = {
  object: YardiAmendments_Deals_Insert_Input;
  on_conflict?: InputMaybe<YardiAmendments_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiAmendments_OneArgs = {
  object: YardiAmendments_Insert_Input;
  on_conflict?: InputMaybe<YardiAmendments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContactsArgs = {
  objects: Array<YardiContacts_Insert_Input>;
  on_conflict?: InputMaybe<YardiContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_ContactsArgs = {
  objects: Array<YardiContacts_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<YardiContacts_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_Contacts_OneArgs = {
  object: YardiContacts_Contacts_Insert_Input;
  on_conflict?: InputMaybe<YardiContacts_Contacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_DealOwnersArgs = {
  objects: Array<YardiContacts_DealOwners_Insert_Input>;
  on_conflict?: InputMaybe<YardiContacts_DealOwners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_DealOwners_OneArgs = {
  object: YardiContacts_DealOwners_Insert_Input;
  on_conflict?: InputMaybe<YardiContacts_DealOwners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_YardiPropertiesArgs = {
  objects: Array<YardiContacts_YardiProperties_Insert_Input>;
  on_conflict?: InputMaybe<YardiContacts_YardiProperties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_YardiProperties_OneArgs = {
  object: YardiContacts_YardiProperties_Insert_Input;
  on_conflict?: InputMaybe<YardiContacts_YardiProperties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiContacts_OneArgs = {
  object: YardiContacts_Insert_Input;
  on_conflict?: InputMaybe<YardiContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiCustomersArgs = {
  objects: Array<YardiCustomers_Insert_Input>;
  on_conflict?: InputMaybe<YardiCustomers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiCustomers_DealsArgs = {
  objects: Array<YardiCustomers_Deals_Insert_Input>;
  on_conflict?: InputMaybe<YardiCustomers_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiCustomers_Deals_OneArgs = {
  object: YardiCustomers_Deals_Insert_Input;
  on_conflict?: InputMaybe<YardiCustomers_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiCustomers_OneArgs = {
  object: YardiCustomers_Insert_Input;
  on_conflict?: InputMaybe<YardiCustomers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiDealSyncStatusesArgs = {
  objects: Array<YardiDealSyncStatuses_Insert_Input>;
  on_conflict?: InputMaybe<YardiDealSyncStatuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiDealSyncStatuses_OneArgs = {
  object: YardiDealSyncStatuses_Insert_Input;
  on_conflict?: InputMaybe<YardiDealSyncStatuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiIngestionInfosArgs = {
  objects: Array<YardiIngestionInfos_Insert_Input>;
  on_conflict?: InputMaybe<YardiIngestionInfos_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiIngestionInfos_OneArgs = {
  object: YardiIngestionInfos_Insert_Input;
  on_conflict?: InputMaybe<YardiIngestionInfos_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiPropertiesArgs = {
  objects: Array<YardiProperties_Insert_Input>;
  on_conflict?: InputMaybe<YardiProperties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiProperties_OneArgs = {
  object: YardiProperties_Insert_Input;
  on_conflict?: InputMaybe<YardiProperties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiPropertyProfilesArgs = {
  objects: Array<YardiPropertyProfiles_Insert_Input>;
  on_conflict?: InputMaybe<YardiPropertyProfiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiPropertyProfiles_OneArgs = {
  object: YardiPropertyProfiles_Insert_Input;
  on_conflict?: InputMaybe<YardiPropertyProfiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenantToUnitsArgs = {
  objects: Array<YardiTenantToUnits_Insert_Input>;
  on_conflict?: InputMaybe<YardiTenantToUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenantToUnits_OneArgs = {
  object: YardiTenantToUnits_Insert_Input;
  on_conflict?: InputMaybe<YardiTenantToUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenant_YardiContactsArgs = {
  objects: Array<YardiTenant_YardiContacts_Insert_Input>;
  on_conflict?: InputMaybe<YardiTenant_YardiContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenant_YardiContacts_OneArgs = {
  object: YardiTenant_YardiContacts_Insert_Input;
  on_conflict?: InputMaybe<YardiTenant_YardiContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenantsArgs = {
  objects: Array<YardiTenants_Insert_Input>;
  on_conflict?: InputMaybe<YardiTenants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenants_DealsArgs = {
  objects: Array<YardiTenants_Deals_Insert_Input>;
  on_conflict?: InputMaybe<YardiTenants_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenants_Deals_OneArgs = {
  object: YardiTenants_Deals_Insert_Input;
  on_conflict?: InputMaybe<YardiTenants_Deals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiTenants_OneArgs = {
  object: YardiTenants_Insert_Input;
  on_conflict?: InputMaybe<YardiTenants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiUnitProfilesArgs = {
  objects: Array<YardiUnitProfiles_Insert_Input>;
  on_conflict?: InputMaybe<YardiUnitProfiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiUnitProfiles_OneArgs = {
  object: YardiUnitProfiles_Insert_Input;
  on_conflict?: InputMaybe<YardiUnitProfiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiUnitsArgs = {
  objects: Array<YardiUnits_Insert_Input>;
  on_conflict?: InputMaybe<YardiUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiUnits_OneArgs = {
  object: YardiUnits_Insert_Input;
  on_conflict?: InputMaybe<YardiUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiVirtualUnitsArgs = {
  objects: Array<YardiVirtualUnits_Insert_Input>;
  on_conflict?: InputMaybe<YardiVirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiVirtualUnits_VirtualUnitsArgs = {
  objects: Array<YardiVirtualUnits_VirtualUnits_Insert_Input>;
  on_conflict?: InputMaybe<YardiVirtualUnits_VirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiVirtualUnits_VirtualUnits_OneArgs = {
  object: YardiVirtualUnits_VirtualUnits_Insert_Input;
  on_conflict?: InputMaybe<YardiVirtualUnits_VirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_YardiVirtualUnits_OneArgs = {
  object: YardiVirtualUnits_Insert_Input;
  on_conflict?: InputMaybe<YardiVirtualUnits_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_ActionAllocationsArgs = {
  _inc?: InputMaybe<ActionAllocations_Inc_Input>;
  _set?: InputMaybe<ActionAllocations_Set_Input>;
  where: ActionAllocations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ActionAllocations_By_PkArgs = {
  _inc?: InputMaybe<ActionAllocations_Inc_Input>;
  _set?: InputMaybe<ActionAllocations_Set_Input>;
  pk_columns: ActionAllocations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ActionAllocations_ManyArgs = {
  updates: Array<ActionAllocations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ActionItemsArgs = {
  _inc?: InputMaybe<ActionItems_Inc_Input>;
  _set?: InputMaybe<ActionItems_Set_Input>;
  where: ActionItems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ActionItems_By_PkArgs = {
  _inc?: InputMaybe<ActionItems_Inc_Input>;
  _set?: InputMaybe<ActionItems_Set_Input>;
  pk_columns: ActionItems_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ActionItems_ManyArgs = {
  updates: Array<ActionItems_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLogArgs = {
  _inc?: InputMaybe<ActivityLog_Inc_Input>;
  _set?: InputMaybe<ActivityLog_Set_Input>;
  where: ActivityLog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLogTypeArgs = {
  _set?: InputMaybe<ActivityLogType_Set_Input>;
  where: ActivityLogType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLogType_By_PkArgs = {
  _set?: InputMaybe<ActivityLogType_Set_Input>;
  pk_columns: ActivityLogType_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLogType_ManyArgs = {
  updates: Array<ActivityLogType_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLog_By_PkArgs = {
  _inc?: InputMaybe<ActivityLog_Inc_Input>;
  _set?: InputMaybe<ActivityLog_Set_Input>;
  pk_columns: ActivityLog_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ActivityLog_ManyArgs = {
  updates: Array<ActivityLog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AgreementsArgs = {
  _inc?: InputMaybe<Agreements_Inc_Input>;
  _set?: InputMaybe<Agreements_Set_Input>;
  where: Agreements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Agreements_By_PkArgs = {
  _inc?: InputMaybe<Agreements_Inc_Input>;
  _set?: InputMaybe<Agreements_Set_Input>;
  pk_columns: Agreements_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Agreements_ManyArgs = {
  updates: Array<Agreements_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ApproversArgs = {
  _inc?: InputMaybe<Approvers_Inc_Input>;
  _set?: InputMaybe<Approvers_Set_Input>;
  where: Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Approvers_By_PkArgs = {
  _inc?: InputMaybe<Approvers_Inc_Input>;
  _set?: InputMaybe<Approvers_Set_Input>;
  pk_columns: Approvers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Approvers_ManyArgs = {
  updates: Array<Approvers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_BaseLandlordServicesArgs = {
  _inc?: InputMaybe<BaseLandlordServices_Inc_Input>;
  _set?: InputMaybe<BaseLandlordServices_Set_Input>;
  where: BaseLandlordServices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_BaseLandlordServices_By_PkArgs = {
  _inc?: InputMaybe<BaseLandlordServices_Inc_Input>;
  _set?: InputMaybe<BaseLandlordServices_Set_Input>;
  pk_columns: BaseLandlordServices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_BaseLandlordServices_ManyArgs = {
  updates: Array<BaseLandlordServices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CenterApproversArgs = {
  _inc?: InputMaybe<CenterApprovers_Inc_Input>;
  _set?: InputMaybe<CenterApprovers_Set_Input>;
  where: CenterApprovers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CenterApprovers_By_PkArgs = {
  _inc?: InputMaybe<CenterApprovers_Inc_Input>;
  _set?: InputMaybe<CenterApprovers_Set_Input>;
  pk_columns: CenterApprovers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CenterApprovers_ManyArgs = {
  updates: Array<CenterApprovers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CommentTagsArgs = {
  _inc?: InputMaybe<CommentTags_Inc_Input>;
  _set?: InputMaybe<CommentTags_Set_Input>;
  where: CommentTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CommentTags_By_PkArgs = {
  _inc?: InputMaybe<CommentTags_Inc_Input>;
  _set?: InputMaybe<CommentTags_Set_Input>;
  pk_columns: CommentTags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CommentTags_ManyArgs = {
  updates: Array<CommentTags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CommentUserTagsArgs = {
  _inc?: InputMaybe<CommentUserTags_Inc_Input>;
  _set?: InputMaybe<CommentUserTags_Set_Input>;
  where: CommentUserTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CommentUserTags_By_PkArgs = {
  _inc?: InputMaybe<CommentUserTags_Inc_Input>;
  _set?: InputMaybe<CommentUserTags_Set_Input>;
  pk_columns: CommentUserTags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CommentUserTags_ManyArgs = {
  updates: Array<CommentUserTags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _inc?: InputMaybe<Comments_Inc_Input>;
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _inc?: InputMaybe<Comments_Inc_Input>;
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: Array<Comments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _inc?: InputMaybe<Companies_Inc_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _inc?: InputMaybe<Companies_Inc_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Companies_ManyArgs = {
  updates: Array<Companies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactTypesArgs = {
  _inc?: InputMaybe<ContactTypes_Inc_Input>;
  _set?: InputMaybe<ContactTypes_Set_Input>;
  where: ContactTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactTypes_By_PkArgs = {
  _inc?: InputMaybe<ContactTypes_Inc_Input>;
  _set?: InputMaybe<ContactTypes_Set_Input>;
  pk_columns: ContactTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactTypes_ManyArgs = {
  updates: Array<ContactTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _inc?: InputMaybe<Contacts_Inc_Input>;
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Countries_ManyArgs = {
  updates: Array<Countries_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CustomLicencesArgs = {
  _inc?: InputMaybe<CustomLicences_Inc_Input>;
  _set?: InputMaybe<CustomLicences_Set_Input>;
  where: CustomLicences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CustomLicences_By_PkArgs = {
  _inc?: InputMaybe<CustomLicences_Inc_Input>;
  _set?: InputMaybe<CustomLicences_Set_Input>;
  pk_columns: CustomLicences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CustomLicences_ManyArgs = {
  updates: Array<CustomLicences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealActionItemArgs = {
  _inc?: InputMaybe<DealActionItem_Inc_Input>;
  _set?: InputMaybe<DealActionItem_Set_Input>;
  where: DealActionItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealActionItem_By_PkArgs = {
  _inc?: InputMaybe<DealActionItem_Inc_Input>;
  _set?: InputMaybe<DealActionItem_Set_Input>;
  pk_columns: DealActionItem_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealActionItem_ManyArgs = {
  updates: Array<DealActionItem_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealBusinessContactsArgs = {
  _inc?: InputMaybe<DealBusinessContacts_Inc_Input>;
  _set?: InputMaybe<DealBusinessContacts_Set_Input>;
  where: DealBusinessContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealBusinessContacts_By_PkArgs = {
  _inc?: InputMaybe<DealBusinessContacts_Inc_Input>;
  _set?: InputMaybe<DealBusinessContacts_Set_Input>;
  pk_columns: DealBusinessContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealBusinessContacts_ManyArgs = {
  updates: Array<DealBusinessContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealContactsTagsArgs = {
  _inc?: InputMaybe<DealContactsTags_Inc_Input>;
  _set?: InputMaybe<DealContactsTags_Set_Input>;
  where: DealContactsTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealContactsTags_By_PkArgs = {
  _inc?: InputMaybe<DealContactsTags_Inc_Input>;
  _set?: InputMaybe<DealContactsTags_Set_Input>;
  pk_columns: DealContactsTags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealContactsTags_ManyArgs = {
  updates: Array<DealContactsTags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataArgs = {
  _inc?: InputMaybe<DealData_Inc_Input>;
  _set?: InputMaybe<DealData_Set_Input>;
  where: DealData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataCustomLicencesArgs = {
  _inc?: InputMaybe<DealDataCustomLicences_Inc_Input>;
  _set?: InputMaybe<DealDataCustomLicences_Set_Input>;
  where: DealDataCustomLicences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataCustomLicences_By_PkArgs = {
  _inc?: InputMaybe<DealDataCustomLicences_Inc_Input>;
  _set?: InputMaybe<DealDataCustomLicences_Set_Input>;
  pk_columns: DealDataCustomLicences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataCustomLicences_ManyArgs = {
  updates: Array<DealDataCustomLicences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataGuarantorsArgs = {
  _inc?: InputMaybe<DealDataGuarantors_Inc_Input>;
  _set?: InputMaybe<DealDataGuarantors_Set_Input>;
  where: DealDataGuarantors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataGuarantors_By_PkArgs = {
  _inc?: InputMaybe<DealDataGuarantors_Inc_Input>;
  _set?: InputMaybe<DealDataGuarantors_Set_Input>;
  pk_columns: DealDataGuarantors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealDataGuarantors_ManyArgs = {
  updates: Array<DealDataGuarantors_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealData_By_PkArgs = {
  _inc?: InputMaybe<DealData_Inc_Input>;
  _set?: InputMaybe<DealData_Set_Input>;
  pk_columns: DealData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealData_ManyArgs = {
  updates: Array<DealData_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealExtensionItemsArgs = {
  _inc?: InputMaybe<DealExtensionItems_Inc_Input>;
  _set?: InputMaybe<DealExtensionItems_Set_Input>;
  where: DealExtensionItems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealExtensionItems_By_PkArgs = {
  _inc?: InputMaybe<DealExtensionItems_Inc_Input>;
  _set?: InputMaybe<DealExtensionItems_Set_Input>;
  pk_columns: DealExtensionItems_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealExtensionItems_ManyArgs = {
  updates: Array<DealExtensionItems_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileChunksArgs = {
  _inc?: InputMaybe<DealFileChunks_Inc_Input>;
  _set?: InputMaybe<DealFileChunks_Set_Input>;
  where: DealFileChunks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileChunks_By_PkArgs = {
  _inc?: InputMaybe<DealFileChunks_Inc_Input>;
  _set?: InputMaybe<DealFileChunks_Set_Input>;
  pk_columns: DealFileChunks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileChunks_ManyArgs = {
  updates: Array<DealFileChunks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileTagsArgs = {
  _inc?: InputMaybe<DealFileTags_Inc_Input>;
  _set?: InputMaybe<DealFileTags_Set_Input>;
  where: DealFileTags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileTags_By_PkArgs = {
  _inc?: InputMaybe<DealFileTags_Inc_Input>;
  _set?: InputMaybe<DealFileTags_Set_Input>;
  pk_columns: DealFileTags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealFileTags_ManyArgs = {
  updates: Array<DealFileTags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealFilesArgs = {
  _inc?: InputMaybe<DealFiles_Inc_Input>;
  _set?: InputMaybe<DealFiles_Set_Input>;
  where: DealFiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealFiles_By_PkArgs = {
  _inc?: InputMaybe<DealFiles_Inc_Input>;
  _set?: InputMaybe<DealFiles_Set_Input>;
  pk_columns: DealFiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealFiles_ManyArgs = {
  updates: Array<DealFiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealFitoutLandlordWorksArgs = {
  _inc?: InputMaybe<DealFitoutLandlordWorks_Inc_Input>;
  _set?: InputMaybe<DealFitoutLandlordWorks_Set_Input>;
  where: DealFitoutLandlordWorks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealFitoutLandlordWorks_By_PkArgs = {
  _inc?: InputMaybe<DealFitoutLandlordWorks_Inc_Input>;
  _set?: InputMaybe<DealFitoutLandlordWorks_Set_Input>;
  pk_columns: DealFitoutLandlordWorks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealFitoutLandlordWorks_ManyArgs = {
  updates: Array<DealFitoutLandlordWorks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealLafApprovalsArgs = {
  _inc?: InputMaybe<DealLafApprovals_Inc_Input>;
  _set?: InputMaybe<DealLafApprovals_Set_Input>;
  where: DealLafApprovals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealLafApprovals_By_PkArgs = {
  _inc?: InputMaybe<DealLafApprovals_Inc_Input>;
  _set?: InputMaybe<DealLafApprovals_Set_Input>;
  pk_columns: DealLafApprovals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealLafApprovals_ManyArgs = {
  updates: Array<DealLafApprovals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealLinksArgs = {
  _inc?: InputMaybe<DealLinks_Inc_Input>;
  _set?: InputMaybe<DealLinks_Set_Input>;
  where: DealLinks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealLinks_By_PkArgs = {
  _inc?: InputMaybe<DealLinks_Inc_Input>;
  _set?: InputMaybe<DealLinks_Set_Input>;
  pk_columns: DealLinks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealLinks_ManyArgs = {
  updates: Array<DealLinks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealOptionsArgs = {
  _inc?: InputMaybe<DealOptions_Inc_Input>;
  _set?: InputMaybe<DealOptions_Set_Input>;
  where: DealOptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealOptions_By_PkArgs = {
  _inc?: InputMaybe<DealOptions_Inc_Input>;
  _set?: InputMaybe<DealOptions_Set_Input>;
  pk_columns: DealOptions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealOptions_ManyArgs = {
  updates: Array<DealOptions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealPermittedUsesArgs = {
  _inc?: InputMaybe<DealPermittedUses_Inc_Input>;
  _set?: InputMaybe<DealPermittedUses_Set_Input>;
  where: DealPermittedUses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealPermittedUses_By_PkArgs = {
  _inc?: InputMaybe<DealPermittedUses_Inc_Input>;
  _set?: InputMaybe<DealPermittedUses_Set_Input>;
  pk_columns: DealPermittedUses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealPermittedUses_ManyArgs = {
  updates: Array<DealPermittedUses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealPhasesArgs = {
  _inc?: InputMaybe<DealPhases_Inc_Input>;
  _set?: InputMaybe<DealPhases_Set_Input>;
  where: DealPhases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealPhases_By_PkArgs = {
  _inc?: InputMaybe<DealPhases_Inc_Input>;
  _set?: InputMaybe<DealPhases_Set_Input>;
  pk_columns: DealPhases_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealPhases_ManyArgs = {
  updates: Array<DealPhases_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealRiskLevelsArgs = {
  _set?: InputMaybe<DealRiskLevels_Set_Input>;
  where: DealRiskLevels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealRiskLevels_By_PkArgs = {
  _set?: InputMaybe<DealRiskLevels_Set_Input>;
  pk_columns: DealRiskLevels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealRiskLevels_ManyArgs = {
  updates: Array<DealRiskLevels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivitiesArgs = {
  _inc?: InputMaybe<DealShopActivities_Inc_Input>;
  _set?: InputMaybe<DealShopActivities_Set_Input>;
  where: DealShopActivities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivities_By_PkArgs = {
  _inc?: InputMaybe<DealShopActivities_Inc_Input>;
  _set?: InputMaybe<DealShopActivities_Set_Input>;
  pk_columns: DealShopActivities_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivities_ManyArgs = {
  updates: Array<DealShopActivities_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivityTypesArgs = {
  _set?: InputMaybe<DealShopActivityTypes_Set_Input>;
  where: DealShopActivityTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivityTypes_By_PkArgs = {
  _set?: InputMaybe<DealShopActivityTypes_Set_Input>;
  pk_columns: DealShopActivityTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealShopActivityTypes_ManyArgs = {
  updates: Array<DealShopActivityTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealSpecialConditionsArgs = {
  _inc?: InputMaybe<DealSpecialConditions_Inc_Input>;
  _set?: InputMaybe<DealSpecialConditions_Set_Input>;
  where: DealSpecialConditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealSpecialConditions_By_PkArgs = {
  _inc?: InputMaybe<DealSpecialConditions_Inc_Input>;
  _set?: InputMaybe<DealSpecialConditions_Set_Input>;
  pk_columns: DealSpecialConditions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealSpecialConditions_ManyArgs = {
  updates: Array<DealSpecialConditions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealStatusArgs = {
  _set?: InputMaybe<DealStatus_Set_Input>;
  where: DealStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealStatus_By_PkArgs = {
  _set?: InputMaybe<DealStatus_Set_Input>;
  pk_columns: DealStatus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealStatus_ManyArgs = {
  updates: Array<DealStatus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealTypesArgs = {
  _inc?: InputMaybe<DealTypes_Inc_Input>;
  _set?: InputMaybe<DealTypes_Set_Input>;
  where: DealTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealTypes_By_PkArgs = {
  _inc?: InputMaybe<DealTypes_Inc_Input>;
  _set?: InputMaybe<DealTypes_Set_Input>;
  pk_columns: DealTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealTypes_ManyArgs = {
  updates: Array<DealTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealsArgs = {
  _inc?: InputMaybe<Deals_Inc_Input>;
  _set?: InputMaybe<Deals_Set_Input>;
  where: Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealsCompaniesArgs = {
  _inc?: InputMaybe<DealsCompanies_Inc_Input>;
  _set?: InputMaybe<DealsCompanies_Set_Input>;
  where: DealsCompanies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealsCompanies_By_PkArgs = {
  _inc?: InputMaybe<DealsCompanies_Inc_Input>;
  _set?: InputMaybe<DealsCompanies_Set_Input>;
  pk_columns: DealsCompanies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealsCompanies_ManyArgs = {
  updates: Array<DealsCompanies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealsContactsArgs = {
  _inc?: InputMaybe<DealsContacts_Inc_Input>;
  _set?: InputMaybe<DealsContacts_Set_Input>;
  where: DealsContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealsContacts_By_PkArgs = {
  _inc?: InputMaybe<DealsContacts_Inc_Input>;
  _set?: InputMaybe<DealsContacts_Set_Input>;
  pk_columns: DealsContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealsContacts_ManyArgs = {
  updates: Array<DealsContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DealsUnitsArgs = {
  _inc?: InputMaybe<DealsUnits_Inc_Input>;
  _set?: InputMaybe<DealsUnits_Set_Input>;
  where: DealsUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DealsUnits_By_PkArgs = {
  _inc?: InputMaybe<DealsUnits_Inc_Input>;
  _set?: InputMaybe<DealsUnits_Set_Input>;
  pk_columns: DealsUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DealsUnits_ManyArgs = {
  updates: Array<DealsUnits_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Deals_By_PkArgs = {
  _inc?: InputMaybe<Deals_Inc_Input>;
  _set?: InputMaybe<Deals_Set_Input>;
  pk_columns: Deals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Deals_ManyArgs = {
  updates: Array<Deals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentRequestsArgs = {
  _inc?: InputMaybe<DocumentRequests_Inc_Input>;
  _set?: InputMaybe<DocumentRequests_Set_Input>;
  where: DocumentRequests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentRequests_By_PkArgs = {
  _inc?: InputMaybe<DocumentRequests_Inc_Input>;
  _set?: InputMaybe<DocumentRequests_Set_Input>;
  pk_columns: DocumentRequests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentRequests_ManyArgs = {
  updates: Array<DocumentRequests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentTemplatesArgs = {
  _inc?: InputMaybe<DocumentTemplates_Inc_Input>;
  _set?: InputMaybe<DocumentTemplates_Set_Input>;
  where: DocumentTemplates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentTemplates_By_PkArgs = {
  _inc?: InputMaybe<DocumentTemplates_Inc_Input>;
  _set?: InputMaybe<DocumentTemplates_Set_Input>;
  pk_columns: DocumentTemplates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentTemplates_ManyArgs = {
  updates: Array<DocumentTemplates_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_EscalatedApproversArgs = {
  _inc?: InputMaybe<EscalatedApprovers_Inc_Input>;
  _set?: InputMaybe<EscalatedApprovers_Set_Input>;
  where: EscalatedApprovers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_EscalatedApprovers_By_PkArgs = {
  _inc?: InputMaybe<EscalatedApprovers_Inc_Input>;
  _set?: InputMaybe<EscalatedApprovers_Set_Input>;
  pk_columns: EscalatedApprovers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EscalatedApprovers_ManyArgs = {
  updates: Array<EscalatedApprovers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GeneratedDocumentsArgs = {
  _inc?: InputMaybe<GeneratedDocuments_Inc_Input>;
  _set?: InputMaybe<GeneratedDocuments_Set_Input>;
  where: GeneratedDocuments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GeneratedDocuments_By_PkArgs = {
  _inc?: InputMaybe<GeneratedDocuments_Inc_Input>;
  _set?: InputMaybe<GeneratedDocuments_Set_Input>;
  pk_columns: GeneratedDocuments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GeneratedDocuments_ManyArgs = {
  updates: Array<GeneratedDocuments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LafActionChoicesArgs = {
  _set?: InputMaybe<LafActionChoices_Set_Input>;
  where: LafActionChoices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_LafActionChoices_By_PkArgs = {
  _set?: InputMaybe<LafActionChoices_Set_Input>;
  pk_columns: LafActionChoices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LafActionChoices_ManyArgs = {
  updates: Array<LafActionChoices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LeasesArgs = {
  _inc?: InputMaybe<Leases_Inc_Input>;
  _set?: InputMaybe<Leases_Set_Input>;
  where: Leases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leases_By_PkArgs = {
  _inc?: InputMaybe<Leases_Inc_Input>;
  _set?: InputMaybe<Leases_Set_Input>;
  pk_columns: Leases_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leases_ManyArgs = {
  updates: Array<Leases_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OffersArgs = {
  _inc?: InputMaybe<Offers_Inc_Input>;
  _set?: InputMaybe<Offers_Set_Input>;
  where: Offers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Offers_By_PkArgs = {
  _inc?: InputMaybe<Offers_Inc_Input>;
  _set?: InputMaybe<Offers_Set_Input>;
  pk_columns: Offers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Offers_ManyArgs = {
  updates: Array<Offers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseActionItemArgs = {
  _inc?: InputMaybe<PhaseActionItem_Inc_Input>;
  _set?: InputMaybe<PhaseActionItem_Set_Input>;
  where: PhaseActionItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseActionItem_By_PkArgs = {
  _inc?: InputMaybe<PhaseActionItem_Inc_Input>;
  _set?: InputMaybe<PhaseActionItem_Set_Input>;
  pk_columns: PhaseActionItem_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseActionItem_ManyArgs = {
  updates: Array<PhaseActionItem_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseDealStatusArgs = {
  _inc?: InputMaybe<PhaseDealStatus_Inc_Input>;
  _set?: InputMaybe<PhaseDealStatus_Set_Input>;
  where: PhaseDealStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseDealStatus_By_PkArgs = {
  _inc?: InputMaybe<PhaseDealStatus_Inc_Input>;
  _set?: InputMaybe<PhaseDealStatus_Set_Input>;
  pk_columns: PhaseDealStatus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PhaseDealStatus_ManyArgs = {
  updates: Array<PhaseDealStatus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PhasesArgs = {
  _inc?: InputMaybe<Phases_Inc_Input>;
  _set?: InputMaybe<Phases_Set_Input>;
  where: Phases_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Phases_By_PkArgs = {
  _inc?: InputMaybe<Phases_Inc_Input>;
  _set?: InputMaybe<Phases_Set_Input>;
  pk_columns: Phases_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Phases_ManyArgs = {
  updates: Array<Phases_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PropertiesInformationArgs = {
  _inc?: InputMaybe<PropertiesInformation_Inc_Input>;
  _set?: InputMaybe<PropertiesInformation_Set_Input>;
  where: PropertiesInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PropertiesInformation_By_PkArgs = {
  _inc?: InputMaybe<PropertiesInformation_Inc_Input>;
  _set?: InputMaybe<PropertiesInformation_Set_Input>;
  pk_columns: PropertiesInformation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PropertiesInformation_ManyArgs = {
  updates: Array<PropertiesInformation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyContactsArgs = {
  _inc?: InputMaybe<PropertyContacts_Inc_Input>;
  _set?: InputMaybe<PropertyContacts_Set_Input>;
  where: PropertyContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyContacts_By_PkArgs = {
  _inc?: InputMaybe<PropertyContacts_Inc_Input>;
  _set?: InputMaybe<PropertyContacts_Set_Input>;
  pk_columns: PropertyContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyContacts_ManyArgs = {
  updates: Array<PropertyContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFileTypesArgs = {
  _set?: InputMaybe<PropertyFileTypes_Set_Input>;
  where: PropertyFileTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFileTypes_By_PkArgs = {
  _set?: InputMaybe<PropertyFileTypes_Set_Input>;
  pk_columns: PropertyFileTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFileTypes_ManyArgs = {
  updates: Array<PropertyFileTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFilesArgs = {
  _inc?: InputMaybe<PropertyFiles_Inc_Input>;
  _set?: InputMaybe<PropertyFiles_Set_Input>;
  where: PropertyFiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFiles_By_PkArgs = {
  _inc?: InputMaybe<PropertyFiles_Inc_Input>;
  _set?: InputMaybe<PropertyFiles_Set_Input>;
  pk_columns: PropertyFiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PropertyFiles_ManyArgs = {
  updates: Array<PropertyFiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProposalsArgs = {
  _inc?: InputMaybe<Proposals_Inc_Input>;
  _set?: InputMaybe<Proposals_Set_Input>;
  where: Proposals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Proposals_By_PkArgs = {
  _inc?: InputMaybe<Proposals_Inc_Input>;
  _set?: InputMaybe<Proposals_Set_Input>;
  pk_columns: Proposals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Proposals_ManyArgs = {
  updates: Array<Proposals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RegistrationsArgs = {
  _inc?: InputMaybe<Registrations_Inc_Input>;
  _set?: InputMaybe<Registrations_Set_Input>;
  where: Registrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Registrations_By_PkArgs = {
  _inc?: InputMaybe<Registrations_Inc_Input>;
  _set?: InputMaybe<Registrations_Set_Input>;
  pk_columns: Registrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Registrations_ManyArgs = {
  updates: Array<Registrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SalesTypeArgs = {
  _set?: InputMaybe<SalesType_Set_Input>;
  where: SalesType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesType_By_PkArgs = {
  _set?: InputMaybe<SalesType_Set_Input>;
  pk_columns: SalesType_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesType_ManyArgs = {
  updates: Array<SalesType_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StatesArgs = {
  _inc?: InputMaybe<States_Inc_Input>;
  _set?: InputMaybe<States_Set_Input>;
  where: States_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_States_By_PkArgs = {
  _inc?: InputMaybe<States_Inc_Input>;
  _set?: InputMaybe<States_Set_Input>;
  pk_columns: States_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_States_ManyArgs = {
  updates: Array<States_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TemplatePermittedUsesArgs = {
  _inc?: InputMaybe<TemplatePermittedUses_Inc_Input>;
  _set?: InputMaybe<TemplatePermittedUses_Set_Input>;
  where: TemplatePermittedUses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TemplatePermittedUses_By_PkArgs = {
  _inc?: InputMaybe<TemplatePermittedUses_Inc_Input>;
  _set?: InputMaybe<TemplatePermittedUses_Set_Input>;
  pk_columns: TemplatePermittedUses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TemplatePermittedUses_ManyArgs = {
  updates: Array<TemplatePermittedUses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TemplateSpecialConditionsArgs = {
  _inc?: InputMaybe<TemplateSpecialConditions_Inc_Input>;
  _set?: InputMaybe<TemplateSpecialConditions_Set_Input>;
  where: TemplateSpecialConditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TemplateSpecialConditions_By_PkArgs = {
  _inc?: InputMaybe<TemplateSpecialConditions_Inc_Input>;
  _set?: InputMaybe<TemplateSpecialConditions_Set_Input>;
  pk_columns: TemplateSpecialConditions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TemplateSpecialConditions_ManyArgs = {
  updates: Array<TemplateSpecialConditions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TenantTypesArgs = {
  _inc?: InputMaybe<TenantTypes_Inc_Input>;
  _set?: InputMaybe<TenantTypes_Set_Input>;
  where: TenantTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TenantTypes_By_PkArgs = {
  _inc?: InputMaybe<TenantTypes_Inc_Input>;
  _set?: InputMaybe<TenantTypes_Set_Input>;
  pk_columns: TenantTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TenantTypes_ManyArgs = {
  updates: Array<TenantTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TermsArgs = {
  _inc?: InputMaybe<Terms_Inc_Input>;
  _set?: InputMaybe<Terms_Set_Input>;
  where: Terms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Terms_By_PkArgs = {
  _inc?: InputMaybe<Terms_Inc_Input>;
  _set?: InputMaybe<Terms_Set_Input>;
  pk_columns: Terms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Terms_ManyArgs = {
  updates: Array<Terms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TriSelectEnumArgs = {
  _inc?: InputMaybe<TriSelectEnum_Inc_Input>;
  _set?: InputMaybe<TriSelectEnum_Set_Input>;
  where: TriSelectEnum_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TriSelectEnum_By_PkArgs = {
  _inc?: InputMaybe<TriSelectEnum_Inc_Input>;
  _set?: InputMaybe<TriSelectEnum_Set_Input>;
  pk_columns: TriSelectEnum_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TriSelectEnum_ManyArgs = {
  updates: Array<TriSelectEnum_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UnitTypesArgs = {
  _inc?: InputMaybe<UnitTypes_Inc_Input>;
  _set?: InputMaybe<UnitTypes_Set_Input>;
  where: UnitTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UnitTypes_By_PkArgs = {
  _inc?: InputMaybe<UnitTypes_Inc_Input>;
  _set?: InputMaybe<UnitTypes_Set_Input>;
  pk_columns: UnitTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UnitTypes_ManyArgs = {
  updates: Array<UnitTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UnitsArgs = {
  _inc?: InputMaybe<Units_Inc_Input>;
  _set?: InputMaybe<Units_Set_Input>;
  where: Units_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Units_ManyArgs = {
  updates: Array<Units_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserStatusArgs = {
  _inc?: InputMaybe<UserStatus_Inc_Input>;
  _set?: InputMaybe<UserStatus_Set_Input>;
  where: UserStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserStatus_By_PkArgs = {
  _inc?: InputMaybe<UserStatus_Inc_Input>;
  _set?: InputMaybe<UserStatus_Set_Input>;
  pk_columns: UserStatus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserStatus_ManyArgs = {
  updates: Array<UserStatus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_VirtualUnitsArgs = {
  _inc?: InputMaybe<VirtualUnits_Inc_Input>;
  _set?: InputMaybe<VirtualUnits_Set_Input>;
  where: VirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_VirtualUnits_By_PkArgs = {
  _inc?: InputMaybe<VirtualUnits_Inc_Input>;
  _set?: InputMaybe<VirtualUnits_Set_Input>;
  pk_columns: VirtualUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_VirtualUnits_ManyArgs = {
  updates: Array<VirtualUnits_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendmentsArgs = {
  _inc?: InputMaybe<YardiAmendments_Inc_Input>;
  _set?: InputMaybe<YardiAmendments_Set_Input>;
  where: YardiAmendments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendments_DealsArgs = {
  _inc?: InputMaybe<YardiAmendments_Deals_Inc_Input>;
  _set?: InputMaybe<YardiAmendments_Deals_Set_Input>;
  where: YardiAmendments_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendments_Deals_By_PkArgs = {
  _inc?: InputMaybe<YardiAmendments_Deals_Inc_Input>;
  _set?: InputMaybe<YardiAmendments_Deals_Set_Input>;
  pk_columns: YardiAmendments_Deals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendments_Deals_ManyArgs = {
  updates: Array<YardiAmendments_Deals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendments_By_PkArgs = {
  _inc?: InputMaybe<YardiAmendments_Inc_Input>;
  _set?: InputMaybe<YardiAmendments_Set_Input>;
  pk_columns: YardiAmendments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiAmendments_ManyArgs = {
  updates: Array<YardiAmendments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContactsArgs = {
  _set?: InputMaybe<YardiContacts_Set_Input>;
  where: YardiContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_ContactsArgs = {
  _inc?: InputMaybe<YardiContacts_Contacts_Inc_Input>;
  _set?: InputMaybe<YardiContacts_Contacts_Set_Input>;
  where: YardiContacts_Contacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_Contacts_By_PkArgs = {
  _inc?: InputMaybe<YardiContacts_Contacts_Inc_Input>;
  _set?: InputMaybe<YardiContacts_Contacts_Set_Input>;
  pk_columns: YardiContacts_Contacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_Contacts_ManyArgs = {
  updates: Array<YardiContacts_Contacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_DealOwnersArgs = {
  _set?: InputMaybe<YardiContacts_DealOwners_Set_Input>;
  where: YardiContacts_DealOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_DealOwners_By_PkArgs = {
  _set?: InputMaybe<YardiContacts_DealOwners_Set_Input>;
  pk_columns: YardiContacts_DealOwners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_DealOwners_ManyArgs = {
  updates: Array<YardiContacts_DealOwners_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_YardiPropertiesArgs = {
  _set?: InputMaybe<YardiContacts_YardiProperties_Set_Input>;
  where: YardiContacts_YardiProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_YardiProperties_By_PkArgs = {
  _set?: InputMaybe<YardiContacts_YardiProperties_Set_Input>;
  pk_columns: YardiContacts_YardiProperties_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_YardiProperties_ManyArgs = {
  updates: Array<YardiContacts_YardiProperties_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_By_PkArgs = {
  _set?: InputMaybe<YardiContacts_Set_Input>;
  pk_columns: YardiContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiContacts_ManyArgs = {
  updates: Array<YardiContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomersArgs = {
  _set?: InputMaybe<YardiCustomers_Set_Input>;
  where: YardiCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomers_DealsArgs = {
  _inc?: InputMaybe<YardiCustomers_Deals_Inc_Input>;
  _set?: InputMaybe<YardiCustomers_Deals_Set_Input>;
  where: YardiCustomers_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomers_Deals_By_PkArgs = {
  _inc?: InputMaybe<YardiCustomers_Deals_Inc_Input>;
  _set?: InputMaybe<YardiCustomers_Deals_Set_Input>;
  pk_columns: YardiCustomers_Deals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomers_Deals_ManyArgs = {
  updates: Array<YardiCustomers_Deals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomers_By_PkArgs = {
  _set?: InputMaybe<YardiCustomers_Set_Input>;
  pk_columns: YardiCustomers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiCustomers_ManyArgs = {
  updates: Array<YardiCustomers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiDealSyncStatusesArgs = {
  _append?: InputMaybe<YardiDealSyncStatuses_Append_Input>;
  _delete_at_path?: InputMaybe<YardiDealSyncStatuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiDealSyncStatuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiDealSyncStatuses_Delete_Key_Input>;
  _inc?: InputMaybe<YardiDealSyncStatuses_Inc_Input>;
  _prepend?: InputMaybe<YardiDealSyncStatuses_Prepend_Input>;
  _set?: InputMaybe<YardiDealSyncStatuses_Set_Input>;
  where: YardiDealSyncStatuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiDealSyncStatuses_By_PkArgs = {
  _append?: InputMaybe<YardiDealSyncStatuses_Append_Input>;
  _delete_at_path?: InputMaybe<YardiDealSyncStatuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiDealSyncStatuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiDealSyncStatuses_Delete_Key_Input>;
  _inc?: InputMaybe<YardiDealSyncStatuses_Inc_Input>;
  _prepend?: InputMaybe<YardiDealSyncStatuses_Prepend_Input>;
  _set?: InputMaybe<YardiDealSyncStatuses_Set_Input>;
  pk_columns: YardiDealSyncStatuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiDealSyncStatuses_ManyArgs = {
  updates: Array<YardiDealSyncStatuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiIngestionInfosArgs = {
  _append?: InputMaybe<YardiIngestionInfos_Append_Input>;
  _delete_at_path?: InputMaybe<YardiIngestionInfos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiIngestionInfos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiIngestionInfos_Delete_Key_Input>;
  _inc?: InputMaybe<YardiIngestionInfos_Inc_Input>;
  _prepend?: InputMaybe<YardiIngestionInfos_Prepend_Input>;
  _set?: InputMaybe<YardiIngestionInfos_Set_Input>;
  where: YardiIngestionInfos_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiIngestionInfos_By_PkArgs = {
  _append?: InputMaybe<YardiIngestionInfos_Append_Input>;
  _delete_at_path?: InputMaybe<YardiIngestionInfos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiIngestionInfos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiIngestionInfos_Delete_Key_Input>;
  _inc?: InputMaybe<YardiIngestionInfos_Inc_Input>;
  _prepend?: InputMaybe<YardiIngestionInfos_Prepend_Input>;
  _set?: InputMaybe<YardiIngestionInfos_Set_Input>;
  pk_columns: YardiIngestionInfos_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiIngestionInfos_ManyArgs = {
  updates: Array<YardiIngestionInfos_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiPropertiesArgs = {
  _append?: InputMaybe<YardiProperties_Append_Input>;
  _delete_at_path?: InputMaybe<YardiProperties_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiProperties_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiProperties_Delete_Key_Input>;
  _inc?: InputMaybe<YardiProperties_Inc_Input>;
  _prepend?: InputMaybe<YardiProperties_Prepend_Input>;
  _set?: InputMaybe<YardiProperties_Set_Input>;
  where: YardiProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiProperties_By_PkArgs = {
  _append?: InputMaybe<YardiProperties_Append_Input>;
  _delete_at_path?: InputMaybe<YardiProperties_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<YardiProperties_Delete_Elem_Input>;
  _delete_key?: InputMaybe<YardiProperties_Delete_Key_Input>;
  _inc?: InputMaybe<YardiProperties_Inc_Input>;
  _prepend?: InputMaybe<YardiProperties_Prepend_Input>;
  _set?: InputMaybe<YardiProperties_Set_Input>;
  pk_columns: YardiProperties_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiProperties_ManyArgs = {
  updates: Array<YardiProperties_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiPropertyProfilesArgs = {
  _inc?: InputMaybe<YardiPropertyProfiles_Inc_Input>;
  _set?: InputMaybe<YardiPropertyProfiles_Set_Input>;
  where: YardiPropertyProfiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiPropertyProfiles_By_PkArgs = {
  _inc?: InputMaybe<YardiPropertyProfiles_Inc_Input>;
  _set?: InputMaybe<YardiPropertyProfiles_Set_Input>;
  pk_columns: YardiPropertyProfiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiPropertyProfiles_ManyArgs = {
  updates: Array<YardiPropertyProfiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenantToUnitsArgs = {
  _set?: InputMaybe<YardiTenantToUnits_Set_Input>;
  where: YardiTenantToUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenantToUnits_By_PkArgs = {
  _set?: InputMaybe<YardiTenantToUnits_Set_Input>;
  pk_columns: YardiTenantToUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenantToUnits_ManyArgs = {
  updates: Array<YardiTenantToUnits_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenant_YardiContactsArgs = {
  _set?: InputMaybe<YardiTenant_YardiContacts_Set_Input>;
  where: YardiTenant_YardiContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenant_YardiContacts_By_PkArgs = {
  _set?: InputMaybe<YardiTenant_YardiContacts_Set_Input>;
  pk_columns: YardiTenant_YardiContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenant_YardiContacts_ManyArgs = {
  updates: Array<YardiTenant_YardiContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenantsArgs = {
  _set?: InputMaybe<YardiTenants_Set_Input>;
  where: YardiTenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenants_DealsArgs = {
  _inc?: InputMaybe<YardiTenants_Deals_Inc_Input>;
  _set?: InputMaybe<YardiTenants_Deals_Set_Input>;
  where: YardiTenants_Deals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenants_Deals_By_PkArgs = {
  _inc?: InputMaybe<YardiTenants_Deals_Inc_Input>;
  _set?: InputMaybe<YardiTenants_Deals_Set_Input>;
  pk_columns: YardiTenants_Deals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenants_Deals_ManyArgs = {
  updates: Array<YardiTenants_Deals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenants_By_PkArgs = {
  _set?: InputMaybe<YardiTenants_Set_Input>;
  pk_columns: YardiTenants_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiTenants_ManyArgs = {
  updates: Array<YardiTenants_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnitProfilesArgs = {
  _inc?: InputMaybe<YardiUnitProfiles_Inc_Input>;
  _set?: InputMaybe<YardiUnitProfiles_Set_Input>;
  where: YardiUnitProfiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnitProfiles_By_PkArgs = {
  _inc?: InputMaybe<YardiUnitProfiles_Inc_Input>;
  _set?: InputMaybe<YardiUnitProfiles_Set_Input>;
  pk_columns: YardiUnitProfiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnitProfiles_ManyArgs = {
  updates: Array<YardiUnitProfiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnitsArgs = {
  _inc?: InputMaybe<YardiUnits_Inc_Input>;
  _set?: InputMaybe<YardiUnits_Set_Input>;
  where: YardiUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnits_By_PkArgs = {
  _inc?: InputMaybe<YardiUnits_Inc_Input>;
  _set?: InputMaybe<YardiUnits_Set_Input>;
  pk_columns: YardiUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiUnits_ManyArgs = {
  updates: Array<YardiUnits_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnitsArgs = {
  _inc?: InputMaybe<YardiVirtualUnits_Inc_Input>;
  _set?: InputMaybe<YardiVirtualUnits_Set_Input>;
  where: YardiVirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnits_VirtualUnitsArgs = {
  _inc?: InputMaybe<YardiVirtualUnits_VirtualUnits_Inc_Input>;
  _set?: InputMaybe<YardiVirtualUnits_VirtualUnits_Set_Input>;
  where: YardiVirtualUnits_VirtualUnits_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnits_VirtualUnits_By_PkArgs = {
  _inc?: InputMaybe<YardiVirtualUnits_VirtualUnits_Inc_Input>;
  _set?: InputMaybe<YardiVirtualUnits_VirtualUnits_Set_Input>;
  pk_columns: YardiVirtualUnits_VirtualUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnits_VirtualUnits_ManyArgs = {
  updates: Array<YardiVirtualUnits_VirtualUnits_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnits_By_PkArgs = {
  _inc?: InputMaybe<YardiVirtualUnits_Inc_Input>;
  _set?: InputMaybe<YardiVirtualUnits_Set_Input>;
  pk_columns: YardiVirtualUnits_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_YardiVirtualUnits_ManyArgs = {
  updates: Array<YardiVirtualUnits_Updates>;
};

/** mutation root */
export type Mutation_RootYardiDeleteQuestionnaireArgs = {
  dealId: Scalars["Int"];
};

export type NewDealId = {
  __typename?: "newDealId";
  newDealId: Scalars["Int"];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["numeric"]>;
  _gt?: InputMaybe<Scalars["numeric"]>;
  _gte?: InputMaybe<Scalars["numeric"]>;
  _in?: InputMaybe<Array<Scalars["numeric"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["numeric"]>;
  _lte?: InputMaybe<Scalars["numeric"]>;
  _neq?: InputMaybe<Scalars["numeric"]>;
  _nin?: InputMaybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

export type Query_Root = {
  __typename?: "query_root";
  /** An array relationship */
  ActionAllocations: Array<ActionAllocations>;
  /** An aggregate relationship */
  ActionAllocations_aggregate: ActionAllocations_Aggregate;
  /** fetch data from the table: "ActionAllocations" using primary key columns */
  ActionAllocations_by_pk?: Maybe<ActionAllocations>;
  /** fetch data from the table: "ActionItems" */
  ActionItems: Array<ActionItems>;
  /** fetch aggregated fields from the table: "ActionItems" */
  ActionItems_aggregate: ActionItems_Aggregate;
  /** fetch data from the table: "ActionItems" using primary key columns */
  ActionItems_by_pk?: Maybe<ActionItems>;
  /** fetch data from the table: "ActivityLog" */
  ActivityLog: Array<ActivityLog>;
  /** fetch data from the table: "ActivityLogType" */
  ActivityLogType: Array<ActivityLogType>;
  /** fetch aggregated fields from the table: "ActivityLogType" */
  ActivityLogType_aggregate: ActivityLogType_Aggregate;
  /** fetch data from the table: "ActivityLogType" using primary key columns */
  ActivityLogType_by_pk?: Maybe<ActivityLogType>;
  /** fetch aggregated fields from the table: "ActivityLog" */
  ActivityLog_aggregate: ActivityLog_Aggregate;
  /** fetch data from the table: "ActivityLog" using primary key columns */
  ActivityLog_by_pk?: Maybe<ActivityLog>;
  /** An array relationship */
  Agreements: Array<Agreements>;
  /** An aggregate relationship */
  Agreements_aggregate: Agreements_Aggregate;
  /** fetch data from the table: "Agreements" using primary key columns */
  Agreements_by_pk?: Maybe<Agreements>;
  /** An array relationship */
  Approvers: Array<Approvers>;
  /** An aggregate relationship */
  Approvers_aggregate: Approvers_Aggregate;
  /** fetch data from the table: "Approvers" using primary key columns */
  Approvers_by_pk?: Maybe<Approvers>;
  /** An array relationship */
  BaseLandlordServices: Array<BaseLandlordServices>;
  /** An aggregate relationship */
  BaseLandlordServices_aggregate: BaseLandlordServices_Aggregate;
  /** fetch data from the table: "BaseLandlordServices" using primary key columns */
  BaseLandlordServices_by_pk?: Maybe<BaseLandlordServices>;
  /** An array relationship */
  CenterApprovers: Array<CenterApprovers>;
  /** An aggregate relationship */
  CenterApprovers_aggregate: CenterApprovers_Aggregate;
  /** fetch data from the table: "CenterApprovers" using primary key columns */
  CenterApprovers_by_pk?: Maybe<CenterApprovers>;
  /** An array relationship */
  CommentTags: Array<CommentTags>;
  /** An aggregate relationship */
  CommentTags_aggregate: CommentTags_Aggregate;
  /** fetch data from the table: "CommentTags" using primary key columns */
  CommentTags_by_pk?: Maybe<CommentTags>;
  /** An array relationship */
  CommentUserTags: Array<CommentUserTags>;
  /** An aggregate relationship */
  CommentUserTags_aggregate: CommentUserTags_Aggregate;
  /** fetch data from the table: "CommentUserTags" using primary key columns */
  CommentUserTags_by_pk?: Maybe<CommentUserTags>;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "Comments" using primary key columns */
  Comments_by_pk?: Maybe<Comments>;
  /** An array relationship */
  Companies: Array<Companies>;
  /** An aggregate relationship */
  Companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "Companies" using primary key columns */
  Companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "ContactTypes" */
  ContactTypes: Array<ContactTypes>;
  /** fetch aggregated fields from the table: "ContactTypes" */
  ContactTypes_aggregate: ContactTypes_Aggregate;
  /** fetch data from the table: "ContactTypes" using primary key columns */
  ContactTypes_by_pk?: Maybe<ContactTypes>;
  /** An array relationship */
  Contacts: Array<Contacts>;
  /** An aggregate relationship */
  Contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "Contacts" using primary key columns */
  Contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "Countries" */
  Countries: Array<Countries>;
  /** fetch aggregated fields from the table: "Countries" */
  Countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "Countries" using primary key columns */
  Countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "CustomLicences" */
  CustomLicences: Array<CustomLicences>;
  /** fetch aggregated fields from the table: "CustomLicences" */
  CustomLicences_aggregate: CustomLicences_Aggregate;
  /** fetch data from the table: "CustomLicences" using primary key columns */
  CustomLicences_by_pk?: Maybe<CustomLicences>;
  /** An array relationship */
  DealActionItem: Array<DealActionItem>;
  /** An aggregate relationship */
  DealActionItem_aggregate: DealActionItem_Aggregate;
  /** fetch data from the table: "DealActionItem" using primary key columns */
  DealActionItem_by_pk?: Maybe<DealActionItem>;
  /** fetch data from the table: "DealBusinessContacts" */
  DealBusinessContacts: Array<DealBusinessContacts>;
  /** fetch aggregated fields from the table: "DealBusinessContacts" */
  DealBusinessContacts_aggregate: DealBusinessContacts_Aggregate;
  /** fetch data from the table: "DealBusinessContacts" using primary key columns */
  DealBusinessContacts_by_pk?: Maybe<DealBusinessContacts>;
  /** fetch data from the table: "DealContactsTags" */
  DealContactsTags: Array<DealContactsTags>;
  /** fetch aggregated fields from the table: "DealContactsTags" */
  DealContactsTags_aggregate: DealContactsTags_Aggregate;
  /** fetch data from the table: "DealContactsTags" using primary key columns */
  DealContactsTags_by_pk?: Maybe<DealContactsTags>;
  /** An array relationship */
  DealData: Array<DealData>;
  /** fetch data from the table: "DealDataCalculations" */
  DealDataCalculations: Array<DealDataCalculations>;
  /** fetch aggregated fields from the table: "DealDataCalculations" */
  DealDataCalculations_aggregate: DealDataCalculations_Aggregate;
  /** An array relationship */
  DealDataCustomLicences: Array<DealDataCustomLicences>;
  /** An aggregate relationship */
  DealDataCustomLicences_aggregate: DealDataCustomLicences_Aggregate;
  /** fetch data from the table: "DealDataCustomLicences" using primary key columns */
  DealDataCustomLicences_by_pk?: Maybe<DealDataCustomLicences>;
  /** An array relationship */
  DealDataGuarantors: Array<DealDataGuarantors>;
  /** An aggregate relationship */
  DealDataGuarantors_aggregate: DealDataGuarantors_Aggregate;
  /** fetch data from the table: "DealDataGuarantors" using primary key columns */
  DealDataGuarantors_by_pk?: Maybe<DealDataGuarantors>;
  /** An aggregate relationship */
  DealData_aggregate: DealData_Aggregate;
  /** fetch data from the table: "DealData" using primary key columns */
  DealData_by_pk?: Maybe<DealData>;
  /** An array relationship */
  DealExtensionItems: Array<DealExtensionItems>;
  /** An aggregate relationship */
  DealExtensionItems_aggregate: DealExtensionItems_Aggregate;
  /** fetch data from the table: "DealExtensionItems" using primary key columns */
  DealExtensionItems_by_pk?: Maybe<DealExtensionItems>;
  /** fetch data from the table: "DealFileChunks" */
  DealFileChunks: Array<DealFileChunks>;
  /** fetch aggregated fields from the table: "DealFileChunks" */
  DealFileChunks_aggregate: DealFileChunks_Aggregate;
  /** fetch data from the table: "DealFileChunks" using primary key columns */
  DealFileChunks_by_pk?: Maybe<DealFileChunks>;
  /** An array relationship */
  DealFileTags: Array<DealFileTags>;
  /** An aggregate relationship */
  DealFileTags_aggregate: DealFileTags_Aggregate;
  /** fetch data from the table: "DealFileTags" using primary key columns */
  DealFileTags_by_pk?: Maybe<DealFileTags>;
  /** An array relationship */
  DealFiles: Array<DealFiles>;
  /** An aggregate relationship */
  DealFiles_aggregate: DealFiles_Aggregate;
  /** fetch data from the table: "DealFiles" using primary key columns */
  DealFiles_by_pk?: Maybe<DealFiles>;
  /** An array relationship */
  DealFitoutLandlordWorks: Array<DealFitoutLandlordWorks>;
  /** An aggregate relationship */
  DealFitoutLandlordWorks_aggregate: DealFitoutLandlordWorks_Aggregate;
  /** fetch data from the table: "DealFitoutLandlordWorks" using primary key columns */
  DealFitoutLandlordWorks_by_pk?: Maybe<DealFitoutLandlordWorks>;
  /** An array relationship */
  DealLAFApprovals: Array<DealLafApprovals>;
  /** An aggregate relationship */
  DealLAFApprovals_aggregate: DealLafApprovals_Aggregate;
  /** fetch data from the table: "DealLAFApprovals" using primary key columns */
  DealLAFApprovals_by_pk?: Maybe<DealLafApprovals>;
  /** An array relationship */
  DealLinks: Array<DealLinks>;
  /** An aggregate relationship */
  DealLinks_aggregate: DealLinks_Aggregate;
  /** fetch data from the table: "DealLinks" using primary key columns */
  DealLinks_by_pk?: Maybe<DealLinks>;
  /** An array relationship */
  DealOptions: Array<DealOptions>;
  /** An aggregate relationship */
  DealOptions_aggregate: DealOptions_Aggregate;
  /** fetch data from the table: "DealOptions" using primary key columns */
  DealOptions_by_pk?: Maybe<DealOptions>;
  /** An array relationship */
  DealPermittedUses: Array<DealPermittedUses>;
  /** An aggregate relationship */
  DealPermittedUses_aggregate: DealPermittedUses_Aggregate;
  /** fetch data from the table: "DealPermittedUses" using primary key columns */
  DealPermittedUses_by_pk?: Maybe<DealPermittedUses>;
  /** An array relationship */
  DealPhases: Array<DealPhases>;
  /** An aggregate relationship */
  DealPhases_aggregate: DealPhases_Aggregate;
  /** fetch data from the table: "DealPhases" using primary key columns */
  DealPhases_by_pk?: Maybe<DealPhases>;
  /** fetch data from the table: "DealRiskLevels" */
  DealRiskLevels: Array<DealRiskLevels>;
  /** fetch aggregated fields from the table: "DealRiskLevels" */
  DealRiskLevels_aggregate: DealRiskLevels_Aggregate;
  /** fetch data from the table: "DealRiskLevels" using primary key columns */
  DealRiskLevels_by_pk?: Maybe<DealRiskLevels>;
  /** An array relationship */
  DealShopActivities: Array<DealShopActivities>;
  /** An aggregate relationship */
  DealShopActivities_aggregate: DealShopActivities_Aggregate;
  /** fetch data from the table: "DealShopActivities" using primary key columns */
  DealShopActivities_by_pk?: Maybe<DealShopActivities>;
  /** fetch data from the table: "DealShopActivityTypes" */
  DealShopActivityTypes: Array<DealShopActivityTypes>;
  /** fetch aggregated fields from the table: "DealShopActivityTypes" */
  DealShopActivityTypes_aggregate: DealShopActivityTypes_Aggregate;
  /** fetch data from the table: "DealShopActivityTypes" using primary key columns */
  DealShopActivityTypes_by_pk?: Maybe<DealShopActivityTypes>;
  /** An array relationship */
  DealSpecialConditions: Array<DealSpecialConditions>;
  /** An aggregate relationship */
  DealSpecialConditions_aggregate: DealSpecialConditions_Aggregate;
  /** fetch data from the table: "DealSpecialConditions" using primary key columns */
  DealSpecialConditions_by_pk?: Maybe<DealSpecialConditions>;
  /** fetch data from the table: "DealStatus" */
  DealStatus: Array<DealStatus>;
  /** fetch aggregated fields from the table: "DealStatus" */
  DealStatus_aggregate: DealStatus_Aggregate;
  /** fetch data from the table: "DealStatus" using primary key columns */
  DealStatus_by_pk?: Maybe<DealStatus>;
  /** fetch data from the table: "DealTypes" */
  DealTypes: Array<DealTypes>;
  /** fetch aggregated fields from the table: "DealTypes" */
  DealTypes_aggregate: DealTypes_Aggregate;
  /** fetch data from the table: "DealTypes" using primary key columns */
  DealTypes_by_pk?: Maybe<DealTypes>;
  /** An array relationship */
  Deals: Array<Deals>;
  /** An array relationship */
  DealsCompanies: Array<DealsCompanies>;
  /** An aggregate relationship */
  DealsCompanies_aggregate: DealsCompanies_Aggregate;
  /** fetch data from the table: "DealsCompanies" using primary key columns */
  DealsCompanies_by_pk?: Maybe<DealsCompanies>;
  /** An array relationship */
  DealsContacts: Array<DealsContacts>;
  /** An aggregate relationship */
  DealsContacts_aggregate: DealsContacts_Aggregate;
  /** fetch data from the table: "DealsContacts" using primary key columns */
  DealsContacts_by_pk?: Maybe<DealsContacts>;
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  /** fetch data from the table: "DealsUnits" using primary key columns */
  DealsUnits_by_pk?: Maybe<DealsUnits>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  /** fetch data from the table: "Deals" using primary key columns */
  Deals_by_pk?: Maybe<Deals>;
  /** An array relationship */
  DocumentRequests: Array<DocumentRequests>;
  /** An aggregate relationship */
  DocumentRequests_aggregate: DocumentRequests_Aggregate;
  /** fetch data from the table: "DocumentRequests" using primary key columns */
  DocumentRequests_by_pk?: Maybe<DocumentRequests>;
  /** An array relationship */
  DocumentTemplates: Array<DocumentTemplates>;
  /** An aggregate relationship */
  DocumentTemplates_aggregate: DocumentTemplates_Aggregate;
  /** fetch data from the table: "DocumentTemplates" using primary key columns */
  DocumentTemplates_by_pk?: Maybe<DocumentTemplates>;
  /** An array relationship */
  EscalatedApprovers: Array<EscalatedApprovers>;
  /** An aggregate relationship */
  EscalatedApprovers_aggregate: EscalatedApprovers_Aggregate;
  /** fetch data from the table: "EscalatedApprovers" using primary key columns */
  EscalatedApprovers_by_pk?: Maybe<EscalatedApprovers>;
  /** An array relationship */
  GeneratedDocuments: Array<GeneratedDocuments>;
  /** An aggregate relationship */
  GeneratedDocuments_aggregate: GeneratedDocuments_Aggregate;
  /** fetch data from the table: "GeneratedDocuments" using primary key columns */
  GeneratedDocuments_by_pk?: Maybe<GeneratedDocuments>;
  /** fetch data from the table: "LafActionChoices" */
  LafActionChoices: Array<LafActionChoices>;
  /** fetch aggregated fields from the table: "LafActionChoices" */
  LafActionChoices_aggregate: LafActionChoices_Aggregate;
  /** fetch data from the table: "LafActionChoices" using primary key columns */
  LafActionChoices_by_pk?: Maybe<LafActionChoices>;
  /** An array relationship */
  Leases: Array<Leases>;
  /** An aggregate relationship */
  Leases_aggregate: Leases_Aggregate;
  /** fetch data from the table: "Leases" using primary key columns */
  Leases_by_pk?: Maybe<Leases>;
  /** An array relationship */
  Locations: Array<Locations>;
  /** An aggregate relationship */
  Locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "Locations" using primary key columns */
  Locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  Offers: Array<Offers>;
  /** An aggregate relationship */
  Offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "Offers" using primary key columns */
  Offers_by_pk?: Maybe<Offers>;
  /** An array relationship */
  PhaseActionItem: Array<PhaseActionItem>;
  /** An aggregate relationship */
  PhaseActionItem_aggregate: PhaseActionItem_Aggregate;
  /** fetch data from the table: "PhaseActionItem" using primary key columns */
  PhaseActionItem_by_pk?: Maybe<PhaseActionItem>;
  /** fetch data from the table: "PhaseDealStatus" */
  PhaseDealStatus: Array<PhaseDealStatus>;
  /** fetch aggregated fields from the table: "PhaseDealStatus" */
  PhaseDealStatus_aggregate: PhaseDealStatus_Aggregate;
  /** fetch data from the table: "PhaseDealStatus" using primary key columns */
  PhaseDealStatus_by_pk?: Maybe<PhaseDealStatus>;
  /** fetch data from the table: "Phases" */
  Phases: Array<Phases>;
  /** fetch aggregated fields from the table: "Phases" */
  Phases_aggregate: Phases_Aggregate;
  /** fetch data from the table: "Phases" using primary key columns */
  Phases_by_pk?: Maybe<Phases>;
  /** fetch data from the table: "Properties" */
  Properties: Array<Properties>;
  /** fetch data from the table: "PropertiesInformation" */
  PropertiesInformation: Array<PropertiesInformation>;
  /** fetch aggregated fields from the table: "PropertiesInformation" */
  PropertiesInformation_aggregate: PropertiesInformation_Aggregate;
  /** fetch data from the table: "PropertiesInformation" using primary key columns */
  PropertiesInformation_by_pk?: Maybe<PropertiesInformation>;
  /** fetch aggregated fields from the table: "Properties" */
  Properties_aggregate: Properties_Aggregate;
  /** An array relationship */
  PropertyContacts: Array<PropertyContacts>;
  /** An aggregate relationship */
  PropertyContacts_aggregate: PropertyContacts_Aggregate;
  /** fetch data from the table: "PropertyContacts" using primary key columns */
  PropertyContacts_by_pk?: Maybe<PropertyContacts>;
  /** fetch data from the table: "PropertyFileTypes" */
  PropertyFileTypes: Array<PropertyFileTypes>;
  /** fetch aggregated fields from the table: "PropertyFileTypes" */
  PropertyFileTypes_aggregate: PropertyFileTypes_Aggregate;
  /** fetch data from the table: "PropertyFileTypes" using primary key columns */
  PropertyFileTypes_by_pk?: Maybe<PropertyFileTypes>;
  /** An array relationship */
  PropertyFiles: Array<PropertyFiles>;
  /** An aggregate relationship */
  PropertyFiles_aggregate: PropertyFiles_Aggregate;
  /** fetch data from the table: "PropertyFiles" using primary key columns */
  PropertyFiles_by_pk?: Maybe<PropertyFiles>;
  /** An array relationship */
  Proposals: Array<Proposals>;
  /** An aggregate relationship */
  Proposals_aggregate: Proposals_Aggregate;
  /** fetch data from the table: "Proposals" using primary key columns */
  Proposals_by_pk?: Maybe<Proposals>;
  /** An array relationship */
  Registrations: Array<Registrations>;
  /** An aggregate relationship */
  Registrations_aggregate: Registrations_Aggregate;
  /** fetch data from the table: "Registrations" using primary key columns */
  Registrations_by_pk?: Maybe<Registrations>;
  /** fetch data from the table: "SalesType" */
  SalesType: Array<SalesType>;
  /** fetch aggregated fields from the table: "SalesType" */
  SalesType_aggregate: SalesType_Aggregate;
  /** fetch data from the table: "SalesType" using primary key columns */
  SalesType_by_pk?: Maybe<SalesType>;
  /** An array relationship */
  States: Array<States>;
  /** An aggregate relationship */
  States_aggregate: States_Aggregate;
  /** fetch data from the table: "States" using primary key columns */
  States_by_pk?: Maybe<States>;
  /** fetch data from the table: "Tags" */
  Tags: Array<Tags>;
  /** fetch aggregated fields from the table: "Tags" */
  Tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "Tags" using primary key columns */
  Tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  TemplatePermittedUses: Array<TemplatePermittedUses>;
  /** An aggregate relationship */
  TemplatePermittedUses_aggregate: TemplatePermittedUses_Aggregate;
  /** fetch data from the table: "TemplatePermittedUses" using primary key columns */
  TemplatePermittedUses_by_pk?: Maybe<TemplatePermittedUses>;
  /** An array relationship */
  TemplateSpecialConditions: Array<TemplateSpecialConditions>;
  /** An aggregate relationship */
  TemplateSpecialConditions_aggregate: TemplateSpecialConditions_Aggregate;
  /** fetch data from the table: "TemplateSpecialConditions" using primary key columns */
  TemplateSpecialConditions_by_pk?: Maybe<TemplateSpecialConditions>;
  /** fetch data from the table: "TenantTypes" */
  TenantTypes: Array<TenantTypes>;
  /** fetch aggregated fields from the table: "TenantTypes" */
  TenantTypes_aggregate: TenantTypes_Aggregate;
  /** fetch data from the table: "TenantTypes" using primary key columns */
  TenantTypes_by_pk?: Maybe<TenantTypes>;
  /** An array relationship */
  Terms: Array<Terms>;
  /** An aggregate relationship */
  Terms_aggregate: Terms_Aggregate;
  /** fetch data from the table: "Terms" using primary key columns */
  Terms_by_pk?: Maybe<Terms>;
  /** fetch data from the table: "TriSelectEnum" */
  TriSelectEnum: Array<TriSelectEnum>;
  /** fetch aggregated fields from the table: "TriSelectEnum" */
  TriSelectEnum_aggregate: TriSelectEnum_Aggregate;
  /** fetch data from the table: "TriSelectEnum" using primary key columns */
  TriSelectEnum_by_pk?: Maybe<TriSelectEnum>;
  /** fetch data from the table: "UnitTypes" */
  UnitTypes: Array<UnitTypes>;
  /** fetch aggregated fields from the table: "UnitTypes" */
  UnitTypes_aggregate: UnitTypes_Aggregate;
  /** fetch data from the table: "UnitTypes" using primary key columns */
  UnitTypes_by_pk?: Maybe<UnitTypes>;
  /** fetch data from the table: "Units" */
  Units: Array<Units>;
  /** fetch aggregated fields from the table: "Units" */
  Units_aggregate: Units_Aggregate;
  /** fetch data from the table: "UserStatus" */
  UserStatus: Array<UserStatus>;
  /** fetch aggregated fields from the table: "UserStatus" */
  UserStatus_aggregate: UserStatus_Aggregate;
  /** fetch data from the table: "UserStatus" using primary key columns */
  UserStatus_by_pk?: Maybe<UserStatus>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "VirtualUnits" */
  VirtualUnits: Array<VirtualUnits>;
  /** fetch aggregated fields from the table: "VirtualUnits" */
  VirtualUnits_aggregate: VirtualUnits_Aggregate;
  /** fetch data from the table: "VirtualUnits" using primary key columns */
  VirtualUnits_by_pk?: Maybe<VirtualUnits>;
  /** fetch data from the table: "YardiActiveLeases" */
  YardiActiveLeases: Array<YardiActiveLeases>;
  /** fetch aggregated fields from the table: "YardiActiveLeases" */
  YardiActiveLeases_aggregate: YardiActiveLeases_Aggregate;
  /** An array relationship */
  YardiAmendments: Array<YardiAmendments>;
  /** An array relationship */
  YardiAmendments_Deals: Array<YardiAmendments_Deals>;
  /** An aggregate relationship */
  YardiAmendments_Deals_aggregate: YardiAmendments_Deals_Aggregate;
  /** fetch data from the table: "YardiAmendments_Deals" using primary key columns */
  YardiAmendments_Deals_by_pk?: Maybe<YardiAmendments_Deals>;
  /** An aggregate relationship */
  YardiAmendments_aggregate: YardiAmendments_Aggregate;
  /** fetch data from the table: "YardiAmendments" using primary key columns */
  YardiAmendments_by_pk?: Maybe<YardiAmendments>;
  /** An array relationship */
  YardiContacts: Array<YardiContacts>;
  /** An array relationship */
  YardiContacts_Contacts: Array<YardiContacts_Contacts>;
  /** An aggregate relationship */
  YardiContacts_Contacts_aggregate: YardiContacts_Contacts_Aggregate;
  /** fetch data from the table: "YardiContacts_Contacts" using primary key columns */
  YardiContacts_Contacts_by_pk?: Maybe<YardiContacts_Contacts>;
  /** fetch data from the table: "YardiContacts_DealOwners" */
  YardiContacts_DealOwners: Array<YardiContacts_DealOwners>;
  /** fetch aggregated fields from the table: "YardiContacts_DealOwners" */
  YardiContacts_DealOwners_aggregate: YardiContacts_DealOwners_Aggregate;
  /** fetch data from the table: "YardiContacts_DealOwners" using primary key columns */
  YardiContacts_DealOwners_by_pk?: Maybe<YardiContacts_DealOwners>;
  /** An array relationship */
  YardiContacts_YardiProperties: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_YardiProperties_aggregate: YardiContacts_YardiProperties_Aggregate;
  /** fetch data from the table: "YardiContacts_YardiProperties" using primary key columns */
  YardiContacts_YardiProperties_by_pk?: Maybe<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_aggregate: YardiContacts_Aggregate;
  /** fetch data from the table: "YardiContacts" using primary key columns */
  YardiContacts_by_pk?: Maybe<YardiContacts>;
  /** fetch data from the table: "YardiCustomers" */
  YardiCustomers: Array<YardiCustomers>;
  /** An array relationship */
  YardiCustomers_Deals: Array<YardiCustomers_Deals>;
  /** An aggregate relationship */
  YardiCustomers_Deals_aggregate: YardiCustomers_Deals_Aggregate;
  /** fetch data from the table: "YardiCustomers_Deals" using primary key columns */
  YardiCustomers_Deals_by_pk?: Maybe<YardiCustomers_Deals>;
  /** fetch aggregated fields from the table: "YardiCustomers" */
  YardiCustomers_aggregate: YardiCustomers_Aggregate;
  /** fetch data from the table: "YardiCustomers" using primary key columns */
  YardiCustomers_by_pk?: Maybe<YardiCustomers>;
  /** An array relationship */
  YardiDealSyncStatuses: Array<YardiDealSyncStatuses>;
  /** An aggregate relationship */
  YardiDealSyncStatuses_aggregate: YardiDealSyncStatuses_Aggregate;
  /** fetch data from the table: "YardiDealSyncStatuses" using primary key columns */
  YardiDealSyncStatuses_by_pk?: Maybe<YardiDealSyncStatuses>;
  /** fetch data from the table: "YardiIngestionInfos" */
  YardiIngestionInfos: Array<YardiIngestionInfos>;
  /** fetch aggregated fields from the table: "YardiIngestionInfos" */
  YardiIngestionInfos_aggregate: YardiIngestionInfos_Aggregate;
  /** fetch data from the table: "YardiIngestionInfos" using primary key columns */
  YardiIngestionInfos_by_pk?: Maybe<YardiIngestionInfos>;
  /** fetch data from the table: "YardiLeasesForRenewal" */
  YardiLeasesForRenewal: Array<YardiLeasesForRenewal>;
  /** fetch aggregated fields from the table: "YardiLeasesForRenewal" */
  YardiLeasesForRenewal_aggregate: YardiLeasesForRenewal_Aggregate;
  /** fetch data from the table: "YardiLimitedCustomers" */
  YardiLimitedCustomers: Array<YardiLimitedCustomers>;
  /** fetch aggregated fields from the table: "YardiLimitedCustomers" */
  YardiLimitedCustomers_aggregate: YardiLimitedCustomers_Aggregate;
  /** fetch data from the table: "YardiProperties" */
  YardiProperties: Array<YardiProperties>;
  /** fetch aggregated fields from the table: "YardiProperties" */
  YardiProperties_aggregate: YardiProperties_Aggregate;
  /** fetch data from the table: "YardiProperties" using primary key columns */
  YardiProperties_by_pk?: Maybe<YardiProperties>;
  /** fetch data from the table: "YardiPropertyProfiles" */
  YardiPropertyProfiles: Array<YardiPropertyProfiles>;
  /** fetch aggregated fields from the table: "YardiPropertyProfiles" */
  YardiPropertyProfiles_aggregate: YardiPropertyProfiles_Aggregate;
  /** fetch data from the table: "YardiPropertyProfiles" using primary key columns */
  YardiPropertyProfiles_by_pk?: Maybe<YardiPropertyProfiles>;
  /** An array relationship */
  YardiTenantToUnits: Array<YardiTenantToUnits>;
  /** An aggregate relationship */
  YardiTenantToUnits_aggregate: YardiTenantToUnits_Aggregate;
  /** fetch data from the table: "YardiTenantToUnits" using primary key columns */
  YardiTenantToUnits_by_pk?: Maybe<YardiTenantToUnits>;
  /** fetch data from the table: "YardiTenant_YardiContacts" */
  YardiTenant_YardiContacts: Array<YardiTenant_YardiContacts>;
  /** fetch aggregated fields from the table: "YardiTenant_YardiContacts" */
  YardiTenant_YardiContacts_aggregate: YardiTenant_YardiContacts_Aggregate;
  /** fetch data from the table: "YardiTenant_YardiContacts" using primary key columns */
  YardiTenant_YardiContacts_by_pk?: Maybe<YardiTenant_YardiContacts>;
  /** An array relationship */
  YardiTenants: Array<YardiTenants>;
  /** An array relationship */
  YardiTenants_Deals: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_Deals_aggregate: YardiTenants_Deals_Aggregate;
  /** fetch data from the table: "YardiTenants_Deals" using primary key columns */
  YardiTenants_Deals_by_pk?: Maybe<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_aggregate: YardiTenants_Aggregate;
  /** fetch data from the table: "YardiTenants" using primary key columns */
  YardiTenants_by_pk?: Maybe<YardiTenants>;
  /** An array relationship */
  YardiUnitProfiles: Array<YardiUnitProfiles>;
  /** An aggregate relationship */
  YardiUnitProfiles_aggregate: YardiUnitProfiles_Aggregate;
  /** fetch data from the table: "YardiUnitProfiles" using primary key columns */
  YardiUnitProfiles_by_pk?: Maybe<YardiUnitProfiles>;
  /** An array relationship */
  YardiUnits: Array<YardiUnits>;
  /** An aggregate relationship */
  YardiUnits_aggregate: YardiUnits_Aggregate;
  /** fetch data from the table: "YardiUnits" using primary key columns */
  YardiUnits_by_pk?: Maybe<YardiUnits>;
  /** An array relationship */
  YardiVirtualUnits: Array<YardiVirtualUnits>;
  /** fetch data from the table: "YardiVirtualUnits_VirtualUnits" */
  YardiVirtualUnits_VirtualUnits: Array<YardiVirtualUnits_VirtualUnits>;
  /** fetch aggregated fields from the table: "YardiVirtualUnits_VirtualUnits" */
  YardiVirtualUnits_VirtualUnits_aggregate: YardiVirtualUnits_VirtualUnits_Aggregate;
  /** fetch data from the table: "YardiVirtualUnits_VirtualUnits" using primary key columns */
  YardiVirtualUnits_VirtualUnits_by_pk?: Maybe<YardiVirtualUnits_VirtualUnits>;
  /** An aggregate relationship */
  YardiVirtualUnits_aggregate: YardiVirtualUnits_Aggregate;
  /** fetch data from the table: "YardiVirtualUnits" using primary key columns */
  YardiVirtualUnits_by_pk?: Maybe<YardiVirtualUnits>;
  /** Chatting with Azure Open AI */
  chat?: Maybe<ChatResponse>;
  /** Get the backend queue counts */
  queueCounts?: Maybe<QueueCounts>;
  /** Use to use cognitive search to search for documents */
  searchDocumentsSearch: Array<Maybe<SearchOutput>>;
};

export type Query_RootActionAllocationsArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

export type Query_RootActionAllocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

export type Query_RootActionAllocations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootActionItemsArgs = {
  distinct_on?: InputMaybe<Array<ActionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionItems_Order_By>>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

export type Query_RootActionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionItems_Order_By>>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

export type Query_RootActionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootActivityLogArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

export type Query_RootActivityLogTypeArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLogType_Order_By>>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

export type Query_RootActivityLogType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLogType_Order_By>>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

export type Query_RootActivityLogType_By_PkArgs = {
  log_type: Scalars["String"];
};

export type Query_RootActivityLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

export type Query_RootActivityLog_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootAgreementsArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

export type Query_RootAgreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

export type Query_RootAgreements_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootApproversArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

export type Query_RootApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

export type Query_RootApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootBaseLandlordServicesArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

export type Query_RootBaseLandlordServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

export type Query_RootBaseLandlordServices_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCenterApproversArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

export type Query_RootCenterApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

export type Query_RootCenterApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCommentTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

export type Query_RootCommentTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

export type Query_RootCommentTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCommentUserTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

export type Query_RootCommentUserTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

export type Query_RootCommentUserTags_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Query_RootComments_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

export type Query_RootCompanies_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootContactTypesArgs = {
  distinct_on?: InputMaybe<Array<ContactTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ContactTypes_Order_By>>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

export type Query_RootContactTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ContactTypes_Order_By>>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

export type Query_RootContactTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Query_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Query_RootContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Query_RootCountries_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<CustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CustomLicences_Order_By>>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

export type Query_RootCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CustomLicences_Order_By>>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

export type Query_RootCustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealActionItemArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

export type Query_RootDealActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

export type Query_RootDealActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealBusinessContactsArgs = {
  distinct_on?: InputMaybe<Array<DealBusinessContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealBusinessContacts_Order_By>>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

export type Query_RootDealBusinessContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealBusinessContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealBusinessContacts_Order_By>>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

export type Query_RootDealBusinessContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealContactsTagsArgs = {
  distinct_on?: InputMaybe<Array<DealContactsTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealContactsTags_Order_By>>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

export type Query_RootDealContactsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealContactsTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealContactsTags_Order_By>>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

export type Query_RootDealContactsTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealDataArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

export type Query_RootDealDataCalculationsArgs = {
  distinct_on?: InputMaybe<Array<DealDataCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCalculations_Order_By>>;
  where?: InputMaybe<DealDataCalculations_Bool_Exp>;
};

export type Query_RootDealDataCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCalculations_Order_By>>;
  where?: InputMaybe<DealDataCalculations_Bool_Exp>;
};

export type Query_RootDealDataCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

export type Query_RootDealDataCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

export type Query_RootDealDataCustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealDataGuarantorsArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

export type Query_RootDealDataGuarantors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

export type Query_RootDealDataGuarantors_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

export type Query_RootDealData_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealExtensionItemsArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

export type Query_RootDealExtensionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

export type Query_RootDealExtensionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealFileChunksArgs = {
  distinct_on?: InputMaybe<Array<DealFileChunks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileChunks_Order_By>>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

export type Query_RootDealFileChunks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileChunks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileChunks_Order_By>>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

export type Query_RootDealFileChunks_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealFileTagsArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

export type Query_RootDealFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

export type Query_RootDealFileTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealFilesArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

export type Query_RootDealFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

export type Query_RootDealFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealFitoutLandlordWorksArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

export type Query_RootDealFitoutLandlordWorks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

export type Query_RootDealFitoutLandlordWorks_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealLafApprovalsArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

export type Query_RootDealLafApprovals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

export type Query_RootDealLafApprovals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealLinksArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

export type Query_RootDealLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

export type Query_RootDealLinks_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootDealOptionsArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

export type Query_RootDealOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

export type Query_RootDealOptions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealPermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

export type Query_RootDealPermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

export type Query_RootDealPermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealPhasesArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

export type Query_RootDealPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

export type Query_RootDealPhases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealRiskLevelsArgs = {
  distinct_on?: InputMaybe<Array<DealRiskLevels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealRiskLevels_Order_By>>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

export type Query_RootDealRiskLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealRiskLevels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealRiskLevels_Order_By>>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

export type Query_RootDealRiskLevels_By_PkArgs = {
  risk_level: Scalars["String"];
};

export type Query_RootDealShopActivitiesArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

export type Query_RootDealShopActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

export type Query_RootDealShopActivities_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealShopActivityTypesArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivityTypes_Order_By>>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

export type Query_RootDealShopActivityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivityTypes_Order_By>>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

export type Query_RootDealShopActivityTypes_By_PkArgs = {
  name: Scalars["String"];
};

export type Query_RootDealSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

export type Query_RootDealSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

export type Query_RootDealSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealStatusArgs = {
  distinct_on?: InputMaybe<Array<DealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealStatus_Order_By>>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

export type Query_RootDealStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealStatus_Order_By>>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

export type Query_RootDealStatus_By_PkArgs = {
  name: Scalars["String"];
};

export type Query_RootDealTypesArgs = {
  distinct_on?: InputMaybe<Array<DealTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealTypes_Order_By>>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

export type Query_RootDealTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealTypes_Order_By>>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

export type Query_RootDealTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Query_RootDealsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

export type Query_RootDealsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

export type Query_RootDealsCompanies_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealsContactsArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

export type Query_RootDealsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

export type Query_RootDealsContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

export type Query_RootDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

export type Query_RootDealsUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Query_RootDeals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDocumentRequestsArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

export type Query_RootDocumentRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

export type Query_RootDocumentRequests_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootDocumentTemplatesArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

export type Query_RootDocumentTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

export type Query_RootDocumentTemplates_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootEscalatedApproversArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

export type Query_RootEscalatedApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

export type Query_RootEscalatedApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootGeneratedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

export type Query_RootGeneratedDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

export type Query_RootGeneratedDocuments_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootLafActionChoicesArgs = {
  distinct_on?: InputMaybe<Array<LafActionChoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<LafActionChoices_Order_By>>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

export type Query_RootLafActionChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LafActionChoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<LafActionChoices_Order_By>>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

export type Query_RootLafActionChoices_By_PkArgs = {
  name: Scalars["String"];
};

export type Query_RootLeasesArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

export type Query_RootLeases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

export type Query_RootLeases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootOffersArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

export type Query_RootOffers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

export type Query_RootOffers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootPhaseActionItemArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

export type Query_RootPhaseActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

export type Query_RootPhaseActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootPhaseDealStatusArgs = {
  distinct_on?: InputMaybe<Array<PhaseDealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseDealStatus_Order_By>>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

export type Query_RootPhaseDealStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseDealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseDealStatus_Order_By>>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

export type Query_RootPhaseDealStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootPhasesArgs = {
  distinct_on?: InputMaybe<Array<Phases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Phases_Order_By>>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

export type Query_RootPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Phases_Order_By>>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

export type Query_RootPhases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootPropertiesArgs = {
  distinct_on?: InputMaybe<Array<Properties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Properties_Order_By>>;
  where?: InputMaybe<Properties_Bool_Exp>;
};

export type Query_RootPropertiesInformationArgs = {
  distinct_on?: InputMaybe<Array<PropertiesInformation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertiesInformation_Order_By>>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

export type Query_RootPropertiesInformation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertiesInformation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertiesInformation_Order_By>>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

export type Query_RootPropertiesInformation_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Properties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Properties_Order_By>>;
  where?: InputMaybe<Properties_Bool_Exp>;
};

export type Query_RootPropertyContactsArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

export type Query_RootPropertyContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

export type Query_RootPropertyContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootPropertyFileTypesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFileTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFileTypes_Order_By>>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

export type Query_RootPropertyFileTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFileTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFileTypes_Order_By>>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

export type Query_RootPropertyFileTypes_By_PkArgs = {
  name: Scalars["String"];
};

export type Query_RootPropertyFilesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

export type Query_RootPropertyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

export type Query_RootPropertyFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootProposalsArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

export type Query_RootProposals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

export type Query_RootProposals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

export type Query_RootRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

export type Query_RootRegistrations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootSalesTypeArgs = {
  distinct_on?: InputMaybe<Array<SalesType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<SalesType_Order_By>>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

export type Query_RootSalesType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<SalesType_Order_By>>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

export type Query_RootSalesType_By_PkArgs = {
  name: Scalars["String"];
};

export type Query_RootStatesArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

export type Query_RootStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

export type Query_RootStates_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTemplatePermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

export type Query_RootTemplatePermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

export type Query_RootTemplatePermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTemplateSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

export type Query_RootTemplateSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

export type Query_RootTemplateSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTenantTypesArgs = {
  distinct_on?: InputMaybe<Array<TenantTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TenantTypes_Order_By>>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

export type Query_RootTenantTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TenantTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TenantTypes_Order_By>>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

export type Query_RootTenantTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTermsArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

export type Query_RootTerms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

export type Query_RootTerms_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootTriSelectEnumArgs = {
  distinct_on?: InputMaybe<Array<TriSelectEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TriSelectEnum_Order_By>>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

export type Query_RootTriSelectEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TriSelectEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TriSelectEnum_Order_By>>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

export type Query_RootTriSelectEnum_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootUnitTypesArgs = {
  distinct_on?: InputMaybe<Array<UnitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UnitTypes_Order_By>>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

export type Query_RootUnitTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UnitTypes_Order_By>>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

export type Query_RootUnitTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootUnitsArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

export type Query_RootUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

export type Query_RootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

export type Query_RootUserStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

export type Query_RootUserStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootVirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

export type Query_RootVirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

export type Query_RootVirtualUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootYardiActiveLeasesArgs = {
  distinct_on?: InputMaybe<Array<YardiActiveLeases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiActiveLeases_Order_By>>;
  where?: InputMaybe<YardiActiveLeases_Bool_Exp>;
};

export type Query_RootYardiActiveLeases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiActiveLeases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiActiveLeases_Order_By>>;
  where?: InputMaybe<YardiActiveLeases_Bool_Exp>;
};

export type Query_RootYardiAmendmentsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

export type Query_RootYardiAmendments_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

export type Query_RootYardiAmendments_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

export type Query_RootYardiAmendments_Deals_By_PkArgs = {
  AmendmentCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Query_RootYardiAmendments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

export type Query_RootYardiAmendments_By_PkArgs = {
  AmendmentKey: Scalars["String"];
};

export type Query_RootYardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

export type Query_RootYardiContacts_ContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

export type Query_RootYardiContacts_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

export type Query_RootYardiContacts_Contacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  contact_id: Scalars["bigint"];
};

export type Query_RootYardiContacts_DealOwnersArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_DealOwners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_DealOwners_Order_By>>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

export type Query_RootYardiContacts_DealOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_DealOwners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_DealOwners_Order_By>>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

export type Query_RootYardiContacts_DealOwners_By_PkArgs = {
  ContactCode: Scalars["String"];
  user_id: Scalars["uuid"];
};

export type Query_RootYardiContacts_YardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

export type Query_RootYardiContacts_YardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

export type Query_RootYardiContacts_YardiProperties_By_PkArgs = {
  ContactCode: Scalars["String"];
  PropertyCode: Scalars["String"];
};

export type Query_RootYardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

export type Query_RootYardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
};

export type Query_RootYardiCustomersArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Order_By>>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

export type Query_RootYardiCustomers_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

export type Query_RootYardiCustomers_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

export type Query_RootYardiCustomers_Deals_By_PkArgs = {
  CustomerCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Query_RootYardiCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Order_By>>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

export type Query_RootYardiCustomers_By_PkArgs = {
  CustomerCode: Scalars["String"];
};

export type Query_RootYardiDealSyncStatusesArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

export type Query_RootYardiDealSyncStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

export type Query_RootYardiDealSyncStatuses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootYardiIngestionInfosArgs = {
  distinct_on?: InputMaybe<Array<YardiIngestionInfos_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiIngestionInfos_Order_By>>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

export type Query_RootYardiIngestionInfos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiIngestionInfos_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiIngestionInfos_Order_By>>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

export type Query_RootYardiIngestionInfos_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootYardiLeasesForRenewalArgs = {
  distinct_on?: InputMaybe<Array<YardiLeasesForRenewal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLeasesForRenewal_Order_By>>;
  where?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
};

export type Query_RootYardiLeasesForRenewal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiLeasesForRenewal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLeasesForRenewal_Order_By>>;
  where?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
};

export type Query_RootYardiLimitedCustomersArgs = {
  distinct_on?: InputMaybe<Array<YardiLimitedCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLimitedCustomers_Order_By>>;
  where?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
};

export type Query_RootYardiLimitedCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiLimitedCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLimitedCustomers_Order_By>>;
  where?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
};

export type Query_RootYardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiProperties_Order_By>>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

export type Query_RootYardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiProperties_Order_By>>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

export type Query_RootYardiProperties_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

export type Query_RootYardiPropertyProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiPropertyProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiPropertyProfiles_Order_By>>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

export type Query_RootYardiPropertyProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiPropertyProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiPropertyProfiles_Order_By>>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

export type Query_RootYardiPropertyProfiles_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

export type Query_RootYardiTenantToUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

export type Query_RootYardiTenantToUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

export type Query_RootYardiTenantToUnits_By_PkArgs = {
  TenantCode: Scalars["String"];
  UnitKey: Scalars["String"];
};

export type Query_RootYardiTenant_YardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenant_YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenant_YardiContacts_Order_By>>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

export type Query_RootYardiTenant_YardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenant_YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenant_YardiContacts_Order_By>>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

export type Query_RootYardiTenant_YardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  TenantCode: Scalars["String"];
};

export type Query_RootYardiTenantsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

export type Query_RootYardiTenants_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

export type Query_RootYardiTenants_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

export type Query_RootYardiTenants_Deals_By_PkArgs = {
  TenantCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Query_RootYardiTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

export type Query_RootYardiTenants_By_PkArgs = {
  TenantCode: Scalars["String"];
};

export type Query_RootYardiUnitProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

export type Query_RootYardiUnitProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

export type Query_RootYardiUnitProfiles_By_PkArgs = {
  UnitProfileKey: Scalars["String"];
};

export type Query_RootYardiUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

export type Query_RootYardiUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

export type Query_RootYardiUnits_By_PkArgs = {
  UnitKey: Scalars["String"];
};

export type Query_RootYardiVirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

export type Query_RootYardiVirtualUnits_VirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

export type Query_RootYardiVirtualUnits_VirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

export type Query_RootYardiVirtualUnits_VirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
  virtual_unit_id: Scalars["bigint"];
};

export type Query_RootYardiVirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

export type Query_RootYardiVirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
};

export type Query_RootChatArgs = {
  messages: Array<InputMaybe<ChatMessageInput>>;
  source?: InputMaybe<Scalars["String"]>;
};

export type Query_RootSearchDocumentsSearchArgs = {
  searchQuery: Scalars["String"];
};

export type SearchOutput = {
  __typename?: "searchOutput";
  centre: Scalars["String"];
  content: Scalars["String"];
  date: Scalars["String"];
  id: Scalars["String"];
  link: Scalars["String"];
  score: Scalars["Float"];
  title: Scalars["String"];
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** An array relationship */
  ActionAllocations: Array<ActionAllocations>;
  /** An aggregate relationship */
  ActionAllocations_aggregate: ActionAllocations_Aggregate;
  /** fetch data from the table: "ActionAllocations" using primary key columns */
  ActionAllocations_by_pk?: Maybe<ActionAllocations>;
  /** fetch data from the table in a streaming manner: "ActionAllocations" */
  ActionAllocations_stream: Array<ActionAllocations>;
  /** fetch data from the table: "ActionItems" */
  ActionItems: Array<ActionItems>;
  /** fetch aggregated fields from the table: "ActionItems" */
  ActionItems_aggregate: ActionItems_Aggregate;
  /** fetch data from the table: "ActionItems" using primary key columns */
  ActionItems_by_pk?: Maybe<ActionItems>;
  /** fetch data from the table in a streaming manner: "ActionItems" */
  ActionItems_stream: Array<ActionItems>;
  /** fetch data from the table: "ActivityLog" */
  ActivityLog: Array<ActivityLog>;
  /** fetch data from the table: "ActivityLogType" */
  ActivityLogType: Array<ActivityLogType>;
  /** fetch aggregated fields from the table: "ActivityLogType" */
  ActivityLogType_aggregate: ActivityLogType_Aggregate;
  /** fetch data from the table: "ActivityLogType" using primary key columns */
  ActivityLogType_by_pk?: Maybe<ActivityLogType>;
  /** fetch data from the table in a streaming manner: "ActivityLogType" */
  ActivityLogType_stream: Array<ActivityLogType>;
  /** fetch aggregated fields from the table: "ActivityLog" */
  ActivityLog_aggregate: ActivityLog_Aggregate;
  /** fetch data from the table: "ActivityLog" using primary key columns */
  ActivityLog_by_pk?: Maybe<ActivityLog>;
  /** fetch data from the table in a streaming manner: "ActivityLog" */
  ActivityLog_stream: Array<ActivityLog>;
  /** An array relationship */
  Agreements: Array<Agreements>;
  /** An aggregate relationship */
  Agreements_aggregate: Agreements_Aggregate;
  /** fetch data from the table: "Agreements" using primary key columns */
  Agreements_by_pk?: Maybe<Agreements>;
  /** fetch data from the table in a streaming manner: "Agreements" */
  Agreements_stream: Array<Agreements>;
  /** An array relationship */
  Approvers: Array<Approvers>;
  /** An aggregate relationship */
  Approvers_aggregate: Approvers_Aggregate;
  /** fetch data from the table: "Approvers" using primary key columns */
  Approvers_by_pk?: Maybe<Approvers>;
  /** fetch data from the table in a streaming manner: "Approvers" */
  Approvers_stream: Array<Approvers>;
  /** An array relationship */
  BaseLandlordServices: Array<BaseLandlordServices>;
  /** An aggregate relationship */
  BaseLandlordServices_aggregate: BaseLandlordServices_Aggregate;
  /** fetch data from the table: "BaseLandlordServices" using primary key columns */
  BaseLandlordServices_by_pk?: Maybe<BaseLandlordServices>;
  /** fetch data from the table in a streaming manner: "BaseLandlordServices" */
  BaseLandlordServices_stream: Array<BaseLandlordServices>;
  /** An array relationship */
  CenterApprovers: Array<CenterApprovers>;
  /** An aggregate relationship */
  CenterApprovers_aggregate: CenterApprovers_Aggregate;
  /** fetch data from the table: "CenterApprovers" using primary key columns */
  CenterApprovers_by_pk?: Maybe<CenterApprovers>;
  /** fetch data from the table in a streaming manner: "CenterApprovers" */
  CenterApprovers_stream: Array<CenterApprovers>;
  /** An array relationship */
  CommentTags: Array<CommentTags>;
  /** An aggregate relationship */
  CommentTags_aggregate: CommentTags_Aggregate;
  /** fetch data from the table: "CommentTags" using primary key columns */
  CommentTags_by_pk?: Maybe<CommentTags>;
  /** fetch data from the table in a streaming manner: "CommentTags" */
  CommentTags_stream: Array<CommentTags>;
  /** An array relationship */
  CommentUserTags: Array<CommentUserTags>;
  /** An aggregate relationship */
  CommentUserTags_aggregate: CommentUserTags_Aggregate;
  /** fetch data from the table: "CommentUserTags" using primary key columns */
  CommentUserTags_by_pk?: Maybe<CommentUserTags>;
  /** fetch data from the table in a streaming manner: "CommentUserTags" */
  CommentUserTags_stream: Array<CommentUserTags>;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "Comments" using primary key columns */
  Comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner: "Comments" */
  Comments_stream: Array<Comments>;
  /** An array relationship */
  Companies: Array<Companies>;
  /** An aggregate relationship */
  Companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "Companies" using primary key columns */
  Companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table in a streaming manner: "Companies" */
  Companies_stream: Array<Companies>;
  /** fetch data from the table: "ContactTypes" */
  ContactTypes: Array<ContactTypes>;
  /** fetch aggregated fields from the table: "ContactTypes" */
  ContactTypes_aggregate: ContactTypes_Aggregate;
  /** fetch data from the table: "ContactTypes" using primary key columns */
  ContactTypes_by_pk?: Maybe<ContactTypes>;
  /** fetch data from the table in a streaming manner: "ContactTypes" */
  ContactTypes_stream: Array<ContactTypes>;
  /** An array relationship */
  Contacts: Array<Contacts>;
  /** An aggregate relationship */
  Contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "Contacts" using primary key columns */
  Contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "Contacts" */
  Contacts_stream: Array<Contacts>;
  /** fetch data from the table: "Countries" */
  Countries: Array<Countries>;
  /** fetch aggregated fields from the table: "Countries" */
  Countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "Countries" using primary key columns */
  Countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "Countries" */
  Countries_stream: Array<Countries>;
  /** fetch data from the table: "CustomLicences" */
  CustomLicences: Array<CustomLicences>;
  /** fetch aggregated fields from the table: "CustomLicences" */
  CustomLicences_aggregate: CustomLicences_Aggregate;
  /** fetch data from the table: "CustomLicences" using primary key columns */
  CustomLicences_by_pk?: Maybe<CustomLicences>;
  /** fetch data from the table in a streaming manner: "CustomLicences" */
  CustomLicences_stream: Array<CustomLicences>;
  /** An array relationship */
  DealActionItem: Array<DealActionItem>;
  /** An aggregate relationship */
  DealActionItem_aggregate: DealActionItem_Aggregate;
  /** fetch data from the table: "DealActionItem" using primary key columns */
  DealActionItem_by_pk?: Maybe<DealActionItem>;
  /** fetch data from the table in a streaming manner: "DealActionItem" */
  DealActionItem_stream: Array<DealActionItem>;
  /** fetch data from the table: "DealBusinessContacts" */
  DealBusinessContacts: Array<DealBusinessContacts>;
  /** fetch aggregated fields from the table: "DealBusinessContacts" */
  DealBusinessContacts_aggregate: DealBusinessContacts_Aggregate;
  /** fetch data from the table: "DealBusinessContacts" using primary key columns */
  DealBusinessContacts_by_pk?: Maybe<DealBusinessContacts>;
  /** fetch data from the table in a streaming manner: "DealBusinessContacts" */
  DealBusinessContacts_stream: Array<DealBusinessContacts>;
  /** fetch data from the table: "DealContactsTags" */
  DealContactsTags: Array<DealContactsTags>;
  /** fetch aggregated fields from the table: "DealContactsTags" */
  DealContactsTags_aggregate: DealContactsTags_Aggregate;
  /** fetch data from the table: "DealContactsTags" using primary key columns */
  DealContactsTags_by_pk?: Maybe<DealContactsTags>;
  /** fetch data from the table in a streaming manner: "DealContactsTags" */
  DealContactsTags_stream: Array<DealContactsTags>;
  /** An array relationship */
  DealData: Array<DealData>;
  /** fetch data from the table: "DealDataCalculations" */
  DealDataCalculations: Array<DealDataCalculations>;
  /** fetch aggregated fields from the table: "DealDataCalculations" */
  DealDataCalculations_aggregate: DealDataCalculations_Aggregate;
  /** fetch data from the table in a streaming manner: "DealDataCalculations" */
  DealDataCalculations_stream: Array<DealDataCalculations>;
  /** An array relationship */
  DealDataCustomLicences: Array<DealDataCustomLicences>;
  /** An aggregate relationship */
  DealDataCustomLicences_aggregate: DealDataCustomLicences_Aggregate;
  /** fetch data from the table: "DealDataCustomLicences" using primary key columns */
  DealDataCustomLicences_by_pk?: Maybe<DealDataCustomLicences>;
  /** fetch data from the table in a streaming manner: "DealDataCustomLicences" */
  DealDataCustomLicences_stream: Array<DealDataCustomLicences>;
  /** An array relationship */
  DealDataGuarantors: Array<DealDataGuarantors>;
  /** An aggregate relationship */
  DealDataGuarantors_aggregate: DealDataGuarantors_Aggregate;
  /** fetch data from the table: "DealDataGuarantors" using primary key columns */
  DealDataGuarantors_by_pk?: Maybe<DealDataGuarantors>;
  /** fetch data from the table in a streaming manner: "DealDataGuarantors" */
  DealDataGuarantors_stream: Array<DealDataGuarantors>;
  /** An aggregate relationship */
  DealData_aggregate: DealData_Aggregate;
  /** fetch data from the table: "DealData" using primary key columns */
  DealData_by_pk?: Maybe<DealData>;
  /** fetch data from the table in a streaming manner: "DealData" */
  DealData_stream: Array<DealData>;
  /** An array relationship */
  DealExtensionItems: Array<DealExtensionItems>;
  /** An aggregate relationship */
  DealExtensionItems_aggregate: DealExtensionItems_Aggregate;
  /** fetch data from the table: "DealExtensionItems" using primary key columns */
  DealExtensionItems_by_pk?: Maybe<DealExtensionItems>;
  /** fetch data from the table in a streaming manner: "DealExtensionItems" */
  DealExtensionItems_stream: Array<DealExtensionItems>;
  /** fetch data from the table: "DealFileChunks" */
  DealFileChunks: Array<DealFileChunks>;
  /** fetch aggregated fields from the table: "DealFileChunks" */
  DealFileChunks_aggregate: DealFileChunks_Aggregate;
  /** fetch data from the table: "DealFileChunks" using primary key columns */
  DealFileChunks_by_pk?: Maybe<DealFileChunks>;
  /** fetch data from the table in a streaming manner: "DealFileChunks" */
  DealFileChunks_stream: Array<DealFileChunks>;
  /** An array relationship */
  DealFileTags: Array<DealFileTags>;
  /** An aggregate relationship */
  DealFileTags_aggregate: DealFileTags_Aggregate;
  /** fetch data from the table: "DealFileTags" using primary key columns */
  DealFileTags_by_pk?: Maybe<DealFileTags>;
  /** fetch data from the table in a streaming manner: "DealFileTags" */
  DealFileTags_stream: Array<DealFileTags>;
  /** An array relationship */
  DealFiles: Array<DealFiles>;
  /** An aggregate relationship */
  DealFiles_aggregate: DealFiles_Aggregate;
  /** fetch data from the table: "DealFiles" using primary key columns */
  DealFiles_by_pk?: Maybe<DealFiles>;
  /** fetch data from the table in a streaming manner: "DealFiles" */
  DealFiles_stream: Array<DealFiles>;
  /** An array relationship */
  DealFitoutLandlordWorks: Array<DealFitoutLandlordWorks>;
  /** An aggregate relationship */
  DealFitoutLandlordWorks_aggregate: DealFitoutLandlordWorks_Aggregate;
  /** fetch data from the table: "DealFitoutLandlordWorks" using primary key columns */
  DealFitoutLandlordWorks_by_pk?: Maybe<DealFitoutLandlordWorks>;
  /** fetch data from the table in a streaming manner: "DealFitoutLandlordWorks" */
  DealFitoutLandlordWorks_stream: Array<DealFitoutLandlordWorks>;
  /** An array relationship */
  DealLAFApprovals: Array<DealLafApprovals>;
  /** An aggregate relationship */
  DealLAFApprovals_aggregate: DealLafApprovals_Aggregate;
  /** fetch data from the table: "DealLAFApprovals" using primary key columns */
  DealLAFApprovals_by_pk?: Maybe<DealLafApprovals>;
  /** fetch data from the table in a streaming manner: "DealLAFApprovals" */
  DealLAFApprovals_stream: Array<DealLafApprovals>;
  /** An array relationship */
  DealLinks: Array<DealLinks>;
  /** An aggregate relationship */
  DealLinks_aggregate: DealLinks_Aggregate;
  /** fetch data from the table: "DealLinks" using primary key columns */
  DealLinks_by_pk?: Maybe<DealLinks>;
  /** fetch data from the table in a streaming manner: "DealLinks" */
  DealLinks_stream: Array<DealLinks>;
  /** An array relationship */
  DealOptions: Array<DealOptions>;
  /** An aggregate relationship */
  DealOptions_aggregate: DealOptions_Aggregate;
  /** fetch data from the table: "DealOptions" using primary key columns */
  DealOptions_by_pk?: Maybe<DealOptions>;
  /** fetch data from the table in a streaming manner: "DealOptions" */
  DealOptions_stream: Array<DealOptions>;
  /** An array relationship */
  DealPermittedUses: Array<DealPermittedUses>;
  /** An aggregate relationship */
  DealPermittedUses_aggregate: DealPermittedUses_Aggregate;
  /** fetch data from the table: "DealPermittedUses" using primary key columns */
  DealPermittedUses_by_pk?: Maybe<DealPermittedUses>;
  /** fetch data from the table in a streaming manner: "DealPermittedUses" */
  DealPermittedUses_stream: Array<DealPermittedUses>;
  /** An array relationship */
  DealPhases: Array<DealPhases>;
  /** An aggregate relationship */
  DealPhases_aggregate: DealPhases_Aggregate;
  /** fetch data from the table: "DealPhases" using primary key columns */
  DealPhases_by_pk?: Maybe<DealPhases>;
  /** fetch data from the table in a streaming manner: "DealPhases" */
  DealPhases_stream: Array<DealPhases>;
  /** fetch data from the table: "DealRiskLevels" */
  DealRiskLevels: Array<DealRiskLevels>;
  /** fetch aggregated fields from the table: "DealRiskLevels" */
  DealRiskLevels_aggregate: DealRiskLevels_Aggregate;
  /** fetch data from the table: "DealRiskLevels" using primary key columns */
  DealRiskLevels_by_pk?: Maybe<DealRiskLevels>;
  /** fetch data from the table in a streaming manner: "DealRiskLevels" */
  DealRiskLevels_stream: Array<DealRiskLevels>;
  /** An array relationship */
  DealShopActivities: Array<DealShopActivities>;
  /** An aggregate relationship */
  DealShopActivities_aggregate: DealShopActivities_Aggregate;
  /** fetch data from the table: "DealShopActivities" using primary key columns */
  DealShopActivities_by_pk?: Maybe<DealShopActivities>;
  /** fetch data from the table in a streaming manner: "DealShopActivities" */
  DealShopActivities_stream: Array<DealShopActivities>;
  /** fetch data from the table: "DealShopActivityTypes" */
  DealShopActivityTypes: Array<DealShopActivityTypes>;
  /** fetch aggregated fields from the table: "DealShopActivityTypes" */
  DealShopActivityTypes_aggregate: DealShopActivityTypes_Aggregate;
  /** fetch data from the table: "DealShopActivityTypes" using primary key columns */
  DealShopActivityTypes_by_pk?: Maybe<DealShopActivityTypes>;
  /** fetch data from the table in a streaming manner: "DealShopActivityTypes" */
  DealShopActivityTypes_stream: Array<DealShopActivityTypes>;
  /** An array relationship */
  DealSpecialConditions: Array<DealSpecialConditions>;
  /** An aggregate relationship */
  DealSpecialConditions_aggregate: DealSpecialConditions_Aggregate;
  /** fetch data from the table: "DealSpecialConditions" using primary key columns */
  DealSpecialConditions_by_pk?: Maybe<DealSpecialConditions>;
  /** fetch data from the table in a streaming manner: "DealSpecialConditions" */
  DealSpecialConditions_stream: Array<DealSpecialConditions>;
  /** fetch data from the table: "DealStatus" */
  DealStatus: Array<DealStatus>;
  /** fetch aggregated fields from the table: "DealStatus" */
  DealStatus_aggregate: DealStatus_Aggregate;
  /** fetch data from the table: "DealStatus" using primary key columns */
  DealStatus_by_pk?: Maybe<DealStatus>;
  /** fetch data from the table in a streaming manner: "DealStatus" */
  DealStatus_stream: Array<DealStatus>;
  /** fetch data from the table: "DealTypes" */
  DealTypes: Array<DealTypes>;
  /** fetch aggregated fields from the table: "DealTypes" */
  DealTypes_aggregate: DealTypes_Aggregate;
  /** fetch data from the table: "DealTypes" using primary key columns */
  DealTypes_by_pk?: Maybe<DealTypes>;
  /** fetch data from the table in a streaming manner: "DealTypes" */
  DealTypes_stream: Array<DealTypes>;
  /** An array relationship */
  Deals: Array<Deals>;
  /** An array relationship */
  DealsCompanies: Array<DealsCompanies>;
  /** An aggregate relationship */
  DealsCompanies_aggregate: DealsCompanies_Aggregate;
  /** fetch data from the table: "DealsCompanies" using primary key columns */
  DealsCompanies_by_pk?: Maybe<DealsCompanies>;
  /** fetch data from the table in a streaming manner: "DealsCompanies" */
  DealsCompanies_stream: Array<DealsCompanies>;
  /** An array relationship */
  DealsContacts: Array<DealsContacts>;
  /** An aggregate relationship */
  DealsContacts_aggregate: DealsContacts_Aggregate;
  /** fetch data from the table: "DealsContacts" using primary key columns */
  DealsContacts_by_pk?: Maybe<DealsContacts>;
  /** fetch data from the table in a streaming manner: "DealsContacts" */
  DealsContacts_stream: Array<DealsContacts>;
  /** An array relationship */
  DealsUnits: Array<DealsUnits>;
  /** An aggregate relationship */
  DealsUnits_aggregate: DealsUnits_Aggregate;
  /** fetch data from the table: "DealsUnits" using primary key columns */
  DealsUnits_by_pk?: Maybe<DealsUnits>;
  /** fetch data from the table in a streaming manner: "DealsUnits" */
  DealsUnits_stream: Array<DealsUnits>;
  /** An aggregate relationship */
  Deals_aggregate: Deals_Aggregate;
  /** fetch data from the table: "Deals" using primary key columns */
  Deals_by_pk?: Maybe<Deals>;
  /** fetch data from the table in a streaming manner: "Deals" */
  Deals_stream: Array<Deals>;
  /** An array relationship */
  DocumentRequests: Array<DocumentRequests>;
  /** An aggregate relationship */
  DocumentRequests_aggregate: DocumentRequests_Aggregate;
  /** fetch data from the table: "DocumentRequests" using primary key columns */
  DocumentRequests_by_pk?: Maybe<DocumentRequests>;
  /** fetch data from the table in a streaming manner: "DocumentRequests" */
  DocumentRequests_stream: Array<DocumentRequests>;
  /** An array relationship */
  DocumentTemplates: Array<DocumentTemplates>;
  /** An aggregate relationship */
  DocumentTemplates_aggregate: DocumentTemplates_Aggregate;
  /** fetch data from the table: "DocumentTemplates" using primary key columns */
  DocumentTemplates_by_pk?: Maybe<DocumentTemplates>;
  /** fetch data from the table in a streaming manner: "DocumentTemplates" */
  DocumentTemplates_stream: Array<DocumentTemplates>;
  /** An array relationship */
  EscalatedApprovers: Array<EscalatedApprovers>;
  /** An aggregate relationship */
  EscalatedApprovers_aggregate: EscalatedApprovers_Aggregate;
  /** fetch data from the table: "EscalatedApprovers" using primary key columns */
  EscalatedApprovers_by_pk?: Maybe<EscalatedApprovers>;
  /** fetch data from the table in a streaming manner: "EscalatedApprovers" */
  EscalatedApprovers_stream: Array<EscalatedApprovers>;
  /** An array relationship */
  GeneratedDocuments: Array<GeneratedDocuments>;
  /** An aggregate relationship */
  GeneratedDocuments_aggregate: GeneratedDocuments_Aggregate;
  /** fetch data from the table: "GeneratedDocuments" using primary key columns */
  GeneratedDocuments_by_pk?: Maybe<GeneratedDocuments>;
  /** fetch data from the table in a streaming manner: "GeneratedDocuments" */
  GeneratedDocuments_stream: Array<GeneratedDocuments>;
  /** fetch data from the table: "LafActionChoices" */
  LafActionChoices: Array<LafActionChoices>;
  /** fetch aggregated fields from the table: "LafActionChoices" */
  LafActionChoices_aggregate: LafActionChoices_Aggregate;
  /** fetch data from the table: "LafActionChoices" using primary key columns */
  LafActionChoices_by_pk?: Maybe<LafActionChoices>;
  /** fetch data from the table in a streaming manner: "LafActionChoices" */
  LafActionChoices_stream: Array<LafActionChoices>;
  /** An array relationship */
  Leases: Array<Leases>;
  /** An aggregate relationship */
  Leases_aggregate: Leases_Aggregate;
  /** fetch data from the table: "Leases" using primary key columns */
  Leases_by_pk?: Maybe<Leases>;
  /** fetch data from the table in a streaming manner: "Leases" */
  Leases_stream: Array<Leases>;
  /** An array relationship */
  Locations: Array<Locations>;
  /** An aggregate relationship */
  Locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "Locations" using primary key columns */
  Locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "Locations" */
  Locations_stream: Array<Locations>;
  /** An array relationship */
  Offers: Array<Offers>;
  /** An aggregate relationship */
  Offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "Offers" using primary key columns */
  Offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table in a streaming manner: "Offers" */
  Offers_stream: Array<Offers>;
  /** An array relationship */
  PhaseActionItem: Array<PhaseActionItem>;
  /** An aggregate relationship */
  PhaseActionItem_aggregate: PhaseActionItem_Aggregate;
  /** fetch data from the table: "PhaseActionItem" using primary key columns */
  PhaseActionItem_by_pk?: Maybe<PhaseActionItem>;
  /** fetch data from the table in a streaming manner: "PhaseActionItem" */
  PhaseActionItem_stream: Array<PhaseActionItem>;
  /** fetch data from the table: "PhaseDealStatus" */
  PhaseDealStatus: Array<PhaseDealStatus>;
  /** fetch aggregated fields from the table: "PhaseDealStatus" */
  PhaseDealStatus_aggregate: PhaseDealStatus_Aggregate;
  /** fetch data from the table: "PhaseDealStatus" using primary key columns */
  PhaseDealStatus_by_pk?: Maybe<PhaseDealStatus>;
  /** fetch data from the table in a streaming manner: "PhaseDealStatus" */
  PhaseDealStatus_stream: Array<PhaseDealStatus>;
  /** fetch data from the table: "Phases" */
  Phases: Array<Phases>;
  /** fetch aggregated fields from the table: "Phases" */
  Phases_aggregate: Phases_Aggregate;
  /** fetch data from the table: "Phases" using primary key columns */
  Phases_by_pk?: Maybe<Phases>;
  /** fetch data from the table in a streaming manner: "Phases" */
  Phases_stream: Array<Phases>;
  /** fetch data from the table: "Properties" */
  Properties: Array<Properties>;
  /** fetch data from the table: "PropertiesInformation" */
  PropertiesInformation: Array<PropertiesInformation>;
  /** fetch aggregated fields from the table: "PropertiesInformation" */
  PropertiesInformation_aggregate: PropertiesInformation_Aggregate;
  /** fetch data from the table: "PropertiesInformation" using primary key columns */
  PropertiesInformation_by_pk?: Maybe<PropertiesInformation>;
  /** fetch data from the table in a streaming manner: "PropertiesInformation" */
  PropertiesInformation_stream: Array<PropertiesInformation>;
  /** fetch aggregated fields from the table: "Properties" */
  Properties_aggregate: Properties_Aggregate;
  /** fetch data from the table in a streaming manner: "Properties" */
  Properties_stream: Array<Properties>;
  /** An array relationship */
  PropertyContacts: Array<PropertyContacts>;
  /** An aggregate relationship */
  PropertyContacts_aggregate: PropertyContacts_Aggregate;
  /** fetch data from the table: "PropertyContacts" using primary key columns */
  PropertyContacts_by_pk?: Maybe<PropertyContacts>;
  /** fetch data from the table in a streaming manner: "PropertyContacts" */
  PropertyContacts_stream: Array<PropertyContacts>;
  /** fetch data from the table: "PropertyFileTypes" */
  PropertyFileTypes: Array<PropertyFileTypes>;
  /** fetch aggregated fields from the table: "PropertyFileTypes" */
  PropertyFileTypes_aggregate: PropertyFileTypes_Aggregate;
  /** fetch data from the table: "PropertyFileTypes" using primary key columns */
  PropertyFileTypes_by_pk?: Maybe<PropertyFileTypes>;
  /** fetch data from the table in a streaming manner: "PropertyFileTypes" */
  PropertyFileTypes_stream: Array<PropertyFileTypes>;
  /** An array relationship */
  PropertyFiles: Array<PropertyFiles>;
  /** An aggregate relationship */
  PropertyFiles_aggregate: PropertyFiles_Aggregate;
  /** fetch data from the table: "PropertyFiles" using primary key columns */
  PropertyFiles_by_pk?: Maybe<PropertyFiles>;
  /** fetch data from the table in a streaming manner: "PropertyFiles" */
  PropertyFiles_stream: Array<PropertyFiles>;
  /** An array relationship */
  Proposals: Array<Proposals>;
  /** An aggregate relationship */
  Proposals_aggregate: Proposals_Aggregate;
  /** fetch data from the table: "Proposals" using primary key columns */
  Proposals_by_pk?: Maybe<Proposals>;
  /** fetch data from the table in a streaming manner: "Proposals" */
  Proposals_stream: Array<Proposals>;
  /** An array relationship */
  Registrations: Array<Registrations>;
  /** An aggregate relationship */
  Registrations_aggregate: Registrations_Aggregate;
  /** fetch data from the table: "Registrations" using primary key columns */
  Registrations_by_pk?: Maybe<Registrations>;
  /** fetch data from the table in a streaming manner: "Registrations" */
  Registrations_stream: Array<Registrations>;
  /** fetch data from the table: "SalesType" */
  SalesType: Array<SalesType>;
  /** fetch aggregated fields from the table: "SalesType" */
  SalesType_aggregate: SalesType_Aggregate;
  /** fetch data from the table: "SalesType" using primary key columns */
  SalesType_by_pk?: Maybe<SalesType>;
  /** fetch data from the table in a streaming manner: "SalesType" */
  SalesType_stream: Array<SalesType>;
  /** An array relationship */
  States: Array<States>;
  /** An aggregate relationship */
  States_aggregate: States_Aggregate;
  /** fetch data from the table: "States" using primary key columns */
  States_by_pk?: Maybe<States>;
  /** fetch data from the table in a streaming manner: "States" */
  States_stream: Array<States>;
  /** fetch data from the table: "Tags" */
  Tags: Array<Tags>;
  /** fetch aggregated fields from the table: "Tags" */
  Tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "Tags" using primary key columns */
  Tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "Tags" */
  Tags_stream: Array<Tags>;
  /** An array relationship */
  TemplatePermittedUses: Array<TemplatePermittedUses>;
  /** An aggregate relationship */
  TemplatePermittedUses_aggregate: TemplatePermittedUses_Aggregate;
  /** fetch data from the table: "TemplatePermittedUses" using primary key columns */
  TemplatePermittedUses_by_pk?: Maybe<TemplatePermittedUses>;
  /** fetch data from the table in a streaming manner: "TemplatePermittedUses" */
  TemplatePermittedUses_stream: Array<TemplatePermittedUses>;
  /** An array relationship */
  TemplateSpecialConditions: Array<TemplateSpecialConditions>;
  /** An aggregate relationship */
  TemplateSpecialConditions_aggregate: TemplateSpecialConditions_Aggregate;
  /** fetch data from the table: "TemplateSpecialConditions" using primary key columns */
  TemplateSpecialConditions_by_pk?: Maybe<TemplateSpecialConditions>;
  /** fetch data from the table in a streaming manner: "TemplateSpecialConditions" */
  TemplateSpecialConditions_stream: Array<TemplateSpecialConditions>;
  /** fetch data from the table: "TenantTypes" */
  TenantTypes: Array<TenantTypes>;
  /** fetch aggregated fields from the table: "TenantTypes" */
  TenantTypes_aggregate: TenantTypes_Aggregate;
  /** fetch data from the table: "TenantTypes" using primary key columns */
  TenantTypes_by_pk?: Maybe<TenantTypes>;
  /** fetch data from the table in a streaming manner: "TenantTypes" */
  TenantTypes_stream: Array<TenantTypes>;
  /** An array relationship */
  Terms: Array<Terms>;
  /** An aggregate relationship */
  Terms_aggregate: Terms_Aggregate;
  /** fetch data from the table: "Terms" using primary key columns */
  Terms_by_pk?: Maybe<Terms>;
  /** fetch data from the table in a streaming manner: "Terms" */
  Terms_stream: Array<Terms>;
  /** fetch data from the table: "TriSelectEnum" */
  TriSelectEnum: Array<TriSelectEnum>;
  /** fetch aggregated fields from the table: "TriSelectEnum" */
  TriSelectEnum_aggregate: TriSelectEnum_Aggregate;
  /** fetch data from the table: "TriSelectEnum" using primary key columns */
  TriSelectEnum_by_pk?: Maybe<TriSelectEnum>;
  /** fetch data from the table in a streaming manner: "TriSelectEnum" */
  TriSelectEnum_stream: Array<TriSelectEnum>;
  /** fetch data from the table: "UnitTypes" */
  UnitTypes: Array<UnitTypes>;
  /** fetch aggregated fields from the table: "UnitTypes" */
  UnitTypes_aggregate: UnitTypes_Aggregate;
  /** fetch data from the table: "UnitTypes" using primary key columns */
  UnitTypes_by_pk?: Maybe<UnitTypes>;
  /** fetch data from the table in a streaming manner: "UnitTypes" */
  UnitTypes_stream: Array<UnitTypes>;
  /** fetch data from the table: "Units" */
  Units: Array<Units>;
  /** fetch aggregated fields from the table: "Units" */
  Units_aggregate: Units_Aggregate;
  /** fetch data from the table in a streaming manner: "Units" */
  Units_stream: Array<Units>;
  /** fetch data from the table: "UserStatus" */
  UserStatus: Array<UserStatus>;
  /** fetch aggregated fields from the table: "UserStatus" */
  UserStatus_aggregate: UserStatus_Aggregate;
  /** fetch data from the table: "UserStatus" using primary key columns */
  UserStatus_by_pk?: Maybe<UserStatus>;
  /** fetch data from the table in a streaming manner: "UserStatus" */
  UserStatus_stream: Array<UserStatus>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "Users" */
  Users_stream: Array<Users>;
  /** fetch data from the table: "VirtualUnits" */
  VirtualUnits: Array<VirtualUnits>;
  /** fetch aggregated fields from the table: "VirtualUnits" */
  VirtualUnits_aggregate: VirtualUnits_Aggregate;
  /** fetch data from the table: "VirtualUnits" using primary key columns */
  VirtualUnits_by_pk?: Maybe<VirtualUnits>;
  /** fetch data from the table in a streaming manner: "VirtualUnits" */
  VirtualUnits_stream: Array<VirtualUnits>;
  /** fetch data from the table: "YardiActiveLeases" */
  YardiActiveLeases: Array<YardiActiveLeases>;
  /** fetch aggregated fields from the table: "YardiActiveLeases" */
  YardiActiveLeases_aggregate: YardiActiveLeases_Aggregate;
  /** fetch data from the table in a streaming manner: "YardiActiveLeases" */
  YardiActiveLeases_stream: Array<YardiActiveLeases>;
  /** An array relationship */
  YardiAmendments: Array<YardiAmendments>;
  /** An array relationship */
  YardiAmendments_Deals: Array<YardiAmendments_Deals>;
  /** An aggregate relationship */
  YardiAmendments_Deals_aggregate: YardiAmendments_Deals_Aggregate;
  /** fetch data from the table: "YardiAmendments_Deals" using primary key columns */
  YardiAmendments_Deals_by_pk?: Maybe<YardiAmendments_Deals>;
  /** fetch data from the table in a streaming manner: "YardiAmendments_Deals" */
  YardiAmendments_Deals_stream: Array<YardiAmendments_Deals>;
  /** An aggregate relationship */
  YardiAmendments_aggregate: YardiAmendments_Aggregate;
  /** fetch data from the table: "YardiAmendments" using primary key columns */
  YardiAmendments_by_pk?: Maybe<YardiAmendments>;
  /** fetch data from the table in a streaming manner: "YardiAmendments" */
  YardiAmendments_stream: Array<YardiAmendments>;
  /** An array relationship */
  YardiContacts: Array<YardiContacts>;
  /** An array relationship */
  YardiContacts_Contacts: Array<YardiContacts_Contacts>;
  /** An aggregate relationship */
  YardiContacts_Contacts_aggregate: YardiContacts_Contacts_Aggregate;
  /** fetch data from the table: "YardiContacts_Contacts" using primary key columns */
  YardiContacts_Contacts_by_pk?: Maybe<YardiContacts_Contacts>;
  /** fetch data from the table in a streaming manner: "YardiContacts_Contacts" */
  YardiContacts_Contacts_stream: Array<YardiContacts_Contacts>;
  /** fetch data from the table: "YardiContacts_DealOwners" */
  YardiContacts_DealOwners: Array<YardiContacts_DealOwners>;
  /** fetch aggregated fields from the table: "YardiContacts_DealOwners" */
  YardiContacts_DealOwners_aggregate: YardiContacts_DealOwners_Aggregate;
  /** fetch data from the table: "YardiContacts_DealOwners" using primary key columns */
  YardiContacts_DealOwners_by_pk?: Maybe<YardiContacts_DealOwners>;
  /** fetch data from the table in a streaming manner: "YardiContacts_DealOwners" */
  YardiContacts_DealOwners_stream: Array<YardiContacts_DealOwners>;
  /** An array relationship */
  YardiContacts_YardiProperties: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_YardiProperties_aggregate: YardiContacts_YardiProperties_Aggregate;
  /** fetch data from the table: "YardiContacts_YardiProperties" using primary key columns */
  YardiContacts_YardiProperties_by_pk?: Maybe<YardiContacts_YardiProperties>;
  /** fetch data from the table in a streaming manner: "YardiContacts_YardiProperties" */
  YardiContacts_YardiProperties_stream: Array<YardiContacts_YardiProperties>;
  /** An aggregate relationship */
  YardiContacts_aggregate: YardiContacts_Aggregate;
  /** fetch data from the table: "YardiContacts" using primary key columns */
  YardiContacts_by_pk?: Maybe<YardiContacts>;
  /** fetch data from the table in a streaming manner: "YardiContacts" */
  YardiContacts_stream: Array<YardiContacts>;
  /** fetch data from the table: "YardiCustomers" */
  YardiCustomers: Array<YardiCustomers>;
  /** An array relationship */
  YardiCustomers_Deals: Array<YardiCustomers_Deals>;
  /** An aggregate relationship */
  YardiCustomers_Deals_aggregate: YardiCustomers_Deals_Aggregate;
  /** fetch data from the table: "YardiCustomers_Deals" using primary key columns */
  YardiCustomers_Deals_by_pk?: Maybe<YardiCustomers_Deals>;
  /** fetch data from the table in a streaming manner: "YardiCustomers_Deals" */
  YardiCustomers_Deals_stream: Array<YardiCustomers_Deals>;
  /** fetch aggregated fields from the table: "YardiCustomers" */
  YardiCustomers_aggregate: YardiCustomers_Aggregate;
  /** fetch data from the table: "YardiCustomers" using primary key columns */
  YardiCustomers_by_pk?: Maybe<YardiCustomers>;
  /** fetch data from the table in a streaming manner: "YardiCustomers" */
  YardiCustomers_stream: Array<YardiCustomers>;
  /** An array relationship */
  YardiDealSyncStatuses: Array<YardiDealSyncStatuses>;
  /** An aggregate relationship */
  YardiDealSyncStatuses_aggregate: YardiDealSyncStatuses_Aggregate;
  /** fetch data from the table: "YardiDealSyncStatuses" using primary key columns */
  YardiDealSyncStatuses_by_pk?: Maybe<YardiDealSyncStatuses>;
  /** fetch data from the table in a streaming manner: "YardiDealSyncStatuses" */
  YardiDealSyncStatuses_stream: Array<YardiDealSyncStatuses>;
  /** fetch data from the table: "YardiIngestionInfos" */
  YardiIngestionInfos: Array<YardiIngestionInfos>;
  /** fetch aggregated fields from the table: "YardiIngestionInfos" */
  YardiIngestionInfos_aggregate: YardiIngestionInfos_Aggregate;
  /** fetch data from the table: "YardiIngestionInfos" using primary key columns */
  YardiIngestionInfos_by_pk?: Maybe<YardiIngestionInfos>;
  /** fetch data from the table in a streaming manner: "YardiIngestionInfos" */
  YardiIngestionInfos_stream: Array<YardiIngestionInfos>;
  /** fetch data from the table: "YardiLeasesForRenewal" */
  YardiLeasesForRenewal: Array<YardiLeasesForRenewal>;
  /** fetch aggregated fields from the table: "YardiLeasesForRenewal" */
  YardiLeasesForRenewal_aggregate: YardiLeasesForRenewal_Aggregate;
  /** fetch data from the table in a streaming manner: "YardiLeasesForRenewal" */
  YardiLeasesForRenewal_stream: Array<YardiLeasesForRenewal>;
  /** fetch data from the table: "YardiLimitedCustomers" */
  YardiLimitedCustomers: Array<YardiLimitedCustomers>;
  /** fetch aggregated fields from the table: "YardiLimitedCustomers" */
  YardiLimitedCustomers_aggregate: YardiLimitedCustomers_Aggregate;
  /** fetch data from the table in a streaming manner: "YardiLimitedCustomers" */
  YardiLimitedCustomers_stream: Array<YardiLimitedCustomers>;
  /** fetch data from the table: "YardiProperties" */
  YardiProperties: Array<YardiProperties>;
  /** fetch aggregated fields from the table: "YardiProperties" */
  YardiProperties_aggregate: YardiProperties_Aggregate;
  /** fetch data from the table: "YardiProperties" using primary key columns */
  YardiProperties_by_pk?: Maybe<YardiProperties>;
  /** fetch data from the table in a streaming manner: "YardiProperties" */
  YardiProperties_stream: Array<YardiProperties>;
  /** fetch data from the table: "YardiPropertyProfiles" */
  YardiPropertyProfiles: Array<YardiPropertyProfiles>;
  /** fetch aggregated fields from the table: "YardiPropertyProfiles" */
  YardiPropertyProfiles_aggregate: YardiPropertyProfiles_Aggregate;
  /** fetch data from the table: "YardiPropertyProfiles" using primary key columns */
  YardiPropertyProfiles_by_pk?: Maybe<YardiPropertyProfiles>;
  /** fetch data from the table in a streaming manner: "YardiPropertyProfiles" */
  YardiPropertyProfiles_stream: Array<YardiPropertyProfiles>;
  /** An array relationship */
  YardiTenantToUnits: Array<YardiTenantToUnits>;
  /** An aggregate relationship */
  YardiTenantToUnits_aggregate: YardiTenantToUnits_Aggregate;
  /** fetch data from the table: "YardiTenantToUnits" using primary key columns */
  YardiTenantToUnits_by_pk?: Maybe<YardiTenantToUnits>;
  /** fetch data from the table in a streaming manner: "YardiTenantToUnits" */
  YardiTenantToUnits_stream: Array<YardiTenantToUnits>;
  /** fetch data from the table: "YardiTenant_YardiContacts" */
  YardiTenant_YardiContacts: Array<YardiTenant_YardiContacts>;
  /** fetch aggregated fields from the table: "YardiTenant_YardiContacts" */
  YardiTenant_YardiContacts_aggregate: YardiTenant_YardiContacts_Aggregate;
  /** fetch data from the table: "YardiTenant_YardiContacts" using primary key columns */
  YardiTenant_YardiContacts_by_pk?: Maybe<YardiTenant_YardiContacts>;
  /** fetch data from the table in a streaming manner: "YardiTenant_YardiContacts" */
  YardiTenant_YardiContacts_stream: Array<YardiTenant_YardiContacts>;
  /** An array relationship */
  YardiTenants: Array<YardiTenants>;
  /** An array relationship */
  YardiTenants_Deals: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_Deals_aggregate: YardiTenants_Deals_Aggregate;
  /** fetch data from the table: "YardiTenants_Deals" using primary key columns */
  YardiTenants_Deals_by_pk?: Maybe<YardiTenants_Deals>;
  /** fetch data from the table in a streaming manner: "YardiTenants_Deals" */
  YardiTenants_Deals_stream: Array<YardiTenants_Deals>;
  /** An aggregate relationship */
  YardiTenants_aggregate: YardiTenants_Aggregate;
  /** fetch data from the table: "YardiTenants" using primary key columns */
  YardiTenants_by_pk?: Maybe<YardiTenants>;
  /** fetch data from the table in a streaming manner: "YardiTenants" */
  YardiTenants_stream: Array<YardiTenants>;
  /** An array relationship */
  YardiUnitProfiles: Array<YardiUnitProfiles>;
  /** An aggregate relationship */
  YardiUnitProfiles_aggregate: YardiUnitProfiles_Aggregate;
  /** fetch data from the table: "YardiUnitProfiles" using primary key columns */
  YardiUnitProfiles_by_pk?: Maybe<YardiUnitProfiles>;
  /** fetch data from the table in a streaming manner: "YardiUnitProfiles" */
  YardiUnitProfiles_stream: Array<YardiUnitProfiles>;
  /** An array relationship */
  YardiUnits: Array<YardiUnits>;
  /** An aggregate relationship */
  YardiUnits_aggregate: YardiUnits_Aggregate;
  /** fetch data from the table: "YardiUnits" using primary key columns */
  YardiUnits_by_pk?: Maybe<YardiUnits>;
  /** fetch data from the table in a streaming manner: "YardiUnits" */
  YardiUnits_stream: Array<YardiUnits>;
  /** An array relationship */
  YardiVirtualUnits: Array<YardiVirtualUnits>;
  /** fetch data from the table: "YardiVirtualUnits_VirtualUnits" */
  YardiVirtualUnits_VirtualUnits: Array<YardiVirtualUnits_VirtualUnits>;
  /** fetch aggregated fields from the table: "YardiVirtualUnits_VirtualUnits" */
  YardiVirtualUnits_VirtualUnits_aggregate: YardiVirtualUnits_VirtualUnits_Aggregate;
  /** fetch data from the table: "YardiVirtualUnits_VirtualUnits" using primary key columns */
  YardiVirtualUnits_VirtualUnits_by_pk?: Maybe<YardiVirtualUnits_VirtualUnits>;
  /** fetch data from the table in a streaming manner: "YardiVirtualUnits_VirtualUnits" */
  YardiVirtualUnits_VirtualUnits_stream: Array<YardiVirtualUnits_VirtualUnits>;
  /** An aggregate relationship */
  YardiVirtualUnits_aggregate: YardiVirtualUnits_Aggregate;
  /** fetch data from the table: "YardiVirtualUnits" using primary key columns */
  YardiVirtualUnits_by_pk?: Maybe<YardiVirtualUnits>;
  /** fetch data from the table in a streaming manner: "YardiVirtualUnits" */
  YardiVirtualUnits_stream: Array<YardiVirtualUnits>;
};

export type Subscription_RootActionAllocationsArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

export type Subscription_RootActionAllocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAllocations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionAllocations_Order_By>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

export type Subscription_RootActionAllocations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootActionAllocations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<ActionAllocations_Stream_Cursor_Input>>;
  where?: InputMaybe<ActionAllocations_Bool_Exp>;
};

export type Subscription_RootActionItemsArgs = {
  distinct_on?: InputMaybe<Array<ActionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionItems_Order_By>>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

export type Subscription_RootActionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActionItems_Order_By>>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

export type Subscription_RootActionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootActionItems_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<ActionItems_Stream_Cursor_Input>>;
  where?: InputMaybe<ActionItems_Bool_Exp>;
};

export type Subscription_RootActivityLogArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

export type Subscription_RootActivityLogTypeArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLogType_Order_By>>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

export type Subscription_RootActivityLogType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLogType_Order_By>>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

export type Subscription_RootActivityLogType_By_PkArgs = {
  log_type: Scalars["String"];
};

export type Subscription_RootActivityLogType_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<ActivityLogType_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivityLogType_Bool_Exp>;
};

export type Subscription_RootActivityLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

export type Subscription_RootActivityLog_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootActivityLog_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<ActivityLog_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

export type Subscription_RootAgreementsArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

export type Subscription_RootAgreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Agreements_Order_By>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

export type Subscription_RootAgreements_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootAgreements_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Agreements_Stream_Cursor_Input>>;
  where?: InputMaybe<Agreements_Bool_Exp>;
};

export type Subscription_RootApproversArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

export type Subscription_RootApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Approvers_Order_By>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

export type Subscription_RootApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootApprovers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Approvers_Stream_Cursor_Input>>;
  where?: InputMaybe<Approvers_Bool_Exp>;
};

export type Subscription_RootBaseLandlordServicesArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

export type Subscription_RootBaseLandlordServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BaseLandlordServices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<BaseLandlordServices_Order_By>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

export type Subscription_RootBaseLandlordServices_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootBaseLandlordServices_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<BaseLandlordServices_Stream_Cursor_Input>>;
  where?: InputMaybe<BaseLandlordServices_Bool_Exp>;
};

export type Subscription_RootCenterApproversArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

export type Subscription_RootCenterApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CenterApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CenterApprovers_Order_By>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

export type Subscription_RootCenterApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootCenterApprovers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<CenterApprovers_Stream_Cursor_Input>>;
  where?: InputMaybe<CenterApprovers_Bool_Exp>;
};

export type Subscription_RootCommentTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

export type Subscription_RootCommentTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentTags_Order_By>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

export type Subscription_RootCommentTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootCommentTags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<CommentTags_Stream_Cursor_Input>>;
  where?: InputMaybe<CommentTags_Bool_Exp>;
};

export type Subscription_RootCommentUserTagsArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

export type Subscription_RootCommentUserTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentUserTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CommentUserTags_Order_By>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

export type Subscription_RootCommentUserTags_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCommentUserTags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<CommentUserTags_Stream_Cursor_Input>>;
  where?: InputMaybe<CommentUserTags_Bool_Exp>;
};

export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootComments_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootCompanies_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Companies_Stream_Cursor_Input>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

export type Subscription_RootContactTypesArgs = {
  distinct_on?: InputMaybe<Array<ContactTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ContactTypes_Order_By>>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

export type Subscription_RootContactTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<ContactTypes_Order_By>>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

export type Subscription_RootContactTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootContactTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<ContactTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ContactTypes_Bool_Exp>;
};

export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Subscription_RootCountries_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Subscription_RootCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<CustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CustomLicences_Order_By>>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

export type Subscription_RootCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CustomLicences_Order_By>>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

export type Subscription_RootCustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootCustomLicences_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<CustomLicences_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomLicences_Bool_Exp>;
};

export type Subscription_RootDealActionItemArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

export type Subscription_RootDealActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealActionItem_Order_By>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

export type Subscription_RootDealActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealActionItem_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealActionItem_Stream_Cursor_Input>>;
  where?: InputMaybe<DealActionItem_Bool_Exp>;
};

export type Subscription_RootDealBusinessContactsArgs = {
  distinct_on?: InputMaybe<Array<DealBusinessContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealBusinessContacts_Order_By>>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

export type Subscription_RootDealBusinessContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealBusinessContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealBusinessContacts_Order_By>>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

export type Subscription_RootDealBusinessContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealBusinessContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealBusinessContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<DealBusinessContacts_Bool_Exp>;
};

export type Subscription_RootDealContactsTagsArgs = {
  distinct_on?: InputMaybe<Array<DealContactsTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealContactsTags_Order_By>>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

export type Subscription_RootDealContactsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealContactsTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealContactsTags_Order_By>>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

export type Subscription_RootDealContactsTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealContactsTags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealContactsTags_Stream_Cursor_Input>>;
  where?: InputMaybe<DealContactsTags_Bool_Exp>;
};

export type Subscription_RootDealDataArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

export type Subscription_RootDealDataCalculationsArgs = {
  distinct_on?: InputMaybe<Array<DealDataCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCalculations_Order_By>>;
  where?: InputMaybe<DealDataCalculations_Bool_Exp>;
};

export type Subscription_RootDealDataCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCalculations_Order_By>>;
  where?: InputMaybe<DealDataCalculations_Bool_Exp>;
};

export type Subscription_RootDealDataCalculations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealDataCalculations_Stream_Cursor_Input>>;
  where?: InputMaybe<DealDataCalculations_Bool_Exp>;
};

export type Subscription_RootDealDataCustomLicencesArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

export type Subscription_RootDealDataCustomLicences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataCustomLicences_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataCustomLicences_Order_By>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

export type Subscription_RootDealDataCustomLicences_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealDataCustomLicences_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealDataCustomLicences_Stream_Cursor_Input>>;
  where?: InputMaybe<DealDataCustomLicences_Bool_Exp>;
};

export type Subscription_RootDealDataGuarantorsArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

export type Subscription_RootDealDataGuarantors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealDataGuarantors_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealDataGuarantors_Order_By>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

export type Subscription_RootDealDataGuarantors_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealDataGuarantors_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealDataGuarantors_Stream_Cursor_Input>>;
  where?: InputMaybe<DealDataGuarantors_Bool_Exp>;
};

export type Subscription_RootDealData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealData_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealData_Order_By>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

export type Subscription_RootDealData_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealData_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealData_Stream_Cursor_Input>>;
  where?: InputMaybe<DealData_Bool_Exp>;
};

export type Subscription_RootDealExtensionItemsArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

export type Subscription_RootDealExtensionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealExtensionItems_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealExtensionItems_Order_By>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

export type Subscription_RootDealExtensionItems_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealExtensionItems_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealExtensionItems_Stream_Cursor_Input>>;
  where?: InputMaybe<DealExtensionItems_Bool_Exp>;
};

export type Subscription_RootDealFileChunksArgs = {
  distinct_on?: InputMaybe<Array<DealFileChunks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileChunks_Order_By>>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

export type Subscription_RootDealFileChunks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileChunks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileChunks_Order_By>>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

export type Subscription_RootDealFileChunks_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealFileChunks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealFileChunks_Stream_Cursor_Input>>;
  where?: InputMaybe<DealFileChunks_Bool_Exp>;
};

export type Subscription_RootDealFileTagsArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

export type Subscription_RootDealFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFileTags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFileTags_Order_By>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

export type Subscription_RootDealFileTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealFileTags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealFileTags_Stream_Cursor_Input>>;
  where?: InputMaybe<DealFileTags_Bool_Exp>;
};

export type Subscription_RootDealFilesArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

export type Subscription_RootDealFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFiles_Order_By>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

export type Subscription_RootDealFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealFiles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<DealFiles_Bool_Exp>;
};

export type Subscription_RootDealFitoutLandlordWorksArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

export type Subscription_RootDealFitoutLandlordWorks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealFitoutLandlordWorks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealFitoutLandlordWorks_Order_By>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

export type Subscription_RootDealFitoutLandlordWorks_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealFitoutLandlordWorks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealFitoutLandlordWorks_Stream_Cursor_Input>>;
  where?: InputMaybe<DealFitoutLandlordWorks_Bool_Exp>;
};

export type Subscription_RootDealLafApprovalsArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

export type Subscription_RootDealLafApprovals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLafApprovals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLafApprovals_Order_By>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

export type Subscription_RootDealLafApprovals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealLafApprovals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealLafApprovals_Stream_Cursor_Input>>;
  where?: InputMaybe<DealLafApprovals_Bool_Exp>;
};

export type Subscription_RootDealLinksArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

export type Subscription_RootDealLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealLinks_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealLinks_Order_By>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

export type Subscription_RootDealLinks_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootDealLinks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealLinks_Stream_Cursor_Input>>;
  where?: InputMaybe<DealLinks_Bool_Exp>;
};

export type Subscription_RootDealOptionsArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

export type Subscription_RootDealOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealOptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealOptions_Order_By>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

export type Subscription_RootDealOptions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealOptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealOptions_Stream_Cursor_Input>>;
  where?: InputMaybe<DealOptions_Bool_Exp>;
};

export type Subscription_RootDealPermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

export type Subscription_RootDealPermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPermittedUses_Order_By>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

export type Subscription_RootDealPermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealPermittedUses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealPermittedUses_Stream_Cursor_Input>>;
  where?: InputMaybe<DealPermittedUses_Bool_Exp>;
};

export type Subscription_RootDealPhasesArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

export type Subscription_RootDealPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealPhases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealPhases_Order_By>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

export type Subscription_RootDealPhases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealPhases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealPhases_Stream_Cursor_Input>>;
  where?: InputMaybe<DealPhases_Bool_Exp>;
};

export type Subscription_RootDealRiskLevelsArgs = {
  distinct_on?: InputMaybe<Array<DealRiskLevels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealRiskLevels_Order_By>>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

export type Subscription_RootDealRiskLevels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealRiskLevels_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealRiskLevels_Order_By>>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

export type Subscription_RootDealRiskLevels_By_PkArgs = {
  risk_level: Scalars["String"];
};

export type Subscription_RootDealRiskLevels_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealRiskLevels_Stream_Cursor_Input>>;
  where?: InputMaybe<DealRiskLevels_Bool_Exp>;
};

export type Subscription_RootDealShopActivitiesArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

export type Subscription_RootDealShopActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivities_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivities_Order_By>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

export type Subscription_RootDealShopActivities_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealShopActivities_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealShopActivities_Stream_Cursor_Input>>;
  where?: InputMaybe<DealShopActivities_Bool_Exp>;
};

export type Subscription_RootDealShopActivityTypesArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivityTypes_Order_By>>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

export type Subscription_RootDealShopActivityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealShopActivityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealShopActivityTypes_Order_By>>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

export type Subscription_RootDealShopActivityTypes_By_PkArgs = {
  name: Scalars["String"];
};

export type Subscription_RootDealShopActivityTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealShopActivityTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<DealShopActivityTypes_Bool_Exp>;
};

export type Subscription_RootDealSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

export type Subscription_RootDealSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealSpecialConditions_Order_By>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

export type Subscription_RootDealSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealSpecialConditions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealSpecialConditions_Stream_Cursor_Input>>;
  where?: InputMaybe<DealSpecialConditions_Bool_Exp>;
};

export type Subscription_RootDealStatusArgs = {
  distinct_on?: InputMaybe<Array<DealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealStatus_Order_By>>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

export type Subscription_RootDealStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealStatus_Order_By>>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

export type Subscription_RootDealStatus_By_PkArgs = {
  name: Scalars["String"];
};

export type Subscription_RootDealStatus_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<DealStatus_Bool_Exp>;
};

export type Subscription_RootDealTypesArgs = {
  distinct_on?: InputMaybe<Array<DealTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealTypes_Order_By>>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

export type Subscription_RootDealTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealTypes_Order_By>>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

export type Subscription_RootDealTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<DealTypes_Bool_Exp>;
};

export type Subscription_RootDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Subscription_RootDealsCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

export type Subscription_RootDealsCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsCompanies_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsCompanies_Order_By>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

export type Subscription_RootDealsCompanies_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealsCompanies_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealsCompanies_Stream_Cursor_Input>>;
  where?: InputMaybe<DealsCompanies_Bool_Exp>;
};

export type Subscription_RootDealsContactsArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

export type Subscription_RootDealsContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsContacts_Order_By>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

export type Subscription_RootDealsContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealsContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealsContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<DealsContacts_Bool_Exp>;
};

export type Subscription_RootDealsUnitsArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

export type Subscription_RootDealsUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DealsUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DealsUnits_Order_By>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

export type Subscription_RootDealsUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDealsUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DealsUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<DealsUnits_Bool_Exp>;
};

export type Subscription_RootDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Subscription_RootDeals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDeals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Deals_Stream_Cursor_Input>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Subscription_RootDocumentRequestsArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

export type Subscription_RootDocumentRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentRequests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentRequests_Order_By>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

export type Subscription_RootDocumentRequests_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDocumentRequests_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DocumentRequests_Stream_Cursor_Input>>;
  where?: InputMaybe<DocumentRequests_Bool_Exp>;
};

export type Subscription_RootDocumentTemplatesArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

export type Subscription_RootDocumentTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DocumentTemplates_Order_By>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

export type Subscription_RootDocumentTemplates_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootDocumentTemplates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DocumentTemplates_Stream_Cursor_Input>>;
  where?: InputMaybe<DocumentTemplates_Bool_Exp>;
};

export type Subscription_RootEscalatedApproversArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

export type Subscription_RootEscalatedApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EscalatedApprovers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<EscalatedApprovers_Order_By>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

export type Subscription_RootEscalatedApprovers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootEscalatedApprovers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<EscalatedApprovers_Stream_Cursor_Input>>;
  where?: InputMaybe<EscalatedApprovers_Bool_Exp>;
};

export type Subscription_RootGeneratedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

export type Subscription_RootGeneratedDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeneratedDocuments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<GeneratedDocuments_Order_By>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

export type Subscription_RootGeneratedDocuments_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootGeneratedDocuments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<GeneratedDocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<GeneratedDocuments_Bool_Exp>;
};

export type Subscription_RootLafActionChoicesArgs = {
  distinct_on?: InputMaybe<Array<LafActionChoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<LafActionChoices_Order_By>>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

export type Subscription_RootLafActionChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LafActionChoices_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<LafActionChoices_Order_By>>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

export type Subscription_RootLafActionChoices_By_PkArgs = {
  name: Scalars["String"];
};

export type Subscription_RootLafActionChoices_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<LafActionChoices_Stream_Cursor_Input>>;
  where?: InputMaybe<LafActionChoices_Bool_Exp>;
};

export type Subscription_RootLeasesArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

export type Subscription_RootLeases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Leases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Leases_Order_By>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

export type Subscription_RootLeases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootLeases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Leases_Stream_Cursor_Input>>;
  where?: InputMaybe<Leases_Bool_Exp>;
};

export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootOffersArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

export type Subscription_RootOffers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Offers_Order_By>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

export type Subscription_RootOffers_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootOffers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Offers_Bool_Exp>;
};

export type Subscription_RootPhaseActionItemArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

export type Subscription_RootPhaseActionItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseActionItem_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseActionItem_Order_By>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

export type Subscription_RootPhaseActionItem_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPhaseActionItem_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PhaseActionItem_Stream_Cursor_Input>>;
  where?: InputMaybe<PhaseActionItem_Bool_Exp>;
};

export type Subscription_RootPhaseDealStatusArgs = {
  distinct_on?: InputMaybe<Array<PhaseDealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseDealStatus_Order_By>>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

export type Subscription_RootPhaseDealStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhaseDealStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PhaseDealStatus_Order_By>>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

export type Subscription_RootPhaseDealStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPhaseDealStatus_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PhaseDealStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<PhaseDealStatus_Bool_Exp>;
};

export type Subscription_RootPhasesArgs = {
  distinct_on?: InputMaybe<Array<Phases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Phases_Order_By>>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

export type Subscription_RootPhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Phases_Order_By>>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

export type Subscription_RootPhases_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPhases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Phases_Stream_Cursor_Input>>;
  where?: InputMaybe<Phases_Bool_Exp>;
};

export type Subscription_RootPropertiesArgs = {
  distinct_on?: InputMaybe<Array<Properties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Properties_Order_By>>;
  where?: InputMaybe<Properties_Bool_Exp>;
};

export type Subscription_RootPropertiesInformationArgs = {
  distinct_on?: InputMaybe<Array<PropertiesInformation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertiesInformation_Order_By>>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

export type Subscription_RootPropertiesInformation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertiesInformation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertiesInformation_Order_By>>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

export type Subscription_RootPropertiesInformation_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPropertiesInformation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PropertiesInformation_Stream_Cursor_Input>>;
  where?: InputMaybe<PropertiesInformation_Bool_Exp>;
};

export type Subscription_RootProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Properties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Properties_Order_By>>;
  where?: InputMaybe<Properties_Bool_Exp>;
};

export type Subscription_RootProperties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Properties_Stream_Cursor_Input>>;
  where?: InputMaybe<Properties_Bool_Exp>;
};

export type Subscription_RootPropertyContactsArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

export type Subscription_RootPropertyContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyContacts_Order_By>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

export type Subscription_RootPropertyContacts_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPropertyContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PropertyContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<PropertyContacts_Bool_Exp>;
};

export type Subscription_RootPropertyFileTypesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFileTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFileTypes_Order_By>>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

export type Subscription_RootPropertyFileTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFileTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFileTypes_Order_By>>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

export type Subscription_RootPropertyFileTypes_By_PkArgs = {
  name: Scalars["String"];
};

export type Subscription_RootPropertyFileTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PropertyFileTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<PropertyFileTypes_Bool_Exp>;
};

export type Subscription_RootPropertyFilesArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

export type Subscription_RootPropertyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PropertyFiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PropertyFiles_Order_By>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

export type Subscription_RootPropertyFiles_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootPropertyFiles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PropertyFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<PropertyFiles_Bool_Exp>;
};

export type Subscription_RootProposalsArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

export type Subscription_RootProposals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Proposals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Proposals_Order_By>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

export type Subscription_RootProposals_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootProposals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Proposals_Stream_Cursor_Input>>;
  where?: InputMaybe<Proposals_Bool_Exp>;
};

export type Subscription_RootRegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

export type Subscription_RootRegistrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Registrations_Order_By>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

export type Subscription_RootRegistrations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootRegistrations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Registrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Registrations_Bool_Exp>;
};

export type Subscription_RootSalesTypeArgs = {
  distinct_on?: InputMaybe<Array<SalesType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<SalesType_Order_By>>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

export type Subscription_RootSalesType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesType_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<SalesType_Order_By>>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

export type Subscription_RootSalesType_By_PkArgs = {
  name: Scalars["String"];
};

export type Subscription_RootSalesType_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<SalesType_Stream_Cursor_Input>>;
  where?: InputMaybe<SalesType_Bool_Exp>;
};

export type Subscription_RootStatesArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

export type Subscription_RootStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<States_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<States_Order_By>>;
  where?: InputMaybe<States_Bool_Exp>;
};

export type Subscription_RootStates_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootStates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<States_Stream_Cursor_Input>>;
  where?: InputMaybe<States_Bool_Exp>;
};

export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTemplatePermittedUsesArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

export type Subscription_RootTemplatePermittedUses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplatePermittedUses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplatePermittedUses_Order_By>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

export type Subscription_RootTemplatePermittedUses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTemplatePermittedUses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<TemplatePermittedUses_Stream_Cursor_Input>>;
  where?: InputMaybe<TemplatePermittedUses_Bool_Exp>;
};

export type Subscription_RootTemplateSpecialConditionsArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

export type Subscription_RootTemplateSpecialConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TemplateSpecialConditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TemplateSpecialConditions_Order_By>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

export type Subscription_RootTemplateSpecialConditions_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTemplateSpecialConditions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<TemplateSpecialConditions_Stream_Cursor_Input>>;
  where?: InputMaybe<TemplateSpecialConditions_Bool_Exp>;
};

export type Subscription_RootTenantTypesArgs = {
  distinct_on?: InputMaybe<Array<TenantTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TenantTypes_Order_By>>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

export type Subscription_RootTenantTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TenantTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TenantTypes_Order_By>>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

export type Subscription_RootTenantTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTenantTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<TenantTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<TenantTypes_Bool_Exp>;
};

export type Subscription_RootTermsArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

export type Subscription_RootTerms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Terms_Order_By>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

export type Subscription_RootTerms_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTerms_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Terms_Stream_Cursor_Input>>;
  where?: InputMaybe<Terms_Bool_Exp>;
};

export type Subscription_RootTriSelectEnumArgs = {
  distinct_on?: InputMaybe<Array<TriSelectEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TriSelectEnum_Order_By>>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

export type Subscription_RootTriSelectEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TriSelectEnum_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TriSelectEnum_Order_By>>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

export type Subscription_RootTriSelectEnum_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootTriSelectEnum_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<TriSelectEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<TriSelectEnum_Bool_Exp>;
};

export type Subscription_RootUnitTypesArgs = {
  distinct_on?: InputMaybe<Array<UnitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UnitTypes_Order_By>>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

export type Subscription_RootUnitTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UnitTypes_Order_By>>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

export type Subscription_RootUnitTypes_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootUnitTypes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<UnitTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<UnitTypes_Bool_Exp>;
};

export type Subscription_RootUnitsArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

export type Subscription_RootUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Units_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Units_Order_By>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

export type Subscription_RootUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Units_Bool_Exp>;
};

export type Subscription_RootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

export type Subscription_RootUserStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatus_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<UserStatus_Order_By>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

export type Subscription_RootUserStatus_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootUserStatus_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<UserStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<UserStatus_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

export type Subscription_RootVirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<VirtualUnits_Order_By>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

export type Subscription_RootVirtualUnits_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootVirtualUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<VirtualUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<VirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiActiveLeasesArgs = {
  distinct_on?: InputMaybe<Array<YardiActiveLeases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiActiveLeases_Order_By>>;
  where?: InputMaybe<YardiActiveLeases_Bool_Exp>;
};

export type Subscription_RootYardiActiveLeases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiActiveLeases_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiActiveLeases_Order_By>>;
  where?: InputMaybe<YardiActiveLeases_Bool_Exp>;
};

export type Subscription_RootYardiActiveLeases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiActiveLeases_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiActiveLeases_Bool_Exp>;
};

export type Subscription_RootYardiAmendmentsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

export type Subscription_RootYardiAmendments_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

export type Subscription_RootYardiAmendments_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Deals_Order_By>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

export type Subscription_RootYardiAmendments_Deals_By_PkArgs = {
  AmendmentCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Subscription_RootYardiAmendments_Deals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiAmendments_Deals_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiAmendments_Deals_Bool_Exp>;
};

export type Subscription_RootYardiAmendments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiAmendments_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiAmendments_Order_By>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

export type Subscription_RootYardiAmendments_By_PkArgs = {
  AmendmentKey: Scalars["String"];
};

export type Subscription_RootYardiAmendments_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiAmendments_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiAmendments_Bool_Exp>;
};

export type Subscription_RootYardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiContacts_ContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

export type Subscription_RootYardiContacts_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Contacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

export type Subscription_RootYardiContacts_Contacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  contact_id: Scalars["bigint"];
};

export type Subscription_RootYardiContacts_Contacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiContacts_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiContacts_Contacts_Bool_Exp>;
};

export type Subscription_RootYardiContacts_DealOwnersArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_DealOwners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_DealOwners_Order_By>>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

export type Subscription_RootYardiContacts_DealOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_DealOwners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_DealOwners_Order_By>>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

export type Subscription_RootYardiContacts_DealOwners_By_PkArgs = {
  ContactCode: Scalars["String"];
  user_id: Scalars["uuid"];
};

export type Subscription_RootYardiContacts_DealOwners_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiContacts_DealOwners_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiContacts_DealOwners_Bool_Exp>;
};

export type Subscription_RootYardiContacts_YardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiContacts_YardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_YardiProperties_Order_By>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiContacts_YardiProperties_By_PkArgs = {
  ContactCode: Scalars["String"];
  PropertyCode: Scalars["String"];
};

export type Subscription_RootYardiContacts_YardiProperties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiContacts_YardiProperties_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiContacts_YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiContacts_Order_By>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
};

export type Subscription_RootYardiContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiCustomersArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Order_By>>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

export type Subscription_RootYardiCustomers_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

export type Subscription_RootYardiCustomers_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Deals_Order_By>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

export type Subscription_RootYardiCustomers_Deals_By_PkArgs = {
  CustomerCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Subscription_RootYardiCustomers_Deals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiCustomers_Deals_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiCustomers_Deals_Bool_Exp>;
};

export type Subscription_RootYardiCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiCustomers_Order_By>>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

export type Subscription_RootYardiCustomers_By_PkArgs = {
  CustomerCode: Scalars["String"];
};

export type Subscription_RootYardiCustomers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiCustomers_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiCustomers_Bool_Exp>;
};

export type Subscription_RootYardiDealSyncStatusesArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

export type Subscription_RootYardiDealSyncStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiDealSyncStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiDealSyncStatuses_Order_By>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

export type Subscription_RootYardiDealSyncStatuses_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootYardiDealSyncStatuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiDealSyncStatuses_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiDealSyncStatuses_Bool_Exp>;
};

export type Subscription_RootYardiIngestionInfosArgs = {
  distinct_on?: InputMaybe<Array<YardiIngestionInfos_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiIngestionInfos_Order_By>>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

export type Subscription_RootYardiIngestionInfos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiIngestionInfos_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiIngestionInfos_Order_By>>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

export type Subscription_RootYardiIngestionInfos_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootYardiIngestionInfos_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiIngestionInfos_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiIngestionInfos_Bool_Exp>;
};

export type Subscription_RootYardiLeasesForRenewalArgs = {
  distinct_on?: InputMaybe<Array<YardiLeasesForRenewal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLeasesForRenewal_Order_By>>;
  where?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
};

export type Subscription_RootYardiLeasesForRenewal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiLeasesForRenewal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLeasesForRenewal_Order_By>>;
  where?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
};

export type Subscription_RootYardiLeasesForRenewal_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiLeasesForRenewal_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiLeasesForRenewal_Bool_Exp>;
};

export type Subscription_RootYardiLimitedCustomersArgs = {
  distinct_on?: InputMaybe<Array<YardiLimitedCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLimitedCustomers_Order_By>>;
  where?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
};

export type Subscription_RootYardiLimitedCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiLimitedCustomers_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiLimitedCustomers_Order_By>>;
  where?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
};

export type Subscription_RootYardiLimitedCustomers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiLimitedCustomers_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiLimitedCustomers_Bool_Exp>;
};

export type Subscription_RootYardiPropertiesArgs = {
  distinct_on?: InputMaybe<Array<YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiProperties_Order_By>>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiProperties_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiProperties_Order_By>>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiProperties_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

export type Subscription_RootYardiProperties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiProperties_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiProperties_Bool_Exp>;
};

export type Subscription_RootYardiPropertyProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiPropertyProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiPropertyProfiles_Order_By>>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

export type Subscription_RootYardiPropertyProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiPropertyProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiPropertyProfiles_Order_By>>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

export type Subscription_RootYardiPropertyProfiles_By_PkArgs = {
  PropertyCode: Scalars["String"];
};

export type Subscription_RootYardiPropertyProfiles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiPropertyProfiles_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiPropertyProfiles_Bool_Exp>;
};

export type Subscription_RootYardiTenantToUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

export type Subscription_RootYardiTenantToUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenantToUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenantToUnits_Order_By>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

export type Subscription_RootYardiTenantToUnits_By_PkArgs = {
  TenantCode: Scalars["String"];
  UnitKey: Scalars["String"];
};

export type Subscription_RootYardiTenantToUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiTenantToUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiTenantToUnits_Bool_Exp>;
};

export type Subscription_RootYardiTenant_YardiContactsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenant_YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenant_YardiContacts_Order_By>>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiTenant_YardiContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenant_YardiContacts_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenant_YardiContacts_Order_By>>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiTenant_YardiContacts_By_PkArgs = {
  ContactCode: Scalars["String"];
  TenantCode: Scalars["String"];
};

export type Subscription_RootYardiTenant_YardiContacts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiTenant_YardiContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiTenant_YardiContacts_Bool_Exp>;
};

export type Subscription_RootYardiTenantsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

export type Subscription_RootYardiTenants_DealsArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

export type Subscription_RootYardiTenants_Deals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Deals_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Deals_Order_By>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

export type Subscription_RootYardiTenants_Deals_By_PkArgs = {
  TenantCode: Scalars["String"];
  deal_id: Scalars["bigint"];
};

export type Subscription_RootYardiTenants_Deals_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiTenants_Deals_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiTenants_Deals_Bool_Exp>;
};

export type Subscription_RootYardiTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiTenants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiTenants_Order_By>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

export type Subscription_RootYardiTenants_By_PkArgs = {
  TenantCode: Scalars["String"];
};

export type Subscription_RootYardiTenants_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiTenants_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiTenants_Bool_Exp>;
};

export type Subscription_RootYardiUnitProfilesArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

export type Subscription_RootYardiUnitProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnitProfiles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnitProfiles_Order_By>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

export type Subscription_RootYardiUnitProfiles_By_PkArgs = {
  UnitProfileKey: Scalars["String"];
};

export type Subscription_RootYardiUnitProfiles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiUnitProfiles_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiUnitProfiles_Bool_Exp>;
};

export type Subscription_RootYardiUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

export type Subscription_RootYardiUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiUnits_Order_By>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

export type Subscription_RootYardiUnits_By_PkArgs = {
  UnitKey: Scalars["String"];
};

export type Subscription_RootYardiUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnits_VirtualUnitsArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnits_VirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_VirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnits_VirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
  virtual_unit_id: Scalars["bigint"];
};

export type Subscription_RootYardiVirtualUnits_VirtualUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiVirtualUnits_VirtualUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiVirtualUnits_VirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<YardiVirtualUnits_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<YardiVirtualUnits_Order_By>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

export type Subscription_RootYardiVirtualUnits_By_PkArgs = {
  VirtualUnitKey: Scalars["String"];
};

export type Subscription_RootYardiVirtualUnits_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<YardiVirtualUnits_Stream_Cursor_Input>>;
  where?: InputMaybe<YardiVirtualUnits_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["time"]>;
  _gt?: InputMaybe<Scalars["time"]>;
  _gte?: InputMaybe<Scalars["time"]>;
  _in?: InputMaybe<Array<Scalars["time"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["time"]>;
  _lte?: InputMaybe<Scalars["time"]>;
  _neq?: InputMaybe<Scalars["time"]>;
  _nin?: InputMaybe<Array<Scalars["time"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

/** Boolean expression to compare columns of type "yardi_deal_sync_status". All fields are combined with logical 'AND'. */
export type Yardi_Deal_Sync_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _gt?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _gte?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _in?: InputMaybe<Array<Scalars["yardi_deal_sync_status"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _lte?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _neq?: InputMaybe<Scalars["yardi_deal_sync_status"]>;
  _nin?: InputMaybe<Array<Scalars["yardi_deal_sync_status"]>>;
};

export type GetApproversQueryVariables = Exact<{ [key: string]: never }>;

export type GetApproversQuery = {
  __typename?: "query_root";
  Approvers: Array<{
    __typename?: "Approvers";
    id: any;
    level?: number | null;
    User: { __typename?: "Users"; id: any; user_full_name?: string | null };
    CenterApprovers: Array<{
      __typename?: "CenterApprovers";
      Property?: {
        __typename?: "YardiProperties";
        PropertyCode: string;
        PropertyName: string;
      } | null;
    }>;
  }>;
};

export type GetEsclatedApproversQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEsclatedApproversQuery = {
  __typename?: "query_root";
  EscalatedApprovers: Array<{
    __typename?: "EscalatedApprovers";
    level?: number | null;
    User?: {
      __typename?: "Users";
      user_full_name?: string | null;
      id: any;
    } | null;
  }>;
};

export type CreateEsclatedApproversMutationVariables = Exact<{
  objects:
    | Array<EscalatedApprovers_Insert_Input>
    | EscalatedApprovers_Insert_Input;
}>;

export type CreateEsclatedApproversMutation = {
  __typename?: "mutation_root";
  insert_EscalatedApprovers?: {
    __typename?: "EscalatedApprovers_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteEsclatedApproversMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteEsclatedApproversMutation = {
  __typename?: "mutation_root";
  delete_EscalatedApprovers?: {
    __typename?: "EscalatedApprovers_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateApproversMutationVariables = Exact<{
  objects?: InputMaybe<Array<Approvers_Insert_Input> | Approvers_Insert_Input>;
}>;

export type CreateApproversMutation = {
  __typename?: "mutation_root";
  delete_CenterApprovers?: {
    __typename?: "CenterApprovers_mutation_response";
    affected_rows: number;
  } | null;
  delete_Approvers?: {
    __typename?: "Approvers_mutation_response";
    affected_rows: number;
  } | null;
  insert_Approvers?: {
    __typename?: "Approvers_mutation_response";
    returning: Array<{ __typename?: "Approvers"; id: any }>;
  } | null;
};

export type ChatQueryVariables = Exact<{
  messages: Array<InputMaybe<ChatMessageInput>> | InputMaybe<ChatMessageInput>;
  source?: InputMaybe<Scalars["String"]>;
}>;

export type ChatQuery = {
  __typename?: "query_root";
  chat?: {
    __typename?: "ChatResponse";
    id: string;
    created: number;
    choices: Array<{
      __typename?: "ChatResponseChoice";
      message: {
        __typename?: "ChatMessage";
        role: string;
        content: string;
        context?: {
          __typename?: "ResponseContext";
          messages: Array<{
            __typename?: "ContextMessage";
            role: string;
            content: string;
          } | null>;
        } | null;
      };
    } | null>;
  } | null;
};

export type GetCompanyQueryVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type GetCompanyQuery = {
  __typename?: "query_root";
  Companies_by_pk?: {
    __typename?: "Companies";
    trading_name?: string | null;
    phone?: string | null;
    modified_at?: any | null;
    legal_name?: string | null;
    id: any;
    email?: string | null;
    created_at?: any | null;
    address_id?: any | null;
    acn?: string | null;
    Location?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
      State?: { __typename?: "States"; name?: string | null; id: any } | null;
    } | null;
    Contacts: Array<{
      __typename?: "Contacts";
      id: any;
      first_name?: string | null;
      middle_name?: string | null;
      last_name?: string | null;
      phone?: string | null;
      email?: string | null;
      Location?: {
        __typename?: "Locations";
        street1?: string | null;
        street2?: string | null;
        postcode?: number | null;
        suburb?: string | null;
        id: any;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompaniesQuery = {
  __typename?: "query_root";
  Companies: Array<{
    __typename?: "Companies";
    id: any;
    acn?: string | null;
    email?: string | null;
    phone?: string | null;
    not_deleted?: boolean | null;
    trading_name?: string | null;
    legal_name?: string | null;
    DealsCompanies: Array<{
      __typename?: "DealsCompanies";
      id: any;
      Contact?: {
        __typename?: "Contacts";
        id: any;
        first_name?: string | null;
        middle_name?: string | null;
        last_name?: string | null;
        phone?: string | null;
        email?: string | null;
      } | null;
    }>;
    Location?: {
      __typename?: "Locations";
      id: any;
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
    Contacts: Array<{
      __typename?: "Contacts";
      id: any;
      first_name?: string | null;
      middle_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      phone?: string | null;
      address_id?: any | null;
      Location?: {
        __typename?: "Locations";
        id: any;
        street1?: string | null;
        street2?: string | null;
        suburb?: string | null;
        postcode?: number | null;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      } | null;
    }>;
  }>;
};

export type CreateCompanyMutationVariables = Exact<{
  company?: InputMaybe<Companies_Insert_Input>;
}>;

export type CreateCompanyMutation = {
  __typename?: "mutation_root";
  insert_Companies_one?: { __typename?: "Companies"; id: any } | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars["bigint"];
  data?: InputMaybe<Companies_Set_Input>;
}>;

export type UpdateCompanyMutation = {
  __typename?: "mutation_root";
  update_Companies_by_pk?: {
    __typename?: "Companies";
    id: any;
    legal_name?: string | null;
    trading_name?: string | null;
    acn?: string | null;
  } | null;
};

export type AddDealCompanyMutationVariables = Exact<{
  data?: InputMaybe<DealsCompanies_Insert_Input>;
}>;

export type AddDealCompanyMutation = {
  __typename?: "mutation_root";
  insert_DealsCompanies_one?: {
    __typename?: "DealsCompanies";
    id: any;
    company_id?: any | null;
  } | null;
};

export type SoftDeleteCompanyMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type SoftDeleteCompanyMutation = {
  __typename?: "mutation_root";
  update_Companies_by_pk?: {
    __typename?: "Companies";
    id: any;
    not_deleted?: boolean | null;
  } | null;
};

export type GetContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactsQuery = {
  __typename?: "query_root";
  Contacts: Array<{
    __typename?: "Contacts";
    id: any;
    first_name?: string | null;
    middle_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    address_id?: any | null;
    content?: string | null;
    Location?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      state_id?: number | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      legal_name?: string | null;
      trading_name?: string | null;
      acn?: string | null;
    } | null;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      deal_id?: any | null;
      contact_type: number;
      tag?: string | null;
    }>;
  }>;
};

export type CreateContactMutationVariables = Exact<{
  contact?: InputMaybe<Contacts_Insert_Input>;
}>;

export type CreateContactMutation = {
  __typename?: "mutation_root";
  insert_Contacts_one?: { __typename?: "Contacts"; id: any } | null;
};

export type GetContactQueryVariables = Exact<{
  contactId: Scalars["bigint"];
}>;

export type GetContactQuery = {
  __typename?: "query_root";
  Contacts_by_pk?: {
    __typename?: "Contacts";
    id: any;
    first_name?: string | null;
    middle_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    address_id?: any | null;
    content?: string | null;
    Location?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      state_id?: number | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      legal_name?: string | null;
      trading_name?: string | null;
      acn?: string | null;
    } | null;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      deal_id?: any | null;
      contact_type: number;
      tag?: string | null;
    }>;
  } | null;
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars["bigint"];
  data?: InputMaybe<Contacts_Set_Input>;
}>;

export type UpdateContactMutation = {
  __typename?: "mutation_root";
  update_Contacts_by_pk?: {
    __typename?: "Contacts";
    Location?: {
      __typename?: "Locations";
      id: any;
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      legal_name?: string | null;
      trading_name?: string | null;
      acn?: string | null;
    } | null;
  } | null;
};

export type GetDealContactsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealContactsQuery = {
  __typename?: "query_root";
  DealsContacts: Array<{
    __typename?: "DealsContacts";
    id: any;
    contact_type: number;
    tag?: string | null;
    Contact?: {
      __typename?: "Contacts";
      email?: string | null;
      first_name?: string | null;
      id: any;
      last_name?: string | null;
      middle_name?: string | null;
      phone?: string | null;
      content?: string | null;
      Location?: {
        __typename?: "Locations";
        street1?: string | null;
        street2?: string | null;
        postcode?: number | null;
        suburb?: string | null;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      } | null;
    } | null;
  }>;
};

export type GetDealContactsTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDealContactsTagsQuery = {
  __typename?: "query_root";
  DealContactsTags: Array<{
    __typename?: "DealContactsTags";
    id: any;
    name: string;
  }>;
};

export type CreateDealContactsTagsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<DealContactsTags_Insert_Input> | DealContactsTags_Insert_Input
  >;
}>;

export type CreateDealContactsTagsMutation = {
  __typename?: "mutation_root";
  insert_DealContactsTags?: {
    __typename?: "DealContactsTags_mutation_response";
    returning: Array<{
      __typename?: "DealContactsTags";
      id: any;
      name: string;
    }>;
  } | null;
};

export type GetDealsByContactQueryVariables = Exact<{
  contact_id: Scalars["bigint"];
}>;

export type GetDealsByContactQuery = {
  __typename?: "query_root";
  DealsContacts: Array<{
    __typename?: "DealsContacts";
    Deal?: {
      __typename?: "Deals";
      name?: string | null;
      id: any;
      trading_name?: string | null;
      status: DealStatus_Enum;
      User: { __typename?: "Users"; user_full_name?: string | null };
      Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
      DealPhases: Array<{ __typename?: "DealPhases"; name?: string | null }>;
      DealData: Array<{
        __typename?: "DealData";
        id: any;
        lease_start: string;
        DealsUnits: Array<{
          __typename?: "DealsUnits";
          Unit?: {
            __typename?: "Units";
            label?: string | null;
            Property?: {
              __typename?: "Properties";
              label?: string | null;
            } | null;
          } | null;
        }>;
      }>;
    } | null;
  }>;
};

export type GetContactsByPropertyQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetContactsByPropertyQuery = {
  __typename?: "query_root";
  PropertyContacts: Array<{
    __typename?: "PropertyContacts";
    Contact?: {
      __typename?: "Contacts";
      id: any;
      first_name?: string | null;
      middle_name?: string | null;
      last_name?: string | null;
      email?: string | null;
      phone?: string | null;
      address_id?: any | null;
      content?: string | null;
      Location?: {
        __typename?: "Locations";
        street1?: string | null;
        street2?: string | null;
        suburb?: string | null;
        state_id?: number | null;
        postcode?: number | null;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      } | null;
      Company?: {
        __typename?: "Companies";
        id: any;
        legal_name?: string | null;
        trading_name?: string | null;
        acn?: string | null;
      } | null;
      DealsContacts: Array<{
        __typename?: "DealsContacts";
        deal_id?: any | null;
        contact_type: number;
        tag?: string | null;
      }>;
    } | null;
  }>;
};

export type CreatePropertyContactMutationVariables = Exact<{
  data?: InputMaybe<PropertyContacts_Insert_Input>;
}>;

export type CreatePropertyContactMutation = {
  __typename?: "mutation_root";
  insert_PropertyContacts_one?: {
    __typename?: "PropertyContacts";
    id: any;
  } | null;
};

export type CreateLocationMutationVariables = Exact<{
  location?: InputMaybe<Locations_Insert_Input>;
}>;

export type CreateLocationMutation = {
  __typename?: "mutation_root";
  insert_Locations_one?: { __typename?: "Locations"; id: any } | null;
};

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars["bigint"];
  data?: InputMaybe<Locations_Set_Input>;
}>;

export type UpdateLocationMutation = {
  __typename?: "mutation_root";
  update_Locations_by_pk?: {
    __typename?: "Locations";
    id: any;
    street1?: string | null;
    street2?: string | null;
    suburb?: string | null;
    state_id?: number | null;
    postcode?: number | null;
  } | null;
};

export type GetStatesQueryVariables = Exact<{
  country_id?: InputMaybe<Scalars["Int"]>;
}>;

export type GetStatesQuery = {
  __typename?: "query_root";
  States: Array<{ __typename?: "States"; id: any; name?: string | null }>;
};

export type GetPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPropertiesQuery = {
  __typename?: "query_root";
  Properties: Array<{
    __typename?: "Properties";
    property_code?: string | null;
    label?: string | null;
    street1?: string | null;
    street2?: string | null;
    suburb?: string | null;
    postcode?: string | null;
    hub?: string | null;
    modified_at?: any | null;
  }>;
};

export type GetPropertyQueryVariables = Exact<{
  property_code?: InputMaybe<Scalars["String"]>;
}>;

export type GetPropertyQuery = {
  __typename?: "query_root";
  Properties: Array<{
    __typename?: "Properties";
    property_code?: string | null;
    label?: string | null;
    property_type?: string | null;
    status?: string | null;
    street1?: string | null;
    street2?: string | null;
    suburb?: string | null;
    state?: string | null;
    postcode?: string | null;
    country?: string | null;
    center_under_development?: string | null;
    notes?: string | null;
    hub?: string | null;
  }>;
};

export type GetUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnitsQuery = {
  __typename?: "query_root";
  Units: Array<{
    __typename?: "Units";
    UnitKey?: string | null;
    UnitCode?: string | null;
    label?: string | null;
    estimated_area?: any | null;
    survey_area?: any | null;
    property_code?: string | null;
    PropertyName?: string | null;
    UnitType?: string | null;
    area_survey_required?: boolean | null;
    unit_type_id?: number | null;
  }>;
};

export type GetYardiUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetYardiUnitsQuery = {
  __typename?: "query_root";
  YardiUnits: Array<{
    __typename?: "YardiUnits";
    UnitKey: string;
    UnitCode: string;
    PropertyName?: string | null;
    PropertyCode?: string | null;
    UnitType?: string | null;
    UnitArea?: any | null;
    YardiUnitProfiles: Array<{
      __typename?: "YardiUnitProfiles";
      BUDGETEXPIRYDATE?: any | null;
      BUDGETFITOUTDAYS?: any | null;
      BUDGETLEASESTART?: any | null;
      BUDGETSTATUS?: string | null;
      BUDGETVACATEDATE?: any | null;
      DANNUALPROMOLEVY?: any | null;
      DANNUALRECOVERYPSM?: any | null;
      DANNUALRENT?: any | null;
      DBUDGETEDLANDLORDWORKCOST?: any | null;
      DBUDGETLANDLORDCONTRIBTOFITOUT?: any | null;
      DBUDGETMAKEGOOD?: any | null;
      DTBUDGETLEASEDATE?: any | null;
      DTPROFILEYEARSTART?: any | null;
      FBASERENT?: any | null;
      FEXPIRYDATE?: any | null;
      FFITOUTDAYS?: number | null;
      FINCENTIVE?: any | null;
      FLEASESTART?: any | null;
      FLEASETYPE?: string | null;
      FLESSORWORKS?: any | null;
      FMAKEGOOD?: any | null;
      FOGPSM?: any | null;
      FPROMO?: any | null;
      FRENTCOMMENCE?: any | null;
      FSTATUS?: string | null;
      FVACATEDATE?: any | null;
      INHOUSENETRENT?: any | null;
      PAREA?: any | null;
      PBASERENT?: any | null;
      PCAPOCCCOST?: any | null;
      POGPSM?: any | null;
      POWNERNAME?: string | null;
      PPROMO?: any | null;
      PTENANT?: string | null;
      PTENANTNAME?: string | null;
      PVACATEDATE?: any | null;
      SDEFAULTRECOVERYPROFILEDESC?: string | null;
      SECTION1?: string | null;
      SECTION2?: string | null;
      SECTION3?: string | null;
      SECTION4?: string | null;
      SECTION5?: string | null;
      SMGMTFEE?: any | null;
      UnitKey?: string | null;
      VALUATIONNETRENT?: any | null;
      deleted_at?: any | null;
    }>;
  }>;
};

export type GetYardiUnitByKeyQueryVariables = Exact<{
  unit_key?: InputMaybe<Scalars["String"]>;
}>;

export type GetYardiUnitByKeyQuery = {
  __typename?: "query_root";
  YardiUnits: Array<{
    __typename?: "YardiUnits";
    UnitCode: string;
    UnitKey: string;
    Attribute1?: string | null;
    Attribute10?: string | null;
    Attribute2?: string | null;
    Attribute3?: string | null;
    Attribute4?: string | null;
    Attribute5?: string | null;
    Attribute6?: string | null;
    Attribute7?: string | null;
    Attribute8?: string | null;
    Attribute9?: string | null;
    Building?: string | null;
    FloorCode?: string | null;
    IsExclude?: string | null;
    LevelName?: string | null;
    LevelNumber?: string | null;
    Location?: string | null;
    MarketRent?: any | null;
    Notes?: string | null;
    PropertyCode?: string | null;
    PropertyName?: string | null;
    UnitArea?: any | null;
    UnitStatus?: string | null;
    deleted_at?: any | null;
  }>;
};

export type GetTemplateSpecialConditionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTemplateSpecialConditionsQuery = {
  __typename?: "query_root";
  TemplateSpecialConditions: Array<{
    __typename?: "TemplateSpecialConditions";
    title?: string | null;
    description?: string | null;
    laf_description?: string | null;
    id: any;
  }>;
};

export type GetVirtualUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetVirtualUnitsQuery = {
  __typename?: "query_root";
  VirtualUnits: Array<{
    __typename?: "VirtualUnits";
    id: any;
    label?: string | null;
    estimated_area?: any | null;
    survey_area?: number | null;
    property_code?: string | null;
    area_survey_required?: boolean | null;
  }>;
};

export type GetUnitTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnitTypesQuery = {
  __typename?: "query_root";
  Units: Array<{ __typename?: "Units"; UnitType?: string | null }>;
};

export type GetAllPhasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPhasesQuery = {
  __typename?: "query_root";
  Phases: Array<{
    __typename?: "Phases";
    id: any;
    is_tenancy_delivery?: boolean | null;
    sequence?: number | null;
    name?: string | null;
    PhaseActionItem: Array<{
      __typename?: "PhaseActionItem";
      ActionItem?: {
        __typename?: "ActionItems";
        id: any;
        required: boolean;
        state: boolean;
        content?: string | null;
        metadata?: string | null;
      } | null;
    }>;
  }>;
};

export type GetUnitTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnitTypeQuery = {
  __typename?: "query_root";
  UnitTypes_by_pk?: { __typename?: "UnitTypes"; id: any } | null;
};

export type GetTenantContactTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTenantContactTypeQuery = {
  __typename?: "query_root";
  ContactTypes: Array<{ __typename?: "ContactTypes"; id: any }>;
};

export type AddDealMutationVariables = Exact<{
  deal: Deals_Insert_Input;
}>;

export type AddDealMutation = {
  __typename?: "mutation_root";
  insert_Deals_one?: {
    __typename?: "Deals";
    id: any;
    DealsCompanies: Array<{
      __typename?: "DealsCompanies";
      Contact?: { __typename?: "Contacts"; id: any } | null;
    }>;
  } | null;
};

export type AddDealUnitsMutationVariables = Exact<{
  dealUnit: DealsUnits_Insert_Input;
}>;

export type AddDealUnitsMutation = {
  __typename?: "mutation_root";
  insert_DealsUnits_one?: { __typename?: "DealsUnits"; id: any } | null;
};

export type AddDealDataMutationVariables = Exact<{
  dealData: DealData_Insert_Input;
}>;

export type AddDealDataMutation = {
  __typename?: "mutation_root";
  insert_DealData_one?: { __typename?: "DealData"; id: any } | null;
};

export type AddDealPhasesMutationVariables = Exact<{
  objects: Array<DealPhases_Insert_Input> | DealPhases_Insert_Input;
}>;

export type AddDealPhasesMutation = {
  __typename?: "mutation_root";
  insert_DealPhases?: {
    __typename?: "DealPhases_mutation_response";
    returning: Array<{
      __typename?: "DealPhases";
      id: any;
      name?: string | null;
    }>;
  } | null;
};

export type AddDealActionItemsMutationVariables = Exact<{
  objects: Array<DealActionItem_Insert_Input> | DealActionItem_Insert_Input;
}>;

export type AddDealActionItemsMutation = {
  __typename?: "mutation_root";
  insert_DealActionItem?: {
    __typename?: "DealActionItem_mutation_response";
    returning: Array<{ __typename?: "DealActionItem"; id: any }>;
  } | null;
};

export type AddCompanyMutationVariables = Exact<{
  Company: Array<Companies_Insert_Input> | Companies_Insert_Input;
}>;

export type AddCompanyMutation = {
  __typename?: "mutation_root";
  insert_Companies?: {
    __typename?: "Companies_mutation_response";
    returning: Array<{
      __typename?: "Companies";
      id: any;
      trading_name?: string | null;
    }>;
  } | null;
};

export type AddContactMutationVariables = Exact<{
  Contacts: Array<Contacts_Insert_Input> | Contacts_Insert_Input;
}>;

export type AddContactMutation = {
  __typename?: "mutation_root";
  insert_Contacts?: {
    __typename?: "Contacts_mutation_response";
    returning: Array<{ __typename?: "Contacts"; id: any }>;
  } | null;
};

export type AddDealsContactMutationVariables = Exact<{
  dealContacts: Array<DealsContacts_Insert_Input> | DealsContacts_Insert_Input;
}>;

export type AddDealsContactMutation = {
  __typename?: "mutation_root";
  insert_DealsContacts?: {
    __typename?: "DealsContacts_mutation_response";
    returning: Array<{ __typename?: "DealsContacts"; id: any }>;
  } | null;
};

export type GetExistingContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExistingContactsQuery = {
  __typename?: "query_root";
  Contacts: Array<{
    __typename?: "Contacts";
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    trading_name?: string | null;
    legal_name?: string | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      trading_name?: string | null;
      legal_name?: string | null;
      acn?: string | null;
    } | null;
  }>;
};

export type GetAllContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllContactsQuery = {
  __typename?: "query_root";
  Contacts: Array<{
    __typename?: "Contacts";
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    middle_name?: string | null;
    email?: string | null;
    phone?: string | null;
    content?: string | null;
    address_id?: any | null;
    trading_name?: string | null;
    legal_name?: string | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      trading_name?: string | null;
      legal_name?: string | null;
      acn?: string | null;
    } | null;
    Location?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
  }>;
};

export type GetAllContactsByCompanyQueryVariables = Exact<{
  company_id: Scalars["bigint"];
}>;

export type GetAllContactsByCompanyQuery = {
  __typename?: "query_root";
  Contacts: Array<{
    __typename?: "Contacts";
    id: any;
    first_name?: string | null;
    last_name?: string | null;
    middle_name?: string | null;
    email?: string | null;
    phone?: string | null;
    content?: string | null;
    address_id?: any | null;
    trading_name?: string | null;
    legal_name?: string | null;
    Company?: {
      __typename?: "Companies";
      id: any;
      trading_name?: string | null;
      legal_name?: string | null;
      acn?: string | null;
    } | null;
    Location?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
      State?: { __typename?: "States"; id: any; name?: string | null } | null;
    } | null;
  }>;
};

export type CreateBaseLandlordServicesMutationVariables = Exact<{
  object?: InputMaybe<BaseLandlordServices_Insert_Input>;
}>;

export type CreateBaseLandlordServicesMutation = {
  __typename?: "mutation_root";
  insert_BaseLandlordServices_one?: {
    __typename?: "BaseLandlordServices";
    id: any;
  } | null;
};

export type DealCreationSpecialConditionsMutationVariables = Exact<{
  deal_options:
    | Array<DealSpecialConditions_Insert_Input>
    | DealSpecialConditions_Insert_Input;
}>;

export type DealCreationSpecialConditionsMutation = {
  __typename?: "mutation_root";
  insert_DealSpecialConditions?: {
    __typename?: "DealSpecialConditions_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateCreateDealFitoutLandlordWorksMutationVariables = Exact<{
  update_object: DealFitoutLandlordWorks_Insert_Input;
}>;

export type UpdateCreateDealFitoutLandlordWorksMutation = {
  __typename?: "mutation_root";
  insert_DealFitoutLandlordWorks_one?: {
    __typename?: "DealFitoutLandlordWorks";
    id: any;
  } | null;
};

export type SubGetActivityLogSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type SubGetActivityLogSubscription = {
  __typename?: "subscription_root";
  ActivityLog: Array<{
    __typename?: "ActivityLog";
    id: any;
    user_id?: any | null;
    old_value: string;
    new_value: string;
    event_id: string;
    change_key: string;
    created_at: any;
    log_type?: ActivityLogType_Enum | null;
    display_string?: string | null;
    User?: {
      __typename?: "Users";
      id: any;
      username?: string | null;
      user_full_name?: string | null;
      status: number;
    } | null;
  }>;
};

export type CancelDealMutationVariables = Exact<{
  dealId: Scalars["bigint"];
  cancelDate: Scalars["date"];
  capitalIncurred: Scalars["Boolean"];
  cancelComment?: InputMaybe<Scalars["String"]>;
}>;

export type CancelDealMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    affected_rows: number;
  } | null;
  insert_Comments_one?: {
    __typename?: "Comments";
    id: any;
    text?: string | null;
  } | null;
};

export type CreateCommentMutationVariables = Exact<{
  text: Scalars["String"];
  deal_id: Scalars["bigint"];
  user_tags_object?: InputMaybe<
    Array<CommentUserTags_Insert_Input> | CommentUserTags_Insert_Input
  >;
  tags_object?: InputMaybe<
    Array<CommentTags_Insert_Input> | CommentTags_Insert_Input
  >;
}>;

export type CreateCommentMutation = {
  __typename?: "mutation_root";
  insert_Comments_one?: {
    __typename?: "Comments";
    id: any;
    text?: string | null;
    CommentUserTags: Array<{
      __typename?: "CommentUserTags";
      id: number;
      User: { __typename?: "Users"; id: any; user_full_name?: string | null };
    }>;
    CommentTags: Array<{
      __typename?: "CommentTags";
      id: any;
      Tag?: { __typename?: "Tags"; id: any; name?: string | null } | null;
    }>;
  } | null;
};

export type CreateCommentBulkMutationVariables = Exact<{
  object: Comments_Insert_Input;
}>;

export type CreateCommentBulkMutation = {
  __typename?: "mutation_root";
  insert_Comments_one?: {
    __typename?: "Comments";
    id: any;
    text?: string | null;
  } | null;
};

export type CreateCommentTagMutationVariables = Exact<{
  comment_id: Scalars["bigint"];
  tag_id: Scalars["bigint"];
}>;

export type CreateCommentTagMutation = {
  __typename?: "mutation_root";
  insert_CommentTags_one?: {
    __typename?: "CommentTags";
    id: any;
    Tag?: { __typename?: "Tags"; name?: string | null } | null;
    Comment?: { __typename?: "Comments"; text?: string | null } | null;
  } | null;
};

export type CreateCommentTagBulkMutationVariables = Exact<{
  objects: Array<CommentTags_Insert_Input> | CommentTags_Insert_Input;
}>;

export type CreateCommentTagBulkMutation = {
  __typename?: "mutation_root";
  insert_CommentTags?: {
    __typename?: "CommentTags_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealCommentsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealCommentsQuery = {
  __typename?: "query_root";
  Comments: Array<{
    __typename?: "Comments";
    id: any;
    text?: string | null;
    created_at?: any | null;
    User?: {
      __typename?: "Users";
      id: any;
      status: number;
      user_full_name?: string | null;
      username?: string | null;
    } | null;
    CommentTags: Array<{
      __typename?: "CommentTags";
      Tag?: { __typename?: "Tags"; id: any; name?: string | null } | null;
    }>;
    CommentUserTags: Array<{
      __typename?: "CommentUserTags";
      User: {
        __typename?: "Users";
        id: any;
        status: number;
        user_full_name?: string | null;
        username?: string | null;
      };
    }>;
  }>;
};

export type GetAllDealContactsListQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetAllDealContactsListQuery = {
  __typename?: "query_root";
  DealsContacts: Array<{
    __typename?: "DealsContacts";
    id: any;
    tag?: string | null;
    Contact?: {
      __typename?: "Contacts";
      email?: string | null;
      first_name?: string | null;
      id: any;
      last_name?: string | null;
      middle_name?: string | null;
      phone?: string | null;
      content?: string | null;
    } | null;
  }>;
  DealBusinessContacts: Array<{
    __typename?: "DealBusinessContacts";
    id: any;
    tag?: string | null;
    Contact?: {
      __typename?: "Contacts";
      email?: string | null;
      first_name?: string | null;
      id: any;
      last_name?: string | null;
      middle_name?: string | null;
      phone?: string | null;
      content?: string | null;
    } | null;
  }>;
};

export type AddDealBusinessContactMutationVariables = Exact<{
  dealContacts:
    | Array<DealBusinessContacts_Insert_Input>
    | DealBusinessContacts_Insert_Input;
}>;

export type AddDealBusinessContactMutation = {
  __typename?: "mutation_root";
  insert_DealBusinessContacts?: {
    __typename?: "DealBusinessContacts_mutation_response";
    returning: Array<{ __typename?: "DealBusinessContacts"; id: any }>;
  } | null;
};

export type UpdateDealBusinessContactMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  contact_id: Scalars["bigint"];
  _set?: InputMaybe<DealBusinessContacts_Set_Input>;
}>;

export type UpdateDealBusinessContactMutation = {
  __typename?: "mutation_root";
  update_DealBusinessContacts?: {
    __typename?: "DealBusinessContacts_mutation_response";
    returning: Array<{ __typename?: "DealBusinessContacts"; id: any }>;
  } | null;
};

export type DeleteDealBusinessContactMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  contact_id: Scalars["bigint"];
}>;

export type DeleteDealBusinessContactMutation = {
  __typename?: "mutation_root";
  delete_DealBusinessContacts?: {
    __typename?: "DealBusinessContacts_mutation_response";
    affected_rows: number;
  } | null;
};

export type CopyDealReLafMutationVariables = Exact<{
  dealId: Scalars["Int"];
  comment: Scalars["String"];
}>;

export type CopyDealReLafMutation = {
  __typename?: "mutation_root";
  copyDealRelaf?: { __typename?: "newDealId"; newDealId: number } | null;
};

export type CopyDealMutationVariables = Exact<{
  dealId: Scalars["Int"];
  comment: Scalars["String"];
  unit: Unit;
}>;

export type CopyDealMutation = {
  __typename?: "mutation_root";
  copyDeal?: { __typename?: "newDealId"; newDealId: number } | null;
};

export type UpdateDealPhaseMutationVariables = Exact<{
  id: Scalars["bigint"];
  status_id: Scalars["Int"];
}>;

export type UpdateDealPhaseMutation = {
  __typename?: "mutation_root";
  update_DealPhases?: {
    __typename?: "DealPhases_mutation_response";
    returning: Array<{ __typename?: "DealPhases"; id: any }>;
  } | null;
};

export type UpsertActionAllocationMutationVariables = Exact<{
  object?: InputMaybe<ActionAllocations_Insert_Input>;
}>;

export type UpsertActionAllocationMutation = {
  __typename?: "mutation_root";
  insert_ActionAllocations_one?: {
    __typename?: "ActionAllocations";
    id: any;
  } | null;
};

export type GetActionAllocationQueryVariables = Exact<{
  deal_action_id: Scalars["bigint"];
}>;

export type GetActionAllocationQuery = {
  __typename?: "query_root";
  ActionAllocations: Array<{ __typename?: "ActionAllocations"; id: any }>;
};

export type DeleteActionAllocationMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type DeleteActionAllocationMutation = {
  __typename?: "mutation_root";
  delete_ActionAllocations?: {
    __typename?: "ActionAllocations_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealPhaseStatusMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  deal_phase_id: Scalars["bigint"];
  status_id: Scalars["Int"];
}>;

export type UpdateDealPhaseStatusMutation = {
  __typename?: "mutation_root";
  update_DealPhases?: {
    __typename?: "DealPhases_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetFirstTdPhaseQueryVariables = Exact<{
  deal_id?: InputMaybe<Scalars["bigint"]>;
}>;

export type GetFirstTdPhaseQuery = {
  __typename?: "query_root";
  DealPhases: Array<{
    __typename?: "DealPhases";
    id: any;
    name?: string | null;
    PhaseDealStatus?: {
      __typename?: "PhaseDealStatus";
      id: any;
      name: string;
      description?: string | null;
    } | null;
  }>;
};

export type UpdateDealStatusMutationVariables = Exact<{
  dealId: Scalars["bigint"];
  status?: InputMaybe<DealStatus_Enum>;
  filter?: Deals_Bool_Exp;
}>;

export type UpdateDealStatusMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealPhaseStatusQueryVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type GetDealPhaseStatusQuery = {
  __typename?: "query_root";
  DealPhases_by_pk?: {
    __typename?: "DealPhases";
    name?: string | null;
    deal_id?: any | null;
    PhaseDealStatus?: {
      __typename?: "PhaseDealStatus";
      id: any;
      name: string;
    } | null;
  } | null;
};

export type DeleteDealMutationVariables = Exact<{
  dealId: Scalars["bigint"];
}>;

export type DeleteDealMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    affected_rows: number;
  } | null;
};

export type GenerateDocumentMutationVariables = Exact<{
  deal_id?: InputMaybe<Scalars["bigint"]>;
  template_id?: InputMaybe<Scalars["bigint"]>;
}>;

export type GenerateDocumentMutation = {
  __typename?: "mutation_root";
  insert_DocumentRequests_one?: {
    __typename?: "DocumentRequests";
    id: any;
    deal_id: any;
    template_id: any;
  } | null;
};

export type SubGetDealDocumentsSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type SubGetDealDocumentsSubscription = {
  __typename?: "subscription_root";
  GeneratedDocuments: Array<{
    __typename?: "GeneratedDocuments";
    id: any;
    document_name?: string | null;
    full_path?: string | null;
    generated_at?: any | null;
    has_error: boolean;
    errors_json?: string | null;
  }>;
};

export type GetDealFilesQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealFilesQuery = {
  __typename?: "query_root";
  DealFiles: Array<{
    __typename?: "DealFiles";
    id: any;
    file_name: string;
    file_path: string;
    modified_at?: any | null;
    Users: { __typename?: "Users"; user_full_name?: string | null };
    DealFileTags: Array<{
      __typename?: "DealFileTags";
      Tags: { __typename?: "Tags"; name?: string | null; id: any };
    }>;
  }>;
};

export type CreateDealFileMutationVariables = Exact<{
  file_object: DealFiles_Insert_Input;
}>;

export type CreateDealFileMutation = {
  __typename?: "mutation_root";
  insert_DealFiles_one?: { __typename?: "DealFiles"; id: any } | null;
};

export type CreateDealFileTagsMutationVariables = Exact<{
  tag_object: Array<DealFileTags_Insert_Input> | DealFileTags_Insert_Input;
}>;

export type CreateDealFileTagsMutation = {
  __typename?: "mutation_root";
  insert_DealFileTags?: {
    __typename?: "DealFileTags_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealFileMutationVariables = Exact<{
  file_id: Scalars["bigint"];
  file_name: Scalars["String"];
  tag_object?: InputMaybe<
    Array<DealFileTags_Insert_Input> | DealFileTags_Insert_Input
  >;
}>;

export type UpdateDealFileMutation = {
  __typename?: "mutation_root";
  update_DealFiles_by_pk?: { __typename?: "DealFiles"; id: any } | null;
  insert_DealFileTags?: {
    __typename?: "DealFileTags_mutation_response";
    returning: Array<{ __typename?: "DealFileTags"; id: any }>;
  } | null;
};

export type DeleteOldFileTagsMutationVariables = Exact<{
  file_id: Scalars["bigint"];
  new_n_updated_tag_ids: Array<Scalars["bigint"]> | Scalars["bigint"];
}>;

export type DeleteOldFileTagsMutation = {
  __typename?: "mutation_root";
  delete_DealFileTags?: {
    __typename?: "DealFileTags_mutation_response";
    affected_rows: number;
  } | null;
};

export type SoftDeleteFileMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type SoftDeleteFileMutation = {
  __typename?: "mutation_root";
  update_DealFiles_by_pk?: {
    __typename?: "DealFiles";
    id: any;
    not_deleted?: boolean | null;
  } | null;
};

export type GetDealLinksQueryVariables = Exact<{
  deal_id: Scalars["Int"];
}>;

export type GetDealLinksQuery = {
  __typename?: "query_root";
  DealLinks: Array<{
    __typename?: "DealLinks";
    id: number;
    dealByDealLinkId: {
      __typename?: "Deals";
      id: any;
      laf_version: number;
      status: DealStatus_Enum;
    };
  }>;
};

export type GetTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTagsQuery = {
  __typename?: "query_root";
  Tags: Array<{ __typename?: "Tags"; id: any; name?: string | null }>;
};

export type CreateTagsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Tags_Insert_Input> | Tags_Insert_Input>;
}>;

export type CreateTagsMutation = {
  __typename?: "mutation_root";
  insert_Tags?: {
    __typename?: "Tags_mutation_response";
    affected_rows: number;
    returning: Array<{ __typename?: "Tags"; id: any; name?: string | null }>;
  } | null;
};

export type GetTradingNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTradingNamesQuery = {
  __typename?: "query_root";
  Deals: Array<{ __typename?: "Deals"; trading_name?: string | null }>;
};

export type GetTermsDataQueryVariables = Exact<{
  DealID?: InputMaybe<Scalars["bigint"]>;
}>;

export type GetTermsDataQuery = {
  __typename?: "query_root";
  DealData: Array<{
    __typename?: "DealData";
    analyst_deal_comp?: any | null;
    analyst_deal_reason?: string | null;
    analyst_normalised_base_rent?: any | null;
    analyst_normalised_promo?: any | null;
    annual_increase?: any | null;
    annual_increase_type?: string | null;
    bank_guarantee_amount?: any | null;
    bank_guarantee_id?: number | null;
    bank_guarantee_months?: number | null;
    base_net_rent: any;
    cash_incentive?: any | null;
    comments?: string | null;
    created_at?: any | null;
    deal_id: any;
    development_fees?: any | null;
    fitout_contribution?: any | null;
    fitout_term: number;
    fixed_handover_date?: boolean | null;
    gross_rent?: any | null;
    guarantors_id?: number | null;
    handover_date: string;
    hoarding_amt?: any | null;
    hoarding_id?: number | null;
    id: any;
    last_modified_by?: string | null;
    lease_start: string;
    lease_term: number;
    mat_sales?: any | null;
    rent_free_period?: any | null;
    metadata?: string | null;
    notice_address_id?: any | null;
    notice_email?: string | null;
    number_of_carparks?: number | null;
    number_of_signs?: number | null;
    opening_promotion_levy_fees?: any | null;
    outgoings_psm?: any | null;
    outgoings_state?: string | null;
    parking_licence_required?: boolean | null;
    parking_location?: string | null;
    parking_rent?: any | null;
    promotion_amt?: any | null;
    promotion_levy_state?: string | null;
    promotion_levy_type?: string | null;
    recovery_survey_fee?: any | null;
    rent_deposit?: any | null;
    rent_deposit_months?: number | null;
    rubbish_removal_fees?: any | null;
    seating_area?: any | null;
    seating_licence_required?: boolean | null;
    seating_rent?: any | null;
    security_amt?: any | null;
    security_id?: number | null;
    signage_licence_required?: boolean | null;
    signage_location?: string | null;
    signage_rent?: any | null;
    storage_area?: any | null;
    storage_licence_required?: boolean | null;
    storage_rent?: any | null;
    tenancy_coordination?: any | null;
    tenancy_coordination_fee?: any | null;
    tenant_contribution_landlord_works?: any | null;
    turn_over_rent_unnatural?: any | null;
    turnover_based_rent_percent?: any | null;
    turnover_rent_state?: string | null;
    version?: number | null;
    BaseLandlordServices: Array<{
      __typename?: "BaseLandlordServices";
      air_conditioning?: boolean | null;
      as_per_existing_toggle?: boolean | null;
      cool_room?: boolean | null;
      created_at?: any | null;
      deal_data_id?: any | null;
      electrical_dist_load_1_phase?: boolean | null;
      electrical_dist_load_3_phase?: boolean | null;
      floor_covering?: boolean | null;
      grease_trap?: boolean | null;
      hot_water_services?: boolean | null;
      id: any;
      lighting?: boolean | null;
      mechanical_exhaust?: boolean | null;
      modified_at?: any | null;
      other?: boolean | null;
      other_description?: string | null;
      painted_walls?: boolean | null;
      separate_utility_meter_gas?: boolean | null;
      separate_utility_meter_water?: boolean | null;
      shop_front?: boolean | null;
      sink?: boolean | null;
      sprinklers?: boolean | null;
      suspended_ceilings?: boolean | null;
      telephone?: boolean | null;
      waste?: boolean | null;
      water_supply?: boolean | null;
    }>;
    Deal: {
      __typename?: "Deals";
      tenant_code?: string | null;
      created_at?: any | null;
      created_by: any;
      deal_type_id: any;
      deleted_at?: any | null;
      id: any;
      name?: string | null;
      owner?: any | null;
      owner_description?: string | null;
      tenant_type_id?: number | null;
      trading_name?: string | null;
      DealType: { __typename?: "DealTypes"; description?: string | null };
      YardiTenants_Deals: Array<{
        __typename?: "YardiTenants_Deals";
        TenantCode: string;
      }>;
      DealsCompanies: Array<{
        __typename?: "DealsCompanies";
        Company?: {
          __typename?: "Companies";
          legal_name?: string | null;
          trading_name?: string | null;
          acn?: string | null;
          email?: string | null;
          phone?: string | null;
          Location?: {
            __typename?: "Locations";
            street1?: string | null;
            street2?: string | null;
            suburb?: string | null;
            postcode?: number | null;
            State?: {
              __typename?: "States";
              name?: string | null;
              Country?: {
                __typename?: "Countries";
                name?: string | null;
              } | null;
            } | null;
          } | null;
          Contacts: Array<{
            __typename?: "Contacts";
            email?: string | null;
            content?: string | null;
            first_name?: string | null;
            middle_name?: string | null;
            last_name?: string | null;
            legal_name?: string | null;
            phone?: string | null;
            trading_name?: string | null;
          }>;
        } | null;
      }>;
      DealsContacts: Array<{
        __typename?: "DealsContacts";
        contact_id?: any | null;
        contact_type: number;
        created_at?: any | null;
        deal_id?: any | null;
        id: any;
        modified_at?: any | null;
        tag?: string | null;
        Contact?: {
          __typename?: "Contacts";
          address_id?: any | null;
          alternate_email?: string | null;
          company_id?: any | null;
          created_at?: any | null;
          id: any;
          modified_at?: any | null;
          email?: string | null;
          content?: string | null;
          first_name?: string | null;
          middle_name?: string | null;
          last_name?: string | null;
          legal_name?: string | null;
          phone?: string | null;
          trading_name?: string | null;
          Company?: {
            __typename?: "Companies";
            address_id?: any | null;
            created_at?: any | null;
            id: any;
            modified_at?: any | null;
            legal_name?: string | null;
            trading_name?: string | null;
            acn?: string | null;
            email?: string | null;
            phone?: string | null;
            Contacts: Array<{
              __typename?: "Contacts";
              alternate_email?: string | null;
              company_id?: any | null;
              created_at?: any | null;
              id: any;
              modified_at?: any | null;
              email?: string | null;
              content?: string | null;
              first_name?: string | null;
              middle_name?: string | null;
              last_name?: string | null;
              legal_name?: string | null;
              phone?: string | null;
              trading_name?: string | null;
              Location?: {
                __typename?: "Locations";
                created_at?: any | null;
                id: any;
                modified_at?: any | null;
                street1?: string | null;
                street2?: string | null;
                suburb?: string | null;
                postcode?: number | null;
                State?: {
                  __typename?: "States";
                  name?: string | null;
                  Country?: {
                    __typename?: "Countries";
                    name?: string | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
          Location?: {
            __typename?: "Locations";
            created_at?: any | null;
            id: any;
            modified_at?: any | null;
            street1?: string | null;
            street2?: string | null;
            suburb?: string | null;
            postcode?: number | null;
            State?: {
              __typename?: "States";
              name?: string | null;
              Country?: {
                __typename?: "Countries";
                name?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }>;
      Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
      TD_Assignee?: {
        __typename?: "Users";
        user_full_name?: string | null;
      } | null;
      LA_Assignee?: {
        __typename?: "Users";
        user_full_name?: string | null;
      } | null;
      TenantType?: { __typename?: "TenantTypes"; title?: string | null } | null;
    };
    DealDataCustomLicences: Array<{
      __typename?: "DealDataCustomLicences";
      created_at?: any | null;
      modified_at?: any | null;
      CustomLicences?: {
        __typename?: "CustomLicences";
        modified_at?: any | null;
        licence_description?: string | null;
        licence_name?: string | null;
        licence_rent?: any | null;
        created_at?: any | null;
      } | null;
    }>;
    DealDataGuarantors: Array<{
      __typename?: "DealDataGuarantors";
      created_at?: any | null;
      deal_data_id?: any | null;
      email?: string | null;
      full_name?: string | null;
      id: any;
      modified_at?: any | null;
      postcode?: number | null;
      state_id?: number | null;
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      State?: {
        __typename?: "States";
        name?: string | null;
        Country?: { __typename?: "Countries"; name?: string | null } | null;
      } | null;
    }>;
    DealExtensionItems: Array<{
      __typename?: "DealExtensionItems";
      created_at?: any | null;
      deal_data_id?: any | null;
      id: any;
      item_data?: string | null;
      tag?: string | null;
    }>;
    DealFitoutLandlordWork?: {
      __typename?: "DealFitoutLandlordWorks";
      capped_category?: any | null;
      cost_landlord_works?: any | null;
      cost_makegood?: any | null;
      date_complete_fitout?: string | null;
      deal_data_id?: any | null;
      fitout_description?: string | null;
      fitout_previous_tenant?: string | null;
      hoarding?: number | null;
      id: any;
      landlord_undertake_works?: boolean | null;
      landlord_works_type?: string | null;
      partial_fitout_items?: string | null;
      preview?: string | null;
      refurbished_fitout_works?: boolean | null;
      skins?: number | null;
      tenancy_delivery_deal_type?: string | null;
      tenant_works?: boolean | null;
      tenant_works_options?: string | null;
    } | null;
    DealOptions: Array<{
      __typename?: "DealOptions";
      created_at?: any | null;
      deal_data_id?: any | null;
      first_year_amount?: number | null;
      first_year_type?: string | null;
      id: any;
      modified_at?: any | null;
      subsequent_year_amount?: number | null;
      subsequent_year_type?: string | null;
      term_length?: number | null;
      works_required?: string | null;
    }>;
    DealPermittedUses: Array<{
      __typename?: "DealPermittedUses";
      created_at?: any | null;
      deal_data_id: any;
      description?: string | null;
      food_menu?: string | null;
      id: any;
      sublet?: string | null;
      template_id?: any | null;
      title?: string | null;
      TemplatePermittedUse?: {
        __typename?: "TemplatePermittedUses";
        title?: string | null;
        description?: string | null;
      } | null;
    }>;
    DealSpecialConditions: Array<{
      __typename?: "DealSpecialConditions";
      created_at?: any | null;
      deal_data_id: any;
      description?: string | null;
      id: any;
      laf_description?: string | null;
      template_id?: any | null;
      title?: string | null;
      TemplateSpecialCondition?: {
        __typename?: "TemplateSpecialConditions";
        title?: string | null;
        description?: string | null;
      } | null;
    }>;
    DealsUnits: Array<{
      __typename?: "DealsUnits";
      deal_data_id?: any | null;
      id: any;
      unit_key?: string | null;
      Unit?: {
        __typename?: "Units";
        area_survey_required?: boolean | null;
        estimated_area?: any | null;
        label?: string | null;
        survey_area?: any | null;
        UnitType?: string | null;
        Property?: {
          __typename?: "Properties";
          property_code?: string | null;
          center_under_development?: string | null;
          label?: string | null;
          notes?: string | null;
          street1?: string | null;
          street2?: string | null;
          suburb?: string | null;
          country?: string | null;
        } | null;
      } | null;
    }>;
    hoardingTriSelectEnum?: {
      __typename?: "TriSelectEnum";
      id: any;
      name?: string | null;
    } | null;
    triSelectEnumByBankGuaranteeId?: {
      __typename?: "TriSelectEnum";
      id: any;
      name?: string | null;
    } | null;
    triSelectEnumByGuarantorsId?: {
      __typename?: "TriSelectEnum";
      id: any;
      name?: string | null;
    } | null;
    triSelectEnumBySecurityId?: {
      __typename?: "TriSelectEnum";
      id: any;
      name?: string | null;
    } | null;
    DealDataCalculations?: {
      __typename?: "DealDataCalculations";
      promo_calc?: any | null;
      outgoings_calc?: any | null;
      annual_promo?: any | null;
      rent_deposit_monthly?: any | null;
    } | null;
    NoticeServingAddress?: {
      __typename?: "Locations";
      street1?: string | null;
      street2?: string | null;
      suburb?: string | null;
      postcode?: number | null;
    } | null;
  }>;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: "query_root";
  Users: Array<{
    __typename?: "Users";
    id: any;
    user_full_name?: string | null;
    username?: string | null;
    UserStatus: {
      __typename?: "UserStatus";
      id: any;
      description?: string | null;
    };
  }>;
};

export type UpdateTDassigneeMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  user_id: Scalars["uuid"];
  description: Scalars["String"];
}>;

export type UpdateTDassigneeMutation = {
  __typename?: "mutation_root";
  update_Deals_by_pk?: {
    __typename?: "Deals";
    td_assignee?: any | null;
    td_assignee_description?: string | null;
  } | null;
};

export type UpdateLAassigneeMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  user_id: Scalars["uuid"];
  description: Scalars["String"];
}>;

export type UpdateLAassigneeMutation = {
  __typename?: "mutation_root";
  update_Deals_by_pk?: {
    __typename?: "Deals";
    la_assignee?: any | null;
    la_assignee_description?: string | null;
  } | null;
};

export type UpdateOwnerMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  user_id: Scalars["uuid"];
  description: Scalars["String"];
}>;

export type UpdateOwnerMutation = {
  __typename?: "mutation_root";
  update_Deals_by_pk?: {
    __typename?: "Deals";
    owner?: any | null;
    owner_description?: string | null;
  } | null;
};

export type GetYardiContactsByTenantQueryVariables = Exact<{
  tenant_code?: InputMaybe<Scalars["String"]>;
}>;

export type GetYardiContactsByTenantQuery = {
  __typename?: "query_root";
  YardiTenant_YardiContacts: Array<{
    __typename?: "YardiTenant_YardiContacts";
    YardiContact: {
      __typename?: "YardiContacts";
      Address1?: string | null;
      Address2?: string | null;
      Address3?: string | null;
      Address4?: string | null;
      AlternateEmail?: string | null;
      City?: string | null;
      CompanyName?: string | null;
      Country?: string | null;
      Description?: string | null;
      Email?: string | null;
      ExtRefId?: string | null;
      FirstName?: string | null;
      IsPrimary?: string | null;
      LastName?: string | null;
      Notes?: string | null;
      PhoneNumber1?: string | null;
      PhoneNumber10?: string | null;
      PhoneNumber2?: string | null;
      PhoneNumber3?: string | null;
      PhoneNumber4?: string | null;
      PhoneNumber5?: string | null;
      PhoneNumber6?: string | null;
      PhoneNumber7?: string | null;
      PhoneNumber8?: string | null;
      PhoneNumber9?: string | null;
      RecordType?: string | null;
      Salutation?: string | null;
      State?: string | null;
      TenantCode?: string | null;
      Title?: string | null;
      URL?: string | null;
      ZipCode?: string | null;
      deleted_at?: any | null;
    };
    YardiTenant: {
      __typename?: "YardiTenants";
      TenantName?: string | null;
      TenantField1?: string | null;
      TenantField2?: string | null;
    };
  }>;
};

export type GetYardiLimitedCustomersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetYardiLimitedCustomersQuery = {
  __typename?: "query_root";
  YardiLimitedCustomers: Array<{
    __typename?: "YardiLimitedCustomers";
    CustomerName?: string | null;
    CustomerCode?: string | null;
    ParentCustomerCode?: string | null;
  }>;
};

export type GetChargesInformationQueryVariables = Exact<{
  deal_id?: InputMaybe<Scalars["bigint"]>;
}>;

export type GetChargesInformationQuery = {
  __typename?: "query_root";
  DealData: Array<{
    __typename?: "DealData";
    tenancy_coordination_fee?: any | null;
    tenant_contribution_landlord_works?: any | null;
    recovery_survey_fee?: any | null;
    opening_promotion_levy_fees?: any | null;
    development_fees?: any | null;
    rubbish_removal_fees?: any | null;
    DealExtensionItems: Array<{
      __typename?: "DealExtensionItems";
      id: any;
      item_data?: string | null;
      tag?: string | null;
    }>;
  }>;
};

export type UpdateChargesInformationMutationVariables = Exact<{
  deal_id?: InputMaybe<Scalars["bigint"]>;
  data?: InputMaybe<DealData_Set_Input>;
}>;

export type UpdateChargesInformationMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateDealExtensionItemsMutationVariables = Exact<{
  data?: InputMaybe<
    Array<DealExtensionItems_Insert_Input> | DealExtensionItems_Insert_Input
  >;
}>;

export type CreateDealExtensionItemsMutation = {
  __typename?: "mutation_root";
  insert_DealExtensionItems?: {
    __typename?: "DealExtensionItems_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteDealCustomChargesMutationVariables = Exact<{
  deal_data_id?: InputMaybe<Scalars["bigint"]>;
}>;

export type DeleteDealCustomChargesMutation = {
  __typename?: "mutation_root";
  delete_DealExtensionItems?: {
    __typename?: "DealExtensionItems_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealInfoCommercialQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInfoCommercialQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      turnover_based_rent_percent?: any | null;
      turn_over_rent_unnatural?: any | null;
      turnover_rent_state?: string | null;
      outgoings_psm?: any | null;
      outgoings_state?: string | null;
      promotion_amt?: any | null;
      promotion_levy_state?: string | null;
      promotion_levy_type?: string | null;
      base_net_rent: any;
      gross_rent?: any | null;
      annual_increase?: any | null;
      annual_increase_type?: string | null;
      fitout_contribution?: any | null;
      cash_incentive?: any | null;
      capped_occupancy_costs_state: string;
    }>;
  } | null;
};

export type GetDealInfoDateQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInfoDateQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      handover_date: string;
      lease_start: string;
      lease_term: number;
      fitout_term: number;
      fixed_handover_date?: boolean | null;
      rent_starts?: boolean | null;
      rent_free_period?: any | null;
    }>;
  } | null;
};

export type UpdateDealInfoDateMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  update_object: DealData_Set_Input;
}>;

export type UpdateDealInfoDateMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealDataInfoMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  update_object: DealData_Set_Input;
}>;

export type UpdateDealDataInfoMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealDataIdQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealDataIdQuery = {
  __typename?: "query_root";
  DealData: Array<{ __typename?: "DealData"; id: any }>;
};

export type SubDealLafApprovalsStatusSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type SubDealLafApprovalsStatusSubscription = {
  __typename?: "subscription_root";
  DealLAFApprovals: Array<{
    __typename?: "DealLAFApprovals";
    id: any;
    deal_data_id?: any | null;
    laf_action?: LafActionChoices_Enum | null;
  }>;
};

export type SubLeaseExecAllowedToEditDealSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
  started_phases?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type SubLeaseExecAllowedToEditDealSubscription = {
  __typename?: "subscription_root";
  DealPhases_aggregate: {
    __typename?: "DealPhases_aggregate";
    aggregate?: {
      __typename?: "DealPhases_aggregate_fields";
      count: number;
    } | null;
  };
};

export type AddDealShopActivityMutationVariables = Exact<{
  input: DealShopActivities_Insert_Input;
}>;

export type AddDealShopActivityMutation = {
  __typename?: "mutation_root";
  insert_DealShopActivities_one?: {
    __typename?: "DealShopActivities";
    id: any;
  } | null;
};

export type GetOpenDateFromShopHandoverActivitiesQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetOpenDateFromShopHandoverActivitiesQuery = {
  __typename?: "query_root";
  DealShopActivities: Array<{
    __typename?: "DealShopActivities";
    id: any;
    activity: DealShopActivityTypes_Enum;
    open_date?: string | null;
  }>;
};

export type GetDealTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDealTypesQuery = {
  __typename?: "query_root";
  DealTypes: Array<{
    __typename?: "DealTypes";
    id: any;
    title?: string | null;
    description?: string | null;
    parent_id: any;
  }>;
};

export type GetDealAnalystDataQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealAnalystDataQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealType: {
      __typename?: "DealTypes";
      id: any;
      title?: string | null;
      description?: string | null;
      parent_id: any;
    };
    DealData: Array<{
      __typename?: "DealData";
      analyst_deal_comp?: any | null;
      analyst_normalised_base_rent?: any | null;
      analyst_normalised_promo?: any | null;
      analyst_deal_reason?: string | null;
    }>;
  } | null;
};

export type UpdateDealAnalystDataMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  analyst_deal_comp: Scalars["numeric"];
  analyst_normalised_base_rent: Scalars["numeric"];
  analyst_normalised_promo: Scalars["numeric"];
  analyst_deal_reason: Scalars["String"];
}>;

export type UpdateDealAnalystDataMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    returning: Array<{ __typename?: "DealData"; id: any }>;
  } | null;
};

export type UpdateDealTypeMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  deal_type_id: Scalars["bigint"];
}>;

export type UpdateDealTypeMutation = {
  __typename?: "mutation_root";
  update_Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    name?: string | null;
    deal_type_id: any;
  } | null;
};

export type GetDepositInformationQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDepositInformationQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      bank_guarantee_id?: number | null;
      guarantors_id?: number | null;
      rent_deposit_months?: number | null;
      rent_deposit?: any | null;
      bank_guarantee_months?: number | null;
      bank_guarantee_amount?: any | null;
      achieved_bank_guarantee?: any | null;
      achieved_rent_deposit?: any | null;
      DealDataGuarantors: Array<{
        __typename?: "DealDataGuarantors";
        full_name?: string | null;
        email?: string | null;
        street1?: string | null;
        street2?: string | null;
        postcode?: number | null;
        suburb?: string | null;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      }>;
    }>;
  } | null;
};

export type DeleteDealDataGuarantorsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealDataGuarantorsMutation = {
  __typename?: "mutation_root";
  delete_DealDataGuarantors?: {
    __typename?: "DealDataGuarantors_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateDealDataGuarantorsMutationVariables = Exact<{
  data?: InputMaybe<
    Array<DealDataGuarantors_Insert_Input> | DealDataGuarantors_Insert_Input
  >;
}>;

export type CreateDealDataGuarantorsMutation = {
  __typename?: "mutation_root";
  insert_DealDataGuarantors?: {
    __typename?: "DealDataGuarantors_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDepositInformationMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  data?: InputMaybe<DealData_Set_Input>;
}>;

export type UpdateDepositInformationMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealFitoutLandlordWorksQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealFitoutLandlordWorksQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      DealFitoutLandlordWork?: {
        __typename?: "DealFitoutLandlordWorks";
        preview?: string | null;
        refurbished_fitout_works?: boolean | null;
        tenancy_delivery_deal_type?: string | null;
        tenant_works?: boolean | null;
        tenant_works_options?: string | null;
        capped_category?: any | null;
        cost_landlord_works?: any | null;
        cost_makegood?: any | null;
        date_complete_fitout?: string | null;
        fitout_previous_tenant?: string | null;
        landlord_undertake_works?: boolean | null;
        partial_fitout_items?: string | null;
        hoarding?: number | null;
        skins?: number | null;
        fitout_description?: string | null;
        landlord_works_type?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateDealFitoutLandlordWorksMutationVariables = Exact<{
  update_object: DealFitoutLandlordWorks_Insert_Input;
}>;

export type UpdateDealFitoutLandlordWorksMutation = {
  __typename?: "mutation_root";
  insert_DealFitoutLandlordWorks_one?: {
    __typename?: "DealFitoutLandlordWorks";
    id: any;
  } | null;
};

export type GetHistoricalDataQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
  filter_tenant_dates: Scalars["timestamptz"];
}>;

export type GetHistoricalDataQuery = {
  __typename?: "query_root";
  DealData: Array<{
    __typename?: "DealData";
    historical_base_rent?: any | null;
    historical_outgoings?: any | null;
    historical_promo_levy?: any | null;
    historical_tenant_name: string;
    DealsUnits: Array<{
      __typename?: "DealsUnits";
      YardiUnit?: {
        __typename?: "YardiUnits";
        YardiTenantToUnits: Array<{
          __typename?: "YardiTenantToUnits";
          YardiTenant: {
            __typename?: "YardiTenants";
            TenantCode: string;
            TenantName?: string | null;
            BaseRent?: string | null;
            PromoLevy?: string | null;
            Outgoings?: string | null;
          };
        }>;
      } | null;
    }>;
  }>;
};

export type UpdateHistoricalDataMutationVariables = Exact<{
  deal_data_id: Scalars["bigint"];
  historical_base_rent?: InputMaybe<Scalars["numeric"]>;
  historical_outgoings?: InputMaybe<Scalars["numeric"]>;
  historical_promo_levy?: InputMaybe<Scalars["numeric"]>;
  historical_tenant_name?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateHistoricalDataMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetLandlordInformationQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLandlordInformationQuery = {
  __typename?: "query_root";
  DealData: Array<{
    __typename?: "DealData";
    BaseLandlordServices: Array<{
      __typename?: "BaseLandlordServices";
      id: any;
      air_conditioning?: boolean | null;
      as_per_existing_toggle?: boolean | null;
      cool_room?: boolean | null;
      electrical_dist_load_1_phase?: boolean | null;
      electrical_dist_load_3_phase?: boolean | null;
      floor_covering?: boolean | null;
      grease_trap?: boolean | null;
      hot_water_services?: boolean | null;
      lighting?: boolean | null;
      mechanical_exhaust?: boolean | null;
      other?: boolean | null;
      other_description?: string | null;
      painted_walls?: boolean | null;
      separate_utility_meter_gas?: boolean | null;
      separate_utility_meter_water?: boolean | null;
      separate_utility_meter_electricity?: boolean | null;
      shop_front?: boolean | null;
      sink?: boolean | null;
      sprinklers?: boolean | null;
      suspended_ceilings?: boolean | null;
      telephone?: boolean | null;
      waste?: boolean | null;
      water_supply?: boolean | null;
      plastered_walls_unpainted?: boolean | null;
    }>;
  }>;
};

export type UpdateBaseLandlordServicesMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  data?: InputMaybe<BaseLandlordServices_Set_Input>;
}>;

export type UpdateBaseLandlordServicesMutation = {
  __typename?: "mutation_root";
  update_BaseLandlordServices?: {
    __typename?: "BaseLandlordServices_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealInfoDealOptionsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInfoDealOptionsQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      DealOptions: Array<{
        __typename?: "DealOptions";
        term_length?: number | null;
        first_year_amount?: number | null;
        first_year_type?: string | null;
        subsequent_year_amount?: number | null;
        subsequent_year_type?: string | null;
        works_required?: string | null;
      }>;
    }>;
  } | null;
};

export type DeleteDealOptionsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealOptionsMutation = {
  __typename?: "mutation_root";
  delete_DealOptions?: {
    __typename?: "DealOptions_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateDealOptionsMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<DealOptions_Insert_Input> | DealOptions_Insert_Input
  >;
}>;

export type CreateDealOptionsMutation = {
  __typename?: "mutation_root";
  insert_DealOptions?: {
    __typename?: "DealOptions_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealSpecialConditionsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealSpecialConditionsQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      DealSpecialConditions: Array<{
        __typename?: "DealSpecialConditions";
        description?: string | null;
        title?: string | null;
        laf_description?: string | null;
        TemplateSpecialCondition?: {
          __typename?: "TemplateSpecialConditions";
          id: any;
        } | null;
      }>;
    }>;
  } | null;
  TemplateSpecialConditions: Array<{
    __typename?: "TemplateSpecialConditions";
    title?: string | null;
    description?: string | null;
    laf_description?: string | null;
    id: any;
  }>;
};

export type DeleteAndInsertDealSpecialConditionsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  deal_options:
    | Array<DealSpecialConditions_Insert_Input>
    | DealSpecialConditions_Insert_Input;
}>;

export type DeleteAndInsertDealSpecialConditionsMutation = {
  __typename?: "mutation_root";
  delete_DealSpecialConditions?: {
    __typename?: "DealSpecialConditions_mutation_response";
    affected_rows: number;
  } | null;
  insert_DealSpecialConditions?: {
    __typename?: "DealSpecialConditions_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetStorageInformationQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetStorageInformationQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      storage_licence_required?: boolean | null;
      signage_licence_required?: boolean | null;
      seating_licence_required?: boolean | null;
      parking_licence_required?: boolean | null;
      storage_rent?: any | null;
      signage_rent?: any | null;
      seating_rent?: any | null;
      parking_rent?: any | null;
      storage_area?: any | null;
      seating_area?: any | null;
      signage_location?: string | null;
      parking_location?: string | null;
      number_of_signs?: number | null;
      number_of_carparks?: number | null;
      DealDataCustomLicences: Array<{
        __typename?: "DealDataCustomLicences";
        CustomLicences?: {
          __typename?: "CustomLicences";
          id: any;
          licence_name?: string | null;
          licence_rent?: any | null;
          licence_description?: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type UpdateStorageInformationMutationVariables = Exact<{
  deal_id?: InputMaybe<Scalars["bigint"]>;
  data?: InputMaybe<DealData_Set_Input>;
}>;

export type UpdateStorageInformationMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateCustomLicencesMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<CustomLicences_Insert_Input> | CustomLicences_Insert_Input
  >;
}>;

export type CreateCustomLicencesMutation = {
  __typename?: "mutation_root";
  insert_CustomLicences?: {
    __typename?: "CustomLicences_mutation_response";
    affected_rows: number;
    returning: Array<{ __typename?: "CustomLicences"; id: any }>;
  } | null;
};

export type DeleteDealDataCustomLicencesMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealDataCustomLicencesMutation = {
  __typename?: "mutation_root";
  delete_DealDataCustomLicences?: {
    __typename?: "DealDataCustomLicences_mutation_response";
    affected_rows: number;
  } | null;
};

export type CreateDealDataCustomLicencesMutationVariables = Exact<{
  data?: InputMaybe<
    | Array<DealDataCustomLicences_Insert_Input>
    | DealDataCustomLicences_Insert_Input
  >;
}>;

export type CreateDealDataCustomLicencesMutation = {
  __typename?: "mutation_root";
  insert_DealDataCustomLicences?: {
    __typename?: "DealDataCustomLicences_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealInformationTenantInformationQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInformationTenantInformationQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    tenant_type_id?: number | null;
    trading_name?: string | null;
    customer_code?: string | null;
    YardiCustomer?: {
      __typename?: "YardiCustomers";
      CustomerName?: string | null;
      CustomerCode: string;
    } | null;
    DealData: Array<{
      __typename?: "DealData";
      notice_email?: string | null;
      sales_category?: string | null;
      charge_code?: string | null;
      sales_type?: SalesType_Enum | null;
      lease_type?: string | null;
      NoticeServingAddress?: {
        __typename?: "Locations";
        id: any;
        street1?: string | null;
        street2?: string | null;
        suburb?: string | null;
        postcode?: number | null;
        State?: { __typename?: "States"; id: any; name?: string | null } | null;
      } | null;
    }>;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      contact_type: number;
      tag?: string | null;
      Contact?: {
        __typename?: "Contacts";
        id: any;
        first_name?: string | null;
        middle_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        phone?: string | null;
        content?: string | null;
        trading_name?: string | null;
        legal_name?: string | null;
        Location?: {
          __typename?: "Locations";
          id: any;
          street1?: string | null;
          street2?: string | null;
          suburb?: string | null;
          postcode?: number | null;
          State?: {
            __typename?: "States";
            id: any;
            name?: string | null;
          } | null;
        } | null;
        Company?: {
          __typename?: "Companies";
          id: any;
          legal_name?: string | null;
          trading_name?: string | null;
          acn?: string | null;
          email?: string | null;
          phone?: string | null;
          Location?: {
            __typename?: "Locations";
            id: any;
            street1?: string | null;
            street2?: string | null;
            suburb?: string | null;
            postcode?: number | null;
            State?: {
              __typename?: "States";
              id: any;
              name?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
    DealsCompanies: Array<{
      __typename?: "DealsCompanies";
      id: any;
      Contact?: {
        __typename?: "Contacts";
        id: any;
        first_name?: string | null;
        middle_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        phone?: string | null;
        address_id?: any | null;
        Location?: {
          __typename?: "Locations";
          id: any;
          street1?: string | null;
          street2?: string | null;
          suburb?: string | null;
          postcode?: number | null;
          State?: {
            __typename?: "States";
            id: any;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      Company?: {
        __typename?: "Companies";
        id: any;
        trading_name?: string | null;
        legal_name?: string | null;
        acn?: string | null;
        not_deleted?: boolean | null;
        email?: string | null;
        phone?: string | null;
        Contacts: Array<{
          __typename?: "Contacts";
          id: any;
          first_name?: string | null;
          middle_name?: string | null;
          last_name?: string | null;
          email?: string | null;
          phone?: string | null;
          trading_name?: string | null;
          legal_name?: string | null;
          Location?: {
            __typename?: "Locations";
            id: any;
            street1?: string | null;
            street2?: string | null;
            suburb?: string | null;
            postcode?: number | null;
            State?: {
              __typename?: "States";
              id: any;
              name?: string | null;
            } | null;
          } | null;
        }>;
        Location?: {
          __typename?: "Locations";
          id: any;
          street1?: string | null;
          street2?: string | null;
          suburb?: string | null;
          postcode?: number | null;
          State?: {
            __typename?: "States";
            id: any;
            name?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type UpdateDealTenantTypeMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  tenant_type: Scalars["Int"];
}>;

export type UpdateDealTenantTypeMutation = {
  __typename?: "mutation_root";
  update_Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    tenant_type_id?: number | null;
  } | null;
};

export type UpdateDealsContactsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  dealsContacts?: InputMaybe<
    Array<DealsContacts_Insert_Input> | DealsContacts_Insert_Input
  >;
}>;

export type UpdateDealsContactsMutation = {
  __typename?: "mutation_root";
  delete_DealsContacts?: {
    __typename?: "DealsContacts_mutation_response";
    affected_rows: number;
  } | null;
  insert_DealsContacts?: {
    __typename?: "DealsContacts_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteDealsContactsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealsContactsMutation = {
  __typename?: "mutation_root";
  delete_DealsContacts?: {
    __typename?: "DealsContacts_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteDealsTenantContactsMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealsTenantContactsMutation = {
  __typename?: "mutation_root";
  delete_DealsContacts?: {
    __typename?: "DealsContacts_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteDealsCompaniesMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type DeleteDealsCompaniesMutation = {
  __typename?: "mutation_root";
  delete_DealsCompanies?: {
    __typename?: "DealsCompanies_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealsCompaniesMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  dealsCompanies?: InputMaybe<
    Array<DealsCompanies_Insert_Input> | DealsCompanies_Insert_Input
  >;
}>;

export type UpdateDealsCompaniesMutation = {
  __typename?: "mutation_root";
  delete_DealsCompanies?: {
    __typename?: "DealsCompanies_mutation_response";
    affected_rows: number;
  } | null;
  insert_DealsCompanies?: {
    __typename?: "DealsCompanies_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealDataMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  deal_data?: InputMaybe<DealData_Set_Input>;
}>;

export type UpdateDealDataMutation = {
  __typename?: "mutation_root";
  update_DealData?: {
    __typename?: "DealData_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdateDealTradingNameMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  trading_name: Scalars["String"];
  customer_code?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateDealTradingNameMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetYardiCustomersQueryVariables = Exact<{ [key: string]: never }>;

export type GetYardiCustomersQuery = {
  __typename?: "query_root";
  YardiCustomers: Array<{
    __typename?: "YardiCustomers";
    CustomerCode: string;
    CustomerName?: string | null;
    CustomerStatus?: string | null;
    CustomerType?: string | null;
    ParentCustomerCode?: string | null;
    deleted_at?: any | null;
  }>;
};

export type UpdateDealCustomerMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  customer_code?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateDealCustomerMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetLeaseTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLeaseTypesQuery = {
  __typename?: "query_root";
  YardiTenants: Array<{
    __typename?: "YardiTenants";
    LeaseType?: string | null;
  }>;
};

export type GetDealInfoUnitInfoQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInfoUnitInfoQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        area_survey_required?: boolean | null;
        description?: string | null;
        Unit?: {
          __typename?: "Units";
          UnitCode?: string | null;
          label?: string | null;
          estimated_area?: any | null;
          area_survey_required?: boolean | null;
          unit_type_id?: number | null;
          UnitType?: string | null;
          UnitKey?: string | null;
          Property?: {
            __typename?: "Properties";
            property_code?: string | null;
            label?: string | null;
            center_under_development?: string | null;
          } | null;
        } | null;
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          id: any;
          label?: string | null;
          estimated_area?: any | null;
          area_survey_required?: boolean | null;
          Property?: {
            __typename?: "Properties";
            property_code?: string | null;
            label?: string | null;
            center_under_development?: string | null;
          } | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type CheckDealExistsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type CheckDealExistsQuery = {
  __typename?: "query_root";
  Deals_by_pk?: { __typename?: "Deals"; id: any } | null;
};

export type UpdateDealUnitMutationVariables = Exact<{
  deal_id: Scalars["bigint"];
  unit_id?: InputMaybe<Scalars["String"]>;
  virtual_unit_id?: InputMaybe<Scalars["bigint"]>;
  area_survey_required?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateDealUnitMutation = {
  __typename?: "mutation_root";
  update_DealsUnits?: {
    __typename?: "DealsUnits_mutation_response";
    returning: Array<{
      __typename?: "DealsUnits";
      unit_key?: string | null;
      DealDatum?: {
        __typename?: "DealData";
        Deal: { __typename?: "Deals"; id: any };
      } | null;
    }>;
  } | null;
};

export type AddVirtualUnitMutationVariables = Exact<{
  unit?: InputMaybe<VirtualUnits_Insert_Input>;
}>;

export type AddVirtualUnitMutation = {
  __typename?: "mutation_root";
  insert_VirtualUnits_one?: {
    __typename?: "VirtualUnits";
    id: any;
    unit_type?: string | null;
    created_at?: any | null;
    modified_at?: any | null;
    area_survey_required?: boolean | null;
    estimated_area?: any | null;
    label?: string | null;
    property_code?: string | null;
    survey_area?: number | null;
    deleted: boolean;
  } | null;
};

export type GetDealInfoUsageQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealInfoUsageQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      DealPermittedUses: Array<{
        __typename?: "DealPermittedUses";
        description?: string | null;
        food_menu?: string | null;
        sublet?: string | null;
        TemplatePermittedUse?: {
          __typename?: "TemplatePermittedUses";
          title?: string | null;
          id: any;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetUsagePermittedUsesTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsagePermittedUsesTemplateQuery = {
  __typename?: "query_root";
  TemplatePermittedUses: Array<{
    __typename?: "TemplatePermittedUses";
    id: any;
    title?: string | null;
    description?: string | null;
    modified_at?: any | null;
    ModifiedBy?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
  }>;
};

export type GetPermittedUseTemplateQueryVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type GetPermittedUseTemplateQuery = {
  __typename?: "query_root";
  TemplatePermittedUses_by_pk?: {
    __typename?: "TemplatePermittedUses";
    title?: string | null;
    description?: string | null;
    modified_at?: any | null;
    id: any;
    ModifiedBy?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
  } | null;
};

export type CreatePermittedUseTemplateMutationVariables = Exact<{
  data: TemplatePermittedUses_Insert_Input;
}>;

export type CreatePermittedUseTemplateMutation = {
  __typename?: "mutation_root";
  insert_TemplatePermittedUses_one?: {
    __typename?: "TemplatePermittedUses";
    id: any;
  } | null;
};

export type UpdatePermittedUseTemplateMutationVariables = Exact<{
  id: Scalars["bigint"];
  data: TemplatePermittedUses_Set_Input;
}>;

export type UpdatePermittedUseTemplateMutation = {
  __typename?: "mutation_root";
  update_TemplatePermittedUses_by_pk?: {
    __typename?: "TemplatePermittedUses";
    id: any;
  } | null;
};

export type UpdateDealPermittedUsesMutationVariables = Exact<{
  update_object: DealPermittedUses_Insert_Input;
}>;

export type UpdateDealPermittedUsesMutation = {
  __typename?: "mutation_root";
  insert_DealPermittedUses_one?: {
    __typename?: "DealPermittedUses";
    id: any;
  } | null;
};

export type DeletePermittedUseTemplateMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type DeletePermittedUseTemplateMutation = {
  __typename?: "mutation_root";
  delete_TemplatePermittedUses?: {
    __typename?: "TemplatePermittedUses_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDealsQueryVariables = Exact<{
  filters?: InputMaybe<Deals_Bool_Exp>;
}>;

export type GetDealsQuery = {
  __typename?: "query_root";
  Deals: Array<{
    __typename?: "Deals";
    id: any;
    status: DealStatus_Enum;
    trading_name?: string | null;
    customer_code?: string | null;
    tenant_code?: string | null;
    laf_version: number;
    risk_level?: DealRiskLevels_Enum | null;
    YardiTenants_Deals: Array<{
      __typename?: "YardiTenants_Deals";
      TenantCode: string;
      YardiActiveLeases?: {
        __typename?: "YardiActiveLeases";
        EndDate?: any | null;
      } | null;
    }>;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      Contact?: {
        __typename?: "Contacts";
        Company?: {
          __typename?: "Companies";
          trading_name?: string | null;
        } | null;
      } | null;
    }>;
    Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
    Comments: Array<{ __typename?: "Comments"; text?: string | null }>;
    User: { __typename?: "Users"; user_full_name?: string | null };
    LA_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    TD_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    DealPhases: Array<{
      __typename?: "DealPhases";
      name?: string | null;
      status_id?: number | null;
      is_tenancy_delivery?: boolean | null;
      DealActionItem: Array<{
        __typename?: "DealActionItem";
        id: any;
        content?: string | null;
        required: boolean;
        state: boolean;
      }>;
    }>;
    DealData: Array<{
      __typename?: "DealData";
      lease_start: string;
      lease_term: number;
      handover_date: string;
      metadata?: string | null;
      DealLAFApprovals?: {
        __typename?: "DealLAFApprovals";
        laf_action?: LafActionChoices_Enum | null;
        ActiveApprover?: {
          __typename?: "Users";
          user_full_name?: string | null;
        } | null;
      } | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: {
          __typename?: "Units";
          label?: string | null;
          UnitType?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
            property_code?: string | null;
          } | null;
        } | null;
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          label?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
            property_code?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    DealType: { __typename?: "DealTypes"; title?: string | null };
  }>;
};

export type GetDealQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    name?: string | null;
    status: DealStatus_Enum;
    laf_version: number;
    trading_name?: string | null;
    created_at?: any | null;
    tenant_code?: string | null;
    deal_made_from_active_lease: boolean;
    LA_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    TD_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
    YardiTenants_Deals: Array<{
      __typename?: "YardiTenants_Deals";
      TenantCode: string;
    }>;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      contact_type: number;
      Contact?: {
        __typename?: "Contacts";
        id: any;
        Company?: {
          __typename?: "Companies";
          trading_name?: string | null;
        } | null;
      } | null;
    }>;
    User: { __typename?: "Users"; user_full_name?: string | null };
    DealPhases: Array<{
      __typename?: "DealPhases";
      id: any;
      name?: string | null;
      is_tenancy_delivery?: boolean | null;
      sequence?: number | null;
      DealActionItem: Array<{
        __typename?: "DealActionItem";
        id: any;
        content?: string | null;
        required: boolean;
        state: boolean;
        metadata?: string | null;
        ActionAllocations: Array<{
          __typename?: "ActionAllocations";
          User: { __typename?: "Users"; user_full_name?: string | null };
        }>;
      }>;
      PhaseDealStatus?: { __typename?: "PhaseDealStatus"; id: any } | null;
    }>;
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      lease_start: string;
      lease_term: number;
      metadata?: string | null;
      rent_free_period?: any | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: {
          __typename?: "Units";
          label?: string | null;
          UnitType?: string | null;
          Property?: {
            __typename?: "Properties";
            property_code?: string | null;
            label?: string | null;
            street1?: string | null;
            street2?: string | null;
            postcode?: string | null;
            suburb?: string | null;
            country?: string | null;
          } | null;
        } | null;
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          label?: string | null;
          Property?: {
            __typename?: "Properties";
            property_code?: string | null;
            label?: string | null;
            street1?: string | null;
            street2?: string | null;
            postcode?: string | null;
            suburb?: string | null;
            country?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    DealType: { __typename?: "DealTypes"; title?: string | null };
  } | null;
};

export type GetDealRiskLevelQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetDealRiskLevelQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    risk_level?: DealRiskLevels_Enum | null;
  } | null;
};

export type UpdateDealActionItemMutationVariables = Exact<{
  id: Scalars["bigint"];
  data: DealActionItem_Set_Input;
}>;

export type UpdateDealActionItemMutation = {
  __typename?: "mutation_root";
  update_DealActionItem_by_pk?: {
    __typename?: "DealActionItem";
    id: any;
  } | null;
};

export type UpdateDealRiskLevelMutationVariables = Exact<{
  id: Scalars["bigint"];
  risk_level: DealRiskLevels_Enum;
}>;

export type UpdateDealRiskLevelMutation = {
  __typename?: "mutation_root";
  update_Deals?: {
    __typename?: "Deals_mutation_response";
    returning: Array<{ __typename?: "Deals"; id: any }>;
  } | null;
};

export type InsertDealActionItemMutationVariables = Exact<{
  object: DealActionItem_Insert_Input;
}>;

export type InsertDealActionItemMutation = {
  __typename?: "mutation_root";
  insert_DealActionItem_one?: { __typename?: "DealActionItem"; id: any } | null;
};

export type GetDealsByTenantCodeQueryVariables = Exact<{
  tenant_code?: InputMaybe<Scalars["String"]>;
}>;

export type GetDealsByTenantCodeQuery = {
  __typename?: "query_root";
  Deals: Array<{
    __typename?: "Deals";
    id: any;
    trading_name?: string | null;
    tenant_code?: string | null;
    customer_code?: string | null;
    created_at?: any | null;
    YardiTenants_Deals: Array<{
      __typename?: "YardiTenants_Deals";
      TenantCode: string;
    }>;
    Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
    DealData: Array<{
      __typename?: "DealData";
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: {
          __typename?: "Units";
          label?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
          } | null;
        } | null;
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          label?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
          } | null;
        } | null;
      }>;
    }>;
  }>;
};

export type GetLeasesForRenewalQueryVariables = Exact<{ [key: string]: never }>;

export type GetLeasesForRenewalQuery = {
  __typename?: "query_root";
  YardiLeasesForRenewal: Array<{
    __typename?: "YardiLeasesForRenewal";
    Anchor?: string | null;
    BrandCode?: string | null;
    CustomerCode?: string | null;
    EndDate?: any | null;
    IsHoldover?: string | null;
    LeaseType?: string | null;
    MaintenanceNotes?: string | null;
    Notes?: string | null;
    PropertyCode?: string | null;
    PropertyName?: string | null;
    SalesCategory?: string | null;
    TIC?: string | null;
    TenantCode?: string | null;
    TenantName?: string | null;
    TenantStartDate?: any | null;
    TenantStatus?: string | null;
    TradeName?: string | null;
    BaseRent?: string | null;
    PromoLevy?: string | null;
    Outgoings?: string | null;
    unit1?: string | null;
    unit2?: string | null;
    unit3?: string | null;
    unit4?: string | null;
    unit5?: string | null;
  }>;
};

export type GetYardiOutgoingsQueryVariables = Exact<{
  unit_key?: InputMaybe<Scalars["String"]>;
}>;

export type GetYardiOutgoingsQuery = {
  __typename?: "query_root";
  YardiUnits: Array<{
    __typename?: "YardiUnits";
    UnitKey: string;
    UnitCode: string;
    PropertyName?: string | null;
    PropertyCode?: string | null;
    UnitType?: string | null;
    UnitArea?: any | null;
    YardiUnitProfiles: Array<{
      __typename?: "YardiUnitProfiles";
      POGPSM?: any | null;
      FOGPSM?: any | null;
      DANNUALRECOVERYPSM?: any | null;
      PBASERENT?: any | null;
      FBASERENT?: any | null;
      PPROMO?: any | null;
      FPROMO?: any | null;
    }>;
  }>;
};

export type SubGetDealStatusSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type SubGetDealStatusSubscription = {
  __typename?: "subscription_root";
  Deals_by_pk?: { __typename?: "Deals"; status: DealStatus_Enum } | null;
};

export type GetLafDataQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafDataQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    name?: string | null;
    trading_name?: string | null;
    created_at?: any | null;
    TenantType?: { __typename?: "TenantTypes"; id: any } | null;
    LA_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    TD_Assignee?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
    Owner?: { __typename?: "Users"; user_full_name?: string | null } | null;
    DealsCompanies: Array<{
      __typename?: "DealsCompanies";
      Company?: {
        __typename?: "Companies";
        legal_name?: string | null;
        acn?: string | null;
      } | null;
    }>;
    DealsContacts: Array<{
      __typename?: "DealsContacts";
      contact_type: number;
      Contact?: {
        __typename?: "Contacts";
        id: any;
        first_name?: string | null;
        legal_name?: string | null;
        middle_name?: string | null;
        last_name?: string | null;
        Company?: {
          __typename?: "Companies";
          trading_name?: string | null;
        } | null;
      } | null;
    }>;
    User: { __typename?: "Users"; user_full_name?: string | null };
    DealPhases: Array<{
      __typename?: "DealPhases";
      id: any;
      name?: string | null;
      is_tenancy_delivery?: boolean | null;
      DealActionItem: Array<{
        __typename?: "DealActionItem";
        id: any;
        content?: string | null;
        metadata?: string | null;
        ActionAllocations: Array<{
          __typename?: "ActionAllocations";
          User: { __typename?: "Users"; user_full_name?: string | null };
        }>;
      }>;
      PhaseDealStatus?: { __typename?: "PhaseDealStatus"; id: any } | null;
    }>;
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      lease_start: string;
      lease_term: number;
      fitout_term: number;
      metadata?: string | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: {
          __typename?: "Units";
          estimated_area?: any | null;
          label?: string | null;
          UnitType?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
          } | null;
        } | null;
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          estimated_area?: any | null;
          label?: string | null;
          Property?: {
            __typename?: "Properties";
            label?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    DealType: { __typename?: "DealTypes"; title?: string | null };
  } | null;
};

export type GetLafDealDataQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafDealDataQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: { __typename?: "Units"; UnitCode?: string | null } | null;
        VirtualUnit?: { __typename?: "VirtualUnits"; id: any } | null;
      }>;
    }>;
  } | null;
};

export type GetLafLeaseDetailsQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafLeaseDetailsQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      capped_occupancy_costs_state: string;
      outgoings_psm?: any | null;
      base_net_rent: any;
      turn_over_rent_unnatural?: any | null;
      historical_base_rent?: any | null;
      historical_outgoings?: any | null;
      historical_promo_levy?: any | null;
      outgoings_state?: string | null;
      turnover_based_rent_percent?: any | null;
      turnover_rent_state?: string | null;
      lease_term: number;
      lease_start: string;
      promotion_levy_state?: string | null;
      promotion_levy_type?: string | null;
      promotion_amt?: any | null;
      annual_increase?: any | null;
      annual_increase_type?: string | null;
      tenancy_coordination_fee?: any | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          estimated_area?: any | null;
        } | null;
        Unit?: {
          __typename?: "Units";
          estimated_area?: any | null;
          UnitKey?: string | null;
        } | null;
        SavedYardiUnitProfile?: {
          __typename?: "YardiUnitProfiles";
          BUDGETEXPIRYDATE?: any | null;
          BUDGETFITOUTDAYS?: any | null;
          BUDGETLEASESTART?: any | null;
          BUDGETSTATUS?: string | null;
          BUDGETVACATEDATE?: any | null;
          DANNUALPROMOLEVY?: any | null;
          DANNUALRECOVERYPSM?: any | null;
          DANNUALRENT?: any | null;
          DBUDGETEDLANDLORDWORKCOST?: any | null;
          DBUDGETLANDLORDCONTRIBTOFITOUT?: any | null;
          DBUDGETMAKEGOOD?: any | null;
          DTBUDGETLEASEDATE?: any | null;
          DTPROFILEYEARSTART?: any | null;
          FBASERENT?: any | null;
          FEXPIRYDATE?: any | null;
          FFITOUTDAYS?: number | null;
          FINCENTIVE?: any | null;
          FLEASESTART?: any | null;
          FLEASETYPE?: string | null;
          FLESSORWORKS?: any | null;
          FMAKEGOOD?: any | null;
          FOGPSM?: any | null;
          FPROMO?: any | null;
          FRENTCOMMENCE?: any | null;
          FSTATUS?: string | null;
          FVACATEDATE?: any | null;
          INHOUSENETRENT?: any | null;
          PAREA?: any | null;
          PBASERENT?: any | null;
          PCAPOCCCOST?: any | null;
          POGPSM?: any | null;
          POWNERNAME?: string | null;
          PPROMO?: any | null;
          PTENANT?: string | null;
          PTENANTNAME?: string | null;
          PVACATEDATE?: any | null;
          SDEFAULTRECOVERYPROFILEDESC?: string | null;
          SECTION1?: string | null;
          SECTION2?: string | null;
          SECTION3?: string | null;
          SECTION4?: string | null;
          SECTION5?: string | null;
          SMGMTFEE?: any | null;
          UnitKey?: string | null;
          VALUATIONNETRENT?: any | null;
        } | null;
        YardiUnit?: {
          __typename?: "YardiUnits";
          YardiUnitProfiles: Array<{
            __typename?: "YardiUnitProfiles";
            BUDGETEXPIRYDATE?: any | null;
            BUDGETFITOUTDAYS?: any | null;
            BUDGETLEASESTART?: any | null;
            BUDGETSTATUS?: string | null;
            BUDGETVACATEDATE?: any | null;
            DANNUALPROMOLEVY?: any | null;
            DANNUALRECOVERYPSM?: any | null;
            DANNUALRENT?: any | null;
            DBUDGETEDLANDLORDWORKCOST?: any | null;
            DBUDGETLANDLORDCONTRIBTOFITOUT?: any | null;
            DBUDGETMAKEGOOD?: any | null;
            DTBUDGETLEASEDATE?: any | null;
            DTPROFILEYEARSTART?: any | null;
            FBASERENT?: any | null;
            FEXPIRYDATE?: any | null;
            FFITOUTDAYS?: number | null;
            FINCENTIVE?: any | null;
            FLEASESTART?: any | null;
            FLEASETYPE?: string | null;
            FLESSORWORKS?: any | null;
            FMAKEGOOD?: any | null;
            FOGPSM?: any | null;
            FPROMO?: any | null;
            FRENTCOMMENCE?: any | null;
            FSTATUS?: string | null;
            FVACATEDATE?: any | null;
            INHOUSENETRENT?: any | null;
            PAREA?: any | null;
            PBASERENT?: any | null;
            PCAPOCCCOST?: any | null;
            POGPSM?: any | null;
            POWNERNAME?: string | null;
            PPROMO?: any | null;
            PTENANT?: string | null;
            PTENANTNAME?: string | null;
            PVACATEDATE?: any | null;
            SDEFAULTRECOVERYPROFILEDESC?: string | null;
            SECTION1?: string | null;
            SECTION2?: string | null;
            SECTION3?: string | null;
            SECTION4?: string | null;
            SECTION5?: string | null;
            SMGMTFEE?: any | null;
            UnitKey?: string | null;
            VALUATIONNETRENT?: any | null;
          }>;
        } | null;
      }>;
      DealOptions: Array<{
        __typename?: "DealOptions";
        term_length?: number | null;
      }>;
    }>;
  } | null;
};

export type GetLafSecurityQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafSecurityQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      id: any;
      outgoings_psm?: any | null;
      base_net_rent: any;
      guarantors_id?: number | null;
      bank_guarantee_id?: number | null;
      bank_guarantee_amount?: any | null;
      bank_guarantee_months?: number | null;
      achieved_bank_guarantee?: any | null;
      achieved_rent_deposit?: any | null;
      rent_deposit?: any | null;
      rent_deposit_months?: number | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        Unit?: { __typename?: "Units"; estimated_area?: any | null } | null;
      }>;
      DealDataGuarantors: Array<{
        __typename?: "DealDataGuarantors";
        full_name?: string | null;
      }>;
    }>;
  } | null;
};

export type GetLafIncentiveQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafIncentiveQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      fitout_contribution?: any | null;
      cash_incentive?: any | null;
      tenant_contribution_landlord_works?: any | null;
      DealFitoutLandlordWork?: {
        __typename?: "DealFitoutLandlordWorks";
        preview?: string | null;
        cost_landlord_works?: any | null;
        landlord_undertake_works?: boolean | null;
        cost_makegood?: any | null;
        capped_category?: any | null;
      } | null;
      DealsUnits: Array<{
        __typename?: "DealsUnits";
        VirtualUnit?: {
          __typename?: "VirtualUnits";
          estimated_area?: any | null;
        } | null;
        Unit?: {
          __typename?: "Units";
          estimated_area?: any | null;
          UnitKey?: string | null;
        } | null;
        SavedYardiUnitProfile?: {
          __typename?: "YardiUnitProfiles";
          BUDGETEXPIRYDATE?: any | null;
          BUDGETFITOUTDAYS?: any | null;
          BUDGETLEASESTART?: any | null;
          BUDGETSTATUS?: string | null;
          BUDGETVACATEDATE?: any | null;
          DANNUALPROMOLEVY?: any | null;
          DANNUALRECOVERYPSM?: any | null;
          DANNUALRENT?: any | null;
          DBUDGETEDLANDLORDWORKCOST?: any | null;
          DBUDGETLANDLORDCONTRIBTOFITOUT?: any | null;
          DBUDGETMAKEGOOD?: any | null;
          DTBUDGETLEASEDATE?: any | null;
          DTPROFILEYEARSTART?: any | null;
          FBASERENT?: any | null;
          FEXPIRYDATE?: any | null;
          FFITOUTDAYS?: number | null;
          FINCENTIVE?: any | null;
          FLEASESTART?: any | null;
          FLEASETYPE?: string | null;
          FLESSORWORKS?: any | null;
          FMAKEGOOD?: any | null;
          FOGPSM?: any | null;
          FPROMO?: any | null;
          FRENTCOMMENCE?: any | null;
          FSTATUS?: string | null;
          FVACATEDATE?: any | null;
          INHOUSENETRENT?: any | null;
          PAREA?: any | null;
          PBASERENT?: any | null;
          PCAPOCCCOST?: any | null;
          POGPSM?: any | null;
          POWNERNAME?: string | null;
          PPROMO?: any | null;
          PTENANT?: string | null;
          PTENANTNAME?: string | null;
          PVACATEDATE?: any | null;
          SDEFAULTRECOVERYPROFILEDESC?: string | null;
          SECTION1?: string | null;
          SECTION2?: string | null;
          SECTION3?: string | null;
          SECTION4?: string | null;
          SECTION5?: string | null;
          SMGMTFEE?: any | null;
          UnitKey?: string | null;
          VALUATIONNETRENT?: any | null;
        } | null;
        YardiUnit?: {
          __typename?: "YardiUnits";
          YardiUnitProfiles: Array<{
            __typename?: "YardiUnitProfiles";
            BUDGETEXPIRYDATE?: any | null;
            BUDGETFITOUTDAYS?: any | null;
            BUDGETLEASESTART?: any | null;
            BUDGETSTATUS?: string | null;
            BUDGETVACATEDATE?: any | null;
            DANNUALPROMOLEVY?: any | null;
            DANNUALRECOVERYPSM?: any | null;
            DANNUALRENT?: any | null;
            DBUDGETEDLANDLORDWORKCOST?: any | null;
            DBUDGETLANDLORDCONTRIBTOFITOUT?: any | null;
            DBUDGETMAKEGOOD?: any | null;
            DTBUDGETLEASEDATE?: any | null;
            DTPROFILEYEARSTART?: any | null;
            FBASERENT?: any | null;
            FEXPIRYDATE?: any | null;
            FFITOUTDAYS?: number | null;
            FINCENTIVE?: any | null;
            FLEASESTART?: any | null;
            FLEASETYPE?: string | null;
            FLESSORWORKS?: any | null;
            FMAKEGOOD?: any | null;
            FOGPSM?: any | null;
            FPROMO?: any | null;
            FRENTCOMMENCE?: any | null;
            FSTATUS?: string | null;
            FVACATEDATE?: any | null;
            INHOUSENETRENT?: any | null;
            PAREA?: any | null;
            PBASERENT?: any | null;
            PCAPOCCCOST?: any | null;
            POGPSM?: any | null;
            POWNERNAME?: string | null;
            PPROMO?: any | null;
            PTENANT?: string | null;
            PTENANTNAME?: string | null;
            PVACATEDATE?: any | null;
            SDEFAULTRECOVERYPROFILEDESC?: string | null;
            SECTION1?: string | null;
            SECTION2?: string | null;
            SECTION3?: string | null;
            SECTION4?: string | null;
            SECTION5?: string | null;
            SMGMTFEE?: any | null;
            UnitKey?: string | null;
            VALUATIONNETRENT?: any | null;
          }>;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetLafOtherInfoQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafOtherInfoQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      storage_rent?: any | null;
      storage_area?: any | null;
      seating_rent?: any | null;
      seating_area?: any | null;
      signage_rent?: any | null;
      number_of_signs?: number | null;
    }>;
  } | null;
};

export type GetLafUsageQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafUsageQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      DealPermittedUses: Array<{
        __typename?: "DealPermittedUses";
        description?: string | null;
        food_menu?: string | null;
        sublet?: string | null;
        TemplatePermittedUse?: {
          __typename?: "TemplatePermittedUses";
          title?: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetLafSpecialConditionQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafSpecialConditionQuery = {
  __typename?: "query_root";
  Deals_by_pk?: {
    __typename?: "Deals";
    id: any;
    DealData: Array<{
      __typename?: "DealData";
      DealSpecialConditions: Array<{
        __typename?: "DealSpecialConditions";
        title?: string | null;
        laf_description?: string | null;
      }>;
    }>;
  } | null;
};

export type SubGetApprovalsSubscriptionVariables = Exact<{
  deal_data_id: Scalars["bigint"];
  lafPhaseName?: Scalars["String"];
}>;

export type SubGetApprovalsSubscription = {
  __typename?: "subscription_root";
  DealData_by_pk?: {
    __typename?: "DealData";
    Deal: {
      __typename?: "Deals";
      status: DealStatus_Enum;
      DealPhases: Array<{
        __typename?: "DealPhases";
        status_id?: number | null;
      }>;
    };
    DealLAFApprovals?: {
      __typename?: "DealLAFApprovals";
      id: any;
      level?: number | null;
      escalate_level?: boolean | null;
      last_approver?: boolean | null;
      laf_action?: LafActionChoices_Enum | null;
      ActiveApprover?: { __typename?: "Users"; id: any } | null;
    } | null;
  } | null;
};

export type UpdateApprovalsMutationVariables = Exact<{
  deal_data_id: Scalars["bigint"];
  laf_action: LafActionChoices_Enum;
  modified_at: Scalars["timestamptz"];
}>;

export type UpdateApprovalsMutation = {
  __typename?: "mutation_root";
  insert_DealLAFApprovals_one?: {
    __typename?: "DealLAFApprovals";
    id: any;
  } | null;
};

export type GetLafVersionQueryVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type GetLafVersionQuery = {
  __typename?: "query_root";
  Deals_by_pk?: { __typename?: "Deals"; laf_version: number } | null;
};

export type GetPropertyDetailsQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetPropertyDetailsQuery = {
  __typename?: "query_root";
  YardiPropertyProfiles: Array<{
    __typename?: "YardiPropertyProfiles";
    profile_year_start?: any | null;
    landlord_name?: string | null;
    landlord_acn?: string | null;
    landlord_address?: string | null;
    management_company_real_estate_licence?: string | null;
    management_company?: string | null;
    management_company_abn?: string | null;
    management_company_phone?: string | null;
    management_company_address?: string | null;
    management_company_postal?: string | null;
    parking_space_num?: any | null;
    customer_parking_space_num?: any | null;
    alteration?: string | null;
    development?: string | null;
    alteration_works?: string | null;
    anchor_tenant_expiry?: string | null;
    annual_gr_return_centre?: any | null;
    annual_gr_return_food?: any | null;
    annual_gr_return_nonfood?: any | null;
    annual_gr_return_service?: any | null;
    turn_over_sat?: any | null;
    head_or_crown_lease?: string | null;
    head_or_crown_lease_start?: any | null;
    head_or_crown_lease_end?: any | null;
    head_or_crown_lease_option_start?: any | null;
    head_or_crown_lease_option_end?: any | null;
    require_consent_to_lease?: string | null;
    require_minister_consent_to_crown?: string | null;
    require_mortgagee_consent?: string | null;
    effect_of_obligations?: string | null;
    trade_hour_mon?: string | null;
    trade_hour_tues?: string | null;
    trade_hour_wed?: string | null;
    trade_hour_thur?: string | null;
    trade_hour_fri?: string | null;
    trade_hour_sat?: string | null;
    trade_hour_sun?: string | null;
    trade_hour_holiday?: string | null;
    YardiProperty: {
      __typename?: "YardiProperties";
      PropertyCode: string;
      PropertyName: string;
      PropertyArea: any;
      Address1?: string | null;
      Address2?: string | null;
      City?: string | null;
      PostCode?: string | null;
      Country: string;
    };
  }>;
};

export type GetPropertyExpenseQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetPropertyExpenseQuery = {
  __typename?: "query_root";
  YardiPropertyProfiles: Array<{
    __typename?: "YardiPropertyProfiles";
    exp_admin?: any | null;
    exp_audit?: any | null;
    exp_air_maint?: any | null;
    exp_air_ops?: any | null;
    exp_strata?: any | null;
    exp_building_intelligence?: any | null;
    exp_traffic_flow?: any | null;
    exp_energy_mgmt?: any | null;
    exp_mgmt_fee?: any | null;
    exp_insurance?: any | null;
    exp_pest?: any | null;
    exp_ventilation?: any | null;
    exp_caretaking?: any | null;
    exp_emergency_sys?: any | null;
    exp_fire_levy?: any | null;
    exp_fire_protection?: any | null;
    exp_security?: any | null;
    exp_cleaning_costs?: any | null;
    exp_post_boxes?: any | null;
    exp_public_telephones?: any | null;
    exp_car_parking?: any | null;
    exp_child_minding?: any | null;
    exp_escalators?: any | null;
    exp_lift?: any | null;
    exp_uniforms?: any | null;
    exp_info_directories?: any | null;
    exp_public_address?: any | null;
    exp_signage?: any | null;
    exp_local_gov_charges?: any | null;
    exp_water_sewage_rates?: any | null;
    exp_electricity?: any | null;
    exp_repairs_and_maint?: any | null;
    exp_sinking_fund?: any | null;
    exp_gas?: any | null;
    exp_oil?: any | null;
    exp_water?: any | null;
    exp_sewerage_disposal?: any | null;
    exp_water_collection_and_disposal?: any | null;
    exp_ohs?: any | null;
    exp_landscape?: any | null;
    exp_clean_consumable?: any | null;
    exp_staff_wages?: any | null;
    miscellaneous?: any | null;
  }>;
};

export type GetLandlordFacilitiesQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetLandlordFacilitiesQuery = {
  __typename?: "query_root";
  YardiPropertyProfiles: Array<{
    __typename?: "YardiPropertyProfiles";
    services_tenancy_clean?: string | null;
    services_common_clean?: string | null;
    services_common_light?: string | null;
    services_security?: string | null;
    services_bin_room?: string | null;
    exp_staff_toilet?: string | null;
    exp_child?: string | null;
    num_shop?: string | null;
    shops_leased_and_occupied?: string | null;
    shops_leased_and_unoccupied?: string | null;
    tenancy_coord_fee_per_trans?: any | null;
    survey_fee_per_trans?: any | null;
    site_area?: number | null;
    market_competitor?: string | null;
    exp_other?: string | null;
  }>;
};

export type GetPropertyInformationQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetPropertyInformationQuery = {
  __typename?: "query_root";
  PropertiesInformation: Array<{
    __typename?: "PropertiesInformation";
    id: any;
    other_toggle?: any | null;
    description?: string | null;
    embedded_network_toggle?: any | null;
    market_impact?: string | null;
  }>;
};

export type UpsertPropertiesInformationMutationVariables = Exact<{
  object?: InputMaybe<PropertiesInformation_Insert_Input>;
}>;

export type UpsertPropertiesInformationMutation = {
  __typename?: "mutation_root";
  insert_PropertiesInformation_one?: {
    __typename?: "PropertiesInformation";
    id: any;
  } | null;
};

export type GetPropertyFilesQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetPropertyFilesQuery = {
  __typename?: "query_root";
  PropertyFiles: Array<{
    __typename?: "PropertyFiles";
    id: any;
    type?: PropertyFileTypes_Enum | null;
    user_id: any;
    property_code?: string | null;
    file_path: string;
    file_name: string;
  }>;
};

export type CreatePropertyFileMutationVariables = Exact<{
  object?: InputMaybe<PropertyFiles_Insert_Input>;
}>;

export type CreatePropertyFileMutation = {
  __typename?: "mutation_root";
  insert_PropertyFiles_one?: {
    __typename?: "PropertyFiles";
    id: any;
    file_name: string;
    file_path: string;
  } | null;
};

export type GetUnitsByPropertyQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetUnitsByPropertyQuery = {
  __typename?: "query_root";
  Units: Array<{
    __typename?: "Units";
    Attribute1?: string | null;
    Attribute2?: string | null;
    Attribute3?: string | null;
    Attribute4?: string | null;
    Attribute5?: string | null;
    Attribute6?: string | null;
    Attribute7?: string | null;
    Attribute8?: string | null;
    Attribute9?: string | null;
    Attribute10?: string | null;
    Building?: string | null;
    FloorCode?: string | null;
    IsExclude?: string | null;
    LevelName?: string | null;
    LevelNumber?: string | null;
    Location?: string | null;
    MarketRent?: any | null;
    PropertyName?: string | null;
    RentalType?: string | null;
    UnitArea?: any | null;
    UnitCode?: string | null;
    UnitKey?: string | null;
    UnitStatus?: string | null;
    UnitType?: string | null;
    created_at?: any | null;
    modified_at?: any | null;
    deleted_at?: any | null;
    area_survey_required?: boolean | null;
    estimated_area?: any | null;
    label?: string | null;
    notes?: string | null;
    property_code?: string | null;
    survey_area?: any | null;
  }>;
};

export type GetCentreMapsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCentreMapsQuery = {
  __typename?: "query_root";
  PropertyFiles: Array<{
    __typename?: "PropertyFiles";
    id: any;
    property_code?: string | null;
    file_name: string;
    file_path: string;
    created_at?: any | null;
  }>;
};

export type GetLegislationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLegislationsQuery = {
  __typename?: "query_root";
  PropertyFiles: Array<{
    __typename?: "PropertyFiles";
    id: any;
    property_code?: string | null;
    file_name: string;
    file_path: string;
    created_at?: any | null;
  }>;
};

export type GetYardiPropertyContactsQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetYardiPropertyContactsQuery = {
  __typename?: "query_root";
  YardiProperties_by_pk?: {
    __typename?: "YardiProperties";
    YardiContacts_YardiProperties: Array<{
      __typename?: "YardiContacts_YardiProperties";
      Role?: string | null;
      YardiContact: {
        __typename?: "YardiContacts";
        ContactCode: string;
        Email?: string | null;
        FirstName?: string | null;
        LastName?: string | null;
        PhoneNumber1?: string | null;
        PhoneNumber10?: string | null;
        PhoneNumber2?: string | null;
        PhoneNumber3?: string | null;
        PhoneNumber4?: string | null;
        PhoneNumber5?: string | null;
        PhoneNumber6?: string | null;
        PhoneNumber7?: string | null;
        PhoneNumber8?: string | null;
        PhoneNumber9?: string | null;
        Title?: string | null;
      };
    }>;
  } | null;
};

export type GetVirtualUnitsByPropertyQueryVariables = Exact<{
  property_code: Scalars["String"];
}>;

export type GetVirtualUnitsByPropertyQuery = {
  __typename?: "query_root";
  VirtualUnits: Array<{
    __typename?: "VirtualUnits";
    id: any;
    unit_type?: string | null;
    created_at?: any | null;
    modified_at?: any | null;
    area_survey_required?: boolean | null;
    estimated_area?: any | null;
    label?: string | null;
    property_code?: string | null;
    survey_area?: number | null;
    deleted: boolean;
  }>;
};

export type DeleteVirtualUnitMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type DeleteVirtualUnitMutation = {
  __typename?: "mutation_root";
  update_VirtualUnits?: {
    __typename?: "VirtualUnits_mutation_response";
    returning: Array<{ __typename?: "VirtualUnits"; id: any }>;
  } | null;
};

export type UpdateVirtualUnitMutationVariables = Exact<{
  id: Scalars["bigint"];
  label: Scalars["String"];
  estimated_area: Scalars["numeric"];
}>;

export type UpdateVirtualUnitMutation = {
  __typename?: "mutation_root";
  update_VirtualUnits?: {
    __typename?: "VirtualUnits_mutation_response";
    returning: Array<{ __typename?: "VirtualUnits"; id: any }>;
  } | null;
};

export type GetDealsUnitsForVirtualUnitQueryVariables = Exact<{
  virtual_unit_id: Scalars["bigint"];
}>;

export type GetDealsUnitsForVirtualUnitQuery = {
  __typename?: "query_root";
  DealsUnits: Array<{ __typename?: "DealsUnits"; id: any }>;
};

export type SearchDocumentsQueryVariables = Exact<{
  searchQuery: Scalars["String"];
}>;

export type SearchDocumentsQuery = {
  __typename?: "query_root";
  searchDocumentsSearch: Array<{
    __typename?: "searchOutput";
    id: string;
    title: string;
    content: string;
    date: string;
    link: string;
    score: number;
    centre: string;
  } | null>;
};

export type GetSpecialConditionTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSpecialConditionTemplatesQuery = {
  __typename?: "query_root";
  TemplateSpecialConditions: Array<{
    __typename?: "TemplateSpecialConditions";
    title?: string | null;
    modified_at?: any | null;
    priority?: number | null;
    id: any;
    ModifiedBy?: {
      __typename?: "Users";
      user_full_name?: string | null;
    } | null;
  }>;
};

export type GetSpecialConditionTemplateQueryVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type GetSpecialConditionTemplateQuery = {
  __typename?: "query_root";
  TemplateSpecialConditions_by_pk?: {
    __typename?: "TemplateSpecialConditions";
    title?: string | null;
    description?: string | null;
    priority?: number | null;
    modified_at?: any | null;
    laf_description?: string | null;
    id: any;
  } | null;
};

export type CreateSpecialConditionTemplateMutationVariables = Exact<{
  data: TemplateSpecialConditions_Insert_Input;
}>;

export type CreateSpecialConditionTemplateMutation = {
  __typename?: "mutation_root";
  insert_TemplateSpecialConditions_one?: {
    __typename?: "TemplateSpecialConditions";
    id: any;
  } | null;
};

export type UpdateSpecialConditionTemplateMutationVariables = Exact<{
  id: TemplateSpecialConditions_Pk_Columns_Input;
  data: TemplateSpecialConditions_Set_Input;
}>;

export type UpdateSpecialConditionTemplateMutation = {
  __typename?: "mutation_root";
  update_TemplateSpecialConditions_by_pk?: {
    __typename?: "TemplateSpecialConditions";
    id: any;
  } | null;
};

export type CreateTemplateMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<DocumentTemplates_Insert_Input> | DocumentTemplates_Insert_Input
  >;
}>;

export type CreateTemplateMutation = {
  __typename?: "mutation_root";
  insert_DocumentTemplates?: {
    __typename?: "DocumentTemplates_mutation_response";
    returning: Array<{ __typename?: "DocumentTemplates"; id: any }>;
  } | null;
};

export type GetDocumentTemplatesQueryVariables = Exact<{
  filters?: InputMaybe<DocumentTemplates_Bool_Exp>;
}>;

export type GetDocumentTemplatesQuery = {
  __typename?: "query_root";
  DocumentTemplates: Array<{
    __typename?: "DocumentTemplates";
    id: any;
    generate_to_name?: string | null;
    event_trigger_name: string;
    template_name: string;
    template_path: string;
    information?: string | null;
    convert_to_pdf: boolean;
    modified_at?: any | null;
    created_at?: any | null;
    hidden: boolean;
    Users: { __typename?: "Users"; id: any; user_full_name?: string | null };
  }>;
};

export type SoftDeleteDocumentTemplateMutationVariables = Exact<{
  id: Scalars["bigint"];
}>;

export type SoftDeleteDocumentTemplateMutation = {
  __typename?: "mutation_root";
  update_DocumentTemplates_by_pk?: {
    __typename?: "DocumentTemplates";
    id: any;
    deleted: boolean;
  } | null;
};

export type ToggleDocumentTemplateConvertToPdfMutationVariables = Exact<{
  id: Scalars["bigint"];
  convert_to_pdf: Scalars["Boolean"];
}>;

export type ToggleDocumentTemplateConvertToPdfMutation = {
  __typename?: "mutation_root";
  update_DocumentTemplates_by_pk?: {
    __typename?: "DocumentTemplates";
    id: any;
    convert_to_pdf: boolean;
  } | null;
};

export type ToggleDocumentTemplateHiddenMutationVariables = Exact<{
  id: Scalars["bigint"];
  hidden: Scalars["Boolean"];
}>;

export type ToggleDocumentTemplateHiddenMutation = {
  __typename?: "mutation_root";
  update_DocumentTemplates_by_pk?: {
    __typename?: "DocumentTemplates";
    id: any;
    hidden: boolean;
  } | null;
};

export type GetTemplateGeneratedNameQueryVariables = Exact<{
  template_name?: InputMaybe<Scalars["String"]>;
}>;

export type GetTemplateGeneratedNameQuery = {
  __typename?: "query_root";
  DocumentTemplates: Array<{
    __typename?: "DocumentTemplates";
    generate_to_name?: string | null;
  }>;
};

export type Upsert_UserMutationVariables = Exact<{
  object: Users_Insert_Input;
}>;

export type Upsert_UserMutation = {
  __typename?: "mutation_root";
  insert_Users_one?: { __typename?: "Users"; id: any } | null;
};

export type YardiDataSyncStatusSubscriptionVariables = Exact<{
  deal_id: Scalars["bigint"];
}>;

export type YardiDataSyncStatusSubscription = {
  __typename?: "subscription_root";
  YardiDealSyncStatuses: Array<{
    __typename?: "YardiDealSyncStatuses";
    status: any;
    state: any;
    created_at: any;
    modified_at: any;
  }>;
};

export type YardiEgestDealMutationVariables = Exact<{
  dealArg: EgestInput;
}>;

export type YardiEgestDealMutation = {
  __typename?: "mutation_root";
  egestJob?: {
    __typename?: "EgestOutput";
    jobId?: string | null;
    message?: string | null;
  } | null;
};

export type YardiDeleteQuestionnaireMutationVariables = Exact<{
  dealId: Scalars["Int"];
}>;

export type YardiDeleteQuestionnaireMutation = {
  __typename?: "mutation_root";
  yardiDeleteQuestionnaire?: {
    __typename?: "QuestionnaireDeleteOutput";
    message?: string | null;
  } | null;
};

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetApproversQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Approvers })
 *   )
 * })
 */
export const mockGetApproversQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetApproversQueryVariables>,
    GraphQLContext<GetApproversQuery>,
    any
  >
) =>
  graphql.query<GetApproversQuery, GetApproversQueryVariables>(
    "GetApprovers",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetEsclatedApproversQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ EscalatedApprovers })
 *   )
 * })
 */
export const mockGetEsclatedApproversQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetEsclatedApproversQueryVariables>,
    GraphQLContext<GetEsclatedApproversQuery>,
    any
  >
) =>
  graphql.query<GetEsclatedApproversQuery, GetEsclatedApproversQueryVariables>(
    "getEsclatedApprovers",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateEsclatedApproversMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_EscalatedApprovers })
 *   )
 * })
 */
export const mockCreateEsclatedApproversMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateEsclatedApproversMutationVariables>,
    GraphQLContext<CreateEsclatedApproversMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateEsclatedApproversMutation,
    CreateEsclatedApproversMutationVariables
  >("CreateEsclatedApprovers", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteEsclatedApproversMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ delete_EscalatedApprovers })
 *   )
 * })
 */
export const mockDeleteEsclatedApproversMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteEsclatedApproversMutationVariables>,
    GraphQLContext<DeleteEsclatedApproversMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteEsclatedApproversMutation,
    DeleteEsclatedApproversMutationVariables
  >("DeleteEsclatedApprovers", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateApproversMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ delete_CenterApprovers, delete_Approvers, insert_Approvers })
 *   )
 * })
 */
export const mockCreateApproversMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateApproversMutationVariables>,
    GraphQLContext<CreateApproversMutation>,
    any
  >
) =>
  graphql.mutation<CreateApproversMutation, CreateApproversMutationVariables>(
    "CreateApprovers",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockChatQuery((req, res, ctx) => {
 *   const { messages, source } = req.variables;
 *   return res(
 *     ctx.data({ chat })
 *   )
 * })
 */
export const mockChatQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<ChatQueryVariables>,
    GraphQLContext<ChatQuery>,
    any
  >
) => graphql.query<ChatQuery, ChatQueryVariables>("Chat", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCompanyQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ Companies_by_pk })
 *   )
 * })
 */
export const mockGetCompanyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetCompanyQueryVariables>,
    GraphQLContext<GetCompanyQuery>,
    any
  >
) =>
  graphql.query<GetCompanyQuery, GetCompanyQueryVariables>(
    "GetCompany",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCompaniesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Companies })
 *   )
 * })
 */
export const mockGetCompaniesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetCompaniesQueryVariables>,
    GraphQLContext<GetCompaniesQuery>,
    any
  >
) =>
  graphql.query<GetCompaniesQuery, GetCompaniesQueryVariables>(
    "GetCompanies",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCompanyMutation((req, res, ctx) => {
 *   const { company } = req.variables;
 *   return res(
 *     ctx.data({ insert_Companies_one })
 *   )
 * })
 */
export const mockCreateCompanyMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCompanyMutationVariables>,
    GraphQLContext<CreateCompanyMutation>,
    any
  >
) =>
  graphql.mutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    "CreateCompany",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCompanyMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_Companies_by_pk })
 *   )
 * })
 */
export const mockUpdateCompanyMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateCompanyMutationVariables>,
    GraphQLContext<UpdateCompanyMutation>,
    any
  >
) =>
  graphql.mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    "UpdateCompany",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealCompanyMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealsCompanies_one })
 *   )
 * })
 */
export const mockAddDealCompanyMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealCompanyMutationVariables>,
    GraphQLContext<AddDealCompanyMutation>,
    any
  >
) =>
  graphql.mutation<AddDealCompanyMutation, AddDealCompanyMutationVariables>(
    "AddDealCompany",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSoftDeleteCompanyMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ update_Companies_by_pk })
 *   )
 * })
 */
export const mockSoftDeleteCompanyMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SoftDeleteCompanyMutationVariables>,
    GraphQLContext<SoftDeleteCompanyMutation>,
    any
  >
) =>
  graphql.mutation<
    SoftDeleteCompanyMutation,
    SoftDeleteCompanyMutationVariables
  >("SoftDeleteCompany", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContactsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Contacts })
 *   )
 * })
 */
export const mockGetContactsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContactsQueryVariables>,
    GraphQLContext<GetContactsQuery>,
    any
  >
) =>
  graphql.query<GetContactsQuery, GetContactsQueryVariables>(
    "GetContacts",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateContactMutation((req, res, ctx) => {
 *   const { contact } = req.variables;
 *   return res(
 *     ctx.data({ insert_Contacts_one })
 *   )
 * })
 */
export const mockCreateContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateContactMutationVariables>,
    GraphQLContext<CreateContactMutation>,
    any
  >
) =>
  graphql.mutation<CreateContactMutation, CreateContactMutationVariables>(
    "CreateContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContactQuery((req, res, ctx) => {
 *   const { contactId } = req.variables;
 *   return res(
 *     ctx.data({ Contacts_by_pk })
 *   )
 * })
 */
export const mockGetContactQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContactQueryVariables>,
    GraphQLContext<GetContactQuery>,
    any
  >
) =>
  graphql.query<GetContactQuery, GetContactQueryVariables>(
    "GetContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateContactMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_Contacts_by_pk })
 *   )
 * })
 */
export const mockUpdateContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateContactMutationVariables>,
    GraphQLContext<UpdateContactMutation>,
    any
  >
) =>
  graphql.mutation<UpdateContactMutation, UpdateContactMutationVariables>(
    "UpdateContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealContactsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealsContacts })
 *   )
 * })
 */
export const mockGetDealContactsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealContactsQueryVariables>,
    GraphQLContext<GetDealContactsQuery>,
    any
  >
) =>
  graphql.query<GetDealContactsQuery, GetDealContactsQueryVariables>(
    "GetDealContacts",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealContactsTagsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ DealContactsTags })
 *   )
 * })
 */
export const mockGetDealContactsTagsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealContactsTagsQueryVariables>,
    GraphQLContext<GetDealContactsTagsQuery>,
    any
  >
) =>
  graphql.query<GetDealContactsTagsQuery, GetDealContactsTagsQueryVariables>(
    "GetDealContactsTags",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealContactsTagsMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealContactsTags })
 *   )
 * })
 */
export const mockCreateDealContactsTagsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealContactsTagsMutationVariables>,
    GraphQLContext<CreateDealContactsTagsMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealContactsTagsMutation,
    CreateDealContactsTagsMutationVariables
  >("CreateDealContactsTags", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealsByContactQuery((req, res, ctx) => {
 *   const { contact_id } = req.variables;
 *   return res(
 *     ctx.data({ DealsContacts })
 *   )
 * })
 */
export const mockGetDealsByContactQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealsByContactQueryVariables>,
    GraphQLContext<GetDealsByContactQuery>,
    any
  >
) =>
  graphql.query<GetDealsByContactQuery, GetDealsByContactQueryVariables>(
    "GetDealsByContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContactsByPropertyQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ PropertyContacts })
 *   )
 * })
 */
export const mockGetContactsByPropertyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContactsByPropertyQueryVariables>,
    GraphQLContext<GetContactsByPropertyQuery>,
    any
  >
) =>
  graphql.query<
    GetContactsByPropertyQuery,
    GetContactsByPropertyQueryVariables
  >("GetContactsByProperty", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePropertyContactMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_PropertyContacts_one })
 *   )
 * })
 */
export const mockCreatePropertyContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreatePropertyContactMutationVariables>,
    GraphQLContext<CreatePropertyContactMutation>,
    any
  >
) =>
  graphql.mutation<
    CreatePropertyContactMutation,
    CreatePropertyContactMutationVariables
  >("CreatePropertyContact", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateLocationMutation((req, res, ctx) => {
 *   const { location } = req.variables;
 *   return res(
 *     ctx.data({ insert_Locations_one })
 *   )
 * })
 */
export const mockCreateLocationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateLocationMutationVariables>,
    GraphQLContext<CreateLocationMutation>,
    any
  >
) =>
  graphql.mutation<CreateLocationMutation, CreateLocationMutationVariables>(
    "CreateLocation",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateLocationMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_Locations_by_pk })
 *   )
 * })
 */
export const mockUpdateLocationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateLocationMutationVariables>,
    GraphQLContext<UpdateLocationMutation>,
    any
  >
) =>
  graphql.mutation<UpdateLocationMutation, UpdateLocationMutationVariables>(
    "UpdateLocation",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStatesQuery((req, res, ctx) => {
 *   const { country_id } = req.variables;
 *   return res(
 *     ctx.data({ States })
 *   )
 * })
 */
export const mockGetStatesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetStatesQueryVariables>,
    GraphQLContext<GetStatesQuery>,
    any
  >
) =>
  graphql.query<GetStatesQuery, GetStatesQueryVariables>("GetStates", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertiesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Properties })
 *   )
 * })
 */
export const mockGetPropertiesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertiesQueryVariables>,
    GraphQLContext<GetPropertiesQuery>,
    any
  >
) =>
  graphql.query<GetPropertiesQuery, GetPropertiesQueryVariables>(
    "GetProperties",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertyQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ Properties })
 *   )
 * })
 */
export const mockGetPropertyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertyQueryVariables>,
    GraphQLContext<GetPropertyQuery>,
    any
  >
) =>
  graphql.query<GetPropertyQuery, GetPropertyQueryVariables>(
    "GetProperty",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUnitsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Units })
 *   )
 * })
 */
export const mockGetUnitsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUnitsQueryVariables>,
    GraphQLContext<GetUnitsQuery>,
    any
  >
) => graphql.query<GetUnitsQuery, GetUnitsQueryVariables>("GetUnits", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiUnitsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ YardiUnits })
 *   )
 * })
 */
export const mockGetYardiUnitsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiUnitsQueryVariables>,
    GraphQLContext<GetYardiUnitsQuery>,
    any
  >
) =>
  graphql.query<GetYardiUnitsQuery, GetYardiUnitsQueryVariables>(
    "GetYardiUnits",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiUnitByKeyQuery((req, res, ctx) => {
 *   const { unit_key } = req.variables;
 *   return res(
 *     ctx.data({ YardiUnits })
 *   )
 * })
 */
export const mockGetYardiUnitByKeyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiUnitByKeyQueryVariables>,
    GraphQLContext<GetYardiUnitByKeyQuery>,
    any
  >
) =>
  graphql.query<GetYardiUnitByKeyQuery, GetYardiUnitByKeyQueryVariables>(
    "GetYardiUnitByKey",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplateSpecialConditionsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ TemplateSpecialConditions })
 *   )
 * })
 */
export const mockGetTemplateSpecialConditionsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTemplateSpecialConditionsQueryVariables>,
    GraphQLContext<GetTemplateSpecialConditionsQuery>,
    any
  >
) =>
  graphql.query<
    GetTemplateSpecialConditionsQuery,
    GetTemplateSpecialConditionsQueryVariables
  >("GetTemplateSpecialConditions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetVirtualUnitsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ VirtualUnits })
 *   )
 * })
 */
export const mockGetVirtualUnitsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetVirtualUnitsQueryVariables>,
    GraphQLContext<GetVirtualUnitsQuery>,
    any
  >
) =>
  graphql.query<GetVirtualUnitsQuery, GetVirtualUnitsQueryVariables>(
    "GetVirtualUnits",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUnitTypesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Units })
 *   )
 * })
 */
export const mockGetUnitTypesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUnitTypesQueryVariables>,
    GraphQLContext<GetUnitTypesQuery>,
    any
  >
) =>
  graphql.query<GetUnitTypesQuery, GetUnitTypesQueryVariables>(
    "GetUnitTypes",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllPhasesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Phases })
 *   )
 * })
 */
export const mockGetAllPhasesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAllPhasesQueryVariables>,
    GraphQLContext<GetAllPhasesQuery>,
    any
  >
) =>
  graphql.query<GetAllPhasesQuery, GetAllPhasesQueryVariables>(
    "GetAllPhases",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUnitTypeQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ UnitTypes_by_pk })
 *   )
 * })
 */
export const mockGetUnitTypeQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUnitTypeQueryVariables>,
    GraphQLContext<GetUnitTypeQuery>,
    any
  >
) =>
  graphql.query<GetUnitTypeQuery, GetUnitTypeQueryVariables>(
    "GetUnitType",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTenantContactTypeQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ ContactTypes })
 *   )
 * })
 */
export const mockGetTenantContactTypeQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTenantContactTypeQueryVariables>,
    GraphQLContext<GetTenantContactTypeQuery>,
    any
  >
) =>
  graphql.query<GetTenantContactTypeQuery, GetTenantContactTypeQueryVariables>(
    "GetTenantContactType",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealMutation((req, res, ctx) => {
 *   const { deal } = req.variables;
 *   return res(
 *     ctx.data({ insert_Deals_one })
 *   )
 * })
 */
export const mockAddDealMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealMutationVariables>,
    GraphQLContext<AddDealMutation>,
    any
  >
) =>
  graphql.mutation<AddDealMutation, AddDealMutationVariables>(
    "AddDeal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealUnitsMutation((req, res, ctx) => {
 *   const { dealUnit } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealsUnits_one })
 *   )
 * })
 */
export const mockAddDealUnitsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealUnitsMutationVariables>,
    GraphQLContext<AddDealUnitsMutation>,
    any
  >
) =>
  graphql.mutation<AddDealUnitsMutation, AddDealUnitsMutationVariables>(
    "AddDealUnits",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealDataMutation((req, res, ctx) => {
 *   const { dealData } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealData_one })
 *   )
 * })
 */
export const mockAddDealDataMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealDataMutationVariables>,
    GraphQLContext<AddDealDataMutation>,
    any
  >
) =>
  graphql.mutation<AddDealDataMutation, AddDealDataMutationVariables>(
    "AddDealData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealPhasesMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealPhases })
 *   )
 * })
 */
export const mockAddDealPhasesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealPhasesMutationVariables>,
    GraphQLContext<AddDealPhasesMutation>,
    any
  >
) =>
  graphql.mutation<AddDealPhasesMutation, AddDealPhasesMutationVariables>(
    "AddDealPhases",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealActionItemsMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealActionItem })
 *   )
 * })
 */
export const mockAddDealActionItemsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealActionItemsMutationVariables>,
    GraphQLContext<AddDealActionItemsMutation>,
    any
  >
) =>
  graphql.mutation<
    AddDealActionItemsMutation,
    AddDealActionItemsMutationVariables
  >("AddDealActionItems", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddCompanyMutation((req, res, ctx) => {
 *   const { Company } = req.variables;
 *   return res(
 *     ctx.data({ insert_Companies })
 *   )
 * })
 */
export const mockAddCompanyMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddCompanyMutationVariables>,
    GraphQLContext<AddCompanyMutation>,
    any
  >
) =>
  graphql.mutation<AddCompanyMutation, AddCompanyMutationVariables>(
    "AddCompany",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddContactMutation((req, res, ctx) => {
 *   const { Contacts } = req.variables;
 *   return res(
 *     ctx.data({ insert_Contacts })
 *   )
 * })
 */
export const mockAddContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddContactMutationVariables>,
    GraphQLContext<AddContactMutation>,
    any
  >
) =>
  graphql.mutation<AddContactMutation, AddContactMutationVariables>(
    "AddContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealsContactMutation((req, res, ctx) => {
 *   const { dealContacts } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealsContacts })
 *   )
 * })
 */
export const mockAddDealsContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealsContactMutationVariables>,
    GraphQLContext<AddDealsContactMutation>,
    any
  >
) =>
  graphql.mutation<AddDealsContactMutation, AddDealsContactMutationVariables>(
    "AddDealsContact",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetExistingContactsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Contacts })
 *   )
 * })
 */
export const mockGetExistingContactsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetExistingContactsQueryVariables>,
    GraphQLContext<GetExistingContactsQuery>,
    any
  >
) =>
  graphql.query<GetExistingContactsQuery, GetExistingContactsQueryVariables>(
    "GetExistingContacts",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllContactsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Contacts })
 *   )
 * })
 */
export const mockGetAllContactsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAllContactsQueryVariables>,
    GraphQLContext<GetAllContactsQuery>,
    any
  >
) =>
  graphql.query<GetAllContactsQuery, GetAllContactsQueryVariables>(
    "GetAllContacts",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllContactsByCompanyQuery((req, res, ctx) => {
 *   const { company_id } = req.variables;
 *   return res(
 *     ctx.data({ Contacts })
 *   )
 * })
 */
export const mockGetAllContactsByCompanyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAllContactsByCompanyQueryVariables>,
    GraphQLContext<GetAllContactsByCompanyQuery>,
    any
  >
) =>
  graphql.query<
    GetAllContactsByCompanyQuery,
    GetAllContactsByCompanyQueryVariables
  >("GetAllContactsByCompany", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateBaseLandlordServicesMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_BaseLandlordServices_one })
 *   )
 * })
 */
export const mockCreateBaseLandlordServicesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateBaseLandlordServicesMutationVariables>,
    GraphQLContext<CreateBaseLandlordServicesMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateBaseLandlordServicesMutation,
    CreateBaseLandlordServicesMutationVariables
  >("CreateBaseLandlordServices", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDealCreationSpecialConditionsMutation((req, res, ctx) => {
 *   const { deal_options } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealSpecialConditions })
 *   )
 * })
 */
export const mockDealCreationSpecialConditionsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DealCreationSpecialConditionsMutationVariables>,
    GraphQLContext<DealCreationSpecialConditionsMutation>,
    any
  >
) =>
  graphql.mutation<
    DealCreationSpecialConditionsMutation,
    DealCreationSpecialConditionsMutationVariables
  >("DealCreationSpecialConditions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCreateDealFitoutLandlordWorksMutation((req, res, ctx) => {
 *   const { update_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealFitoutLandlordWorks_one })
 *   )
 * })
 */
export const mockUpdateCreateDealFitoutLandlordWorksMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateCreateDealFitoutLandlordWorksMutationVariables>,
    GraphQLContext<UpdateCreateDealFitoutLandlordWorksMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateCreateDealFitoutLandlordWorksMutation,
    UpdateCreateDealFitoutLandlordWorksMutationVariables
  >("updateCreateDealFitoutLandlordWorks", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelDealMutation((req, res, ctx) => {
 *   const { dealId, cancelDate, capitalIncurred, cancelComment } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals, insert_Comments_one })
 *   )
 * })
 */
export const mockCancelDealMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CancelDealMutationVariables>,
    GraphQLContext<CancelDealMutation>,
    any
  >
) =>
  graphql.mutation<CancelDealMutation, CancelDealMutationVariables>(
    "CancelDeal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCommentMutation((req, res, ctx) => {
 *   const { text, deal_id, user_tags_object, tags_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_Comments_one })
 *   )
 * })
 */
export const mockCreateCommentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCommentMutationVariables>,
    GraphQLContext<CreateCommentMutation>,
    any
  >
) =>
  graphql.mutation<CreateCommentMutation, CreateCommentMutationVariables>(
    "CreateComment",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCommentBulkMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_Comments_one })
 *   )
 * })
 */
export const mockCreateCommentBulkMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCommentBulkMutationVariables>,
    GraphQLContext<CreateCommentBulkMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateCommentBulkMutation,
    CreateCommentBulkMutationVariables
  >("CreateCommentBulk", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCommentTagMutation((req, res, ctx) => {
 *   const { comment_id, tag_id } = req.variables;
 *   return res(
 *     ctx.data({ insert_CommentTags_one })
 *   )
 * })
 */
export const mockCreateCommentTagMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCommentTagMutationVariables>,
    GraphQLContext<CreateCommentTagMutation>,
    any
  >
) =>
  graphql.mutation<CreateCommentTagMutation, CreateCommentTagMutationVariables>(
    "CreateCommentTag",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCommentTagBulkMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_CommentTags })
 *   )
 * })
 */
export const mockCreateCommentTagBulkMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCommentTagBulkMutationVariables>,
    GraphQLContext<CreateCommentTagBulkMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateCommentTagBulkMutation,
    CreateCommentTagBulkMutationVariables
  >("CreateCommentTagBulk", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealCommentsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Comments })
 *   )
 * })
 */
export const mockGetDealCommentsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealCommentsQueryVariables>,
    GraphQLContext<GetDealCommentsQuery>,
    any
  >
) =>
  graphql.query<GetDealCommentsQuery, GetDealCommentsQueryVariables>(
    "GetDealComments",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllDealContactsListQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealsContacts, DealBusinessContacts })
 *   )
 * })
 */
export const mockGetAllDealContactsListQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAllDealContactsListQueryVariables>,
    GraphQLContext<GetAllDealContactsListQuery>,
    any
  >
) =>
  graphql.query<
    GetAllDealContactsListQuery,
    GetAllDealContactsListQueryVariables
  >("GetAllDealContactsList", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealBusinessContactMutation((req, res, ctx) => {
 *   const { dealContacts } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealBusinessContacts })
 *   )
 * })
 */
export const mockAddDealBusinessContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealBusinessContactMutationVariables>,
    GraphQLContext<AddDealBusinessContactMutation>,
    any
  >
) =>
  graphql.mutation<
    AddDealBusinessContactMutation,
    AddDealBusinessContactMutationVariables
  >("AddDealBusinessContact", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealBusinessContactMutation((req, res, ctx) => {
 *   const { deal_id, contact_id, _set } = req.variables;
 *   return res(
 *     ctx.data({ update_DealBusinessContacts })
 *   )
 * })
 */
export const mockUpdateDealBusinessContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealBusinessContactMutationVariables>,
    GraphQLContext<UpdateDealBusinessContactMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealBusinessContactMutation,
    UpdateDealBusinessContactMutationVariables
  >("UpdateDealBusinessContact", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealBusinessContactMutation((req, res, ctx) => {
 *   const { deal_id, contact_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealBusinessContacts })
 *   )
 * })
 */
export const mockDeleteDealBusinessContactMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealBusinessContactMutationVariables>,
    GraphQLContext<DeleteDealBusinessContactMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealBusinessContactMutation,
    DeleteDealBusinessContactMutationVariables
  >("DeleteDealBusinessContact", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCopyDealReLafMutation((req, res, ctx) => {
 *   const { dealId, comment } = req.variables;
 *   return res(
 *     ctx.data({ copyDealRelaf })
 *   )
 * })
 */
export const mockCopyDealReLafMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CopyDealReLafMutationVariables>,
    GraphQLContext<CopyDealReLafMutation>,
    any
  >
) =>
  graphql.mutation<CopyDealReLafMutation, CopyDealReLafMutationVariables>(
    "CopyDealReLaf",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCopyDealMutation((req, res, ctx) => {
 *   const { dealId, comment, unit } = req.variables;
 *   return res(
 *     ctx.data({ copyDeal })
 *   )
 * })
 */
export const mockCopyDealMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CopyDealMutationVariables>,
    GraphQLContext<CopyDealMutation>,
    any
  >
) =>
  graphql.mutation<CopyDealMutation, CopyDealMutationVariables>(
    "CopyDeal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealPhaseMutation((req, res, ctx) => {
 *   const { id, status_id } = req.variables;
 *   return res(
 *     ctx.data({ update_DealPhases })
 *   )
 * })
 */
export const mockUpdateDealPhaseMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealPhaseMutationVariables>,
    GraphQLContext<UpdateDealPhaseMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealPhaseMutation, UpdateDealPhaseMutationVariables>(
    "UpdateDealPhase",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpsertActionAllocationMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_ActionAllocations_one })
 *   )
 * })
 */
export const mockUpsertActionAllocationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpsertActionAllocationMutationVariables>,
    GraphQLContext<UpsertActionAllocationMutation>,
    any
  >
) =>
  graphql.mutation<
    UpsertActionAllocationMutation,
    UpsertActionAllocationMutationVariables
  >("UpsertActionAllocation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetActionAllocationQuery((req, res, ctx) => {
 *   const { deal_action_id } = req.variables;
 *   return res(
 *     ctx.data({ ActionAllocations })
 *   )
 * })
 */
export const mockGetActionAllocationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetActionAllocationQueryVariables>,
    GraphQLContext<GetActionAllocationQuery>,
    any
  >
) =>
  graphql.query<GetActionAllocationQuery, GetActionAllocationQueryVariables>(
    "GetActionAllocation",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteActionAllocationMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ delete_ActionAllocations })
 *   )
 * })
 */
export const mockDeleteActionAllocationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteActionAllocationMutationVariables>,
    GraphQLContext<DeleteActionAllocationMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteActionAllocationMutation,
    DeleteActionAllocationMutationVariables
  >("DeleteActionAllocation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealPhaseStatusMutation((req, res, ctx) => {
 *   const { deal_id, deal_phase_id, status_id } = req.variables;
 *   return res(
 *     ctx.data({ update_DealPhases })
 *   )
 * })
 */
export const mockUpdateDealPhaseStatusMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealPhaseStatusMutationVariables>,
    GraphQLContext<UpdateDealPhaseStatusMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealPhaseStatusMutation,
    UpdateDealPhaseStatusMutationVariables
  >("UpdateDealPhaseStatus", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetFirstTdPhaseQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealPhases })
 *   )
 * })
 */
export const mockGetFirstTdPhaseQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetFirstTdPhaseQueryVariables>,
    GraphQLContext<GetFirstTdPhaseQuery>,
    any
  >
) =>
  graphql.query<GetFirstTdPhaseQuery, GetFirstTdPhaseQueryVariables>(
    "GetFirstTDPhase",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealStatusMutation((req, res, ctx) => {
 *   const { dealId, status, filter } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals })
 *   )
 * })
 */
export const mockUpdateDealStatusMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealStatusMutationVariables>,
    GraphQLContext<UpdateDealStatusMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealStatusMutation, UpdateDealStatusMutationVariables>(
    "UpdateDealStatus",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealPhaseStatusQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ DealPhases_by_pk })
 *   )
 * })
 */
export const mockGetDealPhaseStatusQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealPhaseStatusQueryVariables>,
    GraphQLContext<GetDealPhaseStatusQuery>,
    any
  >
) =>
  graphql.query<GetDealPhaseStatusQuery, GetDealPhaseStatusQueryVariables>(
    "GetDealPhaseStatus",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealMutation((req, res, ctx) => {
 *   const { dealId } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals })
 *   )
 * })
 */
export const mockDeleteDealMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealMutationVariables>,
    GraphQLContext<DeleteDealMutation>,
    any
  >
) =>
  graphql.mutation<DeleteDealMutation, DeleteDealMutationVariables>(
    "DeleteDeal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGenerateDocumentMutation((req, res, ctx) => {
 *   const { deal_id, template_id } = req.variables;
 *   return res(
 *     ctx.data({ insert_DocumentRequests_one })
 *   )
 * })
 */
export const mockGenerateDocumentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<GenerateDocumentMutationVariables>,
    GraphQLContext<GenerateDocumentMutation>,
    any
  >
) =>
  graphql.mutation<GenerateDocumentMutation, GenerateDocumentMutationVariables>(
    "GenerateDocument",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealFilesQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealFiles })
 *   )
 * })
 */
export const mockGetDealFilesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealFilesQueryVariables>,
    GraphQLContext<GetDealFilesQuery>,
    any
  >
) =>
  graphql.query<GetDealFilesQuery, GetDealFilesQueryVariables>(
    "GetDealFiles",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealFileMutation((req, res, ctx) => {
 *   const { file_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealFiles_one })
 *   )
 * })
 */
export const mockCreateDealFileMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealFileMutationVariables>,
    GraphQLContext<CreateDealFileMutation>,
    any
  >
) =>
  graphql.mutation<CreateDealFileMutation, CreateDealFileMutationVariables>(
    "CreateDealFile",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealFileTagsMutation((req, res, ctx) => {
 *   const { tag_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealFileTags })
 *   )
 * })
 */
export const mockCreateDealFileTagsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealFileTagsMutationVariables>,
    GraphQLContext<CreateDealFileTagsMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealFileTagsMutation,
    CreateDealFileTagsMutationVariables
  >("CreateDealFileTags", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealFileMutation((req, res, ctx) => {
 *   const { file_id, file_name, tag_object } = req.variables;
 *   return res(
 *     ctx.data({ update_DealFiles_by_pk, insert_DealFileTags })
 *   )
 * })
 */
export const mockUpdateDealFileMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealFileMutationVariables>,
    GraphQLContext<UpdateDealFileMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealFileMutation, UpdateDealFileMutationVariables>(
    "UpdateDealFile",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteOldFileTagsMutation((req, res, ctx) => {
 *   const { file_id, new_n_updated_tag_ids } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealFileTags })
 *   )
 * })
 */
export const mockDeleteOldFileTagsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteOldFileTagsMutationVariables>,
    GraphQLContext<DeleteOldFileTagsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteOldFileTagsMutation,
    DeleteOldFileTagsMutationVariables
  >("deleteOldFileTags", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSoftDeleteFileMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ update_DealFiles_by_pk })
 *   )
 * })
 */
export const mockSoftDeleteFileMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SoftDeleteFileMutationVariables>,
    GraphQLContext<SoftDeleteFileMutation>,
    any
  >
) =>
  graphql.mutation<SoftDeleteFileMutation, SoftDeleteFileMutationVariables>(
    "SoftDeleteFile",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealLinksQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealLinks })
 *   )
 * })
 */
export const mockGetDealLinksQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealLinksQueryVariables>,
    GraphQLContext<GetDealLinksQuery>,
    any
  >
) =>
  graphql.query<GetDealLinksQuery, GetDealLinksQueryVariables>(
    "GetDealLinks",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTagsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Tags })
 *   )
 * })
 */
export const mockGetTagsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTagsQueryVariables>,
    GraphQLContext<GetTagsQuery>,
    any
  >
) => graphql.query<GetTagsQuery, GetTagsQueryVariables>("GetTags", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateTagsMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_Tags })
 *   )
 * })
 */
export const mockCreateTagsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateTagsMutationVariables>,
    GraphQLContext<CreateTagsMutation>,
    any
  >
) =>
  graphql.mutation<CreateTagsMutation, CreateTagsMutationVariables>(
    "CreateTags",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTradingNamesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Deals })
 *   )
 * })
 */
export const mockGetTradingNamesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTradingNamesQueryVariables>,
    GraphQLContext<GetTradingNamesQuery>,
    any
  >
) =>
  graphql.query<GetTradingNamesQuery, GetTradingNamesQueryVariables>(
    "GetTradingNames",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTermsDataQuery((req, res, ctx) => {
 *   const { DealID } = req.variables;
 *   return res(
 *     ctx.data({ DealData })
 *   )
 * })
 */
export const mockGetTermsDataQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTermsDataQueryVariables>,
    GraphQLContext<GetTermsDataQuery>,
    any
  >
) =>
  graphql.query<GetTermsDataQuery, GetTermsDataQueryVariables>(
    "GetTermsData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsersQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ Users })
 *   )
 * })
 */
export const mockGetUsersQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUsersQueryVariables>,
    GraphQLContext<GetUsersQuery>,
    any
  >
) => graphql.query<GetUsersQuery, GetUsersQueryVariables>("GetUsers", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateTDassigneeMutation((req, res, ctx) => {
 *   const { deal_id, user_id, description } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals_by_pk })
 *   )
 * })
 */
export const mockUpdateTDassigneeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateTDassigneeMutationVariables>,
    GraphQLContext<UpdateTDassigneeMutation>,
    any
  >
) =>
  graphql.mutation<UpdateTDassigneeMutation, UpdateTDassigneeMutationVariables>(
    "UpdateTDassignee",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateLAassigneeMutation((req, res, ctx) => {
 *   const { deal_id, user_id, description } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals_by_pk })
 *   )
 * })
 */
export const mockUpdateLAassigneeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateLAassigneeMutationVariables>,
    GraphQLContext<UpdateLAassigneeMutation>,
    any
  >
) =>
  graphql.mutation<UpdateLAassigneeMutation, UpdateLAassigneeMutationVariables>(
    "UpdateLAassignee",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateOwnerMutation((req, res, ctx) => {
 *   const { deal_id, user_id, description } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals_by_pk })
 *   )
 * })
 */
export const mockUpdateOwnerMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateOwnerMutationVariables>,
    GraphQLContext<UpdateOwnerMutation>,
    any
  >
) =>
  graphql.mutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(
    "UpdateOwner",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiContactsByTenantQuery((req, res, ctx) => {
 *   const { tenant_code } = req.variables;
 *   return res(
 *     ctx.data({ YardiTenant_YardiContacts })
 *   )
 * })
 */
export const mockGetYardiContactsByTenantQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiContactsByTenantQueryVariables>,
    GraphQLContext<GetYardiContactsByTenantQuery>,
    any
  >
) =>
  graphql.query<
    GetYardiContactsByTenantQuery,
    GetYardiContactsByTenantQueryVariables
  >("GetYardiContactsByTenant", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiLimitedCustomersQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ YardiLimitedCustomers })
 *   )
 * })
 */
export const mockGetYardiLimitedCustomersQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiLimitedCustomersQueryVariables>,
    GraphQLContext<GetYardiLimitedCustomersQuery>,
    any
  >
) =>
  graphql.query<
    GetYardiLimitedCustomersQuery,
    GetYardiLimitedCustomersQueryVariables
  >("GetYardiLimitedCustomers", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetChargesInformationQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealData })
 *   )
 * })
 */
export const mockGetChargesInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetChargesInformationQueryVariables>,
    GraphQLContext<GetChargesInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetChargesInformationQuery,
    GetChargesInformationQueryVariables
  >("GetChargesInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateChargesInformationMutation((req, res, ctx) => {
 *   const { deal_id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateChargesInformationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateChargesInformationMutationVariables>,
    GraphQLContext<UpdateChargesInformationMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateChargesInformationMutation,
    UpdateChargesInformationMutationVariables
  >("UpdateChargesInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealExtensionItemsMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealExtensionItems })
 *   )
 * })
 */
export const mockCreateDealExtensionItemsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealExtensionItemsMutationVariables>,
    GraphQLContext<CreateDealExtensionItemsMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealExtensionItemsMutation,
    CreateDealExtensionItemsMutationVariables
  >("CreateDealExtensionItems", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealCustomChargesMutation((req, res, ctx) => {
 *   const { deal_data_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealExtensionItems })
 *   )
 * })
 */
export const mockDeleteDealCustomChargesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealCustomChargesMutationVariables>,
    GraphQLContext<DeleteDealCustomChargesMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealCustomChargesMutation,
    DeleteDealCustomChargesMutationVariables
  >("DeleteDealCustomCharges", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInfoCommercialQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInfoCommercialQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInfoCommercialQueryVariables>,
    GraphQLContext<GetDealInfoCommercialQuery>,
    any
  >
) =>
  graphql.query<
    GetDealInfoCommercialQuery,
    GetDealInfoCommercialQueryVariables
  >("GetDealInfoCommercial", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInfoDateQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInfoDateQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInfoDateQueryVariables>,
    GraphQLContext<GetDealInfoDateQuery>,
    any
  >
) =>
  graphql.query<GetDealInfoDateQuery, GetDealInfoDateQueryVariables>(
    "GetDealInfoDate",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealInfoDateMutation((req, res, ctx) => {
 *   const { deal_id, update_object } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateDealInfoDateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealInfoDateMutationVariables>,
    GraphQLContext<UpdateDealInfoDateMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealInfoDateMutation,
    UpdateDealInfoDateMutationVariables
  >("UpdateDealInfoDate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealDataInfoMutation((req, res, ctx) => {
 *   const { deal_id, update_object } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateDealDataInfoMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealDataInfoMutationVariables>,
    GraphQLContext<UpdateDealDataInfoMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealDataInfoMutation,
    UpdateDealDataInfoMutationVariables
  >("UpdateDealDataInfo", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealDataIdQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealData })
 *   )
 * })
 */
export const mockGetDealDataIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealDataIdQueryVariables>,
    GraphQLContext<GetDealDataIdQuery>,
    any
  >
) =>
  graphql.query<GetDealDataIdQuery, GetDealDataIdQueryVariables>(
    "GetDealDataId",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDealShopActivityMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealShopActivities_one })
 *   )
 * })
 */
export const mockAddDealShopActivityMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddDealShopActivityMutationVariables>,
    GraphQLContext<AddDealShopActivityMutation>,
    any
  >
) =>
  graphql.mutation<
    AddDealShopActivityMutation,
    AddDealShopActivityMutationVariables
  >("AddDealShopActivity", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOpenDateFromShopHandoverActivitiesQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealShopActivities })
 *   )
 * })
 */
export const mockGetOpenDateFromShopHandoverActivitiesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetOpenDateFromShopHandoverActivitiesQueryVariables>,
    GraphQLContext<GetOpenDateFromShopHandoverActivitiesQuery>,
    any
  >
) =>
  graphql.query<
    GetOpenDateFromShopHandoverActivitiesQuery,
    GetOpenDateFromShopHandoverActivitiesQueryVariables
  >("GetOpenDateFromShopHandoverActivities", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealTypesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ DealTypes })
 *   )
 * })
 */
export const mockGetDealTypesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealTypesQueryVariables>,
    GraphQLContext<GetDealTypesQuery>,
    any
  >
) =>
  graphql.query<GetDealTypesQuery, GetDealTypesQueryVariables>(
    "GetDealTypes",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealAnalystDataQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealAnalystDataQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealAnalystDataQueryVariables>,
    GraphQLContext<GetDealAnalystDataQuery>,
    any
  >
) =>
  graphql.query<GetDealAnalystDataQuery, GetDealAnalystDataQueryVariables>(
    "GetDealAnalystData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealAnalystDataMutation((req, res, ctx) => {
 *   const { deal_id, analyst_deal_comp, analyst_normalised_base_rent, analyst_normalised_promo, analyst_deal_reason } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateDealAnalystDataMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealAnalystDataMutationVariables>,
    GraphQLContext<UpdateDealAnalystDataMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealAnalystDataMutation,
    UpdateDealAnalystDataMutationVariables
  >("UpdateDealAnalystData", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealTypeMutation((req, res, ctx) => {
 *   const { deal_id, deal_type_id } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals_by_pk })
 *   )
 * })
 */
export const mockUpdateDealTypeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealTypeMutationVariables>,
    GraphQLContext<UpdateDealTypeMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealTypeMutation, UpdateDealTypeMutationVariables>(
    "UpdateDealType",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositInformationQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDepositInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositInformationQueryVariables>,
    GraphQLContext<GetDepositInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetDepositInformationQuery,
    GetDepositInformationQueryVariables
  >("GetDepositInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealDataGuarantorsMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealDataGuarantors })
 *   )
 * })
 */
export const mockDeleteDealDataGuarantorsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealDataGuarantorsMutationVariables>,
    GraphQLContext<DeleteDealDataGuarantorsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealDataGuarantorsMutation,
    DeleteDealDataGuarantorsMutationVariables
  >("DeleteDealDataGuarantors", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealDataGuarantorsMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealDataGuarantors })
 *   )
 * })
 */
export const mockCreateDealDataGuarantorsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealDataGuarantorsMutationVariables>,
    GraphQLContext<CreateDealDataGuarantorsMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealDataGuarantorsMutation,
    CreateDealDataGuarantorsMutationVariables
  >("CreateDealDataGuarantors", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDepositInformationMutation((req, res, ctx) => {
 *   const { deal_id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateDepositInformationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDepositInformationMutationVariables>,
    GraphQLContext<UpdateDepositInformationMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDepositInformationMutation,
    UpdateDepositInformationMutationVariables
  >("UpdateDepositInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealFitoutLandlordWorksQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealFitoutLandlordWorksQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealFitoutLandlordWorksQueryVariables>,
    GraphQLContext<GetDealFitoutLandlordWorksQuery>,
    any
  >
) =>
  graphql.query<
    GetDealFitoutLandlordWorksQuery,
    GetDealFitoutLandlordWorksQueryVariables
  >("GetDealFitoutLandlordWorks", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealFitoutLandlordWorksMutation((req, res, ctx) => {
 *   const { update_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealFitoutLandlordWorks_one })
 *   )
 * })
 */
export const mockUpdateDealFitoutLandlordWorksMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealFitoutLandlordWorksMutationVariables>,
    GraphQLContext<UpdateDealFitoutLandlordWorksMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealFitoutLandlordWorksMutation,
    UpdateDealFitoutLandlordWorksMutationVariables
  >("updateDealFitoutLandlordWorks", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetHistoricalDataQuery((req, res, ctx) => {
 *   const { deal_id, filter_tenant_dates } = req.variables;
 *   return res(
 *     ctx.data({ DealData })
 *   )
 * })
 */
export const mockGetHistoricalDataQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetHistoricalDataQueryVariables>,
    GraphQLContext<GetHistoricalDataQuery>,
    any
  >
) =>
  graphql.query<GetHistoricalDataQuery, GetHistoricalDataQueryVariables>(
    "GetHistoricalData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateHistoricalDataMutation((req, res, ctx) => {
 *   const { deal_data_id, historical_base_rent, historical_outgoings, historical_promo_levy, historical_tenant_name } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateHistoricalDataMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateHistoricalDataMutationVariables>,
    GraphQLContext<UpdateHistoricalDataMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateHistoricalDataMutation,
    UpdateHistoricalDataMutationVariables
  >("UpdateHistoricalData", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLandlordInformationQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ DealData })
 *   )
 * })
 */
export const mockGetLandlordInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLandlordInformationQueryVariables>,
    GraphQLContext<GetLandlordInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetLandlordInformationQuery,
    GetLandlordInformationQueryVariables
  >("GetLandlordInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateBaseLandlordServicesMutation((req, res, ctx) => {
 *   const { deal_id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_BaseLandlordServices })
 *   )
 * })
 */
export const mockUpdateBaseLandlordServicesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateBaseLandlordServicesMutationVariables>,
    GraphQLContext<UpdateBaseLandlordServicesMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateBaseLandlordServicesMutation,
    UpdateBaseLandlordServicesMutationVariables
  >("UpdateBaseLandlordServices", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInfoDealOptionsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInfoDealOptionsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInfoDealOptionsQueryVariables>,
    GraphQLContext<GetDealInfoDealOptionsQuery>,
    any
  >
) =>
  graphql.query<
    GetDealInfoDealOptionsQuery,
    GetDealInfoDealOptionsQueryVariables
  >("GetDealInfoDealOptions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealOptionsMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealOptions })
 *   )
 * })
 */
export const mockDeleteDealOptionsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealOptionsMutationVariables>,
    GraphQLContext<DeleteDealOptionsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealOptionsMutation,
    DeleteDealOptionsMutationVariables
  >("DeleteDealOptions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealOptionsMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealOptions })
 *   )
 * })
 */
export const mockCreateDealOptionsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealOptionsMutationVariables>,
    GraphQLContext<CreateDealOptionsMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealOptionsMutation,
    CreateDealOptionsMutationVariables
  >("CreateDealOptions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealSpecialConditionsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk, TemplateSpecialConditions })
 *   )
 * })
 */
export const mockGetDealSpecialConditionsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealSpecialConditionsQueryVariables>,
    GraphQLContext<GetDealSpecialConditionsQuery>,
    any
  >
) =>
  graphql.query<
    GetDealSpecialConditionsQuery,
    GetDealSpecialConditionsQueryVariables
  >("GetDealSpecialConditions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteAndInsertDealSpecialConditionsMutation((req, res, ctx) => {
 *   const { deal_id, deal_options } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealSpecialConditions, insert_DealSpecialConditions })
 *   )
 * })
 */
export const mockDeleteAndInsertDealSpecialConditionsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteAndInsertDealSpecialConditionsMutationVariables>,
    GraphQLContext<DeleteAndInsertDealSpecialConditionsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteAndInsertDealSpecialConditionsMutation,
    DeleteAndInsertDealSpecialConditionsMutationVariables
  >("DeleteAndInsertDealSpecialConditions", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStorageInformationQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetStorageInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetStorageInformationQueryVariables>,
    GraphQLContext<GetStorageInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetStorageInformationQuery,
    GetStorageInformationQueryVariables
  >("GetStorageInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateStorageInformationMutation((req, res, ctx) => {
 *   const { deal_id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateStorageInformationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateStorageInformationMutationVariables>,
    GraphQLContext<UpdateStorageInformationMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateStorageInformationMutation,
    UpdateStorageInformationMutationVariables
  >("UpdateStorageInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCustomLicencesMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_CustomLicences })
 *   )
 * })
 */
export const mockCreateCustomLicencesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateCustomLicencesMutationVariables>,
    GraphQLContext<CreateCustomLicencesMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateCustomLicencesMutation,
    CreateCustomLicencesMutationVariables
  >("CreateCustomLicences", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealDataCustomLicencesMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealDataCustomLicences })
 *   )
 * })
 */
export const mockDeleteDealDataCustomLicencesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealDataCustomLicencesMutationVariables>,
    GraphQLContext<DeleteDealDataCustomLicencesMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealDataCustomLicencesMutation,
    DeleteDealDataCustomLicencesMutationVariables
  >("DeleteDealDataCustomLicences", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateDealDataCustomLicencesMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealDataCustomLicences })
 *   )
 * })
 */
export const mockCreateDealDataCustomLicencesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateDealDataCustomLicencesMutationVariables>,
    GraphQLContext<CreateDealDataCustomLicencesMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateDealDataCustomLicencesMutation,
    CreateDealDataCustomLicencesMutationVariables
  >("CreateDealDataCustomLicences", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInformationTenantInformationQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInformationTenantInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInformationTenantInformationQueryVariables>,
    GraphQLContext<GetDealInformationTenantInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetDealInformationTenantInformationQuery,
    GetDealInformationTenantInformationQueryVariables
  >("GetDealInformationTenantInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealTenantTypeMutation((req, res, ctx) => {
 *   const { deal_id, tenant_type } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals_by_pk })
 *   )
 * })
 */
export const mockUpdateDealTenantTypeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealTenantTypeMutationVariables>,
    GraphQLContext<UpdateDealTenantTypeMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealTenantTypeMutation,
    UpdateDealTenantTypeMutationVariables
  >("UpdateDealTenantType", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealsContactsMutation((req, res, ctx) => {
 *   const { deal_id, dealsContacts } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealsContacts, insert_DealsContacts })
 *   )
 * })
 */
export const mockUpdateDealsContactsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealsContactsMutationVariables>,
    GraphQLContext<UpdateDealsContactsMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealsContactsMutation,
    UpdateDealsContactsMutationVariables
  >("UpdateDealsContacts", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealsContactsMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealsContacts })
 *   )
 * })
 */
export const mockDeleteDealsContactsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealsContactsMutationVariables>,
    GraphQLContext<DeleteDealsContactsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealsContactsMutation,
    DeleteDealsContactsMutationVariables
  >("DeleteDealsContacts", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealsTenantContactsMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealsContacts })
 *   )
 * })
 */
export const mockDeleteDealsTenantContactsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealsTenantContactsMutationVariables>,
    GraphQLContext<DeleteDealsTenantContactsMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealsTenantContactsMutation,
    DeleteDealsTenantContactsMutationVariables
  >("DeleteDealsTenantContacts", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDealsCompaniesMutation((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealsCompanies })
 *   )
 * })
 */
export const mockDeleteDealsCompaniesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteDealsCompaniesMutationVariables>,
    GraphQLContext<DeleteDealsCompaniesMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteDealsCompaniesMutation,
    DeleteDealsCompaniesMutationVariables
  >("DeleteDealsCompanies", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealsCompaniesMutation((req, res, ctx) => {
 *   const { deal_id, dealsCompanies } = req.variables;
 *   return res(
 *     ctx.data({ delete_DealsCompanies, insert_DealsCompanies })
 *   )
 * })
 */
export const mockUpdateDealsCompaniesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealsCompaniesMutationVariables>,
    GraphQLContext<UpdateDealsCompaniesMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealsCompaniesMutation,
    UpdateDealsCompaniesMutationVariables
  >("UpdateDealsCompanies", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealDataMutation((req, res, ctx) => {
 *   const { deal_id, deal_data } = req.variables;
 *   return res(
 *     ctx.data({ update_DealData })
 *   )
 * })
 */
export const mockUpdateDealDataMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealDataMutationVariables>,
    GraphQLContext<UpdateDealDataMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealDataMutation, UpdateDealDataMutationVariables>(
    "UpdateDealData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealTradingNameMutation((req, res, ctx) => {
 *   const { deal_id, trading_name, customer_code } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals })
 *   )
 * })
 */
export const mockUpdateDealTradingNameMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealTradingNameMutationVariables>,
    GraphQLContext<UpdateDealTradingNameMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealTradingNameMutation,
    UpdateDealTradingNameMutationVariables
  >("UpdateDealTradingName", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiCustomersQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ YardiCustomers })
 *   )
 * })
 */
export const mockGetYardiCustomersQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiCustomersQueryVariables>,
    GraphQLContext<GetYardiCustomersQuery>,
    any
  >
) =>
  graphql.query<GetYardiCustomersQuery, GetYardiCustomersQueryVariables>(
    "GetYardiCustomers",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealCustomerMutation((req, res, ctx) => {
 *   const { deal_id, customer_code } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals })
 *   )
 * })
 */
export const mockUpdateDealCustomerMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealCustomerMutationVariables>,
    GraphQLContext<UpdateDealCustomerMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealCustomerMutation,
    UpdateDealCustomerMutationVariables
  >("UpdateDealCustomer", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLeaseTypesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ YardiTenants })
 *   )
 * })
 */
export const mockGetLeaseTypesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLeaseTypesQueryVariables>,
    GraphQLContext<GetLeaseTypesQuery>,
    any
  >
) =>
  graphql.query<GetLeaseTypesQuery, GetLeaseTypesQueryVariables>(
    "GetLeaseTypes",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInfoUnitInfoQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInfoUnitInfoQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInfoUnitInfoQueryVariables>,
    GraphQLContext<GetDealInfoUnitInfoQuery>,
    any
  >
) =>
  graphql.query<GetDealInfoUnitInfoQuery, GetDealInfoUnitInfoQueryVariables>(
    "GetDealInfoUnitInfo",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCheckDealExistsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockCheckDealExistsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CheckDealExistsQueryVariables>,
    GraphQLContext<CheckDealExistsQuery>,
    any
  >
) =>
  graphql.query<CheckDealExistsQuery, CheckDealExistsQueryVariables>(
    "CheckDealExists",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealUnitMutation((req, res, ctx) => {
 *   const { deal_id, unit_id, virtual_unit_id, area_survey_required, description } = req.variables;
 *   return res(
 *     ctx.data({ update_DealsUnits })
 *   )
 * })
 */
export const mockUpdateDealUnitMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealUnitMutationVariables>,
    GraphQLContext<UpdateDealUnitMutation>,
    any
  >
) =>
  graphql.mutation<UpdateDealUnitMutation, UpdateDealUnitMutationVariables>(
    "UpdateDealUnit",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddVirtualUnitMutation((req, res, ctx) => {
 *   const { unit } = req.variables;
 *   return res(
 *     ctx.data({ insert_VirtualUnits_one })
 *   )
 * })
 */
export const mockAddVirtualUnitMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddVirtualUnitMutationVariables>,
    GraphQLContext<AddVirtualUnitMutation>,
    any
  >
) =>
  graphql.mutation<AddVirtualUnitMutation, AddVirtualUnitMutationVariables>(
    "AddVirtualUnit",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealInfoUsageQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealInfoUsageQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealInfoUsageQueryVariables>,
    GraphQLContext<GetDealInfoUsageQuery>,
    any
  >
) =>
  graphql.query<GetDealInfoUsageQuery, GetDealInfoUsageQueryVariables>(
    "GetDealInfoUsage",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsagePermittedUsesTemplateQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ TemplatePermittedUses })
 *   )
 * })
 */
export const mockGetUsagePermittedUsesTemplateQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUsagePermittedUsesTemplateQueryVariables>,
    GraphQLContext<GetUsagePermittedUsesTemplateQuery>,
    any
  >
) =>
  graphql.query<
    GetUsagePermittedUsesTemplateQuery,
    GetUsagePermittedUsesTemplateQueryVariables
  >("GetUsagePermittedUsesTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPermittedUseTemplateQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ TemplatePermittedUses_by_pk })
 *   )
 * })
 */
export const mockGetPermittedUseTemplateQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPermittedUseTemplateQueryVariables>,
    GraphQLContext<GetPermittedUseTemplateQuery>,
    any
  >
) =>
  graphql.query<
    GetPermittedUseTemplateQuery,
    GetPermittedUseTemplateQueryVariables
  >("GetPermittedUseTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePermittedUseTemplateMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_TemplatePermittedUses_one })
 *   )
 * })
 */
export const mockCreatePermittedUseTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreatePermittedUseTemplateMutationVariables>,
    GraphQLContext<CreatePermittedUseTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    CreatePermittedUseTemplateMutation,
    CreatePermittedUseTemplateMutationVariables
  >("CreatePermittedUseTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePermittedUseTemplateMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_TemplatePermittedUses_by_pk })
 *   )
 * })
 */
export const mockUpdatePermittedUseTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdatePermittedUseTemplateMutationVariables>,
    GraphQLContext<UpdatePermittedUseTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdatePermittedUseTemplateMutation,
    UpdatePermittedUseTemplateMutationVariables
  >("UpdatePermittedUseTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealPermittedUsesMutation((req, res, ctx) => {
 *   const { update_object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealPermittedUses_one })
 *   )
 * })
 */
export const mockUpdateDealPermittedUsesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealPermittedUsesMutationVariables>,
    GraphQLContext<UpdateDealPermittedUsesMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealPermittedUsesMutation,
    UpdateDealPermittedUsesMutationVariables
  >("updateDealPermittedUses", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePermittedUseTemplateMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ delete_TemplatePermittedUses })
 *   )
 * })
 */
export const mockDeletePermittedUseTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeletePermittedUseTemplateMutationVariables>,
    GraphQLContext<DeletePermittedUseTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    DeletePermittedUseTemplateMutation,
    DeletePermittedUseTemplateMutationVariables
  >("DeletePermittedUseTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealsQuery((req, res, ctx) => {
 *   const { filters } = req.variables;
 *   return res(
 *     ctx.data({ Deals })
 *   )
 * })
 */
export const mockGetDealsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealsQueryVariables>,
    GraphQLContext<GetDealsQuery>,
    any
  >
) => graphql.query<GetDealsQuery, GetDealsQueryVariables>("GetDeals", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealQueryVariables>,
    GraphQLContext<GetDealQuery>,
    any
  >
) => graphql.query<GetDealQuery, GetDealQueryVariables>("GetDeal", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealRiskLevelQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetDealRiskLevelQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealRiskLevelQueryVariables>,
    GraphQLContext<GetDealRiskLevelQuery>,
    any
  >
) =>
  graphql.query<GetDealRiskLevelQuery, GetDealRiskLevelQueryVariables>(
    "GetDealRiskLevel",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealActionItemMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_DealActionItem_by_pk })
 *   )
 * })
 */
export const mockUpdateDealActionItemMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealActionItemMutationVariables>,
    GraphQLContext<UpdateDealActionItemMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealActionItemMutation,
    UpdateDealActionItemMutationVariables
  >("UpdateDealActionItem", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateDealRiskLevelMutation((req, res, ctx) => {
 *   const { id, risk_level } = req.variables;
 *   return res(
 *     ctx.data({ update_Deals })
 *   )
 * })
 */
export const mockUpdateDealRiskLevelMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateDealRiskLevelMutationVariables>,
    GraphQLContext<UpdateDealRiskLevelMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateDealRiskLevelMutation,
    UpdateDealRiskLevelMutationVariables
  >("UpdateDealRiskLevel", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInsertDealActionItemMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealActionItem_one })
 *   )
 * })
 */
export const mockInsertDealActionItemMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InsertDealActionItemMutationVariables>,
    GraphQLContext<InsertDealActionItemMutation>,
    any
  >
) =>
  graphql.mutation<
    InsertDealActionItemMutation,
    InsertDealActionItemMutationVariables
  >("InsertDealActionItem", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealsByTenantCodeQuery((req, res, ctx) => {
 *   const { tenant_code } = req.variables;
 *   return res(
 *     ctx.data({ Deals })
 *   )
 * })
 */
export const mockGetDealsByTenantCodeQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealsByTenantCodeQueryVariables>,
    GraphQLContext<GetDealsByTenantCodeQuery>,
    any
  >
) =>
  graphql.query<GetDealsByTenantCodeQuery, GetDealsByTenantCodeQueryVariables>(
    "GetDealsByTenantCode",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLeasesForRenewalQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ YardiLeasesForRenewal })
 *   )
 * })
 */
export const mockGetLeasesForRenewalQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLeasesForRenewalQueryVariables>,
    GraphQLContext<GetLeasesForRenewalQuery>,
    any
  >
) =>
  graphql.query<GetLeasesForRenewalQuery, GetLeasesForRenewalQueryVariables>(
    "GetLeasesForRenewal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiOutgoingsQuery((req, res, ctx) => {
 *   const { unit_key } = req.variables;
 *   return res(
 *     ctx.data({ YardiUnits })
 *   )
 * })
 */
export const mockGetYardiOutgoingsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiOutgoingsQueryVariables>,
    GraphQLContext<GetYardiOutgoingsQuery>,
    any
  >
) =>
  graphql.query<GetYardiOutgoingsQuery, GetYardiOutgoingsQueryVariables>(
    "GetYardiOutgoings",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafDataQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafDataQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafDataQueryVariables>,
    GraphQLContext<GetLafDataQuery>,
    any
  >
) =>
  graphql.query<GetLafDataQuery, GetLafDataQueryVariables>(
    "GetLafData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafDealDataQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafDealDataQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafDealDataQueryVariables>,
    GraphQLContext<GetLafDealDataQuery>,
    any
  >
) =>
  graphql.query<GetLafDealDataQuery, GetLafDealDataQueryVariables>(
    "GetLafDealData",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafLeaseDetailsQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafLeaseDetailsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafLeaseDetailsQueryVariables>,
    GraphQLContext<GetLafLeaseDetailsQuery>,
    any
  >
) =>
  graphql.query<GetLafLeaseDetailsQuery, GetLafLeaseDetailsQueryVariables>(
    "GetLafLeaseDetails",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafSecurityQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafSecurityQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafSecurityQueryVariables>,
    GraphQLContext<GetLafSecurityQuery>,
    any
  >
) =>
  graphql.query<GetLafSecurityQuery, GetLafSecurityQueryVariables>(
    "GetLafSecurity",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafIncentiveQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafIncentiveQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafIncentiveQueryVariables>,
    GraphQLContext<GetLafIncentiveQuery>,
    any
  >
) =>
  graphql.query<GetLafIncentiveQuery, GetLafIncentiveQueryVariables>(
    "GetLafIncentive",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafOtherInfoQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafOtherInfoQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafOtherInfoQueryVariables>,
    GraphQLContext<GetLafOtherInfoQuery>,
    any
  >
) =>
  graphql.query<GetLafOtherInfoQuery, GetLafOtherInfoQueryVariables>(
    "GetLafOtherInfo",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafUsageQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafUsageQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafUsageQueryVariables>,
    GraphQLContext<GetLafUsageQuery>,
    any
  >
) =>
  graphql.query<GetLafUsageQuery, GetLafUsageQueryVariables>(
    "GetLafUsage",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafSpecialConditionQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafSpecialConditionQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafSpecialConditionQueryVariables>,
    GraphQLContext<GetLafSpecialConditionQuery>,
    any
  >
) =>
  graphql.query<
    GetLafSpecialConditionQuery,
    GetLafSpecialConditionQueryVariables
  >("GetLafSpecialCondition", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateApprovalsMutation((req, res, ctx) => {
 *   const { deal_data_id, laf_action, modified_at } = req.variables;
 *   return res(
 *     ctx.data({ insert_DealLAFApprovals_one })
 *   )
 * })
 */
export const mockUpdateApprovalsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateApprovalsMutationVariables>,
    GraphQLContext<UpdateApprovalsMutation>,
    any
  >
) =>
  graphql.mutation<UpdateApprovalsMutation, UpdateApprovalsMutationVariables>(
    "UpdateApprovals",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLafVersionQuery((req, res, ctx) => {
 *   const { deal_id } = req.variables;
 *   return res(
 *     ctx.data({ Deals_by_pk })
 *   )
 * })
 */
export const mockGetLafVersionQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLafVersionQueryVariables>,
    GraphQLContext<GetLafVersionQuery>,
    any
  >
) =>
  graphql.query<GetLafVersionQuery, GetLafVersionQueryVariables>(
    "GetLafVersion",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertyDetailsQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ YardiPropertyProfiles })
 *   )
 * })
 */
export const mockGetPropertyDetailsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertyDetailsQueryVariables>,
    GraphQLContext<GetPropertyDetailsQuery>,
    any
  >
) =>
  graphql.query<GetPropertyDetailsQuery, GetPropertyDetailsQueryVariables>(
    "GetPropertyDetails",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertyExpenseQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ YardiPropertyProfiles })
 *   )
 * })
 */
export const mockGetPropertyExpenseQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertyExpenseQueryVariables>,
    GraphQLContext<GetPropertyExpenseQuery>,
    any
  >
) =>
  graphql.query<GetPropertyExpenseQuery, GetPropertyExpenseQueryVariables>(
    "GetPropertyExpense",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLandlordFacilitiesQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ YardiPropertyProfiles })
 *   )
 * })
 */
export const mockGetLandlordFacilitiesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLandlordFacilitiesQueryVariables>,
    GraphQLContext<GetLandlordFacilitiesQuery>,
    any
  >
) =>
  graphql.query<
    GetLandlordFacilitiesQuery,
    GetLandlordFacilitiesQueryVariables
  >("GetLandlordFacilities", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertyInformationQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ PropertiesInformation })
 *   )
 * })
 */
export const mockGetPropertyInformationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertyInformationQueryVariables>,
    GraphQLContext<GetPropertyInformationQuery>,
    any
  >
) =>
  graphql.query<
    GetPropertyInformationQuery,
    GetPropertyInformationQueryVariables
  >("GetPropertyInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpsertPropertiesInformationMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_PropertiesInformation_one })
 *   )
 * })
 */
export const mockUpsertPropertiesInformationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpsertPropertiesInformationMutationVariables>,
    GraphQLContext<UpsertPropertiesInformationMutation>,
    any
  >
) =>
  graphql.mutation<
    UpsertPropertiesInformationMutation,
    UpsertPropertiesInformationMutationVariables
  >("UpsertPropertiesInformation", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPropertyFilesQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ PropertyFiles })
 *   )
 * })
 */
export const mockGetPropertyFilesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPropertyFilesQueryVariables>,
    GraphQLContext<GetPropertyFilesQuery>,
    any
  >
) =>
  graphql.query<GetPropertyFilesQuery, GetPropertyFilesQueryVariables>(
    "GetPropertyFiles",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePropertyFileMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_PropertyFiles_one })
 *   )
 * })
 */
export const mockCreatePropertyFileMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreatePropertyFileMutationVariables>,
    GraphQLContext<CreatePropertyFileMutation>,
    any
  >
) =>
  graphql.mutation<
    CreatePropertyFileMutation,
    CreatePropertyFileMutationVariables
  >("CreatePropertyFile", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUnitsByPropertyQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ Units })
 *   )
 * })
 */
export const mockGetUnitsByPropertyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUnitsByPropertyQueryVariables>,
    GraphQLContext<GetUnitsByPropertyQuery>,
    any
  >
) =>
  graphql.query<GetUnitsByPropertyQuery, GetUnitsByPropertyQueryVariables>(
    "GetUnitsByProperty",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCentreMapsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ PropertyFiles })
 *   )
 * })
 */
export const mockGetCentreMapsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetCentreMapsQueryVariables>,
    GraphQLContext<GetCentreMapsQuery>,
    any
  >
) =>
  graphql.query<GetCentreMapsQuery, GetCentreMapsQueryVariables>(
    "GetCentreMaps",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLegislationsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ PropertyFiles })
 *   )
 * })
 */
export const mockGetLegislationsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLegislationsQueryVariables>,
    GraphQLContext<GetLegislationsQuery>,
    any
  >
) =>
  graphql.query<GetLegislationsQuery, GetLegislationsQueryVariables>(
    "GetLegislations",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetYardiPropertyContactsQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ YardiProperties_by_pk })
 *   )
 * })
 */
export const mockGetYardiPropertyContactsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetYardiPropertyContactsQueryVariables>,
    GraphQLContext<GetYardiPropertyContactsQuery>,
    any
  >
) =>
  graphql.query<
    GetYardiPropertyContactsQuery,
    GetYardiPropertyContactsQueryVariables
  >("GetYardiPropertyContacts", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetVirtualUnitsByPropertyQuery((req, res, ctx) => {
 *   const { property_code } = req.variables;
 *   return res(
 *     ctx.data({ VirtualUnits })
 *   )
 * })
 */
export const mockGetVirtualUnitsByPropertyQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetVirtualUnitsByPropertyQueryVariables>,
    GraphQLContext<GetVirtualUnitsByPropertyQuery>,
    any
  >
) =>
  graphql.query<
    GetVirtualUnitsByPropertyQuery,
    GetVirtualUnitsByPropertyQueryVariables
  >("GetVirtualUnitsByProperty", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteVirtualUnitMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ update_VirtualUnits })
 *   )
 * })
 */
export const mockDeleteVirtualUnitMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteVirtualUnitMutationVariables>,
    GraphQLContext<DeleteVirtualUnitMutation>,
    any
  >
) =>
  graphql.mutation<
    DeleteVirtualUnitMutation,
    DeleteVirtualUnitMutationVariables
  >("DeleteVirtualUnit", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateVirtualUnitMutation((req, res, ctx) => {
 *   const { id, label, estimated_area } = req.variables;
 *   return res(
 *     ctx.data({ update_VirtualUnits })
 *   )
 * })
 */
export const mockUpdateVirtualUnitMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateVirtualUnitMutationVariables>,
    GraphQLContext<UpdateVirtualUnitMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateVirtualUnitMutation,
    UpdateVirtualUnitMutationVariables
  >("UpdateVirtualUnit", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDealsUnitsForVirtualUnitQuery((req, res, ctx) => {
 *   const { virtual_unit_id } = req.variables;
 *   return res(
 *     ctx.data({ DealsUnits })
 *   )
 * })
 */
export const mockGetDealsUnitsForVirtualUnitQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDealsUnitsForVirtualUnitQueryVariables>,
    GraphQLContext<GetDealsUnitsForVirtualUnitQuery>,
    any
  >
) =>
  graphql.query<
    GetDealsUnitsForVirtualUnitQuery,
    GetDealsUnitsForVirtualUnitQueryVariables
  >("GetDealsUnitsForVirtualUnit", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSearchDocumentsQuery((req, res, ctx) => {
 *   const { searchQuery } = req.variables;
 *   return res(
 *     ctx.data({ searchDocumentsSearch })
 *   )
 * })
 */
export const mockSearchDocumentsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<SearchDocumentsQueryVariables>,
    GraphQLContext<SearchDocumentsQuery>,
    any
  >
) =>
  graphql.query<SearchDocumentsQuery, SearchDocumentsQueryVariables>(
    "SearchDocuments",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetSpecialConditionTemplatesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ TemplateSpecialConditions })
 *   )
 * })
 */
export const mockGetSpecialConditionTemplatesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetSpecialConditionTemplatesQueryVariables>,
    GraphQLContext<GetSpecialConditionTemplatesQuery>,
    any
  >
) =>
  graphql.query<
    GetSpecialConditionTemplatesQuery,
    GetSpecialConditionTemplatesQueryVariables
  >("GetSpecialConditionTemplates", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetSpecialConditionTemplateQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ TemplateSpecialConditions_by_pk })
 *   )
 * })
 */
export const mockGetSpecialConditionTemplateQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetSpecialConditionTemplateQueryVariables>,
    GraphQLContext<GetSpecialConditionTemplateQuery>,
    any
  >
) =>
  graphql.query<
    GetSpecialConditionTemplateQuery,
    GetSpecialConditionTemplateQueryVariables
  >("GetSpecialConditionTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSpecialConditionTemplateMutation((req, res, ctx) => {
 *   const { data } = req.variables;
 *   return res(
 *     ctx.data({ insert_TemplateSpecialConditions_one })
 *   )
 * })
 */
export const mockCreateSpecialConditionTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateSpecialConditionTemplateMutationVariables>,
    GraphQLContext<CreateSpecialConditionTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateSpecialConditionTemplateMutation,
    CreateSpecialConditionTemplateMutationVariables
  >("CreateSpecialConditionTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateSpecialConditionTemplateMutation((req, res, ctx) => {
 *   const { id, data } = req.variables;
 *   return res(
 *     ctx.data({ update_TemplateSpecialConditions_by_pk })
 *   )
 * })
 */
export const mockUpdateSpecialConditionTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateSpecialConditionTemplateMutationVariables>,
    GraphQLContext<UpdateSpecialConditionTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateSpecialConditionTemplateMutation,
    UpdateSpecialConditionTemplateMutationVariables
  >("UpdateSpecialConditionTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateTemplateMutation((req, res, ctx) => {
 *   const { objects } = req.variables;
 *   return res(
 *     ctx.data({ insert_DocumentTemplates })
 *   )
 * })
 */
export const mockCreateTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateTemplateMutationVariables>,
    GraphQLContext<CreateTemplateMutation>,
    any
  >
) =>
  graphql.mutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
    "CreateTemplate",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDocumentTemplatesQuery((req, res, ctx) => {
 *   const { filters } = req.variables;
 *   return res(
 *     ctx.data({ DocumentTemplates })
 *   )
 * })
 */
export const mockGetDocumentTemplatesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDocumentTemplatesQueryVariables>,
    GraphQLContext<GetDocumentTemplatesQuery>,
    any
  >
) =>
  graphql.query<GetDocumentTemplatesQuery, GetDocumentTemplatesQueryVariables>(
    "GetDocumentTemplates",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSoftDeleteDocumentTemplateMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ update_DocumentTemplates_by_pk })
 *   )
 * })
 */
export const mockSoftDeleteDocumentTemplateMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SoftDeleteDocumentTemplateMutationVariables>,
    GraphQLContext<SoftDeleteDocumentTemplateMutation>,
    any
  >
) =>
  graphql.mutation<
    SoftDeleteDocumentTemplateMutation,
    SoftDeleteDocumentTemplateMutationVariables
  >("SoftDeleteDocumentTemplate", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockToggleDocumentTemplateConvertToPdfMutation((req, res, ctx) => {
 *   const { id, convert_to_pdf } = req.variables;
 *   return res(
 *     ctx.data({ update_DocumentTemplates_by_pk })
 *   )
 * })
 */
export const mockToggleDocumentTemplateConvertToPdfMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ToggleDocumentTemplateConvertToPdfMutationVariables>,
    GraphQLContext<ToggleDocumentTemplateConvertToPdfMutation>,
    any
  >
) =>
  graphql.mutation<
    ToggleDocumentTemplateConvertToPdfMutation,
    ToggleDocumentTemplateConvertToPdfMutationVariables
  >("ToggleDocumentTemplateConvertToPdf", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockToggleDocumentTemplateHiddenMutation((req, res, ctx) => {
 *   const { id, hidden } = req.variables;
 *   return res(
 *     ctx.data({ update_DocumentTemplates_by_pk })
 *   )
 * })
 */
export const mockToggleDocumentTemplateHiddenMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ToggleDocumentTemplateHiddenMutationVariables>,
    GraphQLContext<ToggleDocumentTemplateHiddenMutation>,
    any
  >
) =>
  graphql.mutation<
    ToggleDocumentTemplateHiddenMutation,
    ToggleDocumentTemplateHiddenMutationVariables
  >("ToggleDocumentTemplateHidden", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplateGeneratedNameQuery((req, res, ctx) => {
 *   const { template_name } = req.variables;
 *   return res(
 *     ctx.data({ DocumentTemplates })
 *   )
 * })
 */
export const mockGetTemplateGeneratedNameQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTemplateGeneratedNameQueryVariables>,
    GraphQLContext<GetTemplateGeneratedNameQuery>,
    any
  >
) =>
  graphql.query<
    GetTemplateGeneratedNameQuery,
    GetTemplateGeneratedNameQueryVariables
  >("GetTemplateGeneratedName", resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpsertUserMutation((req, res, ctx) => {
 *   const { object } = req.variables;
 *   return res(
 *     ctx.data({ insert_Users_one })
 *   )
 * })
 */
export const mockUpsertUserMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<Upsert_UserMutationVariables>,
    GraphQLContext<Upsert_UserMutation>,
    any
  >
) =>
  graphql.mutation<Upsert_UserMutation, Upsert_UserMutationVariables>(
    "upsert_user",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockYardiEgestDealMutation((req, res, ctx) => {
 *   const { dealArg } = req.variables;
 *   return res(
 *     ctx.data({ egestJob })
 *   )
 * })
 */
export const mockYardiEgestDealMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<YardiEgestDealMutationVariables>,
    GraphQLContext<YardiEgestDealMutation>,
    any
  >
) =>
  graphql.mutation<YardiEgestDealMutation, YardiEgestDealMutationVariables>(
    "YardiEgestDeal",
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockYardiDeleteQuestionnaireMutation((req, res, ctx) => {
 *   const { dealId } = req.variables;
 *   return res(
 *     ctx.data({ yardiDeleteQuestionnaire })
 *   )
 * })
 */
export const mockYardiDeleteQuestionnaireMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<YardiDeleteQuestionnaireMutationVariables>,
    GraphQLContext<YardiDeleteQuestionnaireMutation>,
    any
  >
) =>
  graphql.mutation<
    YardiDeleteQuestionnaireMutation,
    YardiDeleteQuestionnaireMutationVariables
  >("YardiDeleteQuestionnaire", resolver);

export const GetApproversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetApprovers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Approvers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CenterApprovers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Property" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PropertyCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PropertyName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "level" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApproversQuery, GetApproversQueryVariables>;
export const GetEsclatedApproversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getEsclatedApprovers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "EscalatedApprovers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "level" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "level" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEsclatedApproversQuery,
  GetEsclatedApproversQueryVariables
>;
export const CreateEsclatedApproversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateEsclatedApprovers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "EscalatedApprovers_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_EscalatedApprovers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEsclatedApproversMutation,
  CreateEsclatedApproversMutationVariables
>;
export const DeleteEsclatedApproversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteEsclatedApprovers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_EscalatedApprovers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: { kind: "ObjectValue", fields: [] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEsclatedApproversMutation,
  DeleteEsclatedApproversMutationVariables
>;
export const CreateApproversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateApprovers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Approvers_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_CenterApprovers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: { kind: "ObjectValue", fields: [] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_Approvers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: { kind: "ObjectValue", fields: [] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Approvers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateApproversMutation,
  CreateApproversMutationVariables
>;
export const ChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Chat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "messages" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ChatMessageInput" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "source" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "messages" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "messages" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "source" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "source" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "created" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "choices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "role" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "context" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "role" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "content",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatQuery, ChatQueryVariables>;
export const GetCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Companies_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "address_id" } },
                { kind: "Field", name: { kind: "Name", value: "acn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanies" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Companies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "not_deleted" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "acn" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "not_deleted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsCompanies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "first_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "middle_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "last_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const CreateCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "company" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Companies_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Companies_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "company" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: { kind: "EnumValue", value: "uniqueacn_const" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "trading_name" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const UpdateCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Companies_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Companies_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "acn" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const AddDealCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealsCompanies_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealsCompanies_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "company_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealCompanyMutation,
  AddDealCompanyMutationVariables
>;
export const SoftDeleteCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SoftDeleteCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Companies_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "not_deleted" },
                      value: { kind: "NullValue" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "not_deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SoftDeleteCompanyMutation,
  SoftDeleteCompanyMutationVariables
>;
export const GetContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetContacts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Contacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "first_name" } },
                { kind: "Field", name: { kind: "Name", value: "middle_name" } },
                { kind: "Field", name: { kind: "Name", value: "last_name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "address_id" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "Deal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "DealPhases" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "PhaseDealStatus",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "name",
                                              },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "_eq",
                                                    },
                                                    value: {
                                                      kind: "StringValue",
                                                      value: "Active",
                                                      block: false,
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contact_type" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactsQuery, GetContactsQueryVariables>;
export const CreateContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contact" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Contacts_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Contacts_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contact" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const GetContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Contacts_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contactId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "first_name" } },
                { kind: "Field", name: { kind: "Name", value: "middle_name" } },
                { kind: "Field", name: { kind: "Name", value: "last_name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "address_id" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "Deal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "DealPhases" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "PhaseDealStatus",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "name",
                                              },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "_eq",
                                                    },
                                                    value: {
                                                      kind: "StringValue",
                                                      value: "Active",
                                                      block: false,
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contact_type" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactQuery, GetContactQueryVariables>;
export const UpdateContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Contacts_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Contacts_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const GetDealContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_type" },
                },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealContactsQuery,
  GetDealContactsQueryVariables
>;
export const GetDealContactsTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealContactsTags" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealContactsTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealContactsTagsQuery,
  GetDealContactsTagsQueryVariables
>;
export const CreateDealContactsTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealContactsTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DealContactsTags_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealContactsTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "DealContactsTags_name_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: { kind: "ListValue", values: [] },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealContactsTagsMutation,
  CreateDealContactsTagsMutationVariables
>;
export const GetDealsByContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealsByContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contact_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contact_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "contact_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Deal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "User" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealPhases" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "where" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "PhaseDealStatus",
                                  },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "name" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "_eq",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Active",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lease_start" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "DealsUnits" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Unit" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "Property",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "label",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealsByContactQuery,
  GetDealsByContactQueryVariables
>;
export const GetContactsByPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetContactsByProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PropertyContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Property" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "PropertyCode" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legal_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trading_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acn" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsContacts" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "where" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "Deal" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "DealPhases",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "PhaseDealStatus",
                                              },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "name",
                                                    },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "_eq",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value: "Active",
                                                            block: false,
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deal_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contact_type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tag" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactsByPropertyQuery,
  GetContactsByPropertyQueryVariables
>;
export const CreatePropertyContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePropertyContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PropertyContacts_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_PropertyContacts_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePropertyContactMutation,
  CreatePropertyContactMutationVariables
>;
export const CreateLocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateLocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "location" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Locations_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Locations_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "location" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const UpdateLocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateLocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Locations_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Locations_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "street1" } },
                { kind: "Field", name: { kind: "Name", value: "street2" } },
                { kind: "Field", name: { kind: "Name", value: "suburb" } },
                { kind: "Field", name: { kind: "Name", value: "state_id" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const GetStatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetStates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "country_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          defaultValue: { kind: "IntValue", value: "10" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "States" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "country_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "country_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStatesQuery, GetStatesQueryVariables>;
export const GetPropertiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProperties" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Properties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deleted_at" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_is_null" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "street1" } },
                { kind: "Field", name: { kind: "Name", value: "street2" } },
                { kind: "Field", name: { kind: "Name", value: "suburb" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
                { kind: "Field", name: { kind: "Name", value: "hub" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Properties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "property_code" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_type" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "street1" } },
                { kind: "Field", name: { kind: "Name", value: "street2" } },
                { kind: "Field", name: { kind: "Name", value: "suburb" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
                { kind: "Field", name: { kind: "Name", value: "country" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "center_under_development" },
                },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                { kind: "Field", name: { kind: "Name", value: "hub" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyQuery, GetPropertyQueryVariables>;
export const GetUnitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUnits" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Units" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "UnitStatus" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_neq" },
                            value: {
                              kind: "StringValue",
                              value: "Inactive",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "UnitKey" } },
                { kind: "Field", name: { kind: "Name", value: "UnitCode" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimated_area" },
                },
                { kind: "Field", name: { kind: "Name", value: "survey_area" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                { kind: "Field", name: { kind: "Name", value: "UnitType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "area_survey_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unit_type_id" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUnitsQuery, GetUnitsQueryVariables>;
export const GetYardiUnitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiUnits" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiUnits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "UnitKey" } },
                { kind: "Field", name: { kind: "Name", value: "UnitCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "UnitType" } },
                { kind: "Field", name: { kind: "Name", value: "UnitArea" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiUnitProfiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "BUDGETEXPIRYDATE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "BUDGETFITOUTDAYS" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "BUDGETLEASESTART" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "BUDGETSTATUS" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "BUDGETVACATEDATE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DANNUALPROMOLEVY" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DANNUALRECOVERYPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DANNUALRENT" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "DBUDGETEDLANDLORDWORKCOST",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "DBUDGETLANDLORDCONTRIBTOFITOUT",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DBUDGETMAKEGOOD" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DTBUDGETLEASEDATE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DTPROFILEYEARSTART" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FBASERENT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FEXPIRYDATE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FFITOUTDAYS" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FINCENTIVE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLEASESTART" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLEASETYPE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLESSORWORKS" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FMAKEGOOD" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FOGPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FPROMO" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FRENTCOMMENCE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FSTATUS" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FVACATEDATE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "INHOUSENETRENT" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "PAREA" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PBASERENT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PCAPOCCCOST" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "POGPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "POWNERNAME" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PPROMO" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTENANT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTENANTNAME" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PVACATEDATE" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "SDEFAULTRECOVERYPROFILEDESC",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SECTION1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SECTION2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SECTION3" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SECTION4" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SECTION5" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SMGMTFEE" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "UnitKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "VALUATIONNETRENT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deleted_at" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetYardiUnitsQuery, GetYardiUnitsQueryVariables>;
export const GetYardiUnitByKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiUnitByKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unit_key" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "UnitKey" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "unit_key" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "UnitCode" } },
                { kind: "Field", name: { kind: "Name", value: "UnitKey" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute1" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute10" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute2" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute3" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute4" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute5" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute6" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute7" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute8" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute9" } },
                { kind: "Field", name: { kind: "Name", value: "Building" } },
                { kind: "Field", name: { kind: "Name", value: "FloorCode" } },
                { kind: "Field", name: { kind: "Name", value: "IsExclude" } },
                { kind: "Field", name: { kind: "Name", value: "LevelName" } },
                { kind: "Field", name: { kind: "Name", value: "LevelNumber" } },
                { kind: "Field", name: { kind: "Name", value: "Location" } },
                { kind: "Field", name: { kind: "Name", value: "MarketRent" } },
                { kind: "Field", name: { kind: "Name", value: "Notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                { kind: "Field", name: { kind: "Name", value: "UnitArea" } },
                { kind: "Field", name: { kind: "Name", value: "UnitStatus" } },
                { kind: "Field", name: { kind: "Name", value: "deleted_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiUnitByKeyQuery,
  GetYardiUnitByKeyQueryVariables
>;
export const GetTemplateSpecialConditionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTemplateSpecialConditions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplateSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priority" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priority" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_is_null" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "laf_description" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTemplateSpecialConditionsQuery,
  GetTemplateSpecialConditionsQueryVariables
>;
export const GetVirtualUnitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetVirtualUnits" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "VirtualUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deleted" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimated_area" },
                },
                { kind: "Field", name: { kind: "Name", value: "survey_area" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "area_survey_required" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVirtualUnitsQuery,
  GetVirtualUnitsQueryVariables
>;
export const GetUnitTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUnitTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Units" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "distinct_on" },
                value: { kind: "EnumValue", value: "UnitType" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "UnitType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUnitTypesQuery, GetUnitTypesQueryVariables>;
export const GetAllPhasesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllPhases" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Phases" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "is_tenancy_delivery" },
                },
                { kind: "Field", name: { kind: "Name", value: "sequence" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PhaseActionItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ActionItem" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "required" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllPhasesQuery, GetAllPhasesQueryVariables>;
export const GetUnitTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUnitType" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "UnitTypes_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "IntValue", value: "1" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUnitTypeQuery, GetUnitTypeQueryVariables>;
export const GetTenantContactTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTenantContactType" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ContactTypes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "StringValue",
                              value: "Tenant",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTenantContactTypeQuery,
  GetTenantContactTypeQueryVariables
>;
export const AddDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deal" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Deals_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Deals_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsCompanies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddDealMutation, AddDealMutationVariables>;
export const AddDealUnitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealUnits" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealUnit" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealsUnits_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealsUnits_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealUnit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealUnitsMutation,
  AddDealUnitsMutationVariables
>;
export const AddDealDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealData_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealData_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddDealDataMutation, AddDealDataMutationVariables>;
export const AddDealPhasesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealPhases" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DealPhases_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealPhases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealPhasesMutation,
  AddDealPhasesMutationVariables
>;
export const AddDealActionItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealActionItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DealActionItem_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealActionItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealActionItemsMutation,
  AddDealActionItemsMutationVariables
>;
export const AddCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "Company" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Companies_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Companies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Company" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCompanyMutation, AddCompanyMutationVariables>;
export const AddContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "Contacts" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Contacts_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Contacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Contacts" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddContactMutation, AddContactMutationVariables>;
export const AddDealsContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealsContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealContacts" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DealsContacts_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealContacts" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealsContactMutation,
  AddDealsContactMutationVariables
>;
export const GetExistingContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExistingContacts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Contacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "first_name" } },
                { kind: "Field", name: { kind: "Name", value: "last_name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetExistingContactsQuery,
  GetExistingContactsQueryVariables
>;
export const GetAllContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllContacts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Contacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "first_name" } },
                { kind: "Field", name: { kind: "Name", value: "last_name" } },
                { kind: "Field", name: { kind: "Name", value: "middle_name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "address_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllContactsQuery, GetAllContactsQueryVariables>;
export const GetAllContactsByCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllContactsByCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "company_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Contacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "company_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "company_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "first_name" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "first_name" } },
                { kind: "Field", name: { kind: "Name", value: "last_name" } },
                { kind: "Field", name: { kind: "Name", value: "middle_name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "address_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "legal_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legal_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "acn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllContactsByCompanyQuery,
  GetAllContactsByCompanyQueryVariables
>;
export const CreateBaseLandlordServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateBaseLandlordServices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BaseLandlordServices_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_BaseLandlordServices_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBaseLandlordServicesMutation,
  CreateBaseLandlordServicesMutationVariables
>;
export const DealCreationSpecialConditionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DealCreationSpecialConditions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_options" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "DealSpecialConditions_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_options" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DealCreationSpecialConditionsMutation,
  DealCreationSpecialConditionsMutationVariables
>;
export const UpdateCreateDealFitoutLandlordWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateCreateDealFitoutLandlordWorks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "update_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "DealFitoutLandlordWorks_insert_input",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealFitoutLandlordWorks_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "update_object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCreateDealFitoutLandlordWorksMutation,
  UpdateCreateDealFitoutLandlordWorksMutationVariables
>;
export const SubGetActivityLogDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubGetActivityLog" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ActivityLog" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "user_id" } },
                { kind: "Field", name: { kind: "Name", value: "old_value" } },
                { kind: "Field", name: { kind: "Name", value: "new_value" } },
                { kind: "Field", name: { kind: "Name", value: "event_id" } },
                { kind: "Field", name: { kind: "Name", value: "change_key" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "log_type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display_string" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubGetActivityLogSubscription,
  SubGetActivityLogSubscriptionVariables
>;
export const CancelDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CancelDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cancelDate" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "capitalIncurred" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cancelComment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dealId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "Cancelled" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fallover_date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cancelDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "capital_incurred" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "capitalIncurred" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cancel_comment" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cancelComment" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Comments_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cancelComment" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelDealMutation, CancelDealMutationVariables>;
export const CreateCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "text" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "user_tags_object" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "CommentUserTags_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ListValue", values: [] },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tags_object" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "CommentTags_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ListValue", values: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Comments_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "text" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "CommentUserTags" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "data" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "user_tags_object" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "CommentTags" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "data" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "tags_object" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CommentUserTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "User" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CommentTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Tag" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const CreateCommentBulkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCommentBulk" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Comments_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Comments_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCommentBulkMutation,
  CreateCommentBulkMutationVariables
>;
export const CreateCommentTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCommentTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tag_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_CommentTags_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "comment_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "comment_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tag_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tag_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Tag" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Comment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCommentTagMutation,
  CreateCommentTagMutationVariables
>;
export const CreateCommentTagBulkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCommentTagBulk" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "CommentTags_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_CommentTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCommentTagBulkMutation,
  CreateCommentTagBulkMutationVariables
>;
export const GetDealCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Comments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CommentTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Tag" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CommentUserTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "User" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "username" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealCommentsQuery,
  GetDealCommentsQueryVariables
>;
export const GetAllDealContactsListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllDealContactsList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "DealBusinessContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "middle_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllDealContactsListQuery,
  GetAllDealContactsListQueryVariables
>;
export const AddDealBusinessContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealBusinessContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealContacts" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "DealBusinessContacts_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealBusinessContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealContacts" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealBusinessContactMutation,
  AddDealBusinessContactMutationVariables
>;
export const UpdateDealBusinessContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealBusinessContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contact_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "_set" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealBusinessContacts_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealBusinessContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contact_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "contact_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "_set" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealBusinessContactMutation,
  UpdateDealBusinessContactMutationVariables
>;
export const DeleteDealBusinessContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealBusinessContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contact_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealBusinessContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contact_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "contact_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealBusinessContactMutation,
  DeleteDealBusinessContactMutationVariables
>;
export const CopyDealReLafDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CopyDealReLaf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "copyDealRelaf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dealId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "newDealId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyDealReLafMutation,
  CopyDealReLafMutationVariables
>;
export const CopyDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CopyDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unit" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Unit" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "copyDeal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dealId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "newDealId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyDealMutation, CopyDealMutationVariables>;
export const UpdateDealPhaseDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealPhase" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealPhases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealPhaseMutation,
  UpdateDealPhaseMutationVariables
>;
export const UpsertActionAllocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpsertActionAllocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ActionAllocations_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_ActionAllocations_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "actionallocations_deal_id_unique",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "description" },
                          { kind: "EnumValue", value: "user_id" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertActionAllocationMutation,
  UpsertActionAllocationMutationVariables
>;
export const GetActionAllocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetActionAllocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_action_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ActionAllocations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_action_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_action_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActionAllocationQuery,
  GetActionAllocationQueryVariables
>;
export const DeleteActionAllocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteActionAllocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_ActionAllocations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteActionAllocationMutation,
  DeleteActionAllocationMutationVariables
>;
export const UpdateDealPhaseStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealPhaseStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_phase_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealPhases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_phase_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealPhaseStatusMutation,
  UpdateDealPhaseStatusMutationVariables
>;
export const GetFirstTdPhaseDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFirstTDPhase" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealPhases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "is_tenancy_delivery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sequence" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PhaseDealStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFirstTdPhaseQuery,
  GetFirstTdPhaseQueryVariables
>;
export const UpdateDealStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealStatus_enum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Deals_bool_exp" },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_and" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "id" },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "_eq" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "dealId" },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: "Variable",
                            name: { kind: "Name", value: "filter" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealStatusMutation,
  UpdateDealStatusMutationVariables
>;
export const GetDealPhaseStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealPhaseStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealPhases_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "deal_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PhaseDealStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealPhaseStatusQuery,
  GetDealPhaseStatusQueryVariables
>;
export const DeleteDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dealId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "Deleted" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDealMutation, DeleteDealMutationVariables>;
export const GenerateDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GenerateDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "template_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DocumentRequests_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "template_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "template_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "deal_id" } },
                { kind: "Field", name: { kind: "Name", value: "template_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateDocumentMutation,
  GenerateDocumentMutationVariables
>;
export const SubGetDealDocumentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubGetDealDocuments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "GeneratedDocuments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "generated_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "document_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "full_path" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "generated_at" },
                },
                { kind: "Field", name: { kind: "Name", value: "has_error" } },
                { kind: "Field", name: { kind: "Name", value: "errors_json" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubGetDealDocumentsSubscription,
  SubGetDealDocumentsSubscriptionVariables
>;
export const GetDealFilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealFiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "modified_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "file_name" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_path" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealFileTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Tags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealFilesQuery, GetDealFilesQueryVariables>;
export const CreateDealFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "file_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealFiles_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealFiles_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "file_object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealFileMutation,
  CreateDealFileMutationVariables
>;
export const CreateDealFileTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealFileTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tag_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DealFileTags_insert_input" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealFileTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tag_object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealFileTagsMutation,
  CreateDealFileTagsMutationVariables
>;
export const UpdateDealFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "file_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "file_name" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tag_object" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DealFileTags_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ListValue", values: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealFiles_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "file_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "file_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "file_name" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealFileTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tag_object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "DealFileTags_file_id_tag_id_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "file_id" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealFileMutation,
  UpdateDealFileMutationVariables
>;
export const DeleteOldFileTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteOldFileTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "file_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "new_n_updated_tag_ids" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "bigint" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealFileTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "file_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "file_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_nin" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "new_n_updated_tag_ids",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOldFileTagsMutation,
  DeleteOldFileTagsMutationVariables
>;
export const SoftDeleteFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SoftDeleteFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealFiles_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "not_deleted" },
                      value: { kind: "NullValue" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "not_deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SoftDeleteFileMutation,
  SoftDeleteFileMutationVariables
>;
export const GetDealLinksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealLinks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealLinks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealByDealLinkId" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "laf_version" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealByDealLinkId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "laf_version" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealLinksQuery, GetDealLinksQueryVariables>;
export const GetTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTags" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagsQuery, GetTagsQueryVariables>;
export const CreateTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Tags_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Tags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: { kind: "EnumValue", value: "tags_name_unique" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "name" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTagsMutation, CreateTagsMutationVariables>;
export const GetTradingNamesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTradingNames" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "trading_name" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_is_null" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "trading_name" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTradingNamesQuery,
  GetTradingNamesQueryVariables
>;
export const GetTermsDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTermsData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "DealID" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Deal" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "DealID" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "BaseLandlordServices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "air_conditioning" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "as_per_existing_toggle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cool_room" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electrical_dist_load_1_phase",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electrical_dist_load_3_phase",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "floor_covering" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grease_trap" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hot_water_services" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lighting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mechanical_exhaust" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modified_at" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "other" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "other_description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "painted_walls" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "separate_utility_meter_gas",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "separate_utility_meter_water",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shop_front" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "sink" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sprinklers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suspended_ceilings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "telephone" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "waste" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "water_supply" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Deal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant_code" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealType" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YardiTenants_Deals" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "order_by" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "created_at" },
                                  value: { kind: "EnumValue", value: "desc" },
                                },
                              ],
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "TenantCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsCompanies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Location" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "State",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "Country",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Contacts" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "content",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "first_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "middle_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "last_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "legal_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phone",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trading_name",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "legal_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "acn" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsContacts" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Contact" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Company" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "Contacts",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "Location",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "created_at",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modified_at",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "State",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "Country",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "street1",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "street2",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "suburb",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "postcode",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "alternate_email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "company_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "created_at",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modified_at",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "content",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "first_name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "middle_name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "last_name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "legal_name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "phone",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "trading_name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address_id",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "created_at",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modified_at",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "legal_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trading_name",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "acn" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phone",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Location" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "created_at",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modified_at",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "State",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "Country",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "address_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "alternate_email",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "company_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created_at" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modified_at",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "content" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "first_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "middle_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "last_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "legal_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contact_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contact_type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "created_at" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deal_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modified_at" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tag" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TD_Assignee" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "LA_Assignee" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user_full_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantType" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_by" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_type_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deleted_at" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner_description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant_type_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trading_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealDataCustomLicences" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "CustomLicences" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modified_at" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licence_description",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licence_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licence_rent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "created_at" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modified_at" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealDataGuarantors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "State" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "full_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modified_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealExtensionItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "item_data" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealFitoutLandlordWork" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "capped_category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cost_landlord_works" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cost_makegood" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "date_complete_fitout" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_previous_tenant" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hoarding" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "landlord_undertake_works",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "landlord_works_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "partial_fitout_items" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "preview" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "refurbished_fitout_works",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "skins" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "tenancy_delivery_deal_type",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant_works" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant_works_options" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealOptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_year_amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "first_year_type" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modified_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subsequent_year_amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subsequent_year_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "term_length" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "works_required" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealPermittedUses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TemplatePermittedUse" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "food_menu" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sublet" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "template_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealSpecialConditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "TemplateSpecialCondition",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "laf_description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "template_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsUnits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Unit" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "area_survey_required",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "estimated_area" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "survey_area" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Property" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "property_code",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "center_under_development",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "notes" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suburb" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "country" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "UnitType" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit_key" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "analyst_deal_comp" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "analyst_deal_reason" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "analyst_normalised_base_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "analyst_normalised_promo" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_increase" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_increase_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bank_guarantee_amount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bank_guarantee_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bank_guarantee_months" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "base_net_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cash_incentive" },
                },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "deal_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "development_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fitout_contribution" },
                },
                { kind: "Field", name: { kind: "Name", value: "fitout_term" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fixed_handover_date" },
                },
                { kind: "Field", name: { kind: "Name", value: "gross_rent" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guarantors_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "handover_date" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hoardingTriSelectEnum" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hoarding_amt" },
                },
                { kind: "Field", name: { kind: "Name", value: "hoarding_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_modified_by" },
                },
                { kind: "Field", name: { kind: "Name", value: "lease_start" } },
                { kind: "Field", name: { kind: "Name", value: "lease_term" } },
                { kind: "Field", name: { kind: "Name", value: "mat_sales" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rent_free_period" },
                },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notice_address_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notice_email" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "number_of_carparks" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "number_of_signs" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opening_promotion_levy_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "outgoings_psm" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "outgoings_state" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parking_licence_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parking_location" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parking_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promotion_amt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promotion_levy_state" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promotion_levy_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recovery_survey_fee" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rent_deposit" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rent_deposit_months" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rubbish_removal_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seating_area" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seating_licence_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seating_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "security_amt" },
                },
                { kind: "Field", name: { kind: "Name", value: "security_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signage_licence_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signage_location" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signage_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "storage_area" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "storage_licence_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "storage_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy_coordination" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy_coordination_fee" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "tenant_contribution_landlord_works",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "triSelectEnumByBankGuaranteeId",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "triSelectEnumByGuarantorsId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "triSelectEnumBySecurityId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "turn_over_rent_unnatural" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "turnover_based_rent_percent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "turnover_rent_state" },
                },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealDataCalculations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promo_calc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_calc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annual_promo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_deposit_monthly" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "NoticeServingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suburb" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTermsDataQuery, GetTermsDataQueryVariables>;
export const GetUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUsers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user_full_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "UserStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateTDassigneeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateTDassignee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "user_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "td_assignee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "user_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "td_assignee_description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "td_assignee" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "td_assignee_description" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTDassigneeMutation,
  UpdateTDassigneeMutationVariables
>;
export const UpdateLAassigneeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateLAassignee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "user_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "la_assignee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "user_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "la_assignee_description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "la_assignee" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "la_assignee_description" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLAassigneeMutation,
  UpdateLAassigneeMutationVariables
>;
export const UpdateOwnerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOwner" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "user_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "owner" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "user_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "owner_description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "owner" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "owner_description" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOwnerMutation, UpdateOwnerMutationVariables>;
export const GetYardiContactsByTenantDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiContactsByTenant" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenant_code" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiTenant_YardiContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "TenantCode" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "tenant_code" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "YardiContact" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "IsInternal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: { kind: "BooleanValue", value: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiContact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address3" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address4" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AlternateEmail" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "City" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "CompanyName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Country" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ExtRefId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FirstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "IsPrimary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "LastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Notes" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber10" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber2" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber3" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber4" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber5" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber6" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber7" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber8" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhoneNumber9" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "RecordType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Salutation" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "State" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                      { kind: "Field", name: { kind: "Name", value: "URL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ZipCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deleted_at" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiTenant" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantField1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantField2" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiContactsByTenantQuery,
  GetYardiContactsByTenantQueryVariables
>;
export const GetYardiLimitedCustomersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiLimitedCustomers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiLimitedCustomers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ParentCustomerCode" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiLimitedCustomersQuery,
  GetYardiLimitedCustomersQueryVariables
>;
export const GetChargesInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetChargesInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
          defaultValue: { kind: "IntValue", value: "10" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy_coordination_fee" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "tenant_contribution_landlord_works",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recovery_survey_fee" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opening_promotion_levy_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "development_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rubbish_removal_fees" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealExtensionItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "item_data" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChargesInformationQuery,
  GetChargesInformationQueryVariables
>;
export const UpdateChargesInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateChargesInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
          defaultValue: { kind: "IntValue", value: "10" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealData_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChargesInformationMutation,
  UpdateChargesInformationMutationVariables
>;
export const CreateDealExtensionItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealExtensionItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "DealExtensionItems_insert_input",
                },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealExtensionItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealExtensionItemsMutation,
  CreateDealExtensionItemsMutationVariables
>;
export const DeleteDealCustomChargesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealCustomCharges" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_data_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
          defaultValue: { kind: "IntValue", value: "10" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealExtensionItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_data_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_data_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tag" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "StringValue",
                              value: "charge",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealCustomChargesMutation,
  DeleteDealCustomChargesMutationVariables
>;
export const GetDealInfoCommercialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInfoCommercial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "turnover_based_rent_percent",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "turn_over_rent_unnatural",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "turnover_rent_state" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_psm" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_state" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_amt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_levy_state" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_levy_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "base_net_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gross_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annual_increase" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annual_increase_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_contribution" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cash_incentive" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "capped_occupancy_costs_state",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInfoCommercialQuery,
  GetDealInfoCommercialQueryVariables
>;
export const GetDealInfoDateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInfoDate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "handover_date" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_start" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fixed_handover_date" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_starts" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_free_period" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInfoDateQuery,
  GetDealInfoDateQueryVariables
>;
export const UpdateDealInfoDateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealInfoDate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "update_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealData_set_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Deal" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "update_object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealInfoDateMutation,
  UpdateDealInfoDateMutationVariables
>;
export const UpdateDealDataInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealDataInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "update_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealData_set_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Deal" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "update_object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealDataInfoMutation,
  UpdateDealDataInfoMutationVariables
>;
export const GetDealDataIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealDataId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealDataIdQuery, GetDealDataIdQueryVariables>;
export const SubDealLafApprovalsStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubDealLafApprovalsStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealLAFApprovals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealData" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deal_data_id" },
                },
                { kind: "Field", name: { kind: "Name", value: "laf_action" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubDealLafApprovalsStatusSubscription,
  SubDealLafApprovalsStatusSubscriptionVariables
>;
export const SubLeaseExecAllowedToEditDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubLeaseExecAllowedToEditDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "started_phases" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealPhases_aggregate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_in" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "started_phases" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_nin" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "StringValue",
                                  value: "Terms",
                                  block: false,
                                },
                                {
                                  kind: "StringValue",
                                  value: "Proposal",
                                  block: false,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubLeaseExecAllowedToEditDealSubscription,
  SubLeaseExecAllowedToEditDealSubscriptionVariables
>;
export const AddDealShopActivityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDealShopActivity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealShopActivities_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealShopActivities_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDealShopActivityMutation,
  AddDealShopActivityMutationVariables
>;
export const GetOpenDateFromShopHandoverActivitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOpenDateFromShopHandoverActivities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealShopActivities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "activity" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "EnumValue", value: "ShopHandover" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "activity" } },
                { kind: "Field", name: { kind: "Name", value: "open_date" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOpenDateFromShopHandoverActivitiesQuery,
  GetOpenDateFromShopHandoverActivitiesQueryVariables
>;
export const GetDealTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "parent_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealTypesQuery, GetDealTypesQueryVariables>;
export const GetDealAnalystDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealAnalystData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parent_id" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "analyst_deal_comp" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "analyst_normalised_base_rent",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "analyst_normalised_promo",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "analyst_deal_reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealAnalystDataQuery,
  GetDealAnalystDataQueryVariables
>;
export const UpdateDealAnalystDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealAnalystData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "analyst_deal_comp" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "numeric" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "analyst_normalised_base_rent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "numeric" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "analyst_normalised_promo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "numeric" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "analyst_deal_reason" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Deal" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "analyst_deal_comp" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "analyst_deal_comp" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "analyst_normalised_base_rent",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "analyst_normalised_base_rent",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "analyst_normalised_promo" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "analyst_normalised_promo",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "analyst_deal_reason" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "analyst_deal_reason" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealAnalystDataMutation,
  UpdateDealAnalystDataMutationVariables
>;
export const UpdateDealTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_type_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_type_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_type_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deal_type_id" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealTypeMutation,
  UpdateDealTypeMutationVariables
>;
export const GetDepositInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDepositInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "guarantors_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_deposit_months" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_months" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_amount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "achieved_bank_guarantee",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "achieved_rent_deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealDataGuarantors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "full_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepositInformationQuery,
  GetDepositInformationQueryVariables
>;
export const DeleteDealDataGuarantorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealDataGuarantors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealDataGuarantors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealData" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealDataGuarantorsMutation,
  DeleteDealDataGuarantorsMutationVariables
>;
export const CreateDealDataGuarantorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealDataGuarantors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "DealDataGuarantors_insert_input",
                },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealDataGuarantors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealDataGuarantorsMutation,
  CreateDealDataGuarantorsMutationVariables
>;
export const UpdateDepositInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDepositInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealData_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDepositInformationMutation,
  UpdateDepositInformationMutationVariables
>;
export const GetDealFitoutLandlordWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealFitoutLandlordWorks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealFitoutLandlordWork" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "preview" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "refurbished_fitout_works",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "tenancy_delivery_deal_type",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tenant_works" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "tenant_works_options",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "capped_category" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "cost_landlord_works",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cost_makegood" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "date_complete_fitout",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "fitout_previous_tenant",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "landlord_undertake_works",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "partial_fitout_items",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hoarding" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "skins" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "fitout_description",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "landlord_works_type",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealFitoutLandlordWorksQuery,
  GetDealFitoutLandlordWorksQueryVariables
>;
export const UpdateDealFitoutLandlordWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateDealFitoutLandlordWorks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "update_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "DealFitoutLandlordWorks_insert_input",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealFitoutLandlordWorks_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "update_object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "dealfitoutlandlordworks_deal_id_unique",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "preview" },
                          {
                            kind: "EnumValue",
                            value: "refurbished_fitout_works",
                          },
                          {
                            kind: "EnumValue",
                            value: "tenancy_delivery_deal_type",
                          },
                          { kind: "EnumValue", value: "tenant_works" },
                          { kind: "EnumValue", value: "tenant_works_options" },
                          { kind: "EnumValue", value: "capped_category" },
                          { kind: "EnumValue", value: "cost_landlord_works" },
                          { kind: "EnumValue", value: "cost_makegood" },
                          { kind: "EnumValue", value: "date_complete_fitout" },
                          {
                            kind: "EnumValue",
                            value: "fitout_previous_tenant",
                          },
                          {
                            kind: "EnumValue",
                            value: "landlord_undertake_works",
                          },
                          { kind: "EnumValue", value: "partial_fitout_items" },
                          { kind: "EnumValue", value: "hoarding" },
                          { kind: "EnumValue", value: "skins" },
                          { kind: "EnumValue", value: "fitout_description" },
                          { kind: "EnumValue", value: "landlord_works_type" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealFitoutLandlordWorksMutation,
  UpdateDealFitoutLandlordWorksMutationVariables
>;
export const GetHistoricalDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetHistoricalData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter_tenant_dates" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "historical_base_rent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "historical_outgoings" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "historical_promo_levy" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "historical_tenant_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsUnits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YardiUnit" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "YardiTenantToUnits",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "order_by" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "OccupancyEndDate",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "desc_nulls_first",
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "where" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_or" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            {
                                              kind: "ObjectValue",
                                              fields: [
                                                {
                                                  kind: "ObjectField",
                                                  name: {
                                                    kind: "Name",
                                                    value: "OccupancyEndDate",
                                                  },
                                                  value: {
                                                    kind: "ObjectValue",
                                                    fields: [
                                                      {
                                                        kind: "ObjectField",
                                                        name: {
                                                          kind: "Name",
                                                          value: "_is_null",
                                                        },
                                                        value: {
                                                          kind: "BooleanValue",
                                                          value: true,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              kind: "ObjectValue",
                                              fields: [
                                                {
                                                  kind: "ObjectField",
                                                  name: {
                                                    kind: "Name",
                                                    value: "OccupancyEndDate",
                                                  },
                                                  value: {
                                                    kind: "ObjectValue",
                                                    fields: [
                                                      {
                                                        kind: "ObjectField",
                                                        name: {
                                                          kind: "Name",
                                                          value: "_gt",
                                                        },
                                                        value: {
                                                          kind: "Variable",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "filter_tenant_dates",
                                                          },
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "YardiTenant",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "TenantCode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "TenantName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BaseRent",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PromoLevy",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "Outgoings",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHistoricalDataQuery,
  GetHistoricalDataQueryVariables
>;
export const UpdateHistoricalDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateHistoricalData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_data_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "historical_base_rent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "numeric" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "historical_outgoings" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "numeric" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "historical_promo_levy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "numeric" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "historical_tenant_name" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_data_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "historical_base_rent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "historical_base_rent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "historical_outgoings" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "historical_outgoings" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "historical_promo_levy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "historical_promo_levy" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "historical_tenant_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "historical_tenant_name" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHistoricalDataMutation,
  UpdateHistoricalDataMutationVariables
>;
export const GetLandlordInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLandlordInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "BaseLandlordServices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "air_conditioning" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "as_per_existing_toggle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cool_room" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electrical_dist_load_1_phase",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electrical_dist_load_3_phase",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "floor_covering" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grease_trap" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hot_water_services" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lighting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mechanical_exhaust" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "other" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "other_description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "painted_walls" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "separate_utility_meter_gas",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "separate_utility_meter_water",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "separate_utility_meter_electricity",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shop_front" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "sink" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sprinklers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suspended_ceilings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "telephone" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "waste" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "water_supply" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "plastered_walls_unpainted",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLandlordInformationQuery,
  GetLandlordInformationQueryVariables
>;
export const UpdateBaseLandlordServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateBaseLandlordServices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BaseLandlordServices_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_BaseLandlordServices" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealData" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBaseLandlordServicesMutation,
  UpdateBaseLandlordServicesMutationVariables
>;
export const GetDealInfoDealOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInfoDealOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealOptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "term_length" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "first_year_amount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "first_year_type" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "subsequent_year_amount",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "subsequent_year_type",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "works_required" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInfoDealOptionsQuery,
  GetDealInfoDealOptionsQueryVariables
>;
export const DeleteDealOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealDatum" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealOptionsMutation,
  DeleteDealOptionsMutationVariables
>;
export const CreateDealOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DealOptions_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealOptionsMutation,
  CreateDealOptionsMutationVariables
>;
export const GetDealSpecialConditionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealSpecialConditions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealSpecialConditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "laf_description" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "TemplateSpecialCondition",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplateSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "laf_description" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealSpecialConditionsQuery,
  GetDealSpecialConditionsQueryVariables
>;
export const DeleteAndInsertDealSpecialConditionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteAndInsertDealSpecialConditions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_options" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "DealSpecialConditions_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealDatum" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_options" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAndInsertDealSpecialConditionsMutation,
  DeleteAndInsertDealSpecialConditionsMutationVariables
>;
export const GetStorageInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetStorageInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "storage_licence_required",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "signage_licence_required",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "seating_licence_required",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "parking_licence_required",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "storage_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signage_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seating_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parking_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "storage_area" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seating_area" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signage_location" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parking_location" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number_of_signs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number_of_carparks" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealDataCustomLicences" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "CustomLicences" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "licence_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "licence_rent",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "licence_description",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStorageInformationQuery,
  GetStorageInformationQueryVariables
>;
export const UpdateStorageInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateStorageInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
          defaultValue: { kind: "IntValue", value: "10" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealData_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStorageInformationMutation,
  UpdateStorageInformationMutationVariables
>;
export const CreateCustomLicencesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCustomLicences" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "CustomLicences_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_CustomLicences" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomLicencesMutation,
  CreateCustomLicencesMutationVariables
>;
export const DeleteDealDataCustomLicencesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealDataCustomLicences" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealDataCustomLicences" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealData" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deal_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "deal_id" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealDataCustomLicencesMutation,
  DeleteDealDataCustomLicencesMutationVariables
>;
export const CreateDealDataCustomLicencesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDealDataCustomLicences" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "DealDataCustomLicences_insert_input",
                },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealDataCustomLicences" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDealDataCustomLicencesMutation,
  CreateDealDataCustomLicencesMutationVariables
>;
export const GetDealInformationTenantInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInformationTenantInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenant_type_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer_code" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiCustomer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "CustomerName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "CustomerCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "notice_email" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sales_category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "charge_code" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sales_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "NoticeServingAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suburb" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postcode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "State" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "contact_type" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contact_type" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "first_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "middle_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "last_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trading_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legal_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Location" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suburb" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postcode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "State" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "legal_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "acn" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Location" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "State",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsCompanies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "first_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "middle_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "last_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Location" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suburb" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postcode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "State" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trading_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legal_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acn" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "not_deleted" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Contacts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "first_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "middle_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "last_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "legal_name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Location" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "State",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Location" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "street2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suburb" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postcode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "State" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInformationTenantInformationQuery,
  GetDealInformationTenantInformationQueryVariables
>;
export const UpdateDealTenantTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealTenantType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenant_type" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tenant_type_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenant_type" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenant_type_id" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealTenantTypeMutation,
  UpdateDealTenantTypeMutationVariables
>;
export const UpdateDealsContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealsContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsContacts" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DealsContacts_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealsContacts" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealsContactsMutation,
  UpdateDealsContactsMutationVariables
>;
export const DeleteDealsContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealsContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealsContactsMutation,
  DeleteDealsContactsMutationVariables
>;
export const DeleteDealsTenantContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealsTenantContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealsContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contact_type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealsTenantContactsMutation,
  DeleteDealsTenantContactsMutationVariables
>;
export const DeleteDealsCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDealsCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealsCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDealsCompaniesMutation,
  DeleteDealsCompaniesMutationVariables
>;
export const UpdateDealsCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealsCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsCompanies" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DealsCompanies_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_DealsCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealsCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealsCompanies" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealsCompaniesMutation,
  UpdateDealsCompaniesMutationVariables
>;
export const UpdateDealDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_data" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DealData_set_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealDataMutation,
  UpdateDealDataMutationVariables
>;
export const UpdateDealTradingNameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealTradingName" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trading_name" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customer_code" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "trading_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "trading_name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "customer_code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "customer_code" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealTradingNameMutation,
  UpdateDealTradingNameMutationVariables
>;
export const GetYardiCustomersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiCustomers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiCustomers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ParentCustomerCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "deleted_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiCustomersQuery,
  GetYardiCustomersQueryVariables
>;
export const UpdateDealCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customer_code" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "customer_code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "customer_code" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealCustomerMutation,
  UpdateDealCustomerMutationVariables
>;
export const GetLeaseTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLeaseTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiTenants" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "distinct_on" },
                value: {
                  kind: "ListValue",
                  values: [{ kind: "EnumValue", value: "LeaseType" }],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "LeaseType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLeaseTypesQuery, GetLeaseTypesQueryVariables>;
export const GetDealInfoUnitInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInfoUnitInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "area_survey_required",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "area_survey_required",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "unit_type_id",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "center_under_development",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "area_survey_required",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "center_under_development",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInfoUnitInfoQuery,
  GetDealInfoUnitInfoQueryVariables
>;
export const CheckDealExistsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CheckDealExists" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckDealExistsQuery,
  CheckDealExistsQueryVariables
>;
export const UpdateDealUnitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unit_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "virtual_unit_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "bigint" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "area_survey_required" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealsUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "DealDatum" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "Deal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "id" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "deal_id",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "unit_key" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "unit_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "virtual_unit_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "virtual_unit_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "area_survey_required" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "area_survey_required" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit_key" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealDatum" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Deal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealUnitMutation,
  UpdateDealUnitMutationVariables
>;
export const AddVirtualUnitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddVirtualUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unit" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "VirtualUnits_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_VirtualUnits_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "unit_type" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "area_survey_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimated_area" },
                },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "survey_area" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddVirtualUnitMutation,
  AddVirtualUnitMutationVariables
>;
export const GetDealInfoUsageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealInfoUsage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealPermittedUses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "food_menu" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sublet" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "TemplatePermittedUse",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealInfoUsageQuery,
  GetDealInfoUsageQueryVariables
>;
export const GetUsagePermittedUsesTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUsagePermittedUsesTemplate" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplatePermittedUses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ModifiedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsagePermittedUsesTemplateQuery,
  GetUsagePermittedUsesTemplateQueryVariables
>;
export const GetPermittedUseTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPermittedUseTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplatePermittedUses_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ModifiedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPermittedUseTemplateQuery,
  GetPermittedUseTemplateQueryVariables
>;
export const CreatePermittedUseTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePermittedUseTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "TemplatePermittedUses_insert_input",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_TemplatePermittedUses_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePermittedUseTemplateMutation,
  CreatePermittedUseTemplateMutationVariables
>;
export const UpdatePermittedUseTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePermittedUseTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TemplatePermittedUses_set_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_TemplatePermittedUses_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePermittedUseTemplateMutation,
  UpdatePermittedUseTemplateMutationVariables
>;
export const UpdateDealPermittedUsesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateDealPermittedUses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "update_object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealPermittedUses_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealPermittedUses_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "update_object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "DealPermittedUses_deal_data_id_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "description" },
                          { kind: "EnumValue", value: "template_id" },
                          { kind: "EnumValue", value: "description" },
                          { kind: "EnumValue", value: "food_menu" },
                          { kind: "EnumValue", value: "sublet" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealPermittedUsesMutation,
  UpdateDealPermittedUsesMutationVariables
>;
export const DeletePermittedUseTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeletePermittedUseTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_TemplatePermittedUses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePermittedUseTemplateMutation,
  DeletePermittedUseTemplateMutationVariables
>;
export const GetDealsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDeals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Deals_bool_exp" },
          },
          defaultValue: {
            kind: "ObjectValue",
            fields: [
              {
                kind: "ObjectField",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_nin" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "Superseded" },
                          { kind: "EnumValue", value: "Deleted" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "tenant_code" } },
                { kind: "Field", name: { kind: "Name", value: "laf_version" } },
                { kind: "Field", name: { kind: "Name", value: "risk_level" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiTenants_Deals" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YardiActiveLeases" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "EndDate" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Comments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "LA_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TD_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealPhases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "is_tenancy_delivery" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealActionItem" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "required" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_start" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "handover_date" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealLAFApprovals" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ActiveApprover" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "user_full_name",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "laf_action" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealsQuery, GetDealsQueryVariables>;
export const GetDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "laf_version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "tenant_code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deal_made_from_active_lease" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "LA_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TD_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiTenants_Deals" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contact_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealPhases" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: {
                                  kind: "Name",
                                  value: "is_tenancy_delivery",
                                },
                                value: { kind: "EnumValue", value: "asc" },
                              },
                            ],
                          },
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "sequence" },
                                value: { kind: "EnumValue", value: "asc" },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealActionItem" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "order_by" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "id" },
                                  value: { kind: "EnumValue", value: "asc" },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "required" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "ActionAllocations",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "User" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "user_full_name",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhaseDealStatus" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "is_tenancy_delivery" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sequence" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_start" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_free_period" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "country",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "property_code",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "street2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postcode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "suburb",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "country",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDealQuery, GetDealQueryVariables>;
export const GetDealRiskLevelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealRiskLevel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "risk_level" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealRiskLevelQuery,
  GetDealRiskLevelQueryVariables
>;
export const UpdateDealActionItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealActionItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealActionItem_set_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DealActionItem_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealActionItemMutation,
  UpdateDealActionItemMutationVariables
>;
export const UpdateDealRiskLevelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDealRiskLevel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "risk_level" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealRiskLevels_enum" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "risk_level" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "risk_level" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "risk_set_manually" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDealRiskLevelMutation,
  UpdateDealRiskLevelMutationVariables
>;
export const InsertDealActionItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "InsertDealActionItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DealActionItem_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealActionItem_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertDealActionItemMutation,
  InsertDealActionItemMutationVariables
>;
export const GetDealsByTenantCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealsByTenantCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenant_code" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_or" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "tenant_code" },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "_eq" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "tenant_code",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: {
                                  kind: "Name",
                                  value: "YardiTenants_Deals",
                                },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: {
                                        kind: "Name",
                                        value: "TenantCode",
                                      },
                                      value: {
                                        kind: "ObjectValue",
                                        fields: [
                                          {
                                            kind: "ObjectField",
                                            name: {
                                              kind: "Name",
                                              value: "_eq",
                                            },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "tenant_code",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "tenant_code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiTenants_Deals" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "TenantCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealsByTenantCodeQuery,
  GetDealsByTenantCodeQueryVariables
>;
export const GetLeasesForRenewalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLeasesForRenewal" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiLeasesForRenewal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "Anchor" } },
                { kind: "Field", name: { kind: "Name", value: "BrandCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CustomerCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "EndDate" } },
                { kind: "Field", name: { kind: "Name", value: "IsHoldover" } },
                { kind: "Field", name: { kind: "Name", value: "LeaseType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "MaintenanceNotes" },
                },
                { kind: "Field", name: { kind: "Name", value: "Notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "SalesCategory" },
                },
                { kind: "Field", name: { kind: "Name", value: "TIC" } },
                { kind: "Field", name: { kind: "Name", value: "TenantCode" } },
                { kind: "Field", name: { kind: "Name", value: "TenantName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TenantStartDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TenantStatus" },
                },
                { kind: "Field", name: { kind: "Name", value: "TradeName" } },
                { kind: "Field", name: { kind: "Name", value: "BaseRent" } },
                { kind: "Field", name: { kind: "Name", value: "PromoLevy" } },
                { kind: "Field", name: { kind: "Name", value: "Outgoings" } },
                { kind: "Field", name: { kind: "Name", value: "unit1" } },
                { kind: "Field", name: { kind: "Name", value: "unit2" } },
                { kind: "Field", name: { kind: "Name", value: "unit3" } },
                { kind: "Field", name: { kind: "Name", value: "unit4" } },
                { kind: "Field", name: { kind: "Name", value: "unit5" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLeasesForRenewalQuery,
  GetLeasesForRenewalQueryVariables
>;
export const GetYardiOutgoingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiOutgoings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unit_key" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "UnitKey" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "unit_key" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "UnitKey" } },
                { kind: "Field", name: { kind: "Name", value: "UnitCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "UnitType" } },
                { kind: "Field", name: { kind: "Name", value: "UnitArea" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiUnitProfiles" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "DTPROFILEYEARSTART" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "POGPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FOGPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DANNUALRECOVERYPSM" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PBASERENT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FBASERENT" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PPROMO" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FPROMO" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiOutgoingsQuery,
  GetYardiOutgoingsQueryVariables
>;
export const SubGetDealStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubGetDealStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubGetDealStatusSubscription,
  SubGetDealStatusSubscriptionVariables
>;
export const GetLafDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TenantType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trading_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "LA_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "TD_Assignee" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsCompanies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legal_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "acn" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealsContacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contact_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Contact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "first_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legal_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "middle_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "last_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "trading_name",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "User" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealPhases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "is_tenancy_delivery" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealActionItem" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "ActionAllocations",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "User" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "user_full_name",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PhaseDealStatus" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_start" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Property" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "label",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLafDataQuery, GetLafDataQueryVariables>;
export const GetLafDealDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafDealData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitCode" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLafDealDataQuery, GetLafDealDataQueryVariables>;
export const GetLafLeaseDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafLeaseDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "capped_occupancy_costs_state",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_psm" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "base_net_rent" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "turn_over_rent_unnatural",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "historical_base_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "historical_outgoings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "historical_promo_levy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitKey" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "SavedYardiUnitProfile",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETEXPIRYDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETFITOUTDAYS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETLEASESTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETSTATUS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALPROMOLEVY",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALRECOVERYPSM",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALRENT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETEDLANDLORDWORKCOST",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETLANDLORDCONTRIBTOFITOUT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETMAKEGOOD",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DTBUDGETLEASEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DTPROFILEYEARSTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FBASERENT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FEXPIRYDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FFITOUTDAYS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FINCENTIVE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FLEASESTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FLEASETYPE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FLESSORWORKS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FMAKEGOOD" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FOGPSM" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FPROMO" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FRENTCOMMENCE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FSTATUS" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "INHOUSENETRENT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PAREA" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PBASERENT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PCAPOCCCOST",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "POGPSM" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "POWNERNAME" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PPROMO" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PTENANT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PTENANTNAME",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "SDEFAULTRECOVERYPROFILEDESC",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION3" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION4" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION5" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SMGMTFEE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "VALUATIONNETRENT",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "YardiUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "YardiUnitProfiles",
                                    },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "limit" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "order_by",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "DTPROFILEYEARSTART",
                                              },
                                              value: {
                                                kind: "EnumValue",
                                                value: "desc",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "where" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "deleted_at",
                                              },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "_is_null",
                                                    },
                                                    value: {
                                                      kind: "BooleanValue",
                                                      value: true,
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETEXPIRYDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETFITOUTDAYS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETLEASESTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETSTATUS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALPROMOLEVY",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALRECOVERYPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALRENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DBUDGETEDLANDLORDWORKCOST",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "DBUDGETLANDLORDCONTRIBTOFITOUT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DBUDGETMAKEGOOD",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DTBUDGETLEASEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DTPROFILEYEARSTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FBASERENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FEXPIRYDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FFITOUTDAYS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FINCENTIVE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLEASESTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLEASETYPE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLESSORWORKS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FMAKEGOOD",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FOGPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FPROMO",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FRENTCOMMENCE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FSTATUS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "INHOUSENETRENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PAREA",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PBASERENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PCAPOCCCOST",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "POGPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "POWNERNAME",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PPROMO",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PTENANT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PTENANTNAME",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "SDEFAULTRECOVERYPROFILEDESC",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION3",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION4",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION5",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SMGMTFEE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "UnitKey",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "VALUATIONNETRENT",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_state" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "turnover_based_rent_percent",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "turnover_rent_state" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_term" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lease_start" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_levy_state" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_levy_type" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "promotion_amt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annual_increase" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annual_increase_type" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "tenancy_coordination_fee",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealOptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "term_length" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLafLeaseDetailsQuery,
  GetLafLeaseDetailsQueryVariables
>;
export const GetLafSecurityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafSecurity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outgoings_psm" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "base_net_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "guarantors_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_guarantee_months" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "achieved_bank_guarantee",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "achieved_rent_deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rent_deposit_months" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealDataGuarantors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "full_name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLafSecurityQuery, GetLafSecurityQueryVariables>;
export const GetLafIncentiveDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafIncentive" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fitout_contribution" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cash_incentive" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealFitoutLandlordWork" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "preview" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "cost_landlord_works",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "landlord_undertake_works",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cost_makegood" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "capped_category" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "tenant_contribution_landlord_works",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealsUnits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "VirtualUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Unit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "estimated_area",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitKey" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "SavedYardiUnitProfile",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETEXPIRYDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETFITOUTDAYS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETLEASESTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETSTATUS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "BUDGETVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALPROMOLEVY",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALRECOVERYPSM",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DANNUALRENT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETEDLANDLORDWORKCOST",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETLANDLORDCONTRIBTOFITOUT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DBUDGETMAKEGOOD",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DTBUDGETLEASEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "DTPROFILEYEARSTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FBASERENT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FEXPIRYDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FFITOUTDAYS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FINCENTIVE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FLEASESTART",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FLEASETYPE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FLESSORWORKS",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FMAKEGOOD" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FOGPSM" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FPROMO" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FRENTCOMMENCE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "FSTATUS" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "FVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "INHOUSENETRENT",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PAREA" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PBASERENT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PCAPOCCCOST",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "POGPSM" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "POWNERNAME" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PPROMO" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "PTENANT" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PTENANTNAME",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "PVACATEDATE",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "SDEFAULTRECOVERYPROFILEDESC",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION1" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION3" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION4" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SECTION5" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "SMGMTFEE" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "UnitKey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "VALUATIONNETRENT",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "YardiUnit" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "YardiUnitProfiles",
                                    },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "limit" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "order_by",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "DTPROFILEYEARSTART",
                                              },
                                              value: {
                                                kind: "EnumValue",
                                                value: "desc",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "where" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "deleted_at",
                                              },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "_is_null",
                                                    },
                                                    value: {
                                                      kind: "BooleanValue",
                                                      value: true,
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETEXPIRYDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETFITOUTDAYS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETLEASESTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETSTATUS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "BUDGETVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALPROMOLEVY",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALRECOVERYPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DANNUALRENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DBUDGETEDLANDLORDWORKCOST",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "DBUDGETLANDLORDCONTRIBTOFITOUT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DBUDGETMAKEGOOD",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DTBUDGETLEASEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "DTPROFILEYEARSTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FBASERENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FEXPIRYDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FFITOUTDAYS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FINCENTIVE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLEASESTART",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLEASETYPE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FLESSORWORKS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FMAKEGOOD",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FOGPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FPROMO",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FRENTCOMMENCE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FSTATUS",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "FVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "INHOUSENETRENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PAREA",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PBASERENT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PCAPOCCCOST",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "POGPSM",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "POWNERNAME",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PPROMO",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PTENANT",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PTENANTNAME",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "PVACATEDATE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "SDEFAULTRECOVERYPROFILEDESC",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION1",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION2",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION3",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION4",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SECTION5",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "SMGMTFEE",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "UnitKey",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "VALUATIONNETRENT",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLafIncentiveQuery,
  GetLafIncentiveQueryVariables
>;
export const GetLafOtherInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafOtherInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "storage_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "storage_area" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seating_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seating_area" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signage_rent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number_of_signs" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLafOtherInfoQuery,
  GetLafOtherInfoQueryVariables
>;
export const GetLafUsageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafUsage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealPermittedUses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "food_menu" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sublet" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "TemplatePermittedUse",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLafUsageQuery, GetLafUsageQueryVariables>;
export const GetLafSpecialConditionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafSpecialCondition" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealData" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "version" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealSpecialConditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "laf_description" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLafSpecialConditionQuery,
  GetLafSpecialConditionQueryVariables
>;
export const SubGetApprovalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "SubGetApprovals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_data_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lafPhaseName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          defaultValue: {
            kind: "StringValue",
            value: "LAF review",
            block: false,
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealData_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_data_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Deal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DealPhases" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "where" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "name" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "lafPhaseName",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status_id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "DealLAFApprovals" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "level" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "escalate_level" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "last_approver" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ActiveApprover" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "laf_action" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubGetApprovalsSubscription,
  SubGetApprovalsSubscriptionVariables
>;
export const UpdateApprovalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateApprovals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_data_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "laf_action" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LafActionChoices_enum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "modified_at" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DealLAFApprovals_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_data_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deal_data_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "modified_at" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "modified_at" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "laf_action" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "laf_action" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "deallafapprovals_deal_id_unique",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "laf_action" },
                          { kind: "EnumValue", value: "modified_at" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateApprovalsMutation,
  UpdateApprovalsMutationVariables
>;
export const GetLafVersionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLafVersion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Deals_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deal_id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "laf_version" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLafVersionQuery, GetLafVersionQueryVariables>;
export const GetPropertyDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPropertyDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiPropertyProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "PropertyCode" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "YardiProperty" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PropertyCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PropertyName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PropertyArea" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address1" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Address2" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "City" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PostCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Country" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile_year_start" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "landlord_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "landlord_acn" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "landlord_address" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "management_company_real_estate_licence",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "management_company" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "management_company_abn" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "management_company_phone" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "management_company_address" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "management_company_postal" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parking_space_num" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer_parking_space_num" },
                },
                { kind: "Field", name: { kind: "Name", value: "alteration" } },
                { kind: "Field", name: { kind: "Name", value: "development" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "alteration_works" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "anchor_tenant_expiry" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_gr_return_centre" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_gr_return_food" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_gr_return_nonfood" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "annual_gr_return_service" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "turn_over_sat" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "head_or_crown_lease" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "head_or_crown_lease_start" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "head_or_crown_lease_end" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "head_or_crown_lease_option_start",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "head_or_crown_lease_option_end",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "require_consent_to_lease" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "require_minister_consent_to_crown",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "require_mortgagee_consent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "effect_of_obligations" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_mon" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_tues" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_wed" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_thur" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_fri" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_sat" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_sun" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trade_hour_holiday" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyDetailsQuery,
  GetPropertyDetailsQueryVariables
>;
export const GetPropertyExpenseDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPropertyExpense" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiPropertyProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "PropertyCode" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "exp_admin" } },
                { kind: "Field", name: { kind: "Name", value: "exp_audit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_air_maint" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_air_ops" } },
                { kind: "Field", name: { kind: "Name", value: "exp_strata" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_building_intelligence" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_traffic_flow" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_energy_mgmt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_mgmt_fee" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_insurance" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_pest" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_ventilation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_caretaking" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_emergency_sys" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_fire_levy" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_fire_protection" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_security" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_cleaning_costs" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_post_boxes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_public_telephones" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_car_parking" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_child_minding" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_escalators" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_lift" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_uniforms" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_info_directories" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_public_address" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_signage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_local_gov_charges" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_water_sewage_rates" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_electricity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_repairs_and_maint" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_sinking_fund" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_gas" } },
                { kind: "Field", name: { kind: "Name", value: "exp_oil" } },
                { kind: "Field", name: { kind: "Name", value: "exp_water" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_sewerage_disposal" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "exp_water_collection_and_disposal",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_ohs" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_landscape" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_clean_consumable" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_staff_wages" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "miscellaneous" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyExpenseQuery,
  GetPropertyExpenseQueryVariables
>;
export const GetLandlordFacilitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLandlordFacilities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiPropertyProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "PropertyCode" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services_tenancy_clean" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services_common_clean" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services_common_light" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services_security" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services_bin_room" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "exp_staff_toilet" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_child" } },
                { kind: "Field", name: { kind: "Name", value: "num_shop" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shops_leased_and_occupied" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shops_leased_and_unoccupied" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy_coord_fee_per_trans" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "survey_fee_per_trans" },
                },
                { kind: "Field", name: { kind: "Name", value: "site_area" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "market_competitor" },
                },
                { kind: "Field", name: { kind: "Name", value: "exp_other" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLandlordFacilitiesQuery,
  GetLandlordFacilitiesQueryVariables
>;
export const GetPropertyInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPropertyInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PropertiesInformation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "property_code" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "other_toggle" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "embedded_network_toggle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "market_impact" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyInformationQuery,
  GetPropertyInformationQueryVariables
>;
export const UpsertPropertiesInformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpsertPropertiesInformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PropertiesInformation_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_PropertiesInformation_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "PropertiesInformation_property_code_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "other_toggle" },
                          {
                            kind: "EnumValue",
                            value: "embedded_network_toggle",
                          },
                          { kind: "EnumValue", value: "description" },
                          { kind: "EnumValue", value: "market_impact" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPropertiesInformationMutation,
  UpsertPropertiesInformationMutationVariables
>;
export const GetPropertyFilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPropertyFiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PropertyFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "property_code" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "property_code" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "user_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "file_path" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyFilesQuery,
  GetPropertyFilesQueryVariables
>;
export const CreatePropertyFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePropertyFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PropertyFiles_insert_input" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_PropertyFiles_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "PropertyFiles_file_path_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "file_name" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_path" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePropertyFileMutation,
  CreatePropertyFileMutationVariables
>;
export const GetUnitsByPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUnitsByProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Units" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Property" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "property_code" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "property_code",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "UnitStatus" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_neq" },
                            value: {
                              kind: "StringValue",
                              value: "Inactive",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "Attribute1" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute2" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute3" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute4" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute5" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute6" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute7" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute8" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute9" } },
                { kind: "Field", name: { kind: "Name", value: "Attribute10" } },
                { kind: "Field", name: { kind: "Name", value: "Building" } },
                { kind: "Field", name: { kind: "Name", value: "FloorCode" } },
                { kind: "Field", name: { kind: "Name", value: "IsExclude" } },
                { kind: "Field", name: { kind: "Name", value: "LevelName" } },
                { kind: "Field", name: { kind: "Name", value: "LevelNumber" } },
                { kind: "Field", name: { kind: "Name", value: "Location" } },
                { kind: "Field", name: { kind: "Name", value: "MarketRent" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "PropertyName" },
                },
                { kind: "Field", name: { kind: "Name", value: "RentalType" } },
                { kind: "Field", name: { kind: "Name", value: "UnitArea" } },
                { kind: "Field", name: { kind: "Name", value: "UnitCode" } },
                { kind: "Field", name: { kind: "Name", value: "UnitKey" } },
                { kind: "Field", name: { kind: "Name", value: "UnitStatus" } },
                { kind: "Field", name: { kind: "Name", value: "UnitType" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                { kind: "Field", name: { kind: "Name", value: "deleted_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "area_survey_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimated_area" },
                },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "survey_area" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnitsByPropertyQuery,
  GetUnitsByPropertyQueryVariables
>;
export const GetCentreMapsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCentreMaps" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PropertyFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "EnumValue", value: "centre_map" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_path" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCentreMapsQuery, GetCentreMapsQueryVariables>;
export const GetLegislationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLegislations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "PropertyFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "EnumValue", value: "legislation" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_path" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLegislationsQuery,
  GetLegislationsQueryVariables
>;
export const GetYardiPropertyContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYardiPropertyContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiProperties_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "PropertyCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "property_code" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "YardiContacts_YardiProperties",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "Role" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YardiContact" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ContactCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "FirstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "LastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber10" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber3" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber4" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber5" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber6" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber7" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber8" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "PhoneNumber9" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Title" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetYardiPropertyContactsQuery,
  GetYardiPropertyContactsQueryVariables
>;
export const GetVirtualUnitsByPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetVirtualUnitsByProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "property_code" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "VirtualUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "Property" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "property_code" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "property_code",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deleted" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "unit_type" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "area_survey_required" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimated_area" },
                },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property_code" },
                },
                { kind: "Field", name: { kind: "Name", value: "survey_area" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVirtualUnitsByPropertyQuery,
  GetVirtualUnitsByPropertyQueryVariables
>;
export const DeleteVirtualUnitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteVirtualUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_VirtualUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deleted" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteVirtualUnitMutation,
  DeleteVirtualUnitMutationVariables
>;
export const UpdateVirtualUnitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateVirtualUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "label" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "estimated_area" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "numeric" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_VirtualUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "label" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "label" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "estimated_area" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "estimated_area" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVirtualUnitMutation,
  UpdateVirtualUnitMutationVariables
>;
export const GetDealsUnitsForVirtualUnitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDealsUnitsForVirtualUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "virtual_unit_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DealsUnits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "virtual_unit_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "virtual_unit_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDealsUnitsForVirtualUnitQuery,
  GetDealsUnitsForVirtualUnitQueryVariables
>;
export const SearchDocumentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchDocuments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchQuery" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchDocumentsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchQuery" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchQuery" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "date" } },
                { kind: "Field", name: { kind: "Name", value: "link" } },
                { kind: "Field", name: { kind: "Name", value: "score" } },
                { kind: "Field", name: { kind: "Name", value: "centre" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchDocumentsQuery,
  SearchDocumentsQueryVariables
>;
export const GetSpecialConditionTemplatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSpecialConditionTemplates" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplateSpecialConditions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priority" },
                      value: { kind: "EnumValue", value: "asc_nulls_last" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                { kind: "Field", name: { kind: "Name", value: "priority" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ModifiedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSpecialConditionTemplatesQuery,
  GetSpecialConditionTemplatesQueryVariables
>;
export const GetSpecialConditionTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSpecialConditionTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "TemplateSpecialConditions_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "priority" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "laf_description" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSpecialConditionTemplateQuery,
  GetSpecialConditionTemplateQueryVariables
>;
export const CreateSpecialConditionTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSpecialConditionTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "TemplateSpecialConditions_insert_input",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "insert_TemplateSpecialConditions_one",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSpecialConditionTemplateMutation,
  CreateSpecialConditionTemplateMutationVariables
>;
export const UpdateSpecialConditionTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSpecialConditionTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "TemplateSpecialConditions_pk_columns_input",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "TemplateSpecialConditions_set_input",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "update_TemplateSpecialConditions_by_pk",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSpecialConditionTemplateMutation,
  UpdateSpecialConditionTemplateMutationVariables
>;
export const CreateTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DocumentTemplates_insert_input" },
              },
            },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_DocumentTemplates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;
export const GetDocumentTemplatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDocumentTemplates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DocumentTemplates_bool_exp" },
          },
          defaultValue: { kind: "ObjectValue", fields: [] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DocumentTemplates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "generate_to_name" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "generate_to_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "event_trigger_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "template_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "template_path" },
                },
                { kind: "Field", name: { kind: "Name", value: "information" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "convert_to_pdf" },
                },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "hidden" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_full_name" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDocumentTemplatesQuery,
  GetDocumentTemplatesQueryVariables
>;
export const SoftDeleteDocumentTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SoftDeleteDocumentTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DocumentTemplates_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deleted" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SoftDeleteDocumentTemplateMutation,
  SoftDeleteDocumentTemplateMutationVariables
>;
export const ToggleDocumentTemplateConvertToPdfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ToggleDocumentTemplateConvertToPdf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "convert_to_pdf" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DocumentTemplates_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "convert_to_pdf" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "convert_to_pdf" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "convert_to_pdf" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleDocumentTemplateConvertToPdfMutation,
  ToggleDocumentTemplateConvertToPdfMutationVariables
>;
export const ToggleDocumentTemplateHiddenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ToggleDocumentTemplateHidden" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hidden" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_DocumentTemplates_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "hidden" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hidden" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "hidden" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleDocumentTemplateHiddenMutation,
  ToggleDocumentTemplateHiddenMutationVariables
>;
export const GetTemplateGeneratedNameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTemplateGeneratedName" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "template_name" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DocumentTemplates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "template_name" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "template_name" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "generate_to_name" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTemplateGeneratedNameQuery,
  GetTemplateGeneratedNameQueryVariables
>;
export const Upsert_UserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsert_user" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "object" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Users_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_Users_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "object" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: { kind: "EnumValue", value: "Users_pkey" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "last_login" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Upsert_UserMutation, Upsert_UserMutationVariables>;
export const YardiDataSyncStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "YardiDataSyncStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deal_id" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "bigint" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "YardiDealSyncStatuses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deal_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "deal_id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "created_at" },
                      value: { kind: "EnumValue", value: "desc" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "modified_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YardiDataSyncStatusSubscription,
  YardiDataSyncStatusSubscriptionVariables
>;
export const YardiEgestDealDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "YardiEgestDeal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealArg" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EgestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "egestJob" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dealArg" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealArg" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "jobId" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YardiEgestDealMutation,
  YardiEgestDealMutationVariables
>;
export const YardiDeleteQuestionnaireDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "YardiDeleteQuestionnaire" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "yardiDeleteQuestionnaire" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dealId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dealId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YardiDeleteQuestionnaireMutation,
  YardiDeleteQuestionnaireMutationVariables
>;
