import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { GetLeasesForRenewalQuery } from "../../generated/graphql-operations";
import { FormField, SelectField } from "../Form/Form";
import { RenewalDialogSchema, RenewalDialogType } from "./Config";
import { DealTenantType } from "../../utils/CommonVariables";

interface RenewalDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: GetLeasesForRenewalQuery["YardiLeasesForRenewal"][0];
}

interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const RenewalDialog = (props: RenewalDialogProps) => {
  const form = useForm<RenewalDialogType>({
    resolver: yupResolver(RenewalDialogSchema),
  });

  const units = useMemo(
    () =>
      Object.entries(props.data ?? {})
        .filter(([key, v]) => key.startsWith("unit") && (v?.length ?? 0) > 0)
        .map((item) => item[1]),
    [props.data]
  );

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const navigate = useNavigate();

  const tenantTypeSelectField: FormField = {
    type: "select",
    fieldName: "tenantType",
    label: "Tenant type",
    helperText: "",
    defaultValue: { label: "", value: -1 },
    choices: [
      {
        label: "Individual",
        value: DealTenantType.Individual,
      },
      {
        label: "Company",
        value: DealTenantType.Company,
      },
    ],
    columns: 12,
  };

  const mainUnitSelectField: FormField = {
    type: "select",
    fieldName: "mainUnit",
    label: "Primary unit",
    helperText: "",
    defaultValue: { label: "", value: "" },
    choices: units.map((unit) => {
      return { label: unit ?? "", value: unit ?? "" };
    }),
    columns: 12,
  };

  useEffect(() => {
    if (units.length >= 1) {
      form.setValue("mainUnit", {
        label: units[0],
        value: units[0],
      });
    }
  }, [form, units]);

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Renew lease for {props.data.TenantName}</DialogTitle>
      <DialogContent>
        <SelectField form={form} formField={tenantTypeSelectField} />
        <Box sx={{ mt: 2 }} />
        <SelectField form={form} formField={mainUnitSelectField} />

        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={form.handleSubmit((data) => {
              const renewalData = {
                ...data,
                ...props.data,
              };
              navigate("/renew-deal", { state: renewalData });
            })}
          >
            Renew lease
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              form.reset();
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
