import React from "react";
import {
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Table,
  TableCell,
  Paper,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

export interface ScrollableTableProps<Columns extends string> {
  columns: ReadonlyArray<Columns>;
  rows: (Record<Columns, string | JSX.Element | number | undefined> & {
    key: string;
    sx?: SxProps<Theme>;
  })[];
}

export const ScrollableTable = <Columns extends string>({
  columns,
  rows,
}: ScrollableTableProps<Columns>) => {
  const stickySx: SxProps<Theme> = {
    position: "sticky",
    left: 0,
    backgroundColor: "secondary.light",
  };

  const headerStyle = {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "secondary.contrastText",
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                ...stickySx,
                ...headerStyle,
              }}
            >
              {columns[0]}
            </TableCell>
            {columns.slice(1).map((column) => (
              <TableCell key={column} sx={headerStyle}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.key}
              sx={{
                backgroundColor: "secondary.light",
              }}
            >
              <TableCell
                sx={{
                  ...stickySx,
                  ...row.sx,
                }}
              >
                {row[columns[0]]}
              </TableCell>
              {columns.slice(1).map((column) => (
                <TableCell key={`${column} - ${row.key}`} sx={row.sx}>
                  {row[column]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
