import React from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpecialConditionSchema, SpecialConditionType } from "./Config";

import {
  useUpdateSpecialConditions,
  useGetSpecialConditionTemplate,
  applySpecialConditionDefaults,
  useCreateSpecialConditions,
} from "../../../../adapters/Templates/Templates";
import { EditorControlled } from "../../../Form/FormComponents";
import { TextNumberField } from "../../../Form/Form";

interface SpecialConditionDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  defaultData: SpecialConditionType;
  onSubmit: (data: SpecialConditionType) => void;
}

interface UpdateDialogProps {
  open: boolean;
  onClose: () => void;
  templateId: number;
}

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SpecialConditionDialog = (props: SpecialConditionDialogProps) => {
  const form = useForm({
    resolver: yupResolver(SpecialConditionSchema),
    mode: "onBlur",
    defaultValues: props.defaultData,
  });

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700, pl: 3 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={12}>
            <TextNumberField
              form={form}
              formField={{
                fieldName: "title",
                helperText: "",
                label: "Special condition title",
                type: "text",
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextNumberField
              form={form}
              formField={{
                fieldName: "priority",
                helperText: "",
                label: "Priority",
                type: "number",
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <EditorControlled
              control={form.control}
              label="Full description"
              name="description"
            />
          </Grid>

          <Grid item sm={12}>
            <EditorControlled
              control={form.control}
              label="Description for LAF"
              name="descriptionLaf"
              height={100}
            />
          </Grid>

          {/* Dialog button Actions  */}
          <Grid item sm={12}>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color="primary"
              disabled={form.formState.isSubmitting}
              onClick={form.handleSubmit(async (submitData) => {
                await props.onSubmit(submitData);
                props.onClose();
              })}
            >
              Submit
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.onClose()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const UpdateDialog = ({ templateId, ...props }: UpdateDialogProps) => {
  const updateTemplate = useUpdateSpecialConditions(templateId);
  const data = useGetSpecialConditionTemplate(templateId);

  if (
    data?.TemplateSpecialConditions_by_pk === undefined ||
    data?.TemplateSpecialConditions_by_pk === null
  ) {
    return <Skeleton />;
  }

  return (
    <SpecialConditionDialog
      {...props}
      title="Update special condition clause"
      defaultData={applySpecialConditionDefaults(
        data.TemplateSpecialConditions_by_pk
      )}
      onSubmit={updateTemplate}
    />
  );
};

export const CreateDialog = (props: CreateDialogProps) => {
  const createTemplate = useCreateSpecialConditions();

  return (
    <SpecialConditionDialog
      {...props}
      title="Create special condition clause"
      defaultData={applySpecialConditionDefaults(undefined)}
      onSubmit={createTemplate}
    />
  );
};
