import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Grid } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { TemplateUploadDialog } from "../TemplateUploadDialog/TemplateUploadDialog";
import theme from "../../../themes/theme";
import { GetDocumentTemplatesDocument } from "../../../generated/graphql-operations";
import { TemplateCard } from "./TemplateCard";

export const TemplateDocument = () => {
  const [open, setOpen] = useState(false);
  const templateDocuments = useQuery(GetDocumentTemplatesDocument);
  const documents = templateDocuments?.data?.DocumentTemplates;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{ mb: 2 }}
        >
          <FileUploadIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
          <span style={{ color: theme.palette.primary.main }}>Upload file</span>
        </Button>
      </Grid>
      <Grid container item sm={12} spacing={2}>
        {documents?.map((document) => {
          return (
            <Grid item xs="auto" key={document.id}>
              <TemplateCard data={document} />
            </Grid>
          );
        })}
      </Grid>

      <TemplateUploadDialog open={open} setOpen={setOpen} />
    </Grid>
  );
};
