import React, { useState } from "react";
import * as yup from "yup";
import { parseISO } from "date-fns";
import {
  Divider,
  Typography,
  Stack,
  Chip,
  Grid,
  Box,
  Alert,
  Avatar,
  Autocomplete,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateCommentDocument,
  GetDealCommentsDocument,
  GetUsersDocument,
  Tags_Constraint,
  Tags_Update_Column,
} from "../../../../generated/graphql-operations";
import { TextMultiField } from "../../../Form/FormComponents";
import { Button } from "../../../Button";
import { TagBar } from "../../../TagBar";
import { BLACK_ALPHA_60 } from "../../../../utils/CommonVariables";
import { checkAndFormatDate } from "../../../../utils/DateUtils";
import {
  FormField,
  IformFieldChoice,
  MultiSelectField,
} from "../../../Form/Form";

const DealCommentsSchema = yup.object({
  text: yup.string().required("Comment text cannot be empty").default(""),
  tags: yup.array().of(yup.string()),
  tagged_users: yup
    .array()
    .of(yup.object({ label: yup.string(), value: yup.string() })),
});

type DealCommentsType = yup.InferType<typeof DealCommentsSchema>;

interface DealCommentsProps {
  dealId: number;
  dealDisabled: boolean;
  defaultTags?: string[];
}

interface DealCommentProps {
  comment: {
    name: string;
    text: string;
    date: string;
    tags: string[];
    taggedUsers: string[];
  };
}

const commentField = {
  fieldName: "text",
  label: "Comment",
  helperText: "",
};

const tagField = {
  label: "Tags",
  fieldName: "tags",
  helperText: "",
  placeholder: "Search for tags or type a new tag and press Enter",
};

const DealComment = (props: DealCommentProps) => {
  return (
    <>
      <Typography variant="subtitle1" display="inline">
        {props.comment.name}{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        display="inline"
        sx={{ color: BLACK_ALPHA_60 }}
      >
        {checkAndFormatDate(
          parseISO(`${props.comment.date}Z`),
          "ccc d LLL, HH:mm a"
        ) ?? "Invalid date"}
      </Typography>
      {props.comment.taggedUsers.length > 0 && (
        <Typography variant="body1" sx={{ mb: 1, color: BLACK_ALPHA_60 }}>
          {props.comment.taggedUsers.map((user) => `@${user} `)}
        </Typography>
      )}
      <Typography variant="body1" sx={{ mb: 1, color: BLACK_ALPHA_60 }}>
        {props.comment.text}
      </Typography>
      <Stack direction="row">
        {props.comment.tags.map((tag) => (
          <Chip key={tag} variant="outlined" label={tag} sx={{ mr: 1 }} />
        ))}
      </Stack>
    </>
  );
};

export const DealComments = (props: DealCommentsProps) => {
  const users = useQuery(GetUsersDocument);
  const userChoices =
    users.data?.Users?.map(
      (user) =>
        ({
          label: user?.user_full_name ?? "",
          value: user?.id ?? null,
        } as IformFieldChoice)
    ) ?? [];

  const taggedUsersField: FormField = {
    type: "select",
    fieldName: "tagged_users",
    label: "Tag user",
    helperText: "",
    choices: userChoices,
    columns: 12,
  };

  const form = useForm<DealCommentsType>({
    resolver: yupResolver(DealCommentsSchema),
    defaultValues: { tags: props.defaultTags },
  });

  const [filterTag, setFilterTag] = useState(props.defaultTags?.[0] ?? "");

  const dealComments = useQuery(GetDealCommentsDocument, {
    variables: {
      deal_id: props.dealId,
    },
  });

  const allTags =
    Array.from(
      new Set(
        dealComments?.data?.Comments?.flatMap((comment) =>
          comment?.CommentTags?.flatMap((ct) => ct.Tag?.name)
        )
      )
    ).sort() ?? [];

  const allComments = dealComments?.data?.Comments?.map((item) => ({
    id: item?.id,
    name: item?.User?.user_full_name ?? "",
    text: item?.text ?? "",
    tags:
      item?.CommentTags.map((commentTag) => commentTag.Tag?.name ?? "") ?? [],
    date: item?.created_at,
    taggedUsers:
      item?.CommentUserTags.map(
        (commentUserTag) => commentUserTag.User?.user_full_name ?? ""
      ) ?? [],
  }));

  const [CreateComment] = useMutation(CreateCommentDocument);

  interface ValidationProps {
    show: boolean;
    severity: "success" | "error";
    message: string;
  }

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (data: DealCommentsType) => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });

    const userTagsObject = (data.tagged_users ?? []).map((user) => {
      return {
        user_id: user?.value,
      };
    });

    const tagsObject = (data.tags ?? []).map((tag) => {
      return {
        Tag: {
          data: { name: tag },
          on_conflict: {
            constraint: Tags_Constraint.TagsNameUnique,
            update_columns: [Tags_Update_Column.Name],
          },
        },
      };
    });

    const comment = await CreateComment({
      variables: {
        text: form.getValues("text"),
        deal_id: props.dealId,
        user_tags_object: userTagsObject,
        tags_object: tagsObject,
      },
      refetchQueries: ["GetDealComments"],
    });

    if (comment.data) {
      setValidation({
        show: true,
        severity: "success",
        message: "Success - comment added",
      });

      form.reset({ text: "" });
    } else {
      setValidation({
        show: true,
        severity: "error",
        message: "Error - problem adding comment. Please refresh and try again",
      });
    }
  };

  return (
    <Grid container>
      {props.dealDisabled ? null : (
        <>
          <Grid item xs={12}>
            <MultiSelectField
              control={form.control}
              formField={taggedUsersField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextMultiField control={form.control} formField={commentField} />
          </Grid>
          <Grid item xs={12}>
            <TagBar control={form.control} formField={tagField} />
          </Grid>
          <Grid item xs={12}>
            {validation.show && (
              <Alert sx={{ mt: 2 }} severity={validation.severity}>
                {validation.message}
              </Alert>
            )}
          </Grid>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => {
              form.handleSubmit(async (data) => {
                await onSubmit(data);
              })();
            }}
          >
            Send
          </Button>
        </>
      )}
      <Divider sx={{ mt: 3, width: "100%" }} />

      <Autocomplete
        data-testid="comment-filter-autocomplete"
        value={filterTag}
        size="medium"
        fullWidth
        options={allTags}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            margin="dense"
            data-testid="comment-filter-text"
            fullWidth
            hiddenLabel
            color="primary"
            placeholder="Search by tag"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(_, data, reason) => {
          if (reason === "clear") {
            setFilterTag("");
          }
          if (data) {
            setFilterTag(data);
          }
        }}
      />

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {allComments
          ?.filter(
            (comment) => filterTag === "" || comment.tags.includes(filterTag)
          )
          .map((comment) => (
            <React.Fragment key={comment.id}>
              <Grid item xs="auto">
                <Avatar>
                  {comment.name
                    .split(" ")
                    .map((name) => name?.[0]?.toUpperCase())
                    .join("")}
                </Avatar>
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <DealComment comment={comment} />
              </Grid>
              <Box width="100%" />
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};
