/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useForm, Controller, FieldError } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Grid,
  Box,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Alert,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { Button } from "../../Button";
import { ToggleButton } from "../../ToggleButton";
import {
  CompanyTenantType,
  DealTerm,
  DealTermSchema,
  FinalStepDealProps,
  IndividualTenantType,
} from "../DealConfig";
import { useGetSpecialConditions, useGetStateData } from "../utils";
import { FormChecklist } from "./FormChecklist/FormChecklist";
import {
  useGetPropertyData,
  getUnitsByCentre,
  getVirtualUnitsByCentre,
  useGetVirtualUnits,
  useGetUnitTypes,
} from "../../../adapters/Properties/Properties";
import { PermittedUsage } from "./PermittedUsage/PermittedUsage";
import {
  AddNewContactOption,
  DealTenantType,
  DealType,
  PROMOTION_LEVY_CHOICES,
  VIRTUAL_UNIT_LABEL,
  VIRTUAL_UNIT_LABEL_SUFFIX,
  hoardingAndSkinsOptions,
} from "../../../utils/CommonVariables";

import { TENANT_WORK_CHOICES } from "../../DealInformation/FitoutLandlordWorks/Config";
import {
  CurrencyFormatter,
  MuiNumberFormatter,
  PercentageFormatter,
} from "../../MuiCustomNumberFormat";
import {
  CheckboxForm,
  EditorControlled,
  FormSwitch,
  TextMultiField,
} from "../../Form/FormComponents";
import { LandlordWorks } from "./LandlordWorks/LandlordWorks";
import {
  GetYardiContactsByTenantDocument,
  GetYardiOutgoingsDocument,
  GetYardiUnitByKeyDocument,
} from "../../../generated/graphql-operations";
import { RenewalDataType } from "../../Renewals/Config";
import { Form } from "../../Form/Form";
import { DEFAULT_VALUES } from "../../DealInformation/Commercial/Config";
import { getEstimatedArea, LocationSelect } from "../../LocationSelect";

interface FormField {
  type: "text" | "select" | "number";
  defaultValue?: any;
  fieldName: keyof DealTerm;
  label: string;
  helperText: string;
  choices?: { label: string; value: string | number }[];
  columns: number;
  inlineLabel?: string;
  adornment?: {
    value: string;
    position: "start" | "end";
  };
  customComp?: ReturnType<typeof MuiNumberFormatter>;
  disabled?: boolean;
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data?: any
  ) => void | {} | Promise<void> | Promise<{}>;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void | {} | Promise<void> | Promise<{}>;
}
interface RadioSectionProps {
  id: keyof DealTerm;
  parentProps: FinalStepDealProps;
  options: {
    value: string;
    label: string;
  }[];
}

const RadioSection = (props: RadioSectionProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    props.parentProps.setState((prev) => ({
      ...prev,
      [props.id]: target.value,
    }));
  };

  return (
    <FormControl>
      <RadioGroup
        value={props.parentProps.state[props.id]}
        onChange={handleChange}
      >
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const securityRadioOptions = [
  {
    value: "bankGuarantee",
    label: "Bank guarantee",
  },
  {
    value: "guarantors",
    label: "Guarantors",
  },
];

const toggleOptions = [
  { text: "Yes", id: "yes" },
  { text: "No", id: "no" },
  { text: "N/A", id: "na" },
];

const landlordWorksOptions = [
  {
    value: "asPerFitoutGuide",
    label: "As per fitout guide",
  },
  {
    value: "asIsWhereIs",
    label: '"As is where is " condition',
  },
  {
    value: "other",
    label: "Other",
  },
];

export const tenantWorksOptions = [
  {
    value: TENANT_WORK_CHOICES[0],
    label: "Full fitout",
  },
  {
    value: TENANT_WORK_CHOICES[2],
    label: "Partial refurbishment",
  },
];

const RentalDepositSwitch = (props: FinalStepDealProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setState((prev) => ({
      ...prev,
      rentalDeposit: event.target.checked,
    }));
  };

  return (
    <FormControlLabel
      sx={{ p: 1 }}
      control={
        <Switch checked={props.state.rentalDeposit} onChange={handleChange} />
      }
      label="Rental deposit"
    />
  );
};

const LandlordWorksSwitch = (props: FinalStepDealProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setState((prev) => ({
      ...prev,
      landlordWorksToggle: event.target.checked,
    }));
  };

  return (
    <FormControlLabel
      sx={{ p: 1 }}
      control={
        <Switch
          checked={props.state.landlordWorksToggle}
          onChange={handleChange}
        />
      }
      label="Landlord will undertake works"
    />
  );
};

export const DealTerms = (
  props: FinalStepDealProps & {
    renewalData?: RenewalDataType;
  }
) => {
  const { setState } = props;
  const [centres, units] = useGetPropertyData();
  const virtualUnits = useGetVirtualUnits();
  const [failCreateData, setFailCreateData] = React.useState(false);

  const primaryUnit = useQuery(GetYardiUnitByKeyDocument, {
    variables: {
      unit_key: `${props.renewalData?.PropertyCode}:${props.renewalData?.mainUnit.label}`,
    },
  });

  const outgoings = useQuery(GetYardiOutgoingsDocument, {
    variables: {
      unit_key: `${props.renewalData?.PropertyCode}:${props.renewalData?.mainUnit.label}`,
    },
  });

  const centreOptions = useMemo(
    () =>
      centres.data?.Properties.map((centre) => ({
        label: centre.label as string,
        value: centre.property_code ?? "",
      })) ?? [],
    [centres.data?.Properties]
  );

  const unitOptions = useMemo(
    () => [
      { label: VIRTUAL_UNIT_LABEL, value: "" },
      ...getUnitsByCentre(props.state.centre.value, units),
      ...getVirtualUnitsByCentre(props.state.centre.value, virtualUnits.data),
    ],
    [props.state.centre.value, units, virtualUnits.data]
  );

  const form = useForm({
    resolver: yupResolver(DealTermSchema),
    defaultValues: props.state,
    mode: "onBlur",
  });

  const fitoutFields: FormField[] = [
    {
      type: "text",
      fieldName: "fitoutPeriodWeeks",
      label: "Fitout period",
      helperText: "Mandatory",
      columns: 3,
      inlineLabel: "Weeks",
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          fitoutPeriodWeeks: newValue,
        }));
      },
    },
    {
      type: "text",
      fieldName: "fitoutPeriodDays",
      label: "",
      helperText: "",
      columns: 3,
      inlineLabel: "Days",
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          fitoutPeriodDays: newValue,
        }));
      },
    },
  ];

  const termFields: FormField[] = [
    {
      type: "number",
      fieldName: "termYear",
      label: "Term",
      helperText: "Mandatory",
      columns: 2,
      inlineLabel: "Years",
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          termYear: parseInt(newValue, 10),
        }));
      },
    },
    {
      type: "number",
      fieldName: "termMonth",
      label: "",
      helperText: "",
      columns: 2,
      inlineLabel: "Months",
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          termMonth: parseInt(newValue, 10),
        }));
      },
    },
    {
      type: "number",
      fieldName: "termDay",
      label: "",
      helperText: "",
      columns: 2,
      inlineLabel: "Days",
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          termDay: parseInt(newValue, 10),
        }));
      },
    },
  ];

  const baseRentField: FormField = {
    type: "text",
    fieldName: "baseRent",
    label: "Base rent",
    helperText: "Mandatory",
    columns: 6,
    adornment: {
      value: "$",
      position: "start",
    },
    customComp: CurrencyFormatter,
    onBlur: (e) => {
      const newValue = (e.target as HTMLInputElement).value;
      setState((prev) => ({
        ...prev,
        baseRent: parseFloat(newValue),
      }));
    },
  };

  const rentReviewField: FormField = {
    type: "number",
    fieldName: "rentReviewAnnualIncrease",
    label: "Annual increase",
    helperText: "Mandatory",
    columns: 6,
    adornment: {
      value: "%",
      position: "start",
    },
    onBlur: (e) => {
      const newValue = (e.target as HTMLInputElement).value;
      setState((prev) => ({
        ...prev,
        rentReviewAnnualIncrease: parseInt(newValue, 10),
      }));
    },
  };

  const turnoverFields: FormField[] = [
    {
      type: "number",
      fieldName: "turnover",
      label: "Turnover",
      helperText: "Mandatory",
      columns: 6,
      adornment: {
        value: "%",
        position: "start",
      },
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          turnover: parseInt(newValue, 10),
        }));
      },
    },
  ];

  const promotionLevyFields: FormField[] = [
    {
      type: "select",
      fieldName: "promotionLevyType",
      label: "Type",
      helperText: "",
      columns: 1,
      choices: PROMOTION_LEVY_CHOICES,
      defaultValue: DEFAULT_VALUES.promotionLevyType,
      onChange: (_, data) => {
        form.setValue("promotionLevyType", data);
        setState((prev) => ({
          ...prev,
          promotionLevyType: {
            label: data.label,
            value: data.value as string,
          },
        }));
      },
    },
    {
      type: "text",
      fieldName: "promotionLevy",
      label: "Amount",
      helperText: "",
      columns: 5,
      customComp: CurrencyFormatter,
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          promotionLevy: parseInt(newValue, 10),
        }));
      },
    },
  ];

  const outgoingsFields: FormField[] = [
    {
      type: "text",
      fieldName: "ratePerSquareMetre",
      label: "Rate per square metre",
      helperText: "",
      columns: 6,
      adornment: {
        value: "$",
        position: "start",
      },
      customComp: CurrencyFormatter,
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          ratePerSquareMetre: parseFloat(newValue),
        }));
      },
    },
  ];

  const securityFields: FormField[] = [
    {
      type: "number",
      fieldName: "guaranteeAmount",
      label: "Months",
      helperText: "Mandatory",
      columns: 6,
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          guaranteeAmount: parseFloat(newValue),
        }));
      },
    },
  ];

  const fitoutContributionFields: FormField[] = [
    {
      type: "text",
      fieldName: "fitoutContributionAmount",
      label: "Amount (exc GST)",
      helperText: "",
      columns: 6,
      adornment: {
        value: "$",
        position: "start",
      },
      customComp: CurrencyFormatter,
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          fitoutContributionAmount: parseFloat(newValue),
        }));
      },
    },
  ];

  const cashIncentiveFields: FormField[] = [
    {
      type: "text",
      fieldName: "incentiveAmount",
      label: "Amount (exc GST)",
      helperText: "",
      columns: 6,
      adornment: {
        value: "$",
        position: "start",
      },
      customComp: CurrencyFormatter,
      onBlur: (e) => {
        const newValue = (e.target as HTMLInputElement).value;
        setState((prev) => ({
          ...prev,
          incentiveAmount: parseFloat(newValue),
        }));
      },
    },
  ];

  useGetSpecialConditions((data) => {
    const defaultSpecialCondition = data.TemplateSpecialConditions.map(
      (item) => item.title ?? ""
    );
    const newValue = Array.from(
      new Set([
        ...(props.state.specialConditions ?? []),
        ...defaultSpecialCondition,
      ])
    );
    form.setValue("specialConditions", newValue);
    setState((prev) => ({
      ...prev,
      specialConditions: newValue,
    }));
  });

  const handleToggle =
    (unregisterFields: Parameters<typeof form.unregister>[0][]) =>
    (event: React.MouseEvent<HTMLElement>, newState: string) => {
      if (newState !== null) {
        const target = event.target as HTMLElement;
        setState((prev) => ({ ...prev, [target.id]: newState }));
        if (newState !== "yes") {
          unregisterFields.forEach((item) =>
            form.unregister(item, { keepIsValid: true, keepDefaultValue: true })
          );
        }
      }
    };

  // Get all contacts from tenants, will have to change code below with new table
  const contactsTenants = useQuery(GetYardiContactsByTenantDocument, {
    variables: { tenant_code: props.renewalData?.TenantCode },
  });

  const yardiContacts = contactsTenants.data?.YardiTenant_YardiContacts.map(
    (YardiTenant_YardiContacts) => YardiTenant_YardiContacts.YardiContact
  );
  // All YardiContact have the same YardiTenant. So, store the YardiTenant property of the first element in YardiTenant_YardiContacts array
  const yardiTenantForYardiContacts =
    contactsTenants.data?.YardiTenant_YardiContacts?.[0]?.YardiTenant;

  const states = useGetStateData(1);

  const defaultPhoneNumber = "0400000000";

  const ifEmpty = (value: string | null | undefined, alternate: string) => {
    if (typeof value === "string") {
      if (value === "") {
        return alternate;
      }
      return value;
    }

    if (!value) {
      return alternate;
    }

    return value;
  };

  const validateACN = (acn: string | null | undefined) => {
    if (acn === "" || !acn) {
      return "";
    }

    // remove spaces
    const acnNoSpace = acn.replace(/\s/g, "");
    // is not number
    if (Number.isNaN(Number(acnNoSpace))) {
      return "";
    }

    if (acnNoSpace.length !== 9) {
      return "";
    }

    return acnNoSpace;
  };

  useEffect(() => {
    if (!props.renewalData) {
      return;
    }

    let individualContacts: IndividualTenantType[] = [];
    let companyContacts: CompanyTenantType[] = [];

    if (props.renewalData?.tenantType.value === DealTenantType.Individual) {
      individualContacts =
        yardiContacts?.map((contact) => {
          const phoneNumbers = Object.entries(contact)
            .filter((entry) => entry[0].startsWith("Phone") && !!entry[1])
            .map((entry) => entry[1]);

          const individual: IndividualTenantType = {
            tenant: {
              label: `${contact?.FirstName} ${contact?.LastName}`,
              value: -1,
            },
            label: `${contact?.FirstName} ${contact?.LastName}`,
            id: -1,
            firstName: ifEmpty(contact?.FirstName, "-"),
            middleName: "",
            lastName: ifEmpty(contact?.LastName, "-"),
            email: contact?.Email ?? "",
            phone:
              phoneNumbers?.find((num) => num !== "") ?? defaultPhoneNumber,
            addressId: -1,
            address: `${contact?.Address1 ?? ""} ${contact?.Address2 ?? ""} ${
              contact?.Address3 ?? ""
            }`,
            city: contact?.City ?? "",
            state: {
              label: contact?.State ?? "",
              value:
                states?.data?.States?.find(
                  (state) => state.name === contact?.State
                )?.id ?? 1,
            },
            postCode: contact?.ZipCode ?? "",
          };

          return individual;
        }) ?? [];
    } else if (props.renewalData?.tenantType.value === DealTenantType.Company) {
      companyContacts =
        yardiContacts?.map((contact) => {
          const phoneNumbers = Object.entries(contact)
            .filter(
              (entry) =>
                entry[0].startsWith("Phone") &&
                entry[1] !== null &&
                entry[1] !== ""
            )
            .map((entry) => entry[1]);

          const company: CompanyTenantType = {
            company: {
              label: contact?.CompanyName ?? "",
              value: -1,
            },
            contactSelect: AddNewContactOption,
            acn: validateACN(yardiTenantForYardiContacts!.TenantField2),
            // Put a space there on purpose to make sure the label is not empty
            // I think it messes with deal info screen if this is empty string
            legalName: yardiTenantForYardiContacts!.TenantName ?? " ",
            firstName: ifEmpty(contact?.FirstName, "-"),
            middleName: "",
            lastName: ifEmpty(contact?.LastName, "-"),
            email: contact?.Email ?? "",
            phone:
              phoneNumbers?.find((num) => num !== "") ?? defaultPhoneNumber,
            addressId: -1,
            address: `${contact?.Address1 ?? ""} ${contact?.Address2 ?? ""} ${
              contact?.Address3 ?? ""
            }`,
            city: contact?.City ?? "",
            state: {
              label: contact?.State ?? "",
              value:
                states?.data?.States?.find(
                  (state) => state.name === contact?.State
                )?.id ?? 1,
            },
            postCode: contact?.ZipCode ?? "",
          };

          return company;
        }) ?? [];
    }

    const leaseCommenecementDate = new Date(props.renewalData?.EndDate);
    leaseCommenecementDate.setDate(leaseCommenecementDate.getDate() + 1);

    // Calculation: Promotion levy (pa) / base rent (pa) x 100
    const promoLevy = parseFloat(props.renewalData?.PromoLevy ?? "0");
    const baseRent = parseFloat(props.renewalData?.BaseRent ?? "0");
    const promoLevyPercentage = (promoLevy / baseRent) * 100;

    // Calculation: Outgoings (pa) / unit area
    const outgoingsPerAnum = parseFloat(props.renewalData?.Outgoings ?? "0");
    const area = primaryUnit?.data?.YardiUnits?.[0]?.UnitArea as number;
    const outgoingsPSM = !area ? 0 : outgoingsPerAnum / area;

    setState((prev) => ({
      ...prev,
      tenantInfo: {
        individuals: individualContacts,
        companies: companyContacts,
        tenantCode: props.renewalData?.TenantCode ?? "",
        tradingName: props.renewalData?.TradeName ?? "",
        tradingNameSelect: {
          label: props.renewalData?.TradeName ?? "",
          value: props.renewalData?.CustomerCode ?? "",
        },
        tenantType: {
          label: props.renewalData?.tenantType.label ?? "",
          value: props.renewalData?.tenantType.value ?? -1,
        },
      },
      dealType: DealType.Renewal,
      centre: centreOptions.find(
        (option) => option.value === props.renewalData?.PropertyCode ?? ""
      ) ?? { label: "", value: "" },
      unit: unitOptions.find(
        (option) => option.label === props.renewalData?.mainUnit?.label ?? ""
      ) ?? { label: "", value: "" },
      estimatedArea: primaryUnit?.data?.YardiUnits?.[0]?.UnitArea as string,
      fitoutPeriodWeeks: "0",
      leaseCommenecementDate,
      baseRent: parseFloat(props.renewalData?.BaseRent ?? "0"),
      promotionLevy: promoLevyPercentage,
      ratePerSquareMetre: outgoingsPSM,
      renewal: {
        salesCategory: props.renewalData?.SalesCategory ?? "",
        leaseType: props.renewalData?.LeaseType ?? "",
      },
    }));
    form.reset(props.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.renewalData,
    props.state.unit,
    form,
    primaryUnit,
    outgoings,
    centreOptions,
    unitOptions,
    contactsTenants,
    states,
  ]);

  return (
    <>
      <Box width="100%" />
      <LocationSelect
        form={form}
        onCentreChange={(e, data) => {
          setState((prev) => ({
            ...prev,
            unit: { label: "", value: "" },
            estimatedArea: "",
            centre: {
              label: data.label,
              value: data.value as string,
            },
          }));
        }}
        onUnitChange={(e, data) => {
          const estimatedAreaStr = getEstimatedArea(data, virtualUnits, units);
          setState((prev) => ({
            ...prev,
            estimatedArea: estimatedAreaStr,
            unit: {
              label: data.label,
              value: data.value,
            },
          }));
        }}
        onVirtualUnitTypeChange={(e, data) => {
          setState((prev) => ({
            ...prev,
            unitType: {
              label: data.label,
              value: data.value as string,
            },
          }));
        }}
        onEstimatedAreaBlur={(e) => {
          const newValue = (e.target as HTMLInputElement).value;
          setState((prev) => ({
            ...prev,
            estimatedArea: newValue,
          }));
        }}
        onVirtualUnitNameBlur={(e) => {
          const newValue = (e.target as HTMLInputElement).value;
          setState((prev) => ({
            ...prev,
            virtualUnit: newValue,
          }));
        }}
        onVirtualUnitDescriptionChange={(e, data) => {
          const newValue = (e.target as HTMLInputElement).value;
          setState((prev) => ({
            ...prev,
            virtualUnitDescription: newValue,
          }));
        }}
      />

      <Box width="100%" />
      <Grid item xs={12} sm={6} p={1} key="leaseCommenecementDate">
        <InputLabel>Lease commencement date</InputLabel>

        <Controller
          control={form.control}
          name="leaseCommenecementDate"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DatePicker
              onChange={(datePickValue: Date | null) => {
                setState((prev) => ({
                  ...prev,
                  // If null return invalid date
                  leaseCommenecementDate: datePickValue ?? new Date(""),
                }));
                onChange(datePickValue);
              }}
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  error={error !== undefined}
                  helperText={error?.message ?? "Mandatory"}
                  id="leaseCommenecementDate"
                  margin="dense"
                  fullWidth
                  size="small"
                  hiddenLabel
                  color="primary"
                  autoComplete="bday"
                />
              )}
            />
          )}
        />
      </Grid>
      <Form form={form} fields={fitoutFields} />
      <Form form={form} fields={termFields} />
      <Box width="100%" />
      <Form form={form} fields={[baseRentField]} />
      <Box width="100%" />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Rent review
        </Typography>
      </Grid>
      <Box width="100%" />
      <Form form={form} fields={[rentReviewField]} />
      <Box width="100%" />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Turnover rent
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={2} p={1}>
        <ToggleButton
          name="turnoverRentToggle"
          options={toggleOptions}
          state={props.state.turnoverRentToggle}
          handleState={handleToggle(
            turnoverFields.map((item) => item.fieldName)
          )}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      {props.state.turnoverRentToggle === "yes" && (
        <Form form={form} fields={turnoverFields} />
      )}
      <Box width="100%" mb={2} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Promotion levy
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={2} p={1}>
        <ToggleButton
          name="promotionLevyToggle"
          options={toggleOptions}
          state={props.state.promotionLevyToggle}
          handleState={handleToggle(
            promotionLevyFields.map((item) => item.fieldName)
          )}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      {props.state.promotionLevyToggle === "yes" && (
        <Form form={form} fields={promotionLevyFields} />
      )}
      <Box width="100%" mb={2} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Outgoings
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={2} p={1}>
        <ToggleButton
          name="outgoingsToggle"
          options={toggleOptions}
          state={props.state.outgoingsToggle}
          handleState={handleToggle(
            outgoingsFields.map((item) => item.fieldName)
          )}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      {props.state.outgoingsToggle === "yes" && (
        <Form form={form} fields={outgoingsFields} />
      )}
      <Box width="100%" mb={2} />
      <Grid item sm={6} p={1}>
        <PermittedUsage {...props} />
      </Grid>
      <Box width="100%" mb={2} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Security
        </Typography>
      </Grid>
      <Box width="100%" />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Bank guarantee
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={2} p={1}>
        <ToggleButton
          name="bankGuaranteeToggle"
          options={toggleOptions}
          state={props.state.bankGuaranteeToggle}
          handleState={handleToggle(["guaranteeAmount"])}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      {props.state.bankGuaranteeToggle === "yes" ? (
        <>
          <Form form={form} fields={securityFields} />
          <Box width="100%" mb={1} />
        </>
      ) : null}
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Guarantors
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={2} p={1}>
        <ToggleButton
          name="guarantorsToggle"
          options={toggleOptions}
          state={props.state.guarantorsToggle}
          handleState={handleToggle([])}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      <RentalDepositSwitch {...props} />
      <Box width="100%" mb={1} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Landlord works
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Box width="100%" />
      <LandlordWorksSwitch {...props} />
      <Box width="100%" />
      {props.state.landlordWorksToggle && <LandlordWorks {...props} />}
      <Box width="100%" mb={1} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Tenant works
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={12}>
        <FormSwitch
          name="tenantWorksToggle"
          title="Tenant Works"
          control={form.control}
          onChange={(e) => {
            const { checked } = e.target as HTMLInputElement;
            setState((prev) => ({
              ...prev,
              tenantWorksToggle: checked,
            }));
            if (!checked) {
              form.setValue("tenantWorks", []);
              form.setValue("fitoutDescription", "");
              setState((prev) => ({
                ...prev,
                tenantWorks: [],
              }));
            }
          }}
        />
      </Grid>
      <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
        <CheckboxForm
          fieldName="tenantWorks"
          control={form.control}
          choices={TENANT_WORK_CHOICES}
          disable={!form.watch("tenantWorksToggle")}
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              tenantWorks: form.getValues("tenantWorks"),
            }));
          }}
        />
      </Grid>
      <Box width="100%" mb={1} />
      <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
        <EditorControlled
          name="fitoutDescription"
          label="Fitout description"
          control={form.control}
          disable={!form.watch("tenantWorksToggle")}
          onChange={(data) => {
            setState((prev) => ({
              ...prev,
              fitoutDescription: data ?? "",
            }));
          }}
        />
      </Grid>
      <Box width="100%" mb={1} />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Fitout contribution
        </Typography>
      </Grid>
      <Grid item sm={9}>
        <Box width="100%" />
      </Grid>
      <Form form={form} fields={fitoutContributionFields} />
      <Box width="100%" />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Cash Incentive
        </Typography>
      </Grid>
      <Grid item sm={9}>
        <Box width="100%" />
      </Grid>
      <Form form={form} fields={cashIncentiveFields} />
      <Box width="100%" />
      {/* For Hoarding and Skins toggle, 4 options overflows the standard 3 columns so adjusted subtitle to 2 columns and toggle to 4 columns */}
      <Grid item sm={2}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Hoarding
        </Typography>
      </Grid>
      {/* Toggle: 3 columns if 3 toggle options (New Deal), 4 columns if 4 toggle options (Renewal) */}
      <Grid item sm={4} p={1}>
        <ToggleButton
          options={hoardingAndSkinsOptions}
          name="hoardingToggle"
          state={props.state.hoardingToggle}
          handleState={handleToggle([])}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      <Grid item sm={2}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Skins
        </Typography>
      </Grid>
      <Grid item sm={4} p={1}>
        <ToggleButton
          options={hoardingAndSkinsOptions}
          name="skinsToggle"
          state={props.state.skinsToggle}
          handleState={handleToggle([])}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid>
      <Box width="100%" />
      <Grid item sm={3}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Tenancy coordination
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Box width="100%" />
      </Grid>
      {/* <Grid item sm={2} p={1}>
        <ToggleButton
          name="tenancyCoordinationFeeToggle"
          options={toggleOptions}
          state={props.state.tenancyCoordinationFeeToggle}
          handleState={handleToggle([])}
          buttonGroupExtraSx={{ height: 30 }}
          buttonExtraSx={{ fontSize: 12 }}
        />
      </Grid> */}
      <Grid item sm={12}>
        <FormSwitch
          name="tenancyCoordinationFeeToggle"
          title="Tenancy Coordination fee"
          control={form.control}
          onChange={(e) => {
            const { checked } = e.target as HTMLInputElement;
            setState((prev) => ({
              ...prev,
              tenancyCoordinationFeeToggle: checked,
            }));
          }}
        />
      </Grid>
      <Box width="100%" />
      {/* TODO: replace this to have an array of ids mapped to special condition template in DB 
      Currently uses title */}
      <FormChecklist
        title="Special conditions"
        parentProps={props}
        formChecklistName="specialConditions"
        selectedFormChecklistName="selectedSpecialConditions"
      />
      {failCreateData && (
        <>
          <Box width="100%" />
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setFailCreateData(false);
                }}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Something failed when saving data, please try again soon or contact
            support.
          </Alert>
        </>
      )}
      <Box width="100%" />
      <Grid item p={1}>
        <Button
          variant="contained"
          color="primary"
          disabled={form.formState.isSubmitting}
          onClick={form.handleSubmit(async () => {
            try {
              await props.submitHandler();
            } catch (e) {
              setFailCreateData(true);
            }
          })}
        >
          Submit
        </Button>
      </Grid>
      <Grid item p={1}>
        <Button variant="outlined" color="primary" href="/">
          Cancel
        </Button>
      </Grid>
    </>
  );
};
