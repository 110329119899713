import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";

import { BreadCrumbs } from "../components";
import { SearchDocsComp } from "../components/SearchDocs/SearchDocs";
import { FeatureFlagsContext } from "../contexts/FeatureFlags";

export const SearchDocs = () => {
  const featureFlags = useContext(FeatureFlagsContext);

  if (!featureFlags?.features.isAiEnabled) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h6">Search documents</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Search documents", url: "" },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <SearchDocsComp />
      </Grid>
    </Grid>
  );
};
