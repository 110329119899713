import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { Form, FormField, IformFieldChoice } from "../../Form/Form";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import {
  transformData,
  useGetDealAnalystData,
  useGetDealTypes,
} from "../../../adapters/DealInformation/DealType/DealType";
import { Alert } from "../../Alert";

export const DealType = (props: DealInformationSectionProps) => {
  const dealTypes = useGetDealTypes();
  const analystData = useGetDealAnalystData(props.dealId);

  const { form, setDataState } = props;

  useEffect(() => {
    if (analystData.data) {
      const data = transformData(analystData.data);
      form.setValue("dealType", data);
      setDataState((prev) => ({ ...prev, dealTypeLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, dealTypeLoaded: false }));
    }
  }, [analystData, form, setDataState]);

  if (analystData.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!analystData.data) {
    return <Skeleton />;
  }

  const dealTypeChoices: IformFieldChoice[] =
    dealTypes.data?.DealTypes?.map((item) => ({
      label: item?.title ?? "",
      value: item?.id ?? -1,
    })) ?? [];

  const dealTypeField: FormField = {
    type: "select",
    fieldName: "dealType.dealType",
    label: "Deal type",
    helperText: "Mandatory",
    columns: 6,
    choices: dealTypeChoices,
  };

  const fields: FormField[] = [
    {
      type: "text",
      fieldName: "dealType.dealComp",
      label: "Deal comp (exc GST)",
      helperText: "Analyst only",
      columns: 6,
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
    },
    {
      type: "text",
      fieldName: "dealType.normalisedBaseRent",
      label: "Normalised base rent (exc GST)",
      helperText: "Analyst only",
      columns: 6,
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
    },
    {
      type: "text",
      fieldName: "dealType.dealReason",
      label: "Deal reason",
      helperText: "Analyst only",
      columns: 6,
    },
    {
      type: "text",
      fieldName: "dealType.normalisedPromo",
      label: "Normalised promo (exc GST)",
      helperText: "Analyst only",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
  ];

  return (
    <>
      <Form form={props.form} fields={[dealTypeField]} />
      <Form form={props.form} fields={fields} />
    </>
  );
};
