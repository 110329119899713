import React from "react";
import { Typography } from "@mui/material";

interface TypoProps {
  children: React.ReactNode;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
}

export const Title = (props: TypoProps) => {
  return <Typography variant={props.variant}>{props.children}</Typography>;
};
