import { useQuery, useMutation } from "@apollo/client";
import {
  GetDealInformationTenantInformationDocument,
  GetDealInformationTenantInformationQuery,
  UpdateDealTenantTypeDocument,
  UpdateDealDataDocument,
  AddCompanyDocument,
  AddDealsContactDocument,
  AddContactDocument,
  AddDealCompanyDocument,
  DeleteDealsCompaniesDocument,
  UpdateDealTradingNameDocument,
  GetTradingNamesDocument,
  GetDealContactsDocument,
  DeleteDealsTenantContactsDocument,
  UpdateDealCustomerDocument,
  SalesType_Enum,
  GetCompaniesDocument,
} from "../../../generated/graphql-operations";
import {
  CompanyTenantType,
  IndividualTenantType,
  TenantInformationType,
} from "../../../components/DealInformation/TenantInformation/TenantInformationConfig";
import {
  AddNewContactOption,
  ContactType,
  CONTACT_TENANT_TYPE_COMPANY,
  DealTenantType,
} from "../../../utils/CommonVariables";
import {
  useCreateLocation,
  useUpdateLocation,
} from "../../Contact/Locations/Locations";

export const useGetDealInformationTenantInformation = (dealId: number) => {
  const tenantInformation = useQuery(
    GetDealInformationTenantInformationDocument,
    {
      variables: {
        deal_id: dealId,
      },
    }
  );

  return tenantInformation;
};

export const transformData = (
  data: GetDealInformationTenantInformationQuery
): TenantInformationType => {
  const individuals =
    data?.Deals_by_pk?.DealsContacts?.map((dealContact) => {
      const individual: IndividualTenantType = {
        tenant: {
          label: `${dealContact?.Contact?.first_name} ${
            dealContact?.Contact?.middle_name ?? ""
          } ${dealContact?.Contact?.last_name}`,
          value: dealContact?.Contact?.id ?? -1,
        },
        id: dealContact?.Contact?.id ?? -1,
        label: `${dealContact?.Contact?.first_name} ${
          dealContact?.Contact?.middle_name ?? ""
        } ${dealContact?.Contact?.last_name}`,
        email: dealContact?.Contact?.email ?? "",
        phone: dealContact?.Contact?.phone ?? "",
        firstName: dealContact?.Contact?.first_name ?? "",
        middleName: dealContact?.Contact?.middle_name ?? "",
        lastName: dealContact?.Contact?.last_name ?? "",
        address: dealContact?.Contact?.Location?.street1 ?? "",
        city: dealContact?.Contact?.Location?.suburb ?? "",
        state: {
          label: dealContact?.Contact?.Location?.State?.name ?? "",
          value: dealContact?.Contact?.Location?.State?.id ?? -1,
        },
        postCode: dealContact?.Contact?.Location?.postcode?.toString() ?? "",
        addressId: dealContact?.Contact?.Location?.id ?? -1,
      };

      return individual;
    }) ?? [];

  const companies =
    data?.Deals_by_pk?.DealsCompanies?.map((dealCompany) => {
      const company: CompanyTenantType = {
        company: {
          label: dealCompany?.Company?.legal_name ?? "",
          value: dealCompany?.Company?.id ?? -1,
        },
        legalName: dealCompany?.Company?.legal_name ?? "",
        acn: dealCompany?.Company?.acn ?? "",
        address: dealCompany?.Company?.Location?.street1 ?? "",
        city: dealCompany?.Company?.Location?.suburb ?? "",
        state: {
          label: dealCompany?.Company?.Location?.State?.name ?? "",
          value: dealCompany?.Company?.Location?.State?.id ?? 1,
        },
        postCode: dealCompany?.Company?.Location?.postcode?.toString() ?? "",
        addressId: dealCompany?.Company?.Location?.id ?? -1,
        contactSelect: {
          label: `${dealCompany?.Contact?.first_name} ${dealCompany?.Contact?.last_name}`,
          value: dealCompany?.Contact?.id ?? -1,
        },
        firstName: dealCompany?.Contact?.first_name ?? "",
        middleName: dealCompany?.Contact?.middle_name ?? "",
        lastName: dealCompany?.Contact?.last_name ?? "",
        email: dealCompany?.Contact?.email ?? "",
        phone: dealCompany?.Contact?.phone ?? "",
      };

      return company;
    }) ?? [];

  const tenantTypeId = data?.Deals_by_pk?.tenant_type_id ?? 1;
  const tenantType = {
    value: tenantTypeId,
    label:
      tenantTypeId === DealTenantType.Individual ? "Individual" : "Company",
  };

  const tradingName = data?.Deals_by_pk?.trading_name ?? "";
  const tradingNameCode = data?.Deals_by_pk?.customer_code ?? "";
  const customer = {
    label: data?.Deals_by_pk?.YardiCustomer?.CustomerName ?? "",
    value: data?.Deals_by_pk?.YardiCustomer?.CustomerCode ?? "",
  };
  const salesCategory = {
    label: data?.Deals_by_pk?.DealData?.[0]?.sales_category ?? "",
    value: data?.Deals_by_pk?.DealData?.[0]?.sales_category ?? "",
  };
  const salesType = {
    label: data?.Deals_by_pk?.DealData?.[0]?.sales_type ?? "",
    value: data?.Deals_by_pk?.DealData?.[0]?.sales_type ?? "",
  };
  const leaseType = {
    label: data?.Deals_by_pk?.DealData?.[0]?.lease_type ?? "",
    value: data?.Deals_by_pk?.DealData?.[0]?.lease_type ?? "",
  };
  const chargeCode = data?.Deals_by_pk?.DealData?.[0]?.charge_code;

  let noticeInformation = {
    noticeAddressId: -1,
    noticeAddress: "",
    noticeCity: "",
    noticePostCode: "",
    noticeState: { label: "", value: 1 },
    noticeEmail: "",
  };

  if (tenantType.value === DealTenantType.Individual) {
    noticeInformation = {
      noticeAddressId:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.id ??
        noticeInformation.noticeAddressId,
      noticeAddress:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.street1 ??
        individuals[0]?.address ??
        noticeInformation.noticeAddress,
      noticeCity:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.suburb ??
        individuals[0]?.city ??
        noticeInformation.noticeCity,
      noticePostCode:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.postcode?.toString() ??
        individuals[0]?.postCode ??
        noticeInformation.noticePostCode,
      noticeState: {
        label:
          data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.State?.name ??
          individuals[0]?.state?.label ??
          noticeInformation.noticeState?.label,
        value:
          data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.State?.id ??
          individuals[0]?.state?.value ??
          noticeInformation.noticeState?.value,
      },
      noticeEmail:
        data?.Deals_by_pk?.DealData?.[0]?.notice_email ??
        individuals[0]?.email ??
        noticeInformation.noticeEmail,
    };
  } else if (tenantType.value === DealTenantType.Company) {
    noticeInformation = {
      noticeAddressId:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.id ??
        noticeInformation.noticeAddressId,
      noticeAddress:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.street1 ??
        companies[0]?.address ??
        noticeInformation.noticeAddress,
      noticeCity:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.suburb ??
        companies[0]?.city ??
        noticeInformation.noticeCity,
      noticePostCode:
        data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.postcode?.toString() ??
        companies[0]?.postCode ??
        noticeInformation.noticePostCode,
      noticeState: {
        label:
          data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.State?.name ??
          companies[0]?.state?.label ??
          noticeInformation.noticeState?.label,
        value:
          data?.Deals_by_pk?.DealData?.[0]?.NoticeServingAddress?.State?.id ??
          companies[0]?.state?.value ??
          noticeInformation.noticeState?.value,
      },
      noticeEmail:
        data?.Deals_by_pk?.DealData?.[0]?.notice_email ??
        companies[0]?.email ??
        noticeInformation.noticeEmail,
    };
  }

  return {
    tenantType,
    tradingName,
    individuals,
    companies,
    customer,
    noticeAddressId: noticeInformation.noticeAddressId,
    noticeAddress: noticeInformation.noticeAddress,
    noticeCity: noticeInformation.noticeCity,
    noticePostCode: noticeInformation.noticePostCode,
    noticeState: noticeInformation.noticeState,
    noticeEmail: noticeInformation.noticeEmail,
    tradingNameSelect: { label: tradingName, value: tradingNameCode },
    salesCategory,
    chargeCode,
    salesType,
    leaseType,
  };
};

export const useMutateTenantInformation = (dealId: number) => {
  const [UpdateDealTenantType] = useMutation(UpdateDealTenantTypeDocument);
  const [UpdateDealCustomer] = useMutation(UpdateDealCustomerDocument);
  const [UpdateDealData] = useMutation(UpdateDealDataDocument);
  const [AddCompany] = useMutation(AddCompanyDocument);
  const [AddDealContacts] = useMutation(AddDealsContactDocument);
  const [AddDealCompany] = useMutation(AddDealCompanyDocument);
  const [AddContact] = useMutation(AddContactDocument);
  const [DeleteDealsTenantContacts] = useMutation(
    DeleteDealsTenantContactsDocument
  );
  const [DeleteDealsCompanies] = useMutation(DeleteDealsCompaniesDocument);
  const [UpdateDealTradingName] = useMutation(UpdateDealTradingNameDocument);
  const createLocation = useCreateLocation();
  const updateLocation = useUpdateLocation();

  const createDealContactsIndividuals = async (data: TenantInformationType) => {
    await Promise.all(
      data.individuals.map(async (tenant) => {
        let tenantId = tenant.id;

        const locationData = {
          street1: tenant.address,
          street2: "",
          suburb: tenant.city,
          state_id: tenant.state?.value,
          postcode: parseInt(tenant.postCode ?? "", 10) || null,
        };

        const tenantData = {
          phone: tenant.phone,
          email: tenant.email,
          trading_name: tenant.tradingName,
          first_name: tenant.firstName,
          middle_name: tenant.middleName,
          last_name: tenant.lastName,
          address_id: tenant.addressId,
        };

        if (tenantId === -1) {
          const location = await createLocation(locationData);

          const addressId = location.data?.insert_Locations_one?.id;

          const newTenant = {
            ...tenantData,
            address_id: addressId,
          };

          const newContact = await AddContact({
            variables: { Contacts: newTenant },
          });

          tenantId =
            newContact.data?.insert_Contacts?.returning[0].id ?? tenantId;
        }

        const dealContact = {
          contact_id: tenantId,
          deal_id: dealId,
          contact_type: ContactType.Tenant,
          tag: "Tenant",
        };

        await AddDealContacts({
          variables: { dealContacts: dealContact },
          refetchQueries: [GetDealContactsDocument],
        });
      })
    );
  };

  const createDealContactsCompanies = async (data: TenantInformationType) => {
    await Promise.all(
      data.companies.map(async (company) => {
        let companyId = company.company.value;
        let { addressId } = company;

        const locationData = {
          street1: company.address,
          street2: "",
          suburb: company.city,
          state_id: company.state?.value,
          postcode: parseInt(company.postCode ?? "", 10) || null,
        };

        if (addressId === -1) {
          const location = await createLocation(locationData);
          addressId = location.data?.insert_Locations_one?.id ?? addressId;
        }

        const companyData = {
          trading_name: company.tradingName,
          legal_name: company.legalName,
          acn: company.acn || null,
          email: company.email,
          phone: company.phone,
          address_id: addressId,
        };

        if (companyId === -1) {
          const newCompany = await AddCompany({
            variables: { Company: companyData },
          });

          companyId =
            newCompany.data?.insert_Companies?.returning[0].id ?? companyId;
        }

        let contactId = company.contactSelect.value;

        if (contactId === AddNewContactOption.value) {
          // We need to create a contact

          const contactLocation = {
            street1: company.address,
            street2: "",
            suburb: company.city,
            state_id: company.state?.value,
            postcode: parseInt(company.postCode ?? "", 10) || null,
          };

          const location = await createLocation(contactLocation);

          const newContact = await AddContact({
            variables: {
              Contacts: {
                first_name: company.firstName,
                middle_name: company.middleName,
                last_name: company.lastName,
                legal_name: `${company.firstName} ${company.lastName}`,
                email: company.email,
                phone: company.phone,
                company_id: companyId,
                address_id: location.data?.insert_Locations_one?.id,
              },
            },
          });

          contactId = newContact?.data?.insert_Contacts?.returning?.[0]?.id;
        }

        // Update main company contact

        await AddDealCompany({
          variables: {
            data: {
              deal_id: dealId,
              company_id: companyId,
              contact_id: contactId,
            },
          },
          refetchQueries: [GetCompaniesDocument],
        });

        const dealContact = {
          contact_id: contactId,
          deal_id: dealId,
          contact_type: ContactType.Tenant,
          tag: CONTACT_TENANT_TYPE_COMPANY,
        };

        await AddDealContacts({
          variables: {
            dealContacts: dealContact,
          },
          refetchQueries: [GetDealContactsDocument],
        });
      })
    );
  };

  return async (data: TenantInformationType) => {
    await UpdateDealTenantType({
      variables: {
        deal_id: dealId,
        tenant_type: data.tenantType.value,
      },
    });

    if (data.tenantType.value === DealTenantType.Individual) {
      await DeleteDealsCompanies({
        variables: {
          deal_id: dealId,
        },
      });

      await DeleteDealsTenantContacts({
        variables: {
          deal_id: dealId,
        },
      });

      await createDealContactsIndividuals(data);
    }

    if (data.tenantType.value === DealTenantType.Company) {
      await DeleteDealsTenantContacts({
        variables: {
          deal_id: dealId,
        },
      });

      await DeleteDealsCompanies({
        variables: {
          deal_id: dealId,
        },
      });

      await createDealContactsCompanies(data);
    }

    let { noticeAddressId } = data;

    const locationData = {
      street1: data.noticeAddress,
      street2: "",
      suburb: data.noticeCity,
      state_id: data.noticeState.value,
      postcode: parseInt(data.noticePostCode ?? "", 10) || undefined,
    };

    if (noticeAddressId === -1) {
      const newAddress = await createLocation(locationData);
      noticeAddressId =
        newAddress.data?.insert_Locations_one?.id ?? data.noticeAddressId;
    } else {
      await updateLocation(noticeAddressId, locationData);
    }

    await UpdateDealTradingName({
      variables: {
        deal_id: dealId,
        trading_name: data.tradingName,
        customer_code:
          data.tradingNameSelect.value === ""
            ? null
            : data.tradingNameSelect.value,
      },
      refetchQueries: [GetTradingNamesDocument],
    });

    await UpdateDealCustomer({
      variables: {
        deal_id: dealId,
        customer_code: data.customer.value === "" ? null : data?.customer.value,
      },
    });

    await UpdateDealData({
      variables: {
        deal_id: dealId,
        deal_data: {
          notice_email: data.noticeEmail,
          notice_address_id: noticeAddressId,
          sales_category: data.salesCategory.value,
          sales_type:
            data.salesType.value === ""
              ? null
              : (data.salesType.value as SalesType_Enum),
          lease_type: data.leaseType.value,
          charge_code: data.chargeCode,
        },
      },
      refetchQueries: [GetDealInformationTenantInformationDocument],
    });
  };
};
