import React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  UpdateDealPhaseDocument,
  GetDealDocument,
  UpdateDealStatusDocument,
  DealStatus_Enum,
  GetFirstTdPhaseDocument,
} from "../../generated/graphql-operations";

import {
  DealPhaseStatus,
  LAST_PHASE_NAME,
  TENANCY_DELIVERY_PHASE_NAME,
} from "../../utils/CommonVariables";
import { apolloClient } from "../../contexts/ApolloConfig";

export interface ActionItem {
  id: number;
  text: string;
  state: boolean;
  userDisabled: boolean;
  required: boolean;
  assigned_to: string[];
}

export interface DealPhase {
  dealId?: number;
  id: number;
  statusID?: number | null;
  name?: string | null;
  actionItems: ActionItem[];
  isTenancyDelivery?: boolean;
}

export interface DealPushValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const DealStatusToString = (dealStatus: DealPhaseStatus) => {
  return DealPhaseStatus[dealStatus];
};

export const validateActionList = (items: ActionItem[]) => {
  return items.filter((item) => item.required).every((item) => item.state);
};

export const useUpdateAndSaveData = (
  phases: DealPhase[],
  setValidation: React.Dispatch<React.SetStateAction<DealPushValidationProps>>
) => {
  const [updateDealPhase] = useMutation(UpdateDealPhaseDocument);
  const [updateDealStatus] = useMutation(UpdateDealStatusDocument);
  const [getFirstTDPhase] = useLazyQuery(GetFirstTdPhaseDocument);

  const handleSave = async () => {
    const invalidPhases = phases.filter(
      (phase) =>
        phase.statusID === DealPhaseStatus.Complete &&
        !validateActionList(phase.actionItems)
    );

    if (invalidPhases.length > 0) {
      const invalidPhasesName = invalidPhases
        .map((phase) => phase.name)
        .join(", ");
      setValidation({
        show: true,
        severity: "error",
        message: `${invalidPhasesName} cannot be set to Complete because it has unfinished required actions.`,
      });
      return;
    }

    phases.forEach(async (phase) => {
      // In case deal is pushed back from Registration
      // we want to change the deal status back to Approved
      if (
        phase.name === LAST_PHASE_NAME &&
        phase.statusID === DealPhaseStatus.Active
      ) {
        await updateDealStatus({
          variables: {
            dealId: phase?.dealId,
            status: DealStatus_Enum.Approved,
            filter: {
              DealPhases: { status_id: { _eq: DealPhaseStatus.Complete } },
            },
          },
          refetchQueries: [GetDealDocument],
        });
      }

      // If tenancy Delivery also active first tenancy phase
      if (
        phase?.name === TENANCY_DELIVERY_PHASE_NAME &&
        phase.statusID === DealPhaseStatus.Active
      ) {
        const tdFirstPhase = await getFirstTDPhase({
          variables: { deal_id: phase?.dealId },
        });

        if (
          tdFirstPhase?.data?.DealPhases?.[0]?.PhaseDealStatus?.id ===
          DealPhaseStatus.Inactive
        ) {
          await updateDealPhase({
            variables: {
              id: tdFirstPhase?.data?.DealPhases?.[0]?.id,
              status_id: DealPhaseStatus.Active,
            },
          });
        }
      }

      await updateDealPhase({
        variables: {
          id: phase.id,
          status_id: phase.statusID as number,
        },
        refetchQueries: [GetDealDocument],
      });
    });

    await apolloClient.refetchQueries({ include: [GetDealDocument] });

    setValidation({
      show: true,
      severity: "success",
      message: "Saved successfully",
    });
  };

  return [handleSave] as const;
};
