import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { DrawerHeader, drawerWidth } from "./common";

interface BodyContainerProps {
  open: boolean;
  children: React.ReactNode;
}

const BodyContainer = ({ open, children }: BodyContainerProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        }),
      }}
    >
      <DrawerHeader />
      {children}
    </Box>
  );
};

export default BodyContainer;
