import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const drawerWidth = 230;

export const DrawerHeader = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
      }}
    >
      {children}
    </Box>
  );
};
