import React from "react";
import { useQuery } from "@apollo/client";
import { Add, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { GetPropertiesDocument } from "../../generated/graphql-operations";
import { IformFieldChoice } from "../Form/Form";
import { Button } from "../Button";
import { CreateButton } from "../CreateButton";
import { Setting } from "./Setting";
import { ApproverType } from "./Config";

interface ApproverProps {
  index: number;
  form: UseFormReturn<any>;
  users: IformFieldChoice[];
}

export const allPropertiesOption = {
  label: "All properties",
  value: "",
};

export const Approver = (props: ApproverProps) => {
  const centres = useQuery(GetPropertiesDocument);

  const selectedCentres = props.form
    .watch(`approver_${props.index}`)
    ?.flatMap((setting: ApproverType) => setting?.properties)
    .map((property: IformFieldChoice) => property?.value);

  const settingFieldArray = useFieldArray({
    name: `approver_${props.index}`,
    control: props.form.control,
  });

  let availableCentres =
    centres.data?.Properties?.filter(
      (elem) => !selectedCentres?.includes(elem.property_code)
    ).map(
      (elem) =>
        ({
          label: elem.label,
          value: elem.property_code,
        } as IformFieldChoice)
    ) ?? [];

  availableCentres = [allPropertiesOption, ...availableCentres];

  if (selectedCentres?.includes(allPropertiesOption.value)) {
    availableCentres = [];
  } else if (
    selectedCentres?.length > 0 &&
    !selectedCentres?.includes(allPropertiesOption.value)
  ) {
    availableCentres = availableCentres.filter(
      (elem) => elem.value !== allPropertiesOption.value
    );
  }

  return (
    <>
      {settingFieldArray.fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Grid item sm={12} p={1}>
            <Setting
              index={index}
              parentIndex={props.index}
              form={props.form}
              users={props.users}
              centres={availableCentres}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item p={1}>
              <Button
                startIcon={<Delete />}
                color="error"
                variant="text"
                onClick={() => {
                  settingFieldArray.remove(index);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Grid item p={1}>
        <CreateButton
          startIcon={<Add />}
          color="primary"
          variant="text"
          onClick={() => {
            settingFieldArray.append({ user: [], properties: [] });
          }}
        >
          Add setting
        </CreateButton>
      </Grid>
    </>
  );
};
