import React from "react";
import { useMutation } from "@apollo/client";
import { BlobServiceClient } from "@azure/storage-blob";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CreateTemplateDocument,
  GetDocumentTemplatesDocument,
} from "../../generated/graphql-operations";
import { ValidationProps } from "../../components/Deal/SummaryTab/Files/Config";

import { BrowserCredential } from "../../contexts/Auth/AzureBrowserAuth";
import {
  BLOB_CLIENT_URL,
  TEMPLATE_CONTAINER_NAME,
} from "../../utils/CommonVariables";
import { TemplateUploadDialogType } from "../../components/Template/TemplateUploadDialog/Config";

// Need this default otherwise will crash the backend service mounting to this
// storage container it is uploading too, i.e  metadata empty string "" crashes
// the mounting.
const DEFAULTMETADATA = "None";

export type BlobVersions = {
  id: string;
  date: Date;
  name: string;
  information: string;
  modifiedBy: string;
  fileName: string;
}[];

export const useCreateTemplateSubmit = (
  setValidation: React.Dispatch<React.SetStateAction<ValidationProps>>,
  selectedFile: File | undefined,
  isNewTemplate: boolean
) => {
  const [createTemplate] = useMutation(CreateTemplateDocument);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  return async (data: TemplateUploadDialogType) => {
    // FIXME: Surely this shouldn't be handled here
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      setValidation({
        show: true,
        severity: "error",
        message: "Not Authenticated, please log out and log back in",
      });
      return;
    }

    if (selectedFile === undefined) {
      setValidation({
        show: true,
        severity: "error",
        message: "No file selected",
      });
      return;
    }

    // Remove the extension from the filename and store it
    const path = data.fileName?.split(".");
    const extension =
      path?.length === undefined || path?.length <= 1 ? undefined : path?.pop();
    const fileName = path?.join(".");

    const blobServiceClient = new BlobServiceClient(
      BLOB_CLIENT_URL,
      new BrowserCredential(instance, isAuthenticated, inProgress)
    );

    // Azure blobs make / into new folders which we really don't want. So replace with :
    const templateName = data.generateToName.replaceAll("/", ":");
    // templates are always docx files
    const pathName = `${templateName}.docx`;
    const blockBlobClient = blobServiceClient
      .getContainerClient(TEMPLATE_CONTAINER_NAME)
      .getBlockBlobClient(pathName);

    await blockBlobClient.upload(
      selectedFile,
      selectedFile?.arrayBuffer.length,
      {
        metadata: {
          name: data.generateToName || DEFAULTMETADATA,
          information: data.information || DEFAULTMETADATA,
          modified_by:
            instance.getActiveAccount()?.localAccountId || DEFAULTMETADATA,
          file_name: fileName || DEFAULTMETADATA,
          extension: extension || DEFAULTMETADATA,
        },
      }
    );

    if (!isNewTemplate) return;

    await createTemplate({
      variables: {
        objects: {
          event_trigger_name: "terms",
          generate_to_name: data.generateToName,
          information: data.information,
          convert_to_pdf: data.convertToPdf,
          hidden: data.hidden,
          // FIXME: is this being used anywhere?
          template_path: "",
          template_name: templateName,
          deleted: false,
        },
      },
      refetchQueries: [GetDocumentTemplatesDocument],
      onCompleted: () =>
        setValidation({
          show: true,
          severity: "success",
          message: "Success - template successfully uploaded",
        }),
      onError: (error) => {
        let message =
          "Error - problem uploading template file. Please refresh and try again";
        if (
          error.graphQLErrors[0]?.extensions?.code === "constraint-violation"
        ) {
          message = `Error - Template with name ${data.generateToName} already exists`;
        }
        setValidation({
          show: true,
          severity: "error",
          message,
        });
        blockBlobClient.deleteIfExists();
      },
    });
  };
};

export const useGetBlobList = (filePath: string | undefined) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [blobs, setBlobs] = React.useState<BlobVersions>([]);
  const clearBlobCache = () => setBlobs([]);

  // Because {} !== {} it forces state to be reloaded
  const [forceReloadValue, setForceReload] = React.useState({});
  const forceReload = () => setForceReload({});

  React.useEffect(() => {
    if (filePath && isAuthenticated && inProgress === InteractionStatus.None) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );
      const getSetBlobs = async () => {
        const listBlobs = blobServiceClient
          .getContainerClient(TEMPLATE_CONTAINER_NAME)
          .listBlobsFlat({
            prefix: filePath,
            includeVersions: true,
            includeMetadata: true,
          });

        const tempBlobList: BlobVersions = [];
        for await (const item of listBlobs) {
          if (item.versionId && item.metadata) {
            tempBlobList.push({
              id: item.versionId,
              date: new Date(item.properties.createdOn ?? ""),
              name: item.metadata.name ?? "",
              information: item.metadata.information ?? "",
              modifiedBy: item.metadata.modified_by ?? "",
              fileName: item.metadata.file_name ?? "",
            });
          }
        }
        tempBlobList.sort((a, b) => b.date.getTime() - a.date.getTime());
        setBlobs(tempBlobList);
      };
      getSetBlobs();
    }
  }, [filePath, isAuthenticated, inProgress, instance, forceReloadValue]);

  return [blobs, clearBlobCache, forceReload] as const;
};

export const useDownloadBlob = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  return async (fileName: string, filePath: string, version?: string) => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );
      const blobFileClient = blobServiceClient
        .getContainerClient(TEMPLATE_CONTAINER_NAME)
        .getBlockBlobClient(filePath);

      const blobFile = await blobFileClient
        .withVersion(version ?? "")
        .download();
      const blobBody = await blobFile.blobBody;

      if (blobBody === undefined) {
        return;
      }

      const element = document.createElement("a");
      element.href = URL.createObjectURL(blobBody);
      element.download = fileName;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      URL.revokeObjectURL(element.href);
      document.body.removeChild(element);
    }
  };
};
