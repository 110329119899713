import React from "react";
import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { parseISO } from "date-fns";

import { useMsal } from "@azure/msal-react";
import { BreadCrumbs } from "../components/BreadCrumbs";
import {
  DealOverview,
  DealOverviewProps,
} from "../components/Deal/DealOverview/DealOverview";
import {
  DealStatus_Enum,
  GetDealDocument,
  GetOpenDateFromShopHandoverActivitiesDocument,
} from "../generated/graphql-operations";
import { PushDealDialog } from "../components/Deal/PushDealDialog/PushDealDialog";
import { Button } from "../components/Button";
import {
  DealPhaseStatus,
  DealTenancyPhase,
  LAF_PHASE_NAME,
  TENANCY_DELIVERY_PHASE_NAME,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../utils/CommonVariables";
import { ActionList } from "../components/Deal/ActionList/ActionList";
import { DealStatusBar } from "../components/Deal/DealStatusBar/DealStatusBar";
import {
  checkAndFormatDate,
  convertDaysToYearsMonthsDays,
} from "../utils/DateUtils";
import { TenancyStatusBar } from "../components/Deal/TenancyStatusBar/TenancyStatusBar";
import { DealPhase } from "../components/Deal/PushDealDialogServices";
import { DealSummaryTabs } from "../components/Deal/SummaryTab/DealSummaryTabs";
import { ShopOpenNotificationDialog } from "../components/Deal/DealShopActivities/ShopOpen";
import {
  CopyDealDialog,
  CopyDealDialogReLaf,
} from "../components/Deal/CopyDeal/CopyDealDialog";
import { dealIsDisabled } from "../utils/CommonFunctions";
import { CancelDealDialog } from "../components/Deal/CancelDeal/CancelDealDialog";
import { DeleteDealDialog } from "../components/Deal/DeleteDeal/DeleteDealDialog";
import { ShopHandoverNotificationDialog } from "../components/Deal/DealShopActivities/ShopHandover";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";
import { RiskLevelSelect } from "../components/Deal/RiskLevel/RiskLevelSelect";

export const Deal = () => {
  // TODO temporary placeholder till we inject data from database
  const params = useParams();
  const dealID = parseInt(params.dealId ?? "", 10);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentPhaseId, setCurrentPhaseId] = React.useState<
    number | undefined
  >(undefined);
  const [currentTenancyPhaseId, setCurrentTenancyPhaseId] = React.useState<
    number | undefined
  >(undefined);

  const msalInstance = useMsal();
  const userName = msalInstance.instance.getActiveAccount()?.name!;
  const userId = msalInstance.instance.getActiveAccount()?.localAccountId!;

  const { data } = useQuery(GetDealDocument, {
    variables: { deal_id: dealID },
  });

  const { data: dealShopActivities, loading: dealShopActivitiesLoading } =
    useQuery(GetOpenDateFromShopHandoverActivitiesDocument, {
      variables: { deal_id: dealID },
    });

  // When deal rejected
  const dealDisabled = dealIsDisabled(data?.Deals_by_pk?.status);
  const userRole = useReturnUserRole();
  const isReadOnly = userRole === Role.ReadOnly;
  const isLeaseExec = userRole === Role.Exec;
  const isTdAssign = userRole === Role.TdAssignee;
  const isAdmin = userRole === Role.Admin;

  // Sets default active phase to most recent Active, otherwise don't set it
  React.useEffect(() => {
    if (data?.Deals_by_pk === undefined) {
      setCurrentPhaseId(undefined);
      return;
    }
    if (currentPhaseId !== undefined) {
      return;
    }
    const normalPhases = data?.Deals_by_pk?.DealPhases.filter(
      (phase) => !phase?.is_tenancy_delivery
    );
    const firstActivePhase = normalPhases?.find(
      (item) => item?.PhaseDealStatus?.id === DealPhaseStatus.Active
    )?.id;
    // if first Active phase doesn't exist get last Complete Phase
    if (firstActivePhase !== undefined) {
      setCurrentPhaseId(firstActivePhase);
    } else {
      setCurrentPhaseId(
        normalPhases
          ?.reverse()
          ?.find(
            (item) => item?.PhaseDealStatus?.id === DealPhaseStatus.Complete
          )?.id
      );
    }

    const tenancyPhases = data?.Deals_by_pk?.DealPhases.filter(
      (phase) => phase?.is_tenancy_delivery
    );
    setCurrentTenancyPhaseId(
      tenancyPhases?.find(
        (item) => item?.PhaseDealStatus?.id === DealPhaseStatus.Active
      )?.id ?? tenancyPhases?.[0]?.id
    );
  }, [currentPhaseId, data]);

  if (data?.Deals_by_pk?.id === undefined || dealShopActivitiesLoading) {
    return <>Loading...</>;
  }

  const deal: DealOverviewProps = {
    unit:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.label ??
      (data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label
        ? `${data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label}${VIRTUAL_UNIT_LABEL_SUFFIX}`
        : null) ??
      "",
    status: data.Deals_by_pk.status,
    // TODO: add enums for all DB types
    laAssignee: data.Deals_by_pk?.LA_Assignee?.user_full_name ?? "",
    tdAssignee: data.Deals_by_pk?.TD_Assignee?.user_full_name ?? "",
    tradingName: data.Deals_by_pk?.trading_name ?? "",
    tenantCode:
      data.Deals_by_pk?.tenant_code ??
      data.Deals_by_pk?.YardiTenants_Deals?.[0]?.TenantCode ??
      "",
    propertyCode:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property
        ?.property_code ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.property_code ??
      "",
    created_by: data.Deals_by_pk?.User.user_full_name ?? "",
    owner: data.Deals_by_pk?.Owner?.user_full_name ?? "",
    leaseStart:
      checkAndFormatDate(
        parseISO(data.Deals_by_pk?.DealData[0]?.lease_start),
        "dd/MM/yyyy"
      ) ?? "",
    leaseTerm:
      convertDaysToYearsMonthsDays(
        parseISO(data.Deals_by_pk?.DealData[0]?.lease_start),
        data.Deals_by_pk?.DealData[0]?.lease_term
      ) ?? "",
    centre:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.label ??
      data.Deals_by_pk?.DealData[0].DealsUnits[0]?.VirtualUnit?.Property
        ?.label ??
      "",
    // Need to add Z so date-fns knows its UTC time from DB.
    creationDate:
      checkAndFormatDate(
        parseISO(`${data.Deals_by_pk?.created_at}Z`),
        "dd/MM/yyyy"
      ) ?? "",
    version:
      data.Deals_by_pk?.laf_version === 1
        ? ""
        : `LAF ${data.Deals_by_pk?.laf_version}`,
    disabled: dealDisabled || isReadOnly,
    dealType: data.Deals_by_pk?.DealType?.title || "",
  };

  const tenantId =
    data.Deals_by_pk?.tenant_code ??
    data.Deals_by_pk?.YardiTenants_Deals?.[0]?.TenantCode ??
    "";
  const dealType = data.Deals_by_pk?.DealType?.title ?? "";
  const rentFreePeriod = data.Deals_by_pk?.DealData[0]?.rent_free_period ?? 0;

  const propertyAddress = {
    street: `${
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.street1 ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.street1 ??
      ""
    } ${
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.street2 ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.street2 ??
      ""
    }`,
    city:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.suburb ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.suburb ??
      "",
    postcode:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.postcode ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.postcode ??
      "",
    country:
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.Unit?.Property?.country ??
      data.Deals_by_pk?.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
        ?.country ??
      "",
  };

  const phaseData: DealPhase[] =
    data.Deals_by_pk?.DealPhases.map((dealPhase) => ({
      dealId: data?.Deals_by_pk?.id,
      id: dealPhase.id,
      name: dealPhase?.name,
      statusID: dealPhase.PhaseDealStatus?.id,
      isTenancyDelivery: dealPhase?.is_tenancy_delivery ?? false,
      actionItems:
        dealPhase.DealActionItem.map((item) => ({
          id: item.id,
          required: item.required,
          state: item.state,
          text: JSON.parse(item.content ?? "{}").text,
          userDisabled: JSON.parse(item.content ?? "{}").userDisabled ?? false,
          assigned_to:
            item.ActionAllocations?.map(
              (allocation) => allocation?.User?.user_full_name ?? ""
            ).filter((user) => user !== "") ?? [],
        })) ?? [],
    })) ?? [];

  const normalPhases = phaseData.filter((item) => !item.isTenancyDelivery);
  const tenancyPhases = phaseData.filter((item) => item.isTenancyDelivery);

  const activePhase = normalPhases.filter(
    (item) => item.id === currentPhaseId
  )[0];

  const activeTenancyPhase = tenancyPhases.filter(
    (item) => item.id === currentTenancyPhaseId
  )[0];

  const isActivePhaseTenancyDelivery =
    activePhase?.name === TENANCY_DELIVERY_PHASE_NAME;

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{`${
              data.Deals_by_pk?.trading_name ?? ""
            }, ${
              data.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0]?.Unit?.Property
                ?.label ??
              data.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0]?.VirtualUnit
                ?.Property?.label ??
              ""
            }`}</Typography>
            <Box>
              <CopyDealDialogReLaf
                dealId={dealID}
                disableReLaf={
                  normalPhases.find((item) => item.name === LAF_PHASE_NAME)
                    ?.statusID !== DealPhaseStatus.Complete ||
                  dealDisabled ||
                  isReadOnly ||
                  isLeaseExec ||
                  isTdAssign
                }
              />
              <CopyDealDialog
                dealId={dealID}
                disable={isReadOnly || isTdAssign}
              />
              <CancelDealDialog
                dealId={dealID}
                dealDisabled={
                  dealDisabled || isReadOnly || isLeaseExec || isTdAssign
                }
                propertyName={deal.centre}
                shopNumber={deal.unit}
                tradingName={deal.tradingName}
              />
              <DeleteDealDialog
                dealId={dealID}
                disable={
                  data.Deals_by_pk.status === DealStatus_Enum.Rejected ||
                  isReadOnly ||
                  isLeaseExec ||
                  isTdAssign
                }
              />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mb: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <BreadCrumbs
              links={[
                { text: "Dashboard", url: "/" },
                { text: "Deals", url: "/deals/" },
                { text: `${dealID}`, url: "" },
              ]}
            />

            {!(dealDisabled || isReadOnly || isLeaseExec || isTdAssign) && (
              <>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                  Push workflow
                </Button>
                <PushDealDialog
                  open={openDialog}
                  setOpen={setOpenDialog}
                  dealPhases={normalPhases}
                />
              </>
            )}
          </Stack>
        </Grid>

        {isAdmin && (
          <Grid item>
            <RiskLevelSelect dealId={dealID} />
          </Grid>
        )}

        <Grid item xs={12} sx={{ mt: 3 }}>
          <DealStatusBar
            setCurrentPhase={setCurrentPhaseId}
            currentPhase={currentPhaseId}
            phases={normalPhases}
          />
        </Grid>

        {activePhase?.name === TENANCY_DELIVERY_PHASE_NAME && (
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TenancyStatusBar
              setCurrentPhase={setCurrentTenancyPhaseId}
              currentPhase={currentTenancyPhaseId}
              phases={tenancyPhases}
            />
          </Grid>
        )}

        <Grid item xs={12} className="Deal-overview">
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Card elevation={10} sx={{ height: 320, overflow: "scroll" }}>
                <Typography variant="subtitle1">Deal Overview</Typography>
                <Box width="100%" p={1} />
                <Grid item md={12}>
                  <DealOverview {...deal} dealId={dealID} />
                </Grid>
                <Button
                  href={`/deals/${dealID}/information`}
                  variant="outlined"
                  sx={{ mt: 2, mr: 1 }}
                >
                  Deal information
                </Button>
                <Button
                  href={`/deals/${dealID}/laf`}
                  variant="contained"
                  sx={{ mt: 2, mr: 1 }}
                >
                  Lease approval form
                </Button>
                {activePhase?.name === TENANCY_DELIVERY_PHASE_NAME &&
                  activeTenancyPhase?.name === DealTenancyPhase.ShopOpen && (
                    <ShopOpenNotificationDialog
                      dealDisabled={dealDisabled}
                      dealId={dealID}
                      tenantCode={data?.Deals_by_pk?.tenant_code}
                      propertyName={deal.centre}
                      shopNumber={deal.unit}
                      tenantName={deal.tradingName}
                      submittedUser={userName}
                      userId={userId}
                      estimatedOpenDate={
                        dealShopActivities?.DealShopActivities?.[0]
                          ?.open_date ?? ""
                      }
                    />
                  )}
                {activePhase?.name === TENANCY_DELIVERY_PHASE_NAME &&
                  activeTenancyPhase?.name ===
                    DealTenancyPhase.ShopHandover && (
                    <ShopHandoverNotificationDialog
                      dealDisabled={dealDisabled}
                      propertyName={deal.centre}
                      shopNumber={deal.unit}
                      tenantName={deal.tradingName}
                      tenantId={tenantId}
                      dealType={dealType}
                      isRenewal={data.Deals_by_pk?.deal_made_from_active_lease}
                      address={propertyAddress}
                      dealId={dealID}
                      userId={userId}
                      rentFreePeriod={rentFreePeriod}
                    />
                  )}
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12} className="action-list">
                <ActionList
                  {...(isActivePhaseTenancyDelivery
                    ? activeTenancyPhase
                    : activePhase)}
                  dealId={dealID}
                  normalPhases={normalPhases}
                  tenancyPhases={tenancyPhases}
                  setCurrentPhase={setCurrentPhaseId}
                  setCurrentTenancyPhase={setCurrentTenancyPhaseId}
                  dealDisabled={dealDisabled || isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
          <Card sx={{ mt: 2, pt: 0, pl: 0, pr: 0 }}>
            <DealSummaryTabs dealId={dealID} dealDisabled={dealDisabled} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Deal;
