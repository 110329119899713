import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import {
  GetUnitsByPropertyDocument,
  GetUnitsByPropertyQuery,
} from "../../generated/graphql-operations";

export interface PropertyUnitsProps {
  propertyId: string;
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const PropertyUnits = ({
  propertyId,
  toolBar,
  hideColumns = undefined,
}: PropertyUnitsProps) => {
  const { data, loading, error } = useQuery(GetUnitsByPropertyDocument, {
    variables: { property_code: propertyId },
  });
  const columns: GridColDef<
    NonNullable<GetUnitsByPropertyQuery["Units"][0]>
  >[] = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "estimated_area",
      headerName: "Estimated area",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "survey_area",
      headerName: "Survey area",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "area_survey_required",
      headerName: "Area survey required",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "UnitType",
      headerName: "Unit type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UnitArea",
      headerName: "Unit area",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UnitCode",
      headerName: "Unit code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UnitKey",
      headerName: "Unit key",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UnitStatus",
      headerName: "Unit status",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "RentalType",
      headerName: "Rental type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "PropertyName",
      headerName: "Property name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "MarketRent",
      headerName: "Market rent",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "LevelNumber",
      headerName: "Level number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "LevelName",
      headerName: "Level name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "IsExclude",
      headerName: "Is exclude",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "FloorCode",
      headerName: "Floor code",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Building",
      headerName: "Building",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute1",
      headerName: "Attribute 1",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute2",
      headerName: "Attribute 2",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute3",
      headerName: "Attribute 3",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute4",
      headerName: "Attribute 4",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute5",
      headerName: "Attribute 5",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute6",
      headerName: "Attribute 6",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute7",
      headerName: "Attribute 7",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute8",
      headerName: "Attribute 8",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute9",
      headerName: "Attribute 9",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Attribute10",
      headerName: "Attribute 10",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 150,
    },
  ];
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .disabled-row": {
          backgroundColor: "lightgray",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        },
      }}
    >
      <DataGrid
        rows={data?.Units || []}
        columns={columns}
        loading={loading || error !== undefined}
        columnVisibilityModel={hideColumns}
        components={toolBar ? { Toolbar: CustomToolbar } : {}}
        getRowId={(params) => params.UnitCode ?? ""}
        getRowClassName={() => "disabled-row"}
        isRowSelectable={() => false}
      />
    </Box>
  );
};

export default PropertyUnits;
