import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  useAddDealContact,
  useUpdateDealContact,
} from "../../../../adapters/Deal/Contacts";
import { GetAllContactsDocument } from "../../../../generated/graphql-operations";
import { FormField, SelectField } from "../../../Form/Form";
import { DealContactsTagBar } from "./DealContactsTagBar";
import { CONTACT_TENANT_TYPE } from "../../../../utils/CommonVariables";

export const DealContactDialogSchema = yup.object({
  contact: yup
    .object({
      label: yup.string(),
      value: yup.number(),
    })
    .required("Contact is required")
    .default({ label: "", value: -1 })
    .test("NoContact", "Must select a contact", (value) => value.value !== -1),
  type: yup.array().of(yup.string()).max(1),
});

export type DealContactDialogType = yup.InferType<
  typeof DealContactDialogSchema
> & { isTenant: boolean };

interface DealContactDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealId: number;
  type: "new" | "update";
  data?: DealContactDialogType;
  currentContactsIds: number[];
}

export const DealContactDialog = (props: DealContactDialogProps) => {
  const contacts = useQuery(GetAllContactsDocument);
  const addDealContact = useAddDealContact();
  const updateDealContact = useUpdateDealContact();
  const [showAlert, setShowAlert] = useState<string | undefined>(undefined);

  const selectableContacts = contacts.data?.Contacts.filter(
    (item) => !props.currentContactsIds?.includes(item.id)
  );

  const form = useForm({
    resolver: yupResolver(DealContactDialogSchema),
    mode: "onBlur",
    defaultValues: props.data,
  });

  useEffect(() => {
    if (props.data) {
      form.reset(props.data);
    }
  }, [form, props.data]);

  const userField: FormField = {
    type: "select",
    fieldName: "contact",
    label: "Contact",
    choices: selectableContacts?.map((contact) => ({
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id,
    })),
    columns: 12,
    helperText: "Mandatory",
    disabled: props.type === "update",
  };

  const typeField = {
    fieldName: "type",
    label: "Type",
    helperText: "Mandatory",
    placeholder:
      "Select a tag or create a new one by typing and pressing enter",
  };

  const onSubmit = async (data: DealContactDialogType) => {
    if (data.isTenant || data.type?.[0] === CONTACT_TENANT_TYPE) {
      throw Error("You cannot select Tenant as type");
    }

    if (data.contact.value) {
      if (props.type === "new") {
        await addDealContact(
          data.contact.value,
          props.dealId,
          data.type?.[0] ?? ""
        );
        return;
      }
      await updateDealContact(
        data.contact.value,
        props.dealId,
        data.type?.[0] ?? ""
      );
    }
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700, pl: 3 }}>
        {props.type === "new" ? "Add contact to this deal" : "Update contact"}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <SelectField form={form} formField={userField} />
          </Grid>
          <Box width="100%" />
          <Grid item xs={12}>
            <DealContactsTagBar control={form.control} formField={typeField} />
          </Grid>
          <Grid mt={2}>
            <Button
              variant="contained"
              onClick={() => {
                form.handleSubmit(async (data) => {
                  try {
                    await onSubmit(data as DealContactDialogType);
                  } catch (e) {
                    if (e instanceof Error) {
                      setShowAlert(e.message);
                    }
                    return;
                  }
                  props.setOpen(false);
                  setShowAlert(undefined);
                  if (props.type === "new") form.reset();
                })();
              }}
            >
              {props.type === "new" ? "Add contact" : "Update contact"}
            </Button>
            <Button
              variant="text"
              onClick={() => {
                props.setOpen(false);
                setShowAlert(undefined);
                form.reset();
              }}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {showAlert && (
          <Alert
            sx={{ mt: 1 }}
            variant="filled"
            severity="error"
            onClose={() => setShowAlert(undefined)}
          >
            {showAlert}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};
