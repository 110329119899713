import { Alert, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface SubmitAlertProps {
  successSubmit: boolean | undefined;
  onClick: () => void;
}

export const SubmitAlert = (props: SubmitAlertProps) => {
  if (props.successSubmit === undefined) {
    return null;
  }
  return (
    <Alert
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={props.onClick}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      severity={props.successSubmit ? "success" : "error"}
    >
      {props.successSubmit
        ? "Saved"
        : "Something went wrong submitting, please try again"}
    </Alert>
  );
};
