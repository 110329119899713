import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, Alert } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import {
  DealProps,
  defaultIndividual,
  defaultCompany,
  TenantInformationSchema,
} from "../../DealConfig";
import { IndividualTenantForm } from "./IndividualForm";
import { CompanyTenantForm } from "./CompanyForm/CompanyForm";
import { Button } from "../../../Button";
import { CreateButton } from "../../../CreateButton";
import { Form, FormField } from "../../../Form/Form";
import { DealTenantType } from "../../../../utils/CommonVariables";
import { TradingName } from "./TradingName";

const TENANT_TYPE_CHOICES = [
  { label: "Individual", value: DealTenantType.Individual },
  { label: "Company", value: DealTenantType.Company },
];

export const NewDeal = (props: DealProps) => {
  const form = useForm({
    resolver: yupResolver(TenantInformationSchema),
    defaultValues: props.state.tenantInfo,
    mode: "onChange",
  });

  const onSubmit = () => {
    props.dispatch({ type: "next" });
  };

  const individualFieldArray = useFieldArray({
    name: "individuals",
    control: form.control,
  });

  const companiesFieldArray = useFieldArray({
    name: "companies",
    control: form.control,
  });

  const tenantTypeField: FormField = {
    type: "select",
    fieldName: "tenantType",
    label: "Tenant type",
    helperText: "Mandatory",
    disabled:
      (form.watch("tenantType.value") === DealTenantType.Individual &&
        form.watch("individuals").length > 0) ||
      (form.watch("tenantType.value") === DealTenantType.Company &&
        form.watch("companies").length > 0),
    choices: TENANT_TYPE_CHOICES,
    columns: 12,
    onChange: (e, data) => {
      const selection =
        TENANT_TYPE_CHOICES.find((item) => item.value === data.value) ??
        TENANT_TYPE_CHOICES[0];

      const tenantInfo = { ...props.state.tenantInfo };
      tenantInfo.tenantType = selection;

      props.setState((prev) => ({ ...prev, tenantInfo }));
    },
  };

  return (
    <>
      <Grid container p={1}>
        <TradingName
          parentProps={props}
          form={form}
          tradingNameFieldName="tradingName"
          tradingNameSelectFieldName="tradingNameSelect"
        />

        <Box width="100%" />
        <Form form={form} fields={[tenantTypeField]} />
      </Grid>
      {form.watch("tenantType.value") === DealTenantType.Individual ? (
        <>
          {individualFieldArray.fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item sm={12} p={1}>
                <IndividualTenantForm
                  form={form}
                  index={index}
                  parentProps={props}
                />
                <Grid container justifyContent="flex-end">
                  <Grid item p={1}>
                    <Button
                      startIcon={<Delete />}
                      color="error"
                      variant="text"
                      onClick={() => {
                        individualFieldArray.remove(index);
                        const individuals = [
                          ...props.state.tenantInfo.individuals,
                        ];
                        individuals.splice(index, 1);
                        const tenantInfo = { ...props.state.tenantInfo };
                        tenantInfo.individuals = individuals;
                        props.setState((prev) => ({
                          ...prev,
                          tenantInfo,
                        }));
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}

          {form.watch("tenantType.value") === -1 ? null : (
            <Grid item p={1}>
              <CreateButton
                startIcon={<Add />}
                color="primary"
                variant="text"
                onClick={() => {
                  individualFieldArray.append(defaultIndividual);
                  const tenantInfo = { ...props.state.tenantInfo };
                  tenantInfo.individuals = [
                    ...props.state.tenantInfo.individuals,
                    defaultIndividual,
                  ];
                  props.setState((prev) => ({
                    ...prev,
                    tenantInfo,
                  }));
                }}
              >
                Add tenant
              </CreateButton>
            </Grid>
          )}
        </>
      ) : (
        <>
          {companiesFieldArray.fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item sm={12} p={1}>
                <CompanyTenantForm
                  form={form}
                  index={index}
                  parentProps={props}
                />
                <Grid container justifyContent="flex-end">
                  <Grid item p={1}>
                    <Button
                      startIcon={<Delete />}
                      color="error"
                      variant="text"
                      onClick={() => {
                        companiesFieldArray.remove(index);
                        const companies = [...props.state.tenantInfo.companies];
                        companies.splice(index, 1);
                        const tenantInfo = { ...props.state.tenantInfo };
                        tenantInfo.companies = companies;
                        props.setState((prev) => ({
                          ...prev,
                          tenantInfo,
                        }));
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}

          {form.watch("tenantType.value") === -1 ? null : (
            <Grid item p={1}>
              <CreateButton
                startIcon={<Add />}
                color="primary"
                variant="text"
                onClick={() => {
                  companiesFieldArray.append(defaultCompany);
                  const tenantInfo = { ...props.state.tenantInfo };
                  tenantInfo.companies = [
                    ...props.state.tenantInfo.companies,
                    defaultCompany,
                  ];
                  props.setState((prev) => ({
                    ...prev,
                    tenantInfo,
                  }));
                }}
              >
                Add company
              </CreateButton>
            </Grid>
          )}
        </>
      )}

      {props.state.tenantInfo.individuals.length === 0 &&
        props.state.tenantInfo.companies.length === 0 &&
        form.formState.dirtyFields.tenantType && (
          <Grid>
            <Alert sx={{ mt: 2 }} severity="error">
              You must select at least one tenant
            </Alert>
            <Box sx={{ pb: 1 }} width="100%" />
          </Grid>
        )}

      <Grid p={1}>
        <Button
          color="primary"
          variant="contained"
          // TODO: got to handle displaying error of having no tenants, currently is set on FormField.errors.[""]
          onClick={async () => {
            await Promise.all([form.handleSubmit(onSubmit)()]);
          }}
        >
          Next
        </Button>
        <Button color="primary" variant="outlined" href="/" sx={{ ml: 2 }}>
          Cancel
        </Button>
      </Grid>
    </>
  );
};
