import { SxProps } from "@mui/system";
import { ApolloCache, FetchResult, TypedDocumentNode } from "@apollo/client";
import {
  DealRiskLevels_Enum,
  DealStatus_Enum,
} from "../generated/graphql-operations";
import theme from "../themes/theme";
import { DealDisabledStatus, Toggle } from "./CommonVariables";

export const toggleToInt = (value: string) => {
  if (value === "yes") return Toggle.YES;
  if (value === "no") return Toggle.NO;
  if (value === "na") return Toggle.NA;

  throw new TypeError("Value must be either 'yes', 'no' or 'na'");
};

export const IntToToggle = (value: number) => {
  if (value === Toggle.YES) return "yes";
  if (value === Toggle.NO) return "no";
  if (value === Toggle.NA) return "na";

  throw new TypeError("Value must be either 1, 2 or 3");
};

export const hoardingAndSkinsToggleToInt = (value: string) => {
  if (value === "landlord") return 1;
  if (value === "tenant") return 2;
  if (value === "split") return 3;
  if (value === "na") return 4;

  throw new TypeError("Value must be either 'landlord', 'tenant' or 'split'");
};

export const IntToHoardingAndSkinsToggle = (value: number) => {
  if (value === 1) return "landlord";
  if (value === 2) return "tenant";
  if (value === 3) return "split";
  if (value === 4) return "na";

  throw new TypeError("Value must be either 1, 2 or 3");
};

// Required for some number fields to make nullable.
// If its a number field with no custom Comp then it raises
// type error when making it null
export const yupAllowEmptyTransform = (value: any, org: any) =>
  org === "" ? undefined : Number(value);

export const splitPascalCase = (word: string | undefined) => {
  const wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  return word?.match(wordRe)?.join(" ") ?? "";
};

export const formatDealStatusText = (status: DealStatus_Enum) => {
  if (status === DealStatus_Enum.RelafReview) {
    return "ReLAF Review";
  }

  return splitPascalCase(status);
};

export const statusSxColor = (status: string): SxProps => {
  const defaultStatusSx = {
    color: "white",
    backgroundColor: theme.palette.success.main,
  };
  switch (status) {
    case DealStatus_Enum.Review:
      return { ...defaultStatusSx, backgroundColor: "#FF9900" };
    case DealStatus_Enum.Approved:
      return {
        ...defaultStatusSx,
        backgroundColor: theme.palette.primary.light,
      };
    case DealStatus_Enum.OnHold:
      return { ...defaultStatusSx, backgroundColor: "#00008B" };
    case DealStatus_Enum.RelafReview:
      return { ...defaultStatusSx, backgroundColor: "#7951DF" };
    case DealStatus_Enum.Rejected:
    case DealStatus_Enum.Superseded:
    case DealStatus_Enum.Cancelled:
    case DealStatus_Enum.Deleted:
      return {
        ...defaultStatusSx,
        backgroundColor: theme.palette.error.main,
      };
    default:
      return defaultStatusSx;
  }
};

export const riskSxColor = (risk: string): SxProps => {
  const defaultRiskSx = {
    color: "white",
    backgroundColor: theme.palette.success.main,
  };
  switch (risk) {
    case DealRiskLevels_Enum.High:
      return {
        ...defaultRiskSx,
        backgroundColor: theme.palette.error.light,
      };
    case DealRiskLevels_Enum.Medium:
      return {
        ...defaultRiskSx,
        backgroundColor: theme.palette.warning.dark,
      };
    case DealRiskLevels_Enum.Low:
      return {
        ...defaultRiskSx,
        backgroundColor: theme.palette.success.light,
      };
    default:
      return defaultRiskSx;
  }
};

const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
export const formatOrdinals = (n: number) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const dealIsDisabled = (dealStatus: String | undefined) => {
  return DealDisabledStatus.some((item) => item === dealStatus);
};

export const createCacheUpdateFunction = (
  queryToUpdate: TypedDocumentNode<any, any>,
  cacheFieldKey: string,
  operationName: string
) => {
  return (cache: ApolloCache<any>, { data: result }: FetchResult) => {
    const existingData =
      cache.readQuery({
        query: queryToUpdate,
      })?.[cacheFieldKey] || [];

    const newData = result?.[operationName];

    cache.writeQuery({
      query: queryToUpdate,
      data: { [cacheFieldKey]: [newData, ...existingData] },
    });
  };
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
