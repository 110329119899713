import React, { useState } from "react";
import { Button, Stack, TextField } from "@mui/material";

interface Props {
  onSend: (question: string, id?: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  conversationId,
}: Props) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    if (conversationId) {
      onSend(question, conversationId);
    } else {
      onSend(question);
    }

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value || "");
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack direction="row">
      <TextField
        placeholder={placeholder}
        multiline
        rows={2}
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        sx={{
          width: "90%",
          mr: 3,
        }}
      />
      <Button
        onClick={sendQuestion}
        role="button"
        disabled={sendQuestionDisabled}
        aria-label="Ask question button"
        tabIndex={0}
        variant="contained"
        sx={{
          width: "10%",
        }}
      >
        Send
      </Button>
    </Stack>
  );
};
