import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { GetLafSecurityDocument } from "../../generated/graphql-operations";
import { ScrollableTable, ScrollableTableProps } from "./ScrollableTable";
import { dollarFormat, EMPTY_SYMBOL } from "./Common";
import { Alert } from "../Alert";
import { Toggle } from "../../utils/CommonVariables";

const columns = [
  "Item",
  "Months",
  "Required",
  "Achieved",
  "Guarantors",
] as const;

type Columns = typeof columns[number];

export const LeaseSecurityTable = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafSecurityDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const bankGuranteeAmount =
    data.Deals_by_pk?.DealData[0].bank_guarantee_amount;
  const achievedBankGuranteeAmount =
    data.Deals_by_pk?.DealData[0].achieved_bank_guarantee;
  const rentDeposit = data.Deals_by_pk?.DealData[0].rent_deposit;
  const achievedRentDeposit =
    data.Deals_by_pk?.DealData[0].achieved_rent_deposit;
  const gurantors =
    data.Deals_by_pk?.DealData[0].DealDataGuarantors.map(
      (item) => item.full_name
    ).sort() ?? [];

  const rows: ScrollableTableProps<Columns>["rows"] = [
    {
      Item: "Bank guarantee",
      Months:
        data.Deals_by_pk?.DealData[0].bank_guarantee_months ?? EMPTY_SYMBOL,
      Achieved: achievedBankGuranteeAmount
        ? dollarFormat.format(achievedBankGuranteeAmount)
        : EMPTY_SYMBOL,
      Required: bankGuranteeAmount
        ? dollarFormat.format(bankGuranteeAmount)
        : EMPTY_SYMBOL,
      Guarantors: "",
      key: "BankGurantee",
    },
    {
      Item: "Rent deposit",
      Months: data.Deals_by_pk?.DealData[0].rent_deposit_months ?? EMPTY_SYMBOL,
      Achieved: achievedRentDeposit
        ? dollarFormat.format(achievedRentDeposit)
        : EMPTY_SYMBOL,
      Required: rentDeposit ? dollarFormat.format(rentDeposit) : EMPTY_SYMBOL,
      Guarantors: "",
      key: "rent",
    },
    {
      Item: "Guarantor required",
      Months: "",
      Achieved:
        data.Deals_by_pk?.DealData[0].guarantors_id === Toggle.YES
          ? "Yes"
          : "No",
      Required: EMPTY_SYMBOL,
      Guarantors: gurantors.join(", "),
      key: "guarantors",
    },
  ];

  return <ScrollableTable columns={columns} rows={rows} />;
};
