import React from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowsProp,
} from "@mui/x-data-grid";

interface ListProps {
  hideColumns?: GridColumnVisibilityModel;
  handleRowSelect?: (number: number) => void;
  columnFormat: GridColDef[];
  rowData: GridRowsProp;
  loading: boolean;
}

export const List = ({
  handleRowSelect,
  hideColumns,
  columnFormat,
  rowData,
  loading,
}: ListProps) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          classes={{
            sortIcon: "color:red;margin-top:75px;",
          }}
          rows={rowData}
          columns={columnFormat}
          loading={loading}
          columnVisibilityModel={hideColumns}
          onCellClick={(params, event) => {
            if (!event.ctrlKey) {
              handleRowSelect?.(params.row.id);
            }
          }}
        />
      </div>
    </div>
  );
};
