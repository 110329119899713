import React from "react";
import { Container, Typography, Grid, Card } from "@mui/material";
import { BreadCrumbs, SpecialConditionTemplate } from "../components";
import { TemplateDocument } from "../components/Template/TemplateDocument/TemplateDocument";
import { PermittedUseTemplate } from "../components/Template/PermittedUse/PermittedUse";

export const Templates = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">Templates</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Templates", url: "" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" mb={2}>
              Document templates
            </Typography>
            <TemplateDocument />
          </Card>
        </Grid>
        <SpecialConditionTemplate />
        <PermittedUseTemplate />
      </Grid>
    </Container>
  );
};
