import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  DataState,
  DealInformationType,
} from "../DealInformation/DealInformationConfig";
import {
  transformFitoutLandlordWorks,
  useGetDealFitoutLandlordWorks,
} from "../../adapters/DealInformation/FitoutLandlordWorks/FitoutLandlordWorks";
import { Alert } from "../Alert";
import { ReadOnlyEditor } from "../Form/RichTextEditor";
import { ScrollableTable, ScrollableTableProps } from "./ScrollableTable";
import { checkAndFormatDate } from "../../utils/DateUtils";

export const FitoutSection = (props: { dealId: number }) => {
  // Create a useless form instance because FitoutLandlordWorks comp requires it
  // This is a simpler solution than making the form be optional in the props
  // The form will never be used since nothing is saved in the LAF page
  // Same with setDataState
  const form = useForm<DealInformationType>();
  const setDataState: React.Dispatch<
    React.SetStateAction<DataState>
  > = () => {};

  const data = useGetDealFitoutLandlordWorks(
    props.dealId,
    (dataComplete) => {
      form.setValue(
        "fitoutLandlordWorks",
        transformFitoutLandlordWorks(dataComplete)
      );
      setDataState((prev) => ({ ...prev, fitoutLandlordWorksLoaded: true }));
    },
    () =>
      setDataState((prev) => ({ ...prev, fitoutLandlordWorksLoaded: false }))
  );

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  const [tenantWorks] = form.watch(["fitoutLandlordWorks.tenantWorks"]);

  const columns = ["Item", "To pay"] as const;
  type Columns = typeof columns[number];

  const hoardingPayee = form.watch(
    "fitoutLandlordWorks.hoardingToggle"
  ) as string;

  const skinsPayee = form.watch("fitoutLandlordWorks.skinsToggle") as string;

  const rows: ScrollableTableProps<Columns>["rows"] = [
    {
      Item: "Hoarding",
      "To pay":
        hoardingPayee === "na"
          ? "N/A"
          : hoardingPayee.charAt(0).toUpperCase() + hoardingPayee.slice(1),
      key: "Hoarding",
    },
    {
      Item: "Skins",
      "To pay":
        skinsPayee === "na"
          ? "N/A"
          : skinsPayee.charAt(0).toUpperCase() + skinsPayee.slice(1),
      key: "Skins",
    },
  ];

  return (
    <fieldset disabled>
      <Grid container width="100%">
        <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
          {tenantWorks &&
            form
              .watch("fitoutLandlordWorks.tenantWorksOptions")
              .map((item) => <Typography>{item}</Typography>)}
        </Grid>

        {form.watch("fitoutLandlordWorks.tenantWorks") &&
          form.watch("fitoutLandlordWorks.fitoutDescription") !== "" && (
            <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
              <Typography>Fitout description:</Typography>
              <ReadOnlyEditor
                content={
                  form.watch("fitoutLandlordWorks.fitoutDescription") ?? ""
                }
              />
            </Grid>
          )}

        {form.watch("fitoutLandlordWorks.dateCompleteFitout") && (
          <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
            <Typography>Date to complete refurbishment:</Typography>
            <Typography>
              {checkAndFormatDate(
                form.watch("fitoutLandlordWorks.dateCompleteFitout")!,
                "dd/MM/yyyy"
              )}
            </Typography>
          </Grid>
        )}

        {form.watch("fitoutLandlordWorks.refurbishedFitoutWorks") &&
          form.watch("fitoutLandlordWorks.partialFitoutItems") !== "" && (
            <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
              <Typography>Refurbishment works to include:</Typography>
              <ReadOnlyEditor
                content={
                  form.watch("fitoutLandlordWorks.partialFitoutItems") ?? ""
                }
              />
            </Grid>
          )}
        <ScrollableTable columns={columns} rows={rows} />
      </Grid>
    </fieldset>
  );
};
