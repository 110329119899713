export const dollarFormat = new Intl.NumberFormat("en-au", {
  maximumFractionDigits: 2,
  style: "currency",
  currency: "AUD",
});

export const percentFormat = new Intl.NumberFormat("en-au", {
  style: "percent",
  maximumFractionDigits: 4,
});

export const numberFormat = new Intl.NumberFormat("en-au", {
  maximumFractionDigits: 2,
});

export const EMPTY_SYMBOL = "-";

export const DEFAULT_LAF_TAG = "LAF";
