import React from "react";
import { Grid, Button } from "@mui/material";
import { useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import { parseISO } from "date-fns";
import { GridColDef } from "@mui/x-data-grid";
import { List } from "../List";
import { UpdateDialog, CreateDialog } from "./Dialog/Dialog";
import {
  GetSpecialConditionTemplatesDocument,
  GetSpecialConditionTemplatesQuery,
} from "../../../generated/graphql-operations";
import { checkAndFormatDate } from "../../../utils/DateUtils";

const ColumnFormat: GridColDef<
  NonNullable<
    GetSpecialConditionTemplatesQuery["TemplateSpecialConditions"][number]
  >
>[] = [
  {
    field: "title",
    headerName: "Item name",
    flex: 1,
  },
  {
    field: "Updated by",
    headerName: "Updated by",
    flex: 1,
    valueGetter: (params) => params.row.ModifiedBy?.user_full_name,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    type: "date",
    valueFormatter: (params) =>
      checkAndFormatDate(params.value, "dd/MM/yyyy HH:mm"),
    valueGetter: (params) => {
      return params.row.modified_at
        ? parseISO(`${params.row.modified_at}Z`)
        : undefined;
    },
  },
  // Dummy edit button, doesn't do anything
  {
    field: "edit",
    headerName: "",
    flex: 0.5,
    renderCell: () => (
      <Button variant="outlined" startIcon={<EditIcon />}>
        Edit
      </Button>
    ),
  },
];

export const SpecialConditionTemplate = () => {
  const [createDialogOpen, setcreateDialogOpen] = React.useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
  const [updateDialogId, setUpdateDialogId] = React.useState<
    number | undefined
  >(undefined);

  const { data, loading } = useQuery(GetSpecialConditionTemplatesDocument);

  const setClose = () => {
    // Need to set this to undefined otherwise, form will not rerender with default value of
    // template from db
    setUpdateDialogId(undefined);
    setUpdateDialogOpen(false);
  };

  return (
    <Grid container item xs={12} justifyContent="flex-end">
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setcreateDialogOpen(false)}
      />
      {updateDialogId !== undefined && (
        <UpdateDialog
          templateId={updateDialogId}
          open={updateDialogOpen}
          onClose={setClose}
        />
      )}
      <Grid item pb={1}>
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => setcreateDialogOpen(true)}
        >
          Create new clause
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: 500,

          width: "100%",
        }}
      >
        <List
          handleRowSelect={(rowId) => {
            setUpdateDialogId(rowId);
            setUpdateDialogOpen(true);
          }}
          columnFormat={ColumnFormat}
          rowData={data?.TemplateSpecialConditions ?? []}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};
