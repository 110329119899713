import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

import { SelectField, TextNumberField } from "../../Form/Form";
import {
  EditorControlled,
  FormDate,
  FormSwitch,
  CheckboxForm,
  FormToggleButton,
} from "../../Form/FormComponents";
import { DealInformationSectionProps } from "../DealInformationConfig";
import {
  TENANCY_DELIVERY_CHOICES,
  TENANT_WORK_CHOICES,
  DEFAULT_VALUES,
} from "./Config";
import {
  useGetDealFitoutLandlordWorks,
  transformFitoutLandlordWorks,
} from "../../../adapters/DealInformation/FitoutLandlordWorks/FitoutLandlordWorks";
import { Alert } from "../../Alert";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import { LandlordWorks } from "./LandlordWorks";
import { hoardingAndSkinsOptions } from "../../../utils/CommonVariables";

export const FitoutLandlordWorks = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;
  const data = useGetDealFitoutLandlordWorks(
    props.dealId,
    (dataComplete) => {
      form.setValue(
        "fitoutLandlordWorks",
        transformFitoutLandlordWorks(dataComplete)
      );
      setDataState((prev) => ({ ...prev, fitoutLandlordWorksLoaded: true }));
    },
    () =>
      setDataState((prev) => ({ ...prev, fitoutLandlordWorksLoaded: false }))
  );

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  const [landlordUndertakeWorks, tenantWorks, refurbFitoutWorks] = form.watch([
    "fitoutLandlordWorks.landlordUndertakeWorks",
    "fitoutLandlordWorks.tenantWorks",
    "fitoutLandlordWorks.refurbishedFitoutWorks",
  ]);

  return (
    <Grid container width="100%">
      {/* Landlord works */}
      <Grid item sm={12}>
        <FormSwitch
          name="fitoutLandlordWorks.landlordUndertakeWorks"
          title="Landlord will undertake works"
          control={form.control}
          onChange={(e) => {
            if (!(e.target as HTMLInputElement).checked) {
              form.setValue("fitoutLandlordWorks.preview", "");
              form.setValue("fitoutLandlordWorks.costLandlordWorks", undefined);
              form.setValue("fitoutLandlordWorks.costMakegood", undefined);
              form.setValue("fitoutLandlordWorks.costLandlordWorks", undefined);
              form.setValue("fitoutLandlordWorks.cappedCategory", undefined);
              form.setValue(
                "fitoutLandlordWorks.tenancyDeliveryDealType",
                DEFAULT_VALUES.tenancyDeliveryDealType
              );
            }
          }}
        />
      </Grid>

      <Grid item sm={12}>
        <LandlordWorks {...props} disabled={!landlordUndertakeWorks} />
      </Grid>

      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            disabled: !landlordUndertakeWorks,
            type: "text",
            fieldName: "fitoutLandlordWorks.costLandlordWorks",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Cost of landlord works (exc GST)",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>

      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            disabled: !landlordUndertakeWorks,
            type: "text",
            fieldName: "fitoutLandlordWorks.costMakegood",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Cost of makegood (exc GST)",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>

      <Grid item sm={6} p={1}>
        <SelectField
          form={props.form}
          formField={{
            disabled: !landlordUndertakeWorks,
            type: "select",
            fieldName: "fitoutLandlordWorks.tenancyDeliveryDealType",
            helperText: "",
            choices: TENANCY_DELIVERY_CHOICES,
            label: "Tenancy delivery deal type",
          }}
        />
      </Grid>

      <Grid item sm={6} p={1}>
        <TextNumberField
          form={props.form}
          formField={{
            disabled: !landlordUndertakeWorks,
            type: "text",
            fieldName: "fitoutLandlordWorks.cappedCategory",
            helperText: "",
            adornment: { value: "$", position: "start" },
            label: "Landlord contribution - category 1 costs (exc GST)",
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>

      {/* Tenants works */}
      <Grid item sm={12}>
        <FormSwitch
          name="fitoutLandlordWorks.tenantWorks"
          title="Tenant Works"
          control={form.control}
          onChange={(e) => {
            if (!(e.target as HTMLInputElement).checked) {
              form.setValue("fitoutLandlordWorks.tenantWorksOptions", []);
              // form.setValue("fitoutLandlordWorks.fitoutDescription", "");
            }
          }}
        />
      </Grid>

      <Grid item sm={12} sx={{ p: 1, mb: 1 }}>
        <CheckboxForm
          fieldName="fitoutLandlordWorks.tenantWorksOptions"
          control={form.control}
          choices={TENANT_WORK_CHOICES}
          disable={!tenantWorks}
        />
      </Grid>

      <Grid item sm={12}>
        <EditorControlled
          disable={!tenantWorks}
          label="Fitout description"
          name="fitoutLandlordWorks.fitoutDescription"
          control={form.control}
        />
      </Grid>

      {/* Refurbishment works */}
      <Grid item sm={12}>
        <FormSwitch
          name="fitoutLandlordWorks.refurbishedFitoutWorks"
          title="Refurbish works required"
          control={form.control}
          onChange={(e) => {
            if (!(e.target as HTMLInputElement).checked) {
              form.setValue(
                "fitoutLandlordWorks.dateCompleteFitout",
                undefined
              );
              form.setValue("fitoutLandlordWorks.partialFitoutItems", "");
            }
          }}
        />
      </Grid>

      <Grid item sm={12}>
        <FormDate
          fieldName="fitoutLandlordWorks.dateCompleteFitout"
          title="Date to complete refurbishment"
          control={form.control}
          disabled={!refurbFitoutWorks}
        />
      </Grid>

      <Grid item sm={12}>
        <EditorControlled
          disable={!refurbFitoutWorks}
          label="Refurbishment works to include"
          name="fitoutLandlordWorks.partialFitoutItems"
          control={form.control}
        />
      </Grid>

      <Box width="100%" mb={2} />

      <Grid item sm={2}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Hoarding
        </Typography>
      </Grid>

      <Grid item sm={4} p={1}>
        <FormToggleButton
          control={props.form.control}
          name="fitoutLandlordWorks.hoardingToggle"
          options={hoardingAndSkinsOptions}
          onChange={(e) => {
            const targetValue = (e.target as HTMLInputElement).value;
            props.form.setValue(
              "fitoutLandlordWorks.hoardingToggle",
              targetValue
            );
          }}
        />
      </Grid>

      <Box width="100%" />

      <Grid item sm={2}>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 16, fontWeight: 700, p: 1 }}
        >
          Skins
        </Typography>
      </Grid>

      <Grid item sm={4} p={1}>
        <FormToggleButton
          control={props.form.control}
          name="fitoutLandlordWorks.skinsToggle"
          options={hoardingAndSkinsOptions}
          onChange={(e) => {
            const targetValue = (e.target as HTMLInputElement).value;
            props.form.setValue("fitoutLandlordWorks.skinsToggle", targetValue);
          }}
        />
      </Grid>
    </Grid>
  );
};
