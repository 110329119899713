import { useMsal } from "@azure/msal-react";

export enum Role {
  Admin = "leaseadmin",
  Exec = "leasingexec",
  ReadOnly = "readonly",
  TdAssignee = "tdassignee",
}

export const useReturnUserRole = () => {
  const { instance } = useMsal();
  const currenUser = instance.getActiveAccount();
  // This is how Hasura checks the token(i.e first item in the array)
  const userRole = currenUser?.idTokenClaims?.roles?.[0] ?? "";
  return Object.values(Role).some((values) => values === userRole)
    ? userRole
    : Role.ReadOnly;
};
