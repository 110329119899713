import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import {
  GetCentreMapsDocument,
  GetLegislationsDocument,
  GetPropertiesDocument,
  GetPropertiesQuery,
  PropertyFileTypes_Enum,
} from "../../generated/graphql-operations";
import { checkAndFormatDate } from "../../utils/DateUtils";
import { useDownloadBlob } from "../../adapters/Properties/PropertyFiles";

export interface PropertyListProps {
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const PropertyList = ({
  toolBar,
  hideColumns = undefined,
}: PropertyListProps) => {
  const { data, loading, error } = useQuery(GetPropertiesDocument);
  const centreMaps = useQuery(GetCentreMapsDocument);
  const legislations = useQuery(GetLegislationsDocument);
  const navigate = useNavigate();
  const downloadBlob = useDownloadBlob();
  const columns: GridColDef<
    NonNullable<GetPropertiesQuery["Properties"][0]>
  >[] = [
    {
      field: "property_name",
      headerName: "Property",
      flex: 1,
      valueGetter: (params) => params.row.label,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.street1}, ${params.row.suburb} ${params.row.suburb} ${params.row.postcode}`,
    },
    {
      field: "hub",
      headerName: "Hub",
      flex: 1,
    },
    {
      field: "property_code",
      headerName: "Property code",
      flex: 1,
    },
    {
      field: "centre_map",
      headerName: "Centre map",
      flex: 1,
      valueGetter: (params) =>
        centreMaps?.data?.PropertyFiles.find(
          (file) => file.property_code === params.row.property_code
        )?.file_path ?? "",
    },
    {
      field: "legislation",
      headerName: "Legislation",
      flex: 1,
      valueGetter: (params) =>
        legislations?.data?.PropertyFiles.find(
          (file) => file.property_code === params.row.property_code
        )?.file_path ?? "",
    },
    {
      field: "integration_date",
      headerName: "Integration",
      flex: 1,
      valueGetter: (params) =>
        checkAndFormatDate(
          parseISO(`${params.row.modified_at}Z`),
          "dd/MM/yyyy HH:mm a"
        ),
    },
  ];
  return (
    <DataGrid
      rows={data?.Properties || []}
      columns={columns}
      loading={loading || error !== undefined}
      columnVisibilityModel={hideColumns}
      onCellClick={async (params, event) => {
        if (
          params.field === PropertyFileTypes_Enum.CentreMap ||
          params.field === PropertyFileTypes_Enum.Legislation
        ) {
          const filePath = (event.target as HTMLTextAreaElement).innerText;
          if (filePath === "") {
            return;
          }
          await downloadBlob(filePath, filePath);
          return;
        }
        if (!event.ctrlKey) {
          navigate(`/properties/${params.row.property_code}`);
        }
      }}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
      getRowId={(params) => params.property_code ?? ""}
    />
  );
};

export default PropertyList;
