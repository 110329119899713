import React, { useMemo, useState } from "react";
import {
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { add, areIntervalsOverlapping, parseISO, sub } from "date-fns";
import { useMsal } from "@azure/msal-react";
import {
  DealRiskLevels_Enum,
  DealStatus_Enum,
  GetDealsDocument,
  GetDealsQuery,
  LafActionChoices_Enum,
} from "../../generated/graphql-operations";
import {
  checkAndFormatDate,
  convertDaysToYearsMonthsDays,
} from "../../utils/DateUtils";
import {
  DEAL_APPROVAL_PHASE_NAME,
  DealPhaseStatus,
  DealType,
  HANDOVER_PHASE_NAME,
  LAF_PHASE_NAME,
  LEASE_ADMIN_NAME,
  LEASE_EXECUTED_AND_UPLOADED,
  LEASE_PHASE_NAME,
  LETTER_OF_ACCEPTANCE_SENT,
  SURVEY_PLAN_ATTACHED,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../../utils/CommonVariables";
import {
  formatDealStatusText,
  riskSxColor,
  statusSxColor,
} from "../../utils/CommonFunctions";
import PersistedDataGrid from "../PersistedDataGrid";

export interface DealsListProps {
  toolBar: boolean;
  filterToUser?: boolean;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <Stack direction="row" spacing={2}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </Stack>
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

const getLarActionsDeals = (data: GetDealsQuery | undefined) => {
  const larActionFilteredDeals = data?.Deals.filter((deal) => {
    // Include deals that are also completed
    const dealCompleted = deal.status === DealStatus_Enum.Completed;
    // Deal status is approved (all deals)
    const dealApproved = deal.status === DealStatus_Enum.Approved;
    if (!dealApproved && !dealCompleted) return false;

    // determine if expire  date overlaps month before or after MatchingActiveEnd
    const MatchingActiveTenantEndDate =
      deal?.YardiTenants_Deals?.[0]?.YardiActiveLeases?.EndDate;
    const handoverDate = deal?.DealData?.[0]?.handover_date;
    if (MatchingActiveTenantEndDate && handoverDate) {
      const daysToEndDate = deal?.DealData?.[0]?.lease_term ?? 0;
      const ActiveTenantParse = parseISO(MatchingActiveTenantEndDate);
      const handoverDateParse = parseISO(handoverDate);
      const expireDate = add(handoverDateParse, { days: daysToEndDate });

      if (
        areIntervalsOverlapping(
          { start: expireDate, end: expireDate },
          {
            start: sub(ActiveTenantParse, { months: 1 }),
            end: add(ActiveTenantParse, { months: 1 }),
          }
        )
      ) {
        return false;
      }
    }

    // Letter of Acceptance checklist item ticked in the ‘Deal Approval’ phase (All deals)
    // If letter of acceptance is not ticked, return false and filter out the deal
    const letterOfAcceptanceTicked = deal.DealPhases.find(
      (phase) =>
        phase.name?.toLowerCase() === DEAL_APPROVAL_PHASE_NAME.toLowerCase() &&
        phase.DealActionItem.find(
          (dealActionItem) =>
            dealActionItem.content
              ?.toLowerCase()
              .includes(LETTER_OF_ACCEPTANCE_SENT.toLowerCase()) &&
            dealActionItem.state
        )
    );
    if (!letterOfAcceptanceTicked) return false;

    const checkDealTypes = [
      DealType.New,
      DealType.Opportunity,
      DealType.NewLeaseChangeOwnership,
      DealType.RemixRelocation,
      DealType.Churn,
      DealType.CurrentYearVacancy,
    ];

    if (checkDealTypes.includes(deal.DealType.title!)) {
      // Lease Executed and Uploaded ticked in the 'Lease' Phase (New deals only)
      // If DealType is New and Lease Executed and Uploaded is not ticked, return false and filter out the deal
      const leaseExecutedAndUploaded = deal.DealPhases.find(
        (phase) =>
          // Appears in Lease phase in preprod but Registration phase in local
          phase.name?.toLowerCase() === LEASE_PHASE_NAME.toLowerCase() &&
          phase.DealActionItem.find(
            (dealActionItem) =>
              dealActionItem.content
                ?.toLowerCase()
                .includes(LEASE_EXECUTED_AND_UPLOADED.toLowerCase()) &&
              dealActionItem.state
          )
      );
      if (!leaseExecutedAndUploaded) return false;

      // Handover phase completed (New deals only)
      // If DealType is New and HandoverPhase is not completed, return false and filter out the deal
      const handoverCompleted = deal.DealPhases.find(
        (phase) =>
          phase.name?.toLowerCase() === HANDOVER_PHASE_NAME.toLowerCase() &&
          phase.status_id === DealPhaseStatus.Complete
      );
      if (!handoverCompleted) return false;
    }

    // Survey Plan attached (Virtual units only)
    // If Virtual Unit deal and Survey Plan is not attached, return false and filter out the deal
    if (deal.DealData[0].DealsUnits && !deal.DealData[0].DealsUnits[0].Unit) {
      const surveyPlanAttached = deal.DealPhases.find(
        (phase) =>
          // Appears in Lease phase in preprod but Registration phase in local
          phase.name?.toLowerCase() === LEASE_PHASE_NAME.toLowerCase() &&
          phase.DealActionItem.find(
            (dealActionItem) =>
              dealActionItem.content
                ?.toLowerCase()
                .includes(SURVEY_PLAN_ATTACHED.toLowerCase()) &&
              dealActionItem.state
          )
      );
      if (!surveyPlanAttached) return false;
    }

    return true;
  });
  return larActionFilteredDeals;
};

export const DealsList = ({
  toolBar,
  filterToUser = false,
}: DealsListProps) => {
  const { instance } = useMsal();
  const userId = instance.getActiveAccount()?.idTokenClaims?.oid;

  const { data, loading, error } = useQuery(
    GetDealsDocument,
    filterToUser
      ? {
          variables: {
            filters: {
              _and: [
                {
                  status: {
                    _nin: [
                      DealStatus_Enum.Superseded,
                      DealStatus_Enum.Deleted,
                      DealStatus_Enum.Cancelled,
                      DealStatus_Enum.Completed,
                    ],
                  },
                },
                {
                  _or: [
                    { owner: { _eq: userId } },
                    { la_assignee: { _eq: userId } },
                    { td_assignee: { _eq: userId } },
                  ],
                },
              ],
            },
          },
        }
      : undefined
  );

  const navigate = useNavigate();
  const [larAction, setLarAction] = useState(false);

  const larActionFilteredDeals = useMemo(
    () => getLarActionsDeals(data),
    [data]
  );

  const handleLarActionchange = (
    event: React.SyntheticEvent,
    isChecked: boolean
  ) => {
    setLarAction(isChecked);
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const riskLevelColumn: GridColDef<NonNullable<GetDealsQuery["Deals"][0]>> = {
    field: "riskLevel",
    headerName: "Risk",
    renderCell: (params) =>
      params.value && (
        <Chip label={params.value} sx={riskSxColor(params.value)} />
      ),
    sortComparator: (
      v1: DealRiskLevels_Enum,
      v2: DealRiskLevels_Enum,
      cellParams
    ) => {
      const riskLevelOrder = [
        DealRiskLevels_Enum.Low,
        DealRiskLevels_Enum.Medium,
        DealRiskLevels_Enum.High,
      ];

      const sortColumn = sortModel.find(
        (sm: GridSortItem) => sm.field === cellParams.field
      );

      if (sortColumn && sortColumn.sort === "asc") {
        if (!v1) return 1;
        if (!v2) return -1;
      }

      return riskLevelOrder.indexOf(v1) - riskLevelOrder.indexOf(v2);
    },
    flex: 1,
    valueGetter: (params) => params.row.risk_level,
  };

  const columns: GridColDef<NonNullable<GetDealsQuery["Deals"][0]>>[] = [
    {
      field: "centre",
      headerName: "Centre",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealData[0]?.DealsUnits[0]?.Unit?.Property?.label ??
        params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property?.label ??
        "",
    },
    {
      field: "shop",
      headerName: "Shop",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealData[0]?.DealsUnits[0]?.Unit?.label ??
        (params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label
          ? `${params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.label}${VIRTUAL_UNIT_LABEL_SUFFIX}`
          : null) ??
        "",
    },
    {
      field: "property_code",
      headerName: "Property code",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealData[0]?.DealsUnits[0]?.Unit?.Property?.property_code ??
        params.row.DealData[0]?.DealsUnits[0]?.VirtualUnit?.Property
          ?.property_code ??
        "",
    },
    {
      field: "tradingname",
      headerName: "Trading name",
      flex: 1,
      valueGetter: (params) => params.row.trading_name,
    },
    {
      field: "tenant_code",
      headerName: "Tenant ID",
      flex: 1,
      valueGetter: (params) =>
        params.row.tenant_code ??
        params.row.YardiTenants_Deals?.[0]?.TenantCode ??
        "",
    },
    {
      field: "phase",
      headerName: "Phase",
      flex: 1,
      valueGetter: (params) =>
        params.row.DealPhases.filter((item) => !item.is_tenancy_delivery).find(
          (item) => item.status_id === DealPhaseStatus.Active
        )?.name ?? "",
    },
    {
      field: "leasestart",
      headerName: "Lease start date",
      flex: 1,
      type: "date",
      valueFormatter: (params) =>
        checkAndFormatDate(params.value, "dd/MM/yyyy"),
      valueGetter: (params) =>
        params.row.DealData[0]?.lease_start
          ? parseISO(params.row.DealData[0]?.lease_start)
          : undefined,
    },
    {
      field: "handover_date",
      headerName: "Handover date",
      flex: 1,
      type: "date",
      valueFormatter: (params) =>
        checkAndFormatDate(params.value, "dd/MM/yyyy"),
      valueGetter: (params) =>
        params.row.DealData[0]?.handover_date
          ? parseISO(params.row.DealData[0]?.handover_date)
          : undefined,
    },
    {
      field: "lease_term",
      headerName: "Lease term",
      flex: 1,
      valueGetter: (params) => params.row.DealData[0]?.lease_term,
      renderCell: (params) =>
        convertDaysToYearsMonthsDays(
          parseISO(params.row.DealData[0]?.lease_start),
          params.row.DealData[0]?.lease_term
        ),
    },
    {
      field: "dealowner",
      headerName: "Deal owner",
      flex: 1,
      valueGetter: (params) => params.row.Owner?.user_full_name ?? "",
    },
    {
      field: "laAssignee",
      headerName: "LA assignee",
      flex: 1,
      valueGetter: (params) => params.row.LA_Assignee?.user_full_name ?? "",
    },
    {
      field: "tdAssignee",
      headerName: "TD assignee",
      flex: 1,
      valueGetter: (params) => params.row.TD_Assignee?.user_full_name ?? "",
    },
    {
      field: "laf_version",
      headerName: "Version",
      flex: 1,
      valueGetter: (params) => params.row.laf_version,
    },
    {
      field: "text",
      headerName: "Comments",
      flex: 1,
      valueGetter: (params) => params.row.Comments?.[0]?.text ?? "",
    },
    {
      field: "lafAprover",
      headerName: "LAF reviewee",
      flex: 1,
      valueGetter: (params) => {
        const dealApproval = params.row.DealData?.[0]?.DealLAFApprovals;
        if (
          dealApproval?.laf_action &&
          [
            LafActionChoices_Enum.Approved,
            LafActionChoices_Enum.Escalation,
            LafActionChoices_Enum.LeaseAdminApproval,
          ].includes(dealApproval?.laf_action)
        ) {
          return dealApproval?.ActiveApprover?.user_full_name;
        }
        const LafPhaseActive =
          params.row.DealPhases.find((item) => item.name === LAF_PHASE_NAME)
            ?.status_id === DealPhaseStatus.Active;

        if (
          dealApproval?.laf_action === LafActionChoices_Enum.RequestChange ||
          (LafPhaseActive && dealApproval === undefined)
        ) {
          return LEASE_ADMIN_NAME;
        }
        return undefined;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={formatDealStatusText(params.value)}
          sx={statusSxColor(params.value)}
        />
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) => params.row.DealType.title,
    },
    riskLevelColumn,
  ];

  return (
    <Box
      style={{
        display: "flex",
        height: "100%",
        backgroundColor: "white",
        width: "100%",
      }}
    >
      <Box style={{ flexGrow: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            label="LAR Action"
            onChange={handleLarActionchange}
          />
        </FormGroup>
        <PersistedDataGrid
          classes={{
            sortIcon: "color:red;margin-top:75px;",
          }}
          rows={(larAction ? larActionFilteredDeals : data?.Deals) ?? []}
          columns={columns}
          loading={loading || error !== undefined}
          onCellClick={(params, event) => {
            if (!event.ctrlKey) {
              navigate(`/deals/${params.row.id}`);
            }
          }}
          components={toolBar ? { Toolbar: CustomToolbar } : {}}
          applyFilters={!filterToUser}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Box>
    </Box>
  );
};

export default DealsList;
