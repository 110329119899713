import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Stack,
  Card,
  useTheme,
  Typography,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextNumberField } from "../../../Form/Form";
import { TagBar } from "../../../TagBar";
import {
  ValidationProps,
  CreateFileDialogSchema,
  CreateFileDialogType,
} from "./Config";

import { useCreateFileSubmit } from "../../../../adapters/Deal/Files";

interface FileUploadDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealId: number;
  defaultTags?: string[];
}

export const FileUploadDialog = (props: FileUploadDialogProps) => {
  const theme = useTheme();
  const form = useForm<CreateFileDialogType>({
    resolver: yupResolver(CreateFileDialogSchema),
    defaultValues: { tags: props.defaultTags },
  });

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [userFile, setUserFile] = React.useState<File | undefined>(undefined);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        setUserFile(acceptedFiles[0]);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const onSubmit = useCreateFileSubmit(props.dealId, setValidation, userFile);

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Upload file</DialogTitle>
      <DialogContent>
        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}
        <TextNumberField
          form={form}
          formField={{
            fieldName: "fileName",
            helperText: "",
            label: "File name",
            type: "text",
          }}
        />
        <TagBar
          control={form.control}
          formField={{
            fieldName: "tags",
            label: "Tag",
            placeholder: "value",
            helperText: "",
          }}
        />
        <Card
          sx={{
            my: 2,
            backgroundColor: isDragActive
              ? theme.palette.secondary.dark
              : theme.palette.secondary.main,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive
            ? "Drop file here"
            : "Drop file here, or click to select file"}
        </Card>
        {userFile && (
          <Stack direction="row" justifyContent="flex-start">
            <InsertDriveFileOutlinedIcon />
            <Typography>{userFile.name}</Typography>
          </Stack>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            disabled={form.formState.isSubmitting}
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => {
              form.handleSubmit(async (data) => {
                try {
                  await onSubmit(data);
                } catch (e: any) {
                  setValidation({
                    show: true,
                    severity: "error",
                    message: `Failed due to: ${e?.message}`,
                  });
                }
              })();
            }}
          >
            {form.formState.isSubmitting ? <CircularProgress /> : "Submit"}
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
              setUserFile(undefined);
              form.reset();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
