import React from "react";
import { useParams } from "react-router-dom";
import { DealInformation } from "../components/DealInformation/DealInformation";
import { Alert } from "../components/Alert";
import { useCheckDealExists } from "../adapters/DealInformation/DealInformation";

export const DealInformationPage = () => {
  const params = useParams();
  const dealId = parseInt(params.dealId ?? "", 10) || -1;
  const deal = useCheckDealExists(dealId);

  if (
    Number.isNaN(dealId) ||
    (deal?.data?.Deals_by_pk?.id === undefined && !deal?.loading)
  ) {
    return (
      <Alert
        type="error"
        variant="filled"
        text={`Deal (id: ${dealId}) could not be found, please make sure you have selected a valid deal`}
      />
    );
  }

  return <DealInformation dealId={dealId} />;
};
