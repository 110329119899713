import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  InputLabel,
  TextField,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { CompanyContact } from "./CompanyContact";
import { useGetCompanies } from "../../../../adapters/Company/Company";
import { CompanyTenantType, defaultCompany } from "../TenantInformationConfig";
import { useGetStateData } from "../../../../utils/CommonQueries";
import { Form, FormField } from "../../../Form/Form";
import { AcnNumberFormatter } from "../../../MuiCustomNumberFormat";
import { DealInformationType } from "../../DealInformationConfig";
import { Alert } from "../../../Alert";

const ADD_NEW_COMPANY_FIELD = {
  ...defaultCompany,
  company: {
    label: "Add new company",
    value: -1,
  },
};
export interface TenantFormProps {
  form: UseFormReturn<DealInformationType, any>;
  index: number;
}

const filterOptions = createFilterOptions({
  limit: 10,
  stringify: ({
    email,
    phone,
    company,
    legalName,
    acn,
    address,
    city,
    state,
    postCode,
  }: CompanyTenantType) =>
    `${email} ${phone} ${company} ${legalName} ${acn} ${address} ${city} ${state} ${postCode}`,
});

export const CompanyTenantForm = (props: TenantFormProps) => {
  const states = useGetStateData(1);
  const companies = useGetCompanies();

  if (companies.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (companies.loading) {
    return <Skeleton />;
  }

  const allExistingCompanies: CompanyTenantType[] =
    companies.data?.Companies?.map((company) => {
      const tenant: CompanyTenantType = {
        company: {
          label: company?.legal_name ?? "",
          value: company?.id ?? -1,
        },
        legalName: company?.legal_name ?? "",
        acn: company?.acn ?? "",
        address: company?.Location?.street1 ?? "",
        city: company?.Location?.suburb ?? "",
        state: {
          label: company?.Location?.State?.name ?? "",
          value: company?.Location?.State?.id ?? 1,
        },
        postCode: company?.Location?.postcode?.toString() ?? "",
        addressId: company?.Location?.id ?? -1,
        contactSelect: {
          label: `${company?.DealsCompanies?.[0]?.Contact?.first_name ?? ""} ${
            company?.DealsCompanies?.[0]?.Contact?.last_name ?? ""
          }`,
          value: company?.DealsCompanies?.[0]?.Contact?.id ?? -1,
        },
        firstName: company?.DealsCompanies?.[0]?.Contact?.first_name ?? "",
        middleName: company?.DealsCompanies?.[0]?.Contact?.middle_name ?? "",
        lastName: company?.DealsCompanies?.[0]?.Contact?.last_name ?? "",
        email: company?.DealsCompanies?.[0]?.Contact?.email ?? "",
        phone: company?.DealsCompanies?.[0]?.Contact?.phone ?? "",
      };

      return tenant;
    }).sort((a: CompanyTenantType, b: CompanyTenantType) =>
      a.company.label.localeCompare(b.company.label)
    ) ?? [];

  const formFields: FormField[] = [
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.legalName` as const,
      label: "Legal name",
      helperText: "Mandatory",
      columns: 6,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.acn` as const,
      label: "ACN",
      helperText: "",
      columns: 6,
      customComp: AcnNumberFormatter,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
  ];
  const addressFields: FormField[] = [
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.address` as const,
      label: "Address",
      helperText: "",
      columns: 12,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.city` as const,
      label: "City/Suburb",
      helperText: "",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
    {
      type: "select",
      fieldName: `tenantInfo.companies.${props.index}.state` as const,
      label: "State",
      helperText: "",
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.postCode` as const,
      label: "Postcode",
      helperText: "",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.company.value` as const
        ) !== -1,
    },
  ];

  const companyOptions = [ADD_NEW_COMPANY_FIELD, ...allExistingCompanies];

  const companyExists = companyOptions.find(
    (item) =>
      item.company.value ===
      props.form.watch(`tenantInfo.companies.${props.index}.company.value`)
  );

  // TODO: Generalise search field

  return (
    <>
      <Grid item xs={12} p={1}>
        <InputLabel>Search by company name or ACN</InputLabel>
        <Controller
          defaultValue={{}}
          control={props.form.control}
          name={`tenantInfo.companies.${props.index}.company.label` as const}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disableClearable
              id={`tenant-${props.index}`}
              options={companyOptions}
              filterOptions={filterOptions}
              getOptionLabel={(value) => value.company?.label}
              filterSelectedOptions
              renderOption={(renderProps, option) => {
                return (
                  <li {...renderProps} key={option.company.value}>
                    {option.company?.label}
                  </li>
                );
              }}
              value={props.form.watch(`tenantInfo.companies.${props.index}`)}
              inputValue={props.form.watch(
                `tenantInfo.companies.${props.index}.label`
              )}
              onChange={(e, value: CompanyTenantType | null) => {
                field.onChange();
                if (!value) {
                  return;
                }

                props.form.setValue(
                  `tenantInfo.companies.${props.index}`,
                  value,
                  {
                    shouldValidate: true,
                  }
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  placeholder="Search..."
                  variant="filled"
                  error={
                    props.form.formState.errors.tenantInfo?.companies?.[
                      props.index
                    ] !== undefined
                  }
                  helperText={
                    props.form.formState.errors.tenantInfo?.companies?.[
                      props.index
                    ]
                      ? props.form.formState.errors.tenantInfo?.companies?.[
                          props.index
                        ]?.message?.toString()
                      : "Mandatory"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      {props.form.watch(`tenantInfo.companies.${props.index}.company.value`) !==
        0 &&
      props.form.watch(`tenantInfo.companies.${props.index}.company.label`) !==
        "" ? (
        <>
          <Typography variant="subtitle2" pl={1} pt={1}>
            Tenant company information
          </Typography>
          {!companyExists && (
            <Typography variant="body2" pl={1} pt={1} color="error">
              Warning: this company is deleted
            </Typography>
          )}
          <Form form={props.form} fields={formFields} />
          <Typography variant="subtitle2" pl={1} pt={1}>
            Primary contact information
          </Typography>
          <CompanyContact {...props} />
          <Typography variant="subtitle2" pl={1} pt={1}>
            Company information
          </Typography>
          <Form form={props.form} fields={addressFields} />
        </>
      ) : null}
    </>
  );
};
