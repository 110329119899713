import * as React from "react";
import { useTheme, Theme, CSSObject } from "@mui/material/styles";

import { Tabs, Tab, Avatar, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import { CommentRounded, FolderRounded } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DealComments } from "../Deal/SummaryTab/Comments/DealComments";
import { FilesComponent } from "../Deal/SummaryTab/Files/Files";
import { WHITE_ALPHA_08 } from "../../utils/CommonVariables";
import { useDealDisabled } from "../../adapters/DisabledDeal";
import { LafDetail } from "./Laf";
import { useGetLafVersion, lafVersionTag } from "../../adapters/Laf/Laf";

interface BodyContainerProps {
  open: boolean;
  children: React.ReactNode;
  disable: boolean;
}

const tabs: {
  label: string;
  icon: typeof CommentRounded;
  component: typeof DealComments;
}[] = [
  {
    label: "COMMENTS",
    icon: CommentRounded,
    component: DealComments,
  },
  {
    label: "FILES",
    icon: FolderRounded,
    component: FilesComponent,
  },
];

const sideBarOpenWidth = "400px";
const sideBarCloseWidth = "56px";

const ToolBarPadding = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      }}
    />
  );
};

// If drawer make it width, otherwise its for the body wrapper
const openedMixin = (theme: Theme, drawer: boolean = true): CSSObject => ({
  ...(drawer ? { width: sideBarOpenWidth } : { marginRight: sideBarOpenWidth }),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme, drawer: boolean = true): CSSObject => ({
  ...(drawer
    ? { width: sideBarCloseWidth }
    : { marginRight: sideBarCloseWidth }),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const LafBody = ({ open, children, disable }: BodyContainerProps) => {
  const theme = useTheme();
  const sxValue: SxProps = {
    flexGrow: 1,
    ...closedMixin(theme, false),
    ...(open && openedMixin(theme, false)),
  };
  return (
    <Box component="main" sx={disable ? {} : sxValue}>
      {children}
    </Box>
  );
};

export interface SideBarProps {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  dealId: number;
}

const SideBar = ({
  open,
  handleDrawerClose,
  handleDrawerOpen,
  dealId,
}: SideBarProps) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState(1);
  const lafVersion = useGetLafVersion(dealId);
  const { disabled: dealDisabled } = useDealDisabled(dealId);
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };
  const SectionComponent = tabs[currentTab - 1]?.component;
  return (
    <Drawer
      sx={{
        flexShrink: 0,
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
        ...(open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
      }}
      variant="permanent"
      anchor="right"
      open={open}
    >
      <ToolBarPadding />

      {!open && (
        <Grid container>
          {tabs.map((item, index) => (
            <Grid item xs={12} padding={1} key={item.label}>
              <IconButton
                onClick={() => {
                  setCurrentTab(index + 1);
                  handleDrawerOpen();
                }}
                sx={{ backgroundColor: theme.palette.secondary.main }}
              >
                <item.icon sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      )}

      {open && (
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="fullWidth"
              sx={{ backgroundColor: WHITE_ALPHA_08 }}
              TabIndicatorProps={{
                sx: { backgroundColor: theme.palette.text.primary },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="close alert"
                component="span"
                size="small"
                onClick={handleDrawerClose}
              >
                <ChevronRightIcon />
              </IconButton>
              {tabs.map((tab) => (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  iconPosition="start"
                  icon={
                    <Avatar
                      sx={{ backgroundColor: theme.palette.secondary.main }}
                    >
                      <tab.icon sx={{ color: theme.palette.text.primary }} />
                    </Avatar>
                  }
                  sx={{
                    color: theme.palette.text.primary,
                    "&.Mui-selected": {
                      backgroundColor: "white",
                      color: theme.palette.text.primary,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item container xs={12} p={2}>
            {SectionComponent && (
              <SectionComponent
                dealId={dealId}
                dealDisabled={dealDisabled}
                defaultTags={[lafVersionTag(lafVersion)]}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
};

const MobileSwipeableBar = ({ dealId }: { dealId: number }) => {
  const theme = useTheme();
  const { disabled: dealDisabled } = useDealDisabled(dealId);
  const [open, setOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const lafVersion = useGetLafVersion(dealId);
  const SectionComponent = tabs[currentTab]?.component;
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
    setOpen(true);
  };
  // default tab size is 72
  const [drawerBleeding, setDrawerBleeding] = React.useState(72);

  const measuredDrawBleedRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDrawerBleeding(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          overflow: "visible",
          height: `calc(50% - ${drawerBleeding}px)`,
        },
      }}
    >
      <Box
        sx={{
          // Add line below if want to select icons instead of drag
          "pointer-events": "all",
          position: "absolute",
          top: -drawerBleeding,
          overflowY: "scroll",
          height: `calc(100% + ${drawerBleeding}px)`,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: "white",
          visibility: "visible",
          right: 0,
          left: 0,
        }}
      >
        <Grid container height="100%" sx={{ overflow: "auto" }}>
          <Grid
            item
            xs={12}
            ref={measuredDrawBleedRef}
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleChange}
              onClick={() => {
                setOpen(true);
              }}
              variant="fullWidth"
              sx={{ backgroundColor: WHITE_ALPHA_08 }}
              TabIndicatorProps={{
                sx: { backgroundColor: theme.palette.text.primary },
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.label}
                  iconPosition="start"
                  label={tab.label}
                  icon={
                    <Avatar
                      sx={{ backgroundColor: theme.palette.secondary.main }}
                    >
                      <tab.icon sx={{ color: theme.palette.text.primary }} />
                    </Avatar>
                  }
                  sx={{
                    color: theme.palette.text.primary,
                    "&.Mui-selected": {
                      backgroundColor: "white",
                      color: theme.palette.text.primary,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item container xs={12} p={2}>
            {SectionComponent && (
              <SectionComponent
                dealId={dealId}
                defaultTags={[lafVersionTag(lafVersion)]}
                dealDisabled={dealDisabled}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

export const Laf = (props: { dealId: number }) => {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <LafBody open={open} disable={!desktopView}>
        <LafDetail dealId={props.dealId} />
      </LafBody>
      {desktopView && (
        <SideBar
          {...{
            open,
            handleDrawerClose: () => setOpen(false),
            handleDrawerOpen: () => setOpen(true),
            dealId: props.dealId,
          }}
        />
      )}
      {!desktopView && <MobileSwipeableBar dealId={props.dealId} />}
    </>
  );
};
