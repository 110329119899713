import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  OIDC_DEFAULT_SCOPES,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { pca } from "./Auth/AuthConfig";

export const HASURA_ENDPOINT = `${process.env.REACT_APP_HASURA_DOMAIN}/v1/graphql`;
const httpLink = new HttpLink({ uri: HASURA_ENDPOINT });

const httpUrlToWebSockeUrl = (url: String) => {
  return url.replace(/(http)(s)?:\/\//, "ws$2://");
};

const getAuthHeader = async () => {
  const account = pca.getActiveAccount();
  const authHeader = {
    Authorization: "Bearer",
  };

  if (account !== null) {
    const tokenRequest = {
      scopes: OIDC_DEFAULT_SCOPES,
      account,
    };
    try {
      const response = await pca.acquireTokenSilent(tokenRequest);
      authHeader.Authorization = `Bearer ${response.idToken}`;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        pca.acquireTokenRedirect(tokenRequest);
      }
    }
  }
  return authHeader;
};

const authLink = setContext(async (_, { headers }) => {
  const authHeader = await getAuthHeader();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...authHeader,
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: httpUrlToWebSockeUrl(HASURA_ENDPOINT),
    connectionParams: async () => {
      return {
        headers: {
          Authorization: (await getAuthHeader()).Authorization,
        },
      };
    },
  })
);

interface Definintion {
  kind: string;
  operation?: string;
}

const splittedLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: from([authLink, splittedLink]),
  cache: new InMemoryCache(),
});
