import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMsal } from "@azure/msal-react";
import { drawerWidth } from "./common";
// import MailIcon from "@mui/icons-material/Mail";
// import PersonIcon from "@mui/icons-material/Person";
// import Badge from "@mui/material/Badge";
// import Avatar from "@mui/material/Avatar";

interface AppBarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}
const AppBar = ({ open, handleDrawerOpen }: AppBarProps) => {
  const theme = useTheme();
  const { instance } = useMsal();

  const userName = instance.getActiveAccount()?.name ?? "";

  return (
    <MuiAppBar
      color="inherit"
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="right"
          gap={1}
          marginLeft={5}
          alignItems="center"
        >
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <Avatar sx={{ width: 30, height: 30 }}>
              <PersonIcon />
            </Avatar>
          </IconButton> */}
          <Typography variant="body1" color="inherit">
            Hi, {userName}
          </Typography>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
