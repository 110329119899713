import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";

import { GetTermsDataDocument } from "../../../generated/graphql-operations";

type JsonObject = any | Array<any>;

const flatten = (
  objectOrArray: JsonObject,
  prefix = "",
  formatter = (k: any) => `.${k}`,
  depthLevel = 0
) => {
  const nestElement: any = (prev: any, value: any, key: any) =>
    value && typeof value === "object"
      ? {
          ...prev,
          ...flatten(
            value,
            `${prefix}${depthLevel === 0 ? key : formatter(key)}`,
            formatter,
            depthLevel + 1
          ),
        }
      : {
          ...prev,
          ...{ [`${prefix}${depthLevel === 0 ? key : formatter(key)}`]: value },
        };

  const result = Array.isArray(objectOrArray)
    ? objectOrArray.reduce(nestElement, {})
    : Object.keys(objectOrArray).reduce(
        (prev, element) => nestElement(prev, objectOrArray[element], element),
        {}
      );

  return result;
};

export interface PreviewProps {
  toolBar: boolean;
  dealId: number;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const PreviewList = ({ toolBar, dealId }: PreviewProps) => {
  const { data, loading, error } = useQuery(GetTermsDataDocument, {
    variables: { DealID: dealId },
  });

  const flatData = flatten(data?.DealData?.[0] ?? {});
  const dataMapping = Object.entries(flatData)
    .filter(([k]) => !k.includes("typename") && !k.includes("id"))
    .map(([k, v], index) => ({
      id: index,
      key: k,
      value: v,
    }));

  const columns: GridColDef<typeof dataMapping[0]>[] = [
    {
      field: "key",
      headerName: "Key",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
    },
  ];
  return (
    <DataGrid
      rows={dataMapping || []}
      columns={columns}
      loading={loading || error !== undefined}
      pageSize={10}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
    />
  );
};
