import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { parseISO } from "date-fns";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormField } from "../../components/Form/Form";
import { TextMultiField } from "../../components/Form/FormComponents";
import { CurrencyFormatter } from "../../components/MuiCustomNumberFormat";
import {
  GetPropertyDetailsDocument,
  GetPropertyDocument,
} from "../../generated/graphql-operations";
import { checkAndFormatDate } from "../../utils/DateUtils";

const fieldsA: FormField[] = [
  {
    type: "text",
    fieldName: "propertyCode",
    label: "Property code",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "name",
    label: "Name",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "hub",
    label: "Hub",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "propertyAddress",
    label: "Property address",
    columns: 12,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "city",
    label: "City",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "statePostCode",
    label: "State-postcode",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "country",
    label: "Country",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "propertyProfileYearStart",
    label: "Property profile year start",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "landlord",
    label: "Landlord",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "landlordACN",
    label: "Landlord ACN",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "landlordAddress",
    label: "Landlord address",
    columns: 12,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "managementCompany",
    label: "Management company",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "managementCompanyABN",
    label: "Management company ABN",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "centrePhoneNumber",
    label: "Centre phone number",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "managementCompanyAddress",
    label: "Management company address",
    columns: 12,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "managementCompanyMailingAddress",
    label: "Management company mailing address",
    columns: 12,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "managementCompanyLicenseNumber",
    label: "Management company license number",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "approxTotalParkingSpaces",
    label: "Approx total parking spaces",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "numberOfCustomerParkingSpaces",
    label: "No. of customer parking spaces",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "alterationWorkPlanned",
    label: "Alteration work planned",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "isThisADevelopmentProperty",
    label: "Is this a development property?",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "currentLegalProceedings",
    label: "Current legal proceedings",
    columns: 4,
    helperText: "",
    disabled: true,
  },
];

const fieldsB = [
  {
    type: "text",
    fieldName: "alterationWorks",
    label: "Alteration works",
    columns: 12,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "majorAnchorExpiry",
    label: "Major/anchor expiry",
    columns: 12,
    helperText: "",
    disabled: true,
  },
];

const fieldsC: FormField[] = [
  {
    type: "text",
    fieldName: "annualTurnoverSHCentre",
    label: "Annual turnover sh centre",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "annualGrossTurnover_Food",
    label: "Annual gross turnover (food)",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "annualGrossTurnover_NonFood",
    label: "Annual gross turnover (non-food)",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "annualGrossTurnover_Service",
    label: "Annual gross turnover (service)",
    columns: 4,
    helperText: "",
    disabled: true,
    customComp: CurrencyFormatter,
  },
  {
    type: "text",
    fieldName: "turnoverAsAt",
    label: "Turnover as at",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "premiseHeadLeadCrownLE",
    label: "Premise head lead crown le",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "currentHeadCrownLeaseFRDate",
    label: "Current head/crown lease Fr date",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "currentHeadCrownLeaseEndDate",
    label: "Current head/crown lease end date",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "headOrCrownLeaseOptionFr",
    label: "Head or crown lease option Fr",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "headOrCrownLeaseOptionEnd",
    label: "Head or crown lease option end",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "landlordConsentToLeaseReq",
    label: "Landlord consent to lease req",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "reqMinisterConsentToLease",
    label: "Req minister consent to lease",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "reqMortgageeConsentToLease",
    label: "Req mortgagee consent to lease",
    columns: 4,
    helperText: "",
    disabled: true,
  },
];

const fieldsD = [
  {
    type: "text",
    fieldName: "effectOfObligations",
    label: "Effect of obligations",
    columns: 12,
    helperText: "",
    disabled: true,
  },
];

const fieldsE: FormField[] = [
  {
    type: "text",
    fieldName: "th_monday",
    label: "Monday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_tuesday",
    label: "Tuesday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_wednesday",
    label: "Wednesday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_thursday",
    label: "Thursday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_friday",
    label: "Friday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_saturday",
    label: "Saturday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_sunday",
    label: "Sunday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
  {
    type: "text",
    fieldName: "th_publicHoliday",
    label: "Public holiday",
    columns: 4,
    helperText: "",
    disabled: true,
  },
];

export const PropertyDetails = (props: { propertyId: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const property = useQuery(GetPropertyDocument, {
    variables: { property_code: props.propertyId },
  });
  const propertyDetails = useQuery(GetPropertyDetailsDocument, {
    variables: { property_code: props.propertyId },
  });

  const form = useForm();

  useEffect(() => {
    form.reset({
      propertyCode:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.YardiProperty
          ?.PropertyCode,
      name: propertyDetails?.data?.YardiPropertyProfiles?.[0]?.YardiProperty
        ?.PropertyName,
      hub: property?.data?.Properties?.[0]?.hub,
      propertyAddress:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.YardiProperty
          ?.Address1,
      city: propertyDetails?.data?.YardiPropertyProfiles?.[0]?.YardiProperty
        ?.City,
      statePostCode: `${property?.data?.Properties?.[0]?.state} ${property?.data?.Properties?.[0]?.postcode}`,
      country: property?.data?.Properties?.[0]?.country,
      propertyProfileYearStart: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.profile_year_start}Z`
        ),
        "dd-MM-yyyy"
      ),
      landlord:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.landlord_name,
      landlordACN:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.landlord_acn,
      landlordAddress:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.landlord_address,
      managementCompany:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.management_company,
      managementCompanyABN:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.management_company_abn,
      centrePhoneNumber:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.management_company_phone,
      managementCompanyAddress:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.management_company_address,
      managementCompanyMailingAddress:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.management_company_postal,
      managementCompanyLicenseNumber:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.management_company_real_estate_licence,

      approxTotalParkingSpaces:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.parking_space_num,
      numberOfCustomerParkingSpaces:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.customer_parking_space_num,
      alterationWorkPlanned:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.alteration,
      isThisADevelopmentProperty:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.development,
      alterationWorks:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.alteration_works,
      majorAnchorExpiry:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.anchor_tenant_expiry,
      annualTurnoverSHCentre:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.annual_gr_return_centre,
      annualGrossTurnover_Food:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.annual_gr_return_food,
      annualGrossTurnover_NonFood:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.annual_gr_return_nonfood,
      annualGrossTurnover_Service:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.annual_gr_return_service,
      turnoverAsAt: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.turn_over_sat}Z`
        ),
        "dd-MM-yyyy"
      ),
      premiseHeadLeadCrownLE:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.head_or_crown_lease,

      currentHeadCrownLeaseFRDate: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.head_or_crown_lease_start}Z`
        ),
        "dd-MM-yyyy"
      ),

      currentHeadCrownLeaseEndDate: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.head_or_crown_lease_end}Z`
        ),
        "dd-MM-yyyy"
      ),

      headOrCrownLeaseOptionFr: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.head_or_crown_lease_option_start}Z`
        ),
        "dd-MM-yyyy"
      ),

      headOrCrownLeaseOptionEnd: checkAndFormatDate(
        parseISO(
          `${propertyDetails?.data?.YardiPropertyProfiles?.[0]?.head_or_crown_lease_option_end}Z`
        ),
        "dd-MM-yyyy"
      ),

      landlordConsentToLeaseReq:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.require_consent_to_lease,
      reqMinisterConsentToLease:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.require_minister_consent_to_crown,
      reqMortgageeConsentToLease:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.require_mortgagee_consent,
      effectOfObligations:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]
          ?.effect_of_obligations,

      th_monday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_mon ?? "",
      th_tuesday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_tues ??
        "",
      th_wednesday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_wed ?? "",
      th_thursday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_thur ??
        "",
      th_friday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_fri ?? "",
      th_saturday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_sat ?? "",
      th_sunday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_sun ?? "",
      th_publicHoliday:
        propertyDetails?.data?.YardiPropertyProfiles?.[0]?.trade_hour_holiday ??
        "",
    });
  }, [
    form,
    propertyDetails?.data?.YardiPropertyProfiles,
    property?.data?.Properties,
  ]);

  return (
    <>
      <Form form={form} fields={fieldsA} />
      <TextMultiField control={form.control} formField={fieldsB[0]} />
      <TextMultiField control={form.control} formField={fieldsB[1]} />
      <Form form={form} fields={fieldsC} />
      <TextMultiField control={form.control} formField={fieldsD[0]} />
      <Typography variant="body1" mt={2} mb={2}>
        Trading hours
      </Typography>
      <Form form={form} fields={fieldsE} />
    </>
  );
};
