import React, { useState } from "react";
import produce from "immer";

import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import {
  DealPhase,
  DealPushValidationProps,
  useUpdateAndSaveData,
} from "../PushDealDialogServices";

import { DealPhaseStatus } from "../../../utils/CommonVariables";

import { Button } from "../../Button";

const DIALOG_MIN_LENGTH = 500;

interface PushDialogProps {
  open: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealPhases: DealPhase[];
}

const SelectDropdownWorkflow = ({
  dealStatus,
  dealPhases,
  setDealPhases,
}: {
  dealStatus: number;
  dealPhases: DealPhase[];
  setDealPhases: React.Dispatch<React.SetStateAction<DealPhase[]>>;
}) => {
  const handleSelectedPhase = (event: SelectChangeEvent<DealPhase[]>) => {
    const {
      target: { value: selectedPhases },
    } = event;

    if (typeof selectedPhases === "string") {
      return;
    }

    const phaseIds = selectedPhases.map((item) => item.id);
    setDealPhases(
      produce((draft) => {
        draft
          .filter((phase) => phaseIds.includes(phase.id))
          .forEach((phase) => {
            const updatingPhase = phase;
            updatingPhase.statusID = dealStatus;
          });
      })
    );
  };

  const handleDeleteChip = (selectedPhaseId: number) => {
    return () => {
      setDealPhases(
        produce((draft) => {
          const updatingPhase = draft.find(
            (phase) => phase.id === selectedPhaseId
          );
          if (updatingPhase) {
            updatingPhase.statusID = DealPhaseStatus.Inactive;
          }
        })
      );
    };
  };

  return (
    <Select
      variant="filled"
      sx={{ minWidth: DIALOG_MIN_LENGTH }}
      size="small"
      value={dealPhases.filter((phase) => phase.statusID === dealStatus)}
      fullWidth
      multiple
      onChange={handleSelectedPhase}
      renderValue={(selected: DealPhase[]) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5,
            pb: 1.5,
          }}
        >
          {selected.map((selectedPhase) => (
            <Chip
              color="primary"
              key={selectedPhase.id}
              label={selectedPhase.name}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              onDelete={handleDeleteChip(selectedPhase.id)}
            />
          ))}
        </Box>
      )}
    >
      {dealPhases
        .filter((phase) => phase.statusID === DealPhaseStatus.Inactive)
        .map((phase) => (
          <MenuItem value={phase as any} key={phase.id}>
            {phase.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export const PushDealDialog = (props: PushDialogProps) => {
  const [dealPhases, setDealPhases] = useState(props.dealPhases);
  const [validation, setValidation] = useState<DealPushValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [handleSave] = useUpdateAndSaveData(dealPhases, setValidation);

  // Reset dealPhases state when closing component
  React.useEffect(() => {
    setDealPhases(props.dealPhases);
  }, [props.dealPhases]);

  return (
    <Dialog
      open={props.open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle variant="h6">Push workflow</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <InputLabel>Completed phases</InputLabel>
        </DialogContentText>

        <SelectDropdownWorkflow
          dealStatus={DealPhaseStatus.Complete}
          dealPhases={dealPhases}
          setDealPhases={setDealPhases}
        />

        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}

        <DialogContentText>
          <InputLabel>Active phases</InputLabel>
        </DialogContentText>

        <SelectDropdownWorkflow
          dealStatus={DealPhaseStatus.Active}
          dealPhases={dealPhases}
          setDealPhases={setDealPhases}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-start", ml: 2 }}>
        <Button variant="contained" onClick={handleSave} sx={{ mr: 1 }}>
          Save
        </Button>

        <Button
          onClick={() => {
            props.setOpen(false);
            setValidation({
              show: false,
              severity: "success",
              message: "",
            });
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
