import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { GetLafOtherInfoDocument } from "../../generated/graphql-operations";
import { ScrollableTable, ScrollableTableProps } from "./ScrollableTable";
import { dollarFormat, numberFormat, EMPTY_SYMBOL } from "./Common";
import { Alert } from "../Alert";

const columns = ["Item", "Achieved"] as const;

type Columns = typeof columns[number];

// Formats string to correct dollar format for laf view
const formatter = (
  value: number | undefined | null,
  dollar: boolean = false
) => {
  if (typeof value === "number") {
    if (dollar) {
      return dollarFormat.format(value);
    }
    return numberFormat.format(value);
  }
  return EMPTY_SYMBOL;
};

export const LeaseOtherTable = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafOtherInfoDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const storageArea = data.Deals_by_pk?.DealData[0].storage_area;
  const storageRent = data.Deals_by_pk?.DealData[0].storage_rent;
  let storageRentPsqm;
  if (storageArea && storageRent !== undefined && storageRent !== null) {
    storageRentPsqm = storageRent / storageArea;
  }

  const rows: ScrollableTableProps<Columns>["rows"] = [
    {
      Item: "Licensed seating area (sqm)",
      Achieved: formatter(data.Deals_by_pk?.DealData[0].seating_area),
      key: "seatingArea",
    },
    {
      Item: "Licensed seating rent (pa)",
      Achieved: formatter(data.Deals_by_pk?.DealData[0].seating_rent, true),
      key: "seatingRent",
    },
    {
      Item: "Storage area (sqm)",
      Achieved: formatter(data.Deals_by_pk?.DealData[0].storage_area),
      key: "storageArea",
    },
    {
      Item: "Storage rent (pa)",
      Achieved: formatter(data.Deals_by_pk?.DealData[0].storage_rent, true),
      key: "StorageRentPa",
    },
    {
      Item: "Storage rent (psqm)",
      Achieved: formatter(storageRentPsqm, true),
      key: "StorageRent$psm",
    },
    {
      Item: "Signage rent",
      Achieved: formatter(data.Deals_by_pk?.DealData[0].signage_rent, true),
      key: "signageRent",
    },
    {
      Item: "Number of signs",
      Achieved: data.Deals_by_pk?.DealData[0].number_of_signs ?? EMPTY_SYMBOL,
      key: "numberSigns",
    },
  ];

  return <ScrollableTable columns={columns} rows={rows} />;
};
