import { useQuery, QueryResult } from "@apollo/client";
import {
  Exact,
  GetPropertiesDocument,
  GetUnitsDocument,
  GetUnitsQuery,
  GetUnitTypesDocument,
  GetVirtualUnitsDocument,
  GetVirtualUnitsQuery,
} from "../../generated/graphql-operations";
import { VIRTUAL_UNIT_LABEL_SUFFIX } from "../../utils/CommonVariables";

export const useGetPropertyData = () => {
  const centres = useQuery(GetPropertiesDocument);
  const units = useQuery(GetUnitsDocument);

  return [centres, units] as const;
};

export const useGetVirtualUnits = () => {
  const virtualUnits = useQuery(GetVirtualUnitsDocument);
  return virtualUnits;
};

export const getVirtualUnitsByCentre = (
  centerId: string | undefined,
  virtualUnits: GetVirtualUnitsQuery | undefined
) => {
  const filteredVirtualUnits =
    virtualUnits?.VirtualUnits.filter(
      (unit) => unit.property_code === centerId
    ).map((unit) => ({
      label: `${unit.label}${VIRTUAL_UNIT_LABEL_SUFFIX}` as string,
      value: unit.id,
    })) ?? [];

  return filteredVirtualUnits;
};

export const getUnitsByCentre = (
  centre_id: string | undefined,
  units: QueryResult<
    GetUnitsQuery,
    Exact<{
      [key: string]: never;
    }>
  >
) => {
  const unitsByCentre =
    units.data?.Units.filter((unit) => unit.property_code === centre_id).map(
      (unit) => ({ label: unit.label as string, value: unit.UnitKey })
    ) ?? [];

  return unitsByCentre as { label: string; value: string }[];
};

export const useGetUnitTypes = () => {
  const getUnityTypes = useQuery(GetUnitTypesDocument);
  const filtered =
    getUnityTypes.data?.Units.filter((item) => item.UnitType?.match(/^\d/))
      .sort((a, b) => (a.UnitType ?? "").localeCompare(b.UnitType ?? ""))
      .map((item) => ({
        label: item.UnitType ?? "",
        value: item.UnitType ?? "",
      })) ?? [];
  return filtered;
};
