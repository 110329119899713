import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import { HTMLOptions, jsPDF } from "jspdf";
import { BreadCrumbs } from "../BreadCrumbs";
import { LeaseDetailsTable } from "./LeaseDetails";
import { LeaseIncentiveTable } from "./Incentive";
import { LeaseSecurityTable } from "./Security";
import { LeaseOtherTable } from "./Other";
import { LafOverview } from "./Overview";
import { LafUsage } from "./UsageDetails";
import { LafSpecialCondition } from "./SpecialCondition";
import { LafActionButtons } from "./LafActions";
import { LafComments } from "./Comments";
import { WHITE_ALPHA_08 } from "../../utils/CommonVariables";
import theme from "../../themes/theme";
import { GetLafDealDataDocument } from "../../generated/graphql-operations";
import {
  lafVersionTag,
  useCreateBlobSubmit,
  useGetLafVersion,
} from "../../adapters/Laf/Laf";
import { FitoutSection } from "./Fitout";
import { VirtualUnitSection } from "./VirtualUnitSection";

const PrintTheme = createTheme(theme, {
  typography: {
    button: {
      fontWeight: 400,
    },
    h6: {
      fontSize: "0.8rem",
      fontWeight: "500",
    },
    h4: {
      "@media (max-width:1400px)": {
        fontSize: "0.7rem",
      },
    },
    subtitle1: {
      fontWeight: "500",
    },
    body2: {
      fontSize: "0.475rem",
    },

    subtitle2: {
      fontSize: "0.475rem",
      fontWeight: "700",
    },
  },
});

export const LafData = (props: {
  dealId: number;
  printRef: React.ForwardedRef<unknown>;
  lafVersion: string;
}) => {
  const finalPrintLafRef = React.useRef<HTMLDivElement | null>(null);
  // TODO change this to be argument input into this comp DealData
  const { data } = useQuery(GetLafDealDataDocument, {
    variables: { deal_id: props.dealId },
  });
  const submitBlob = useCreateBlobSubmit(props.dealId);

  if (!data?.Deals_by_pk?.DealData[0].id) {
    return <Skeleton />;
  }

  const LafOverviewComp = <LafOverview dealId={props.dealId} />;
  const LeaseDetailComp = <LeaseDetailsTable dealId={props.dealId} />;
  const LeaseSecurityComp = <LeaseSecurityTable dealId={props.dealId} />;
  const LeaseIncentiveComp = <LeaseIncentiveTable dealId={props.dealId} />;
  const FitoutComp = <FitoutSection dealId={props.dealId} />;
  const OtherComp = <LeaseOtherTable dealId={props.dealId} />;
  const VirtualUnitComp = (
    <>
      <Typography p={2} variant="subtitle1">
        Virtual unit description
      </Typography>
      <Grid container p={2}>
        <VirtualUnitSection dealId={props.dealId} />
      </Grid>
    </>
  );
  const UsageComp = (
    <>
      <Typography p={2} variant="subtitle1">
        Usage details
      </Typography>
      <Grid container p={2}>
        <LafUsage dealId={props.dealId} />
      </Grid>
    </>
  );

  const SpecialConditionComp = (
    <>
      <Typography p={2} variant="subtitle1">
        Special conditions
      </Typography>

      <Grid container p={2}>
        <LafSpecialCondition dealId={props.dealId} />
      </Grid>
    </>
  );

  const CommentsComp = (
    <LafComments dealId={props.dealId} lafVersion={props.lafVersion} />
  );

  const isVirtual = !data?.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0]?.Unit;

  const LafPrintViewComp = (
    <Box p={2}>
      <Typography variant="subtitle1">{props.lafVersion}</Typography>
      {LafOverviewComp}
      <Typography variant="subtitle1">Lease details</Typography>
      {LeaseDetailComp}
      <Typography variant="subtitle1">Security</Typography>
      {LeaseSecurityComp}
      <Typography variant="subtitle1">Incentive details</Typography>
      {LeaseIncentiveComp}
      <Typography variant="subtitle1">Tenant works</Typography>
      {FitoutComp}
      <Typography variant="subtitle1">Other information</Typography>
      {OtherComp}
      {UsageComp}
      {isVirtual && VirtualUnitComp}
      {SpecialConditionComp}
      <Typography variant="subtitle1">Comments</Typography>
      {CommentsComp}
    </Box>
  );

  const getLafAndCreateFile = async () => {
    if (!finalPrintLafRef.current) return;

    const pdfWidth = 1920;

    const container = document.createElement("div");
    const timestampElement = document.createElement("div");

    timestampElement.textContent = `Final Approval Timestamp: ${new Date().toLocaleString(
      "en-AU"
    )}`;

    container.appendChild(timestampElement);
    container.appendChild(finalPrintLafRef.current);

    const pdfOptions: HTMLOptions = {
      html2canvas: {
        scale: 0.11,
      },
      autoPaging: "text",
      width: pdfWidth,
      windowWidth: pdfWidth,
    };

    // eslint-disable-next-line new-cap
    const myDoc = new jsPDF();
    await myDoc.html(container, pdfOptions);

    const blob = new Blob([myDoc.output("blob")], { type: "application/pdf" });
    await submitBlob(blob, props.lafVersion);
  };

  return (
    <>
      <Box>
        {LafOverviewComp}
        <Accordion defaultExpanded expanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Lease details</Typography>
          </AccordionSummary>
          <AccordionDetails>{LeaseDetailComp}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Security</Typography>
          </AccordionSummary>
          <AccordionDetails>{LeaseSecurityComp}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Incentive details</Typography>
          </AccordionSummary>
          <AccordionDetails>{LeaseIncentiveComp}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Tenant works</Typography>
          </AccordionSummary>
          <AccordionDetails>{FitoutComp}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Other information</Typography>
          </AccordionSummary>
          <AccordionDetails>{OtherComp}</AccordionDetails>
        </Accordion>
        <Card sx={{ mt: 2, pt: 0, pl: 0, pr: 0 }}>{UsageComp}</Card>
        {isVirtual && (
          <Card sx={{ mt: 2, pt: 0, pl: 0, pr: 0 }}>{VirtualUnitComp}</Card>
        )}
        <Card sx={{ mt: 2, pt: 0, pl: 0, pr: 0 }}> {SpecialConditionComp}</Card>
        <LafActionButtons
          dealId={props.dealId}
          dealDataId={data?.Deals_by_pk?.DealData[0].id}
          getLafAndCreateFile={getLafAndCreateFile}
        />
      </Box>
      {/* Need to overflow hide to not show it but still appear in print */}
      <Box style={{ overflow: "hidden", height: 0 }}>
        <Box ref={props.printRef}>
          <ThemeProvider theme={PrintTheme}>{LafPrintViewComp}</ThemeProvider>
        </Box>
        {/* This ref is for final approver file create, themeProvider makes writing too small */}
        <Box ref={finalPrintLafRef}>{LafPrintViewComp}</Box>
      </Box>
    </>
  );
};

export const LafTabs = (props: {
  dealId: number;
  printRef: React.ForwardedRef<unknown>;
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };
  const lafVersion = useGetLafVersion(props.dealId);

  // May require a .reverse() if want order of LAf and preLaf to be correct.
  const tabs = [{ label: lafVersionTag(lafVersion) }];

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{ backgroundColor: WHITE_ALPHA_08 }}
        TabIndicatorProps={{
          sx: { backgroundColor: theme.palette.text.primary },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            label={tab.label}
            sx={{
              color: theme.palette.text.primary,
              "&.Mui-selected": {
                backgroundColor: "white",
                color: theme.palette.text.primary,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            }}
          />
        ))}
      </Tabs>
      <Box p={2}>
        <LafData
          dealId={props.dealId}
          printRef={props.printRef}
          lafVersion={tabs[currentTab]?.label}
        />
      </Box>
    </>
  );
};

export const LafDetail = (props: { dealId: number }) => {
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs="auto">
            <Typography variant="h6">Deal #{props.dealId}</Typography>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={handlePrint}>
              <PrintIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <Button
              variant="outlined"
              href={`/deals/${props.dealId}`}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 3 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Deals", url: "/deals/" },
              { text: `${props.dealId}`, url: `/deals/${props.dealId}` },
              { text: "Lease approval form", url: "" },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ mt: 2, pt: 0, pl: 0, pr: 0 }}>
            <LafTabs dealId={props.dealId} printRef={componentRef} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
