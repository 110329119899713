import * as yup from "yup";

const GuarantorSchema = yup.object({
  fullName: yup.string().default("").required("Full name is required"),
  email: yup.string().default(""),
  address: yup.string().label("Address"),
  suburb: yup.string(),
  state: yup
    .object({
      label: yup.string(),
      value: yup.number(),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string(),
});

export const defaultGuarantor = GuarantorSchema.getDefault();

export const DepositSchema = yup.object({
  guarantorRequired: yup.string().oneOf(["yes", "no", "na"]),
  rentDepositMonths: yup
    .number()
    .integer("Rent deposit in months needs to be a whole number")
    .nullable(true),
  rentDeposit: yup.number().nullable(true),
  achievedRentDeposit: yup.number().nullable(true),
  bankGuaranteeRequired: yup.string().oneOf(["yes", "no", "na"]),
  bankGuaranteeMonths: yup
    .number()
    .integer("Bank guarantee in months needs to be a whole number")
    .nullable(true),
  bankGuaranteeAmount: yup.number().nullable(true),
  achievedBankGuarantee: yup.number().nullable(true),
  guarantors: yup.array().of(GuarantorSchema).required().default([]),
});

export type DepositType = yup.InferType<typeof DepositSchema>;
