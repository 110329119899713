import React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButtonMUI from "@mui/material/ToggleButton";
import { SxProps } from "@mui/system";

import theme from "../themes/theme";

interface ToggleButtonProps {
  name?: string;
  options: {
    text: string;
    id: string;
  }[];
  state: string;
  handleState: (
    event: React.MouseEvent<HTMLElement>,
    newState: string
  ) => Promise<void> | void;
  buttonGroupExtraSx?: SxProps;
  buttonExtraSx?: SxProps;
}

export const defaultToggleButtonSx: SxProps = {
  "& .MuiToggleButtonGroup-grouped": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.contrastText,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.contrastText,
        color: "white",
      },
    },
  },
};

export const ToggleButton = (props: ToggleButtonProps) => {
  return (
    <ToggleButtonGroup
      value={props.state}
      onChange={props.handleState}
      color="primary"
      exclusive
      fullWidth
      sx={{ ...defaultToggleButtonSx, ...props.buttonGroupExtraSx }}
    >
      {props.options.map((option) => (
        <ToggleButtonMUI
          id={props.name}
          key={option.id}
          value={option.id}
          fullWidth
          size="small"
          sx={{ fontWeight: 700, fontSize: 14, ...props.buttonExtraSx }}
        >
          {option.text}
        </ToggleButtonMUI>
      ))}
    </ToggleButtonGroup>
  );
};
