import React, { useEffect } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { Form, FormField } from "../../Form/Form";
import { FormToggleButton } from "../../Form/FormComponents";
import { TOGGLE_OPTIONS_TWO } from "../../../utils/CommonVariables";
import { CustomLicenceForm } from "./CustomLicenceForm";
import { Button } from "../../Button";
import { CreateButton } from "../../CreateButton";
import { Alert } from "../../Alert";
import { defaultCustomLicence } from "./StorageConfig";
import {
  transformData,
  useGetDealInformationStorageInformation,
} from "../../../adapters/DealInformation/Storage/Storage";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";

export const StorageInfo = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;

  const customLicencesFieldArray = useFieldArray({
    name: "storage.customLicences",
    control: form.control,
  });

  const storageInformation = useGetDealInformationStorageInformation(
    props.dealId
  );

  useEffect(() => {
    if (storageInformation.data) {
      const data = transformData(storageInformation.data);
      form.setValue("storage", data);
      form.setValue("storage.customLicences", data.customLicences);
      setDataState((prev) => ({ ...prev, storageLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, storageLoaded: false }));
    }
  }, [form, setDataState, storageInformation]);

  if (storageInformation.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!storageInformation.data) {
    return <Skeleton />;
  }

  const storageToggle = form.watch("storage.storageRoomLicenceRequired");
  const seatingToggle = form.watch("storage.seatingLicenceRequired");
  const signageToggle = form.watch("storage.signageLicenceRequired");
  const parkingToggle = form.watch("storage.parkingLicenceRequired");

  const storageFields: FormField[] = [
    {
      type: "text",
      fieldName: "storage.storageRent",
      label: "Storage rent (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
      disabled: storageToggle === "no",
    },
    {
      type: "number",
      fieldName: "storage.storageArea",
      label: "Storage area",
      helperText: "",
      adornment: {
        position: "start",
        value: "m\u00B2",
      },
      columns: 6,
      disabled: storageToggle === "no",
    },
  ];

  const seatingFields: FormField[] = [
    {
      type: "text",
      fieldName: "storage.seatingRent",
      label: "Seating rent (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
      disabled: seatingToggle === "no",
    },
    {
      type: "number",
      fieldName: "storage.seatingArea",
      label: "Seating area",
      helperText: "",
      adornment: {
        position: "start",
        value: "m\u00B2",
      },
      columns: 6,
      disabled: seatingToggle === "no",
    },
  ];

  const signageFields: FormField[] = [
    {
      type: "text",
      fieldName: "storage.signageRent",
      label: "Signage rent (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
      disabled: signageToggle === "no",
    },
    {
      type: "number",
      fieldName: "storage.numberOfSigns",
      label: "Number of signs",
      helperText: "",
      columns: 6,
      disabled: signageToggle === "no",
    },
    {
      type: "text",
      fieldName: "storage.signageLocation",
      label: "Signage location",
      helperText: "",
      columns: 12,
      disabled: signageToggle === "no",
    },
  ];

  const parkingFields: FormField[] = [
    {
      type: "text",
      fieldName: "storage.parkingRent",
      label: "Parking rent (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
      disabled: parkingToggle === "no",
    },
    {
      type: "number",
      fieldName: "storage.numberOfCarparks",
      label: "Number of car parks",
      helperText: "",
      columns: 6,
      disabled: parkingToggle === "no",
    },
    {
      type: "text",
      fieldName: "storage.parkingLocation",
      label: "Parking location",
      helperText: "",
      columns: 12,
      disabled: parkingToggle === "no",
    },
  ];

  return (
    <>
      <Grid item sm={4}>
        <Typography variant="subtitle2">
          Storage room licence required
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <FormToggleButton
          name="storage.storageRoomLicenceRequired"
          control={form.control}
          options={TOGGLE_OPTIONS_TWO}
          defaultValue="no"
          onChange={() => {
            props.form.setValue("storage.storageRent", undefined);
            props.form.setValue("storage.storageArea", undefined);
          }}
        />
      </Grid>
      <Form form={form} fields={storageFields} />

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Seating licence required</Typography>
      </Grid>
      <Grid item sm={2} mt={4}>
        <FormToggleButton
          name="storage.seatingLicenceRequired"
          control={form.control}
          options={TOGGLE_OPTIONS_TWO}
          defaultValue="no"
          onChange={() => {
            props.form.setValue("storage.seatingRent", undefined);
            props.form.setValue("storage.seatingArea", undefined);
          }}
        />
      </Grid>
      <Form form={form} fields={seatingFields} />

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Signage licence required</Typography>
      </Grid>
      <Grid item sm={2} mt={4}>
        <FormToggleButton
          name="storage.signageLicenceRequired"
          control={form.control}
          options={TOGGLE_OPTIONS_TWO}
          defaultValue="no"
          onChange={() => {
            props.form.setValue("storage.signageRent", undefined);
            props.form.setValue("storage.numberOfSigns", undefined);
            props.form.setValue("storage.signageLocation", "");
          }}
        />
      </Grid>
      <Form form={form} fields={signageFields} />

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Parking licence required</Typography>
      </Grid>
      <Grid item sm={2} mt={4}>
        <FormToggleButton
          name="storage.parkingLicenceRequired"
          control={form.control}
          options={TOGGLE_OPTIONS_TWO}
          defaultValue="no"
          onChange={() => {
            props.form.setValue("storage.parkingRent", undefined);
            props.form.setValue("storage.numberOfCarparks", undefined);
            props.form.setValue("storage.parkingLocation", "");
          }}
        />
      </Grid>
      <Form form={form} fields={parkingFields} />

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Custom licences</Typography>
      </Grid>

      <Box width="100%" />

      <>
        {customLicencesFieldArray.fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid item sm={12} p={1}>
              <CustomLicenceForm form={props.form} index={index} />
              <Grid container justifyContent="flex-end">
                <Grid item p={1}>
                  <Button
                    startIcon={<Delete />}
                    color="error"
                    variant="text"
                    onClick={() => {
                      customLicencesFieldArray.remove(index);
                    }}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item p={1}>
          <CreateButton
            startIcon={<Add />}
            color="primary"
            variant="text"
            onClick={() => {
              customLicencesFieldArray.append(defaultCustomLicence);
            }}
          >
            Add custom licence
          </CreateButton>
        </Grid>
      </>
    </>
  );
};
