import React, { useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Delete, Edit, Email, MoreVert } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Popover, Stack } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetAllDealContactsListDocument } from "../../../../generated/graphql-operations";
import theme from "../../../../themes/theme";
import { DealContactDialog, DealContactDialogType } from "./DealContactDialog";
import { ConfirmationDialog } from "../../../ConfirmationDialog";
import {
  transformDealContacts,
  useDeleteDealContact,
} from "../../../../adapters/Deal/Contacts";
import { Role, useReturnUserRole } from "../../../../contexts/Auth/UserRole";

export interface DealContactsListProps {
  toolBar?: boolean;
  hideColumns?: GridColumnVisibilityModel;
  dealId: number;
  dealDisabled: boolean;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

const RowDataOptionPopUp = (props: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleOpenEdit: () => void;
  handleDelete: () => void;
  data: (DealContactDialogType & { email?: string }) | undefined;
}) => (
  <Popover
    open={props.open}
    anchorEl={props.anchorEl}
    onClose={props.handleClose}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  >
    <Grid container pl={1} pr={1}>
      <Grid item xs={4}>
        <Button
          startIcon={<Email />}
          LinkComponent="a"
          href={`mailto:${props.data?.email}`}
        >
          Email
        </Button>
      </Grid>

      {!props.data?.isTenant && (
        <>
          <Grid item xs={4}>
            <Button startIcon={<Edit />} onClick={props.handleOpenEdit}>
              Edit
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              sx={{ color: theme.palette.error.main }}
              startIcon={<Delete sx={{ color: theme.palette.error.main }} />}
              onClick={props.handleDelete}
            >
              Delete
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  </Popover>
);

export const DealContactsList = ({
  toolBar,
  hideColumns = undefined,
  dealId,
  dealDisabled,
}: DealContactsListProps) => {
  const { data, loading, error } = useQuery(GetAllDealContactsListDocument, {
    variables: { deal_id: dealId },
  });
  const currentRole = useReturnUserRole();
  const isReadOnly = currentRole === Role.ReadOnly;

  const transformData = transformDealContacts(data);

  const deleteDealContact = useDeleteDealContact();

  const [openNew, setOpenNew] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [contactData, setContactData] = useState<
    DealContactDialogType & { email?: string }
  >();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const columns: GridColDef<typeof transformData[number]>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "settings",
      headerName: "",
      flex: 1,
      maxWidth: 60,
      renderCell: (params) => (
        <IconButton
          disabled={dealDisabled}
          onClick={(e) => {
            setContactData({
              contact: {
                label: params.row.name,
                value: params.row.id,
              },
              type: [params.row.type],
              email: params.row.email,
              isTenant: params.row.isTenant,
            });
            handleClick(e);
          }}
        >
          <MoreVert sx={{ cursor: "pointer" }} color="primary" />
        </IconButton>
      ),
    },
  ];
  return (
    <>
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .tenant-row": {
            backgroundColor: "lightgray",
            "&:hover": {
              backgroundColor: "lightgray",
            },
          },
        }}
      >
        <DataGrid
          rows={transformData}
          columns={columns}
          loading={loading || error !== undefined}
          columnVisibilityModel={hideColumns}
          components={toolBar ? { Toolbar: CustomToolbar } : {}}
          getRowClassName={(params) =>
            params.row.isTenant ? "tenant-row" : ""
          }
          isRowSelectable={(params) => !params.row.isTenant}
        />
      </Box>

      <RowDataOptionPopUp
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        data={contactData}
        handleDelete={() => setConfirmationOpen(true)}
        handleOpenEdit={() => setEditOpen(true)}
      />

      <DealContactDialog
        open={editOpen}
        setOpen={setEditOpen}
        type="update"
        dealId={dealId}
        data={contactData}
        // contacts not used for update
        currentContactsIds={[]}
      />

      <ConfirmationDialog
        title="Delete confirmation"
        description="Are you sure you want to remove contact from deal?"
        confirmText="Yes"
        declineText="No"
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        onConfirm={async () => {
          if (contactData?.contact.value) {
            await deleteDealContact(contactData?.contact.value, dealId);
          }
          handleClose();
        }}
      />

      <Grid>
        <Button
          variant="outlined"
          sx={{ mt: 3 }}
          onClick={() => {
            setOpenNew(true);
          }}
          disabled={dealDisabled || isReadOnly}
        >
          Add contact to deal
        </Button>
      </Grid>

      <DealContactDialog
        open={openNew}
        setOpen={setOpenNew}
        type="new"
        dealId={dealId}
        currentContactsIds={transformData.map((item) => item.id)}
      />
    </>
  );
};
