import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, Typography, Chip, Skeleton } from "@mui/material";
import { GetLafSpecialConditionDocument } from "../../generated/graphql-operations";
import { Alert } from "../Alert";
import { ReadOnlyEditor } from "../Form/RichTextEditor";

export const LafSpecialCondition = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafSpecialConditionDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data) {
    return (
      <Skeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width="100%"
        height={100}
      />
    );
  }

  return (
    <Grid container columns={{ xs: 12 }} justifyContent="space-between">
      {data.Deals_by_pk?.DealData[0].DealSpecialConditions.map((item) => {
        return (
          <Grid xs={12} item pb={2}>
            <Chip sx={{ ml: -1 }} label={item.title} />
            <Typography>Description:</Typography>
            <ReadOnlyEditor content={item.laf_description ?? ""} />
          </Grid>
        );
      })}
    </Grid>
  );
};
