import React from "react";
import { Card, Typography, useTheme, Grid, Link } from "@mui/material";

import { GetDealLinksQuery } from "../../../../generated/graphql-operations";
import { BLACK_ALPHA_60 } from "../../../../utils/CommonVariables";

export const LinksCard = (props: {
  data: GetDealLinksQuery["DealLinks"][number];
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        pt: 2,
        pl: 2,
        pb: 0,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Grid container alignItems="flex-start">
        <Grid item sm={10} sx={{ mb: 1 }}>
          <Typography variant="subtitle1" color={theme.palette.text.primary}>
            Old LAF approved deal
          </Typography>
          <Typography variant="body2" color={BLACK_ALPHA_60}>
            {`Version: ${props.data?.dealByDealLinkId.laf_version}`}
          </Typography>
          <Link
            underline="hover"
            color="primary"
            href={`/deals/${props.data.dealByDealLinkId.id}`}
          >
            View Deal
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};
