import React from "react";

import { Grid, Paper } from "@mui/material";
import { Title } from "../../../themes/ThemeComponents";
import { DealProps } from "../DealConfig";
import { NewDeal } from "./TenantInformation/TenantInformation";

export const StepOne = (props: DealProps) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ m: "0 auto" }}
    >
      <Paper sx={{ m: 2, p: 2, width: "60%" }} elevation={10}>
        <Grid container xs={12}>
          <Grid xs={12} m={1}>
            <Title variant="h6"> Create a new deal </Title>
          </Grid>
          <Grid xs={12} md={12} m={1}>
            <NewDeal {...props} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
