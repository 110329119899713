import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Form, FormField } from "../../Form/Form";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import { DealInformationType } from "../DealInformationConfig";

interface CustomLicenceFormProps {
  form: UseFormReturn<DealInformationType, any>;
  index: number;
}

export const CustomLicenceForm = (props: CustomLicenceFormProps) => {
  const customLicenceFields: FormField[] = [
    {
      type: "text",
      fieldName: `storage.customLicences.${props.index}.licenceName` as const,
      label: "Licence name",
      helperText: "Mandatory",
      columns: 6,
    },
    {
      type: "text",
      fieldName: `storage.customLicences.${props.index}.licenceRent` as const,
      label: "Licence rent (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
    {
      type: "text",
      fieldName: `storage.customLicences.${props.index}.description` as const,
      label: "Description",
      helperText: "",
      columns: 12,
    },
  ];

  return <Form form={props.form} fields={customLicenceFields} />;
};
