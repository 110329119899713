import * as yup from "yup";

export const SpecialConditionSchema = yup.object({
  title: yup.string().required(),
  priority: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value))),
  description: yup.string(),
  descriptionLaf: yup.string(),
});

export type SpecialConditionType = yup.InferType<typeof SpecialConditionSchema>;
