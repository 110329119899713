import React, { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import {
  transformUnitInformation,
  useGetDealInfoUnitInfo,
} from "../../../adapters/DealInformation/UnitInformation/UnitInformation";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { Form, FormField } from "../../Form/Form";
import { FormSwitch, TextMultiField } from "../../Form/FormComponents";
import {
  getUnitsByCentre,
  getVirtualUnitsByCentre,
  useGetPropertyData,
  useGetUnitTypes,
  useGetVirtualUnits,
} from "../../../adapters/Properties/Properties";
import { Alert } from "../../Alert";
import {
  DealType,
  VIRTUAL_UNIT_LABEL,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../../../utils/CommonVariables";

export const UnitInformation = (props: DealInformationSectionProps) => {
  const unitInfo = useGetDealInfoUnitInfo(props.dealId);
  const units = useGetPropertyData()[1];
  const unitTypes = useGetUnitTypes();
  const virtualUnits = useGetVirtualUnits();

  const { form, setDataState } = props;

  useEffect(() => {
    if (unitInfo.data) {
      const unitInformation = transformUnitInformation(unitInfo.data);
      form.setValue("unitInfo", unitInformation);
      setDataState((prev) => ({ ...prev, unitInfoLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, unitInfoLoaded: false }));
    }
  }, [unitInfo, form, setDataState]);

  if (unitInfo.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!unitInfo.data) {
    return <Skeleton />;
  }
  const filteredVirtualUnits = getVirtualUnitsByCentre(
    props.form.getValues("unitInfo.centre")?.value,
    virtualUnits.data
  );

  const unitChoices = [
    { label: VIRTUAL_UNIT_LABEL, value: "" },
    ...getUnitsByCentre(props.form.getValues("unitInfo.centre")?.value, units),
    ...filteredVirtualUnits,
  ];

  const centreFields: FormField[] = [
    {
      type: "select",
      fieldName: "unitInfo.centre",
      label: "Centre",
      helperText: "Mandatory",
      columns: 12,
      disabled: true,
      choices: [],
    },
  ];

  const unitFields: FormField[] = [
    {
      type: "select",
      fieldName: "unitInfo.unit",
      label: "Unit",
      helperText: "Mandatory",
      columns: 6,
      choices: unitChoices,
      disabled:
        props.form.watch("dealType")?.dealType?.label === DealType.Renewal,
      onChange: (_, data) => {
        const isVirtualUnit =
          data.label.includes(VIRTUAL_UNIT_LABEL_SUFFIX) ||
          data.label.includes(VIRTUAL_UNIT_LABEL);
        form.setValue("unitInfo.isVirtual", isVirtualUnit, {
          shouldValidate: true,
        });
        if (isVirtualUnit) {
          const selectedVirtualUnit = virtualUnits.data?.VirtualUnits.find(
            (unit) => unit.id === data.value
          );
          form.setValue(
            "unitInfo.estimatedArea",
            selectedVirtualUnit?.estimated_area ?? 0,
            { shouldValidate: true }
          );
        } else {
          const selectedUnit = units.data?.Units.find(
            (unit) => unit.UnitKey === data.value
          );
          form.setValue(
            "unitInfo.estimatedArea",
            parseInt(selectedUnit?.estimated_area ?? "0", 10),
            { shouldValidate: true }
          );
        }
      },
    },
    {
      type: "number",
      fieldName: "unitInfo.estimatedArea",
      label: "Estimated area",
      helperText: "Mandatory",
      columns: 6,
      disabled: form.watch("unitInfo.unit")?.label !== VIRTUAL_UNIT_LABEL,
      adornment: {
        value: "m\u00B2",
        position: "start",
      },
    },
  ];

  const virtualUnitDescriptionField = {
    type: "text",
    fieldName: "unitInfo.virtualUnitDescription",
    label: "Virtual unit description",
    helperText: "",
    columns: 6,
  };

  const virtualUnitFields: FormField[] = [
    {
      type: "text",
      fieldName: "unitInfo.virtualUnit",
      label: "Virtual unit name",
      helperText: "",
      columns: 6,
    },
    {
      type: "select",
      fieldName: "unitInfo.unitType",
      label: `Virtual unit type`,
      helperText: "",
      choices: unitTypes,
      columns: 6,
    },
  ];

  return (
    <>
      <Form form={form} fields={centreFields} />

      <FormSwitch
        control={form.control}
        name="centreUnderDevelopment"
        title="Centre under development"
        disabled
      />

      <Box width="100%" />

      <FormSwitch
        control={form.control}
        name="unitInfo.isVirtual"
        title={VIRTUAL_UNIT_LABEL}
        disabled
      />

      <Form form={form} fields={unitFields} />

      <FormSwitch
        control={form.control}
        name="unitInfo.areaSurveyRequired"
        title="Area survey required"
      />

      {form.watch("unitInfo.unit")?.label === VIRTUAL_UNIT_LABEL ? (
        <Form form={form} fields={virtualUnitFields} />
      ) : null}

      {form.watch("unitInfo.isVirtual") && (
        <Box width="100%" mb={2}>
          <TextMultiField
            control={form.control}
            formField={virtualUnitDescriptionField}
          />
        </Box>
      )}
    </>
  );
};
