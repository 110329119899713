import { useMutation } from "@apollo/client";
import {
  AddDealShopActivityDocument,
  DealShopActivities_Insert_Input,
  GetOpenDateFromShopHandoverActivitiesDocument,
} from "../../generated/graphql-operations";
import { useCreateTenantFile } from "./Files";

export const useCreateDealShopWithFileNotification: {
  (
    dealId: number,
    tenantCode: undefined | null,
    selectedFile: File | undefined
  ): (insertObj: DealShopActivities_Insert_Input) => Promise<void>;
  (
    dealId: null | undefined,
    tenantCode: string,
    selectedFile: File | undefined
  ): (insertObj: DealShopActivities_Insert_Input) => Promise<void>;
} = (
  dealId: number | null | undefined,
  tenantCode: string | null | undefined,
  selectedFile: File | undefined
) => {
  const [AddDealShopActivity] = useMutation(AddDealShopActivityDocument);

  const onFileSubmit = useCreateTenantFile(dealId, tenantCode, selectedFile);

  return async (insertObj: DealShopActivities_Insert_Input) => {
    let filePath = null;
    if (insertObj.file_name) {
      filePath = await onFileSubmit({
        fileName: insertObj.file_name,
        tags: [],
      });
    }

    await AddDealShopActivity({
      variables: {
        input: {
          file_path: filePath,
          ...insertObj,
        },
      },
    });
  };
};

export const useCreateDealShopNotification = () => {
  const [AddDealShopActivity] = useMutation(AddDealShopActivityDocument, {
    refetchQueries: [GetOpenDateFromShopHandoverActivitiesDocument],
  });

  return async (insertObj: DealShopActivities_Insert_Input) => {
    await AddDealShopActivity({
      variables: {
        input: {
          ...insertObj,
        },
      },
    });
  };
};
