import React from "react";
import DOMPurify from "dompurify";
import { EditorContent, Editor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import {
  Card,
  useTheme,
  IconButton,
  Stack,
  InputLabel,
  Divider,
} from "@mui/material";
import { TAG_SPACE_CHAR } from "../../utils/CommonVariables";

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="flex-start">
      <IconButton onClick={() => editor.chain().focus().toggleBold().run()}>
        <FormatBoldIcon
          color={editor.isActive("bold") ? "inherit" : "disabled"}
          fontSize="small"
        />
      </IconButton>
      <IconButton onClick={() => editor.chain().focus().toggleItalic().run()}>
        <FormatItalicIcon
          color={editor.isActive("italic") ? "inherit" : "disabled"}
          fontSize="small"
        />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <FormatUnderlinedIcon
          color={editor.isActive("underline") ? "inherit" : "disabled"}
          fontSize="small"
        />
      </IconButton>
      <Divider orientation="vertical" variant="middle" flexItem />
      <IconButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FormatListBulletedIcon
          color={editor.isActive("bulletList") ? "inherit" : "disabled"}
          fontSize="small"
        />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FormatListNumberedIcon
          color={editor.isActive("orderedList") ? "inherit" : "disabled"}
          fontSize="small"
        />
      </IconButton>
    </Stack>
  );
};

const EditorForm = ({
  label,
  editor,
  height = 160,
}: {
  label: string;
  editor: Editor | null;
  height?: number;
}) => {
  const theme = useTheme();

  return (
    <>
      <InputLabel>{label || TAG_SPACE_CHAR}</InputLabel>
      <Card
        onClick={() => editor?.commands.focus()}
        sx={{
          p: 0,
          py: -1,
          px: 2,
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.primary,
          overflow: "auto",
          maxHeight: height,
          height,
          ":hover": {
            backgroundColor: theme.palette.action.focus,
          },
          ":focus-within": { backgroundColor: theme.palette.action.focus },
        }}
      >
        <EditorContent editor={editor} />
      </Card>
      <MenuBar editor={editor} />{" "}
    </>
  );
};

export const EditorFormWithEditor = (props: {
  label: string;
  content: string;
  onChange: (...event: any[]) => void;
  disable?: boolean;
  height?: number;
}) => {
  const editor = useEditor({
    editable: props.disable ?? true,
    extensions: [StarterKit, Underline],
    // Note doing an onUpdate triggers
    // The use effect and sets cursor to end of the text
    // So have to fix that if we also want to update onChange
    onBlur: ({ editor: editorModel }) => {
      props.onChange(editorModel?.getHTML());
    },

    content: props.content,
  });

  React.useEffect(() => {
    if (props.content !== editor?.getHTML()) {
      editor?.commands.setContent(props.content, false, {});
    }
  }, [props.content, editor]);

  React.useEffect(() => {
    editor?.setEditable(!props.disable);
  }, [props.disable, editor]);

  return (
    <EditorForm editor={editor} label={props.label} height={props.height} />
  );
};

/* Editor used to convert html from database, to react component */
export const ReadOnlyEditor = (props: { content: string }) => {
  // Make sure html content doesn't contain any xss attack
  const cleanContent = DOMPurify.sanitize(props.content);
  const editor = useEditor({
    editable: false,
    extensions: [StarterKit, Underline],

    content: cleanContent,
  });

  return <EditorContent editor={editor} />;
};
