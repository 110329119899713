import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { parseISO } from "date-fns";
import { useQuery } from "@apollo/client";
import { Chip, Stack } from "@mui/material";
import {
  GetDealsByContactDocument,
  GetDealsByContactQuery,
} from "../../generated/graphql-operations";
import { checkAndFormatDate } from "../../utils/DateUtils";
import {
  statusSxColor,
  formatDealStatusText,
} from "../../utils/CommonFunctions";

export interface ContactDealsListProps {
  toolBar: boolean;
  contactId: number;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const ContactDealsList = ({
  toolBar,
  contactId,
  hideColumns = undefined,
}: ContactDealsListProps) => {
  const { data, loading, error } = useQuery(GetDealsByContactDocument, {
    variables: { contact_id: contactId },
  });
  const navigate = useNavigate();
  const columns: GridColDef<
    NonNullable<GetDealsByContactQuery["DealsContacts"][0]>
  >[] = [
    {
      field: "centre",
      headerName: "Centre",
      flex: 2,
      valueGetter: (params) =>
        params.row.Deal?.DealData?.[0]?.DealsUnits?.[0]?.Unit?.Property?.label,
    },
    {
      field: "shop",
      headerName: "Shop",
      flex: 1,
      valueGetter: (params) =>
        params.row.Deal?.DealData?.[0]?.DealsUnits?.[0]?.Unit?.label,
    },
    {
      field: "trading_name",
      headerName: "Trading name",
      flex: 1,
      valueGetter: (params) => params.row.Deal?.trading_name,
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
      valueGetter: (params) => params.row.Deal?.DealPhases?.[0]?.name,
    },
    {
      field: "lease_start",
      headerName: "Lease start date",
      flex: 1,
      type: "date",
      valueFormatter: (params) =>
        checkAndFormatDate(parseISO(`${params.value}Z`), "dd/MM/yyyy"),
      valueGetter: (params) => params.row.Deal?.DealData?.[0]?.lease_start,
    },
    {
      field: "deal_owner",
      headerName: "Deal owner",
      flex: 1,
      valueGetter: (params) => params.row.Deal?.Owner?.user_full_name,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => params.row.Deal?.status,
      renderCell: (params) => (
        <Chip
          label={formatDealStatusText(params.value)}
          sx={statusSxColor(params.value)}
        />
      ),
    },
  ];
  return (
    <DataGrid
      rows={data?.DealsContacts || []}
      columns={columns}
      loading={loading || error !== undefined}
      columnVisibilityModel={hideColumns}
      getRowId={(row) => row.Deal?.id ?? 0}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
      onCellClick={(params, event) => {
        if (!event.ctrlKey) {
          navigate(`/Deals/${params.row.Deal?.id}`);
        }
      }}
    />
  );
};
