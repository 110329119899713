import { useMutation, useLazyQuery } from "@apollo/client";
import {
  AddDealBusinessContactDocument,
  GetDealContactsDocument,
  CreateDealContactsTagsDocument,
  GetDealContactsTagsDocument,
  GetAllDealContactsListDocument,
  GetAllDealContactsListQuery,
  UpdateDealBusinessContactDocument,
  DeleteDealBusinessContactDocument,
} from "../../generated/graphql-operations";

const getContactInfo = (
  item:
    | GetAllDealContactsListQuery["DealBusinessContacts"][number]
    | GetAllDealContactsListQuery["DealsContacts"][number]
) => ({
  id: item.Contact?.id,
  postion: JSON.parse(item.Contact?.content ?? "{}").position ?? "",
  type: item.tag ?? "",
  email: item.Contact?.email ?? "",
  phone: item.Contact?.phone ?? "",
  name: `${item.Contact?.first_name} ${item.Contact?.middle_name ?? ""} ${
    item.Contact?.last_name
  }`,
});

export const transformDealContacts = (
  data: GetAllDealContactsListQuery | undefined
) => {
  const TenantContact =
    data?.DealsContacts.map((item) => ({
      isTenant: true,
      ...getContactInfo(item),
    })) ?? [];

  const businessContacts =
    data?.DealBusinessContacts.map((item) => ({
      isTenant: false,
      ...getContactInfo(item),
    })) ?? [];

  return [...TenantContact, ...businessContacts];
};

export const useAddDealContact = () => {
  const [AddDealContacts] = useMutation(AddDealBusinessContactDocument);
  const [CreateDealsContactTags] = useMutation(CreateDealContactsTagsDocument);
  const [getTenantContacts] = useLazyQuery(GetDealContactsDocument);

  const addDealContact = async (
    contactId: number,
    dealId: number,
    contactTag: string
  ) => {
    const tenantContacts = await getTenantContacts({
      variables: { deal_id: dealId },
    });

    if (
      tenantContacts.data?.DealsContacts.some(
        (item) => item.Contact?.id === contactId
      )
    ) {
      throw Error("This contact is an already existing Tenant.");
    }

    const dealContact = {
      contact_id: contactId,
      deal_id: dealId,
      tag: contactTag,
    };

    await AddDealContacts({
      variables: { dealContacts: dealContact },
      refetchQueries: [GetAllDealContactsListDocument],
    });

    if (contactTag !== "") {
      await CreateDealsContactTags({
        variables: { objects: { name: contactTag } },
        refetchQueries: [GetDealContactsTagsDocument],
      });
    }
  };

  return addDealContact;
};

export const useUpdateDealContact = () => {
  const [UpdateDealContact] = useMutation(UpdateDealBusinessContactDocument);
  const [CreateDealsContactTags] = useMutation(CreateDealContactsTagsDocument);

  const updateDealContact = async (
    contactId: number,
    dealId: number,
    contactTag: string
  ) => {
    await UpdateDealContact({
      variables: {
        deal_id: dealId,
        contact_id: contactId,
        _set: { tag: contactTag },
      },
      refetchQueries: [GetAllDealContactsListDocument],
    });

    if (contactTag !== "") {
      await CreateDealsContactTags({
        variables: { objects: { name: contactTag } },
        refetchQueries: [GetDealContactsTagsDocument],
      });
    }
  };

  return updateDealContact;
};

export const useDeleteDealContact = () => {
  const [DeleteDealContact] = useMutation(DeleteDealBusinessContactDocument);

  const deleteDealContact = async (contactId: number, dealId: number) => {
    await DeleteDealContact({
      variables: {
        deal_id: dealId,
        contact_id: contactId,
      },
      refetchQueries: [GetAllDealContactsListDocument],
    });
  };

  return deleteDealContact;
};
