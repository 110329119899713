import * as yup from "yup";

import { YupPhoneValidation } from "../MuiCustomNumberFormat";
import {
  INVALID_NUMBER,
  GREATER_THAN_0,
  INTEGER_VALUE,
} from "../../utils/CommonErrors";

import { NO_OPTION_USE } from "../DealInformation/Usage/Config";
import {
  DealType,
  PromotionLevyTypes,
  VIRTUAL_UNIT_LABEL,
} from "../../utils/CommonVariables";

interface TestContextExtended {
  originalValue?: {
    label: string;
  };
}

export interface RenewalData {
  salesCategory: string;
  leaseType: string;
}

export interface StepOneData {
  dealType: string;
  renewal: RenewalData;
  tenantInfo: TenantInformationType;
}

// Note: object().shape causes type errors
export const DealTermSchema = yup.object({
  centre: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .required("Centre is required")
    .default({ label: "", value: "" })
    .test("NoCentre", "Must select Centre", (value) => value.value !== ""),
  unit: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .default({ label: "", value: "" })
    .test("NoUnit", "Must select Unit", (value, testContext) => {
      const { originalValue } = testContext as yup.TestContext &
        TestContextExtended;
      if (originalValue!.label === VIRTUAL_UNIT_LABEL) {
        return true;
      }
      return value.value !== "";
    })
    .default({ label: "", value: "" }),
  virtualUnit: yup
    .string()
    .when("unit", {
      is: (unit: { label: string; value: string }) =>
        unit?.label === VIRTUAL_UNIT_LABEL,
      then: (schema) =>
        schema.required(`${VIRTUAL_UNIT_LABEL} Name is required`),
      otherwise: (schema) => schema,
    })
    .default(""),
  unitType: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .when("unit", {
      is: (unit: { label: string; value: string }) =>
        unit?.label === VIRTUAL_UNIT_LABEL,
      then: (schema) =>
        schema.test(
          "NoUnitType",
          "Must select Unit Type",
          (value) => value.value !== ""
        ),
      otherwise: (schema) => schema,
    })
    .default({ label: "", value: "" }),
  virtualUnitDescription: yup.string().default(""),
  estimatedArea: yup
    .string()
    .required("Estimated area is required")
    .default(""),
  leaseCommenecementDate: yup
    .date()
    .required("Lease commencement date is required")
    .typeError("Invalid Date")
    .default(() => null),
  fitoutPeriodWeeks: yup
    .string()
    .required("Fitout period weeks is required")
    .default(""),
  fitoutPeriodDays: yup.string().default(""),
  termYear: yup
    .number()
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE)
    .typeError(INVALID_NUMBER)
    .required("Term year is required")
    .default(1),
  termMonth: yup
    .number()
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE)
    .typeError(INVALID_NUMBER)
    .required()
    .default(0),
  termDay: yup
    .number()
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE)
    .typeError(INVALID_NUMBER)
    .required()
    .default(0),
  baseRent: yup
    .number()
    .min(0)
    .required("Base rent is required")
    .default(0)
    .typeError("Base rent must be a number"),
  rentReviewAnnualIncrease: yup
    .number()
    .min(0)
    .required("Rent review annual increase is required")
    .default(4)
    .typeError("Rent review annual increase must be a number"),
  turnoverRentToggle: yup.string().oneOf(["yes", "no", "na"]).default("yes"),
  turnover: yup
    .number()
    .when("turnoverRentToggle", {
      is: "yes",
      then: (schema) =>
        schema
          .typeError("Turnover must be a number")
          .min(0)
          .required("Turnover is required"),
      otherwise: (schema) => schema,
    })
    .default(10),
  promotionLevyToggle: yup.string().oneOf(["yes", "no", "na"]).default("yes"),
  promotionLevyType: yup
    .object({
      label: yup.string().oneOf(Object.keys(PromotionLevyTypes)).required(),
      value: yup.string().oneOf(Object.values(PromotionLevyTypes)).required(),
    })
    .default({ label: "%", value: "%" })
    .required(),
  promotionLevy: yup
    .number()
    .min(0)
    .required("Promotion amount is required")
    .default(5)
    .typeError("Promotion amount must be a number"),
  outgoingsToggle: yup.string().oneOf(["yes", "no", "na"]).default("yes"),
  ratePerSquareMetre: yup
    .number()
    .min(0)
    .default(0.0)
    .typeError("Rate per square metre must be a number"),
  permittedUse: yup
    .object({ label: yup.string(), value: yup.number() })
    .required()
    .default(NO_OPTION_USE),
  permittedUsePreview: yup.string().default(""),
  guarantorsToggle: yup.string().oneOf(["yes", "no", "na"]).default("yes"),
  bankGuaranteeToggle: yup.string().oneOf(["yes", "no", "na"]).default("yes"),
  guaranteeAmount: yup
    .number()
    .required("Guarantee amount is required")
    .default(4)
    .min(1, "Guarantee amount cannot be less than 1 month")
    .typeError("Guarantee amount must be a number"),
  rentalDeposit: yup.bool().default(true),
  landlordWorksToggle: yup.bool().default(true),
  landlordWorks: yup
    .object({ label: yup.string(), value: yup.string() })
    .required()
    .default({ label: "Other", value: "other" }),
  landlordWorksPreview: yup.string().default(""),
  tenantWorksToggle: yup.bool().default(false),
  tenantWorks: yup.array().of(yup.string()).required().default([]),
  incentiveAmount: yup.number().nullable().default(null),
  fitoutContributionAmount: yup.number().nullable().default(null),
  fitoutDescription: yup.string(),
  hoardingToggle: yup
    .string()
    .oneOf(["landlord", "tenant", "split", "na"])
    .default("landlord"),
  skinsToggle: yup
    .string()
    .oneOf(["landlord", "tenant", "split", "na"])
    .default("landlord"),
  tenancyCoordinationFeeToggle: yup.bool().default(true),
  specialConditions: yup.array().of(yup.string()).required().default([]),
  selectedSpecialConditions: yup
    .array()
    .of(yup.string())
    .required()
    .default([]),
  vacantPossession: yup.bool().default(true),
  subjectToLandlordBoardApproval: yup.bool().default(true),
  surrenderOfExistingPremises: yup.bool().default(false),
  licensedSeatingArea: yup.bool().default(false),
  licensedStorageArea: yup.bool().default(false),
});

export const PermittedUseSchema = yup.object({
  permittedUse: yup
    .object({ label: yup.string(), value: yup.number() })
    .required()
    .default(NO_OPTION_USE),
  permittedUsePreview: yup.string().default(""),
});

export const LandlordWorksSchema = yup.object({
  landlordWorks: yup
    .object({ label: yup.string(), value: yup.string() })
    .required()
    .default({ label: "Other", value: "other" }),
  landlordWorksPreview: yup.string().default(""),
});

export const IndividualSchema = yup.object({
  tenant: yup
    .object({
      label: yup.string().required().default(""),
      value: yup.number().required().default(-1),
    })
    .default({ label: "", value: -1 })
    .test(
      "NoTenant",
      "Must select Tenant",
      (value) => !(value.value === -1 && value.label === "")
    ),
  id: yup.number().default(-1),
  label: yup.string().default(""),
  firstName: yup
    .string()
    .required("First name is a required field")
    .default(""),
  middleName: yup.string().default(""),
  lastName: yup.string().required("Last name is a required field").default(""),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  address: yup.string().default(""),
  city: yup.string().default(""),
  state: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string().default(""),
  addressId: yup.number().default(-1),
});

export const CompanySchema = yup.object({
  company: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .required("Company is required")
    .default({ label: "", value: -1 })
    .test(
      "NoCompany",
      "Must select Company",
      (value) => !(value.value === -1 && value.label === "")
    ),
  legalName: yup
    .string()
    .required("Legal name is a required field")
    .default(""),
  acn: yup
    .string()
    .test(
      "AcnLength",
      "ACN must be 9 digits",
      (val) => val?.length === 0 || val?.length === 9
    )
    .label("ACN")
    .default(""),
  contactSelect: yup
    .object({
      label: yup.string().required().default(""),
      value: yup.number().required().default(-1),
    })
    .required("Contact is required")
    .default({ label: "", value: -1 })
    .test("NoContact", "Must select Contact", (value) => !(value.value === -1)),
  firstName: yup
    .string()
    .required("First name is a required field")
    .default(""),
  middleName: yup.string().default(""),
  lastName: yup.string().required("Last name is a required field").default(""),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  address: yup.string().default(""),
  city: yup.string().default(""),
  state: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string().default(""),
  addressId: yup.number().default(-1),
});

export const TenantInformationSchema = yup.object({
  tenantCode: yup.string().nullable(true).default(null),
  tenantType: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .required()
    .default({ label: "", value: -1 })
    .test(
      "NoTenantType",
      "Must select Tenant type",
      (value) => !(value.value === -1 && value.label === "")
    ),
  tradingNameSelect: yup
    .object({
      label: yup.string().default(""),
      value: yup.string().default(""),
    })
    .required()
    .default({ label: "", value: "" })
    .test(
      "NoTradingName",
      "Must select Trading name",
      (value) => !(value.value === "" && value.label === "")
    ),
  tradingName: yup.string().required("Trading name is required").default(""),
  individuals: yup
    .array()
    .default([])
    .when("tenantType", {
      is: (tenantType: { label: string; value: number }) =>
        tenantType?.label === "Individual",
      then: (schema) =>
        schema
          .of(IndividualSchema)
          .required()
          .default([])
          .min(1, "Deal cannot have 0 tenants"),
      otherwise: (schema) => schema.of(IndividualSchema).required().default([]),
    }),
  companies: yup
    .array()
    .default([])
    .when("tenantType", {
      is: (tenantType: { label: string; value: number }) =>
        tenantType?.label === "Company",
      then: (schema) =>
        schema
          .of(CompanySchema)
          .required()
          .default([])
          .min(1, "Deal cannot have 0 tenants"),
      otherwise: (schema) => schema.of(CompanySchema).required().default([]),
    }),
});

export type IndividualTenantType = yup.InferType<typeof IndividualSchema>;
export type CompanyTenantType = yup.InferType<typeof CompanySchema>;
export type TenantInformationType = yup.InferType<
  typeof TenantInformationSchema
>;

export const defaultIndividual = IndividualSchema.getDefault();
export const defaultCompany = CompanySchema.getDefault();
export const defaultTenantInformation = TenantInformationSchema.getDefault();

export type DealTerm = yup.InferType<typeof DealTermSchema>;

export const StepTwoDefaultData = DealTermSchema.getDefault();

export interface DealData extends StepOneData, DealTerm {}

export interface DealProps {
  state: DealData;
  setState: React.Dispatch<React.SetStateAction<DealData>>;
  dispatch: React.Dispatch<{
    type: string;
  }>;
}

export const defaultData: DealData = {
  dealType: DealType.New,
  renewal: {
    salesCategory: "",
    leaseType: "",
  },
  tenantInfo: defaultTenantInformation,
  ...StepTwoDefaultData,
};

export interface FinalStepDealProps extends DealProps {
  submitHandler: () => void;
}
