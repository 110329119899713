import React from "react";
import { useMutation } from "@apollo/client";
import { BlobServiceClient } from "@azure/storage-blob";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CreatePropertyFileDocument,
  GetCentreMapsDocument,
  GetLegislationsDocument,
  GetPropertiesDocument,
  PropertyFileTypes_Enum,
} from "../../generated/graphql-operations";
import { ValidationProps } from "./Config";
import { BrowserCredential } from "../../contexts/Auth/AzureBrowserAuth";
import {
  DOCUMENT_CONTAINER_NAME,
  BLOB_CLIENT_URL,
} from "../../utils/CommonVariables";

export const useCreateFileSubmit = (
  propertyCode: string,
  setValidation: React.Dispatch<React.SetStateAction<ValidationProps>>,
  selectedFile: File | undefined,
  type: PropertyFileTypes_Enum
) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [createFile] = useMutation(CreatePropertyFileDocument);

  const onSubmit = async (data: { fileName: string }) => {
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      setValidation({
        show: true,
        severity: "error",
        message: "Not Authenticated, please relogging back in",
      });
      return;
    }

    if (selectedFile === undefined) {
      setValidation({
        show: true,
        severity: "error",
        message: "No file selected",
      });
      return;
    }

    const blobServiceClient = new BlobServiceClient(
      BLOB_CLIENT_URL,
      new BrowserCredential(instance, isAuthenticated, inProgress)
    );
    const pathName = `${propertyCode}/${type}`;
    const blockBlobClient = blobServiceClient
      .getContainerClient(DOCUMENT_CONTAINER_NAME)
      .getBlockBlobClient(pathName);

    await blockBlobClient.upload(
      selectedFile,
      selectedFile?.arrayBuffer.length,
      {
        metadata: {
          name: selectedFile.name,
        },
      }
    );

    await createFile({
      variables: {
        object: {
          property_code: propertyCode,
          file_name: data.fileName,
          file_path: pathName,
          type,
        },
      },
      refetchQueries: [
        GetPropertiesDocument,
        GetCentreMapsDocument,
        GetLegislationsDocument,
      ],
      onCompleted: () =>
        setValidation({
          show: true,
          severity: "success",
          message: "Successfully uploaded file",
        }),
      onError: () => {
        const message =
          "Error - problem uploading file. Please refresh and try again";

        setValidation({
          show: true,
          severity: "error",
          message,
        });
      },
    });
  };

  return onSubmit;
};

export const useDownloadBlob = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  return async (fileName: string, filePath: string, version?: string) => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );
      const blobFileClient = blobServiceClient
        .getContainerClient(DOCUMENT_CONTAINER_NAME)
        .getBlockBlobClient(filePath);

      const blobFile = await blobFileClient
        .withVersion(version ?? "")
        .download();
      const blobBody = await blobFile.blobBody;
      const fileExtension = blobFile.metadata?.name?.split(".")?.pop();

      if (blobBody === undefined) {
        return;
      }

      const element = document.createElement("a");
      element.href = URL.createObjectURL(blobBody);
      element.download = fileName;
      if (fileExtension) {
        element.download += `.${fileExtension}`;
      }
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      URL.revokeObjectURL(element.href);
      document.body.removeChild(element);
    }
  };
};
