import React from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  DeleteOldFileTagsDocument,
  CreateDealFileDocument,
  GetDealFilesDocument,
  UpdateDealFileDocument,
  GetTagsDocument,
  Tags_Constraint,
  Tags_Update_Column,
} from "../../generated/graphql-operations";
import {
  CreateFileDialogType,
  ValidationProps,
  BlobVersions,
} from "../../components/Deal/SummaryTab/Files/Config";

import { BrowserCredential } from "../../contexts/Auth/AzureBrowserAuth";
import {
  DOCUMENT_CONTAINER_NAME,
  BLOB_CLIENT_URL,
} from "../../utils/CommonVariables";

export const useCreateFileSubmit = (
  dealId: number,
  setValidation: React.Dispatch<React.SetStateAction<ValidationProps>>,
  selectedFile: File | undefined
) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [createFile] = useMutation(CreateDealFileDocument);

  const onSubmit = async (data: CreateFileDialogType) => {
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      setValidation({
        show: true,
        severity: "error",
        message: "Not Authenticated, please try relogging back in",
      });
      return;
    }

    if (selectedFile === undefined) {
      setValidation({
        show: true,
        severity: "error",
        message: "No file selected",
      });
      return;
    }

    const blobServiceClient = new BlobServiceClient(
      BLOB_CLIENT_URL,
      new BrowserCredential(instance, isAuthenticated, inProgress)
    );
    const pathName = `${dealId}/${uuidv4()}`;
    const blockBlobClient = blobServiceClient
      .getContainerClient(DOCUMENT_CONTAINER_NAME)
      .getBlockBlobClient(pathName);

    await blockBlobClient.upload(
      selectedFile,
      selectedFile?.arrayBuffer.length,
      {
        metadata: {
          // Apparently metadata only takes certain chars
          name: encodeURIComponent(selectedFile.name),
        },
      }
    );

    await createFile({
      variables: {
        file_object: {
          deal_id: dealId,
          file_name: data.fileName,
          file_path: pathName,
          not_deleted: true,
          DealFileTags: {
            data:
              data.tags?.map((tag) => ({
                Tags: {
                  data: { name: tag },
                  on_conflict: {
                    constraint: Tags_Constraint.TagsNameUnique,
                    update_columns: [Tags_Update_Column.Name],
                  },
                },
              })) ?? [],
          },
        },
      },
      refetchQueries: [GetDealFilesDocument, GetTagsDocument],
      onCompleted: () =>
        setValidation({
          show: true,
          severity: "success",
          message: "Success - your file will appear in the files tab shortly",
        }),
      onError: (error) => {
        let message =
          "Error - problem uploading file. Please refresh and try again";
        if (
          error.graphQLErrors[0]?.extensions?.code === "constraint-violation"
        ) {
          message = `Error - File with name ${data.fileName} already exists`;
        }
        setValidation({
          show: true,
          severity: "error",
          message,
        });
        blockBlobClient.deleteIfExists();
      },
    });
  };

  return onSubmit;
};

export const useUpdateFileSubmit = (
  setValidation: React.Dispatch<React.SetStateAction<ValidationProps>>,
  selectedFile: File | undefined
) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [updateFile] = useMutation(UpdateDealFileDocument);
  const [deleteOldFileTags] = useMutation(DeleteOldFileTagsDocument);

  const onSubmit = async (
    data: CreateFileDialogType,
    pathName: string,
    fileId: number
  ) => {
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      setValidation({
        show: true,
        severity: "error",
        message: "Not Authenticated, please try relogging back in",
      });
      return;
    }

    if (selectedFile !== undefined) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );

      const blockBlobClient = blobServiceClient
        .getContainerClient(DOCUMENT_CONTAINER_NAME)
        .getBlockBlobClient(pathName);

      await blockBlobClient.upload(
        selectedFile,
        selectedFile?.arrayBuffer.length,
        {
          metadata: { name: encodeURIComponent(selectedFile.name) },
        }
      );
    }

    try {
      const newUpdatedFiles = await updateFile({
        variables: {
          file_id: fileId,
          file_name: data.fileName,
          tag_object:
            data.tags?.map((item) => ({
              file_id: fileId,
              Tags: {
                data: { name: item },
                on_conflict: {
                  constraint: Tags_Constraint.TagsNameUnique,
                  update_columns: [Tags_Update_Column.Name],
                },
              },
            })) ?? [],
        },
        refetchQueries: [GetTagsDocument],
      });

      // Delete FIleTag rows which where not updated or created
      const newUpdatedIds =
        newUpdatedFiles.data?.insert_DealFileTags?.returning.map(
          (item) => item.id
        ) ?? [];

      await deleteOldFileTags({
        variables: { file_id: fileId, new_n_updated_tag_ids: newUpdatedIds },
        refetchQueries: [GetDealFilesDocument],
      });
    } catch (error: any) {
      let message = `Error - problem updating data. Please refresh and try again. Error message: ${error?.message}`;

      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0].extensions?.code === "constraint-violation"
      ) {
        message = `Error - File with name ${data.fileName} already exists`;
      }

      setValidation({
        show: true,
        severity: "error",
        message,
      });
      return;
    }

    setValidation({
      show: true,
      severity: "success",
      message: "Success",
    });
  };

  return onSubmit;
};

export const useGetBlobList = (filePath: string | undefined) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [blobs, setBlobs] = React.useState<BlobVersions>([]);
  const clearBlobCache = () => setBlobs([]);

  // Because {} !== {} it forces state to be reloaded
  const [forceReloadValue, setForceReload] = React.useState({});
  const forceReload = () => setForceReload({});

  React.useEffect(() => {
    if (filePath && isAuthenticated && inProgress === InteractionStatus.None) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );
      const getSetBlobs = async () => {
        const listBlobs = await blobServiceClient
          .getContainerClient(DOCUMENT_CONTAINER_NAME)
          .listBlobsFlat({
            prefix: filePath,
            includeVersions: true,
            includeMetadata: true,
          });

        const tempBlobList: BlobVersions = [];
        for await (const item of listBlobs) {
          if (item.versionId !== undefined) {
            tempBlobList.push({
              id: item.versionId,
              date: new Date(item.properties.createdOn ?? ""),
              name: item.metadata?.name ?? "",
            });
          }
        }
        setBlobs(tempBlobList.sort((a, b) => Number(b.date) - Number(a.date)));
      };
      getSetBlobs();
    }
  }, [filePath, isAuthenticated, inProgress, instance, forceReloadValue]);

  return [blobs, clearBlobCache, forceReload] as const;
};

export const useDownloadBlob = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  return async (fileName: string, filePath: string, version?: string) => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const blobServiceClient = new BlobServiceClient(
        BLOB_CLIENT_URL,
        new BrowserCredential(instance, isAuthenticated, inProgress)
      );
      const blobFileClient = blobServiceClient
        .getContainerClient(DOCUMENT_CONTAINER_NAME)
        .getBlockBlobClient(filePath);

      const blobFile = await blobFileClient
        .withVersion(version ?? "")
        .download();
      const blobBody = await blobFile.blobBody;
      const fileExtension = blobFile.metadata?.name?.split(".")?.pop();

      if (blobBody === undefined) {
        return;
      }

      const element = document.createElement("a");
      element.href = URL.createObjectURL(blobBody);
      element.download = fileName;
      if (fileExtension) {
        element.download += `.${fileExtension}`;
      }
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      URL.revokeObjectURL(element.href);
      document.body.removeChild(element);
    }
  };
};

export const useCreateTenantFile = (
  dealId: number | null | undefined,
  tenantId: string | null | undefined,
  selectedFile: File | undefined
) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const onSubmit = async (data: CreateFileDialogType) => {
    if (!isAuthenticated || inProgress !== InteractionStatus.None) {
      throw new Error("Not Authenticated, please try relogging back in");
    }

    if (selectedFile === undefined) {
      throw new Error("No file selected");
    }

    const blobServiceClient = new BlobServiceClient(
      BLOB_CLIENT_URL,
      new BrowserCredential(instance, isAuthenticated, inProgress)
    );
    const rootDir = tenantId ?? dealId ?? null;
    // Shouldn't happen but just in case
    if (rootDir === null) {
      throw new Error("No deal or tenant selected");
    }

    const pathName = `${rootDir}/${data.fileName}`;
    const blockBlobClient = blobServiceClient
      .getContainerClient(DOCUMENT_CONTAINER_NAME)
      .getBlockBlobClient(pathName);

    await blockBlobClient.upload(
      selectedFile,
      selectedFile?.arrayBuffer.length,
      {
        metadata: {
          name: selectedFile.name,
        },
      }
    );
    return pathName;
  };

  return onSubmit;
};
