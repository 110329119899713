import React, { useState } from "react";
import {
  DataGrid,
  DataGridProps,
  GridColumnVisibilityModel,
  GridFilterModel,
} from "@mui/x-data-grid";

interface PersistedDataGridProps extends DataGridProps {
  applyFilters?: boolean;
}

const PersistedDataGrid = (props: PersistedDataGridProps) => {
  let storedFilters;
  let storedColumnVisibility;

  try {
    storedFilters = JSON.parse(localStorage.getItem("filters") || "{}");
    storedColumnVisibility = JSON.parse(
      localStorage.getItem("columnVisibility") || "{}"
    );
  } catch {
    storedFilters = {};
    storedColumnVisibility = {};
  }

  const [filters, setFilters] = useState<GridFilterModel>(storedFilters);
  const [columnVisibility, setColumnVisibility] =
    useState<GridColumnVisibilityModel>(storedColumnVisibility);

  const persistFilters = (newFilters: GridFilterModel) => {
    localStorage.setItem("filters", JSON.stringify(newFilters));
    setFilters(newFilters);
  };

  const persistColumnVisibility = (
    newColumnVisibility: GridColumnVisibilityModel
  ) => {
    localStorage.setItem(
      "columnVisibility",
      JSON.stringify(newColumnVisibility)
    );
    setColumnVisibility(newColumnVisibility);
  };

  return (
    <DataGrid
      filterModel={Object.keys(filters).length > 0 ? filters : undefined}
      onFilterModelChange={persistFilters}
      columnVisibilityModel={columnVisibility}
      onColumnVisibilityModelChange={persistColumnVisibility}
      {...props}
    />
  );
};

export default PersistedDataGrid;
