import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";

interface ErrorProps {
  title: string;
  message: string;
}

export const Error = (props: ErrorProps) => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>{props.title}</h1>
      <p>{props.message}</p>

      <Button onClick={() => navigate(-1)}>Back</Button>
    </div>
  );
};
