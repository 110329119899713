import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import {
  CheckDealExistsDocument,
  GetDealDataIdDocument,
  LafActionChoices_Enum,
  SubDealLafApprovalsStatusDocument,
  SubLeaseExecAllowedToEditDealDocument,
} from "../../generated/graphql-operations";
import { DealInformationType } from "../../components/DealInformation/DealInformationConfig";
import { useMutateDateInfo } from "./DateInformation/DateInformation";
import { useMutateUnitInformation } from "./UnitInformation/UnitInformation";
import { useMutateDealType } from "./DealType/DealType";
import { useMutateTenantInformation } from "./TenantInformation/TenantInformation";
import { useMutateDealCommercial } from "./Commercial/Commercial";
import { useMutateDealUsage } from "./Usage/Usage";
import { useMutateStorage } from "./Storage/Storage";
import { useMutateCharges } from "./Charges/Charges";
import { useMutateDealOptionTerms } from "./OptionTerms/OptionTerms";
import { useMutateDeposit } from "./Deposit/Deposit";
import { useMutateLandlordServices } from "./LandlordServices/LandlordServices";
import { useMutateFitoutLandlordWorks } from "./FitoutLandlordWorks/FitoutLandlordWorks";
import { useMutateDealSpecialConditions } from "./SpecialConditions/SpecialConditions";
import { useDealDisabled } from "../DisabledDeal";
import { useReturnUserRole, Role } from "../../contexts/Auth/UserRole";
import { useMutateHistoricalData } from "./HistoricalData/HistoricalData";
import { DealPhaseStatus } from "../../utils/CommonVariables";

export const useCheckDealExists = (dealId: number) => {
  const result = useQuery(CheckDealExistsDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return result;
};

export const useCanSaveDeal = (dealId: number) => {
  const isDisabled = useDealDisabled(dealId);
  const {
    data: approvalsData,
    loading: approvalsDataLoading,
    error: approvalsDataError,
  } = useSubscription(SubDealLafApprovalsStatusDocument, {
    variables: { deal_id: dealId },
  });

  const {
    data: leaseExecData,
    loading: leaseExecDataLoading,
    error: leaseExecDataError,
  } = useSubscription(SubLeaseExecAllowedToEditDealDocument, {
    variables: {
      deal_id: dealId,
      started_phases: [DealPhaseStatus.Active, DealPhaseStatus.Complete],
    },
  });

  // Only Admin and Exec can edit data
  const userRole = useReturnUserRole();
  if (userRole !== Role.Admin && userRole !== Role.Exec) {
    return {
      status: false,
      reason: "Don't have permissions to edit data",
    };
  }

  if (approvalsDataError || leaseExecDataError || isDisabled.isError) {
    return {
      status: false,
      reason: "Error loading data, try refreshing page.",
    };
  }

  if (
    approvalsDataLoading ||
    !approvalsData ||
    leaseExecDataLoading ||
    !leaseExecData ||
    isDisabled.status === undefined
  ) {
    return { status: false, reason: "Can't edit while loading data." };
  }

  if (isDisabled.disabled) {
    return {
      status: false,
      reason: `Can't edit data while Deal is in Status: ${isDisabled.status}`,
    };
  }

  // Admin can edit if no LAF has started, or if LAF has started and is in Request Change state
  if (userRole === Role.Admin) {
    // No Laf has started so editable
    if (
      !approvalsData?.DealLAFApprovals ||
      approvalsData?.DealLAFApprovals.length === 0
    ) {
      return { status: true, reason: null };
    }

    // If Laf has started and is not in Request Change state then not editable
    return {
      status:
        approvalsData.DealLAFApprovals.filter(
          (item) =>
            item.laf_action === LafActionChoices_Enum.RequestChange ||
            item.laf_action === LafActionChoices_Enum.Finished
        ).length > 0,
      reason: "Deal cannot be edited when in active LAF Review.",
    };
  }

  // Exec can edit if proposal phase is not complete
  if (userRole === Role.Exec) {
    // Proposal phase is complete so not editable
    if (
      leaseExecData?.DealPhases_aggregate?.aggregate?.count &&
      leaseExecData?.DealPhases_aggregate?.aggregate?.count !== 0
    ) {
      return {
        status: false,
        reason: "Deal cannot be edited outside of Terms and Proposal phases.",
      };
    }

    // Proposal phase is not complete so editable
    return {
      status: true,
      reason: null,
    };
  }

  // Default to not editable
  return {
    status: false,
    reason: "Don't have permissions to edit data",
  };
};

export const useTransformAndSubmitData = (dealId: number) => {
  const [getDealDataId] = useLazyQuery(GetDealDataIdDocument);
  const updateDateInfo = useMutateDateInfo(dealId);
  const updateUnitInfo = useMutateUnitInformation(dealId);
  const updateDealType = useMutateDealType(dealId);
  const updateCommercial = useMutateDealCommercial(dealId);
  const updateUsage = useMutateDealUsage();
  const updateTenantInfo = useMutateTenantInformation(dealId);
  const updateStorage = useMutateStorage(dealId);
  const updateCharges = useMutateCharges(dealId);
  const updateOptionTerms = useMutateDealOptionTerms(dealId);
  const updateDeposit = useMutateDeposit(dealId);
  const updateLandlordServices = useMutateLandlordServices(dealId);
  const updateFitoutLandlordWorks = useMutateFitoutLandlordWorks();
  const updateSpecialConditions = useMutateDealSpecialConditions(dealId);
  const updateHistoricalData = useMutateHistoricalData();

  const onSubmit = async (formData: DealInformationType) => {
    const dealDataId = (await getDealDataId({ variables: { deal_id: dealId } }))
      .data?.DealData[0]?.id;
    if (
      formData.unitInfo.unit.value === undefined ||
      dealDataId === undefined
    ) {
      return;
    }

    await updateDateInfo(formData.dateInfo);

    await updateUnitInfo(formData.unitInfo);

    await updateDealType(formData.dealType);

    await updateCommercial(formData.commercial);

    await updateTenantInfo(formData.tenantInfo);

    await updateUsage(dealDataId, formData.usage);

    await updateStorage(formData.storage);

    await updateCharges(formData.charges);

    await updateLandlordServices(formData.landlordServices);

    await updateFitoutLandlordWorks(dealDataId, formData.fitoutLandlordWorks);

    await updateOptionTerms(dealDataId, formData.optionTerm);

    await updateDeposit(dealDataId, formData.deposit);

    await updateSpecialConditions(dealDataId, formData.specialConditions);

    await updateHistoricalData(dealDataId, formData.historicalData);
  };

  return [onSubmit] as const;
};
