import React from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { GetAllContactsByCompanyDocument } from "../../../../generated/graphql-operations";
import { TenantFormProps } from "../IndividualForm";
import { Form, FormField, SelectField } from "../../../Form/Form";
import { PhoneNumberFormatter } from "../../../MuiCustomNumberFormat";
import { IndividualTenantType } from "../TenantInformationConfig";
import { AddNewContactOption } from "../../../../utils/CommonVariables";

export const CompanyContact = (props: TenantFormProps) => {
  const contacts = useQuery(GetAllContactsByCompanyDocument, {
    variables: {
      company_id: props.form.watch(
        `tenantInfo.companies.${props.index}.company.value`
      ),
    },
  });

  const allContacts =
    contacts?.data?.Contacts?.map((contact) => {
      const tenant: IndividualTenantType = {
        tenant: {
          label: `${contact?.first_name} ${contact?.middle_name ?? ""} ${
            contact?.last_name
          }`,
          value: contact?.id ?? -1,
        },
        label: `${contact?.first_name} ${contact?.middle_name ?? ""} ${
          contact?.last_name
        }`,
        id: contact?.id ?? -1,
        email: contact?.email ?? "",
        phone: contact?.phone ?? "",
        firstName: contact?.first_name ?? "",
        middleName: contact?.middle_name ?? "",
        lastName: contact?.last_name ?? "",
        address: contact?.Location?.street1 ?? "",
        city: contact?.Location?.suburb ?? "",
        state: {
          label: contact?.Location?.State?.name ?? "",
          value: contact?.Location?.State?.id ?? 1,
        },
        postCode: contact?.Location?.postcode?.toString() ?? "",
        addressId: contact?.address_id ?? -1,
      };

      return tenant;
    }) ?? [];

  const contactSelectField: FormField = {
    type: "select",
    label: "Contact",
    fieldName: `tenantInfo.companies.${props.index}.contactSelect` as const,
    choices: [
      AddNewContactOption,
      ...allContacts.map((contact) => ({
        label: `${contact.firstName} ${contact.lastName}`,
        value: contact.id,
      })),
    ],
    columns: 6,
    helperText: "",
    onChange: (e, data) => {
      props.form.setValue(
        `tenantInfo.companies.${props.index}.contactSelect`,
        data,
        {
          shouldValidate: true,
        }
      );

      const contact = allContacts.find((elem) => elem.id === data.value);

      const fieldNames = [
        "firstName",
        "middleName",
        "lastName",
        "email",
        "phone",
      ];

      fieldNames.forEach((fieldName) => {
        props.form.setValue(
          `tenantInfo.companies.${props.index}.${fieldName}`,
          data.value === 0 ? "" : contact?.[fieldName as keyof typeof contact],
          {
            shouldValidate: true,
          }
        );
      });
    },
  };

  const formFields: FormField[] = [
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.firstName` as const,
      label: "First name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.contactSelect.value` as const
        ) !== 0,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.middleName` as const,
      label: "Middle name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.contactSelect.value` as const
        ) !== 0,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.lastName` as const,
      label: "Last name",
      helperText: "Mandatory",
      columns: 4,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.contactSelect.value` as const
        ) !== 0,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.email` as const,
      label: "Email",
      helperText: "Mandatory",
      columns: 6,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.contactSelect.value` as const
        ) !== 0,
    },
    {
      type: "text",
      fieldName: `tenantInfo.companies.${props.index}.phone` as const,
      label: "Phone number",
      helperText: "Mandatory",
      columns: 6,
      customComp: PhoneNumberFormatter,
      disabled:
        props.form.watch(
          `tenantInfo.companies.${props.index}.contactSelect.value` as const
        ) !== 0,
    },
  ];

  return (
    <>
      <Grid p={1}>
        <SelectField form={props.form} formField={contactSelectField} />
      </Grid>
      <Form fields={formFields} form={props.form} />
    </>
  );
};
