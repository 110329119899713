import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import theme from "../../../../themes/theme";
import { Button } from "../../../Button";
import { BLACK_ALPHA_60 } from "../../../../utils/CommonVariables";
import { ErrorDetailsDialog, Error } from "./ErrorDetailsDialog";
import { useDownloadBlob } from "../../../../adapters/Deal/Files";

interface DocumentCardProps {
  documentName: string;
  documentPath: string;
  errorsJson?: string | null;
  createdAt: string;
  disabled: boolean;
}

export const ErroredDocumentCard = (props: DocumentCardProps) => {
  const [errorDetailsOpen, setErrorDetailsOpen] = React.useState(false);
  const errors: Error[] = props.errorsJson && JSON.parse(props.errorsJson);

  const downloadFile = useDownloadBlob();
  const downloadOnClick = async () =>
    downloadFile(props.documentPath, props.documentPath);

  return (
    <>
      <Card
        sx={{
          pt: 2,
          pl: 2,
          pr: 6,
          pb: 0,
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <CardHeader
          avatar={<ErrorIcon color="error" />}
          title={props.documentName}
          titleTypographyProps={{
            variant: "subtitle1",
            color: theme.palette.text.primary,
          }}
        />
        <CardContent>
          <Typography variant="body2" color={BLACK_ALPHA_60}>
            Errored at {props.createdAt}.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            disabled={props.disabled}
            variant="text"
            sx={{ p: 1 }}
            onClick={downloadOnClick}
          >
            Download
          </Button>
          <Button
            onClick={() => setErrorDetailsOpen(true)}
            variant="text"
            sx={{ p: 1 }}
          >
            Error details
          </Button>
        </CardActions>
      </Card>
      <ErrorDetailsDialog
        open={errorDetailsOpen}
        setOpen={setErrorDetailsOpen}
        errors={errors}
      />
    </>
  );
};
