import React, { useMemo } from "react";
import { Grid, Box } from "@mui/material";

import { UseFormReturn } from "react-hook-form";
import { QueryResult } from "@apollo/client";
import {
  getUnitsByCentre,
  getVirtualUnitsByCentre,
  useGetPropertyData,
  useGetUnitTypes,
  useGetVirtualUnits,
} from "../adapters/Properties/Properties";
import {
  VIRTUAL_UNIT_LABEL,
  VIRTUAL_UNIT_LABEL_SUFFIX,
} from "../utils/CommonVariables";
import { TextMultiField } from "./Form/FormComponents";
import { Form, FormField } from "./Form/Form";
import { DealData } from "./CreateDeal/DealConfig";
import {
  GetUnitsQuery,
  GetUnitsQueryVariables,
  GetVirtualUnitsQuery,
  GetVirtualUnitsQueryVariables,
} from "../generated/graphql-operations";

export const getEstimatedArea = (
  data: any,
  virtualUnits: QueryResult<
    GetVirtualUnitsQuery,
    GetVirtualUnitsQueryVariables
  >,
  units: QueryResult<GetUnitsQuery, GetUnitsQueryVariables>
) => {
  if (data.value === 0) {
    return "";
  }

  if (data.label.includes(VIRTUAL_UNIT_LABEL_SUFFIX)) {
    const selectedUnit = virtualUnits.data?.VirtualUnits.find(
      (unit) => unit.id === data.value
    );
    return selectedUnit?.estimated_area?.toString() ?? "0";
  }

  const selectedUnit = units.data?.Units.find(
    (unit) => unit.UnitKey === data.value
  );
  return (selectedUnit?.estimated_area as string) ?? "0";
};

interface LocationFormFields extends Omit<FormField, "defaultValue"> {
  defaultValue?: string;
}

interface LocationSelectProps {
  form: UseFormReturn<DealData>;
  onCentreChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data: any
  ) => void;
  onUnitChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data: any
  ) => void;
  onVirtualUnitNameBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  onEstimatedAreaBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  onVirtualUnitDescriptionChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data: any
  ) => void;
  onVirtualUnitTypeChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data: any
  ) => void;
}

export const LocationSelect = ({
  form,
  onCentreChange,
  onUnitChange,
  onVirtualUnitNameBlur,
  onVirtualUnitTypeChange,
  onEstimatedAreaBlur,
  onVirtualUnitDescriptionChange,
}: LocationSelectProps) => {
  const [centres, units] = useGetPropertyData();
  const virtualUnits = useGetVirtualUnits();
  const unitTypes = useGetUnitTypes();

  const centreOptions = useMemo(
    () =>
      centres.data?.Properties.map((centre) => ({
        label: centre.label as string,
        value: centre.property_code ?? "",
      })) ?? [],
    [centres.data?.Properties]
  );

  const selectedCentre = form.watch("centre")?.value;

  const unitOptions = useMemo(
    () => [
      { label: VIRTUAL_UNIT_LABEL, value: "" },
      ...getUnitsByCentre(selectedCentre, units),
      ...getVirtualUnitsByCentre(selectedCentre, virtualUnits.data),
    ],
    [selectedCentre, units, virtualUnits.data]
  );

  const centreFields: LocationFormFields[] = [
    {
      type: "select",
      fieldName: "centre",
      label: "Centre",
      helperText: "Mandatory",
      columns: 6,
      choices: centreOptions,
      onChange: (e, data) => {
        form.setValue("unit", { label: "", value: "" });
        form.setValue("estimatedArea", "");

        if (onCentreChange) {
          onCentreChange(e, data);
        }
      },
    },
    {
      type: "select",
      fieldName: "unit",
      label: "Unit",
      helperText: "Mandatory",
      columns: 6,
      choices: unitOptions,
      onChange: (e, data) => {
        const estimatedAreaStr = getEstimatedArea(data, virtualUnits, units);
        form.setValue("estimatedArea", estimatedAreaStr);

        if (onUnitChange) {
          onUnitChange(e, data);
        }
      },
    },
    {
      type: "number",
      fieldName: "estimatedArea",
      label: "Estimated area",
      helperText: "Mandatory",
      columns: 6,
      adornment: {
        value: "m\u00B2",
        position: "start",
      },
      disabled: form.watch("unit")?.label !== VIRTUAL_UNIT_LABEL,
      onBlur: (e) => {
        if (onEstimatedAreaBlur) {
          onEstimatedAreaBlur(e);
        }
      },
    },
  ];

  const virtualUnitFields: LocationFormFields[] = [
    {
      type: "text",
      fieldName: "virtualUnit",
      label: "Virtual unit name",
      helperText: "",
      columns: 6,
      onBlur: (e) => {
        if (onVirtualUnitNameBlur) {
          onVirtualUnitNameBlur(e);
        }
      },
    },
    {
      type: "select",
      fieldName: "unitType",
      label: `Virtual unit type`,
      helperText: "",
      choices: unitTypes,
      columns: 6,
      onChange: (e, data) => {
        if (onVirtualUnitTypeChange) {
          onVirtualUnitTypeChange(e, data);
        }
      },
    },
  ];

  const virtualUnitDescriptionField: LocationFormFields = {
    type: "text",
    fieldName: "virtualUnitDescription",
    label: `Virtual unit description`,
    helperText: "",
    columns: 12,
    defaultValue: "",
    onChange: (e, data) => {
      if (onVirtualUnitDescriptionChange) {
        onVirtualUnitDescriptionChange(e, data);
      }
    },
  };

  return (
    <>
      <Form form={form} fields={centreFields} />

      {form.watch("unit")?.label === VIRTUAL_UNIT_LABEL ? (
        <>
          <Form form={form} fields={virtualUnitFields} />
          <Grid container p={1}>
            <TextMultiField
              control={form.control}
              formField={virtualUnitDescriptionField}
            />
          </Grid>
          <Box width="100%" />
        </>
      ) : null}
    </>
  );
};
