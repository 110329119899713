// Kinda Disagree with this rule here as we are using all the props
// Just ordering them using a array
// Maybe we should just use props directly but leave this for time being
/* eslint react/no-unused-prop-types: 0 */
import React, { useState } from "react";
import { Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { SxProps } from "@mui/system";

import { AssignDealDialog } from "../../AssignDealDialog";
import {
  formatDealStatusText,
  statusSxColor,
} from "../../../utils/CommonFunctions";

export interface DealOverviewProps {
  status: string;
  created_by: string;
  owner: string;
  tradingName: string;
  tenantCode: string;
  propertyCode: string;
  laAssignee: string;
  dealType: string;
  leaseStart: string;
  leaseTerm: string;
  centre: string;
  tdAssignee: string;
  creationDate: string;
  unit: string;
  version: string;
  disabled: boolean;
}

interface DealOverviewField {
  title: string;
  field: keyof DealOverviewProps;
  valueTransform?: (value: any) => string;
  extraSx?: (status: string) => SxProps;
}

const fields: DealOverviewField[] = [
  {
    title: "Status",
    field: "status",
    valueTransform: formatDealStatusText,
    extraSx: statusSxColor,
  },
  { title: "Owner", field: "owner" },
  { title: "Trading name", field: "tradingName" },
  { title: "Deal type", field: "dealType" },
  { title: "Tenant code", field: "tenantCode" },
  { title: "Property code", field: "propertyCode" },
  { title: "LA Assignee", field: "laAssignee" },
  { title: "Lease start", field: "leaseStart" },
  { title: "Lease term", field: "leaseTerm" },
  { title: "Centre", field: "centre" },
  { title: "TD Assignee", field: "tdAssignee" },
  { title: "Creation date", field: "creationDate" },
  { title: "Unit", field: "unit" },
  { title: "Version", field: "version" },
];

interface DealOverviewChipProps {
  label: string;
  extraSx?: SxProps;
}

export const DealOverviewChip = (props: DealOverviewChipProps) => {
  if (props.label === "") {
    return null;
  }
  return (
    <Chip
      size="small"
      variant="filled"
      label={props.label}
      sx={{
        ...props.extraSx,
      }}
    />
  );
};

const mapFields = (
  props: DealOverviewProps,
  dealFields: DealOverviewField[],
  setLAopen: React.Dispatch<React.SetStateAction<boolean>>,
  setTDopen: React.Dispatch<React.SetStateAction<boolean>>,
  setOwnerOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return dealFields.map((field) => (
    <React.Fragment key={field.title}>
      <Grid item mb={1} xs="auto" mr={3} pb={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography paddingRight={2} variant="body1">
            {field.title}
          </Typography>
          <DealOverviewChip
            label={
              field.valueTransform
                ? field.valueTransform(props[field.field])
                : (props[field.field] as string)
            }
            extraSx={
              field.extraSx ? field.extraSx(props[field.field] as string) : {}
            }
          />
          {field.field === "laAssignee" && (
            <IconButton
              size="small"
              onClick={() => setLAopen(true)}
              disabled={props.disabled}
            >
              <KeyboardArrowDown />
            </IconButton>
          )}

          {field.field === "tdAssignee" && (
            <IconButton
              size="small"
              onClick={() => setTDopen(true)}
              disabled={props.disabled}
            >
              <KeyboardArrowDown />
            </IconButton>
          )}

          {field.field === "owner" && (
            <IconButton
              size="small"
              onClick={() => setOwnerOpen(true)}
              disabled={props.disabled}
            >
              <KeyboardArrowDown />
            </IconButton>
          )}
        </Stack>
      </Grid>
    </React.Fragment>
  ));
};

export const DealOverview = (props: DealOverviewProps & { dealId: number }) => {
  const [LAopen, setLAopen] = useState(false);
  const [TDopen, setTDopen] = useState(false);
  const [OwnerOpen, setOwnerOpen] = useState(false);

  return (
    <Grid container mb={1} p={1} justifyContent="space-between">
      <AssignDealDialog
        type="LA"
        open={LAopen}
        setOpen={setLAopen}
        dealId={props.dealId}
      />

      <AssignDealDialog
        type="TD"
        open={TDopen}
        setOpen={setTDopen}
        dealId={props.dealId}
      />

      <AssignDealDialog
        type="Owner"
        open={OwnerOpen}
        setOpen={setOwnerOpen}
        dealId={props.dealId}
      />

      {mapFields(props, fields, setLAopen, setTDopen, setOwnerOpen)}
    </Grid>
  );
};
