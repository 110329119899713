import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { YardiEgestion } from "../components/YardiEgestion";

export const YardiEgestionPage = () => {
  const params = useParams();
  const dealId = parseInt(params.dealId ?? "", 10) || -1;

  return (
    <Box width="100%">
      <YardiEgestion dealId={dealId} />
    </Box>
  );
};
