import { useQuery, useMutation } from "@apollo/client";
import {
  UsageType,
  NO_OPTION_USE,
} from "../../../components/DealInformation/Usage/Config";
import {
  GetDealInfoUsageDocument,
  GetDealInfoUsageQuery,
  GetUsagePermittedUsesTemplateDocument,
  UpdateDealPermittedUsesDocument,
} from "../../../generated/graphql-operations";

export const useGetDealInfoUsage = (dealId: number) => {
  const dealInfoUnitInfo = useQuery(GetDealInfoUsageDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return dealInfoUnitInfo;
};

export const useGetPermittedTemplates = () => {
  return useQuery(GetUsagePermittedUsesTemplateDocument);
};

export const transformUsage = (data: GetDealInfoUsageQuery): UsageType => {
  const dealPermittedUse = data.Deals_by_pk?.DealData[0]?.DealPermittedUses[0];
  const dealTemplatePermUse =
    data.Deals_by_pk?.DealData[0]?.DealPermittedUses[0]?.TemplatePermittedUse;
  return {
    permittedUse: {
      label: dealTemplatePermUse?.title ?? NO_OPTION_USE.label,
      value: dealTemplatePermUse?.id ?? NO_OPTION_USE.value,
    },
    preview: dealPermittedUse?.description ?? "",
    subletFranchisee: dealPermittedUse?.sublet ?? undefined,
    foodMenu: dealPermittedUse?.food_menu ?? undefined,
  };
};

export const useMutateDealUsage = () => {
  const [updateUsageInfo] = useMutation(UpdateDealPermittedUsesDocument, {
    refetchQueries: [{ query: GetDealInfoUsageDocument }],
  });
  return async (dealDataId: number, data: UsageType) => {
    await updateUsageInfo({
      variables: {
        update_object: {
          deal_data_id: dealDataId,
          description: data.preview,
          food_menu: data.foodMenu ?? null,
          sublet: data.subletFranchisee ?? null,
          template_id:
            data.permittedUse.value === NO_OPTION_USE.value
              ? null
              : data.permittedUse.value,
        },
      },
    });
  };
};
