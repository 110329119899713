import { useSubscription } from "@apollo/client";

import { SubGetDealStatusDocument } from "../generated/graphql-operations";
import { dealIsDisabled } from "../utils/CommonFunctions";

export const useDealDisabled = (dealId: number) => {
  const { data, error } = useSubscription(SubGetDealStatusDocument, {
    variables: { deal_id: dealId },
  });

  const dealStatus = data?.Deals_by_pk?.status;

  return {
    disabled: dealIsDisabled(data?.Deals_by_pk?.status),
    status: dealStatus,
    isError: error,
  };
};
