import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GetUsersDocument,
  UpsertActionAllocationDocument,
  DeleteActionAllocationDocument,
  GetActionAllocationDocument,
  GetDealDocument,
} from "../../../generated/graphql-operations";
import { useReturnUserRole, Role } from "../../../contexts/Auth/UserRole";
import { Button } from "../../Button";
import { FormField, SelectField } from "../../Form/Form";
import { TextMultiField } from "../../Form/FormComponents";
import { ActionItem } from "../PushDealDialogServices";

const AllocateTaskDialogSchema = yup.object({
  user: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .test(
      "NoUser",
      "Must select User",
      (value) => !(value.value === "" && value.label === "")
    ),
  task: yup
    .object({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .test(
      "NoTask",
      "Must select Task",
      (value) => !(value.value === -1 && value.label === "")
    ),
  description: yup.string(),
});

type AllocateTaskDialogType = yup.InferType<typeof AllocateTaskDialogSchema>;

interface AllocateTaskDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actionItems: ActionItem[];
  dealId: number;
}

interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

// TODO this should really be done in Hasura backend, but need to make userDisabled a field not metadata
const filterActionItem = (
  actionItems: AllocateTaskDialogProps["actionItems"],
  leaseAdmin: boolean
) => {
  if (leaseAdmin) {
    return actionItems?.filter((item) => !item.state);
  }

  return actionItems?.filter((item) => !item.userDisabled && !item.state);
};

export const AllocateTaskDialog = (props: AllocateTaskDialogProps) => {
  const form = useForm<AllocateTaskDialogType>({
    resolver: yupResolver(AllocateTaskDialogSchema),
  });
  const leaseAdmin = useReturnUserRole() === Role.Admin;

  const users = useQuery(GetUsersDocument);
  const [UpsertActionAllocation] = useMutation(UpsertActionAllocationDocument);
  const [DeleteActionAllocation] = useMutation(DeleteActionAllocationDocument);
  const [GetActionAllocation] = useLazyQuery(GetActionAllocationDocument);

  const NONE_USER = { label: "None", value: "None" };

  const allUsers =
    users?.data?.Users?.map((user) => ({
      label: user.user_full_name ?? "",
      value: user.id ?? "",
    })) ?? [];

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (data: AllocateTaskDialogType) => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });

    const queryObj = {
      refetchQueries: [GetDealDocument, GetActionAllocationDocument],
      onError: (error: any) => {
        let message =
          "Error - problem allocating task. Please refresh and try again";
        if (error.message?.includes("constraint")) {
          message =
            "Error - this task has already been assigned to the selected user";
        }
        setValidation({
          show: true,
          severity: "error",
          message,
        });
      },
      onCompleted: (_data: any) => {
        if (_data) {
          setValidation({
            show: true,
            severity: "success",
            message: "Success - allocated task",
          });
        }
      },
    };

    if (data.user.label === NONE_USER.label) {
      const existingAllocation = await GetActionAllocation({
        variables: {
          deal_action_id: data.task.value,
        },
      });

      if (existingAllocation.data?.ActionAllocations?.[0]?.id) {
        await DeleteActionAllocation({
          variables: {
            id: existingAllocation.data?.ActionAllocations?.[0]?.id,
          },
          ...queryObj,
        });
      }

      return;
    }

    await UpsertActionAllocation({
      variables: {
        object: {
          deal_id: props.dealId,
          deal_action_id: data.task.value,
          description: data.description,
          user_id: data.user.value,
        },
      },
      ...queryObj,
    });
  };

  const userSelectField: FormField = {
    type: "select",
    fieldName: "user",
    label: "User",
    helperText: "",
    defaultValue: { label: "", value: "" },
    choices: [...allUsers, NONE_USER],
    columns: 12,
  };

  const ActionItemFiltered = filterActionItem(props.actionItems, leaseAdmin);

  const actionItemSelectField: FormField = {
    type: "select",
    fieldName: "task",
    label: "Task",
    helperText: "",
    choices:
      ActionItemFiltered?.map((item) => ({
        label: item.text,
        value: item.id,
      })) ?? [],
    columns: 12,
  };

  const descriptionField = {
    label: "Description",
    fieldName: "description",
    helperText: "",
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Allocate task</DialogTitle>
      <DialogContent>
        <SelectField form={form} formField={userSelectField} />
        <SelectField form={form} formField={actionItemSelectField} />
        <TextMultiField control={form.control} formField={descriptionField} />

        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={form.handleSubmit(async (data) => {
              await onSubmit(data);
            })}
          >
            Save
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              form.reset();
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
