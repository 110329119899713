import { useQuery, useMutation } from "@apollo/client";
import { UseFormReturn } from "react-hook-form";
import { DealInformationType } from "../../../components/DealInformation/DealInformationConfig";
import { DepositType } from "../../../components/DealInformation/Deposit/DepositConfig";
import {
  CreateDealDataGuarantorsDocument,
  DeleteDealDataGuarantorsDocument,
  GetDepositInformationDocument,
  GetDepositInformationQuery,
  UpdateDepositInformationDocument,
} from "../../../generated/graphql-operations";
import { IntToToggle, toggleToInt } from "../../../utils/CommonFunctions";
import {
  PromotionLevyTypes,
  Toggle,
  ToggleString,
} from "../../../utils/CommonVariables";

export const useGetDealInformationDepositInformation = (dealId: number) => {
  const depositInformation = useQuery(GetDepositInformationDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return depositInformation;
};

export const calculateBasePromoOutgoings = (
  form: UseFormReturn<DealInformationType, object>
) => {
  const baseRent = form.getValues("commercial.baseRent");
  const promoLevyTypeObj = form.getValues("commercial.promotionLevyType");
  const promoLevyType = promoLevyTypeObj?.label ?? PromotionLevyTypes.$;
  const promoLevy = form.getValues("commercial.promotionLevy") ?? 0;
  const outgoings = form.getValues("commercial.outgoings") ?? 0;
  const estimatedArea = form.getValues("unitInfo.estimatedArea") ?? 0;
  const outgoingsCalc = outgoings * estimatedArea;
  const promoLevyCalc =
    promoLevyType === PromotionLevyTypes.$
      ? promoLevy
      : (promoLevy / 100) * baseRent;
  const calc = baseRent + promoLevyCalc + outgoingsCalc;
  return (calc * 1.1) / 12;
};

export const transformData = (
  data: GetDepositInformationQuery
): DepositType => {
  const transformedData = {
    guarantorRequired: IntToToggle(
      data?.Deals_by_pk?.DealData?.[0]?.guarantors_id ?? Toggle.NO
    ),
    bankGuaranteeRequired: IntToToggle(
      data?.Deals_by_pk?.DealData?.[0]?.bank_guarantee_id ?? Toggle.NO
    ),
    rentDeposit: data?.Deals_by_pk?.DealData?.[0]?.rent_deposit ?? undefined,
    achievedRentDeposit:
      data?.Deals_by_pk?.DealData?.[0]?.achieved_rent_deposit ?? undefined,
    bankGuaranteeAmount:
      data?.Deals_by_pk?.DealData?.[0]?.bank_guarantee_amount ?? undefined,
    achievedBankGuarantee:
      data?.Deals_by_pk?.DealData?.[0]?.achieved_bank_guarantee ?? undefined,
    bankGuaranteeMonths:
      data?.Deals_by_pk?.DealData?.[0]?.bank_guarantee_months ?? undefined,
    rentDepositMonths:
      data?.Deals_by_pk?.DealData?.[0]?.rent_deposit_months ?? undefined,
    guarantors:
      data?.Deals_by_pk?.DealData?.[0]?.DealDataGuarantors.map((item) => {
        const guarantor = {
          fullName: item?.full_name ?? "",
          email: item?.email ?? "",
          address: item?.street1 ?? "",
          suburb: item?.suburb ?? "",
          state: {
            label: item?.State?.name ?? "",
            value: item?.State?.id ?? 1,
          },
          postCode: item?.postcode?.toString() ?? "",
        };

        return guarantor;
      }) ?? [],
  };

  return transformedData;
};

export const useMutateDeposit = (dealId: number) => {
  const [UpdateDepositInformation] = useMutation(
    UpdateDepositInformationDocument
  );
  const [CreateDealDataGuarantors] = useMutation(
    CreateDealDataGuarantorsDocument
  );
  const [DeleteDealDataGuarantors] = useMutation(
    DeleteDealDataGuarantorsDocument
  );

  return async (dealDataId: number, data: DepositType) => {
    const transformedData = {
      bank_guarantee_id: toggleToInt(
        data?.bankGuaranteeRequired ?? ToggleString.No
      ),
      guarantors_id: toggleToInt(data?.guarantorRequired ?? ToggleString.No),
      rent_deposit_months: data?.rentDepositMonths ?? null,
      rent_deposit: data?.rentDeposit ?? null,
      bank_guarantee_months: data?.bankGuaranteeMonths ?? null,
      bank_guarantee_amount: data?.bankGuaranteeAmount ?? null,
      achieved_bank_guarantee: data?.achievedBankGuarantee ?? null,
      achieved_rent_deposit: data?.achievedRentDeposit ?? null,
    };

    const guarantors =
      data?.guarantors?.map((item) => ({
        full_name: item?.fullName ?? null,
        email: item?.email ?? null,
        street1: item?.address ?? null,
        street2: "",
        suburb: item?.suburb ?? null,
        postcode: parseInt(item.postCode ?? "", 10) || null,
        state_id: item?.state.value ?? 1,
        deal_data_id: dealDataId,
      })) ?? [];

    await UpdateDepositInformation({
      variables: {
        deal_id: dealId,
        data: transformedData,
      },
    });

    await DeleteDealDataGuarantors({
      variables: {
        deal_id: dealId,
      },
    });

    if (guarantors.length === 0) return;

    await CreateDealDataGuarantors({
      variables: {
        data: guarantors,
      },
    });
  };
};
