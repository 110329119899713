import * as yup from "yup";

export const ApproverSchema = yup.object({
  user: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .min(1, "Must select a user"),
  properties: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .min(1, "Must select at least one property"),
});

export const ApproverSettingsSchema = yup.object({
  approver_2: yup.array().of(ApproverSchema),
  approver_3: yup.array().of(ApproverSchema),
  approver_4: yup.array().of(ApproverSchema),
  approver_5: yup.array().of(ApproverSchema),
});

export const defaultApproverSettings = {
  approver_2: [{ user: [], properties: [] }],
  approver_3: [{ user: [], properties: [] }],
  approver_4: [{ user: [], properties: [] }],
  approver_5: [],
};
export type ApproverType = yup.InferType<typeof ApproverSchema>;
export type ApproverSettingsType = yup.InferType<typeof ApproverSettingsSchema>;

export const defaultEscalatedApprover = { label: "", value: "noUser" };
export const EscalatedApproverSchema = yup.object({
  users: yup.array().of(
    yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .test(
        "NoUserSelected",
        "Must select User",
        (value) => !(value.value === defaultEscalatedApprover.value)
      )
  ),
});

export type EscalatedApproverType = yup.InferType<
  typeof EscalatedApproverSchema
>;
