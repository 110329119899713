import React from "react";
import { useQuery } from "@apollo/client";
import { add, getDaysInMonth, parseISO } from "date-fns";
import { Skeleton } from "@mui/material";
import { GetLafLeaseDetailsDocument } from "../../generated/graphql-operations";
import { convertDaysToTerms } from "../../utils/DateUtils";
import {
  GeneralAmountTypes,
  PromotionLevyTypes,
  ToggleString,
} from "../../utils/CommonVariables";
import { ScrollableTable, ScrollableTableProps } from "./ScrollableTable";
import {
  dollarFormat,
  EMPTY_SYMBOL,
  numberFormat,
  percentFormat,
} from "./Common";
import { Alert } from "../Alert";
import { capitalizeFirstLetter } from "../../utils/CommonFunctions";

const checkIsNaN = (value: number) => (Number.isNaN(value) ? undefined : value);

const columns = [
  "Item",
  "Current/Previous",
  "Budget",
  "Achieved",
  "Variance to budget",
  "Valuation",
  "Variance to valuation",
] as const;

type Columns = typeof columns[number];

const formatter = (value: string | number | undefined, type?: "%" | "$") => {
  if (value == null) {
    return EMPTY_SYMBOL;
  }

  if (typeof value === "string" || typeof value === "object" || value === 0) {
    return value;
  }

  if (!value) {
    return EMPTY_SYMBOL;
  }

  if (type === "$") {
    return dollarFormat.format(value);
  }

  if (type === "%") {
    return percentFormat.format(value / 100);
  }
  return numberFormat.format(value);
};

const getPromotionLevy = (
  promoLevyState: boolean,
  promoLevyType: string | undefined,
  promoLevy: number | undefined,
  baseRent: number
) => {
  if (!promoLevyState) {
    return undefined;
  }

  if (promoLevyType === PromotionLevyTypes.$) {
    return promoLevy;
  }

  if (promoLevyType === PromotionLevyTypes["%"]) {
    return ((promoLevy ?? 0) * baseRent) / 100;
  }

  return undefined;
};

const getAnnualIncrease = (
  type: string | undefined,
  amount: number | undefined
) => {
  if (
    type === GeneralAmountTypes.CPI ||
    type === GeneralAmountTypes["Market review"]
  ) {
    return [type, undefined] as const;
  }

  if (type === GeneralAmountTypes.$ || type === GeneralAmountTypes["%"]) {
    return [amount, type] as const;
  }

  return [undefined, undefined] as const;
};

export const LeaseDetailsTable = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafLeaseDetailsDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data || !data.Deals_by_pk) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  const dealUnits = data?.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0];
  const unit = dealUnits?.Unit ?? dealUnits?.VirtualUnit;
  const budgetInfo =
    dealUnits?.SavedYardiUnitProfile ??
    dealUnits?.YardiUnit?.YardiUnitProfiles?.[0];

  const baseRent = data?.Deals_by_pk?.DealData[0]?.base_net_rent;
  const baseRentBudget = budgetInfo?.DANNUALRENT;
  const previousBaseRent = data?.Deals_by_pk?.DealData[0]?.historical_base_rent;

  const area = unit?.estimated_area ?? undefined;
  const areaBudget = area; // PAREA gives weird numbers

  const previousBaseRentPsm = previousBaseRent / area;
  const baseRentPsm = checkIsNaN(Number(baseRent) / Number(area));
  const baseRentPsmBudget = checkIsNaN(
    Number(baseRentBudget) / Number(areaBudget)
  );

  const outgoings =
    data?.Deals_by_pk?.DealData[0]?.outgoings_state === ToggleString.Yes
      ? data?.Deals_by_pk?.DealData[0]?.outgoings_psm ?? undefined
      : undefined;

  const previousOutgoings =
    data?.Deals_by_pk?.DealData[0]?.historical_outgoings;

  const previousOutgoingsPsm = previousOutgoings / area;

  const outgoingsBudget = budgetInfo?.DANNUALRECOVERYPSM;

  const subtotalNOutgoings =
    (baseRent ?? 0) + (outgoings ?? 0) * Number(area ?? 0);

  const subtotalNOutgoingsBudgets =
    (baseRentBudget ?? 0) + (outgoingsBudget ?? 0) * Number(areaBudget ?? 0);

  const subtotalNOutgoingsBudgetsPrevious =
    previousOutgoings + previousBaseRent;

  const promotionLevy = getPromotionLevy(
    data?.Deals_by_pk?.DealData[0]?.promotion_levy_state === ToggleString.Yes,
    data?.Deals_by_pk?.DealData[0]?.promotion_levy_type ?? undefined,
    data?.Deals_by_pk?.DealData[0]?.promotion_amt ?? undefined,
    data?.Deals_by_pk?.DealData[0]?.base_net_rent ?? 0
  );

  const previousPromotionLevy =
    data?.Deals_by_pk?.DealData[0]?.historical_promo_levy;

  const promotionLevyBudget = budgetInfo?.DANNUALPROMOLEVY;

  const totalCost = (subtotalNOutgoings ?? 0) + (promotionLevy ?? 0);
  const totalCostPrevious =
    subtotalNOutgoingsBudgetsPrevious + previousPromotionLevy;

  const totalCostBudget =
    (subtotalNOutgoingsBudgets ?? 0) + (promotionLevyBudget ?? 0);
  const baseRentValuation = budgetInfo?.VALUATIONNETRENT ?? undefined;

  const turnoverAllowed =
    data?.Deals_by_pk?.DealData[0]?.turnover_rent_state === ToggleString.Yes;

  const unaturalTurnover =
    data?.Deals_by_pk?.DealData[0]?.turn_over_rent_unnatural;

  const turnover = data?.Deals_by_pk?.DealData[0]?.turnover_based_rent_percent;
  let turnoverAmount: number | undefined;
  let turnoverDescription = "";
  if (turnoverAllowed) {
    if (unaturalTurnover) {
      turnoverAmount = unaturalTurnover;
      turnoverDescription = "(unnatural)";
    } else {
      turnoverAmount = turnover
        ? ((baseRent ?? 0) / turnover) * 100
        : undefined;
      turnoverDescription = "(natural)";
    }
  }

  const [annualIncrease, annualType] = getAnnualIncrease(
    data?.Deals_by_pk?.DealData[0]?.annual_increase_type ?? undefined,
    data?.Deals_by_pk?.DealData[0]?.annual_increase ?? undefined
  );

  const leaseCommencementDate = parseISO(
    data.Deals_by_pk.DealData[0].lease_start
  );
  const leaseTerm = convertDaysToTerms(
    leaseCommencementDate,
    data?.Deals_by_pk?.DealData[0]?.lease_term ?? 0
  );

  const leaseTermMonths =
    (leaseTerm?.years ?? 0) * 12 +
    (leaseTerm?.months ?? 0) +
    (leaseTerm?.days ?? 0) /
      getDaysInMonth(add(leaseCommencementDate, leaseTerm ?? {}));

  const leaseTermYears = leaseTermMonths / 12;

  // Make sure getDaysInMonths doesn't return NaN for invalid dates
  const leaseTermYearsCorrected = checkIsNaN(leaseTermYears);

  const optionPeriod = data?.Deals_by_pk?.DealData[0]?.DealOptions.reduce(
    (prev, curr) => prev + (curr.term_length ?? 0),
    0
  );

  const tenancyCoordFee =
    data?.Deals_by_pk?.DealData[0]?.tenancy_coordination_fee;

  const occupancyCostsCappedState =
    data?.Deals_by_pk?.DealData[0]?.capped_occupancy_costs_state;

  const rows: ScrollableTableProps<Columns>["rows"] = [
    {
      Item: "Rent (pa)",
      "Current/Previous": formatter(previousBaseRent, "$"),
      Budget: formatter(baseRentBudget, "$"),
      Achieved: formatter(baseRent, "$"),
      "Variance to budget": formatter(baseRent - baseRentBudget, "$"),
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "rent",
    },
    {
      Item: "Rent (psm)",
      "Current/Previous": formatter(previousBaseRentPsm, "$"),
      Budget: formatter(baseRentPsmBudget, "$"),
      Achieved: formatter(baseRentPsm, "$"),
      "Variance to budget": formatter(
        (baseRentPsm ?? Number.NaN) - (baseRentPsmBudget ?? Number.NaN),
        "$"
      ),
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "rentpsm",
    },
    {
      Item: "Outgoings (psm)",
      "Current/Previous": formatter(previousOutgoingsPsm, "$"),
      Budget: formatter(outgoingsBudget, "$"),
      Achieved: formatter(outgoings, "$"),
      "Variance to budget": formatter(outgoings - outgoingsBudget, "$"),
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "outgoing",
    },
    {
      Item: "Subtotal rent & outgoings",
      "Current/Previous": formatter(subtotalNOutgoingsBudgetsPrevious, "$"),
      Budget: formatter(subtotalNOutgoingsBudgets, "$"),
      Achieved: formatter(subtotalNOutgoings, "$"),
      "Variance to budget": formatter(
        subtotalNOutgoings - subtotalNOutgoingsBudgets,
        "$"
      ),
      Valuation: formatter(baseRentValuation, "$"),
      "Variance to valuation": formatter(
        subtotalNOutgoings - (baseRentValuation ?? undefined),
        "$"
      ),
      sx: { fontWeight: "bold", backgroundColor: "secondary.main" },
      key: "total",
    },
    {
      Item: "Promotion levy (pa)",
      "Current/Previous": formatter(previousPromotionLevy, "$"),
      Budget: formatter(promotionLevyBudget, "$"),
      Achieved: formatter(promotionLevy, "$"),
      "Variance to budget": formatter(
        (promotionLevy ?? Number.NaN) - promotionLevyBudget,
        "$"
      ),
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "levy",
    },
    {
      Item: "Total occupancy cost",
      "Current/Previous": formatter(totalCostPrevious, "$"),
      Budget: formatter(totalCostBudget, "$"),
      Achieved: formatter(totalCost, "$"),
      "Variance to budget": formatter(totalCost - totalCostBudget, "$"),
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      sx: { fontWeight: "bold", backgroundColor: "secondary.main" },
      key: "totalOcc",
    },
    {
      Item: "Turnover rent %",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(turnover, "%"),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "turnover",
    },
    {
      Item: `Turnover rent threshold ${turnoverDescription}`,
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(turnoverAmount, "$"),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "turnoverThreshold",
    },
    {
      Item: "Annual rent reviews",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(annualIncrease, annualType),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "annualRent",
    },
    {
      Item: "Lease term (Years)",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(leaseTermYearsCorrected),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "leaseTerm",
    },
    {
      Item: "Tenancy Coordination fee",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(tenancyCoordFee ?? undefined, "$"),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "tenantContribution",
    },
    {
      Item: "Option period",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: formatter(optionPeriod),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "option",
    },
    {
      Item: "Capped occupancy costs",
      "Current/Previous": EMPTY_SYMBOL,
      Budget: EMPTY_SYMBOL,
      Achieved: capitalizeFirstLetter(occupancyCostsCappedState),
      "Variance to budget": EMPTY_SYMBOL,
      Valuation: EMPTY_SYMBOL,
      "Variance to valuation": EMPTY_SYMBOL,
      key: "occupancyCostsCapped",
    },
  ];

  return <ScrollableTable columns={columns} rows={rows} />;
};
