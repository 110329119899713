import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    postLogoutRedirectUri: "/",
  },
};

export const pca = new PublicClientApplication(configuration);
