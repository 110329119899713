import React from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  CopyDealReLafDocument,
  CopyDealDocument,
  Unit,
} from "../../generated/graphql-operations";

export const useCopyDealReLaf = (dealId: number) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const [AddDeal] = useMutation(CopyDealReLafDocument);

  return [
    isSubmitting,
    async (comment: string) => {
      setIsSubmitting(true);
      let newDealId;
      try {
        const createdDeal = await AddDeal({
          variables: {
            dealId,
            comment,
          },
        });
        newDealId = createdDeal.data?.copyDealRelaf?.newDealId;
      } catch (e) {
        setIsSubmitting(false);
        throw e;
      }
      navigate(`/deals/${newDealId}`, {
        replace: true,
      });
      setIsSubmitting(false);
    },
  ] as const;
};

export const useCopyDeal = (dealId: number) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const [AddDeal] = useMutation(CopyDealDocument);

  return [
    isSubmitting,
    async (comment: string, unit: Unit) => {
      setIsSubmitting(true);
      let newDealId;
      try {
        const createdDeal = await AddDeal({
          variables: {
            dealId,
            comment,
            unit,
          },
        });
        newDealId = createdDeal.data?.copyDeal?.newDealId;
      } catch (e) {
        setIsSubmitting(false);
        throw e;
      }
      navigate(`/deals/${newDealId}`, {
        replace: true,
      });
      setIsSubmitting(false);
    },
  ] as const;
};
