import React from "react";
import * as yup from "yup";

import { YupPhoneValidation } from "../../MuiCustomNumberFormat";

export const CompanySchema = yup.object({
  legalName: yup
    .string()
    .required("Trading name is a required field")
    .default(""),
  acn: yup
    .string()
    .required("ACN is a required field")
    .test(
      "AcnLength",
      "ACN must be 9 digits",
      (val) => val?.length === 0 || val?.length === 9
    )
    .label("ACN")
    .default(""),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  address: yup.string().default(""),
  city: yup.string().default(""),
  state: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string().default(""),
  addressId: yup.number().default(-1),
});

export interface CompanyData extends yup.InferType<typeof CompanySchema> {}

export const defaultCompanyData: CompanyData = CompanySchema.getDefault();

export interface CompanyProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId?: number;
}
