import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export interface BreadCrumbsProps {
  links: {
    text: string;
    url: string;
  }[];
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = (props) => {
  const lastIndex = props.links.length - 1;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.links.slice(0, -1).map(({ text, url }) => (
        <Link underline="hover" color="primary" href={url} key={text}>
          {text}
        </Link>
      ))}
      <Typography color="text.secondary">
        {props.links[lastIndex].text}
      </Typography>
    </Breadcrumbs>
  );
};
