import React from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useCreatePermittedUse,
  useGetPermittedUseTemplate,
  applyPermittedUseDefaults,
  useUpdatePermittedUse,
} from "../../../../adapters/Templates/Templates";
import { EditorControlled } from "../../../Form/FormComponents";
import { TextNumberField } from "../../../Form/Form";
import { PermittedUseSchema, PermittedUseType } from "./Config";

interface PermittedUseDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  defaultData: PermittedUseType;
  onSubmit: (data: PermittedUseType) => void;
}

interface UpdateDialogProps {
  open: boolean;
  onClose: () => void;
  templateId: number;
}

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const PermittedUseDialog = (props: PermittedUseDialogProps) => {
  const form = useForm({
    resolver: yupResolver(PermittedUseSchema),
    mode: "onBlur",
    defaultValues: props.defaultData,
  });

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700, pl: 3 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={12}>
            <TextNumberField
              form={form}
              formField={{
                fieldName: "title",
                helperText: "",
                label: "Permitted Use title",
                type: "text",
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <EditorControlled
              control={form.control}
              label="Description"
              name="description"
            />
          </Grid>

          <Grid item sm={12}>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color="primary"
              disabled={form.formState.isSubmitting}
              onClick={form.handleSubmit(async (submitData) => {
                await props.onSubmit(submitData);
                props.onClose();
              })}
            >
              Submit
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.onClose()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const UpdateDialog = ({ templateId, ...props }: UpdateDialogProps) => {
  const updateTemplate = useUpdatePermittedUse(templateId);
  const data = useGetPermittedUseTemplate(templateId);

  if (
    data?.TemplatePermittedUses_by_pk === undefined ||
    data?.TemplatePermittedUses_by_pk === null
  ) {
    return <Skeleton />;
  }

  return (
    <PermittedUseDialog
      {...props}
      title="Update Permitted Use"
      defaultData={applyPermittedUseDefaults(data.TemplatePermittedUses_by_pk)}
      onSubmit={updateTemplate}
    />
  );
};

export const CreateDialog = (props: CreateDialogProps) => {
  const createTemplate = useCreatePermittedUse();

  return (
    <PermittedUseDialog
      {...props}
      title="Create Permitted Use"
      defaultData={applyPermittedUseDefaults()}
      onSubmit={createTemplate}
    />
  );
};
