import * as yup from "yup";
import {
  REQUIRED_FIELD,
  GREATER_THAN_0,
  INTEGER_VALUE,
} from "../../../utils/CommonErrors";
import { GeneralAmountTypes } from "../../../utils/CommonVariables";

const OptionSchema = yup.object({
  TermYear: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE),
  TermMonth: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0)
    .integer(INTEGER_VALUE),
  year1Review: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  yearSubsequentReview: yup
    .number()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  year1ReviewType: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .required(REQUIRED_FIELD),
  yearSubsequentReviewType: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .required(REQUIRED_FIELD),
  worksRequired: yup.string(),
});

export const OptionTermSchema = yup.object({
  options: yup.array(OptionSchema),
});

export type OptionTermType = yup.InferType<typeof OptionTermSchema>;
export type OptionsType = yup.InferType<typeof OptionSchema>;

export const DEFAULT_REVIEW_TYPE = {
  label: GeneralAmountTypes["%"],
  value: GeneralAmountTypes["%"],
};

export const DEFAULT_OPTIONS: OptionsType = {
  TermMonth: undefined,
  TermYear: undefined,
  year1Review: undefined,
  yearSubsequentReview: undefined,
  worksRequired: "",
  year1ReviewType: DEFAULT_REVIEW_TYPE,
  yearSubsequentReviewType: DEFAULT_REVIEW_TYPE,
};
