import React, { useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import {
  GetCompaniesDocument,
  GetCompaniesQuery,
  SoftDeleteCompanyDocument,
} from "../../generated/graphql-operations";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { UpdateCompanyDialog } from "./CreateUpdateCompany/CreateUpdateCompany";
import { Role, useReturnUserRole } from "../../contexts/Auth/UserRole";

export interface CompanyListProps {
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const CompanyList = ({
  toolBar,
  hideColumns = undefined,
}: CompanyListProps) => {
  const { data, loading, error } = useQuery(GetCompaniesDocument);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const disable = ![Role.Admin, Role.TdAssignee].includes(
    useReturnUserRole() as Role
  );
  const [companyId, setCompanyId] = useState(0);
  const [softDeleteCompany] = useMutation(SoftDeleteCompanyDocument);
  const columns: GridColDef<NonNullable<GetCompaniesQuery["Companies"][0]>>[] =
    [
      {
        field: "legal_name",
        headerName: "Legal Name",
        flex: 1,
      },
      {
        field: "acn",
        headerName: "ACN",
        flex: 1,
        valueGetter: (params) => params.row.acn,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "buttons",
        headerName: "",
        flex: 0.6,
        renderCell: (params) => (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                disabled={disable}
                variant="outlined"
                startIcon={<Edit />}
                onClick={() => {
                  setCompanyId(params.row.id);
                  setOpenEditDialog(true);
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  setCompanyId(params.row.id);
                  setOpenConfirmation(true);
                }}
                disabled={disable}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
    ];
  return (
    <>
      <DataGrid
        rows={data?.Companies || []}
        columns={columns}
        loading={loading || error !== undefined}
        columnVisibilityModel={hideColumns}
        components={toolBar ? { Toolbar: CustomToolbar } : {}}
      />
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Delete Company"
        description="Are you sure you want to delete this company?"
        confirmText="Yes"
        declineText="No"
        onConfirm={async () => {
          await softDeleteCompany({
            variables: {
              id: companyId,
            },
            refetchQueries: [GetCompaniesDocument],
          });
        }}
      />
      <UpdateCompanyDialog
        companyId={companyId}
        open={openEditDialog}
        setOpen={setOpenEditDialog}
      />
    </>
  );
};

export default CompanyList;
