import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, Typography, Chip, Skeleton } from "@mui/material";
import DOMPurify from "dompurify";
import { GetLafUsageDocument } from "../../generated/graphql-operations";
import { Alert } from "../Alert";
import { ReadOnlyEditor } from "../Form/RichTextEditor";

export const LafUsage = (props: { dealId: number }) => {
  const { data, error } = useQuery(GetLafUsageDocument, {
    variables: { deal_id: props.dealId },
  });

  if (error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data) {
    return (
      <Skeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width="100%"
        height={100}
      />
    );
  }

  const usage =
    data.Deals_by_pk?.DealData[0]?.DealPermittedUses[0]?.description ?? "";

  const foodMenu =
    data.Deals_by_pk?.DealData[0]?.DealPermittedUses[0]?.food_menu ?? "";

  const sublet = DOMPurify.sanitize(
    data.Deals_by_pk?.DealData[0]?.DealPermittedUses[0]?.sublet ?? ""
  );

  return (
    <Grid container columns={{ xs: 12 }} justifyContent="space-between">
      <Grid item xs={12} pb={2}>
        {usage !== "" && (
          <>
            <Chip
              sx={{ ml: -1 }}
              label={
                data.Deals_by_pk?.DealData[0].DealPermittedUses[0]
                  ?.TemplatePermittedUse?.title ?? ""
              }
            />
            <Typography>Description:</Typography>
            <ReadOnlyEditor content={usage} />
          </>
        )}
      </Grid>

      {foodMenu !== "" && (
        <Grid item xs={12} pb={2}>
          <Chip sx={{ ml: -1 }} label="Food menu required for lease" />
          <Typography>Description:</Typography>
          <ReadOnlyEditor content={foodMenu} />
        </Grid>
      )}

      {sublet !== "" && (
        <Grid xs={12} item>
          <Chip sx={{ ml: -1 }} label="Sublet to franchisee" />
          <Typography>Description:</Typography>
          <ReadOnlyEditor content={sublet} />
        </Grid>
      )}
    </Grid>
  );
};
