import React from "react";

import { Grid, Button, Box, Paper } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Title } from "../../../themes/ThemeComponents";

import { FinalStepDealProps } from "../DealConfig";

import { DealTerms } from "./DealTerms";
import { RenewalDataType } from "../../Renewals/Config";

export const StepTwo = (
  props: FinalStepDealProps & {
    renewalData?: RenewalDataType;
  }
) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      maxWidth="md"
      sx={{ m: "0 auto" }}
    >
      <Paper sx={{ m: 2, p: 2 }} elevation={10}>
        <Grid container>
          <Grid item xs={12} m={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Title variant="h6"> Deal terms </Title>
              {!props.renewalData && (
                <Button
                  size="medium"
                  startIcon={<ArrowBack />}
                  variant="outlined"
                  color="primary"
                  onClick={() => props.dispatch({ type: "back" })}
                >
                  Back
                </Button>
              )}
            </Box>
          </Grid>
          <Grid container>
            <DealTerms {...props} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
