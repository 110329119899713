import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Typography,
} from "@mui/material";
import theme from "../../themes/theme";
import { ChatRole, Citation } from "../../pages/Chat/models";

interface Props {
  message: string;
  citations?: Citation[];
  role: ChatRole;
}

export const Message = ({ message, citations, role }: Props) => {
  const colour = (() => {
    switch (role) {
      case ChatRole.Assistant:
        return alpha(theme.palette.info.light, 0.2);
      case ChatRole.User:
        return alpha(theme.palette.secondary.light, 0.2);
      default:
        return alpha(theme.palette.error.light, 0.2);
    }
  })();

  // TODO (Alex): move this out of the component
  const ml = role === ChatRole.User ? "auto" : 0;

  return (
    <Box
      sx={{
        borderRadius: "5px",
        boxShadow: 3,
        width: "fit-content",
        maxWidth: "100%",
        my: 2,
        px: 1,
        py: 0.5,
        ml,
        backgroundColor: colour,
      }}
    >
      <Typography>{message}</Typography>
      {role === ChatRole.Assistant && citations && citations.length > 0 && (
        <Accordion sx={{ boxShadow: "none", border: "none", mb: 1 }}>
          <AccordionSummary sx={{ maxHeight: "5px", mt: 2 }}>
            <Typography variant="subtitle2">Citations</Typography>
          </AccordionSummary>

          {citations.map((citation, index) => {
            return (
              <React.Fragment key={citation.id}>
                <AccordionDetails>
                  <Typography>{`${index + 1} - ${citation.title}`}</Typography>
                  <Accordion>
                    <AccordionSummary sx={{ mt: 1 }}>
                      <Typography variant="subtitle2">Full document</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography>{citation.content}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </React.Fragment>
            );
          })}
        </Accordion>
      )}

      {role === ChatRole.Assistant && (
        <Typography variant="caption">
          AI generated responses may be incorrect
        </Typography>
      )}
    </Box>
  );
};
