import * as yup from "yup";
import { GetLeasesForRenewalQuery } from "../../generated/graphql-operations";

export const RenewalDialogSchema = yup.object({
  tenantType: yup
    .object({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .default({
      label: "Individual",
      value: -1,
    })
    .test(
      "NoTenantType",
      "Must select Tenant type",
      (value) => !(value.value === -1 && value.label === "")
    ),
  mainUnit: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .test(
      "NoMainUnit",
      "Must select Main unit",
      (value) => !(value.value === "" && value.label === "")
    ),
});

export type RenewalDialogType = yup.InferType<typeof RenewalDialogSchema>;

export type RenewalDataType =
  GetLeasesForRenewalQuery["YardiLeasesForRenewal"][0] & RenewalDialogType;
