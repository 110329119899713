import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { parseISO } from "date-fns";
import {
  FitoutLandlordWorksType,
  DEFAULT_VALUES,
} from "../../../components/DealInformation/FitoutLandlordWorks/Config";
import { landlordWorksOptions } from "../../../components/DealInformation/FitoutLandlordWorks/LandlordWorks";
import {
  GetDealFitoutLandlordWorksDocument,
  GetDealFitoutLandlordWorksQuery,
  UpdateDealFitoutLandlordWorksDocument,
} from "../../../generated/graphql-operations";
import {
  hoardingAndSkinsToggleToInt,
  IntToHoardingAndSkinsToggle,
} from "../../../utils/CommonFunctions";

export const useGetDealFitoutLandlordWorks = (
  dealId: number,
  loadData: (data: GetDealFitoutLandlordWorksQuery) => void,
  onError: (error: ApolloError) => void
) => {
  const dealInfoUnitInfo = useQuery(GetDealFitoutLandlordWorksDocument, {
    variables: {
      deal_id: dealId,
    },
    onCompleted: loadData,
    onError,
  });

  return dealInfoUnitInfo;
};

export const transformFitoutLandlordWorks = (
  data: GetDealFitoutLandlordWorksQuery
): FitoutLandlordWorksType => {
  const fitoutData = data.Deals_by_pk?.DealData[0].DealFitoutLandlordWork;

  return {
    cappedCategory: fitoutData?.capped_category ?? undefined,
    costLandlordWorks: fitoutData?.cost_landlord_works ?? undefined,
    costMakegood: fitoutData?.cost_makegood ?? undefined,
    dateCompleteFitout: fitoutData?.date_complete_fitout
      ? parseISO(fitoutData?.date_complete_fitout)
      : undefined,
    fitoutPreviousTenant: fitoutData?.fitout_previous_tenant ?? undefined,
    landlordUndertakeWorks:
      fitoutData?.landlord_undertake_works ??
      DEFAULT_VALUES.landlordUndertakeWorks,
    partialFitoutItems: fitoutData?.partial_fitout_items ?? undefined,
    preview: fitoutData?.preview ?? undefined,
    landlordWorks: landlordWorksOptions.find(
      (elem) => elem.value === fitoutData?.landlord_works_type
    ) ?? { label: "", value: "" },
    refurbishedFitoutWorks:
      fitoutData?.refurbished_fitout_works ??
      DEFAULT_VALUES.refurbishedFitoutWorks,
    tenancyDeliveryDealType: {
      label:
        fitoutData?.tenancy_delivery_deal_type ??
        DEFAULT_VALUES.tenancyDeliveryDealType.label,
      value:
        fitoutData?.tenancy_delivery_deal_type ??
        DEFAULT_VALUES.tenancyDeliveryDealType.value,
    },
    tenantWorks: fitoutData?.tenant_works ?? DEFAULT_VALUES.tenantWorks,
    // convert string of ',' to array, filter out empty array
    tenantWorksOptions: (fitoutData?.tenant_works_options ?? "")
      .split(",")
      .filter((item) => item !== ""),
    hoardingToggle: IntToHoardingAndSkinsToggle(fitoutData?.hoarding ?? 1),
    skinsToggle: IntToHoardingAndSkinsToggle(fitoutData?.skins ?? 1),
    fitoutDescription: fitoutData?.fitout_description ?? "",
  };
};

export const useMutateFitoutLandlordWorks = () => {
  const [updateFitoutLandlordWorksInfo] = useMutation(
    UpdateDealFitoutLandlordWorksDocument
  );
  return async (dealDataId: number, data: FitoutLandlordWorksType) => {
    await updateFitoutLandlordWorksInfo({
      variables: {
        update_object: {
          deal_data_id: dealDataId,
          capped_category: data.cappedCategory ?? null,
          cost_landlord_works: data.costLandlordWorks ?? null,
          cost_makegood: data.costMakegood ?? null,
          date_complete_fitout: data.dateCompleteFitout?.toDateString(),
          fitout_previous_tenant: data.fitoutPreviousTenant ?? "",
          landlord_undertake_works: data.landlordUndertakeWorks,
          partial_fitout_items: data.partialFitoutItems ?? "",
          landlord_works_type: data.landlordWorks.value,
          preview: data.preview ?? "",
          refurbished_fitout_works: data.refurbishedFitoutWorks,
          tenancy_delivery_deal_type: data.tenancyDeliveryDealType.label,
          tenant_works: data.tenantWorks,
          tenant_works_options: data.tenantWorksOptions?.join(","),
          hoarding: hoardingAndSkinsToggleToInt(
            data.hoardingToggle ?? "tenant"
          ),
          skins: hoardingAndSkinsToggleToInt(data.skinsToggle ?? "tenant"),
          fitout_description: data.fitoutDescription ?? "",
        },
      },
    });
  };
};
