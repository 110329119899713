import React from "react";
import { Box } from "@mui/material";
import { SelectField } from "../../Form/Form";
import { EditorControlled } from "../../Form/FormComponents";
import { DealInformationSectionProps } from "../DealInformationConfig";

export const NO_OPTION_USE = { label: "Other", value: -1 };
export const landlordWorksOptions = [
  {
    value: "asPerFitoutGuide",
    label: "As per fitout guide",
  },
  {
    value: "asIsWhereIs",
    label: '"As is where is" condition',
  },
  {
    value: "other",
    label: "Other",
  },
];

export const LandlordWorks = (
  props: DealInformationSectionProps & { disabled?: boolean }
) => {
  return (
    <Box width="100%">
      <SelectField
        form={props.form}
        formField={{
          type: "select",
          fieldName: "fitoutLandlordWorks.landlordWorks",
          helperText: "",
          choices: landlordWorksOptions,
          label: "Landlord works:",
          disabled: props.disabled,
          onChange: (_, choice) => {
            let preview = "";

            if (choice.value === "asPerFitoutGuide") {
              preview =
                "Refer to the Charter Hall Retail Fitout Guide for details of the 'bare shell' that will be provided by the Landlord.";
            } else if (choice.value === "asIsWhereIs") {
              preview =
                "Nil works by Landlord. The Landlord Works referred to in the Charter Hall Retail Fitout Guide do not apply, with the Premises to be provided by the Landlord in an ‘as is, where is’ condition.";
            }
            props.form.setValue("fitoutLandlordWorks.preview", preview);
          },
        }}
      />

      <Box p={1} width="100%" />

      <EditorControlled
        label="Preview"
        name="fitoutLandlordWorks.preview"
        control={props.form.control}
        disable={props.disabled}
      />
    </Box>
  );
};
