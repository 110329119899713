import React from "react";
import { useQuery } from "@apollo/client";
import { Box, Grid, Skeleton } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { GetYardiLimitedCustomersDocument } from "../../../../generated/graphql-operations";
import { SelectField, TextNumberField, FormField } from "../../../Form/Form";
import { DealProps } from "../../DealConfig";
import { DealType } from "../../../../utils/CommonVariables";

interface TradingNameProps {
  form: UseFormReturn<any, any>;
  parentProps?: DealProps;
  tradingNameFieldName: string;
  tradingNameSelectFieldName: string;
}

const newTradingNameChoice = { label: "Add new", value: "" };

export const TradingName = (props: TradingNameProps) => {
  const tradingNames = useQuery(GetYardiLimitedCustomersDocument);

  if (!tradingNames.data) {
    return <Skeleton />;
  }

  const choiceNames = Array.from(
    new Set(
      tradingNames.data.YardiLimitedCustomers.map((customer) => {
        return {
          name: customer.CustomerName ?? "",
          code: customer.CustomerCode ?? "",
        };
      })
    )
  );

  const tradingNameSelectField: FormField = {
    type: "select",
    label: "Trading name",
    fieldName: props.tradingNameSelectFieldName,
    columns: 12,
    disabled:
      props.form.watch("dealType")?.dealType?.label === DealType.Renewal,
    choices: [
      newTradingNameChoice,
      ...choiceNames.map((choice) => ({
        label: choice.name,
        value: choice.code,
      })),
    ],
    helperText: "",
    onChange: (e, data) => {
      const choice = { ...data };
      if (choice.value === newTradingNameChoice.value) {
        choice.label = "";
      }
      props.form.setValue(props.tradingNameFieldName as any, choice.label);

      if (props.parentProps) {
        const newTenantInfo = {
          ...props.parentProps.state.tenantInfo,
          tradingName: choice.label,
          tradingNameSelect: { label: choice.label, value: choice.value },
        };
        props.parentProps.setState((prev) => ({
          ...prev,
          tenantInfo: newTenantInfo,
        }));
      }
    },
  };

  const tradingNameField: FormField = {
    type: "text",
    label: "",
    fieldName: props.tradingNameFieldName,
    columns: 6,
    helperText: "",
    onBlur: (e) => {
      const data = (e.target as HTMLInputElement).value;
      props.form.setValue(props.tradingNameFieldName as any, data);

      if (props.parentProps) {
        const newTenantInfo = {
          ...props.parentProps.state.tenantInfo,
          tradingName: data,
        };
        props.parentProps.setState((prev) => ({
          ...prev,
          tenantInfo: newTenantInfo,
        }));
      }
    },
  };

  return (
    <>
      <Grid item xs={tradingNameSelectField.columns} p={1}>
        <SelectField formField={tradingNameSelectField} form={props.form} />
      </Grid>
      <Box width="100%" />
      {props.form.watch(props.tradingNameSelectFieldName as any)?.label ===
        newTradingNameChoice.label && (
        <Grid item xs={tradingNameField.columns} p={1}>
          <TextNumberField formField={tradingNameField} form={props.form} />
        </Grid>
      )}
    </>
  );
};
