import * as yup from "yup";
import { REQUIRED_FIELD, INTEGER_VALUE } from "../../../utils/CommonErrors";

const CustomLicenceSchema = yup.object({
  licenceName: yup.string().default("").required(REQUIRED_FIELD),
  licenceRent: yup.number().required(REQUIRED_FIELD).default(0),
  description: yup.string().default(""),
});

export const StorageSchema = yup.object({
  storageRoomLicenceRequired: yup.string().oneOf(["yes", "no"]).default("no"),
  storageRent: yup.number().when("storageRoomLicenceRequired", {
    is: "yes",
    then: (schema) => schema.required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  storageArea: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  seatingLicenceRequired: yup.string().oneOf(["yes", "no"]).default("no"),
  seatingRent: yup.number().when("seatingLicenceRequired", {
    is: "yes",
    then: (schema) => schema.required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  seatingArea: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  signageLicenceRequired: yup.string().oneOf(["yes", "no"]).default("no"),
  signageRent: yup.number().when("signageLicenceRequired", {
    is: "yes",
    then: (schema) => schema.required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  numberOfSigns: yup
    .number()
    .integer(INTEGER_VALUE)
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  signageLocation: yup.string().default(""),
  parkingLicenceRequired: yup.string().oneOf(["yes", "no"]).default("no"),
  parkingRent: yup.number().when("parkingLicenceRequired", {
    is: "yes",
    then: (schema) => schema.required(REQUIRED_FIELD),
    otherwise: (schema) => schema,
  }),
  numberOfCarparks: yup
    .number()
    .integer(INTEGER_VALUE)
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  parkingLocation: yup.string().default(""),
  customLicences: yup.array().of(CustomLicenceSchema).required().default([]),
});

export const defaultCustomLicence = CustomLicenceSchema.getDefault();
export type CustomLicenceType = yup.InferType<typeof CustomLicenceSchema>;
export type StorageType = yup.InferType<typeof StorageSchema>;
