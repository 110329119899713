import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GetContactsDocument,
  GetContactsQuery,
} from "../../generated/graphql-operations";

export interface ContactListProps {
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const ContactList = ({
  toolBar,
  hideColumns = undefined,
}: ContactListProps) => {
  const { data, loading, error } = useQuery(GetContactsDocument);
  const navigate = useNavigate();
  const columns: GridColDef<NonNullable<GetContactsQuery["Contacts"][0]>>[] = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.5,
      valueGetter: (params) => params.row.first_name,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      valueGetter: (params) => params.row.last_name,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      valueGetter: (params) => params.row.phone,
    },
    {
      field: "company",
      headerName: "Company",
      flex: 2,
      valueGetter: (params) => params.row.Company?.legal_name,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 2,
      valueGetter: (params) =>
        JSON.parse(params.row.content ?? "{}").position ?? "",
    },
    {
      field: "deals",
      headerName: "Active Deals",
      align: "center",
      flex: 1,
      valueGetter: (params) => params.row.DealsContacts.length,
    },
  ];
  return (
    <DataGrid
      rows={data?.Contacts || []}
      columns={columns}
      loading={loading || error !== undefined}
      columnVisibilityModel={hideColumns}
      onCellClick={(params, event) => {
        if (!event.ctrlKey) {
          navigate(`/Contacts/${params.row.id}`);
        }
      }}
      components={toolBar ? { Toolbar: CustomToolbar } : {}}
    />
  );
};

export default ContactList;
