import React from "react";
import { parseISO } from "date-fns";
import { Typography, Stack, Chip, Grid, Box, Avatar } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetDealCommentsDocument } from "../../generated/graphql-operations";
import { BLACK_ALPHA_60 } from "../../utils/CommonVariables";
import { checkAndFormatDate } from "../../utils/DateUtils";

interface LafCommentsProps {
  dealId: number;
  lafVersion: string;
}

interface LafCommentProps {
  comment: {
    name: string;
    text: string;
    date: string;
    tags: string[];
    taggedUsers: string[];
  };
}

const LafComment = (props: LafCommentProps) => {
  return (
    <>
      <Typography variant="subtitle1" display="inline">
        {props.comment.name}{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        display="inline"
        sx={{ color: BLACK_ALPHA_60 }}
      >
        {checkAndFormatDate(
          parseISO(`${props.comment.date}Z`),
          "ccc d LLL, HH:mm a"
        ) ?? "Invalid date"}
      </Typography>
      {props.comment.taggedUsers.length > 0 && (
        <Typography variant="body1" sx={{ mb: 1, color: BLACK_ALPHA_60 }}>
          {props.comment.taggedUsers.map((user) => `@${user} `)}
        </Typography>
      )}
      <Typography variant="body1" sx={{ mb: 1, color: BLACK_ALPHA_60 }}>
        {props.comment.text}
      </Typography>
      <Stack direction="row">
        {props.comment.tags.map((tag) => (
          <Chip key={tag} variant="outlined" label={tag} sx={{ mr: 1 }} />
        ))}
      </Stack>
    </>
  );
};

export const LafComments = (props: LafCommentsProps) => {
  const filterTag = props.lafVersion;

  const dealComments = useQuery(GetDealCommentsDocument, {
    variables: {
      deal_id: props.dealId,
    },
  });

  const allComments = dealComments?.data?.Comments?.map((item) => ({
    id: item?.id,
    name: item?.User?.user_full_name ?? "",
    text: item?.text ?? "",
    tags:
      item?.CommentTags.map((commentTag) => commentTag.Tag?.name ?? "") ?? [],
    date: item?.created_at,
    taggedUsers:
      item?.CommentUserTags.map(
        (commentUserTag) => commentUserTag.User?.user_full_name ?? ""
      ) ?? [],
  }));

  return (
    <Grid container>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {allComments
          ?.filter(
            (comment) => filterTag === "" || comment.tags.includes(filterTag)
          )
          .map((comment) => (
            <React.Fragment key={comment.id}>
              <Grid item xs="auto">
                <Avatar>
                  {comment.name
                    .split(" ")
                    .map((name) => name?.[0]?.toUpperCase())
                    .join("")}
                </Avatar>
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <LafComment comment={comment} />
              </Grid>
              <Box width="100%" />
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};
