import React from "react";

import { Route, Routes } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { TemplateDetails } from "./components/Template/TemplateDetails/TemplateDetails";
import { Role, useReturnUserRole } from "./contexts/Auth/UserRole";

import {
  ApproverSettingsPage,
  ChatPage,
  Companies,
  Contact,
  Contacts,
  CreateDeal,
  Dashboard,
  Deal,
  DealInformationPage,
  Deals,
  LafPage,
  MainAppBar,
  Properties,
  Property,
  Templates,
  SearchDocs,
  YardiEgestionPage,
} from "./pages";
import { ActiveLeases } from "./pages/ActiveLease/ActiveLeases";
import { ActiveLease } from "./pages/ActiveLease/ActiveLease";

// Don't have Permissions to access page react comp
const NotAllowed = () => {
  return (
    <Box>
      <Typography color="text.secondary">
        Do not have permissions to access this page
      </Typography>
    </Box>
  );
};

const App = () => {
  const role = useReturnUserRole();
  const allowed = role !== Role.TdAssignee;
  return (
    <MainAppBar>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/create-deal"
          element={allowed ? <CreateDeal /> : <NotAllowed />}
        />
        <Route
          path="/renew-deal"
          element={allowed ? <CreateDeal /> : <NotAllowed />}
        />
        <Route path="/deals" element={<Deals />} />
        <Route path="/deals/:dealId" element={<Deal />} />
        <Route
          path="/deals/:dealId/information"
          element={<DealInformationPage />}
        />
        <Route path="/deals/:dealId/laf" element={<LafPage />} />
        <Route
          path="/deals/:dealId/yardiegestion"
          element={role === Role.Admin ? <YardiEgestionPage /> : <NotAllowed />}
        />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/contacts/:contactId" element={<Contact />} />
        <Route path="/companies" element={<Companies />} />
        <Route
          path="/properties"
          element={allowed ? <Properties /> : <NotAllowed />}
        />
        <Route
          path="/properties/:propertyId"
          element={allowed ? <Property /> : <NotAllowed />}
        />
        <Route
          path="/templates"
          element={role === Role.Admin ? <Templates /> : <NotAllowed />}
        />
        <Route
          path="/templates/*"
          element={role === Role.Admin ? <TemplateDetails /> : <NotAllowed />}
        />
        <Route
          path="/approvals-settings"
          element={allowed ? <ApproverSettingsPage /> : <NotAllowed />}
        />
        <Route path="/active-leases" element={<ActiveLeases />} />
        <Route path="/active-leases/:tenantCode" element={<ActiveLease />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/search-docs" element={<SearchDocs />} />
      </Routes>
    </MainAppBar>
  );
};

export default App;
