import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { Alert } from "../Alert";
import { useGetDealInfoUnitInfo } from "../../adapters/DealInformation/UnitInformation/UnitInformation";

export const VirtualUnitSection = (props: { dealId: number }) => {
  const unitInfo = useGetDealInfoUnitInfo(props.dealId);

  if (unitInfo.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!unitInfo.data) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  return (
    <Typography>
      {unitInfo?.data?.Deals_by_pk?.DealData?.[0]?.DealsUnits?.[0]?.description}
    </Typography>
  );
};
