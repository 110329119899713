import React, { ChangeEvent } from "react";
import { UseFormReturn, Controller, Control } from "react-hook-form";

import {
  Grid,
  Autocomplete,
  InputAdornment,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Chip,
} from "@mui/material";

import { MuiNumberFormatter } from "../MuiCustomNumberFormat";
import { FormTextField } from "./FormComponents";
import theme from "../../themes/theme";

export interface IformFieldChoice {
  label: string;
  value: number | string;
}

export interface FormField {
  type: "text" | "select" | "number";
  fieldName: string;
  label: string;
  helperText: string;
  choices?: IformFieldChoice[];
  columns: number;
  inlineLabel?: string;
  disabled?: boolean;
  defaultValue?: string | number | IformFieldChoice;
  adornment?: {
    value: string;
    position: "start" | "end";
  };
  customComp?: ReturnType<typeof MuiNumberFormatter>;
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<Element, Event>,
    data?: any,
    reason?: AutocompleteChangeReason,
    detail?: AutocompleteChangeDetails<any> | undefined
  ) => void | {} | Promise<void> | Promise<{}>;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void | {} | Promise<void> | Promise<{}>;
}

interface FormProps {
  fields: FormField[];
  form: UseFormReturn<any, any>;
}

interface FieldProps {
  formField: Omit<FormField, "columns">;
  form: UseFormReturn<any, any>;
  // eslint-disable-next-line react/no-unused-prop-types
  disableClearable?: boolean;
}

interface ControlFieldProps {
  formField: Omit<FormField, "columns">;
  control: Control<any>;
  disableClearable?: boolean;
}

export const TextNumberField = (props: FieldProps) => {
  return (
    <Controller
      // Provide default value so don't get error of
      // this switching between un/controlled component
      // This will be replaced by defaultValues in hook useForm
      defaultValue={props.formField.defaultValue ?? ""}
      control={props.form.control}
      name={props.formField.fieldName}
      render={({ field, fieldState }) => (
        <FormTextField
          value={field.value}
          label={props.formField.label}
          disabled={props.formField.disabled}
          textFieldProps={{
            ...field,
            id: props.formField.fieldName,
            type: props.formField.type,
            placeholder: props.formField.inlineLabel,
            error: !!fieldState.error?.message,
            helperText: fieldState.error?.message
              ? fieldState.error?.message
              : props.formField.helperText,
            InputProps: {
              inputComponent: props.formField.customComp as any,
              startAdornment: props.formField.adornment ? (
                <InputAdornment position={props.formField.adornment.position}>
                  {props.formField.adornment.value}
                </InputAdornment>
              ) : (
                ""
              ),
              role: "input",
              "aria-label": props.formField.fieldName,
            },
            onChange: (e) => {
              field.onChange(e);
              if (props.formField.onChange) {
                props.formField.onChange(e);
              }
            },
            onBlur: (e) => {
              field.onBlur();
              props.formField.onBlur?.(e);
            },
            // Ignore this type error because you can assign this as a prop on
            // mui Text Field, useful for tests
            // @ts-ignore
            "data-testid": `${field.name}-text`,
          }}
        />
      )}
    />
  );
};

export const SelectField = (props: FieldProps) => {
  return (
    <Controller
      defaultValue={props.formField.defaultValue ?? { label: "", value: -1 }}
      control={props.form.control}
      name={props.formField.fieldName}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          data-testid={`${props.formField.fieldName}-autocomplete`}
          disableClearable={props.disableClearable ?? true}
          value={props.form.watch(props.formField.fieldName)}
          id={props.formField.fieldName}
          size="small"
          fullWidth
          disabled={props.formField.disabled}
          options={props.formField.choices ?? []}
          getOptionLabel={(option) => (option as IformFieldChoice)?.label ?? ""}
          isOptionEqualToValue={(option, value) =>
            (option as IformFieldChoice).value ===
            (value as IformFieldChoice).value
          }
          renderOption={(params, option) => {
            return (
              <li {...params} key={option.value}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <FormTextField
              value={field.value}
              label={props.formField.label}
              disabled={props.formField.disabled}
              textFieldProps={{
                ...params,
                type: props.formField.type,
                placeholder: props.formField.inlineLabel,
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message
                  ? fieldState.error?.message
                  : props.formField.helperText,
                // Ignore this type error because you can assign this as a prop on
                // mui Text Field, useful for tests
                // @ts-ignore
                "data-testid": `${field.name}-text`,
              }}
            />
          )}
          onChange={(_, data, reason, detail) => {
            field.onChange(data);
            props.form.setValue(props.formField.fieldName, data, {
              shouldValidate: true,
            });
            props.formField.onChange?.(_, data, reason, detail);
          }}
        />
      )}
    />
  );
};

export const MultiSelectField = (
  props: ControlFieldProps & { limitSelections?: number }
) => {
  return (
    <Controller
      defaultValue={props.formField.defaultValue ?? []}
      control={props.control}
      name={props.formField.fieldName}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          multiple
          data-testid={`${props.formField.fieldName}-autocomplete`}
          disableClearable
          value={field.value}
          id={props.formField.fieldName}
          size="medium"
          fullWidth
          disabled={props.formField.disabled}
          options={props.formField.choices ?? []}
          getOptionDisabled={() => {
            if (props.limitSelections) {
              return field.value.length >= props.limitSelections;
            }

            return false;
          }}
          getOptionLabel={(option) => (option as IformFieldChoice)?.label ?? ""}
          isOptionEqualToValue={(option, val) =>
            (option as IformFieldChoice).value ===
            (val as IformFieldChoice).value
          }
          renderTags={(val: IformFieldChoice[], getTagProps) =>
            val.map((option: IformFieldChoice, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                sx={{
                  background: theme.palette.primary.main,
                  color: "white",
                  "& .MuiChip-deleteIcon": {
                    color: "rgba(255, 255, 255, 0.7)",
                  },
                }}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <FormTextField
              value={field.value}
              label={props.formField.label}
              disabled={props.formField.disabled}
              textFieldProps={{
                ...params,
                type: props.formField.type,
                placeholder: props.formField.inlineLabel,
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message
                  ? fieldState.error?.message
                  : props.formField.helperText,
                // Ignore this type error because you can assign this as a prop on
                // mui Text Field, useful for tests
                // @ts-ignore
                "data-testid": `${field.name}-text`,
              }}
            />
          )}
          onChange={(_, data, reason, detail) => {
            field.onChange(data);
            props.formField.onChange?.(_, data, reason, detail);
          }}
        />
      )}
    />
  );
};

export const Form = (props: FormProps) => {
  return (
    <Grid container>
      {props.fields.map((field) => (
        <Grid item sm={field.columns} p={1} key={field.fieldName}>
          {(field.type === "text" || field.type === "number") && (
            <TextNumberField form={props.form} formField={field} />
          )}

          {field.type === "select" && (
            <SelectField form={props.form} formField={field} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
