import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanySchema, CompanyData, defaultCompanyData } from "./Config";

import { useGetStateData } from "../../../utils/CommonQueries";

import { Button } from "../..";
import {
  useCreateNewCompany,
  useGetCompanyData,
  useUpdateCompanyData,
} from "../../../adapters/Company/Company";
import {
  AcnNumberFormatter,
  PhoneNumberFormatter,
} from "../../MuiCustomNumberFormat";
import { Form, FormField } from "../../Form/Form";
import { CompanyContactsList } from "../CompanyContactsList";

interface CreateUpdateCompanyDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  defaultData: CompanyData;
  onSubmit: (data: CompanyData) => {} | Promise<{}>;
  companyId?: number;
}

interface CreateCompanyDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UpdateCompanyDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: number;
}

export const CreateUpdateCompanyDialog = (
  props: CreateUpdateCompanyDialogProps
) => {
  const states = useGetStateData(1);
  const form = useForm({
    resolver: yupResolver(CompanySchema),
    mode: "onBlur",
    defaultValues: props.defaultData,
  });

  useEffect(() => {
    form.reset(props.defaultData);
  }, [form, props.defaultData]);

  const formFields: FormField[] = [
    {
      type: "text",
      fieldName: `legalName` as const,
      label: "Legal name",
      helperText: "Mandatory",
      columns: 6,
    },
    {
      type: "text",
      fieldName: `acn` as const,
      label: "ACN",
      helperText: "",
      columns: 6,
      customComp: AcnNumberFormatter,
    },
    {
      type: "text",
      fieldName: `email` as const,
      label: "Email",
      helperText: "",
      columns: 6,
    },
    {
      type: "text",
      fieldName: `phone` as const,
      label: "Phone",
      helperText: "",
      columns: 6,
      customComp: PhoneNumberFormatter,
    },
  ];
  const addressFields: FormField[] = [
    {
      type: "text",
      fieldName: `address` as const,
      label: "Address",
      helperText: "",
      columns: 12,
    },
    {
      type: "text",
      fieldName: `city` as const,
      label: "City/Suburb",
      helperText: "",
      columns: 4,
    },
    {
      type: "select",
      fieldName: `state` as const,
      label: "State",
      helperText: "",
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      columns: 4,
    },
    {
      type: "text",
      fieldName: `postCode` as const,
      label: "Postcode",
      helperText: "",
      columns: 4,
    },
  ];

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700, pl: 3 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Form form={form} fields={formFields} />
          <Form form={form} fields={addressFields} />

          <Box width="100%" mt={2} />

          <Grid item p={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={form.formState.isSubmitting}
              onClick={form.handleSubmit(async (data) => {
                await props.onSubmit(data);
                props.setOpen(false);
              })}
            >
              Submit
            </Button>
          </Grid>

          <Grid item p={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.setOpen(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        {props.companyId && (
          <Grid
            item
            xs={12}
            sx={{
              height: 400,

              width: "100%",
            }}
          >
            <Typography variant="subtitle1" sx={{ pt: 2, pb: 2, pl: 1 }}>
              Contacts in this company
            </Typography>
            <CompanyContactsList toolBar companyId={props.companyId} />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const CreateCompanyDialog = (props: CreateCompanyDialogProps) => {
  const createCompany = useCreateNewCompany();
  return (
    <CreateUpdateCompanyDialog
      {...props}
      title="Create new company"
      defaultData={defaultCompanyData}
      onSubmit={createCompany}
    />
  );
};

export const UpdateCompanyDialog = (props: UpdateCompanyDialogProps) => {
  const company = useGetCompanyData(props.companyId ?? -1);
  const updateCompany = useUpdateCompanyData(props.companyId);

  return (
    <CreateUpdateCompanyDialog
      {...props}
      title="Update company"
      defaultData={company}
      onSubmit={updateCompany}
      companyId={props.companyId}
    />
  );
};
