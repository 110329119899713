import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PersonIcon from "@mui/icons-material/Person";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LogoutIcon from "@mui/icons-material/Logout";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BusinessIcon from "@mui/icons-material/Business";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectoryRounded";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import Link from "@mui/material/Link";
import logo from "./ch_logo.png";
import { DrawerHeader, drawerWidth } from "./common";
import { FeatureFlagsContext } from "../../contexts/FeatureFlags";

export interface NavBarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const NavBar = ({ open, handleDrawerClose }: NavBarProps) => {
  const featureFlags = useContext(FeatureFlagsContext);

  const navLinks = [
    {
      title: "Dashboard",
      link: "/",
      icon: <DashboardIcon htmlColor="white" />,
    },
    {
      title: "Deals",
      link: "/deals",
      icon: <WorkspacePremiumIcon htmlColor="white" />,
    },
    {
      title: "Active leases",
      link: "/active-leases",
      icon: <StoreMallDirectoryIcon htmlColor="white" />,
    },
    {
      title: "Contacts",
      link: "/contacts",
      icon: <PersonIcon htmlColor="white" />,
    },
    {
      title: "Companies",
      link: "/companies",
      icon: <BusinessIcon htmlColor="white" />,
    },
    {
      title: "Properties",
      link: "/properties",
      icon: <StorefrontIcon htmlColor="white" />,
    },
    {
      title: "Templates",
      link: "/templates",
      icon: <FileCopyIcon htmlColor="white" />,
    },
    {
      title: "Approvals settings",
      link: "/approvals-settings",
      icon: <HowToRegIcon htmlColor="white" />,
    },
  ];

  if (featureFlags?.features.isAiEnabled) {
    navLinks.push({
      title: "Search documents",
      link: "/search-docs",
      icon: <FindInPageIcon htmlColor="white" />,
    });
  }

  const location = useLocation();
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "secondary.contrastText",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box pt={2} pl={3} pb={1}>
          <img src={logo} alt="Charter Hall logo" width={167} />
        </Box>
        <IconButton onClick={handleDrawerClose} aria-label="close drawer">
          <ChevronLeftIcon color="secondary" />
        </IconButton>
      </DrawerHeader>

      <List>
        {navLinks.map(({ title, link, icon }) => (
          <React.Fragment key={title}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                href={link}
                selected={link === location.pathname}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    color: "white",
                    variant: "body1",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.12)" }} />
          </React.Fragment>
        ))}
      </List>

      <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.12)" }} />
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon htmlColor="white" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              color: "white",
              variant: "body1",
            }}
          />
        </ListItemButton>
      </Box>
    </Drawer>
  );
};

export default NavBar;
