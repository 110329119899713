import React, { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetUsersDocument,
  UpdateTDassigneeDocument,
  UpdateLAassigneeDocument,
  UpdateOwnerDocument,
} from "../generated/graphql-operations";
import { Button } from "./Button";
import { FormField, SelectField } from "./Form/Form";
import { TextMultiField } from "./Form/FormComponents";

const TYPE_TO_LABEL = {
  LA: "LA Assignee",
  TD: "TD Assignee",
  Owner: "Owner",
};

const AssignDealDialogSchema = yup.object({
  user: yup
    .object({
      label: yup.string().required(),
      value: yup.string().uuid().required(),
    })
    .test(
      "NoUser",
      "Must select User",
      (value) => !(value.value === "" && value.label === "")
    ),
  description: yup.string(),
});

type AssignDealDialogType = yup.InferType<typeof AssignDealDialogSchema>;

interface AssignDealDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dealId: number;
  type: "LA" | "TD" | "Owner";
}

interface ValidationProps {
  show: boolean;
  severity: "success" | "error";
  message: string;
}

export const AssignDealDialog = (props: AssignDealDialogProps) => {
  const form = useForm<AssignDealDialogType>({
    resolver: yupResolver(AssignDealDialogSchema),
  });

  const users = useQuery(GetUsersDocument);
  const [UpdateLAassignee] = useMutation(UpdateLAassigneeDocument);
  const [UpdateTDassignee] = useMutation(UpdateTDassigneeDocument);
  const [UpdateOwner] = useMutation(UpdateOwnerDocument);

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (data: AssignDealDialogType) => {
    setValidation({
      show: false,
      severity: "success",
      message: "",
    });

    const queryOptions = {
      variables: {
        deal_id: props.dealId,
        user_id: data.user.value,
        description: data.description ?? "",
      },
      refetchQueries: ["GetDeal"],
      onCompleted: (_data: any) => {
        if (_data) {
          setValidation({
            show: true,
            severity: "success",
            message: "Success - deal assigned",
          });
        }
      },
      onError: () => {
        setValidation({
          show: true,
          severity: "error",
          message:
            "Error - there was an issue assigning deal. Please refresh your browser and try again",
        });
      },
    };

    if (props.type === "LA") {
      await UpdateLAassignee(queryOptions);
    } else if (props.type === "TD") {
      await UpdateTDassignee(queryOptions);
    } else {
      await UpdateOwner(queryOptions);
    }
  };

  const templateSelectField: FormField = {
    type: "select",
    fieldName: "user",
    label: TYPE_TO_LABEL[props.type],
    helperText: "",
    defaultValue: { label: "", value: "" },
    choices:
      users?.data?.Users?.map((user) => ({
        label: user.user_full_name ?? "",
        value: user.id ?? "",
      })) ?? [],
    columns: 12,
  };

  const descriptionField = {
    label: "Description",
    fieldName: "description",
    helperText: "",
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Assign deal</DialogTitle>
      <DialogContent>
        <SelectField form={form} formField={templateSelectField} />
        <Box sx={{ mt: 2 }} />
        <TextMultiField control={form.control} formField={descriptionField} />

        {validation.show && (
          <Alert sx={{ mt: 2 }} severity={validation.severity}>
            {validation.message}
          </Alert>
        )}

        <DialogActions sx={{ justifyContent: "flex-start", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={form.handleSubmit(async (data) => {
              await onSubmit(data);
            })}
          >
            Save
          </Button>

          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => {
              props.setOpen(false);
              form.reset();
              setValidation({
                show: false,
                severity: "success",
                message: "",
              });
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
