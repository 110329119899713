import React, { useEffect } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { Form, FormField } from "../../Form/Form";
import { GuarantorForm } from "./GuarantorForm";
import { Button } from "../../Button";
import { CreateButton } from "../../CreateButton";
import { Alert } from "../../Alert";
import { defaultGuarantor } from "./DepositConfig";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import { FormToggleButton } from "../../Form/FormComponents";
import {
  ERROR_COLOR,
  TOGGLE_OPTIONS,
  ToggleString,
} from "../../../utils/CommonVariables";
import {
  calculateBasePromoOutgoings,
  transformData,
  useGetDealInformationDepositInformation,
} from "../../../adapters/DealInformation/Deposit/Deposit";

export const Deposit = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;

  const guarantorsFieldArray = useFieldArray({
    name: "deposit.guarantors",
    control: form.control,
  });

  const depositInformation = useGetDealInformationDepositInformation(
    props.dealId
  );

  useEffect(() => {
    setDataState((prev) => ({ ...prev, depositLoaded: true }));

    if (depositInformation.data) {
      const transformedData = transformData(depositInformation.data);

      form.setValue("deposit", transformedData);
      form.setValue("deposit.guarantors", transformedData.guarantors);

      if (
        transformedData.rentDeposit === undefined ||
        transformedData.rentDeposit === null
      ) {
        const rentDeposit =
          (transformedData.rentDepositMonths ?? 0) *
          calculateBasePromoOutgoings(form);
        form.setValue("deposit.rentDeposit", rentDeposit);
      }

      if (transformedData.bankGuaranteeRequired === ToggleString.Yes) {
        if (
          transformedData.bankGuaranteeAmount === undefined ||
          transformedData.bankGuaranteeAmount === null
        ) {
          const bankGuaranteeAmount =
            (transformedData.bankGuaranteeMonths ?? 0) *
            calculateBasePromoOutgoings(form);
          form.setValue("deposit.bankGuaranteeAmount", bankGuaranteeAmount);
        }
      } else {
        form.setValue("deposit.bankGuaranteeMonths", undefined);
      }
    } else {
      setDataState((prev) => ({ ...prev, chargesLoaded: false }));
    }
  }, [form, depositInformation, setDataState]);

  if (depositInformation.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!depositInformation.data) {
    return <Skeleton />;
  }

  const guarantorRequired = form.watch("deposit.guarantorRequired");
  const bankGuaranteeRequired = form.watch("deposit.bankGuaranteeRequired");

  const rentDepositFields: FormField[] = [
    {
      type: "number",
      fieldName: "deposit.rentDepositMonths",
      label: "Rent deposit in months",
      helperText: "New deals only",
      columns: 6,
      defaultValue: 1,
    },
    {
      type: "text",
      fieldName: "deposit.rentDeposit",
      label: "Required rent deposit amount  (exc GST)",
      helperText: "New deals only",
      columns: 6,
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
    },
  ];

  const achievedRentDepositField: FormField = {
    type: "text",
    fieldName: "deposit.achievedRentDeposit",
    label: "Achieved rent deposit amount  (exc GST)",
    helperText: "New deals only",
    columns: 12,
    customComp: CurrencyFormatter,
    adornment: {
      position: "start",
      value: "$",
    },
  };

  const bankGuaranteeFields: FormField[] = [
    {
      type: "number",
      fieldName: "deposit.bankGuaranteeMonths",
      label: "Bank guarantee in months",
      helperText: "",
      columns: 6,
      disabled: bankGuaranteeRequired !== ToggleString.Yes,
    },
    {
      type: "text",
      fieldName: "deposit.bankGuaranteeAmount",
      label: "Required bank guarantee amount (exc GST)",
      helperText: "",
      columns: 6,
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      disabled: bankGuaranteeRequired !== ToggleString.Yes,
    },
  ];

  const achievedBankGuaranteeField: FormField = {
    type: "text",
    fieldName: "deposit.achievedBankGuarantee",
    label: "Achieved bank guarantee amount  (exc GST)",
    helperText: "",
    columns: 12,
    customComp: CurrencyFormatter,
    adornment: {
      position: "start",
      value: "$",
    },
  };

  return (
    <>
      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Guarantor required</Typography>
      </Grid>
      <Grid item sm={2} mt={4}>
        <FormToggleButton
          name="deposit.guarantorRequired"
          control={form.control}
          options={TOGGLE_OPTIONS}
          onChange={(e) => {
            const targetValue = (e.target as HTMLInputElement).value;
            if (targetValue !== ToggleString.Yes) {
              form.setValue("deposit.guarantors", []);
            }
          }}
        />
      </Grid>

      <Box width="100%" />

      {form.formState.errors.deposit?.guarantors?.message ? (
        <Grid item>
          <Typography color={ERROR_COLOR}>
            {form.formState.errors.deposit?.guarantors?.message}
          </Typography>
        </Grid>
      ) : null}

      <Box width="100%" />

      <>
        {guarantorsFieldArray.fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid item sm={12} p={1}>
              <GuarantorForm
                form={props.form}
                index={index}
                disabled={guarantorRequired !== ToggleString.Yes}
              />
              <Grid container justifyContent="flex-end">
                <Grid item p={1}>
                  <Button
                    startIcon={<Delete />}
                    color="error"
                    variant="text"
                    onClick={() => {
                      guarantorsFieldArray.remove(index);
                    }}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}

        {guarantorRequired === ToggleString.Yes ? (
          <Grid item p={1}>
            <CreateButton
              startIcon={<Add />}
              color="primary"
              variant="text"
              onClick={() => {
                guarantorsFieldArray.append(defaultGuarantor);
              }}
            >
              Add guarantor
            </CreateButton>
          </Grid>
        ) : null}
      </>

      <Box width="100%" />

      <Grid item sm={4} mt={4} mb={2}>
        <Typography variant="subtitle2">Rent deposit</Typography>
      </Grid>

      <Form form={props.form} fields={rentDepositFields} />
      <Grid item sm={6}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={4}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            const rentDepositAmount =
              (form.getValues("deposit.rentDepositMonths") ?? 0) *
              calculateBasePromoOutgoings(form);
            form.setValue("deposit.rentDeposit", rentDepositAmount);
          }}
        >
          Calculate rent deposit amount
        </Button>
      </Grid>

      <Grid container ml="50%">
        <Form form={props.form} fields={[achievedRentDepositField]} />
      </Grid>

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Bank guarantee required</Typography>
      </Grid>
      <Grid item sm={2} mt={4}>
        <FormToggleButton
          name="deposit.bankGuaranteeRequired"
          control={form.control}
          options={TOGGLE_OPTIONS}
          onChange={(e) => {
            const targetValue = (e.target as HTMLInputElement).value;
            if (targetValue !== ToggleString.Yes) {
              form.setValue("deposit.bankGuaranteeMonths", undefined);
              form.setValue("deposit.bankGuaranteeAmount", undefined);
            }
          }}
        />
      </Grid>

      <Form form={props.form} fields={bankGuaranteeFields} />
      <Grid item sm={6}>
        <Box width="100%" />
      </Grid>
      <Grid item sm={4}>
        <Button
          color="secondary"
          variant="contained"
          disabled={
            form.watch("deposit.bankGuaranteeRequired") !== ToggleString.Yes
          }
          onClick={() => {
            const bankGuartanteeAmount =
              (form.getValues("deposit.bankGuaranteeMonths") ?? 0) *
              calculateBasePromoOutgoings(form);
            form.setValue("deposit.bankGuaranteeAmount", bankGuartanteeAmount);
          }}
        >
          Calculate bank guarantee amount
        </Button>
      </Grid>

      <Grid container ml="50%">
        <Form form={props.form} fields={[achievedBankGuaranteeField]} />
      </Grid>
    </>
  );
};
