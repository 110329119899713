import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Button, Chip, Stack } from "@mui/material";
import theme from "../themes/theme";
import {
  GetLeasesForRenewalDocument,
  GetLeasesForRenewalQuery,
} from "../generated/graphql-operations";
import { RenewalDialog } from "./Renewals/RenewalDialog";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";
import { ERROR_COLOR } from "../utils/CommonVariables";
import { ShopClosedNotificationDialog } from "./Deal/DealShopActivities/ShopClosed";

enum LeaseStatus {
  Holdover = "Holdover",
  OnLease = "On lease",
}

const statusColor = (status: LeaseStatus) => {
  switch (status) {
    case LeaseStatus.OnLease:
      return {
        backgroundColor: theme.palette.success.main,
        color: "white",
      };
    case LeaseStatus.Holdover:
      return { backgroundColor: "#D14F4F", color: "white" };
    default:
      return {
        backgroundColor: theme.palette.success.main,
        color: "white",
      };
  }
};

export interface ActiveLeasesListProps {
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const ActiveLeasesList = ({
  toolBar,
  hideColumns = undefined,
}: ActiveLeasesListProps) => {
  const { data, loading, error } = useQuery(GetLeasesForRenewalDocument);
  const [open, setOpen] = useState(false);
  const [closeShopOpen, setCloseShopOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<
    GetLeasesForRenewalQuery["YardiLeasesForRenewal"][0]
  >({});
  const msalInstance = useMsal();
  const userId = msalInstance.instance.getActiveAccount()?.localAccountId!;
  const navigate = useNavigate();
  const role = useReturnUserRole();
  const disabled = role === Role.TdAssignee || role === Role.ReadOnly;
  const columns: GridColDef<
    NonNullable<GetLeasesForRenewalQuery["YardiLeasesForRenewal"][0]>
  >[] = [
    {
      field: "PropertyName",
      headerName: "Centre",
      flex: 1,
    },
    {
      field: "TradeName",
      headerName: "Trading name",
      flex: 1,
    },
    {
      field: "TenantName",
      headerName: "Tenant name",
      flex: 1,
    },
    {
      field: "shop_units",
      headerName: "Shop units",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.unit1 ?? ""} ${params.row.unit2 ?? ""} ${
          params.row.unit3 ?? ""
        } ${params.row.unit4 ?? ""} ${params.row.unit5 ?? ""}`,
    },
    {
      field: "TenantCode",
      headerName: "Tenant ID",
      flex: 1,
    },
    {
      field: "EndDate",
      headerName: "Lease expiry",
      flex: 2,
      type: "dateTime",
      renderCell: (params) =>
        params.row.EndDate ? new Date(params.row.EndDate).toDateString() : null,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => params.row.IsHoldover,
      renderCell: (params) => {
        const status =
          params.row.IsHoldover === "No"
            ? LeaseStatus.OnLease
            : LeaseStatus.Holdover;
        return <Chip label={status} sx={statusColor(status)} />;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: (params) => (
        <>
          <Button
            disabled={disabled}
            variant="contained"
            onClick={() => {
              setSelectedData(params.row);
              setOpen(true);
            }}
          >
            Renew lease
          </Button>
          <Button
            sx={{ ml: 1, backgroundColor: ERROR_COLOR }}
            disabled={role === Role.TdAssignee}
            variant="contained"
            onClick={() => {
              setSelectedData(params.row);
              setCloseShopOpen(true);
            }}
          >
            Close shop
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <DataGrid
        rows={data?.YardiLeasesForRenewal || []}
        columns={columns}
        loading={loading || error !== undefined}
        columnVisibilityModel={hideColumns}
        components={toolBar ? { Toolbar: CustomToolbar } : {}}
        onCellClick={(params, event) => {
          if (!event.ctrlKey && params.field !== "actions") {
            navigate(`/active-leases/${params?.row?.TenantCode}`);
          }
        }}
        getRowId={(params) =>
          // Don't make tenant unique
          `${params.CustomerCode}-${params.PropertyCode}-${params.TenantCode}-${params.unit1}`
        }
        initialState={{
          sorting: {
            sortModel: [{ field: "status", sort: "desc" }],
          },
        }}
      />
      <RenewalDialog open={open} setOpen={setOpen} data={selectedData} />
      <ShopClosedNotificationDialog
        open={closeShopOpen}
        setOpen={setCloseShopOpen}
        propertyName={selectedData?.PropertyName ?? ""}
        tenantName={selectedData?.TradeName ?? ""}
        tenantCode={selectedData?.TenantCode ?? ""}
        shopNumber={`${selectedData.unit1 ?? ""} ${selectedData.unit2 ?? ""} ${
          selectedData.unit3 ?? ""
        } ${selectedData.unit4 ?? ""} ${selectedData.unit5 ?? ""}`}
        userId={userId}
      />
    </>
  );
};

export default ActiveLeasesList;
