import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Snackbar,
  Grid,
  Paper,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import { useForm } from "react-hook-form";
import {
  FormDate,
  FormSwitch,
  FormTextField,
  TextMultiField,
} from "../../Form/FormComponents";
import { useCancelDeal } from "../../../adapters/Deal/CancelDeal";

interface CancelDealDialogProps {
  dealDisabled: boolean;
  dealId: number;
  propertyName: string;
  tradingName: string;
  shopNumber: string;
}

const CancelDealDialogSchema = yup.object({
  cancelDate: yup.date().required("Cancel date is required"),
  capitalIncurred: yup.boolean().required("Capital Incurred is required"),
  cancelComment: yup.string(),
});

type CancelDealDialogType = yup.InferType<typeof CancelDealDialogSchema>;

export const CancelDealDialog = (props: CancelDealDialogProps) => {
  const { handleSubmit, control, reset } = useForm<CancelDealDialogType>({
    resolver: yupResolver(CancelDealDialogSchema),
    defaultValues: {
      cancelDate: new Date(),
      capitalIncurred: false,
      cancelComment: "",
    },
  });

  const [isSubmittingCancel, submitCancelDeal] = useCancelDeal(props.dealId);
  const [errorSubmit, setErrorSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const closeErrorSubmit = () => setErrorSubmit(false);

  const resetAndClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (data: CancelDealDialogType) => {
    try {
      await submitCancelDeal(
        data.cancelDate,
        data.capitalIncurred,
        data.cancelComment
      );
    } catch {
      setErrorSubmit(true);
    }
    resetAndClose();
  };

  return (
    <>
      <Button
        disabled={props.dealDisabled}
        color="error"
        onClick={() => setOpen(true)}
        startIcon={<CancelIcon />}
      >
        Cancel
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>Cancel Deal Notification</DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ mb: 1 }}>
            This will put the current deal in Cancelled status
          </Alert>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            maxWidth="md"
            sx={{ m: "0 auto" }}
          >
            <Paper sx={{ m: 2, p: 2 }} elevation={10}>
              <Grid container>
                <Grid container>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.propertyName}
                      label="Property Name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.tradingName}
                      label="Trading Name"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormTextField
                      value={props.shopNumber}
                      label="Shop Number"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <FormDate
                      fieldName="cancelDate"
                      title="Cancel Date"
                      control={control}
                    />
                  </Grid>
                  <FormSwitch
                    control={control}
                    name="capitalIncurred"
                    title="Capital already incurred"
                  />
                  <Grid item sm={12}>
                    <TextMultiField
                      control={control}
                      formField={{
                        label: "Add your comment",
                        fieldName: "cancelComment",
                        helperText: "",
                      }}
                    />
                  </Grid>
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mr: 1 }}
                      disabled={isSubmittingCancel}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Cancel & Send Notification
                    </Button>

                    <Button
                      onClick={resetAndClose}
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      Close
                    </Button>
                  </Grid>
                  <Snackbar
                    open={errorSubmit}
                    autoHideDuration={6000}
                    onClose={closeErrorSubmit}
                  >
                    <Alert
                      onClose={closeErrorSubmit}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      Something went wrong, try submitting again.
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
