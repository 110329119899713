import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Card,
  Skeleton,
  Box,
  Chip,
  Tab,
  Tabs,
  Alert,
  Stack,
  Button,
} from "@mui/material";
import { InsertDriveFile, InsertDriveFileOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { BreadCrumbs } from "../../components";
import {
  GetCentreMapsDocument,
  GetLegislationsDocument,
  GetPropertyDocument,
  GetYardiPropertyContactsDocument,
  PropertyFileTypes_Enum,
} from "../../generated/graphql-operations";
import { Error } from "../Error";
import { PropertyDetails } from "./PropertyDetails";
import { PropertyExpense } from "./PropertyExpense";
import { PropertyLandlordServices } from "./PropertyLandlordServices";
import { PropertyContacts } from "./PropertyContact/PropertyContacts";
import theme from "../../themes/theme";
import {
  useCreateFileSubmit,
  useDownloadBlob,
} from "../../adapters/Properties/PropertyFiles";
import { PropertyUnits } from "./PropertyUnits";
import { PropertyVirtualUnits } from "./PropertyVirtualUnit";
import { Role, useReturnUserRole } from "../../contexts/Auth/UserRole";
import { DISABLE_SX_PROPS } from "../../utils/CommonVariables";

const ContactsTabContent = (props: { id: string }) => {
  return <PropertyContacts toolBar propertyId={props.id} />;
};

const PropertyDetailsTabContent = (props: { id: string }) => {
  return <PropertyDetails propertyId={props.id} />;
};

const PropertyExpenseTabContent = (props: { id: string }) => {
  return <PropertyExpense propertyId={props.id} />;
};

const LandlordServicesTabContent = (props: { id: string }) => {
  return <PropertyLandlordServices propertyId={props.id} />;
};

const PropertyUnitsTabContent = (props: { id: string }) => {
  return <PropertyUnits toolBar propertyId={props.id} />;
};

const PropertyVirtualUnitsTabContent = (props: { id: string }) => {
  return <PropertyVirtualUnits toolBar propertyId={props.id} />;
};

const TabContent = (props: { index: number; id: string; disable: boolean }) => {
  switch (props.index) {
    case 0:
      return <ContactsTabContent id={props.id} />;
    case 1:
      return <PropertyDetailsTabContent id={props.id} />;
    case 2:
      return <PropertyExpenseTabContent id={props.id} />;
    case 3:
      return (
        <Box sx={props.disable ? DISABLE_SX_PROPS : undefined}>
          <LandlordServicesTabContent id={props.id} />
        </Box>
      );
    case 4:
      return <PropertyUnitsTabContent id={props.id} />;
    case 5:
      return <PropertyVirtualUnitsTabContent id={props.id} />;
    default:
      return <ContactsTabContent id={props.id} />;
  }
};

export const Property = () => {
  const params = useParams();
  const propertyId = params.propertyId ?? "";
  const { data, loading, error } = useQuery(GetPropertyDocument, {
    variables: { property_code: propertyId },
  });
  const disable = useReturnUserRole() !== Role.Admin;

  const propertyContacts = useQuery(GetYardiPropertyContactsDocument, {
    variables: {
      property_code: propertyId,
    },
  });

  const CENTRE_MANAGER_STR = "Centre Manager";

  const centreManager =
    propertyContacts?.data?.YardiProperties_by_pk?.YardiContacts_YardiProperties?.find(
      (contact) => contact.Role === CENTRE_MANAGER_STR
    );

  const [tabValue, setTabValue] = useState(0);
  const form = useForm();

  interface ValidationProps {
    show: boolean;
    severity: "success" | "error";
    message: string;
  }

  const [validation, setValidation] = useState<ValidationProps>({
    show: false,
    severity: "success",
    message: "",
  });

  const [centreMapFile, setCentreMapFile] = React.useState<File | undefined>(
    undefined
  );

  const [legislationFile, setLegislationFile] = React.useState<
    File | undefined
  >(undefined);

  const centreMaps = useQuery(GetCentreMapsDocument);
  const legislations = useQuery(GetLegislationsDocument);

  const currentCentreMap = centreMaps.data?.PropertyFiles.find(
    (file) => file.property_code === propertyId
  )?.file_name;

  const currentLegislation = legislations.data?.PropertyFiles.find(
    (file) => file.property_code === propertyId
  )?.file_name;

  const centreMapDropZone = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const ext = acceptedFiles[0].name.split(".").pop();
        if (ext !== "jpg" && ext !== "jpeg" && ext !== "png") {
          setValidation({
            show: true,
            severity: "error",
            message: "Centre map: Only JPG, JPEG or PNG files are accepted",
          });
          return;
        }
        setCentreMapFile(acceptedFiles[0]);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const legislationDropZone = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const ext = acceptedFiles[0].name.split(".").pop();
        if (ext !== "docx") {
          setValidation({
            show: true,
            severity: "error",
            message: "Legislation: Only docx files are accepted",
          });
          return;
        }
        setLegislationFile(acceptedFiles[0]);
        setValidation((prev) => ({
          ...prev,
          show: false,
        }));
      } else if (fileRejections.length > 0) {
        setValidation({
          show: true,
          severity: "error",
          message: "Can only upload 1 file",
        });
      }
    },
    maxFiles: 1,
    disabled: form.formState.isSubmitting,
  });

  const downloadBlob = useDownloadBlob();

  const createCentreMapFile = useCreateFileSubmit(
    propertyId,
    setValidation,
    centreMapFile,
    PropertyFileTypes_Enum.CentreMap
  );

  const createLegistlationFile = useCreateFileSubmit(
    propertyId,
    setValidation,
    legislationFile,
    PropertyFileTypes_Enum.Legislation
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error || data?.Properties === null) {
    <Error
      title="Oops, something went wrong"
      message="Try refreshing your browser and try again"
    />;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const tabStyle = { fontSize: 14, fontWeight: 700, color: "black" };

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Typography variant="h6">
          {data?.Properties?.[0]?.label ?? "Property"}
        </Typography>
      </Grid>
      <Grid item sm={12} mb={2}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Properties", url: "/properties/" },
            { text: `${data?.Properties?.[0]?.label ?? "Property"}`, url: "" },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 2, mb: 2 }}>
            <Grid container p={2} justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="subtitle1" mb={2}>
                  Property overview
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body2">Address</Typography>
                <Box width="100%" mb={1} />
                <Chip
                  label={`${data?.Properties?.[0]?.street1}, ${data?.Properties?.[0]?.suburb} ${data?.Properties?.[0]?.state} ${data?.Properties?.[0]?.postcode}`}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">Hub</Typography>
                <Box width="100%" mb={1} />
                <Chip label={data?.Properties?.[0]?.hub} />
              </Grid>
              <Grid item>
                <Typography variant="body2">Property code</Typography>
                <Box width="100%" mb={1} />
                <Chip label={data?.Properties?.[0]?.property_code} />
              </Grid>
              <Grid item>
                <Typography variant="body2">Centre manager</Typography>
                <Box width="100%" mb={1} />
                <Chip
                  label={`${centreManager?.YardiContact.FirstName} ${centreManager?.YardiContact.LastName}`}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle2">Centre map</Typography>
            <Card
              sx={{
                my: 2,
                backgroundColor: centreMapDropZone.isDragActive
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.main,
              }}
              {...centreMapDropZone.getRootProps()}
            >
              <input {...centreMapDropZone.getInputProps()} />
              {centreMapDropZone.isDragActive
                ? "Drop file here"
                : "Drop file here, or click to select file"}
            </Card>
            {centreMapFile && (
              <Stack direction="row" justifyContent="flex-start">
                <InsertDriveFileOutlined />{" "}
                <Typography>{centreMapFile.name}</Typography>
              </Stack>
            )}
            {currentCentreMap && (
              <Stack direction="row" justifyContent="flex-start">
                <InsertDriveFile /> <Typography>{currentCentreMap}</Typography>
              </Stack>
            )}
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              disabled={disable}
              onClick={() => {
                if (!centreMapFile) {
                  return;
                }
                createCentreMapFile({
                  fileName: centreMapFile?.name,
                });
                setCentreMapFile(undefined);
              }}
            >
              Save Centre map
            </Button>
            <Button
              sx={{ mt: 1, ml: 2 }}
              variant="contained"
              onClick={async () => {
                const centreMapFilePath =
                  centreMaps.data?.PropertyFiles.find(
                    (file) => file.property_code === propertyId
                  )?.file_path ?? "";

                if (centreMapFilePath === "") {
                  setValidation({
                    message: "No centre map file found",
                    severity: "error",
                    show: true,
                  });

                  return;
                }

                await downloadBlob(centreMapFilePath, centreMapFilePath);
              }}
            >
              Download
            </Button>
            <Typography variant="subtitle2" mt={2}>
              Legislation
            </Typography>
            <Card
              sx={{
                my: 2,
                backgroundColor: legislationDropZone.isDragActive
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.main,
              }}
              {...legislationDropZone.getRootProps()}
            >
              <input {...legislationDropZone.getInputProps()} />
              {legislationDropZone.isDragActive
                ? "Drop file here"
                : "Drop file here, or click to select file"}
            </Card>
            {legislationFile && (
              <Stack direction="row" justifyContent="flex-start">
                <InsertDriveFileOutlined />{" "}
                <Typography>{legislationFile.name}</Typography>
              </Stack>
            )}
            {currentLegislation && (
              <Stack direction="row" justifyContent="flex-start">
                <InsertDriveFile />{" "}
                <Typography>{currentLegislation}</Typography>
              </Stack>
            )}
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              disabled={disable}
              onClick={() => {
                if (!legislationFile) {
                  return;
                }
                createLegistlationFile({
                  fileName: legislationFile?.name,
                });

                setLegislationFile(undefined);
              }}
            >
              Save Legislation
            </Button>
            <Button
              sx={{ mt: 1, ml: 2 }}
              variant="contained"
              onClick={async () => {
                const legislationFilePath =
                  legislations.data?.PropertyFiles.find(
                    (file) => file.property_code === propertyId
                  )?.file_path ?? "";

                if (legislationFilePath === "") {
                  setValidation({
                    message: "No legislation file found",
                    severity: "error",
                    show: true,
                  });

                  return;
                }

                await downloadBlob(legislationFilePath, legislationFilePath);
              }}
            >
              Download
            </Button>
            {validation.show && (
              <Alert sx={{ mt: 2 }} severity={validation.severity}>
                {validation.message}
              </Alert>
            )}
          </Card>

          <Card sx={{ p: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
            >
              <Tab sx={tabStyle} label="CONTACTS" />
              <Tab sx={tabStyle} label="PROPERTY DETAILS" />
              <Tab sx={tabStyle} label="PROPERTY EXPENSE" />
              <Tab sx={tabStyle} label="LANDLORD SERVICES AND FACILITIES" />
              <Tab sx={tabStyle} label="UNITS" />
              <Tab sx={tabStyle} label="VIRTUAL UNITS" />
            </Tabs>
            <TabContent index={tabValue} id={propertyId} disable={disable} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
