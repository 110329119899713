import { add } from "date-fns";
import { OptionsType } from "./Config";
import { DateInfoType } from "../DateInformation/Config";
import { GeneralAmountTypes } from "../../../utils/CommonVariables";

export const isDisabledType = (typeValue: string) =>
  (
    [GeneralAmountTypes.CPI, GeneralAmountTypes["Market review"]] as string[]
  ).includes(typeValue);

export const calculateStartEndDates = (
  leaseCommenceDate: DateInfoType["leaseCommencementDate"],
  leaseTerms: {
    days: DateInfoType["leaseTermDay"];
    months: DateInfoType["leaseTermMonth"];
    years: DateInfoType["leaseTermYear"];
  },
  firstOptionTerms: {
    months: OptionsType["TermMonth"];
    years: OptionsType["TermYear"];
  },
  sliceData: OptionsType[]
) => {
  const initStartDate = add(leaseCommenceDate, {
    years: leaseTerms.years,
    months: leaseTerms.months,
    days: leaseTerms.days ?? 0,
  });

  const initExpireDate = add(initStartDate, {
    years: firstOptionTerms.years,
    months: firstOptionTerms.months,
    days: -1,
  });

  return sliceData?.reduce(
    (prevDates, item) => {
      return {
        startDate: add(prevDates.expireDate, { days: 1 }),
        expireDate: add(prevDates.expireDate, {
          years: item.TermYear,
          months: item.TermMonth,
        }),
      };
    },
    { startDate: initStartDate, expireDate: initExpireDate }
  );
};
