import React from "react";
import { useMutation, useSubscription } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  YardiDataSyncStatusDocument,
  YardiDeleteQuestionnaireDocument,
  YardiEgestDealDocument,
} from "../../generated/graphql-operations";

export const YardiEgestion = (props: { dealId: number }) => {
  const { data, loading, error } = useSubscription(
    YardiDataSyncStatusDocument,
    {
      variables: { deal_id: props.dealId },
    }
  );

  const [egestJob, { data: jobData, loading: jobLoading, error: jobError }] =
    useMutation(YardiEgestDealDocument);

  const [
    deleteQuestionnaire,
    { data: deleteQuestionnaireData, loading: deleteQuestionnaireLoading },
  ] = useMutation(YardiDeleteQuestionnaireDocument);

  if (loading) {
    return <Typography variant="body1"> Loading... </Typography>;
  }

  const NewJobComp = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Starting new jobs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" color="error">
          Warning: This will create a new Job, starting it from scratch. It will
          replace the Sync logs above with new ones and create everything from
          scratch.
        </Typography>
        <Button
          color="error"
          size="medium"
          onClick={async () => {
            await egestJob({
              variables: {
                dealArg: { dealId: props.dealId, startFromStart: true },
              },
            });
          }}
          disabled={jobLoading}
        >
          New Job
        </Button>
      </AccordionDetails>
    </Accordion>
  );

  if (error) {
    return (
      <Typography variant="body1">Error might not have permissions</Typography>
    );
  }
  const syncStatus = data?.YardiDealSyncStatuses?.[0];
  if (!syncStatus) {
    return (
      <>
        <Typography variant="body1">
          No Job started for {props.dealId}
        </Typography>
        <Typography variant="body1">Starting Job queues</Typography>
        {NewJobComp}
      </>
    );
  }

  return (
    <>
      <Typography variant="body1">
        Current job status: {syncStatus?.status}, Created:{" "}
        {syncStatus?.created_at}, Modified: {syncStatus?.modified_at}
      </Typography>
      {Object.keys(syncStatus.state).map((item) => {
        const jsonData = { errorMsg: undefined, ...syncStatus.state[item] };
        const { errorMsg, ...rest } = jsonData;
        return (
          <Accordion key={item}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {item} ({rest?.status})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                {JSON.stringify(rest, null, 2)}
              </Typography>
              {errorMsg ? (
                <>
                  <Typography variant="h6">error Message:</Typography>
                  <Typography variant="body2">
                    <pre>{JSON.stringify(errorMsg, null, 2)}</pre>
                  </Typography>
                </>
              ) : null}
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Typography variant="body1">Starting Job queues</Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Resync Job</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            This will restart the job, starting from where it failed or starting
            a new job, if no job has started for this deal yet.
          </Typography>
          <Button
            color="primary"
            size="medium"
            onClick={async () => {
              await egestJob({
                variables: {
                  dealArg: { dealId: props.dealId, startFromStart: false },
                },
              });
            }}
            disabled={jobLoading}
          >
            Resync Job
          </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">
            Delete existing questionnaire record
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            This will delete a row from the AMENDBUT_SCHEDULE1 table in Yardi.
            Only run this if egestion is failing in the deal questionnaire phase
            with the error: &quot;Custom Table API Request Failed - Custom Table
            Record already exist.&quot;
          </Typography>
          <Button
            color="primary"
            size="medium"
            onClick={async () => {
              await deleteQuestionnaire({
                variables: {
                  dealId: props.dealId,
                },
              });
            }}
            disabled={deleteQuestionnaireLoading}
          >
            Delete questionnaire record
          </Button>

          {deleteQuestionnaireLoading && <Typography>Loading...</Typography>}
          <Typography>{JSON.stringify(deleteQuestionnaireData)}</Typography>
        </AccordionDetails>
      </Accordion>

      {NewJobComp}

      {jobData ? (
        <>
          <Typography variant="h6">Last Job Resync Message:</Typography>
          <Typography variant="body2">
            <pre>{JSON.stringify(jobData, null, 2)}</pre>
          </Typography>
        </>
      ) : null}

      {jobError ? (
        <>
          <Typography variant="h6">Last Job Resync Error:</Typography>
          <Typography variant="body2">
            <pre>{JSON.stringify(jobError, null, 2)}</pre>
          </Typography>
        </>
      ) : null}
    </>
  );
};
