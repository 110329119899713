import { useQuery, useMutation } from "@apollo/client";
import {
  GetStorageInformationDocument,
  GetStorageInformationQuery,
  UpdateStorageInformationDocument,
  CreateCustomLicencesDocument,
  DeleteDealDataCustomLicencesDocument,
  CreateDealDataCustomLicencesDocument,
  GetDealDataIdDocument,
} from "../../../generated/graphql-operations";
import { StorageType } from "../../../components/DealInformation/Storage/StorageConfig";
import { ToggleTwoOption } from "../../../utils/CommonVariables";

export const useGetDealInformationStorageInformation = (dealId: number) => {
  const storageInformation = useQuery(GetStorageInformationDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  return storageInformation;
};

export const transformData = (
  data: GetStorageInformationQuery
): StorageType => {
  return {
    storageRoomLicenceRequired: data?.Deals_by_pk?.DealData?.[0]
      ?.storage_licence_required
      ? ToggleTwoOption.Yes
      : ToggleTwoOption.No,
    seatingLicenceRequired: data?.Deals_by_pk?.DealData?.[0]
      ?.seating_licence_required
      ? ToggleTwoOption.Yes
      : ToggleTwoOption.No,
    signageLicenceRequired: data?.Deals_by_pk?.DealData?.[0]
      ?.signage_licence_required
      ? ToggleTwoOption.Yes
      : ToggleTwoOption.No,
    parkingLicenceRequired: data?.Deals_by_pk?.DealData?.[0]
      ?.parking_licence_required
      ? ToggleTwoOption.Yes
      : ToggleTwoOption.No,
    storageRent: data?.Deals_by_pk?.DealData?.[0]?.storage_rent ?? undefined,
    signageRent: data?.Deals_by_pk?.DealData?.[0]?.signage_rent ?? undefined,
    seatingRent: data?.Deals_by_pk?.DealData?.[0]?.seating_rent ?? undefined,
    parkingRent: data?.Deals_by_pk?.DealData?.[0]?.parking_rent ?? undefined,
    signageLocation: data?.Deals_by_pk?.DealData?.[0]?.signage_location ?? "",
    parkingLocation: data?.Deals_by_pk?.DealData?.[0]?.parking_location ?? "",
    numberOfSigns:
      data?.Deals_by_pk?.DealData?.[0]?.number_of_signs ?? undefined,
    numberOfCarparks:
      data?.Deals_by_pk?.DealData?.[0]?.number_of_carparks ?? undefined,
    storageArea: data?.Deals_by_pk?.DealData?.[0]?.storage_area ?? undefined,
    seatingArea: data?.Deals_by_pk?.DealData?.[0]?.seating_area ?? undefined,
    customLicences:
      data?.Deals_by_pk?.DealData?.[0]?.DealDataCustomLicences.map(
        (licence) => ({
          licenceName: licence?.CustomLicences?.licence_name ?? "",
          licenceRent: licence?.CustomLicences?.licence_rent ?? 0,
          description: licence?.CustomLicences?.licence_description ?? "",
        })
      ) ?? [],
  };
};

export const useMutateStorage = (dealId: number) => {
  const [UpdateStorageInformation] = useMutation(
    UpdateStorageInformationDocument
  );
  const [CreateCustomLicences] = useMutation(CreateCustomLicencesDocument);
  const [DeleteDealDataCustomLicences] = useMutation(
    DeleteDealDataCustomLicencesDocument
  );
  const [CreateDealDataCustomLicences] = useMutation(
    CreateDealDataCustomLicencesDocument
  );
  const dealDataId = useQuery(GetDealDataIdDocument, {
    variables: {
      deal_id: dealId,
    },
  });

  const createCustomDealDataLicences = (data: StorageType) => {
    Promise.all(
      data.customLicences.map(async (licence) => {
        const licenceData = {
          licence_name: licence.licenceName,
          licence_rent: licence.licenceRent,
          licence_description: licence.description,
        };

        const customLicence = await CreateCustomLicences({
          variables: {
            objects: licenceData,
          },
        });

        await CreateDealDataCustomLicences({
          variables: {
            data: {
              deal_data_id: dealDataId?.data?.DealData?.[0]?.id,
              custom_licence_id:
                customLicence.data?.insert_CustomLicences?.returning?.[0]?.id,
            },
          },
        });
      })
    );
  };

  return async (data: StorageType) => {
    const transformedData = {
      storage_licence_required:
        data.storageRoomLicenceRequired === ToggleTwoOption.Yes,
      storage_area: data.storageArea ?? null,
      storage_rent: data.storageRent ?? null,
      seating_licence_required:
        data.seatingLicenceRequired === ToggleTwoOption.Yes,
      seating_area: data.seatingArea ?? null,
      seating_rent: data.seatingRent ?? null,
      signage_licence_required:
        data.signageLicenceRequired === ToggleTwoOption.Yes,
      number_of_signs: data.numberOfSigns ?? null,
      signage_rent: data.signageRent ?? null,
      signage_location: data.signageLocation ?? "",
      parking_licence_required:
        data.parkingLicenceRequired === ToggleTwoOption.Yes,
      number_of_carparks: data.numberOfCarparks ?? null,
      parking_rent: data.parkingRent ?? null,
      parking_location: data.parkingLocation ?? "",
    };

    await UpdateStorageInformation({
      variables: {
        deal_id: dealId,
        data: transformedData,
      },
    });

    await DeleteDealDataCustomLicences({
      variables: {
        deal_id: dealId,
      },
    });

    await createCustomDealDataLicences(data);
  };
};
