import { AccessToken } from "@azure/core-auth";
import {
  IPublicClientApplication,
  InteractionStatus,
} from "@azure/msal-browser";

/* 
Custom Class to auth Browser using MSAL.js for Azure management packages, such as BlobServiceClient
Fetching the instance, isAUthetnicated and inProgress should be done by react hook useIsAuthenticated & useMsal
*/
export class BrowserCredential {
  instance: IPublicClientApplication;

  isAuthenticated: true;

  inProgress: InteractionStatus.None;

  constructor(
    instance: IPublicClientApplication,
    isAuthenticated: true,
    inProgress: InteractionStatus.None
  ) {
    this.instance = instance;
    this.isAuthenticated = isAuthenticated;
    this.inProgress = inProgress;
  }

  async getToken(scopes: string | string[]): Promise<AccessToken> {
    const parameters = {
      scopes: Array.isArray(scopes) ? scopes : [scopes],
      account: this.instance.getActiveAccount() ?? undefined,
    };
    const response = await this.instance.acquireTokenSilent(parameters);
    return {
      token: response.accessToken,
      expiresOnTimestamp: (response.expiresOn?.getTime() ?? 0) / 1000,
    };
  }
}
