/* eslint react/no-unused-prop-types: 0 */

import { useSubscription } from "@apollo/client";
import { Avatar, Box, Chip, Grid, Skeleton, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import React from "react";
import {
  ActivityLogType_Enum,
  SubGetActivityLogDocument,
} from "../../../../generated/graphql-operations";
import theme from "../../../../themes/theme";
import {
  BLACK_ALPHA_38,
  BLACK_ALPHA_60,
} from "../../../../utils/CommonVariables";
import { checkAndFormatDate } from "../../../../utils/DateUtils";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AUD",
  currencyDisplay: "narrowSymbol",
});

const keyToText = (key: string) => {
  const split = key.split("_");
  if (split[split.length - 1] === "id") {
    split[split.length - 1] = "switch";
  }
  const res = split.join(" ");
  return res.charAt(0).toUpperCase() + res.slice(1);
};

const valueToText = (value: string) => {
  switch (value) {
    case "true": {
      return "yes";
    }
    case "false": {
      return "no";
    }
    case "undefined": {
      return "empty";
    }
    default: {
      return value;
    }
  }
};

const formatDealData = (key: string, value: string) => {
  if (key.includes("percent")) {
    return `${value}%`;
  }

  const dateKeys = ["handover_date", "lease_start", "created_at"];
  const currencyKeys = [
    "base_net_rent",
    "outgoings_psm",
    "mat_sales",
    "security_amt",
    "hoarding_amt",
    "tenancy_coordination",
    "analyst_deal_comp",
    "analyst_normalised_base_rent",
    "analyst_normalised_promo",
    "gross_rent",
    "turn_over_rent_unnatural",
    "storage_rent",
    "seating_rent",
    "signage_rent",
    "parking_rent",
    "tenancy_coordination_fee",
    "tenant_contribution_landlord_works",
    "recovery_survey_fee",
    "opening_promotion_levy_fees",
    "development_fees",
    "rubbish_removal_fees",
    "rent_deposit_months",
    "rent_deposit",
    "bank_guarantee_amount",
    "fitout_contribution",
    "cash_incentive",
  ];

  if (dateKeys.includes(key)) {
    return checkAndFormatDate(parseISO(`${value}Z`), "dd/MM/yy");
  }

  if (currencyKeys.includes(key)) {
    return currencyFormatter.format(parseFloat(value));
  }

  return value;
};

const extractDocumentName = (path: string) => {
  return path.split("/")[1].split(".")[0];
};

const display = (activity: ActivityProps) => {
  switch (activity.logType) {
    case ActivityLogType_Enum.Dlchg: {
      return {
        activity: "Changed deal information",
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {keyToText(activity.changeKey)} - from{" "}
            <b>
              {formatDealData(
                activity.changeKey,
                valueToText(activity.oldValue)
              )}
            </b>{" "}
            to{" "}
            <b>
              {formatDealData(
                activity.changeKey,
                valueToText(activity.newValue)
              )}
            </b>
          </Typography>
        ),
      };
    }
    case ActivityLogType_Enum.Autorsk: {
      return {
        activity: "Automated risk level change",
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {activity.displayString}
          </Typography>
        ),
      };
    }
    case ActivityLogType_Enum.Daiup: {
      return {
        activity: "Changed deal action item",
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {activity.displayString} - marked as{" "}
            {activity.newValue === "true" ? "checked" : "unchecked"}
          </Typography>
        ),
      };
    }
    case ActivityLogType_Enum.Alloc: {
      return {
        activity: "Task allocated",
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {activity.displayString}
          </Typography>
        ),
      };
    }
    case ActivityLogType_Enum.Comms: {
      return {
        activity: activity.displayString,
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {activity.newValue}
          </Typography>
        ),
      };
    }
    case ActivityLogType_Enum.Docup: {
      return {
        activity: "Generated a document",
        content: (
          <Box sx={{ mt: 1 }}>
            <Chip
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
              label={extractDocumentName(activity.newValue)}
            />
          </Box>
        ),
      };
    }
    case ActivityLogType_Enum.Filup: {
      return {
        activity: "Uploaded a file",
        content: (
          <Box sx={{ mt: 1 }}>
            <Chip
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
              label={activity.displayString}
            />
          </Box>
        ),
      };
    }
    case ActivityLogType_Enum.Laf: {
      return {
        activity: activity.displayString,
        content: (
          <Box sx={{ mt: 1 }}>
            <Chip
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
              label={activity.newValue}
            />
          </Box>
        ),
      };
    }
    case ActivityLogType_Enum.Dlpchg: {
      return {
        activity: activity.displayString,
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            {`From Status ${activity.oldValue} to Status ${activity.newValue}`}
          </Typography>
        ),
      };
    }

    default: {
      return {
        activity: activity.displayString,
        content: (
          <Typography variant="body1" sx={{ color: BLACK_ALPHA_60 }}>
            To {activity.newValue}
          </Typography>
        ),
      };
    }
  }
};

interface ActivityLogProps {
  dealId: number;
}

interface ActivityProps {
  name: string;
  changeKey: string;
  oldValue: string;
  newValue: string;
  createdAt: Date;
  logType: string;
  displayString: string;
}

const Activity = (props: ActivityProps) => {
  const { activity, content } = display(props);
  return (
    <>
      <Typography variant="subtitle1" display="inline">
        {props.name}{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        display="inline"
        sx={{ color: BLACK_ALPHA_60 }}
      >
        {activity}{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        display="inline"
        sx={{ color: BLACK_ALPHA_38 }}
      >
        {checkAndFormatDate(
          parseISO(`${props.createdAt}Z`),
          "ccc d LLL yyyy, HH:mm a"
        ) ?? "Invalid date"}
      </Typography>
      {content}
    </>
  );
};

export const ActivityLog = (props: ActivityLogProps) => {
  const activities = useSubscription(SubGetActivityLogDocument, {
    variables: {
      deal_id: props.dealId,
    },
    fetchPolicy: "no-cache",
  });

  if (!activities.data) {
    return <Skeleton />;
  }

  // making this an array in case we add more fields ending with id in the future
  // should we remove this in the backend I wonder?
  const omitedActivities = ["notice_address_id"];

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {activities.data?.ActivityLog.filter(
        (item) => !omitedActivities.includes(item.change_key)
      ).map((item) => (
        <React.Fragment key={item.id}>
          <Grid item xs="auto">
            <Avatar>
              {(item.User?.user_full_name ?? "System")
                .split(" ")
                .map((name) => name[0].toUpperCase())
                .join("")}
            </Avatar>
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Activity
              name={item.User?.user_full_name ?? ""}
              changeKey={item.change_key}
              oldValue={item.old_value}
              newValue={item.new_value}
              createdAt={item.created_at}
              logType={item.log_type ?? ""}
              displayString={item.display_string ?? ""}
            />
          </Grid>
          <Box width="100%" />
        </React.Fragment>
      ))}
    </Grid>
  );
};
