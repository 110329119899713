import { useQuery, useMutation, ApolloError } from "@apollo/client";
import {
  SpecialConditionsType,
  NO_CONDITION_USE,
} from "../../../components/DealInformation/SpecialConditions/Config";

import {
  DeleteAndInsertDealSpecialConditionsDocument,
  GetDealSpecialConditionsQuery,
  GetDealSpecialConditionsDocument,
} from "../../../generated/graphql-operations";

export const useGetDealSpecialConditions = (
  dealId: number,
  LoadData: (data: GetDealSpecialConditionsQuery) => void,
  onError?: (error: ApolloError) => void
) => {
  const data = useQuery(GetDealSpecialConditionsDocument, {
    variables: {
      deal_id: dealId,
    },
    onCompleted: LoadData,
    onError,
  });

  return data;
};

export const transformSpecialConditions = (
  data: GetDealSpecialConditionsQuery
): SpecialConditionsType => {
  const dealData = data.Deals_by_pk?.DealData[0]?.DealSpecialConditions;

  return {
    conditions: dealData?.map((item) => ({
      description: item.description ?? "",
      descriptionLaf: item.laf_description ?? "",
      specialCondition: {
        label: item.title ?? NO_CONDITION_USE.label,
        value: item.TemplateSpecialCondition?.id ?? NO_CONDITION_USE.value,
      },
    })),
  };
};

export const useMutateDealSpecialConditions = (dealId: number) => {
  const [deleteAndInsertConditions] = useMutation(
    DeleteAndInsertDealSpecialConditionsDocument
  );

  return async (dealDataId: number, data: SpecialConditionsType) => {
    if (data.conditions === undefined) {
      return;
    }

    const dealOptions = data.conditions?.map((item) => ({
      deal_data_id: dealDataId,
      description: item.description,
      title: item.specialCondition?.label,
      laf_description: item.descriptionLaf,
      template_id:
        item.specialCondition.value === NO_CONDITION_USE.value
          ? null
          : item.specialCondition.value,
    }));

    await deleteAndInsertConditions({
      variables: {
        deal_id: dealId,
        deal_options: dealOptions,
      },
    });
  };
};
