import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { SearchDocumentsDocument } from "../../generated/graphql-operations";

const SearchBar = (props: {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searchText, setSearchText] = useState("");
  return (
    <>
      <Stack direction="row">
        <TextField
          fullWidth
          id="search-bar"
          value={searchText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              props.setSearchQuery(searchText);
            }
          }}
          label="Enter a search term"
          variant="outlined"
          placeholder="Search..."
          size="small"
        />
        <IconButton
          onClick={() => props.setSearchQuery(searchText)}
          type="button"
          aria-label="search"
        >
          <SearchIcon style={{ fill: "blue" }} />
        </IconButton>
      </Stack>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        href="/chat"
        sx={{ mt: 2 }}
      >
        Go directly to AI chat
      </Button>
    </>
  );
};

const SearchList = (props: { searchQuery: string }) => {
  const { loading, error, data } = useQuery(SearchDocumentsDocument, {
    variables: {
      searchQuery: `%${props.searchQuery}%`,
    },
    skip: props.searchQuery === "",
  });

  if (props.searchQuery === "") {
    return null;
  }

  if (error) {
    return <Typography>Error loading data: {error.message}</Typography>;
  }

  if (loading) {
    return <LinearProgress />;
  }

  if (!data || data?.searchDocumentsSearch.length === 0) {
    return (
      <Typography variant="body1">
        Your search - {props.searchQuery} - did not match any documents
      </Typography>
    );
  }

  // FIXME: this is a bandaid fix for duplicate results. We need to figure out
  // why cognitive search is returning duplicates in the first place when we have time
  const noDuplicates = data.searchDocumentsSearch.filter(
    (item, index, self) => index === self.findIndex((t) => t?.id === item?.id)
  );

  return (
    <>
      {noDuplicates.map((item) => (
        <Box>
          <Link to="/chat" state={{ source: item?.id, title: item?.title }}>
            <Typography variant="h6">{item?.title}</Typography>
          </Link>
          <Typography fontSize={10} variant="body2">
            {item?.date}
          </Typography>
          <Typography fontSize={10} variant="body2">
            {`${item?.score ? item.score.toFixed(3) : ""}${
              item?.score && item?.centre ? " - " : ""
            }${item?.centre || ""}`}
          </Typography>
          <Typography variant="body1">{item?.content}</Typography>
        </Box>
      ))}
    </>
  );
};

export const SearchDocsComp = () => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <Card elevation={10} sx={{ p: 3, mt: 3 }}>
      <SearchBar setSearchQuery={setSearchQuery} />
      <Divider sx={{ p: 1, mb: 3 }} />
      <Stack spacing={2}>
        <SearchList searchQuery={searchQuery} />
      </Stack>
    </Card>
  );
};
