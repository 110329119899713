import React, { useState } from "react";
import { Typography, Grid, Stack, Container } from "@mui/material";
import { CreateButton, BreadCrumbs } from "../components";
import { ContactList } from "../components/Contacts";
import { CreateContactDialog } from "../components/Contacts/CreateUpdateContact/CreateUpdateContactDialog";
import { Role, useReturnUserRole } from "../contexts/Auth/UserRole";

export const Contacts = () => {
  const [createContactOpen, setCreateContactOpen] = useState(false);
  const disable = ![Role.Admin, Role.TdAssignee].includes(
    useReturnUserRole() as Role
  );

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Contacts</Typography>
            <CreateButton
              disabled={disable}
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => setCreateContactOpen(true)}
            >
              Create new contact
            </CreateButton>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mb: 1 }}>
          <BreadCrumbs
            links={[
              { text: "Dashboard", url: "/" },
              { text: "Contacts", url: "/Contacts" },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: 500,

            width: "100%",
          }}
        >
          <ContactList toolBar />
        </Grid>

        <CreateContactDialog
          open={createContactOpen}
          setOpen={setCreateContactOpen}
        />
      </Grid>
    </Container>
  );
};
