import React, { useEffect } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { DealInformationSectionProps } from "../DealInformationConfig";
import { Form, FormField } from "../../Form/Form";
import { CustomChargeForm } from "./CustomChargeForm";
import { Button } from "../../Button";
import { CreateButton } from "../../CreateButton";
import { Alert } from "../../Alert";
import { defaultCustomCharge } from "./ChargesConfig";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import {
  transformData,
  useGetDealInformationChargesInformation,
} from "../../../adapters/DealInformation/Charges/Charges";

export const Charges = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;

  const otherChargesFieldArray = useFieldArray({
    name: "charges.otherCharges",
    control: form.control,
  });

  const chargesInformation = useGetDealInformationChargesInformation(
    props.dealId
  );

  useEffect(() => {
    if (chargesInformation.data) {
      const data = transformData(chargesInformation.data);
      form.setValue("charges", data);
      form.setValue("charges.otherCharges", data.otherCharges);
      setDataState((prev) => ({ ...prev, chargesLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, chargesLoaded: false }));
    }
  }, [form, setDataState, chargesInformation]);

  if (chargesInformation.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!chargesInformation.data) {
    return <Skeleton />;
  }

  const fields: FormField[] = [
    {
      type: "text",
      fieldName: "charges.tenancyCoordinationFee",
      label: "Tenancy coordination fee (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
    {
      type: "text",
      fieldName: "charges.tenantContributionLandlordWorks",
      label: "Tenant contribution category 1 costs (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
    {
      type: "text",
      fieldName: "charges.recoverySurveyFee",
      label: "Recovery survey fee (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
    {
      type: "text",
      fieldName: "charges.openingPromotionLevyFees",
      label: "Opening promotion levy fees (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
    {
      type: "text",
      fieldName: "charges.developmentFees",
      label: "Development fees (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },

    {
      type: "text",
      fieldName: "charges.rubbishRemovalFees",
      label: "Rubbish removal fees (exc GST)",
      helperText: "",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
  ];

  return (
    <>
      <Form form={form} fields={fields} />

      <Grid item sm={4} mt={4}>
        <Typography variant="subtitle2">Other charges</Typography>
      </Grid>

      <Box width="100%" />

      {otherChargesFieldArray.fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Grid item sm={12} p={1}>
            <CustomChargeForm form={props.form} index={index} />
            <Grid container justifyContent="flex-end">
              <Grid item p={1}>
                <Button
                  startIcon={<Delete />}
                  color="error"
                  variant="text"
                  onClick={() => {
                    otherChargesFieldArray.remove(index);
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Grid item p={1}>
        <CreateButton
          startIcon={<Add />}
          color="primary"
          variant="text"
          onClick={() => {
            otherChargesFieldArray.append(defaultCustomCharge);
          }}
        >
          Add other charge
        </CreateButton>
      </Grid>
    </>
  );
};
