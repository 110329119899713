import React, { useState } from "react";
import MUIAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export interface AlertProps {
  title?: string;
  text: string;
  type: "info" | "success" | "warning" | "error";
  variant: "outlined" | "filled";
}

export const Alert: React.FC<AlertProps> = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const toRender = !isVisible ? null : (
    <MUIAlert
      variant={props.variant}
      severity={props.type}
      action={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            color="inherit"
            size="medium"
            onClick={() => setIsVisible(false)}
          >
            CLOSE
          </Button>
          <IconButton
            color="inherit"
            aria-label="close alert"
            component="span"
            size="small"
            onClick={() => setIsVisible(false)}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      }
    >
      {props.title ? <AlertTitle>{props.title}</AlertTitle> : null}
      {props.text}
    </MUIAlert>
  );

  return toRender;
};
