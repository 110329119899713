import React from "react";
import { DealContactsList } from "./DealContactsList";

interface DealContactsProps {
  dealId: number;
  dealDisabled: boolean;
}

export const DealContacts = (props: DealContactsProps) => {
  return (
    <DealContactsList dealId={props.dealId} dealDisabled={props.dealDisabled} />
  );
};
