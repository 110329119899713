import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import { BreadCrumbs, DealsList } from "../components";
import { CreateDealButton } from "../components/createDeal";

export const Deals = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Deals</Typography>
          <CreateDealButton />
        </Stack>
      </Grid>

      <Grid item xs={12} sx={{ mb: 1 }}>
        <BreadCrumbs
          links={[
            { text: "Dashboard", url: "/" },
            { text: "Deals", url: "" },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: 1500,

          width: "100%",
        }}
      >
        <DealsList toolBar />
      </Grid>
    </Grid>
  );
};
