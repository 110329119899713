import * as yup from "yup";
import { YupPhoneValidation } from "../../MuiCustomNumberFormat";

export const IndividualSchema = yup.object({
  tenant: yup
    .object({
      label: yup.string().required().default(""),
      value: yup.number().required().default(-1),
    })
    .default({ label: "", value: -1 })
    .test(
      "NoTenant",
      "Must select Tenant",
      (value) => !(value.value === -1 && value.label === "")
    ),
  id: yup.number().default(-1),
  label: yup.string().default(""),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  firstName: yup
    .string()
    .required("First name is a required field")
    .default(""),
  middleName: yup.string().default(""),
  lastName: yup.string().required("Last name is a required field").default(""),
  address: yup.string().default(""),
  city: yup.string().default(""),
  state: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string().default(""),
  addressId: yup.number().default(-1),
});

export const CompanySchema = yup.object({
  company: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .required("Company is required")
    .default({ label: "", value: -1 })
    .test(
      "NoCompany",
      "Must select Company",
      (value) => !(value.value === -1 && value.label === "")
    ),
  legalName: yup
    .string()
    .required("Trading name is a required field")
    .default(""),
  acn: yup
    .string()
    .test(
      "AcnLength",
      "ACN must be 9 digits",
      (val) => val?.length === 0 || val?.length === 9
    )
    .label("ACN")
    .default(""),
  contactSelect: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .required("Contact is required")
    .default({ label: "", value: -1 })
    .test("NoContact", "Must select Contact", (value) => !(value.value === -1)),
  firstName: yup
    .string()
    .required("First name is a required field")
    .default(""),
  middleName: yup.string().default(""),
  lastName: yup.string().required("Last name is a required field").default(""),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  phone: yup
    .string()
    .required("Phone number is a required field")
    .test("phone-test-validator", YupPhoneValidation)
    .default(""),
  address: yup.string().default(""),
  city: yup.string().default(""),
  state: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  postCode: yup.string().default(""),
  addressId: yup.number().default(-1),
});

export const TenantInformationSchema = yup.object({
  tenantType: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(-1),
    })
    .required()
    .default({ label: "", value: -1 })
    .test(
      "NoTenantType",
      "Must select Tenant type",
      (value) => !(value.value === -1 && value.label === "")
    ),
  tradingNameSelect: yup
    .object({
      label: yup.string().default(""),
      value: yup.string().default(""),
    })
    .default({ label: "", value: "" }),

  tradingName: yup.string().required("Trading name is required").default(""),
  individuals: yup
    .array()
    .default([])
    .when("tenantType", {
      is: (tenantType: { label: string; value: number }) =>
        tenantType?.label === "Individual",
      then: (schema) =>
        schema
          .of(IndividualSchema)
          .required()
          .default([])
          .min(1, "Deal cannot have 0 tenants"),
      otherwise: (schema) => schema.of(IndividualSchema).required().default([]),
    }),
  companies: yup
    .array()
    .default([])
    .when("tenantType", {
      is: (tenantType: { label: string; value: number }) =>
        tenantType?.label === "Company",
      then: (schema) =>
        schema
          .of(CompanySchema)
          .required()
          .default([])
          .min(1, "Deal cannot have 0 tenants"),
      otherwise: (schema) => schema.of(CompanySchema).required().default([]),
    }),
  noticeAddress: yup.string().default(""),
  noticeCity: yup.string().default(""),
  noticeState: yup
    .object({
      label: yup.string().default(""),
      value: yup.number().default(1),
    })
    .default({ label: "", value: 1 }),
  noticePostCode: yup.string().default(""),
  noticeAddressId: yup.number().default(-1),
  noticeEmail: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field")
    .default(""),
  customer: yup.object({
    label: yup.string().default(""),
    value: yup.string().default(""),
  }),
  salesCategory: yup.object({
    label: yup.string().default(""),
    value: yup.string().default(""),
  }),
  salesType: yup.object({
    label: yup.string().default(""),
    value: yup.string().default(""),
  }),
  leaseType: yup.object({
    label: yup.string().default(""),
    value: yup.string().default(""),
  }),
  chargeCode: yup.string().nullable(true),
});

export type IndividualTenantType = yup.InferType<typeof IndividualSchema>;
export type CompanyTenantType = yup.InferType<typeof CompanySchema>;
export type TenantInformationType = yup.InferType<
  typeof TenantInformationSchema
>;

export const defaultIndividual = IndividualSchema.getDefault();
export const defaultCompany = CompanySchema.getDefault();
export const defaultTenantInformation = TenantInformationSchema.getDefault();

export const SalesCategories = [
  "apparel",
  "foodcat",
  "foodret",
  "genretai",
  "homeware",
  "jewel",
  "leisure",
  "mobphone",
  "nonretai",
  "otherret",
  "services",
];
