import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useFieldArray } from "react-hook-form";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { DealInformationSectionProps } from "../DealInformationConfig";
import {
  defaultCompany,
  defaultIndividual,
  SalesCategories,
} from "./TenantInformationConfig";
import { IndividualTenantForm } from "./IndividualForm";
import { CompanyTenantForm } from "./CompanyForm/CompanyForm";
import { Button } from "../../Button";
import { CreateButton } from "../../CreateButton";
import { Form, FormField } from "../../Form/Form";
import { useGetStateData } from "../../../utils/CommonQueries";
import { DealTenantType } from "../../../utils/CommonVariables";
import {
  transformData,
  useGetDealInformationTenantInformation,
} from "../../../adapters/DealInformation/TenantInformation/TenantInformation";
import { Alert } from "../../Alert";
import { TradingName } from "../../CreateDeal/StepOne/TenantInformation/TradingName";
import {
  GetLeaseTypesDocument,
  GetYardiCustomersDocument,
  SalesType_Enum,
} from "../../../generated/graphql-operations";

const TENANT_TYPE_CHOICES = [
  { label: "Individual", value: DealTenantType.Individual },
  { label: "Company", value: DealTenantType.Company },
];

export const TenantInformation = (props: DealInformationSectionProps) => {
  const { form, setDataState } = props;
  const yardiCustomers = useQuery(GetYardiCustomersDocument);
  const leaseTypes = useQuery(GetLeaseTypesDocument);

  const individualFieldArray = useFieldArray({
    name: "tenantInfo.individuals",
    control: form.control,
  });

  const companiesFieldArray = useFieldArray({
    name: "tenantInfo.companies",
    control: form.control,
  });

  const tenantInformation = useGetDealInformationTenantInformation(
    props.dealId
  );

  useEffect(() => {
    if (tenantInformation.data) {
      const data = transformData(tenantInformation.data);
      form.setValue("tenantInfo", data);
      // I have to set the nested arrays separately otherwise the fieldArrays won't update properly
      form.setValue("tenantInfo.individuals", data.individuals);
      form.setValue("tenantInfo.companies", data.companies);
      setDataState((prev) => ({ ...prev, tenantInfoLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, tenantInfoLoaded: false }));
    }
  }, [tenantInformation, form, setDataState]);

  const states = useGetStateData(1);

  if (tenantInformation.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!tenantInformation.data) {
    return <Skeleton />;
  }

  const tenantTypeField: FormField = {
    type: "select",
    fieldName: "tenantInfo.tenantType",
    label: "Tenant type",
    helperText: "Mandatory",
    disabled:
      (form.watch("tenantInfo.tenantType.value") ===
        DealTenantType.Individual &&
        form.watch("tenantInfo.individuals").length > 0) ||
      (form.watch("tenantInfo.tenantType.value") === DealTenantType.Company &&
        form.watch("tenantInfo.companies").length > 0),
    choices: TENANT_TYPE_CHOICES,
    columns: 6,
  };

  const customerField: FormField = {
    type: "select",
    fieldName: "tenantInfo.customer",
    label: "Customer",
    helperText:
      "* If this field is blank, a new customer will be created in Yardi",
    columns: 6,
    choices: [
      { label: "", value: "" },
      ...(yardiCustomers?.data?.YardiCustomers?.map((customer) => ({
        label: customer.CustomerName ?? "",
        value: customer.CustomerCode,
      })) ?? []),
    ],
    onChange: (e, data) => {
      if (data.value !== "") {
        form.setValue("tenantInfo.tradingNameSelect", data);
        form.setValue("tenantInfo.tradingName", data?.label);
      }
    },
  };

  const salesCategoryField: FormField = {
    type: "select",
    fieldName: "tenantInfo.salesCategory",
    label: "Sales category",
    helperText: "",
    columns: 6,
    choices:
      SalesCategories.map((category) => ({
        label: category,
        value: category,
      })) ?? [],
  };

  const salesTypeField: FormField = {
    type: "select",
    fieldName: "tenantInfo.salesType",
    label: "Sales type",
    helperText: "",
    columns: 6,
    choices: Object.values(SalesType_Enum).map((salesType) => ({
      label: salesType,
      value: salesType,
    })),
  };

  const leaseTypeField: FormField = {
    type: "select",
    fieldName: "tenantInfo.leaseType",
    label: "Lease type",
    helperText: "",
    columns: 6,
    choices: leaseTypes?.data?.YardiTenants?.map((leaseType) => ({
      label: leaseType.LeaseType ?? "",
      value: leaseType.LeaseType ?? "",
    })),
  };

  const noticeFields: FormField[] = [
    {
      type: "text",
      fieldName: "tenantInfo.noticeAddress",
      label: "Address for serving notices",
      helperText: "Mandatory",
      columns: 12,
    },
    {
      type: "text",
      fieldName: "tenantInfo.noticeCity",
      label: "City/Suburb",
      helperText: "Mandatory",
      columns: 4,
    },
    {
      type: "select",
      fieldName: `tenantInfo.noticeState` as const,
      label: "State",
      helperText: "Mandatory",
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      columns: 4,
    },
    {
      type: "text",
      fieldName: "tenantInfo.noticePostCode",
      label: "Postcode",
      helperText: "Mandatory",
      columns: 4,
    },
    {
      type: "text",
      fieldName: "tenantInfo.noticeEmail",
      label: "Email for serving notices",
      helperText: "Mandatory",
      columns: 12,
    },
  ];

  return (
    <>
      <Grid container p={1}>
        <TradingName
          form={props.form}
          tradingNameFieldName="tenantInfo.tradingName"
          tradingNameSelectFieldName="tenantInfo.tradingNameSelect"
        />
        <Box width="100%" />
        <Form form={form} fields={[tenantTypeField]} />
        <Box width="100%" />
        <Form form={props.form} fields={[customerField]} />
        <Box width="100%" />
        <Form form={props.form} fields={[salesCategoryField, salesTypeField]} />
        <Box width="100%" />
        <Form form={props.form} fields={[leaseTypeField]} />
      </Grid>
      {form.watch("tenantInfo.tenantType.value") ===
      DealTenantType.Individual ? (
        <>
          {individualFieldArray.fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item sm={12} p={1}>
                <IndividualTenantForm form={props.form} index={index} />
                <Grid container justifyContent="flex-end">
                  <Grid item p={1}>
                    <Button
                      startIcon={<Delete />}
                      color="error"
                      variant="text"
                      onClick={() => {
                        individualFieldArray.remove(index);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}

          {form.watch("tenantInfo.tenantType.value") === -1 ? null : (
            <Grid item p={1}>
              <CreateButton
                startIcon={<Add />}
                color="primary"
                variant="text"
                onClick={() => {
                  individualFieldArray.append(defaultIndividual);
                }}
              >
                Add tenant
              </CreateButton>
            </Grid>
          )}
        </>
      ) : (
        <>
          {companiesFieldArray.fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item sm={12} p={1}>
                <CompanyTenantForm form={props.form} index={index} />
                <Grid container justifyContent="flex-end">
                  <Grid item p={1}>
                    <Button
                      startIcon={<Delete />}
                      color="error"
                      variant="text"
                      onClick={() => {
                        companiesFieldArray.remove(index);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}

          {form.watch("tenantInfo.tenantType.value") === -1 ? null : (
            <Grid item p={1}>
              <CreateButton
                startIcon={<Add />}
                color="primary"
                variant="text"
                onClick={() => {
                  companiesFieldArray.append(defaultCompany);
                }}
              >
                Add company
              </CreateButton>
            </Grid>
          )}
        </>
      )}

      <Box width="100%" />

      <Divider sx={{ width: "100%" }} />

      <Grid>
        <Form form={props.form} fields={noticeFields} />
      </Grid>
    </>
  );
};
