import React, { useEffect } from "react";
import { Box, Grid, InputLabel, Skeleton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { checkAndFormatDate } from "../../../utils/DateUtils";
import { SelectField, TextNumberField } from "../../Form/Form";
import { FormTextField, TextMultiField } from "../../Form/FormComponents";
import { DEFAULT_OPTIONS } from "./Config";
import { DealInformationSectionProps } from "../DealInformationConfig";
import {
  transformOptionTerms,
  useGetDealInfoOptionTerms,
} from "../../../adapters/DealInformation/OptionTerms/OptionTerms";
import { Alert } from "../../Alert";
import { CreateButton } from "../../CreateButton";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import { Button } from "../../Button";
import { AMOUNT_TYPE_CHOICES } from "../../../utils/CommonVariables";

import { calculateStartEndDates, isDisabledType } from "./utils";

const Options = ({
  form,
  index,
}: {
  form: DealInformationSectionProps["form"];
  index: number;
}) => {
  const prefixName = "optionTerm.options";

  const sliceData = form.watch(prefixName)?.slice(1, index + 1);

  const [
    CommencementDate,
    leaseYear,
    leaseMonth,
    leaseDay,
    termYear,
    termMonth,
  ] = form.watch([
    "dateInfo.leaseCommencementDate",
    "dateInfo.leaseTermYear",
    "dateInfo.leaseTermMonth",
    "dateInfo.leaseTermDay",
    `${prefixName}.0.TermYear`,
    `${prefixName}.0.TermMonth`,
  ]);

  const termsDates = React.useMemo(
    () =>
      calculateStartEndDates(
        CommencementDate,
        { years: leaseYear, months: leaseMonth, days: leaseDay },
        { years: termYear, months: termMonth },
        sliceData ?? []
      ),
    [
      CommencementDate,
      leaseYear,
      leaseMonth,
      leaseDay,
      termYear,
      termMonth,
      sliceData,
    ]
  );

  const termStartDate =
    checkAndFormatDate(termsDates?.startDate ?? new Date(""), "dd/MM/yyyy") ??
    "Not Valid Date";

  const termExpireDate =
    checkAndFormatDate(termsDates?.expireDate ?? new Date(""), "dd/MM/yyyy") ??
    "Not Valid Date";

  return (
    <Grid container>
      {/* ----- Term ------ */}
      <Grid item sm={3} p={1}>
        <TextNumberField
          form={form}
          formField={{
            type: "number",
            fieldName: `${prefixName}.${index}.TermYear`,
            inlineLabel: "Years",
            helperText: "",
            label: "Term",
          }}
        />
      </Grid>

      <Grid item sm={3} p={1}>
        <TextNumberField
          form={form}
          formField={{
            type: "number",
            fieldName: `${prefixName}.${index}.TermMonth`,
            inlineLabel: "Months",
            helperText: "",
            label: "",
          }}
        />
      </Grid>

      {/* ----- Dates ------ */}
      <Box width="100%" />

      <Grid item sm={6} p={1}>
        <FormTextField value={termStartDate} label="Term start date" disabled />
      </Grid>

      <Grid item sm={6} p={1}>
        <FormTextField
          value={termExpireDate}
          label="Term expiry date"
          disabled
        />
      </Grid>

      {/* -----Review type ------ */}
      <Grid item sm={6} px={1} pt={1}>
        <InputLabel>Year 1 review type</InputLabel>
      </Grid>

      <Grid item sm={6} px={1} pt={1}>
        <InputLabel>Subsequent year review type</InputLabel>
      </Grid>

      <Grid item sm={2} px={1} pb={1}>
        <SelectField
          form={form}
          formField={{
            type: "select",
            fieldName: `${prefixName}.${index}.year1ReviewType`,
            helperText: "",
            choices: AMOUNT_TYPE_CHOICES,
            defaultValue: DEFAULT_OPTIONS.year1ReviewType,
            label: "Type",
            onChange: (_, data) => {
              if (isDisabledType(data.value)) {
                form.setValue(`${prefixName}.${index}.year1Review`, undefined);
              }
            },
          }}
        />
      </Grid>

      <Grid item sm={4} px={1} pb={1}>
        <TextNumberField
          form={form}
          formField={{
            type: "text",
            fieldName: `${prefixName}.${index}.year1Review`,
            helperText: "",
            label: "Amount",
            disabled: isDisabledType(
              form.watch(`${prefixName}.${index}.year1ReviewType`)?.label
            ),
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>

      <Grid item sm={2} px={1} pb={1}>
        <SelectField
          form={form}
          formField={{
            type: "select",
            fieldName: `${prefixName}.${index}.yearSubsequentReviewType`,
            helperText: "",
            choices: AMOUNT_TYPE_CHOICES,
            label: "Type",
            defaultValue: DEFAULT_OPTIONS.yearSubsequentReviewType,
            onChange: (_, data) => {
              if (isDisabledType(data.value)) {
                form.setValue(
                  `${prefixName}.${index}.yearSubsequentReview`,
                  undefined
                );
              }
            },
          }}
        />
      </Grid>

      <Grid item sm={4} px={1} pb={1}>
        <TextNumberField
          form={form}
          formField={{
            type: "text",
            fieldName: `${prefixName}.${index}.yearSubsequentReview`,
            helperText: "",
            label: "Amount",
            disabled: isDisabledType(
              form.watch(`${prefixName}.${index}.yearSubsequentReviewType`)
                ?.label
            ),
            customComp: CurrencyFormatter,
          }}
        />
      </Grid>

      {/* ----- Description ------ */}
      <Grid item sm={12} p={1}>
        <TextMultiField
          control={form.control}
          formField={{
            fieldName: `${prefixName}.${index}.worksRequired`,
            helperText: "",
            label: "Description of refurbishment works required (if any)",
          }}
        />
      </Grid>
    </Grid>
  );
};

export const OptionTerm = (props: DealInformationSectionProps) => {
  const data = useGetDealInfoOptionTerms(props.dealId);

  const { form, setDataState } = props;

  const optionsFieldArray = useFieldArray({
    name: "optionTerm.options",
    control: form.control,
  });

  useEffect(() => {
    if (data.data) {
      form.setValue(
        "optionTerm.options",
        transformOptionTerms(data.data).options
      );
      setDataState((prev) => ({ ...prev, optionTermsLoaded: true }));
    } else {
      setDataState((prev) => ({ ...prev, optionTermsLoaded: false }));
    }
  }, [data, form, setDataState]);

  if (data.error) {
    return (
      <Alert
        variant="filled"
        type="error"
        title="Oops, something went wrong..."
        text="Please refresh your browser and try again"
      />
    );
  }

  if (!data.data) {
    return <Skeleton />;
  }

  return (
    <Box width="100%">
      {optionsFieldArray.fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Options form={props.form} index={index} />
          <Grid container justifyContent="flex-end">
            <Grid item p={1}>
              <Button
                startIcon={<Delete />}
                color="error"
                variant="text"
                onClick={() => {
                  optionsFieldArray.remove(index);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Box width="100%" p={1} />

      <Grid item p={1}>
        <CreateButton
          startIcon={<Add />}
          color="primary"
          variant="text"
          onClick={() => optionsFieldArray.append(DEFAULT_OPTIONS)}
        >
          Add option term
        </CreateButton>
      </Grid>
    </Box>
  );
};
