import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { Grid, Stack } from "@mui/material";
import {
  GetYardiPropertyContactsDocument,
  GetYardiPropertyContactsQuery,
} from "../../../generated/graphql-operations";

export interface PropertyListProps {
  propertyId: string;
  toolBar: boolean;
  hideColumns?: GridColumnVisibilityModel;
}

const CustomToolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter variant="filled" />
    </Stack>
  );
};

export const PropertyContacts = ({
  propertyId,
  toolBar,
  hideColumns = undefined,
}: PropertyListProps) => {
  const { data, loading, error } = useQuery(GetYardiPropertyContactsDocument, {
    variables: {
      property_code: propertyId,
    },
  });

  const columns: GridColDef<
    NonNullable<
      GetYardiPropertyContactsQuery["YardiProperties_by_pk"]
    >["YardiContacts_YardiProperties"][number]
  >[] = [
    {
      field: "Role",
      headerName: "Position",
      flex: 1,
      valueGetter: (params) => params.row.Role,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row?.YardiContact?.FirstName} ${params.row?.YardiContact?.LastName}`,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      valueGetter: (params) => {
        const [phoneNumber] = Object.entries(params.row?.YardiContact)
          .filter(([key, value]) => key.startsWith("Phone") && !!value)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(([_, value]) => value);

        return phoneNumber;
      },
    },
    {
      field: "Email",
      headerName: "Email",
      valueGetter: (params) => params.row?.YardiContact?.Email,
      flex: 1,
    },
  ];

  return (
    <Grid
      item
      xs={12}
      sx={{
        height: 500,
        width: "100%",
      }}
    >
      <DataGrid
        rows={data?.YardiProperties_by_pk?.YardiContacts_YardiProperties || []}
        columns={columns}
        loading={loading || error !== undefined}
        columnVisibilityModel={hideColumns}
        components={toolBar ? { Toolbar: CustomToolbar } : {}}
        getRowId={(row) => row?.YardiContact?.ContactCode}
        initialState={{
          sorting: {
            sortModel: [{ field: "Role", sort: "desc" }],
          },
        }}
      />
    </Grid>
  );
};
