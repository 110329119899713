import ReactDOM from "react-dom/client";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enAU from "date-fns/locale/en-AU";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

import "./themes/GlobalCss.css";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { apolloClient } from "./contexts/ApolloConfig";
import { AuthGate } from "./contexts/Auth/AuthGate";
import App from "./App";
import theme from "./themes/theme";
import reportWebVitals from "./reportWebVitals";
import { AppInsightsContext, reactPlugin } from "./contexts/AppInsights";
import { Error } from "./pages/Error";
import { ChatStateProvider } from "./contexts/Chat/ChatProvider";
import { FeatureFlagsProvider } from "./contexts/FeatureFlags";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
      onError={() => (
        <Error
          title="Something went wrong"
          message="Please try refreshing the page"
        />
      )}
    >
      <AppInsightsContext>
        <FeatureFlagsProvider>
          <BrowserRouter>
            <AuthGate>
              <ApolloProvider client={apolloClient}>
                <ThemeProvider theme={theme}>
                  <ChatStateProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enAU}
                    >
                      <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <App />
                      </StyledEngineProvider>
                    </LocalizationProvider>
                  </ChatStateProvider>
                </ThemeProvider>
              </ApolloProvider>
            </AuthGate>
          </BrowserRouter>
        </FeatureFlagsProvider>
      </AppInsightsContext>
    </AppInsightsErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
