import * as yup from "yup";

import { GREATER_THAN_0 } from "../../../utils/CommonErrors";

export const HistoricalDataSchema = yup.object({
  HistoricalTenant: yup
    .object({ label: yup.string(), value: yup.string() })
    .required()
    .default({ label: "", value: "" }),
  historicalBaseRent: yup
    .number()
    .nullable()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  historicalOutgoings: yup
    .number()
    .nullable()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
  historicalPromoLevy: yup
    .number()
    .nullable()
    .transform((value, org) => (org === "" ? undefined : Number(value)))
    .min(0, GREATER_THAN_0),
});

export type HistoricalDataType = yup.InferType<typeof HistoricalDataSchema>;
