import React, { createContext, ReactNode, useMemo, useReducer } from "react";
import { Conversation } from "../../pages/Chat/models";

export const appStateReducer = (
  state: ChatState,
  action: Action
): ChatState => {
  switch (action.type) {
    case "UPDATE_CURRENT_CHAT":
      return { ...state, currentChat: action.conversation };
    case "CLEAR_CHAT":
      return { ...state, currentChat: null };
    case "UPDATE_DOCUMENT_FILENAME":
      return { ...state, documentFilename: action.documentFilename };
    default:
      return state;
  }
};

export interface ChatState {
  documentFilename: string | null | undefined;
  currentChat: Conversation | null | undefined;
}

export type Action = {
  type: "UPDATE_CURRENT_CHAT" | "CLEAR_CHAT" | "UPDATE_DOCUMENT_FILENAME";
  conversation?: Conversation;
  documentFilename?: string;
};

const initialState: ChatState = {
  documentFilename: null,
  currentChat: null,
};

export const ChatStateContext = createContext<
  | {
      state: ChatState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

type ChatStateProviderProps = {
  children: ReactNode;
};

export const ChatStateProvider: React.FC<ChatStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  const contextObj = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ChatStateContext.Provider value={contextObj}>
      {children}
    </ChatStateContext.Provider>
  );
};
