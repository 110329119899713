import * as yup from "yup";

import { REQUIRED_FIELD, INVALID_NUMBER } from "../../../utils/CommonErrors";
import { VIRTUAL_UNIT_LABEL } from "../../../utils/CommonVariables";

export const UnitInformationSchema = yup.object({
  centre: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_FIELD)
    .default({ label: "", value: -1 })
    .test("NoCentre", "Must select Centre", (value) => value.value !== ""),
  unit: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .default({ label: "", value: -1 }),
  estimatedArea: yup
    .number()
    .required(REQUIRED_FIELD)
    .default(0)
    .typeError(INVALID_NUMBER),
  centreUnderDevelopment: yup.bool().default(false),
  areaSurveyRequired: yup.bool().default(false),
  isVirtual: yup.bool().default(false),
  virtualUnitDescription: yup.string().default(""),
  virtualUnit: yup
    .string()
    .when("unit", {
      is: (unit: { label: string; value: string }) =>
        unit?.label === VIRTUAL_UNIT_LABEL,
      then: (schema) =>
        schema.required(`${VIRTUAL_UNIT_LABEL} Name is required`),
      otherwise: (schema) => schema,
    })
    .default(""),
  unitType: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .when("unit", {
      is: (unit: { label: string; value: string }) =>
        unit?.label === VIRTUAL_UNIT_LABEL,
      then: (schema) =>
        schema.test(
          "NoUnitType",
          "Must select Unit Type",
          (value) => value.value !== ""
        ),
      otherwise: (schema) => schema,
    })
    .default({ label: "", value: "" }),
});
