import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Form, FormField } from "../../Form/Form";
import { DealInformationType } from "../DealInformationConfig";
import { useGetStateData } from "../../../utils/CommonQueries";

interface GuarantorFormProps {
  form: UseFormReturn<DealInformationType, any>;
  index: number;
  disabled?: boolean;
}

export const GuarantorForm = (props: GuarantorFormProps) => {
  const states = useGetStateData(1);
  const fields: FormField[] = [
    {
      type: "text",
      fieldName: `deposit.guarantors.${props.index}.fullName` as const,
      label: "Full name",
      helperText: "Mandatory",
      columns: 6,
      disabled: props.disabled,
    },
    {
      type: "text",
      fieldName: `deposit.guarantors.${props.index}.email` as const,
      label: "Email",
      helperText: "",
      columns: 6,
      disabled: props.disabled,
    },
    {
      type: "text",
      fieldName: `deposit.guarantors.${props.index}.address` as const,
      label: "Address",
      helperText: "",
      columns: 12,
      disabled: props.disabled,
    },
    {
      type: "text",
      fieldName: `deposit.guarantors.${props.index}.suburb` as const,
      label: "City/Suburb",
      helperText: "",
      columns: 4,
      disabled: props.disabled,
    },
    {
      type: "select",
      fieldName: `deposit.guarantors.${props.index}.state` as const,
      label: "State",
      helperText: "",
      columns: 4,
      choices:
        states.data?.States.map((state) => ({
          label: state.name as string,
          value: state.id,
        })) ?? [],
      disabled: props.disabled,
    },
    {
      type: "text",
      fieldName: `deposit.guarantors.${props.index}.postCode` as const,
      label: "Postcode",
      helperText: "",
      columns: 4,
      disabled: props.disabled,
    },
  ];

  return <Form form={props.form} fields={fields} />;
};
