import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";

import { v4 as uuidv4 } from "uuid";
import { Grid, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { ChatStateContext } from "../../contexts/Chat/ChatProvider";
import { QuestionInput } from "../../components/QuestionInput";
import { ChatDocument, ChatMessage } from "../../generated/graphql-operations";
import { AskResponse, ChatRole, Conversation } from "./models";
import { Message } from "../../components/Chat";
import { parseAnswer } from "./answerParser";
import { FeatureFlagsContext } from "../../contexts/FeatureFlags";

interface LocationState {
  source?: string;
  title?: string;
}

export const ChatPage = () => {
  // FIXME (Alex): There is a brief flicker that occasionally happens when
  // an AI response is received. Need to figure out why and how to fix if
  // possible.
  const featureFlags = useContext(FeatureFlagsContext);

  const chatStateContext = useContext(ChatStateContext);
  const chatMessageStreamEnd = createRef<HTMLDivElement>();
  const messages = chatStateContext?.state?.currentChat?.messages;
  const [sendMessage, { loading }] = useLazyQuery(ChatDocument);
  const location = useLocation();
  const state = location.state as LocationState;
  const source = state ? state.source : "";
  const title = state ? state.title : "";

  useEffect(() => {
    if (source !== chatStateContext?.state.documentFilename) {
      chatStateContext?.dispatch({
        type: "CLEAR_CHAT",
      });

      chatStateContext?.dispatch({
        type: "UPDATE_DOCUMENT_FILENAME",
        documentFilename: source,
      });
    }
  }, [chatStateContext, source]);

  const makeChatApiRequest = async (
    question: string,
    conversationId?: string
  ) => {
    const userMessage: ChatMessage = {
      id: uuidv4(),
      role: ChatRole.User,
      content: question,
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuidv4(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
      };
    } else {
      conversation = chatStateContext?.state?.currentChat;

      if (!conversation) {
        return;
      }
      conversation.messages.push(userMessage);
    }

    chatStateContext?.dispatch({
      type: "UPDATE_CURRENT_CHAT",
      conversation,
    });

    const response = await sendMessage({
      // Apollo seems to get confused when IDs are non-unique. This is a quick
      // fix, because we don't need to cache this anyway.
      // See https://github.com/apollographql/apollo-client/issues/8359
      // for more info.
      fetchPolicy: "no-cache",
      variables: {
        messages: conversation.messages.filter(
          (answer) => answer.role !== "error" && answer.role !== "tool"
        ),
        source: source ?? "",
      },
    });

    if (response.error) {
      const errorMessage =
        "An error occurred. Please try again. If the problem persists, please contact the site administrator.";

      const errorChatMsg: ChatMessage = {
        id: uuidv4(),
        role: ChatRole.Error,
        content: errorMessage,
      };

      conversation.messages.push(errorChatMsg);
      chatStateContext?.dispatch({
        type: "UPDATE_CURRENT_CHAT",
        conversation,
      });

      return;
    }

    const aiMessage = response.data?.chat?.choices[0]?.message as ChatMessage;
    const citations = aiMessage?.context?.messages as ChatMessage[];

    // Need to map the fields, because Apollo is adding a __typename field
    // which is causing trouble and because we need to generate an id
    const aiReply = [aiMessage, ...citations].map(({ id, role, content }) => {
      return {
        id: id || uuidv4(),
        role,
        content,
      };
    });

    if (aiReply) {
      conversation.messages.push(...aiReply);
      chatStateContext?.dispatch({
        type: "UPDATE_CURRENT_CHAT",
        conversation,
      });
    }
  };

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessageStreamEnd, loading]);

  if (!featureFlags?.features.isAiEnabled) {
    return null;
  }

  return (
    <Grid
      sx={{
        backgroundColor: "white",
        py: 1,
        px: 3,
        mb: 3,
        borderRadius: "10px",
        boxShadow: 3,
        minHeight: "80vh",
        justifyContent: "space-between",
      }}
      container
      direction="column"
    >
      <Grid item container direction="column">
        {!messages || messages.length < 1 ? (
          <>
            <Grid item sx={{ mt: 2 }}>
              <Typography variant="h4">Start chatting</Typography>
            </Grid>
            <Grid item>
              {title ? (
                <Typography variant="h5" sx={{ mt: 3 }}>
                  Ask me questions about the document {title}
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                  Here you can ask questions about all your documents. However,
                  due to the large scope of data, this query may yield
                  inaccurate results, and thus we recommend you select a
                  specific document using the search page for better results.
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <>
            {messages?.map((message, index) => {
              if (message.role === ChatRole.Assistant) {
                const answerText = message.content;

                const { citations } = JSON.parse(messages[index + 1].content);

                const answer: AskResponse = {
                  answer: answerText,
                  citations,
                };

                const parsedAnswer = parseAnswer(answer);

                return (
                  <Grid item key={message.id}>
                    <Message
                      message={parsedAnswer.markdownFormatText}
                      citations={parsedAnswer.citations}
                      role={ChatRole.Assistant}
                    />
                  </Grid>
                );
              }

              return (
                message.role !== ChatRole.Tool && (
                  <Grid item key={message.id}>
                    <Message
                      message={message.content}
                      role={message.role as ChatRole}
                    />
                  </Grid>
                )
              );
            })}
            <Grid item>
              {loading && (
                <Message
                  message="Generating answer.."
                  role={ChatRole.Assistant}
                />
              )}
            </Grid>
            <div ref={chatMessageStreamEnd} />
          </>
        )}
      </Grid>

      <Grid item sx={{ mb: 3 }}>
        <QuestionInput
          clearOnSend
          placeholder="Type a new question..."
          disabled={loading}
          onSend={(question, id) => {
            makeChatApiRequest(question, id);
          }}
          conversationId={
            chatStateContext?.state.currentChat?.id
              ? chatStateContext?.state.currentChat?.id
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};
