import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Form, FormField } from "../../Form/Form";
import { CurrencyFormatter } from "../../MuiCustomNumberFormat";
import { DealInformationType } from "../DealInformationConfig";

interface CustomChargeFormProps {
  form: UseFormReturn<DealInformationType, any>;
  index: number;
}

export const CustomChargeForm = (props: CustomChargeFormProps) => {
  const customChargeFields: FormField[] = [
    {
      type: "text",
      fieldName: `charges.otherCharges.${props.index}.description` as const,
      label: "Description",
      helperText: "Mandatory",
      columns: 6,
    },
    {
      type: "text",
      fieldName: `charges.otherCharges.${props.index}.amount` as const,
      label: "Amount (exc GST)",
      helperText: "Mandatory",
      customComp: CurrencyFormatter,
      adornment: {
        position: "start",
        value: "$",
      },
      columns: 6,
    },
  ];

  return <Form form={props.form} fields={customChargeFields} />;
};
