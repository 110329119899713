import React from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { GetDealFilesDocument } from "../../../../generated/graphql-operations";
import theme from "../../../../themes/theme";
import { Button } from "../../../Button";
import { FileUploadDialog } from "./FilesUploadDialog";
import { FileEditDialog } from "./FilesEditDialog";
import { FileCard } from "./FileCard";

export const FilesComponent = (props: {
  dealId: number;
  dealDisabled: boolean;
  defaultTags?: string[];
}) => {
  const [open, setOpen] = React.useState(false);
  const [editFileId, setEditFileId] = React.useState<number | undefined>(
    undefined
  );

  const dealFiles = useQuery(GetDealFilesDocument, {
    variables: {
      deal_id: props.dealId,
    },
  });

  const files = dealFiles?.data?.DealFiles;

  // Filter files based on the default tags, if provided
  const filteredFiles = props.defaultTags
    ? files?.filter((file) => {
        const fileTags = file.DealFileTags.map((tag) => tag.Tags.name);
        return props.defaultTags?.every((tag) => fileTags.includes(tag));
      })
    : files;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{ mb: 2 }}
        >
          <FileUploadIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
          <span style={{ color: theme.palette.primary.main }}>Upload file</span>
        </Button>
      </Grid>
      <Grid container item sm={12} spacing={2}>
        {filteredFiles?.map((file) => {
          return (
            <Grid item xs="auto" key={file.id}>
              <FileCard
                dealDisabled={props.dealDisabled}
                data={file}
                setEditDialog={() => setEditFileId(file.id)}
              />
            </Grid>
          );
        })}
      </Grid>

      <FileUploadDialog
        dealId={props.dealId}
        open={open}
        setOpen={setOpen}
        defaultTags={props.defaultTags}
      />
      <FileEditDialog
        dealDisabled={props.dealDisabled}
        fileData={filteredFiles?.find((item) => item.id === editFileId)}
        setFileDataUndefined={() => {
          setEditFileId(undefined);
        }}
      />
    </Grid>
  );
};
