import React from "react";
import MUIButton from "@mui/material/Button";
import { ButtonProps } from "@mui/material/Button/Button";

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <MUIButton
      {...props}
      sx={{
        ...props.sx,
        textTransform: "none",
      }}
    >
      {props.children}
    </MUIButton>
  );
};
